<template>
  <Login />
</template>

<script>
  import Login from '../components/Login'

  export default {
    components: {
      Login
    }
  }
</script>
