<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="6">
                <page-title title="INTRASTAT - Compras"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-row>
                    <v-col>
                        <v-btn small @click="download" color="success" :disabled="!formValid">Download</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- HEADER -->

        <!-- CONTENT -->

        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-form v-model="formValid" ref="myForm">
                        <v-row>
                            <v-col class="pb-0">
                                <v-text-field label="Tipo de Documento" outlined dense
                                              v-model="filters.docType"
                                              :rules="rules.required"></v-text-field>
                            </v-col>
                            <v-col class="pb-0">
                                <v-text-field label="Série" outlined dense v-model="filters.serie"
                                              :rules="rules.required"></v-text-field>
                            </v-col>
                            <v-col class="pb-0">
                                <v-text-field label="Número de Documento" outlined dense
                                              v-model="filters.numDoc"
                                              :rules="rules.required"></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pb-0">
                                <v-btn block color="success" :disabled="!formValid" @click="search()">Pesquisar</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-title style="background-color: lightgrey">Artigos sem código intrastat</v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th>Artigo</th>
                                <th>Descrição</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="line in products">
                                <td>{{ line.Artigo }}</td>
                                <td>{{ line.Descricao }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title style="background-color: lightgrey">Artigos Intrastat</v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th style="max-width: 50px">
                                    INTRASTAT
                                </th>
                                <th class="text-center">
                                    Peso
                                </th>
                                <th class="text-center">
                                    Quantidade
                                </th>
                                <th style="max-width: 25px" class="text-right">
                                    Total Preço
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="line in intrastat">
                                <td style="max-width: 50px">{{ line.CDU_INTSTAT }}</td>
                                <td class="text-center">{{ line.Peso.toFixed(2) }}</td>
                                <td class="text-center">{{ line.Quantidade }}</td>
                                <td style="max-width: 25px" class="text-right">{{ $money(line.TotalPreco) }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title style="background-color: lightgrey">Artigos no Intrastat</v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th>INTRASTAT</th>
                                <th>Artigo</th>
                                <th>Descrição</th>
                                <th class="text-center">Peso</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="line in intraProds">
                                <td>{{ line.CDU_INTSTAT }}</td>
                                <td>{{ line.Artigo }}</td>
                                <td>{{ line.Descricao }}</td>
                                <td class="text-center">{{ line.Peso }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- CONTENT -->

    </v-container>
</template>

<script>
import _ from "lodash";


export default {
    name: "IntrastatCompras",
    data: function () {
        return {
            filters: {
                docType: '',
                serie: '',
                numDoc: ''
            },
            products: [],
            intrastat: [],
            intraProds: [],
            formValid: false,
            rules: {
                required: [value => !!value || "Required."]
            },
        }
    },
    created() {
        document.title = 'Intrastat Compras | STAFF';
    },
    methods: {
        search() {
            let loader = this.$loading.show();
            this.products = [];
            this.intrastat = [];
            this.intraProds = [];
            let request = {
                url: '/api/staff/primavera/intrastatPurchases',
                data: {
                    filters: this.filters
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.products = responseData.products;
                            this.intrastat = responseData.intrastat;
                            this.intraProds = responseData.intrastatProducts;
                            //this.total = this.results.length;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })

        },
        download() {
            let request = {
                url: '/api/staff/primavera/intrastatPurchases/download',
                data: {
                    filters: this.filters
                }
            }
            ApiWS.dispatch('makeDownloadPost', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        })
                        var fileURL = URL.createObjectURL(file)
                        var a = document.createElement('a')
                        a.href = fileURL
                        a.target = '_blank'
                        a.download = this.filters.docType + '_' + this.filters.serie + '_' + this.filters.numDoc + '_intrastat.xlsx'
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                        console.log(response.data)
                    })
                    .catch((error) => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },

    },
    computed: {
        validateForm() {
            if (this.filters.numDoc !== '' && this.filters.docType !== '' && this.filters.serie !== '') {
                this.formValid = true;
            }
        }
    }

}
</script>
