<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title title="Clientes"
                            :subtitle="$t('show')+' '+clients.length+' de '+totalClients+' clientes'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small color="success" class="mr-3" to="/clients/clients/new">NOVO</v-btn>
                <v-btn tile small color="error" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th width="80px">Cliente</th>
                            <th>{{ $t('name') }}</th>
                            <th>Email</th>
                            <th>{{ $t('locality') }}</th>
                            <th width="100px">Código Postal</th>
                            <th width="40px">Zona</th>
                            <th width="60px">País</th>
                            <th width="100px">{{ $t('vatNumber') }}</th>
                            <th width="100px">Telefone</th>
                            <th width="100px">B2B</th>
                            <th width="1px">Form</th>
                            <th width="1px">Vend</th>
                            <th width="1px">Perfil</th>
                            <th width="1px" class="text-center" v-if="$readPermission('clients/client_cosec_guaranty')">
                                Cosec Dinamico
                            </th>
                            <th width="1px">
                                <v-img :src="require('../../assets/img/FOX.png')"></v-img>
                            </th>
                            <th width="1px">
                                <v-img :src="require('../../assets/img/NW.png')"></v-img>
                            </th>
                            <th width="1px">
                                <v-img :src="require('../../assets/img/SANTA.png')"></v-img>
                            </th>
                            <th width="1px">
                                <v-img :src="require('../../assets/img/KENDA.png')"></v-img>
                            </th>
                            <th width="1px">
                                <v-img :src="require('../../assets/img/COMP.png')"></v-img>
                            </th>
                            <th width="1px">
                                <v-img :src="require('../../assets/img/RODAS.png')"></v-img>
                            </th>
                            <th width="1px">
                                <v-img :src="require('../../assets/img/CERVELO.png')"></v-img>
                            </th>

                            <th width="1px"></th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                        v-model="filters.entity"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.email"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.locality"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.postalCode"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.zone"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.country"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.vatNumber"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.phone"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.accessB2B"
                                        :items="filtersB2B"
                                        dense
                                        outlined
                                        hide-details
                                        @change="setFilters"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.formacao"
                                        :items="filtersYesOrNo"
                                        dense
                                        outlined
                                        hide-details
                                        @change="setFilters"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.salesman"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.profile"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td v-if="$readPermission('clients/client_cosec_guaranty')">
                                <v-select
                                        v-model="filters.cosec"
                                        :items="filtersYesOrNo2"
                                        item-value="VALUE"
                                        item-text="TEXT"
                                        dense
                                        outlined
                                        hide-details
                                        @change="setFilters"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.fox"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.nw"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.santa"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.kenda"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.components"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.wheels"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.cervelo"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="20">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="client in clients">
                            <td>{{ client.Cliente }}</td>
                            <td>{{ client.Nome }}</td>
                            <td>
                                {{ client.Email }}
                                <template v-if="readPermission('clients/clients_accounting')"
                                          v-for="email in client.emailsAccounting">
                                    <br> <span class="red--text"> {{ email.Email }} </span>
                                </template>
                            </td>
                            <td>{{ client.Fac_Local }}</td>
                            <td>{{ client.Fac_Cp }}</td>
                            <td class="text-center">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">{{ client.Zona }}</span>
                                    </template>
                                    <span>{{ client.NomeZona }}</span>
                                </v-tooltip>
                            </td>
                            <td style="white-space: nowrap">
                                {{ client.Pais }}
                                <flag :iso="client.Pais" v-bind:squared=false></flag>
                            </td>
                            <td>{{ client.NumContrib }}</td>
                            <td>{{ client.Telefone }}</td>
                            <td style="white-space: nowrap">
                                <span v-if="client.B2B === '1'"><i class="fas fa-times fa-fw" style="color: red"></i>1-Fech</span>
                                <span v-if="client.B2B === '2'"><i class="fas fa-check fa-fw" style="color: green"></i>2-Aber</span>
                                <span v-if="client.B2B === '3'"><i class="fas fa-times fa-fw" style="color: orange"></i>3-Susp</span>
                                <span v-if="client.B2B === '4'"><i class="fas fa-times fa-fw" style="color: orange"></i>4-Canc</span>
                            </td>
                            <td class="text-center">
                                <span v-if="client.CDU_FORMACAO === 1">{{ $t('yes') }}</span>
                                <span v-if="client.CDU_FORMACAO === 0">{{ $t('no') }}</span>
                            </td>
                            <td class="text-center">{{ client.Vendedor }}</td>
                            <td class="text-center">{{ client.CDU_PROFILE }}</td>
                            <td class="text-center" v-if="$readPermission('clients/client_cosec_guaranty')">
                                <span v-if="client.cosecDynamic > 0">{{ $t('yes') }}</span>
                                <span v-if="client.cosecDynamic === 0">{{ $t('no') }}</span>
                            </td>
                            <td class="text-center" v-bind:class="{ 'red--text font-weight-bold': client.CDU_FOX > 0 }">
                                {{ client.CDU_FOX }}
                            </td>
                            <td class="text-center" v-bind:class="{ 'red--text font-weight-bold': client.CDU_NW > 0 }">
                                {{ client.CDU_NW }}
                            </td>
                            <td class="text-center"
                                v-bind:class="{ 'red--text font-weight-bold': client.CDU_SANTABIKES > 0 }">
                                {{ client.CDU_SANTABIKES }}
                            </td>
                            <td class="text-center"
                                v-bind:class="{ 'red--text font-weight-bold': client.CDU_KENDA > 0 }">
                                {{ client.CDU_KENDA }}
                            </td>
                            <td class="text-center"
                                v-bind:class="{ 'red--text font-weight-bold': client.COMPONENTS > 0 }">
                                {{ client.COMPONENTS }}
                            </td>
                            <td class="text-center" v-bind:class="{ 'red--text font-weight-bold': client.WHEELS > 0 }">
                                {{ client.WHEELS }}
                            </td>
                            <td class="text-center" v-bind:class="{ 'red--text font-weight-bold': client.CDU_CERVELO > 0 }">
                                {{ client.CDU_CERVELO }}
                            </td>
                            <td>
                                <v-btn tile small color="primary" :to="'/clients/clients/'+client.Cliente">Editar
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="4">
                <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        dense
                        outlined
                        hide-details
                        @change="initialize"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="float: left; width: 100px;"
                ></v-select>
            </v-col>
            <v-col class="text-right">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="initialize"
                        style="justify-content: flex-end;"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'

export default {
    data: () => ({
        clients: [],
        totalClients: 0,
        loading: false,
        pagination: {
            per_page: 25,
            page: 1
        },
        filters: {
            entity: '',
            name: '',
            email: '',
            locality: '',
            postalCode: '',
            district: '',
            country: '',
            vatNumber: '',
            phone: '',
            accessB2B: '',
            salesman: '',
            formacao: '',
            profile: '',
            cosec: '',
            fox: '',
            nw: '',
            components: '',
            wheels: '',
            kenda: '',
            santa: '',
            cervelo: '',
            zone: ''
        },
        filtersYesOrNo: [
            {TEXT: '-', VALUE: ''},
            {TEXT: 'Sim', VALUE: 100},
            {TEXT: i18n.t('no'), VALUE: 250},
        ],
        filtersYesOrNo2: [
            {TEXT: 'Todos', VALUE: ''},
            {TEXT: i18n.t('no'), VALUE: 0},
            {TEXT: i18n.t('yes'), VALUE: 1},
        ],
        filtersPerPage: [
            {TEXT: '25', VALUE: 25},
            {TEXT: '50', VALUE: 50},
            {TEXT: '100', VALUE: 100},
            {TEXT: '250', VALUE: 250},
            {TEXT: '500', VALUE: 500},
            {TEXT: '750', VALUE: 750}
        ],
        filtersB2B: [
            {TEXT: 'Todos', VALUE: ''},
            {TEXT: i18n.t('closed'), VALUE: '1'},
            {TEXT: i18n.t('opened'), VALUE: '2'},
            {TEXT: i18n.t('suspense'), VALUE: '3'},
            {TEXT: 'Cancelado', VALUE: '4'}
        ]
    }),
    computed: {
        getTotalPages() {
            return Math.ceil(this.totalClients / this.pagination.per_page)
        }
    },
    created() {
        document.title = 'Clientes';
        this.initialize()
    },
    methods: {
        initialize() {
            this.loading = true;
            this.clients = [];

            let filters = {
                pagination: this.pagination,
                filters: this.filters
            };

            ClientModel.dispatch('getClients', filters)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.clients = responseData.clients;
                            this.totalClients = responseData.totalClients;
                        }
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
        },
        setFilters() {
            this.pagination.page = 1;
            this.initialize();
        },
        clearFilters() {
            this.filters = {
                entity: '',
                name: '',
                email: '',
                locality: '',
                postalCode: '',
                district: '',
                country: '',
                vatNumber: '',
                phone: '',
                accessB2B: '',
                salesman: '',
                formacao: '',
                profile: '',
                cosec: '',
                fox: '',
                nw: '',
                components: '',
                wheels: '',
                kenda: '',
                santa: '',
                cervelo: '',
                zone: ''
            };
            this.pagination.page = 1;
            this.initialize()
        },
        readPermission(permission) {
            return User.getters.readPermission(permission)
        }
    }
}
</script>
