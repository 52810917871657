<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="$t('ordersHistoric')"
                        :subtitle="$t('show') + ' ' + orders.length + ' de ' + originalOrders.length + ' ' + $t('orders').toLowerCase()"
                ></page-title>
            </v-col>
            <v-col class="text-right" align-self="center">
                <v-btn tile small color="success" @click="exportExcel()">Exportar Excel</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 50px">País</th>
                            <th>Entidade</th>
                            <th>{{ $t('name') }}</th>
                            <th>{{ $t('date') }}</th>
                            <th>{{ $t('product') }}</th>
                            <th>CodBarras</th>
                            <th>{{ $t('description') }}</th>
                            <th>Marca</th>
                            <th style="text-align: center">Qt Pedida</th>
                            <th style="text-align: center">Qt Servida</th>
                            <th style="text-align: center">Qt Pendente</th>
                            <th>{{ $t('address1') }}</th>
                            <th>ModoPag</th>
                            <th style="text-align: center">Vend</th>
                            <th>Estado</th>
                            <th>NumDoc</th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-select
                                        v-model="filters.country"
                                        :items="filtersCountry"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.entity"
                                        outlined
                                        hide-details
                                        single-line
                                        @keypress.enter="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        outlined
                                        hide-details
                                        single-line
                                        @keypress.enter="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.date"
                                        outlined
                                        hide-details
                                        single-line
                                        @keypress.enter="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.product"
                                        outlined
                                        hide-details
                                        single-line
                                        @keypress.enter="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.barcode"
                                        outlined
                                        hide-details
                                        single-line
                                        @keypress.enter="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.description"
                                        outlined
                                        hide-details
                                        single-line
                                        @keypress.enter="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.brand"
                                        outlined
                                        hide-details
                                        single-line
                                        @keypress.enter="updateFilters"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.salesman_id"
                                        outlined
                                        hide-details
                                        single-line
                                        @keypress.enter="updateFilters"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.numDoc"
                                        outlined
                                        hide-details
                                        single-line
                                        @keypress.enter="updateFilters"
                                ></v-text-field>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="18">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <template v-for="order in orders">
                            <tr v-if="differentDate(order)">
                                <td colspan="18" class="grey lighten-2 date_row">{{ order.DataEntrega }}</td>
                            </tr>
                            <tr>
                                <td>{{ order.Entidade_pais }}</td>
                                <td>
                                    {{ order.Entidade }}
                                </td>
                                <td>{{ order.ClienteNome }}</td>
                                <td>{{ order.Data }}</td>
                                <td>{{ order.Artigo }}</td>
                                <td>{{ order.CodBarras }}</td>
                                <td>{{ order.ArtigoDescricao }}</td>
                                <td>{{ order.Marca }}</td>
                                <td style="text-align: center">{{ order.QuantidadePedida }}</td>
                                <td style="text-align: center">{{ order.QuantidadeServida }}</td>
                                <td style="text-align: center">{{ order.QuantidadePendente }}</td>
                                <td>{{ order.MoradaAltEntrega }}</td>
                                <td>{{ order.ModoPag }}</td>
                                <td>{{ order.Vendedor }}</td>
                                <td class="text-center">
                                    <v-chip small v-if="order.Fechado == 0 && order.Estado == 'P'" color="warning">{{
                                            capitalize($t('pending'))
                                        }}
                                    </v-chip>
                                    <v-chip small v-if="order.Fechado == 1 && order.Estado == 'P'" color="error">{{
                                            $t('canceled')
                                        }}
                                    </v-chip>
                                    <v-chip small v-if="order.Fechado == 0 && order.Estado == 'T'" color="success">
                                        Faturado
                                    </v-chip>
                                </td>
                                <td>
                                    {{ order.NumDoc }}
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        outlined
                        single-line
                        @change="updateFilters()"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="width: 90px; float: left"
                        class="staff_select"
                        hide-details
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="filterOrders"
                        style="width: auto; float: right;"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: function () {
        return {
            orders: [],
            originalOrders: [],
            filteredOrders: [],
            loading: false,
            filters: {
                country: '',
                entity: '',
                name: '',
                date: '',
                product: '',
                description: '',
                barcode: '',
                brand: '',
                salesman_id: '',
                numDoc: ''
            },
            filtersCountry: [
                {text: '-', value: ''},
                {text: 'PT', value: 'PT'},
                {text: 'ES', value: 'ES'}
            ],
            pagination: {
                per_page: 500,
                page: 1
            },
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750},
                {TEXT: '1000', VALUE: 1000}
            ],
            totalOrders: 0
        }
    },
    private: {
        currentDate: ''
    },
    created() {
        this.getOrders()
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.totalOrders / this.pagination.per_page)
        },
        docNumbers() {
            return this.filteredOrders.map(order => order.NumDoc).filter(
                    (value, index, self) => self.indexOf(value) === index
            );
        }
    },
    methods: {
        getOrders() {
            this.loading = true
            let request = {
                url: api.orderHistoric()
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.orders = responseData.orders
                            this.originalOrders = responseData.originalOrders
                            this.filterOrders();
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        filterOrders() {
            window.scrollTo(0, 0);
            this.orders = this.originalOrders

            if (this.filters.country !== '') {
                this.orders = this.orders.filter(order => order.Entidade_pais === this.filters.country)
            }
            if (this.filters.entity !== '') {
                this.orders = this.orders.filter(order => order.Entidade.toUpperCase().includes(this.filters.entity.toUpperCase()))
            }
            if (this.filters.name !== '') {
                this.orders = this.orders.filter(order => order.ClienteNome.toLowerCase().includes(this.filters.name.toLowerCase()) || order.NomeFiscal.toLowerCase().includes(this.filters.name.toLowerCase()))
            }
            if (this.filters.date !== '') {
                this.orders = this.orders.filter(order => order.Data.includes(this.filters.date))
            }
            if (this.filters.product !== '') {
                this.orders = this.orders.filter(order => order.Artigo.toUpperCase().includes(this.filters.product.toUpperCase()))
            }
            if (this.filters.barcode !== '') {
                this.orders = this.orders.filter(order => (order.CodBarras !== null && order.CodBarras.includes(this.filters.barcode)))
            }
            if (this.filters.description !== '') {
                this.orders = this.orders.filter(order => order.ArtigoDescricao.toLowerCase().includes(this.filters.description.toLowerCase()))
            }
            if (this.filters.brand !== '') {
                this.orders = this.orders.filter(order => order.Marca.toLowerCase().includes(this.filters.brand.toLowerCase()))
            }
            if (this.filters.salesman_id !== '') {
                this.orders = this.orders.filter(order => order.Vendedor.toUpperCase().includes(this.filters.salesman_id.toUpperCase()))
            }
            if (this.filters.numDoc !== '') {
                this.orders = this.orders.filter(order => order.NumDoc.toString().includes(this.filters.numDoc));
                //this.orders = this.orders.filter(order => order.NumDoc == this.filters.numDoc)
            }

            this.totalOrders = this.orders.length;
            let offset = (this.pagination.page - 1) * this.pagination.per_page;
            this.filteredOrders = this.orders;
            this.orders = this.orders.slice(offset).slice(0, this.pagination.per_page);
        },
        updateFilters() {
            this.pagination.page = 1;
            this.filterOrders();
        },
        capitalize(s) {
            if (typeof s !== 'string') return ''
            return s.charAt(0).toUpperCase() + s.slice(1)
        },
        differentDate(order) {
            if (order.DataEntrega !== this.currentDate) {
                this.currentDate = order.DataEntrega
                return true
            }
            return false
        },
        exportExcel() {
            let processedDocs = "";
            this.docNumbers.forEach((doc, index) => {
                if (index > 0)
                    processedDocs += ";";
                processedDocs += doc;
            });

            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/orders/exportOrdersHistoric',
                data: {
                    docs: this.docNumbers
                }
            }

            ApiWS.dispatch('makeDownloadPost', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';

                        a.download = 'HistoricoEncomendas.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        }
    }
}
</script>
