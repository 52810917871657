<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title back :title="$t('reportsApp')+' '+$t('detail')"></page-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>{{ $t('customerInformation') }}</v-card-title>
                    <v-card-text>
                        <p><strong>Data: </strong>{{ report.visited_at }}</p>
                        <p><strong>Tipo de Visita: </strong>{{ report.description }}</p>
                        <p><strong>Cliente: </strong>{{ report.client_id }} - {{ report.Nome }}</p>
                        <p><strong>Vendedor: </strong>{{ report.salesman_id }}</p>
                        <p>
                            <strong>{{ $t('sale') }} {{ $t('made') }}: </strong>
                            <span v-if="report.saleaccomplished === 0">{{ $t('no') }}</span>
                            <span v-if="report.saleaccomplished === 1">{{ $t('yes') }}</span>
                        </p>
                        <p>
                            <strong>Cliente {{ $t('closed') }}: </strong>
                            <span v-if="report.clientclosed === 0">{{ $t('no') }}</span>
                            <span v-if="report.clientclosed === 1">{{ $t('yes') }}</span>
                        </p>
                        <p>
                            <strong>Problemas {{ $t('financial') }}: </strong>
                            <span v-if="report.clientfinancialproblems === 0">{{ $t('no') }}</span>
                            <span v-if="report.clientfinancialproblems === 1">{{ $t('yes') }}</span>
                        </p>
                        <p><strong>Notas: </strong> {{ report.notes }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>{{ $t('campaignInterest') }}</v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <tbody>
                            <tr v-for="campaign in campaigns">
                                <td style="width: 30%">{{ campaign.campaign_name }}</td>
                                <td style="width: 160px">
                                    <span class="fa-stack" v-for="index in [1,2,3,4,5]">
                                        <i v-if="campaign.campaign_interest >= index" class="fas fa-star fa-2x" style="color: gold"></i>
                                        <i v-if="campaign.campaign_interest < index" class="far fa-star fa-2x" style="color: grey"></i>
                                    </span>
                                </td>
                                <td>{{ campaign.campaign_notes }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from "../../plugins/i18n";

    export default {
        data: function () {
            return {
                reportId: '',
                report: {},
                campaigns: [],
            }
        },
        created() {
            document.title = i18n.t('reportsApp')+' '+i18n.t('detail')
            this.reportId = this.$route.params.id
            this.getReportDetail()
        },
        methods: {
            getReportDetail() {
                let request = {
                    url: api.commercialReportsApp() + '/' + this.reportId
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.report = responseData.report
                            this.campaigns = responseData.campaigns
                        }
                    })
                    .catch(response => {

                    })
            }
        }
    }
</script>
