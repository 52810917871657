<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title :title="'Entregas '+$t('pending')"></page-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th>Estado da Expedição</th>
                        <th>Data de Criação</th>
                        <th>Última Atualização</th>
                        <th>Cliente</th>
                        <th>{{ $t('name') }}</th>
                        <th>Faturas</th>
                        <th>Transp.</th>
                        <th>Refêrencias</th>
                        <th>Ref. Transp.</th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr class="filters_row">
                        <td></td>
                        <td>
                            <v-text-field
                                    v-model="filters.created_at"
                                    dense
                                    outlined
                                    hide-details
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.updated_at"
                                    dense
                                    outlined
                                    hide-details
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.client"
                                    dense
                                    outlined
                                    hide-details
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.name"
                                    dense
                                    outlined
                                    hide-details
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.docs"
                                    dense
                                    outlined
                                    hide-details
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-select
                                    v-model="filters.courier"
                                    dense
                                    outlined
                                    hide-details
                                    :items="carriers"
                                    item-value="VALUE"
                                    item-text="TEXT"
                                    style="width: 115px"
                                    @change="filterTable"
                            ></v-select>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.reference"
                                    dense
                                    outlined
                                    hide-details
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.tracking"
                                    dense
                                    outlined
                                    hide-details
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="loading">
                        <td colspan="12">
                            <v-progress-linear indeterminate></v-progress-linear>
                        </td>
                    </tr>
                    <tr v-for="shipped in shippedPending" :class="{'red lighten-4': dateDiffInDays(shipped.createdAt) > 4}">
                        <td>{{ shipped.status }}</td>
                        <td>{{ shipped.createdAt }}</td>
                        <td>{{ shipped.updatedAt }}</td>
                        <td>{{ shipped.clientCode }}</td>
                        <td>{{ shipped.clientName }}</td>
                        <td>{{ shipped.docs }}</td>
                        <td>{{ shipped.courier }}</td>
                        <td>{{ shipped.reference }}</td>
                        <td>{{ shipped.trackingNumber }}</td>
                        <td>
                            <template v-if="shipped.courier === 'SEUR' || shipped.courier === 'SEUR2'">
                                <a :href="`https://dpd.pt/track-and-trace?reference=${shipped.trackingNumber}`" target="_blank"><i class="fas fa-fw fa-external-link-alt"></i></a>
                            </template>
                            <template v-if="shipped.courier === 'CTT'">
                                <a :href="`http://www.ctt.pt/feapl_2/app/open/cttexpresso/objectSearch/objectSearch.jspx?objects=${shipped.trackingNumber}}`" target="_blank"><i class="fas fa-fw fa-external-link-alt"></i></a>
                            </template>
                        </td>
                        <td>
                            <v-btn small class="info" @click="editNotesShipped(shipped)" v-if="writePermission('orders/shipped')">
                                Notas
                            </v-btn>
                        </td>
                        <td>
                            <v-btn small class="error" @click="closeShipped(shipped)" v-if="writePermission('orders/shipped')">
                                Arquivar
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialogCancel" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Arquivar envio?</v-card-title>
                    <v-card-text>{{ $t('wantArchiveShipped') }} {{ itemCancel.ref }}?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" @click="dialogCancel = false">{{ $t('no') }}</v-btn>
                        <v-btn color="red darken-1" @click="archive()">{{ $t('yes') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogNotes" persistent max-width="500">
                <v-card>
                    <v-card-title class="headline">Notas do Envio</v-card-title>
                    <v-card-text>
                        <v-textarea
                                v-model="itemEdit.notes"
                                outlined
                                dense
                                hide-details
                        ></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn small @click="dialogNotes = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn small class="success" @click="saveNotes()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import i18n from "../../plugins/i18n";
import Api from "@/routers/api";

export default {
    data: () => ({
        loading: false,
        headers: [],
        shippedPending: [],
        shippedPendingAux: [],
        dialogCancel: false,
        dialogNotes: false,
        itemCancel: {},
        itemEdit: {},
        filters: {
            created_at: '',
            updated_at: '',
            client: '',
            name: '',
            docs: '',
            courier: '',
            reference: '',
            tracking: ''
        },
        carriers: [
            {TEXT: 'Todas', VALUE: ''},
            {TEXT: 'SEUR2', VALUE: 'SEUR2'},
            {TEXT: 'CTT', VALUE: 'CTT'},
            {TEXT: 'REDUR', VALUE: 'REDUR'},
            {TEXT: 'DELNEXT', VALUE: 'DELNEXT'},
        ]
    }),
    created() {
        document.title = 'Entregas ' + i18n.t('pending')
        this.initialize()
    },
    methods: {
        initialize() {
            this.loading = true
            this.shippedPending = [];
            Order.dispatch('getOrdersShipped', 'flag_pendingonly')
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.headers = responseData.headers
                            this.shippedPending = responseData.shipped
                            this.shippedPendingAux = this.shippedPending
                            this.filterTable()
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        dateDiffInDays(date1) {
            var t2 = new Date().getTime()
            var t1 = new Date(date1).getTime()

            return parseInt((t2 - t1) / (24 * 3600 * 1000));
        },
        closeShipped(item) {
            this.itemCancel = item
            this.dialogCancel = true

        },
        archive() {
            let request = {
                url: '/api/staff/orders/shipped/archive',
                data: {
                    'shippedRef': this.itemCancel.reference
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then((response) => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let alert = {
                                snackbar: true,
                                text: 'Envio arquivado com sucesso',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)

                            this.initialize()
                        } else {
                            let alert = {
                                snackbar: true,
                                text: responseData.data,
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                        this.dialogCancel = false
                        this.itemCancel = {}
                    })
                    .catch(() => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao arquivar envio',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        filterTable() {
            this.shippedPending = this.shippedPendingAux
            if (this.filters.created_at !== '') {
                this.shippedPending = this.shippedPending.filter(shipped => shipped.createdAt !== null && shipped.createdAt.includes(this.filters.created_at))
            }
            if (this.filters.updated_at !== '') {
                this.shippedPending = this.shippedPending.filter(shipped => shipped.updatedAt !== null && shipped.updatedAt.includes(this.filters.updated_at))
            }
            if (this.filters.client !== '') {
                this.shippedPending = this.shippedPending.filter(shipped => shipped.clientCode !== null && shipped.clientCode.toUpperCase().includes(this.filters.client.toUpperCase()))
            }
            if (this.filters.name !== '') {
                this.shippedPending = this.shippedPending.filter(shipped => shipped.clientName !== null && shipped.clientName.toLowerCase().includes(this.filters.name.toLowerCase()))
            }
            if (this.filters.docs !== '') {
                this.shippedPending = this.shippedPending.filter(shipped => shipped.docs !== null && shipped.docs !== false && shipped.docs.toUpperCase().includes(this.filters.docs.toUpperCase()))
            }
            if (this.filters.courier !== '') {
                this.shippedPending = this.shippedPending.filter(shipped => shipped.courier !== null && shipped.courier === this.filters.courier)
            }
            if (this.filters.reference !== '') {
                this.shippedPending = this.shippedPending.filter(shipped => shipped.ref !== null && shipped.ref.toUpperCase().includes(this.filters.reference.toUpperCase()))
            }
            if (this.filters.tracking !== '') {
                this.shippedPending = this.shippedPending.filter(shipped => shipped.tracking_number !== null && shipped.tracking_number.toUpperCase().includes(this.filters.tracking.toUpperCase()))
            }
        },
        editNotesShipped(shipped) {
            this.itemEdit = shipped;
            this.dialogNotes = true;
        },
        saveNotes() {
            let request = {
                url: '/api/staff/orders/saveShippingNotes/' + this.itemEdit.reference,
                data: {
                    notes: this.itemEdit.notes
                }
            }

            ApiWS.dispatch('makePut', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.dialogNotes = false;
                            this.initialize();
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao editar notas do envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        }
    }
}
</script>
