<style>
.forms-stepper {
    .v-stepper__step--inactive, v-stepper__step {
        .v-stepper__step__step {
            background-color: rgba(192, 13, 13, .5) !important;
        }
    }

    .v-stepper__step--active {
        .v-stepper__step__step {
            background-color: rgb(192, 13, 13) !important;
        }
    }
}

.create-new-form {
    background-image: url("../../assets/img/new-form-img.jpg");
    background-position: center;
    background-size: cover;

    .content-new-card-form {
        width: 100%;
        height: 100%;
        min-height: 235px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        backdrop-filter: blur(2px);

        &:hover {
            cursor: pointer;
            backdrop-filter: blur(2px) brightness(0.8);

            .v-btn {
                animation: hover-new 1s forwards;

                .not-hover {
                    display: none !important;
                }

                .hover {
                    display: block !important;
                }
            }
        }
    }
}

.form-card {
    transition: box-shadow .3s ease, transform .2s ease;

    .form-card-content {
        transition: transform .2s ease, opacity .2s ease;
    }

    &:hover {
        border: 1px solid black;
        transform: scale(1.01);
        box-shadow: 0 8px 50px #23232333;

    }
}

@keyframes hover-new {
    0% {
        transform: scale(1);
        font-weight: normal;
        margin-bottom: 0;
    }
    100% {
        transform: scale(1.2);
        font-weight: bold;
        margin-bottom: 15px;
        border: 1px solid white;
    }
}
</style>
<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="8">
                <page-title title="Questionários"></page-title>
                <p class="mb-0 pl-2" style="color: gray">
                    Página responsável pela gestão de questionários realizados pela bicimax. Nesta página é possivel,
                    ver a listagem de questionários criados e ainda criar novos questionários.</p>
            </v-col>
            <v-col cols="12" md="4" class="text-right">
                <v-btn tile small class="ml-2 mb-2" @click="showInactive = true" color="warning">Todos</v-btn>
                <v-btn tile small class="ml-2 mb-2" @click="showInactive = false" color="success">Apenas Ativos</v-btn>
            </v-col>
        </v-row>


        <!-- LISTA DE FORMULÁRIOS -->
        <v-row v-if="!editingForm && !loading">
            <v-col cols="12" md="3" v-for="form in forms" :key="form.id" v-if="showInactive || !showInactive && form.active == true">
                <v-card
                        class="mx-auto form-card"
                        max-width="360"
                        min-width="360"
                        style="min-height: 235px;"
                        outlined
                >
                    <v-list-item three-line style="min-height: 180px;">
                        <v-list-item-content class="form-card-content">
                            <div class="text-overline mb-4">
                                <!-- NAME & ID -->
                                <span style="font-weight: bold;color: rgba(0,0,0,.8)">
                                    {{ form.name }}
                                </span>
                                <span class="pl-2 pr-2"
                                      style="float: right; font-weight: bold; font-size: 20px; background-color: rgba(0,0,0,.08)">{{
                                        form.id
                                    }}</span>
                            </div>
                            <!-- FORM TITLE -->
                            <v-list-item-title class="text-h5 mb-1">
                                <!--                                {{ form.name }}-->
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <!-- FORM INFO -->
                                <p style="margin-bottom: 4px">{{ form.infoForm }}</p>
                            </v-list-item-subtitle>

                            <!-- NUM STEPS -->
                            <v-divider class="mt-2 mb-2"/>
                            <p class="mt-2" style="font-size: 12px">Num. de passos:
                                <span style="font-weight: bold">{{ form.numSteps }}</span></p>
                            <p class="mb-0" style="font-size: 12px">Num. de respostas:
                                <span style="font-weight: bold">{{ form.totalResponses }}</span>
                            </p>
                        </v-list-item-content>
                    </v-list-item>

                    <v-card-actions style="background-color: rgba(0,0,0,.03);">
                        <!--editingForm = true; editedForm = form -->
                        <v-btn
                                color="warning"
                                text
                                style="font-size: 10px; font-weight: bold;"
                                :to="'/forms/'+form.id"
                        >
                            <v-icon size="20">mdi-pencil-circle</v-icon>
                            EDITAR
                        </v-btn>
                        <v-btn
                                v-if="form.totalResponses > 0"
                                color="info"
                                text
                                style="font-size: 10px; font-weight: bold;"
                                :to="'/forms/'+form.id+'/stats'"
                        >
                            <v-icon size="20">mdi-chart-bar</v-icon>
                            STATS
                        </v-btn>
                        <v-btn
                                v-if="form.numSteps == 0"
                                color="error"
                                text
                                style="font-size: 10px; font-weight: bold;"
                                @click="deleteForm(form.id)"
                        >
                            <v-icon size="20">mdi-delete</v-icon>
                            APAGAR
                        </v-btn>
                        <v-spacer/>
                        <!-- ACTIVE OR NOT FORM -->
                        <v-icon v-if="form.active == 1 && form.endDate > new Date().toISOString().substr(0,10)" size="15" color="green">mdi-check-circle</v-icon>
                        <v-icon v-if="form.active == 0 || form.endDate < new Date().toISOString().substr(0,10)" size="15" color="red">mdi-close-circle</v-icon>
                        <!-- UPDATED AT -->
                        <span style="font-size: 10px; color: rgba(0,0,0,.8);">{{ form.startDate.substring(0, 10) + ' a ' + form.endDate.substring(0, 10) }}</span>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="3" class="col-new-form">
                <v-card
                        class="mx-auto create-new-form form-card"
                        max-width="360"
                        min-width="360"
                        style="min-height: 235px;"
                        outlined
                        @click="createForm()"
                >
                    <div class="content-new-card-form">
                        <v-btn
                                color="white"
                                text
                                style="font-size: 13px">
                            NOVO FORMULÁRIO
                            <v-icon class="not-hover pl-1" size="20">mdi-plus</v-icon>
                            <v-icon class="hover pl-1" style="display: none" size="15">mdi-pencil-plus
                            </v-icon>
                        </v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import axios from "axios";
import Alert from "@/models/Alert";

export default {
    data: function () {
        return {
            loading: true,
            forms: [],
            editingForm: false,
            editedForm: '',
            editedFormSteps: [],
            selectedStep: 1,
            lang: null,
            showInactive: true,
            newEmptyForm: {
                id: 'new',
                name: ' ',
                infoForm: ' ',
                numSteps: 0,
                active: false,
                startDate: '',
                endDate: ''
            }
        }
    },
    mounted() {
        this.getForms();
    },
    methods: {
        //Make request to api2 to get all forms
        getForms() {
            let loader = this.$loading.show();
            axios.get(api.formsList())
                    .then(response => {
                        let responseData = response.data
                        // console.log(response)
                        if (responseData.status === 'OK' || response.status != '204') {
                            this.forms = responseData.data.results
                        }
                        loader.hide()
                        this.loading = false;
                    })
                    .catch(response => {
                        loader.hide()
                    })
        },
        createForm() {
            this.newEmptyForm.startDate = new Date();
            this.newEmptyForm.endDate = new Date();

            let body = {
                form: this.newEmptyForm
            }

            let loader = this.$loading.show();
            axios.post(api.createForm(), body)
                    .then(response => {
                        let responseData = response.data
                        // console.log(responseData)
                        if (responseData.status === 'OK') {
                            //Success
                            //redirect to nem form page
                            let newFormId = responseData.data;
                            window.location = '/forms/' + newFormId;
                        }
                        loader.hide()
                    })
                    .catch(response => {
                        loader.hide()
                    })
        },
        deleteForm(formId){
            let loader = this.$loading.show()
            axios.delete(api.formDeleteForm(formId))
                    .then(response => {
                        loader.hide()
                        let alert = {
                            snackbar: true,
                            text: 'Questionário removido com sucesso!',
                            type: 'success'
                        }

                        if (response.status == 200) {
                            //Question updated with success
                            this.getForms();

                        } else {
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                        }

                        Alert.dispatch('show', alert)
                    })
                    .catch(response => {
                        loader.hide()
                        alert = {
                            snackbar: true,
                            text: 'Erro!',
                            type: 'error'
                        }
                        Alert.dispatch('show', alert)
                    })
        }
    },
    computed: {},
    created() {
        document.title = 'Questionários';
    }
}
</script>