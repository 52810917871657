<template>
    <v-row v-if="lines.length > 0">
        <v-col>
            <v-card outlined>
                <v-toolbar dense flat dark color="red">
                    <v-toolbar-title class="text-uppercase">B2B - Encomendas Pendentes</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                        <v-icon>warning</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-list-item-subtitle>Existe<span v-if="lines.length > 1">m</span> {{ lines.length }} encomenda<span v-if="lines.length > 1">s</span> pendente<span v-if="lines.length > 1">s</span> de submeter no Primavera
                    </v-list-item-subtitle>
                    <v-simple-table dense style="background-color: transparent;">
                        <thead>
                        <tr>
                            <th class="text-center">Id</th>
                            <th class="text-center">Data</th>
                            <th class="text-center">Cliente</th>
                            <th class="text-center">Artigos</th>
                            <th class="text-center">Origem</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="line in lines" @click="openDialog(line)" style="cursor: pointer;">
                            <td class="text-center">{{ line.id }}</td>
                            <td class="text-center">{{ line.created_at }}</td>
                            <td class="text-center">{{ line.order.Cliente.Cliente }}</td>
                            <td class="text-center">{{ line.order.Artigos.length }}</td>
                            <td class="text-center">{{ line.order.Encomenda.Origem }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
            <v-dialog v-model="dialog.open" persistent max-width="600">
                <v-card>
                    <v-toolbar dense flat dark color="red">
                        <v-toolbar-title class="text-uppercase">{{ dialog.title }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text v-if="selectedLine.id > 0">
                        <v-row v-if="!dialog.loading">
                            <v-col>
                                <v-list-item-subtitle>
                                    <strong>Cliente: </strong> {{selectedLine.order.Cliente.Cliente}}
                                    <v-btn small icon :href="'/clients/clients/' + selectedLine.order.Cliente.Cliente" target="_blank">
                                        <i class="fas fa-external-link-alt" style="color: #1867c0;"></i>
                                    </v-btn>
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <strong>Morada: </strong> {{selectedLine.order.MoradaEntrega.Id}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <strong>Data: </strong> {{selectedLine.created_at}}
                                </v-list-item-subtitle>
                            </v-col>
                            <v-col>
                                <v-list-item-subtitle>
                                    <strong>CondPag: </strong> {{selectedLine.order.Encomenda.CondPag}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <strong>ModoPag: </strong> {{selectedLine.order.Encomenda.ModoPag}}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle>
                                    <strong>Origem: </strong> {{selectedLine.order.Encomenda.Origem}}
                                </v-list-item-subtitle>
                            </v-col>
                        </v-row>
                        <div class="text-center">

                            <v-simple-table v-if="!dialog.loading" dense style="background-color: transparent;">
                                <thead>
                                <tr>
                                    <th class="text-left">Artigo</th>
                                    <th class="text-center">Desconto</th>
                                    <th class="text-center">Desconto2</th>
                                    <th class="text-center">Quantidade</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="line in selectedLine.order.Artigos">
                                    <td class="text-left">
                                        {{ line.Codigo }}
                                        <v-btn small icon :href="'/products/products/' + line.Codigo" target="_blank">
                                            <i class="fas fa-external-link-alt" style="color: #1867c0;"></i>
                                        </v-btn>
                                    <td class="text-center">{{ line.Desconto }}</td>
                                    <td class="text-center">{{ line.Desconto2 }}</td>
                                    <td class="text-center">{{ line.Quantidade }}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>

                            <v-row v-if="!dialog.loading">
                                <v-col>
                                    <p class="text-left"><strong>Notas:</strong>{{selectedLine.order.Encomenda.Notas}}</p>
                                </v-col>
                            </v-row>

                            <v-progress-circular
                                    v-if="dialog.loading"
                                    indeterminate
                                    color="error"
                                    size="100"
                                    class="mt-6"
                                    width="2"
                            ></v-progress-circular>

                        </div>
                    </v-card-text>
                    <v-card-actions v-if="!dialog.loading">
                        <v-btn tile small  color="default" @click="dialog.open = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  color="error" @click="updateOrder('delete', selectedLine.id)">APAGAR</v-btn>
                        <v-btn tile small  color="success" @click="updateOrder('submit', selectedLine.id)">SUBMETER</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        data: () => ({
            id: 0,
            action: '',
            loading: true,
            lines: [],
            dialog: {
                open: false,
                loading: false,
                error: false,
                alert: false,
                title: '',
                message: '',
                alertMessage: ''
            },
            selectedLine: {
                id: 0,
            }
        }),
        created() {
            this.initialize()
        },
        methods: {
            initialize() {
                let request = {
                    url: api.dashboardWidgetPendingOrders()
                };
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.lines = responseData.orders;
                            this.loading = false
                        }
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },
            openDialog(line) {
                this.selectedLine = line;
                this.dialog.title = 'Encomenda pendente - ' + line.id;
                this.dialog.loading = false;
                this.dialog.open = true;
            },
            updateOrder(action, id) {
                this.dialog.loading = true;
                this.id = id;
                this.action = action;
                let request = {
                    url: api.dashboardWidgetUpdatePendingOrder(),
                    data: {
                        id: id,
                        action: action
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        this.action = '';
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.dialog.open = false;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.message,
                                type: 'success'
                            });
                            this.lines = responseData.orders;
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.message,
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        this.action = '';
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            }
        },
        computed: {
            profile() {
                return User.getters.getProfileId
            }
        }
    }
</script>
