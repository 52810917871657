<template>
    <v-container fluid>


        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title :title="$t('nozamaTemplates')" subtitle="Exportação dos templates Nozama"></page-title>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>

                        <v-row>
                            <v-col cols="12" sm="3" md="3">
                                <v-autocomplete
                                        v-model="inputExport.brand"
                                        :items="brands"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        label="Marca"
                                        hide-details
                                        outlined
                                        dense
                                        @change="getFamilies"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-autocomplete
                                        v-model="inputExport.family"
                                        :items="families"
                                        item-value="VALUE"
                                        item-text="TEXT"
                                        label="Familia"
                                        hide-details
                                        outlined
                                        dense
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="3" md="3">
                                <v-btn color="success" :disabled="validateButtonExport" @click="exportNozama()">
                                    Exportar
                                </v-btn>
                                <v-btn color="primary" class="ml-2" :disabled="validateButtonExport" @click="exportImages()">
                                    Imagens
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    export default {
        data: function () {
            return {
                loading: false,
                brands: [],
                families: [],
                inputExport: {
                    brand: '',
                    family: ''
                }
            }
        },
        created() {
            document.title = 'Exportar Nozama';
            this.getBrands();
            var query = '' +
                    'SELECT\n' +
                    '    A.Artigo,\n' +
                    '    A.CDU_EAN AS \'EAN\',\n' +
                    '    ISNULL(A.Marca,\'\') AS \'MARCA_ID\',\n' +
                    '    M.CDU_CC AS \'MarcaCC\',\n' +
                    '    ES.DescricaoComercial AS \'DescB2B\',\n' +
                    '    ES.CDU_Descricao2 AS \'DescB2B2\',\n' +
                    '    PT.CDU_DescricaoWeb AS \'NamePT\',\n' +
                    '    ES.CDU_DescricaoWeb AS \'NameES\',\n' +
                    '    EN.CDU_DescricaoWeb AS \'NameEN\',\n' +
                    '    FR.CDU_DescricaoWeb AS \'NameFR\',\n' +
                    '    IT.CDU_DescricaoWeb AS \'NameIT\',\n' +
                    '    DE.CDU_DescricaoWeb AS \'NameDE\',\n' +
                    '    PT.CDU_CaracteristicasWeb AS \'DescPT\',\n' +
                    '    ES.CDU_CaracteristicasWeb AS \'DescES\',\n' +
                    '    EN.CDU_CaracteristicasWeb AS \'DescEN\',\n' +
                    '    FR.CDU_CaracteristicasWeb AS \'DescFR\',\n' +
                    '    IT.CDU_CaracteristicasWeb AS \'DescIT\',\n' +
                    '    DE.CDU_CaracteristicasWeb AS \'DescDE\',\n' +
                    '    A.Familia,\n' +
                    '    A.SubFamilia,\n' +
                    '    A.CDU_PVP_ES AS \'PVP_ES\',\n' +
                    '    A.CDU_CampoVar1 AS \'YEAR\',\n' +
                    '    A.Peso\n' +
                    'FROM\n' +
                    '    Artigo A\n' +
                    '    LEFT JOIN B2BWEBCatalogArticlesVisibility VC ON A.Artigo = VC.Artigo\n' +
                    '    LEFT JOIN Marcas M ON A.Marca = M.Marca\n' +
                    '    LEFT JOIN ArtigoIdioma PT ON ISNULL(A.ArtigoPai, A.Artigo) = PT.Artigo AND PT.Idioma = \'PT\'\n' +
                    '    LEFT JOIN ArtigoIdioma ES ON ISNULL(A.ArtigoPai, A.Artigo) = ES.Artigo AND ES.Idioma = \'ES\'\n' +
                    '    LEFT JOIN ArtigoIdioma EN ON ISNULL(A.ArtigoPai, A.Artigo) = EN.Artigo AND EN.Idioma = \'EN\'\n' +
                    '    LEFT JOIN ArtigoIdioma FR ON ISNULL(A.ArtigoPai, A.Artigo) = FR.Artigo AND FR.Idioma = \'FR\'\n' +
                    '    LEFT JOIN ArtigoIdioma IT ON ISNULL(A.ArtigoPai, A.Artigo) = IT.Artigo AND IT.Idioma = \'IT\'\n' +
                    '    LEFT JOIN ArtigoIdioma DE ON ISNULL(A.ArtigoPai, A.Artigo) = DE.Artigo AND DE.Idioma = \'DE\'\n' +
                    'WHERE\n' +
                    '    A.ArtigoAnulado = 0\n' +
                    '    AND A.CDU_B2B_SYNC = 1\n' +
                    '    AND A.Marca=\'$brand\'\n' +
                    '    AND A.Familia = \'$family\'\n' +
                    '    AND A.TratamentoDim != 1\n' +
                    '    AND A.CDU_EAN IS NOT NULL\n' +
                    '    AND VC.profile_id = \'62\'';
            console.log(query);
        },
        methods: {
            getBrands() {
                let request = {
                    url: '/api/staff/products/brands'
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.brands = responseData.brands;
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            getFamilies() {
                this.inputExport.family = '';
                let request = {
                    url: 'api/staff/products/families',
                    data: {
                        'brand': this.inputExport.brand
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.families = responseData.families;
                            this.families.shift();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            exportNozama() {
                let loader = this.$loading.show();

                let request = {
                    url: 'api/staff/dropshipping/exportNozama/' + this.inputExport.brand + '/' + this.inputExport.family
                }

                ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'exportNozama_' + this.inputExport.brand + '_' + this.inputExport.family + '.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            exportImages() {
                let loader = this.$loading.show();

                let request = {
                    url: 'api/staff/dropshipping/imagesNozama/' + this.inputExport.brand + '/' + this.inputExport.family
                }

                ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {type: 'application/zip'});
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'exportNozama_' + this.inputExport.brand + '_' + this.inputExport.family + '.zip';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            }
        },
        computed: {
            validateButtonExport() {
                return this.inputExport.brand === '' || this.inputExport.family === '';
            }
        }
    }
</script>
