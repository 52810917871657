<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        back
                        :title="'ECF - '+ECFNumber"
                        :subtitle="purchase.Entidade+' - '+purchase.Nome"
                ></page-title>
            </v-col>
            <v-col>
                <strong>{{ $t('dateOfDocument') }}:</strong><br>
                {{ purchase.DataDoc }}
            </v-col>
            <v-col>
                <strong>Total Documento:</strong><br>
                <template v-if="purchase.Moeda === 'USD'">
                    {{ $dollar(purchase.TotalDocumento) }}
                </template>
                <template v-else>
                    {{ $money(purchase.TotalDocumento) }}
                </template>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-row>
                        <v-col>
                            <v-text-field
                                    v-model="inputSearch"
                                    label="Pesquisa"
                                    outlined
                                    dense
                                    :ref="'input_search'"
                                    style="width: 300px"
                                    hide-details
                                    @keypress.enter="searchProduct()"
                                    @focus="$event.target.select()"
                            ></v-text-field>
                        </v-col>
                        <v-col class="text-right" align-self="center">
                            <v-btn tile small  color="success" @click="updateECF()">Atualizar ECF</v-btn>
                        </v-col>
                    </v-row>
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>{{ $t('product') }}</th>
                            <th>{{ $t('description') }}</th>
                            <th>CodBarras</th>
                            <th>Ref.Fornecedor</th>
                            <th width="80" class="text-center">{{ $t('quantity') }}</th>
                            <th width="90" class="text-right">{{ $t('price') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="line in purchaseLines">
                            <td>{{ line.Artigo }}</td>
                            <td>{{ line.Descricao }}</td>
                            <td>{{ line.CodBarras }}</td>
                            <td>{{ line.CDU_REFFORNECEDOR }}</td>
                            <td>
                                <v-text-field
                                        v-model="line.Quantidade"
                                        hide-details
                                        dense
                                        outlined
                                        class="staff_text-field_small"
                                        :ref="'input_qty_'+line.Artigo"
                                        @focus="$event.target.select()"
                                        @keypress.enter="focusPrice('input_price_'+line.Artigo)"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="line.PrecUnit"
                                        hide-details
                                        dense
                                        outlined
                                        class="staff_text-field_small"
                                        :ref="'input_price_'+line.Artigo"
                                        @focus="$event.target.select()"
                                        @keypress.enter="focusPrice('input_search')"
                                ></v-text-field>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-row justify="center">
            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: function () {
        return {
            ECFNumber: '',
            purchase: {},
            purchaseLines: [],
            purchaseLinesOriginal: [],
            inputSearch: '',
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
        }
    },
    created() {
        this.ECFNumber = this.$route.params.id;
        document.title = 'ECF ' + this.ECFNumber + ' | STAFF';
        this.getPurchaseLines();
    },
    methods: {
        getPurchaseLines() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/' + this.ECFNumber
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.purchase = responseData.purchase;
                            this.purchaseLines = responseData.purchaseLines;
                            this.purchaseLinesOriginal = responseData.purchaseLines;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        searchProduct() {
            for (let i = 0; i < this.purchaseLinesOriginal.length; i++) {
                for (let j = 0; j < this.purchaseLines.length; j++) {
                    if (this.purchaseLinesOriginal[i].Artigo === this.purchaseLines[j].Artigo) {
                        this.purchaseLinesOriginal[i].Quantidade = this.purchaseLines[j].Quantidade;
                        this.purchaseLinesOriginal[i].PrecUnit = this.purchaseLines[j].PrecUnit;
                    }
                }
            }

            this.purchaseLines = this.purchaseLinesOriginal;

            if (this.inputSearch !== '') {
                this.purchaseLines = this.purchaseLines.filter(line => line.Artigo.toUpperCase().includes(this.inputSearch.toUpperCase())
                        || (line.CodBarras && line.CodBarras.toUpperCase().includes(this.inputSearch.toUpperCase()))
                        || (line.CDU_REFFORNECEDOR && line.CDU_REFFORNECEDOR.toUpperCase().includes(this.inputSearch.toUpperCase())));
            }

            if (this.purchaseLines.length === 1) {
                let inputRef = 'input_qty_' + this.purchaseLines[0].Artigo;
                this.$nextTick(() => this.$refs[inputRef][0].focus());
            }
        },
        focusPrice(ref) {
            if (ref !== 'input_search') {
                this.$nextTick(() => this.$refs[ref][0].focus());
            } else {
                this.$nextTick(() => this.$refs[ref].focus());
            }
        },
        updateECF() {
            let loader = this.$loading.show();

            for (let i = 0; i < this.purchaseLinesOriginal.length; i++) {
                for (let j = 0; j < this.purchaseLines.length; j++) {
                    if (this.purchaseLinesOriginal[i].Artigo === this.purchaseLines[j].Artigo) {
                        this.purchaseLinesOriginal[i].Quantidade = this.purchaseLines[j].Quantidade;
                        this.purchaseLinesOriginal[i].PrecUnit = this.purchaseLines[j].PrecUnit;
                    }
                }
            }

            let request = {
                url: '/api/staff/VFAs/' + this.ECFNumber + '/updateLines',
                data: {
                    lines: this.purchaseLinesOriginal,
                    VFA: this.purchase
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'ECF atualizada com sucesso!',
                                type: 'success'
                            })
                            this.$router.go(-1);
                        } else {
                            this.responseDialog.open = true;
                            this.responseDialog.error = true;
                            this.responseDialog.title = 'Erro ao Atualizar ECF!';
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.loading = false;
                        }
                        loader.hide();
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        }
    }
}
</script>
