<template>
    <div>
        <template v-for="option in Object.keys(data[0].Options)">
            <label>{{ option }} </label>
            <v-select
                    v-model="selectedSpec1"
                    :items="Object.keys(data[0].Options[option])"
                    style="width:140px"
                    outlined
                    single-line
                    dense
                    hide-details
            ></v-select>
            <template  v-if="selectedSpec1 != null" >
                <template v-for="option2 in Object.keys(data[0].Options[option][selectedSpec1])">
                    <label>{{ option2 }}</label>
                    <v-select
                            v-model="selectedSpec2"
                            :items="Object.keys(data[0].Options[option][selectedSpec1][option2])"
                            style="width:140px"
                            outlined
                            single-line
                            dense
                            hide-details
                    ></v-select>
                </template>
            </template>

        </template>
        <br>
        <hr>
        <v-btn @click="GoGoProductToCart()">Add to cart</v-btn>
    </div>
</template>

<script>
import FileUpload from "v-file-upload";

export default {
    components: {
        FileUpload
    },
    data: () => ({
        selectedSpec1: null,
        selectedSpec2: null,
        selectedSpec3: null,
        data: [
            {
                "Artigo": "RFPT19ARCC29",
                "ArtigoPai": null,
                "Marca": "RF_COMP",
                "Familia": "RIMS",
                "SubFamilia": null,
                "MARCA_ID": "RF_COMP",
                "campaign": 0,
                "STKMinimo": 1,
                "STKReposicao": 2,
                "PVP1": "528.1",
                "PVP1_ORDER": 528.1,
                "PVPBox": 528.1,
                "ptPVP": "649.56",
                "esPVP": "639.00",
                "Desconto": 40,
                "sale": 1,
                "TratamentoDim": 1,
                "Descricao": "Aro ARC Carbono 29",
                "Nome2": null,
                "Dim1": "HOLES",
                "Dim2": "RIMS",
                "Dim3": null,
                "RubDim1": null,
                "RubDim2": null,
                "RubDim3": null,
                "DescDim1": "HOLES NUMBER",
                "DescDim2": "RIMS",
                "DescDim3": null,
                "DescRubDim1": null,
                "DescRubDim2": null,
                "DescRubDim3": null,
                "CDU_B2B_SYNC": 1,
                "totalChildren": 6,
                "totalChildrenStockOff": 0,
                "CDU_B2BEsgotado": 0,
                "Options": {
                    "HOLES": {
                        "28H": {
                            "RIMS": {
                                "26": {
                                    "Artigo": "RFPT19ARCC29.28H.26",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "RIMS",
                                    "Dim1": "HOLES",
                                    "Dim2": "RIMS",
                                    "RubDim1": "28H",
                                    "RubDim2": "26",
                                    "DescRubDim1": "28 HOLES",
                                    "DescRubDim2": "26MM",
                                    "CodBarras": "821973335667",
                                    "STKMinimo": 1,
                                    "STKReposicao": 2,
                                    "PVP1": "528.1",
                                    "PVP1_ORDER": 528.1,
                                    "PVPBox": 528.1,
                                    "ptPVP": "649.56",
                                    "esPVP": "639.00",
                                    "Desconto": 40
                                },
                                "31": {
                                    "Artigo": "RFPT19ARCC29.28H.31",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "RIMS",
                                    "Dim1": "HOLES",
                                    "Dim2": "RIMS",
                                    "RubDim1": "28H",
                                    "RubDim2": "31",
                                    "DescRubDim1": "28 HOLES",
                                    "DescRubDim2": "31MM",
                                    "CodBarras": "821973316451",
                                    "STKMinimo": 1,
                                    "STKReposicao": 2,
                                    "PVP1": "528.1",
                                    "PVP1_ORDER": 528.1,
                                    "PVPBox": 528.1,
                                    "ptPVP": "649.56",
                                    "esPVP": "639.00",
                                    "Desconto": 0
                                },
                                "36": {
                                    "Artigo": "RFPT19ARCC29.28H.36",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "RIMS",
                                    "Dim1": "HOLES",
                                    "Dim2": "RIMS",
                                    "RubDim1": "28H",
                                    "RubDim2": "36",
                                    "DescRubDim1": "28 HOLES",
                                    "DescRubDim2": "36MM",
                                    "CodBarras": "821973335704",
                                    "STKMinimo": 1,
                                    "STKReposicao": 2,
                                    "PVP1": "528.1",
                                    "PVP1_ORDER": 528.1,
                                    "PVPBox": 528.1,
                                    "ptPVP": "649.56",
                                    "esPVP": "639.00",
                                    "Desconto": 0
                                }
                            }
                        },
                        "32H": {
                            "RIMS": {
                                "26": {
                                    "Artigo": "RFPT19ARCC29.32H.26",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "RIMS",
                                    "Dim1": "HOLES",
                                    "Dim2": "RIMS",
                                    "RubDim1": "32H",
                                    "RubDim2": "26",
                                    "DescRubDim1": "32 HOLES",
                                    "DescRubDim2": "26MM",
                                    "CodBarras": "821973335674",
                                    "STKMinimo": 1,
                                    "STKReposicao": 2,
                                    "PVP1": "528.1",
                                    "PVP1_ORDER": 528.1,
                                    "PVPBox": 528.1,
                                    "ptPVP": "649.56",
                                    "esPVP": "639.00",
                                    "Desconto": 40
                                },
                                "31": {
                                    "Artigo": "RFPT19ARCC29.32H.31",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "RIMS",
                                    "Dim1": "HOLES",
                                    "Dim2": "RIMS",
                                    "RubDim1": "32H",
                                    "RubDim2": "31",
                                    "DescRubDim1": "32 HOLES",
                                    "DescRubDim2": "31MM",
                                    "CodBarras": "821973316468",
                                    "STKMinimo": 1,
                                    "STKReposicao": 2,
                                    "PVP1": "528.1",
                                    "PVP1_ORDER": 528.1,
                                    "PVPBox": 528.1,
                                    "ptPVP": "649.56",
                                    "esPVP": "639.00",
                                    "Desconto": 0
                                },
                                "36": {
                                    "Artigo": "RFPT19ARCC29.32H.36",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "RIMS",
                                    "Dim1": "HOLES",
                                    "Dim2": "RIMS",
                                    "RubDim1": "32H",
                                    "RubDim2": "36",
                                    "DescRubDim1": "32 HOLES",
                                    "DescRubDim2": "36MM",
                                    "CodBarras": "821973335711",
                                    "STKMinimo": 1,
                                    "STKReposicao": 2,
                                    "PVP1": "528.1",
                                    "PVP1_ORDER": 528.1,
                                    "PVPBox": 528.1,
                                    "ptPVP": "649.56",
                                    "esPVP": "639.00",
                                    "Desconto": 0
                                }
                            }
                        }
                    }
                },
                "CDU_Cliente": "174",
                "CDU_Marca": "RF_COMP",
                "CDU_Nivel": 0,
                "CDU_Created_at": "2019-03-14 09:39:44.000",
                "CDU_Updated_at": "2021-05-29 15:16:11.000",
                "CDU_Deleted_at": "2019-03-11 00:00:00.000",
                "CDU_Valor": 25,
                "CDU_Pais": "ALL",
                "CDU_ID": 3,
                "STOCK_ATUAL": 3,
                "STOCK_REAL": "3",
                "DescontoMarca": 35,
                "FICHEIRO": "{F9967C20-5279-E811-813C-000C291E7900}.png",
                "_links": {
                    "self": "https://dev.apicore.bicimax.com/api/v1/products/RFPT19ARCC29"
                },
                "color": null
            },
            {
                "Artigo": "RFCK19NXT30",
                "ArtigoPai": "RFCK19NXT30",
                "Marca": "RF_COMP",
                "Familia": "CRANKS",
                "SubFamilia": null,
                "MARCA_ID": "RF_COMP",
                "campaign": 0,
                "STKMinimo": 1,
                "STKReposicao": 2,
                "PVP1": "420.661",
                "PVP1_ORDER": 420.66115702,
                "PVPBox": 420.66115702,
                "ptPVP": "517.00",
                "esPVP": "509.00",
                "Desconto": 0,
                "sale": 0,
                "TratamentoDim": 2,
                "Descricao": "Pedaleira Next R Cinch Ø30 (Sem prato)",
                "Nome2": null,
                "Dim1": "CRANK",
                "Dim2": "COLOR",
                "Dim3": null,
                "RubDim1": "170",
                "RubDim2": "BLK",
                "RubDim3": null,
                "DescDim1": "CRANK SIZE",
                "DescDim2": "COLOR",
                "DescDim3": null,
                "DescRubDim1": "170MM",
                "DescRubDim2": "BLACK",
                "DescRubDim3": null,
                "CDU_B2B_SYNC": 1,
                "totalChildren": 0,
                "totalChildrenStockOff": 0,
                "CDU_B2BEsgotado": 0,
                "Options": {
                    "CRANK": {
                        "170": {
                            "Artigo": "RFCK19NXT30.170.BLK",
                            "Marca": "RF_COMP",
                            "DescDim1": "CRANK SIZE",
                            "DescDim2": "COLOR",
                            "Dim1": "CRANK",
                            "RubDim1": "170",
                            "RubDim2": "BLK",
                            "DescRubDim1": "170MM",
                            "DescRubDim2": "BLACK",
                            "CodBarras": "821973344713",
                            "STKMinimo": 1,
                            "STKReposicao": 2,
                            "PVP1": "420.661",
                            "PVP1_ORDER": 420.66115702,
                            "PVPBox": 420.66115702,
                            "ptPVP": "517.00",
                            "esPVP": "509.00",
                            "Desconto": 0
                        },
                        "175": {
                            "Artigo": "RFCK19NXT30.175.BLK",
                            "Marca": "RF_COMP",
                            "DescDim1": "CRANK SIZE",
                            "DescDim2": "COLOR",
                            "Dim1": "CRANK",
                            "RubDim1": "175",
                            "RubDim2": "BLK",
                            "DescRubDim1": "175MM",
                            "DescRubDim2": "BLACK",
                            "CodBarras": "821973344720",
                            "STKMinimo": 1,
                            "STKReposicao": 2,
                            "PVP1": "420.661",
                            "PVP1_ORDER": 420.66115702,
                            "PVPBox": 420.66115702,
                            "ptPVP": "517.00",
                            "esPVP": "509.00",
                            "Desconto": 0
                        }
                    }
                },
                "CDU_Cliente": "174",
                "CDU_Marca": "RF_COMP",
                "CDU_Nivel": 0,
                "CDU_Created_at": "2019-03-14 09:39:44.000",
                "CDU_Updated_at": "2021-05-29 15:16:11.000",
                "CDU_Deleted_at": "2019-03-11 00:00:00.000",
                "CDU_Valor": 25,
                "CDU_Pais": "ALL",
                "CDU_ID": 3,
                "STOCK_ATUAL": 16,
                "STOCK_REAL": "16",
                "DescontoMarca": 35,
                "FICHEIRO": "{879BE074-0F34-EB11-B51D-005056A5FE8E}.jpg",
                "_links": {
                    "self": "https://dev.apicore.bicimax.com/api/v1/products/RFCK19NXT30.170.BLK"
                },
                "color": "BLACK"
            },
            {
                "Artigo": "RFCK20NXTSL",
                "ArtigoPai": "RFCK20NXTSL",
                "Marca": "RF_COMP",
                "Familia": "CRANKS",
                "SubFamilia": null,
                "MARCA_ID": "RF_COMP",
                "campaign": 0,
                "STKMinimo": 1,
                "STKReposicao": 4,
                "PVP1": "420.661",
                "PVP1_ORDER": 420.66115702,
                "PVPBox": 420.66115702,
                "ptPVP": "517.00",
                "esPVP": "509.00",
                "Desconto": 0,
                "sale": 0,
                "TratamentoDim": 2,
                "Descricao": "Pedaleira Next SL G5",
                "Nome2": "CINCH Ø30 (SEM PRATO)",
                "Dim1": "CRANK",
                "Dim2": "COLOR",
                "Dim3": null,
                "RubDim1": "170",
                "RubDim2": "BLK",
                "RubDim3": null,
                "DescDim1": "CRANK SIZE",
                "DescDim2": "COLOR",
                "DescDim3": null,
                "DescRubDim1": "170MM",
                "DescRubDim2": "BLACK",
                "DescRubDim3": null,
                "CDU_B2B_SYNC": 1,
                "totalChildren": 0,
                "totalChildrenStockOff": 0,
                "CDU_B2BEsgotado": 0,
                "Options": {
                    "CRANK": {
                        "170": {
                            "Artigo": "RFCK20NXTSL.170.BLK",
                            "Marca": "RF_COMP",
                            "DescDim1": "CRANK SIZE",
                            "DescDim2": "COLOR",
                            "Dim1": "CRANK",
                            "RubDim1": "170",
                            "RubDim2": "BLK",
                            "DescRubDim1": "170MM",
                            "DescRubDim2": "BLACK",
                            "CodBarras": "821973349664",
                            "STKMinimo": 1,
                            "STKReposicao": 4,
                            "PVP1": "420.661",
                            "PVP1_ORDER": 420.66115702,
                            "PVPBox": 420.66115702,
                            "ptPVP": "517.00",
                            "esPVP": "509.00",
                            "Desconto": 0
                        },
                        "175": {
                            "Artigo": "RFCK20NXTSL.175.BLK",
                            "Marca": "RF_COMP",
                            "DescDim1": "CRANK SIZE",
                            "DescDim2": "COLOR",
                            "Dim1": "CRANK",
                            "RubDim1": "175",
                            "RubDim2": "BLK",
                            "DescRubDim1": "175MM",
                            "DescRubDim2": "BLACK",
                            "CodBarras": "821973349671",
                            "STKMinimo": 1,
                            "STKReposicao": 4,
                            "PVP1": "420.661",
                            "PVP1_ORDER": 420.66115702,
                            "PVPBox": 420.66115702,
                            "ptPVP": "517.00",
                            "esPVP": "509.00",
                            "Desconto": 0
                        }
                    }
                },
                "CDU_Cliente": "174",
                "CDU_Marca": "RF_COMP",
                "CDU_Nivel": 0,
                "CDU_Created_at": "2019-03-14 09:39:44.000",
                "CDU_Updated_at": "2021-05-29 15:16:11.000",
                "CDU_Deleted_at": "2019-03-11 00:00:00.000",
                "CDU_Valor": 25,
                "CDU_Pais": "ALL",
                "CDU_ID": 3,
                "STOCK_ATUAL": -1,
                "STOCK_REAL": "4",
                "DescontoMarca": 35,
                "FICHEIRO": "{837C0088-674A-E911-8149-000C291E7900}.png",
                "_links": {
                    "self": "https://dev.apicore.bicimax.com/api/v1/products/RFCK20NXTSL.170.BLK"
                },
                "color": "BLACK"
            },
            {
                "Artigo": "RFPT18HUBVLTBSTR",
                "ArtigoPai": null,
                "Marca": "RF_COMP",
                "Familia": "HUBS",
                "SubFamilia": null,
                "MARCA_ID": "RF_COMP",
                "campaign": 0,
                "STKMinimo": 1,
                "STKReposicao": 2,
                "PVP1": "371.07",
                "PVP1_ORDER": 371.07,
                "PVPBox": 371.07,
                "ptPVP": "456.00",
                "esPVP": "449.00",
                "Desconto": 0,
                "sale": 0,
                "TratamentoDim": 1,
                "Descricao": "Cubo Vault 12x148 Trás",
                "Nome2": "",
                "Dim1": "HOLES",
                "Dim2": "WHEELS",
                "Dim3": null,
                "RubDim1": null,
                "RubDim2": null,
                "RubDim3": null,
                "DescDim1": "HOLES NUMBER",
                "DescDim2": "NÚCLEO",
                "DescDim3": null,
                "DescRubDim1": null,
                "DescRubDim2": null,
                "DescRubDim3": null,
                "CDU_B2B_SYNC": 1,
                "totalChildren": 4,
                "totalChildrenStockOff": 0,
                "CDU_B2BEsgotado": 0,
                "Options": {
                    "HOLES": {
                        "28H": {
                            "WHEELS": {
                                "SH": {
                                    "Artigo": "RFPT18HUBVLTBSTR.28H.SH",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "NÚCLEO",
                                    "Dim1": "HOLES",
                                    "Dim2": "WHEELS",
                                    "RubDim1": "28H",
                                    "RubDim2": "SH",
                                    "DescRubDim1": "28 HOLES",
                                    "DescRubDim2": "SHIMANO CAS. BODY",
                                    "CodBarras": "821973333823",
                                    "STKMinimo": 1,
                                    "STKReposicao": 3,
                                    "PVP1": "371.07",
                                    "PVP1_ORDER": 371.07438016,
                                    "PVPBox": 371.07438016,
                                    "ptPVP": "456.00",
                                    "esPVP": "449.00",
                                    "Desconto": 0
                                },
                                "XD": {
                                    "Artigo": "RFPT18HUBVLTBSTR.28H.XD",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "NÚCLEO",
                                    "Dim1": "HOLES",
                                    "Dim2": "WHEELS",
                                    "RubDim1": "28H",
                                    "RubDim2": "XD",
                                    "DescRubDim1": "28 HOLES",
                                    "DescRubDim2": "SRAM CAS. BODY",
                                    "CodBarras": "821973333830",
                                    "STKMinimo": 1,
                                    "STKReposicao": 3,
                                    "PVP1": "371.07",
                                    "PVP1_ORDER": 371.07438016,
                                    "PVPBox": 371.07438016,
                                    "ptPVP": "456.00",
                                    "esPVP": "449.00",
                                    "Desconto": 0
                                }
                            }
                        },
                        "32H": {
                            "WHEELS": {
                                "SH": {
                                    "Artigo": "RFPT18HUBVLTBSTR.32H.SH",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "NÚCLEO",
                                    "Dim1": "HOLES",
                                    "Dim2": "WHEELS",
                                    "RubDim1": "32H",
                                    "RubDim2": "SH",
                                    "DescRubDim1": "32 HOLES",
                                    "DescRubDim2": "SHIMANO CAS. BODY",
                                    "CodBarras": "821973333847",
                                    "STKMinimo": 1,
                                    "STKReposicao": 3,
                                    "PVP1": "371.07",
                                    "PVP1_ORDER": 371.07438016,
                                    "PVPBox": 371.07438016,
                                    "ptPVP": "456.00",
                                    "esPVP": "449.00",
                                    "Desconto": 0
                                },
                                "XD": {
                                    "Artigo": "RFPT18HUBVLTBSTR.32H.XD",
                                    "Marca": "RF_COMP",
                                    "DescDim1": "HOLES NUMBER",
                                    "DescDim2": "NÚCLEO",
                                    "Dim1": "HOLES",
                                    "Dim2": "WHEELS",
                                    "RubDim1": "32H",
                                    "RubDim2": "XD",
                                    "DescRubDim1": "32 HOLES",
                                    "DescRubDim2": "SRAM CAS. BODY",
                                    "CodBarras": "821973333854",
                                    "STKMinimo": 1,
                                    "STKReposicao": 3,
                                    "PVP1": "371.07",
                                    "PVP1_ORDER": 371.07438016,
                                    "PVPBox": 371.07438016,
                                    "ptPVP": "456.00",
                                    "esPVP": "449.00",
                                    "Desconto": 0
                                }
                            }
                        }
                    }
                },
                "CDU_Cliente": "174",
                "CDU_Marca": "RF_COMP",
                "CDU_Nivel": 0,
                "CDU_Created_at": "2019-03-14 09:39:44.000",
                "CDU_Updated_at": "2021-05-29 15:16:11.000",
                "CDU_Deleted_at": "2019-03-11 00:00:00.000",
                "CDU_Valor": 25,
                "CDU_Pais": "ALL",
                "CDU_ID": 3,
                "STOCK_ATUAL": 1,
                "STOCK_REAL": "1",
                "DescontoMarca": 35,
                "FICHEIRO": "{8541B971-5179-E811-813C-000C291E7900}.png",
                "_links": {
                    "self": "https://dev.apicore.bicimax.com/api/v1/products/RFPT18HUBVLTBSTR"
                },
                "color": null
            },
            {
                "Artigo": "RFDP20AEFR309",
                "ArtigoPai": null,
                "Marca": "RF_COMP",
                "Familia": "SEATPOSTS",
                "SubFamilia": null,
                "MARCA_ID": "RF_COMP",
                "campaign": 0,
                "STKMinimo": 1,
                "STKReposicao": 2,
                "PVP1": "329.75",
                "PVP1_ORDER": 329.75,
                "PVPBox": 329.75,
                "ptPVP": "405.00",
                "esPVP": "399.00",
                "Desconto": 0,
                "sale": 0,
                "TratamentoDim": 1,
                "Descricao": "Espigão de selim RaceFace Aeffect R 30,9",
                "Nome2": "",
                "Dim1": "DP",
                "Dim2": null,
                "Dim3": null,
                "RubDim1": null,
                "RubDim2": null,
                "RubDim3": null,
                "DescDim1": "DROPPER POST",
                "DescDim2": null,
                "DescDim3": null,
                "DescRubDim1": null,
                "DescRubDim2": null,
                "DescRubDim3": null,
                "CDU_B2B_SYNC": 1,
                "totalChildren": 4,
                "totalChildrenStockOff": 0,
                "CDU_B2BEsgotado": 0,
                "Options": {
                    "DP": {
                        "100": {
                            "Artigo": "RFDP20AEFR309.100",
                            "Marca": "RF_COMP",
                            "DescDim1": "DROPPER POST",
                            "Dim1": "DP",
                            "RubDim1": "100",
                            "DescRubDim1": "100MM",
                            "CodBarras": "821973354903",
                            "STKMinimo": 2,
                            "STKReposicao": 3,
                            "PVP1": "329.75",
                            "PVP1_ORDER": 329.75206611,
                            "PVPBox": 329.75206611,
                            "ptPVP": "405.00",
                            "esPVP": "399.00",
                            "Desconto": 0
                        },
                        "125": {
                            "Artigo": "RFDP20AEFR309.125",
                            "Marca": "RF_COMP",
                            "DescDim1": "DROPPER POST",
                            "Dim1": "DP",
                            "RubDim1": "125",
                            "DescRubDim1": "125MM",
                            "CodBarras": "821973354910",
                            "STKMinimo": 1,
                            "STKReposicao": 2,
                            "PVP1": "329.75",
                            "PVP1_ORDER": 329.75206611,
                            "PVPBox": 329.75206611,
                            "ptPVP": "405.00",
                            "esPVP": "399.00",
                            "Desconto": 0
                        },
                        "150": {
                            "Artigo": "RFDP20AEFR309.150",
                            "Marca": "RF_COMP",
                            "DescDim1": "DROPPER POST",
                            "Dim1": "DP",
                            "RubDim1": "150",
                            "DescRubDim1": "150MM",
                            "CodBarras": "821973354927",
                            "STKMinimo": 1,
                            "STKReposicao": 2,
                            "PVP1": "329.75",
                            "PVP1_ORDER": 329.75206611,
                            "PVPBox": 329.75206611,
                            "ptPVP": "405.00",
                            "esPVP": "399.00",
                            "Desconto": 0
                        },
                        "170": {
                            "Artigo": "RFDP20AEFR309.170",
                            "Marca": "RF_COMP",
                            "DescDim1": "DROPPER POST",
                            "Dim1": "DP",
                            "RubDim1": "170",
                            "DescRubDim1": "170MM",
                            "CodBarras": "821973354934",
                            "STKMinimo": 1,
                            "STKReposicao": 2,
                            "PVP1": "329.75",
                            "PVP1_ORDER": 329.75206611,
                            "PVPBox": 329.75206611,
                            "ptPVP": "405.00",
                            "esPVP": "399.00",
                            "Desconto": 0
                        }
                    }
                },
                "CDU_Cliente": "174",
                "CDU_Marca": "RF_COMP",
                "CDU_Nivel": 0,
                "CDU_Created_at": "2019-03-14 09:39:44.000",
                "CDU_Updated_at": "2021-05-29 15:16:11.000",
                "CDU_Deleted_at": "2019-03-11 00:00:00.000",
                "CDU_Valor": 25,
                "CDU_Pais": "ALL",
                "CDU_ID": 3,
                "STOCK_ATUAL": 48,
                "STOCK_REAL": "48",
                "DescontoMarca": 35,
                "FICHEIRO": "{1247B923-FE88-E911-8150-000C291E7900}.png",
                "_links": {
                    "self": "https://dev.apicore.bicimax.com/api/v1/products/RFDP20AEFR309"
                },
                "color": null
            },
            {
                "Artigo": "RFDP20AEFR316",
                "ArtigoPai": null,
                "Marca": "RF_COMP",
                "Familia": "SEATPOSTS",
                "SubFamilia": null,
                "MARCA_ID": "RF_COMP",
                "campaign": 0,
                "STKMinimo": 1,
                "STKReposicao": 2,
                "PVP1": "329.75",
                "PVP1_ORDER": 329.75,
                "PVPBox": 329.75,
                "ptPVP": "405.00",
                "esPVP": "399.00",
                "Desconto": 0,
                "sale": 0,
                "TratamentoDim": 1,
                "Descricao": "Espigão de selim RaceFace Aeffect R 31,6",
                "Nome2": "",
                "Dim1": "DP",
                "Dim2": null,
                "Dim3": null,
                "RubDim1": null,
                "RubDim2": null,
                "RubDim3": null,
                "DescDim1": "DROPPER POST",
                "DescDim2": null,
                "DescDim3": null,
                "DescRubDim1": null,
                "DescRubDim2": null,
                "DescRubDim3": null,
                "CDU_B2B_SYNC": 1,
                "totalChildren": 4,
                "totalChildrenStockOff": 0,
                "CDU_B2BEsgotado": 0,
                "Options": {
                    "DP": {
                        "100": {
                            "Artigo": "RFDP20AEFR316.100",
                            "Marca": "RF_COMP",
                            "DescDim1": "DROPPER POST",
                            "Dim1": "DP",
                            "RubDim1": "100",
                            "DescRubDim1": "100MM",
                            "CodBarras": "821973354941",
                            "STKMinimo": 2,
                            "STKReposicao": 6,
                            "PVP1": "329.75",
                            "PVP1_ORDER": 329.75206611,
                            "PVPBox": 329.75206611,
                            "ptPVP": "405.00",
                            "esPVP": "399.00",
                            "Desconto": 0
                        },
                        "125": {
                            "Artigo": "RFDP20AEFR316.125",
                            "Marca": "RF_COMP",
                            "DescDim1": "DROPPER POST",
                            "Dim1": "DP",
                            "RubDim1": "125",
                            "DescRubDim1": "125MM",
                            "CodBarras": "821973354958",
                            "STKMinimo": 1,
                            "STKReposicao": 2,
                            "PVP1": "329.75",
                            "PVP1_ORDER": 329.75206611,
                            "PVPBox": 329.75206611,
                            "ptPVP": "405.00",
                            "esPVP": "399.00",
                            "Desconto": 0
                        },
                        "150": {
                            "Artigo": "RFDP20AEFR316.150",
                            "Marca": "RF_COMP",
                            "DescDim1": "DROPPER POST",
                            "Dim1": "DP",
                            "RubDim1": "150",
                            "DescRubDim1": "150MM",
                            "CodBarras": "821973354965",
                            "STKMinimo": 4,
                            "STKReposicao": 6,
                            "PVP1": "329.75",
                            "PVP1_ORDER": 329.75206611,
                            "PVPBox": 329.75206611,
                            "ptPVP": "405.00",
                            "esPVP": "399.00",
                            "Desconto": 0
                        },
                        "170": {
                            "Artigo": "RFDP20AEFR316.170",
                            "Marca": "RF_COMP",
                            "DescDim1": "DROPPER POST",
                            "Dim1": "DP",
                            "RubDim1": "170",
                            "DescRubDim1": "170MM",
                            "CodBarras": "821973354972",
                            "STKMinimo": 2,
                            "STKReposicao": 6,
                            "PVP1": "329.75",
                            "PVP1_ORDER": 329.75206611,
                            "PVPBox": 329.75206611,
                            "ptPVP": "405.00",
                            "esPVP": "399.00",
                            "Desconto": 0
                        }
                    }
                },
                "CDU_Cliente": "174",
                "CDU_Marca": "RF_COMP",
                "CDU_Nivel": 0,
                "CDU_Created_at": "2019-03-14 09:39:44.000",
                "CDU_Updated_at": "2021-05-29 15:16:11.000",
                "CDU_Deleted_at": "2019-03-11 00:00:00.000",
                "CDU_Valor": 25,
                "CDU_Pais": "ALL",
                "CDU_ID": 3,
                "STOCK_ATUAL": 107,
                "STOCK_REAL": "107",
                "DescontoMarca": 35,
                "FICHEIRO": "{FBA3D840-FE88-E911-8150-000C291E7900}.png",
                "_links": {
                    "self": "https://dev.apicore.bicimax.com/api/v1/products/RFDP20AEFR316"
                },
                "color": null
            }
        ]
    }),
    created() {
        console.log(this.data);
        console.log(Object.keys(this.data[0].Options))
        console.log(Object.keys(this.data[0].Options.HOLES))
        console.log(this.data[0].Options.HOLES['28H'])
        console.log(Object.keys(this.data[0].Options.HOLES['28H'].RIMS))
        console.log(this.data[0].Options.HOLES['28H'].RIMS['26'])
    },
    methods: {
        GoGoProductToCart(productId='blablabla'){
            let product = null;

            /** this.data[0] será this.data[productId] que será o index do produto pai que se esta a tratar **/
            if(this.selectedSpec3 != null){
                const option1 = Object.keys(this.data[0].Options)[0];
                const option2 = Object.keys(this.data[0].Options[option1][this.selectedSpec1]);
                const option3 = Object.keys(this.data[0].Options[option1][this.selectedSpec1][option2][this.selectedSpec2]);

                product = this.data[0].Options[option1][this.selectedSpec1][option2][this.selectedSpec2][option3][this.selectedSpec3];
            }else if(this.selectedSpec2 != null){
                const option1 = Object.keys(this.data[0].Options)[0];
                const option2 = Object.keys(this.data[0].Options[option1][this.selectedSpec1]);
                product = this.data[0].Options[option1][this.selectedSpec1][option2][this.selectedSpec2];
            }else if(this.selectedSpec1 != null){
                const option1 = Object.keys(this.data[0].Options)[0];
                product = this.data[0].Options[option1][this.selectedSpec1];
            }
            console.log(product);

        }
    },
    computed: {

    }
}
</script>

<style scoped>

</style>
