<template>
    <v-container fluid>

    </v-container>
</template>

<script>
    import TableOrdersB2B from "./Widgets/TableOrdersB2B";
    import PendingOrders from "./Widgets/PendingOrders";
    import TableServiceCenter from "./Widgets/TableServiceCenter";

    export default {
        components: {
            TableServiceCenter,
            TableOrdersB2B,
            PendingOrders,
        },
        computed: {
            profile() {
                return User.getters.getProfileId
            }
        }
    }
</script>
