<style>
.v-input--selection-controls.v-input {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    margin-top: 0;
}

button.fas.fa-minus {
    color: #E53935;
    font-size: 10px !important;
    cursor: pointer;
}

button.fas.fa-plus {
    color: #00C853;
    font-size: 10px !important;
    cursor: pointer;
}

</style>
<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="'VFA Picking '+pickOrderId"
                        :subtitle="pickOrder.cliente+' - '+pickOrder.nome"
                ></page-title>
            </v-col>
            <v-col class="text-right col-2" align-self="center">
                <v-btn tile small color="primary" @click="showDialogMatchInvoice" class="mr-1">
                    Pré-Selecionar
                </v-btn>
            </v-col>
            <v-col class="text-right" align-self="center">
                Total linhas para faturar: {{ totalProductsSelected }}
                <v-btn tile small color="success" :disabled="buttonCreateVFA" @click="createVFA" class="mr-3">
                    Criar VFA
                </v-btn>
                <v-btn tile small color="error" v-if="totalProductsNotInvoice === 0" @click="closeInvoice('Arquivado')">
                    Fechar e
                    Arquivar
                </v-btn>
                <v-btn tile small color="error" v-if="totalProductsNotInvoice > 0" @click="closeInvoice('Eliminado')">
                    Eliminar e
                    Fechar
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-menu
                                        v-model="datePickerDocumentDate"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="inputPurchase.documentDate"
                                                label="Data Documento"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                dense
                                                hide-details
                                                @change="validateButtonCreateVFA"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="inputPurchase.documentDate"
                                            @input="changeDatePickerDocumentDate"
                                            locale="pt-pt"
                                            :max="new Date().toISOString().substr(0, 10)"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                        v-model="datePickerIntroductionDate"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="inputPurchase.introductionDate"
                                                label="Data Introdução"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                dense
                                                hide-details
                                                @change="validateButtonCreateVFA"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="inputPurchase.introductionDate"
                                            @input="datePickerIntroductionDate = false"
                                            locale="pt-pt"
                                            :max="new Date().toISOString().substr(0, 10)"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-menu
                                        v-model="datePickerExpirationDate"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                                v-model="inputPurchase.expirationDate"
                                                label="Data Vencimento"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                dense
                                                hide-details
                                                @change="validateButtonCreateVFA"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                            v-model="inputPurchase.expirationDate"
                                            @input="datePickerExpirationDate = false"
                                            locale="pt-pt"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="inputPurchase.numDocExterno"
                                        outlined
                                        hide-details
                                        dense
                                        label="Num. Documento Externo"
                                        @input="validateButtonCreateVFA"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="inputPurchase.exchange"
                                        outlined
                                        hide-details
                                        dense
                                        label="Câmbio"
                                        @input="validateButtonCreateVFA"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <h3>Totais:</h3>
                                </v-col>
                            </v-row>
                            <hr>
                            <br>
                            <v-row></v-row>
                            <v-row>
                                <v-col class="text-center">
                                    <h5>Produtos selecionados:</h5>
                                    <span style="text-align:center">{{ totalProductsSelected }}</span>
                                </v-col>
                                <v-col class="text-center">
                                    <h5>Quantidade total de produtos:</h5>
                                    <span style="text-align:center">{{ selectedProductsQuantity.amount }}</span>
                                </v-col>
                                <v-col class="text-center">
                                    <h5>Preço total:</h5>
                                    <span style="text-align:center">{{ selectedProductsQuantity.value }}€</span>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- IF MATCHING PRODUCTS WAS SELECTED -->

        <!-- PRoducts that where matchind in a ECF -->
        <template v-if="matchingProducts">
            <v-row>
                <v-col cols="11" class="text-center">
                    <h5>Encontrados</h5>
                </v-col>
                <v-col cols="1">
                    <v-btn icon small class="ml-2" color="grey"
                           @click="openorder.collapse=true">
                        <v-icon>unfold_less</v-icon>
                    </v-btn>
                    <v-btn icon small class="ml-2" color="grey"
                           @click="openorder.collapse=false">
                        <v-icon>unfold_more</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <hr>
            <br>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-simple-table class="staff_table">
                                <thead>
                                <tr class="titles_row">
                                    <th></th>
                                    <th style="cursor: pointer;" @click="orderList('artigo')">
                                        Artigo
                                        <i v-if="selectedHeader === 'artigo'" class="fas"
                                           :class="[headers['artigo'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                    </th>
                                    <th style="cursor: pointer;" @click="orderList('codBarras')">
                                        Cód. Barras
                                        <i v-if="selectedHeader === 'codBarras'" class="fas"
                                           :class="[headers['codBarras'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                    </th>
                                    <th style="cursor: pointer;" @click="orderList('CDU_EAN')">
                                        EAN
                                        <i v-if="selectedHeader === 'CDU_EAN'" class="fas"
                                           :class="[headers['CDU_EAN'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                    </th>
                                    <th style="cursor: pointer;" @click="orderList('CDU_REFFORNECEDOR')">
                                        Ref. Fornecedor
                                        <i v-if="selectedHeader === 'CDU_REFFORNECEDOR'" class="fas"
                                           :class="[headers['CDU_REFFORNECEDOR'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                    </th>
                                    <th style="cursor: pointer;" @click="orderList('descricao')">
                                        Descrição
                                        <i v-if="selectedHeader === 'descricao'" class="fas"
                                           :class="[headers['descricao'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                    </th>
                                    <!--                                <th>Num. Série</th>-->
                                    <th style="cursor: pointer;" @click="orderList('qty')" class="text-center">
                                        QTD
                                        <i v-if="selectedHeader === 'qty'" class="fas"
                                           :class="[headers['qty'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                    </th>
                                    <th style="cursor: pointer;" @click="orderList('quantTrans')" class="text-center">
                                        QTD Trans.
                                        <i v-if="selectedHeader === 'quantTrans'" class="fas"
                                           :class="[headers['quantTrans'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                    </th>
                                    <th>Num. Enc.</th>
                                    <!--                                <th>NumDocExterno</th>-->
                                    <th style="width: 90px; cursor: pointer;" @click="orderList('quantATrans')">
                                        QTD A Trans.
                                        <i v-if="selectedHeader === 'quantATrans'" class="fas"
                                           :class="[headers['quantATrans'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                    </th>
                                </tr>
                                <tr class="filters_row">
                                    <td class="text-center">
                                        <span style="cursor: pointer">
                                            <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                                            <i v-if="checkboxAll" class="fas fa-check-square fa-lg"
                                               style="color:#1976d2"
                                               @click="unselectAll()"></i>
                                        </span>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.product"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filter"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.barcode"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filter"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.ean"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filter"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.refSupplier"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filter"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.description"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filter"
                                        ></v-text-field>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.ecf"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filter"
                                        ></v-text-field>
                                    </td>
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="product in matchedProducts">
                                    <tr v-if="product.numOrderPri && differentECF(product)">
                                        <td colspan="18" class="grey lighten-2 date_row">
                                            ECF {{ product.numOrderPri }}
                                        </td>
                                    </tr>
                                    <tr v-if="(product.quantATransOrigin > 0 || product.quantATransOrigin === '') && product.visible"
                                        :class="{'blue lighten-5':product.selected}">
                                        <td class="text-center">
                                            <i v-if="!product.selected" class="far fa-square fa-lg"
                                               style="cursor: pointer"
                                               @click="selectLine(product, true)"></i>
                                            <i v-if="product.selected" class="fas fa-check-square fa-lg"
                                               style="color:#1976d2; cursor: pointer"
                                               @click="selectLine(product, false)"></i>
                                            <span v-if="product.order">{{ product.order }}</span>
                                        </td>
                                        <td>{{ product.artigo }}</td>
                                        <td>{{ product.codBarras }}</td>
                                        <td>{{ product.CDU_EAN }}</td>
                                        <td>{{ product.CDU_REFFORNECEDOR }}</td>
                                        <td>{{ product.descricao }}</td>
                                        <td class="text-center">{{ product.qty }}</td>
                                        <td class="text-center">{{ product.quantTrans || 0 }}</td>
                                        <td>
                                            <template v-if="product.numOrderPri && product.ECFs.length === 0">
                                                {{ product.tipoDocPri }} {{ product.numOrderPri }}
                                            </template>
                                            <template v-else>
                                                <template v-if="product.ECFs.length > 1">
                                    <tr v-for="ECFOption in product.ECFs" v-if="ECFOption.visible">
                                        <template v-if="Array.isArray(product.numOrderPri)">
                                            <template v-if="product.numOrderPri.length > 0">
                                <span style="cursor: pointer">
                                    <span
                                            v-if="validateECFOption(product.numOrderPri, ECFOption.VALUE, product.numLinhaPri, ECFOption.NumLinha) === 0"><i
                                            class="far fa-square fa-lg"
                                            @click="selectNumLine(product, ECFOption.VALUE, ECFOption.NumLinha)"></i> {{
                                            ECFOption.TEXT
                                        }} - <strong>Qty:</strong> {{
                                            ECFOption.Quantidade
                                        }} - <strong>Data:</strong> {{
                                            ECFOption.DataEntrega
                                        }}</span>
                                    <span
                                            v-if="validateECFOption(product.numOrderPri, ECFOption.VALUE, product.numLinhaPri, ECFOption.NumLinha) !== 0"><i
                                            class="fas fa-check-square fa-lg" style="color:#1976d2"
                                            @click="selectNumLine(product, ECFOption.VALUE, ECFOption.NumLinha)"></i> {{
                                            ECFOption.TEXT
                                        }} - <strong>Qty:</strong> {{
                                            ECFOption.Quantidade
                                        }} - <strong>Data:</strong> {{
                                            ECFOption.DataEntrega
                                        }}</span>
                                </span>
                                            </template>
                                            <template v-if="product.numOrderPri.length === 0">
                                <span style="cursor: pointer">
                                    <i class="far fa-square fa-lg"
                                       @click="selectNumLine(product, ECFOption.VALUE, ECFOption.NumLinha)"></i> {{
                                        ECFOption.TEXT
                                    }} - <strong>Qty:</strong> {{
                                        ECFOption.Quantidade
                                    }} - <strong>Data:</strong> {{
                                        ECFOption.DataEntrega
                                    }}
                                </span>
                                            </template>
                                        </template>
                                    </tr>
                                </template>
                                <template v-else-if="product.numOrderPri">
                                    ECF {{ product.numOrderPri }}
                                </template>
        </template>
        </td>
        <td>
            <v-text-field
                    class="staff_text-field_small"
                    style="width: 160px"
                    v-if="product.selected"
                    v-model="product.quantATrans"
                    outlined
                    hide-details
                    dense
                    prepend-icon="fas fa-minus"
                    append-outer-icon="fas fa-plus"
                    @click:prepend="decrementQuantity(product)"
                    @click:append-outer="incrementQuantity(product)"
            ></v-text-field>
        </td>
        </tr>
</template>
</tbody>
</v-simple-table>
</v-card-text>
</v-card>
</v-col>
</v-row>
</template>

<!-- end where matchind in a ECF -->

<!-- PRoducts that where found in ecf but not in picking  -->
<template v-if="matchingProducts">
    <v-row>
        <v-col cols="11" class="text-center">
            <h5>Em falta no picking</h5>
        </v-col>
        <v-col cols="1">
            <v-btn icon small class="ml-2" color="grey"
                   @click="openorder.collapse=true">
                <v-icon>unfold_less</v-icon>
            </v-btn>
            <v-btn icon small class="ml-2" color="grey"
                   @click="openorder.collapse=false">
                <v-icon>unfold_more</v-icon>
            </v-btn>
        </v-col>
    </v-row>
    <hr>
    <br>
    <v-row>
        <v-col>
            <v-card>
                <v-card-text>
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th></th>
                            <th style="cursor: pointer;" @click="orderList('artigo')">
                                Artigo
                                <i v-if="selectedHeader === 'artigo'" class="fas"
                                   :class="[headers['artigo'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('codBarras')">
                                Cód. Barras
                                <i v-if="selectedHeader === 'codBarras'" class="fas"
                                   :class="[headers['codBarras'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('CDU_EAN')">
                                EAN
                                <i v-if="selectedHeader === 'CDU_EAN'" class="fas"
                                   :class="[headers['CDU_EAN'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('CDU_REFFORNECEDOR')">
                                Ref. Fornecedor
                                <i v-if="selectedHeader === 'CDU_REFFORNECEDOR'" class="fas"
                                   :class="[headers['CDU_REFFORNECEDOR'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('descricao')">
                                Descrição
                                <i v-if="selectedHeader === 'descricao'" class="fas"
                                   :class="[headers['descricao'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <!--                                <th>Num. Série</th>-->
                            <th style="cursor: pointer;" @click="orderList('qty')" class="text-center">
                                QTD
                                <i v-if="selectedHeader === 'qty'" class="fas"
                                   :class="[headers['qty'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('quantTrans')" class="text-center">
                                QTD Trans.
                                <i v-if="selectedHeader === 'quantTrans'" class="fas"
                                   :class="[headers['quantTrans'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th>Num. Enc.</th>
                            <!--                                <th>NumDocExterno</th>-->
                            <th style="width: 90px; cursor: pointer;" @click="orderList('quantATrans')">
                                QTD A Trans.
                                <i v-if="selectedHeader === 'quantATrans'" class="fas"
                                   :class="[headers['quantATrans'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                        </tr>
                        <tr class="filters_row">
                            <td class="text-center">
                                        <span style="cursor: pointer">
                                            <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                                            <i v-if="checkboxAll" class="fas fa-check-square fa-lg"
                                               style="color:#1976d2"
                                               @click="unselectAll()"></i>
                                        </span>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.product"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.barcode"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.ean"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.refSupplier"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.description"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.ecf"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody style="background-color: rgb(255 248 204);">
                        <template v-for="product in missingProducts">
                            <tr v-if="product.numOrderPri && differentECF(product)">
                                <td colspan="18" class="grey lighten-2 date_row">
                                    ECF {{ product.numOrderPri }}
                                </td>
                            </tr>
                            <tr
                                    :class="{'blue lighten-5':product.selected}">
                                <td class="text-center">
                                    <i v-if="!product.selected" class="far fa-square fa-lg"
                                       style="cursor: pointer"
                                       @click="selectLine(product, true)"></i>
                                    <i v-if="product.selected" class="fas fa-check-square fa-lg"
                                       style="color:#1976d2; cursor: pointer"
                                       @click="selectLine(product, false)"></i>
                                    <span v-if="product.order">{{ product.order }}</span>
                                </td>
                                <td>{{ product.artigo }}</td>
                                <td>{{ product.codBarras }}</td>
                                <td>{{ product.CDU_EAN }}</td>
                                <td>{{ product.CDU_REFFORNECEDOR }}</td>
                                <td>{{ product.descricao }}</td>
                                <td class="text-center">{{ product.qty }}</td>
                                <td class="text-center">{{ product.quantTrans || 0 }}</td>
                                <td>

                                    {{ product.tipoDocPri }} {{ product.numOrderPri }}

                                </td>
                                <td>
                                    <v-text-field
                                            class="staff_text-field_small"
                                            style="width: 160px"
                                            v-if="product.selected"
                                            v-model="product.quantATrans"
                                            outlined
                                            hide-details
                                            dense
                                            prepend-icon="fas fa-minus"
                                            append-outer-icon="fas fa-plus"
                                            @click:prepend="decrementQuantity(product)"
                                            @click:append-outer="incrementQuantity(product)"
                                    ></v-text-field>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<!-- end where found in ecf but not in picking -->

<!-- PRoducts in pick but not in ecf  -->
<template v-if="matchingProducts">
    <v-row>
        <v-col cols="11" class="text-center">
            <h5>Sem correspondencia na fatura</h5>
        </v-col>
        <v-col cols="1">
            <v-btn icon small class="ml-2" color="grey"
                   @click="openorder.collapse=true">
                <v-icon>unfold_less</v-icon>
            </v-btn>
            <v-btn icon small class="ml-2" color="grey"
                   @click="openorder.collapse=false">
                <v-icon>unfold_more</v-icon>
            </v-btn>
        </v-col>
    </v-row>
    <hr>
    <br>
    <v-row>
        <v-col>
            <v-card>
                <v-card-text>
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th></th>
                            <th style="cursor: pointer;" @click="orderList('artigo')">
                                Artigo
                                <i v-if="selectedHeader === 'artigo'" class="fas"
                                   :class="[headers['artigo'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('codBarras')">
                                Cód. Barras
                                <i v-if="selectedHeader === 'codBarras'" class="fas"
                                   :class="[headers['codBarras'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('CDU_EAN')">
                                EAN
                                <i v-if="selectedHeader === 'CDU_EAN'" class="fas"
                                   :class="[headers['CDU_EAN'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('CDU_REFFORNECEDOR')">
                                Ref. Fornecedor
                                <i v-if="selectedHeader === 'CDU_REFFORNECEDOR'" class="fas"
                                   :class="[headers['CDU_REFFORNECEDOR'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('descricao')">
                                Descrição
                                <i v-if="selectedHeader === 'descricao'" class="fas"
                                   :class="[headers['descricao'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <!--                                <th>Num. Série</th>-->
                            <th style="cursor: pointer;" @click="orderList('qty')" class="text-center">
                                QTD
                                <i v-if="selectedHeader === 'qty'" class="fas"
                                   :class="[headers['qty'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('quantTrans')" class="text-center">
                                QTD Trans.
                                <i v-if="selectedHeader === 'quantTrans'" class="fas"
                                   :class="[headers['quantTrans'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th>Num. Enc.</th>
                            <!--                                <th>NumDocExterno</th>-->
                            <th style="width: 90px; cursor: pointer;" @click="orderList('quantATrans')">
                                QTD A Trans.
                                <i v-if="selectedHeader === 'quantATrans'" class="fas"
                                   :class="[headers['quantATrans'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                        </tr>
                        <tr class="filters_row">
                            <td class="text-center">
                                        <span style="cursor: pointer">
                                            <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                                            <i v-if="checkboxAll" class="fas fa-check-square fa-lg"
                                               style="color:#1976d2"
                                               @click="unselectAll()"></i>
                                        </span>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.product"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.barcode"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.ean"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.refSupplier"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.description"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.ecf"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="product in notFoundProducts">
                            <tr v-if="product.numOrderPri && differentECF(product)">
                                <td colspan="18" class="grey lighten-2 date_row">
                                    ECF {{ product.numOrderPri }}
                                </td>
                            </tr>
                            <tr v-if="(product.quantATransOrigin > 0 || product.quantATransOrigin === '') && product.visible"
                                :class="{'blue lighten-5':product.selected}">
                                <td class="text-center">
                                    <i v-if="!product.selected" class="far fa-square fa-lg"
                                       style="cursor: pointer"
                                       @click="selectLine(product, true)"></i>
                                    <i v-if="product.selected" class="fas fa-check-square fa-lg"
                                       style="color:#1976d2; cursor: pointer"
                                       @click="selectLine(product, false)"></i>
                                    <span v-if="product.order">{{ product.order }}</span>
                                </td>
                                <td>{{ product.artigo }}</td>
                                <td>{{ product.codBarras }}</td>
                                <td>{{ product.CDU_EAN }}</td>
                                <td>{{ product.CDU_REFFORNECEDOR }}</td>
                                <td>{{ product.descricao }}</td>
                                <td class="text-center">{{ product.qty }}</td>
                                <td class="text-center">{{ product.quantTrans || 0 }}</td>
                                <td>
                                    <template v-if="product.numOrderPri && product.ECFs.length === 0">
                                        {{ product.tipoDocPri }} {{ product.numOrderPri }}
                                    </template>
                                    <template v-else>
                                        <template v-if="product.ECFs.length > 1">
                            <tr v-for="ECFOption in product.ECFs" v-if="ECFOption.visible">
                                <template v-if="Array.isArray(product.numOrderPri)">
                                    <template v-if="product.numOrderPri.length > 0">
                                <span style="cursor: pointer">
                                    <span
                                            v-if="validateECFOption(product.numOrderPri, ECFOption.VALUE, product.numLinhaPri, ECFOption.NumLinha) === 0"><i
                                            class="far fa-square fa-lg"
                                            @click="selectNumLine(product, ECFOption.VALUE, ECFOption.NumLinha)"></i> {{
                                            ECFOption.TEXT
                                        }} - <strong>Qty:</strong> {{
                                            ECFOption.Quantidade
                                        }} - <strong>Data:</strong> {{
                                            ECFOption.DataEntrega
                                        }}</span>
                                    <span
                                            v-if="validateECFOption(product.numOrderPri, ECFOption.VALUE, product.numLinhaPri, ECFOption.NumLinha) !== 0"><i
                                            class="fas fa-check-square fa-lg" style="color:#1976d2"
                                            @click="selectNumLine(product, ECFOption.VALUE, ECFOption.NumLinha)"></i> {{
                                            ECFOption.TEXT
                                        }} - <strong>Qty:</strong> {{
                                            ECFOption.Quantidade
                                        }} - <strong>Data:</strong> {{
                                            ECFOption.DataEntrega
                                        }}</span>
                                </span>
                                    </template>
                                    <template v-if="product.numOrderPri.length === 0">
                                <span style="cursor: pointer">
                                    <i class="far fa-square fa-lg"
                                       @click="selectNumLine(product, ECFOption.VALUE, ECFOption.NumLinha)"></i> {{
                                        ECFOption.TEXT
                                    }} - <strong>Qty:</strong> {{
                                        ECFOption.Quantidade
                                    }} - <strong>Data:</strong> {{
                                        ECFOption.DataEntrega
                                    }}
                                </span>
                                    </template>
                                </template>
                            </tr>
                        </template>
                        <template v-else-if="product.numOrderPri">
                            ECF {{ product.numOrderPri }}
                        </template>
</template>
</td>
<td>
<v-text-field
        class="staff_text-field_small"
        style="width: 160px"
        v-if="product.selected"
        v-model="product.quantATrans"
        outlined
        hide-details
        dense
        prepend-icon="fas fa-minus"
        append-outer-icon="fas fa-plus"
        @click:prepend="decrementQuantity(product)"
        @click:append-outer="incrementQuantity(product)"
></v-text-field>
</td>
</tr>
</template>
</tbody>
</v-simple-table>
</v-card-text>
</v-card>
</v-col>
</v-row>
</template>

<!-- end where found in pick but not in ECF -->

<!-- END MATCHING SELECTED -->


<template v-if="matchingProducts == false">
    <v-row>
        <v-col>
            <v-card>
                <v-card-text>
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th></th>
                            <th style="cursor: pointer;" @click="orderList('artigo')">
                                Artigo
                                <i v-if="selectedHeader === 'artigo'" class="fas"
                                   :class="[headers['artigo'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('codBarras')">
                                Cód. Barras
                                <i v-if="selectedHeader === 'codBarras'" class="fas"
                                   :class="[headers['codBarras'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('CDU_EAN')">
                                EAN
                                <i v-if="selectedHeader === 'CDU_EAN'" class="fas"
                                   :class="[headers['CDU_EAN'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('CDU_REFFORNECEDOR')">
                                Ref. Fornecedor
                                <i v-if="selectedHeader === 'CDU_REFFORNECEDOR'" class="fas"
                                   :class="[headers['CDU_REFFORNECEDOR'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('descricao')">
                                Descrição
                                <i v-if="selectedHeader === 'descricao'" class="fas"
                                   :class="[headers['descricao'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <!--                                <th>Num. Série</th>-->
                            <th style="cursor: pointer;" @click="orderList('qty')" class="text-center">
                                QTD
                                <i v-if="selectedHeader === 'qty'" class="fas"
                                   :class="[headers['qty'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="cursor: pointer;" @click="orderList('quantTrans')" class="text-center">
                                QTD Trans.
                                <i v-if="selectedHeader === 'quantTrans'" class="fas"
                                   :class="[headers['quantTrans'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th>Num. Enc.</th>
                            <!--                                <th>NumDocExterno</th>-->
                            <th style="width: 90px; cursor: pointer;" @click="orderList('quantATrans')">
                                QTD A Trans.
                                <i v-if="selectedHeader === 'quantATrans'" class="fas"
                                   :class="[headers['quantATrans'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                        </tr>
                        <tr class="filters_row">
                            <td class="text-center">
                                        <span style="cursor: pointer">
                                            <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                                            <i v-if="checkboxAll" class="fas fa-check-square fa-lg"
                                               style="color:#1976d2"
                                               @click="unselectAll()"></i>
                                        </span>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.product"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.barcode"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.ean"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.refSupplier"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.description"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.ecf"
                                        outlined
                                        hide-details
                                        dense
                                        @keypress.enter="filter"
                                ></v-text-field>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="product in orderedProducts">
                            <tr v-if="product.numOrderPri && differentECF(product)">
                                <td colspan="18" class="grey lighten-2 date_row">
                                    ECF {{ product.numOrderPri }}
                                </td>
                            </tr>
                            <tr v-if="(product.quantATransOrigin > 0 || product.quantATransOrigin === '') && product.visible"
                                :class="{'blue lighten-5':product.selected}">
                                <td class="text-center">
                                    <i v-if="!product.selected" class="far fa-square fa-lg"
                                       style="cursor: pointer"
                                       @click="selectLine(product, true)"></i>
                                    <i v-if="product.selected" class="fas fa-check-square fa-lg"
                                       style="color:#1976d2; cursor: pointer"
                                       @click="selectLine(product, false)"></i>
                                    {{ product.order }}
                                </td>
                                <td>{{ product.artigo }}</td>
                                <td>{{ product.codBarras }}</td>
                                <td>{{ product.CDU_EAN }}</td>
                                <td>{{ product.CDU_REFFORNECEDOR }}</td>
                                <td>{{ product.descricao }}</td>
                                <td class="text-center">{{ product.qty }}</td>
                                <td class="text-center">{{ product.quantTrans || 0 }}</td>
                                <td>
                                    <template v-if="product.numOrderPri && product.ECFs.length === 0">
                                        {{ product.tipoDocPri }} {{ product.numOrderPri }}
                                    </template>
                                    <template v-else>
                                        <template v-if="product.ECFs.length > 1">
                            <tr v-for="ECFOption in product.ECFs" v-if="ECFOption.visible">
                                <template v-if="Array.isArray(product.numOrderPri)">
                                    <template v-if="product.numOrderPri.length > 0">
                                <span style="cursor: pointer">
                                    <span
                                            v-if="validateECFOption(product.numOrderPri, ECFOption.VALUE, product.numLinhaPri, ECFOption.NumLinha) === 0"><i
                                            class="far fa-square fa-lg"
                                            @click="selectNumLine(product, ECFOption.VALUE, ECFOption.NumLinha)"></i> {{
                                            ECFOption.TEXT
                                        }} - <strong>Qty:</strong> {{
                                            ECFOption.Quantidade
                                        }} - <strong>Data:</strong> {{
                                            ECFOption.DataEntrega
                                        }}</span>
                                    <span
                                            v-if="validateECFOption(product.numOrderPri, ECFOption.VALUE, product.numLinhaPri, ECFOption.NumLinha) !== 0"><i
                                            class="fas fa-check-square fa-lg" style="color:#1976d2"
                                            @click="selectNumLine(product, ECFOption.VALUE, ECFOption.NumLinha)"></i> {{
                                            ECFOption.TEXT
                                        }} - <strong>Qty:</strong> {{
                                            ECFOption.Quantidade
                                        }} - <strong>Data:</strong> {{
                                            ECFOption.DataEntrega
                                        }}</span>
                                </span>
                                    </template>
                                    <template v-if="product.numOrderPri.length === 0">
                                <span style="cursor: pointer">
                                    <i class="far fa-square fa-lg"
                                       @click="selectNumLine(product, ECFOption.VALUE, ECFOption.NumLinha)"></i> {{
                                        ECFOption.TEXT
                                    }} - <strong>Qty:</strong> {{
                                        ECFOption.Quantidade
                                    }} - <strong>Data:</strong> {{
                                        ECFOption.DataEntrega
                                    }}
                                </span>
                                    </template>
                                </template>
                            </tr>
                        </template>
                        <template v-else-if="product.numOrderPri">
                            ECF {{ product.numOrderPri }}
                        </template>
</template>
</td>
<td>
<v-text-field
        class="staff_text-field_small"
        style="width: 160px"
        v-if="product.selected"
        v-model="product.quantATrans"
        outlined
        hide-details
        dense
        prepend-icon="fas fa-minus"
        append-outer-icon="fas fa-plus"
        @click:prepend="decrementQuantity(product)"
        @click:append-outer="incrementQuantity(product)"
></v-text-field>
</td>
</tr>
</template>
</tbody>
</v-simple-table>
</v-card-text>
</v-card>
</v-col>
</v-row>
</template>

<!-- DIALOGS -->
<v-row justify="center">
<!-- RESPONSE -->
<v-dialog v-model="responseDialog.open" persistent max-width="700">
    <v-card>
        <v-card-title>
            {{ responseDialog.title }}
        </v-card-title>
        <v-card-text>
            <div class="text-center">
                <v-progress-circular
                        v-if="responseDialog.loading"
                        indeterminate
                        color="primary"
                        size="100"
                ></v-progress-circular>
                <div v-if="!responseDialog.loading">
                    <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                    <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                    <p>{{ responseDialog.message }}</p>
                    <v-alert v-if="responseDialog.submessage" type="error">
                        {{ responseDialog.submessage }}
                    </v-alert>
                </div>
            </div>
        </v-card-text>
        <v-card-actions class="pa-3">
            <v-spacer></v-spacer>
            <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">
                OK
            </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
<!-- Match -->
<v-dialog v-model="dialogMatchInvoice" persistent max-width="400">
    <v-card>
        <v-card-title class="headline light-blue white--text" dark primary-title>
            Pré-selecionar por encomenda
        </v-card-title>
        <v-card-text class="py-12">
            <v-container>
                <v-row>
                    <v-col>
                        <h4>Insira o "Order Number"</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field label="Order Number" v-model="invoiceMatch" @keydown="numberOnly" solo dense
                                      outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-container>


        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-container>
                <v-row>
                    <v-col class="text-center">
                        <v-btn tile small color="default" @click="dialogMatchInvoice = false">Voltar</v-btn>
                    </v-col>
                    <v-col class="text-center">
                        <v-btn :disabled="invoiceMatch.length < 6" @click="matchInvoice" tile small color="primary">
                            Pré-Selecionar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>


        </v-card-actions>
    </v-card>
</v-dialog>
</v-row>
</v-container>
</template>

<script>
import _ from "lodash";
import Alert from "../../models/Alert";

export default {
    data: function () {
        return {
            pickOrderId: '',
            pickOrder: {},
            products: [],
            productsOriginal: [],
            filters: {
                product: '',
                barcode: '',
                ean: '',
                refSupplier: '',
                description: '',
                ecf: '',
                NumDocExterno: '',
            },
            checkboxAll: false,
            datePickerDocumentDate: false,
            datePickerIntroductionDate: false,
            datePickerExpirationDate: false,
            inputPurchase: {
                documentDate: new Date().toISOString().substr(0, 10),
                introductionDate: new Date().toISOString().substr(0, 10),
                expirationDate: new Date().toISOString().substr(0, 10),
                numDocExterno: '',
                exchange: ''
            },
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
            dialogClosePurchase: false,
            buttonCreateVFA: true,
            productsToAdd: [],
            selectedHeader: "artigo",
            headers: {
                "artigo": "asc",
                "codBarras": "asc",
                "CDU_EAN": "asc",
                "CDU_REFFORNECEDOR": "asc",
                "descricao": "asc",
                "qty": "asc",
                "quantTrans": "asc",
                "quantATrans": "asc",
            },
            dialogMatchInvoice: false,
            invoiceMatch: '',
            matchedProducts: [],
            missingProducts: [],
            notFoundProducts: [],
            matchingProducts: false
        }
    },
    created() {
        this.pickOrderId = this.$route.params.id;
        document.title = 'VFA Picking - ' + this.pickOrderId + ' | STAFF';
        this.getPurchasePickOrder();
    },
    private: {
        currentECF: ''
    },
    computed: {
        selectedProductsQuantity(){
            let count = 0;
            let price = 0;
            if(this.matchingProducts == false)
                this.products.forEach((product) => {
                    if (product.selected) {
                        count += parseInt(product.quantATrans);
                        price += parseFloat(product.price) * parseFloat(product.quantATrans);
                    }
                })
            else{
                this.matchedProducts.forEach((product) => {
                    if (product.selected) {
                        count += parseInt(product.quantATrans);
                        price += parseFloat(product.price) * parseFloat(product.quantATrans);
                    }
                });
                this.notFoundProducts.forEach((product) => {
                    if (product.selected) {
                        count += parseInt(product.quantATrans);
                        price += parseFloat(product.price) * parseFloat(product.quantATrans);
                    }
                });
                this.missingProducts.forEach((product) => {
                    if (product.selected) {
                        count += parseInt(product.quantATrans);
                        price += parseFloat(product.price) * parseFloat(product.quantATrans);
                    }
                });
            }
            return {'amount': count, 'value': price.toFixed(2)};
        },
        totalProductsSelected() {
            let count = 0;
            if(this.matchingProducts == false)
                this.products.forEach((product) => {
                    if (product.selected) {
                        count++;
                    }
                })
            else{
                this.matchedProducts.forEach((product) => {
                    if (product.selected) {
                        count++;
                    }
                });
                this.notFoundProducts.forEach((product) => {
                    if (product.selected) {
                        count++;
                    }
                });
                this.missingProducts.forEach((product) => {
                    if (product.selected) {
                        count++;
                    }
                });
            }
            return count;
        },
        totalProductsNotInvoice() {
            let count = 0;
            this.products.forEach((product) => {
                if (product.status !== 'Faturado') {
                    count++
                }
            })
            return count;
        },
        orderedProducts: function () {
            return _.orderBy(this.products, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    },
    methods: {
        getPurchasePickOrder() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/pick/' + this.pickOrderId + '/purchase',
                data: {
                    filters: this.filters
                }
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pickOrder = responseData.pickOrder;
                            this.products = responseData.products;
                            this.productsOriginal = responseData.products;
                            this.getExchange();
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        filter() {
            // this.getPurchasePickOrder();
            // this.products = this.productsOriginal;
            this.products.ECFs = this.productsOriginal.ECFs;
            let filters = this.filters;
            this.products.forEach(function (product) {
                product.visible = true;
                if (filters.product !== '') {
                    if (!product.artigo.toUpperCase().includes(filters.product.toUpperCase())) {
                        product.visible = false;
                    }
                }

                if (filters.barcode !== '') {
                    if (!product.codBarras.includes(filters.barcode)) {
                        product.visible = false;
                    }
                }

                if (filters.ean !== '') {
                    if (product.CDU_EAN == null || !product.CDU_EAN.includes(filters.ean)) {
                        product.visible = false;
                    }
                }

                if (filters.refSupplier !== '') {
                    if (product.CDU_REFFORNECEDOR == null || !product.CDU_REFFORNECEDOR.includes(filters.refSupplier)) {
                        product.visible = false;
                    }
                }

                if (filters.description !== '') {
                    if (!product.descricao.toUpperCase().includes(filters.description.toUpperCase())) {
                        product.visible = false;
                    }
                }

                if (filters.NumDocExterno !== '') {
                    if (!product.NumDocExterno.includes(filters.NumDocExterno)) {
                        product.visible = false;
                    }
                }

                let totalVisible = 0;
                product.ECFs.forEach(function (ecfOption) {
                    ecfOption.visible = true;
                    if (filters.ecf !== '') {
                        if (!(ecfOption.TEXT.toUpperCase().includes(filters.ecf))) {
                            ecfOption.visible = false;
                            totalVisible++;
                        }
                    }
                })
                if (filters.ecf !== '') {
                    if (Array.isArray(product.numOrderPri)) {
                        if (product.numOrderPri.length > 0) {
                            product.numOrderPri.forEach((numOrder) => {
                                if (!(numOrder.toString().includes(filters.ecf))) {
                                    product.visible = false;
                                }
                            })
                        } else {
                            if (product.ECFs.length === totalVisible) {
                                product.visible = false;
                            }
                        }
                    } else {
                        if (!(product.numOrderPri.toString().includes(filters.ecf))) {
                            product.visible = false;
                        }
                    }
                }
            });
        },
        selectAll() {
            this.currentECF = '';
            this.checkboxAll = true
            this.products.forEach(function (product) {
                if (product.status !== 'Faturado' && product.visible) {
                    product.selected = true
                }
            })
            this.validateButtonCreateVFA();
        },
        unselectAll() {
            this.currentECF = '';
            this.checkboxAll = false
            this.products.forEach(function (product) {
                if (product.visible) {
                    product.selected = false
                }
            })
            this.validateButtonCreateVFA();
        },
        differentECF(product) {
            if (product.numOrderPri !== this.currentECF && product.quantATrans > 0 && product.visible) {
                this.currentECF = product.numOrderPri
                return true
            }
            return false
        },
        selectLine(product, selected) {
            this.currentECF = '';
            product.selected = selected;
            product.order = selected ? this.totalProductsSelected : null;
            this.validateButtonCreateVFA();
        },
        createVFA() {
            this.currentECF = '';
            let productsToSend = [];
            if (this.matchingProducts == true) {
                this.matchedProducts.forEach((product) => {
                    if (product.selected && product.quantATrans > 0) {
                        productsToSend.push(product);
                    }
                });
                this.missingProducts.forEach((product) => {
                    if (product.selected && product.quantATrans > 0) {
                        productsToSend.push(product);
                    }
                });
                this.notFoundProducts.forEach((product) => {
                    if (product.selected && product.quantATrans > 0) {
                        productsToSend.push(product);
                    }
                })
            } else {
                this.products.forEach((product) => {
                    if (product.selected && product.quantATrans > 0) {
                        productsToSend.push(product);
                    }
                })
            }

            let request = {
                url: '/api/staff/purchases/pick/createVFA',
                data: {
                    products: productsToSend,
                    purchase: this.inputPurchase,
                    supplier: this.pickOrder.cliente
                }
            }
            let loader = this.$loading.show();
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.checkboxAll = false;
                            this.getPurchasePickOrder();
                        } else if (responseData.status === 'WARN') {
                            this.checkboxAll = false;
                            this.getPurchasePickOrder();

                            this.responseDialog.open = true;
                            this.responseDialog.loading = false;
                            this.responseDialog.title = 'Criar VFA!';
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.submessage = responseData.warn;
                            this.responseDialog.error = false;
                        } else {
                            this.responseDialog.open = true;
                            this.responseDialog.loading = false;
                            this.responseDialog.title = 'Criar VFA!';
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.error = true;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        toggleDialogClosePurchase() {
            this.dialogClosePurchase = true;
        },
        closeInvoice(status) {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/purchase/' + this.pickOrderId + '/closeInvoice',
                data: {
                    nextStatus: status
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.$router.push('/purchases/pick');
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        getExchange() {
            this.currentECF = '';
            let request = {
                url: '/api/staff/purchases/pick/exchange',
                data: {
                    startDate: this.inputPurchase.documentDate,
                    endDate: this.inputPurchase.documentDate,
                    currency: this.pickOrder.Moeda
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.inputPurchase.exchange = responseData.exchange;
                            if (responseData.exchange === '') {
                                let newStartDate = new Date(this.inputPurchase.documentDate);
                                let newEndDate = new Date(this.inputPurchase.documentDate);

                                newStartDate.setDate(newStartDate.getDate() - 1);
                                newEndDate.setDate(newEndDate.getDate() - 1);

                                let request = {
                                    url: '/api/staff/purchases/pick/exchange',
                                    data: {
                                        startDate: newStartDate.toISOString().slice(0, 10),
                                        endDate: newEndDate.toISOString().slice(0, 10),
                                        currency: this.pickOrder.Moeda
                                    }
                                }

                                ApiWS.dispatch('makePost', request)
                                        .then(response => {
                                            let responseData = response.data;
                                            if (responseData.status === 'OK') {
                                                this.currentECF = '';
                                                this.inputPurchase.exchange = responseData.exchange;
                                            }
                                        })
                                        .catch(() => {

                                        })
                            }
                        }
                    })
                    .catch(() => {

                    })
        },
        changeDatePickerDocumentDate() {
            this.currentECF = '';
            this.datePickerDocumentDate = false;
            this.getExchange();
        },
        closeECF() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/' + this.pickOrderId + '/closeECF'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.closeInvoice('Arquivado');
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        validateButtonCreateVFA() {
            let count = 0;
            this.products.forEach((product) => {
                if (product.selected === true && product.ECFs.length > 0) {
                    if (Array.isArray(product.numOrderPri)) {
                        if (product.numOrderPri.length === 0) {
                            count++;
                        }
                    } else {
                        if (product.numOrderPri === '' || product.numOrderPri === null) {
                            count++;
                        }
                    }
                }
            })
            // if (count > 0) {
            //     this.buttonCreateVFA = true;
            //     return;
            // }
            if (this.inputPurchase.numDocExterno === '') {
                this.buttonCreateVFA = true;
                return;
            }

            let productsSelected = 0;
            if (this.matchingProducts == false) {
                this.products.forEach((product) => {
                    if (product.selected === true) {
                        productsSelected++;
                    }
                })
            } else {
                this.matchedProducts.forEach((product) => {
                    if (product.selected === true) {
                        productsSelected++;
                    }
                });
                this.missingProducts.forEach((product) => {
                    if (product.selected === true) {
                        productsSelected++;
                    }
                });
                this.notFoundProducts.forEach((product) => {
                    if (product.selected === true) {
                        productsSelected++;
                    }
                })
            }
            if (productsSelected === 0) {
                this.buttonCreateVFA = true;
                return;
            }

            this.buttonCreateVFA = false;
        },
        selectNumLine(product, ecfSelected, ecfNumLine) {
            if (Array.isArray(product.numOrderPri)) {
                let count = 0;
                product.numOrderPri.forEach((numOrder, index) => {
                    if (numOrder === ecfSelected && product.numLinhaPri[index] === ecfNumLine) {
                        product.numOrderPri.splice(index, 1);
                        product.numLinhaPri.splice(index, 1);
                        product.numOrderPriOrder.splice(index, 1);
                        count++;
                    }
                })
                if (count === 0) {
                    product.numOrderPri.push(ecfSelected);
                    product.numLinhaPri.push(ecfNumLine);
                    product.numOrderPriOrder.push(this.totalProductsSelected);
                }
                if (product.numOrderPri.length === 0 && count === 0) {
                    product.numOrderPri.push(ecfSelected);
                    product.numLinhaPri.push(ecfNumLine);
                    product.numOrderPriOrder.push(this.totalProductsSelected);
                }
            } else {
                if (product.numOrderPri === ecfSelected) {
                    product.numOrderPri = '';
                    product.numLinhaPri = '';
                } else {
                    product.numOrderPri = ecfSelected;
                    product.numLinhaPri = ecfNumLine;
                    product.numOrderPriOrder = this.totalProductsSelected;
                }
            }

            if (Array.isArray(product.numOrderPri)) {
                // product.numLinhaPri = [];
                product.tipoDocPri = [];
                product.seriePri = [];
            }
            product.ECFs.forEach((ecf) => {
                if (Array.isArray(product.numOrderPri)) {
                    product.numOrderPri.forEach((numPri) => {
                        if (numPri === ecf.VALUE) {
                            // product.numLinhaPri.push(ecf.NumLinha);
                            product.tipoDocPri.push(ecf.TipoDoc);
                            product.seriePri.push(ecf.Serie);
                        }
                    })
                } else {
                    if (product.numOrderPri === ecf.VALUE) {
                        // product.numLinhaPri = ecf.NumLinha;
                        product.tipoDocPri = ecf.TipoDoc;
                        product.seriePri = ecf.Serie;
                    }
                }
            })
            console.log(product);
            this.validateButtonCreateVFA();
        },
        validateECFOption(numOrders, ECFValue, numLine, ECFNumLine) {
            let count = 0;
            console.log(numLine);
            numOrders.forEach((numOrder, index) => {
                if (numOrder === ECFValue && numLine[index] === ECFNumLine) {
                    count++;
                }
            })
            return count;
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.currentECF = ''
            this.selectedHeader = col;
        },
        incrementQuantity(obj) {
            obj.quantATrans += 1

            return obj
        },
        decrementQuantity(obj) {
            if (obj.quantATrans > 1)
                obj.quantATrans -= 1

            return obj
        },
        showDialogMatchInvoice() {
            this.invoiceMatch = '';
            this.dialogMatchInvoice = true;
        },
        numberOnly(event) {
            if (event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57)) {
                event.preventDefault();
            }
        },
        matchInvoice() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/pick/match-pick/' + this.invoiceMatch,
                data: {
                    products: this.productsOriginal
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;

                        if (responseData.status === 'OK') {
                            this.notFoundProducts = responseData.notFoundProducts;
                            this.missingProducts = responseData.missingProducts;
                            this.matchedProducts = responseData.matchedProducts;
                            this.matchingProducts = true;
                            this.dialogMatchInvoice = false;
                        } else {
                            if (response.status == 204)
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Não existem mais produtos para picar com o numero de fatura indicado!',
                                    type: 'warning'
                                })
                            else
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Não foi possivel fazer match com a fatura!',
                                    type: 'error'
                                })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Não foi possivel fazer match com a fatura!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        }
    }
}
</script>
