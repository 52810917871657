<style scoped>
    .dropzone {
        min-height: 120px;
    }

    .vue-dropzone {
        border: 2px solid #e5e5e5;
    }

    .vue-dropzone:hover {
        background-color: #f6f6f6;
        border: 2px dashed green;
    }
</style>

<template>
    <div>
        <v-card tile min-height="300">
            <v-card-text>
                <v-row>
                    <v-col cols="12" md="7">
                        <v-card v-if="Object.keys(product).length > 0">
                            <v-card-text>
                                <v-btn color="primary" fab small absolute right @click="toggleDialogEditBasicSetting()">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <v-row v-if="writePermission('products/product_basicSettings')">
                                    <v-col>
                                        <p class="mb-2 text-uppercase font-weight-bold">Visibilidade</p>
                                        <v-btn tile small  :color="product.CDU_B2B_SYNC === 1 ? 'success' : 'error'" @click="updateVisibility(product.Artigo, 'B2B', product.CDU_B2B_SYNC)">
                                            B2B
                                        </v-btn>
                                        <v-btn tile small  :color="product.CDU_SHOP_SYNC === 1 ? 'success' : 'error'" @click="updateVisibility(product.Artigo, 'STORE', product.CDU_SHOP_SYNC)">
                                            STORE
                                        </v-btn>
                                        <v-btn tile small  :color="product.CDU_FOX_SYNC === 1 ? 'success' : 'error'" @click="updateVisibility(product.Artigo, 'FOX', product.CDU_FOX_SYNC)">
                                            FOX
                                        </v-btn>
                                        <v-btn tile small  :color="product.CDU_B2C_SYNC === 1 ? 'success' : 'error'" @click="updateVisibility(product.Artigo, 'B2C', product.CDU_B2C_SYNC)">
                                            B2C
                                        </v-btn>
                                        <v-btn tile small  v-if="$writePermission('products/products_nozama')" :color="product.CDU_NOZAMA_SYNC === 1 ? 'success' : 'error'" @click="updateVisibility(product.Artigo, 'NOZAMA', product.CDU_NOZAMA_SYNC)">
                                            NOZAMA
                                        </v-btn>
                                        <v-btn tile small  :color="product.CDU_BIKEPLANET_SYNC === 1 ? 'success' : 'error'" @click="updateVisibility(product.Artigo, 'BIKEPLANET', product.CDU_BIKEPLANET_SYNC)">
                                            BIKEPLANET
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="4" md="4">
                                        <div><strong>Marca: </strong>{{ product.Marca }}</div>
                                        <div><strong>Família: </strong>{{ product.Familia }}</div>
                                        <div><strong>SubFamília: </strong>{{ product.SubFamilia }}</div>
                                        <div><strong>Ano Modelo: </strong>{{ product.Ano }}</div>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4" v-if="product.TratamentoDim != 1">
                                        <div>
                                            <v-btn icon small
                                                   @click="printLabel(product.Artigo, 'product')">
                                                <v-icon small>print</v-icon>
                                            </v-btn>
                                            <v-btn icon small
                                                   @click="printLabel(product.Artigo, 'product', 'big')">
                                              <v-icon medium>print</v-icon>
                                            </v-btn>
                                            <strong>{{ $t('product') }}: </strong>{{ product.Artigo }}
                                        </div>
                                        <div>
                                            <v-btn icon small
                                                   @click="printLabel(product.Artigo, 'barcode')">
                                                <v-icon small>print</v-icon>
                                            </v-btn>
                                          <v-btn icon small
                                                 @click="printLabel(product.Artigo, 'barcode', 'big')">
                                            <v-icon medium>print</v-icon>
                                          </v-btn>
                                            <strong>Cód. Barras: </strong>{{ product.CodBarras }}
                                        </div>
                                        <div>
                                            <v-btn  icon small :disabled="product.CDU_EAN == null"
                                                   @click="printLabel(product.Artigo, 'ean')">
                                              <v-icon small>print</v-icon>
                                            </v-btn>
                                          <v-btn  icon small :disabled="product.CDU_EAN == null"
                                                  @click="printLabel(product.Artigo, 'ean', 'big')">
                                            <v-icon medium>print</v-icon>
                                          </v-btn>
                                            <strong>EAN-13: </strong>{{ product.CDU_EAN }}</div>
                                        <div>
                                            <v-btn icon small
                                                   @click="printLabel(product.Artigo, 'refSupplier')">
                                                <v-icon small>print</v-icon>
                                            </v-btn>
                                            <v-btn icon small
                                                   @click="printLabel(product.Artigo, 'refSupplier', 'big')">
                                              <v-icon medium>print</v-icon>
                                            </v-btn>
                                            <strong>Ref. Fornecedor: </strong>{{ product.CDU_REFFORNECEDOR }}
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="4" md="4">
                                        <div><strong>PVP1: </strong>{{ $money(product.PVP1) }}</div>
                                        <div><strong>PVP2: </strong>{{ $money(product.PVP2) }}</div>
                                        <div><strong>PVP_PT: </strong>{{ $money(product.CDU_PVP_PT) }}</div>
                                        <div><strong>PVP_ES: </strong>{{ $money(product.CDU_PVP_ES) }}</div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="5">

                        <vue-dropzone v-if="writePermission('products/product_images')" ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>

                        <v-row>
                            <v-col class="text-right">
                                <v-btn tile small  color="primary" @click="toggleDialogImage()">Obter Imagem</v-btn>
                            </v-col>
                        </v-row>

                        <v-row>

                            <v-col cols="6" sm="4" md="4" lg="3" v-for="image in images" :key="image.Id">
                                <div>
                                    <v-btn tile  x-small block v-if="image.TratamentoDim !== 2" :color="image.Tipo === 'IPR' && image.TratamentoDim === 0 ? 'primary' : ''" @click="defineCover(image.Id)">
                                        PRINCIPAL
                                    </v-btn>
                                    <div style="position: relative;">
                                        <v-img
                                                :src="'https://static.bicimax.com/thumbs/'+image.File"
                                                style="border: 1px darkgray solid; cursor: pointer"
                                                @click="showImage(image)"
                                                contain
                                        ></v-img>
                                        <v-btn tile  x-small
                                               style="position: absolute; bottom: 1px; right: 1px;"
                                               @click="deleteImage(image.Id)">
                                            <i class="fas fa-times red--text"></i>
                                        </v-btn>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <!-- DIALOGS -->
        <v-row justify="center">
            <v-dialog v-model="dialogShowImage" max-width="870">
                <v-img
                        :src="'https://static.bicimax.com/anexos/'+imageSelected.File"
                        max-height="870"
                        max-width="870"
                        contain
                ></v-img>
            </v-dialog>
            <v-dialog persistent v-model="dialogEditBasicSetting" max-width="900">
                <staff-dialog :title="$t('change')+' '+$t('product')">
                    <template slot="content">
                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="editProduct.Marca"
                                        dense
                                        outlined
                                        label="Marca"
                                        :items="brands"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                ></v-select>
                                <v-select
                                        v-model="editProduct.Familia"
                                        dense
                                        outlined
                                        label="Família"
                                        @change="changeFamily()"
                                        :items="families"
                                        item-value="Familia"
                                        item-text="Familia"
                                ></v-select>
                                <v-select
                                        v-model="editProduct.SubFamilia"
                                        dense
                                        outlined
                                        label="SubFamília"
                                        :items="subFamilies"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                ></v-select>
                                <v-text-field
                                        v-model="editProduct.Ano"
                                        dense
                                        outlined
                                        label="Ano Modelo"
                                        hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col v-if="editProduct.TratamentoDim != 1">
                                <v-text-field
                                        v-model="editProduct.CodBarras"
                                        dense
                                        outlined
                                        clearable
                                        label="Código Barras"
                                ></v-text-field>
                                <v-text-field
                                        v-model="editProduct.CDU_EAN"
                                        dense
                                        outlined
                                        clearable
                                        label="EAN-13"
                                ></v-text-field>
                                <v-text-field
                                        v-model="editProduct.CDU_REFFORNECEDOR"
                                        dense
                                        outlined
                                        clearable
                                        label="Ref Fornecedor"
                                ></v-text-field>

                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="editProduct.PVP1"
                                        dense
                                        outlined
                                        label="PVP1"
                                        append-outer-icon="euro"
                                ></v-text-field>
                                <v-text-field
                                        v-model="editProduct.PVP2"
                                        dense
                                        outlined
                                        label="PVP2"
                                        append-outer-icon="euro"
                                ></v-text-field>
                                <v-text-field
                                        v-model="editProduct.CDU_PVP_PT"
                                        dense
                                        outlined
                                        label="PVP_PT"
                                        append-outer-icon="euro"
                                ></v-text-field>
                                <v-text-field
                                        v-model="editProduct.CDU_PVP_ES"
                                        dense
                                        outlined
                                        label="PVP_ES"
                                        append-outer-icon="euro"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    <template slot="action">
                        <v-btn @click="dialogEditBasicSetting = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="saveBasicSettings()">Guardar</v-btn>
                    </template>
                </staff-dialog>
            </v-dialog>

            <v-dialog persistent v-model="dialogGetImage" max-width="400">
                <v-card>
                    <v-card-title>
                        Obter Imagem do Artigo
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                            v-model="productToGetImage"
                            outlined
                            dense
                            label="Código do Artigo"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogGetImage = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="imageTransfer(true)">Inserir e Apagar</v-btn>
                        <v-btn color="success" @click="imageTransfer(false)">Inserir</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogPrint" width="400">
                <v-card>
                    <v-toolbar dense dark color="info">
                        <v-toolbar-title class="text-uppercase">{{ $t('quantity') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogPrint = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row class="mt-4">
                            <v-col offset="4" cols="3">
                                <v-text-field
                                        v-model.number="labelQuantity"
                                        hide-details
                                        type="number"
                                        min="1"
                                        max="5000"
                                        label="Quantidade"
                                        @change="limitNumber()"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogPrint=false">
                            Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="primary" @click="printLabelCall()">
                            Imprimir
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        props: {
            productId: String,
            banner: Object,
        },
        components: {
            vueDropzone: vue2Dropzone
        },
        data: function () {
            return {
                labelOption: null,
                labelProduct: null,
                labelSize: null,
                labelQuantity: 1,
                dialogPrint: false,
                product: {},
                images: [],
                dialogShowImage: false,
                imageSelected: '',
                dropzoneOptions: {
                    url: AppModel.getters.getUrlAPI + '/api/staff/products/' + this.productId + '/images',
                    thumbnailWidth: 150,
                    maxFilesize: 32,
                    acceptedFiles: "image/png,image/jpeg",
                    headers: {"Authorization": "Bearer " + User.getters.getAccessToken},
                    init: function () {

                    }
                },
                imageOver: '',
                dialogEditBasicSetting: false,
                editProduct: {},
                brands: [],
                families: [],
                subFamilies: [],
                dialogGetImage: false,
                productToGetImage: ''
            }
        },
        mounted() {
            this.getProduct()
        },
        methods: {
            getProduct() {
                let request = {
                    url: api.products() + '/' + this.productId
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.product = responseData.product
                            this.images = responseData.images
                            this.brands = responseData.brands
                            this.families = responseData.families
                            this.subFamilies = responseData.subFamilies
                        }
                    })
                    .catch(response => {

                    })
            },
            showImage(image) {
                this.imageSelected = image
                this.dialogShowImage = true
            },
            defineCover(idImage) {
                let request = {
                    url: api.products() + '/' + this.productId + '/imagesCover',
                    data: {
                        imageId: idImage
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.images = responseData.images
                        }
                    })
                    .catch(response => {

                    })
            },
            deleteImage(imageId) {
                let request = {
                    url: api.products() + '/' + this.productId + '/deleteImage',
                    data: {
                        imageId: imageId
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.images = responseData.images
                        }
                    })
                    .catch(response => {

                    })
            },
            toggleDialogEditBasicSetting() {
                this.editProduct = Object.assign({}, this.product)
                this.dialogEditBasicSetting = true
            },
            changeFamily() {
                this.editProduct.SubFamilia = null
                let request = {
                    url: api.productsSubFamilies() + 'ByFamily',
                    data: {
                        family: this.editProduct.Familia
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.subFamilies = responseData.subFamilies
                        }
                    })
                    .catch(response => {

                    })
            },
            saveBasicSettings() {
                let request = {
                    url: api.products() + '/' + this.productId + '/basicSettings',
                    data: {
                        product: this.editProduct
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.getProduct()
                            this.dialogEditBasicSetting = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao atualizar o artigo!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            writePermission(permission) {
                return User.getters.writePermission(permission)
            },
            updateVisibility(product, option, visibility) {
                let request = {
                    url: api.productsChangeVisibility(),
                    data: {
                        products: [
                            product
                        ],
                        visibility: {
                            'B2B': option === 'B2B',
                            'B2C': option === 'B2C',
                            'FOX': option === 'FOX',
                            'STORE': option === 'STORE',
                            'NOZAMA': option === 'NOZAMA',
                            'BIKEPLANET': option === 'BIKEPLANET'
                        },
                        option: visibility === 1 ? 'hide' : 'show'
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Visibilidade alterada com sucesso!',
                                type: 'success'
                            })
                            this.getProduct()
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao alterar visibilidade!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro servidor!',
                            type: 'error'
                        })
                    })
            },
            downloadImg(fileId) {
                let url = 'https://static.bicimax.com/anexos/' + fileId;
                fetch(url)
                    .then((response) => response.blob())
                    .then((blob) => {
                        saveAs(blob, 'image_name.jpg');
                    });
                console.log('downloading', url);
            },
            toggleDialogImage() {
                this.productToGetImage = '';
                this.dialogGetImage = true;
            },
            imageTransfer(apagar) {
                let loader = this.$loading.show();

                let request = {
                    url: '/api/staff/products/'+this.productId+'/imagesTransfer',
                    data: {
                        delete: apagar,
                        productToGetImage: this.productToGetImage
                    }
                }

                ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.images = responseData.images;
                        this.dialogGetImage = false;
                    }
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                })
            },
            printLabelCall() {
                let request = {
                    url: '/api/staff/products/printLabel',
                    data: {
                        product: this.labelProduct,
                        option: this.labelOption,
                        size: this.labelSize,
                        quantity: this.labelQuantity
                    }
                }
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Etiqueta enviada para a impressora!',
                                    type: 'success'
                                })
                                this.$nextTick(() => this.$refs.barcode.focus())
                                this.dialogPrint = false;
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro a imprimir etiqueta!',
                                    type: 'error'
                                })
                                this.dialogPrint = false;
                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            this.dialogPrint = false;
                        })
                this.labelOption = null;
                this.labelProduct = null;
                this.labelSize = null;
            },
            printLabel(product, option, size=null) {
                this.labelOption = option;
                this.labelProduct = product;
                this.labelSize = size;
                this.dialogPrint = true;
            },
            limitNumber() {
                if (this.labelQuantity < 1)
                    this.labelQuantity = 1
                else if (this.labelQuantity > 5000)
                    this.labelQuantity = 5000;
            }
        }
    }
</script>
