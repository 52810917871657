import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios"

Vue.use(Vuex)

const DropShipping = new Vuex.Store({
    actions: {
        getOrders(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    option: filters.option,
                    filters: filters.filters
                }

                axios.post(api.dropShippingOrders(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        createInvoice(context, order) {
            return new Promise((resolve, reject) => {
                let data = {
                    orderReference: order.orderReference,
                    option: order.option
                }

                axios.post(api.dropShippingCreateInvoice(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        cancelInvoice(context, order) {
            return new Promise((resolve, reject) => {
                let data = {
                    orderReference: order.orderReference,
                    option: order.option
                }

                axios.post(api.dropShippingCancelInvoice(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getPriceCourier(context, ship) {
            return new Promise((resolve, reject) => {
                let data = {
                    shipping: ship.shipping,
                    shipper: ship.shipper
                }

                axios.post(api.dropShippingGetPriceCourier(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        createShipping(context, ship) {
            return new Promise((resolve, reject) => {
                let data = {
                    shipping: ship.shipping,
                    shipper: ship.shipper
                }

                axios.post(api.dropShippingCreateShipping(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getHistoricDocs(context, orderReference) {
            return new Promise((resolve, reject) => {
                let data = {
                    orderReference: orderReference
                }

                axios.post(api.dropShippingHistoricDocs(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        downloadReturnLabel(context, trackingNumber) {
            return new Promise((resolve, reject) => {
                let data = {
                    trackingNumber: trackingNumber
                }

                axios.post(api.dropShippingDownloadReturnLabel(), data, {responseType: 'blob'})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    }
})

export default DropShipping
