<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="3">
                <page-title
                        title="Adicionar linhas"
                        :subtitle="'A mostrar ' + lines.length + ' débitos.'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <span class="subtitle-2 mb-0">Linhas Selecionadas: {{
                        selectedItems
                    }} | Valor Total: {{ $money(totalAmountDebits) }}</span>
                <v-select
                        v-if="totalDebits !== 0"
                        v-model="debitSelectedID"
                        :items="debits"
                        item-text="name"
                        item-value="id"
                        outlined
                        hide-details
                        class="staff_select_small ml-2 mb-2"
                        style="top: -4px; width: 160px; display: inline-block;"
                ></v-select>
                <v-btn tile small class="ml-2 mb-2" color="success" :disabled="selectedItems === 0" @click="addLines()">
                    Adicionar Linhas
                </v-btn>
                <v-btn tile small @click="getLines()" color="secondary" class="ml-2 mb-2">Atualizar</v-btn>
                <v-btn tile small @click="resetFilters()" color="error" class="ml-2 mb-2">Limpar Filtros</v-btn>
            </v-col>
        </v-row>

        <!-- DEBITS TABLE -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 24px;"></th>
                            <!--                    <th style="width: 60px;" class="text-center" @click="updateSort('Pais', 'string')">-->
                            <!--                        Pais <i class="fas fa-arrow-up" v-if="sort.field==='Pais' && !sort.reverse"></i>-->
                            <!--                        <i class="fas fa-arrow-down" v-if="sort.field==='Pais' && sort.reverse"></i>-->
                            <!--                    </th>-->
                            <th style="width: 80px;" @click="updateSort('Entidade', 'string')">
                                Entidade <i class="fas fa-arrow-up" v-if="sort.field==='Entidade' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='Entidade' && sort.reverse"></i>
                            </th>
                            <th @click="updateSort('Nome', 'string')">
                                Nome <i class="fas fa-arrow-up" v-if="sort.field==='Nome' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='Nome' && sort.reverse"></i>
                            </th>
<!--                            <th style="width: 80px;" @click="updateSort('ModoPag', 'string')">-->
<!--                                ModoPag <i class="fas fa-arrow-up" v-if="sort.field==='ModoPag' && !sort.reverse"></i>-->
<!--                                <i class="fas fa-arrow-down" v-if="sort.field==='ModoPag' && sort.reverse"></i>-->
<!--                            </th>-->
                            <th style="width: 80px;" @click="updateSort('ModoPagCabec', 'string')">
                                ModoPagCD <i class="fas fa-arrow-up"
                                             v-if="sort.field==='ModoPagCabec' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='ModoPagCabec' && sort.reverse"></i>
                            </th>
                            <th style="width: 70px;" @click="updateSort('TipoDoc', 'string')">
                                TipoDoc <i class="fas fa-arrow-up" v-if="sort.field==='TipoDoc' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='TipoDoc' && sort.reverse"></i>
                            </th>
                            <th style="width: 80px;" @click="updateSort('NumDoc', 'string')">
                                NumDoc <i class="fas fa-arrow-up" v-if="sort.field==='NumDoc' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='NumDoc' && sort.reverse"></i>
                            </th>
                            <th style="width: 70px;" @click="updateSort('DataDoc', 'string')">
                                DataDoc <i class="fas fa-arrow-up" v-if="sort.field==='DataDoc' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='DataDoc' && sort.reverse"></i>
                            </th>
                            <th style="width: 80px;" class="text-center" @click="updateSort('DataVenc', 'string')">
                                DataVenc <i class="fas fa-arrow-up" v-if="sort.field==='DataVenc' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='DataVenc' && sort.reverse"></i>
                            </th>
                            <th style="width: 90px;" class="text-center" @click="updateSort('ValorTotal', 'number')">
                                ValorTotal <i class="fas fa-arrow-up"
                                              v-if="sort.field==='ValorTotal' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='ValorTotal' && sort.reverse"></i>
                            </th>
                            <th style="width: 110px;" class="text-center"
                                @click="updateSort('ValorPendente', 'number')">
                                ValorPendente
                                <i class="fas fa-arrow-up" v-if="sort.field==='ValorPendente' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='ValorPendente' && sort.reverse"></i>
                            </th>
                            <th style="width: 60px;" class="text-center" @click="updateSort('Prest', 'number')">
                                Prest <i class="fas fa-arrow-up" v-if="sort.field==='Prest' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='Prest' && sort.reverse"></i>
                            </th>
                            <th style="width: 60px;" class="text-center">Dividir</th>
                            <th style="width: 100px;" class="text-center" @click="updateSort('Vendedor', 'string')">
                                Vendedor <i class="fas fa-arrow-up" v-if="sort.field==='Vendedor' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='Vendedor' && sort.reverse"></i>
                            </th>
                            <th style="width: 60px;" class="text-center" @click="updateSort('NC', 'number')">
                                NC <i class="fas fa-arrow-up" v-if="sort.field==='NC' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='NC' && sort.reverse"></i>
                            </th>
<!--                            <th class="text-center">Tracking</th>-->
                            <th class="text-center">Nova Data</th>
                            <th class="text-center">Observações</th>
                            <th class="text-center">OBS Emissor</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="filters_row">
                            <td></td>
                            <!--                    <td>-->
                            <!--                        <v-text-field-->
                            <!--                                v-model="filters.Pais"-->
                            <!--                                outlined-->
                            <!--                                hide-details-->
                            <!--                                @change="updateFilters"-->
                            <!--                        ></v-text-field>-->
                            <!--                    </td>-->
                            <td>
                                <v-text-field
                                        v-model="filters.Entidade"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.Nome"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
<!--                            <td>-->
<!--                                <v-text-field-->
<!--                                        v-model="filters.ModoPag"-->
<!--                                        outlined-->
<!--                                        hide-details-->
<!--                                        @change="updateFilters"-->
<!--                                ></v-text-field>-->
<!--                            </td>-->
                            <td>
                                <v-text-field
                                        v-model="filters.ModoPagCabec"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.TipoDoc"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.NumDoc"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.DataDoc"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                        style="width: 90px"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.DataVenc"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                        style="width: 90px"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.ValorTotal"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.ValorPendente"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.Prest"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.Vendedor"
                                        outlined
                                        hide-details
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr v-if="loading">
                            <td colspan="18">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="line in lines"
                            :class="{'red lighten-4': line.NC !== 0 || line.Devolvido === 1, 'blue-grey lighten-3': line.selected}"
                            style="cursor: pointer;">
                            <td @click="line.selected = !line.selected" class="text-center">
                                <i v-if="line.selected" class="fas fa-arrow-right"></i>
                            </td>
                            <!--                    <td @click="line.selected = !line.selected" class="text-center">{{ line.Pais }}</td>-->
                            <td @click="line.selected = !line.selected" style="white-space: nowrap">
                                <a :href="'/clients/clients/'+line.Entidade" target="_blank" class="ml-1"><i
                                        class="fas fa-fw fa-external-link-alt"></i></a>
                                {{ line.Entidade }}
                            </td>
                            <td @click="line.selected = !line.selected">
                                <v-tooltip left color="black" v-if="line.Devolvido > 0">
                                    <template v-slot:activator="{ on }">
                                        <i class="far fa-calendar-alt" style="color: red" v-on="on"></i>
                                    </template>
                                    <span>
                                <strong>ÚLTIMA TENTATIVA DE COBRANÇA</strong><hr>
                                <strong>Data: </strong> {{ line.OLD_DATE }} <br>
                                <strong>Motivo: </strong> {{ line.Motivo }} <br>
                                <strong>IBAN: </strong> {{ line.OLD_IBAN }} <br>
                                <strong>BIC: </strong> {{ line.OLD_BIC }}
                            </span>
                                </v-tooltip>
                                {{ line.Nome }}
                                <v-tooltip top v-if="line.SWIFT.length < 6">
                                    <template v-slot:activator="{ on }">
                                        <i class="fas fa-exclamation-triangle" style="color: red" v-on="on"></i>
                                    </template>
                                    <span>Cliente não têm BIC/SWIF</span>
                                </v-tooltip>
                            </td>

<!--                            <td @click="line.selected = !line.selected">-->
<!--                                {{ line.ModoPag }}-->
<!--                                <v-tooltip top color="black"-->
<!--                                           v-if="line.ModoPag === 'DEBDI' && ( line.IBAN.length !== 24 || (line.SWIFT.length < 8 || line.SWIFT.length > 11))">-->
<!--                                    <template v-slot:activator="{ on }">-->
<!--                                        <i class="fas fa-exclamation-triangle" style="color: red" v-on="on"></i>-->
<!--                                    </template>-->
<!--                                    <span>IBAN: {{ line.IBAN }} <i v-if="line.IBAN.length !== 24"-->
<!--                                                                   class="fas fa-exclamation-triangle"-->
<!--                                                                   style="color: red"></i> </span><br>-->
<!--                                    <span>SWIFT: {{ line.SWIFT }} <i-->
<!--                                            v-if="line.SWIFT.length < 8 || line.SWIFT.length > 11"-->
<!--                                            class="fas fa-exclamation-triangle" style="color: red"></i></span>-->
<!--                                </v-tooltip>-->
<!--                            </td>-->
                            <td @click="line.selected = !line.selected">
                                {{ line.ModoPagCabec }}
                                <v-tooltip top color="black"
                                           v-if="line.ModoPagCabec === 'DEBDI' && ( line.IBAN.length !== 24 || (line.SWIFT.length < 8 || line.SWIFT.length > 11))">
                                    <template v-slot:activator="{ on }">
                                        <i class="fas fa-exclamation-triangle" style="color: red" v-on="on"></i>
                                    </template>
                                    <span>IBAN: {{ line.IBAN }} <i v-if="line.IBAN.length !== 24"
                                                                   class="fas fa-exclamation-triangle"
                                                                   style="color: red"></i> </span><br>
                                    <span>SWIFT: {{ line.SWIFT }} <i
                                            v-if="line.SWIFT.length < 8 || line.SWIFT.length > 11"
                                            class="fas fa-exclamation-triangle" style="color: red"></i></span>
                                </v-tooltip>
                            </td>
                            <td @click="line.selected = !line.selected">{{ line.TipoDoc }}</td>
                            <td @click="line.selected = !line.selected">{{ line.NumDoc }}</td>
                            <td @click="line.selected = !line.selected">{{ line.DataDoc }}</td>
                            <td @click="line.selected = !line.selected" class="text-center">{{ line.DataVenc }}</td>
                            <td @click="line.selected = !line.selected" class="text-right">{{
                                    $money(line.ValorTotal)
                                }}
                            </td>
                            <td @click="line.selected = !line.selected" class="text-right">{{
                                    $money(line.ValorPendente)
                                }}
                            </td>
                            <td @click="line.selected = !line.selected" class="text-center">{{ line.NumPrestacao }}</td>
                            <td class="text-center">
                                <v-btn-toggle v-model="line.divide" mandatory color="black">
                                    <v-btn value="1" x-small style="min-width: 20px; padding: 0 6px;">1</v-btn>
                                    <v-btn value="2" x-small style="min-width: 20px; padding: 0 6px;">2</v-btn>
                                    <v-btn value="3" x-small style="min-width: 20px; padding: 0 6px;">3</v-btn>
                                </v-btn-toggle>
                            </td>
                            <td @click="line.selected = !line.selected" style="text-align: center">{{ line.Vendedor }}</td>
                            <td @click="line.selected = !line.selected" class="text-center">
                                <v-tooltip color="black" top v-if="line.NC === 1">
                                    <template v-slot:activator="{ on }">
                                        <i class="fas fa-exclamation-triangle" style="color: red" v-on="on"></i>
                                    </template>
                                    <span>Este Cliente tem uma Nota de Crédito</span>
                                </v-tooltip>
                            </td>
<!--                            <td>-->
<!--                                <v-icon small v-if="line.Tracking === '' && line.loading === 0"-->
<!--                                        @click="getTracking(line)">search-->
<!--                                </v-icon>-->
<!--                                <v-icon small v-if="line.loading === 1">fas fa-circle-notch fa-spin</v-icon>-->
<!--                                <span v-else>{{ line.Tracking }}</span>-->
<!--                            </td>-->
                            <td>{{ line.NEW_DATE }}</td>
                            <td>{{ line.OBSERVATION }}</td>
                            <td>{{ line.OBS_DOC }}</td>
                            <td>
                                <v-btn x-small tile color="primary" @click="toggleDialogEdit(line)">Editar</v-btn>
                            </td>
                        </tr>
                        <tr v-if="!loading && lines.length === 0">
                            <td colspan="17">Sem resultados</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialogEdit" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Editar Pendente
                    </v-card-title>
                    <v-card-text>
                        <v-menu
                                v-model="menuDatePicker"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        label="Data"
                                        v-model="inputEdit.newDate"
                                        prepend-icon="event"
                                        readonly
                                        v-on="on"
                                        dense
                                        outlined
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="inputEdit.newDate" @input="menuDatePicker = false"
                                           :locale="locale"></v-date-picker>
                        </v-menu>
                        <v-text-field
                                v-model="inputEdit.observation"
                                label="Observações"
                                outlined
                                dense
                                hide-details
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogEdit = false">
                            Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="saveEdit" color="success" :loading="loading">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: () => ({
        loading: false,
        lines: [],
        totalLines: 0,
        totalDebits: 0,
        debits: [],
        debitSelectedID: '',
        filters: {
            TipoDoc: 'FA',
            Vendedor: '',
            ModoPagCabec: 'DEBDI',
            NumDoc: '',
            DataDoc: '',
            Entidade: '',
            Nome: '',
            Pais: 'ES',
            DataVenc: '',
            ValorTotal: '',
            ValorPendente: '',
            Prest: ''
        },
        originLines: [],
        sort: {
            field: 'DataVenc',
            reverse: true,
            type: 'string'
        },
        menuDatePicker: false,
        inputEdit: {
            id: '',
            newDate: '',
            observation: ''
        },
        dialogEdit: false
    }),
    created() {
        document.title = 'Adicionar Linhas - Débitos'
        this.getLines()
    },
    methods: {
        getLines() {
            this.loading = true;
            this.filters.DataDoc = this.currentDate();

            let request = {
                url: api.debitsLines(),
            };
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.lines = responseData.pending;
                            this.totalLines = responseData.pending.length;
                            this.totalDebits = responseData.debits.length;
                            this.debits = responseData.debits;
                            this.originLines = this.lines;
                            if (this.debits.length > 0) {
                                this.debitSelectedID = this.debits[0].id;
                                this.filters.DataVenc = this.debits[0].charge_date
                            }
                            this.updateFilters();
                            this.lines.sortAttr(this.sort.field, this.sort.reverse, this.sort.type)
                        }
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
        },
        updateFilters() {
            this.lines = this.originLines
            if (this.filters.TipoDoc !== '') {
                this.lines = this.lines.filter(line => line.TipoDoc.toUpperCase() === this.filters.TipoDoc.toUpperCase())
            }
            if (this.filters.Vendedor !== '') {
                this.lines = this.lines.filter(line => line.Vendedor != null && line.Vendedor.toLowerCase().includes(this.filters.Vendedor.toLowerCase()))
            }
            // if (this.filters.ModoPag !== '') {
            //     this.lines = this.lines.filter(line => line.ModoPag.toUpperCase() === this.filters.ModoPag.toUpperCase())
            // }
            if (this.filters.ModoPagCabec !== '') {
                this.lines = this.lines.filter(line => line.ModoPagCabec.toUpperCase() === this.filters.ModoPagCabec.toUpperCase())
            }
            if (this.filters.NumDoc !== '') {
                this.lines = this.lines.filter(line => line.NumDoc.includes(this.filters.NumDoc))
            }
            if (this.filters.DataDoc !== '') {
                this.lines = this.lines.filter(line => line.DataDoc < this.filters.DataDoc)
            }
            if (this.filters.Entidade !== '') {
                this.lines = this.lines.filter(line => line.Entidade.toUpperCase().includes(this.filters.Entidade.toUpperCase()))
            }
            if (this.filters.Nome !== '') {
                this.lines = this.lines.filter(line => line.Nome.toLowerCase().includes(this.filters.Nome.toLowerCase()))
            }
            if (this.filters.Pais !== '') {
                this.lines = this.lines.filter(line => line.Pais.toUpperCase().includes(this.filters.Pais.toUpperCase()))
            }
            if (this.filters.DataVenc !== '') {
                this.lines = this.lines.filter(line => line.DataVenc < this.filters.DataVenc)
            }
            if (this.filters.ValorTotal !== '') {
                this.lines = this.lines.filter(line => line.ValorTotal === this.filters.ValorTotal)
            }
            if (this.filters.ValorPendente !== '') {
                this.lines = this.lines.filter(line => line.ValorPendente === this.filters.ValorPendente)
            }
            if (this.filters.Prest !== '') {
                this.lines = this.lines.filter(line => line.NumPrestacao.toString() === this.filters.Prest)
            }
            this.updateSort(this.sort.field, this.sort.type, false);
        },
        addLines() {
            var selectedItems = []
            this.lines.forEach(function (line) {
                if (line.selected) {
                    selectedItems.push({id: line.IdHistorico, divide: line.divide})
                }
            })
            // var lines_ids = JSON.stringify(selectedItems)

            if (selectedItems.length > 0) {
                this.loading = true
                let request = {
                    url: api.debitsLinesAdd(),
                    data: {
                        file_id: this.debitSelectedID,
                        lines: selectedItems
                    }
                }
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.lines = responseData.pending
                                this.totalLines = responseData.pending.length
                                this.totalDebits = responseData.debits.length
                                this.debits = responseData.debits
                                this.originLines = this.lines
                                if (this.debits.length > 0) {
                                    this.debitSelectedID = this.debits[0].id
                                }
                                this.updateFilters()
                                this.lines.sortAttr(this.sort.field, this.sort.reverse, this.sort.type)
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: responseData.lines + ' linhas adicionadas com sucesso!',
                                    type: 'success'
                                })
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao adicionar linhas',
                                    type: 'error'
                                })
                            }
                            this.loading = false
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            this.loading = false
                        })
            }
        },
        currentDate() {
            var today = new Date()
            var dd = today.getDate()
            var mm = today.getMonth() + 1
            var yyyy = today.getFullYear()
            if (dd < 10) {
                dd = '0' + dd
            }
            if (mm < 10) {
                mm = '0' + mm
            }

            return yyyy + '-' + mm + '-' + dd
        },
        updateSort(field, type, reverse = true) {
            if (reverse) {
                if (this.sort.field === field) {
                    this.sort.reverse = !this.sort.reverse
                }
            }
            this.sort.field = field
            this.sort.type = type

            this.lines.sortAttr(this.sort.field, this.sort.reverse, this.sort.type)
        },
        resetFilters() {
            this.filters = {
                TipoDoc: 'FA',
                Vendedor: '',
                // ModoPag: 'DEBDI',
                NumDoc: '',
                DataDoc: '',
                Entidade: '',
                Nome: '',
                Pais: 'ES',
                DataVenc: '',
                ValorTotal: '',
                ValorPendente: '',
                Prest: ''
            };
            this.getLines()
        },
        getTracking(line) {
            line.loading = 1;

            let request = {
                url: '/api/staff/debits/lines/tracking',
                data: {
                    TipoDoc: line.TipoDoc,
                    NumDoc: line.NumDoc,
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            line.Tracking = responseData.tracking;
                        }
                        line.loading = 0;
                    })
                    .catch(() => {
                        line.loading = 0;
                    })
        },
        saveEdit() {
            this.loading = true;
            let request = {
                url: '/api/staff/debits/updatePendent',
                data: {
                    id: this.inputEdit.id,
                    date: this.inputEdit.newDate,
                    observation: this.inputEdit.observation
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.lines = responseData.pending;
                            this.totalLines = responseData.pending.length;
                            this.originLines = this.lines;
                            this.updateFilters();
                            this.lines.sortAttr(this.sort.field, this.sort.reverse, this.sort.type);
                            this.dialogEdit = false;
                        }
                        this.loading = false;
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loading = false;
                    })
        },
        toggleDialogEdit(line) {
            this.inputEdit.id = line.IdHistorico;
            this.inputEdit.newDate = line.NEW_DATE;
            this.inputEdit.observation = line.OBSERVATION;
            this.dialogEdit = true;
        }
    },
    computed: {
        selectedItems() {
            var selectedItem = 0;
            this.lines.forEach(function (line) {
                if (line.selected) {
                    selectedItem++
                }
            });
            return selectedItem
        },
        totalAmountDebits() {
            let amount = 0;
            this.lines.forEach(function (line) {
                if (line.selected) {
                    amount = amount + line.ValorPendente;
                }
            });
            return amount;
        },
        locale() {
            return (User.getters.getLanguage + '-' + User.getters.getLanguage).toLocaleLowerCase();
        }
    }
}
</script>
