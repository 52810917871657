<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Artigos com Problemas"
                        subtitle=""
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <!--                <v-btn tile color="primary" class="mr-4" @click="syncProduct()" v-if="writePermission('products/product')">-->
                <!--                    <i class="fas fa-sync mr-1"></i>Actualizar-->
                <!--                </v-btn>-->
                <!--                <v-btn tile color="error" :href="'https://b2b.bicimax.com/product?id='+productId" target="_blank">B2B-->
                <!--                    <i class="fas fa-external-link-alt ml-1"></i>-->
                <!--                </v-btn>-->
            </v-col>
        </v-row>

<!--        <ProblemsMenu :selected="''"></ProblemsMenu>-->
        <v-row>
            <v-col>
                {{ problem }}
                <v-btn small tile class="mr-2 mb-2"
                       :to="'/products/problems/refsDupes'"
                       :color="problem === 'refDupes' ? 'primary' : 'default'">Refs Fornecedor Duplicadas
                </v-btn>
                <v-btn small tile class="mr-2 mb-2"
                       :to="'/products/problems/refsNull'"
                       :color="problem === 'refNull' ? 'primary' : 'default'">Sem Ref Fornecedor
                </v-btn>
            </v-col>
        </v-row>

<!--        <RefsDupes v-if="problem === 'refsDupes'"></RefsDupes>-->
        <RefsDupes></RefsDupes>

    </v-container>
</template>

<script>
import RefsDupes from "../Problems/RefsDupes";

export default {
    components: {RefsDupes},
    data: () => ({
        problem: '',
    }),
    created() {
        document.title = 'Artigos com Problemas | STAFF';
        this.problem = this.$route.params.problem
    }
}
</script>
