<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title back title="Detalhe do débito" :subtitle="'A mostrar ' + lines.length + ' linhas'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn v-if="debit.B2BSEPAFileStatus_id === '1'" small color="error" class="ml-2 mb-2" @click="removeAll()">Remover todas as linhas</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>

                <v-card tile class="mb-2">
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col cols="6" :sm="4" :md="2" :lg="2">
                                <strong>ID:</strong>
                                <div>{{ debit.ID }}</div>
                            </v-col>
                            <v-col cols="6" :sm="4" :md="3" :lg="2">
                                <strong>Estado:</strong>
                                <div>{{ debit.B2BSEPAFileStatus_id }} - {{ debit.estado }}</div>
                            </v-col>
                            <v-col cols="6" :sm="4" :md="2" :lg="2">
                                <strong>Cobrança:</strong>
                                <div>{{ debit.name }}</div>
                            </v-col>
                            <v-col cols="6" :sm="4" :md="2" :lg="2">
                                <strong>Data:</strong>
                                <div>{{ debit.charge_date }}</div>
                            </v-col>
                            <v-col cols="6" :sm="4" :md="2" :lg="2">
                                <strong>ID do Débito:</strong>
                                <div>{{ debit.bank_dd || '-' }}</div>
                            </v-col>
                            <v-col cols="6" :sm="4" :md="2" :lg="2">
                                <strong>Valor:</strong>
                                <div>{{ debit.SUM }} €</div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 45px">ID</th>
                            <th style="width: 90px">DocOriginal</th>
                            <th style="width: 80px">Data Doc.</th>
                            <th>Estado</th>
                            <th style="width: 120px">BIC/SWIFT</th>
                            <th>IBAN</th>
                            <th>Cliente</th>
                            <th style="width: 90px" class="text-center">Valor Doc.</th>
                            <th style="width: 90px" class="text-center">Valor</th>
                            <th style="width: 1px">Prest</th>
                            <th style="width: 90px" class="text-center">Recibo</th>
                            <th style="width: 90px" class="text-center">Estorno</th>
                            <th style="width: 90px" class="text-center">Adiant.</th>
                            <th style="width: 1px" class="text-center">Email</th>
                            <th style="width: 1px" class="text-center">OK</th>
                            <th style="width: 1px" class="text-center">Email Estorno</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="12">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                        v-model="filters.id"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.docOrigin"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.dataDoc"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.status"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.SWIFT"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.iban"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.client"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.valueDoc"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.value"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.prest"
                                        outlined
                                        hide-details
                                        single-line
                                        style="width: 50px"
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.recibo"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.estorno"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.advance"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="updateFilters"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr v-for="line in lines" :class="{'red lighten-4': line.swift.length <= 6 || line.estado.substring(0,4) === 'PRIM' }">
                            <td>{{ line.id_line }}</td>
                            <td>{{ line.DocOriginal }}</td>
                            <td>{{ line.DataDoc.substring(0, 10) }}</td>
                            <td>{{ line.estado }}</td>
                            <td>
                                {{ line.swift }}
                                <i v-if="line.swift.length < 6" class="fas fa-exclamation-triangle" style="color: red"></i>
                            </td>
                            <td>{{ line.iban }}</td>
                            <td>{{ line.nome }}</td>
                            <td class="text-right">{{ $money(line.document_amount) }}</td>
                            <td class="text-right">{{ $money(line.amount_to_pay) }}</td>
                            <td class="text-center">{{ line.NumPrestacao }}</td>
                            <td class="text-center">
                                {{ line.recibo }}
                                <i v-if="line.recibo && line.estorno == 0 && debit.B2BSEPAFileStatus_id == 2" class="fas fa-times-circle fa-lg" style="color: red; cursor: pointer;" @click="estornoRecibo(line)"></i>
                            </td>
                            <td class="text-center">{{ line.estorno }}</td>
                            <td class="text-center">{{ line.adiantamento }}</td>
                            <td class="text-center">
                                <i v-if="line.email === 1" class="fas fa-lg fa-check" style="color: green"></i>
                                <i v-if="line.email !== 1" class="fas fa-lg fa-times" style="color: red"></i>
                            </td>
                            <td v-if="debit.B2BSEPAFileStatus_id !== '1'" class="text-center">
                                <i v-if="line.estado_id === '1' || line.estado_id === '124' || line.estado_id === '127'" class="fas fa-check fa-lg" style="color: green"></i>
                                <i v-if="line.estado_id !== '1' && line.estado_id !== '124' && line.estado_id !== '127'" class="fas fa-times fa-lg" style="color: red"></i>
                            </td>
                            <td v-if="debit.B2BSEPAFileStatus_id === '1'" class="text-center">
                                <v-btn small color="error" @click="remove(line.id_line)">Remover</v-btn>
                            </td>
                            <td class="text-center">
                                <v-btn small color="warning" v-if="writePermission('debits/debits') && line.estorno > 0" @click="sendEmail(line.id_line)">
                                    Reenviar
                                </v-btn>
                            </td>
                        </tr>
                        <tr v-if="lines.length === 0">
                            <td colspan="11">Sem resultados</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>

            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialogRemoveLine" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Eliminar Débito
                    </v-card-title>
                    <v-card-text>
                        Confirma que pretende eliminar a linha?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogRemoveLine = false">Cancelar</v-btn>
                        <v-btn color="success" style="margin-left: auto" @click="deleteLine()" ref="btnRemoveLine">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

    export default {
        data: () => ({
            loading: false,
            debitId: '',
            debit: {},
            lines: [],
            originLines: [],
            filters: {
                id: '',
                docOrigin: '',
                dataDoc: '',
                status: '',
                SWIFT: '',
                iban: '',
                client: '',
                valueDoc: '',
                value: '',
                prest: '',
                recibo: '',
                estorno: '',
                advance: ''
            },
            lineIdToRemove: '',
            dialogRemoveLine: false
        }),
        created() {
            this.debitId = this.$route.params.id
            this.getDebit()
        },
        methods: {
            getDebit() {
                let request = {
                    url: api.debitsDetail() + '/' + this.debitId
                }
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.debit = responseData.debit
                            this.lines = responseData.lines
                            this.originLines = this.lines
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            remove(idLine) {
                this.lineIdToRemove = idLine
                this.dialogRemoveLine = true
                this.$nextTick(() => this.$refs.btnRemoveLine.focus())
            },
            removeAll() {
                this.lineIdToRemove = 'ALL'
                this.dialogRemoveLine = true
                this.$nextTick(() => this.$refs.btnRemoveLine.focus())
            },
            deleteLine() {
                let request = {
                    url: api.debitsDeleteLine(),
                    data: {
                        id: this.debitId,
                        lineId: this.lineIdToRemove
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.debit = responseData.debit
                            this.lines = responseData.lines
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Linha removida com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao remover linha',
                                type: 'error'
                            })
                        }
                        this.dialogRemoveLine = false
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.dialogRemoveLine = false
                    })
            },
            updateFilters() {
                this.lines = this.originLines
                if (this.filters.id !== '') {
                    this.lines = this.lines.filter(line => line.id_line.includes(this.filters.id))
                }
                if (this.filters.docOrigin !== '') {
                    this.lines = this.lines.filter(line => line.DocOriginal.toUpperCase().includes(this.filters.docOrigin.toUpperCase()))
                }
                if (this.filters.dataDoc !== '') {
                    this.lines = this.lines.filter(line => line.DataDoc.toUpperCase().includes(this.filters.dataDoc.toUpperCase()))
                }
                if (this.filters.status !== '') {
                    this.lines = this.lines.filter(line => line.estado.includes(this.filters.status))
                }
                if (this.filters.SWIFT !== '') {
                    this.lines = this.lines.filter(line => line.swift.toUpperCase().includes(this.filters.SWIFT.toUpperCase()))
                }
                if (this.filters.iban !== '') {
                    this.lines = this.lines.filter(line => line.iban.toUpperCase().includes(this.filters.iban.toUpperCase()))
                }
                if (this.filters.client !== '') {
                    this.lines = this.lines.filter(line => line.nome.toUpperCase().includes(this.filters.client.toUpperCase()))
                }
                if (this.filters.valueDoc !== '') {
                    this.lines = this.lines.filter(line => line.document_amount.includes(this.filters.valueDoc))
                }
                if (this.filters.value !== '') {
                    this.lines = this.lines.filter(line => line.amount_to_pay.includes(this.filters.value))
                }
                if (this.filters.prest !== '') {
                    this.lines = this.lines.filter(line => line.NumPrestacao.includes(this.filters.prest))
                }
                if (this.filters.recibo !== '') {
                    this.lines = this.lines.filter(line => line.recibo == this.filters.recibo)
                }
                if (this.filters.estorno !== '') {
                    this.lines = this.lines.filter(line => line.estorno.includes(this.filters.estorno))
                }
                if (this.filters.advance !== '') {
                    this.lines = this.lines.filter(line => line.adiantamento.includes(this.filters.advance))
                }
            },
            sendEmail(idLine) {
                let request = {
                    url: api.debitsSendEmailEstorno() + '/' + idLine
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Email enviado com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao enviar email!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            writePermission(permission) {
                return User.getters.writePermission(permission)
            },
            estornoRecibo(line) {
                let request = {
                    url: '/api/staff/debits/estornoRecibo/' + line.id_line
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            line.estorno = responseData.message;
                            line.recibo = '0';
                            line.estado = 'MANU - Estorno manual';
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Estorno efetuado com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao efetuar estorno!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            }
        }
    }
</script>
