<template>
  <v-container fluid>
    <v-row class="content-header">
      <v-col>
        <page-title
            title="Famílias"
        ></page-title>
      </v-col>
      <v-col class="text-right" align-self="center">
        <v-btn tile small color="success" @click="toggleDialogCreateFamily()">{{ $t('add') }}</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table class="staff_table">
          <thead>
          <tr>
            <th>Família</th>
            <th>{{ $t('description') }}</th>
            <th>{{ $t('description') }} PT</th>
            <th>{{ $t('description') }} ES</th>
            <th>{{ $t('description') }} EN</th>
            <th>{{ $t('description') }} FR</th>
            <th>{{ $t('description') }} IT</th>
            <th>{{ $t('description') }} DE</th>
            <th>{{ $t('products') }} B2B</th>
            <th>{{ $t('products') }} Activos</th>
            <th>Total</th>
            <th></th>
            <th></th>
            <th v-if="$readPermission('products/families_specs')"></th>
            <th></th>
          </tr>
          <tr class="filters_row">
            <td>
              <v-text-field
                  v-model="filters.Familia"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.Descricao"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.DescricaoPT"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.DescricaoES"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.DescricaoEN"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.DescricaoFR"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.DescricaoIT"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.DescricaoDE"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-select
                  v-model="filters.ArtigosB2B"
                  outlined
                  dense
                  hide-details
                  :items="filtersYesNo"
                  item-text="TEXT"
                  item-value="VALUE"
                  @change="filterTable"
              ></v-select>
            </td>
            <td>
              <v-select
                  v-model="filters.Artigos"
                  outlined
                  dense
                  hide-details
                  :items="filtersYesNo"
                  item-text="TEXT"
                  item-value="VALUE"
                  @change="filterTable"
              ></v-select>
            </td>
            <td>
              <v-select
                  v-model="filters.Total"
                  outlined
                  dense
                  hide-details
                  :items="filtersYesNo"
                  item-value="VALUE"
                  item-text="TEXT"
                  @change="filterTable"
              ></v-select>
            </td>
            <td></td>
            <td v-if="$readPermission('products/families_specs')"></td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="12">
              <v-progress-linear indeterminate></v-progress-linear>
            </td>
          </tr>
          <tr v-for="family in families">
            <td>{{ family.Familia }}</td>
            <td>{{ family.Descricao }}</td>
            <td>{{ family.DescricaoPT }}</td>
            <td>{{ family.DescricaoES }}</td>
            <td>{{ family.DescricaoEN }}</td>
            <td>{{ family.DescricaoFR }}</td>
            <td>{{ family.DescricaoIT }}</td>
            <td>{{ family.DescricaoDE }}</td>
            <td>{{ family.ArtigosB2B }}</td>
            <td>{{ family.Artigos }}</td>
            <td>{{ family.Total }}</td>
            <td>
              <v-btn x-small @click="toggleDialogEditFamily(family)" color="secondary">Editar</v-btn>
            </td>
            <td v-if="$readPermission('products/families_descriptions')">
              <v-btn x-small @click="toggleDialogDescriptions(family)" color="error">Descriptions</v-btn>
            </td>
            <td v-if="$readPermission('products/families_specs')">
              <v-btn x-small @click="toggleDialogSpecs(family)" color="error">Specs</v-btn>
            </td>
            <td>
              <v-btn x-small :to="'/products/subFamilies/'+family.Familia" color="primary" style="width: 124px">
                {{ family.SubFamilias }} SubFamilias
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-dialog v-model="dialogEditFamily" persistent max-width="600">
          <staff-dialog :title="$t('change')+' Familia '+editFamily.Familia">
            <template slot="content">
              <v-row>
                <v-col>
                  <v-text-field
                      v-model="editFamily.DescricaoPT"
                      dense
                      outlined
                      :label="$t('description')+' PT'"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoEN"
                      dense
                      outlined
                      :label="$t('description')+' EN'"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoIT"
                      dense
                      outlined
                      :label="$t('description')+' IT'"
                      hide-details
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="editFamily.DescricaoES"
                      dense
                      outlined
                      :label="$t('description')+' ES'"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoFR"
                      dense
                      outlined
                      :label="$t('description')+' FR'"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoDE"
                      dense
                      outlined
                      :label="$t('description')+' DE'"
                      hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template slot="action">
              <v-btn @click="dialogEditFamily = false">Fechar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="saveDescriptionFamily()">Guardar</v-btn>
            </template>
          </staff-dialog>
        </v-dialog>

        <!-- TODO / Remove duplicated block -->

        <v-dialog v-model="dialogDescriptions" persistent max-width="500">
          <staff-dialog :title="'Descrições Familia '+editFamily.Familia">
            <template slot="content">
              <v-row>
                <v-col class="text-center" style="border-right: 1px solid black">Exportar</v-col>
                <v-col class="text-center">Importar</v-col>
              </v-row>
              <v-row>
                <v-col style="border-right: 1px solid black">
                  <v-btn color="success" small @click="exportTemplateDescriptions('PT')">Exportar Template
                    PT
                  </v-btn>
                </v-col>
                <v-col v-if="$writePermission('products/families_descriptions')">
                  <v-select
                      v-model="languageSpecs"
                      :items="['PT', 'ES', 'EN']"
                      outlined
                      label="Idioma"
                      dense
                      hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="border-right: 1px solid black">
                  <v-btn color="success" small @click="exportTemplateDescriptions('ES')">Exportar Template
                    ES
                  </v-btn>
                </v-col>
                <v-col v-if="$writePermission('products/families_descriptions')">
                  <v-text-field class="pt-0" label="Importar Descrições" @click='pickFile' v-model='fileName'
                                prepend-icon='attach_file' hide-details></v-text-field>
                  <input
                      type="file"
                      style="display: none"
                      ref="image"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      @change="onFilePicked"
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col style="border-right: 1px solid black">
                  <v-btn color="success" small @click="exportTemplateDescriptions('EN')">Exportar Template
                    EN
                  </v-btn>
                </v-col>
                <v-col></v-col>
              </v-row>
            </template>
            <template slot="action">
              <v-btn @click="dialogDescriptions = false">Fechar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="analyzeDescriptions()" :disabled="fileName === '' || languageSpecs === ''"
                     :loading="loadingButtonDescriptions" v-if="$writePermission('products/families_descriptions')">
                Importar
              </v-btn>
            </template>
          </staff-dialog>
        </v-dialog>

        <!-- After file submission -->
        <v-dialog v-model="dialogDescriptionsSubmit" persistent max-width="600">
          <staff-dialog :title="'Descrições Familia '+editFamily.Familia">
            <template slot="content">
              <template v-if="linesSpecs.length === 0">
                <v-row>
                  <v-col class="text-center">
                    <i class="far fa-check-circle fa-3x" style="color: green"></i>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">{{ $t('successSpecs') }}</v-col>
                </v-row>
              </template>
              <template v-if="linesSpecs.length > 0">
                <v-row>
                  <v-col>
                    {{ $t('errorSpecs') }}: {{ editFamily.Familia }} <br>
                    {{ $t('errorSpecs2') }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-simple-table class="staff_table">
                      <thead>
                      <tr>
                        <th>{{ $t('product') }}</th>
                        <th>{{ $t('description') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="line in linesSpecs">
                        <td>{{ line[0] }}</td>
                        <td>{{ line[1] }}</td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template slot="action">
              <v-spacer></v-spacer>
              <v-btn @click="dialogDescriptionsSubmit = false">Fechar</v-btn>
            </template>
          </staff-dialog>
        </v-dialog>
        <!-- END After file submission END -->

        <!--                                -->

        <v-dialog v-model="dialogSpecs" persistent max-width="500">
          <staff-dialog :title="'Specs Familia '+editFamily.Familia">
            <template slot="content">
              <v-row>
                <v-col class="text-center" style="border-right: 1px solid black">Exportar</v-col>
                <v-col class="text-center">Importar</v-col>
              </v-row>
              <v-row>
                <v-col style="border-right: 1px solid black">
                  <v-btn color="success" small @click="exportTemplateSpecs('PT')">Exportar Template
                    PT
                  </v-btn>
                </v-col>
                <v-col v-if="$writePermission('products/families_specs')">
                  <v-select
                      v-model="languageSpecs"
                      :items="['PT', 'ES', 'EN']"
                      outlined
                      label="Idioma"
                      dense
                      hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col style="border-right: 1px solid black">
                  <v-btn color="success" small @click="exportTemplateSpecs('ES')">Exportar Template
                    ES
                  </v-btn>
                </v-col>
                <v-col v-if="$writePermission('products/families_specs')">
                  <v-text-field class="pt-0" label="Importar Specs" @click='pickFile' v-model='fileName'
                                prepend-icon='attach_file' hide-details></v-text-field>
                  <input
                      type="file"
                      style="display: none"
                      ref="image"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      @change="onFilePicked"
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col style="border-right: 1px solid black">
                  <v-btn color="success" small @click="exportTemplateSpecs('EN')">Exportar Template
                    EN
                  </v-btn>
                </v-col>
                <v-col></v-col>
              </v-row>
            </template>
            <template slot="action">
              <v-btn @click="dialogSpecs = false">Fechar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="analyzeSpecs()" :disabled="fileName === '' || languageSpecs === ''"
                     :loading="loadingButtonSpecs" v-if="$writePermission('products/families_specs')">
                Analisar
              </v-btn>
            </template>
          </staff-dialog>
        </v-dialog>
        <v-dialog v-model="dialogSpecsSubmit" persistent max-width="600">
          <staff-dialog :title="'Specs Familia '+editFamily.Familia">
            <template slot="content">
              <template v-if="linesSpecs.length === 0">
                <v-row>
                  <v-col class="text-center">
                    <i class="far fa-check-circle fa-3x" style="color: green"></i>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-center">{{ $t('successSpecs') }}</v-col>
                </v-row>
              </template>
              <template v-if="linesSpecs.length > 0">
                <v-row>
                  <v-col>
                    {{ $t('errorSpecs') }}: {{ editFamily.Familia }} <br>
                    {{ $t('errorSpecs2') }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-simple-table class="staff_table">
                      <thead>
                      <tr>
                        <th>{{ $t('product') }}</th>
                        <th>{{ $t('description') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="line in linesSpecs">
                        <td>{{ line[0] }}</td>
                        <td>{{ line[1] }}</td>
                      </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template slot="action">
              <v-spacer></v-spacer>
              <v-btn @click="dialogSpecsSubmit = false">Fechar</v-btn>
            </template>
          </staff-dialog>
        </v-dialog>
        <v-dialog v-model="dialogCreateFamily" persistent max-width="600">
          <staff-dialog :title="$t('add')+' Familia'">
            <template slot="content">
              <v-row>
                <v-col>
                  <v-text-field
                      v-model="editFamily.Familia"
                      dense
                      outlined
                      label="Familia"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoPT"
                      dense
                      outlined
                      :label="$t('description')+' PT'"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoEN"
                      dense
                      outlined
                      :label="$t('description')+' EN'"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoIT"
                      dense
                      outlined
                      :label="$t('description')+' IT'"
                      hide-details
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      v-model="editFamily.Descricao"
                      dense
                      outlined
                      :label="$t('description')"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoES"
                      dense
                      outlined
                      :label="$t('description')+' ES'"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoFR"
                      dense
                      outlined
                      :label="$t('description')+' FR'"
                  ></v-text-field>
                  <v-text-field
                      v-model="editFamily.DescricaoDE"
                      dense
                      outlined
                      :label="$t('description')+' DE'"
                      hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template slot="action">
              <v-btn @click="dialogCreateFamily = false">Fechar</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success"
                     :disabled="editFamily.Familia === '' || editFamily.Descricao === '' || editFamily.DescricaoPT === '' || editFamily.DescricaoES === ''"
                     @click="createFamily()">Guardar
              </v-btn>
            </template>
          </staff-dialog>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data: function () {
    return {
      loading: false,
      loadingButtonSpecs: false,
      loadingButtonDescriptions: false,
      families: [],
      familiesAux: [],
      filters: {
        Familia: '',
        Descricao: '',
        DescricaoPT: '',
        DescricaoES: '',
        DescricaoEN: '',
        DescricaoFR: '',
        DescricaoIT: '',
        DescricaoDE: '',
        ArtigosB2B: '',
        Artigos: '',
        Total: ''
      },
      filtersYesNo: [
        {TEXT: 'Todos', VALUE: ''},
        {TEXT: 'Não', VALUE: '0'},
        {TEXT: 'Sim', VALUE: '1'}
      ],
      dialogEditFamily: false,
      dialogSpecs: false,
      dialogSpecsSubmit: false,
      dialogDescriptions: false,
      dialogDescriptionsSubmit: false,
      editFamily: {
        Familia: '',
        Descricao: '',
        DescricaoPT: '',
        DescricaoES: '',
        DescricaoIT: '',
        DescricaoDE: '',
        DescricaoFR: '',
        DescricaoEN: '',
      },
      fileName: '',
      fileUrl: '',
      fileFile: '',
      languageSpecs: '',
      linesSpecs: [],
      dialogCreateFamily: false
    }
  },
  created() {
    document.title = 'Famílias';
    this.getFamilies()
  },
  methods: {
    getFamilies() {
      this.loading = true

      let request = {
        url: api.families()
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {
              this.families = responseData.families
              this.familiesAux = this.families
              this.filterTable()
            }
            this.loading = false
          })
          .catch(response => {
            this.loading = false
          })
    },
    filterTable() {
      this.families = this.familiesAux
      if (this.filters.Familia !== '') {
        this.families = this.families.filter(family => family.Familia !== null && family.Familia.toLowerCase().includes(this.filters.Familia.toLowerCase()))
      }
      if (this.filters.Descricao !== '') {
        this.families = this.families.filter(family => family.Descricao.toLowerCase().includes(this.filters.Descricao.toLowerCase()))
      }
      if (this.filters.DescricaoPT !== '') {
        this.families = this.families.filter(family => family.DescricaoPT !== null && family.DescricaoPT !== '' && family.DescricaoPT.toLowerCase().includes(this.filters.DescricaoPT.toLowerCase()))
      }
      if (this.filters.DescricaoES !== '') {
        this.families = this.families.filter(family => family.DescricaoES !== null && family.DescricaoES.toLowerCase().includes(this.filters.DescricaoES.toLowerCase()))
      }
      if (this.filters.DescricaoEN !== '') {
        this.families = this.families.filter(family => family.DescricaoEN !== null && family.DescricaoEN.toLowerCase().includes(this.filters.DescricaoEN.toLowerCase()))
      }
      if (this.filters.DescricaoFR !== '') {
        this.families = this.families.filter(family => family.DescricaoFR !== null && family.DescricaoFR.toLowerCase().includes(this.filters.DescricaoFR.toLowerCase()))
      }
      if (this.filters.DescricaoIT !== '') {
        this.families = this.families.filter(family => family.DescricaoIT !== null && family.DescricaoIT.toLowerCase().includes(this.filters.DescricaoIT.toLowerCase()))
      }
      if (this.filters.DescricaoDE !== '') {
        this.families = this.families.filter(family => family.DescricaoDE !== null && family.DescricaoDE.toLowerCase().includes(this.filters.DescricaoDE.toLowerCase()))
      }
      if (this.filters.ArtigosB2B !== '') {
        this.families = this.families.filter(family => this.filters.ArtigosB2B === '0' ? family.ArtigosB2B === 0 : family.ArtigosB2B > 0)
      }
      if (this.filters.Artigos !== '') {
        this.families = this.families.filter(family => this.filters.Artigos === '0' ? family.Artigos === 0 : family.Artigos > 0)
      }
      if (this.filters.Total !== '') {
        this.families = this.families.filter(family => this.filters.Total === '0' ? family.Total === 0 : family.Total > 0)
      }
    },
    toggleDialogEditFamily(family) {
      this.editFamily = Object.assign({}, family)
      this.dialogEditFamily = true
    },
    saveDescriptionFamily() {
      let request = {
        url: api.families(),
        data: {
          family: this.editFamily
        }
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Descrição alterada com sucesso!',
                type: 'success'
              })
              this.getFamilies()
              this.dialogEditFamily = false
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao alterar descrição!',
                type: 'error'
              })
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    pickFile() {
      this.$refs.image.value = '';
      this.$refs.image.click()
    },
    onFilePicked(e) {
      const files = e.target.files
      if (files[0] !== undefined) {
        this.fileName = files[0].name
        if (this.fileName.lastIndexOf('.') <= 0) {
          return
        }
        const fr = new FileReader()
        fr.readAsDataURL(files[0])
        fr.addEventListener('load', () => {
          this.fileUrl = fr.result
          this.fileFile = files[0] // this is an image file that can be sent to server...
        })
      } else {
        this.fileName = ''
        this.fileFile = ''
        this.fileUrl = ''
      }
    },
    toggleDialogDescriptions(family) {
      this.editFamily = Object.assign({}, family);
      this.languageSpecs = '';
      this.fileName = '';
      this.fileUrl = '';
      this.fileFile = '';
      this.dialogDescriptions = true;
    },
    exportTemplateDescriptions(language) {
      let loader = this.$loading.show();
      let request = {
        url: "/api/staff/products/families/" + this.editFamily.Familia + "/descriptions/" + language,
      };

      ApiWS.dispatch('makeDownload', request)
          .then(response => {
            var file = new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            });
            var fileURL = URL.createObjectURL(file);
            var a = document.createElement('a');
            a.href = fileURL;
            a.target = '_blank';
            a.download = 'Descriptions_' + this.editFamily.Familia + '_' + language + '.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            loader.hide();
          })
          .catch(response => {
            loader.hide();
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    analyzeDescriptions() {
      this.loadingButtonDescriptions = true;
      let request = {
        url: "/api/staff/products/families/" + this.editFamily.Familia + "/descriptions/" + this.languageSpecs + "/analyze",
        file: this.fileFile
      }

      ApiWS.dispatch('makeFile', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.dialogDescriptions = false;
              this.dialogDescriptionsSubmit = true;
            }
            this.loadingButtonDescriptions = false;
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            this.loadingButtonDescriptions = false;
          })
    },
    toggleDialogSpecs(family) {
      this.editFamily = Object.assign({}, family);
      this.languageSpecs = '';
      this.fileName = '';
      this.fileUrl = '';
      this.fileFile = '';
      this.dialogSpecs = true;
    },
    exportTemplateSpecs(language) {
      let loader = this.$loading.show();

      let request = {
        url: "/api/staff/products/families/" + this.editFamily.Familia + "/specs/" + language,
      };
      ApiWS.dispatch('makeDownload', request)
          .then(response => {
            var file = new Blob([response.data], {
              type: 'application/vnd.ms-excel'
            });
            var fileURL = URL.createObjectURL(file);
            var a = document.createElement('a');
            a.href = fileURL;
            a.target = '_blank';
            a.download = 'Specs_' + this.editFamily.Familia + '_' + language + '.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            loader.hide();
          })
          .catch(response => {
            loader.hide();
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    analyzeSpecs() {
      this.loadingButtonSpecs = true;
      let request = {
        url: "/api/staff/products/families/" + this.editFamily.Familia + "/specs/" + this.languageSpecs + "/analyze",
        file: this.fileFile
      }

      ApiWS.dispatch('makeFile', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.linesSpecs = responseData.linesSpecs;
              this.dialogSpecs = false;
              this.dialogSpecsSubmit = true;
            }
            this.loadingButtonSpecs = false;
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            this.loadingButtonSpecs = false;
          })
    },
    toggleDialogCreateFamily() {
      this.editFamily = {
        Familia: '',
        Descricao: '',
        DescricaoPT: '',
        DescricaoES: '',
        DescricaoIT: '',
        DescricaoDE: '',
        DescricaoFR: '',
        DescricaoEN: '',
      }
      this.dialogCreateFamily = true;
    },
    createFamily() {
      let loader = this.$loading.show();

      let request = {
        url: '/api/staff/families/create',
        data: {
          family: this.editFamily
        }
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.dialogCreateFamily = false;
              this.getFamilies();
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao criar familia!',
                type: 'error'
              })
            }
            loader.hide();
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            loader.hide();
          })
    }
  }
}
</script>
