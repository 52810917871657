<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="2">
                <page-title :title="$t('products')"
                            :subtitle="'A mostrar '+products.length+' de '+totalItems+' artigo(s)'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small color="success" class="mr-4" @click="downloadExcel" :loading="excel"
                       :disabled="excel">
                    Download XLSX
                </v-btn>
                <v-btn tile small color="error" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-btn tile small :color="filters.showChildren ? 'success' : ''" @click="getDimension" class="mr-3">
                    Ver Dimensões
                </v-btn>
                <v-btn tile small :color="displayFilters.refSupplier ? 'success' : ''"
                       @click="displayFilters.refSupplier = !displayFilters.refSupplier">
                    Ref.Forn.
                </v-btn>
                <v-btn tile small :color="displayFilters.discount ? 'success': ''"
                       @click="displayFilters.discount = !displayFilters.discount">
                    Desconto
                </v-btn>
                <v-btn tile small :color="displayFilters.type ? 'success' : ''"
                       @click="displayFilters.type = !displayFilters.type">
                    Tipo
                </v-btn>
                <template v-if="$readPermission('products/products_intrasat')">
                    <v-btn tile small :color="displayFilters.weight ? 'success' : ''"
                           @click="displayFilters.weight = !displayFilters.weight">
                        Peso
                    </v-btn>
                    <v-btn tile small :color="displayFilters.intrasat ? 'success' : ''"
                           @click="displayFilters.intrasat = !displayFilters.intrasat">
                        Intrasat
                    </v-btn>
                </template>
                <!--                <v-btn tile small :color="displayFilters.description ? 'success' : ''"-->
                <!--                       @click="displayFilters.description = !displayFilters.description">-->
                <!--                    TEXTOS B2B-->
                <!--                </v-btn>-->
                <!--                <v-btn tile small :color="displayFilters.descriptionWeb ? 'success' : ''"-->
                <!--                       @click="displayFilters.descriptionWeb = !displayFilters.descriptionWeb">-->
                <!--                    TEXTOS WEB-->
                <!--                </v-btn>-->
                <v-btn tile small :color="displayFilters.descriptions ? 'success' : ''"
                       @click="displayFilters.descriptions = !displayFilters.descriptions">
                    TEXTOS
                </v-btn>
                <v-btn tile small :color="displayFilters.image ? 'success' : ''"
                       @click="displayFilters.image = !displayFilters.image">
                    Imagem
                </v-btn>
                <v-btn tile small :color="displayFilters.visibilidade ? 'success' : ''"
                       @click="displayFilters.visibilidade = !displayFilters.visibilidade">
                    Visibilidade
                </v-btn>
                <!--                <v-btn tile small :color="displayFilters.B2B ? 'success' : ''"-->
                <!--                       @click="displayFilters.B2B = !displayFilters.B2B">-->
                <!--                    B2B-->
                <!--                </v-btn>-->
                <!--                <v-btn tile small :color="displayFilters.FOX ? 'success' : ''"-->
                <!--                       @click="displayFilters.FOX = !displayFilters.FOX">-->
                <!--                    FOX-->
                <!--                </v-btn>-->
                <!--                <v-btn tile small :color="displayFilters.B2C ? 'success' : ''"-->
                <!--                       @click="displayFilters.B2C = !displayFilters.B2C">-->
                <!--                    B2C-->
                <!--                </v-btn>-->
                <!--                <v-btn tile small :color="displayFilters.NOZAMA ? 'success': ''"-->
                <!--                       @click="displayFilters.NOZAMA = !displayFilters.NOZAMA"-->
                <!--                       v-if="$readPermission('products/products_nozama')">-->
                <!--                    NOZAMA-->
                <!--                </v-btn>-->
                <!--                <v-btn tile small :color="displayFilters.BIKEPLANET ? 'success' : ''"-->
                <!--                       @click="displayFilters.BIKEPLANET = !displayFilters.BIKEPLANET">-->
                <!--                    BIKEPLANET-->
                <!--                </v-btn>-->
                <v-btn tile small :color="displayFilters.prices ? 'success' : ''"
                       @click="displayFilters.prices = !displayFilters.prices">
                    {{ $t('prices') }}
                </v-btn>
                <!--                <v-btn tile small :color="displayFilters.PVP ? 'success' : ''"-->
                <!--                       @click="displayFilters.PVP = !displayFilters.PVP">-->
                <!--                    PVP-->
                <!--                </v-btn>-->
                <!--                <v-btn tile small :color="displayFilters.PVP1 ? 'success' : ''"-->
                <!--                       @click="displayFilters.PVP1 = !displayFilters.PVP1">-->
                <!--                    PVP1-->
                <!--                </v-btn>-->
                <!--                <v-btn tile small :color="displayFilters.PVP_PT ? 'success' : ''"-->
                <!--                       @click="displayFilters.PVP_PT = !displayFilters.PVP_PT">-->
                <!--                    PVP PT-->
                <!--                </v-btn>-->
                <!--                <v-btn tile small :color="displayFilters.PVP_ES ? 'success' : ''"-->
                <!--                       @click="displayFilters.PVP_ES = !displayFilters.PVP_ES">-->
                <!--                    PVP ES-->
                <!--                </v-btn>-->
                <v-btn tile small :color="displayFilters.includeProfiles ? 'success' : ''"
                       @click="displayFilters.includeProfiles = !displayFilters.includeProfiles"
                       v-if="$readPermission('products/products_includeProfiles')">
                    Perfil Extra
                </v-btn>
                <v-btn tile small :color="displayFilters.B2BSoldOff ? 'success' : ''"
                       @click="displayFilters.B2BSoldOff = !displayFilters.B2BSoldOff"
                       v-if="$readPermission('products/products_b2bSoldOff')">
                    B2B Esgotado
                </v-btn>
                <v-btn tile small :color="displayFilters.blackFriday ? 'success' : ''"
                       @click="displayFilters.blackFriday = !displayFilters.blackFriday"
                       v-if="$readPermission('products/blackFriday')">
                    Black Friday
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="mt-0">
            <v-col>


                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th style="width: 25px;"></th>
                            <th style="width: 190px;">{{ $t('product') }}</th>
                            <th style="width: 150px;" v-if="displayFilters.refSupplier">Ref.Forn.</th>
                            <th style="width: 140px;">Cód. Barras Pri</th>
                            <th style="width: 140px;">EAN</th>
                            <th>{{ $t('description') }}</th>
                            <th style="width: 130px;">Marca</th>
                            <th style="width: 130px;">Familia</th>
                            <th style="width: 130px;">SubFamilia</th>
                            <th style="width: 70px;" class="text-center">Ano</th>
                            <th style="width: 75px;" class="text-center">Stock A1</th>
                            <th style="width: 75px" v-if="displayFilters.discount" class="text-center">Desconto</th>
                            <th style="width: 100px;" v-if="displayFilters.type" class="text-center">Tipo</th>
                            <th style="width: 75px;" v-if="displayFilters.weight" class="text-right">Peso</th>
                            <th style="width: 75px;" v-if="displayFilters.intrasat" class="text-right">Intrasat</th>
                            <th style="width: 90px;" v-if="displayFilters.descriptions" class="text-center">NomePRI</th>
                            <th style="width: 90px;" v-if="displayFilters.descriptions" class="text-center">NomeB2B</th>
                            <th style="width: 90px;" v-if="displayFilters.descriptions" class="text-center">DescB2B</th>
                            <th style="width: 90px;" v-if="displayFilters.descriptions" class="text-center">NomeWEB
                            </th>
                            <th style="width: 90px;" v-if="displayFilters.descriptions" class="text-center">DescWEB
                            </th>
                            <th style="width: 75px;" v-if="displayFilters.image" class="text-center">Imagens</th>
                            <th style="width: 75px;" v-if="displayFilters.visibilidade" class="text-center">B2B</th>
                            <th style="width: 75px;" v-if="displayFilters.visibilidade" class="text-center">FOX</th>
                            <th style="width: 75px;"
                                v-if="displayFilters.visibilidade && $readPermission('products/products_bicimax_com')"
                                class="text-center">Bicimax_com
                            </th>
                            <th style="width: 75px;"
                                v-if="displayFilters.visibilidade && $readPermission('products/products_marketplace')"
                                class="text-center">Marketplace
                            </th>
                            <th style="width: 75px;"
                                v-if="displayFilters.visibilidade && $readPermission('products/products_nozama')"
                                class="text-center">
                                NOZAMA
                            </th>
                            <th style="width: 75px;" v-if="displayFilters.visibilidade" class="text-center">BIKEPLANET
                            </th>
                            <th style="width: 75px;" v-if="displayFilters.prices" class="text-center">PVP1</th>
                            <th style="width: 75px;" v-if="displayFilters.prices" class="text-center">PVP PT</th>
                            <th style="width: 75px;" v-if="displayFilters.prices" class="text-center">PVP ES</th>
                            <th style="width: 75px;" v-if="displayFilters.includeProfiles">Perfil Extra</th>
                            <th style="width: 75px;" v-if="displayFilters.B2BSoldOff">B2B Esgotado</th>
                            <th style="width: 80px" v-if="displayFilters.blackFriday">Black Friday</th>
                            <th style="width: 75px;"></th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                        <span style="cursor: pointer; margin-left: 2px">
                            <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                            <i v-if="checkboxAll" class="fas fa-check-square fa-lg" style="color:#1976d2"
                               @click="unselectAll()"></i>
                        </span>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.productId"
                                        outlined
                                        @keyup.enter="search('productId')"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td v-if="displayFilters.refSupplier">
                                <v-text-field
                                        v-model="filters.refSupplier"
                                        outlined
                                        @keyup.enter="search('refSupplier')"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.barcode"
                                        outlined
                                        @keyup.enter="search('barcode')"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.EAN"
                                        outlined
                                        @keyup.enter="search('ean')"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.description"
                                        outlined
                                        @keyup.enter="search('description')"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td>
                                <v-autocomplete
                                        v-model="filters.brand"
                                        :items="brands"
                                        dense
                                        outlined
                                        @change="search('brand')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-autocomplete>
                            </td>
                            <td>
                                <v-autocomplete
                                        v-model="filters.family"
                                        :items="families"
                                        dense
                                        outlined
                                        @change="search('family')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-autocomplete>
                            </td>
                            <td>
                                <v-autocomplete
                                        v-model="filters.subFamily"
                                        :items="subFamilies"
                                        dense
                                        outlined
                                        @change="search('subFamily')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-autocomplete>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.year"
                                        outlined
                                        @keyup.enter="search('year')"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.stock"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('stock')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.discount"></td>
                            <td v-if="displayFilters.type">
                                <v-select
                                        v-model="filters.type"
                                        :items="types"
                                        outlined
                                        dense
                                        @change="search('type')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.weight"></td>
                            <td v-if="displayFilters.intrasat">
                                <v-text-field
                                        v-model="filters.intrasat"
                                        outlined
                                        hide-details
                                        @keyup.enter="search('intrasat')"
                                ></v-text-field>
                            </td>
                            <td v-if="displayFilters.descriptions">
                                <v-select
                                        v-model="filters.namePRI"
                                        :items="filtersDescription"
                                        outlined
                                        dense
                                        @change="search('namePRI')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.descriptions">
                                <v-select
                                        v-model="filters.DESC"
                                        :items="filtersDescription"
                                        outlined
                                        dense
                                        @change="search('DESC')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.descriptions">
                                <v-select
                                        v-model="filters.SPECS"
                                        :items="filtersDescription"
                                        outlined
                                        dense
                                        @change="search('SPECS')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.descriptions">
                                <v-select
                                        v-model="filters.nameWEB"
                                        :items="filtersDescription"
                                        outlined
                                        dense
                                        @change="search('nameWEB')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.descriptions">
                                <v-select
                                        v-model="filters.DESCWEB"
                                        :items="filtersDescription"
                                        outlined
                                        dense
                                        @change="search('DESCWEB')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.image">
                                <v-select
                                        v-model="filters.image"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('image')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.visibilidade">
                                <v-select
                                        v-model="filters.B2B"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('B2B')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.visibilidade">
                                <v-select
                                        v-model="filters.FOX"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('FOX')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.visibilidade && $readPermission('products/products_bicimax_com')">
                                <v-select
                                        v-model="filters.bicimax_com"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('bicimax_com')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.visibilidade && $readPermission('products/products_marketplace')">
                                <v-select
                                        v-model="filters.marketplace"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('Marketplace')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.visibilidade && $readPermission('products/products_nozama')">
                                <v-select
                                        v-model="filters.NOZAMA"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('NOZAMA')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.visibilidade">
                                <v-select
                                        v-model="filters.BIKEPLANET"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('BIKEPLANET')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.prices"></td>
                            <td v-if="displayFilters.prices"></td>
                            <td v-if="displayFilters.prices"></td>
                            <td v-if="displayFilters.includeProfiles">
                                <v-select
                                        v-model="filters.includeProfiles"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('includeProfiles')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.B2BSoldOff">
                                <v-select
                                        v-model="filters.B2BSoldOff"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('B2BSoldOff')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td v-if="displayFilters.blackFriday">
                                <v-select
                                        v-model="filters.blackFriday"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="search('blackFriday')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="20">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="product in products" :class="{'orange lighten-5': product.TratamentoDim == '2'}">
                            <td>
                                <span style="cursor: pointer">
                                    <i v-if="!product.selected" class="far fa-square fa-lg"
                                       @click="product.selected = true"></i>
                                    <i v-if="product.selected" class="fas fa-check-square fa-lg" style="color:#1976d2"
                                       @click="product.selected = false"></i>
                                </span>
                            </td>
                            <td>{{ product.Artigo }}</td>
                            <td v-if="displayFilters.refSupplier">{{ product.CDU_REFFORNECEDOR }}</td>
                            <td>
                                {{ product.CodBarras }}
                            </td>
                            <td :style="[product.TratamentoDim != 1 && !isValidEAN13(product.CodBarras) ? {'background-color': 'rgb(255,184,194)'} : '']">
                                {{ product.CDU_EAN }}
                            </td>
                            <td>{{ product.Descricao }}</td>
                            <td>{{ product.Marca }}</td>
                            <td>{{ product.Familia }}</td>
                            <td>{{ product.SubFamilia }}</td>
                            <td class="text-center">{{ product.CDU_CampoVar1 }}</td>
                            <td class="text-center">{{ product.STKActualA1 }}</td>
                            <td class="text-center" v-if="displayFilters.discount">{{ product.Desconto }}</td>
                            <td class="text-center" v-if="displayFilters.type">
                                <v-chip small v-if="product.TratamentoDim == '0'">S</v-chip>
                                <v-chip small v-if="product.TratamentoDim == '1'" color="green" text-color="white">D
                                </v-chip>
                                <v-chip small v-if="product.TratamentoDim == '2'" color="red" text-color="white">F
                                </v-chip>
                            </td>
                            <td class="text-right" v-if="displayFilters.weight">{{ product.Peso }}</td>
                            <td class="text-right" v-if="displayFilters.intrasat">{{ product.CDU_INTSTAT }}</td>
                            <td v-if="displayFilters.descriptions" class="text-center">
                                <flag iso="pt" v-bind:squared=false
                                      v-if="product.NomePRI_PT != null && product.NomePRI_PT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="es" v-bind:squared=false
                                      v-if="product.NomePRI_ES != null && product.NomePRI_ES.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="gb" v-bind:squared=false
                                      v-if="product.NomePRI_EN != null && product.NomePRI_EN.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="fr" v-bind:squared=false
                                      v-if="product.NomePRI_FR != null && product.NomePRI_FR.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="it" v-bind:squared=false
                                      v-if="product.NomePRI_IT != null && product.NomePRI_IT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="de" v-bind:squared=false
                                      v-if="product.NomePRI_DE != null && product.NomePRI_DE.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                            </td>
                            <td v-if="displayFilters.descriptions" class="text-center">
                                <flag iso="pt" v-bind:squared=false
                                      v-if="product.Nome_PT != null && product.Nome_PT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="es" v-bind:squared=false
                                      v-if="product.Nome_ES != null && product.Nome_ES.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="gb" v-bind:squared=false
                                      v-if="product.Nome_EN != null && product.Nome_EN.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="fr" v-bind:squared=false
                                      v-if="product.Nome_FR != null && product.Nome_FR.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="it" v-bind:squared=false
                                      v-if="product.Nome_IT != null && product.Nome_IT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="de" v-bind:squared=false
                                      v-if="product.Nome_DE != null && product.Nome_DE.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                            </td>
                            <td v-if="displayFilters.descriptions" class="text-center">
                                <flag iso="pt" v-bind:squared=false
                                      v-if="product.Descricao_PT != null && product.Descricao_PT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="es" v-bind:squared=false
                                      v-if="product.Descricao_ES != null && product.Descricao_ES.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="gb" v-bind:squared=false
                                      v-if="product.Descricao_EN != null && product.Descricao_EN.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="fr" v-bind:squared=false
                                      v-if="product.Descricao_FR != null && product.Descricao_FR.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="it" v-bind:squared=false
                                      v-if="product.Descricao_IT != null && product.Descricao_IT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="de" v-bind:squared=false
                                      v-if="product.Descricao_DE != null && product.Descricao_DE.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                            </td>
                            <td v-if="displayFilters.descriptions" class="text-center">
                                <flag iso="pt" v-bind:squared=false
                                      v-if="product.NomeWeb_PT != null && product.NomeWeb_PT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="es" v-bind:squared=false
                                      v-if="product.NomeWeb_ES != null && product.NomeWeb_ES.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="gb" v-bind:squared=false
                                      v-if="product.NomeWeb_EN != null && product.NomeWeb_EN.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="fr" v-bind:squared=false
                                      v-if="product.NomeWeb_FR != null && product.NomeWeb_FR.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="it" v-bind:squared=false
                                      v-if="product.NomeWeb_IT != null && product.NomeWeb_IT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="de" v-bind:squared=false
                                      v-if="product.NomeWeb_DE != null && product.NomeWeb_DE.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                            </td>
                            <td v-if="displayFilters.descriptions" class="text-center">
                                <flag iso="pt" v-bind:squared=false
                                      v-if="product.DescricaoWeb_PT != null && product.DescricaoWeb_PT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="es" v-bind:squared=false
                                      v-if="product.DescricaoWeb_ES != null && product.DescricaoWeb_ES.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="gb" v-bind:squared=false
                                      v-if="product.DescricaoWeb_EN != null && product.DescricaoWeb_EN.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="fr" v-bind:squared=false
                                      v-if="product.DescricaoWeb_FR != null && product.DescricaoWeb_FR.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="it" v-bind:squared=false
                                      v-if="product.DescricaoWeb_IT != null && product.DescricaoWeb_IT.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                                <flag iso="de" v-bind:squared=false
                                      v-if="product.DescricaoWeb_DE != null && product.DescricaoWeb_DE.length > 0 && product.TratamentoDim != '2'"
                                      class="ma-1"></flag>
                            </td>
                            <td v-if="displayFilters.image" class="text-center">
                                <i class="far fa-image" v-if="product.Imagens > 0"></i>
                            </td>
                            <td v-if="displayFilters.visibilidade" class="text-center">
                                <i class="fas fa-check" style="color: green" v-if="product.CDU_B2B_SYNC == '1'"></i>
                                <i class="fas fa-times" style="color: red" v-if="product.CDU_B2B_SYNC == '0'"></i>
                            </td>
                            <td v-if="displayFilters.visibilidade" class="text-center">
                                <i class="fas fa-check" style="color: green" v-if="product.CDU_FOX_SYNC == '1'"></i>
                                <i class="fas fa-times" style="color: red" v-if="product.CDU_FOX_SYNC == '0'"></i>
                            </td>
                            <td v-if="displayFilters.visibilidade && $readPermission('products/products_bicimax_com')"
                                class="text-center">
                                <template v-if="product.bicimax_sync.length > 0">
                                    <i class="fas fa-check" style="color: green"></i>
                                    <span> (<span v-for="(category, index) in product.bicimax_sync">
                                        <span v-if="index>0">,</span>
                                        <span>{{ category.category }}</span>
                                    </span>)</span>
                                </template>
                                <i class="fas fa-times" style="color: red"
                                   v-if="product.bicimax_sync.length == '0'"></i>
                            </td>
                            <td v-if="displayFilters.visibilidade && $readPermission('products/products_marketplace')"
                                class="text-center">
                                <i class="fas fa-check" style="color: green" v-if="product.marketplace == '1'"></i>
                                <i class="fas fa-times" style="color: red" v-if="product.marketplace == '0'"></i>
                            </td>
                            <td v-if="displayFilters.visibilidade && $readPermission('products/products_nozama')"
                                class="text-center">
                                <i class="fas fa-check" style="color: green" v-if="product.CDU_NOZAMA_SYNC == '1'"></i>
                                <i class="fas fa-times" style="color: red" v-if="product.CDU_NOZAMA_SYNC == '0'"></i>
                            </td>
                            <td v-if="displayFilters.visibilidade" class="text-center">
                                <i class="fas fa-check" style="color: green"
                                   v-if="product.CDU_BIKEPLANET_SYNC == '1'"></i>
                                <i class="fas fa-times" style="color: red"
                                   v-if="product.CDU_BIKEPLANET_SYNC == '0'"></i>
                            </td>
                            <td v-if="displayFilters.prices" class="text-right">
                                {{ product.PVP1 }}€
                            </td>
                            <td v-if="displayFilters.prices" class="text-right">
                                {{ product.CDU_PVP_PT }}€
                            </td>
                            <td v-if="displayFilters.prices" class="text-right">
                                {{ product.CDU_PVP_ES }}€
                            </td>

                            <td v-if="displayFilters.includeProfiles" class="text-center">
                                <i class="fas fa-check" style="color: green"
                                   v-if="product.CDU_IncluirPerfis == '1'"></i>
                                <i class="fas fa-times" style="color: red" v-if="product.CDU_IncluirPerfis == '0'"></i>
                            </td>
                            <td v-if="displayFilters.B2BSoldOff" class="text-center">
                                <i class="fas fa-check" style="color: green" v-if="product.CDU_B2BEsgotado == '1'"></i>
                                <i class="fas fa-times" style="color: red" v-if="product.CDU_B2BEsgotado == '0'"></i>
                            </td>
                            <td v-if="displayFilters.blackFriday" class="text-center">
                                <i class="fas fa-check" style="color: green" v-if="product.CDU_BlackFriday == '1'"></i>
                                <i class="fas fa-times" style="color: red" v-if="product.CDU_BlackFriday == '0'"></i>
                            </td>
                            <td class="text-center">
                                <v-btn small tile v-if="product.TratamentoDim !== 2"
                                       :to="'/products/products/'+product.Artigo" color="secondary">
                                    Editar
                                </v-btn>
                                <v-btn small tile v-if="product.TratamentoDim === 2"
                                       :to="'/products/products/'+product.ArtigoPai" color="secondary">
                                    Editar
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>

                    <div v-if="writePermission('products/products_cancel') && productsSelected > 0" class="mt-2">
                        <!--                        <v-btn tile small color="error" @click="toggleDialogCancel()">Anular {{-->
                        <!--                                this.numProductsSelected-->
                        <!--                            }} Artigos-->
                        <!--                        </v-btn>-->
                        <v-btn tile small color="warning" @click="toggleDialogVisibility() ; getPrestashopCategories()"
                               class="ml-3">Alterar
                            visibilidade {{ this.numProductsSelected }} Artigos
                        </v-btn>
                        <v-btn tile small color="primary" @click="toggleDialogEditProduct()" class="ml-3">
                            Editar {{ numProductsSelected }} Artigos
                        </v-btn>
                        <v-btn tile small style="color:white" color="deep-orange lighten-2"
                               @click="addQuantityForm=true" class="ml-3">
                            Quantidades caixa
                        </v-btn>
                        <!--                        <template v-if="writePermission('products/products_intrasat')">-->
                        <!--                            <v-btn tile small color="primary" @click="toggleDialogWeight()" class="ml-3">Alterar Peso-->
                        <!--                                {{ this.numProductsSelected }} Artigos-->
                        <!--                            </v-btn>-->
                        <!--                            <v-btn tile small @click="toggleDialogIntrasat()" class="ml-3">Alterar Intrasat-->
                        <!--                                {{ this.numProductsSelected }} Artigos-->
                        <!--                            </v-btn>-->
                        <!--                        </template>-->
                        <!--                        <v-btn tile small color="info" class="ml-3" @click="toggleDialogCAT()">Alterar CAT-->
                        <!--                            {{ this.numProductsSelected }} Artigos-->
                        <!--                        </v-btn>-->
                    </div>
                </div>
                <v-container fluid style="background-color: #dedede">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-select
                                    v-model="pagination.per_page"
                                    :items="filtersPerPage"
                                    outlined
                                    single-line
                                    @change="updateTable()"
                                    item-text="TEXT"
                                    item-value="VALUE"
                                    style="width: 120px; float: left"
                                    class="staff_select"
                                    hide-details
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-pagination
                                    v-model="pagination.page"
                                    :length="getTotalPages"
                                    :total-visible="7"
                                    @input="initialize"
                                    style="width: auto; float: right;"
                            ></v-pagination>
                        </v-col>
                    </v-row>
                </v-container>

            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialogVisibility" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Alterar Visibilidade Artigos
                    </v-card-title>
                    <v-card-text>
                        <v-checkbox v-model="visibility.B2B" label="B2B"></v-checkbox>
                        <v-checkbox v-if="$writePermission('products/products_bicimax_com')" v-model="visibility.bicimax_com" label="Bicimax.com"></v-checkbox>
                        <v-row v-show="visibility.bicimax_com">
                            <v-col>
                                <label>Categorias</label>
                                <v-select
                                        v-model="visibility.categories"
                                        :items="prestashopCategories"
                                        @click="debugCategories()"
                                        item-text="category"
                                        item-value="id"
                                        outlined
                                        hide-details
                                        multiple
                                        class="staff_select_small ml-2 mb-2"
                                        style="top: -4px; width: 160px; display: inline-block;"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-checkbox v-if="$writePermission('products/products_marketplace')" v-model="visibility.Marketplace" label="Marketplace"></v-checkbox>
                        <v-checkbox v-model="visibility.FOX" label="Service Center"></v-checkbox>
                        <v-checkbox v-if="$writePermission('products/products_nozama')" v-model="visibility.NOZAMA"
                                    label="Nozama"></v-checkbox>
                        <v-checkbox v-model="visibility.BIKEPLANET" label="Bikeplanet"></v-checkbox>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-btn @click="toggleDialogVisibility()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="changeVisibility('hide')">Esconder</v-btn>
                        <v-btn color="success" @click="changeVisibility('show')" :disabled="validateBtnSyncProducts">
                            Mostrar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCancel" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Anular Artigos
                    </v-card-title>
                    <v-card-text>
                        Confirma que pretende anular os artigos selecionados?
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-btn @click="toggleDialogCancel()">Cancelar</v-btn>
                        <v-btn color="success" style="margin-left: auto" @click="cancelProduct()">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEditProduct" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Editar {{ numProductsSelected }} artigos
                    </v-card-title>
                    <v-card-text>
                        <v-select
                                v-model="editProduct.column"
                                outlined
                                dense
                                label="Coluna"
                                :items="columnsToEdit"
                                item-text="TEXT"
                                item-value="VALUE"
                        ></v-select>
                        <v-text-field
                                v-if="editProduct.column !== 'CDU_IncluirPerfis' && editProduct.column !== 'CDU_B2BEsgotado' && editProduct.column !== 'CDU_BlackFriday'"
                                v-model="editProduct.value"
                                outlined
                                dense
                                label="Valor"
                                hide-details
                        ></v-text-field>
                        <v-select
                                v-else
                                v-model="editProduct.value"
                                outlined
                                dense
                                label="Valor"
                                hide-details
                                :items="yesNo"
                                item-value="VALUE"
                                item-text="TEXT"
                        ></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogEditProduct()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" :disabled="validateBtnSaveEditProduct" @click="updateField()">Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="addQuantityForm" persistent max-width="984" @click:outside="addQuantityForm=false">
                <ProductBoxQuantityForm v-on:close-box-form="addQuantityForm=false" :products="selectedIds"
                                        :singleProductMode="false"/>
            </v-dialog>

            <!--            <v-dialog v-model="dialogWeight" persistent max-width="400">-->
            <!--                <v-card>-->
            <!--                    <v-card-title>-->
            <!--                        Alterar Peso-->
            <!--                    </v-card-title>-->
            <!--                    <v-card-text>-->
            <!--                        <v-text-field-->
            <!--                                v-model="fieldValue"-->
            <!--                                outlined-->
            <!--                                dense-->
            <!--                                label="Peso"-->
            <!--                        ></v-text-field>-->
            <!--                    </v-card-text>-->
            <!--                    <v-card-actions>-->
            <!--                        <v-btn @click="toggleDialogWeight()">Cancelar</v-btn>-->
            <!--                        <v-spacer></v-spacer>-->
            <!--                        <v-btn color="success" @click="updateField('Peso')">Guardar</v-btn>-->
            <!--                    </v-card-actions>-->
            <!--                </v-card>-->
            <!--            </v-dialog>-->
            <!--            <v-dialog v-model="dialogIntrasat" persistent max-width="400">-->
            <!--                <v-card>-->
            <!--                    <v-card-title>-->
            <!--                        Alterar Intrasat-->
            <!--                    </v-card-title>-->
            <!--                    <v-card-text>-->
            <!--                        <v-text-field-->
            <!--                                v-model="fieldValue"-->
            <!--                                outlined-->
            <!--                                dense-->
            <!--                                label="Intrasat"-->
            <!--                        ></v-text-field>-->
            <!--                    </v-card-text>-->
            <!--                    <v-card-actions>-->
            <!--                        <v-btn @click="toggleDialogIntrasat()">Cancelar</v-btn>-->
            <!--                        <v-spacer></v-spacer>-->
            <!--                        <v-btn color="success" @click="updateField('CDU_INTSTAT')">Guardar</v-btn>-->
            <!--                    </v-card-actions>-->
            <!--                </v-card>-->
            <!--            </v-dialog>-->
            <!--            <v-dialog v-model="dialogCAT" persistent max-width="400">-->
            <!--                <v-card>-->
            <!--                    <v-card-title>Alterar Categoria</v-card-title>-->
            <!--                    <v-card-text>-->
            <!--                        <v-text-field-->
            <!--                                v-model="fieldValue"-->
            <!--                                outlined-->
            <!--                                dense-->
            <!--                                label="Categoria"-->
            <!--                        ></v-text-field>-->
            <!--                    </v-card-text>-->
            <!--                    <v-card-actions>-->
            <!--                        <v-btn @click="dialogCAT = false">Cancelar</v-btn>-->
            <!--                        <v-spacer></v-spacer>-->
            <!--                        <v-btn color="success" @click="updateField('CDU_B2C_IDCATEGORY')">Guardar</v-btn>-->
            <!--                    </v-card-actions>-->
            <!--                </v-card>-->
            <!--            </v-dialog>-->
        </v-row>
    </v-container>
</template>

<script>
import i18n from '../../plugins/i18n';
import ProductBoxQuantityForm from '@/components/Products/AddBoxQuantity.vue';
import axios from "axios";

export default {
    data() {
        return {
            addQuantityForm: false,
            loading: false,
            excel: false,
            selected: [],
            show1: false,
            dialog: false,
            dialogCancel: false,
            dialogVisibility: false,
            dialogWeight: false,
            dialogIntrasat: false,
            dialogCAT: false,
            dialogEditProduct: false,
            headers: [],
            products: [],
            prestashopCategories: [],
            editedIndex: -1,
            rules: {
                required: value => !!value || value === 0 || i18n.t('required')
            },
            pagination: {
                per_page: 250,
                page: 1
            },
            editedItem: {
                id: '',
                permission: ''
            },
            defaultItem: {
                id: '',
                permission: ''
            },
            itemCancel: {},
            totalItems: 0,
            filters: {
                productId: '',
                refSupplier: '',
                barcode: '',
                EAN: '',
                description: '',
                brand: '',
                family: '',
                subFamily: '',
                year: '',
                stock: '',
                type: '',
                namePRI: '',
                DESC: '',
                SPECS: '',
                image: '',
                visibilidade: '',
                B2B: '',
                FOX: '',
                B2C: '',
                marketplace: '',
                NOZAMA: '',
                BIKEPLANET: '',
                CAT: '',
                nameWEB: '',
                DESCWEB: '',
                showChildren: false,
                includeProfiles: '',
                B2BSoldOff: '',
                blackFriday: '',
                intrasat: '',
                bicimax_com: ''
            },
            displayFilters: {
                type: false,
                descriptions: false,
                image: false,
                visibilidade: false,
                B2B: false,
                Marketplace: false,
                bicimax_com: false,
                NOZAMA: false,
                BIKEPLANET: false,
                prices: false,
                FOX: false,
                weight: false,
                intrasat: false,
                refSupplier: false,
                includeProfiles: false,
                B2BSoldOff: false,
                blackFriday: false,
                discount: false
            },
            flagValidateBtn: false,
            checkboxAll: false,
            brands: [],
            families: [],
            subFamilies: [],
            types: [],
            filtersDescription: [
                {TEXT: 'Todos', VALUE: ''},
                {TEXT: 'Sem PT', VALUE: 'PT'},
                {TEXT: 'Sem ES', VALUE: 'ES'},
                {TEXT: 'Sem EN', VALUE: 'EN'},
                {TEXT: 'Sem FR', VALUE: 'FR'},
                {TEXT: 'Sem IT', VALUE: 'IT'},
                {TEXT: 'Sem DE', VALUE: 'DE'}
            ],
            filtersYesNo: [
                {TEXT: 'Todos', VALUE: ''},
                {TEXT: 'Não', VALUE: '0'},
                {TEXT: 'Sim', VALUE: '1'}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            visibility: {
                B2B: false,
                Marketplace: false,
                FOX: false,
                NOZAMA: false,
                BIKEPLANET: false,
                bicimax_com: false,
                selectedOption: false,
                categories: [],
            },
            numProductsSelected: 0,
            fieldValue: '',
            editProduct: {
                column: '',
                value: ''
            },
            yesNo: [
                {TEXT: 'Não', VALUE: '0'},
                {TEXT: 'Sim', VALUE: '1'}
            ],
            columnsToEdit: [
                {TEXT: 'Categoria', VALUE: 'CDU_B2C_IDCATEGORY'},
                {TEXT: 'Desconto', VALUE: 'Desconto'},
            ]
        }
    },
    components: {
        ProductBoxQuantityForm,
    },
    computed: {
        validateBtnSyncProducts() {
            if (this.visibility.bicimax_com == true && this.visibility.categories.length === 0) {
                this.flagValidateBtn = true;
            } else {
                this.flagValidateBtn = false;
            }
            return this.flagValidateBtn;
        },
        selectedIds() {
            return this.products.filter((product) => product.selected === true).map((product) => product.Artigo);
        },
        formTitle() {
            return this.editedIndex === -1 ? i18n.t('addItem') : 'Editar'
        },
        getTotalPages() {
            return Math.ceil(this.totalItems / this.pagination.per_page)
        },
        productsSelected() {
            this.numProductsSelected = 0
            let selectedProducts = 0
            this.products.forEach(function (product) {
                if (product.selected === true) {
                    selectedProducts++
                }
            })
            this.numProductsSelected = selectedProducts
            return this.numProductsSelected
        },
        validateBtnSaveEditProduct() {
            if (this.editProduct.column === '' || this.editProduct.value === '') {
                return true;
            }
            return false;
        },
        // pages () {
        //     if (this.pagination.rowsPerPage == null ||
        //         this.pagination.totalItems == null
        //     ) return 0
        //     return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        // }
    },
    created() {
        if (this.$cookies.isKey('listProducts_filtersProducts_13')) {
            this.filters = this.$cookies.get('listProducts_filtersProducts_13')
        }
        if (this.$cookies.isKey('listProducts_pagination_01')) {
            this.pagination = this.$cookies.get('listProducts_pagination_01')
        }
        if (this.$cookies.isKey('listProducts_displayFilters_13')) {
            this.displayFilters = this.$cookies.get('listProducts_displayFilters_13')
        }
        document.title = i18n.t('products') + ' | STAFF';
        this.initialize()
    },
    methods: {
        debugCategories() {
            console.log(this.visibility.categories)
        },
        initialize() {
            this.loading = true;
            this.products = [];

            let filters = {
                pagination: this.pagination,
                filters: this.filters
            };

            Product.dispatch('getProducts', filters)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            responseData.products.forEach((product) => product.bicimax_sync = JSON.parse(product.bicimax_sync))
                            this.products = responseData.products;
                            this.totalItems = responseData.totalProducts;
                            this.brands = responseData.brands;
                            this.getFamilies();
                            this.getSubFamilies();
                            this.getTypes();
                            window.scrollTo(0, 0)
                            // console.log(this.pagination)
                        }
                        this.loading = false
                    })
        },
        getPrestashopCategories() {
            let loader = this.$loading.show();
            axios.get(api.bicimaxCategories())
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.prestashopCategories = responseData.data
                        }
                        loader.hide()
                    })
                    .catch(response => {
                        loader.hide()
                    })
        },
        updateTable() {
            this.pagination.page = 1;
            this.initialize();
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        selectAll() {
            this.checkboxAll = true
            this.products.forEach(function (product) {
                product.selected = true
            })
        },
        unselectAll() {
            this.checkboxAll = false
            this.products.forEach(function (product) {
                product.selected = false
            })
        },
        getFamilies() {
            Product.dispatch('getFamilies', this.filters.brand)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.families = responseData.families
                        }
                    })
                    .catch(response => {

                    })
        },
        getSubFamilies() {
            Product.dispatch('getSubFamilies', this.filters.family)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.subFamilies = responseData.subFamilies
                        }
                    })
                    .catch(response => {

                    })
        },
        getTypes() {
            let filters = {
                brand: this.filters.brand,
                family: this.filters.family,
                subFamily: this.filters.subFamily
            }

            Product.dispatch('getTypes', filters)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.types = responseData.types
                        }
                    })
                    .catch(() => {

                    })
        },
        isValidEAN13(a) {
            for (var b = /\d/g,             //initialize regular expression to match single digits
                         c = 0,                        //initialize checksum
                         d,
                         e = 25;                       //should be 25 - 13 * 2 = -1 in the end, so `~e` is 0
                 d = b.exec(a);                //fetch a single digit from the input
                 e -= 2)
                c += e % 4 * d;               //multiply current digit with 3, 1, 3, 1 and so on
            return !(~e | c % 10)           //e must be -1 and sum must be 0
        },
        getDimension() {
            this.filters.showChildren = !this.filters.showChildren;
            this.$cookies.set("listProducts_filtersProducts_13", this.filters);
            this.initialize()
        },
        search(column) {
            if (column === 'brand') {
                this.filters.family = '';
                this.filters.subFamily = ''
            }
            if (column === 'family') {
                this.filters.subFamily = ''
            }
            this.$cookies.set("listProducts_filtersProducts_13", this.filters);
            this.pagination.page = 1;
            this.initialize()
        },
        downloadExcel() {
            this.excel = true;
            let filters = {
                pagination: this.pagination,
                filters: this.filters
            };
            let request = {
                url: '/api/staff/products/excel',
                data: filters
            };
            ApiWS.dispatch('makePostDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'products_list.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        // loader.hide();
                        this.excel = false;
                    })
                    .catch(response => {
                        // loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.excel = false;
                    })

        },
        clearFilters() {
            this.filters = {
                productId: '',
                refSupplier: '',
                barcode: '',
                EAN: '',
                description: '',
                brand: '',
                family: '',
                subFamily: '',
                year: '',
                stock: '',
                type: '',
                namePRI: '',
                DESC: '',
                SPECS: '',
                image: '',
                B2B: '',
                FOX: '',
                B2C: '',
                marketplace: '',
                NOZAMA: '',
                BIKEPLANET: '',
                CAT: '',
                nameWEB: '',
                DESCWEB: '',
                showChildren: this.filters.showChildren,
                includeProfiles: '',
                B2BSoldOff: '',
                blackFriday: '',
                intrasat: '',
                bicimax_com: ''
            }
            this.$cookies.set("listProducts_filtersProducts_13", this.filters);
            this.pagination.page = 1
            this.initialize()
        },
        toggleDialogVisibility() {
            this.visibility = {
                B2B: false,
                bicimax_com: false,
                Marketplace: false,
                FOX: false,
                NOZAMA: false,
                BIKEPLANET: false,
                categories: [],
            }
            this.dialogVisibility = !this.dialogVisibility
        },
        toggleDialogEditProduct() {
            if (this.writePermission('products/products_intrasat')) {
                this.columnsToEdit.push({TEXT: 'Peso', VALUE: 'Peso'});
                this.columnsToEdit.push({TEXT: 'Intrasat', VALUE: 'CDU_INTSTAT'});
            }

            if (this.writePermission('products/products_includeProfiles')) {
                this.columnsToEdit.push({TEXT: 'Incluir Perfis', VALUE: 'CDU_IncluirPerfis'});
            }

            if (this.writePermission('products/products_b2bSoldOff')) {
                this.columnsToEdit.push({TEXT: 'B2B Esgotado', VALUE: 'CDU_B2BEsgotado'});
            }

            if (this.writePermission('products/blackFriday')) {
                this.columnsToEdit.push({TEXT: 'Black Friday', VALUE: 'CDU_BlackFriday'});
            }

            this.editProduct = {
                column: '',
                value: ''
            }

            this.dialogEditProduct = !this.dialogEditProduct;
        },
        changeVisibility(option) {
            let linesSelected = []
            let linesBicimaxCom = []
            this.products.forEach(function (product) {
                if (product.selected === true) {
                    linesSelected.push(product.Artigo)
                    linesBicimaxCom.push(product)
                }
            })

            let product = {
                products: linesSelected,
                productsBicimaxCom: linesBicimaxCom,
                visibility: this.visibility,
                option: option
            }

            Product.dispatch('changeVisibility', product)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let alert = {
                                snackbar: true,
                                text: 'Visibilidade alterada com sucesso!',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)

                            this.toggleDialogVisibility()
                            this.initialize()
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao alterar visibilidade!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao alterar visibilidade!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        toggleDialogCancel() {
            this.dialogCancel = !this.dialogCancel
        },
        cancelProduct() {
            let linesSelected = []
            this.products.forEach(function (product) {
                if (product.selected === true) {
                    linesSelected.push(product.Artigo)
                }
            })

            let products = {
                products: linesSelected
            }

            Product.dispatch('cancel', products)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let alert = {
                                snackbar: true,
                                text: 'Producto anulado com sucesso!',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)

                            this.toggleDialogCancel()
                            this.initialize()
                        } else if (responseData.status === 'WARN') {
                            let alert = {
                                snackbar: true,
                                text: responseData.data,
                                type: 'warning'
                            }

                            Alert.dispatch('show', alert)
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao anular producto!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao anular producto!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        toggleDialogWeight() {
            this.dialogWeight = !this.dialogWeight
        },
        toggleDialogIntrasat() {
            this.dialogIntrasat = !this.dialogIntrasat
        },
        updateField() {
            let linesSelected = []
            this.products.forEach(function (product) {
                if (product.selected === true) {
                    linesSelected.push(product.Artigo)
                }
            })

            let request = {
                url: api.productsUpdateField(),
                data: {
                    products: linesSelected,
                    field: this.editProduct.column,
                    value: this.editProduct.value
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Campo atualizado com sucesso!',
                                type: 'success'
                            })
                            this.dialogEditProduct = false;
                            this.editProduct = {
                                column: '',
                                value: ''
                            };
                            this.initialize();
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao atualizar o campo!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        // toggleDialogCAT() {
        //     this.dialogCAT = true;
        // }
    },
    watch: {
        // filters: function () {
        //     console.log('entrou filters');
        //     this.$cookies.set("listProducts_filtersProducts_2", this.filters)
        // }
        'pagination.per_page': function () {
            this.$cookies.set("listProducts_pagination_01", this.pagination)
        },
        'pagination.page': function () {
            this.$cookies.set("listProducts_pagination_01", this.pagination)
        },
        'displayFilters.refSupplier': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.type': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.weight': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.intrasat': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.descriptions': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.image': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.visibilidade': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.FOX': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.bicimax_com': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.NOZAMA': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.BIKEPLANET': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.prices': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.includeProfiles': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.B2BSoldOff': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.blackFriday': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
        'displayFilters.discount': function () {
            this.$cookies.set("listProducts_displayFilters_13", this.displayFilters)
        },
    }
}
</script>
