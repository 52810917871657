<template>
  <v-dialog v-model="loaded" max-width="700" @click:outside="closeDialogParent">
    <v-card>
      <v-card-title>
        <span>Produtos detetados com informações duplicadas</span>
      </v-card-title>
      <v-btn
          color="grey lighten-3"
          dark
          fab
          top
          absolute
          right
          @click="closeDialogParent"
      >
        <span class="buttonClose">x</span>
      </v-btn>

      <v-card-text>
        <v-container>
          <v-row v-show="duplicatedSyncMessage!=''" class="staff_v-card mb-3"
                 style="color: red;background-color: #ffc06342;font-weight: bold;">
            <span>{{ duplicatedSyncMessage }}</span>
            <small>{{ duplicatedSyncSmallMessage }}</small>
          </v-row>
          <v-row v-for="(product, productIndex) in duplicatedProducts" :key="product.productId"
                 style="background-color: transparent;"
                 class="staff_v-card mb-3">
            <v-container>
              <v-row>
                <v-col cols="10">
<!--                <v-text-field
                    style="font-size: 12px;color: grey;"
                    :value="product.productId + ' - ' + product.brandId + ' - ' + product.name"
                    readonly
                    label="Produto"
                ></v-text-field>-->
                  <small>Produto</small>
                  <br>
                  <span style="font-size: 14px">
                    {{ product.productId + ' - ' + product.brandId + ' - ' + product.name }}
                  </span>
                  <hr>
                </v-col>
                <v-col cols="2">
                  <div @click="" style="cursor:pointer" @click="dialogConfirm = true, productToCancel = product">
                    <i style="align-self: center; font-size:xx-large; color: red" class="fas fa-times-circle buttonSaveInfo"></i>
                    <br><small>anular</small>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                      :class="{isDuplicate:
                                        (product.evaluator == 1 ||
                                          product.evaluator == 4 ||
                                          product.evaluator == 6 ||
                                          product.evaluator == 9)
                            }"
                      v-model="product.ean"
                      :prepend-icon="(product.evaluator == 1 ||
                                          product.evaluator == 4 ||
                                          product.evaluator == 6 ||
                                          product.evaluator == 9) ? 'mdi-wrench' : undefined"
                      hint="Insira o EAN do produto"
                      label="EAN"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      :class="{isDuplicate:
                                        (product.evaluator == 3 ||
                                          product.evaluator == 4 ||
                                          product.evaluator == 8 ||
                                          product.evaluator == 9)
                            }"
                      :prepend-icon="(product.evaluator == 3 ||
                                          product.evaluator == 4 ||
                                          product.evaluator == 8 ||
                                          product.evaluator == 9) ? 'mdi-wrench' : undefined"
                      v-model="product.barcode"
                      hint="Insira o código de barras do produto"
                      label="Código de Barras"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                      :class="{isDuplicate:
                                        (product.evaluator == 5 ||
                                          product.evaluator == 6 ||
                                          product.evaluator == 8 ||
                                          product.evaluator == 9)
                            }"
                      :prepend-icon="(product.evaluator == 5 ||
                                          product.evaluator == 6 ||
                                          product.evaluator == 8 ||
                                          product.evaluator == 9) ? 'mdi-wrench' : undefined"
                      v-model="product.supplierCode"
                      hint="Insira a referência de fornecedor do produto"
                      label="Ref. Fornecedor"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" style="align-self: center; font-size:xx-large">
                  <i @click="saveProductInfo(product, duplicatedProductsOriginal[productIndex], duplicatedProducts[0])"
                     v-if="product.ean != duplicatedProductsOriginal[productIndex].ean || product.barcode != duplicatedProductsOriginal[productIndex].barcode || product.supplierCode != duplicatedProductsOriginal[productIndex].supplierCode"
                     style="color: green" class="fas fa-check-circle buttonSaveInfo"></i>
                </v-col>
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn tile small @click="closeDialogParent">Fechar</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogConfirm" persistent max-width="400">
      <v-card>
        <v-card-title>
          {{ $t('cancel')}} {{ $t('product')}}
        </v-card-title>
        <v-card-text>
          {{ $t('confirmCancelProduct') }}
          <hr>
          <br>
          <span> {{ productToCancel.productId + ' - ' + productToCancel.brandId + ' - ' + productToCancel.name }}?</span>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialogConfirm()">Cancelar</v-btn>
          <v-btn color="success" style="margin-left: auto" @click="cancelProduct()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>


</template>

<script>
export default {
  data: () => ({
    duplicatedSyncMessage: "",
    duplicatedSyncSmallMessage: "",
    duplicatedProducts: [],
    duplicatedProductsOriginal: [],
    loaded: false,
    dialogConfirm: false,
    productToCancel: {
      productId: null,
      brandId: null,
      name: null
    }
  }),
  name: "ProductEditing",
  props: {
    product: {
      type: String,
      required: true
    }
  },
  created() {
    this.checkDuplicatedProducts();
  },
  methods: {
    checkDuplicatedProducts() {//product) {
      let product = this.product;
      let loader = this.$loading.show();
      this.duplicatedSyncMessage = "";
      let request = {
        url: '/api/staff/products/inconsistencies/' + btoa(product),
      };

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              if (responseData.dups > 0) {
                this.duplicatedProducts = responseData.data.duplicatedInfo[product];
              } else if (responseData.missing == 1) {
                this.duplicatedProducts = responseData.data.missingInfo ;
              }else if(responseData.dups == 0 && responseData.missing == 0)
                this.closeDialogParent();
              if (this.duplicatedProducts.length > 0) {
                this.duplicatedProductsOriginal = JSON.stringify(this.duplicatedProducts);
                this.duplicatedProductsOriginal = JSON.parse(this.duplicatedProductsOriginal);
              }
              if (this.dialogDuplicatedProducts == false)
                this.dialogDuplicatedProducts = true;
            } else {
              this.duplicatedSyncMessage = "Ocorreram erros ao sincronizar problemas, por favor feche e volte a abrir esta janela."
              this.duplicatedSyncSmallMessage = "Se o erro persistir, reportar ao departamento informático.";
            }
            this.loaded = true;
            loader.hide();
          })
          .catch(() => {
            //this.loaded = true;
            loader.hide();
          })
    },
    closeDialogParent(){
      this.$emit('close-editing');
    },
    closeDialogConfirm(){
      this.dialogConfirm = false;
      this.productToCancel= {
        productId: null,
        brandId: null,
        name: null
      };
    },
    cancelProduct(){
      let loader = this.$loading.show();
      this.duplicatedSyncMessage = "";
      let request = {
        url: '/api/staff/products/' + this.productToCancel.productId,
      };

      ApiWS.dispatch('makeDelete', request)
          .then(response => {
            let responseData = response.data;
            console.log(this.duplicatedProducts.length);
            if (responseData.status === 'OK') {
              /**************** If there is only one item then close the window, else update ******************* **/
              if(this.duplicatedProducts.length == 1){
                Alert.dispatch('show', {
                  snackbar: true,
                  text: 'Produto anulado com sucesso',
                  type: 'success'
                });
                this.closeDialogParent();
              }else if(this.duplicatedProducts.length > 2){
                this.checkDuplicatedProducts();
              }
            } else {
              this.duplicatedSyncMessage = responseData.message;
              setTimeout(()=>this.duplicatedSyncMessage = "", 4000);
              this.closeDialogConfirm();
            }
            loader.hide();
          })
          .catch(() => {
            //this.loaded = true;
            loader.hide();
          })
    },
    saveProductInfo(product, productOriginal, productToCheck) {
      let loader = this.$loading.show();

      let request = {
        url: '/api/staff/products/field',
        data: {
          products: [product.productId],
          checkDuplicated: true
        }
      };

      let snackMessage = "";
      let snackType = "success";

      let promiseEan = {text: null, success: true};
      let promiseBarcode = {text: null, success: true};
      let promiseSuppliercode = {text: null, success: true};

      if (product.ean != productOriginal.ean) {
        request.data.field = 'CDU_EAN';
        request.data.value = product.ean;

        promiseEan = ApiWS.dispatch('makePost', request)
            .then(response => {
              let responseData = response.data;
              if (responseData.status === 'OK') {
                return {text: "EAN", success: true};
              } else
                return {text: null, success: false};

            })
            .catch(() => {

            })
      }
      if (product.barcode != productOriginal.barcode) {
        request.data.field = 'CodBarras';
        request.data.value = product.barcode;

        promiseBarcode = ApiWS.dispatch('makePost', request)
            .then(response => {
              let responseData = response.data;
              if (responseData.status === 'OK') {
                return {text: "Código Barras", success: true};
              } else
                return {text: null, success: false};
              //loader.hide();
            })
            .catch(() => {
              return {text: null, success: false};
            })
      }
      if (product.supplierCode != productOriginal.supplierCode) {
        request.data.field = 'CDU_REFFORNECEDOR';
        request.data.value = product.supplierCode;

        promiseSuppliercode = ApiWS.dispatch('makePost', request)
            .then(response => {
              let responseData = response.data;
              if (responseData.status === 'OK') {
                return {text: "Ref. Fornecedor", success: true};
              } else
                return {text: null, success: false};
              //loader.hide();
            })
            .catch(() => {
              return {text: null, success: false};
            })
      }
      let counter = 0;
      let valid = false;
      Promise.all([promiseEan, promiseBarcode, promiseSuppliercode]).then((values) => {
        for (let i = 0; i < values.length; i++) {
          counter++;
          if (values[i].text != null) {
            valid = true;
            if (snackMessage != "")
              snackMessage += '/';
            snackMessage += values[i].text;
          }
          if (values[i].success == false) {
            snackType = "warning";
            switch (counter) {
              case 1:
                product.ean = productOriginal.ean;
                break;
              case 2:
                product.barcode = productOriginal.barcode;
                break;
              default:
                product.supplierCode = productOriginal.supplierCode;
            }
          }
        }
        if (counter == 3) {
          if (valid == true) {
            if (snackType == 'success')
              snackMessage += " atualizado com sucesso.";
            else
              snackMessage = "Apenas " + snackMessage + " atualizado com sucesso.";
          } else {
            snackMessage = "Ocorreu um problema ao atualizar os valores";
            snackType = "error";
          }
        }
      }).then(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: snackMessage,
              type: snackType
            });
            loader.hide();
            if (snackType == "success") {
              //productToCheck.product = productToCheck.productId;
              this.checkDuplicatedProducts();
            }
          }
      );
    }
  }
}
</script>

<style scoped>

.buttonClose {
  margin-top: 24px;
  color: #a79090;
  font-size: 22px;
  font-weight: 1000;
}

.buttonSaveInfo:hover {
  cursor: pointer;
}

.buttonSaveInfo:active {
  color: #09a509 !important;
  font-size: 30px;
}
</style>

