import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const B2B = new Vuex.Store({
    actions: {
        getLogins(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters
                }
                axios.post(api.b2bLogins(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        // getBanners(context, country) {
        //     return new Promise((resolve, reject) => {
        //         axios.get(api.b2bBanners() + '/' + country)
        //             .then(response => {
        //                 resolve(response)
        //             })
        //             .catch(response => {
        //                 reject(response)
        //             })
        //     })
        // },
        getMiniBanners(context, country) {
            return new Promise((resolve, reject) => {
                axios.get(api.b2bBannersMini() + '/' + country)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSCPrice(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.b2bSCPrice())
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        uploadSCPrice(context, image) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('file', image.imageFile)
                axios.post(api.b2bSCPrice()+'/'+image.language, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getCoupons(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.b2bCoupons())
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        saveCoupon(context, coupon) {
            return new Promise((resolve, reject) => {
                let data = {
                    coupon: coupon
                }
                axios.post(api.b2bCoupons(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        removeCoupon(context, coupon) {
            return new Promise((resolve, reject) => {
                axios.delete(api.b2bCoupons()+'/'+coupon.id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    }
})

export default B2B
