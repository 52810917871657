<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="3">
                <page-title
                        title="Débitos Directos"
                        :subtitle="'A mostrar ' + debits.length + ' débitos.'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <file-upload :url='url' btn-label="Importar XML" :headers="headers" accept="text/xml"
                             @change="onUploadChange" @success="onUploadSuccess" @error="onUploadError"></file-upload>
                <v-btn tile small class="ml-2" color="success" :to="{ name: 'debits/addLines' }">Adicionar Linhas
                </v-btn>
                <v-btn tile small class="ml-2" color="error" @click="dialogAddDebit = true">Novo Débito</v-btn>
                <v-btn tile small class="ml-2" color="info" @click="getDebits()">Atualizar</v-btn>
            </v-col>
        </v-row>

        <!-- DEBITS TABLE -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th width="1">ID</th>
                            <th>Estado</th>
                            <th width="1">Nome</th>
                            <th width="56">Banco</th>
                            <th width="1">DataCobranca</th>
                            <th width="100">Comentários</th>
                            <th width="90">Cód.Banco</th>
                            <th width="1" class="text-center">Linhas</th>
                            <th width="1" class="text-center">Recib</th>
                            <th width="1" class="text-center">Estor</th>
                            <th width="1" class="text-center">Emails</th>
                            <th width="85" class="text-center">Total</th>
                            <th width="85" class="text-center">Recebido</th>
                            <th width="85" class="text-center">Por Receber</th>
                            <th width="1"></th>
                            <th width="1"></th>
                            <th width="1"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="17">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="debit in debits">
                            <td>{{ debit.ID }}</td>
                            <td>{{ debit.B2BSEPAFileStatus_id }}-{{ debit.estado }}</td>
                            <td>{{ debit.name }}</td>
                            <td>{{ debit.Bank_name }}</td>
                            <td>
                                <template v-if="debit.B2BSEPAFileStatus_id === '1'">
                                    <span style="color: #0000FF; text-decoration: underline; cursor: pointer;"
                                          @click="changeDate(debit)">{{ debit.charge_date }}</span>
                                </template>
                                <template v-if="debit.B2BSEPAFileStatus_id !== '1'">
                                    {{ debit.charge_date }}
                                </template>
                            </td>
                            <td>{{ debit.comments }}</td>
                            <td>{{ debit.bank_dd }}</td>
                            <td class="text-center">{{ debit.Linhas }}</td>
                            <td class="text-center">{{ debit.Recibos }}</td>
                            <td class="text-center">{{ debit.Estornos }}</td>
                            <td class="text-center">{{ debit.Emails }}</td>
                            <td class="text-right">{{ debit.SUM }} €</td>
                            <td class="text-right">{{ debit.Recebido }} €</td>
                            <td class="text-right">{{ debit.PorReceber }} €</td>
                            <td>
                                <v-btn tile small v-if="['1'].includes(debit.B2BSEPAFileStatus_id) && debit.Linhas > 0"
                                       color="error" @click="makeReceipts(debit.ID)">Fechar/Recibos
                                </v-btn>
                                <v-btn tile small v-if="['2'].includes(debit.B2BSEPAFileStatus_id)" color="info"
                                       @click="makeXML(debit.ID, debit.name)">Gerar XML
                                </v-btn>
                                <v-btn tile small v-if="['3'].includes(debit.B2BSEPAFileStatus_id)" color="success"
                                       @click="downloadXML(debit.ID, debit.name)">Download
                                </v-btn>
                                <v-btn tile small v-if="['4'].includes(debit.B2BSEPAFileStatus_id)" color="warning"
                                       @click="insertBankCode(debit)">Código Banco
                                </v-btn>
                            </td>
                            <td class="text-center">
                                <v-btn tile small v-if="['1','2','3','4'].includes(debit.B2BSEPAFileStatus_id)"
                                       @click="editDebit(debit)" color="primary">Editar
                                </v-btn>
                                <v-progress-circular
                                        v-if="['5'].includes(debit.B2BSEPAFileStatus_id)"
                                        :rotate="-90"
                                        :value="((debit.Emails/debit.Linhas)*100)"
                                        :size="20"
                                        :width="2"
                                        @click="callResendEmails(debit)"
                                        color="primary"
                                ></v-progress-circular>
                                <v-progress-circular
                                        v-if="['6'].includes(debit.B2BSEPAFileStatus_id)"
                                        :rotate="-90"
                                        :value="(debit.Recibos/debit.Linhas*100)"
                                        :size="20"
                                        :width="2"
                                        color="primary"
                                ></v-progress-circular>
                            </td>
                            <td>
                                <v-btn tile small :to="'/debits/detail/'+debit.ID">Ver</v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <!-- DIAOLOGS -->
        <v-row justify="center">
            <!-- ADD/EDIT DEBIT -->
            <v-dialog v-model="dialogAddDebit" persistent max-width="400">
                <v-card>
                    <v-card-title class="blue darken-2">
                        <v-row>
                            <v-col class="text-uppercase">
                                <span style="color:white">
                                    <template v-if="newDebit.id===''">Adicionar novo Débito</template>
                                    <template v-if="newDebit.id!==''">Editar Débito</template>
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form class="mt-6" ref="addDebit">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="newDebit.name"
                                            label="Nome"
                                            outlined
                                            dense
                                            autofocus
                                            :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-select
                                            v-model="newDebit.bank"
                                            :items="banks"
                                            dense
                                            label="Banco"
                                            item-text="text"
                                            item-value="value"
                                            outlined
                                            :rules="[rules.required]"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-menu
                                            v-model="menu3"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="newDebit.date"
                                                    label="Data"
                                                    readonly
                                                    outlined
                                                    dense
                                                    v-on="on"
                                                    :rules="[rules.required]"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="newDebit.date" locale="pt-pt" show-current
                                                       @input="menu3 = false" @change=""
                                                       :min="minDate"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="newDebit.comments"
                                            label="Comentários"
                                            outlined
                                            dense
                                            :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-btn small tile @click="dialogAddDebit = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn small tile color="success" @click="addDebitConfirm()">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--  -->
            <v-dialog v-model="dialogChangeDate" max-width="400">
                <v-card>
                    <v-card-title>
                        Alterar data
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-form ref="form">
                                    <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            full-width
                                            min-width="290px"
                                    >
                                        <template v-slot:activator="{ on }">
                                            <v-text-field
                                                    v-model="debitToChange.new_date"
                                                    label="Nova Data"
                                                    prepend-icon="event"
                                                    readonly
                                                    v-on="on"
                                                    :rules="[rules.required]"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="debitToChange.new_date" locale="pt-pt" show-current
                                                       @input="menu2 = false" @change="" :min="minDate"></v-date-picker>
                                    </v-menu>
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogChangeDate = false">Cancelar</v-btn>
                        <v-btn color="success" @click="changeDateConfirm()" style="margin-left: auto">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- BANK CODE -->
            <v-dialog v-model="dialogBank" max-width="400">
                <v-card>
                    <v-card-title class="headline orange darken-1">
                        <v-row no-gutters>
                            <v-col class="text-uppercase">
                                <span style="color:white">Código</span>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="pb-0">
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="debitToChange.bank_dd"
                                        outlined
                                        placeholder="DD00000000"
                                        :rules="[rules.required, rules.length]"
                                        label="Código do Banco"
                                ></v-text-field>
                                <v-divider></v-divider>
                                <p class="text-center mb-0">Os emails são enviados após confirmar</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogBank = false" class="ma-2">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="insertBankCodeConfirm()" class="ma-2">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- GENERIC DIALOG -->
            <v-dialog v-model="dialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ dialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">

                            <template v-if="dialog.loading">
                                <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
                            </template>

                            <template v-if="dialog.progress">
                                <v-progress-circular rotate="-90" color="primary" size="100"
                                                     :value="dialog.progressCurrent">
                                    {{ linhasProcessadas }}/{{ linhasTotal }}
                                </v-progress-circular>
                            </template>

                            <template v-if="!dialog.loading && !dialog.progress">
                                <i v-if="dialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!dialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ dialog.message }}</p>
                                <v-alert v-if="dialog.alert" type="error">
                                    {{ dialog.alertMessage }}
                                </v-alert>
                            </template>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="!dialog.loading && !dialog.progress" color="success" @click="dialog.open = false"
                               style="margin-left: auto">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import FileUpload from 'v-file-upload'

export default {
    components: {
        FileUpload
    },
    data: () => ({
        loading: false,
        debits: [],
        lines: [],
        linhasTotal: 0,
        linhasProcessadas: 0,
        linhasOK: 0,
        linhasNOK: 0,
        estornos: 0,
        banks: [],
        debitToChange: {},
        dialogChangeDate: false,
        dialogAddDebit: false,
        menu2: false,
        menu3: false,
        rules: {
            required: value => !!value || value === '0' || 'Este campo é obrigatório.',
            length: value => (value || '').length === 10 || 'Este campo tem de ter 10 caracteres'
        },
        newDebit: {
            id: '',
            name: '',
            bank: '',
            date: '',
            comments: ''
        },
        editModeDebit: false,
        dialogBank: false,
        dialog: {
            open: false,
            loading: false,
            progress: false,
            error: false,
            alert: false,
            title: '',
            message: '',
            alertMessage: '',
            progressCurrent: 0,
        },
        url: '',
        headers: {},
        filesUploaded: [],
        linhasXML: [],
    }),
    created() {
        document.title = 'Débitos Directos';
        this.url = process.env.VUE_APP_API_URL + api.debitsUploadXML();
        this.headers = {'Authorization': 'Bearer ' + User.getters.getAccessToken};
        this.getDebits()
    },
    methods: {
        onUploadChange() {
            this.newDialog('A processar o ficheiro XML...');
        },
        onUploadError(response) {
            this.errorDialog('Ocorreu um erro ao importar o ficheiro');
            console.log(response);
        },
        onUploadSuccess(response) {
            let responseData = response.target.response;
            this.dialog.loading = false;
            if (responseData.status === 'OK') {

                let TxInfAndSts = responseData.xml.CstmrPmtStsRpt.OrgnlPmtInfAndSts.TxInfAndSts;
                this.linhasXML = [];
                if (TxInfAndSts.length === undefined) {
                    this.linhasXML.push(TxInfAndSts);
                } else {
                    for (let i = 0; i < TxInfAndSts.length; i++) {
                        this.linhasXML.push(TxInfAndSts[i]);
                    }
                }
                this.linhasTotal = this.linhasXML.length;
                this.linhasProcessadas = 0;
                this.estornos = 0;
                this.linhasOK = 0;
                this.linhasNOK = 0;
                this.dialog.loading = false;
                this.dialog.progress = true;
                this.dialog.progressCurrent = 0;
                this.processXmlLine()
            } else {
                this.errorDialog(responseData.message)
            }
        },
        processXmlLine() {
            let linhaXML = this.linhasXML[this.linhasProcessadas];
            if (this.linhasProcessadas < this.linhasTotal) {
                let request = {
                    url: api.debitsProcessXmlLine(),
                    data: {
                        linha: linhaXML
                    }
                };
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.linhasOK++;
                                this.linhasProcessadas++;
                                this.dialog.progressCurrent = Math.ceil(this.linhasProcessadas / this.linhasTotal * 100);
                                this.processXmlLine()
                            } else if (responseData.status === 'END') {
                                this.linhasOK++;
                                this.estornos++;
                                this.linhasProcessadas++;
                                this.dialog.progressCurrent = Math.ceil(this.linhasProcessadas / this.linhasTotal * 100);
                                this.processXmlLine()
                            } else {
                                this.linhasNOK++;
                                this.linhasProcessadas++;
                                this.dialog.progressCurrent = Math.ceil(this.linhasProcessadas / this.linhasTotal * 100);
                                this.processXmlLine()
                            }
                        })
                        .catch(() => {
                            this.linhasNOK++;
                            this.linhasProcessadas++;
                            this.dialog.progressCurrent = Math.ceil(this.linhasProcessadas / this.linhasTotal * 100);
                            this.processXmlLine()
                        })
            } else {
                if (this.linhasOK > 0) {
                    this.dialog.message = this.linhasOK + ' linhas processadas. Foram efectuados ' + this.estornos + ' estornos.';
                    if (this.linhasNOK > 0) {
                        this.dialog.alertMessage = this.linhasNOK + ' linhas não foram processadas!';
                        this.dialog.alert = true;
                    }
                } else {
                    this.dialog.message = 'Nenhuma linha foi processada';
                    this.dialog.error = true;
                }
                this.dialog.progress = false;
                this.getDebits();
            }
        },
        getDebits() {
            this.loading = true
            this.debits = []
            this.banks = []
            let request = {
                url: api.debits(),
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.debits = responseData.debits;
                            this.banks = responseData.banks;
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        changeDate(debit) {
            this.debitToChange = Object.assign({}, debit)
            this.dialogChangeDate = true
        },
        changeDateConfirm() {
            if (this.$refs.form.validate()) {
                let request = {
                    url: api.debitsChange(),
                    data: {
                        id: this.debitToChange.ID,
                        field: 'charge_date',
                        value: this.debitToChange.new_date
                    }
                }
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.getDebits()
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Data alterada com sucesso!',
                                    type: 'success'
                                })
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao alterar data!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
                this.dialogChangeDate = false
            }
        },
        addDebitConfirm() {
            if (this.$refs.addDebit.validate()) {
                this.loading = true

                if (!this.editModeDebit) {
                    let request = {
                        url: api.debitsAdd(),
                        data: {
                            debit: this.newDebit
                        }
                    };
                    ApiWS.dispatch('makePost', request)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    this.getDebits()
                                    Alert.dispatch('show', {
                                        snackbar: true,
                                        text: 'Novo débito adicionado com sucesso!',
                                        type: 'success'
                                    })
                                } else {
                                    Alert.dispatch('show', {
                                        snackbar: true,
                                        text: 'Erro ao adicionar débito!',
                                        type: 'error'
                                    })
                                }
                                this.loading = false
                            })
                            .catch(response => {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro de servidor!',
                                    type: 'error'
                                })
                            })
                } else {
                    let request = {
                        url: api.debitsEdit(),
                        data: {
                            debit: this.newDebit
                        }
                    };

                    ApiWS.dispatch('makePost', request)
                            .then(response => {
                                let responseData = response.data;
                                if (responseData.status === 'OK') {
                                    this.getDebits();
                                    Alert.dispatch('show', {
                                        snackbar: true,
                                        text: 'Débito editado com sucesso!',
                                        type: 'success'
                                    })
                                } else {
                                    Alert.dispatch('show', {
                                        snackbar: true,
                                        text: 'Erro ao editar débito!',
                                        type: 'error'
                                    })
                                }
                            })
                            .catch(response => {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro de servidor!',
                                    type: 'error'
                                })
                            })
                }
                this.newDebit = {
                    id: '',
                    name: '',
                    bank: '',
                    date: '',
                    comments: ''
                };
                this.dialogAddDebit = false;
                this.editModeDebit = false
            }
        },
        makeReceipts(id) {
            this.newDialog('A gerar recibos...')
            let request = {
                url: api.debitsReceipts(),
                data: {
                    id: id,
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        this.dialog.message = response.data.message
                        this.dialog.error = response.data.status !== 'OK';
                        this.dialog.loading = false
                        this.getDebits()
                    })
                    .catch(response => {
                        this.errorDialog('Erro de servidor!')
                    })
        },
        makeXML(id, name) {
            this.newDialog('A gerar ficheiro XML...')
            let request = {
                url: api.debitsXml(),
                data: {
                    id: id,
                    name: name,
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        this.dialog.message = response.data.message
                        this.dialog.error = response.data.status !== 'OK';
                        this.dialog.loading = false
                        this.getDebits()
                    })
                    .catch(response => {
                        this.errorDialog('Erro de servidor!')
                    })
        },
        downloadXML(id, name) {
            let request = {
                url: api.debitsDownload(),
                data: {
                    id: id,
                    name: name,
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'text/xml'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = name + '.xml';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        this.getDebits()
                    })
                    .catch(response => {
                        this.newDialog('A gerar ficheiro XML...')
                        this.dialog.message = 'Erro de servidor!'
                        this.dialog.error = true
                        this.dialog.loading = false
                    })

        },
        insertBankCode(debit) {
            this.debitToChange = Object.assign({}, debit);
            this.dialogBank = true
        },
        callResendEmails(debit) {
            this.debitToChange = Object.assign({}, debit);
            this.insertBankCodeConfirm();
        },
        insertBankCodeConfirm() {
            this.dialogBank = false;
            this.newDialog('A atualizar o código do banco...');
            let request = {
                url: api.debitsBank(),
                data: {
                    id: this.debitToChange.ID,
                    bank_dd: this.debitToChange.bank_dd
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        if (response.data.status === 'OK') {
                            this.dialog.message = 'Os emails vão ser enviados em segundo plano.';
                            this.dialog.loading = false;
                        } else {
                            this.errorDialog(response.data.message)
                        }
                        this.getDebits()
                    })
                    .catch(() => {
                        this.errorDialog('Erro de servidor!')
                    })
        },
        // sendEmails(id) {
        //     let request = {
        //         url: api.debitsDetail() + '/' + id
        //     };
        //     ApiWS.dispatch('makeGet', request)
        //         .then(response => {
        //             let responseData = response.data;
        //             if (responseData.status === 'OK') {
        //                 this.lines = responseData.lines;
        //                 this.linhasTotal = this.lines.length;
        //                 this.linhasProcessadas = 0;
        //                 this.linhasOK = 0;
        //                 this.linhasNOK = 0;
        //                 this.dialog.title = 'A enviar os emails...';
        //                 this.dialog.loading = false;
        //                 this.dialog.progress = true;
        //                 this.dialog.progressCurrent = 0;
        //                 // this.sendSingleEmail()
        //             }
        //         })
        //         .catch(response => {
        //             this.errorDialog('Erro de servidor!')
        //         })
        // },
        // sendSingleEmail() {
        //     let debito = this.lines[this.linhasProcessadas];
        //     if (this.linhasProcessadas < this.linhasTotal) {
        //         let request = {
        //             url: api.debitsSendEmail(),
        //             data: {
        //                 debito: debito.id_line,
        //                 doc: debito.DocOriginal,
        //                 serie: debito.Serie,
        //                 tipoDoc: debito.TipoDoc,
        //                 numDoc: debito.NumDoc,
        //                 valor: debito.amount_to_pay,
        //                 nome: debito.nome,
        //                 data: this.debitToChange.charge_date,
        //                 iban: debito.iban,
        //                 swift: debito.swift
        //             }
        //         };
        //         ApiWS.dispatch('makePost', request)
        //             .then(response => {
        //                 let responseData = response.data;
        //                 if (responseData.status === 'OK') {
        //                     this.linhasOK++;
        //                     this.linhasProcessadas++;
        //                     this.dialog.progressCurrent = Math.ceil(this.linhasProcessadas / this.linhasTotal * 100);
        //                     // this.sendSingleEmail()
        //                 } else {
        //                     this.linhasNOK++;
        //                     this.linhasProcessadas++;
        //                     this.dialog.progressCurrent = Math.ceil(this.linhasProcessadas / this.linhasTotal * 100);
        //                     // this.sendSingleEmail()
        //                 }
        //             })
        //             .catch(response => {
        //                 this.linhasNOK++;
        //                 this.linhasProcessadas++;
        //                 this.dialog.progressCurrent = Math.ceil(this.linhasProcessadas / this.linhasTotal * 100);
        //                 // this.sendSingleEmail()
        //             })
        //     } else {
        //         if (this.linhasOK > 0) {
        //             this.dialog.message = this.linhasOK + ' emails enviados com sucesso.';
        //             if (this.linhasNOK > 0) {
        //                 this.dialog.alertMessage = this.linhasNOK + ' emails não foram enviados';
        //                 this.dialog.alert = true;
        //             }
        //         } else {
        //             this.dialog.message = 'Nenhum email foi enviado';
        //             this.dialog.error = true;
        //         }
        //         this.dialog.progress = false;
        //     }
        // },
        editDebit(debit) {
            let bankSelect = '';
            this.newDebit.id = debit.ID;
            this.newDebit.name = debit.name;
            this.banks.forEach(function (element) {
                if (element.text === debit.Bank_name) {
                    bankSelect = element.value
                }
            });
            this.newDebit.bank = bankSelect;
            this.newDebit.date = debit.charge_date;
            this.newDebit.comments = debit.comments;
            this.editModeDebit = true;
            this.dialogAddDebit = true
        },
        newDialog(title) {
            this.dialog.title = title;
            this.dialog.message = '';
            this.dialog.alertMessage = '';
            this.dialog.loading = true;
            this.dialog.progress = false;
            this.dialog.progressCurrent = 0;
            this.dialog.error = false;
            this.dialog.alert = false;
            this.dialog.open = true
        },
        errorDialog(message) {
            this.dialog.message = message
            this.dialog.loading = false
            this.dialog.progress = false
            this.dialog.error = true
        }
    },
    computed: {
        minDate() {
            return new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
        }
    }
}
</script>
