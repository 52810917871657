<style>
.selectedPage {
  font-weight: bold;
  color: blue;
  font-size: 21px;
}
.selectedPage:hover{
  cursor:default !important;
}
.selectablePage{
  margin-left: 15px;
  text-align:center;
  display: inline-block;
  width: 1rem;
}
.selectablePage:hover{
  cursor:pointer;
  font-size: 21px;
  color: #5a5aff;
}

</style>
<template>
  <v-container fluid>

    <!-- HEADER -->
    <v-row class="content-header">
      <v-col class="col-8">
        <page-title
            title="Formações B2B - Níveis"
        ></page-title>
      </v-col>
      <v-col class="text-right col-2">
        <v-btn tile small class="ml-2 mb-2" @click="cleanFilters()" color="error">Limpar Filtros</v-btn>
      </v-col>
      <v-col class="text-right col-2">
        <v-btn tile small class="ml-2 mb-2" @click="dialogLevel = true" color="info">Novo Nível</v-btn>
      </v-col>
    </v-row>

    <!-- Tabela Formações -->
    <v-row>
      <v-col>
        <div class="staff_v-card">
          <v-simple-table class="staff_table">
            <thead>
            <tr class="titles_row">
              <th style="width: 200px; cursor: pointer;" @click="orderList('id')" class="text-center">
                Id
                <i v-if="selectedHeader === 'id'" class="fas" :class="[headers['id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('name')" class="text-center">
                Nome do Nível
                <i v-if="selectedHeader === 'name'" class="fas" :class="[headers['name'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('namePT')" class="text-center">
                Nome do Nível em PT
                <i v-if="selectedHeader === 'namePT'" class="fas" :class="[headers['namePT'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('nameES')" class="text-center">
                Nome do Nível em ES
                <i v-if="selectedHeader === 'nameES'" class="fas" :class="[headers['nameES'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('days')" class="text-center">
                Duração da Formação (dias)
                <i v-if="selectedHeader === 'days'" class="fas" :class="[headers['days'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('codPri')" class="text-center">
                Código Primavera
                <i v-if="selectedHeader === 'codPri'" class="fas" :class="[headers['codPri'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('created_at')" class="text-center">
                Data Criação
                <i v-if="selectedHeader === 'created_at'" class="fas" :class="[headers['created_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
            <tr class="filters_row">
              <td>
                <v-text-field
                    v-model="filters.id"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.name"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.namePT"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.nameES"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.days"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.codPri"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.created_at"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="3">
                <v-progress-linear indeterminate></v-progress-linear>
              </td>
            </tr>
            <tr v-for="level in pagedLevels">
              <td class="text-center">{{ level.id }}</td>
              <td>{{ level.name }}</td>
              <td>{{ level.namePT }}</td>
              <td>{{ level.nameES }}</td>
              <td class="text-center">{{ level.days }}</td>
              <td class="text-center">{{ level.codPri }}</td>
              <td >{{ level.created_at }}</td>
              <td class="text-center">
                <v-btn small v-if="writePermission('courses/levels')" color="primary"
                       @click="levelEdition(level)">
                  Editar
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn small v-if="writePermission('courses/levels')" color="error"
                       @click="removingLevel(level)">
                  <i class="fas fa-times"></i>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogLevel" persistent max-width="720">
      <v-card>
        <v-card-title v-if="editLevel === false">
          <strong>Criar Novo Nível</strong>
        </v-card-title>
        <v-card-title v-if="editLevel === true">
          <strong>Editar Nível</strong>
        </v-card-title>
        <v-card-text>
          <v-form
              ref="form"
          >

<!--            <p class="mb-0"><strong>Nome: </strong></p>-->
            <v-text-field class="mb-0" dense v-model="level.name" label="Nome" outlined hide-details></v-text-field>

<!--            <p class="mt-7 mb-0"><strong>Nome do Level em Português: </strong></p>-->
            <v-text-field class="mt-7 mb-0" dense v-model="level.namePT" label="Nome em Português" outlined hide-details></v-text-field>

<!--            <p class="mt-7  mb-0"><strong>Nome do Level em Espanhol: </strong></p>-->
            <v-text-field class="mt-7 mb-0" dense v-model="level.nameES" label="Nome em Espanhol" outlined hide-details></v-text-field>

<!--            <p class="mt-7 mb-0"><strong>Duração em dias: </strong></p>-->
            <v-text-field class="mt-7 mb-0" dense v-model="level.days" label="Duração" outlined hide-details></v-text-field>

<!--            <p class="mt-7 mb-0"><strong>Cod. Primavera: </strong></p>-->
            <v-text-field class="mt-7 mb-0" dense v-model="level.codPri" label="Código Primavera" outlined hide-details></v-text-field>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cleanLevel" color="error">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="createLevel(level)" color="success" :disabled="levelDataValidator" v-if="editLevel === false">
            Criar Nível
          </v-btn>
          <v-btn @click="updateLevel(level)" color="success" :disabled="levelDataValidator" v-if="editLevel === true">
            Confimar Atualização
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRemoveLevel" persistent max-width="400">
      <v-card>
        <v-card-title>
          Eliminar Nível
        </v-card-title>
        <v-card-text>
          Confirma que pretende eliminar o nível?
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="dialogRemoveLevel = false">Cancelar</v-btn>
          <v-btn color="success" style="margin-left: auto" @click="deleteLevel(level)">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
import _ from "lodash";

export default {
  data: () => ({
    menu2: false,
    menu3: false,
    loading: false,
    levels: [],
    editLevel: false,
    total: 0,
    originalLevels: [],
    pagination: {
      rowsPerPage: 250,
      page: 1
    },
    filters: {
      initialDate: '',
      finalDate: '',
      id: '',
      name: '',
      nameES: '',
      namePT: '',
      codPri: '',
      days: '',
      created_at: '',
    },
    itemPerPage: [
      {text: '50', value: 50},
      {text: '100', value: 100},
      {text: '250', value: 250},
      {text: '500', value: 500}
    ],
    currentDate: new Date().toISOString().substr(0, 10),
    selectedHeader: "id",
    headers: {
      "id": "asc",
      "name": "asc",
      "nameES": "asc",
      "namePT": "asc",
      "days": "asc",
      "codPri": "asc",
      "created_at": "desc",
    },
    dialogLevel: false,
    dialogRemoveLevel: false,
    level: {
      name: '',
      namePT: '',
      nameES: '',
      days: '',
      codPri: '',
      created_at: '',
    }
  }),
  mounted() {
    document.title = 'Formações - Níveis';
    this.initialize()
  },
  methods: {
    initialize() {

      this.loading = true;

      let request = {
        url: '/api/staff/courses/levels',
        data: {
          pagination: this.pagination
        }
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              this.originalLevels = responseData.data;
              this.levels = responseData.data;
              this.total = this.levels.length;
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
      this.loading = false
    },
    orderList(col){
      if(col == this.selectedHeader)
        this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
      else
        this.headers[this.selectedHeader] = "desc";

      this.selectedHeader = col;
      this.pagination.page=1;

    },
    renderDuration(duration) {
      var modDays = duration % 86400;
      var days = (duration - modDays) / 86400;
      var labelDays = '';
      if (days > 0) {
        labelDays = days + 'D ';
      }
      //obter as horas
      var modHours = modDays % 3600;
      var hours = (modDays - modHours) / 3600;
      //obter os minutos
      var modMinutes = modHours % 60;
      var minutes = (modHours - modMinutes) / 60;
      //os segundos sao o resto de tudo (modMinutes neste caso)
      return labelDays + hours + 'h ' + minutes + 'm ' + modMinutes + 's';
    },
    updateTable() {
      this.levels= this.originalLevels

      if (this.filters.initialDate !== '') {
        this.levels = this.levels.filter(level => level.created_at >= this.filters.initialDate + ' 00:00:00')
      }
      if (this.filters.finalDate !== '') {
        this.levels = this.levels.filter(level => level.created_at <= this.filters.finalDate + ' 23:59:59')
      }
      if (this.filters.id !== '') {
        this.levels = this.levels.filter(level => level.id == this.filters.id)
      }
      if (this.filters.name !== '') {
        this.levels = this.levels.filter(level =>
            level.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.namePT !== '') {
        this.levels = this.levels.filter(level =>
            level.namePT.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.namePT.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.nameES !== '') {
        this.levels = this.levels.filter(level =>
            level.nameES.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.nameES.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.days !== '') {
        this.levels = this.levels.filter(level => level.days == this.filters.days)
      }
      if (this.filters.codPri !== '') {
        this.levels = this.levels.filter(level => level.codPri.includes(this.filters.codPri))
      }
      if (this.filters.status !== '') {
        this.levels = this.levels.filter(level => level.active === this.filters.status)
      }
      if (this.filters.created_at !== '') {
        this.levels = this.levels.filter(level => level.created_at != null && level.created_at.includes(this.filters.created_at))
      }

      this.total = this.levels.length

    },
    cleanFilters(){
      this.filters = _.mapValues(this.filters, () => '')
      this.updateTable()
    },
    cleanLevel(){
      // this.level.name= ''
      // this.level.namePT= ''
      // this.level.nameES= ''
      // this.level.days= ''
      // this.level.codPri= ''

      this.level = _.mapValues(this.level, () => '')
      this.dialogRemoveLevel = false
      this.dialogLevel=false
      this.editLevel= false

    },
    deleteLevel(level) {

      let request = {
        url: '/api/staff/courses/levels/'+level.id,
      }

      ApiWS.dispatch('makeDelete', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Registo eliminado com sucesso!',
                type: 'success'
              })

              this.cleanLevel()
              this.initialize()
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    createLevel(level) {
      let request = {
        url: '/api/staff/courses/levels/',
        data: level
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Formação criada com sucesso!',
                type: 'success'
              })

              this.cleanLevel()

              this.initialize()
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    levelEdition(level){
      this.cleanLevel()
      Object.assign(this.level, level)

      this.editLevel = true
      this.dialogLevel = true
    },
    updateLevel(level) {

      let request = {
        url: '/api/staff/courses/levels/' + level.id,
        data: level
      }

      ApiWS.dispatch('makePut', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Formação atualizada com sucesso!',
                type: 'success'
              })

              this.cleanLevel()
              this.initialize()
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    writePermission(permission) {
      return User.getters.writePermission(permission)
    },
    removingLevel(level) {
      Object.assign(this.level, level)
      this.dialogRemoveLevel=true
    }
  },

  computed: {
    getTotalPages() {
      return Math.ceil(this.total / this.pagination.rowsPerPage)
    },
    pagedLevels: function(){
      return this.orderedLevels.slice((this.pagination.page-1)*this.pagination.rowsPerPage,this.pagination.page*this.pagination.rowsPerPage);
    },
    orderedLevels: function () {
      return _.orderBy(this.levels, this.selectedHeader,this.headers[this.selectedHeader]);
    },
    levelDataValidator: function () {
      return  !(this.level.name !== '' &&
          this.level.namePT !== '' &&
          this.level.nameES !== '' &&
          this.level.days !== '' &&
          this.level.codPri !== '' );
    }
  }
}
</script>
