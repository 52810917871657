<style scoped>
#supplier-id-details {
    border: #cc0b0f solid;
    border-radius: 13px;
    padding: 3px;
    background-color: whitesmoke;
}

#supplier-id-details:hover {
    cursor: pointer;
}

.clear-filters-link {
    color: #1976d2;
    cursor: pointer;
}

</style>

<template>
    <v-container fluid>
        <template v-if="loaded == true">
            <v-row class="content-header">
                <v-col>
                    <h6 class="title text-uppercase" style="min-height: 32px;">Amazon - Listagem</h6>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <div class="staff_v-card mb-3">
                        <v-simple-table class="staff_table mt-2">
                            <thead>
                            <tr>
                                <th>Data</th>
                                <th>Entidade</th>
                                <th class="text-center">Total Merc</th><!--<th width="100">EAN</th>-->
                                <th class="text-center">Total Iva</th>
                                <!--                            <th width="10">N.Linha</th>-->
                                <th class="text-center">Total Doc</th>
                                <th class="text-center">Total Outros</th>
                                <th class="text-center">Tipo Doc</th>
                                <th class="text-center">Num Doc</th>
                                <th class="text-center">Referencia</th>

                            </tr>
                            <tr class="filters_row">
                                <td>
                                    <v-text-field
                                            :value="filters.Data.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'Data')"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filters.Entidade.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'Entidade')"
                                    ></v-text-field>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <v-text-field
                                            :value="filters.TipoDoc.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'TipoDoc')"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filters.NumDoc.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'NumDoc')"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filters.Referencia.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'Referencia')"
                                    ></v-text-field>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(order, orderIndex) in orders" v-if="orders.length > 0">
                                <td>{{ order.Data }}</td>
                                <td>{{ order.Entidade }}</td>
                                <td class="text-center">{{ $dollar(order.TotalMerc) }}</td>
                                <td class="text-center">{{ $dollar(order.TotalIva) }}</td>
                                <td class="text-center">{{ $dollar(order.TotalDocumento) }}</td>
                                <td class="text-center">{{ $dollar(order.TotalOutros) }}</td>
                                <td class="text-center">{{ order.TipoDoc }}</td>
                                <td class="text-center">{{ order.NumDoc }}</td>
                                <td class="text-center">{{ order.Referencia }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </div>

                </v-col>
            </v-row>


        </template>
    </v-container>

</template>

<script>

export default {
    data: () => ({
        loaded: false,
        orders: [],
        originalOrders: [],
        filters: {
            Data: {value: null, exact: false},
            Entidade: {value: null, exact: false},
            TipoDoc: {value: null, exact: false},
            NumDoc: {value: null, exact: false},
            Referencia: {value: null, exact: false},
        },
    }),
    private: {},
    components: {},
    created() {
        document.title = 'Amazon Listing';
        this.getOrders();
        //this.getOpenOrderDetail();
    },
    methods: {
        filterValues() {
            let requestedFilters = [];
            Object.entries(this.filters).forEach(([filter, value]) => {
                if (value.value != null) {
                    requestedFilters[requestedFilters.length] = filter
                }
            });


            if (requestedFilters.length == 0) {
                this.orders = this.originalOrders;
            } else {
                requestedFilters.forEach((filter, index) => {
                    if (index == 0) {
                        this.orders = this.originalOrders.filter((order) => this.filters[filter].exact ? (typeof this.filters[filter].bigger !== undefined && this.filters[filter].value !== 0 ? order[filter] >= this.filters[filter].bigger : order[filter] == this.filters[filter].value) : order[filter].toUpperCase().includes(this.filters[filter].value.toUpperCase()));
                    } else {
                        this.orders = this.orders.filter((order) => this.filters[filter].exact ? (typeof this.filters[filter].bigger !== undefined && this.filters[filter].value !== 0 ? order[filter] >= this.filters[filter].bigger : order[filter] == this.filters[filter].value) : order[filter].toUpperCase().includes(this.filters[filter].value.toUpperCase()));
                    }
                });
            }
        },
        getOrders() {
            let loader = this.$loading.show();
            let request = {url: '/api/staff/bikeplanet/orders-list'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.orders = responseData.orders;
                            this.originalOrders = responseData.orders;
                            console.log(responseData);
                            this.loaded = true;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        updateValue(event, target){
                this.filters[target].value = event.target.value;
                this.filterValues();
        }
    }
}
</script>
