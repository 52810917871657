<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="4">
<!--                <page-title :title="$t('products')"
                            :subtitle="$t('show') + ' ' + currentRecords + ' de ' + totalProducts + ' ' + $t('products')"></page-title>-->
                <page-title :title="$t('products')"
                            :subtitle="$t('show') + ' ' + totalProducts + ' ' + $t('products')"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small color="success" class="mr-4" :disabled="selectedProducts.length == 0"
                       @click="dialogConfirm = true">
                    Corrigir Selecionados
                </v-btn>
            </v-col>
        </v-row>
<!--        <v-row>
            <v-col cols="3" sm="2" md="1" class="offset-md-7 offset-sm-6">
                <v-select
                        v-model="countPerPage"
                        :items="countPerPageList"
                        outlined
                        single-line
                        dense
                        style="width: 90px;"
                        hide-details
                        @change="changePageCount"
                ></v-select>
            </v-col>
            <v-col cols="6" sm="4">
                <v-pagination
                        v-model="currentPage"
                        :length="Math.ceil(totalProducts/countPerPage)"
                        :total-visible="7"
                        @input="changePage"
                        style="width: auto; float: right;"
                        circle
                ></v-pagination>
            </v-col>
        </v-row>-->
        <v-row class="mt-0">
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th style="width: 4%"></th>
                            <th style="width: 8%;">Artigo</th>
                            <th style="width: 36%;">{{ $t('description') }}</th>
                            <th style="width: 11%;">Marca</th>
                            <th style="width: 11%;">Familia</th>
                            <th style="width: 10%;">EAN</th>
                            <th style="width: 10%;">EAN Corrigido</th>
                            <th style="width:10%"></th>
                        </tr>
                        <tr class="filters_row">
                            <td style="width:2px">
                                <span style="cursor: pointer; margin-left: 2px">
                                    <i v-if="!checkBoxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                                    <i v-if="checkBoxAll" class="fas fa-check-square fa-lg" style="color:#1976d2"
                                       @click="unselectAll()"></i>
                                    ({{ selectedProducts.length }})
                                </span>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.productId.value"
                                        outlined
                                        @keyup.enter="filter()"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.brand.value"
                                        :items="availableBrands"
                                        outlined
                                        dense
                                        @change="filter()"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                        style="width: 90%"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.category.value"
                                        :items="availableCategories"
                                        outlined
                                        dense
                                        @change="filter()"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                        style="width: 90%"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.ean.value"
                                        outlined
                                        @keyup.enter="filter()"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="20">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="(product, index) in products" :key="product.productId">
                            <td @click="product.selected = !product.selected">
                                <span style="cursor: pointer">
                                    <i v-if="!product.selected" class="far fa-square fa-lg"
                                    ></i>
                                    <i v-if="product.selected" class="fas fa-check-square fa-lg" style="color:#1976d2"
                                    ></i>
                                </span>
                            </td>
                            <td @click="product.selected = !product.selected">{{ product.productId }}</td>
                            <td @click="product.selected = !product.selected">{{ product.description }}</td>
                            <td @click="product.selected = !product.selected">{{ product.brand }}</td>
                            <td @click="product.selected = !product.selected">{{ product.category }}</td>
                            <td style="background-color: #ffc9c9" @click="product.selected = !product.selected">
                                {{ product.ean }}
                            </td>
                            <td style="background-color: #bfffbf" @click="product.selected = !product.selected">
                                {{ product.fixedEan }}
                            </td>
                            <td style="text-align:center">
                                <v-btn small color="primary" @click="openSingleDialog(product, index)">Corrigir</v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogConfirmSingle" persistent max-width="400">
            <v-card>
                <v-card-title>
                    {{ $t('cancel') }} {{ $t('product') }}
                </v-card-title>
                <v-card-text>
                    <strong>{{ $t('confirmCancelProducts') }}:</strong>
                    <!--        <span> {{ productToCancel.productId + ' - ' + productToCancel.brandId + ' - ' + productToCancel.name }}?</span>-->
                    <hr style="border: 1px solid black;">
                    <br>
                    <span>Confirmar a atualizacao de EAN do produto {{ selectedProduct.productId }}?</span>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="closeSingleDialog()">Fechar</v-btn>
                    <v-btn :disabled="selectedProduct=={}" color="success" style="margin-left: auto"
                           @click="confirmUpdate(false);closeSingleDialog()">Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConfirm" persistent max-width="400">
            <v-card>
                <v-card-title>
                    {{ $t('cancel') }} {{ $t('product') }}
                </v-card-title>
                <v-card-text>
                    <strong>{{ $t('confirmCancelProducts') }}:</strong>
                    <!--        <span> {{ productToCancel.productId + ' - ' + productToCancel.brandId + ' - ' + productToCancel.name }}?</span>-->
                    <hr style="border: 1px solid black;">
                    <br>
                    <span>Confirmar a atualizacao do EAN de {{ selectedProducts.length }} produtos?</span>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogConfirm = false">Fechar</v-btn>
                    <v-btn :disabled="selectedProducts.length==0" color="success" style="margin-left: auto"
                           @click="confirmUpdate(true);dialogConfirm = false">Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
            selectedProductIndex: null,
            selectedProduct: {},
            products: [],
            originalProducts: [],
            loading: false,
            dialogConfirmSingle: false,
            dialogConfirm: false,
            currentRecords: "0",
            countPerPage: 50,
            countPerPageList: [50, 100, 200, 350, 500],

            currentPageOffset: 0,
            currentPage: 1,

            filters: {
                productId: {value: null, exact: false},
                ean: {value: null, exact: false},
                brand: {value: null, exact: false},
                category: {value: null, exact: false},
            },
            checkBoxAll: false,
        }
    },
    components: {},
    computed: {
        selectedProducts: function () {
            return this.products.filter((product) => product.selected);
        },
        availableCategories: function () {
            let categories = [...new Set(this.products.flatMap(product => {
                return product.category;
            }))];
            categories = categories.sort().flatMap((category) => {
                if (category != "" && category != null)
                    return {TEXT: category, VALUE: category};
                else
                    return [];
            })
            categories.splice(0, 0, {TEXT: 'Todas', VALUE: ''})
            return categories;
        },
        availableBrands: function () {
            let brands = [...new Set(this.originalProducts.flatMap(product => {
                return product.brand;
            }))];
            brands = brands.sort().flatMap((brand) => {
                if (brand != "" && brand != null)
                    return {TEXT: brand, VALUE: brand};
                else
                    return [];
            })
            brands.splice(0, 0, {TEXT: 'Todas', VALUE: ''})
            return brands;
        },
        /*pagedProducts: function () {
            return this.products.slice((this.currentPage - 1) * this.countPerPage, this.currentPage * this.countPerPage);
        },*/
        totalProducts: function () {
            return this.products.length;
        }
    },
    created() {
        this.getProducts();
    },
    methods: {
        getProducts() {
            let request = {
                url: api.products() + '/ean-check'
            };
            this.loading = true;
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.products = responseData.products;
                            this.originalProducts = responseData.products;

                         /*   if (this.countPerPage < this.countPerPageList[0])
                                this.countPerPageList = [this.countPerPage];
                            else
                                this.countPerPageList = [50, 100, 200, 350, 500];

                            this.currentRecords = ((this.currentPage - 1) * this.countPerPage) + "-" + ((this.currentPage * this.countPerPage) < this.totalProducts ? (this.currentPage * this.countPerPage) : this.totalProducts);
                        */

                        } else {
                            Alert.dispatch('show', {snackbar: true, text: 'Erro de servidor!', type: 'error'})
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {snackbar: true, text: 'Erro de servidor!', type: 'error'})
                    })
        },
        selectAll() {
            this.checkBoxAll = true
            this.products.forEach(function (product) {
                product.selected = true
            })
        },
        unselectAll() {
            this.checkBoxAll = false
            this.products.forEach(function (product) {
                product.selected = false
            })
        },
        changePageCount(count) {
            this.countPerPage = count;
            this.getProducts();
        },
        changePage(page) {
            this.currentRecords = ((this.currentPage - 1) * this.countPerPage) + "-" + ((this.currentPage * this.countPerPage) < this.totalProducts ? (this.currentPage * this.countPerPage) : this.totalProducts);
        },
        filter() {
            let requestedFilters = [];
            Object.entries(this.filters).forEach(([filter, value]) => {
                if (value.value != null) {
                    requestedFilters[requestedFilters.length] = filter
                }
            });


            if (requestedFilters.length == 0) {
                this.products = this.originalProducts;
            } else {
                requestedFilters.forEach((filter, index) => {
                    if (index == 0) {
                        this.products = this.originalProducts.filter((product) => this.filters[filter].exact ? (typeof this.filters[filter].bigger !== undefined && this.filters[filter].value !== 0 ? product[filter] >= this.filters[filter].bigger : product[filter] == this.filters[filter].value) : product[filter].toUpperCase().includes(this.filters[filter].value.toUpperCase()));
                    } else {
                        this.products = this.products.filter((product) => this.filters[filter].exact ? (typeof this.filters[filter].bigger !== undefined && this.filters[filter].value !== 0 ? product[filter] >= this.filters[filter].bigger : product[filter] == this.filters[filter].value) : product[filter].toUpperCase().includes(this.filters[filter].value.toUpperCase()));
                    }
                });
            }
            this.currentRecords = ((this.currentPage - 1) * this.countPerPage) + "-" + ((this.currentPage * this.countPerPage) < this.totalProducts ? (this.currentPage * this.countPerPage) : this.totalProducts);
        },

        openSingleDialog(product, index) {
            this.selectedProduct = product;
            this.selectedProductIndex = index;
            this.dialogConfirmSingle = true;
        },
        closeSingleDialog() {
            this.selectedProduct = {};
            this.selectedProductIndex = null;
            this.dialogConfirmSingle = false;
        },

        confirmUpdate(multi) {
            let selected = [];

            if (multi) {
                if (this.selectedProducts.length == 0)
                    return;
                selected = this.selectedProducts;
            } else {
                if (this.selectedProduct == {})
                    return;
                selected = [this.selectedProduct];
            }

            let request = {
                url: api.products() + '/ean-update',
                data: {products: selected}
            };
            let loader = this.$loading.show();
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            if (multi) {
                                this.products = this.products.filter(
                                        ({productId: id1}) =>
                                                !this.selectedProducts.some(
                                                        ({productId: id2}) => id2 === id1
                                                )
                                );
                            } else {
                                this.products.splice(this.selectedProductIndex, 1);
                            }
                            Alert.dispatch('show', {snackbar: true, text: 'Atualizado com sucesso!', type: 'success'})
                        } else {
                            Alert.dispatch('show', {snackbar: true, text: 'Erro de servidor!', type: 'error'})
                        }
                        this.loading = false;
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {snackbar: true, text: 'Erro de servidor!', type: 'error'})
                    })


        }
    },
    watch: {}
}
</script>
