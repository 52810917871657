<style>
.selectedPage {
  font-weight: bold;
  color: blue;
  font-size: 21px;
}
.selectedPage:hover{
  cursor:default !important;
}
.selectablePage{
  margin-left: 15px;
  text-align:center;
  display: inline-block;
  width: 1rem;
}
.selectablePage:hover{
  cursor:pointer;
  font-size: 21px;
  color: #5a5aff;
}

</style>
<template>
  <v-container fluid>

    <!-- HEADER -->
    <v-row class="content-header">
      <v-col class="col-8">
        <page-title
            title="Formações B2B"
        ></page-title>
      </v-col>
      <v-col class="text-right col-2">
        <v-btn tile small class="ml-2 mb-2" @click="cleanFilters()" color="error">Limpar Filtros</v-btn>
      </v-col>
      <v-col class="text-right col-2">
        <v-btn tile small class="ml-2 mb-2" @click="openDialog();" color="info">Nova Formação</v-btn>
      </v-col>
    </v-row>

    <!-- Tabela Formações -->
    <v-row>
      <v-col>
        <div class="staff_v-card">
          <v-simple-table class="staff_table">
            <thead>
            <tr class="titles_row">
              <th style="max-width: 50px; cursor: pointer;" @click="orderList('id')" class="text-center">
                Id
                <i v-if="selectedHeader === 'id'" class="fas" :class="[headers['id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 150px; cursor: pointer;" @click="orderList('name')" class="text-center">
                Formação
                <i v-if="selectedHeader === 'name'" class="fas" :class="[headers['name'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 150px; cursor: pointer;" @click="orderList('namePT')" class="text-center">
                Nome PT
                <i v-if="selectedHeader === 'namePT'" class="fas" :class="[headers['namePT'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 150px; cursor: pointer;" @click="orderList('nameES')" class="text-center">
                Nome ES
                <i v-if="selectedHeader === 'nameES'" class="fas" :class="[headers['nameES'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('placeId')" class="text-center">
                Localização
                <i v-if="selectedHeader === 'placeId'" class="fas" :class="[headers['placeId'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('levelId')" class="text-center">
                Nível
                <i v-if="selectedHeader === 'levelId'" class="fas" :class="[headers['levelId'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 70px; cursor: pointer;" @click="orderList('status')" class="text-center">
                Estado
                <i v-if="selectedHeader === 'status'" class="fas" :class="[headers['status'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>

              <th style="max-width: 50px; cursor: pointer;" @click="orderList('capacity')" class="text-center">
                Capacidade
                <i v-if="selectedHeader === 'capacity'" class="fas" :class="[headers['capacity'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="max-width: 50px; cursor: pointer;" @click="orderList('reservationsTotal')" class="text-center">
                Reservadas
                <i v-if="selectedHeader === 'reservationsTotal'" class="fas" :class="[headers['reservationsTotal'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 80px; cursor: pointer;" @click="orderList('implementation')" class="text-center">
                Implementação
                <i v-if="selectedHeader === 'implementation'" class="fas" :class="[headers['implementation'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>

              <th style="cursor: pointer;" @click="orderList('date')" class="text-center">
                Data
                <i v-if="selectedHeader === 'date'" class="fas" :class="[headers['date'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
<!--              <th style="cursor: pointer;" @click="orderList('created_at')" class="text-center">-->
<!--                Data Criação-->
<!--                <i v-if="selectedHeader === 'created_at'" class="fas" :class="[headers['created_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>-->
<!--              </th>-->
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr class="filters_row">
              <td>
                <v-text-field
                    v-model="filters.id"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.name"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.namePT"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.nameES"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-select
                    class="mt-6"
                    :items="filteredPlaces"
                    outlined
                    single-line
                    v-model="filters.placeId"
                    dense
                    @change="updateTable"
                ></v-select>
              </td>
              <td>
                <v-select
                    class="mt-6"
                    :items="filteredLevels"
                    outlined
                    single-line
                    v-model="filters.levelId"
                    dense
                    @change="updateTable"
                ></v-select>
              </td>
              <td>
                <v-select
                    class="mt-6"
                    :items="status"
                    outlined
                    single-line
                    v-model="filters.status"
                    dense
                    @change="updateTable"
                ></v-select>
              </td>
              <td>
                <v-text-field
                    v-model="filters.capacity"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.reservationsTotal"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-select
                    class="mt-6"
                    :items="statusImplementation"
                    outlined
                    single-line
                    v-model="filters.implementation"
                    dense
                    @change="updateTable"
                ></v-select>
              </td>
              <td>
                <v-text-field
                    v-model="filters.date"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
<!--              <td>-->
<!--                <v-text-field-->
<!--                    v-model="filters.created_at"-->
<!--                    outlined-->
<!--                    hide-details-->
<!--                    single-line-->
<!--                    @keyup.enter="updateTable"-->
<!--                ></v-text-field>-->
<!--              </td>-->
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="3">
                <v-progress-linear indeterminate></v-progress-linear>
              </td>
            </tr>
            <tr v-for="course in pagedCourses">
              <td class="text-center">{{ course.id }}</td>
              <td>{{ course.name }}</td>
              <td>{{ course.namePT }}</td>
              <td>{{ course.nameES }}</td>
              <td class="text-center">{{ course.placeId }} - {{ course.locationName }}</td>
              <td class="text-center">{{ course.levelId }} - {{ course.levelName }} </td>
              <td class="text-center">{{ course.active == 1 ? "Activo" : "Inactivo" }}</td>
              <td class="text-center">{{ course.capacity}}</td>
              <td class="text-center">{{ course.reservationsTotal}}</td>
              <td class="text-center">{{ course.implementation== 1 ? "Sim" : "Não" }}</td>
              <td >{{ course.date }}</td>
<!--              <td >{{ course.created_at }}</td>-->
              <td class="text-center">
                <v-btn small v-if="writePermission('courses/courses')"
                       @click="getClients(course)">
                  Detalhes
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn small v-if="writePermission('courses/courses')" color="primary"
                       @click="courseEdition(course)">
                  Editar
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn small v-if="writePermission('courses/courses')" color="error"
                       @click="removingCourse(course)">
                  <i class="fas fa-times"></i>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogCourse" persistent max-width="720">
      <v-card>
        <v-card-title v-if="editCourse === false">
          <strong>Criar Nova Formação</strong>
        </v-card-title>
        <v-card-title v-if="editCourse === true">
          <strong>Editar Formação</strong>
        </v-card-title>
        <v-card-text>
          <v-form
              ref="form"
          >

<!--            <p class="mb-0"><strong>Nome Formação: </strong></p>-->
            <v-text-field class="mb-0" dense v-model="course.name" label="Nome Formação" outlined hide-details></v-text-field>

<!--            <p class="mt-7 mb-0"><strong>Nome da Formação em Português: </strong></p>-->
            <v-text-field class="mt-7 mb-0" dense v-model="course.namePT" label="Nome B2B em Português" outlined hide-details></v-text-field>

<!--            <p class="mt-7  mb-0"><strong>Nome da Formação em Espanhol: </strong></p>-->
            <v-text-field class="mt-7  mb-0" dense v-model="course.nameES" label="Nome B2B em Espanhol" outlined hide-details></v-text-field>


<!--            <p class="mt-4 mb-0"><strong>Localização: </strong></p>-->
<!--            <v-text-field dense v-model="course.placeId" label="Localização" outlined hide-details></v-text-field>-->
            <v-select
                class="mt-7 mb-0"
                item-text="placeId"
                item-value="id"
                :items="places"
                outlined
                v-model="course.placeId"
                dense
                label="Localização da Formação"
            ></v-select>

<!--            <p class="mt-1 mb-0"><strong>Nível: </strong></p>-->
<!--            <v-text-field dense v-model="course.levelId" label="Level" outlined hide-details></v-text-field>-->
            <v-select
                class="mt-1 mb-0"
                item-text="levelId"
                item-value="id"
                :items="levels"
                outlined
                v-model="course.levelId"
                dense
                label="Nível da Formação"
            ></v-select>

<!--            <p class="mt-1 mb-0"><strong>Estado da Formação: </strong></p>-->
            <v-select
                class="mt-1 mb-0"
                item-text="text"
                item-value="value"
                :items="status"
                outlined
                dense
                v-model="course.active"
                label="Estado da Formação"
            ></v-select>

            <v-select
                class="mt-1 mb-0"
                item-text="text"
                item-value="value"
                :items="statusImplementation"
                outlined
                dense
                v-model="course.implementation"
                label="Implementação"
            ></v-select>

            <v-text-field class="mt-7  mb-0" dense v-model="course.capacity" label="Capacidade de Clientes" outlined hide-details></v-text-field>


<!--            <p class="mb-0"><strong>Data da Formação: </strong></p>-->
<!--            <v-text-field dense v-model="course.date" label="Data da Formação" outlined hide-details></v-text-field>-->
<!--            <v-date-picker dense v-model="course.date" ></v-date-picker>-->
            <v-menu
                class="mb-0"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                top
                dense
                min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    dense
                    v-model="course.date"
                    label="Data da Formação"
                    prepend-icon="event"
                    readonly
                    hide-details
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="course.date" locale="pt-pt" :show-current="currentDate"
                :min="todayDate" @input="menu2 = false"></v-date-picker>
            </v-menu>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cleanCourse" color="error">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="createCourse(course)" color="success" :disabled="courseDataValidator" v-if="editCourse === false">
            Criar Formação
          </v-btn>
          <v-btn @click="updateCourse(course)" color="success" :disabled="courseDataValidator" v-if="editCourse === true">
            Confimar Atualização
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRemoveCourse" persistent max-width="400">
      <v-card>
        <v-card-title>
          Eliminar Formação
        </v-card-title>
        <v-card-text>
          Confirma que pretende eliminar a formação?
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="dialogRemoveCourse = false">Cancelar</v-btn>
          <v-btn color="success" style="margin-left: auto" @click="deleteCourse(course)">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogClients" persistent max-width="600">
      <v-card>
        <v-card-title>
          <strong class="col-12">Lista de Participantes da Formação</strong>
          <p class="col-12" style="margin-top: -25px; font-size: 0.75em; color: #999999">
            Número de Paticipantes: {{ totalClients }}
            <br>
            Vagas Reservadas: {{ totalReservations}}
          </p>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <div class="staff_v-card">
                <v-simple-table class="staff_table">
                  <thead>
                    <tr class="titles_row">
                      <th  class="text-left">
                        Id
                      </th>
                      <th  class="text-left">
                        Nome
                      </th>
                      <th  class="text-left">
                        Vagas Reservadas
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr v-for="client in clients">
                    <td>{{ client.clientId }}</td>
                    <td>{{ client.nome }}</td>
                    <td class="text-center">{{ client.quantity }}</td>
                  </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>

<!--          <v-row v-for="client in clients" class="mt-6 pl-4">-->
<!--              <strong style="font-size: 1.2em;">{{ client.clientId }} - {{ client.nome }} - {{ client.quantity }} vagas reservadas</strong>-->
<!--          </v-row>-->
        </v-card-text>
        <v-card-actions>
          <v-btn style="margin-left: auto; margin-top: 20px;" color="error" @click="dialogClients=false" >Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
import _ from "lodash";

export default {
  data: () => ({
    search: '',
    menu2: false,
    menu3: false,
    loading: false,
    courses: [],
    filteredPlaces: [],
    filteredLevels: [],
    places: [],
    levels: [],
    clients: [],
    editCourse: false,
    total: 0,
    originalCourses: [],
    pagination: {
      rowsPerPage: 250,
      page: 1
    },
    filters: {
      initialDate: '',
      finalDate: '',
      id: '',
      name: '',
      placeId: '',
      levelId: '',
      status: '',
      capacity: '',
      reservationsTotal: '',
      implementation: '',
      date: '',
      nameES: '',
      namePT: '',
      created_at: ''
    },
    itemPerPage: [
      {text: '50', value: 50},
      {text: '100', value: 100},
      {text: '250', value: 250},
      {text: '500', value: 500}
    ],
    currentDate: new Date().toISOString().substr(0, 10),
    todayDate: new Date().toISOString().slice(0, 10),
    selectedHeader: "id",
    headers: {
      "id": "asc",
      "name": "asc",
      "nameES": "asc",
      "namePT": "asc",
      "placeId": "asc",
      "levelId": "asc",
      "status": "asc",
      "capacity": "asc",
      "reservationsTotal": 'asc',
      "implementation": "asc",
      "date": "asc",
      "created_at": "desc",
    },
    dialogCourse: false,
    dialogRemoveCourse: false,
    dialogClients: false,
    totalClients: 0,
    totalReservations: 0,
    status: [
      {text: "", value: ""},
      {text: "Activo", value: 1},
      {text: "Inactivo", value: 0}
    ],
    statusImplementation: [
      {text: "", value: ""},
      {text: "Sim", value: 1},
      {text: "Não", value: 0}
    ],
    course: {
      name: '',
      namePT: '',
      nameES: '',
      placeId: '',
      levelId: '',
      active: false,
      date: '',
      implementation: false,
      capacity: ''
    }
  }),
  mounted() {
    document.title = 'Formações';
    this.initialize()
  },
  methods: {
    initialize() {
      this.courses = [];
      this.loading = true;

      let request = {
        url: '/api/staff/courses',
        data: {
          pagination: this.pagination
        }
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              this.originalCourses = responseData.data;
              this.courses = responseData.data;
              this.total = this.courses.length;
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
      this.loading = false
    },
    orderList(col){
      if(col == this.selectedHeader)
        this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
      else
        this.headers[this.selectedHeader] = "desc";

      this.selectedHeader = col;
      this.pagination.page=1;

    },
    renderDuration(duration) {
      var modDays = duration % 86400;
      var days = (duration - modDays) / 86400;
      var labelDays = '';
      if (days > 0) {
        labelDays = days + 'D ';
      }
      //obter as horas
      var modHours = modDays % 3600;
      var hours = (modDays - modHours) / 3600;
      //obter os minutos
      var modMinutes = modHours % 60;
      var minutes = (modHours - modMinutes) / 60;
      //os segundos sao o resto de tudo (modMinutes neste caso)
      return labelDays + hours + 'h ' + minutes + 'm ' + modMinutes + 's';
    },
    updateTable() {
      this.courses= this.originalCourses

      if (this.filters.initialDate !== '') {
        this.courses = this.courses.filter(course => course.created_at >= this.filters.initialDate + ' 00:00:00')
      }
      if (this.filters.finalDate !== '') {
        this.courses = this.courses.filter(course => course.created_at <= this.filters.finalDate + ' 23:59:59')
      }
      if (this.filters.id !== '') {
        this.courses = this.courses.filter(course => course.id == this.filters.id)
      }
      if (this.filters.name !== '') {
        this.courses = this.courses.filter(course =>
            course.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.namePT !== '') {
        this.courses = this.courses.filter(course =>
            course.namePT.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.namePT.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.nameES !== '') {
        this.courses = this.courses.filter(course =>
            course.nameES.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.nameES.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.placeId !== '') {
        this.courses = this.courses.filter(course => course.locationName === this.filters.placeId.split('-', 2)[1])
      }
      if (this.filters.levelId !== '') {
        this.courses = this.courses.filter(course => course.levelName === this.filters.levelId.split('-', 2)[1])
      }
      if (this.filters.status !== '') {
        this.courses = this.courses.filter(course => course.active === this.filters.status)
      }

      if (this.filters.capacity !== '') {
        this.courses = this.courses.filter(course => course.capacity == this.filters.capacity)
      }

      if (this.filters.reservationsTotal !== '') {
        this.courses = this.courses.filter(course => course.reservationsTotal == this.filters.reservationsTotal)
      }

      if (this.filters.implementation !== '') {
        this.courses = this.courses.filter(course => course.implementation == this.filters.implementation)
      }

      if (this.filters.date !== '') {
        this.courses = this.courses.filter(course => course.date.includes(this.filters.date))
      }
      if (this.filters.created_at !== '') {
        this.courses = this.courses.filter(course => course.created_at != null && course.created_at.includes(this.filters.created_at))
      }

      this.total = this.courses.length

    },
    cleanFilters(){
      this.filters = _.mapValues(this.filters, () => '')
      this.updateTable()
    },
    cleanCourse(){
      this.course = _.mapValues(this.course, () => '')
      this.dialogRemoveCourse = false
      this.dialogCourse=false
      this.editCourse= false
    },
    deleteCourse(course) {

      let request = {
        url: '/api/staff/courses/'+course.id,
      }

      ApiWS.dispatch('makeDelete', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Registo eliminado com sucesso!',
                type: 'success'
              })

              this.cleanCourse()
              this.initialize()
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    createCourse(course) {
      let request = {
        url: '/api/staff/courses',
        data: course
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Formação criada com sucesso!',
                type: 'success'
              })

              this.cleanCourse()

              this.initialize()
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    courseEdition(course){
      this.cleanCourse()
      Object.assign(this.course, course)

      this.editCourse = true

      this.openDialog()
    },
    updateCourse(course) {

      let request = {
        url: '/api/staff/courses/' + course.id,
        data: course
      }

      ApiWS.dispatch('makePut', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Formação atualizada com sucesso!',
                type: 'success'
              })

              this.cleanCourse()
              this.initialize()
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    writePermission(permission) {
      return User.getters.writePermission(permission)
    },
    openDialog() {
      let request = {
        url: '/api/staff/courses/places-levels',
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {

              this.places = responseData.places;
              this.levels = responseData.levels;
              this.dialogCourse = true
            }else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro de servidor!',
                type: 'error'
              })
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    removingCourse(course) {
      Object.assign(this.course, course)
      this.dialogRemoveCourse=true
    },
    getClients(course) {
      this.totalClients= 0

      let request = {
        url: '/api/staff/courses/'+ course.id +'/clients',
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {

              this.clients = responseData.data;
              this.totalClients = this.clients.length > 0 ? this.clients.length : 0;
              this.totalReservations =  this.clients.length > 0 ? this.clients[0].totalQuantity : 0;
              this.dialogClients = true

            } else {
              this.clients = []
              this.totalClients= 0
              this.dialogClients = true
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    }

  },

  computed: {
    getTotalPages() {
      return Math.ceil(this.total / this.pagination.rowsPerPage)
    },
    pagedCourses: function(){
      return this.orderedCourses.slice((this.pagination.page-1)*this.pagination.rowsPerPage,this.pagination.page*this.pagination.rowsPerPage);
    },
    orderedCourses: function () {
      this.filteredPlaces = [...new Set(this.originalCourses.map(place => place.placeId + '-' + place.locationName))];
      this.filteredLevels = [...new Set(this.originalCourses.map(level => level.levelId + '-' + level.levelName))];
      this.filteredPlaces.sort().unshift('')
      this.filteredLevels.sort().unshift('')

      return _.orderBy(this.courses, this.selectedHeader,this.headers[this.selectedHeader]);
    },
    courseDataValidator: function () {
       return  !(this.course.name !== '' &&
          this.course.namePT !== '' &&
          this.course.nameES !== '' &&
          this.course.placeId !== '' &&
          this.course.levelId !== '' &&
          this.course.active !== '' &&
          this.course.capacity !== '' &&
          this.course.implementation !== '' &&
          this.course.date !== '');
    }
  }
}
</script>
