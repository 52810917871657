import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"ma-2"},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dense":"","dark":""}},[_c(VToolbarTitle,{staticClass:"subtitle-1"},[_vm._v("HISTÓRICO DA CAMPANHA")]),_c(VSpacer)],1),(_vm.loading)?_c(VListItem,[_c(VProgressLinear,{attrs:{"height":"2","indeterminate":"","color":"blue darken-4"}})],1):_vm._e(),(!_vm.loading)?_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"ma-2"},[_c(VSimpleTable,{staticClass:"staff_table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Cliente")]),_c('th',[_vm._v("Nome")]),_c('th',{staticClass:"text-center"},[_vm._v("Nível")]),_c('th',{staticClass:"text-center"},[_vm._v("Cupão")]),_c('th',{staticClass:"text-center",attrs:{"width":"130"}},[_vm._v("Data")])])]),_c('tbody',_vm._l((_vm.history),function(item){return _c('tr',[_c('td',[_vm._v(" "+_vm._s(item.clientId)+" "),_c(VBtn,{attrs:{"small":"","icon":"","href":'/clients/clients/' + item.clientId,"target":"_blank"}},[_c('i',{staticClass:"fas fa-external-link-alt",staticStyle:{"color":"#1867c0"}})])],1),_c('td',[_vm._v(_vm._s(item.clientName))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.discountLevel))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.notes))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(item.created_at))])])}),0)])],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }