<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        title="Bicicletas"
                ></page-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th>{{ $t('product') }}</th>
                        <th>Componente</th>
                        <th>Código Barras</th>
                        <th>{{ $t('description') }}</th>
                        <th>Marca</th>
                        <th>Familia</th>
                        <th>SubFamilia</th>
                        <th>Tipo</th>
                        <th>Stock</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="loading">
                        <td colspan="9">
                            <v-progress-linear indeterminate></v-progress-linear>
                        </td>
                    </tr>
                    <template v-for="(bike, index) in bikes">
                        <tr :class="{'grey lighten-1': bike.TratamentoDim == '1', 'grey lighten-3': index % 2 != 0}">
                            <td>{{ bike.Artigo }}</td>
                            <td></td>
                            <td :style="[bike.TratamentoDim != 1 && !isValidEAN13(bike.CodBarras) ? {'background-color': 'rgb(239, 111, 108)'} : '']">
                                {{ bike.CodBarras }}
                            </td>
                            <td>{{ bike.Descricao }}</td>
                            <td>{{ bike.Marca }}</td>
                            <td>{{ bike.Familia }}</td>
                            <td>{{ bike.SubFamilia }}</td>
                            <td class="text-center">
                                <v-chip small v-if="bike.TratamentoDim == '0'">S</v-chip>
                                <v-chip small v-if="bike.TratamentoDim == '1'" color="green" text-color="white">D
                                </v-chip>
                                <v-chip small v-if="bike.TratamentoDim == '2'" color="red" text-color="white">F
                                </v-chip>
                            </td>
                            <td class="text-center">{{ bike.STKActual }}</td>
                        </tr>
                        <tr v-for="(component, index2) in components" v-if="bike.Artigo == component.ArtigoComposto" :class="{'grey lighten-3': index % 2 != 0}">
                            <td></td>
                            <td>{{ component.Componente }}</td>
                            <td :style="[!isValidEAN13(bike.CodBarras) ? {'background-color': 'rgb(239, 111, 108)'} : '']">{{ component.CodBarras }}</td>
                            <td>{{ component.Descricao }}</td>
                            <td>{{ component.Marca }}</td>
                            <td>{{ component.Familia }}</td>
                            <td>{{ component.SubFamilia }}</td>
                            <td class="text-center">
<!--                                <v-chip small>C</v-chip>-->
                            </td>
                            <td class="text-center">{{ component.STKActual }}</td>
                        </tr>
                    </template>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6">
                <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        outlined
                        single-line
                        @change="updateTable()"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="width: 90px; float: left"
                        class="staff_select"
                        hide-details
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="getBikes"
                        style="width: auto; float: right;"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: function () {
            return {
                loading: false,
                bikes: [],
                totalBikes: 0,
                components: [],
                pagination: {
                    per_page: 100,
                    page: 1
                },
                filtersPerPage: [
                    {TEXT: '25', VALUE: 25},
                    {TEXT: '50', VALUE: 50},
                    {TEXT: '100', VALUE: 100},
                    {TEXT: '250', VALUE: 250},
                    {TEXT: '500', VALUE: 500},
                    {TEXT: '750', VALUE: 750}
                ],
            }
        },
        created() {
            this.getBikes()
        },
        computed: {
            getTotalPages() {
                return Math.ceil(this.totalBikes / this.pagination.per_page)
            },
        },
        methods: {
            getBikes() {
                this.bikes = []
                this.components = []
                this.loading = true

                let request = {
                    url: api.productsBikes(),
                    data: {
                        filters: this.filters,
                        pagination: this.pagination
                    }
                };

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.bikes = responseData.bikes;
                            this.components = responseData.componentsBikes
                            this.totalBikes = responseData.totalBikes[0].totalBikes

                            window.scrollTo(0, 0)
                        }
                        this.loading = false
                    })
                    .catch(response => {

                    })
            },
            isValidEAN13(a) {
                for (var b = /\d/g,             //initialize regular expression to match single digits
                         c = 0,                        //initialize checksum
                         d,
                         e = 25;                       //should be 25 - 13 * 2 = -1 in the end, so `~e` is 0
                     d = b.exec(a);                //fetch a single digit from the input
                     e -= 2)
                    c += e % 4 * d;               //multiply current digit with 3, 1, 3, 1 and so on
                return !(~e | c % 10)           //e must be -1 and sum must be 0
            },
            updateTable() {
                this.pagination.page = 1;
                this.getBikes();
            },
        }
    }
</script>
