<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title back :title="campaign.title" :subtitle="campaign.subtitle"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn text v-if="campaign.status && $isDateBetween(campaign.start_ts,campaign.end_ts)">
                    VISÍVEL NO B2B
                    <v-icon class="green--text ml-1">check_circle</v-icon>
                </v-btn>
                <v-btn text v-if="!campaign.status || !$isDateBetween(campaign.start_ts,campaign.end_ts)">
                    NÃO VISÍVEL NO B2B
                    <v-icon class="red--text ml-1">cancel</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <v-tabs v-model="activeTab">
                    <v-tab
                            v-for="tab of tabs"
                            :key="tab.index"
                            @click="changeToTab(activeTab,tab.index)">
                        {{tab.name}}
                    </v-tab>
                </v-tabs>
                <BasicInfo v-if="activeTab === 0" :campaign="campaign"></BasicInfo>
                <Levels v-if="activeTab === 1" :campaign="campaign"></Levels>
                <Clients v-if="activeTab === 2" :campaign="campaign"></Clients>
                <Products v-if="activeTab === 3" :id="id"></Products>
                <History v-if="activeTab === 4" :id="id"></History>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="modalChangeTab" persistent max-width="400">
                <v-card>
                    <v-card-title class="headline red white--text" dark primary-title>
                        Alterações por gravar
                    </v-card-title>

                    <v-card-text class="py-12">
                        Deseja continuar e descartar as alterações efetuadas?
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-btn tile small  color="default" @click="cancelToTab()">Voltar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  color="error" @click="confirmChangeTab()">Descartar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>

    </v-container>
</template>

<script>
    import BasicInfo from "./OLD___Campaign/BasicInfo";
    import Levels from "./OLD___Campaign/Levels";
    import Clients from "./OLD___Campaign/Clients";
    import Products from "./OLD___Campaign/Products";
    import History from "./OLD___Campaign/History";

    export default {
        components: {BasicInfo, Levels, Clients, Products, History},
        data: () => ({
            id: 0,
            language: '',
            campaign: {},
            campaignOriginal: {},
            tabs: [
                {index: 0, name: 'DEFINIÇÕES BASE'},
                {index: 1, name: 'NÍVEIS'},
                {index: 2, name: 'CLIENTES'},
                {index: 3, name: 'ARTIGOS'},
                {index: 4, name: 'HISTÓRICO'},
            ],
            activeTab: 0,
            currentTab: 0,
            newTab: 0,
            modalChangeTab: false,
        }),
        created() {
            this.id = this.$route.params.id;
            document.title = 'Campanha ' + this.id;
            this.getCampaign()
        },
        mounted() {
            this.language = User.getters.getLanguage;
        },
        methods: {
            getCampaign() {
                let request = {url: api.b2bCampaigns() + '/' + this.id};
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.campaign = responseData.campaign;
                            this.campaignOriginal = responseData.campaignOriginal;
                            this.campaign.subtitle = 'Configurações da campanha';
                            this.campaign.pristine = true;
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            cancelToTab(){
                this.modalChangeTab = false;
                this.activeTab = this.currentTab;
            },
            changeToTab(current, next) {
                this.currentTab = current;
                this.newTab = next;
                if (!this.campaign.pristine) {
                    this.modalChangeTab = true;
                } else {
                    this.activeTab = next;
                }
            },
            confirmChangeTab() {
                this.campaign.pristine = true;

                this.campaign.title = this.campaignOriginal.title;
                this.campaign.status = this.campaignOriginal.status;
                this.campaign.type = this.campaignOriginal.type;
                this.campaign.b2b_visualization = this.campaignOriginal.b2b_visualization;
                this.campaign.multiple_orders = this.campaignOriginal.multiple_orders;
                this.campaign.fixed_dates = this.campaignOriginal.fixed_dates;
                this.campaign.stock_only = this.campaignOriginal.stock_only;
                this.campaign.order = this.campaignOriginal.order;
                this.campaign.color1 = this.campaignOriginal.color1;
                this.campaign.color2 = this.campaignOriginal.color2;
                this.campaign.start_ts = this.campaignOriginal.start_ts;
                this.campaign.end_ts = this.campaignOriginal.end_ts;
                this.campaign.name_pt = this.campaignOriginal.name_pt;
                this.campaign.name_es = this.campaignOriginal.name_es;
                this.campaign.subtitle_pt = this.campaignOriginal.subtitle_pt;
                this.campaign.subtitle_es = this.campaignOriginal.subtitle_es;
                this.campaign.description_pt = this.campaignOriginal.description_pt;
                this.campaign.description_es = this.campaignOriginal.description_es;

                this.modalChangeTab = false;
                this.activeTab = this.newTab;
            },
        }
    }
</script>
