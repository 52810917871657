<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                Calendarização
                <v-spacer></v-spacer>
                <v-btn tile small color="success" class="ml-3 mr-2" @click="toggleAddDialog">
                    Adicionar
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th style="width: 200px; cursor: pointer;" @click="orderList('startDate')" class="text-center">Data de Início
                            <i v-if="selectedHeader === 'startDate'" class="fas"
                               :class="[headers['startDate'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                        <th style="width: 200px; cursor: pointer;" @click="orderList('endDate')" class="text-center">Data de Fim
                            <i v-if="selectedHeader === 'endDate'" class="fas"
                               :class="[headers['endDate'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                    </tr>
                    <tr class="filters_row">
                        <th>
                            <v-text-field outlined hide-details v-model="filters.startDate" @change="updateTable()"></v-text-field>
                        </th>
                        <th>
                            <v-text-field outlined hide-details v-model="filters.endDate" @change="updateTable()"></v-text-field>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in pagedLines" v-if="line.deleted_at ==null">
                        <td class="text-center">{{ line.startDate }}</td>
                        <td class="text-center">{{ line.endDate }}</td>
                        <td class="text-center" style="width: 100px;">
                            <v-btn tile small width="60%"color="error" class="mb-1 mt-1" @click="toggleDelete(line.id)">
                                REMOVER
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <!--        BANNER SCHEDULE DIALOG-->
        <v-dialog persistent width="800" v-model="dialogAddSchedule">
            <v-card>
                <v-card-title class="headline darken-1">
                    Calendarização de Banner
                </v-card-title>
                <v-card-text>
                    <v-row justify="center" class="ml-4 mt-1">
                        <v-col>
                            <h3>Data de início</h3>
                        <v-date-picker v-model="inputStartDate" min="2023-01-01" elevation="5" locale="pt-pt"></v-date-picker>
                        </v-col>
                        <v-col>
                            <h3>Data de fim</h3>
                            <v-date-picker v-model="inputEndDate" :min="inputStartDate" elevation="5" locale="pt-pt"></v-date-picker>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="dialogAddSchedule = false">Fechar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addNewSchedule()"> Adicionar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" overlay-color="white" overlay-opacity="0.5" persistent max-width="640">
            <v-card>
                <v-card-title class="red--text">
                    Tem a certeza que quer apagar esta calendarização de banner?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="error" @click="deleteDialog = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="removeSchedule()">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import _ from "lodash";

export default {
    name: "Schedule",
    data: function (){
        return{
            bannerId: '',
            todayDate: '',
            schedules:[],
            lines: [],
            originalLines: [],
            dialogAddSchedule: false,
            deleteDialog: false,
            inputStartDate: '',
            inputEndDate: '',
            idToDelete: '',
            filters: {
                id: '',
                startDate: '',
                endDate: '',
            },
            selectedHeader: "id",
            headers: {
                "id": 'asc',
                "startDate": 'asc',
                "endDate": 'asc',

            },
            pagination: {
                rowsPerPage: 25,
                page: 1
            },
            itemPerPage: [
                {text: '50', value: 50},
                {text: '100', value: 100},
                {text: '250', value: 250},
                {text: '500', value: 500}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
        }
    },
    created() {
        this.bannerId = this.$route.params.id;
        this.getSchedules();
        this.currentDate();
    },
    methods:{
        currentDate() {
            const current = new Date();
            const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
            this.todayDate = date;
            console.log(this.todayDate);
        },
        getSchedules(){
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.bannerId + '/banners-schedules'
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.schedules = responseData.bannerSchedules;
                            this.originalLines = responseData.bannerSchedules;
                            this.lines = responseData.bannerSchedules;
                            this.total = this.originalLines.length;
                            loader.hide();
                            this.loading = false
                        }
                        if (responseData.status === "NOK") {
                            loader.hide();
                            this.loading = false
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
                loader.hide();
                this.loading = false
            })
        },
        addNewSchedule(){
            this.dialogAddSchedule = false
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.bannerId + '/banners-schedules',
                data: {
                    bannersSchedule:{
                        startDate: this.inputStartDate,
                        endDate: this.inputEndDate
                    }
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        console.log(responseData)
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Calendarização adicionada com sucesso!',
                                type: 'success'
                            })

                            this.getSchedules();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
                loader.hide();
                this.loading = false
        },
        removeSchedule(){
            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.bannerId + '/banners-schedules/' + this.idToDelete
            }

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show',{
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            this.getSchedules();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.deleteDialog = !this.deleteDialog;
        },
        toggleDelete(id){
          this.deleteDialog = !this.deleteDialog;
            this.idToDelete = id;
        },
        updateTable() {
            this.lines = this.originalLines

            if (this.filters.id !== '') {
                this.lines = this.lines.filter(line => line.id == this.filters.id)
            }
            if (this.filters.startDate !== '') {
                this.lines = this.lines.filter(line => line.startDate != null && line.startDate.includes(this.filters.startDate))
            }
            if (this.filters.endDate !== '') {
                this.lines = this.lines.filter(line => line.endDate != null && line.endDate.includes(this.filters.endDate))
            }

           /* if (this.filters.created_at !== '') {
                this.lines = this.lines.filter(line => line.created_at != null && line.created_at.includes(this.filters.created_at))
            }
            if (this.filters.updated_at !== '') {
                this.lines = this.lines.filter(line => line.updated_at != null && line.updated_at.includes(this.filters.updated_at))
            }*/

            this.total = this.lines.length
            this.pagination.page = 1;

        },
        resetFilters() {
            this.filters = _.mapValues(this.filters, () => '')
            this.updateTable()
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;

        },
        toggleAddDialog(){
            this.dialogAddSchedule = !this.dialogAddSchedule;
        }
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.pagination.rowsPerPage)
        },
        pagedLines: function () {
            return this.orderedLines.slice((this.pagination.page - 1) * this.pagination.rowsPerPage, this.pagination.page * this.pagination.rowsPerPage);
        },
        orderedLines: function () {
            return _.orderBy(this.lines, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    }
}
</script>

<style scoped>

</style>