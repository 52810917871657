<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Custos Encomendas Amazon"
                        :subtitle="'A mostrar '+invoices.length +' de '+totalInvoices+' encomendas faturas.'"
                ></page-title>
            </v-col>
            <v-col>
                <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="filters.dataInicial"
                                label="Data Inicial"
                                dense
                                outlined
                                readonly
                                v-on="on"
                                style="width: 200px; float: left"
                                hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.dataInicial" @input="menu2 = false"></v-date-picker>
                </v-menu>
                <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="filters.dataFinal"
                                label="Data Final"
                                readonly
                                dense
                                outlined
                                v-on="on"
                                style="width: 200px; float: left"
                                hide-details
                                class="ml-4"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.dataFinal" @input="menu3 = false"></v-date-picker>
                </v-menu>
                <v-select
                        v-model="filters.country"
                        :items="countryOptions"
                        hide-details
                        dense
                        outlined
                        style="width: 100px;"
                        class="pl-2"
                        label="Pais"
                ></v-select>
            </v-col>
            <v-col cols="1">
                <v-btn tile small class="ml-2" color="info" @click="getOrdersCosts">Actualizar</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th>Referencia</th>
                        <th>Cliente</th>
                        <th>Produtos</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="filters_row">
                        <td>
                            <v-text-field
                                    v-model="filters.reference"
                                    outlined
                                    dense
                                    hide-details
                                    @keyup.enter="getOrdersCosts"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.client"
                                    outlined
                                    dense
                                    hide-details
                                    @keyup.enter="getOrdersCosts"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.product"
                                    outlined
                                    dense
                                    hide-details
                                    @keyup.enter="getOrdersCosts"
                            ></v-text-field>
                        </td>
                    </tr>
                    <tr v-for="invoice in invoices">
                        <td>
                            <strong>{{ invoice.Referencia }}</strong> <br>
                            {{ invoice.Documento }} <br>
                            {{ invoice.Data }}
                        </td>
                        <td>
                            <strong>{{ invoice.Nome }}</strong><br>
                            <span>{{ invoice.Morada }}</span><br>
                            <span v-if="invoice.Morada2">{{ invoice.Morada2 }}<br></span>
                            <span>{{ invoice.CodPostal }} - {{ invoice.CodPostalLocalidade }}</span><br>
                            <strong>{{ invoice.Pais }}</strong><br>
                            <strong>NIF: {{ invoice.NumContribuinte }}</strong>
                        </td>
                        <td style="width: 70%">
                            <v-simple-table class="staff_table" style="background-color: transparent !important;">
                    <thead>
                    <tr>
                        <th style="width: 150px">Artigo</th>
                        <th>Descricao</th>
                        <th style="width: 80px">Quantidade</th>
                        <th style="width: 40px">Total</th>
                        <th style="width: 40px">IVA</th>
                        <th style="width: 60px">Total Iliq.</th>
                        <th style="width: 50px">Preço</th>
                        <th style="width: 55px">PCM</th>
                        <th style="width: 60px">MB Un.</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="product in invoice.products">
                        <tr>
                            <td>{{ product.Artigo }}</td>
                            <td>{{ product.Descricao }}</td>
                            <td>{{ product.Quantidade }}</td>
                            <td>{{ $2decimal(product.TotalIliquido + product.TotalIva) }}</td>
                            <td>{{ $2decimal(product.TotalIva) }}</td>
                            <td>{{ $2decimal(product.TotalIliquido) }}</td>
                            <td>{{ $2decimal(product.PrecUnit) }}</td>
                            <td>{{ $2decimal(product.PCM) }}</td>
                            <td>{{ $2decimal(product.PrecUnit - product.PCM) }}</td>
                        </tr>
                        <tr>
                            <th v-for="n in totalBlancArray(product.costs)"></th>
                            <td v-for="cost in product.costs"><strong>{{ cost.amountDescription }}: </strong>{{ cost.amount }}</td>
                            <td><strong>Total: </strong> <br> {{ totalCostsByProduct(product.costs) }}</td>
                            <td :style="{'background-color': $2decimal((product.PrecUnit - product.PCM) + totalCostsByProduct(product.costs)) < 0 ? '#ffb8c2' : '' }"><strong>Saldo: </strong> <br> {{ $2decimal((product.PrecUnit - product.PCM) + totalCostsByProduct(product.costs)) }}</td>
                        </tr>
                    </template>
                    </tbody>
                </v-simple-table>
                </td>
                </tr>
                </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="getOrdersCosts"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: function () {
        return {
            invoices: [],
            totalInvoices: 0,
            pagination: {
                rowsPerPage: 100,
                page: 1
            },
            filters: {
                dataInicial: '2022-01-01',
                dataFinal: '',
                reference: '',
                client: '',
                country: 'ALL',
                product: ''
            },
            menu2: false,
            menu3: false,
            countryOptions: []
        }
    },
    created() {
        let currentYear = new Date().getFullYear();
        this.filters.dataInicial = currentYear + '-01-01';
        this.filters.dataFinal = currentYear + '-12-31';

        this.getOrdersCosts();
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.totalInvoices / this.pagination.rowsPerPage)
        }
    },
    methods: {
        getOrdersCosts() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/bikeplanet/orders/costs',
                data: {
                    pagination: this.pagination,
                    filters: this.filters
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.invoices = responseData.invoices;
                            this.totalInvoices = responseData.totalInvoices;
                            this.countryOptions = [...new Set(this.invoices.map(item => item.Pais))];
                            this.countryOptions = ['ALL'].concat(this.countryOptions);
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        totalCostsByProduct(costs) {
            let total = 0;
            if (costs != undefined) {
                costs.forEach((cost) => {
                    if (cost.amountDescription != 'Shipping' && cost.amountDescription != 'ShippingChargeback') {
                        total += parseFloat(cost.amount)
                    }
                })
            }
            return total;
        },
        totalBlancArray(costs) {
            let total = 0;
            if (costs != undefined) {
                total = costs.length
            }
            return 7 - total < 0 ? 0 : 7 - total;
        }
    }
}
</script>
