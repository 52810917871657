import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,[_c(VCard,{attrs:{"tile":"","min-height":"300"}},[_c(VCardText,[_c(VSimpleTable,{staticClass:"staff_table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Descricao")]),_c('th',[_vm._v("Artigo")]),_c('th',[_vm._v("Ref Fornecedor")]),_c('th',[_vm._v("CodBarras")]),_c('th',{staticClass:"text-center"},[_vm._v("EAN-13")]),_c('th',{staticClass:"text-center"},[_vm._v("B2B")]),_c('th',{staticClass:"text-center"},[_vm._v("Oficina")])])]),_c('tbody',_vm._l((_vm.rows),function(row){return _c('tr',[_c('td',[_vm._v(_vm._s(row.Descricao))]),_c('td',[_vm._v(_vm._s(row.Artigo))]),_c('td',[_c(VTextField,{attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(row.CDU_REFFORNECEDOR),callback:function ($$v) {_vm.$set(row, "CDU_REFFORNECEDOR", $$v)},expression:"row.CDU_REFFORNECEDOR"}})],1),_c('td',[_c(VTextField,{attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(row.CodBarras),callback:function ($$v) {_vm.$set(row, "CodBarras", $$v)},expression:"row.CodBarras"}})],1),_c('td',[_c(VTextField,{attrs:{"dense":"","outlined":"","hide-details":""},model:{value:(row.CDU_EAN),callback:function ($$v) {_vm.$set(row, "CDU_EAN", $$v)},expression:"row.CDU_EAN"}})],1),_c('td',{staticClass:"text-center"},[(row.CDU_B2B_SYNC == '1')?_c('i',{staticClass:"fas fa-check",staticStyle:{"color":"green"}}):_vm._e(),(row.CDU_B2B_SYNC == '0')?_c('i',{staticClass:"fas fa-times",staticStyle:{"color":"red"}}):_vm._e()]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(row.CDU_FOX_SYNC))])])}),0)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }