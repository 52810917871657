<template>
  <v-container fluid>
    <v-row class="content-header">
      <v-col>
        <page-title title="Compras"
                    :subtitle="'A mostrar '+purchases.length+' documentos de compra'"></page-title>
      </v-col>
      <v-col class="text-right" align-self="center">
        <v-btn tile color="error" @click="dialogConfirmMassDelete=true" v-if="$writePermission('orders/pick')"
               :disabled="totalPurchasesSelected === 0" class="mr-3">
          Cancelar Compras
        </v-btn>
        <v-btn tile color="primary" @click="createPick" v-if="$writePermission('orders/pick')"
               :disabled="totalPurchasesSelected === 0" class="mr-3">
          Criar Pick
        </v-btn>
        <v-btn tile color="success" @click="toggleDialogCreateECF"
               v-if="$writePermission('purchases/purchases')">
          Criar ECF
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="staff_v-card">
          <v-row>
            <v-col class="text-right">
              <v-btn outlined tile x-small color="error" @click="clearFilters">Limpar Filtros</v-btn>
            </v-col>
          </v-row>
          <v-simple-table class="staff_table">
            <thead>
            <tr>
              <th></th>
              <th :class="header.class" v-for="(header, index) in headers">
                <span  style="cursor:pointer"  @click="orderList(index)">{{ header.displayName }} </span> <i v-if="selectedHeader == index" class="fas" :class="[header.order == 'asc' ? 'fa-arrow-up' : 'fa-arrow-down']"></i>
              </th>
              <!--<th><span style="cursor:pointer"  @click="orderList('supplier')">{{ $t('supplier') }}</span></th>
             <th><span style="cursor:pointer"  @click="orderList('docNumber')">Documento</span></th>
             <th><span style="cursor:pointer"  @click="orderList('docDate')">{{ $t('dateOfDocument') }}</span></th>
             <th><span style="cursor:pointer"  @click="orderList('externalDoc')">Núm. Documento Externo</span>/th>
             <th class="text-center"><span style="cursor:pointer"  @click="orderList('totalReferences')">Total Referências</span></th>
             <th class="text-center"><span style="cursor:pointer"  @click="orderList('totalProducts')">Total {{ $t('products') }}</span></th>
             <th class="text-center"><span style="cursor:pointer"  @click="orderList('receivedQuantity')">Qty. Recebida</span></th>
             <th class="text-center"><span style="cursor:pointer"  @click="orderList('pendingQuantity')">Qty. Pendente</span></th>
             <th class="text-right"><span style="cursor:pointer"  @click="orderList('totalValue')">Valor Documento</span></th>
             <th class="text-right"><span style="cursor:pointer"  @click="orderList('arrivalDate')">{{ $t('arrivalDate') }}</span></th>
             <th width="1"></th>-->
            </tr>
            <tr class="filters_row">
              <td></td>
              <td>
                <v-autocomplete
                    v-model="filters.supplier"
                    :items="suppliersOptions"
                    dense
                    outlined
                    hide-details
                    @change="filterRows"
                ></v-autocomplete>
              </td>
              <td>
                <v-text-field
                    v-model="filters.doc"
                    outlined
                    hide-details
                    single-line
                    @keyup="filterRows"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.date"
                    outlined
                    hide-details
                    single-line
                    @keyup="filterRows"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.external"
                    outlined
                    hide-details
                    single-line
                    @keyup="filterRows"
                ></v-text-field>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="purchase in orderedPurchases">
              <td class="text-center">
                <i v-if="!purchase.selected" class="far fa-square fa-lg" style="cursor: pointer"
                   @click="purchase.selected = true"></i>
                <i v-if="purchase.selected" class="fas fa-check-square fa-lg"
                   style="color:#1976d2; cursor: pointer"
                   @click="purchase.selected = false"></i>
              </td>
              <td>{{ purchase.Nome }}</td>
              <td>{{ purchase.Documento.substr(9, 8) }}</td>
              <td>{{ purchase.DataDoc.substr(0, 10) }}</td>
              <td><u>{{ purchase.NumDocExterno }}</u> <span v-if="purchase.brands">( {{ purchase.brands.slice(0, -1) }} )</span>
              </td>
              <td class="text-center">{{ purchase.TotalReferencias }}</td>
              <td class="text-center">{{ purchase.TotalArtigos }}</td>
              <td class="text-center">{{ purchase.TotalQuantTrans }}</td>
              <td class="text-center">{{ purchase.TotalQuantPend }}</td>
              <td class="text-right">
                <template v-if="purchase.Moeda === 'USD'">
                  {{ $dollar(purchase.TotalDocumento) }}
                </template>
                <template v-else>
                  {{ $money(purchase.TotalDocumento) }}
                </template>
              </td>
              <td class="text-right">{{ purchase.DataEntrega }}</td>
              <td>
                <v-btn tile small color="primary" :to="'/purchases/purchases/'+purchase.NumDoc">
                  Detalhe
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogConfirmMassDelete" persistent max-width="500">
        <v-card>
          <v-toolbar
              dark
              color="red"
              dense
          >
            <v-toolbar-title>Eliminar compras</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                icon
                dark
                @click="dialogConfirmMassDelete = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <br/>
            <strong>Eliminar todas as compras selecionadas?</strong>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="deletePurchase()">Eliminar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogConfirmMassDelete = false">Não</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCreateECF" persistent max-width="400">
        <v-card>
          <v-card-title>Criar ECF</v-card-title>
          <v-card-text>
            <v-autocomplete
                v-model="input.supplier"
                :items="suppliers"
                item-value="VALUE"
                item-text="TEXT"
                :label="$t('supplier')"
                outlined
                hide-details
            ></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="dialogCreateECF = false">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :disabled="input.supplier === ''" @click="createECF()">Criar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-container>
</template>

<script>

import _ from "lodash";

export default {
  data: function () {
    return {
      dialogConfirmMassDelete: false,
      purchasesOriginal: [],
      purchases: [],
      dialogCreateECF: false,
      suppliers: [],
      suppliersOptions: [],
      input: {
        supplier: ''
      },
      selectedHeader: "DataDoc",
      headers: {
        "Entidade": {"class": "","displayName": "Fornecedor", "col": "CC.Entidade", "order": "desc"},
        "NumDoc": {"class": "","displayName": "Documento", "col": "CC.NumDoc","order": "desc"},
        "DataDoc": {"class": "","displayName": "Data Documento", "col": "CC.DataDoc","order": "desc"},
        "NumDocExterno": {"class": "","displayName": "Núm. Documento Externo", "col": "CC.NumDocExterno","order": "desc"},
        "TotalReferencias": {"class": "text-center","displayName": "Total Referências", "col": "TotalReferencias","order": "desc"},
        "TotalArtigos": {"class": "text-center","displayName": "Total Artigos", "col": "TotalArtigos","order": "desc"},
        "TotalQuantTrans": {"class": "text-center","displayName": "Qty. Recebida", "col": "TotalQuantTrans","order": "desc"},
        "TotalQuantPend": {"class": "text-center","displayName": "Qty. Pendente", "col": "TotalQuantPend","order": "desc"},
        "TotalDocumento": {"class": "text-center","displayName": "Valor Documento", "col": "CC.TotalDocumento","order": "desc"},
        "DataEntrega": {"class": "text-center","displayName": "Data de Chegada", "col": "DataEntrega","order": "desc"},
      },
      filters: {
        supplier: '',
        doc: '',
        date: '',
        external: '',
      },
    }
  },
  created() {
    document.title = 'Compras | STAFF';
    this.getPurchases();
    this.getSuppliers();
  },
  methods: {
    getPurchases() {
      let loader = this.$loading.show();
      let request = {
        url: '/api/staff/purchases'// + this.selectedHeader + '&order=' + this.headers[this.selectedHeader].order
      }
      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.purchasesOriginal = responseData.purchases;
              this.suppliersOptions = [...new Set(this.purchasesOriginal.map(item => item.Nome))];
              this.filterRows();
            }
            loader.hide();
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            });
            loader.hide();
          })
    },
    toggleDialogCreateECF() {
      this.dialogCreateECF = true;
    },
    orderList(col){
      if(col == this.selectedHeader)
        this.headers[this.selectedHeader].order = this.headers[this.selectedHeader].order == "asc" ? "desc" : "asc";
      else
        this.headers[this.selectedHeader].order = "desc";
      this.selectedHeader = col;

    },
    getSuppliers() {
      let request = {url: '/api/staff/purchases/getSuppliers'}
      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {
              this.suppliers = responseData.suppliers
            }
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            });
          })
    },
    createECF() {
      let loader = this.$loading.show();
      let request = {
        url: '/api/staff/purchases/createECF',
        data: {supplier: this.input.supplier}
      }
      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.dialogCreateECF = false;
              this.getPurchases();
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao criar ECF!',
                type: 'error'
              })
            }
            loader.hide();
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            loader.hide();
          })
    },

    deletePurchase() {
      let loader = this.$loading.show();
      let toDelete = [];
      this.purchases.forEach((purchase) => {
        if (purchase.selected == true) {
          toDelete.push(purchase.Id);
        }
      })
      let request = {
        url: '/api/staff/purchases/closeMultiple',
        data: {idArray: toDelete}
      }
      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status == 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Compras eliminadas com sucesso!',
                type: 'success'
              })
              this.dialogConfirmMassDelete = false;
              setTimeout(()=>this.getPurchases(),400);
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao eliminar compras!',
                type: 'error'
              })
            }
            loader.hide();
          })
          .catch(() => {
            loader.hide();
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },

    createPick() {
      let loader = this.$loading.show();
      let purchasesToPick = [];
      this.purchases.forEach((purchase) => {
        if (purchase.selected) {
          purchasesToPick.push(purchase);
        }
      })
      let request = {
        url: '/api/staff/purchases/createPickECF',
        data: {purchases: purchasesToPick}
      }
      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Picking criado com sucesso!',
                type: 'success'
              })
              this.$router.push('/purchases/pick/' + responseData.id);
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao criar o picking!',
                type: 'error'
              })
            }
            loader.hide();
          })
          .catch(() => {
            loader.hide();
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    clearFilters() {
      this.filters = {
        supplier: '',
        doc: '',
        date: '',
        external: '',
      }
      this.filterRows();
    },
    filterRows() {
      this.purchases = this.purchasesOriginal;
      if (this.filters.supplier !== '') {
        this.purchases = this.purchases.filter(item => item.Nome.toUpperCase().includes(this.filters.supplier.toUpperCase()))
      }
      if (this.filters.doc !== '') {
        this.purchases = this.purchases.filter(item => item.Documento.toUpperCase().includes(this.filters.doc.toUpperCase()))
      }
      if (this.filters.date !== '') {
        this.purchases = this.purchases.filter(item => item.DataDoc.toUpperCase().includes(this.filters.date.toUpperCase()))
      }
      if (this.filters.external !== '') {
        this.purchases = this.purchases.filter(item => (item.NumDocExterno != null && item.NumDocExterno.toUpperCase().includes(this.filters.external.toUpperCase())) || (item.brands && item.brands.toUpperCase().includes(this.filters.external.toUpperCase())))
      }
    },
  },
  computed: {
    orderedPurchases: function () {
      return _.orderBy(this.purchases, this.selectedHeader,this.headers[this.selectedHeader].order)
    },
    totalPurchasesSelected() {
      let count = 0;
      this.purchases.forEach((purchase) => {
        if (purchase.selected === true) {
          count++;
        }
      })
      return count;
    }
  }
}
</script>
