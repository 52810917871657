<template>
  <v-container fluid>

    <!-- HEADER -->
    <v-row class="content-header">
      <v-col>
        <page-title
            title="Lista de Pickings"
            :subtitle="$t('show') + ' ' + currentRecords + ' de ' + totalPicks + ' Picks'"
        ></page-title>
      </v-col>
      <v-col class="text-right">
        <v-btn tile small class="ml-2 mb-2" @click="getPickingList()" color="info"><i class="fas fa-sync-alt mr-1"></i>Atualizar
        </v-btn>
      </v-col>
    </v-row>

    <!-- LISTA -->
    <v-row>
      <v-col>
        <div class="staff_v-card">
          <v-simple-table class="staff_table">
            <thead>
            <tr class="titles_row">
              <th width="1">ID</th>
              <th width="80">Data</th>
              <th width="80">Tipo Cliente</th>
              <th width="80">Cliente</th>
              <th>{{ $t('name') }}</th>
              <th width="100" class="text-center">Modo Pagamento</th>
              <th width="150">Condições Pagamento</th>
              <th width="70" class="text-center">Vendedor</th>
              <th width="60" class="text-center">Origem</th>
              <th width="100">Total de Artigos</th>
              <th class="text-center" width="80">Utilizador</th>
              <th width="85">Estado</th>
              <th width="85">Tracking</th>
              <th width="50">Tipo</th>
              <th width="85">Número Doc</th>
              <th width="85">Serie</th>
            </tr>
            <tr class="filters_row">
              <td></td>
              <td></td>
              <td>
                <v-text-field
                    v-model="filters.clientType.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.client.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.name.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.paymentMode.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.paymentConditions.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.salesman.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.source.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
              <td></td>
              <td></td>
              <td>
                <v-select
                    v-model="filters.status.value"
                    :items="availableStates"
                    item-text="state"
                    item-value="value"
                    @change="filtersPickingList"
                ></v-select>
              </td>
              <td>
                <v-text-field
                    v-model="filters.trackingNumber.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
              <td>
                <v-select
                    v-model="filters.docType.value"
                    :items="availableDocTypes"
                    item-text="type"
                    item-value="value"
                    @change="filtersPickingList"
                ></v-select>
              </td>
              <td>
                <v-text-field
                    v-model="filters.docNumber.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.docSerie.value"
                    outlined
                    dense
                    hide-details
                    @keypress.enter="filtersPickingList"
                ></v-text-field>
              </td>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="15">
                <v-progress-linear indeterminate></v-progress-linear>
              </td>
            </tr>
            <tr v-for="pick in pickingList" style="cursor:pointer" @click="redirectDetail(pick.id)">
              <td>{{ pick.id }}</td>
              <td>{{ pick.created_at.substring(0, 10) }}</td>
              <td>{{ pick.tipoCliente }}</td>
              <td>{{ pick.cliente }}</td>
              <td>{{ pick.nome }}</td>
              <td class="text-center">{{ pick.modoPagamento }}</td>
              <td>{{ pick.Descricao }}</td>
              <td class="text-center">{{ pick.vendedor }}</td>
              <td class="text-center">{{ pick.origem }}</td>
              <td class="text-center">{{ pick.TotalArtigos }}</td>
              <td class="text-center">{{ pick.utilizador }}</td>
              <td>{{ pick.status }}</td>
              <td>{{ pick.trackingNumber }}</td>
              <td>{{ pick.tipoDocFat }}</td>
              <td>{{ pick.numDocFat }}</td>
              <td>{{ pick.serieDocFat }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>

    <!-- IMPRIMIR -->
    <v-row style="display: none">
      <v-col>
        <div id="printMe">
          <table style="border: 1px solid gray; border-collapse: collapse; width: 100%">
            <thead>
            <tr>
              <th colspan="2" style="padding: 30px 10px; border: 1px solid gray; font-size: 24px;">{{
                  selectedPick.id
                }}
              </th>
              <th colspan="5" style="padding: 30px 10px; border: 1px solid gray; font-size: 24px; text-align: right">
                {{ selectedPick.cliente }}<br>
                {{ selectedPick.nome }}
              </th>
            </tr>
            </thead>
            <thead>
            <tr>
              <th style="border: 1px solid gray; text-align: center">Stock A1</th>
              <th style="border: 1px solid gray; text-align: center">Stock</th>
              <th style="border: 1px solid gray">{{ $t('product') }}</th>
              <th style="border: 1px solid gray">CodBarras</th>
              <th style="border: 1px solid gray; text-align: center">Quantidade</th>
              <th style="border: 1px solid gray">{{ $t('description') }}</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="order in products">
              <tr>
                <td style="border: 1px solid gray; text-align: center">{{ order.stock === 0 ? '' : order.stock }}</td>
                <td style="border: 1px solid gray; text-align: center">
                  {{ Math.round(order.stockTotal) === 0 ? '' : Math.round(order.stockTotal) }}
                </td>
                <td style="border: 1px solid gray">{{ order.artigo }}</td>
                <td style="border: 1px solid gray">{{ order.codBarras }}</td>
                <td style="border: 1px solid gray; text-align: center">{{ order.quantity }}</td>
                <td style="border: 1px solid gray">{{ order.descricao }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" sm="2" md="1" class="offset-md-7 offset-sm-6">
        <v-select
            v-model="countPerPage"
            :items="countPerPageList"
            outlined
            dense
            single-line
            item-text="TEXT"
            item-value="VALUE"
            style="width: 90px;"
            hide-details
            @change="changePageCount"
        ></v-select>
      </v-col>
      <v-col cols="6" sm="4">
        <v-pagination
            v-model="currentPage"
            :length="Math.ceil(totalPicks/countPerPage)"
            :total-visible="7"
            @input="changePage"
            style="width: auto;"
            circle
        ></v-pagination>
      </v-col>
    </v-row>
    <!-- DIALOGS -->
    <v-row>

      <!-- Close Pick-->
      <v-dialog v-model="dialogClosePick" persistent max-width="400">
        <v-card>
          <v-card-title>
            Arquivar Picking
          </v-card-title>
          <v-card-text>
            Deseja arquivar o picking?
          </v-card-text>
          <v-card-actions>
            <v-btn tile small @click="dialogClosePick = false">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile small color="error" @click="closePickConfirm()">Arquivar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- RESPONSE -->
      <v-dialog v-model="responseDialog.open" persistent max-width="400">
        <v-card>
          <v-card-title>
            {{ responseDialog.title }}
          </v-card-title>
          <v-card-text>
            <div class="text-center">
              <v-progress-circular
                  v-if="responseDialog.loading"
                  indeterminate
                  color="primary"
                  size="100"
              ></v-progress-circular>
              <div v-if="!responseDialog.loading">
                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                <p class="headline">{{ responseDialog.message }}</p>
                <v-alert v-if="responseDialog.submessage" type="error">
                  {{ responseDialog.submessage }}
                </v-alert>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pa-3">
            <v-spacer></v-spacer>
            <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-row>

  </v-container>
</template>

<script>

export default {
  data: function () {
    return {
      loading: false,
      pickingList: [],
      pickingListOriginal: [],
      selectedPick: {},
      totalPicks: 0,
      availableStates: [
        {state: "Todos", value: ""},
        {state: "Apagado", value: "Apagado"},
        {state: "Arquivado", value: "Arquivado"},
        {state: "Conferido", value: "Conferido"},
        {state: "Eliminado", value: "Eliminado"},
        {state: "Faturado", value: "Faturado"},
        {state: "Novo", value: "Novo"},
        {state: "Picking", value: "Picking"}
      ],
      availableDocTypes: [
        {type: "Todos", value: ""},
        {type: "FA", value: "FA"},
        {type: "FAP", value: "FAP"}
      ],
      filters: {
        clientType: {value: '', exactValue: false},
        client: {value: '', exactValue: false},
        name: {value: '', exactValue: false},
        paymentMode: {value: '', exactValue: false},
        paymentConditions: {value: '', exactValue: false},
        salesman: {value: '', exactValue: false},
        source: {value: '', exactValue: false},
        status: {value: '', exactValue: false},
        user: {value: '', exactValue: false},
        trackingNumber: {value: '', exactValue: false},
        docType: {value: '', exactValue: true},
        docNumber: {value: '', exactValue: false},
        docSerie: {value: '', exactValue: false},
      },
      dialogClosePick: false,
      closePickId: '',
      responseDialog: {
        open: false,
        loading: false,
        error: false,
        title: '',
        message: '',
        submessage: ''
      },
      products: [],
      currentRecords: "0",
      countPerPage: 50,
      countPerPageList: [50, 100, 200, 350, 500],

      currentPageOffset: 0,
      currentPage: 1
    }
  },
  created() {
    document.title = 'Picking | STAFF'
    this.getPickingList();
  },
  methods: {
    getPickingList() {
      this.loading = true;
      this.pickingList = [];
      this.pickingListOriginal = [];

      let request = {
        url: '/api/staff/picks-history?filters=' + btoa(JSON.stringify(this.filters)) + '&offset=' + this.currentPageOffset + '&count=' + this.countPerPage
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
           if (responseData.status === 'OK') {
              this.pickingList = responseData.pickingList;
              this.pickingListOriginal = responseData.pickingList;
              this.totalPicks = responseData.totalPicks;
              this.currentPage = responseData.currentPage;
              this.currentPageOffset = responseData.pageOffset;
              this.countPerPage = responseData.pageCount;
              if(this.countPerPage < this.countPerPageList[0])
                this.countPerPageList = [this.countPerPage];
              else
                this.countPerPageList = [50, 100, 200, 350, 500];

              this.currentRecords = this.currentPageOffset + "-" + (this.currentPageOffset + this.pickingList.length);
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          })
    },
    redirectDetail(pick){
      console.log("redirectiong",pick);
      this.$router.push('/orders/pick-history/' + pick);
    },
    changePageCount(count){
      this.countPerPage=count;
      this.getPickingList();
    },
    changePage(page) {
      this.currentPageOffset = this.countPerPage * (page - 1);
      this.getPickingList();
    },
    filtersPickingList() {
      this.currentRecords = "0";
      this.currentPageOffset = 0;
      this.currentPage = 1;
      this.countPerPage = 50;
      this.getPickingList();

    },
    closePick(pickId) {
      this.closePickId = pickId;
      this.dialogClosePick = true;
    },
    closePickConfirm() {
      this.dialogClosePick = false;
      this.responseDialog.open = true;
      this.responseDialog.title = 'Arquivar Picking';
      this.responseDialog.loading = true;

      let request = {
        url: '/api/staff/pick/' + this.closePickId + '/cancel'
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.responseDialog.message = 'Picking arquivado com sucesso!'
              this.responseDialog.error = false;
              this.getPickingList();
            } else {
              this.responseDialog.message = 'Erro ao arquivar picking!'
              this.responseDialog.error = true;
            }
            this.responseDialog.loading = false;
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    print(pick) {
      this.selectedPick = pick;
      this.products = [];

      let request = {
        url: '/api/staff/pick/' + pick.id + '/products'
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.products = responseData.products;

              setTimeout(() => {
                const options = {
                  name: '_blank',
                  specs: [
                    'fullscreen=yes',
                    'titlebar=yes',
                    'scrollbars=yes'
                  ],
                  styles: [
                    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                    '../../assets/css/print.css'
                  ]
                }

                this.$htmlToPaper('printMe', options);
              }, 300)
            }
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
  },
  computed: {
    salesmanId() {
      return User.getters.getSalesmanId;
    }
  }
}
</script>
