<style scoped>

.tableRow:hover{
    background-color:#f7f7f7!important;
    cursor:pointer;
}

.formatedCell{
    font-size: 11px!important;
    text-align: center;
    background-color: #eaeaea;
}

</style>
<template>
    <v-container fluid>

        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Importar Open Order"
                        :subtitle="subtitle"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn small tile color="primary" @click="importOrders = true">Importar</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <td>#</td>
                            <td>Documento</td>
                            <td>Data</td>
                            <td>Utilizador</td>
                            <td>Ficheiro Original</td>
                            <td>Fornecedor</td>
                            <td class="text-right">
                                <v-btn icon @click="getOpenOrders" :loading="loading">
                                    <v-icon>refresh</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="tableRow" :style="openorder.doc_number == null ? 'background-color:#dbab005e' : ''" :id="'row_' + openorder.id" v-for="(openorder, index) in openorders" v-show="openorder.show==true" @click="showShipped(index, openorder)">
                            <template v-if="openorder.shipped==0">
                                <td>{{ openorder.id }}</td>
                                <td>{{ processState(openorder) }}</td>
                                <td>{{ openorder.created_at.substring(0,16).replace('T',' ') }}</td>
                                <td>{{ openorder.user }}</td>
                                <td>{{ openorder.file_name }}</td>
                                <td><u>{{ openorder.supplier }}</u></td>
                                <td class="text-right">
                                    <v-btn small tile color="info" :to="'/purchases/openOrders/'+openorder.supplier">VER</v-btn>
                                </td>
                            </template>
                            <template v-else>
                                <td class="formatedCell">{{ openorder.id }}</td>
                                <td class="formatedCell">{{ processState(openorder) }}</td>
                                <td class="formatedCell">{{ openorder.created_at.substring(0,16).replace('T',' ') }}</td>
                                <td class="formatedCell">{{ openorder.user }}</td>
                                <td class="formatedCell">{{ openorder.file_name }}</td>
                                <td class="formatedCell"></td>
                                <td class="formatedCell">
                                    <v-btn small tile color="info" :to="'/purchases/openOrders/'+openorder.supplier">VER</v-btn>
                                </td>
                            </template>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <OpenOrderImporter v-if="importOrders" v-on:close-importer="importOrders=false,getOpenOrders();"/>
    </v-container>
</template>

<script>
import OpenOrderImporter from '@/components/Purchases/OpenOrderImporter.vue';

export default {
    components: {
        OpenOrderImporter
    },
    data: () => ({
        importOrders: false,
        loading: false,
        subtitle: '',
        openorders: [],
    }),
    created() {
        document.title = 'Import Open Orders';
        this.getOpenOrders();
    },
    methods: {
        processState(order){
            if (order.doc_number == null)
                return 'Por criar';
            else if(typeof order.doc_number === 'object')
                if(order.doc_number.NumDoc == 'processing')
                    return 'A processar';
                else if(order.doc_number.NumDoc == 'error')
                    return '-';
                else
                    return order.doc_number.TipoDoc + ' ' + order.doc_number.Serie + '/' + order.doc_number.NumDoc;
            else
                return '-';
        },
        getOpenOrders() {
            this.loading = true;
            let loader = this.$loading.show();
            let request = {url: '/api/staff/purchases/import'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.openorders = responseData.openorders;
                        }
                        loader.hide();
                        this.loading = false;
                    })
                    .catch(() => {
                        loader.hide();
                        this.loading = false;
                    })
        },
        showShipped(rowIndex, openOrder){
            if(openOrder.shipped == 1)
                return;
            for(let i=rowIndex+1;i<this.openorders.length;i++){
                if(this.openorders[i].supplier != openOrder.supplier)
                    break;
                if(this.openorders[i].shipped == 0)
                    continue;
                this.openorders[i].show = !this.openorders[i].show;
            }
        }
    }
}
</script>
