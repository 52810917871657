<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title title="Registo de Clientes"
                            :subtitle="$t('show') + ' ' + lines.length + ' de ' + originalLines.length + ' registos'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small class="ml-2 mb-2" @click="resetFilters()" color="error">Limpar Filtros</v-btn>
<!--                <v-btn tile small class="ml-2 mb-2" @click="showImported = !showImported" :color="showImported ? 'error' : 'info'">{{showImported ? 'Esconder ' : 'Mostrar '}} importados</v-btn>-->
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card mb-3">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 20%; cursor: pointer;" @click="orderList('businessName')"
                                class="text-center">
                                Nome Comercial
                                <i v-if="selectedHeader === 'businessName'" class="fas"
                                   :class="[headers['businessName'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 20%; cursor: pointer;" @click="orderList('financialName')"
                                class="text-center">
                                Nome Fiscal
                                <i v-if="selectedHeader === 'financialName'" class="fas"
                                   :class="[headers['financialName'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 15%; cursor: pointer;" @click="orderList('email')" class="text-center">
                                Email <i v-if="selectedHeader === 'email'" class="fas"
                                         :class="[headers['email'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 10%; cursor: pointer;" @click="orderList('nif')"
                                class="text-center">
                                NIF/CIF
                                <i v-if="selectedHeader === 'nif'" class="fas"
                                   :class="[headers['nif'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 10%; cursor: pointer;" @click="orderList('phoneNumber')"
                                class="text-center">
                                Num. Telemóvel
                                <i v-if="selectedHeader === 'phoneNumber'" class="fas"
                                   :class="[headers['phoneNumber'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 8%;" class="text-center">
                                Importado
                            </th>
                            <th style="width: 10%;"  class="text-center">
                                Data Criação
                            </th>
                            <th width="5%"></th>
                            <th width="5%"></th>
                        </tr>
                        <tr class="filters_row">
                            <th>
                                <v-text-field outlined hide-details v-model="filters.businessName"
                                              @keyup="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.financialName"
                                              @keyup="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.email"
                                              @keyup="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.nif"
                                              @keyup="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.phoneNumber"
                                              @keyup="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-select dense outlined hide-details @change="updateTable()" v-model="filters.importado" :items="importadosOption" item-value="VALUE" item-text="TEXT">
                                </v-select>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="pagedLines.length <= 0">
                            <td colspan="8" style="text-align: center;font-weight: bold">Sem Resultados</td>
                        </tr>
                        <tr v-else v-for="line in pagedLines">
                            <td class="pl-4">{{ line.businessName }}</td>
                            <td class="pl-4">{{ line.financialName }}</td>
                            <td class="pl-4">{{ line.email }}</td>
                            <td class="text-center">{{ line.nif }}</td>
                            <td class="text-center">{{ line.phoneNumber }}</td>
                            <td class="text-center" :style="{color: line.imported_at != null ? 'green' : 'red'}">
                                {{ line.imported_at != null ? line.imported_at.substring(0,10) : 'não importado'}}
                            </td>
                            <td class="text-center">
                                {{ line.created_at.substring(0,19) }}
                            </td>
                            <td class="text-center">
                                <v-btn tile x-small color="primary"  @click="$router.push({path:'/clients/clientsRegistration/' + line.id})">
                                    Detalhes
                                </v-btn>
                            </td>
                            <td>
                                <v-btn id="delete-registration" tile x-small color="error" @click="deleteRegistration(line.id)">
                                    Apagar
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import _ from "lodash";

export default {
    name: "ClientsRegistration",
    data: function () {
        return {
            lines: [],
            originalLines: [],
            selectedHeader: "date",
            dialogVisibility: false,
            showImported: false,
            filters: {
                businessName: '',
                financialName: '',
                email: '',
                nif: '',
                phoneNumber: '',
                importado: 0
            },
            headers: {
                "businessName": 'asc',
                "financialName": 'asc',
                "email": 'asc',
                "nif": 'asc',
                "phoneNumber": 'asc',
            },
            pagination: {
                rowsPerPage: 25,
                page: 1
            },
            itemPerPage: [
                {text: '50', value: 50},
                {text: '100', value: 100},
                {text: '250', value: 250},
                {text: '500', value: 500}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            importadosOption : [
                {TEXT: 'TODOS', VALUE: ''},
                {TEXT: 'SIM', VALUE: 1},
                {TEXT: 'NÃO', VALUE: 0}
            ]
        }
    },
    created() {
        document.title = 'New Clients | STAFF';
        this.getClientRegistrations();
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.pagination.rowsPerPage)
        },
        pagedLines: function () {
            return this.orderedLines.slice((this.pagination.page - 1) * this.pagination.rowsPerPage, this.pagination.page * this.pagination.rowsPerPage);
        },
        orderedLines: function () {
            return _.orderBy(this.lines, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    },
    methods: {
        getClientRegistrations() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/clients/registration'
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            //console.log(responseData);
                            this.originalLines = responseData.clients;
                            // console.log(this.originalLines)
                            this.lines = responseData.clients.map(((x) => x));
                            this.total = this.originalLines.length;
                        }
                        this.updateTable()
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;

        },
        updateTable() {
            this.lines = this.originalLines.map(((x) => x))

            if (this.filters.businessName !== '') {
                this.lines = this.lines.filter(line => (line.businessName).toLowerCase().trim().includes(this.filters.businessName.toLowerCase().trim()))
            }
            if (this.filters.financialName !== '') {
                this.lines = this.lines.filter(line => (line.financialName).toLowerCase().trim().includes(this.filters.financialName.toLowerCase().trim()))
            }
            if (this.filters.email !== '') {
                this.lines = this.lines.filter(line => (line.email).toLowerCase().trim().includes(this.filters.email.toLowerCase().trim()))
            }
            if (this.filters.nif !== '') {
                this.lines = this.lines.filter(line => (line.nif).toLowerCase().trim().includes(this.filters.nif.toLowerCase().trim()))
            }
            if (this.filters.phoneNumber !== '') {
                this.lines = this.lines.filter(line => (line.phoneNumber).toLowerCase().trim().includes(this.filters.phoneNumber.toLowerCase().trim()))
            }
            if (this.filters.importado !== ''){
                this.lines = this.lines.filter(line => this.filters.importado ? line.imported_at != null : line.imported_at == null)
            }

            this.total = this.lines.length
            this.pagination.page = 1;

        },
        resetFilters() {
            this.filters = _.mapValues(this.filters, () => '')
            this.filters.importado = 0
            this.updateTable()
        },
        deleteRegistration(registrationId) {
            let request = {
                url: '/api/staff/clients/registration/' + registrationId,
            }
            this.loading = true
            let loader = this.$loading.show();

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            loader.hide()
                            this.$router.push({path:'/clients/clientsRegistration'})
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            loader.hide()
        }
    }
}
</script>
