<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card tile>
          <v-card-text>
            <v-toolbar class="mb-3" flat dense dark color="cyan darken-2">
              <v-toolbar-title class="text-uppercase">Histórico de Logins - Staff</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-row class="mb-3">
              <v-col cols="6" md="2" sm="3">
                <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    dense
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        dense
                        v-model="filters.initialDate"
                        label="Data Inicio"
                        prepend-icon="event"
                        readonly
                        hide-details
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filters.initialDate" locale="pt-pt" :show-current="currentDate"
                                 @input="menu2 = false" @change="updateTable"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="11" md="2" sm="3">
                <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        dense
                        v-model="filters.finalDate"
                        label="Data Final"
                        prepend-icon="event"
                        readonly
                        hide-details
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="filters.finalDate" locale="pt-pt" :show-current="currentDate"
                                 @input="menu3 = false" @change="updateTable"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" offset-md="4" sm="6" class="text-right">
                <v-btn tile small color="error" @click="cleanFilters()" >Limpar Filtros</v-btn>
              </v-col>
            </v-row>

            <v-simple-table class="staff_table">
              <thead>
              <tr class="titles_row">
                <th style="width: 200px; cursor: pointer;" @click="orderList('user_id')" class="text-center">
                  Utilizador Id
                  <i v-if="selectedHeader === 'user_id'" class="fas" :class="[headers['user_id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                </th>
                <th style="cursor: pointer;" @click="orderList('name')" class="text-center">
                  Utilizador
                  <i v-if="selectedHeader === 'name'" class="fas" :class="[headers['name'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                </th>
                <th style="cursor: pointer;" @click="orderList('created_at')" class="text-center">
                  Data Login
                  <i v-if="selectedHeader === 'created_at'" class="fas" :class="[headers['created_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                </th>
              </tr>
              <tr class="filters_row">
                <td>
                  <v-text-field
                      v-model="filters.user_id"
                      outlined
                      hide-details
                      single-line
                      @keyup.enter="updateTable"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.name"
                      outlined
                      hide-details
                      single-line
                      @keyup.enter="updateTable"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.created_at"
                      outlined
                      hide-details
                      single-line
                      @keyup.enter="updateTable"
                  ></v-text-field>
                </td>
              </tr>
              </thead>
              <tbody>
              <tr v-if="loading">
                <td colspan="3">
                  <v-progress-linear indeterminate></v-progress-linear>
                </td>
              </tr>
              <tr v-for="login in pagedLogins">
                <td class="text-center">{{ login.user_id }}</td>
                <td>{{ login.name }}</td>
                <td class="text-center">{{ login.created_at }}</td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-row class="mt-2">
              <v-col :md="2">
                <v-select
                    v-model="pagination.rowsPerPage"
                    :items="itemPerPage"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    label="Registos por página"
                    class="staff_select"
                    @change="updateTable"
                ></v-select>
              </v-col>
              <v-col>
                <v-pagination
                    v-model="pagination.page"
                    :length="getTotalPages"
                    :total-visible="7"
                ></v-pagination>
                <!-- @input="updateTable" -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";

export default {
  data: () => ({
    search: '',
    menu2: false,
    menu3: false,
    loading: false,
    selected: [],
    logins: [],
    total: 0,
    originalLogins: [],
    pagination: {
      rowsPerPage: 250,
      page: 1
    },
    filters: {
      initialDate: '',
      finalDate: '',
      user_id: '',
      name: '',
      created_at: ''
    },
    itemPerPage: [
      {text: '50', value: 50},
      {text: '100', value: 100},
      {text: '250', value: 250},
      {text: '500', value: 500}
    ],
    currentDate: new Date().toISOString().substr(0, 10),
    selectedHeader: "created_at",
    headers: {
      "user_id": "asc",
      "name": "asc",
      "created_at": "desc",
    },
  }),
  mounted() {
    document.title = 'Logins';
    this.initialize()
  },
  methods: {
    initialize() {
      this.logins = [];
      this.loading = true;

      let request = {
        url: '/api/staff/user/logins',
        data: {
          pagination: this.pagination
        }
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {

            let responseData = response.data
            if (responseData.status === 'OK') {
              this.originalLogins = responseData.logins;
              this.logins = responseData.logins;
              this.total = this.logins.length;

              this.loading = false
            }
          })
          .catch(response => {
            this.loadingNoTracking = false
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    orderList(col){

      if(col == this.selectedHeader)
        this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
      else
        this.headers[this.selectedHeader] = "desc";

      this.selectedHeader = col;
      this.pagination.page=1;

    },
    renderDuration(duration) {
      var modDays = duration % 86400;
      var days = (duration - modDays) / 86400;
      var labelDays = '';
      if (days > 0) {
        labelDays = days + 'D ';
      }
      //obter as horas
      var modHours = modDays % 3600;
      var hours = (modDays - modHours) / 3600;
      //obter os minutos
      var modMinutes = modHours % 60;
      var minutes = (modHours - modMinutes) / 60;
      //os segundos sao o resto de tudo (modMinutes neste caso)
      return labelDays + hours + 'h ' + minutes + 'm ' + modMinutes + 's';
    },
    updateTable() {

      this.logins= this.originalLogins

      if (this.filters.initialDate !== '') {
        this.logins = this.logins.filter(login => login.created_at >= this.filters.initialDate + ' 00:00:00')
      }
      if (this.filters.finalDate !== '') {
        this.logins = this.logins.filter(login => login.created_at <= this.filters.finalDate + ' 23:59:59')
      }
      if (this.filters.user_id !== '') {
        this.logins = this.logins.filter(login => login.user_id == this.filters.user_id)
      }
      if (this.filters.name !== '') {
        this.logins = this.logins.filter(login =>
            login.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.created_at !== '') {
        this.logins = this.logins.filter(login => login.created_at.includes(this.filters.created_at))
      }

      this.total = this.logins.length

    },
    cleanFilters(){
      this.filters.initialDate = '';
      this.filters.finalDate = '';
      this.filters.user_id = '';
      this.filters.name = '';
      this.filters.created_at = '';

      this.updateTable()
    }
  },

  computed: {
    getTotalPages() {

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    },
    pagedLogins: function(){
      return this.orderedLogins.slice((this.pagination.page-1)*this.pagination.rowsPerPage,this.pagination.page*this.pagination.rowsPerPage);
    },
    orderedLogins: function () {
      return _.orderBy(this.logins, this.selectedHeader,this.headers[this.selectedHeader]);
    }
  }
}
</script>
