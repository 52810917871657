<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        :title="$t('sales')+' Cliente/Ano'"
                        :subtitle="'A mostrar '+salesClient.length+' de '+totalSalesClient"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-row>
                    <v-col>
                        <v-btn small @click="download" color="success">Download</v-btn>
                    </v-col>
                    <v-col cols="2">
                        <v-select
                                v-model="pagination.per_page"
                                :items="filtersPerPage"
                                outlined
                                single-line
                                @change="changeSelect"
                                item-text="TEXT"
                                item-value="VALUE"
                                style="width: 90px; margin-left: auto;"
                                class="staff_select mb-0"
                                hide-details
                        ></v-select>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="3" v-if="salesman.length > 0">
                <v-select
                        v-model="filters.salesman_id"
                        label="Vendedor"
                        :items="salesman"
                        item-value="Vendedor"
                        item-text="Nome"
                        @change="changeSelect"
                        class="staff_select"
                        hide-details
                        v-if="writePermission('reports/sales/client/year')"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
<!--                            <th style="white-space: nowrap" @click="setOrder('Pais', filters.orderAsc)">Pais-->
<!--                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'Pais'" class="fas fa-angle-down"></i>-->
<!--                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'Pais'" class="fas fa-angle-up"></i></th>-->
<!--                            <th style="white-space: nowrap" @click="setOrder('Descricao', filters.orderAsc)">Zona-->
<!--                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'Descricao'" class="fas fa-angle-down"></i>-->
<!--                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'Descricao'" class="fas fa-angle-up"></i></th>-->
                            <th style="white-space: nowrap">Pais</th>
                            <th style="white-space: nowrap">Zona</th>
                            <th style="white-space: nowrap" @click="setOrder('Cliente', filters.orderAsc)">Cliente
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'Cliente'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'Cliente'" class="fas fa-angle-up"></i></th>
                            <th style="white-space: nowrap" @click="setOrder('Nome', filters.orderAsc)">{{ $t('name') }}
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'Nome'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'Nome'" class="fas fa-angle-up"></i></th>
                            <th style="white-space: nowrap" @click="setOrder('NomeFiscal', filters.orderAsc)">{{ $t('taxName') }}
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'NomeFiscal'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'NomeFiscal'" class="fas fa-angle-up"></i>
                            </th>
                            <th style="white-space: nowrap" @click="setOrder('CDU_LOGIN', filters.orderAsc)">Email
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'CDU_LOGIN'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'CDU_LOGIN'" class="fas fa-angle-up"></i> </th>
                            <th style="white-space: nowrap" @click="setOrder('YEAR_N_2', filters.orderAsc)" class="text-right">{{ year(2) }}
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'YEAR_N_2'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'YEAR_N_2'" class="fas fa-angle-up"></i> </th>
                            <th style="white-space: nowrap" @click="setOrder('YEAR_N_1', filters.orderAsc)" class="text-right">{{ year(1) }}
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'YEAR_N_1'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'YEAR_N_1'" class="fas fa-angle-up"></i>
                            </th>
                            <th style="white-space: nowrap" @click="setOrder('YEAR_N', filters.orderAsc)" class="text-right">{{ year(0) }}
                                <i v-if="filters.orderAsc == false && filters.orderColumn == 'YEAR_N'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderAsc == true && filters.orderColumn == 'YEAR_N'" class="fas fa-angle-up"></i> </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="9">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="sale in salesClient">
                            <td>{{ sale.Pais }}</td>
                            <td>{{ sale.Descricao }}</td>
                            <td>{{ sale.Cliente }}</td>
                            <td>{{ sale.Nome }}</td>
                            <td>{{ sale.NomeFiscal }}</td>
                            <td>{{ sale.CDU_LOGIN }}</td>
                            <td class="text-right">{{ $money(sale.YEAR_N_2) }}</td>
                            <td class="text-right">{{ $money(sale.YEAR_N_1) }}</td>
                            <td class="text-right">{{ $money(sale.YEAR_N) }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="initialize"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data: () => ({
            filters: {
                salesman_id: 'ALL',
                orderColumn: 'Nome',
                orderAsc: true
            },
            salesman: [],
            loading: false,
            salesClient: [],
            pagination: {
                page: 1,
                per_page: 250
            },
            totalSalesClient: 0,
            filtersPerPage: [
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ]
        }),
        created() {
            document.title = i18n.t('sales') + ' Cliente/Ano'
            this.initialize()
        },
        methods: {
            initialize() {
                this.loading = true
                this.salesClient = []

                if (!this.writePermission('reports/sales/client/year')) {
                    this.filters.salesman_id = User.getters.getSalesmanId
                }

                let request = {
                    url: api.reportsSalesClientYear(),
                    data: {
                        filters: this.filters,
                        pagination: this.pagination
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.salesman = responseData.salesman
                            this.salesClient = responseData.salesClient
                            this.totalSalesClient = responseData.totalSalesClient
                            this.loading = false
                        } else {
                            this.loading = false
                        }
                    })
                    .catch(response => {
                        this.loading = false
                    })
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            setOrder(column, order) {
                this.filters.orderAsc = this.filters.orderColumn !== column ? order : !order
                this.filters.orderColumn = column
                this.initialize()
            },
            download() {
                Reports.dispatch('getSalesClientYearDownload', this.filters)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/csv'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'Report_Client_Year_' + this.filters.salesman_id + '_' + '.csv';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    })
                    .catch(response => {

                    })
            },
            year(last) {
                return new Date().getFullYear() - last
            },
            changeSelect() {
                this.pagination.page = 1
                this.initialize()
            },
            writePermission(permission) {
                return User.getters.writePermission(permission)
            }
        },
        computed: {
            getTotalPages() {
                return Math.ceil(this.totalSalesClient / this.pagination.per_page)
            }
        }
    }
</script>
