<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title title="Histórico Débitos" :subtitle="'A mostrar '+ getStartPage + '-' +getEndPage+ ' de '+totalLines+' débitos'"></page-title>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 80px">Line</th>
                            <th style="width: 80px">File</th>
                            <th style="width: 100px">Data Cobrança</th>
                            <th style="width: 120px">DocOriginal</th>
                            <th>Estado</th>
                            <th>Cliente</th>
                            <th class="text-right" style="width: 100px">Valor</th>
                            <th class="text-right" style="width: 100px">Recibo</th>
                            <th class="text-right" style="width: 100px">Estorno</th>
                            <th class="text-right" style="width: 100px">Adiant.</th>
                            <th>OK</th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                        v-model="filters.line"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.file"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.chargeDate"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.docOrigin"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.status"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.client"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.amount"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.recibo"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.estorno"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.adiantamento"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilter"
                                ></v-text-field>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="11">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="line in lines">
                            <td>{{ line.id_line }}</td>
                            <td>{{ line.id_file }}</td>
                            <td>{{ line.charge_date }}</td>
                            <td>{{ line.DocOriginal }}</td>
                            <td>{{ line.estado }}</td>
                            <td>{{ line.nome }}</td>
                            <td class="text-right">{{ $money(line.amount_to_pay) }}</td>
                            <td class="text-right">{{ line.recibo }}</td>
                            <td class="text-right">{{ line.estorno }}</td>
                            <td class="text-right">{{ line.adiantamento }}</td>
                            <td>
                                <i v-if="line.estado_id === '1' || line.estado_id === '124' || line.estado_id === '127'" class="fas fa-check-square fa-lg green--text"></i>
                                <i v-if="!(line.estado_id === '1' || line.estado_id === '124' || line.estado_id === '127')" class="fas fa-minus-square fa-lg red--text"></i>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        dense
                        outlined
                        hide-details
                        @change="setFilter"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="float: left; width: 100px;"
                ></v-select>
            </v-col>
            <v-col class="text-right">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="getHistoric"
                        style="justify-content: flex-end;"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    export default {
        data: function () {
            return {
                loading: false,
                lines: [],
                linesAux: [],
                pagination: {
                    page: 1,
                    per_page: 250
                },
                filters: {
                    line: '',
                    file: '',
                    chargeDate: '',
                    docOrigin: '',
                    status: '',
                    client: '',
                    amount: '',
                    recibo: '',
                    estorno: '',
                    adiantamento: ''
                },
                filtersPerPage: [
                    {TEXT: '25', VALUE: 25},
                    {TEXT: '50', VALUE: 50},
                    {TEXT: '100', VALUE: 100},
                    {TEXT: '250', VALUE: 250},
                    {TEXT: '500', VALUE: 500},
                    {TEXT: '750', VALUE: 750}
                ],
                totalLines: 0
            }
        },
        created() {
            this.getHistoric()
        },
        computed: {
            getTotalPages() {
                return Math.ceil(this.totalLines / this.pagination.per_page)
            },
            getStartPage() {
                return Math.ceil(this.lines.length * this.pagination.page - this.lines.length + 1)
            },
            getEndPage() {
                return Math.ceil(this.lines.length * this.pagination.page)
            }
        },
        methods: {
            getHistoric() {
                this.loading = true
                window.scrollTo(0, 0)

                let request = {
                    url: api.debitsHistoric(),
                    data: {
                        pagination: this.pagination,
                        filters: this.filters
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.lines = responseData.lines
                            this.totalLines = responseData.totalLines
                            this.linesAux = this.lines
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })
            },
            setFilter() {
                this.pagination.page = 1
                this.getHistoric()
            }
        }
    }
</script>
