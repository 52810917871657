<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row>
            <v-col cols="12" md="4">
                <div class="staff_v-card">
                    <v-row>
                        <v-col cols="12" md="4">
                            <page-title title="Perfis" :subtitle="$t('show')+' '+profiles.length+' perfis'"></page-title>
                        </v-col>
                    </v-row>
                    <div width="40px">
                        <v-text-field
                                dense
                                label="Nome"
                                v-model="filters.name"
                                outlined
                                hide-details
                                @keyup="filterProfiles()"
                        ></v-text-field>
                    </div>
                    <v-simple-table class="staff_table">
                        <thead>
                        <div width="40px">
                            <v-btn small
                                   color="success"

                                   @click="btnInsertProfile()">
                                <v-icon small>person_add</v-icon>
                            </v-btn>
                        </div>
                        <tr>
                            <th class="text-center">ID</th>
                            <th class="text-center">Nome</th>
                            <th>Ativo</th>
                            <th class="text-center">Editar</th>
                            <th>Delete</th>
                            <th>Regras</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="19">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="profile in profiles" :class="selectedProfile.id === profile.id ? 'green lighten-3' : ''">
                            <td class="text-center">{{ profile.id }}</td>
                            <td class="text-center">{{ profile.name }}</td>
                            <td class="text-center">{{ profile.active }}</td>
                            <td class="text-center">
                                <v-btn x-small
                                       color="primary"

                                       @click.stop="btnEditDetail(profile)">
                                    <v-icon small>edit</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn x-small
                                       color="error"

                                       @click="btnDeleteProfile(profile)">
                                    <v-icon small>delete</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn x-small
                                       color="success"

                                       @click="mostrarRegras(profile)">
                                    <v-icon small>domain</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
            <v-col cols="12" md="8">
                <div class="staff_v-card" v-if="rulesProfile === true">
                    <v-row>
                        <v-col cols="12" md="4">
                            <page-title title="Regras Aplicadas" :subtitle="$t('show')+' '+rules.length+' regras'"></page-title>
                        </v-col>
                    </v-row>
                    <tr>
                        <th width="50px">
                            <v-text-field
                                    dense
                                    label="ID"
                                    v-model="filtersRules.id"
                                    outlined
                                    hide-details
                                    @keyup="filterRules()"
                            ></v-text-field>
                        </th>
                        <th width="150px">
                            <v-text-field
                                    dense
                                    label="Nome"
                                    v-model="filtersRules.name"
                                    outlined
                                    hide-details
                                    @keyup="filterRules()"
                            ></v-text-field>
                        </th>
                        <th width="140px">
                            <v-text-field
                                    dense
                                    label="Value1"
                                    v-model="filtersRules.value1"
                                    outlined
                                    hide-details
                                    @keyup="filterRules()"
                            ></v-text-field>
                        </th>
                        <th width="160px">
                        </th>
                        <th width="100px">
                        </th>
                        <th>
                            <v-text-field
                                    dense
                                    label="Modo"
                                    v-model="filtersRules.mode"
                                    outlined
                                    hide-details
                                    @keyup="filterRules()"
                            ></v-text-field>
                        </th>
                        <th width="150px">
                            <v-text-field
                                    dense
                                    label="Tipo"
                                    v-model="filtersRules.type"
                                    outlined
                                    hide-details
                                    @keyup="filterRules()"
                            ></v-text-field>
                        </th>
                        <th width="80px">
                        </th>
                    </tr>
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th class="text-center">ID</th>
                            <th class="text-center">Nome</th>
                            <th>Value1</th>
                            <th class="text-center">Value2</th>
                            <th>Rule2Profile</th>
                            <th>Mode</th>
                            <th>Type</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loadingRules">
                            <td colspan="19">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="rule in rules" v-if="loadingRules === false">
                            <td class="text-center">{{ rule.id }}</td>
                            <td>{{ rule.name }}</td>
                            <td>{{ rule.value1 }}</td>
                            <td>{{ rule.value2 }}</td>
                            <td class="text-center">{{ rule.weight }}</td>
                            <td>{{ rule.mode }}</td>
                            <td>{{ rule.type }}</td>
                            <td>
                                <v-btn x-small
                                       color="error"

                                       @click="remover(rule)">
                                    <v-icon small>cancel</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
                <div class="staff_v-card" v-if="rulesProfile === true">
                    <v-row>
                        <v-col cols="12" md="4">
                            <page-title title="Regras Não Aplicadas" :subtitle="$t('show')+' '+rulesNotApplied.length+' regras'"></page-title>
                        </v-col>
                    </v-row>
                    <tr>
                        <th width="50px">
                            <v-text-field
                                    dense
                                    label="ID"
                                    v-model="filtersRulesNotApplied.id"
                                    outlined
                                    hide-details
                                    @keyup="filterRulesNotApplied()"
                            ></v-text-field>
                        </th>
                        <th width="180px">
                            <v-text-field
                                    dense
                                    label="Nome"
                                    v-model="filtersRulesNotApplied.name"
                                    outlined
                                    hide-details
                                    @keyup="filterRulesNotApplied()"
                            ></v-text-field>
                        </th>
                        <th width="140px">
                            <v-text-field
                                    dense
                                    label="Value1"
                                    v-model="filtersRulesNotApplied.value1"
                                    outlined
                                    hide-details
                                    @keyup="filterRulesNotApplied()"
                            ></v-text-field>
                        </th>
                        <th width="100px">
                        </th>
                        <th width="100px">
                        </th>
                        <th>
                            <v-text-field
                                    dense
                                    label="Modo"
                                    v-model="filtersRulesNotApplied.mode"
                                    outlined
                                    hide-details
                                    @keyup="filterRulesNotApplied()"
                            ></v-text-field>
                        </th>
                        <th width="170px">
                            <v-text-field
                                    dense
                                    label="Tipo"
                                    v-model="filtersRulesNotApplied.type"
                                    outlined
                                    hide-details
                                    @keyup="filterRulesNotApplied()"
                            ></v-text-field>
                        </th>
                        <th width="80px">
                        </th>
                    </tr>
                    <v-simple-table class="staff_table">
                        <thead>
                        <v-btn small
                               color="success"

                               @click="btnInsertRule()">
                            <v-icon small>post_add</v-icon>
                        </v-btn>
                        <tr>
                            <th class="text-center">ID</th>
                            <th class="text-center">Nome</th>
                            <th>Value1</th>
                            <th class="text-center">Value2</th>
                            <th>Rule2Profile</th>
                            <th>Mode</th>
                            <th>Type</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loadingRules">
                            <td colspan="19">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="rule in rulesNotApplied" v-if="loadingRules === false">
                            <td class="text-center">{{ rule.id }}</td>
                            <td>{{ rule.name }}</td>
                            <td>{{ rule.value1 }}</td>
                            <td>{{ rule.value2 }}</td>
                            <td class="text-center">{{ rule.weight }}</td>
                            <td>{{ rule.mode }}</td>
                            <td>{{ rule.type }}</td>
                            <td>
                                <v-btn x-small
                                       color="primary"

                                       @click="btnEditRule(rule)">
                                    <v-icon small>edit</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn x-small
                                       color="error"

                                       @click="btnDeleteRule(rule)">
                                    <v-icon small>delete</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn x-small
                                       color="success"

                                       @click="aplicarRegra(rule)">
                                    <v-icon small>done</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
            <!--            DIALOG PARA EDITAR REGRA -->
            <v-dialog
                    v-model="dialogEditRule"
                    max-width="290">
                <v-card>
                    <v-card-title class="headline">
                        <span v-if="editMode">Editar Regra</span>
                        <span v-else>Adicionar Nova Regra</span>
                    </v-card-title>
                    <v-col>
                        <v-text-field
                                v-model="editRule.name"
                                dense
                                outlined
                                label="Nome"
                                hide-details
                                class="mt-4"
                        ></v-text-field>
                        <v-select
                                v-model="editRule.mode_id"
                                dense
                                :items="modeItems"
                                item-value="VALUE"
                                item-text="TEXT"
                                outlined
                                label="Mode"
                                hide-details
                                class="mt-4"
                        ></v-select>
                        <v-select
                                v-model="editRule.type_id"
                                dense
                                :items="typeItems"
                                item-value="VALUE"
                                item-text="TEXT"
                                outlined
                                label="Type"
                                hide-details
                                class="mt-4"
                        ></v-select>
                        <v-text-field
                                v-model="editRule.value1"
                                dense
                                outlined
                                label="Value1"
                                hide-details
                                class="mt-4"
                        ></v-text-field>
                        <v-text-field
                                v-model="editRule.value2"
                                dense
                                outlined
                                label="Value2"
                                hide-details
                                class="mt-4"
                        ></v-text-field>
                        <v-select
                                v-model="editRule.weight"
                                dense
                                :items="weightItems"
                                item-value="VALUE"
                                item-text="TEXT"
                                outlined
                                label="Peso"
                                hide-details
                                class="mt-4"
                        ></v-select>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="red lighten-1"
                                text
                                @click="dialogEditRule = false">
                            Cancelar
                        </v-btn>

                        <v-btn
                                color="blue lighten-1"
                                text
                                @click="saveRule()">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--            DIALOG PARA EDITAR PROFILE -->
            <v-dialog
                    v-model="dialogEditDetail"
                    max-width="290">
                <v-card>
                    <v-card-title class="headline">
                        <span v-if="editMode">Editar Perfil</span>
                        <span v-else>Adicionar Novo Perfil</span>
                    </v-card-title>
                    <v-col>
                        <v-text-field
                                v-model="editProfile.name"
                                dense
                                outlined
                                label="Nome"
                                hide-details
                                class="mt-4"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                                v-model="editProfile.active"
                                dense
                                :items="activeItems"
                                item-value="VALUE"
                                item-text="TEXT"
                                outlined
                                label="Selecionar"
                                hide-details
                                class="mt-4"
                        ></v-select>
                    </v-col>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="red lighten-1"
                                text
                                @click="dialogEditDetail = false">
                            Cancelar
                        </v-btn>

                        <v-btn
                                color="blue lighten-1"
                                text
                                @click="saveProfile()">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--            DIALOG PARA ELIMINAR PROFILE -->
            <v-dialog
                    v-model="deleteProfileDialog"
                    max-width="290"
                    overlay-color="red lighten-3">
                <v-card>
                    <v-card-title class="headline">Eliminar Perfil?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="red darken-1"
                                text
                                @click="deleteProfileDialog = false">
                            Cancelar
                        </v-btn>

                        <v-btn
                                color="red darken-1"
                                text
                                @click="eliminarProfile">
                            Eliminar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--            DIALOG PARA ELIMINAR REGRA -->
            <v-dialog
                    v-model="deleteRuleDialog"
                    max-width="290"
                    overlay-color="red lighten-3">
                <v-card>
                    <v-card-title class="headline">Eliminar Regra?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                                color="red darken-1"
                                text
                                @click="deleteRuleDialog = false">
                            Cancelar
                        </v-btn>

                        <v-btn
                                color="red darken-1"
                                text
                                @click="eliminarRegra">
                            Eliminar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        loading: true,
        dialogEditDetail: false,
        deleteProfileDialog: false,
        dialogEditRule: false,
        deleteRuleDialog: false,
        rulesProfile: false,
        loadingRules: false,
        profiles: [],
        profilesOriginal: [],
        rules: [],
        rulesOriginal: [],
        rulesNotApplied: [],
        rulesNotAppliedOriginal: [],
        activeItems: [
            {TEXT: 'Ativo', VALUE: 1},
            {TEXT: 'Não Ativo', VALUE: 0},
        ],
        modeItems: [
            {TEXT: 'Inclusivo', VALUE: '1'},
            {TEXT: 'Exclusivo', VALUE: '2'},
        ],
        typeItems: [
            {TEXT: 'Generic', VALUE: '1'},
            {TEXT: 'Marca', VALUE: '2'},
            {TEXT: 'Familia', VALUE: '3'},
            {TEXT: 'Marca / Familia', VALUE: '4'},
            {TEXT: 'Artigo', VALUE: '5'},
            {TEXT: 'Ano', VALUE: '7'},
            {TEXT: 'Familia / Ano', VALUE: '8'},
            {TEXT: 'Artigo Incluir Perfis', VALUE: '13'},
        ],
        weightItems: [
            {TEXT: '1', VALUE: 1},
            {TEXT: '2', VALUE: 2},
            {TEXT: '3', VALUE: 3},
            {TEXT: '4', VALUE: 4},
            {TEXT: '5', VALUE: 5},
        ],
        editProfile: {},
        deleteProfile: {},
        items: [],
        filters: {
            name: '',
        },
        filtersRules: {
            id: '',
            name: '',
            value1: '',
            mode: '',
            type: '',
        },
        filtersRulesNotApplied: {
            id: '',
            name: '',
            value1: '',
            mode: '',
            type: '',
        },
        deleteRule: {},
        editRule: {},
        selectedProfile: '',
        editMode: false
    }),
    computed: {},
    created() {
        document.title = 'Perfis';
        this.initialize()
    },
    methods: {
        initialize() {
            this.loading = true;
            this.profiles = [];
            let request = {
                url: '/api/staff/primavera/profiles',
                data: {
                    profiles: this.profiles
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.profiles = responseData.profiles;
                            this.profilesOriginal = this.profiles;
                            this.filterProfiles();
                        } else {

                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
        },
        filterProfiles() {
            this.profiles = this.profilesOriginal;
            if (this.filters.name !== '') {
                this.profiles = this.profiles.filter(item => item.name.toUpperCase().includes(this.filters.name.toUpperCase()))
            }
        },
        filterRules() {
            this.rules = this.rulesOriginal;
            if (this.filtersRules.id !== '') {
                this.rules = this.rules.filter(item => item.id.toUpperCase().includes(this.filtersRules.id.toUpperCase()))
            }
            if (this.filtersRules.name !== '') {
                this.rules = this.rules.filter(item => item.name.toUpperCase().includes(this.filtersRules.name.toUpperCase()))
            }
            if (this.filtersRules.value1 !== '') {
                this.rules = this.rules.filter(item => item.value1.toUpperCase().includes(this.filtersRules.value1.toUpperCase()))
            }
            if (this.filtersRules.mode !== '') {
                this.rules = this.rules.filter(item => item.mode.toUpperCase().includes(this.filtersRules.mode.toUpperCase()))
            }
            if (this.filtersRules.type !== '') {
                this.rules = this.rules.filter(item => item.type.toUpperCase().includes(this.filtersRules.type.toUpperCase()))
            }
        },
        filterRulesNotApplied() {
            this.rulesNotApplied = this.rulesNotAppliedOriginal;
            if (this.filtersRulesNotApplied.id !== '') {
                this.rulesNotApplied = this.rulesNotApplied.filter(item => item.id.toUpperCase().includes(this.filtersRulesNotApplied.id.toUpperCase()))
            }
            if (this.filtersRulesNotApplied.name !== '') {
                this.rulesNotApplied = this.rulesNotApplied.filter(item => item.name.toUpperCase().includes(this.filtersRulesNotApplied.name.toUpperCase()))
            }
            if (this.filtersRulesNotApplied.value1 !== '') {
                this.rulesNotApplied = this.rulesNotApplied.filter(item => item.value1.toUpperCase().includes(this.filtersRulesNotApplied.value1.toUpperCase()))
            }
            if (this.filtersRulesNotApplied.mode !== '') {
                this.rulesNotApplied = this.rulesNotApplied.filter(item => item.mode.toUpperCase().includes(this.filtersRulesNotApplied.mode.toUpperCase()))
            }
            if (this.filtersRulesNotApplied.type !== '') {
                this.rulesNotApplied = this.rulesNotApplied.filter(item => item.type.toUpperCase().includes(this.filtersRulesNotApplied.type.toUpperCase()))
            }
        },
        btnEditDetail(profile) {
            this.editProfile = {};
            this.editProfile = Object.assign({}, profile);
            this.editMode = true
            this.dialogEditDetail = true
        },
        btnDeleteProfile(profile) {
            this.deleteProfile = {};
            this.deleteProfile = Object.assign({}, profile);
            this.deleteProfileDialog = true
        },
        btnDeleteRule(rule) {
            this.deleteRule = {};
            this.deleteRule = Object.assign({}, rule);
            this.deleteRuleDialog = true
        },
        btnInsertProfile() {
            this.editProfile.id = ''
            this.editProfile.name = ''
            this.editProfile.active = ''
            this.editMode = false
            this.dialogEditDetail = true
        },
        btnInsertRule() {
            this.editRule.id = ''
            this.editRule.mode_id = ''
            this.editRule.type_id = ''
            this.editRule.name = ''
            this.editRule.value1 = ''
            this.editRule.value2 = ''
            this.editRule.weight = ''
            this.editMode = false
            this.dialogEditRule = true
        },
        btnEditRule(rule) {
            this.editRule = {};
            this.editRule = Object.assign({}, rule);
            this.editMode = true;
            this.dialogEditRule = true;
        },
        mostrarRegras(profile) {
            this.selectedProfile = profile;
            this.loadingRules = true;
            let request = {
                url: '/api/staff/primavera/profiles/rules',
                data: {
                    profile: profile.id,
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.rulesProfile = true;
                            this.rules = responseData.rules;
                            this.rulesNotApplied = responseData.rulesNotApplied;
                            this.loadingRules = false;
                            this.rulesOriginal = this.rules;
                            this.rulesNotAppliedOriginal = this.rulesNotApplied;
                            this.filterRules();
                            this.filterRulesNotApplied();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {})
                    })
        },
        remover(rule) {
            let request = {
                url: '/api/staff/primavera/profiles/remover',
                data: {
                    rule: rule,
                    profile: this.selectedProfile.id
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.rulesProfile = true;
                            this.rules = responseData.rules;
                            this.rulesNotApplied = responseData.rulesNotApplied;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Regra removida do perfil!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao remover a regra!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {})
                    })
        },
        saveRule() {
            if (this.editMode) {
                let request = {
                    url: '/api/staff/primavera/profiles/editarRegra',
                    data: {
                        rule: this.editRule,
                    }
                };
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.dialogEditRule = false;
                                this.rules = responseData.rules;
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Regra editada com sucesso!',
                                    type: 'success'
                                });
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao editar a regra!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {})
                        })
            } else {
                let request = {
                    url: '/api/staff/primavera/profiles/adicionarRegra',
                    data: {
                        rule: this.editRule,
                    }
                };
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.dialogEditRule = false;
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Regra criada com sucesso!',
                                    type: 'success'
                                });
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao criar a regra!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {})
                        })
            }
        },
        eliminarRegra() {
            let request = {
                url: '/api/staff/primavera/profiles/eliminarRegra',
                data: {
                    rule: this.deleteRule,
                    profile: this.profile,
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.deleteRuleDialog = false;
                            this.rulesNotApplied = responseData.rulesNotApplied;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Regra removida com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao remover a regra!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {})
                    })
        },
        aplicarRegra(rule) {
            let request = {
                url: '/api/staff/primavera/profiles/aplicarRegra',
                data: {
                    rule: rule,
                    profile: this.selectedProfile.id
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.rulesProfile = true;
                            this.rules = responseData.rules;
                            this.rulesNotApplied = responseData.rulesNotApplied;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Regra aplicada com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao aplicar a regra!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {})
                    })
        },
        eliminarProfile() {
            let request = {
                url: '/api/staff/primavera/profiles/eliminarProfile',
                data: {
                    profile: this.deleteProfile,
                    rule: this.rules
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.deleteProfileDialog = false;
                            window.location.reload();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Perfil removido com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao remover o perfil!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {})
                    })
        },
        saveProfile() {
            if (this.editMode) {
                let request = {
                    url: '/api/staff/primavera/profiles/editarProfile',
                    data: {
                        profile: this.editProfile
                    }
                };
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Perfil atualizado com sucesso!',
                                    type: 'success'
                                });
                                this.dialogEditDetail = false;
                                this.initialize()
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao atualizar cliente!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            } else {
                let request = {
                    url: '/api/staff/primavera/profiles/insertProfile',
                    data: {
                        profile: this.editProfile,
                    }
                };
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.dialogEditDetail = false;
                                this.profiles = responseData.profiles;
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Perfil criado com sucesso!',
                                    type: 'success'
                                });
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao criar o perfil!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {})
                        })
            }
        }
    }
}
</script>
