<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title title="Banners bicimax.com"></page-title>
                <p class="mb-0 pl-2" style="color: gray">
                    Nesta página é possivel gerir os banners que são apresentados na correspondente página de cada Marca
                    visivel no <strong>bicimax.com</strong>.</p>
                <p class="pl-2 mb-0" style="font-size: 13px;font-weight: bold;color: gray">Os banners devem ser no
                    formato <span style="color:rgb(244, 67, 54)">.jpg</span> e ter um máximo de <span
                            style="color:rgb(244, 67, 54)">2MB</span></p>
            </v-col>
        </v-row>

        <!-- INFO BANNER ROW -->
        <v-row v-if="prestashopBrands != null">
            <v-col>
                <!-- SELECT BRAND + ACTION BUTTONS -->
                <v-row>
                    <v-col style="display: flex;align-items: center;justify-content: space-between;">
                        <!-- SELECIONAR A MARCA -->
                        <v-select
                                :items="prestashopBrands"
                                outlined
                                single-line
                                v-model="selectedBrand"
                                item-value="name"
                                class="mt-0 ml-2"
                                label="Seleciona a Marca ..."
                                dense
                                hide-details
                                @change="newBanner = ''; getBrandBanner()"
                                style="max-width: 500px"
                        >
                            <template v-slot:item="{ item }" style="text-transform: capitalize">
                                {{ item.name }}
                            </template>
                            <template v-slot:selection="{ item }" style="text-transform: capitalize">
                                <strong>{{ item.name }}</strong>
                            </template>
                        </v-select>
                        <div v-if="editBanner">
                            <v-btn
                                    tile
                                    color="success"
                                    class="mr-2 ml-2"
                                    @click="saveBanner"
                            >
                                <v-icon left>
                                    mdi-content-save
                                </v-icon>
                                Guardar
                            </v-btn>
                            <v-btn
                                    tile
                                    color="rgb(244, 67, 54)"
                                    @click="editBanner = !editBanner"
                                    style="color:white"
                            >
                                <v-icon left>
                                    mdi-close
                                </v-icon>
                                Cancelar
                            </v-btn>
                        </div>

                        <div v-if="!editBanner">
                            <v-btn
                                    v-if="!editBanner"
                                    tile
                                    color="warning"
                                    class="ml-2"
                                    v-model="editBanner"
                                    :disabled="selectedBrand == ''"
                                    @click="editBanner = !editBanner"
                            >
                                <v-icon left>
                                    mdi-pencil
                                </v-icon>
                                Editar
                            </v-btn>
                            <v-btn
                                    v-if="!editBanner"
                                    tile
                                    color="rgb(244, 67, 54)"
                                    style="color: white"
                                    class="ml-2"
                                    :disabled="selectedBrand == ''"
                                    @click="deleteBanner()"
                            >
                                <v-icon left>
                                    mdi-delete
                                </v-icon>
                                Remover
                            </v-btn>
                        </div>

                    </v-col>
                </v-row>

                <!-- FILE INPUT -->
                <v-row v-if="editBanner" style="border-top: 1px solid rgba(0,0,0,.05); padding-top: 12px;">
                    <v-col class="pt-0">
                        <template>
                            <v-file-input
                                    show-size
                                    dense
                                    outlined
                                    hide-details
                                    class="pt-2 pb-2"
                                    accept="image/jpeg"
                                    placeholder="Escolha a imagem"
                                    prepend-icon="mdi-camera"
                                    label="Selecionar a nova imagem"
                                    style="padding: 0 20%;"
                                    v-model="newBanner"
                                    @change="fileChange"
                            ></v-file-input>
                        </template>
                    </v-col>
                </v-row>

                <!-- PREVIEW BANNER - TITLE -->
                <v-row style="border-top: 1px solid rgba(0,0,0,.05);">
                    <v-col cols="12">
                        <h3 class="text-center text-uppercase"
                            style="font-weight: 900;font-size: 1.5rem;text-decoration: underline;text-decoration-color: rgb(244, 67, 54);">
                            Preview do banner</h3>
                    </v-col>
                </v-row>

                <!-- PREVIEW BANNER ON BICIMAX PAGE -->
                <div class="container-banner-preview mt-2" style="box-shadow: 0 0 8px rgba(0,0,0,0.5);">
                    <v-img :src="require('../../assets/img/header.png')"/>
                    <!-- NEW BANNER -->
                    <v-img v-if="newBanner != null && newBanner != ''" :src="newBannerURL" id="new-banner-preview"/>
                    <!-- ACTUAL BANNER -->
                    <!--                    <v-img v-else-if="selectedBrand != ''"-->
                    <!--                           :src="'https://static.bicimax.com/bicimaxcom/brand_banner_' + selectedBrand + '.jpg'"/>-->
                    <v-img v-else-if="selectedBrand != '' && selectedBanner != '' && selectedBanner != null"
                           :src="'https://static.bicimax.com/bicimaxcom/' + selectedBanner.value"/>
                    <!-- NO BRAND SELECTED -->
                    <div v-else style="min-height: 535px;display: flex; justify-content: center;align-items: center;">
                        <h1>{{ messageInfo }}</h1>
                    </div>
                    <v-img :src="require('../../assets/img/after_banner.png')"/>
                </div>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import axios from "axios";

export default {
    data: function () {
        return {
            loading: false,
            selectedBrand: '',
            selectedBanner: '',
            newBanner: '',
            newBannerURL: '',
            editBanner: false,
            prestashopBrands: null,
        }
    },
    created() {
        this.getBrands();
    },
    methods: {
        fileChange(file) {
            if (!file) {
                return;
            } else {
                const reader = new FileReader();
                reader.onload = e => {
                    this.newBannerURL = e.target.result;
                }
                reader.readAsDataURL(file)
            }
        },
        getBrands() {
            let loader = this.$loading.show();
            axios.get(api.bicimaxBrands())
                    .then(response => {
                        let responseData = response.data
                        // console.log(responseData)
                        if (responseData.status === 'OK') {
                            this.prestashopBrands = responseData.data
                            this.prestashopBrands.sort((a, b) => a.name.localeCompare(b.name))
                        }
                        loader.hide()
                    })
                    .catch(response => {
                        loader.hide()
                    })
        },
        getBrandBanner() {
            let loader = this.$loading.show();

            axios.get(api.bicimaxBrandBanner(this.selectedBrand))
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.selectedBanner = responseData.data.results[0];
                        } else {
                            this.selectedBanner = null;
                        }

                        loader.hide()
                    })
                    .catch(response => {
                        loader.hide()
                    })
        },
        saveBanner() {
            let loader = this.$loading.show();
            // console.log(this.selectedBrand, this.newBanner, this.newBannerURL)

            let body = {
                key: 'brandBanner-' + this.selectedBrand,
                // name: this.newBanner.name,
                image: this.newBannerURL
            }

            //IF SELECTEDBANNER != null MEANS THAT ALREADY EXIST A BANNER FOR THIS BRAND, SO -> UPDATE delete_at on old banner
            if (this.selectedBanner != null) {
                //delete old_banner

                let request = {url: 'api/staff/bicimax-com/configurations/' + this.selectedBanner.id};

                ApiWS.dispatch('makeDelete', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === "OK") {
                                //Everything OK - deleting old banner
                                //Create new banner
                                axios.post(api.bicimaxCreateBrandBanner(), body)
                                        .then(response => {
                                            loader.hide()
                                            let alert = {
                                                snackbar: true,
                                                text: 'Sucesso!',
                                                type: 'success'
                                            }

                                            if (response.status == 200) {
                                                //Banner created with success

                                            } else {
                                                alert = {
                                                    snackbar: true,
                                                    text: 'Erro!',
                                                    type: 'error'
                                                }
                                            }

                                            Alert.dispatch('show', alert)
                                        })
                                        .catch(response => {
                                            loader.hide()
                                            alert = {
                                                snackbar: true,
                                                text: 'Erro!',
                                                type: 'error'
                                            }
                                            Alert.dispatch('show', alert)
                                        })
                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
            //Create banner
            axios.post(api.bicimaxCreateBrandBanner(), body)
                    .then(response => {
                        loader.hide()
                        let alert = {
                            snackbar: true,
                            text: 'Sucesso!',
                            type: 'success'
                        }

                        if (response.status == 200) {
                            //Banner created with success

                        } else {
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                        }

                        Alert.dispatch('show', alert)
                    })
                    .catch(response => {
                        loader.hide()
                        alert = {
                            snackbar: true,
                            text: 'Erro!',
                            type: 'error'
                        }
                        Alert.dispatch('show', alert)
                    })
        },
        deleteBanner() {
            let loader = this.$loading.show();

            let request = {url: 'api/staff/bicimax-com/configurations/' + this.selectedBanner.id};

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        loader.hide()
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            //Everything OK - deleting old banner
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            location.reload()
                        }
                    })
                    .catch(() => {
                        loader.hide()
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        location.reload()
                    })
        }
    },
    computed: {
        messageInfo() {
            if (this.selectedBanner == null) {
                return "Marca " + this.selectedBrand.toUpperCase() + " sem banner associado!";
            } else {
                return "SELECIONE UMA MARCA"
            }
        }
    }

}
</script>