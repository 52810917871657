<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title title="Banner Locations"
                            :subtitle="$t('show') + ' ' + lines.length + ' de ' + originalLines.length + ' Linhas'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small class="ml-2 mb-2" @click="resetFilters()" color="error">Limpar Filtros</v-btn>
                <v-btn tile small color="primary" class="ml-2 mb-2" @click="toggleDialogVisibility()">
                    Adicionar
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 100px; cursor: pointer;" @click="orderList('id')" class="text-center">ID
                                <i v-if="selectedHeader === 'id'" class="fas"
                                   :class="[headers['id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('page')" class="text-center">
                                Página
                                <i v-if="selectedHeader === 'page'" class="fas"
                                   :class="[headers['page'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('position')"
                                class="text-center">Posição
                                <i v-if="selectedHeader === 'position'" class="fas"
                                   :class="[headers['position'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('ratioHorizontal')"
                                class="text-center">Rácio Horizontal
                                <i v-if="selectedHeader === 'ratioHorizontal'" class="fas"
                                   :class="[headers['ratioHorizontal'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('ratioVertical')"
                                class="text-center">Rácio Vertical
                                <i v-if="selectedHeader === 'ratioVertical'" class="fas"
                                   :class="[headers['ratioVertical'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('created_at')"
                                class="text-center">Data de criação
                                <i v-if="selectedHeader === 'created_at'" class="fas"
                                   :class="[headers['created_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 200px; cursor: pointer;" @click="orderList('updated_at')"
                                class="text-center">Data de atualização
                                <i v-if="selectedHeader === 'updated_at'" class="fas"
                                   :class="[headers['updated_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                        </tr>
                        <tr class="filters_row">
                            <th>
                                <v-text-field outlined hide-details v-model="filters.id"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.page"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.position"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.ratioHorizontal"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.ratioVertical"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.created_at"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.updated_at"
                                              @change="updateTable()"></v-text-field>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="line in pagedLines" v-if="line.deleted_at ==null">
                            <td>{{ line.id }}</td>
                            <td class="text-center">{{ line.page }}</td>
                            <td class="text-center">{{ line.position }}</td>
                            <td class="text-center">{{ line.ratioHorizontal }}</td>
                            <td class="text-center">{{ line.ratioVertical }}</td>
                            <td class="text-center">{{ line.created_at }}</td>
                            <td class="text-center" width="11%">{{ line.updated_at }}</td>
                            <td class="text-center" width="12%">
                                <v-btn tile small width="60%" color="error" class="mb-1 mt-1"
                                       @click="toggleDelete(line.id)">
                                    REMOVER
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-select
                        v-model="pagination.rowsPerPage"
                        :items="filtersPerPage"
                        dense
                        outlined
                        hide-details
                        item-text="TEXT"
                        item-value="VALUE"
                        style="float: left; width: 100px;"
                ></v-select>
            </v-col>
            <v-col class="text-right">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        style="justify-content: flex-end;"
                ></v-pagination>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogVisibility" persistent max-width="400">
            <v-card>
                <v-card-title>
                    Adicionar Localização de Banner
                </v-card-title>
                <v-row>
                    <v-col>
                        <v-form v-model="formValid" ref="myForm">
                            <v-text-field class="mx-2" label="Página" filled v-model="pageInput"
                                          :rules="rules.required"></v-text-field>
                            <v-text-field class="mx-2" label="Posição" filled v-model="positionInput"
                                          :rules="rules.required"></v-text-field>
                            <v-text-field class="mx-2" label="Rácio Horizontal" filled v-model="ratioHorizontalInput"
                                          :rules="rules.required"></v-text-field>
                            <v-text-field class="mx-2" label="Rácio Vertical" filled v-model="ratioVerticalInput"
                                          :rules="rules.required"></v-text-field>
                        </v-form>

                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-btn color="error" @click="toggleDialogVisibility()">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addBannerLocation()">Adicionar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" overlay-color="white" overlay-opacity="0.5" persistent max-width="600">
            <v-card>
                <v-card-title class="red--text">
                    Tem a certeza que quer apagar esta localização de banner?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="error" @click="deleteDialog = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="deleteBannerLocation()">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import _ from "lodash";

export default {
    name: "BannerLocation",
    data: function () {
        return {
            bannerLocations: [],
            bannerLocation: null,
            idToDelete: null,
            pageInput: '',
            positionInput: '',
            ratioVerticalInput: '',
            ratioHorizontalInput: '',
            lines: [],
            originalLines: [],
            total: [],
            formValid: false,
            rules: {
                required: [value => !!value || "Required."]
            },
            filters: {
                id: '',
                page: '',
                position: '',
                ratioHorizontal: '',
                ratioVertical: '',
                created_at: '',
                updated_at: ''
            },
            selectedHeader: "id",
            headers: {
                "id": 'asc',
                "page": 'asc',
                "position": 'asc',
                "ratioHorizontal": 'asc',
                "ratioVertical": 'asc',
                "created_at": 'asc',
                "updated_at": 'asc'
            },
            pagination: {
                rowsPerPage: 25,
                page: 1
            },
            itemPerPage: [
                {text: '50', value: 50},
                {text: '100', value: 100},
                {text: '250', value: 250},
                {text: '500', value: 500}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            dialogVisibility: false,
            deleteDialog: false,
            selectedDocInfo: {
                id: ''
            },
            // validate: false,
        }
    },
    mounted() {
        // this.validate = this.$refs.myForm.validate();
    },
    created() {
        document.title = 'BannerLocations | STAFF';
        this.getBannerLocations();
    },
    methods: {
        getBannerLocations() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/banners-location'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.originalLines = responseData.bannerLocations;
                            this.lines = responseData.bannerLocations;
                            this.total = this.originalLines.length;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        addBannerLocation() {
            if (this.$refs.myForm.validate()) {
                this.dialogVisibility = false
                let request = {
                    url: '/api/staff/b2b/banners-location',
                    data: {
                        bannerLocation: {
                            page: this.pageInput,
                            position: this.positionInput,
                            ratioHorizontal: this.ratioHorizontalInput,
                            ratioVertical: this.ratioVerticalInput,
                        }
                    }
                }
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === "OK") {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Localização adicionada com sucesso!',
                                    type: 'success'
                                })
                                this.getBannerLocations();
                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
                this.pageInput = '';
                this.positionInput = '';
                this.ratioHorizontalInput = '';
                this.ratioVerticalInput = '';
            } else {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Preencha todos os campos!',
                    type: 'alert'
                })
            }

        },
        deleteBannerLocation() {
            let request = {
                url: '/api/staff/b2b/banners-location/' + this.idToDelete
            }

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            this.getBannerLocations();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.deleteDialog = !this.deleteDialog;
        },
        toggleDialogVisibility() {
            this.dialogVisibility = !this.dialogVisibility;
            this.pageInput = '';
            this.positionInput = '';
            this.ratioVerticalInput = '';
            this.ratioHorizontalInput = '';
        },
        toggleDelete(id) {
            this.deleteDialog = !this.deleteDialog;
            this.idToDelete = id;
        },
        updateTable() {
            this.lines = this.originalLines

            if (this.filters.id !== '') {
                this.lines = this.lines.filter(line => line.id == this.filters.id)
            }
            if (this.filters.page !== '') {
                this.lines = this.lines.filter(line => line.page.includes(this.filters.page))
            }
            if (this.filters.position !== '') {
                this.lines = this.lines.filter(line => line.position.includes(this.filters.position))
            }
            if (this.filters.ratioVertical !== '') {
                this.lines = this.lines.filter(line => line.ratioVertical == this.filters.ratioVertical)
            }
            if (this.filters.ratioHorizontal !== '') {
                this.lines = this.lines.filter(line => line.ratioHorizontal == this.filters.ratioHorizontal)
            }
            if (this.filters.created_at !== '') {
                this.lines = this.lines.filter(line => line.created_at != null && line.created_at.includes(this.filters.created_at))
            }
            if (this.filters.updated_at !== '') {
                this.lines = this.lines.filter(line => line.updated_at != null && line.updated_at.includes(this.filters.updated_at))
            }

            this.total = this.lines.length
            this.pagination.page = 1;

        },
        resetFilters() {
            this.filters = _.mapValues(this.filters, () => '')
            this.updateTable()
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;

        },
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.pagination.rowsPerPage)
        },
        pagedLines: function () {
            return this.orderedLines.slice((this.pagination.page - 1) * this.pagination.rowsPerPage, this.pagination.page * this.pagination.rowsPerPage);
        },
        orderedLines: function () {
            return _.orderBy(this.lines, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    }
}
</script>

<style scoped>

</style>