<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title title="Amazon Commingling"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn v-if="lines.length > 0" :disabled="input.typeDoc == ''" small class="mr-3" @click="makeInvoice()">Faturar</v-btn>
                <file-upload
                        :url='urlCommingling'
                        btn-label="Importar Excel"
                        :headers="headers"
                        accept=".xlsx, .xls, .csv"
                        @success="onUploadSuccess"
                        @error="onUploadError"
                ></file-upload>
            </v-col>
        </v-row>

        <v-row v-if="lines.length > 0">
            <v-col>
                <div class="staff_v-card mb-3">
                    <v-row>
                        <v-col>
                            <v-select
                                    label="DOC"
                                    v-model="input.typeDoc"
                                    hide-details
                                    dense
                                    outlined
                                    :items="typeDocs"
                                    item-text="text"
                                    item-value="value"
                            ></v-select>
                        </v-col>
                        <v-col>
                            Marketplace: <strong>{{ header.marketplace }}</strong>
                        </v-col>
                        <v-col>
                            Data: <strong>{{ header.invoiceDate }}</strong>
                        </v-col>
                        <v-col>
                            Referencia: <strong>{{ header.invoiceNumber }}</strong>
                        </v-col>
                        <v-col>
                            Total Doc: <strong>{{ $money(totalDoc) }}</strong>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-simple-table class="staff_table">
                                <thead>
                                <tr>
                                    <th>ASIN</th>
                                    <th>Produto</th>
                                    <th>Descrição</th>
                                    <th class="text-center">Preço</th>
                                    <th class="text-center">Quantidade</th>
                                    <th class="text-center">Taxa IVA</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="line in lines">
                                    <td>{{ line.asin }}</td>
                                    <td>{{ line.productId }}</td>
                                    <td>{{ line.description }}</td>
                                    <td class="text-center">{{ line.price }}</td>
                                    <td class="text-center">{{ line.quantity }}</td>
                                    <td class="text-center">{{ line.VATRate }}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <v-row justify="center">
            <!-- GENERIC DIALOG -->
            <v-dialog v-model="dialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ dialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">

                            <template v-if="dialog.loading">
                                <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
                            </template>

                            <template v-if="dialog.progress">
                                <v-progress-circular rotate="-90" color="primary" size="100"
                                                     :value="dialog.progressCurrent">
                                    {{ dialog.progressStep }}/{{ dialog.progressTotal }}
                                </v-progress-circular>
                            </template>

                            <template v-if="!dialog.loading && !dialog.progress">
                                <i v-if="dialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!dialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ dialog.message }}</p>
                                <v-alert v-if="dialog.alert" type="error">
                                    {{ dialog.alertMessage }}
                                </v-alert>
                            </template>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="!dialog.loading && !dialog.progress" color="success" @click="dialog.open = false"
                               style="margin-left: auto">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import FileUpload from 'v-file-upload'

export default {
    components: {
        FileUpload
    },
    data: () => ({
        menuDate: false,
        input: {
            typeDoc: ''
        },
        typeDocs: [
            {'text': 'FACOM', 'value': 'FACOM'},
            {'text': 'VFCOM', 'value': 'VFCOM'}
        ],
        countries: [
            {'text': 'ES', 'value': 'ES'},
            {'text': 'DE', 'value': 'DE'},
            {'text': 'FR', 'value': 'FR'},
            {'text': 'IT', 'value': 'IT'},
            {'text': 'UK', 'value': 'UK'},
            {'text': 'PL', 'value': 'PL'},
            {'text': 'SE', 'value': 'SE'},
            {'text': 'NL', 'value': 'NL'},
        ],
        urlCommingling: '',
        dialog: {
            open: false,
            loading: false,
            progress: false,
            error: false,
            alert: false,
            title: '',
            message: '',
            alertMessage: '',
            progressCurrent: 0,
            progressStep: 0,
            progressTotal: 0,
        },
        headers: {},
        header: {},
        lines: []
    }),
    mounted() {
        this.urlCommingling = process.env.VUE_APP_API_URL + '/api/staff/bikeplanet/commingling/xls';
        this.headers = {'Authorization': 'Bearer ' + User.getters.getAccessToken};
    },
    created() {
        document.title = 'Amazon Commingling'
    },
    computed: {
        totalDoc() {
            let total = 0;
            this.lines.forEach((line) => {
                total += line.price * line.quantity;
            })

            return total;
        }
    },
    methods: {
        onUploadSuccess(response) {
            let responseData = response.target.response;
            if (responseData.status === 'OK') {
                this.lines = responseData.lines;
                this.header = responseData.header;
                // this.input.date = new Date().toISOString().substr(0, 10);
                // this.validPayments = responseData.lines;
                // this.totalDocuments = responseData.totalDocumentos;
                // this.totalPending = responseData.totalPendente;
                // this.totalSeur = responseData.totalSeur;
            } else {
                this.newDialog('A processar o ficheiro XLS...');
                this.errorDialog(responseData.message)
            }
        },
        onUploadError() {
            this.errorDialog('Ocorreu um erro ao importar o ficheiro');
        },
        newDialog(title) {
            this.dialog.title = title;
            this.dialog.message = '';
            this.dialog.alertMessage = '';
            this.dialog.loading = true;
            this.dialog.progress = false;
            this.dialog.progressCurrent = 0;
            this.dialog.progressStep = 0;
            this.dialog.error = false;
            this.dialog.alert = false;
            this.dialog.open = true;
        },
        errorDialog(message) {
            this.dialog.message = message;
            this.dialog.loading = false;
            this.dialog.progress = false;
            this.dialog.error = true
        },
        makeInvoice() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/bikeplanet/commingling/invoice',
                data: {
                    input: this.input,
                    header: this.header,
                    lines: this.lines
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.dialog.message = responseData.data.Message;
                            this.dialog.loading = false;
                            this.dialog.title = "Fatura criada com sucesso!";
                            this.dialog.open = true;
                            this.lines = [];
                            this.header = {};
                        } else {
                            this.errorDialog("Erro ao criar fatura!");
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide()
                    })
        }
    }
}
</script>
