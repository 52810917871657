<style scoped>
    .v-data-table td, .v-data-table th {
        padding: 0 6px;
        height: 26px;
    }
    .v-icon.v-icon.fas {
        font-size: 16px;
    }
</style>
<template>
    <div>
        <v-card v-if="loading" height="280">
            <v-card-text class="text-center" style="height: 100%;">
                <i class="fas fa-cog fa-spin fa-3x" style="margin-top: 90px"></i>
            </v-card-text>
        </v-card>
        <v-card v-if="!loading">
            <v-card-title><i class="fas fa-shopping-cart fa-fw mr-2"></i>B2B</v-card-title>
            <v-card-text>
                <v-simple-table dense style="background-color: transparent;">
                    <thead>
                    <tr>
                        <th></th>
                        <th class="text-center">Logins</th>
                        <th class="text-center">Encomendas</th>
                        <th class="text-right" v-if="profile === 1 || profile === 2 || profile === 14">Valor</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in lines">
                        <td>{{ line.dia }}</td>
                        <td class="text-center">{{ line.logins }}</td>
                        <td class="text-center">{{ line.encomendas }}</td>
                        <td class="text-right" v-if="profile === 1 || profile === 2 || profile === 14">{{ $money(line.valor) }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </div>
</template>


<script>
    export default {
        data: function () {
            return {
                loading: true,
                lines: [],
            }
        },
        created() {
            this.initialize()
        },
        methods: {
            initialize() {
                let request = {
                    url: api.dashboardWidgetTableB2B()
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.lines = responseData.lines
                            this.loading = false
                        }
                    })
                    .catch(response => {

                    })
            }
        },
        computed: {
            profile() {
                return User.getters.getProfileId
            }
        }
    }
</script>
