<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="6" md="3">
                <page-title
                        :title="$t('orders')"
                        :subtitle="$t('show') + ' ' + orders.length + ' de ' + originalOrders.length + ' ' + $t('orders').toLowerCase()"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  class="ml-6 mb-2" :color="ordersOption === 'today' ? 'primary' : ''"
                       @click="changeOrdersOption('today')">
                    {{ $t('today') }}
                </v-btn>
                <v-btn tile small  class="mb-2" :color="ordersOption === 'all' ? 'primary' : ''"
                       @click="changeOrdersOption('all')">
                    {{ $t('all') }}
                </v-btn>
                <v-btn tile small  class="ml-2 mb-2" @click="clearFilters()" color="error"
                       :block="$vuetify.breakpoint.xs">
                    Limpar Filtros
                </v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <!-- TABLE HEADERS -->
                        <tr class="titles_row">
                            <th style="width: 1px;" class="text-center">A1</th>
                            <th style="width: 45px;" class="text-center">País</th>
                            <th style="min-width: 85px; width: 90px;">{{ $t('entity') }}</th>
                            <th>{{ $t('name') }}</th>
                            <th style="width: 80px;">Data</th>
                            <th style="width: 160px;">{{ $t('product') }}</th>
                            <th style="width: 110px;">CodBarras</th>
                            <th>{{ $t('description') }}</th>
                            <th style="width: 120px;">Marca</th>
                            <th style="width: 32px;" class="text-center">Qt</th>
                            <th>Data Disponibilidade</th>
                        </tr>
                        <!-- FILTERS -->
                        <tr class="filters_row">
                            <td>
                                <v-select
                                        v-model="filters.stock"
                                        :items="filterStock"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterOrders"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.country"
                                        :items="filtersCountry"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterOrders"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.entity"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.date"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.product"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.barcode"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.description"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.brand"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="order in orders">
                            <tr v-if="differentDate(order)">
                                <td colspan="19" class="date_row">{{ order.DataEntrega }}</td>
                            </tr>
                            <tr :class="{'row_proforma': order.CDU_LinVar5ENC, 'row_pick': order.IDPick}">
                                <td class="text-center">
                                    <v-chip small :color="stockAtual(order)" text-color="white">
                                        {{ order.STKActual }}
                                    </v-chip>
                                </td>
                                <td class="text-center">{{ order.Entidade_pais }}</td>
                                <td>
                                    <v-chip v-if="order.Entidade === 'VDSHOP' || order.Entidade === 'VDSHOPES'"
                                            @click="selectEntity(order.Entidade)" style="cursor: pointer" small
                                            color="error" text-color="white">
                                        {{ order.Entidade }}
                                    </v-chip>
                                    <div v-if="!(order.Entidade === 'VDSHOP' || order.Entidade === 'VDSHOPES')"
                                         @click="selectEntity(order.Entidade)" style="cursor: pointer">
                                        <span>{{ order.Entidade }}</span>
                                        <a :href="'/clients/clients/'+order.Entidade" target="_blank" class="ml-1"><i
                                                class="fas fa-fw fa-external-link-alt"></i></a>
                                    </div>
                                </td>
                                <td>
                                    <i v-show="order.TipoCred == 2" class="fas fa-minus-circle" style="color: red"></i>
                                    {{ order.ClienteNome }}
                                </td>
                                <td style="white-space: nowrap">{{ order.Data }}</td>
                                <td>
                                    <span @click="selectProduct(order.Artigo)" style="cursor: pointer">{{
                                            order.Artigo
                                        }}</span>
                                    <a :href="'/products/products/'+order.Artigo" target="_blank" class="ml-1"><i
                                            class="fas fa-fw fa-external-link-alt"></i></a>
                                </td>
                                <td>{{ order.CodBarras }}</td>
                                <td>{{ order.ArtigoDescricao }}</td>
                                <td @click="selectBrand(order.Marca)">{{ order.Marca }}</td>
                                <td class="text-center">{{ order.Quantidade }}</td>
                                <td class="text-center">{{ order.DateAvailability }}</td>
                            </tr>
                        </template>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import i18n from "@/plugins/i18n";

export default {
    data: function () {
        return {
            ordersOption: 'today',
            orders: [],
            originalOrders: [],
            filters: {
                stock: '',
                country: '',
                entity: '',
                name: '',
                date: '',
                product: '',
                barcode: '',
                description: '',
                brand: '',
            },
            filtersCountry: [
                {text: '-', value: ''},
                {text: 'PT', value: 'PT'},
                {text: 'ES', value: 'ES'}
            ],
            filterStock: [
                {text: '', value: ''},
                {text: 'Sim', value: '1'},
                {text: 'Não', value: '0'}
            ],
        }
    },
    private: {
        currentDate: ''
    },
    created() {
        document.title = i18n.t('orders') + ' Disponibilidade'
        this.getOrders()
    },
    methods: {
        getOrders() {
            let loader = this.$loading.show();

            this.orders = [];
            this.originalOrders = [];

            let request = {
                url: '/api/staff/orders/availability',
                data: {
                    today: this.ordersOption === 'today'
                }
            };

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.orders = responseData.orders;
                            this.originalOrders = this.orders
                            this.filterOrders();
                            loader.hide();
                        } else {
                            loader.hide();
                        }
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        filterOrders() {
            this.currentDate = ''
            this.orders = this.originalOrders
            if (this.filters.stock) {
                this.orders = this.orders.filter(order => this.filters.stock === '1' ? order.STKActual > 0 : order.STKActual < 1)
            }
            if (this.filters.country !== '') {
                this.orders = this.orders.filter(order => order.Entidade_pais === this.filters.country)
            }
            if (this.filters.entity !== '') {
                this.orders = this.orders.filter(order => order.Entidade.toUpperCase().includes(this.filters.entity.toUpperCase()))
            }
            if (this.filters.name !== '') {
                this.orders = this.orders.filter(order => order.ClienteNome.toLowerCase().includes(this.filters.name.toLowerCase()))
            }
            if (this.filters.date !== '') {
                this.orders = this.orders.filter(order => order.Data.includes(this.filters.date))
            }
            if (this.filters.product !== '') {
                this.orders = this.orders.filter(order => order.Artigo.toUpperCase().includes(this.filters.product.toUpperCase()))
            }
            if (this.filters.barcode !== '') {
                this.orders = this.orders.filter(order => (order.CodBarras !== null && order.CodBarras.includes(this.filters.barcode)))
            }
            if (this.filters.description !== '') {
                this.orders = this.orders.filter(order => order.ArtigoDescricao.toLowerCase().includes(this.filters.description.toLowerCase()))
            }
            if (this.filters.brand !== '') {
                this.orders = this.orders.filter(order => order.Marca.toLowerCase().includes(this.filters.brand.toLowerCase()))
            }
        },
        differentDate(order) {
            if (order.DataEntrega !== this.currentDate) {
                this.currentDate = order.DataEntrega
                return true
            }
            return false
        },
        stockAtual(order) {
            return order.Stock > 0 ? 'green' : 'red';
        },
        selectEntity(entity) {
            this.filters.entity = entity
            this.filterOrders()
        },
        selectProduct(product) {
            this.filters.product = product;
            this.filterOrders()
        },
        selectBrand(brand) {
            this.filters.brand = brand;
            this.filterOrders()
        },
        changeOrdersOption(option) {
            this.ordersOption = option;
            this.getOrders();
        },
        clearFilters() {
            this.filters.stock = ''
            this.filters.country = ''
            this.filters.entity = ''
            this.filters.name = ''
            this.filters.date = ''
            this.filters.product = ''
            this.filters.barcode = ''
            this.filters.description = ''
            this.filters.brand = ''
            this.filterOrders()
        },
    }
}
</script>
