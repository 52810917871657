<template>
    <v-app id="login">
        <v-main style="background-color: #ddd;">
            <v-container fluid class="mt-12">
                <v-row justify="center" class="mt-12">
                    <v-col cols="12" sm="8" md="4">
                        <v-img
                                :src="require('../assets/img/Staff.png')"
                                class="my-10"
                                max-height="64"
                                max-width="188"
                                style="margin: auto;"
                        ></v-img>

                        <v-card tile class="mx-auto elevation-6" max-width="360">

                            <v-card-text>
                                <v-container>

                                    <v-form ref="form">
                                        <p class="caption" style="margin-bottom: -12px">Email</p>
                                        <v-text-field :rules="[rules.required, rules.email]" v-model="user.email" name="email" type="email" ref="login" @click="errorLoginHidden = 'none'"></v-text-field>
                                        <p class="caption" style="margin-bottom: -12px">Password</p>
                                        <v-text-field :rules="[rules.required]" @click:append="show1 = !show1" :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'text' : 'password'" v-model="user.password" id="password" name="password" ref="password" v-on:keyup.enter="login(user)" @click="errorLoginHidden = 'none'"></v-text-field>
                                        <v-row align="center">
                                            <v-col class="text-center">
                                                <v-btn tile large @click.prevent="login(user)" :loading="loading" color="primary">Log In</v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>

                                </v-container>
                            </v-card-text>

                        </v-card>

                        <v-alert class="mt-12" type="error" :style="{'display': errorLoginHidden}">
                            {{ $t('errorLogin') }}
                        </v-alert>

                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import i18n from '../plugins/i18n'

    export default {
        data() {
            return {
                show1: false,
                loading: false,
                user: {
                    email: null,
                    password: null
                },
                rules: {
                    required: value => !!value || i18n.t('required'),
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || 'E-mail inválido'
                    }
                },
                errorLoginHidden: 'none'
            }
        },
        methods: {
            login(user) {
                if (this.$refs.form.validate()) {
                    this.loading = true;
                    User.dispatch('login', user)
                        .then(() => {
                            User.dispatch('getCurrentUser');
                            this.errorLoginHidden = 'none';
                            this.$router.push('/dashboard');
                        })
                        .catch(() => {
                            this.errorLoginHidden = '';
                            this.loading = false;
                        })
                }
            }
        }
    }
</script>
