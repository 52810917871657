import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from "axios";
import i18n from '../plugins/i18n'

Vue.use(Vuex)

const User = new Vuex.Store({
    plugins: [createPersistedState({
        key: 'user'
    })],

    state: {
        tokens: {
            access_token: null,
            expires_id: null,
            refresh_token: null,
            token_type: null
        },
        currentUser: {
            name: null,
            email: null
        },
        language: '',
        permissions: '',
    },
    actions: {
        login(context, user) {
            return new Promise((resolve, reject) => {
                let data = {
                    username: user.email,
                    password: user.password
                };

                axios.post(api.login(), data)
                    .then(response => {
                        console.log(response)
                        let responseData = response.data

                        if (responseData != null) {
                            let now = Date.now()

                            responseData.expires_in = responseData.expires_in + now

                            context.commit('updateTokens', responseData)

                            axios.defaults.headers.common['Authorization'] = `Bearer ${responseData.access_token}`

                            User.dispatch('getPermissions')

                            resolve(response)
                        }

                    })
                    .catch(response => {
                        console.log(response)
                        reject(response)
                    })
            });
        },
        logout(context) {
            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_API_URL + '/api/logout', '')
                    .then(response => {
                        resolve();
                    })
                    .catch(response => {
                    })
            })
        },
        getCurrentUser(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.currentUser())
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            context.commit('updateCurrentUser', responseData.data)
                            context.commit('updateLanguage', responseData.data.language)
                            i18n.locale = responseData.data.language;
                            resolve(response)
                        } else {
                            reject(response)
                        }
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getUsers() {
            return new Promise((resolve, reject) => {
                axios.get(api.users())
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            resolve(responseData)
                        } else {
                            reject(responseData)
                        }
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        changeLanguage(context, language) {
            return new Promise((resolve, reject) => {
                let data = {
                    language: language
                };
                axios.put(api.updateLanguageUser(), data)
                    .then(response => {
                        let respondeData = response.data
                        if (respondeData.status === 'OK') {
                            context.commit('updateLanguage', language)
                            i18n.locale = language;
                            location.reload()
                        }
                    })
                    .catch(response => {
                        console.log('error response', response)
                    })
            })
        },
        saveUser(context, user) {
            return new Promise((resolve, reject) => {
                let data = {
                    user: {
                        cod: user.cod,
                        name: user.name,
                        email: user.email,
                        B2BAccessCountry: user.B2BAccessCountry,
                        B2BAccessSeller: user.B2BAccessSeller,
                        active: user.active_id,
                        profile: user.profile_id,
                        password: user.password,
                        language: user.language,
                        phone: user.phone
                    }
                }
                axios.post(api.saveUser(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        updateUser(context, user) {
            return new Promise((resolve, reject) => {
                let data = {
                    user: {
                        id: user.id,
                        cod: user.cod,
                        name: user.name,
                        email: user.email,
                        B2BAccessCountry: user.B2BAccessCountry,
                        B2BAccessSeller: user.B2BAccessSeller,
                        active: user.active_id,
                        profile: user.profile_id,
                        password: user.password,
                        language: user.language,
                        phone: user.phone
                    }
                }
                axios.put(api.saveUser(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getPermissions(context) {
            axios.get(api.userPermissions())
                .then(response => {
                    let responseData = response.data
                    if (responseData.status === 'OK') {
                        context.commit('updatePermissions', responseData.data)
                    }
                })
                .catch(response => {
                    // reject(response)
                })
            // context.commit('permissions', alert)
        },
        getNotifications(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.userNotifications())
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        readNotification(context, id) {
            return new Promise((resolve, reject) => {
                axios.get(api.userReadNotification() + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        deleteNotification(context, id) {
            return new Promise((resolve, reject) => {
                axios.get(api.userDeleteNotification() + id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    },

    mutations: {
        updateTokens(state, tokens) {
            state.tokens = tokens
        },
        updateLanguage(state, language) {
            state.language = language
        },
        updateCurrentUser(state, currentUser) {
            state.currentUser = currentUser
        },
        updatePermissions(state, permissions) {
            state.permissions = permissions

            location.reload()
        }
    },

    getters: {
        getAccessToken(state) {
            return state.tokens.access_token
        },
        getLanguage(state) {
            return state.language
        },
        getProfileId(state) {
            return state.currentUser.profile_id;
        },
        getChangeProfile(state) {
            return state.currentUser.change_profile;
        },
        getPermissions(state) {
            return state.permissions
        },
        getSalesmanId(state) {
            return state.currentUser.salesman_id
        },
        getUserName(state) {
            return state.currentUser.name
        },
        readPermission: state => (permission) => {
            if (User.getters.getProfileId === 1) {
                return true
            } else {
                let havePermission = false
                if (state.permissions.length > 0) {
                    state.permissions.forEach(function (element) {
                        if (element.permission === permission) {
                            havePermission = true
                        }
                    })
                }

                return havePermission
            }
        },
        writePermission: state => (permission) => {
            if (User.getters.getProfileId === 1) {
                return true
            } else {
                let havePermission = false
                if (state.permissions.length > 0) {
                    state.permissions.forEach(function (elem) {
                        if (elem.permission === permission && elem.write === 1) {
                            havePermission = true
                        }
                    })
                }

                return havePermission
            }
        }
    }
})

export default User
