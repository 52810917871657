<template>

    <v-container fluid>
        <v-card>
            <v-card-title>
                Perfis
                <v-spacer></v-spacer>
                <v-btn tile small color="success" class="ml-3 mr-2" @click="toggleAddDialog()">
                    Adicionar
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th style="width: 200px; cursor: pointer;" @click="orderList('profileId')" class="text-center">Id de Profile
                            <i v-if="selectedHeader === 'profileId'" class="fas"
                               :class="[headers['profileId'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                        <th style="width: 200px; cursor: pointer;" @click="orderList('name')" class="text-center">Nome de Perfil
                            <i v-if="selectedHeader === 'name'" class="fas"
                               :class="[headers['name'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                        <th style="width: 200px; cursor: pointer;" @click="orderList('created_at')" class="text-center">Data de Criação
                            <i v-if="selectedHeader === 'created_at'" class="fas"
                               :class="[headers['created_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                    </tr>
                    <tr class="filters_row">
                        <th>
                            <v-text-field outlined hide-details v-model="filters.profileId" @change="updateTable()"></v-text-field>
                        </th>
                        <th>
                            <v-text-field outlined hide-details v-model="filters.name" @change="updateTable()"></v-text-field>
                        </th>
                        <th>
                            <v-text-field outlined hide-details v-model="filters.created_at" @change="updateTable()"></v-text-field>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in pagedLines">
                        <td class="text-center">{{ line.profileId }}</td>
                        <td class="text-center">{{ line.name }}</td>
                        <td class="text-center">{{ line.created_at }}</td>
                        <td class="text-center" style="width: 100px;">
                            <v-btn tile small width="60%"color="error" class="mb-1 mt-1" @click="toggleDelete(line.profileId)">
                                REMOVER
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
        <v-dialog v-model="deleteDialog" overlay-color="white" overlay-opacity="0.5" persistent max-width="640">
            <v-card>
                <v-card-title class="red--text">
                    Tem a certeza que quer apagar este perfil de banner?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="error" @click="deleteDialog = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="removeProfileFromBanner()">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAddProfileToBanner" overlay-color="white" overlay-opacity="0.5" persistent max-width="640">
            <v-card>
                <v-card-title>
                    Escolha um perfil para adicionar
                </v-card-title>
                <v-card-text>
                    <v-form v-model="formValid" ref="myForm">
<!--                    <v-select-->
<!--                            :items="profileList"-->
<!--                            outlined-->
<!--                            single-line-->
<!--                            item-text="name"-->
<!--                            item-value="id"-->
<!--                            :rules="rules.required"-->
<!--                            v-model="selectedProfile"-->
<!--                            class="mt-2 ml-2"-->
<!--                            label="Tipo de Banner"-->
<!--                            dense-->
<!--                            hide-details-->
<!--                    >-->
                        <v-select
                                :items="profileList"
                                outlined
                                single-line
                                multiple
                                chips
                                item-value="id"
                                :rules="rules.required"
                                v-model="selectedProfile"
                                class="mt-2 ml-2"
                                label="Perfil"
                                dense
                                hide-details
                        >
                        <template v-slot:item="{ item }">
                            {{ item.id }} - {{ item.name }}
                        </template>
                        <template v-slot:selection="{ item }">
                            {{ item.id }} - {{ item.name }}
                        </template>
                    </v-select>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="dialogAddProfileToBanner = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addProfileToBanner()">ADICIONAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import _ from "lodash";

export default {
    name: "Profiles",
    data: function (){
        return{
            bannerId: '',
            lines: [],
            profileList: [],
            profilesOnBanner: [],
            originalLines: [],
            dialogAddProfileToBanner: false,
            deleteDialog: false,
            inputStartDate: '',
            inputEndDate: '',
            selectedProfile: [],
            idToDelete: '',
            filters: {
                profileId: '',
                created_at: '',
                name: '',
            },
            rules: {
                required: [value => !!value || "Required."]
            },
            formValid: false,
            selectedHeader: "id",
            headers: {
                "profileId": 'asc',
                "created_at": 'asc',
                "name": 'asc',

            },
            pagination: {
                rowsPerPage: 25,
                page: 1
            },
            itemPerPage: [
                {text: '50', value: 50},
                {text: '100', value: 100},
                {text: '250', value: 250},
                {text: '500', value: 500}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
        }
    },
    created() {
        this.bannerId = this.$route.params.id;
        this.getProfiles();
        this.getAllProfilesAvailable();
    },
    methods: {
        getProfiles(){
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.bannerId + '/profiles'
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                      //  console.log(response)
                        if (responseData.status === "OK") {
                            this.originalLines = responseData.profiles;
                            this.profilesOnBanner = responseData.profiles;
                            this.lines = responseData.profiles;
                            this.total = this.originalLines.length;
                            loader.hide();
                            this.loading = false
                            console.log('getprofiles')
                        }
                        if (responseData.status === "NOK") {
                            loader.hide();
                            this.loading = false
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
                loader.hide();
                this.loading = false
            })
        },
        getAllProfilesAvailable(){
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2BProfiles/'
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.profileList = responseData.profiles;
                            for (let i = 0; i < this.profilesOnBanner.length; i++) {
                                for (let j = 0; j < this.profileList.length; j++) {
                                    // console.log(i);
                                    // console.log(this.profilesOnBanner[i].profileId.toString());
                                    // console.log(this.profileList[j].id.toString());
                                    if (this.profilesOnBanner[i].profileId.toString() == this.profileList[j].id.toString()){
                                       // console.log('helllloooo');
                                        this.profileList.splice(j--,1)
                                    }
                                }
                            }
                            loader.hide();
                            this.loading = false
                            console.log('getAllProfiles!')
                        }
                        if (responseData.status === "NOK") {
                            loader.hide();
                            this.loading = false
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
                loader.hide();
                this.loading = false
            })
        },
        addProfileToBanner(){
            if (!this.$refs.myForm.validate()){
                return
            }
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2BProfiles/',
                data:{
                    bannerProfiles:{
                        bannerId: this.bannerId,
                        profileIds: this.selectedProfile
                    }
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        console.log(responseData[0].code)
                        if (responseData[0].code == "201") {
                            console.log("if 201")
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Perfil Adicionado ao Banner!',
                                type: 'success'
                            })
                            this.getProfiles();
                            this.getAllProfilesAvailable();
                            loader.hide();
                            this.loading = false
                        }
                        if (responseData.status === "NOK") {
                            loader.hide();
                            this.loading = false
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
                loader.hide();
                this.loading = false
            })
            this.dialogAddProfileToBanner = false
        },
        removeProfileFromBanner(){
            let request = {
                url: '/api/staff/b2b/bannersB2BProfiles/'+ this.bannerId + '/' + this.idToDelete,

            }
            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show',{
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            this.getProfiles();
                            this.getAllProfilesAvailable();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.deleteDialog = !this.deleteDialog;
        },
        updateTable() {
            this.lines = this.originalLines

            if (this.filters.profileId !== '') {
                this.lines = this.lines.filter(line => line.id == this.filters.profileId)
            }
            if (this.filters.created_at !== '') {
                this.lines = this.lines.filter(line => line.created_at != null && line.created_at.includes(this.filters.created_at))
            }
            if (this.filters.name !== '') {
                this.lines = this.lines.filter(line => line.name != null && line.name.includes(this.filters.name))
            }

            this.total = this.lines.length
            this.pagination.page = 1;

        },
        resetFilters() {
            this.filters = _.mapValues(this.filters, () => '')
            this.updateTable()
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;

        },
        toggleAddDialog(){
            this.selectedProfile = [];
            this.dialogAddProfileToBanner = !this.dialogAddProfileToBanner;
        },
        toggleDelete(id){
            this.deleteDialog = !this.deleteDialog;
            this.idToDelete = id;
        }
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.pagination.rowsPerPage)
        },
        pagedLines: function () {
            return this.orderedLines.slice((this.pagination.page - 1) * this.pagination.rowsPerPage, this.pagination.page * this.pagination.rowsPerPage);
        },
        orderedLines: function () {
            return _.orderBy(this.lines, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    }
}
</script>

<style scoped>

</style>