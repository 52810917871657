import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"content-header"},[_c(VCol,[_c('page-title',{attrs:{"back":"","title":_vm.campaignName}})],1)],1),_c(VRow,[_c(VCol,[_c(VTabs,{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.index,on:{"click":function($event){_vm.activeTab = tab.index}}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),1),(_vm.activeTab === 0)?_c('CampaignDetails',{attrs:{"campaign":_vm.campaign}}):_vm._e(),(_vm.activeTab === 1)?_c('CampaignLevels',{attrs:{"campaign":_vm.campaign}}):_vm._e(),(_vm.activeTab === 2)?_c('CampaignClients',{attrs:{"campaign":_vm.campaign}}):_vm._e(),(_vm.activeTab === 3)?_c('CampaignProducts',{attrs:{"campaign":_vm.campaign}}):_vm._e(),(_vm.activeTab === 4)?_c('CampaignHistory',{attrs:{"campaign":_vm.campaign}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }