<style scoped>
.v-input__append-inner{
    margin-top: 5px !important;
}
</style>

<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="$t('nozamaOrders')"
                        :subtitle="$t('show') + ' ' + lines.length + ' de ' + maxLines + ' artigos'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  class="ml-2 mb-2" @click="exportExcel()"
                       :loading="exporting" color="success"><i
                        class="far fa-file-excel mr-1"></i>Export Excel
                </v-btn>
                <v-btn tile small  class="ml-2 mb-2" @click="getStats()" color="info"><i
                        class="fas fa-sync-alt mr-1"></i>Atualizar
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th>Artigo</th>
                        <th>Descrição</th>
                        <th>Marca</th>
                        <th class="text-center" width="100px"><strong>Total</strong></th>
                        <th class="text-center" width="100px">Encomendas</th>
                        <th class="text-center" width="100px">Maior Encomenda</th>
                        <th class="text-center" width="100px">Total Enviado</th>
                    </tr>
                    <tr class="filters_row">
                        <th>
                            <v-text-field outlined hide-details clearable v-model="filters.Artigo" @keyup.enter="getStats()"></v-text-field>
                        </th>
                        <th>
                            <v-text-field outlined hide-details v-model="filters.Descricao" @keyup.enter="getStats()"></v-text-field>
                        </th>
                        <th>
                            <v-select
                                    v-model="filters.Marca"
                                    :items="brands"
                                    item-text="Marca"
                                    item-value="Marca"
                                    outlined
                                    hide-details
                                    @change="getStats()"
                            ></v-select>
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody v-if="loading">
                    <tr>
                        <td colspan="7">
                            <v-progress-linear indeterminate></v-progress-linear>
                        </td>
                    </tr>
                    </tbody>
                    <tbody v-if="!loading">
                    <tr v-for="line in lines">
                        <td>{{ line.Artigo }}</td>
                        <td>{{ line.Descricao }}</td>
                        <td>{{ line.Marca }}</td>
                        <td class="text-center"><strong>{{ line.Total }}</strong></td>
                        <td class="text-center">{{ line.NumEncomendas }}</td>
                        <td class="text-center">{{ line.EncomendaMaior }}</td>
                        <td class="text-center">{{ line.totalPick }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
    data: function () {
        return {
            loading: false,
            exporting: false,
            lines: [],
            brands: [],
            maxLines: 0,
            filters: {
                Artigo: '',
                Descricao: '',
                Marca: '',
            }
        }
    },
    created() {
        document.title = i18n.t('nozamaOrders');
        this.getStats();
    },
    methods: {
        getStats() {
            this.loading = true
            let request = {
                url: '/api/staff/nozama/stats',
                data: {
                    filters: this.filters
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.lines = responseData.lines
                            this.maxLines = this.lines.length > this.maxLines ? this.lines.length : this.maxLines
                            this.brands = responseData.brands
                        }
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
        },
        exportExcel() {
            this.exporting = true
            let request = {
                url: '/api/staff/nozama/stats/excel',
            }
            ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var m = new Date();
                        var dateString = m.getUTCFullYear() +"-"+ (m.getUTCMonth()+1) +"-"+ m.getUTCDate();
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';

                        a.download = 'statsNozama_' + dateString + '.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        this.exporting = false
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        }
    }
}

</script>
