<template>
    <v-card>
        <v-card-title class="headline darken-1" :class="titleColor">
            {{ title }}
        </v-card-title>
        <v-card-text>
            <slot name="content"></slot>
        </v-card-text>
        <v-card-actions>
            <slot name="action"></slot>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        props: {
            title: String,
            titleColor: String
        }
    }
</script>
