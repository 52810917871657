<template>
    <v-layout>
<!--    <v-dialog v-model="showSelf" persistent max-width="984" @click:outside="closeDialogParent">-->
        <v-card width="100%">
            <v-btn
                v-if="fullScreen == false"
                color="grey lighten-3"
                dark
                fab
                top
                absolute
                right
                @click="closeDialogParent"
            >
                <span class="buttonClose">x</span>
            </v-btn>
            <v-container class="pa-16">
                <v-row class="mb-2">
                    <!--         ######################################################################
                                   IF SHOWING SINGLE PRODUCT INFO(All existing boxes for one product)
                                 ######################################################################
                    -->
                    <template v-if="singleMode">
                        <v-col>
                            <h4>Caixas do produto <span style="text-decoration: underline">{{ singleProduct }}</span></h4>
                        </v-col>
                    </template>
                    <template v-else>
                        <v-col>
                            <h4>Produtos</h4>
                        </v-col>
                        <v-col cols="8">
                            <h4>Caixas a adicionar</h4>
                        </v-col>
                    </template>
                </v-row>
                <hr style="opacity: 0.3" class="mb-4">
                <v-row>
                    <v-col v-if="singleMode==false" cols="4">
                        <v-container>
                            <v-row class="mt-1 mb-3" v-for="(product, productIndex) in products" :key="product">
                                <v-col cols="1">

                                </v-col>
                                <v-col>
                                    <span style="text-decoration: underline">{{ product }}</span>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-col>
                    <v-col>
                        <v-row v-for="(box, boxIndex) in boxQuantities" :key="boxIndex" align="center" class="mt-1" justify="center">
                            <v-col cols="1">
                                <i id="removeBox" v-if="((boxQuantities.length > 1 &&  boxIndex != 0) || (singleMode && box.updated==true))"
                                   @click="removeBox(box, boxIndex)" class="fas fa-times"></i>
                            </v-col>
                            <v-col cols="2">
                                <strong>
                                    <label for="boxQuantity">Quantidade</label>
                                </strong>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field id="boxQuantity" style="width:70px" name="boxQuantity" type="number"
                                              min="1" @change="limitQuantity(box)"
                                              :disabled="singleMode && box.updated==true"
                                              v-model.number="box.quantity" outlined dense hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <strong>
                                    <label for="boxDiscount">Desconto</label>
                                </strong>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field id="boxDiscount" style="width:70px" name="boxDiscount" type="number"
                                              min="1" max="100" @change="limitDiscount(box)"
                                              v-model.number="box.discount" outlined dense hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-combobox
                                        v-model="box.boxType"
                                        hide-details="auto"
                                        dense
                                        outlined
                                        :items="boxTypes"
                                >

                                </v-combobox>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center">
                            <i @click="newBox()" class="fas fa-plus addSign"
                               style="color: rgb(218, 218, 218);background-color: whitesmoke;font-size: 60px;border-radius: 30px;border-color: rgb(218, 218, 218);border-style: dashed;"></i>
                        </v-row>

                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions class="text-center">
                <v-spacer></v-spacer>
                <v-col>
                    <v-btn :disabled="boxQuantities.length == 0 || (singleMode == false && validBoxes.length == 0) || (singleMode==true && boxesChanged == false)" color="primary" @click="addBoxes()"
                    >Guardar
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="deleteBox" max-width="500">
            <v-card>
                <v-card-title>{{ $t('confirmChange') }}</v-card-title>
                <v-card-text>
                    Tem a certeza que deseja remover o desconto de <strong>{{ clickedBox.discount }}%</strong> nas caixas de <strong>{{ clickedBox.quantity }}</strong>
                </v-card-text>
                <v-card-actions>
                    <v-btn tile small @click="deleteBox=false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="deleteBoxConfirm()">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
<!--    </v-dialog>-->
    </v-layout>
</template>

<script>

import axios from "axios";
import _ from "lodash";

export default {
    data: () => ({
        showSelf: true,
        form: {
            idColumn: null,
            quantityColumn: null,
            startingRow: null,
            file: null
        },
        errorMessage: "",
        loaded: false,
        boxQuantities: [],
        boxTypes: ['CAIXA', 'FARDO'],
        //products: ['abcde', 'asdasdas', '12345'],
        //singleProductMode: true,
        singleMode: false,
        singleProduct: null,
        deleteBox: false,
        originalBoxes: {},
        clickedBox: {},
        boxesEdited: false
    }),
    name: "ProductBoxQuantityForm",
    props: {
        products: {
            type: Array,
            required: true
        },
        singleProductMode: {
            type: Boolean,
            required: false,
            default: false
        },
        fullScreen: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        this.singleMode = this.singleProductMode;
        if(this.products.length == 0)
            this.closeDialogParent();
        /** IF SHOWING SINGLE PRODUCT INFO(All existing boxes for one product) OR Only one product received on products list **/
        if(this.singleMode || this.products.length==1){
            this.singleMode = true;
            this.singleProduct = this.products[0];
            this.getSingleProductBoxes();
        }else {
            this.boxQuantities = [{quantity: '', discount: '', updated: false}];
        }
    },
    computed: {
        validBoxes() {
            return this.boxQuantities.filter((box) => {
                return (box.quantity > 0 && box.discount > 0)
            });
        },
        validBoxesSingle(){
            return this.boxQuantities.filter((box) => {
                return (box.quantity > 0 && box.discount > 0 && box.updated == false)
            });
        },
        boxesChanged(){
            if(this.validBoxesSingle.length == 0 || this.boxQuantities.length==1 && (this.boxQuantities[0].quantity == '' || this.boxQuantities[0].discount == ''))
                return false;

            //console.log(this.boxQuantities.length == 0 || (this.singleMode == false && this.validBoxes.length == 0) || (this.singleMode==true && (JSON.stringify(this.boxQuantities) !== JSON.stringify(this.originalBoxes)) == false));

            return (JSON.stringify(this.boxQuantities) !== JSON.stringify(this.originalBoxes));
        }
    },
    methods: {
        closeDialogParent() {
            this.$emit('close-box-form');
        },
        limitQuantity(box) {
            if (box.quantity <= 0)
                box.quantity = 1;

        },
        limitDiscount(box) {
            if (box.discount > 100)
                box.discount = 100;
            if (box.discount <= 0)
                box.discount = 1;
        },
        newBox() {
            this.boxQuantities.push({quantity: '', discount: '', boxType: 'CAIXA', updated: false});
        },
        addBoxes() {
            if ((this.singleMode == false && this.validBoxes.length == 0) || (this.singleMode == true && this.validBoxesSingle.length == 0)  )
                return;

            let loader = this.$loading.show();

            let request = {
                url: "/api/staff/products/boxes",
                data: {
                    products: this.singleMode == false ? this.products : [this.singleProduct],
                    boxes: this.singleMode == false ? this.validBoxes : this.validBoxesSingle
                }
            };
            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        if(this.singleMode == false){
                            this.boxQuantities = [{quantity: '', discount: '', updated:false}];
                        }else{
                            for(let i=0; i<this.boxQuantities.length;i++){
                                this.boxQuantities[i].updated = true;
                            }
                            this.boxQuantities = _.orderBy(this.boxQuantities, 'discount');
                            this.originalBoxes = JSON.parse(JSON.stringify(this.boxQuantities));
                        }
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Dados guardados com sucesso!',
                            type: 'success'
                        });
                    } else if (responseData.status == "WARN") {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Alguns dados não foram guardados corretamente, tente novamente!',
                            type: 'warning'
                        });
                    }
                    loader.hide();
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro ao guardar dados!',
                        type: 'error'
                    });
                    loader.hide();
                })
        },
        getSingleProductBoxes(){
            let loader = this.$loading.show();

            let request = {
                url: "/api/staff/products/" + this.singleProduct + "/boxes",
            };
            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.boxQuantities = responseData.data;
                        this.originalBoxes = JSON.parse(JSON.stringify(responseData.data));
                    }
                    loader.hide();
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro ao guardar dados!',
                        type: 'error'
                    });
                    loader.hide();
                })
        },
        removeBox(box, boxIndex) {
            /** IF in single product mode, delete will remove the box from the  database **/
            if(this.singleMode == true && box.updated == true){
                this.clickedBox = box;
                this.clickedBox.index = boxIndex;
                this.deleteBox = true;
            }else{
                this.boxQuantities.splice(boxIndex, 1);
            }
        },
        deleteBoxConfirm(){
            let loader = this.$loading.show();

            let request = {
                url: "/api/staff/products/" + this.singleProduct + "/boxes/" + this.clickedBox.quantity,
            };
            ApiWS.dispatch('makeDelete', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Dados guardados com sucesso!',
                            type: 'success'
                        });
                        this.boxQuantities.splice(this.clickedBox.index,1);
                        if(this.singleMode == true)
                            this.originalBoxes.splice(this.clickedBox.index,1);

                        if(this.boxQuantities.length == 0){
                            this.boxQuantities = [{quantity: '', discount: '', updated: false}];
                            if(this.singleMode == true)
                                this.originalBoxes = [{quantity: '', discount: '', updated: false}];
                        }
                    }
                    this.clickedBox = {};
                    this.deleteBox = false;
                    loader.hide();
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro ao guardar dados!',
                        type: 'error'
                    });
                    this.deleteBox = false;
                    loader.hide();
                })


        }
    }
}
</script>

<style scoped>


#removeBox {
    cursor: pointer;
    font-size: 28px;
    color: #F44336;
    opacity: 0.8;
}

#removeBox:hover {
    opacity: 1 !important;
}

#removeBox:active {
    font-size: 25px !important;
}

.buttonClose {
    margin-top: 24px;
    color: #a79090;
    font-size: 22px;
    font-weight: 1000;
}
</style>
