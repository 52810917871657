<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Permissões"
                        :subtitle="'A mostrar ' + permissions.length + ' permissões.'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-dialog v-model="dialog" max-width="400px">
                    <template v-slot:activator="{ on }" v-if="writePermission('administration/permissions')">
                        <v-btn tile small  color="info" class="ml-2" v-on="on">
                            {{ $t('addItem') }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="headline blue darken-2">
                            <v-row no-gutters>
                                <v-col class="text-uppercase">
                                <span style="color:white">
                                    {{ formTitle }}
                                </span>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-card-text class="pt-3">
                            <v-form ref="form">
                                <v-text-field v-model="editedItem.permission" :label="$t('permission')"
                                              :rules="[rules.required]" name="permission"></v-text-field>
                            </v-form>
                        </v-card-text>

                        <v-card-actions class="pa-3">
                            <v-btn tile small  @click.prevent="close">Cancelar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn tile small  color="success" @click.prevent="save">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="staff_v-card">

                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Permissão</th>
                            <th>Criado</th>
                            <th>Atualizado</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="16">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="item in permissions">
                            <td>{{ item.id }}</td>
                            <td>{{ item.permission }}</td>
                            <td>{{ item.created_at.substring(0, 19).replace('T', ' ') }}</td>
                            <td>{{ item.updated_at.substring(0, 19).replace('T', ' ') }}</td>
                            <td class="text-right">
                                <template v-if="writePermission('administration/permissions')">
                                    <v-btn class="mx-2" x-small tile outlined color="info" @click="editItem(item)">
                                        <i class="fas fa-pencil-alt"></i>
                                    </v-btn>
                                    <v-btn class="mx-2" x-small tile outlined color="error" @click="deleteItem(item)">
                                        <i class="fas fa-times"></i>
                                    </v-btn>
                                </template>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <!-- DIAOLOGS -->
        <v-row justify="center">
            <v-dialog v-model="dialogCancel" persistent max-width="400">
                <v-card>
                    <v-card-title class="headline red darken-2">
                        <v-row no-gutters>
                            <v-col class="text-uppercase">
                                <span style="color:white">
                                    {{ $t('removePermission') }}?
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="pt-3">
                        {{ $t('wantRemovePermission') }}? <br> <strong>{{ itemCancel.permission }}</strong>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-btn tile small  @click="dialogCancel = false">{{ $t('no') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  color="error" @click="removerPermissao()">{{ $t('yes') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'

export default {
    data: () => ({
        loading: true,
        show1: false,
        dialog: false,
        dialogCancel: false,
        permissions: [],
        editedIndex: -1,
        editedItem: {
            id: '',
            permission: ''
        },
        defaultItem: {
            id: '',
            permission: ''
        },
        rules: {
            required: value => !!value || value === 0 || i18n.t('required')
        },
        itemCancel: {}
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? i18n.t('addItem') : 'Editar'
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        }
    },
    created() {
        document.title = i18n.t('permissions')
        this.initialize()
    },
    methods: {
        initialize() {
            Permission.dispatch('getPermissions')
                    .then(response => {
                        this.loading = false;
                        this.permissions = response.data;
                    })
        },
        editItem(item) {
            this.editedIndex = this.permissions.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteItem(item) {
            this.itemCancel = item
            this.dialogCancel = true
            // const index = this.permissions.indexOf(item)
            // confirm('Are you sure you want to delete this item?') && this.users.splice(index, 1)
        },
        close() {
            this.dialog = false
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                this.$refs.form.resetValidation()
            }, 300)
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.editedIndex > -1) {
                    Permission.dispatch('updatePermission', this.editedItem)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    let alert = {
                                        snackbar: true,
                                        text: 'Permissão alterada com sucesso',
                                        type: 'success'
                                    }

                                    Alert.dispatch('show', alert)

                                    this.close()

                                    this.initialize()
                                } else {
                                    let alert = {
                                        snackbar: true,
                                        text: responseData.data,
                                        type: 'error'
                                    }

                                    Alert.dispatch('show', alert)
                                }
                            })
                            .catch(response => {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao alterar permissão',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            })
                } else {
                    Permission.dispatch('savePermission', this.editedItem)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    let alert = {
                                        snackbar: true,
                                        text: 'Permissão adicionada com sucesso',
                                        type: 'success'
                                    }

                                    Alert.dispatch('show', alert)

                                    this.close()

                                    this.initialize()
                                } else {
                                    let alert = {
                                        snackbar: true,
                                        text: responseData.data,
                                        type: 'error'
                                    }

                                    Alert.dispatch('show', alert)
                                }
                            })
                            .catch(response => {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao adicionar permissão',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            })
                }
                // this.close()
            }
        },
        removerPermissao() {
            Permission.dispatch('removerPermission', this.itemCancel)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let alert = {
                                snackbar: true,
                                text: 'Permissão removida com sucesso',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)

                            this.dialogCancel = false;

                            this.initialize()
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao remover permissão',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao remover permissão',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        }
    }
}
</script>
