import { render, staticRenderFns } from "./BoxBarcode.vue?vue&type=template&id=736aa1db"
import script from "./BoxBarcode.vue?vue&type=script&lang=js"
export * from "./BoxBarcode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports