import { VCard } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"float":"left"}},[_c(VCard,{staticClass:"mr-3 mb-3",attrs:{"dark":"","width":"200","color":"light-green lighten-1","href":"/orders/shippedHistory?filter=redur"}},[_c(VListItem,[_c(VListItemTitle,{staticClass:"headline"},[_vm._v("REDUR")])],1),_c(VListItem,[_c(VListItemContent,[_c('div',{staticClass:"overline",staticStyle:{"height":"30px","line-height":"15px"}},[_vm._v("REEMBOLSOS PENDENTES")])]),_c(VListItemAvatar,{attrs:{"tile":"","size":"40"}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.value))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }