<template>
    <v-container fluid grid-list-xl>
        <v-layout row justify-center>
            <v-flex xs4>
                <v-card>
                    <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/desert.jpg"
                            aspect-ratio="2.75"
                    ></v-img>
                    <v-card-title>
                        <div class="headline">Tabela de Preços Service Center - PT</div>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                                :value="pricePT.value"
                                label="Nome PDF"
                                readonly
                        ></v-text-field>
                        <v-text-field
                                :value="pricePT.updated_at"
                                label="Ultima atualização"
                                readonly
                        ></v-text-field>
                        <v-text-field label="Selecionar PDF" @click='pickFile' v-model='imageName' prepend-icon='attach_file'></v-text-field>
                        <input
                                type="file"
                                style="display: none"
                                ref="image"
                                accept="application/pdf"
                                @change="onFilePicked"
                        >
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-btn @click="savePTPrice">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-flex xs4>
                <v-card>
                    <v-img
                            src="https://cdn.vuetifyjs.com/images/cards/desert.jpg"
                            aspect-ratio="2.75"
                    ></v-img>
                    <v-card-title>
                        <div class="headline">Tabla de precios Service Center - ES</div>
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                                :value="priceES.value"
                                label="Nombre PDF"
                                readonly
                        ></v-text-field>
                        <v-text-field
                                :value="priceES.updated_at"
                                label="Última actualización"
                                readonly
                        ></v-text-field>
                        <v-text-field label="Selecionar PDF" @click='pickFile2' v-model='imageName2' prepend-icon='attach_file'></v-text-field>
                        <input
                                type="file"
                                style="display: none"
                                ref="image2"
                                accept="application/pdf"
                                @change="onFilePicked2"
                        >
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="saveESPrice">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data: () => ({
            pricePT: [],
            priceES: [],
            imageName: '',
            imageUrl: '',
            imageFile: '',
            imageName2: '',
            imageUrl2: '',
            imageFile2: ''
        }),
        created() {
            document.title = 'Tabela de Preços Service Center'
            this.initialize()
        },
        methods: {
            initialize() {
                B2B.dispatch('getSCPrice')
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.pricePT = responseData.pdfServiceCenterPT
                            this.priceES = responseData.pdfServiceCenterES
                        }
                    })
            },
            pickFile() {
                this.$refs.image.click()
            },
            pickFile2() {
                this.$refs.image2.click()
            },
            onFilePicked(e) {
                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName = files[0].name
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result
                        this.imageFile = files[0] // this is an image file that can be sent to server...
                    })
                } else {
                    this.imageName = ''
                    this.imageFile = ''
                    this.imageUrl = ''
                }
            },
            onFilePicked2(e) {
                const files = e.target.files
                if (files[0] !== undefined) {
                    this.imageName2 = files[0].name
                    if (this.imageName2.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader()
                    fr.readAsDataURL(files[0])
                    fr.addEventListener('load', () => {
                        this.imageUrl2 = fr.result
                        this.imageFile2 = files[0] // this is an image file that can be sent to server...
                    })
                } else {
                    this.imageName2 = ''
                    this.imageFile2 = ''
                    this.imageUrl2 = ''
                }
            },
            savePTPrice() {
                let data = {
                    language: 'PT',
                    imageFile: this.imageFile
                }
                B2B.dispatch('uploadSCPrice', data)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.pricePT = responseData.pdfServiceCenterPT
                            this.priceES = responseData.pdfServiceCenterES

                            let alert = {
                                snackbar: true,
                                text: 'Atualizado com sucesso',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)
                        } else if (responseData.status === 'WARNING') {
                            let alert = {
                                snackbar: true,
                                text: responseData.error,
                                type: 'warning'
                            }

                            Alert.dispatch('show', alert)
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro servidor',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
            },
            saveESPrice() {
                let data = {
                    language: 'ES',
                    imageFile: this.imageFile2
                }
                B2B.dispatch('uploadSCPrice', data)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.pricePT = responseData.pdfServiceCenterPT
                            this.priceES = responseData.pdfServiceCenterES

                            let alert = {
                                snackbar: true,
                                text: 'Atualizado com sucesso',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)
                        } else if (responseData.status === 'WARNING') {
                            let alert = {
                                snackbar: true,
                                text: responseData.error,
                                type: 'warning'
                            }

                            Alert.dispatch('show', alert)
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro servidor',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
            }
        }
    }
</script>
