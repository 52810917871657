<template>
    <v-app>
        <v-main :is="layout">
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>
    const default_layout = "login";
    export default {
        created() {
            this.initialize()
        },
        methods: {
            initialize() {
                Array.prototype.sortAttr = function (attr, reverse, type) {
                    var sorter = function (a, b) {
                        var aa = a[attr];
                        var bb = b[attr];
                        if (type === 'number') {
                            return aa - bb; // numbers
                        } else {
                            // if (aa === null || bb === null) {
                            //     return 0
                            // } else {
                            aa = aa === null ? '' : aa
                            bb = bb === null ? '' : bb
                            return aa.localeCompare(bb); // strings
                            // }
                        }
                    }
                    this.sort(function (a, b) {
                        var result = sorter(a, b);
                        if (reverse) result *= -1;
                        return result;
                    });
                };
            }
        },
        computed: {
            layout() {
                return (this.$route.meta.layout || default_layout) +'-layout'
            }
        }
    }
</script>
