<style>
.vue-recycle-scroller::-webkit-scrollbar {
    display: none;
}
</style>

<template>
    <v-container fluid style="font-size:12px !important">

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="6" md="3">
                <page-title
                        :title="$t('orders')"
                        :subtitle="$t('show') + ' ' + orders.length + ' de ' + originalOrders.length + ' ' + $t('orders').toLowerCase()"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-badge v-if="ordersB2C > 0 && writePermission('orders/orders')" overlap color="error" class="mr-2">
                    <template v-slot:badge>
                        {{ ordersB2C }}
                    </template>
                    <v-btn tile small class="ml-3 mb-2" color="success">
                        Encomendas B2C
                    </v-btn>
                </v-badge>

                <v-btn tile small color="info" @click="showDialogPickOrder" :loading="loadingPick" class="ml-3 mb-2"
                       v-if="writePermission('orders/pick')">
                    Criar Pick
                </v-btn>

                <v-btn tile small @click="print()" class="ml-5 mb-2">
                    Imprimir
                </v-btn>


                <v-btn tile small v-if="writePermission('orders/proformas')" class="ml-5 mb-2" color="warning"
                       @click="showDialogInvoice('proforma')">
                    Enviar Pró-Forma
                </v-btn>

                <v-badge v-if="paidProformas > 0 && writePermission('orders/orders')" overlap color="error"
                         class="mr-2">
                    <template v-slot:badge>
                        {{ paidProformas }}
                    </template>
                    <v-btn tile small class="ml-2 mb-2" color="success" :to="{ name: 'orders/proformas' }">
                        Pró-Formas Pagas
                    </v-btn>
                </v-badge>

                <v-btn v-if="writePermission('orders/orders') && false" small class="ml-2 mb-2"
                       :block="$vuetify.breakpoint.xs" color="primary" @click="showDialogInvoice('bikeplanet')">
                    Transferir BikePlanet
                </v-btn>

                <v-btn tile small class="ml-6 mb-2" :color="ordersOption === '2NextMonth' ? 'primary' : ''"
                       @click="changeOrdersOption('2NextMonth')" :loading="loading && ordersOption === '2NextMonth'">
                    2 mês seg.
                </v-btn>
                <!--                <v-btn tile small class="ml-6 mb-2" :color="ordersOption === 'today' ? 'primary' : ''"-->
                <!--                       @click="changeOrdersOption('today')" :loading="loading && ordersOption === 'today'">-->
                <v-btn tile small class="mb-2" :color="ordersOption === 'today' ? 'primary' : ''"
                       @click="changeOrdersOption('today')" :loading="loading && ordersOption === 'today'">
                    {{ $t('today') }}
                </v-btn>
                <v-btn tile small class="mb-2" :color="ordersOption === 'all' ? 'primary' : ''"
                       @click="changeOrdersOption('all')" :loading="loading && ordersOption === 'all'">
                    {{ $t('all') }}
                </v-btn>

                <v-btn tile small class="ml-2 mb-2" @click="clearFilters()" color="error"
                       :block="$vuetify.breakpoint.xs">
                    Limpar Filtros
                </v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>

                        <!-- TABLE HEADERS -->
                        <tr class="titles_row">
                            <th style="width: 20px;"></th>
                            <th style="width: 45px;" class="text-center">A1</th>
                            <th style="width: 25px;">Enc</th>
                            <th style="width: 25px;">B2B</th>
                            <th style="width: 45px;" class="text-center">País</th>
                            <th style="width: 86px;">{{ $t('entity') }}</th>
                            <th style="width: 246px">{{ $t('name') }}</th>
                            <th style="width: 76px;">Data</th>
                            <th style="width: 170px;">{{ $t('product') }}</th>
                            <th style="width: 110px;">CodBarras</th>
                            <th style="width: 273px">{{ $t('description') }}</th>
                            <th style="width: 100px;">Marca</th>
                            <th style="width: 32px;" class="text-center">Qt</th>
                            <th style="width: 32px;" class="text-center">Uni</th>
                            <th style="width: 10px;" class="text-center">ModPag</th>
                            <th style="width: 10px;" class="text-center">Vend</th>
                            <th style="width: 50px;" class="text-center">Peso</th>
                            <th style="width: 50px;" class="text-center">Morada</th>
                            <th style="width: 60px;" class="text-center">Proforma</th>
                            <th style="width: 30px;" class="text-center">Fox</th>
                            <th style="width: 85px;" class="text-center">NumDoc</th>
                        </tr>

                        <!-- FILTERS -->
                        <tr class="filters_row">
                            <td class="text-center">
                                <span style="cursor: pointer">
                                    <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                                    <i v-if="checkboxAll" class="fas fa-check-square fa-lg" style="color:#1976d2"
                                       @click="unselectAll()"></i>
                                </span>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.stock"
                                        :items="filterStock"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterOrders"
                                ></v-select>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <v-select
                                        v-model="filters.country"
                                        :items="filtersCountry"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterOrders"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.entity"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.date"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.product"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.barcode"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.description"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.brand"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td class="text-center">{{ totalProductsOrder }}</td>
                            <td></td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.salesman_id"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.proforma"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.numDoc"
                                        outlined
                                        hide-details
                                        single-line
                                        @change="filterOrders"
                                ></v-text-field>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <!-- LOADING PROGRESS BAR -->
                        <tr v-if="loading">
                            <td colspan="21">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>

                        <template v-for="order in orders">
                            <tr v-if="differentDate(order)">
                                <td colspan="21" class="date_row">{{ order.DataEntrega }}</td>
                            </tr>
                            <tr :class="{'row_proforma': order.CDU_LinVar5ENC, 'row_pick': order.IDPick}">
                                <td style="width: 20px;" class="text-center">
                                    <template style="cursor: pointer">
                                        <i v-if="!order.selected" class="far fa-square fa-lg"
                                           @click="order.selected = true"></i>
                                        <i v-if="order.selected" class="fas fa-check-square fa-lg" style="color:#1976d2"
                                           @click="order.selected = false"></i>
                                    </template>
                                </td>
                                <td style="width: 45px;" class="text-center">
                                    <v-chip small :color="stockAtual(order)" text-color="white"
                                            @click="showDialogStocks(order)">
                                        {{ order.STKActual }}
                                    </v-chip>
                                </td>
                                <td style="width: 25px;" class="text-center">
                                    <!--                                    <i class="fas fa-circle" :style="{color: order.STKActual - order.QuantidadeEnc >= 0 ? 'green' : 'red'}"></i>-->
                                    <i class="fas fa-circle"
                                       :style="{color: order.StockRemanescente >= 0 ? ( order.STKActual >= order.QuantidadeEnc ? 'green' : '#ffc800') : 'red'}"></i>
                                </td>
                                <td style="width: 25px;" class="text-center">
                                    <i class="fas fa-circle" :style="{color: availability(order)}"></i>
                                </td>
                                <td style="width: 45px;" class="text-center">{{ order.Entidade_pais }}</td>
                                <td style="width: 86px;">
                                    <v-chip v-if="order.Entidade === 'VDSHOP' || order.Entidade === 'VDSHOPES'"
                                            @click="selectEntity(order.Entidade)" style="cursor: pointer" small
                                            color="error" text-color="white">
                                        {{ order.Entidade }}
                                    </v-chip>
                                    <div v-if="!(order.Entidade === 'VDSHOP' || order.Entidade === 'VDSHOPES')"
                                         @click="selectEntity(order.Entidade)" style="cursor: pointer">
                                        <span>{{ order.Entidade }}</span>
                                        <a :href="'/clients/clients/'+order.Entidade" target="_blank" class="ml-1"><i
                                                class="fas fa-fw fa-external-link-alt"></i></a>
                                    </div>
                                </td>
                                <td style="width: 246px">
                                    <i v-show="order.TipoCred == 2" class="fas fa-minus-circle" style="color: red"></i>
                                    {{ order.ClienteNome }}
                                </td>
                                <td style="white-space: nowrap;width: 76px;">{{ order.Data }}</td>
                                <td style="width: 166px;">
                                        <span @click="selectProduct(order.Artigo)" style="cursor: pointer">{{
                                                order.Artigo
                                            }}</span>
                                    <a :href="'/products/products/'+order.Artigo" target="_blank" class="ml-1"><i
                                            class="fas fa-fw fa-external-link-alt"></i></a>
                                </td>
                                <td style="width: 100px;">{{ order.CodBarras }}</td>
                                <td style="width: 260px">{{ order.ArtigoDescricao }}</td>
                                <td style="width: 90px;" @click="selectBrand(order.Marca)">{{
                                        order.Marca
                                    }}
                                </td>
                                <td style="width: 32px;" class="text-center">{{ order.Quantidade }}</td>
                                <td style="width: 32px;" class="text-center">{{ order.Unidade }}</td>
                                <td style="width: 51px;" class="text-center">{{ order.ModoPag }}</td>
                                <td style="width: 30px;" class="text-center">{{ order.IdVendedor }}</td>
                                <td style="width: 50px;" class="text-center">{{ order.Peso }}</td>
                                <td style="width: 50px; cursor:default" class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                      <i v-if="order.CDU_PreOrder !== 0" class="fab fa-product-hunt"
                                         style="color: #0d70b7"></i>
                                      {{ order.MoradaAltEntrega }}
                                    </span>
                                        </template>
                                        <v-row class="pa-4" v-if="order.MoradaAltEntrega != null">
                                            <v-container>
                                                <v-row>
                                                    <strong>Morada: </strong>
                                                    <span>
                            {{ order.MoradaAlternativa.address }}
                          </span>
                                                </v-row>
                                                <v-row>
                                                    <strong>Morada2: </strong>
                                                    <span>
                            {{ order.MoradaAlternativa.addressExtra }}
                          </span>
                                                </v-row>
                                                <v-row>
                                                    <strong>Cidade: </strong>
                                                    <span>
                            {{ order.MoradaAlternativa.city }}
                          </span>
                                                </v-row>
                                                <v-row>
                                                    <strong>Cod.Postal: </strong>
                                                    <span>
                            {{ order.MoradaAlternativa.zipCode }}
                          </span>
                                                </v-row>
                                                <v-row>
                                                    <strong>País: </strong>
                                                    <span>
                            {{ order.MoradaAlternativa.country }}
                          </span>
                                                </v-row>
                                            </v-container>

                                        </v-row>

                                    </v-tooltip>
                                </td>
                                <td style="width: 65px;">{{ order.CDU_LinVar5ENC }}</td>
                                <td style="width: 30px;" class="text-center">
                                    <i class="fas fa-tools orange--text" v-if="order.CDU_LinVar1ENC != null"></i>
                                </td>
                                <td style="width: 85px;" class="text-center">
                                    <v-btn tile small color="info" @click="showDialogDetail(order)">{{
                                            order.NumDoc
                                        }}
                                    </v-btn>
                                </td>
                            </tr>
                        </template>

                        <!-- NO RESULTS -->
                        <tr v-if="!loading && orders.length === 0">
                            <td colspan="19" class="text-center">
                                {{ $t('noResults') }}
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>

                    <!-- LINES -->
                    <!--                    <DynamicScroller-->
                    <!--                            :style="{ 'height': virtualScrollHeight}"-->
                    <!--                            :items="orders"-->
                    <!--                            :min-item-size="32"-->
                    <!--                            key-field="Id"-->
                    <!--                            :buffer="5000"-->
                    <!--                    >-->
                    <!--                        <template v-slot="{ item: order, index }">-->
                    <!--                            <DynamicScrollerItem-->
                    <!--                                    :item="order"-->
                    <!--                                    :active="true"-->
                    <!--                                    :data-index="index"-->
                    <!--                            >-->
                    <!--                                <v-simple-table class="staff_table">-->
                    <!--                                    <tbody>-->
                    <!--                                    <tr v-if="differentDate(order)">-->
                    <!--                                        <td colspan="20" class="date_row">{{ order.DataEntrega }}</td>-->
                    <!--                                    </tr>-->
                    <!--                                    <tr :class="{'row_proforma': order.CDU_LinVar5ENC, 'row_pick': order.IDPick}">-->
                    <!--                                        <td style="width: 20px;" class="text-center">-->
                    <!--                                            <template style="cursor: pointer">-->
                    <!--                                                <i v-if="!order.selected" class="far fa-square fa-lg"-->
                    <!--                                                   @click="order.selected = true"></i>-->
                    <!--                                                <i v-if="order.selected" class="fas fa-check-square fa-lg" style="color:#1976d2"-->
                    <!--                                                   @click="order.selected = false"></i>-->
                    <!--                                            </template>-->
                    <!--                                        </td>-->
                    <!--                                        <td style="width: 45px;" class="text-center">-->
                    <!--                                            <v-chip small :color="stockAtual(order)" text-color="white"-->
                    <!--                                                    @click="showDialogStocks(order)">-->
                    <!--                                                {{ order.STKActual }}-->
                    <!--                                            </v-chip>-->
                    <!--                                        </td>-->
                    <!--                                        <td style="width: 25px;" class="text-center">-->
                    <!--                                            &lt;!&ndash;                                    <i class="fas fa-circle" :style="{color: order.STKActual - order.QuantidadeEnc >= 0 ? 'green' : 'red'}"></i>&ndash;&gt;-->
                    <!--                                            <i class="fas fa-circle"-->
                    <!--                                               :style="{color: order.StockRemanescente >= 0 ? ( order.STKActual >= order.QuantidadeEnc ? 'green' : '#ffc800') : 'red'}"></i>-->
                    <!--                                        </td>-->
                    <!--                                        <td style="width: 25px;" class="text-center">-->
                    <!--                                            <i class="fas fa-circle" :style="{color: availability(order)}"></i>-->
                    <!--                                        </td>-->
                    <!--                                        <td style="width: 45px;" class="text-center">{{ order.Entidade_pais }}</td>-->
                    <!--                                        <td style="width: 86px;">-->
                    <!--                                            <v-chip v-if="order.Entidade === 'VDSHOP' || order.Entidade === 'VDSHOPES'"-->
                    <!--                                                    @click="selectEntity(order.Entidade)" style="cursor: pointer" small-->
                    <!--                                                    color="error" text-color="white">-->
                    <!--                                                {{ order.Entidade }}-->
                    <!--                                            </v-chip>-->
                    <!--                                            <div v-if="!(order.Entidade === 'VDSHOP' || order.Entidade === 'VDSHOPES')"-->
                    <!--                                                 @click="selectEntity(order.Entidade)" style="cursor: pointer">-->
                    <!--                                                <span>{{ order.Entidade }}</span>-->
                    <!--                                                <a :href="'/clients/clients/'+order.Entidade" target="_blank" class="ml-1"><i-->
                    <!--                                                        class="fas fa-fw fa-external-link-alt"></i></a>-->
                    <!--                                            </div>-->
                    <!--                                        </td>-->
                    <!--                                        <td style="width: 246px">-->
                    <!--                                            <i v-show="order.TipoCred == 2" class="fas fa-minus-circle" style="color: red"></i>-->
                    <!--                                            {{ order.ClienteNome }}-->
                    <!--                                        </td>-->
                    <!--                                        <td style="white-space: nowrap;width: 76px;">{{ order.Data }}</td>-->
                    <!--                                        <td style="width: 166px;">-->
                    <!--                                        <span @click="selectProduct(order.Artigo)" style="cursor: pointer">{{-->
                    <!--                                                order.Artigo-->
                    <!--                                            }}</span>-->
                    <!--                                            <a :href="'/products/products/'+order.Artigo" target="_blank" class="ml-1"><i-->
                    <!--                                                    class="fas fa-fw fa-external-link-alt"></i></a>-->
                    <!--                                        </td>-->
                    <!--                                        <td style="width: 100px;">{{ order.CodBarras }}</td>-->
                    <!--                                        <td style="width: 260px">{{ order.ArtigoDescricao }}</td>-->
                    <!--                                        <td style="width: 90px;" @click="selectBrand(order.Marca)">{{-->
                    <!--                                                order.Marca-->
                    <!--                                            }}-->
                    <!--                                        </td>-->
                    <!--                                        <td style="width: 32px;" class="text-center">{{ order.Quantidade }}</td>-->
                    <!--                                        <td style="width: 51px;" class="text-center">{{ order.ModoPag }}</td>-->
                    <!--                                        <td style="width: 30px;" class="text-center">{{ order.IdVendedor }}</td>-->
                    <!--                                        <td style="width: 50px;" class="text-center">{{ order.Peso }}</td>-->
                    <!--                                        <td style="width: 50px;" class="text-center">-->
                    <!--                                            <i v-if="order.CDU_PreOrder !== 0" class="fab fa-product-hunt"-->
                    <!--                                               style="color: #0d70b7"></i>-->
                    <!--                                            {{ order.MoradaAltEntrega }}-->
                    <!--                                        </td>-->
                    <!--                                        <td style="width: 65px;">{{ order.CDU_LinVar5ENC }}</td>-->
                    <!--                                        <td style="width: 30px;" class="text-center">-->
                    <!--                                            <i class="fas fa-tools orange&#45;&#45;text" v-if="order.CDU_LinVar1ENC != null"></i>-->
                    <!--                                        </td>-->
                    <!--                                        <td style="width: 85px;" class="text-center">-->
                    <!--                                            <v-btn tile small color="info" @click="showDialogDetail(order)">{{-->
                    <!--                                                    order.NumDoc-->
                    <!--                                                }}-->
                    <!--                                            </v-btn>-->
                    <!--                                        </td>-->
                    <!--                                    </tr>-->
                    <!--                                    </tbody>-->
                    <!--                                </v-simple-table>-->
                    <!--                            </DynamicScrollerItem>-->
                    <!--                        </template>-->
                    <!--                    </DynamicScroller>-->
                </div>
            </v-col>
        </v-row>

        <!-- IMPRIMIR -->
        <v-row style="display: none">
            <v-col>
                <div id="printMe">
                    <table style="border: 1px solid gray; border-collapse: collapse; width: 100%">
                        <thead>
                        <tr>
                            <th style="border: 1px solid gray; text-align: center">Stock</th>
                            <th style="border: 1px solid gray">{{ $t('product') }}</th>
                            <th style="border: 1px solid gray">CodBarras</th>
                            <th style="border: 1px solid gray">CobBarras CX</th>
                            <th style="border: 1px solid gray; text-align: center">Quantidade</th>
                            <th style="border: 1px solid gray">{{ $t('description') }}</th>
                            <th style="width: 1px; border: 1px solid gray">Uni</th>
                            <th style="border: 1px solid gray">Lotes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="order in orders">
                            <tr v-if="differentDate(order)">
                                <td colspan="5" style="border: 1px solid gray">{{ order.DataEntrega }}</td>
                            </tr>
                            <tr>
                                <td style="border: 1px solid gray; text-align: center">{{ order.Stock }}</td>
                                <td style="border: 1px solid gray">{{ order.Artigo }}</td>
                                <td style="border: 1px solid gray">{{ order.CodBarras }}</td>
                                <td style="border: 1px solid gray">{{ order.boxBarcode }}</td>
                                <td style="border: 1px solid gray; text-align: center">{{ order.Quantidade }}</td>
                                <td style="border: 1px solid gray">{{ order.ArtigoDescricao }}</td>
                                <td style="border: 1px solid gray">{{ order.Unidade }}</td>
                                <td style="border: 1px solid gray">
                                    <v-simple-table class="staff_table" v-if="order.Lotes">
                                    <tbody>
                                    <tr v-for="lote in order.Lotes">
                                        <td style="border: 1px solid gray">{{ lote.Lote }}</td>
                                        <td style="border: 1px solid gray">{{ lote.Validade }}</td>
                                        <td style="border: 1px solid gray">{{ lote.Stock }}</td>
                                    </tr>
                                    </tbody>
                                    </v-simple-table>
                                    </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </v-col>
        </v-row>

        <!-- PRINT PICK -->
        <v-row style="display: none">
            <v-col>
                <div id="printPick">
                    <table style="border: 1px solid gray; border-collapse: collapse; width: 100%">
                        <thead>
                        <!--                        <span class="subtitle-1" style="color:white">{{ client.Entidade }} - {{ client.Nome }}</span>-->
                        <tr>
                            <th colspan="2" style="padding: 30px 10px; border: 1px solid gray; font-size: 24px;">
                                {{ idLastPick }}
                            </th>
                            <th colspan="5"
                                style="padding: 30px 10px; border: 1px solid gray; font-size: 24px; text-align: right">
                                {{ client.Entidade }}<br>
                                {{ client.Nome }}
                            </th>
                        </tr>
                        <tr>
                            <th style="border: 1px solid gray; text-align: center">Stock A1</th>
                            <th style="border: 1px solid gray; text-align: center">Stock</th>
                            <th style="border: 1px solid gray">{{ $t('product') }}</th>
                            <th style="border: 1px solid gray">CodBarras</th>
                            <th style="border: 1px solid gray">CodBarras CX</th>
                            <th style="border: 1px solid gray; text-align: center">Qty</th>
                            <th style="border: 1px solid gray">{{ $t('description') }}</th>
                            <th style="width: 1px; border: 1px solid gray">Uni</th>
                            <th style="border: 1px solid gray">Lotes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="order in productsToPick">
                            <tr>
                                <td style="border: 1px solid gray; text-align: center; font-size: 10px">
                                    {{ order.STKActual === 0 ? '' : order.STKActual }}
                                </td>
                                <td style="border: 1px solid gray; text-align: center; font-size: 10px">
                                    {{ Math.round(order.Stock) === 0 ? '' : Math.round(order.Stock) }}
                                </td>
                                <td style="border: 1px solid gray; font-size: 10px">{{ order.Artigo }}</td>
                                <td style="border: 1px solid gray; font-size: 10px">{{ order.CodBarras }}</td>
                                <td style="border: 1px solid gray; font-size: 10px">{{ order.boxBarcode }}</td>
                                <td style="border: 1px solid gray; text-align: center; font-size: 10px">{{ order.Quantidade }}</td>
                                <td style="border: 1px solid gray; font-size: 10px">{{ order.ArtigoDescricao }}</td>
                                <td style="border: 1px solid gray">{{ order.Unidade }}</td>
                                <td style="border: 1px solid gray">
                                    <v-simple-table class="staff_table" v-if="order.Lotes">
                                    <tbody>
                                    <tr v-for="lote in order.Lotes">
                                        <td style="border: 1px solid gray">{{ lote.Lote }}</td>
                                        <td style="border: 1px solid gray">{{ lote.Validade }}</td>
                                        <td style="border: 1px solid gray">{{ lote.Stock }}</td>
                                    </tr>
                                    </tbody>
                                    </v-simple-table>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">

            <!-- PROFORMA -->
            <v-dialog v-model="dialogInvoice" persistent scrollable max-width="1000">
                <v-card>
                    <v-toolbar flat dense dark color="orange darken-1">
                        <v-toolbar-title class="text-uppercase">
                            <span v-if="invoiceOption === 'proforma'" style="color:white">Enviar Pró-Forma</span>
                            <span v-if="invoiceOption === 'bikeplanet'"
                                  style="color:white">Transferir encomenda para BikePlanet</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <span class="subtitle-1" style="color:white">{{ client.Entidade }} - {{ client.Nome }}</span>
                        <v-chip class="ml-2 black--text" color="white">
                            {{ client.Localidade }}
                        </v-chip>
                        <v-btn icon @click="dialogInvoice = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <template v-if="optionWaiting === true">
                            <div class="text-center ma-4">
                                <v-progress-circular
                                        indeterminate
                                        color="primary"
                                        size="100"
                                ></v-progress-circular>
                            </div>
                        </template>
                        <template v-if="optionWaiting === false">
                            <v-simple-table class="staff_table mt-4">
                                <thead>
                                <tr class="titles_row">
                                    <th>ARTIGOS</th>
                                    <th>DESCRIÇÃO</th>
                                    <th v-if="false">NUM.SÉRIE</th>
                                    <th style="width: 1px;">DESC.1</th>
                                    <th style="width: 1px;">DESC.2</th>
                                    <th style="width: 1px;">QUANT.</th>
                                    <th style="width: 1px;">Lote</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="product in productsToInvoice">
                                    <td style="white-space: nowrap">{{ product.Artigo }}</td>
                                    <td>{{ product.Descricao }}</td>
                                    <td v-if="false">
                                        <v-text-field
                                                v-model="product.NumSerie"
                                                outlined
                                                hide-details
                                                single-line
                                                class="staff_text-field_small"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-center">{{ product.Desconto1 }}</td>
                                    <td class="text-center">{{ product.Desconto2 }}</td>
                                    <td>
                                        <v-text-field
                                                v-model="product.Quantidade"
                                                outlined
                                                single-line
                                                hide-details
                                                class="staff_text-field_small"
                                                style="width: 50px"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-select
                                                v-if="product.Lotes"
                                                v-model="product.Lote"
                                                :items="product.Lotes"
                                                item-text="Lote"
                                                item-value="Lote"
                                                hide-details
                                                dense
                                                outlined
                                                class="staff_select_small"
                                        ></v-select>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                            <v-row class="mt-3">
                                <v-col>
                                    <v-text-field
                                            v-model="client.Observacoes"
                                            dense
                                            outlined
                                            hide-details
                                            label="Observações"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="client.Portes"
                                            dense
                                            outlined
                                            hide-details
                                            label="Portes S/Iva"
                                            placeholder="0"
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="client.Valor"
                                            dense
                                            outlined
                                            hide-details
                                            label="Valor Adicional C/Iva"
                                            placeholder="0"
                                            class=""
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="client.Email"
                                            dense
                                            outlined
                                            hide-details
                                            label="Email"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-checkbox class="mt-0" v-model="client.DescontoPP"
                                                label="Desconto PP (5%)"></v-checkbox>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="client.Acerto"
                                            dense
                                            outlined
                                            hide-details
                                            label="Acerto C/Iva"
                                            placeholder="0"
                                            class=""
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-checkbox class="mt-0" v-model="client.EmailVendedor"
                                                label="Enviar email Vendedor"></v-checkbox>
                                </v-col>
                            </v-row>
                        </template>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="!optionWaiting" small tile @click="cancelInvoice()">
                            Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn v-if="invoiceOption === 'proforma'" small tile color="success" :disabled="optionWaiting || validateFormProforma > 0"
                               @click="confirmInvoice()">
                            Enviar Pró-Forma
                        </v-btn>
                        <v-btn v-if="invoiceOption === 'bikeplanet'" small tile color="success"
                               :disabled="optionWaiting" @click="confirmTransferBikePlanet()">
                            Transferir para BikePlanet
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- PICK -->
            <v-dialog v-model="dialogCreatePicking" persistent scrollable max-width="900">
                <v-card>
                    <v-toolbar flat dense dark color="blue darken-1">
                        <v-toolbar-title class="text-uppercase">
                            <span style="color: white">Criar Picking</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <span class="subtitle-1" style="color:white">{{ client.Entidade }} - {{ client.Nome }}</span>
                        <v-chip class="ml-2 black--text" color="white">
                            {{ client.Localidade }}
                        </v-chip>
                        <v-btn icon @click="dialogCreatePicking = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-simple-table class="staff_table mt-4">
                            <thead>
                            <tr>
                                <th>ARTIGOS</th>
                                <th>DESCRIÇÃO</th>
                                <th v-if="false">NUM.SÉRIE</th>
                                <th style="width: 1px;">DESC.1</th>
                                <th style="width: 1px;">DESC.2</th>
                                <th style="width: 1px;">QUANT.</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="product in productsToPick">
                                <td style="white-space: nowrap">{{ product.Artigo }}</td>
                                <td>{{ product.ArtigoDescricao }}</td>
                                <td v-if="false">
                                    <v-text-field
                                            v-model="product.NumSerie"
                                            outlined
                                            hide-details
                                            single-line
                                            class="staff_text-field_small"
                                    ></v-text-field>
                                </td>
                                <td class="text-center">{{ product.Desconto1 }}</td>
                                <td class="text-center">{{ product.Desconto2 }}</td>
                                <td class="text-center">{{ product.Quantidade }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogCreatePicking = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="createPickOrder">Criar Picking</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- ORDER DETAIL -->
            <v-dialog v-model="dialogDetail" persistent max-width="1200">
                <v-card>
                    <v-toolbar dense dark color="info">
                        <v-toolbar-title>ENCOMENDA Nº {{ currentOrder.NumDoc }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-chip v-if="currentOrder.TipoCred === '2'" class="ml-2" color="error">
                            Crédito Suspenso
                        </v-chip>
                        <v-btn icon :disabled="loading" @click="dialogDetail = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="mt-4">
                        <v-row v-if="loadingDetail">
                            <v-col class="text-center">
                                <v-progress-circular
                                        class="ma-12"
                                        :size="100"
                                        :width="2"
                                        color="blue darken-4"
                                        indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-row v-if="!loadingDetail">
                            <v-col>
                                <strong>{{ $t('entity') }}: </strong> {{ currentOrder.Entidade }} <br>
                                <strong>{{ $t('name') }}: </strong> {{ currentOrder.ClienteNome }} <br>
                                <strong>Vendedor: </strong> {{ currentOrder.Vendedor }} <br>
                            </v-col>
                            <v-col>
                                <strong>{{ $t('source') }}: </strong> {{ currentOrder.Origem }} <br>
                                <strong>{{ $t('payment_mode') }}: </strong> {{ currentOrder.ModoPag }} <br>
                                <strong>{{ $t('payment_conditions') }}: </strong> {{ currentOrder.CondPag }} <br>
                            </v-col>
                            <v-col>
                                <strong>{{ $t('discount') }} ProntoPag: </strong> {{ currentOrder.DescPag }}% <br>
                                <strong>{{ $t('discount') }} Cliente: </strong> {{ currentOrder.DescEntidade }}% <br>
                                <strong>Total Encomenda: </strong> {{ $money(currentOrder.ValorEncomenda) }}
                            </v-col>
                        </v-row>
                        <v-row v-if="!loadingDetail">
                            <v-col>
                                <v-simple-table dense class="staff_table">
                                    <template v-slot:default>
                                        <thead>
                                        <tr class="titles_row">
                                            <th width="200px">Artigo</th>
                                            <th>Descricao</th>
                                            <th class="text-center" style="width: 1px;">Qt.</th>
                                            <th class="text-center" style="width: 1px;">Qt.Env</th>
                                            <th class="text-center" style="width: 1px;">Uni</th>
                                            <th class="text-center" style="width: 1px;">CIVA</th>
                                            <th class="text-center" style="width: 1px;">IVA</th>
                                            <th class="text-center" style="width: 1px;">Desc.</th>
                                            <th class="text-right" style="width: 110px;">Total</th>
                                            <th class="text-right" style="width: 1px;">Dta.Entrega</th>
                                            <th class="text-right" style="width: 75px;">Estado</th>
                                            <th class="text-center" style="width: 1px;">SC</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="item in products" style="font-size: 11px !important;"
                                            v-bind:class="{'indigo--text': item.QuantTrans > 0 && item.QuantTrans <  item.Quantidade, 'red--text': item.EstadoTrans === 'T' || item.Fechado === 1}">
                                            <td>{{ item.Artigo }}</td>
                                            <td>{{ item.Descricao }}</td>
                                            <td class="text-center">
                                                <span v-if="item.Artigo">{{ item.Quantidade }}</span>
                                            </td>
                                            <td class="text-center">{{ item.QuantTrans }}</td>
                                            <td class="text-center">{{ item.Unidade }}</td>
                                            <td class="text-center">{{ item.CodIva }}</td>
                                            <td class="text-center">{{ item.TaxaIva }}%</td>
                                            <td class="text-center">{{ item.Desconto1 }}<span v-if="item.Desconto2 > 0">+{{
                                                    item.Desconto2
                                                }}</span><span v-if="item.Desconto3 > 0">+{{ item.Desconto3 }}</span>%
                                            </td>
                                            <td class="text-right">{{ $money(item.PrecoLiquido) }}</td>
                                            <td class="text-right" style="white-space: nowrap">{{
                                                    item.DataEntrega
                                                }}
                                            </td>
                                            <td class="text-right">
                                                <small v-if="item.EstadoTrans === 'T'">Enviado</small>
                                                <small v-if="item.Fechado === 1">Anulado</small>
                                            </td>
                                            <td>
                                                <template v-if="((item.Marca === 'FOX' || item.Marca === 'MARZOCC' )
                                                && !(item.EstadoTrans === 'T' || item.Fechado === 1)) ">
                                                    <v-btn small tile icon depressed v-if="item.CDU_LinVar1ENC === null"
                                                           @click="setServiceCenter(item)">
                                                        <v-icon>check_box_outline_blank</v-icon>
                                                    </v-btn>
                                                    <template v-else>
                                                        <v-icon color="green">check_box</v-icon>
                                                    </template>
                                                </template>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="dialogDetail = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- ORDER STOCKS -->
            <v-dialog v-model="dialogStocks" persistent max-width="400">
                <v-card>
                    <v-toolbar dense dark color="info">
                        <v-toolbar-title>ARTIGO - {{ currentOrder.Artigo }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon :disabled="loading" @click="dialogStocks = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row v-if="loadingDetail">
                            <v-col class="text-center">
                                <v-progress-circular
                                        class="ma-12"
                                        :size="100"
                                        :width="2"
                                        color="blue darken-4"
                                        indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-row v-if="!loadingDetail">
                            <v-col>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th>Armazém</th>
                                            <th>Descricao</th>
                                            <th class="text-right">Stock</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="warehouse in warehouses">
                                            <td>{{ warehouse.Armazem }}</td>
                                            <td>{{ warehouse.Descricao }}</td>
                                            <td class="text-right">{{ Math.round(warehouse.STKActual) }}</td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="dialogStocks = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- AFTER PICK -->
            <v-dialog v-model="dialogAfterPick" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Criar Picking
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <i style="color: green" class="fas fa-check fa-5x"></i>
                            <p class="headline">Artigos adicionados ao picking com sucesso!</p>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-btn tile small @click="printPick">Imprimir</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="closeDialogAfterPick">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>

    </v-container>
</template>

<style scoped>


/*.hack-table tr, .hack-table td {*/
/*    display: block;*/
/*    overflow: hidden;*/
/*}*/

/*.hack-table td {*/
/*    margin-right: 0em;*/
/*}*/

/*.hack-table td:nth-child(1) {*/
/*    width: 2em;*/
/*}*/

/*.hack-table td:nth-child(2) {*/
/*    width: 3em;*/
/*}*/

/*.hack-table td:nth-child(3) {*/
/*    width: 1em;*/
/*}*/

/*.hack-table tr {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/
</style>
<script>
import i18n from "../../plugins/i18n";

export default {
    data: function () {
        return {
            loading: false,
            ordersOption: 'today',
            orders: [],
            ordersB2C: 0,
            paidProformas: 0,
            linesSelected: [],
            checkboxAll: false,
            originalOrders: [],
            filters: {
                stock: '',
                country: '',
                entity: '',
                name: '',
                date: '',
                product: '',
                barcode: '',
                description: '',
                brand: '',
                salesman_id: '',
                proforma: '',
                numDoc: '',
            },
            filtersCountry: [
                {text: '-', value: ''},
                {text: 'PT', value: 'PT'},
                {text: 'ES', value: 'ES'}
            ],
            filterStock: [
                {text: '', value: ''},
                {text: 'Sim', value: '1'},
                {text: 'Não', value: '0'}
            ],
            dialogInvoice: false,
            loadingDetail: false,
            dialogDetail: false,
            dialogStocks: false,
            invoiceOption: '',
            client: {},
            productsToInvoice: [],
            optionWaiting: false,
            currentOrder: {},
            products: [],
            warehouses: [],
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
            loadingPick: false,
            dialogCreatePicking: false,
            dialogAfterPick: false,
            productsToPick: [],
            idLastPick: 0,
            virtualScrollHeight: '730px'
        }
    },
    private: {
        currentDate: ''
    },
    created() {
        document.title = i18n.t('orders')
        // this.virtualScrollHeight = screen.height - 350 + 'px';
        this.initialize()
    },
    methods: {
        initialize() {
            this.currentDate = ''
            this.checkboxAll = false;
            this.loading = true;
            this.orders = [];
            this.originalOrders = [];
            let request = {
                url: api.orders(),
                data: {
                    today: this.ordersOption
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.ordersB2C = responseData.ordersB2C;
                            this.paidProformas = responseData.paidProformas;
                            this.originalOrders = responseData.orders;
                            this.filterOrders();
                            this.loading = false
                        } else {
                            this.loading = false
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        availability(order) {
            var atual = parseInt(order.STKActual) - parseInt(order.QuantidadeEnc)
            var reposicao = parseInt(order.STKReposicao)
            var minimo = parseInt(order.STKMinimo)

            if (atual > reposicao) {
                return 'green'
            } else if (minimo <= reposicao && atual >= minimo) {
                return '#ffc800'
            } else if (atual <= reposicao && atual < minimo) {
                return 'red'
            }
        },
        stockAtual(order) {
            return order.Stock > 0 ? 'green' : 'red';
        },
        differentDate(order) {
            if (order.DataEntrega !== this.currentDate) {
                this.currentDate = order.DataEntrega
                order.size = 64;
                return true
            }
            order.size = 32;
            return false
        },
        changeOrdersOption(option) {
            this.ordersOption = option
            this.initialize()
        },
        selectAll() {
            this.currentDate = ''
            this.checkboxAll = true
            this.orders.forEach(function (order) {
                order.selected = true
            })
        },
        unselectAll() {
            this.currentDate = ''
            this.checkboxAll = false
            this.orders.forEach(function (order) {
                order.selected = false
            })
        },
        filterOrders() {
            this.currentDate = ''
            this.linesSelected = []
            let filters = [];
            if (this.filters.stock) {
                const localFilter = order => order && this.filters.stock === '1' ? order.STKActual > 0 : order.STKActual < 1;
                filters.push(localFilter)
            }
            if (this.filters.country !== '') {
                const localFilter = order => order && order.Entidade_pais === this.filters.country
                filters.push(localFilter)
            }
            if (this.filters.entity !== '') {
                const localFilter = order => order && order.Entidade.toUpperCase().includes(this.filters.entity.toUpperCase())
                filters.push(localFilter)
            }
            if (this.filters.name !== '') {
                const localFilter = order => order && order.ClienteNome.toLowerCase().includes(this.filters.name.toLowerCase())
                filters.push(localFilter)
            }
            if (this.filters.date !== '') {
                const localFilter = order => order && order.Data.includes(this.filters.date)
                filters.push(localFilter)
            }
            if (this.filters.product !== '') {
                const localFilter = order => order && order.Artigo.toUpperCase().includes(this.filters.product.toUpperCase())
                filters.push(localFilter)
            }
            if (this.filters.barcode !== '') {
                const localFilter = order => order && (order.CodBarras !== null && order.CodBarras.includes(this.filters.barcode))
                filters.push(localFilter)
            }
            if (this.filters.description !== '') {
                const localFilter = order => order && order.ArtigoDescricao.toLowerCase().includes(this.filters.description.toLowerCase())
                filters.push(localFilter)
            }
            if (this.filters.brand !== '') {
                const localFilter = order => order && (order.Marca !== null && order.Marca.toLowerCase().includes(this.filters.brand.toLowerCase()))
                filters.push(localFilter)
            }
            if (this.filters.salesman_id !== '') {
                const localFilter = order => order && order.IdVendedor.toUpperCase().includes(this.filters.salesman_id.toUpperCase())
                filters.push(localFilter)
            }
            if (this.filters.proforma !== '') {
                const localFilter = order => order && order.CDU_LinVar5ENC.toString().includes(this.filters.proforma)
                filters.push(localFilter)
            }
            if (this.filters.numDoc !== '') {
                const localFilter = order => order && order.NumDoc.toString().includes(this.filters.numDoc)
                filters.push(localFilter)
            }
            if (filters.length) {
                const filter = o => {
                    for (let i = 0; i < filters.length; i++) {
                        if (!filters[i](o)) return false;
                    }
                    return true;
                }
                this.orders = this.originalOrders.filter(filter);
            } else {
                this.orders = this.originalOrders;
            }
        },
        showDialogInvoice(option) {
            this.currentDate = ''
            this.invoiceOption = option
            this.productsToInvoice = []
            let currentClient = ''
            for (var i = 0; i < this.orders.length; i++) {
                if (this.orders[i].selected) {
                    let discount2 = this.orders[i].Desconto2 + this.orders[i].DescEntidade;

                    this.productsToInvoice.push({
                        Id: this.orders[i].Id,
                        NumDoc: this.orders[i].NumDoc,
                        SerieDoc: this.orders[i].SerieDoc,
                        NumLinha: this.orders[i].NumLinha,
                        Desconto1: this.orders[i].Desconto1,
                        Desconto2: discount2,
                        Artigo: this.orders[i].Artigo,
                        Descricao: this.orders[i].ArtigoDescricao,
                        Quantidade: this.orders[i].Quantidade,
                        NumSerie: '',
                        PrecUnit: this.orders[i].PrecUnit,
                        Lote: '',
                        Lotes: this.orders[i].Lotes,
                        MovStock: this.orders[i].MovStock
                    })
                    if (currentClient === '' || this.client.Entidade === this.orders[i].Entidade) {
                        currentClient = this.orders[i].Entidade
                        this.client.Entidade = this.orders[i].Entidade
                        this.client.Localidade = this.orders[i].Localidade
                        this.client.Nome = this.orders[i].ClienteNome
                        this.client.Email = this.orders[i].Email
                        this.client.EmailVendedor = false
                        this.client.DescontoPP = true
                    } else {
                        let alert = {
                            snackbar: true,
                            text: 'Cliente de Pró-Forma Inválido!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                        return;
                    }
                }
            }

            if (this.productsToInvoice.length > 0) {
                this.dialogInvoice = true
            }
        },
        selectEntity(entity) {
            this.filters.entity = entity
            this.filterOrders()
        },
        cancelInvoice() {
            this.currentDate = ''
            this.client.Portes = 0.0;
            this.client.Valor = 0.0;
            this.client.Acerto = 0.0;
            this.client.Entidade = '';
            this.client.Localidade = '';
            this.client.Nome = '';
            this.client.Email = '';
            this.client.EmailVendedor = false;
            this.client.DescontoPP = true;
            this.client.Observacoes = '';
            this.dialogInvoice = false;
        },
        confirmInvoice() {
            this.dialogInvoice = false;

            this.responseDialog.title = 'Enviar Pró-forma';
            this.responseDialog.error = false;
            this.responseDialog.message = '';
            this.responseDialog.loading = true;
            this.responseDialog.open = true;

            // this.optionWaiting = true
            let request = {
                url: '/api/staff/orders/proformas/create',
                data: {
                    client: this.client,
                    products: this.productsToInvoice
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.responseDialog.message = response.data.message
                            this.responseDialog.submessage = ''
                            this.responseDialog.loading = false
                            this.clearFilters()
                            this.cancelInvoice()
                            this.initialize()
                        } else if (responseData.status === 'EMAIL') {
                            this.responseDialog.message = response.data.message
                            this.responseDialog.submessage = 'Não foi possivel enviar o email'
                            this.responseDialog.loading = false
                            this.clearFilters()
                            this.cancelInvoice()
                            this.initialize()
                        } else if (responseData.status === 'WARN') {
                            responseData.message.forEach(function (element) {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: element,
                                    type: 'error'
                                })
                            })
                            this.responseDialog.open = false;
                        } else {
                            this.responseDialog.error = true
                            this.responseDialog.message = 'Erro de Servidor'
                            this.responseDialog.loading = false

                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor.',
                                type: 'error'
                            })
                            this.dialogInvoice = false
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor.',
                            type: 'error'
                        })
                    })
        },
        confirmTransferBikePlanet() {
            this.optionWaiting = true
            let order = {
                products: this.productsToInvoice
            };

            Order.dispatch('transferBikePlanet', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {

                        }
                    })
                    .catch(() => {

                    })
        },
        clearFilters() {
            this.filters.stock = ''
            this.filters.country = ''
            this.filters.entity = ''
            this.filters.name = ''
            this.filters.date = ''
            this.filters.product = ''
            this.filters.barcode = ''
            this.filters.description = ''
            this.filters.brand = ''
            this.filters.salesman_id = ''
            this.filters.numDoc = ''
            this.filters.proforma = ''
            this.filterOrders()
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        showDialogDetail(order) {
            this.clearCurrentOrder();
            this.loadingDetail = true;
            this.currentOrder.TipoCred = order.TipoCred;
            this.currentOrder.NumDoc = order.NumDoc;
            this.dialogDetail = true;
            let data = {
                numDoc: order.NumDoc
            };
            Order.dispatch('getDetail', data)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {

                            this.currentOrder.Id = order.Id;
                            this.currentOrder.Entidade = order.Entidade;
                            this.currentOrder.ClienteNome = order.ClienteNome;
                            this.currentOrder.Artigo = order.Artigo;
                            this.currentOrder.NumDoc = order.NumDoc;
                            this.currentOrder.SerieDoc = order.SerieDoc;
                            this.currentOrder.Vendedor = order.Vendedor;
                            this.currentOrder.ValorEncomenda = order.valorEncomenda;
                            this.currentOrder.DescEntidade = order.DescEntidade;
                            this.currentOrder.DescPag = order.DescPag;

                            this.products = responseData.products;

                            this.currentOrder.Origem = responseData.products[0].Origem;
                            this.currentOrder.ModoPag = responseData.products[0].modoPag;
                            this.currentOrder.CondPag = responseData.products[0].condPag;
                            this.currentOrder.Marca = responseData.products[0].Marca;

                        }
                        this.loadingDetail = false;
                    })
                    .catch(() => {
                        this.loadingDetail = false;
                    })
        },
        showDialogStocks(order) {
            this.currentOrder.Artigo = order.Artigo;
            this.warehouses = [];
            this.dialogStocks = true;
            this.loadingDetail = true;
            let data = {
                product: order.Artigo
            };
            Order.dispatch('getStocks', data)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.warehouses = responseData.stocks;
                        }
                        this.loadingDetail = false;
                    })
                    .catch(() => {
                        this.loadingDetail = false;
                    })
        },
        clearCurrentOrder() {
            this.currentOrder.TipoCred = '1'
            this.currentOrder.Entidade = ''
            this.currentOrder.ClienteNome = ''
            this.currentOrder.Artigo = ''
            this.currentOrder.NumDoc = ''
            this.currentOrder.SerieDoc = ''
            this.currentOrder.Vendedor = ''
            this.currentOrder.ValorEncomenda = ''
            this.currentOrder.Origem = ''
            this.currentOrder.ModoPag = ''
            this.currentOrder.CondPag = ''
            this.currentOrder.Mandato = ''
            this.currentOrder.Notas = ''
            this.products = []
            this.currentOrder.Id = ''
        },
        formatNumber(number) {
            return Math.round(number)
        },
        selectProduct(product) {
            this.filters.product = product;
            this.filterOrders()
        },
        selectBrand(brand) {
            this.filters.brand = brand;
            this.filterOrders()
        },
        print() {
            const options = {
                name: '_blank',
                specs: [
                    'fullscreen=yes',
                    'titlebar=yes',
                    'scrollbars=yes'
                ],
                styles: [
                    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                    '../../assets/css/print.css'
                ]
            }

            this.$htmlToPaper('printMe', options);
        },
        createPickOrder() {
            let loader = this.$loading.show();

            // this.loadingPick = true;
            // this.productsToPick = [];

            // for (var i = 0; i < this.orders.length; i++) {
            //     if (this.orders[i].selected) {
            //         this.productsToPick.push(this.orders[i]);
            //     }
            // }

            if (this.productsToPick.length > 0) {
                let request = {
                    url: '/api/staff/orders/addPick',
                    data: {
                        products: this.productsToPick
                    }
                }

                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.idLastPick = responseData.pickId;
                                this.dialogAfterPick = true
                                loader.hide();
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Artigos adicionados ao picking com sucesso!',
                                    type: 'success'
                                })
                            } else {
                                loader.hide();
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao adicionar ao picking!',
                                    type: 'error'
                                })
                            }
                            this.loadingPick = false;
                            this.dialogCreatePicking = false;
                        })
                        .catch(() => {
                            this.loadingPick = false;
                            this.dialogCreatePicking = false;
                            loader.hide();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })

            } else {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Não existe artigos para picking!',
                    type: 'error'
                })
            }
        },
        showDialogPickOrder() {
            this.currentDate = '';
            this.productsToPick = [];
            let currentClient = '';
            let currentAddress = '';
            for (let i = 0; i < this.orders.length; i++) {
                if (this.orders[i].selected) {
                    if (this.orders[i].IDPick !== null) {
                        let alert = {
                            snackbar: true,
                            text: 'O artigo ' + this.orders[i].Artigo + ' encontra-se adicionado ao pick ' + this.orders[i].IDPick + '!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                        return;
                    }
                    this.productsToPick.push(this.orders[i]);
                    // this.productsToPick.push({
                    //     Id: this.orders[i].Id,
                    //     NumDoc: this.orders[i].NumDoc,
                    //     SerieDoc: this.orders[i].SerieDoc,
                    //     NumLinha: this.orders[i].NumLinha,
                    //     Desconto1: this.orders[i].Desconto1,
                    //     Desconto2: this.orders[i].Desconto2,
                    //     Artigo: this.orders[i].Artigo,
                    //     Descricao: this.orders[i].ArtigoDescricao,
                    //     Quantidade: this.orders[i].Quantidade,
                    //     NumSerie: '',
                    //     PrecUnit: this.orders[i].PrecUnit,
                    //     STKActual: this.orders[i].STKActual,
                    //     Stock: this.orders[i].Stock,
                    //     CodBarras: this.orders[i].CodBarras,
                    // })
                    if (currentClient === '' || this.client.Entidade === this.orders[i].Entidade) {
                        currentClient = this.orders[i].Entidade
                        this.client.Entidade = this.orders[i].Entidade
                        this.client.Localidade = this.orders[i].Localidade
                        this.client.Nome = this.orders[i].ClienteNome
                    } else {
                        let alert = {
                            snackbar: true,
                            text: 'Cliente Inválido!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                        return;
                    }

                    if (currentAddress === '' || currentAddress === this.orders[i].MoradaAltEntrega) {
                        currentAddress = this.orders[i].MoradaAltEntrega;
                    } else {
                        let alert = {
                            snackbar: true,
                            text: 'Moradas diferentes selecionadas!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                        return;
                    }
                }
            }

            if (this.productsToPick.length > 0) {
                this.dialogCreatePicking = true;
            }
        },
        printPick() {
            const options = {
                name: '_blank',
                specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
                styles: [
                    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                    '../../assets/css/print.css'
                ]
            }
            this.$htmlToPaper('printPick', options);
        },
        closeDialogAfterPick() {
            this.dialogAfterPick = false
            this.clearFilters()
            this.initialize()
        },
        setServiceCenter(item) {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/orders/lineServiceCenter',
                data: {
                    id: item.id
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            item.CDU_LinVar1ENC = 'FOX';
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao enviar para Service Center!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        itemHeight(item) {
            console.log(item)
        }
    },
    computed: {
        totalProductsOrder() {
            let count = 0;
            this.orders.forEach(function (order) {
                count += order.Quantidade;
            });
            return count;
        },
        validateFormProforma() {
            let count = 0;
            this.productsToInvoice.forEach(function (product) {
                if (product.Lotes && product.Lote == '') {
                    count++;
                }
            })

            return count;
        }
    }
}
</script>
