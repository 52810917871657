<!--<style scoped>-->
<!--.staff_table .v-chip {-->
<!--    border-radius: 12px;-->
<!--    font-size: 12px;-->
<!--    height: 22px;-->
<!--    padding: 0 6px;-->
<!--}-->
<!--</style>-->
<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Documentos PLANET"
                        :subtitle="'A mostrar ' + lines.length + ' de ' + lines.length + ' documentos'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
<!--                <v-btn tile small class="ml-2 mb-2" @click="toggleDialogAddECN()" color="success">-->
<!--                    <i class="fas fa-plus mr-1"></i>Criar Encomenda-->
<!--                </v-btn>-->
                <v-btn tile small class="ml-2 mb-2" @click="init()" color="info">
                    <i class="fas fa-sync-alt mr-1"></i>Atualizar
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>Data Encomenda</th>
                            <th>Referência</th>
                            <th>NumDocPlanet</th>
                        </tr>
                        </thead>
                        <tbody v-if="loading">
                        <tr>
                            <td colspan="10">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-if="!loading">
                        <tr v-for="line in orders">
                            <td>{{ line.created_at }}</td>
                            <td>{{ line.Referencia }}</td>
                            <td>{{ line.Data }}</td>
                            <td class="text-center">{{ line.quantArtigos }}</td>
                            <td class="text-center">{{ line.totalPick }}</td>
                            <td class="text-center">{{ (line.totalPick / line.quantArtigos * 100).toFixed(2) }}%</td>
                            <td class="text-center">{{ line.CDU_CabVar2 }}</td>
                            <td class="text-center">{{ line.CDU_CabVar1 }}</td>
                            <td class="text-center">
                                <v-chip v-if="!line.Anulado && !line.Fechado" color="info">Aberto</v-chip>
                                <v-chip v-if="!line.Anulado && line.Fechado" color="success">Terminado</v-chip>
                                <v-chip v-if="line.Anulado && !line.Fechado" color="error">Cancelado</v-chip>
                                <v-chip v-if="line.Anulado && line.Fechado" color="black" text-color="white">Apagado
                                </v-chip>
                            </td>
                            <td class="text-right">
                                <v-btn tile small color="primary" :to="'/online/nozama/orders/'+line.NumDoc">Ver
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

<!--        <v-row justify="center">-->
<!--            <v-dialog v-model="dialogAddECN" fullscreen scrollable hide-overlay transition="dialog-bottom-transition">-->
<!--                <v-card>-->
<!--                    <v-toolbar class="mb-3 staff_app-bar" dense style="max-height: 48px;">-->
<!--                        <v-toolbar-title style="color: white">Criar Encomenda</v-toolbar-title>-->
<!--                        <v-spacer></v-spacer>-->
<!--                        <v-btn icon dark @click="dialogAddECN = false">-->
<!--                            <v-icon>mdi-close</v-icon>-->
<!--                        </v-btn>-->
<!--                    </v-toolbar>-->
<!--                    <v-card-text>-->
<!--                        <v-row>-->
<!--                            <v-col class="text-right">-->
<!--                                <file-upload-->
<!--                                        v-if="mode === 'import'"-->
<!--                                        :url='urlUploadFile'-->
<!--                                        btn-label="Importar"-->
<!--                                        :headers="headers"-->
<!--                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"-->
<!--                                        @success="onUploadSuccess"-->
<!--                                        @error="onUploadError"-->
<!--                                ></file-upload>-->
<!--                            </v-col>-->
<!--                        </v-row>-->
<!--                        <v-row>-->
<!--                            <v-col>-->
<!--                                <v-simple-table class="staff_table" v-if="lines.length > 0">-->
<!--                                    <thead v-if="mode === 'submit'">-->
<!--                                    <tr>-->
<!--                                        <th>-->
<!--                                            <span style="cursor: pointer">-->
<!--                                                <i v-if="!checkboxAll" class="far fa-square fa-lg"-->
<!--                                                   @click="selectAll()"></i>-->
<!--                                                <i v-if="checkboxAll" class="fas fa-check-square fa-lg"-->
<!--                                                   style="color:#1976d2" @click="unselectAll()"></i>-->
<!--                                            </span>-->
<!--                                        </th>-->
<!--                                        <th>{{ $t('product') }} Pri.</th>-->
<!--                                        <th>{{ $t('product') }}</th>-->
<!--                                        <th>{{ $t('description') }} Pri.</th>-->
<!--                                        <th>{{ $t('quantity') }}</th>-->
<!--                                        <th>EAN</th>-->
<!--                                        <th>Existe Primavera</th>-->
<!--                                    </tr>-->
<!--                                    </thead>-->
<!--                                    <tbody>-->
<!--                                    <tr v-if="mode === 'analyze'">-->
<!--                                        <td></td>-->
<!--                                        <td v-for="(col, index) in lines[0]">-->
<!--                                            <v-select-->
<!--                                                    outlined-->
<!--                                                    hide-details-->
<!--                                                    dense-->
<!--                                                    :items="optionsSelect"-->
<!--                                                    item-text="TEXT"-->
<!--                                                    item-value="VALUE"-->
<!--                                                    @change="selectField(index, $event)"-->
<!--                                            ></v-select>-->
<!--                                        </td>-->
<!--                                    </tr>-->
<!--                                    <tr v-for="(line, index) in lines" v-if="mode === 'analyze'">-->
<!--                                        <td class="text-center">-->
<!--                                            <span style="cursor: pointer">-->
<!--                                                <i v-if="lineSelected !== index" class="far fa-square fa-lg"-->
<!--                                                   @click="lineSelected = index"></i>-->
<!--                                                <i v-if="lineSelected === index" class="fas fa-check-square fa-lg"-->
<!--                                                   style="color:#1976d2" @click="lineSelected = index"></i>-->
<!--                                            </span>-->
<!--                                        </td>-->
<!--                                        <td v-for="col in line">{{ col }}</td>-->
<!--                                    </tr>-->
<!--                                    <template v-for="line in lines" v-if="mode === 'submit'">-->
<!--                                        &lt;!&ndash;                        <tr v-if="differentDate(line)">&ndash;&gt;-->
<!--                                        &lt;!&ndash;                            <td colspan="7" class="grey lighten-2 date_row">{{ line[5] }}</td>&ndash;&gt;-->
<!--                                        &lt;!&ndash;                        </tr>&ndash;&gt;-->
<!--                                        <tr :class="{'red lighten-4': line[line.length - 1] === 'Não'}">-->
<!--                                            <td>-->
<!--                                                <span style="cursor: pointer" v-if="line[line.length - 1] === 'Sim'">-->
<!--                                                    <i v-if="line[0] == false" class="far fa-square fa-lg"-->
<!--                                                       @click="selectLine(line, true)"></i>-->
<!--                                                    <i v-if="line[0] == true" class="fas fa-check-square fa-lg"-->
<!--                                                       style="color:#1976d2" @click="selectLine(line, false)"></i>-->
<!--                                                </span>-->
<!--                                            </td>-->
<!--                                            <td v-for="(col, index) in line" v-if="index > 0">{{ col }}</td>-->
<!--                                        </tr>-->
<!--                                    </template>-->
<!--                                    </tbody>-->
<!--                                </v-simple-table>-->
<!--                            </v-col>-->
<!--                        </v-row>-->
<!--                    </v-card-text>-->
<!--                    <v-card-actions class="pa-0">-->
<!--                        <v-toolbar dense color="white">-->
<!--                            <template v-if="mode === 'analyze'">-->
<!--                                <v-spacer></v-spacer>-->
<!--                                <v-btn-->
<!--                                        :disabled="validateBtnAnalyze"-->
<!--                                        color="warning"-->
<!--                                        @click="initAnalyze()"-->
<!--                                        :loading="loading">-->
<!--                                    Analisar-->
<!--                                </v-btn>-->
<!--                            </template>-->
<!--                            <template v-if="mode === 'submit'">-->
<!--                                <p class="ma-0">Total Artigos Selecionados: {{ totalSelected }}</p>-->
<!--                                <v-spacer></v-spacer>-->
<!--                                <p class="my-2 mx-1">NZ_</p>-->
<!--                                <v-text-field-->
<!--                                        label="Num. Encomenda"-->
<!--                                        v-model="fieldsSelected.numOrder"-->
<!--                                        hide-details-->
<!--                                        dense-->
<!--                                        outlined-->
<!--                                        style="max-width: 150px;"-->
<!--                                        class="mr-3"-->
<!--                                ></v-text-field>-->
<!--                                <v-btn tile color="success" :disabled="validateBtnSubmit" @click="submitECN()">-->
<!--                                    Submeter-->
<!--                                </v-btn>-->
<!--                            </template>-->
<!--                        </v-toolbar>-->
<!--                    </v-card-actions>-->
<!--                </v-card>-->
<!--            </v-dialog>-->

<!--            <v-dialog v-model="dialog.open" persistent max-width="500">-->
<!--                <v-card>-->
<!--                    <v-card-title>-->
<!--                        ECN-->
<!--                    </v-card-title>-->
<!--                    <v-card-text class="text-center">-->
<!--                        <i v-if="dialog.error" style="color: red" class="fas fa-times fa-5x"></i>-->
<!--                        <i v-if="!dialog.error" style="color: green" class="fas fa-check fa-5x"></i>-->
<!--                        <p class="headline">{{ dialog.message }}</p>-->
<!--                        <v-alert v-if="dialog.alert" type="error">-->
<!--                            {{ dialog.alertMessage }}-->
<!--                        </v-alert>-->
<!--                    </v-card-text>-->
<!--                    <v-card-actions>-->
<!--                        <v-btn color="success" @click="closeDialog()" style="margin-left: auto">-->
<!--                            OK-->
<!--                        </v-btn>-->
<!--                    </v-card-actions>-->
<!--                </v-card>-->
<!--            </v-dialog>-->
<!--        </v-row>-->

    </v-container>
</template>

<script>
// import FileUpload from 'v-file-upload'
// import i18n from "@/plugins/i18n";

export default {
    // components: {
    //     FileUpload
    // },
    data: () => ({
      loading: false,
      // checkboxAll: false,
      // dialogAddECN: false,
      // urlUploadFile: '',
      // headers: {},
      // fieldsSelected: {
      //   product: '',
      //   ean: '',
      //   qty: '',
      //   numOrder: ''
      // },
      // mode: 'import',
      lines: [],
      // lineSelected: '',
      // filename: '',
      // optionsSelect: [
      //   {TEXT: '', VALUE: ''},
      //   {TEXT: 'Artigo', VALUE: 'product'},
      //   {TEXT: 'EAN', VALUE: 'ean'},
      //   {TEXT: 'Quantidade', VALUE: 'qty'}
      // ],
      // totalSelected: 0,
      // dialog: {
      //   open: false,
      //   message: '',
      //   error: '',
      //   alert: false,
      //   alertMessage: ''
      // },
      // orders: []
    }),
    created() {
        document.title = 'PLANET';
        // this.urlUploadFile = process.env.VUE_APP_API_URL + '/api/staff/nozama/importXlsECN';
        // this.headers = {'Authorization': 'Bearer ' + User.getters.getAccessToken};
        this.init();
    },
    methods: {
        // toggleDialogAddECN() {
        //     this.dialogAddECN = true;
        //     this.lines = [];
        //     this.lineSelected = '';
        //     this.mode = 'import';
        //     this.fieldsSelected = {
        //         product: '',
        //         ean: '',
        //         qty: '',
        //         numOrder: ''
        //     }
        // },
        // onUploadSuccess(response) {
        //     this.resetValues();
        //     this.lineSelected = '';
        //     let responseData = response.target.response;
        //     if (responseData.status === 'OK') {
        //         this.lines = responseData.lines
        //         this.filename = responseData.filename
        //     }
        // },
        // onUploadError() {
        //     console.log('error');
        // },
        // resetValues() {
        //     this.mode = 'analyze';
        //     this.fieldsSelected = {
        //         product: '',
        //         ean: '',
        //         qty: '',
        //         numOrder: ''
        //     }
        //     this.lines = [];
        // },
        // selectField(index, event) {
        //     if (event === 'product') {
        //         this.fieldsSelected.product = index
        //     } else if (event === 'qty') {
        //         this.fieldsSelected.qty = index
        //     } else if (event === 'ean') {
        //         this.fieldsSelected.ean = index;
        //     }
        // },
        // initAnalyze() {
        //     let loader = this.$loading.show();
        //     this.loading = true;
        //     let request = {
        //         url: '/api/staff/nozama/analyzeXlsECN',
        //         data: {
        //             filename: this.filename,
        //             fieldsSelected: this.fieldsSelected,
        //             lineSelected: this.lineSelected
        //         }
        //     }
        //
        //     ApiWS.dispatch('makePost', request)
        //             .then(response => {
        //                 let responseData = response.data
        //                 if (responseData.status === 'OK') {
        //                     this.lines = responseData.lines
        //                     this.mode = 'submit'
        //                 }
        //                 this.loading = false
        //                 loader.hide();
        //             })
        //             .catch(response => {
        //                 this.loading = false
        //                 loader.hide();
        //             })
        // },
        // selectLine(line, option) {
        //     this.currentDate = '';
        //     line[0] = option;
        //     // this.$forceUpdate();
        //     this.countSelected();
        // },
        // selectAll() {
        //     this.checkboxAll = true;
        //     this.lines.forEach(function (line) {
        //         if (line[6] === 'Sim') {
        //             line[0] = true;
        //         }
        //     });
        //     this.countSelected();
        // },
        // unselectAll() {
        //     this.checkboxAll = false;
        //     this.lines.forEach(function (line) {
        //         line[0] = false;
        //     });
        //     this.countSelected();
        // },
        // countSelected() {
        //     let count = 0;
        //     for (let i = 0; i < this.lines.length; i++) {
        //         if (this.lines[i][0] === true) {
        //             count++
        //         }
        //     }
        //     this.totalSelected = count;
        // },
        // submitECN() {
        //     let loader = this.$loading.show();
        //     let request = {
        //         url: '/api/staff/nozama/submitECN',
        //         data: {
        //             lines: this.lines,
        //             filename: this.filename,
        //             numOrder: this.fieldsSelected.numOrder
        //         }
        //     };
        //     ApiWS.dispatch('makePost', request)
        //             .then(response => {
        //                 let responseData = response.data;
        //                 if (responseData.status === 'OK') {
        //                     this.dialog.open = true;
        //                     this.dialog.message = responseData.data;
        //                     this.dialog.error = false;
        //                     this.dialogAddECN = false;
        //                     this.getOrders();
        //                 } else {
        //                     this.dialog.open = true;
        //                     this.dialog.message = 'Erro ao efetuar ECN!';
        //                     this.dialog.error = true;
        //                     this.dialog.alert = true;
        //                     this.dialog.alertMessage = responseData.data;
        //                 }
        //                 loader.hide();
        //             })
        //             .catch(() => {
        //                 loader.hide();
        //                 this.dialog.open = true;
        //                 this.dialog.message = 'Erro ao efetuar ECN!';
        //                 this.dialog.error = true;
        //                 this.dialog.alert = false;
        //             })
        // },
        // closeDialog() {
        //     this.dialog.open = false;
        // },
        init() {
            this.loading = true;
            // let loader = this.$loading.show({
            //     container: null,
            //     canCancel: false,
            //     color: 'rgb(192, 13, 13)',
            //     backgroundColor: '#ffffff',
            //     height: 128,
            //     width: 128,
            //     loader: 'spinner',
            //     opacity: 0.3,
            // });

            let request = {
                url: '/api/staff/online/docs/planet'
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.orders = responseData.orders;
                        }
                        this.loading = false;
                        // loader.hide();
                    })
                    .catch(() => {
                        this.loading = false;
                        // loader.hide();
                    })
        }
    },
    // computed: {
    //     validateBtnAnalyze() {
    //         return this.lineSelected === '' || this.fieldsSelected.product === '' || this.fieldsSelected.ean === ''
    //                 || this.fieldsSelected.qty === '' || this.loading === true
    //     },
    //     validateBtnSubmit() {
    //         return this.totalSelected === 0 || this.fieldsSelected.numOrder === ''
    //     }
    // }
}

</script>
