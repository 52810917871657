<template>
  <v-container fluid>
    <div id="printPage">
    <v-row class="content-header">
      <v-col cols="3">
        <page-title
            back
            :title="'Picking - '+ pickOrder.id"
            :subtitle="pickOrder.client+' - '+ pickOrder.name"
        ></page-title>
      </v-col>
      <v-col>
        <strong>Pagamento</strong><br>
        {{ pickOrder.paymentConditions }} - {{ pickOrder.paymentMode }}
      </v-col>
      <v-col>
        <strong>Vendedor:</strong><br>
        {{ pickOrder.salesman }}
      </v-col>
      <v-col>
        <strong>Morada faturação{{ pickOrder.shippingAddress == "" ? "/envio" : "" }}:</strong><br>
        {{ pickOrder.invoiceAddress }}
      </v-col>
      <v-col v-if="pickOrder.shippingAddress != ''">
        <strong>Morada envio:</strong><br>
        {{ pickOrder.shippingAddress }}
      </v-col>
      <v-col v-if="pickOrder.carrier">
        <strong>Transportadora:</strong><br>
        {{ pickOrder.carrier }}<br>
        Tracking: <u> {{ pickOrder.trackingNumber }}</u>
      </v-col>
      <v-col>
        <v-btn tile small class="primary" @click="printPicking()">Imprimir</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="staff_v-card">
          <v-row>
            <v-col cols="1"></v-col>
            <v-col cols="3">
              <strong>Document: </strong>
              <u>{{ pickOrder.docType }} {{ pickOrder.docSerie }}/{{ pickOrder.docNumber }}</u>
            </v-col>
            <v-col>
              <strong>Utilizador:</strong>
              {{ pickOrder.user }}
            </v-col>
            <v-col>
              <strong>Volume:</strong>
              {{ pickOrder.volume }}
            </v-col>
            <v-col>
              <strong>Peso:</strong>
              {{ pickOrder.weight }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              Total Referências: {{ totalProducts }} Total de Artigos:
              {{ totalProducts }}
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-simple-table class="staff_table">
                <thead>
                <tr class="titles_row">
                  <th></th>
                  <th style="border: 1px solid #777777; border-collapse: collapse;">{{ $t('product') }}</th>
                  <th v-if="serialNumbers" style="border: 1px solid #777777; border-collapse: collapse;">Núm. Série</th>
                  <th style="border: 1px solid #777777; border-collapse: collapse;">{{ $t('description') }}</th>
                  <th class="text-center" style="border: 1px solid #777777; border-collapse: collapse;">CodBarras</th>
                  <th class="text-center" style="border: 1px solid #777777; border-collapse: collapse;">CodBarrasPick</th>
                  <th class="text-center" style="border: 1px solid #777777; border-collapse: collapse;">Manual Pick</th>
                  <th class="text-center" style="border: 1px solid #777777; border-collapse: collapse;">Total Pick</th>
                  <th class="text-center" style="border: 1px solid #777777; border-collapse: collapse;">{{ $t('price') }}</th>
                  <th  style="white-space: nowrap; border: 1px solid #777777; border-collapse: collapse;" class="text-center">
                    {{ $t('quantity') }}
                  </th>
                  <th  style="white-space: nowrap; border: 1px solid #777777; border-collapse: collapse;" class="text-center">
                    Desconto
                  </th>
                  <th class="text-center" style="border: 1px solid #777777; border-collapse: collapse;">CodIVA</th>
                </tr>
                <tr class="filters_row">
                  <td class="text-center">

                  </td>
                  <td>
                    <v-text-field
                        v-model="filters.product"
                        outlined
                        hide-details
                        dense
                        @keypress.enter="filterLines"
                    ></v-text-field>
                  </td>
                  <td v-if="serialNumbers"></td>
                  <td>
                    <v-text-field
                        v-model="filters.description"
                        outlined
                        hide-details
                        dense
                        @keypress.enter="filterLines"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                        v-model="filters.barcode"
                        outlined
                        hide-details
                        dense
                        @keypress.enter="filterLines"
                    ></v-text-field>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="line in pickLines">
                  <td class="text-center" style="border: none"></td>
                  <td style="border: 1px solid #dedede; border-collapse: collapse;">{{ line.artigo }}</td>
                  <td v-if="serialNumbers" style="border: 1px solid #dedede; border-collapse: collapse;">{{ line.numSerie }}</td>
                  <td style="border: 1px solid #dedede; border-collapse: collapse;">{{ line.descricao }}</td>
                  <td class="text-center" style="border: 1px solid #dedede; border-collapse: collapse;">{{ line.codBarras }}</td>
                  <td class="text-center" style="border: 1px solid #dedede; border-collapse: collapse;">{{ line.codBarrasPick }}</td>
                  <td class="text-center" style="border: 1px solid #dedede; border-collapse: collapse;">
                    {{ line.manualPick }}
                  </td>
                  <td class="text-center" style="border: 1px solid #dedede; border-collapse: collapse;">{{ line.codBarrasPick+line.manualPick }}</td>
                  <td class="text-center" style="border: 1px solid #dedede; border-collapse: collapse;">
                    {{ $money(line.price) }}
                  </td>
                  <td class="text-center" style="border: 1px solid #dedede; border-collapse: collapse;">  {{ line.quantity }}</td>
                  <td class="text-center" style="border: 1px solid #dedede; border-collapse: collapse;">{{ line.desconto1 }}+{{ line.desconto2 }}+{{ line.desconto3 }} </td>
                  <td class="text-center" style="border: 1px solid #dedede; border-collapse: collapse;">
                    {{ line.codIVA }}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    </div>
  </v-container>
</template>

<script>

import i18n from "@/plugins/i18n";

export default {
  data: function () {
    return {
      pickNumber: '',
      serialNumbers: false,
      totalProducts: 0,
      pickOrder: {},
      pickLines: [],
      pickLinesOriginal: [],
      filters: {
        product: '',
        description: '',
        barcode: ''
      }
    }
  },
  created() {
    this.pickNumber = this.$route.params.id;
    document.title = 'Picking ' + this.pickNumber + ' | STAFF';
    this.getPick();
  },
  methods: {
    getPick() {
      let loader = this.$loading.show();

      let request = {
        url: '/api/staff/picks-history/' + this.pickNumber
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;

            if (responseData.status == 'OK') {
              this.pickOrder = responseData.pickOrder;
              this.pickLines = responseData.products;
              this.pickLinesOriginal = responseData.products;
              this.totalProducts = responseData.totalProducts;
              this.serialNumbers = responseData.products.filter((product)=> product.numSerie).length > 0;
            }
            loader.hide();
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            loader.hide();
          })
    },
    filterLines() {
      this.pickLines = this.pickLinesOriginal;
      if (this.filters.product !== '') {
        this.pickLines = this.pickLines.filter(item => item.artigo.toUpperCase().includes(this.filters.product.toUpperCase()));
      }

      if (this.filters.description !== '') {
        this.pickLines = this.pickLines.filter(item => item.descricao.toUpperCase().includes(this.filters.description.toUpperCase()));
      }

      if (this.filters.barcode !== '') {
        this.pickLines = this.pickLines.filter(item => item.codBarras.includes(this.filters.barcode));
      }
    },
    printPicking() {
      const options = {
        name: '_blank',
        specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
        styles: [
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        ]
      }

      let html = document.getElementById('printPage')
      html.querySelectorAll('tr.filters_row').forEach(e => e.style.display = "none");
      html.querySelectorAll('button').forEach(e => e.style.display = "none");
      // html.querySelectorAll('td').forEach(e => e.style.border = "1px solid black");
      // html.querySelectorAll('td').forEach(e => e.style.borderCollapse = "collapse");
      // html.querySelectorAll('th').forEach(e => e.style.border = "1px solid black");
      // html.querySelectorAll('th').forEach(e => e.style.borderCollapse = "collapse");

      this.$htmlToPaper('printPage', options);
      html.querySelectorAll('tr.filters_row').forEach(e => e.style.display = "table-row");
      html.querySelectorAll('button').forEach(e => e.style.display = "inline-flex");
      // html.querySelectorAll('td').forEach(e => e.style.border = "none");
      // html.querySelectorAll('th').forEach(e => e.style.border = "none");
    }
  }
}
</script>
