import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const Permission = new Vuex.Store({
    actions: {
        getPermissions(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.permissions())
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            resolve(responseData)
                        } else {
                            reject(responseData)
                        }
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        savePermission(context, permission) {
            return new Promise((resolve, reject) => {
                let data = {
                    permission: {
                        permission: permission.permission
                    }
                }
                axios.post(api.permissions(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        updatePermission(context, permission){
            return new Promise((resolve, reject) => {
                let data = {
                    permission: {
                        id: permission.id,
                        permission: permission.permission
                    }
                }
                axios.put(api.permissions(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        removerPermission(context, permission) {
            return new Promise((resolve, reject) => {
                axios.delete(api.permissions()+'/'+permission.id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    }
})

export default Permission
