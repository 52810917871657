import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from "axios";
import User from "../models/User"
import i18n from "../plugins/i18n"

Vue.use(Vuex)

const App = new Vuex.Store({
    plugins: [createPersistedState({
        key: 'app'
    })],

    state: {
        version: '0.0.1'
    },

    actions: {
        authenticated(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.userAuthenticated())
                    .then(response => {
                        let responseData = response.data

                        if (responseData.status === 'OK') {
                            context.commit('updateVersion', responseData.data)
                            let language = User.getters.getLanguage
                            if (language !== responseData.language && language !== '') {
                                User.dispatch('changeLanguage', responseData.language)
                            }
                        }

                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
    },

    mutations: {
        updateVersion(state, version) {
            state.version = version
        }
    },

    getters: {
        getVersion(state) {
            return state.version
        },
        getUrlAPI() {
            return process.env.VUE_APP_API_URL
        }
    }
})

export default App
