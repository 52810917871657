<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        :title="$t('brandsDiscounts')"
                        :subtitle="'A mostrar '+brands.length+' marcas'"
                ></page-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>Marca</th>
                            <th>{{ $t('name') }} da Marca</th>
                            <th class="text-center" style="width: 100px;">Necessária CrossSelling</th>
                            <th class="text-center" style="width: 100px;">Usufrui CrossSelling</th>
                            <th>Grupo</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="6">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="brand in brands">
                            <td>{{ brand.Marca }}</td>
                            <td>{{ brand.NomeMarca }}</td>
                            <td class="text-center">
                                <v-checkbox class="ml-8 mt-0" v-model="brand.CDU_CrossSelling" @change="saveCrossSelling(brand)" hide-details></v-checkbox>
                            </td>
                            <td class="text-center">
                                <v-checkbox class="ml-8 mt-0" v-model="brand.CDU_UsaCrossSelling" @change="saveCrossSelling(brand)" hide-details></v-checkbox>
                            </td>
                            <td>{{ brand.CDU_Grupo }}</td>
                            <td class="text-right">
                                <v-btn small color="primary" :to="'/products/brandsDiscount/'+brand.Marca">{{ brand.Niveis }} {{ $t('levelsDiscount') }}</v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialogConfirm" max-width="500">
                <v-card>
                    <v-card-title>{{ $t('confirmChange') }}</v-card-title>
                    <v-card-text>
                        {{ $t('confirmCrossSelling') }}
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small  @click="closeDialogConfirm()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  color="success" @click="confirmChange()">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data: function () {
            return {
                loading: false,
                brands: [],
                brand: null,
                dialogConfirm: false
            }
        },
        created() {
            document.title = i18n.t('brandsDiscounts');
            this.getBrandsDiscount()
        },
        methods: {
            getBrandsDiscount() {
                this.loading = true;
                let request = {
                    url: api.productsBrandsDiscounts()
                };
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.brands = responseData.brands;
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            saveCrossSelling(brand,) {
                this.brand = brand;
                this.dialogConfirm = true;
            },
            closeDialogConfirm() {
                this.dialogConfirm = false;
                this.brand = null;
                this.getBrandsDiscount()
            },
            confirmChange() {
                let request = {
                    url: api.productsBrandsDiscounts(),
                    data: {
                        brand: this.brand.Marca,
                        crossSelling: this.brand.CDU_CrossSelling,
                        usaCrossSelling: this.brand.CDU_UsaCrossSelling,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'CrossSelling alterado com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao alterar CrossSelling!',
                                type: 'error'
                            });
                        }
                        this.closeDialogConfirm();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        });
                        this.closeDialogConfirm();
                    });
            }
        }
    }
</script>
