<style scoped>
.v-input__append-inner {
  margin-top: 5px !important;
}

.staff_table tr .v-btn {
  display: none;
}

.staff_table tr:hover .v-btn {
  display: block;
}

</style>

<template>
  <v-container fluid>

    <v-row class="content-header">
      <v-col>
        <page-title
            title="Cosec"
            :subtitle="$t('show') + ' ' + lines.length + ' de ' + originalLines.length + ' documentos'"
        ></page-title>
      </v-col>
      <v-col class="text-right">
        <v-btn tile small class="ml-2 mb-2" @click="resetFilters()" color="error">Limpar Filtros</v-btn>
<!--        <v-btn tile small class="ml-2 mb-2" @click="update()" color="info">Atualizar</v-btn>-->
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="staff_v-card">
          <v-simple-table class="staff_table">
            <thead>
            <tr>
              <th style="width: 200px; cursor: pointer;" @click="orderList('id')" class="text-center">
                Id
                <i v-if="selectedHeader === 'id'" class="fas"
                   :class="[headers['id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('client_id')" class="text-center">
                Cliente
                <i v-if="selectedHeader === 'client_id'" class="fas"
                   :class="[headers['client_id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('client_cosec_id')" class="text-center">
                Cosec Id
                <i v-if="selectedHeader === 'client_cosec_id'" class="fas"
                   :class="[headers['client_cosec_id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('guaranty_id')" class="text-center">
                Garantia Id
                <i v-if="selectedHeader === 'guaranty_id'" class="fas"
                   :class="[headers['guaranty_id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
<!--              <th style="width: 200px; cursor: pointer;" @click="orderList('policy_id')" class="text-center">-->
<!--                Policy Id-->
<!--                <i v-if="selectedHeader === 'policy_id'" class="fas"-->
<!--                   :class="[headers['policy_id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>-->
<!--              </th>-->
              <th style="width: 200px; cursor: pointer;" @click="orderList('replaced_guaranty_id')" class="text-center">
                Id Garantia Substituída
                <i v-if="selectedHeader === 'replaced_guaranty_id'" class="fas"
                   :class="[headers['replaced_guaranty_id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('requested_amount')" class="text-center">
                Valor Requerido
                <i v-if="selectedHeader === 'requested_amount'" class="fas"
                   :class="[headers['requested_amount'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('granted_amount')" class="text-center">
                Valor Garantia
                <i v-if="selectedHeader === 'granted_amount'" class="fas"
                   :class="[headers['granted_amount'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('status_code')" class="text-center">
                Cód. Estado
                <i v-if="selectedHeader === 'status_code'" class="fas"
                   :class="[headers['status_code'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('status_code_desc')" class="text-center">
                Desc. Estado
                <i v-if="selectedHeader === 'status_code_desc'" class="fas"
                   :class="[headers['status_code_desc'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
<!--              <th style="width: 200px; cursor: pointer;" @click="orderList('type')" class="text-center">-->
<!--                Tipo-->
<!--                <i v-if="selectedHeader === 'type'" class="fas"-->
<!--                   :class="[headers['type'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>-->
<!--              </th>-->
              <th style="width: 200px; cursor: pointer;" @click="orderList('type_desc')" class="text-center">
                Desc. Tipo
                <i v-if="selectedHeader === 'type_desc'" class="fas"
                   :class="[headers['type_desc'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('created_by')" class="text-center">
                Criador
                <i v-if="selectedHeader === 'created_by'" class="fas"
                   :class="[headers['created_by'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('start_validity_date')" class="text-center">
                Validade Inicial
                <i v-if="selectedHeader === 'start_validity_date'" class="fas"
                   :class="[headers['start_validity_date'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('end_validity_date')" class="text-center">
                Validade Final
                <i v-if="selectedHeader === 'end_validity_date'" class="fas"
                   :class="[headers['end_validity_date'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('creation_date')" class="text-center">
                Data Criação Cosec
                <i v-if="selectedHeader === 'creation_date'" class="fas"
                   :class="[headers['creation_date'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('created_at')" class="text-center">
                Data Criação
                <i v-if="selectedHeader === 'created_at'" class="fas"
                   :class="[headers['created_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="width: 200px; cursor: pointer;" @click="orderList('observations')" class="text-center">
                Observações
                <i v-if="selectedHeader === 'observations'" class="fas"
                   :class="[headers['observations'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
            </tr>
            <tr class="filters_row">
              <th>
                <v-text-field outlined hide-details v-model="filters.id" @change="updateTable()"></v-text-field>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.client_id" @change="updateTable()"></v-text-field>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.client_cosec_id"
                              @change="updateTable()"></v-text-field>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.guaranty_id" @change="updateTable()"></v-text-field>
              </th>
<!--              <th>-->
<!--                <v-text-field outlined hide-details v-model="filters.policy_id" @change="updateTable()"></v-text-field>-->
<!--              </th>-->
              <th>
                <v-text-field outlined hide-details v-model="filters.replaced_guaranty_id"
                              @change="updateTable()"></v-text-field>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.requested_amount"
                              @change="updateTable()"></v-text-field>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.granted_amount"
                              @change="updateTable()"></v-text-field>
              </th>
              <th>
<!--                <v-text-field outlined hide-details v-model="filters.status_code" @change="updateTable()"></v-text-field>-->
                <v-select
                    dense
                    v-model="filters.status_code"
                    :items="statusCodes"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    @change="updateTable()"
                ></v-select>
              </th>
              <th>
<!--                <v-text-field outlined hide-details v-model="filters.status_code_desc"-->
<!--                              @change="updateTable()"></v-text-field>-->
                <v-select
                    dense
                    v-model="filters.status_code_desc"
                    :items="statusDescriptions"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    @change="updateTable()"
                ></v-select>
              </th>
<!--              <th>-->
<!--                <v-text-field outlined hide-details v-model="filters.type" @change="updateTable()"></v-text-field>-->
<!--                <v-select-->
<!--                    dense-->
<!--                    v-model="filters.type"-->
<!--                    :items="types"-->
<!--                    item-text="text"-->
<!--                    item-value="value"-->
<!--                    outlined-->
<!--                    hide-details-->
<!--                    @change="updateTable()"-->
<!--                ></v-select>-->
<!--              </th>-->
              <th>
<!--                <v-text-field outlined hide-details v-model="filters.type_desc" @change="updateTable()"></v-text-field>-->
                <v-select
                    dense
                    v-model="filters.type_desc"
                    :items="typeDescriptions"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    @change="updateTable()"
                ></v-select>
              </th>
              <th>
<!--                <v-text-field outlined hide-details v-model="filters.created_by" @change="updateTable()"></v-text-field>-->
                <v-select
                    dense
                    v-model="filters.created_by"
                    :items="creators"
                    item-text="text"
                    item-value="value"
                    outlined
                    hide-details
                    @change="updateTable()"
                ></v-select>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.start_validity_date"
                              @change="updateTable()"></v-text-field>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.end_validity_date"
                              @change="updateTable()"></v-text-field>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.creation_date"
                              @change="updateTable()"></v-text-field>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.created_at" @change="updateTable()"></v-text-field>
              </th>
              <th>
                <v-text-field outlined hide-details v-model="filters.observations" @change="updateTable()"></v-text-field>
              </th>
            </tr>
            </thead>
            <tbody v-if="loading">
            <tr>
              <td colspan="18">
                <v-progress-linear indeterminate></v-progress-linear>
              </td>
            </tr>
            </tbody>
            <tbody v-if="!loading">
            <tr v-for="line in pagedLines">
              <!--              <td>-->
              <!--                <div @click="selectEntidade(line.Entidade)" style="cursor: pointer">-->
              <!--                  {{ line.Entidade }}-->
              <!--                </div>-->
              <!--              </td>-->

              <!--              <td class="text-right">{{ $money(line.ValorPendente) }}</td>-->

              <td class="text-center">{{ line.id }}</td>
              <td>{{ line.client_id }}</td>
              <td class="text-right">{{ line.client_cosec_id }}</td>
              <td class="text-center">{{ line.guaranty_id }}</td>
<!--              <td>{{ line.policy_id }}</td>-->
              <td class="text-center">{{ line.replaced_guaranty_id }}</td>
              <td class="text-right">{{ $money(line.requested_amount) }}</td>
              <td class="text-right">{{ $money(line.granted_amount) }}</td>
              <td class="text-center">{{ line.status_code }}</td>
              <td>{{ line.status_code_desc }}</td>
<!--              <td class="text-center">{{ line.type }}</td>-->
              <td>{{ line.type_desc }}</td>
              <td>{{ line.created_by }}</td>
              <td>{{ line.start_validity_date }}</td>
              <td>{{ line.end_validity_date }}</td>
              <td>{{ line.creation_date }}</td>
              <td>{{ line.created_at }}</td>
              <td>{{ line.observations }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import _ from "lodash";

export default {
  data: function () {
    return {
      loading: false,
      lines: [],
      originalLines: [],
      total: [],
      statusCodes: [],
      statusDescriptions: [],
      types: [],
      typeDescriptions: [],
      creators: [],
      filters: {
        client_cosec_id: '',
        client_id: '',
        created_at: '',
        created_by: '',
        creation_date: '',
        end_validity_date: '',
        granted_amount: '',
        guaranty_id: '',
        id: '',
        observations: '',
        // policy_id: '',
        replaced_guaranty_id: '',
        requested_amount: '',
        start_validity_date: '',
        status_code: '',
        status_code_desc: '',
        // type: '',
        type_desc: '',
      },
      selectedHeader: "id",
      headers: {
        "client_cosec_id": 'asc',
        "client_id": 'asc',
        "created_at": 'asc',
        "created_by": 'asc',
        "creation_date": 'asc',
        "end_validity_date": 'asc',
        "granted_amount": 'asc',
        "guaranty_id": 'asc',
        "id": 'asc',
        "observations": 'asc',
        // "policy_id": 'asc',
        "replaced_guaranty_id": 'asc',
        "requested_amount": 'asc',
        "start_validity_date": 'asc',
        "status_code": 'asc',
        "status_code_desc": 'asc',
        // "type": 'asc',
        "type_desc": 'asc',
      },

      pagination: {
        rowsPerPage: 250,
        page: 1
      },
      itemPerPage: [
        {text: '50', value: 50},
        {text: '100', value: 100},
        {text: '250', value: 250},
        {text: '500', value: 500}
      ],
    }
  },
  created() {
    document.title = 'Cosec';
    this.initialize();
  },
  methods: {
    initialize() {
      this.loading = true
      ApiWS.dispatch('makeGet', {url: '/api/staff/accounts/cosec'})
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.originalLines = responseData.data;
              this.lines = responseData.data;
              this.total = this.originalLines.length;

              // this.filterLines();
              this.loading = false
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro de servidor!',
                type: 'error'
              })
            }
            this.loading = false
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            this.loading = false
          })
    },
    orderList(col) {
      if (col == this.selectedHeader)
        this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
      else
        this.headers[this.selectedHeader] = "desc";

      this.selectedHeader = col;
      this.pagination.page = 1;

    },
    updateTable() {
      this.lines = this.originalLines


      if (this.filters.id !== '') {
        this.lines = this.lines.filter(line => line.id == this.filters.id)
      }
      if (this.filters.client_id !== '') {
        this.lines = this.lines.filter(line => line.client_id.includes(this.filters.client_id))
      }
      if (this.filters.client_cosec_id !== '') {
        this.lines = this.lines.filter(line => line.client_cosec_id.includes(this.filters.client_cosec_id))
      }
      if (this.filters.guaranty_id !== '') {
        this.lines = this.lines.filter(line => line.guaranty_id == this.filters.guaranty_id)
      }
      if (this.filters.granted_amount !== '') {
        this.lines = this.lines.filter(line =>
          this.$2decimal(line.granted_amount) === this.$2decimal(this.filters.granted_amount) )
      }
      if (this.filters.replaced_guaranty_id !== '') {
        this.lines = this.lines.filter(line => line.replaced_guaranty_id == this.filters.replaced_guaranty_id)
      }
      if (this.filters.requested_amount !== '') {
        this.lines = this.lines.filter(line => this.$2decimal(line.requested_amount) === this.$2decimal(this.filters.requested_amount) )
      }
      if (this.filters.status_code !== '') {
        this.lines = this.lines.filter(line => line.status_code == this.filters.status_code)
      }
      if (this.filters.status_code_desc !== '') {
        this.lines = this.lines.filter(line => line.status_code_desc.includes(this.filters.status_code_desc))
      }
      // if (this.filters.type !== '') {
      //   this.lines = this.lines.filter(line => line.type.includes(this.filters.type))
      // }
      if (this.filters.type_desc !== '') {
        this.lines = this.lines.filter(line => line.type_desc.includes(this.filters.type_desc))
      }
      if (this.filters.created_by !== '') {
        this.lines = this.lines.filter(line => line.created_by.includes(this.filters.created_by))
      }
      if (this.filters.start_validity_date !== '') {
        this.lines = this.lines.filter(line => line.start_validity_date != null && line.start_validity_date.includes(this.filters.start_validity_date))
      }
      if (this.filters.end_validity_date !== '') {
        this.lines = this.lines.filter(line => line.end_validity_date != null && line.end_validity_date.includes(this.filters.end_validity_date))
      }
      if (this.filters.creation_date !== '') {
        this.lines = this.lines.filter(line => line.creation_date != null && line.creation_date.includes(this.filters.creation_date))
      }
      if (this.filters.created_at !== '') {
        this.lines = this.lines.filter(line => line.created_at != null && line.created_at.includes(this.filters.created_at))
      }

      if (this.filters.observations !== '') {
        this.lines = this.lines.filter(line => line.observations.includes(this.filters.observations))
      }

      this.total = this.lines.length

    },
    resetFilters() {
      this.filters = _.mapValues(this.filters, () => '')
      this.updateTable()
      // this.filterLines();
    },

  },
  computed: {
    getTotalPages() {
      return Math.ceil(this.total / this.pagination.rowsPerPage)
    },
    pagedLines: function(){
      this.statusCodes= [...new Set(this.originalLines.map(x => x.status_code))]
      this.statusCodes.sort().unshift('')

      this.statusDescriptions= [...new Set(this.originalLines.map(x => x.status_code_desc))]
      this.statusDescriptions.sort().unshift('')

      // this.types= [...new Set(this.originalLines.map(x => x.type))]
      // this.types.sort().unshift('')

      this.typeDescriptions= [...new Set(this.originalLines.map(x => x.type_desc))]
      this.typeDescriptions.sort().unshift('')

      this.creators= [...new Set(this.originalLines.map(x => x.created_by))]
      this.creators.sort().unshift('')

      return this.orderedLines.slice((this.pagination.page-1)*this.pagination.rowsPerPage,this.pagination.page*this.pagination.rowsPerPage);
    },
    orderedLines: function () {
      return _.orderBy(this.lines, this.selectedHeader,this.headers[this.selectedHeader]);
    }
  }
}

</script>
