<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title title="Lotes A EXPIRAR"
                            :subtitle="$t('show') + ' ' + lines.length + ' de ' + originalLines.length + ' Lotes'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small class="ml-2 mb-2" @click="resetFilters()" color="error">Limpar Filtros</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 100px; cursor: pointer;" @click="orderList('Artigo')" class="text-center">Artigo
                                <i v-if="selectedHeader === 'Artigo'" class="fas"
                                   :class="[headers['Artigo'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('Nome')" class="text-center">
                                Nome
                                <i v-if="selectedHeader === 'Nome'" class="fas"
                                   :class="[headers['Nome'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('Lote')"
                                class="text-center">Lote
                                <i v-if="selectedHeader === 'Lote'" class="fas"
                                   :class="[headers['Lote'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                <th style="width: 100px; cursor: pointer;" @click="orderList('Armazem')" class="text-center">Armazem
                                    <i v-if="selectedHeader === 'Armazem'" class="fas"
                                       :class="[headers['Armazem'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                </th></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('Stock')"
                                class="text-center">Stock
                                <i v-if="selectedHeader === 'Stock'" class="fas"
                                   :class="[headers['Stock'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('Validade')"
                                class="text-center">Validade
                                <i v-if="selectedHeader === 'Validade'" class="fas"
                                   :class="[headers['Validade'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                        </tr>
                        <tr class="filters_row">
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Artigo"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Nome"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Lote"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Armazem"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Stock"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Validade"
                                              @change="updateTable()"></v-text-field>
                            </th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="line in pagedLines">
                            <td>{{ line.Artigo }}</td>
                            <td class="text-center">{{ line.Nome }}</td>
                            <td class="text-center">{{ line.Lote }}</td>
                            <td class="text-center">{{ line.Armazem }} - {{line.Descricao}}</td>
                            <td class="text-center">{{ line.Stock }}</td>
                            <td class="text-center">{{ line.Validade }}</td>

                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-select
                        v-model="pagination.rowsPerPage"
                        :items="filtersPerPage"
                        dense
                        outlined
                        hide-details
                        item-text="TEXT"
                        item-value="VALUE"
                        style="float: left; width: 100px;"
                ></v-select>
            </v-col>
            <v-col class="text-right">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        style="justify-content: flex-end;"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import _ from "lodash";

export default {
    name: "ExpiringLotes",
    data: function () {
        return {
            lotes: [],
            lines: [],
            originalLines: [],
            selectedHeader: "Artigo",
            filters: {
                Artigo: '',
                Nome: '',
                Lote: '',
                Armazem: '',
                Stock: '',
                Validade: ''
            },
            headers: {
                "Artigo": 'asc',
                "Nome": 'asc',
                "Lote": 'asc',
                "Armazem": 'asc',
                "Stock": 'asc',
                "Validade": 'asc'
            },
            pagination: {
                rowsPerPage: 25,
                page: 1
            },
            itemPerPage: [
                {text: '50', value: 50},
                {text: '100', value: 100},
                {text: '250', value: 250},
                {text: '500', value: 500}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            total: [],
        }
    },
    created() {
        document.title = 'Lotes a expirar | STAFF';
        this.getExpiringLotes();
    },
    methods: {
        getExpiringLotes() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/products/expiringLotes'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.originalLines = responseData.data;
                            this.lines = responseData.data;
                            this.total = this.originalLines.length;

                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        updateTable() {
            this.lines = this.originalLines

            if (this.filters.Lote !== '') {
                this.lines = this.lines.filter(line => line.Lote != null && line.Lote == this.filters.Lote)
            }
            if (this.filters.Armazem !== '') {
                this.lines = this.lines.filter(line => line.Armazem != null && line.Armazem == this.filters.Armazem)
            }
            if (this.filters.Nome !== '') {
                this.lines = this.lines.filter(line => line.Nome != null && line.Nome.includes(this.filters.Nome))
            }
            if (this.filters.Artigo !== '') {
                this.lines = this.lines.filter(line => line.Artigo != null && line.Artigo.includes(this.filters.Artigo))
            }
            if (this.filters.Stock !== '') {
                this.lines = this.lines.filter(line => line.Stock != null &&  line.Stock == this.filters.Stock)
            }
            if (this.filters.Validade !== '') {
                this.lines = this.lines.filter(line => line.Validade != null && line.Validade.includes(this.filters.Validade))
            }

            this.total = this.lines.length
            this.pagination.page = 1;

        },
        resetFilters() {
            this.filters = _.mapValues(this.filters, () => '')
            this.updateTable()
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;

        },
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.pagination.rowsPerPage)
        },
        pagedLines: function () {
            return this.orderedLines.slice((this.pagination.page - 1) * this.pagination.rowsPerPage, this.pagination.page * this.pagination.rowsPerPage);
        },
        orderedLines: function () {
            return _.orderBy(this.lines, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    }
}
</script>



<style scoped>

</style>