<style scoped>
    .v-data-table td, .v-data-table th {
        padding: 0 6px;
        height: 26px;
    }

    .v-icon.v-icon.fas {
        font-size: 16px;
    }
</style>

<template>
    <div>
        <v-card v-if="loading" height="280">
            <v-card-text class="text-center" style="height: 100%;">
                <i class="fas fa-cog fa-spin fa-3x" style="margin-top: 90px"></i>
            </v-card-text>
        </v-card>
        <v-card v-if="!loading">
            <v-card-title><i class="fas fa-shopping-cart fa-fw mr-2"></i>{{ $t('pendingPayment') }}</v-card-title>
            <v-card-text>
                <v-simple-table dense style="background-color: transparent;">
                    <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>{{ $t('name') }}</th>
                        <th>Localidade</th>
                        <th class="text-center">Vendedor</th>
                        <th class="text-center">Zona Comercial</th>
                        <th class="text-right">Valor Total</th>
                        <th class="text-right">{{ $t('pendingAmount') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in lines">
                        <td>
                            {{ line.Entidade }}
                            <small><a :href="'/clients/clients/'+line.Entidade" target="_blank" class="ml-1"><i class="fas fa-fw fa-external-link-alt"></i></a></small>
                        </td>
                        <td>{{ line.Nome }}</td>
                        <td>{{ line.Fac_Cploc }}</td>
                        <td class="text-center">{{ line.Vendedor }}</td>
                        <td class="text-center">{{ line.CDU_ZonaComercial }}</td>
                        <td class="text-right" style="white-space: nowrap">{{ $money(line.TotalDocumentos) }}</td>
                        <td class="text-right" style="white-space: nowrap">{{ $money(line.TotalPendente) }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

    export default {
        data: function () {
            return {
                loading: true,
                lines: []
            }
        },
        created() {
            this.initialize()
        },
        methods: {
            initialize() {
                let request = {
                    url: api.dashboardWidgetPendingReceipts()
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.lines = responseData.pendentsSales
                            this.loading = false
                        }
                    })
                    .catch(response => {

                    })
            }
        }
    }
</script>
