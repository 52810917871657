<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title title="Banner Types"
                            :subtitle="$t('show') + ' ' + lines.length + ' de ' + originalLines.length + ' linhas'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small class="ml-2 mb-2" @click="resetFilters()" color="error">Limpar Filtros</v-btn>
                <v-btn tile small color="primary" class="ml-2 mb-2" @click="toggleDialogVisibility()">
                    Adicionar
                </v-btn>
                <!--        <v-btn tile small class="ml-2 mb-2" @click="update()" color="info">Atualizar</v-btn>-->
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 20px; cursor: pointer;" @click="orderList('id')" class="text-center">ID
                                <i v-if="selectedHeader === 'id'" class="fas"
                                   :class="[headers['id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 45px; cursor: pointer;" @click="orderList('name')" class="text-center">
                                Name
                                <i v-if="selectedHeader === 'name'" class="fas"
                                   :class="[headers['name'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('created_at')"
                                class="text-center">Data de
                                criação
                                <i v-if="selectedHeader === 'created_at'" class="fas"
                                   :class="[headers['created_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('updated_at')"
                                class="text-center">Data de
                                atualização
                                <i v-if="selectedHeader === 'updated_at'" class="fas"
                                   :class="[headers['updated_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 50px; cursor: pointer;" @click="orderList('language')"
                                class="text-center">Descrição PT
                                <i v-if="selectedHeader === 'language'" class="fas"
                                   :class="[headers['language'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 50px; cursor: pointer;" @click="orderList('description')"
                                class="text-center">Descrição
                                ES
                                <i v-if="selectedHeader === 'description'" class="fas"
                                   :class="[headers['description'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                        </tr>
                        <tr class="filters_row">
                            <th>
                                <v-text-field outlined hide-details v-model="filters.id"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.name"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.created_at"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.updated_at"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.language"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.description"
                                              @change="updateTable()"></v-text-field>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="line in pagedLines" v-if="line.deleted_at ==null">
                            <td>{{ line.id }}</td>
                            <td class="text-center">{{ line.name }}</td>
                            <td class="text-center">{{ line.created_at }}</td>
                            <td class="text-center">{{ line.updated_at }}</td>
                            <td class="text-center">{{ line.description }}
                            </td>
                            <td class="text-center">{{ line.spanishDescription }}
                            </td>
                            <td class="text-center" width="15%">
                                <v-row>
                                    <v-btn tile small width="35%" color="primary" class="mb-2 mt-2 ml-10"
                                           @click="toggleEditDescription(line.id,line.description,line.spanishDescription)">
                                        EDITAR
                                    </v-btn>
                                    <v-btn tile small width="35%" color="error" class="mb-2 mt-2 ml-2"
                                              @click="toggleDelete(line.id)">
                                    REMOVER
                                </v-btn>
                                </v-row>



                                <!--                <v-btn tile small width="80%" color="#ffd700" class="mb-2" @click="toggleEdit(line.id,line.name)">
                                                  EDITAR
                                                </v-btn>-->
                                <!--                <v-btn color="primary" @click="document.getElementById('uploader').click()">
                                                  Upload
                                                  <v-file-input hide-input accept=".pdf" v-model="chosenFile" id="uploader"></v-file-input>
                                                </v-btn>-->
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-select
                        v-model="pagination.rowsPerPage"
                        :items="filtersPerPage"
                        dense
                        outlined
                        hide-details
                        item-text="TEXT"
                        item-value="VALUE"
                        style="float: left; width: 100px;"
                ></v-select>
            </v-col>
            <v-col class="text-right">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        style="justify-content: flex-end;"
                ></v-pagination>
            </v-col>
        </v-row>

        <v-row justify="center">
            <!--    ADD DIALOG-->
            <v-dialog v-model="dialogVisibility" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Adicionar Tipo de Banner
                    </v-card-title>
                    <v-row>
                        <v-col>
                            <v-form v-model="formValid" ref="myForm">
                                <v-text-field class="mx-2" label="Introduza o nome do Tipo de Banner" outlined dense
                                              v-model="nameInput"
                                              :rules="rules.required"></v-text-field>
                                <v-text-field class="mx-2" label="Introduza a descrição portuguesa do Tipo de Banner"
                                              outlined dense
                                              v-model="descriptionInput"></v-text-field>
                                <v-text-field class="mx-2" label="Introduza a descrição espanhola do Tipo de Banner"
                                              outlined dense
                                              v-model="descriptionESInput"></v-text-field>
                            </v-form>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                        <v-btn color="error" @click="dialogVisibility = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="addBannerType()">Adicionar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--    ADD DIALOG-->
            <!--    DELETE DIALOG-->
            <v-dialog v-model="deleteDialog" overlay-color="white" overlay-opacity="0.5" persistent
                      max-width="530">
                <v-card>
                    <v-card-title  class="red--text">
                        Tem a certeza que quer apagar este tipo de banner?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn color="error" @click="deleteDialog = false">Não</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="deleteBannerType()">Sim</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!--    DELETE DIALOG-->
            <!--    EDIT DIALOG-->
            <!--    <v-dialog v-model="editDialog" persistent max-width="400">
                  <v-card>
                    <v-card-title>
                      Editar Tipo de Banner
                    </v-card-title>
                    <v-row>
                      <v-col>
                        <v-text-field class="mx-2" label="Introduza o nome do Banner" filled v-model="editNameAux"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-btn color="error" @click="editDialog = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="success" @click="editBannerType()">Adicionar</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>-->
            <!--    EDIT DIALOG-->
            <!--    EDIT DESCRIPTION DIALOG-->
            <v-dialog v-model="editDescriptionDialog" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Editar Descrições do Tipo de Banner
                    </v-card-title>
                    <v-card-text>
                        <v-text-field class="mx-2" label="Descrição PT" outlined dense
                                      v-model="editDescPT"></v-text-field>
                        <v-text-field class="mx-2" label="Descrição ES" outlined hide-details dense
                                      v-model="editDescES"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="error" @click="editDescriptionDialog = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="editBTDescription()">Adicionar</v-btn>
                        <!--          <v-btn color="success" @click="debugDescs()">Debug To Console</v-btn>-->
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!--    EDIT DESCRIPTION DIALOG-->
    </v-container>
</template>

<script>
import _ from "lodash";

export default {
    name: "BannerTypes",
    data: function () {
        return {
            btdAux: {},
            bannerTypes: [],
            bannerType: null,
            idToDelete: null,
            idToEdit: null,
            editNameAux: null,
            editDescAux: null,
            editDescPT: null,
            editDescES: null,
            editlangAux: null,
            nameInput: '',
            languageInput: '',
            descriptionInput: '',
            descriptionESInput: '',
            description: '',
            lines: [],
            originalLines: [],
            manipulatedLines: [],
            noLanguageLines: [],
            total: [],
            types: [],
            typeDescriptions: [],
            formValid: false,
            rules: {
                required: [value => !!value || "Required."]
            },
            filters: {
                id: '',
                name: '',
                created_at: '',
                updated_at: '',
                language: '',
                description: '',
            },
            selectedHeader: "Nome",
            headers: {
                "id": 'asc',
                "name": 'asc',
                "created_at": 'asc',
                "updated_at": 'asc',
                "language": 'asc',
                "description": 'asc'
            },
            pagination: {
                rowsPerPage: 25,
                page: 1
            },
            itemPerPage: [
                {text: '50', value: 50},
                {text: '100', value: 100},
                {text: '250', value: 250},
                {text: '500', value: 500}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            dialogVisibility: false,
            deleteDialog: false,
            editDialog: false,
            editDescriptionDialog: false,
            selectedDocInfo: {
                id: ''
            },
            renderComponent: true,
            lgToDelete: '',
        }
    },
    created() {
        document.title = 'BannerTypes | STAFF';
        this.getBannerTypes();
    },
    methods: {
        getBannerTypes() {
            this.noLanguageLines = [];
            this.manipulatedLines = [];
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/banners-type'
            }
            var spanishLines = []
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            console.log(responseData);
                            responseData.bannerTypes.forEach(aux => {
                                if (aux.language === null && aux.deleted_at == null) {
                                    this.noLanguageLines.push(aux);
                                    delete responseData.bannerTypes[aux];
                                }
                                if (aux.language === 'ES' && aux.deleted_at == null) {
                                    spanishLines.push(aux);
                                }
                                if (aux.language === 'PT' && aux.deleted_at == null) {
                                    this.manipulatedLines.push(aux);
                                }
                            });
                            //console.log(this.noLanguageLines);
                            //  console.log(this.spanishLines);
                            // console.log(this.manipulatedLines);
                            this.manipulatedLines.forEach(pt => {
                                for (var es = 0; es < spanishLines.length; es++) {
                                    if (pt.name === spanishLines[es].name) {
                                        //console.log("Before delete:")
                                        // console.log(spanishLines);
                                        spanishLines[es].spanishDescription = spanishLines[es].description;
                                        pt.spanishDescription = spanishLines[es].spanishDescription;
                                        spanishLines.splice(es--, 1)
                                        //  console.log("After delete:")
                                        //  console.log(spanishLines);
                                    }
                                }
                            })
                            this.noLanguageLines.forEach(n => {
                                this.manipulatedLines.push(n);
                            })
                            spanishLines.forEach(sp => {
                                sp.spanishDescription = sp.description;
                                delete sp.description;
                                this.manipulatedLines.push(sp);
                            })
                            //this.originalLines = responseData.bannerTypes;
                            this.originalLines = this.manipulatedLines;
                            // this.lines = responseData.bannerTypes;
                            this.lines = this.manipulatedLines;
                            this.total = this.originalLines.length;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        addBannerType() {
            if (this.$refs.myForm.validate()) {
                let nameNotEqual = true;
                this.manipulatedLines.forEach(ml => {
                    if (ml.name === this.nameInput) {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Já existe um tipo de banner com esse nome!',
                            type: 'error'
                        })
                        nameNotEqual = false;
                    }
                })

                if (nameNotEqual) {
                    this.dialogVisibility = false
                    let request = {
                        url: '/api/staff/b2b/banners-type',
                        data: {
                            bannerType: {
                                name: this.nameInput
                            },
                        }
                    }
                    if (this.descriptionInput !== '') {
                        request.data.bannerType.descPT = this.descriptionInput;
                    }
                    if (this.descriptionESInput !== '') {
                        request.data.bannerType.descES = this.descriptionESInput;
                    }
                    console.log(request.data.bannerType);
                    this.dialogVisibility = false
                    ApiWS.dispatch('makePost', request)
                            .then(response => {
                                let responseData = response.data;
                                if (responseData.status === "OK") {
                                    Alert.dispatch('show', {
                                        snackbar: true,
                                        text: 'Tipo de banner adicionado com sucesso!',
                                        type: 'success'
                                    })
                                    this.getBannerTypes();
                                }
                            })
                            .catch(() => {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro de servidor!',
                                    type: 'error'
                                })
                            })
                }

                this.nameInput = '';
                this.languageInput = '';
                this.descriptionInput = '';
                this.descriptionESInput = '';

            } else {
                return
            }

        },
        deleteBannerType() {
            let request = {
                url: '/api/staff/b2b/banners-type/' + this.idToDelete
            }
            this.loading = true
            let loader = this.$loading.show();

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            loader.hide()
                            this.getBannerTypes();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.deleteDialog = !this.deleteDialog;
            loader.hide()
        },
        debugDescs() {
            console.log(this.editDescPT)
            console.log(this.editDescES)
            console.log(this.btdAux.descPT)
            console.log(this.btdAux.descES)
        },
        editBTDescription() {
            let request = {
                url: '/api/staff/b2b/banners-type/' + this.idToEdit + '/description/',
                data: {
                    bannerTypesDescription: {
                        descriptionPT: this.editDescPT,
                        descriptionES: this.editDescES
                    },
                    btdAux: {
                        descriptionPT: this.btdAux.descPT,
                        descriptionES: this.btdAux.descES
                    }
                }
            }

            this.loading = true
            let loader = this.$loading.show();

            ApiWS.dispatch('makePut', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Editado com sucesso!',
                                type: 'success'
                            })
                            loader.hide()
                            this.getBannerTypes();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.editDescriptionDialog = !this.editDescriptionDialog;
            loader.hide()
        },
        deleteBannerTypeDescription() {
            let request = {
                url: '/api/staff/b2b/banners-type/' + this.idToDelete + '/description/' + this.lgToDelete
            }
            this.loading = true
            let loader = this.$loading.show();

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            loader.hide()
                            this.getBannerTypes();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.deleteDialog = !this.deleteDialog;
            loader.hide()
        },
        forceRerender() {
            this.renderComponent = false;
            this.$nextTick(() => {
                this.renderComponent = true;
            });
        },
        toggleDialogVisibility() {
            this.dialogVisibility = !this.dialogVisibility;
            this.nameInput = '';
        },
        toggleDelete(id) {
            this.deleteDialog = !this.deleteDialog;
            this.idToDelete = id;
        },
        toggleEdit(id, name) {
            this.editDialog = !this.editDialog;
            this.idToEdit = id;
            this.editNameAux = name;

        },
        toggleEditDescription(id, desc, descES) {
            this.editDescriptionDialog = !this.editDescriptionDialog;
            this.btdAux.descPT = desc;
            this.btdAux.descES = descES;
            this.idToEdit = id;
            this.editDescPT = desc;
            this.editDescES = descES;

        },
        updateTable() {
            this.lines = this.originalLines

            if (this.filters.id !== '') {
                this.lines = this.lines.filter(line => line.id == this.filters.id)
            }
            if (this.filters.name !== '') {
                this.lines = this.lines.filter(line => line.name.includes(this.filters.name))
            }
            if (this.filters.created_at !== '') {
                this.lines = this.lines.filter(line => line.created_at != null && line.created_at.includes(this.filters.created_at))
            }
            if (this.filters.updated_at !== '') {
                this.lines = this.lines.filter(line => line.updated_at != null && line.updated_at.includes(this.filters.updated_at))
            }
            if (this.filters.language !== '') {
                this.lines = this.lines.filter(line => line.language != null && line.language.includes(this.filters.language))
            }
            if (this.filters.description !== '') {
                this.lines = this.lines.filter(line => line.description != null && line.description.includes(this.filters.description))
            }

            this.total = this.lines.length
            this.pagination.page = 1;

        },
        resetFilters() {
            this.filters = _.mapValues(this.filters, () => '')
            this.updateTable()
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;

        },
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.pagination.rowsPerPage)
        },
        pagedLines: function () {
            return this.orderedLines.slice((this.pagination.page - 1) * this.pagination.rowsPerPage, this.pagination.page * this.pagination.rowsPerPage);
        },
        orderedLines: function () {
            return _.orderBy(this.lines, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    }
}
</script>

<style scoped>

</style>