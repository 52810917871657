import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    iconfont: 'mdi',
    theme: {
        themes: {
            light: {
                secondary: '#607D8B',
            },
        },
    },
});
