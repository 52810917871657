<template>
    <v-card flat>

        <v-row no-gutters>
            <v-col class="ma-2">
                <v-card>

                    <v-toolbar color="primary" dense dark>
                        <v-toolbar-title class="subtitle-1">ARTIGOS DA CAMPANHA</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon v-if="!loading && !addProducts" @click="openDialogProducts">
                            <v-icon>add_circle</v-icon>
                        </v-btn>
                        <v-btn icon v-if="!loading && !addProducts && products.length" :outlined="editProducts" @click="toogleEditProducts">
                            <v-icon>edit</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-list-item v-if="loading">
                        <v-progress-linear height="2" indeterminate color="blue darken-4"></v-progress-linear>
                    </v-list-item>

                    <v-row no-gutters v-if="!loading">
                        <v-col class="ma-2">
                            <v-simple-table v-if="!loading" dense>
                                <thead>
                                <tr>
                                    <th class="text-left">Artigo</th>
                                    <th class="text-left">Nome</th>
                                    <th class="text-left" style="width: 1px;">Marca</th>
                                    <th class="text-left" style="width: 1px;">Familia</th>
                                    <th class="text-left" style="width: 1px;">SubFamilia</th>
                                    <th class="text-left" style="width: 1px;">Ano</th>
                                    <th style="width: 1px">
                                        <v-btn v-if="filters.Artigo !== '' || filters.Descricao !== ''" small class="error">limpar filtros</v-btn>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="pa-1">
                                        <v-text-field class="staff_text-field_small" v-model="filters.Artigo" outlined hide-details dense @keyup="filterProducts"></v-text-field>
                                    </th>
                                    <th class="pa-1">
                                        <v-text-field class="staff_text-field_small" v-model="filters.Descricao" outlined hide-details dense @keyup="filterProducts"></v-text-field>
                                    </th>
                                    <th class="pa-1">
                                        <v-text-field class="staff_text-field_small" v-model="filters.Marca" outlined hide-details dense @keyup="filterProducts"></v-text-field>
                                    </th>
                                    <th class="pa-1">
                                        <v-text-field class="staff_text-field_small" v-model="filters.Familia" outlined hide-details dense @keyup="filterProducts"></v-text-field>
                                    </th>
                                    <th class="pa-1">
                                        <v-text-field class="staff_text-field_small" v-model="filters.SubFamilia" outlined hide-details dense @keyup="filterProducts"></v-text-field>
                                    </th>
                                    <th class="pa-1">
                                        <v-text-field class="staff_text-field_small" v-model="filters.Ano" outlined hide-details dense @keyup="filterProducts"></v-text-field>
                                    </th>
                                    <th class="pa-1"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in items" :key="item.Artigo">
                                    <td>{{ item.Artigo }}</td>
                                    <td>{{ item.Descricao }}</td>
                                    <td>{{ item.Marca }}</td>
                                    <td>{{ item.Familia }}</td>
                                    <td>{{ item.SubFamilia }}</td>
                                    <td>{{ item.Ano }}</td>
                                    <td class="text-center">
                                        <v-btn
                                                x-small
                                                fab

                                                color="error"
                                                :disabled="saving && selectedProduct===item.Artigo"
                                                :loading="saving && selectedProduct===item.Artigo"
                                                v-if="editProducts && !addProducts"
                                                @click="deleteProduct('single',item.Artigo)">
                                            <v-icon>clear</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                        <v-col cols="3" class="ma-2">
                            <v-card>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>Artigos filtrados: {{ itemsFiltered.length}}</v-list-item-title>
                                            <v-btn block class="warning" :disabled="saving" :loading="saving"  @click="deleteProduct('filtered', '')">REMOVER FILTRADOS</v-btn>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>Total Artigos: {{ products.length}}</v-list-item-title>
                                            <v-btn block class="error" :disabled="saving" :loading="saving" @click="deleteProduct('all', '')">REMOVER TODOS</v-btn>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

        </v-row>

        <v-row justify="center">

            <v-dialog v-model="modalProductsPri" fullscreen hide-overlay scrollable transition="dialog-bottom-transition">

                <v-card>

                    <v-toolbar class="mb-3" dark dense color="warning" style="max-height: 48px;">
                        <v-toolbar-title>ADICIONAR ARTIGOS</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="closeModalProductsPri()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>{{ $t('product') }}</th>
                                <th>{{ $t('description') }}</th>
                                <th>Marca</th>
                                <th>Familia</th>
                                <th>SubFamilia</th>
                                <th>Ano</th>
                                <th>Stock</th>
                            </tr>
                            <tr class="filters_row">
                                <td>
                                <span style="cursor: pointer; margin-left: 2px">
                                    <i v-if="selected === 0" class="far fa-square fa-lg" @click="selectAll()"></i>
                                    <i v-if="selected !== 0 && selected !== productsPri.length" class="far fa-minus-square fa-lg" @click="selectAll()"></i>
                                    <i v-if="selected !== 0 && selected === productsPri.length" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="unselectAll()"></i>
                                </span>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersModal.productId"
                                            outlined
                                            single-line
                                            @keyup.enter="filterTableProductsPri()"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersModal.description"
                                            outlined
                                            single-line
                                            @keyup.enter="filterTableProductsPri()"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersModal.brand"
                                            outlined
                                            single-line
                                            @keyup.enter="filterTableProductsPri()"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersModal.family"
                                            outlined
                                            single-line
                                            @keyup.enter="filterTableProductsPri()"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersModal.subFamily"
                                            outlined
                                            single-line
                                            @keyup.enter="filterTableProductsPri()"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersModal.year"
                                            outlined
                                            single-line
                                            @keyup.enter="filterTableProductsPri()"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-select
                                            v-model="filtersModal.stock"
                                            :items="filtersYesNo"
                                            outlined
                                            single-line
                                            @change="filterTableProductsPri()"
                                            item-text="TEXT"
                                            item-value="VALUE"
                                            style="width: 60px"
                                            hide-details
                                    ></v-select>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="loading">
                                <td colspan="20">
                                    <v-progress-linear indeterminate></v-progress-linear>
                                </td>
                            </tr>
                            <tr v-for="product in productsPri" style="cursor: pointer" @click="selectProduct(product.Artigo)">
                                <td>
                                    <i v-if="!product.selected" class="far fa-square fa-lg"></i>
                                    <i v-if="product.selected" class="fas fa-check-square fa-lg" style="color:#1976d2"></i>
                                </td>
                                <td>{{ product.Artigo }}</td>
                                <td>{{ product.Descricao }}</td>
                                <td>{{ product.Marca }}</td>
                                <td>{{ product.Familia }}</td>
                                <td>{{ product.SubFamilia }}</td>
                                <td>{{ product.CDU_CampoVar1 }}</td>
                                <td>{{ product.STKActual }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>

                    <v-card-actions class="pa-0">

                        <v-toolbar dense color="white">
                            <v-select
                                    v-model="pagination.per_page"
                                    :items="filtersPerPage"
                                    outlined
                                    single-line
                                    @change="updateTableProductsPri"
                                    item-text="TEXT"
                                    item-value="VALUE"
                                    style="max-width: 90px;"
                                    class="staff_select"
                                    hide-details
                            ></v-select>
                            <v-pagination
                                    v-model="pagination.page"
                                    :length="pagination.pages"
                                    :total-visible="7"
                                    @input="filterTableProductsPri"
                                    style="max-width: 420px;"
                            ></v-pagination>
                            <p class="ma-0">A mostrar 1-{{ productsPri.length }} de {{ totalItems }} artigos.</p>
                            <v-spacer></v-spacer>
                            <v-btn tile small  color="error" @click="resetFilters">Limpar Filtros</v-btn>
                            <v-btn tile small  color="success" :disabled="saving || selected === 0" :loading="saving" @click="addProductsPri">Adicionar ({{ selected }})</v-btn>
                        </v-toolbar>

                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>

    </v-card>
</template>
<script>
    export default {
        props: {
            id: {},
        },
        data() {
            return {
                loading: false,
                saving: false,
                products: [],
                items: [],
                itemsFiltered: [],
                totalItems: 0,
                addProducts: false,
                editProducts: false,
                selectedProduct: '',
                filters: {
                    Artigo: '',
                    Descricao: '',
                    Marca: '',
                    Familia: '',
                    SubFamilia: '',
                    Ano: '',
                },
                modalProductsPri: false,
                productsPri: [],
                totalProductsPri: 0,
                selected: 0,
                pagination: {
                    per_page: 250,
                    page: 1,
                    pages: 100
                },
                filtersModal: {
                    productId: '',
                    barcode: '',
                    description: '',
                    brand: '',
                    family: '',
                    subFamily: '',
                    year: '',
                    stock: ''
                },
                filtersYesNo: [
                    {TEXT: 'Todos', VALUE: ''},
                    {TEXT: 'Não', VALUE: '0'},
                    {TEXT: 'Sim', VALUE: '1'}
                ],
                filtersPerPage: [
                    {TEXT: '20', VALUE: 20},
                    {TEXT: '50', VALUE: 50},
                    {TEXT: '250', VALUE: 250},
                    {TEXT: '500', VALUE: 500},
                    {TEXT: 'Todos', VALUE: 1000000}
                ],
            }
        },
        created() {
            this.loading = true;
            this.getProducts();
            // this.openDialogProducts();
        },
        methods: {
            getProducts() {
                let request = {url: api.b2bCampaigns() + '/' + this.$route.params.id + '/products'};
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.products = responseData.products;
                            this.filterProducts();
                        }
                        this.loading = false;
                        this.saving = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            toogleEditProducts() {
                this.addProducts = false;
                this.editProducts = !this.editProducts;
            },
            filterProducts() {
                this.itemsFiltered = this.products;
                if (this.filters.Artigo !== '') {
                    this.itemsFiltered = this.itemsFiltered.filter(item => item.Artigo.toUpperCase().includes(this.filters.Artigo.toUpperCase()))
                }
                if (this.filters.Descricao !== '') {
                    this.itemsFiltered = this.itemsFiltered.filter(item => item.Descricao.toUpperCase().includes(this.filters.Descricao.toUpperCase()))
                }
                if (this.filters.Marca !== '') {
                    this.itemsFiltered = this.itemsFiltered.filter(item => item.Marca.toUpperCase().includes(this.filters.Marca.toUpperCase()))
                }
                if (this.filters.Familia !== '') {
                    this.itemsFiltered = this.itemsFiltered.filter(item => item.Familia.toUpperCase().includes(this.filters.Familia.toUpperCase()))
                }
                if (this.filters.SubFamilia !== '') {
                    this.itemsFiltered = this.itemsFiltered.filter(item => item.SubFamilia.toUpperCase().includes(this.filters.SubFamilia.toUpperCase()))
                }
                if (this.filters.Ano !== '') {
                    this.itemsFiltered = this.itemsFiltered.filter(item => item.Ano.toUpperCase().includes(this.filters.Ano.toUpperCase()))
                }
                this.items = this.itemsFiltered.slice(0, 100);
                this.items = this.itemsFiltered;
            },
            deleteProduct(mode, code) {
                this.selectedProduct = code;
                this.saving = true;
                let request = {
                    url: api.b2bCampaignsDeleteProduct(),
                    data: {
                        campaign_id: this.id,
                        mode: mode,
                        product: code,
                        products: this.itemsFiltered
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.getProducts();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Produto removido com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao remover o produto!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            openDialogProducts() {
                this.modalProductsPri = true;
                this.getProductsPri();
            },
            getProductsPri() {
                this.loading = true;
                this.productsPri = [];
                let request = {
                    url: api.b2bCampaignsGetProducts(),
                    data: {
                        pagination: this.pagination,
                        filters: this.filtersModal
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.productsPri = responseData.products;
                            this.totalItems = responseData.totalProducts;
                            this.pagination.pages = Math.ceil(this.totalItems / this.pagination.per_page);
                            this.selected = 0;
                        }
                        this.loading = false;
                    })
            },
            updateTableProductsPri() {
                this.pagination.page = 1;
                this.getProductsPri();
            },
            filterTableProductsPri() {
                this.getProductsPri();
            },
            selectProduct(code) {
                this.selected = 0;
                this.productsPri.forEach((product) => {
                    if (product.Artigo === code) {
                        product.selected = !product.selected;
                    }
                    if (product.selected) {
                        this.selected++;
                    }
                });
            },
            selectAll() {
                this.selected = 0;
                this.productsPri.forEach((product) => {
                    product.selected = true;
                    this.selected++;
                });
            },
            unselectAll() {
                this.selected = 0;
                this.productsPri.forEach((product) => {
                    product.selected = false;
                });
            },
            resetFilters() {
                this.filtersModal = {
                    productId: '',
                    barcode: '',
                    description: '',
                    brand: '',
                    family: '',
                    subFamily: '',
                    year: '',
                    stock: ''
                };
                this.updateTableProductsPri();
            },
            addProductsPri() {
                this.saving = true;
                let request = {
                    url: api.b2bCampaignsAddProducts(),
                    data: {
                        campaign_id: this.id,
                        products: this.productsPri
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.getProductsPri();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Produtos adicionados com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao adicionar os produtos!',
                                type: 'error'
                            })
                        }
                        this.saving = false;
                    })
                    .catch(() => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            closeModalProductsPri() {
                this.modalProductsPri = false;
                this.getProducts();
            }
        }
    }
</script>
