<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="$t('interactions')+' CRM'"
                        :subtitle="'A mostrar '+interactions.length+' de '+totalInteractions"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-col class="text-right">
                    <v-btn tile small class="ml-2 mb-2" @click="clearDates()" color="error">Limpar Filtros</v-btn>
                </v-col>
            </v-col>
        </v-row>
        <!--        <v-row>-->
        <!--            <v-col cols="12" sm="6" md="3">-->
        <!--                <v-text-field-->
        <!--                        v-model="inputStartDate"-->
        <!--                        label="Data Inicio"-->
        <!--                        outlined-->
        <!--                        readonly-->
        <!--                ></v-text-field>-->
        <!--            </v-col>-->
        <!--            <v-col cols="12" sm="6" md="3">-->
        <!--                <v-text-field-->
        <!--                        v-model="inputEndDate"-->
        <!--                        label="Data Fim"-->
        <!--                        outlined-->
        <!--                        readonly-->
        <!--                ></v-text-field>-->
        <!--            </v-col>-->
        <!--            <v-col>-->
        <!--               <v-btn @click="dialogAddSchedule = true">Mudar Datas</v-btn>-->
        <!--            </v-col>-->
        <!--        </v-row>-->
        <v-row>
            <v-col cols="12" sm="6" md="3">
                <v-menu
                        v-model="menuStartDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="inputStartDate"
                                label="Data Início"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="inputStartDate"
                            @input="menuStartDate = false; setDates()"
                    ></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3">
                <v-menu
                        v-model="menuEndDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="inputEndDate"
                                :label="'Data ' + $t('end')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"

                        ></v-text-field>
                    </template>
                    <v-date-picker
                            v-model="inputEndDate"
                            @input="menuEndDate = false; setDates()"
                    ></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>ID do Cliente</th>
                            <th>Nome do Cliente</th>
                            <th>{{$t('address1')}}</th>
                            <th>ID do Comercial</th>
                            <th>Comercial</th>
                            <th>Tipo de {{$t('shop')}}</th>
                            <th>{{$t('sale')}}</th>
                            <th>{{$t('situation')}} {{$t('financial2')}}</th>
                            <th>{{$t('opinion')}} do Cliente</th>
                            <th>Motivo</th>
                            <th>Marcas</th>
                            <th>Oportunidades de Mercado</th>
                            <th>{{$t('marketTendencies')}}</th>
                            <th>{{$t('observations')}}</th>
                            <th>Data</th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                        v-model="filters.clientId"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.clientName"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="setFilters"
                                ></v-text-field>
                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <td>

                            </td>
                            <td>
                                <v-select
                                        v-model="filters.shopType"
                                        :items="shopTypeFilters"
                                        dense
                                        outlined
                                        hide-details
                                        @change="setFilters"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.anySell"
                                        :items="yesOrNoFilters"
                                        dense
                                        outlined
                                        hide-details
                                        @change="setFilters"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.financialSituation"
                                        :items="goodOrBadFilters"
                                        dense
                                        outlined
                                        hide-details
                                        @change="setFilters"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                >
                                    <!--                                <v-select-->
                                    <!--                                        v-model="filters.financialSituation"-->
                                    <!--                                        :items="goodOrBadFilters"-->
                                    <!--                                        dense-->
                                    <!--                                        outlined-->
                                    <!--                                        hide-details-->
                                    <!--                                        @change="setFilters"-->
                                    <!--                                        item-value="VALUE"-->
                                    <!--                                >-->
                                    <!--                                    <template v-slot:item="{ item }">-->
                                    <!--                                        {{$t('bad')}}-->
                                    <!--                                    </template>-->
                                    <!--                                    <template v-slot:selection="{ item }">-->
                                    <!--                                        {{ item.TEXT }}-->
                                    <!--                                    </template>-->
                                </v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.opinion"
                                        :items="goodOrBadFilters"
                                        dense
                                        outlined
                                        hide-details
                                        @change="setFilters"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.motive"
                                        :items="motives"
                                        dense
                                        outlined
                                        hide-details
                                        @change="setFilters"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.brand"
                                        :items="brandsList"
                                        dense
                                        outlined
                                        hide-details
                                        @change="setFilters"
                                ></v-select>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="15">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="interaction in interactions">
                            <td>{{ interaction.clientId }}</td>
                            <td>{{ interaction.clientName }}</td>
                            <td>{{ interaction.Address }}</td>
                            <td>{{ interaction.userId }}</td>
                            <td>{{ interaction.Nome }}</td>
                            <td><span v-if="interaction.shopType == 0">{{$t('small')}}</span>
                                <span v-if="interaction.shopType == 1">{{$t('medium')}}</span>
                                <span v-if="interaction.shopType == 2">{{$t('big')}}</span></td>
                            <td><span v-if="interaction.anySell === 0"><i class="fas fa-times fa-fw" style="color: red"></i>{{$t('no')}}</span>
                                <span v-if="interaction.anySell === 1"><i class="fas fa-check fa-fw" style="color: green"></i>{{$t('yes')}}</span></td>
                            <td><span v-if="interaction.financialSituation === 0"><i class="fas fa-times fa-fw" style="color: red"></i>{{$t('bad')}}</span>
                                <span v-if="interaction.financialSituation === 1"><i class="fas fa-check fa-fw" style="color: green"></i>{{$t('good')}}</span></td>
                            <td><span v-if="interaction.opinion === 0"><i class="fas fa-times fa-fw" style="color: red"></i>{{$t('bad')}}</span>
                                <span v-if="interaction.opinion === 1"><i class="fas fa-check fa-fw" style="color: green"></i>{{$t('good')}}</span></td>
                            <td>{{ getMotiveText(interaction.motive) }}</td>
                            <td>{{ formatBrandsSold(interaction.brands_sold) }}</td>
                            <td>{{ interaction.marketOpportunities }}</td>
                            <td>{{ interaction.marketTendencies }}</td>
                            <td>{{ interaction.observations }}</td>
                            <td>{{ interaction.pickedDate }}</td>
                            <!--                            <td class="text-right">{{ $money(sale.YEAR_N_2) }}</td>-->
                            <!--                            <td class="text-right">{{ $money(sale.YEAR_N_1) }}</td>-->
                            <!--                            <td class="text-right">{{ $money(sale.YEAR_N) }}</td>-->
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="initialize"
                ></v-pagination>
            </v-col>
            <v-col>
                <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        outlined
                        single-line
                        @change="changeSelect"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="width: 90px; margin-left: auto;"
                        class="staff_select mb-0"
                        hide-details
                ></v-select>
            </v-col>
        </v-row>

        <!--SCHEDULE DIALOG-->
        <v-dialog persistent width="800" v-model="dialogAddSchedule">
            <v-card>
                <v-card-title class="headline darken-1">
                    Filtro de Datas
                </v-card-title>
                <v-card-text>
                    <v-row justify="center" class="ml-4 mt-1">
                        <v-col>
                            <h3>Data de início</h3>
                            <v-date-picker v-model="inputStartDate" min="2023-01-01" elevation="5" locale="pt-pt"></v-date-picker>
                        </v-col>
                        <v-col>
                            <h3>Data de fim</h3>
                            <v-date-picker v-model="inputEndDate" :min="inputStartDate" elevation="5" locale="pt-pt"></v-date-picker>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="clearDates()">Apagar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="setDates()"> Guardar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'

export default {
    data: () => ({
        filters: {
            salesman_id: '',
            clientId: '',
            clientName: '',
            shopType: '',
            anySell: '',
            financialSituation: '',
            opinion: '',
            motive: '',
            brand: '',
            startingDate: '',
            endingDate: ''
        },
        shopTypeFilters: [
            {VALUE: '',TEXT: "Todos"},
            {VALUE: 0,TEXT: "Pequena"},
            {VALUE: 1,TEXT: "Média"},
            {VALUE: 2,TEXT: "Grande"},
        ],
        yesOrNoFilters: [
            {VALUE: '',TEXT: "Todos"},
            {VALUE: 0,TEXT: i18n.t('no')},
            {VALUE: 1,TEXT: i18n.t('yes')},
        ],
        goodOrBadFilters: [
            {VALUE: '',TEXT: "Todos"},
            {VALUE: 0,TEXT: i18n.t('bad'),},
            {VALUE: 1,TEXT: i18n.t('good')},
        ],
        motives: [
            {VALUE: 0,TEXT: "Sem Motivo",},
            {VALUE: 1,TEXT: "Abertura Cliente"},
            {VALUE: 2,TEXT: i18n.t('routine')},
            {VALUE: 3,TEXT: i18n.t('campaign')},
            {VALUE: 4,TEXT: i18n.t('planning')},
        ],
        brandsList: [
            "FOX",
            "CLASSIFIED",
            "CEMA",
            "CERVELO",
            "EASTON",
            "EVOC",
            "GT",
            "KENDA",
            "KMC",
            "NAMEDSPORT",
            "RACEFACE",
            "RIDECONCEPTS",
            "RIDEMAX",
            "SANTACRUZ",
            "TAYA",
            "TECMOUSSE",
            "UDOG"
        ],
        loading: false,
        menuStartDate: false,
        menuEndDate: false,
        dialogAddSchedule: false,
        inputStartDate: '',
        inputEndDate: '',
        startDate: '',
        endDate: '',
        interactions: [],
        pagination: {
            page: 1,
            per_page: 250
        },
        totalInteractions: 0,
        filtersPerPage: [
            {TEXT: '100', VALUE: 100},
            {TEXT: '250', VALUE: 250},
            {TEXT: '500', VALUE: 500},
            {TEXT: '750', VALUE: 750}
        ]
    }),
    created() {
        document.title = i18n.t('interactions') + ' Crm'
        this.initialize()
    },
    methods: {
        initialize() {
            this.loading = true
            this.interactions = []

            this.filters.startingDate = this.startDate
            this.filters.endingDate = this.endDate

            let request = {
                url: '/api/staff/commercial/interactions',
                data: {
                    filters: this.filters,
                    pagination: this.pagination
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.interactions = responseData.interactions.data
                            this.totalInteractions = responseData.totalInteractions
                            this.loading = false
                        } else {
                            this.loading = false
                        }
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        getMotiveText(value) {
            const motive = this.motives.find(m => m.VALUE === value);
            return motive ? motive.TEXT : 'Unknown';
        },
        formatBrandsSold(brands) {
            return brands ? brands.join(', ') : '';
        },
        setDates(){
            this.startDate = this.inputStartDate
            this.endDate = this.inputEndDate
            this.dialogAddSchedule = false
            this.changeSelect()
        },
        clearDates(){
            this.startDate = ''
            this.endDate = ''
            this.inputStartDate = ''
            this.inputEndDate = ''
            this.dialogAddSchedule = false
            this.filters = {
                salesman_id: '',
                clientId: '',
                clientName: '',
                shopType: '',
                anySell: '',
                financialSituation: '',
                opinion: '',
                motive: '',
                brand: '',
                startingDate: '',
                endingDate: ''
            }
            this.changeSelect()
        },
        setFilters() {
            this.pagination.page = 1;
            this.initialize();
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        download() {
            Reports.dispatch('getSalesClientYearDownload', this.filters)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/csv'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'Report_Client_Year_' + this.filters.salesman_id + '_' + '.csv';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    })
                    .catch(response => {

                    })
        },
        year(last) {
            return new Date().getFullYear() - last
        },
        changeSelect() {
            this.pagination.page = 1
            this.initialize()
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        }
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.totalInteractions / this.pagination.per_page)
        }
    }
}
</script>
