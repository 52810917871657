<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title title="Banners B2B"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small color="success" class="ml-3" @click="toggleDialogAddBanner()">
                    {{ $t('addItem') }}
                </v-btn>
                <v-btn tile small color="primary" class="ml-3" @click="getBanners">
                    Actualizar
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-row>
                    <v-col>
                        <v-tabs background-color="transparent" slider-color="rgba(192, 13, 13, 1)"
                                color="rgba(192, 13, 13, 1)">
                            <v-tab @click="activeTab = 1; pickTypes()"
                                   :style="[activeTab === 1 ? {'background-color': 'white'} : '']">
                                Slider
                            </v-tab>
                            <v-tab @click="activeTab = 2; pickTypes()"
                                   :style="[activeTab === 2 ? {'background-color': 'white'} : '']">
                                1 Image
                            </v-tab>
                            <v-tab @click="activeTab = 3; pickTypes()"
                                   :style="[activeTab === 3 ? {'background-color': 'white'} : '']">
                                3 Image
                            </v-tab>
                            <v-tab @click="activeTab = 4; pickTypes()"
                                   :style="[activeTab === 4 ? {'background-color': 'white'} : '']">
                                6 Grid
                            </v-tab>
                        </v-tabs>
                    </v-col>
                    <v-col>
                        <div style="text-align: right">
                            <v-btn tile small :color="activeOrInactiveBanners===1 ? 'primary' : ''"
                                   @click="activeOrInactiveBanners=1;">Ativos
                            </v-btn>
                            <v-btn tile small :color="activeOrInactiveBanners===0 ? 'primary' : ''"
                                   @click="activeOrInactiveBanners=0;">Inativos
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
                <div v-if="filteredBanners.length > 0 && canShow">
                    <v-row v-for="location in bannerLocations"
                           v-if="activeOrInactiveBanners ? location.totalActives > 0 : location.totalInactives > 0"
                           v-bind:key="location.id">
                        <v-col cols="12">
                            <v-row>
                                <v-col>
                                    <!--                                    {{ filteredBanners.filter( (banner) => {banner.locationId === location.id}) }}-->
                                    <h2 style="text-align: center">Page: {{ location.page }} -- {{
                                            location.position
                                        }}</h2>
                                </v-col>
                            </v-row>
                            <v-row v-for="brand in location.brands" v-bind:key="brand.brand">
                                <v-card v-if="activeOrInactiveBanners ? brand.active > 0 : brand.inactive > 0"
                                        class="mb-4" style="width: 100%; margin: 0 20px" color="white">

                                    <!--                                        <v-toolbar flat dense dark color="blue-grey darken-1">-->
                                    <v-toolbar flat dense dark color="#db261b">
                                        <v-toolbar-title class="text-uppercase">{{ brand.brand }}</v-toolbar-title>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-row>
                                            <v-col cols="12" md="3" v-for="banner in filteredBanners"
                                                   v-if="banner.locationId === location.id && banner.brand == brand.brand && forceRender === false && banner.active == activeOrInactiveBanners"
                                                   v-bind:key="banner.id">
                                                <v-card color="#ddd" v-if="banner.deleted_at == null"
                                                        class="mt-4 "
                                                        max-width="374" elevation="4">
                                                    <!--                        <v-card color="#ddddce" width="700" v-if="banner.deleted_at == null"
                                                                                class="ma-4 ml-0 pa-2 transition-swing" elevation="8">-->
                                                    <v-img v-if="banner.images.length > 0"
                                                           :src="'https://static.bicimax.com/bannersV2/' + (banner.images[0].device == 'desktop' ? banner.images[0].file : banner.images[1].file)"

                                                           @click="showImage(banner.images[0].device == 'desktop' ? banner.images[0].file : banner.images[1].file)"
                                                           contain
                                                    ></v-img>
                                                    <v-card-title>
                                                        {{ banner.name }}
                                                        <v-spacer></v-spacer>
                                                        <v-btn tile small color="success" class="ml-3 mr-2"
                                                               :to="'BannersB2B/'+ banner.id">
                                                            Editar
                                                        </v-btn>
                                                        <v-btn tile small color="error" class="mr-2"
                                                               @click="toggleDelete(banner.id)">
                                                            Apagar
                                                        </v-btn>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <!--                            <h4>Name: {{ banner.name }}</h4>-->
                                                        <v-row>
                                                            <v-col class="col-md-8">
                                                                <h4>Page: {{ banner.page }}</h4>
                                                                <h4>Position: {{ banner.position }}</h4>
                                                                <h4>Brand: {{ banner.brand }}</h4>
                                                                <h4>Order: {{ banner.order }}</h4>
                                                            </v-col>
                                                            <v-col class="col-md-4"
                                                                   style="display: flex; justify-content: end;align-items: end">
                                                                <h5 style="background-color: #333; color: white; border-radius: 5px; padding: 3px 6px; display: flex; align-items: center;">
                                                                    {{ banner.clicks ? banner.clicks : '0' }}
                                                                    <span class="mdi mdi-cursor-default-click" style="color: red; font-size: 15px;padding-left: 2px"></span>
                                                                    <br>
                                                                </h5>
                                                            </v-col>
                                                        </v-row>

                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                </v-card>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <!--        <div class="staff_v-card"  v-if="banners.length > 0 && this.canShow">
                    <v-row>
                        <v-col cols="12" md="3" v-for="banner in banners" v-bind:key="banner.id">
                            <v-card color="#ddddce" v-if="banner.deleted_at == null"
                                    class="mt-4 rounded-card"
                                    max-width="374" elevation="8">
        &lt;!&ndash;                        <v-card color="#ddddce" width="700" v-if="banner.deleted_at == null"
                                    class="ma-4 ml-0 pa-2 transition-swing" elevation="8">&ndash;&gt;
                                <v-img v-if="banner.images.length > 0"
                                       :src="'https://static.bicimax.com/bannersV2/'+banner.images[0].file"

                                       @click="showImage(banner.images[0].file)"
                                       contain
                                ></v-img>
                                <v-card-title>
                                    {{ banner.name }}
                                    <v-spacer></v-spacer>
                                    <v-btn tile small color="success" class="ml-3 mr-2" :to="'BannersB2B/'+ banner.id">
                                        Editar
                                    </v-btn>
                                    <v-btn tile small color="error" class="mr-2" @click="toggleDelete(banner.id)">
                                        Apagar
                                    </v-btn>
                                </v-card-title>
                                <v-card-text>
        &lt;!&ndash;                            <h4>Name: {{ banner.name }}</h4>&ndash;&gt;
                                    <h4>Page: {{ banner.page }}</h4>
                                    <h4>Position: {{ banner.position }}</h4>
                                    <h4>Brand: {{ banner.brand }}</h4>
                                    <h4>Order: {{ banner.order }}</h4>
                                    <h4 v-if="banner.clicks">Clicks: {{ banner.clicks }}</h4>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>-->
        <!--        ADD BANNER DIALOG-->
        <v-dialog persistent v-model="dialogAddBanner" max-width="900">
            <v-card>
                <v-card-title class="headline darken-1">
                    Adicionar Banner
                </v-card-title>
                <v-card-text>
                    <v-form v-model="formValid" ref="myForm">
                        <!--                        <v-text-field
                                                        v-model="brandToAdd"
                                                        dense
                                                        outlined
                                                        label="Marca"
                                                        hide-details
                                                        class="pl-2 pr-2 pb-6 pt-1"
                                                        style="max-width: 99.5%"
                                                ></v-text-field>-->
                        <v-select
                                :items="bannerLocations"
                                outlined
                                single-line
                                :rules="rules.required"
                                v-model="selectedLocation"
                                item-value="id"
                                class="mt-2 ml-2"
                                label="Localização"
                                dense
                                style="max-width: 98%"
                        >
                            <template v-slot:item="{ item }">
                                Página: {{ item.page }} --- Posição: {{ item.position }}
                            </template>
                            <template v-slot:selection="{ item }">
                                Página: {{ item.page }} --- Posição: {{ item.position }}
                            </template>
                        </v-select>
                        <v-select
                                :items="bannerTypes"
                                outlined
                                single-line
                                item-value="id"
                                item-text="description"
                                :rules="rules.required"
                                v-model="selectedType"
                                class="mt-2 ml-2"
                                label="Tipo de Banner"
                                dense
                                style="max-width: 98%"
                        >
                        </v-select>
                        <!--                        <v-select v-if="selectedLocation == 1002"
                                                        :items="brands"
                                                        outlined
                                                        single-line
                                                        item-value="VALUE"
                                                        item-text="TEXT"
                                                        v-model="brandToAdd"
                                                        class="mt-2 ml-2"
                                                        label="Marca"
                                                        dense
                                                        style="max-width: 98%"
                                                >
                                                </v-select>-->
                        <v-select
                                :items="brands"
                                outlined
                                single-line
                                item-value="VALUE"
                                item-text="VALUE"
                                v-model="brandToAdd"
                                class="mt-2 ml-2"
                                label="Marca"
                                dense
                                style="max-width: 98%"
                        >
                        </v-select>
                        <v-text-field
                                v-model="orderToAdd"
                                dense
                                outlined
                                label="Order"
                                hide-details
                                class="pl-2 pr-2 pb-6 pt-1"
                                style="max-width: 99.5%"
                        ></v-text-field>
                        <v-text-field
                                v-model="linkToAdd"
                                dense
                                outlined
                                label="Link"
                                hide-details
                                class="pl-2 pr-2 pb-6 pt-1"
                                style="max-width: 99.5%"
                        ></v-text-field>
                        <v-checkbox label="Ativo" v-model="activeOption" hide-details></v-checkbox>
                        <v-checkbox label="Nova Janela" v-model="newWindowOption" hide-details></v-checkbox>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogAddBanner = false">Fechar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addNewBanner()">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--        ADD BANNER DIALOG-->

        <v-dialog v-model="dialogShowImage" max-width="870">
            <v-img
                    :src="'https://static.bicimax.com/bannersV2/'+imageSelected"
                    max-height="870"
                    max-width="870"
                    contain
            ></v-img>
        </v-dialog>

        <!--        Delete Banner DIALOG-->
        <v-dialog v-model="deleteDialog" overlay-color="white" overlay-opacity="0.5" persistent max-width="470">
            <v-card>
                <v-card-title class="red--text">
                    Tem a certeza que quer apagar este banner?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="error" @click="deleteDialog = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="deleteBanner()">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import banners from "@/components/B2B/Banners.vue";

export default {
    name: "BannersB2B",
    data: function () {
        return {
            activeOrInactiveBanners: 1,
            activeBrand: '',
            activeOption: null,
            brandToAdd: null,
            orderToAdd: 1,
            activeTab: 1,
            filteredBanners: [],
            brandsList: [],
            brands: [],
            linkToAdd: null,
            newWindowOption: false,
            banners: [],
            logs: [],
            images: [],
            imageSelected: '',
            bannerAux: {
                active: false,
            },
            bannerTypes: [],
            bannerLocations: [],
            brandsToShow: [],
            loadComplete: false,
            selectedLocation: null,
            selectedType: null,
            bannerToAdd: [],
            dialogAddBanner: false,
            dialogSwitchBanner: false,
            dialogShowImage: false,
            deleteDialog: false,
            idToDelete: null,
            formValid: false,
            canShow: false,
            forceRender: false,
            rules: {
                required: [value => !!value || "Required."]
            },
            brandsCounter: []
        }
    },
    created() {
        document.title = 'Banners | STAFF';
        this.getBanners();
    },
    methods: {
        differentBrand(brand) {
            // console.log(brand)
            if (this.activeBrand != brand) {
                this.activeBrand = brand;
                return true;
            }
            return false;
        },
        getBanners() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2B'
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.banners = responseData.banners;
                            this.brands = responseData.brands;
                            this.brands.splice(0, 0, {TEXT: 'ALL', VALUE: 'ALL'})
                            //console.log(this.brands)
                            this.banners.forEach(banner => {
                                if (banner.images == null) {
                                    banner.images = [];
                                }
                            })
                            // for (let i = 0; i < this.banners.length; i++) {
                            //     this.banners[i].clicks = 0;
                            //     for (let j = 0; j < this.logs.length; j++) {
                            //
                            //         console.log(this.banners[i].id + '--> ' + this.logs[j].id)
                            //         if (this.banners[i].id == this.logs[j].bannerId) {
                            //             this.banners[i].clicks = parseInt(this.banners[i].clicks) + parseInt(this.logs[j].clicks);
                            //         }
                            //     }
                            // }
                            // console.log(this.banners)

                            this.getBannerClicks();
                            this.getBannerTypes();
                            this.getBannerLocations();

                            this.canShow = true;
                            loader.hide();
                            //for (let i = 0; i < this.banners.length; i++) {
                            //this.getBannerImages(this.banners[i].id);
                            // this.banners[i].arrayID = i;
                            //}
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
                loader.hide();
            })
            loader.hide();
        },
        pickTypes() {
            let type = '';
            this.filteredBanners = [];
            this.bannerLocations.forEach(l => {
                l.counter = 0
                l.brands = []
            })
            switch (this.activeTab) {
                case 1:
                    type = 'slider'
                    break;
                case 2:
                    type = '1image'
                    break;
                case 3:
                    type = '3image'
                    break;
                case 4:
                    type = '6grid'
                    break;
                default:
                    type = 'slider'
            }

            this.banners.forEach(bn => {
                if (bn.name === type) {
                    this.filteredBanners.push(bn)
                }
            })
            this.filteredBanners.forEach(fb => {
                this.bannerLocations.forEach(l => {
                    if (fb.locationId === l.id) {
                        l.brands.push(fb.brand)
                        l.counter = 1
                    }
                })
            })
            //   console.log(this.bannerLocations)
            const unique = [...new Set(this.filteredBanners.map((banner) => banner.brand))];
            this.brandsList = unique;
            //console.log("ja vvou!")
            let brandsAux = [];
            // console.log(this.bannerLocations)
            this.bannerLocations.forEach(bl => {

                // [...new Set(bl.brands.map((location) => location))].forEach( brand => {
                //     console.log(brand)
                //     brandsAux.push({name: brand , active: 0, inactive: 0})
                // })
                // bl.brands = brandsAux;
                bl.brands = [...new Set(bl.brands.map((location) => location))];
                // bl.brands.active = 0
                // bl.brands.inactive = 0
            })
            //console.log('Newfor')
            for (let i = 0; i < this.bannerLocations.length; i++) {
                // console.log(this.bannerLocations[i].brands)
                this.bannerLocations[i].brands
            }
            //    console.log(this.bannerLocations)
            // console.log("brandsAux")
            // console.log(brandsAux)
            this.filteredBanners.sort(function (a, b) {
                if (a.order < b.order) {
                    return -1;
                }
                if (a.order > b.order) {
                    return 1;
                }
                return 0;
            });


            // this.filteredBanners.forEach(fb => {
            //
            // });
            // console.log("Couves!");
            // console.log(this.filteredBanners)
            // console.log(this.bannerLocations)

            for (let i = 0; i < this.bannerLocations.length; i++) {
                //  quando chamas a função, devolve um array com o numero de banners activos e inactivos,
                //  correspondentes á posição passada
                this.bannerLocations[i].brands = this.activeBrandBanners(this.filteredBanners, this.bannerLocations[i].id);

                this.bannerLocations[i].totalActives = 0
                this.bannerLocations[i].totalInactives = 0
                for (let j = 0; j < this.bannerLocations[i].brands.length; j++) {
                    this.bannerLocations[i].totalActives += this.bannerLocations[i].brands[j].active
                    this.bannerLocations[i].totalInactives += this.bannerLocations[i].brands[j].inactive
                }


            }
            //this.activeBrandBanners(this.filteredBanners, this.bannerLocations.id);

        },
        deleteBanner() {
            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.idToDelete
            }

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            this.getBanners();
                            this.getBannerTypes();
                            this.getBannerLocations();
                            this.pickTypes();
                            this.getBanners();
                            this.getBannerTypes();
                            this.getBannerLocations();
                            this.pickTypes();

                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.deleteDialog = !this.deleteDialog;
        },
        toggleDelete(id) {
            this.deleteDialog = !this.deleteDialog;
            this.idToDelete = id;
        },
        getBannerTypes() {
            this.loading = true
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/b2b/banners-type'
            }
            var spanishLines = []
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            responseData.bannerTypes.forEach(aux => {
                                if (aux.language === null) {
                                    this.bannerTypes.push({
                                        'name': aux.name,
                                        'description': aux.name,
                                        'id': aux.id
                                    })
                                }
                                if (aux.language !== null && aux.language.toUpperCase() === User.getters.getLanguage.toUpperCase()) {
                                    this.bannerTypes.push({
                                        'name': aux.name,
                                        'description': aux.description,
                                        'id': aux.id
                                    })
                                }
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        getBannerLocations() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/banners-location'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.bannerLocations = responseData.bannerLocations;
                            for (let i = 0; i < this.bannerLocations.length; i++) {
                                if (this.bannerLocations[i].deleted_at != null) {
                                    this.bannerLocations.splice(i--, 1);
                                }
                            }
                            this.pickTypes();
                            // console.log(this.bannerLocations);
                            // console.log(this.filteredBanners);
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
            // console.log(this.bannerLocations)
        },
        getBannerClicks() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2B/logs'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            // console.log('logs')
                            this.logs = responseData.bannersLogs;

                            for (let i = 0; i < this.banners.length; i++) {
                                this.banners[i].clicks = 0;
                                for (let j = 0; j < this.logs.length; j++) {
                                    if (this.banners[i].id == this.logs[j].bannerId) {
                                        this.banners[i].clicks = parseInt(this.banners[i].clicks) + parseInt(this.logs[j].clicks);
                                    }
                                }
                            }

                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        getBannerImages(bannerId) {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2B/' + bannerId + '/images'
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (response.status === 200) {
                            //this.images[bannerId] = responseData.images
                            this.loadComplete = true;
                            loader.hide();
                            this.loading = false
                        }
                        if (responseData.status === "NOK") {
                            loader.hide();
                            this.loading = false
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
                loader.hide();
                this.loading = false
            })
        },
        toggleDialogAddBanner() {
            this.brandToAdd = '';
            this.dialogAddBanner = !this.dialogAddBanner;
        },
        addNewBanner() {
            if (this.$refs.myForm.validate()) {
                var auxActive = null
                var auxNewWindow = null
                this.dialogAddBanner = false
                if (this.activeOption) {
                    auxActive = 1;
                } else {
                    auxActive = 0;
                }

                if (this.newWindowOption) {
                    auxNewWindow = 1;
                } else {
                    auxNewWindow = 0;
                }
                let request = {
                    url: '/api/staff/b2b/bannersB2B',
                    data: {
                        banner: {
                            typeId: this.selectedType,
                            locationId: this.selectedLocation,
                            active: auxActive,
                            order: this.orderToAdd,
                            newWindow: auxNewWindow

                        },
                    }
                }
                if (this.brandToAdd) {
                    request.data.banner.brand = this.brandToAdd;
                }
                if (this.linkToAdd) {
                    request.data.banner.link = this.linkToAdd;
                }

                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            this.forceRender = true;
                            if (responseData.status === "OK") {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Banner adicionado com sucesso!',
                                    type: 'success'
                                })
                                // this.banners = [];
                                // this.filteredBanners = [];
                                // this.bannerLocations = [];
                                // this.bannerTypes = [];
                                // this.brands = [];


                                this.getBanners();
                                this.getBannerTypes();
                                this.getBannerLocations();
                                this.pickTypes();
                                this.getBanners();
                                this.getBannerTypes();
                                this.getBannerLocations();
                                this.pickTypes();


                                this.forceRender = false;

                                this.brandToAdd = '';
                                this.selectedType = '';
                                this.selectedLocation = '';
                                this.orderToAdd = 1;
                                this.linkToAdd = '';
                                this.activeOption = false;
                                this.newWindowOption = false;

                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        showImage(image) {
            this.imageSelected = image
            this.dialogShowImage = true
        },

        activeBrandBanners(banners, positionID) {
            //banners recebido vai ser = ao this.filteredBanners
            let bannersBrandCounter = [];
            let brandsPosition = [];
            this.brandsToShow = [];

            // console.log(this.bannerLocations)
            // console.log('banners -> ' + banners + ' //positionId->' + positionID);

            //obter marcas da localização
            for (let i = 0; i < this.bannerLocations.length; i++) {
                if (this.bannerLocations[i].id == positionID) {

                    if (this.bannerLocations[i].brands.length > 0) {

                        for (let j = 0; j < this.bannerLocations[i].brands.length; j++) {
                            brandsPosition.push({brand: this.bannerLocations[i].brands[j]})

                            //Criar já array que vai ser para devolver
                            //injetar no array marcas, as marcas por posição
                            bannersBrandCounter.push({brand: this.bannerLocations[i].brands[j], active: 0, inactive: 0})
                        }
                    }
                }
            }
            /**
             * [
             *  {brand: "FOX", counter: 0}
             *  {brand: "EASTON", counter: 0}
             *  {brand: "SMANIE", counter: 0}
             * ]
             **/


            for (let i = 0; i < banners.length; i++) {

                for (let j = 0; j < brandsPosition.length; j++) {
                    //fazer uppercase
                    if (banners[i].brand == brandsPosition[j].brand) {
                        // console.log(banners[i].brand + ' - vlaidas -' + brandsPosition[j].brand)

                        //Banner da marca activo
                        if (banners[i].active) {
                            for (let k = 0; k < bannersBrandCounter.length; k++) {
                                //Validamos em que objeto(Marca) temos aumentar o counter
                                if (brandsPosition[j].brand == bannersBrandCounter[k].brand) {
                                    // console.log("Oxxi 1")
                                    bannersBrandCounter[k].active = bannersBrandCounter[k].active + 1;
                                }
                            }
                        }
                        //Banner da marca inativo
                        else {
                            for (let k = 0; k < bannersBrandCounter.length; k++) {
                                //Validamos em que objeto(Marca) temos aumentar o counter
                                if (brandsPosition[j].brand == bannersBrandCounter[k].brand) {
                                    // console.log("Oxxi 2")

                                    bannersBrandCounter[k].inactive = bannersBrandCounter[k].inactive + 1;
                                }
                            }
                        }
                    }
                }


            }


            // console.log(bannersBrandCounter);
            return bannersBrandCounter;
            // this.brandsToShow = bannersBrandCounter;

        }

    }
}
</script>

<style scoped>

.rounded-card {
    border-radius: 15px;
}

</style>