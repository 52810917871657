<template>
    <v-container fluid class="pt-0">
        <v-row class="content-header">
            <v-col cols="12" md="4">
                <page-title :title="$t('products') +' Planet'" :subtitle="'A mostrar '+productsPlanet.length+' de '+totalItemsPlanet+' artigo(s)'"></page-title>
            </v-col>
            <v-col class="text-right" align-self="center">
                <v-btn small tile color="primary" class="mr-3" :disabled="productsToAddPlanetToBicimax.length === 0" @click="toggleDialogCreateGTAmazon()">
                    GT Amazon ({{ productsToAddPlanetToBicimax.length }})
                </v-btn>
<!--                <v-btn small tile color="error" class="mr-3" :disabled="productsToAddPlanetToBicimax.length === 0">-->
<!--                    {{ $t('remove') }} {{ $t('products') }} ({{ productsToAddPlanetToBicimax.length }}) Planet-->
<!--                </v-btn>-->
                <v-btn small tile color="success" class="mr-3" @click="toggleDialogProductToPlanet()">{{ $t('add') }}
                    {{ $t('products') }}
                </v-btn>
                <v-btn small tile color="error" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr class="titles_row">
                        <th width="1"></th>
                        <th>{{ $t('product') }}</th>
                        <th>{{ $t('description') }}</th>
                        <th>Stock</th>
                    </tr>
                    <tr class="filters_row">
                        <td>
                            <span style="cursor: pointer; margin-left: 2px">
                                <i v-if="!checkboxAllPlanet" class="far fa-square fa-lg" @click="selectAllPlanet()"></i>
                                <i v-if="checkboxAllPlanet" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="unselectAllPlanet()"></i>
                            </span>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filtersPlanet.productId"
                                    outlined
                                    dense
                                    hide-details
                                    @keypress.enter="search('productId')"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filtersPlanet.description"
                                    outlined
                                    dense
                                    hide-details
                                    @keypress.enter="search('description')"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-select
                                    v-model="filtersPlanet.stock"
                                    :items="filtersYesNo"
                                    outlined
                                    dense
                                    @change="search('stock')"
                                    item-text="TEXT"
                                    item-value="VALUE"
                                    hide-details
                            ></v-select>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="product in productsPlanet">
                        <td>
                            <span style="cursor: pointer">
                                <i v-if="!product.selected" class="far fa-square fa-lg" @click="setProductPlanet(product, true)"></i>
                                <i v-if="product.selected" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="setProductPlanet(product, false)"></i>
                            </span>
                        </td>
                        <td>{{ product.Artigo }}</td>
                        <td>{{ product.Descricao }}</td>
                        <td>{{ product.stock }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" sm="6">
                <v-select
                        v-model="paginationPlanet.per_page"
                        :items="filtersPerPage"
                        outlined
                        single-line
                        @change="search('pagination')"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="width: 90px; float: left"
                        class="staff_select"
                        hide-details
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
                <v-pagination
                        v-model="paginationPlanet.page"
                        :length="getTotalPagesPlanet"
                        :total-visible="7"
                        @input="getProductsPlanet"
                        style="width: auto; float: right;"
                ></v-pagination>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog
                    v-model="dialogAddProducts"
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar
                            dark
                            color="red"
                            dense
                    >
                        <v-toolbar-title>{{ $t('products') }} Bicimax</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                                icon
                                dark
                                @click="dialogAddProducts = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="pa-2">
                        <v-row>
                            <v-col class="text-right">
                                <v-btn small color="success" @click="toggleDialogCreateInvoiceBicimaxToPlanet">Transferir {{ $t('products') }} Bicimax -> Planet ({{ getTotalProductsToAddBicimaxToPlanet }})</v-btn>
                            </v-col>
                        </v-row>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr class="titles_row">
                                <th style="width: 25px;"></th>
                                <th style="width: 190px;">{{ $t('product') }}</th>
                                <th style="width: 150px;">Ref.Forn.</th>
                                <th style="width: 140px;">Cód. Barras Pri</th>
                                <th style="width: 140px;">EAN</th>
                                <th>{{ $t('description') }}</th>
                                <th style="width: 130px;">Marca</th>
                                <th style="width: 130px;">Familia</th>
                                <th style="width: 130px;">SubFamilia</th>
                                <th style="width: 70px;" class="text-center">Ano</th>
                                <th style="width: 75px;" class="text-center">Stock</th>
                                <th style="width: 100px;" class="text-center">Tipo</th>
                                <th style="width: 75px;" class="text-right">Peso</th>
                                <th style="width: 75px;" class="text-center">PVP</th>
                            </tr>
                            <tr class="filters_row">
                                <td>
                                    <span style="cursor: pointer; margin-left: 2px">
                                        <i v-if="!checkboxAllBicimax" class="far fa-square fa-lg" @click="selectAllBicimax()"></i>
                                        <i v-if="checkboxAllBicimax" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="unselectAllBicimax()"></i>
                                    </span>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersBicimax.productId"
                                            outlined
                                            @keyup.enter="searchBicimax('productId')"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersBicimax.refSupplier"
                                            outlined
                                            @keyup.enter="searchBicimax('refSupplier')"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersBicimax.barcode"
                                            outlined
                                            @keyup.enter="searchBicimax('barcode')"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersBicimax.EAN"
                                            outlined
                                            @keyup.enter="searchBicimax('ean')"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersBicimax.description"
                                            outlined
                                            @keyup.enter="searchBicimax('description')"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-autocomplete
                                            v-model="filtersBicimax.brand"
                                            :items="brands"
                                            dense
                                            outlined
                                            @change="searchBicimax('brand')"
                                            item-text="TEXT"
                                            item-value="VALUE"
                                            hide-details
                                    ></v-autocomplete>
                                </td>
                                <td>
                                    <v-autocomplete
                                            v-model="filtersBicimax.family"
                                            :items="families"
                                            dense
                                            outlined
                                            @change="searchBicimax('family')"
                                            item-text="TEXT"
                                            item-value="VALUE"
                                            hide-details
                                    ></v-autocomplete>
                                </td>
                                <td>
                                    <v-autocomplete
                                            v-model="filtersBicimax.subFamily"
                                            :items="subFamilies"
                                            dense
                                            outlined
                                            @change="searchBicimax('subFamily')"
                                            item-text="TEXT"
                                            item-value="VALUE"
                                            hide-details
                                    ></v-autocomplete>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersBicimax.year"
                                            outlined
                                            @keyup.enter="searchBicimax('year')"
                                            hide-details
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-select
                                            v-model="filtersBicimax.stock"
                                            :items="filtersYesNo"
                                            outlined
                                            dense
                                            @change="searchBicimax('stock')"
                                            item-text="TEXT"
                                            item-value="VALUE"
                                            hide-details
                                    ></v-select>
                                </td>
                                <td>
                                    <v-select
                                            v-model="filtersBicimax.type"
                                            :items="types"
                                            outlined
                                            dense
                                            @change="searchBicimax('type')"
                                            item-text="TEXT"
                                            item-value="VALUE"
                                            hide-details
                                    ></v-select>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="product in productsBicimax" :class="{'orange lighten-5': product.TratamentoDim == '2'}">
                                <td>
                                    <span style="cursor: pointer">
                                        <i v-if="!product.selected" class="far fa-square fa-lg" @click="setProduct(product, true)"></i>
                                        <i v-if="product.selected" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="setProduct(product, false)"></i>
                                    </span>
                                </td>
                                <td>{{ product.Artigo }}</td>
                                <td>{{ product.CDU_REFFORNECEDOR }}</td>
                                <td>{{ product.CodBarras }}</td>
                                <td :style="[product.TratamentoDim != 1 && !isValidEAN13(product.CodBarras) ? {'background-color': 'rgb(255,184,194)'} : '']">
                                    {{ product.CDU_EAN }}
                                </td>
                                <td>{{ product.Descricao }}</td>
                                <td>{{ product.Marca }}</td>
                                <td>{{ product.Familia }}</td>
                                <td>{{ product.SubFamilia }}</td>
                                <td class="text-center">{{ product.CDU_CampoVar1 }}</td>
                                <td class="text-center">{{ product.STKActual }}</td>
                                <td class="text-center">
                                    <v-chip small v-if="product.TratamentoDim == '0'">S</v-chip>
                                    <v-chip small v-if="product.TratamentoDim == '1'" color="green" text-color="white">
                                        D
                                    </v-chip>
                                    <v-chip small v-if="product.TratamentoDim == '2'" color="red" text-color="white">
                                        F
                                    </v-chip>
                                </td>
                                <td class="text-right">{{ product.Peso }}</td>
                                <td class="text-right">{{ product.CDU_PVP_PT }}€</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogCreateInvoiceBicimaxToPlanet" persistent max-width="700">
                <v-card>
                    <v-toolbar dense dark color="success">
                        <v-toolbar-title>Criar Fatura Bicimax -> Planet</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogCreateInvoiceBicimaxToPlanet = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                                v-model="inputRefBicimaxToPlanet"
                                outlined
                                hide-details
                                dense
                                label="Referencia"
                        ></v-text-field>
                        <v-simple-table>
                            <tbody>
                            <tr v-for="productToAdd in productsToAddBicimaxToPlanet">
                                <td>{{ productToAdd.product }}</td>
                                <td>{{ productToAdd.description }}</td>
                                <td style="width: 90px">
                                    <v-text-field
                                            v-model="productToAdd.qty"
                                            outlined
                                            hide-details
                                            dense
                                    ></v-text-field>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile @click="dialogCreateInvoiceBicimaxToPlanet = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile color="success" :disabled="inputRefBicimaxToPlanet === ''" @click="createInvoiceBicimaxToPlanet()">Criar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogCreateGTAmazon" persistent max-width="700">
                <v-card>
                    <v-toolbar dense dark color="success">
                        <v-toolbar-title>Criar GT Planet -> Amazon</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogCreateGTAmazon = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                                v-model="inputAddress.reference"
                                outlined
                                dense
                                label="Referencia"
                        ></v-text-field>
                        <v-select
                                v-model="inputAddress.client"
                                outlined
                                dense
                                item-value="Cliente"
                                item-text="Nome"
                                :items="clients"
                                @change="updateClient()"
                        ></v-select>
                        <v-text-field
                                v-model="inputAddress.name"
                                outlined
                                dense
                                :label="$t('name')"
                        ></v-text-field>
                        <v-text-field
                                v-model="inputAddress.address"
                                outlined
                                dense
                                :label="$t('address1')"
                        ></v-text-field>
                        <v-row>
                            <v-col class="pt-0" cols="3">
                                <v-text-field
                                        v-model="inputAddress.cp"
                                        outlined
                                        dense
                                        label="Código Postal"
                                        hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col class="pt-0">
                                <v-text-field
                                        v-model="inputAddress.cpLocality"
                                        outlined
                                        dense
                                        label="Localidade"
                                        hide-details
                                ></v-text-field>
                            </v-col>
                            <v-col cols="2" class="pt-0">
                                <v-text-field
                                        v-model="inputAddress.country"
                                        outlined
                                        dense
                                        label="País"
                                        hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-simple-table>
                            <tbody>
                            <tr v-for="productToAdd in productsToAddPlanetToBicimax">
                                <td>{{ productToAdd.product }}</td>
                                <td>{{ productToAdd.description }}</td>
                                <td style="width: 90px">
                                    <v-text-field
                                            v-model="productToAdd.qty"
                                            outlined
                                            hide-details
                                            dense
                                    ></v-text-field>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile @click="dialogCreateGTAmazon = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile color="success" :disabled="validateButtonGTPlanet" @click="createGTPlanet()">Criar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="700">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p>{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data() {
        return {
            paginationPlanet: {
                per_page: 250,
                page: 1
            },
            paginationBicimax: {
                per_page: 250,
                page: 1
            },
            productsPlanet: [],
            productsBicimax: [],
            totalItemsPlanet: 0,
            totalItemsBicimax: 0,
            filtersPlanet: {
                productId: '',
                description: '',
                stock: ''
            },
            filtersYesNo: [
                {TEXT: 'Todos', VALUE: ''},
                {TEXT: 'Não', VALUE: '0'},
                {TEXT: 'Sim', VALUE: '1'}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            dialogAddProducts: false,
            filtersBicimax: {
                productId: '',
                refSupplier: '',
                barcode: '',
                EAN: '',
                description: '',
                brand: '',
                family: '',
                subFamily: '',
                year: '',
                stock: '',
                type: '',
                namePRI: '',
                DESC: '',
                SPECS: '',
                image: '',
                B2B: '',
                STORE: '',
                FOX: '',
                B2C: '',
                NOZAMA: '',
                BIKEPLANET: '',
                CAT: '',
                nameWEB: '',
                DESCWEB: '',
                showChildren: true,
                includeProfiles: '',
                B2BSoldOff: '',
                blackFriday: ''
            },
            brands: [],
            families: [],
            subFamilies: [],
            types: [],
            checkboxAllBicimax: false,
            productsToAddBicimaxToPlanet: [],
            dialogCreateInvoiceBicimaxToPlanet: false,
            inputRefBicimaxToPlanet: '',
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
            checkboxAllPlanet: false,
            productsToAddPlanetToBicimax: [],
            dialogCreateGTAmazon: false,
            clients: [],
            inputAddress: {
                client: '',
                name: '',
                address: '',
                cp: '',
                cpLocality: '',
                country: '',
                reference: ''
            },
        }
    },
    created() {
        this.getProductsPlanet();
    },
    computed: {
        getTotalPagesPlanet() {
            return Math.ceil(this.totalItemsPlanet / this.paginationPlanet.per_page)
        },
        getTotalProductsToAddBicimaxToPlanet() {
            return this.productsToAddBicimaxToPlanet.length;
        },
        validateButtonGTPlanet() {
            return this.inputAddress.client === '' && this.inputAddress.reference === '' && this.inputAddress.name === '' && (this.inputAddress.address === '' || this.inputAddress.address === null)
                    && (this.inputAddress.cp === '' || this.inputAddress.cp === null) && (this.inputAddress.cpLocality === '' || this.inputAddress.cpLocality === null) && this.inputAddress.country === ''
        }
    },
    methods: {
        getProductsPlanet() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/planet/products',
                data: {
                    filters: this.filtersPlanet,
                    pagination: this.paginationPlanet
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.productsPlanet = responseData.products;
                            this.totalItemsPlanet = responseData.totalProducts;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        });
                        loader.hide();
                    })
        },
        getProductsBicimax() {
            let loader = this.$loading.show();

            let filters = {
                pagination: this.paginationBicimax,
                filters: this.filtersBicimax
            };

            Product.dispatch('getProducts', filters)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.productsBicimax = responseData.products;
                            this.totalItemsBicimax = responseData.totalProducts;
                            this.brands = responseData.brands;
                            this.getFamilies();
                            this.getSubFamilies();
                            this.getTypes();
                            window.scrollTo(0, 0)
                            this.productsToAddBicimaxToPlanet.forEach((productToAdd) => {
                                this.productsBicimax.forEach((product) => {
                                    if (product.Artigo === productToAdd.product) {
                                        product.selected = true;
                                    }
                                })
                            })
                            // console.log(this.pagination)
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        search(column) {
            this.paginationPlanet.page = 1;
            this.getProductsPlanet();
        },
        searchBicimax(column) {

            this.paginationBicimax.page = 1;
            this.getProductsBicimax();
        },
        clearFilters() {
            this.filtersPlanet = {
                productId: '',
                description: '',
                stock: ''
            }
            this.paginationPlanet.page = 1;
            this.getProductsPlanet();
        },
        toggleDialogProductToPlanet() {
            this.dialogAddProducts = true;
            this.paginationBicimax.page = 1;
            this.getProductsBicimax();
        },
        getFamilies() {
            Product.dispatch('getFamilies', this.filtersBicimax.brand)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.families = responseData.families
                        }
                    })
                    .catch(response => {

                    })
        },
        getSubFamilies() {
            Product.dispatch('getSubFamilies', this.filtersBicimax.family)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.subFamilies = responseData.subFamilies
                        }
                    })
                    .catch(response => {

                    })
        },
        getTypes() {
            let filters = {
                brand: this.filtersBicimax.brand,
                family: this.filtersBicimax.family,
                subFamily: this.filtersBicimax.subFamily
            }

            Product.dispatch('getTypes', filters)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.types = responseData.types
                        }
                    })
                    .catch(() => {

                    })
        },
        isValidEAN13(a) {
            for (var b = /\d/g,             //initialize regular expression to match single digits
                         c = 0,                        //initialize checksum
                         d,
                         e = 25;                       //should be 25 - 13 * 2 = -1 in the end, so `~e` is 0
                 d = b.exec(a);                //fetch a single digit from the input
                 e -= 2)
                c += e % 4 * d;               //multiply current digit with 3, 1, 3, 1 and so on
            return !(~e | c % 10)           //e must be -1 and sum must be 0
        },
        selectAllBicimax() {
            this.checkboxAllBicimax = true
            this.productsBicimax.forEach(function (product) {
                if (product.TratamentoDim != 1) {
                    product.selected = true
                    this.productsToAddBicimaxToPlanet.forEach((productToAdd, index) => {
                        if (product.Artigo === productToAdd.product) {
                            this.productsToAddBicimaxToPlanet.splice(index, 1);
                        }
                    })
                    this.productsToAddBicimaxToPlanet.push({
                        product: product.Artigo,
                        qty: 1,
                        description: product.Descricao
                    })
                }
            })
        },
        selectAllPlanet() {
            this.checkboxAllPlanet = true;
            this.productsPlanet.forEach((product) => {
                product.selected = true;
                this.productsToAddPlanetToBicimax.forEach((productToAdd, index) => {
                    if (product.Artigo === productToAdd.product) {
                        this.productsToAddPlanetToBicimax.splice(index, 1);
                    }
                })
                this.productsToAddPlanetToBicimax.push({
                    product: product.Artigo,
                    qty: 1,
                    description: product.Descricao
                })
            })
        },
        unselectAllBicimax() {
            this.checkboxAllBicimax = false
            this.productsBicimax.forEach(function (product) {
                product.selected = false
                this.productsToAddBicimaxToPlanet.forEach((productToAdd, index) => {
                    if (product.Artigo === productToAdd.product) {
                        this.productsToAddBicimaxToPlanet.splice(index, 1);
                    }
                })
            })
        },
        unselectAllPlanet() {
            this.checkboxAllPlanet = false;
            this.productsPlanet.forEach((product) => {
                product.selected = false;
                this.productsToAddPlanetToBicimax.forEach((productToAdd, index) => {
                    if (product.Artigo === productToAdd.product) {
                        this.productsToAddPlanetToBicimax.splice(index, 1);
                    }
                })
            })
        },
        setProduct(product, option) {
            if (option) {
                product.selected = true;
                this.productsToAddBicimaxToPlanet.forEach((productToAdd, index) => {
                    if (product.Artigo === productToAdd.product) {
                        this.productsToAddBicimaxToPlanet.splice(index, 1);
                    }
                })
                this.productsToAddBicimaxToPlanet.push({
                    product: product.Artigo,
                    qty: 1,
                    description: product.Descricao
                })
            } else {
                product.selected = false;
                this.productsToAddBicimaxToPlanet.forEach((productToAdd, index) => {
                    if (product.Artigo === productToAdd.product) {
                        this.productsToAddBicimaxToPlanet.splice(index, 1);
                    }
                })
            }
        },
        setProductPlanet(product, option) {
            if (option) {
                product.selected = true;
                this.productsToAddPlanetToBicimax.forEach((productToAdd, index) => {
                    if (product.Artigo === productToAdd.product) {
                        this.productsToAddPlanetToBicimax.splice(index, 1);
                    }
                })
                this.productsToAddPlanetToBicimax.push({
                    product: product.Artigo,
                    qty: 1,
                    description: product.Descricao
                })
            } else {
                product.selected = false;
                this.productsToAddPlanetToBicimax.forEach((productToAdd, index) => {
                    if (product.Artigo === productToAdd.product) {
                        this.productsToAddPlanetToBicimax.splice(index, 1);
                    }
                })
            }
        },
        createInvoiceBicimaxToPlanet() {
            this.responseDialog.open = true;
            this.responseDialog.title = 'A faturar...';
            this.responseDialog.loading = true;

            let request = {
                url: '/api/staff/online/createInvoiceToPlanet',
                data: {
                    products: this.productsToAddBicimaxToPlanet,
                    reference: 'FBA-'+this.inputRefBicimaxToPlanet
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.createPurchasePlanet();
                        } else {
                            this.responseDialog.loading = false;
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.error = true;
                        }
                    })
                    .catch(() => {
                        this.responseDialog.loading = false;
                        this.responseDialog.message = 'Erro Servidor!';
                        this.responseDialog.error = true;
                    })
        },
        createPurchasePlanet() {
            this.responseDialog.loading = true;

            let request = {
                url: '/api/staff/online/createVFAPlanet',
                data: {
                    reference: 'FBA-'+this.inputRefBicimaxToPlanet
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.responseDialog.loading = false;
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.error = false;
                            this.productsToAddBicimaxToPlanet = [];
                            this.getProductsPlanet();
                        } else {
                            this.responseDialog.loading = false;
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.error = true;
                        }
                    })
                    .catch(() => {
                        this.responseDialog.loading = false;
                        this.responseDialog.message = 'Erro Servidor!';
                        this.responseDialog.error = true;
                    })
        },
        toggleDialogCreateInvoiceBicimaxToPlanet() {
            this.dialogCreateInvoiceBicimaxToPlanet = true;
        },
        toggleDialogCreateGTAmazon() {
            this.getClients();
            this.dialogCreateGTAmazon = true;
        },
        getClients() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/clientsPlanet'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.clients = responseData.clients;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        updateClient() {
            this.clients.forEach((client) => {
                if (client.Cliente === this.inputAddress.client) {
                    this.inputAddress.name = client.Nome;
                    this.inputAddress.address = client.Fac_Mor;
                    this.inputAddress.cp = client.Fac_Cp;
                    this.inputAddress.cpLocality = client.Fac_Cploc;
                    this.inputAddress.country = client.Pais;
                }
            })
        },
        createGTPlanet() {
            this.responseDialog.loading = true;
            this.responseDialog.open = true;

            let request = {
                url: '/api/staff/online/createGTAmazon',
                data: {
                    address: this.inputAddress,
                    products: this.productsToAddPlanetToBicimax
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.loading = false;
                            this.responseDialog.error = false;
                            this.dialogCreateGTAmazon = false;
                            this.productsToAddPlanetToBicimax = [];
                        } else {
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.loading = false;
                            this.responseDialog.error = true;
                        }
                    })
                    .catch(() => {
                        this.responseDialog.loading = false;
                        this.responseDialog.message = 'Erro Servidor!';
                        this.responseDialog.error = true;
                    })
        }
    }
}
</script>
