<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <PendingDPD></PendingDPD>
                <PendingCTT></PendingCTT>
                <PendingDELNEXT></PendingDELNEXT>
            </v-col>
            <v-col>
                <PendingREDUR></PendingREDUR>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
            <PendingClient></PendingClient>
            </v-col>
            <v-col></v-col>
        </v-row>
<!--        <v-row>-->
<!--            <v-col>-->
<!--                <TableOrdersB2B></TableOrdersB2B>-->
<!--            </v-col>-->
<!--            <v-col>-->
<!--                <TableServiceCenter></TableServiceCenter>-->
<!--            </v-col>-->
<!--        </v-row>-->
    </v-container>
</template>

<script>
import TableOrdersB2B from "./Widgets/TableOrdersB2B";
import PendingOrders from "./Widgets/PendingOrders";
import TableServiceCenter from "./Widgets/TableServiceCenter";
import PendingDPD from "./Widgets/PendingDPD";
import PendingCTT from "./Widgets/PendingCTT";
import PendingREDUR from "@/components/Dashboard/Widgets/PendingREDUR";
import PendingDELNEXT from "@/components/Dashboard/Widgets/PendingNACEX.vue";
import PendingClient from "@/components/Dashboard/Widgets/PendingClient";

export default {
    components: {
        PendingClient,
        TableServiceCenter,
        TableOrdersB2B,
        PendingOrders,
        PendingDPD,
        PendingCTT,
        PendingREDUR,
        PendingDELNEXT
    },
}
</script>
