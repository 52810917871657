<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="6">
                <page-title title="Logins"></page-title>
            </v-col>
            <v-col class="text-right">
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <v-card tile>
                    <v-card-text>

                        <v-row>
                            <v-col cols="6" md="2" sm="3">
                                <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        dense
                                        full-width
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                dense
                                                v-model="filters.initialDate"
                                                label="Data Inicio"
                                                prepend-icon="event"
                                                readonly
                                                hide-details
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.initialDate" locale="pt-pt" :show-current="currentDate" @input="menu2 = false" @change="initialize"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6" md="2" sm="3">
                                <v-menu
                                        v-model="menu3"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                dense
                                                v-model="filters.finalDate"
                                                label="Data Final"
                                                prepend-icon="event"
                                                readonly
                                                hide-details
                                                v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filters.finalDate" locale="pt-pt" :show-current="currentDate" @input="menu3 = false" @change="initialize"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="4" offset-md="4" sm="6">
                                <v-text-field
                                        dense
                                        style="margin-left: 20px"
                                        v-model="search"
                                        append-icon="search"
                                        label="Pesquisar"
                                        hide-details
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-data-table
                                dense
                                :headers="headers"
                                :items="logins"
                                :items-per-page="250"
                                :search="search"
                                :loading="loading"
                                :footer-props="{'items-per-page-options': [100,250,500,750,-1]}"
                        >
<!--                            <template v-slot:item.duracao="{ item }">
                                {{ renderDuration(item.duracao) }}
                            </template>
                            <template v-slot:no-data>
                                <v-btn color="primary" @click="initialize">Reset</v-btn>
                            </template>-->
                            <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            search: '',
            menu2: false,
            menu3: false,
            loading: false,
            selected: [],
            logins: [],
            headers: [],
            pagination: {
                rowsPerPage: 250,
                page: 1
            },
            filters: {
                initialDate: new Date().toISOString().substr(0, 10),
                finalDate: new Date().toISOString().substr(0, 10),
            },
            currentDate: new Date().toISOString().substr(0, 10)
        }),
        mounted() {
            document.title = 'Logins';
            this.initialize()
        },
        methods: {
            initialize() {
                this.logins = [];
                this.loading = true;
                B2B.dispatch('getLogins', this.filters)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.headers = responseData.headers;
                            this.logins = responseData.logins;
                            this.loading = false
                        }
                    })
            },
            renderDuration(duration) {
                var modDays = duration % 86400;
                var days = (duration - modDays) / 86400;
                var labelDays = '';
                if (days > 0) {
                    labelDays = days + 'D ';
                }
                //obter as horas
                var modHours = modDays % 3600;
                var hours = (modDays - modHours) / 3600;
                //obter os minutos
                var modMinutes = modHours % 60;
                var minutes = (modHours - modMinutes) / 60;
                //os segundos sao o resto de tudo (modMinutes neste caso)
                return labelDays + hours + 'h ' + minutes + 'm ' + modMinutes + 's';
            }
        }
    }
</script>
