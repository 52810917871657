<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title title="Mini Banners B2B"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  color="primary" class="ml-3" @click="getMiniBanners">
                    Actualizar
                </v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col cols="6" class="pb-0" style="border-right: solid 1px white">
                <div class="float-left">
                    <flag iso="pt" v-bind:squared=false></flag>
                </div>
            </v-col>
            <v-col cols="6" class="pb-0">
                <div class="float-right">
                    <flag iso="es" v-bind:squared=false></flag>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" align="center" class="justify-center" style="border-right: solid 1px white">
                <template v-for="miniBanner in miniBannersPT">
                    <v-card class="mb-4" max-width="500" min-height="164">
                        <v-img v-if="miniBanner.file" :src="'https://static.bicimax.com/banners/'+miniBanner.file"></v-img>
                        <v-img v-else src="https://via.placeholder.com/600x139.png?text=270x150"></v-img>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small icon>
                                <v-icon v-if="miniBanner.order === 1">filter_1</v-icon>
                                <v-icon v-if="miniBanner.order === 2">filter_2</v-icon>
                                <v-icon v-if="miniBanner.order === 3">filter_3</v-icon>
                                <v-icon v-if="miniBanner.order === 4">filter_4</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editMiniBanner(miniBanner)">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-col>

            <v-col cols="6" align="center" class="justify-center">
                <template v-for="miniBanner in miniBannersES">
                    <v-card class="mb-4" max-width="500" min-height="164">
                        <v-img v-if="miniBanner.file" :src="'https://static.bicimax.com/banners/'+miniBanner.file"></v-img>
                        <v-img v-else src="https://via.placeholder.com/600x139.png?text=270x150"></v-img>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small icon>
                                <v-icon v-if="miniBanner.order === 1">filter_1</v-icon>
                                <v-icon v-if="miniBanner.order === 2">filter_2</v-icon>
                                <v-icon v-if="miniBanner.order === 3">filter_3</v-icon>
                                <v-icon v-if="miniBanner.order === 4">filter_4</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editMiniBanner(miniBanner)">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">
            <!-- EDIT BANNER -->
            <v-dialog v-model="editDialog" persistent max-width="600">
                <v-card>
                    <v-img v-if="selected.file !== ''" :src="'https://static.bicimax.com/banners/'+selected.file">
                        <v-app-bar flat color="rgba(0, 0, 0, 0)">
                            <v-spacer></v-spacer>
                            <v-btn icon color="white" @click="openImageUpload()">
                                <v-icon>mdi-image</v-icon>
                            </v-btn>
                        </v-app-bar>
                    </v-img>
                    <v-img v-else src="https://via.placeholder.com/600x139.png?text=270x150">
                        <v-app-bar flat color="rgba(0, 0, 0, 0)">
                            <v-spacer></v-spacer>
                            <v-btn icon color="white" @click="openImageUpload()">
                                <v-icon>mdi-image</v-icon>
                            </v-btn>
                        </v-app-bar>
                    </v-img>
                    <v-card-text class="text-center">
                        <v-row>
                            <v-col>
                                <v-switch
                                        v-model="selected.new_window"
                                        label="Abre nova janela"
                                        hide-details
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-text-field
                                class="mt-3"
                                label="Link"
                                dense
                                outlined
                                v-model="selected.link"
                                hide-details
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn tile small  color="default" @click="editDialog = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  color="success" @click="editConfirm()" :loading="editLoading">Gravar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- UPLOAD IMAGE -->
            <v-dialog v-model="imageDialog" persistent max-width="500">
                <staff-dialog :title="'Inserir imagem mini banner'">
                    <template slot="content">
                        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                    </template>
                    <template slot="action" class="text-right">
                        <v-btn color="error" @click="imageDialog = false">{{ $t('close') }}</v-btn>
                    </template>
                </staff-dialog>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

import vue2Dropzone from "vue2-dropzone";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    data: () => ({
        miniBannersPT: [],
        miniBannersES: [],
        selected: {},
        editDialog: false,
        editLoading: false,
        imageDialog: false,
        dropzoneOptions: {
            url: AppModel.getters.getUrlAPI + '/api/staff/b2b/miniBanners/images',
            thumbnailWidth: 150,
            maxFilesize: 16,
            acceptedFiles: "image/png,image/jpeg",
            headers: {"Authorization": "Bearer " + User.getters.getAccessToken},
            init: function () {
                this.on("queuecomplete", function (file) {
                    // location.reload()
                })
            }
        },
    }),
    created() {
        document.title = 'Mini Banners B2B';
        this.getMiniBanners();
    },
    components: {
        vueDropzone: vue2Dropzone
    },
    methods: {
        getMiniBanners() {
            let loader = this.$loading.show();
            this.miniBannersPT = [];
            this.miniBannersES = [];
            ApiWS.dispatch('makeGet', {url: '/api/staff/b2b/miniBanners'})
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.miniBannersPT = responseData.miniBannersPT;
                            this.miniBannersES = responseData.miniBannersES;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        openImageUpload() {
            this.dropzoneOptions.url = AppModel.getters.getUrlAPI + '/api/staff/b2b/miniBanners/' + this.selected.id + '/upload'
            this.imageDialog = true
        },
        editMiniBanner(miniBanner) {
            this.selected = miniBanner;
            this.editDialog = true;
        },
        editConfirm() {
            this.editDialog = false;
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/b2b/miniBanners/edit',
                data: {
                    miniBanner: this.selected,
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.miniBannersPT = responseData.miniBannersPT;
                            this.miniBannersES = responseData.miniBannersES;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Banner guardado com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.message,
                                type: 'error'
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
    }
}
</script>
