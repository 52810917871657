import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"content-header"},[_c(VCol,[_c('page-title',{attrs:{"title":_vm.banner.name,"subtitle":_vm.banner.id,"back":true}})],1),_c(VCol,{staticClass:"text-right"})],1),(Object.keys(_vm.banner).length > 0)?_c(VRow,[_c(VCol,[_c(VTabs,{attrs:{"background-color":"transparent","slider-color":"rgba(192, 13, 13, 1)","color":"rgba(192, 13, 13, 1)"}},[_c(VTab,{style:([_vm.activeTab === 1 ? {'background-color': 'white'} : '']),on:{"click":function($event){_vm.activeTab = 1}}},[_vm._v(" Imformação ")]),_c(VTab,{style:([_vm.activeTab === 2 ? {'background-color': 'white'} : '']),on:{"click":function($event){_vm.activeTab = 2}}},[_vm._v(" Calendarização ")]),_c(VTab,{style:([_vm.activeTab === 3 ? {'background-color': 'white'} : '']),on:{"click":function($event){_vm.activeTab = 3}}},[_vm._v(" Imagens ")]),_c(VTab,{style:([_vm.activeTab === 4 ? {'background-color': 'white'} : '']),on:{"click":function($event){_vm.activeTab = 4}}},[_vm._v(" Perfis ")])],1),(_vm.activeTab === 1)?_c('BasicSettings',{attrs:{"bannerId":_vm.bannerId,"banner":_vm.banner}}):_vm._e(),(_vm.activeTab === 2)?_c('Schedule',{attrs:{"bannerId":_vm.bannerId,"banner":_vm.banner}}):_vm._e(),(_vm.activeTab === 3)?_c('Images',{attrs:{"banner":_vm.banner}}):_vm._e(),(_vm.activeTab === 4)?_c('Profiles',{attrs:{"bannerId":_vm.bannerId,"banner":_vm.banner}}):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }