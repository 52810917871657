<style scoped>
/*.v-data-table td, .v-data-table th {*/
/*    padding: 0 4px;*/
/*    height: 10px;*/
/*}*/

.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td, .v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
    height: 23px;
}

.v-icon.v-icon.fas {
    font-size: 16px;
}
</style>
<template>
    <div>
        <v-card v-if="loading" height="280" width="500">
            <v-card-text class="text-center" style="height: 100%;">
                <i class="fas fa-cog fa-spin fa-3x" style="margin-top: 90px"></i>
            </v-card-text>
        </v-card>
        <v-card v-if="!loading" width="625">
            <v-card-title><i class="fas fa-shopping-cart fa-fw mr-2"></i>Notas de Crédito</v-card-title>
            <v-card-text>
                <v-simple-table dense style="background-color: transparent;">
                    <thead>
                    <tr style="height: 20px">
                        <th style="font-size: 12px" class="text-center">Motivo</th>
                        <th style="font-size: 12px" class="text-center">Descrição</th>
                        <th style="font-size: 12px" class="text-right"> Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="height: 20px" v-for="line in lines">
                        <td style="font-size: 12px" class="text-center">{{ line.MotivoEstorno }}</td>
                        <td style="font-size: 12px" class="text-center">{{ line.Descricao }}</td>
                        <td style="font-size: 12px" class="text-right">{{ line.Total }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
                <GChart
                        type="PieChart"
                        :data="chartData"
                        :options="chartOptions"
                        style="width: 550px; height: 400px;"
                />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            loading: true,
            lines: [],
            chartData: [],
            chartOptions: {},
        }
    },
    created() {
        this.initialize();
    },
    methods: {
        initialize() {
            let request = {
                url: '/api/staff/dashboard/widget/ncStatistics'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.lines = responseData.lines;
                            this.drawMap();
                            this.loading = false;
                        }
                    })
                    .catch(response => {

                    })
        },
        drawMap() {
            this.chartData = [
                ['Motivo', 'Total']
            ];

            for (let i = 0; i < this.lines.length; i++) {
                this.chartData.push([this.lines[i].Descricao, this.lines[i].Total]);
            }
        }
    },
}
</script>
