<template>
  <v-container fluid>

    <v-row class="content-header">
      <v-col cols="12" md="6">
        <page-title :title="$t('monos')" :subtitle="'A mostrar '+products.length+' de '+rows.length+' artigo(s)'"></page-title>
      </v-col>
      <v-col class="text-right">
        <v-btn tile small  color="error" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-simple-table class="staff_table">
          <thead>
          <tr>
            <th>ARTIGO</th>
            <th>DESCRICAO</th>
            <th>MARCA</th>
            <th>FAMILIA</th>
            <th>SUBFAMILIA</th>
            <th>DataUltSaida</th>
            <th>STKActual</th>
          </tr>
          <tr class="filters_row">
            <td>
              <v-text-field
                  v-model="filters.ARTIGO"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.DESCRICAO"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.MARCA"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.FAMILIA"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.SUBFAMILIA"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.DataUltSaida"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filters.STKActual"
                  outlined
                  dense
                  @keyup="filterTable"
                  hide-details
              ></v-text-field>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="7">
              <v-progress-linear indeterminate></v-progress-linear>
            </td>
          </tr>
          <tr v-for="row in rows">
            <td>{{ row.ARTIGO }}</td>
            <td>{{ row.DESCRICAO }}</td>
            <td>{{ row.MARCA }}</td>
            <td>{{ row.FAMILIA }}</td>
            <td>{{ row.SUBFAMILIA }}</td>
            <td>{{ row.DataUltSaida }}</td>
            <td>{{ row.STKActual }}</td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  data: function () {
    return {
      loading: false,
      products: [],
      rows: [],
      filters: {
        ARTIGO: '',
        DESCRICAO: '',
        MARCA: '',
        FAMILIA: '',
        SUBFAMILIA: '',
        DataUltSaida: '',
        STKActual: '',
      },
      filtersYesNo: [
        {TEXT: 'Todos', VALUE: ''},
        {TEXT: 'Não', VALUE: '0'},
        {TEXT: 'Sim', VALUE: '1'}
      ],
    }
  },
  created() {
    document.title = 'Monos';
    this.getRows()
  },
  methods: {
    getRows() {
      this.loading = true

      let request = {
        url: '/api/staff/products/monos',
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {
              this.products = responseData.products
              this.filterTable()
            }
            this.loading = false
          })
          .catch(response => {
            this.loading = false
          })
    },
    clearFilters() {
      this.filters = {
        ARTIGO: '',
        DESCRICAO: '',
        MARCA: '',
        FAMILIA: '',
        SUBFAMILIA: '',
        DataUltSaida: '',
        STKActual: '',
      }
      this.filterTable();
    },
    filterTable() {
      console.log('filterTable');
      this.rows = this.products
      if (this.filters.ARTIGO !== '') {
        this.rows = this.rows.filter(row => row.ARTIGO !== null && row.ARTIGO.toLowerCase().includes(this.filters.ARTIGO.toLowerCase()))
      }
      if (this.filters.DESCRICAO !== '') {
        this.rows = this.rows.filter(row => row.MARCA !== null && row.DESCRICAO.toLowerCase().includes(this.filters.DESCRICAO.toLowerCase()))
      }
      if (this.filters.MARCA !== '') {
        this.rows = this.rows.filter(row => row.MARCA !== null && row.MARCA.toLowerCase().includes(this.filters.MARCA.toLowerCase()))
      }
      if (this.filters.FAMILIA !== '') {
        this.rows = this.rows.filter(row => row.FAMILIA !== null && row.FAMILIA.toLowerCase().includes(this.filters.FAMILIA.toLowerCase()))
      }
      if (this.filters.SUBFAMILIA !== '') {
        this.rows = this.rows.filter(row => row.SUBFAMILIA !== null && row.SUBFAMILIA.toLowerCase().includes(this.filters.SUBFAMILIA.toLowerCase()))
      }
      if (this.filters.DataUltSaida !== '') {
        this.rows = this.rows.filter(row => row.DataUltSaida !== null && row.DataUltSaida.toLowerCase().includes(this.filters.DataUltSaida.toLowerCase()))
      }
      if (this.filters.STKActual !== '') {
        this.rows = this.rows.filter(row => row.STKActual !== null && row.STKActual.toLowerCase().includes(this.filters.STKActual.toLowerCase()))
      }
    },
  }
}
</script>
