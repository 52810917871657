<template>
    <v-container fluid>
        <v-row>
            <v-col class="pb-0">
                <page-title
                        title="Portes"
                ></page-title>
            </v-col>
            <v-col class="pb-0 text-right">
                <v-btn v-if="$writePermission('orders/shippingCosts')" color="primary" small @click="toggleMapZonesAndAreas()">Mapear Zonas nas Áreas</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="4" class="pt-0">
                <v-card outlined>
                    <v-toolbar color="red darken-1" dark dense>
                        <v-toolbar-title class="subtitle-1">Áreas</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon v-if="!loadingAreas && $writePermission('orders/shippingCosts')" @click="toggleAddArea">
                            <v-icon>add_circle</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-list-item v-if="loadingAreas">
                        <v-progress-linear height="3" indeterminate color="blue darken-4"></v-progress-linear>
                    </v-list-item>
                    <v-list class="pa-0" v-if="!loadingAreas">
                        <v-list-item-group mandatory v-model="selectedAreaList" active-class="red--text">
                            <template v-for="area in areas">
                                <v-divider></v-divider>
                                <v-list-item>
                                    <v-list-item-avatar @click="selectArea(area)">
                                        <span class="title font-weight-black">{{ area.id }}</span>
                                    </v-list-item-avatar>
                                    <v-list-item-content @click="selectArea(area)">
                                        <v-list-item-title class="font-weight-bold">{{ area.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Despacho: {{ $money(area.dispatchCost) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action v-if="$writePermission('orders/shippingCosts')">
                                        <v-row>
                                            <v-col class="pa-0">
                                                <v-btn icon @click="toggleEditArea(area)">
                                                    <v-icon>edit</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col class="pa-0">
                                                <v-btn icon color="error" @click="toggleDeleteArea(area)">
                                                    <v-icon>clear</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>
            <v-col class="pt-0" v-if="selectedArea !== -1">
                <v-row>
                    <v-col class="pt-0">
                        <v-card outlined>
                            <v-toolbar color="red darken-1" dark dense>
                                <v-toolbar-title class="subtitle-1">Portes</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon v-if="!loadingShippingCosts && $writePermission('orders/shippingCosts')" @click="toggleAddShippingCosts">
                                    <v-icon>add_circle</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-list-item v-if="loadingShippingCosts">
                                <v-progress-linear height="3" indeterminate color="blue darken-4"></v-progress-linear>
                            </v-list-item>
                            <v-list class="pa-0" v-if="!loadingShippingCosts">
                                <template v-for="shippingCost in shippingCosts">
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <span class="title font-weight-black">{{ shippingCost.id }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">Peso: {{ shippingCost.minWeight
                                                }} - {{ shippingCost.maxWeight }} Kg
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Custo: {{ $money(shippingCost.cost) }}
                                                <span v-if="shippingCost.type === 'kilo'">por kilo</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$writePermission('orders/shippingCosts')">
                                            <v-row>
                                                <v-col class="pa-0">
                                                    <v-btn icon @click="toggleEditShippingCost(shippingCost)">
                                                        <v-icon>edit</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col class="pa-0">
                                                    <v-btn icon color="error" @click="toggleDeleteShippingCost(shippingCost)">
                                                        <v-icon>clear</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col class="pt-0">
                        <v-card outlined><v-toolbar color="red darken-1" dark dense>
                                <v-toolbar-title class="subtitle-1">Portes Cobrança</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon v-if="!loadingShippingCosts && $writePermission('orders/shippingCosts')" @click="toggleAddShippingCharges">
                                    <v-icon>add_circle</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-list-item v-if="loadingShippingCosts">
                                <v-progress-linear height="3" indeterminate color="blue darken-4"></v-progress-linear>
                            </v-list-item>
                            <v-list class="pa-0" v-if="!loadingShippingCosts">
                                <template v-for="shippingCharge in shippingCharges">
                                    <v-divider></v-divider>
                                    <v-list-item>
                                        <v-list-item-avatar>
                                            <span class="title font-weight-black">{{ shippingCharge.id }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">Valor: {{
                                                shippingCharge.minValue
                                                }}  -  {{ shippingCharge.maxValue }} €
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Custo: <span v-if="shippingCharge.type === 'value'">{{ $money(shippingCharge.cost) }}</span>
                                                <span v-if="shippingCharge.type === 'percentage'">{{ shippingCharge.cost }} %</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action v-if="$writePermission('orders/shippingCosts')">
                                            <v-row>
                                                <v-col class="pa-0">
                                                    <v-btn icon @click="toggleEditShippingCharges(shippingCharge)">
                                                        <v-icon>edit</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col class="pa-0">
                                                    <v-btn icon color="error" @click="toggleDeleteShippingCharges(shippingCharge)">
                                                        <v-icon>clear</v-icon>
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-list-item-action>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialogAddArea" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        <span v-if="areaIndex === -1">Adicionar Area</span>
                        <span v-if="areaIndex !== -1">Editar Area</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="formArea">
                            <v-text-field
                                    v-model="editArea.id"
                                    outlined
                                    dense
                                    label="Id"
                                    readonly
                                    v-if="areaIndex !== -1"
                            ></v-text-field>
                            <v-text-field
                                    v-model="editArea.name"
                                    outlined
                                    dense
                                    :label="$t('name')"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field
                                    v-model="editArea.dispatchCost"
                                    outlined
                                    dense
                                    label="Despacho"
                                    :rules="[rules.required]"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogAddArea = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="saveArea()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeleteArea" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Eliminar Área
                    </v-card-title>
                    <v-card-text>
                        Tem a certeza que deseja eliminar esta área?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogDeleteArea = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="deleteArea()">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogShippingCosts" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        <span v-if="shippingCostsIndex === -1">Adicionar Portes</span>
                        <span v-if="shippingCostsIndex !== -1">Editar Porte</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="formShippingCosts">
                            <v-text-field
                                    v-model="editShippingCosts.id"
                                    outlined
                                    dense
                                    label="Id"
                                    readonly
                                    v-if="shippingCostsIndex !== -1"
                            ></v-text-field>
                            <v-text-field
                                    v-model="editShippingCosts.minWeight"
                                    outlined
                                    dense
                                    label="Peso Minímo"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field
                                    v-model="editShippingCosts.maxWeight"
                                    outlined
                                    dense
                                    label="Peso Máximo"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field
                                    v-model="editShippingCosts.cost"
                                    outlined
                                    dense
                                    label="Custo"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            Tipo:
                            <v-radio-group v-model="editShippingCosts.type" row>
                                <v-radio label="Valor" value="value"></v-radio>
                                <v-radio label="Kilo" value="kilo"></v-radio>
                            </v-radio-group>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogShippingCosts = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="saveShippingCosts()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeleteShippingCosts" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Eliminar Portes
                    </v-card-title>
                    <v-card-text>
                        Tem a certeza que deseja eliminar o porte?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogDeleteShippingCosts = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="deleteShippingCosts()">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogShippingCharges" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        <span v-if="shippingChargesIndex === -1">Adicionar Porte Cobrança</span>
                        <span v-if="shippingChargesIndex !== -1">Editar Porte Cobrança</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="formShippingCharges">
                            <v-text-field
                                    v-model="editShippingCharges.id"
                                    outlined
                                    dense
                                    label="Id"
                                    readonly
                                    v-if="shippingChargesIndex !== -1"
                            ></v-text-field>
                            <v-text-field
                                    v-model="editShippingCharges.minValue"
                                    outlined
                                    dense
                                    label="Valor Minímo"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field
                                    v-model="editShippingCharges.maxValue"
                                    outlined
                                    dense
                                    label="Valor Máximo"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field
                                    v-model="editShippingCharges.cost"
                                    outlined
                                    dense
                                    label="Custo"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            Tipo:
                            <v-radio-group v-model="editShippingCharges.type" row>
                                <v-radio label="Valor" value="value"></v-radio>
                                <v-radio label="Percentagem" value="percentage"></v-radio>
                            </v-radio-group>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogShippingCharges = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="saveShippingCharges()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeleteShippingCharges" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Eliminar Porte Cobrança
                    </v-card-title>
                    <v-card-text>
                        Tem a certeza que deseja eliminar o porte de cobrança?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogDeleteShippingCharges = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="deleteShippingCharges()">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogMapZoneAndArea" scrollable persistent max-width="800">
                <v-card>
                    <v-card-title>
                        Mapear Zonas nas Áreas
                    </v-card-title>
                    <v-card-text style="height: 600px;">
                        <v-list class="pa-0">
                            <template v-for="zone in zones">
                                <v-divider></v-divider>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <span class="title font-weight-black">{{ zone.zona }}</span>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold">
                                            {{ zone.Descricao }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-select
                                            v-model="zone.Area"
                                            outlined
                                            dense
                                            hide-details
                                            :items="areas"
                                            item-value="id"
                                            item-text="name"
                                            style="width: 250px"
                                        ></v-select>
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogMapZoneAndArea = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="saveMapZonesAndAreas()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from "../../plugins/i18n";

    export default {
        data: function () {
            return {
                loadingAreas: false,
                loadingShippingCosts: false,
                areas: [],
                dialogAddArea: false,
                dialogDeleteArea: false,
                dialogShippingCosts: false,
                dialogDeleteShippingCosts: false,
                dialogShippingCharges: false,
                dialogDeleteShippingCharges: false,
                dialogMapZoneAndArea: false,
                areaIndex: -1,
                editArea: {
                    id: '',
                    name: '',
                    dispatchCost: ''
                },
                rules: {
                    required: value => !!value || value === 0 || i18n.t('required')
                },
                selectedAreaList: -1,
                selectedArea: -1,
                shippingCosts: [],
                shippingCharges: [],
                shippingCostsIndex: -1,
                editShippingCosts: {
                    id: '',
                    areaId: '',
                    minWeight: '',
                    maxWeight: '',
                    cost: '',
                    type: ''
                },
                editShippingCharges: {
                    id: '',
                    areaId: '',
                    type: '',
                    minValue: '',
                    maxValue: '',
                    cost: ''
                },
                shippingChargesIndex: -1,
                zones: []
            }
        },
        created() {
            this.getAreas()
        },
        methods: {
            getAreas() {
                this.loadingAreas = true

                let request = {
                    url: api.ordersShippingCostsAreas()
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.areas = responseData.shippingCostsAreas
                        }
                        this.loadingAreas = false
                    })
                    .catch(response => {
                        this.loadingAreas = false
                    })
            },
            toggleAddArea() {
                this.editArea = {
                    id: '',
                    name: '',
                    dispatchCost: ''
                }
                this.areaIndex = -1
                this.dialogAddArea = true
            },
            toggleEditArea(area) {
                this.areaIndex = area.id
                this.editArea.id = area.id
                this.editArea.name = area.name
                this.editArea.dispatchCost = area.dispatchCost
                this.dialogAddArea = true
            },
            toggleDeleteArea(area) {
                this.editArea.id = area.id
                this.editArea.name = area.name
                this.editArea.dispatchCost = area.dispatchCost
                this.dialogDeleteArea = true
            },
            saveArea() {
                if (this.$refs.formArea.validate()) {
                    let mode = this.areaIndex === -1 ? 'add' : 'edit'

                    let request = {
                        url: api.ordersShippingCostsSaveArea(),
                        data: {
                            mode: mode,
                            area: this.editArea
                        }
                    }

                    ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Área adicionada com sucesso!',
                                    type: 'success'
                                });
                                this.getAreas()
                                this.dialogAddArea = false
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao adicionar área!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
                }
            },
            deleteArea() {
                let request = {
                    url: api.ordersShippingCostsSaveArea(),
                    data: {
                        mode: 'delete',
                        area: this.editArea
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Área eliminada com sucesso!',
                                type: 'success'
                            });
                            this.selectedArea = -1
                            this.getAreas()
                            this.dialogDeleteArea = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao eliminar área!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            selectArea(area) {
                this.selectedArea = area.id
                this.getShippingCosts()
            },
            getShippingCosts() {
                this.loadingShippingCosts = true

                let request = {
                    url: api.ordersShippingCosts() + '/' + this.selectedArea
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.shippingCosts = responseData.shippingCosts
                            this.shippingCharges = responseData.shippingCharges
                        }
                        this.loadingShippingCosts = false
                    })
                    .catch(response => {
                        this.loadingShippingCosts = false
                    })
            },
            saveShippingCosts() {
                if (this.$refs.formShippingCosts.validate() && this.editShippingCosts.type !== '') {
                    let mode = this.shippingCostsIndex === -1 ? 'add' : 'edit'

                    let request = {
                        url: api.ordersShippingCosts() + '/' + this.selectedArea + '/saveShippingCosts',
                        data: {
                            mode: mode,
                            shippingCosts: this.editShippingCosts
                        }
                    }

                    ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Portes criados com sucesso!',
                                    type: 'success'
                                });
                                this.getShippingCosts()
                                this.dialogShippingCosts = false
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao adicionar portes!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
                }
            },
            toggleAddShippingCosts() {
                this.editShippingCosts = {
                    id: '',
                    areaId: '',
                    minWeight: '',
                    maxWeight: '',
                    cost: '',
                    type: ''
                }

                if (this.shippingCosts.length > 0) {
                    this.editShippingCosts.minWeight = this.shippingCosts[this.shippingCosts.length - 1].maxWeight + 0.001
                }

                this.shippingCostsIndex = -1
                this.dialogShippingCosts = true
            },
            toggleEditShippingCost(shippingCost) {
                this.editShippingCosts.id = shippingCost.id
                this.editShippingCosts.areaId = shippingCost.areaId
                this.editShippingCosts.minWeight = shippingCost.minWeight
                this.editShippingCosts.maxWeight = shippingCost.maxWeight
                this.editShippingCosts.cost = shippingCost.cost
                this.editShippingCosts.type = shippingCost.type
                this.shippingCostsIndex = shippingCost.id
                this.dialogShippingCosts = true
            },
            deleteShippingCosts() {
                let request = {
                    url: api.ordersShippingCosts() + '/' + this.selectedArea + '/saveShippingCosts',
                    data: {
                        mode: 'delete',
                        shippingCosts: this.editShippingCosts
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Porte eliminado com sucesso!',
                                type: 'success'
                            });
                            this.getShippingCosts()
                            this.dialogDeleteShippingCosts = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao eliminar porte!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            toggleDeleteShippingCost(shippingCost) {
                this.editShippingCosts.id = shippingCost.id
                this.editShippingCosts.areaId = shippingCost.areaId
                this.editShippingCosts.minWeight = shippingCost.minWeight
                this.editShippingCosts.maxWeight = shippingCost.maxWeight
                this.editShippingCosts.cost = shippingCost.cost
                this.editShippingCosts.type = shippingCost.type

                this.dialogDeleteShippingCosts = true
            },
            toggleAddShippingCharges() {
                this.editShippingCharges = {
                    id: '',
                    areaId: '',
                    type: '',
                    minValue: '',
                    maxValue: '',
                    cost: ''
                }

                if (this.shippingCharges.length > 0) {
                    this.editShippingCharges.minValue = this.shippingCharges[this.shippingCharges.length - 1].maxValue + 0.001
                }

                this.shippingChargesIndex = -1
                this.dialogShippingCharges = true
            },
            toggleEditShippingCharges(shippingCharge) {
                this.editShippingCharges.id = shippingCharge.id
                this.editShippingCharges.areaId = shippingCharge.areaId
                this.editShippingCharges.type = shippingCharge.type
                this.editShippingCharges.minValue = shippingCharge.minValue
                this.editShippingCharges.maxValue = shippingCharge.maxValue
                this.editShippingCharges.cost = shippingCharge.cost

                this.shippingChargesIndex = shippingCharge.id
                this.dialogShippingCharges = true
            },
            toggleDeleteShippingCharges(shippingCharge) {
                this.editShippingCharges.id = shippingCharge.id
                this.editShippingCharges.areaId = shippingCharge.areaId
                this.editShippingCharges.type = shippingCharge.type
                this.editShippingCharges.minValue = shippingCharge.minValue
                this.editShippingCharges.maxValue = shippingCharge.maxValue
                this.editShippingCharges.cost = shippingCharge.cost

                this.dialogDeleteShippingCharges = true
            },
            saveShippingCharges() {
                if (this.$refs.formShippingCharges.validate() && this.editShippingCharges.type !== '') {
                    let mode = this.shippingChargesIndex === -1 ? 'add' : 'edit'

                    let request = {
                        url: api.ordersShippingCosts() + '/' + this.selectedArea + '/saveShippingCharges',
                        data: {
                            mode: mode,
                            shippingCharges: this.editShippingCharges
                        }
                    }

                    ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Portes Cobrança criado com sucesso!',
                                    type: 'success'
                                });
                                this.getShippingCosts()
                                this.dialogShippingCharges = false
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao adicionar porte Cobrança!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
                }
            },
            deleteShippingCharges() {
                let request = {
                    url: api.ordersShippingCosts() + '/' + this.selectedArea + '/saveShippingCharges',
                    data: {
                        mode: 'delete',
                        shippingCharges: this.editShippingCharges
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Porte Cobrança eliminado com sucesso!',
                                type: 'success'
                            });
                            this.getShippingCosts()
                            this.dialogDeleteShippingCharges = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao eliminar porte cobrança!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            toggleMapZonesAndAreas() {
                this.getZones()
            },
            getZones() {
                let request = {
                    url: api.ordersShippingCostsZones()
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.zones = responseData.zones
                            this.dialogMapZoneAndArea = true
                        }
                    })
                    .catch(response => {

                    })
            },
            saveMapZonesAndAreas() {
                let request = {
                    url: api.ordersShippingCostsZones(),
                    data: {
                        zones: this.zones
                    }
                }

                ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Zonas mapeadas com sucesso!',
                            type: 'success'
                        });
                        this.dialogMapZoneAndArea = false
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao mapear zonas nas áreas!',
                            type: 'error'
                        })
                    }
                })
                .catch(response => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
            }
        }
    }
</script>
