<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        back
                        :title="'Picking '+pickOrderId"
                        :subtitle="pickOrder.cliente+' - '+pickOrder.nome"
                ></page-title>
            </v-col>
            <v-col class="text-right" align-self="center">
                <v-btn tile small  color="error" @click="reopenPick()">Reabrir</v-btn>
            </v-col>
        </v-row>
        <v-row justify="end">
            <v-col cols="3">
                <v-card tile>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <tbody>
                            <tr>
                                <td><h2>Artigos Picados</h2></td>
                                <td><h2>{{ totalProductsPick }}</h2></td>
                            </tr>
                            <tr>
                                <td>Artigos Por Picar</td>
                                <td><strong>{{ totalProductsNotPick }}</strong></td>
                            </tr>
                            <tr>
                                <td>Artigos Por Referência</td>
                                <td><strong>{{ totalProductsByReference }}</strong></td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card tile>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-simple-table class="staff_table">
                                    <thead>
                                    <tr>
                                        <th>{{ $t('product') }}</th>
                                        <th>Cod.Barras</th>
                                        <th>{{ $t('description') }}</th>
                                        <th class="text-center">Stock A1</th>
                                        <th class="text-center">{{ $t('quantity') }}</th>
                                        <th class="text-center">Pick</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="product in products"
                                        :style="[product.quantity === product.codBarrasPick ? {'background-color': 'rgba(0, 255, 0, 0.40)'} : '', product.stock < product.codBarrasPick ? {'background-color': 'rgba(250, 0, 0, 0.40)'} : '']">
                                        <td>{{ product.artigo }}</td>
                                        <td>{{ product.codBarras }}</td>
                                        <td>{{ product.descricao }}</td>
                                        <td class="text-center">{{ product.stock }}</td>
                                        <td class="text-center">{{ product.quantity }}</td>
                                        <td class="text-center">{{ product.codBarrasPick }}</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: function () {
        return {
            pickOrderId: '',
            pickOrder: {},
            products: [],
            totalProductsByReference: 0,
        }
    },
    created() {
        this.pickOrderId = this.$route.params.id;
        document.title = 'Picking - ' + this.pickOrderId + ' | STAFF';
        this.getPickOrder();
    },
    methods: {
        getPickOrder() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/' + this.pickOrderId + '/view'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pickOrder = responseData.pickOrder;
                            this.products = responseData.products;
                            this.totalProductsByReference = responseData.totalProductsByReference;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        reopenPick() {
            if (this.pickOrder.tipoCliente === 'Nozama') {
                let loader = this.$loading.show();

                let request = {
                    url: '/api/staff/pick/' + this.pickOrderId + '/reopen'
                }

                ApiWS.dispatch('makeGet', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.$router.push('/orders/pick/' + this.pickOrderId)
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                })
                            }
                            loader.hide();
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide();
                        })
            } else {
                this.$router.push('/orders/pick/' + this.pickOrderId);
            }
        }
    },
    computed: {
        totalProductsPick() {
            let count = 0;
            this.products.forEach(function (product) {
                count = count + product.codBarrasPick + product.manualPick;
            });
            return count;
        },
        totalProductsNotPick() {
            let count = 0;
            this.products.forEach(function (product) {
                count = count + product.quantity;
            });

            return count - this.totalProductsPick;
        },
    }
}
</script>
