<template>
  <v-container fluid>

    <v-row class="content-header">
      <v-col cols="12" md="4">
        <page-title
            :title="currentWarehouse.desc"
            :subtitle="'A mostrar '+products.length+' de '+totalProducts+' artigo(s).'"
        ></page-title>
      </v-col>
      <v-col class="text-right">
        <v-btn tile small  class="ml-2" color="info" v-if="$writePermission('products/warehouse')" :disabled="productsSelected === 0" @click="openDialogTransfer">Transferir ({{ productsSelected }})</v-btn>
        <v-btn tile small  class="ml-2" color="error" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="staff_v-card">

          <div class="my-2">
            <template v-for="warehouse in warehouses">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                      small
                      tile
                      v-on="on"
                      :color="warehouse.name === currentWarehouse.name ? 'success' : ''"
                      class="mr-0"
                      @click="selectWarehouse(warehouse)"
                  >{{ warehouse.name }}
                  </v-btn>
                </template>
                <span>{{ warehouse.desc }}</span>
              </v-tooltip>
            </template>
          </div>

          <v-simple-table class="staff_table">
            <thead>
            <tr>
              <th style="width: 24px"></th>
              <th style="width: 180px;">{{ $t('product') }}</th>
              <th>{{ $t('description') }}</th>
              <th style="width: 120px;">CodBarras</th>
              <th style="width: 120px;">Marca</th>
              <th style="width: 120px;">Família</th>
              <th style="width: 120px;">SubFamília</th>
              <th class="text-center">Total</th>
              <th class="text-center" style="width: 60px">Stk {{ currentWarehouse.name }}</th>
              <th class="text-center" style="width: 80px">Transferir</th>
            </tr>
            <tr class="filters_row">
              <td>
                        <span v-if="!loading" style="cursor: pointer; margin-left: 2px">
                            <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                            <i v-if="checkboxAll" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="unselectAll()"></i>
                        </span>
              </td>
              <td>
                <v-text-field
                    v-model="filters.product"
                    outlined
                    hide-details
                    dense
                    @keypress.enter="getProductsByWarehouse"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.description"
                    outlined
                    hide-details
                    dense
                    @keypress.enter="getProductsByWarehouse"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.barcode"
                    outlined
                    hide-details
                    dense
                    @keypress.enter="getProductsByWarehouse"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.brand"
                    outlined
                    hide-details
                    dense
                    @keypress.enter="getProductsByWarehouse"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.family"
                    outlined
                    hide-details
                    dense
                    @keypress.enter="getProductsByWarehouse"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.subFamily"
                    outlined
                    hide-details
                    dense
                    @keypress.enter="getProductsByWarehouse"
                ></v-text-field>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="10">
                <v-progress-linear indeterminate></v-progress-linear>
              </td>
            </tr>
            <tr v-for="product in products" v-bind:class="{'blue lighten-5':product.selected}">
              <td>
                        <span style="cursor: pointer">
                            <i v-if="!product.selected" class="far fa-square fa-lg" @click="product.selected = true"></i>
                            <i v-if="product.selected" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="product.selected = false"></i>
                        </span>
              </td>
              <td>{{ product.Artigo }}</td>
              <td>{{ product.Descricao }}</td>
              <td>{{ product.CodBarras }}</td>
              <td>{{ product.Marca }}</td>
              <td>{{ product.Familia }}</td>
              <td>{{ product.SubFamilia }}</td>
              <td class="text-center">{{ product.StockTotal }}</td>
              <td class="text-center">{{ product.StockWarehouse }}</td>
              <td>
                <v-text-field
                    v-if="product.selected"
                    v-model="product.StockTransfer"
                    dense
                    solo
                    outlined
                    hide-details
                ></v-text-field>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-pagination
            v-model="pagination.page"
            :length="getTotalPages"
            :total-visible="7"
            @input="getProductsByWarehouse"
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogTransfer" persistent max-width="600">
      <v-card>
        <v-toolbar dense dark color="info">
          <v-toolbar-title>Transferência de Armazém</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon :disabled="loading" @click="closeDialogTransfer()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="3">
                <p class="my-2 text-right">Armazém atual:</p>
              </v-col>
              <v-col cols="9">
                <v-text-field
                    :value="currentWarehouse.desc"
                    solo
                    outlined
                    dense
                    hide-details
                    disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <p class="my-2 text-right">Novo armazém:</p>
              </v-col>
              <v-col cols="9">
                <v-select
                    v-model="transfer.to"
                    :items="warehouses"
                    item-text="desc"
                    item-value="name"
                    dense
                    :error="transfer.to === ''"
                    :disabled="loading"
                    hide-details
                    outlined
                    solo
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <p class="my-2 text-right">Motivo:</p>
              </v-col>
              <v-col cols="9">
                <v-text-field
                    v-model="transfer.reason"
                    dense
                    :error="transfer.reason === ''"
                    :disabled="loading"
                    hide-details
                    outlined
                    solo
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <p class="mb-0">Transferir {{ productsSelected }} artigos.</p>
          <v-spacer></v-spacer>
          <v-btn tile small  @click="closeDialogTransfer()" :disabled="loading">Cancelar</v-btn>
          <v-btn tile small  color="success" @click="confirmDialogTransfer()" :disabled="transfer.to === '' || transfer.reason === ''" :loading="loading">Transferir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import i18n from "../../plugins/i18n";

export default {
  data: () => ({
    loading: false,
    dialogTransfer: false,
    warehouses: [],
    checkboxAll: false,
    numProductsSelected: 0,
    currentWarehouse: {
      name: '',
      desc: 'ARMAZÉM',
    },
    transfer: {
      to: '',
      reason: '',
    },
    products: [],
    totalProducts: 0,
    pagination: {
      page: 1,
      per_page: 250
    },
    filters: {
      product: '',
      description: '',
      barcode: '',
      brand: '',
      family: '',
      subFamily: ''
    }
  }),
  computed: {
    productsSelected() {
      let selectedProducts = 0;
      this.products.forEach(function (product) {
        if (product.selected === true) {
          selectedProducts++
        }
      });
      return selectedProducts;
    },
    getTotalPages() {
      return Math.ceil(this.totalProducts / this.pagination.per_page);
    }
  },
  created() {
    document.title = i18n.t('warehouse');
    this.getWarehouses()
  },
  methods: {
    getWarehouses() {
      let request = {
        url: api.productsWarehouses()
      };
      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.warehouses = responseData.warehouses;
              this.currentWarehouse = this.warehouses[0];
              this.getProductsByWarehouse();
            }
          })
    },
    getProductsByWarehouse() {
      this.loading = true;
      this.products = [];
      this.checkboxAll = false;
      let request = {
        url: api.productsWarehouses() + '/' + this.currentWarehouse.name,
        data: {
          pagination: this.pagination,
          filters: this.filters
        }
      };
      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.products = responseData.products;
              this.totalProducts = responseData.totalProducts;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          })
    },
    selectWarehouse(warehouse) {
      this.currentWarehouse = warehouse;
      this.getProductsByWarehouse();
    },
    clearFilters() {
      this.filters = {
        product: '',
        description: '',
        barcode: '',
        brand: '',
        family: '',
        subFamily: ''
      };
      this.pagination.page = 1;
      this.getProductsByWarehouse();
    },
    selectAll() {
      this.checkboxAll = true;
      this.products.forEach(function (product) {
        product.selected = true;
      })
    },
    unselectAll() {
      this.checkboxAll = false;
      this.products.forEach(function (product) {
        product.selected = false;
      })
    },
    openDialogTransfer() {
      this.transfer.to = '';
      this.transfer.reason = '';
      this.dialogTransfer = true;
    },
    closeDialogTransfer() {
      this.dialogTransfer = false;
    },
    confirmDialogTransfer() {
      this.loading = true;
      let request = {
        url: api.productsTransfer(),
        data: {
          from: this.currentWarehouse.name,
          to: this.transfer.to,
          reason: this.transfer.reason,
          products: this.products,
        }
      };
      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.dialogTransfer = false;
              this.getProductsByWarehouse();
            } else {
              // TODO: mostrar alerta erro
              this.dialogTransfer = false;
              this.getProductsByWarehouse();
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
  }
}
</script>
