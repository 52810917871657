<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Campanhas"
                        :subtitle="'A mostrar ' + campaigns.length + ' campanhas.'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  class="ml-2" color="success" @click="dialogAddCampaign = true">Nova Campanha</v-btn>
                <v-btn tile small  class="ml-2" color="warning" @click="exportHistoricCampaigns()">Export Histórico Campanhas</v-btn>
                <v-btn tile small  class="ml-2" color="info" @click="init()">Atualizar</v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 30px;">ID</th>
                            <th style="width: 150px;">Tipo</th>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th>Data de Ínicio</th>
                            <th>Data de Fim</th>
                            <th>Ordem</th>
                            <th>Cor Principal</th>
                            <th>Cor Fundo</th>
                            <th style="width: 100px;">Estado</th>
                            <th style="width: 90px;"></th>
                            <th style="width: 80px;"></th>
                        </tr>
                        <!-- FILTERS -->
                        <tr class="filters_row">
                            <td></td>
                            <td>
                                <v-select
                                        v-model="filters.type"
                                        :items="selectType"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        dense
                                        hide-details
                                        @change="filterTable()"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.title"
                                        dense
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterTable()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        dense
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterTable()"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                                <v-select
                                        v-model="filters.status"
                                        :items="selectStatus"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        dense
                                        hide-details
                                        @change="filterTable()"
                                ></v-select>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="12">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="item in campaigns" :class="item.status ? '' : 'grey lighten-1'">
                            <td>{{ item.campaign_id }}</td>
                            <td>
                                <v-chip
                                        v-if="item.type === 'promotion'"
                                        small
                                        color="deep-orange"
                                        label
                                        text-color="white"
                                >Promoção
                                </v-chip>
                                <v-chip
                                        v-if="item.type === 'pre-order'"
                                        small
                                        color="yellow"
                                        label
                                        text-color="black"
                                >Pré-encomenda
                                </v-chip>
                                <v-chip
                                        v-if="item.type === 'quantity'"
                                        small
                                        color="indigo"
                                        label
                                        text-color="white"
                                >Quantidade
                                </v-chip>
                                <v-chip
                                        v-if="item.type === 'coupon'"
                                        small
                                        color="green"
                                        label
                                        text-color="white"
                                >Cupão
                                </v-chip>
                            </td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.start_ts }}</td>
                            <td>{{ item.end_ts }}</td>
                            <td>{{ item.order }}</td>
                            <td>{{ item.color1 }}</td>
                            <td>{{ item.color2 }}</td>
                            <td>
                                <template v-if="item.status">Activa</template>
                                <template v-if="!item.status">Inactiva</template>
                            </td>
                            <td>
                                <v-chip
                                        v-if="$isDateBetween(item.start_ts,item.end_ts) && item.status"
                                        small
                                        color="green"
                                        label
                                        text-color="white"
                                >A decorrer
                                </v-chip>
                            </td>
                            <td class="text-right">
                                <v-btn tile small  color="primary" :to="'/b2b/campaigns/'+item.campaign_id">Editar</v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-row v-if="!loading">
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Campanha</th>
                            <th>Cliente</th>
                            <th>Nome</th>
                            <th class="text-center">Nível</th>
                            <th class="text-center">Cupão</th>
                            <th width="130" class="text-center">Data</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in history">
                            <td>{{ item.campaign_id }}</td>
                            <td>{{ item.name }}</td>
                            <td>
                                {{ item.entidade }}
                                <v-btn small icon :href="'/clients/clients/' + item.entidade" target="_blank">
                                    <i class="fas fa-external-link-alt" style="color: #1867c0;"></i>
                                </v-btn>
                            </td>
                            <td>{{ item.nome }}</td>
                            <td class="text-center">{{ item.nivel }}</td>
                            <td class="text-center">{{ item.cupon }}</td>
                            <td class="text-right">{{ item.created_at }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <!-- DIAOLOGS -->
        <v-row justify="center">
            <!-- ADD CAMPAIGN -->
            <v-dialog v-model="dialogAddCampaign" persistent max-width="660">
                <v-card>
                    <v-card-title class="headline green darken-1">
                        <v-row no-gutters>
                            <v-col class="text-uppercase">
                                <span style="color:white">Nova Campanha</span>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="addCampaign">
                            <v-row>
                                <v-col>
                                    <div class="subheading my-3">Tipo de Campanha:</div>
                                    <v-btn tile small  :class="{'white--text': campaign.type==='promotion'}" :color="campaign.type==='promotion' ? 'deep-orange' : ''" @click="setType('promotion')">Promoção</v-btn>
                                    <v-btn tile small  :color="campaign.type==='pre-order' ? 'yellow' : ''" @click="setType('pre-order')">Pré-encomenda</v-btn>
                                    <v-btn tile small  :class="{'white--text': campaign.type==='quantity'}" :color="campaign.type==='quantity' ? 'indigo' : ''" @click="setType('quantity')">Quantidade</v-btn>
                                    <v-btn tile small  :class="{'white--text': campaign.type==='coupon'}" :color="campaign.type==='coupon' ? 'green' : ''" @click="setType('coupon')">Cupão</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field label="Nome" v-model="campaign.name" :rules="[rules.required]" @keyup="validateForm"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="6">
                                    <v-date-picker full-width v-model="campaign.start_ts" :locale="locale" @change="validateForm"></v-date-picker>
                                </v-col>
                                <v-col md="6">
                                    <v-date-picker full-width v-model="campaign.end_ts" :locale="locale" @change="validateForm"></v-date-picker>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-btn tile @click="dialogAddCampaign = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile color="success" :disabled="!campaign.valid" @click="addCampaignConfirm()">Adicionar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </v-container>
</template>

<script>
    export default {
        data: () => ({
            campaignsOriginal: [],
            campaigns: [],
            history: [],
            locale: '',
            menu_start_ts: false,
            menu_end_ts: false,
            filters: {
                name: '',
                type: '',
                start: '',
                end: '',
                status: 1
            },
            selectStatus: [
                {value: 2, text: 'Todos'},
                {value: 1, text: 'Activa'},
                {value: 0, text: 'Inactiva'},
            ],
            selectType: [
                {value: '', text: 'Todos'},
                {value: 'pre-order', text: 'Pré-encomenda'},
                {value: 'promotion', text: 'Promoção'},
                {value: 'quantity', text: 'Quantidade'},
                {value: 'coupon', text: 'Cupão'},
            ],
            dialogAddCampaign: false,
            campaign: {
                valid: false,
                name: '',
                type: '',
                start_ts: '',
                end_ts: '',
                status: 1
            },
            rules: {
                required: value => !!value || value === '0' || 'Este campo é obrigatório.',
                length: value => (value || '').length === 10 || 'Este campo tem de ter 10 caracteres'
            },
        }),
        created() {
            this.init();
        },
        mounted() {
            this.locale = (User.getters.getLanguage + '-' + User.getters.getLanguage).toLocaleLowerCase();
        },
        methods: {
            init() {
                this.loading = true;
                this.campaigns = [];
                let request = {
                    url: api.b2bCampaigns(),
                };
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.campaignsOriginal = responseData.campaigns;
                            this.history = responseData.history;
                            this.filterTable();
                        }
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
            },
            filterTable() {
                this.campaigns = this.campaignsOriginal;
                if (this.filters.type !== '') {
                    this.campaigns = this.campaigns.filter(item => item.type === this.filters.type)
                }
                if (this.filters.name !== '') {
                    this.campaigns = this.campaigns.filter(item => item.name.toUpperCase().includes(this.filters.name.toUpperCase()))
                }
                if (this.filters.status !== 2) {
                    this.campaigns = this.campaigns.filter(item => item.status === this.filters.status)
                }
            },
            setType(value) {
                this.campaign.type = value;
                this.validateForm();
            },
            validateForm() {
                let valid = true;
                if (this.campaign.type === '') {
                    valid = false;
                }
                if (this.campaign.start_ts === '') {
                    valid = false;
                }
                if (this.campaign.end_ts === '') {
                    valid = false;
                }
                if (this.$refs.addCampaign.validate() === false) {
                    valid = false;
                }
                this.campaign.valid = valid;
            },
            addCampaignConfirm() {

                if (this.campaign.valid) {
                    let request = {
                        url: api.b2bCampaignsAdd(),
                        data: {
                            campaign: this.campaign
                        }
                    };
                    ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.$router.push('/b2b/campaigns/' + responseData.campaign_id);
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Nova campanha adicionada com sucesso!',
                                    type: 'success'
                                })
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao adicionar campanha!',
                                    type: 'error'
                                })
                            }
                            this.loading = false
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
                }

            },
            exportHistoricCampaigns() {
                let loader = this.$loading.show();

                let request = {
                    url: '/api/staff/b2b/campaigns/exportHistoric'
                }

                ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'HistoricoCampanhas.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            }
        }
    }
</script>
