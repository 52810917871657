<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                Imagens
                <v-spacer></v-spacer>
                <v-btn tile small color="success" :disabled="!addFlag" class="ml-3 mr-2" @click="toggleAddImage()">
                    Adicionar
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <template v-for="image in images">
                        <div style="position: relative; height:300px; width:300px" class="ma-5">
                            <v-img
                                    :src="'https://static.bicimax.com/bannersV2/'+image.file"
                                    style="border: 1px darkgray solid; cursor: pointer"
                                    @click="toggleBannerText(image)"
                                    contain
                            ></v-img>
                            <v-btn tile x-small
                                   style="position: absolute; bottom: 1px; right: 1px; top: 1px;"
                                   @click="toggleDelete(image.id)">
                                <i class="fas fa-times red&#45;&#45;text"></i>
                            </v-btn>
                            <p><span style="font-weight: bold">{{image.id}}</span> - {{image.device}}</p>
                        </div>
                    </template>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialogShowImage" max-width="870">
            <v-img
                    :src="'https://static.bicimax.com/bannersV2/'+imageSelected.file"
                    max-height="870"
                    max-width="870"
                    contain
            ></v-img>
        </v-dialog>
        <v-dialog persistent v-model="dialogAddImage" max-width="900">
            <v-card>
                <v-card-title class="headline darken-1">
                    Adicionar Imagem
                </v-card-title>
                <v-card-text>
                    <!--                    <v-form v-model="formValid" ref="myForm">-->
                    <v-select
                            :items="deviceOptions"
                            outlined
                            single-line
                            v-model="deviceChoice"
                            item-value="id"
                            class="mt-2 ml-2"
                            label="Dispositivo"
                            dense
                            style="max-width: 98%"
                    >
                    </v-select>
                    <v-file-input
                            accept="image/*"
                            label="Upload Nova Imagem"
                            v-model="file"
                    ></v-file-input>
                    <!--                    </v-form>-->
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogAddImage = false">Fechar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addNewImageData()"> Adicionar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!--        BANNER TEXT DIALOG-->
        <v-dialog persistent width="800" v-model="dialogBannerText">
            <v-card>
                <v-card-title class="headline darken-1 banners-card-header">
                    Textos de Banner
                </v-card-title>
                <v-card-text>
                    <v-row class="mt-2">
                        <v-col class="text-center">
                            <v-btn-toggle mandatory color="rgba(192, 13, 13, 1)" dense>
                                <v-btn @click="updateLanguage('PT')">
                                    <flag iso="pt" v-bind:squared=false></flag>&nbsp;PT
                                </v-btn>
                                <v-btn @click="updateLanguage('ES')">
                                    <flag iso="es" v-bind:squared=false></flag>&nbsp;ES
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col>
                            <!--                        <v-form v-model="formValidPT" ref="myFormPT">-->
                            <!-- POSITON BANNER -->
                            <v-row>
                                <v-col>
                                    <h3>POSIÇÃO DO TEXTO</h3>
                                    <v-select
                                            :items="positions"
                                            outlined
                                            single-line
                                            hide-details
                                            item-value="id"
                                            item-text="description"
                                            v-model="inputBannerText.editPosition"
                                            class="mt-2 ml-2"
                                            label="Posição"
                                            dense
                                            style="max-width: 98%"
                                    >
                                    </v-select>
                                    <v-divider class="mt-2"></v-divider>
                                </v-col>
                            </v-row>
                            <!-- TITLE BANNER -->
                            <v-row class="mt-0">
                                <v-col>
                                    <h3>TITULO DO BANNER</h3>
                                    <label class="ml-2"> Titulo </label>
                                    <v-text-field
                                            v-model="inputBannerText.editTitle"
                                            dense
                                            outlined
                                            hide-details
                                            class="mb-2 ml-2"
                                            style="max-width: 99.5%"
                                    ></v-text-field>
                                    <v-row>
                                        <v-col class="col-md-4">
                                            <label class="ml-2"> Tamanho do titulo </label>
                                            <v-text-field
                                                    v-model="inputBannerText.editTitleFontSize"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    class="mb-2 ml-2"
                                                    style="max-width: 99.5%"
                                            ></v-text-field>
                                            <v-checkbox
                                                    class="ml-2"
                                                    v-model="inputBannerText.editUseDropShadow"
                                                    label="Usar dropshadow"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col class="col-md-4">
                                            <label>Cor do titulo</label>
                                            <v-color-picker
                                                    dot-size="25"
                                                    mode="hexa"
                                                    swatches-max-height="200"
                                                    v-model="inputBannerText.editTextColor"
                                            ></v-color-picker>
                                        </v-col>
                                        <v-col class="col-md-4">
                                            <label>Cor da Dropshadow</label>
                                            <v-color-picker
                                                    dot-size="25"
                                                    mode="hexa"
                                                    swatches-max-height="200"
                                                    :disabled="inputBannerText.editUseDropShadow ? false : true"
                                                    v-model="inputBannerText.editDropShadowColor"
                                            ></v-color-picker>
                                        </v-col>
                                    </v-row>
                                    <v-divider class="mb-0"></v-divider>
                                </v-col>
                            </v-row>

                            <!-- SUBTITLE BANNER -->
                            <v-row class="mt-0">
                                <v-col>
                                    <h3>SUBTITULO DO BANENR</h3>
                                    <label class="ml-2">Subtitulo</label>
                                    <v-text-field
                                            v-model="inputBannerText.editSubTitle"
                                            dense
                                            outlined
                                            hide-details
                                            class="ml-2"
                                            style="max-width: 99.5%"
                                    ></v-text-field>
                                    <v-row>
                                        <v-col class="col-md-4">
                                            <label class="ml-2">Tamanho do subtitulo</label>
                                            <v-text-field
                                                    v-model="inputBannerText.editSubTitleFontSize"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    class="ml-2"
                                                    style="max-width: 99.5%"
                                            ></v-text-field>
                                            <v-checkbox
                                                    class="ml-2"
                                                    v-model="inputBannerText.editSubtitleuseDropShadow"
                                                    label="Usar dropshadow"
                                            ></v-checkbox>
                                        </v-col>
                                        <v-col class="col-md-4">
                                            <label>Cor do subtitulo</label>
                                            <v-color-picker
                                                    dot-size="25"
                                                    mode="hexa"
                                                    swatches-max-height="200"
                                                    v-model="inputBannerText.editSubtitleColor"
                                            ></v-color-picker>
                                        </v-col>
                                        <v-col class="col-md-4">
                                            <label>Cor da Dropshadow</label>
                                            <v-color-picker
                                                    dot-size="25"
                                                    mode="hexa"
                                                    swatches-max-height="200"
                                                    :disabled="inputBannerText.editSubtitleuseDropShadow ? false : true"
                                                    v-model="inputBannerText.editSubtitleDropShadowColor"
                                            ></v-color-picker>
                                        </v-col>
                                    </v-row>

                                    <v-divider></v-divider>
                                </v-col>
                            </v-row>

                            <!-- SUBTITLE BANNER -->
                            <v-row class="mt-0">
                                <v-col>
                                    <h3>BOTÃO DO BANNER</h3>
                                    <v-text-field
                                            v-model="inputBannerText.editButtonText"
                                            dense
                                            outlined
                                            label="Texto do botão"
                                            hide-details
                                            class="mt-4 mb-2 ml-2"
                                            style="max-width: 99.5%"
                                    ></v-text-field>

                                    <v-row>
                                        <v-col class="col-md-5">
                                            <label class="ml-2">Cor do botão</label>
                                            <v-color-picker
                                                    class="ml-2"
                                                    dot-size="25"
                                                    mode="hexa"
                                                    swatches-max-height="200"
                                                    v-model="inputBannerText.editButtonColor"
                                            ></v-color-picker>
                                        </v-col>
                                        <v-col class="col-md-5">
                                            <label class="ml-2">Cor do texto no botão</label>
                                            <v-color-picker
                                                    class="ml-2"
                                                    dot-size="25"
                                                    mode="hexa"
                                                    swatches-max-height="200"
                                                    v-model="inputBannerText.editButtonTextColor"
                                            ></v-color-picker>
                                        </v-col>

                                        <v-col class="col-md-2" style="display: flex; align-items: center">
                                            <v-btn style="font-weight: bold"
                                                   :style="{backgroundColor: inputBannerText.editButtonColor, color: inputBannerText.editButtonTextColor != undefined ?  inputBannerText.editButtonTextColor : '#000'}">
                                                EXEMPLO
                                            </v-btn>
                                        </v-col>
                                    </v-row>


                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="error" @click="toggleBtOFF()">Fechar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="deleteBannerText()">Eliminar</v-btn>
                    <v-btn color="success" @click="addOrUpdateBannerText()"> Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--        BANNER TEXT DIALOG-->
        <!--        Delete image DIALOG-->
        <v-dialog v-model="deleteDialog" overlay-color="white" overlay-opacity="0.5" persistent max-width="600">
            <v-card>
                <v-card-title class="red--text">
                    Tem a certeza que quer apagar esta imagem de banner?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="error" @click="deleteDialog = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="deleteImage()">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
    components: {
        'editor': Editor
    },
    name: "Images",
    data: function () {
        return {
            file: null,
            images: [],
            imageSelected: '',
            bannerId: '',
            inputBannerText: {
                editPosition: '',
                editTitle: '',
                editSubTitle: '',
                editTextColor: '',
                editTitleFontSize: '',
                editSubTitleFontSize: '',
                editButtonText: '',
                editButtonColor: '',
                editButtonTextColor: '',
                editUseDropShadow: false,
                editDropShadowColor: '',
                editSubtitleColor: '',
                editSubtitleuseDropShadow: false,
                editSubtitleDropShadowColor: ''
            },

            language: "PT",
            bannerTextEditPT: {
                position: '',
                textColor: '',
                title: '',
                subTitle: '',
                titleFontSize: '',
                subTitleFontSize: '',
                buttonText: '',
                buttonColor: '',
                buttonTextColor: '',
                useDropShadow: false,
                titleDropShadow: '',
                subtitleColor: '',
                subtitleuseDropShadow: false,
                subtitleDropShadow: '',
            },
            bannerTextEditES: {
                position: '',
                textColor: '',
                title: '',
                subTitle: '',
                titleFontSize: '',
                subTitleFontSize: '',
                buttonText: '',
                buttonColor: '',
                buttonTextColor: '',
                useDropShadow: false,
                titleDropShadow: '',
                subtitleColor: '',
                subtitleuseDropShadow: false,
                subtitleDropShadow: '',
            },
            dialogShowImage: false,
            dialogAddImage: false,
            dialogBannerText: false,
            deleteDialog: false,
            hasPT: false,
            hasES: false,
            deviceOptions: ["Desktop", "Mobile"],
            positions: ["left", "center", "right"],
            deviceChoice: null,
            formValid: false,
            formValidPT: false,
            formValidES: false,
            imageSelectedId: null,
            addFlag: true,
            rules: {
                required: [value => !!value || "Required."]
            },
        }
    },
    created() {
        this.bannerId = this.$route.params.id;
        this.getBannerImages();
    },
    methods: {
        getBannerImages() {
            this.dialogBannerText = false;
            // this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.bannerId + '/images'
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (response.status === 200) {
                            this.images = responseData.images
                            if (this.images.length < 2) {
                                this.addFlag = true;
                            } else {
                                this.addFlag = false;
                            }
                            loader.hide();
                            // this.loading = false
                        }
                        if (responseData.status === "NOK") {
                            loader.hide();
                            // this.loading = false
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Banner não tem imagens!',
                    type: 'error'
                })
                loader.hide();
                // this.loading = false
            })
        },
        addNewImageFile() {
            if (this.$refs.myForm.validate() === false)
                return
            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.bannerId + '/images',
                file: this.file,
            }

            ApiWS.dispatch('makeFile', request)
                    .then(response => {
                        let responseData = response.data;
                        if (response.status === 200) {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Imagem Adicionada!',
                                type: 'success'
                            })
                        }
                        if (responseData.status === "NOK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
            })
        },
        addNewImageData() {
            var auxNewWindow = null
            if (this.newWindowOption) {
                auxNewWindow = 1;
            } else {
                auxNewWindow = 0;
            }
            const url = '/api/staff/b2b/bannersB2B/' + this.bannerId + '/imagesData'
            let formData = new FormData();

            formData.append('file', this.file);
            formData.append('device', this.deviceChoice.toLowerCase());

            axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                let responseData = response.data;
                console.log(responseData);
                if (responseData > 0) {
                    this.dialogAddImage = !this.dialogAddImage;
                    this.getBannerImages();
                }
                /* if (responseData.status === 'OK') {

                 } else {


                 }*/

            })
                    .catch(() => {
                        this.errorMessage = "There was an error on the API";

                    })
            /* let request = {
                 url: '/api/staff/b2b/bannersB2B/' + this.bannerId + '/imagesData',
                 data: {
                     bannerImage: {
                         file: this.file.name,
                         device: this.deviceChoice.toLowerCase(),
                         order: this.order,
                         newWindow: auxNewWindow
                     }

                 }
             }
             if (this.link) {
                 request.file.bannerImage.link = this.link;
             }
             ApiWS.dispatch('makePost', request)
                     .then(response => {
                         let responseData = response.data;
                         if (response.status === 200) {
                             Alert.dispatch('show', {
                                 snackbar: true,
                                 text: 'Imagem Adicionada!',
                                 type: 'success'
                             })
                         }
                         if (responseData.status === "NOK") {
                             Alert.dispatch('show', {
                                 snackbar: true,
                                 text: 'Erro de servidor!',
                                 type: 'error'
                             })
                         }
                     }).catch(() => {
                 Alert.dispatch('show', {
                     snackbar: true,
                     text: 'Erro de servidor!',
                     type: 'error'
                 })
             })
            this.addNewImageFile();*/
        },
        getBannerTexts() {
            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.images.id + '/images'
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (response.status === 200) {
                            this.images = responseData.images
                        }
                        if (responseData.status === "NOK") {
                            loader.hide();
                            this.loading = false
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })

            })
        },
        addOrUpdateBannerText() {
            if (this.language === 'PT') {
                if (!this.hasPT) {
                    this.addBannerText()
                } else {
                    this.updateBannerText()
                }
            }
            if (this.language === 'ES') {
                if (!this.hasES) {
                    this.addBannerText()
                } else {
                    this.updateBannerText()
                }
            }

            /*if (!this.hasPT){
                this.bannerTextEditPT.language = 'PT'
                this.addBannerText()
            }
            if (this.hasPT){
                this.updateBannerText()
            }
            if (this.hasES){
                console.log("title ES:"+ this.bannerTextEditES.title)
                this.bannerTextEditES.language = 'ES'
                this.addBannerText()
            } if (this.hasES){
                this.updateBannerText()
            }*/
        },
        addBannerText() {
            if (this.inputBannerText.editTitle === '' || this.inputBannerText.editTitle === undefined || this.inputBannerText.editTitle === null) {
                // console.log("ADD BannerText returned!");
                return
            }
            //  console.log("ADD BannerText will go On!");

            let auxTitleShadow = this.inputBannerText.editUseDropShadow === false ? '' : this.inputBannerText.editDropShadowColor;
            let auxSubTitleShadow = this.inputBannerText.editSubtitleuseDropShadow === false ? '' : this.inputBannerText.editSubtitleDropShadowColor;

            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.imageSelectedId + '/banners-text',
                data: {
                    bannerText: {
                        title: this.inputBannerText.editTitle,
                        subTitle: this.inputBannerText.editSubTitle,
                        position: this.inputBannerText.editPosition,
                        language: this.language,
                        titleFontSize: this.inputBannerText.editTitleFontSize,
                        subTitleFontSize: this.inputBannerText.editSubTitleFontSize,
                        buttonText: this.inputBannerText.editButtonText,
                        buttonTextColor: this.inputBannerText.editButtonTextColor,
                        titleDropShadow: auxTitleShadow,
                        subtitleColor: this.inputBannerText.editSubtitleColor,
                        subtitleDropShadow: auxSubTitleShadow
                    },
                }
            }

            // console.log(this.inputBannerText.editTextColor);
            // console.log(this.inputBannerText.editButtonColor);
            if (this.inputBannerText.editTextColor.hasOwnProperty('hex')) {
                request.data.bannerText.textColor = this.inputBannerText.editTextColor.hex.toLowerCase()
            } else {
                request.data.bannerText.textColor = this.inputBannerText.editTextColor.toLowerCase()
            }
            if (this.inputBannerText.editUseDropShadow) {
                if (this.inputBannerText.editDropShadowColor.hasOwnProperty('hex')) {
                    request.data.bannerText.titleDropShadow = this.inputBannerText.editDropShadowColor.hex.toLowerCase()
                } else {
                    request.data.bannerText.titleDropShadow = this.inputBannerText.editDropShadowColor.toLowerCase()
                }
            }

            if (this.inputBannerText.editSubtitleColor.hasOwnProperty('hex')) {
                request.data.bannerText.subtitleColor = this.inputBannerText.editSubtitleColor.hex.toLowerCase()
            } else {
                request.data.bannerText.subtitleColor = this.inputBannerText.editSubtitleColor.toLowerCase()
            }
            if (this.inputBannerText.editSubtitleuseDropShadow) {
                if (this.inputBannerText.editSubtitleDropShadowColor.hasOwnProperty('hex')) {
                    request.data.bannerText.subtitleDropShadow = this.inputBannerText.editSubtitleDropShadowColor.hex.toLowerCase()
                } else {
                    request.data.bannerText.subtitleDropShadow = this.inputBannerText.editSubtitleDropShadowColor.toLowerCase()
                }
            }
            if (this.inputBannerText.editButtonColor.hasOwnProperty('hex')) {
                request.data.bannerText.buttonColor = this.inputBannerText.editButtonColor.hex.toLowerCase()
            } else {
                request.data.bannerText.buttonColor = this.inputBannerText.editButtonColor.toLowerCase()
            }
            if (this.inputBannerText.editButtonTextColor.hasOwnProperty('hex')) {
                request.data.bannerText.buttonTextColor = this.inputBannerText.editButtonTextColor.hex.toLowerCase()
            } else {
                request.data.bannerText.buttonTextColor = this.inputBannerText.editButtonTextColor.toLowerCase()
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        //console.log(responseData);
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Texto de Banner adicionado com sucesso!',
                                type: 'success'
                            })
                            this.dialogBannerText = false;
                            this.getBannerImages();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })

        },
        updateBannerText() {
            if (this.inputBannerText.editTitle === '' || this.inputBannerText.editTitle === undefined || this.inputBannerText.editTitle === null) {
                //  console.log("Update BannerText returned!");
                return
            }
            //   console.log("Update BannerText will go On!");
            var auxId = '';
            if (this.language === 'PT') {
                this.imageSelected.bannersTexts.forEach(bt => {
                    if (bt.language === 'PT') {
                        auxId = bt.id;
                    }
                })
            }
            if (this.language === 'ES') {
                this.imageSelected.bannersTexts.forEach(bt => {
                    if (bt.language === 'ES') {
                        auxId = bt.id;
                    }
                })
            }

            // AUX to validate if want to use dropshadow
            let auxTitleShadow = this.inputBannerText.editUseDropShadow === false ? '' : this.inputBannerText.editDropShadowColor;
            let auxSubTitleShadow = this.inputBannerText.editSubtitleuseDropShadow === false ? '' : this.inputBannerText.editSubtitleDropShadowColor;

            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.imageSelectedId + '/banners-text/' + auxId,
                data: {
                    bannerText: {
                        title: this.inputBannerText.editTitle,
                        subTitle: this.inputBannerText.editSubTitle,
                        position: this.inputBannerText.editPosition,
                        language: this.language,
                        titleFontSize: this.inputBannerText.editTitleFontSize,
                        subTitleFontSize: this.inputBannerText.editSubTitleFontSize,
                        buttonText: this.inputBannerText.editButtonText,
                        buttonTextColor: this.inputBannerText.editButtonTextColor,
                        titleDropShadow: auxTitleShadow,
                        subtitleColor: this.inputBannerText.editSubtitleColor,
                        subtitleDropShadow: auxSubTitleShadow
                    },
                }
            }

            if (this.inputBannerText.editTextColor.hasOwnProperty('hex')) {
                request.data.bannerText.textColor = this.inputBannerText.editTextColor.hex.toLowerCase()
            } else {
                request.data.bannerText.textColor = this.inputBannerText.editTextColor.toLowerCase()
            }
            if (this.inputBannerText.editUseDropShadow) {
                if (this.inputBannerText.editDropShadowColor.hasOwnProperty('hex')) {
                    request.data.bannerText.titleDropShadow = this.inputBannerText.editDropShadowColor.hex.toLowerCase()
                } else {
                    request.data.bannerText.titleDropShadow = this.inputBannerText.editDropShadowColor.toLowerCase()
                }
            }
            if (this.inputBannerText.editSubtitleColor.hasOwnProperty('hex')) {
                request.data.bannerText.subtitleColor = this.inputBannerText.editSubtitleColor.hex.toLowerCase()
            } else {
                request.data.bannerText.subtitleColor = this.inputBannerText.editSubtitleColor.toLowerCase()
            }
            if (this.inputBannerText.editSubtitleuseDropShadow) {
                if (this.inputBannerText.editSubtitleDropShadowColor.hasOwnProperty('hex')) {
                    request.data.bannerText.subtitleDropShadow = this.inputBannerText.editSubtitleDropShadowColor.hex.toLowerCase()
                } else {
                    request.data.bannerText.subtitleDropShadow = this.inputBannerText.editSubtitleDropShadowColor.toLowerCase()
                }
            }

            if (this.inputBannerText.editButtonColor.hasOwnProperty('hex')) {
                request.data.bannerText.buttonColor = this.inputBannerText.editButtonColor.hex.toLowerCase()
            } else {
                request.data.bannerText.buttonColor = this.inputBannerText.editButtonColor.toLowerCase()
            }
            if (this.inputBannerText.editButtonTextColor.hasOwnProperty('hex')) {
                request.data.bannerText.buttonTextColor = this.inputBannerText.editButtonTextColor.hex.toLowerCase()
            } else {
                request.data.bannerText.buttonTextColor = this.inputBannerText.editButtonTextColor.toLowerCase()
            }
            // console.log(request.data.bannerText)
            ApiWS.dispatch('makePut', request)
                    .then(response => {
                        let responseData = response.data;
                        //       console.log(responseData);
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Texto de Banner atualizado com sucesso!',
                                type: 'success'
                            })
                            this.dialogBannerText = false;
                            this.getBannerImages();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        deleteBannerText() {
            var auxId = '';
            if (this.language === 'PT') {
                this.imageSelected.bannersTexts.forEach(bt => {
                    if (bt.language === 'PT') {
                        auxId = bt.id;
                    }
                })
            }
            if (this.language === 'ES') {
                this.imageSelected.bannersTexts.forEach(bt => {
                    if (bt.language === 'ES') {
                        auxId = bt.id;
                    }
                })
            }
            // console.log(auxId)
            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.imageSelectedId + '/banners-text/' + auxId,
            }
            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            this.getBannerImages();
                            this.dialogBannerText = false;
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        toggleAddImage() {
            this.dialogAddImage = !this.dialogAddImage
            this.file = null;
            this.newWindowOption = false;
            this.link = '';
        },
        toggleBannerText(image) {
            this.inputBannerText.editPosition = '';
            this.inputBannerText.editTitle = '';
            this.inputBannerText.editSubTitle = '';
            this.inputBannerText.editTextColor = '';
            this.inputBannerText.editTitleFontSize = '';
            this.inputBannerText.editSubTitleFontSize = '';
            this.inputBannerText.editButtonText = '';
            this.inputBannerText.editButtonColor = '';
            this.inputBannerText.editButtonTextColor = ''
            this.inputBannerText.editUseDropShadow = false
            this.inputBannerText.editDropShadowColor = ''
            this.inputBannerText.editSubtitleColor = ''
            this.inputBannerText.editSubtitleuseDropShadow = false
            this.inputBannerText.editSubtitleDropShadowColor = ''
            // this.updateLanguage(this.language);
            //   console.log("language:" + this.language)
            this.imageSelected = image;
            this.imageSelectedId = image.id
            this.hasPT = false
            this.hasES = false
            if (image.bannersTexts == null) {
                this.bannerTextEditPT = {}
                this.bannerTextEditES = {}
                this.dialogBannerText = !this.dialogBannerText
                return
            }
            this.bannerTextEditPT = {}
            this.bannerTextEditES = {}

            image.bannersTexts.forEach(bt => {
                if (bt.language === 'PT') {
                    this.bannerTextEditPT = bt
                    console.log(bt)

                    if (bt.titleDropShadow && bt.titleDropShadow != null) {
                        this.bannerTextEditPT.useDropShadow = true;
                    }
                    if (bt.subtitleDropshadow && bt.subtitleDropshadow != null) {
                        this.bannerTextEditPT.subtitleuseDropShadow = true;
                    }

                    this.hasPT = true
                    // this.updateLanguage('PT')
                    //    console.log("PT bt")
                    //    console.log(bt)
                }
                if (bt.language === 'ES') {
                    this.bannerTextEditES = bt

                    if (bt.titleDropShadow && bt.titleDropShadow != null) {
                        this.bannerTextEditES.useDropShadow = true;
                    }
                    if (bt.subtitleDropShadow && bt.subtitleDropShadow != null) {
                        this.bannerTextEditES.subtitleuseDropShadow = true;
                    }

                    this.hasES = true
                    // this.updateLanguage('ES')
                    //     console.log("ES bt")
                    //     console.log(bt)
                }
            })
            this.updateLanguage(this.language);
            this.dialogBannerText = !this.dialogBannerText
        },
        toggleDelete(id) {
            this.deleteDialog = !this.deleteDialog;
            this.idToDelete = id;
        },
        toggleBtOFF() {
            this.inputBannerText.editPosition = '';
            this.inputBannerText.editTitle = '';
            this.inputBannerText.editSubTitle = '';
            this.inputBannerText.editTextColor = '';
            this.inputBannerText.editTitleFontSize = '';
            this.inputBannerText.editSubTitleFontSize = '';
            this.inputBannerText.editButtonText = '';
            this.inputBannerText.editButtonColor = '';
            this.inputBannerText.editButtonTextColor = ''
            this.inputBannerText.editUseDropShadow = false
            this.inputBannerText.editDropShadowColor = ''
            this.inputBannerText.editSubtitleColor = ''
            this.inputBannerText.editSubtitleuseDropShadow = false
            this.inputBannerText.editSubtitleDropShadowColor = ''
            this.dialogBannerText = false;
        },
        updateLanguage(language) {
            this.language = language
            this.setDescription()
        },
        setDescription() {
            switch (this.language) {
                case "PT":
                    this.inputBannerText.editPosition = this.bannerTextEditPT.position
                    this.inputBannerText.editTextColor = this.bannerTextEditPT.textColor
                    this.inputBannerText.editTitle = this.bannerTextEditPT.title
                    this.inputBannerText.editSubTitle = this.bannerTextEditPT.subTitle
                    this.inputBannerText.editTitleFontSize = this.bannerTextEditPT.titleFontSize
                    this.inputBannerText.editSubTitleFontSize = this.bannerTextEditPT.subTitleFontSize
                    this.inputBannerText.editButtonText = this.bannerTextEditPT.buttonText
                    this.inputBannerText.editButtonColor = this.bannerTextEditPT.buttonColor
                    this.inputBannerText.editButtonTextColor = this.bannerTextEditPT.buttonTextColor
                    this.inputBannerText.editUseDropShadow = (this.bannerTextEditPT.titleDropShadow != null && this.bannerTextEditPT.subtitleDropShadow != '') ? true : false
                    this.inputBannerText.editDropShadowColor = this.bannerTextEditPT.titleDropShadow
                    this.inputBannerText.editSubtitleColor = this.bannerTextEditPT.subtitleColor
                    this.inputBannerText.editSubtitleuseDropShadow = (this.bannerTextEditPT.subtitleDropShadow != null && this.bannerTextEditPT.subtitleDropShadow != '') ? true : false
                    this.inputBannerText.editSubtitleDropShadowColor = this.bannerTextEditPT.subtitleDropShadow
                    break
                case "ES":
                    this.inputBannerText.editPosition = this.bannerTextEditES.position
                    this.inputBannerText.editTextColor = this.bannerTextEditES.textColor
                    this.inputBannerText.editTitle = this.bannerTextEditES.title
                    this.inputBannerText.editSubTitle = this.bannerTextEditES.subTitle
                    this.inputBannerText.editTitleFontSize = this.bannerTextEditES.titleFontSize
                    this.inputBannerText.editSubTitleFontSize = this.bannerTextEditES.subTitleFontSize
                    this.inputBannerText.editButtonText = this.bannerTextEditES.buttonText
                    this.inputBannerText.editButtonColor = this.bannerTextEditES.buttonColor
                    this.inputBannerText.editButtonTextColor = this.bannerTextEditES.buttonTextColor
                    // this.inputBannerText.editUseDropShadow = this.bannerTextEditES.useDropShadow
                    this.inputBannerText.editUseDropShadow = (this.bannerTextEditES.titleDropShadow != null && this.bannerTextEditES.subtitleDropShadow != '') ? true : false
                    this.inputBannerText.editDropShadowColor = this.bannerTextEditES.titleDropShadow
                    this.inputBannerText.editSubtitleColor = this.bannerTextEditES.subtitleColor
                    this.inputBannerText.editSubtitleUseDropShadow = (this.bannerTextEditES.subtitleDropShadow != null && this.bannerTextEditES.subtitleDropShadow != '') ? true : false
                    this.inputBannerText.editSubtitleDropShadowColor = this.bannerTextEditES.subtitleDropShadow
                    break
                default:
                    this.inputBannerText.editPosition = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editTextColor = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editTitle = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editSubTitle = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editTitleFontSize = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editSubTitleFontSize = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editButtonText = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editButtonColor = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editButtonTextColor = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editUseDropShadow = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editDropShadowColor = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editSubtitleColor = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editSubtitleuseDropShadow = 'IDIOMA INVÁLIDO!';
                    this.inputBannerText.editSubtitleDropShadowColor = 'IDIOMA INVÁLIDO!';
                    break
            }
        },
        deleteImage() {
            let request = {
                url: '/api/staff/b2b/bannersB2B/' + this.bannerId + '/images/' + this.idToDelete
            }

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            this.getBannerImages();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.deleteDialog = !this.deleteDialog;
        }
    },
}
</script>

<style scoped>

.banners-card-header {
    background-color: #ce0000;
    color: white;
}


</style>