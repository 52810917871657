<template>
  <v-container fluid>
    <v-row class="content-header">
      <v-col>
        <page-title title="Compras com atraso" :subtitle="'A mostrar '+lines.length+' linhas'"></page-title>
      </v-col>
      <v-col class="text-right">
        <v-btn tile small color="primary" @click="getLines">
          Actualizar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="staff_v-card">
          <v-row>
            <v-col class="text-right">
              <v-btn outlined tile x-small color="error" @click="clearFilters">Limpar Filtros</v-btn>
            </v-col>
          </v-row>
          <v-simple-table class="staff_table">
            <thead>
            <tr>
              <th>Artigo</th>
              <th>Marca</th>
              <th>Descrição</th>
              <th>NumDocExterno</th>
              <th>NumEncomenda</th>
              <th>DataEntrega</th>
              <th class="text-center">Qtd.Encomendada</th>
              <th class="text-center">Qtd.Pendente</th>
            </tr>
            <tr class="filters_row">
              <td>
                <v-text-field
                    v-model="filters.product"
                    outlined
                    hide-details
                    single-line
                    @keyup="filterRows"
                ></v-text-field>
              </td>
              <td>
                <v-select
                    v-model="filters.brand"
                    :items="availableBrands"
                    outlined
                    dense
                    @change="filterRows"
                    item-text="TEXT"
                    item-value="VALUE"
                    hide-details
                ></v-select>
              </td>
              <td>
                <v-text-field
                    v-model="filters.description"
                    outlined
                    hide-details
                    single-line
                    @keyup="filterRows"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.external"
                    outlined
                    hide-details
                    single-line
                    @keyup="filterRows"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.doc"
                    outlined
                    hide-details
                    single-line
                    @keyup="filterRows"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.date"
                    outlined
                    hide-details
                    single-line
                    @keyup="filterRows"
                ></v-text-field>
              </td>
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="line in lines">
              <td>{{ line.Artigo }}</td>
              <td>{{ line.Marca }}</td>
              <td>{{ line.Descricao }}</td>
              <td>{{ line.NumDocExterno }}</td>
              <td>{{ line.NumDoc }}</td>
              <td class="text-right">
                <v-btn tile small outlined @click="toggleDialogDate(line)">
                  {{ line.DataEntrega }}
                </v-btn>
              </td>
              <td class="text-center">{{ line.Quantidade }}</td>
              <td class="text-center">{{ line.Quantidade - line.QuantTrans }}</td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDate" persistent max-width="500">
      <v-card>
        <v-card-title>
          Atualizar data
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="py-0">
              Atualizar data do {{ $t('product') }} {{ inputDialog.product }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-0 pb-0">
              <v-menu
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      v-model="inputDialog.arriveDate"
                      label="Data"
                      readonly
                      dense
                      outlined
                      hide-details
                      v-on="on"
                      style="width: 200px; float: left"
                      class="ml-4"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="inputDialog.arriveDate" locale="pt-pt"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn tile small @click="dialogDate = false">Fechar</v-btn>
          <v-spacer></v-spacer>
          <v-btn tile small @click="updateDate" color="success">Atualizar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

export default {
  data: () => ({
    datePicker: false,
    linesOriginal: [],
    lines: [],
    dialogCreateECF: false,
    suppliers: [],
    input: {
      supplier: ''
    },
    filters: {
      product: '',
      brand: '',
      description: '',
      doc: '',
      external: '',
      date: '',
    },
    inputDialog: {
      product: '',
      arriveDate: '',
      id: '',
    },
    dialogDate: false
  }),
  created() {
    document.title = 'Compras | STAFF';
    this.getLines();
  },
  computed: {
    availableBrands: function () {
      let brands = [...new Set(this.linesOriginal.flatMap(product => {
        return product.Marca;
        // else
        // return [];
      }))];
      brands = brands.sort().flatMap((product) => {
        if (product != "")
          return {TEXT: product, VALUE: product};
        else
          return [];
      })
      brands.splice(0, 0, {TEXT: 'Todas', VALUE: ''})
      return brands;
    },
  },
  methods: {
    getLines() {
      let loader = this.$loading.show();
      let request = {
        url: '/api/staff/purchases/outdated'
      }
      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.linesOriginal = responseData.lines;
              this.filterRows();
            }
            loader.hide();
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            loader.hide();
          })
    },
    clearFilters() {
      this.filters = {
        product: '',
        brand: '',
        description: '',
        doc: '',
        external: '',
        date: '',
      }
      this.filterRows();
    },
    filterRows() {
      this.lines = this.linesOriginal;
      if (this.filters.product !== '') {
        this.lines = this.lines.filter(item => item.Artigo.toUpperCase().includes(this.filters.product.toUpperCase()))
      }
      if (this.filters.description !== '') {
        this.lines = this.lines.filter(item => item.Descricao.toUpperCase().includes(this.filters.description.toUpperCase()))
      }
      if (this.filters.doc !== '') {
        this.lines = this.lines.filter(item => item.NumDoc.toString().includes(this.filters.doc))
      }
      if (this.filters.brand !== '') {
        this.lines = this.lines.filter(item => item.Marca.toString().includes(this.filters.brand))
      }
      if (this.filters.date !== '') {
        this.lines = this.lines.filter(item => item.DataEntrega.toUpperCase().includes(this.filters.date.toUpperCase()))
      }
      if (this.filters.external !== '') {
        this.lines = this.lines.filter(item => item.NumDocExterno != null && item.NumDocExterno.toUpperCase().includes(this.filters.external.toUpperCase()))
      }
    },
    toggleDialogDate(line) {
      this.inputDialog.product = line.Artigo;
      this.inputDialog.arriveDate = line.DataEntrega;
      this.inputDialog.id = line.Id;
      this.dialogDate = true;
    },
    updateDate() {
      let loader = this.$loading.show();

        let request = {
          url: '/api/staff/purchases/' + this.inputDialog.id + '/updateArriveDate',
          data: {
            input: this.inputDialog
          }
        }

        ApiWS.dispatch('makePost', request)
            .then(response => {
              let responseData = response.data;
              console.log(responseData);
              if (responseData.status === 'OK') {
                Alert.dispatch('show', {
                  snackbar: true,
                  text: 'Data atualizada com sucesso!',
                  type: 'success'
                })
                this.getLines();
                this.dialogDate = false;



              }
              loader.hide();
            })
            .catch(() => {
              loader.hide();
            })
    },
  },
}
</script>
