<template>
    <v-container>
<!--        <v-btn tile small color="primary" class="mr-3" @click="toggleDialogVisibility()">
            UPLOAD
        </v-btn>-->
        <ProductListSelection v-if="modalProductsPri" v-on:close-list="getProducts;modalProductsPri=false"
                              v-on:confirm-selection="confirmSelectionList" :b2bOnly="true"/>
        <v-row class="content-header">
            <v-col>
                <page-title title="Artigos Recomendados"
                            :subtitle="$t('show') + ' ' + lines.length + ' de ' + originalLines.length + ' Linhas'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small class="ml-2 mb-2" @click="resetFilters()" color="error">Limpar Filtros</v-btn>
                <v-btn tile small color="primary" class="ml-2 mb-2" @click="toggleDialogVisibility()">
                    Adicionar
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 20px;"></th>
                            <th style="width: 100px; cursor: pointer;" @click="orderList('productId')" class="text-center">Artigo
                                <i v-if="selectedHeader === 'productId'" class="fas"
                                   :class="[headers['productId'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('CodBarras')" class="text-center">CodBarras
                                <i v-if="selectedHeader === 'CodBarras'" class="fas"
                                   :class="[headers['CodBarras'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('Descricao')" class="text-center">Descricao
                                <i v-if="selectedHeader === 'Descricao'" class="fas"
                                   :class="[headers['Descricao'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('Familia')" class="text-center">Familia
                                <i v-if="selectedHeader === 'Familia'" class="fas"
                                   :class="[headers['Familia'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('SubFamilia')" class="text-center">SubFamilia
                                <i v-if="selectedHeader === 'SubFamilia'" class="fas"
                                   :class="[headers['SubFamilia'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('stock')" class="text-center">Stock
                                <i v-if="selectedHeader === 'stock'" class="fas"
                                   :class="[headers['stock'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('created_at')" class="text-center">Data de criação
                                <i v-if="selectedHeader === 'created_at'" class="fas"
                                   :class="[headers['created_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 200px; cursor: pointer;" @click="orderList('updated_at')" class="text-center">Data de atualização
                                <i v-if="selectedHeader === 'updated_at'" class="fas"
                                   :class="[headers['updated_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <span style="cursor: pointer; margin-left: 2px">
                                    <i v-if="selected === 0" class="far fa-square fa-lg" @click="selectAll()"></i>
                                    <i v-if="selected !== 0 && selected !== lines.length"
                                       class="far fa-minus-square fa-lg" @click="selectAll()"></i>
                                    <i v-if="selected !== 0 && selected === lines.length"
                                       class="fas fa-check-square fa-lg" style="color:#1976d2"
                                       @click="unselectAll()"></i>
                                </span>
                            </td>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.productId" @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.CodBarras" @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Descricao" @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Familia" @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.SubFamilia" @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.stock"
                                              @change="updateTable()"></v-text-field>
                            </th><th>
                                <v-text-field outlined hide-details v-model="filters.created_at"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.updated_at" @change="updateTable()"></v-text-field>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="line in pagedLines" style="cursor: pointer" @click="line.selected = !line.selected;selected = line.selected ? selected+1 : selected-1">
                            <td>
                                <i v-if="!line.selected" class="far fa-square fa-lg"></i>
                                <i v-if="line.selected" class="fas fa-check-square fa-lg" style="color:#1976d2"></i>
                            </td>
                            <td>{{ line.productId }}</td>
                            <td class="text-center">{{ line.CodBarras }}</td>
                            <td class="text-center">{{ line.Descricao }}</td>
                            <td class="text-center">{{ line.Familia }}</td>
                            <td class="text-center">{{ line.SubFamilia }}</td>
                            <td class="text-center">{{ line.stock }}</td>
                            <td class="text-center">{{ line.created_at }}</td>
                            <td class="text-center" width="11%">{{ line.updated_at }}</td>
                            <td class="text-center" width="11%">
                                <v-btn width="62%" small tile v-if="line.TratamentoDim !== 2" :to="'/products/products/'+line.productId" color="secondary">
                                    Editar
                                </v-btn>
                                <v-btn width="62%" small tile v-if="line.TratamentoDim === 2" :to="'/products/products/'+line.ArtigoPai" color="secondary">
                                    Editar
                                </v-btn>
                            </td>
                            <td class="text-center"  width="11%">
                                <v-btn tile small width="62%"color="error" class="mb-1 mt-1" @click="toggleDelete(line.productId)">
                                    REMOVER
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-select
                        v-model="pagination.rowsPerPage"
                        :items="filtersPerPage"
                        dense
                        outlined
                        hide-details
                        item-text="TEXT"
                        item-value="VALUE"
                        style="float: left; width: 100px;"
                ></v-select>
            </v-col>
            <v-col class="text-right">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        style="justify-content: flex-end;"
                ></v-pagination>

            </v-col>
            <v-col>
                <v-btn tile small color="error" @click="toggleDeleteMultiple()" class="mb-1 mt-1 mr-2" style="float: right">
                    REMOVER SELECIONADOS ({{selected}})
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog dark v-model="deleteDialog" overlay-color="white" overlay-opacity="0.5" persistent max-width="700">
            <v-card>
                <v-card-title>
                    Tem a certeza que quer remover este artigos dos recomendados?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="error" @click="deleteDialog = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="removeRecommendedProduct()">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog dark v-model="deleteDialogMultiple" overlay-color="white" overlay-opacity="0.5" persistent max-width="800">
            <v-card>
                <v-card-title>
                    Tem a certeza que quer remover os artigos selecionados dos recomendados?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="error" @click="deleteDialogMultiple = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="removeSelectedProducts()">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>

</template>

<script>

import ProductListSelection from "@/components/Products/ProductListSelection.vue";
import _ from "lodash";

export default {
    components: {ProductListSelection},

    data: function () {
        return {
            products: [],
            productsToAdd: [],
            idToDelete: null,
            deleteDialog: false,
            loading: false,
            modalProductsPri: false,
            dialogVisibility: false,
            deleteDialogMultiple: false,
            selectedLines : [],
            lines: [],
            total: [],
            originalLines: [],
            selected: 0,
            pagination: {
                rowsPerPage: 25,
                page: 1
            },
            pageInput: '',
            headers: {
                "productId": 'asc',
                "CodBarras": 'asc',
                "Descricao": 'asc',
                "Familia": 'asc',
                "SubFamilia": 'asc',
                "marca": 'asc',
                "stock": 'asc',
                "created_at": 'asc',
                "updated_at": 'asc'
            },
            filters: {
                productId: '',
                CodBarras: '',
                Descricao: '',
                Familia: '',
                SubFamilia: '',
                marca: '',
                stock: '',
                created_at: '',
                updated_at: '',
            },
            itemPerPage: [
                {text: '50', value: 50},
                {text: '100', value: 100},
                {text: '250', value: 250},
                {text: '500', value: 500}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            selectedHeader: "productId",
        }
    },
    created() {
        document.title = 'Recomendados | STAFF';
        this.getRecommendedProducts();
    },
    methods: {
        getRecommendedProducts(){
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/recommendedProducts'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.originalLines = responseData.recommendedProducts;
                            this.lines = responseData.recommendedProducts;
                            this.total = this.originalLines.length;
                        }
                        loader.hide();
                        if (responseData.status === "NOK") {
                            this.originalLines = [];
                            this.lines = [];
                            this.total = 0;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        removeRecommendedProduct() {
            let request = {
                url: '/api/staff/b2b/recommendedProducts/' + this.idToDelete
            }

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show',{
                                snackbar: true,
                                text: 'Removido com sucesso!',
                                type: 'success'
                            })
                            this.getRecommendedProducts();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.getRecommendedProducts();
            this.deleteDialog = !this.deleteDialog;
        },
        removeSelectedProducts(){
           var linesToRemove = [];
           this.lines.forEach(l => {
               if (l.selected){
                   linesToRemove.push(l.productId)
               }
           })
            let request = {
                url: '/api/staff/b2b/deleteRecommendedProducts',
                data: {
                    recommendedProducts: linesToRemove
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show',{
                                snackbar: true,
                                text: 'Removido com sucesso!',
                                type: 'success'
                            })
                            this.getRecommendedProducts();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.getRecommendedProducts();
            this.deleteDialogMultiple = !this.deleteDialogMultiple;
            this.selected = 0;
        },
        getProducts() {
            Product.dispatch('getProducts', filters)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.products = responseData.products;
                            this.totalItems = responseData.totalProducts;
                            this.brands = responseData.brands;
                            this.selected = 0;
                            window.scrollTo(0, 0)
                        }
                        this.loading = false
                    })
        },
        addProductsRecomended(products) {
            this.productsToAdd = [];
            let selectedProducts = [];
            var counter = 0;
            for (let i = 0; i < this.originalLines.length; i++) {
                for (let j = 0; j < products.length; j++) {
                    if (this.originalLines[i].productId == products[j].Artigo){
                        //console.log("Removed :" + products[j].Artigo);
                        products.splice(j,1);
                        counter ++;
                    }
                }
            }
            if (counter > 0){
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Artigos que tentou adicionar que já estão presentes na lista de recomendados: ' + counter,
                    type: 'success'
                })
            }
            //console.log(products);
            if (products == null) {
              //  console.log("Received products is empty!")
                return
            } else {
                selectedProducts = products;
               // console.log("Received products :")
               // console.log(selectedProducts)
                for (let product of selectedProducts) {
                    this.productsToAdd.push(product.Artigo);
                }
                let request = {
                    url: '/api/staff/b2b/recommendedProducts/',
                    data: {
                        recommendedProducts: this.productsToAdd
                    }
                }
                ApiWS.dispatch('makePost', request).then(response => {
                    let responseData = response.data
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Artigos enviados para a BD!',
                            type: 'success'
                        })
                        this.getRecommendedProducts();
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro a enviar Artigos!',
                            type: 'error'
                        })
                    }
                })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        openDialogProducts() {
            this.modalProductsPri = true;
        },
        toggleDialogVisibility() {
            this.dialogVisibility = !this.dialogVisibility;
            this.modalProductsPri = !this.modalProductsPri;
        },
        toggleDelete(productId){
            this.deleteDialog = !this.deleteDialog;
            this.idToDelete = productId;
        },
        toggleDeleteMultiple(){
            this.deleteDialogMultiple = !this.deleteDialogMultiple;
        },
        selectAll() {
            this.selected = 0;
            this.lines.forEach((line) => {
                line.selected = true;
                this.selected++;
            });
        },
        unselectAll() {
            this.selected = 0;
            this.lines.forEach((line) => {
                line.selected = false;
            });
        },
        confirmSelectionList: function (selectedProducts) {
          //  console.log("Got in confirmSelectionList!")
            this.addProductsRecomended(selectedProducts);
        },
        updateTable() {
            this.lines = this.originalLines

            if (this.filters.productId !== '') {
                this.lines = this.lines.filter(line => line.productId == this.filters.productId)
            }
            if (this.filters.CodBarras !== '') {
                this.lines = this.lines.filter(line => line.CodBarras != null && line.CodBarras.includes(this.filters.CodBarras))
            }
            if (this.filters.Descricao !== '') {
                this.lines = this.lines.filter(line => line.Descricao.includes(this.filters.Descricao))
            }
            if (this.filters.Familia !== '') {
                this.lines = this.lines.filter(line => line.Familia.includes(this.filters.Familia))
            }
            if (this.filters.SubFamilia !== '') {
                this.lines = this.lines.filter(line => line.SubFamilia != null && line.SubFamilia.includes(this.filters.SubFamilia))
            }
            if (this.filters.marca !== '') {
                this.lines = this.lines.filter(line => line.marca == this.filters.marca)
            }
             if (this.filters.stock !== '') {
                this.lines = this.lines.filter(line => line.stock == this.filters.stock)
            }
            if (this.filters.created_at !== '') {
                this.lines = this.lines.filter(line => line.created_at != null && line.created_at.includes(this.filters.created_at))
            }
            if (this.filters.updated_at !== '') {
                this.lines = this.lines.filter(line => line.updated_at != null && line.updated_at.includes(this.filters.updated_at))
            }

            this.total = this.lines.length
            this.pagination.page = 1;

        },
        resetFilters() {
            this.filters = _.mapValues(this.filters, () => '')
            this.updateTable()
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;

        },
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.pagination.rowsPerPage)
        },
        pagedLines: function () {
            return this.orderedLines.slice((this.pagination.page - 1) * this.pagination.rowsPerPage, this.pagination.page * this.pagination.rowsPerPage);
        },
        orderedLines: function () {
            return _.orderBy(this.lines, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    }
}
</script>

<style scoped>

</style>
