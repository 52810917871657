<template>
    <v-snackbar
            v-model="snackbar"
            multi-line
            right
            :timeout="timeout"
            top
            :color="type"
    >
        <v-icon class="float-right" v-text="'close'" @click="snackbar = false"/>
        {{ text }}
    </v-snackbar>
</template>

<script>
    export default {
        data () {
            return {
                // snackbar: true,
                y: 'top',
                x: 'right',
                mode: '',
                timeout: 5000,
                // text: 'Hello, I\'m a snackbar'
            }
        },
        computed: {
            snackbar: {
                get: function () {
                    return Alert.state.snackbar;
                },
                set: function (newValue) {
                    Alert.state.snackbar = newValue
                }
            },
            text() {
                return Alert.state.text;
            },
            type() {
                return Alert.state.type;
            }
        }
    }
</script>
