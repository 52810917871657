<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="6">
                <page-title title="INTRASTAT - Exportação" :subtitle="$t('show')+' '+rows.length+' linhas'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn color="primary" @click="download">Download</v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <div>
                    <v-menu v-model="menu2" transition="scale-transition" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                    v-model="filters.date"
                                    dense
                                    label="Data"
                                    outlined
                                    readonly
                                    v-on="on"
                                    style="width: 120px; float: left"
                                    hide-details
                                    @change="initialize"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="filters.date" @input="menu2 = false" @change="initialize"
                                       type="month"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div width="40px">
                    <v-select
                            v-model="filters.agrupar"
                            :items="filtersYesOrNo"
                            label="Agrupar"
                            dense
                            outlined
                            hide-details
                            @change="initialize"
                            item-text="TEXT"
                            item-value="VALUE"
                            style="width: 120px; float: left; margin-left: 20px"
                    ></v-select>
                </div>
            </v-col>
        </v-row>

        <div class="staff_v-card">
            <v-simple-table class="staff_table">
                <thead>
                <tr>
                    <th class="text-center">Ano</th>
                    <th class="text-center">Mês</th>
                    <th>NC (Código)</th>
                    <th class="text-center">País</th>
                    <th class="text-right">VALFAC (Valor Faturado)</th>
                    <th class="text-right">UNSUP (Unidade Suplementar)</th>
                    <th class="text-right">Massa (Peso Kg)</th>
                    <th class="text-right">ADQNIF (NIF Adquirente)</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="19">
                        <v-progress-linear indeterminate></v-progress-linear>
                    </td>
                </tr>
                <tr v-for="row in rows">
                    <td class="text-center">{{ row.Ano}}</td>
                    <td class="text-center">{{ row.Mes}}</td>
                    <td>{{ row.NC}}</td>
                    <td class="text-center">{{ row.PAIS}}</td>
                    <td class="text-right">{{ $money(row.VALFAC)}}</td>
                    <td class="text-right">{{ row.UNSUP}}</td>
                    <td class="text-right">{{ row.MASSA}}</td>
                    <td class="text-right">{{ row.ADQNIF}}</td>
                </tr>
                </tbody>
            </v-simple-table>
        </div>

        <v-row class="mt-6">
            <v-col cols="12" md="6">
                <page-title title="Artigos com informação em falta"
                            :subtitle="$t('show')+' '+artigos.length+' linhas'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn color="primary" href="https://webinq.ine.pt/public/pages/ConsultaNomenclatura.aspx" target="_blank">CONSULTAR CÓDIGOS</v-btn>
            </v-col>
        </v-row>

        <div class="staff_v-card">
            <v-simple-table class="staff_table">
                <thead>
                <tr>
                    <th>Artigo</th>
                    <th>Descrição</th>
                    <th>Marca</th>
                    <th>Familia</th>
                    <th>SubFamilia</th>
                    <th style="width: 80px" class="text-center">Peso</th>
                    <th style="width: 120px">CDU_INTSTAT</th>
                    <th style="width: 1px"></th>
                    <th style="width: 1px"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="19">
                        <v-progress-linear indeterminate></v-progress-linear>
                    </td>
                </tr>
                <tr v-for="row in artigos" :class="selected === row.Artigo ? 'green lighten-3' : ''">
                    <td>
                        <a :href="'/products/products/'+row.Artigo" target="_blank" class="ml-1">{{ row.Artigo }}</a>
                    </td>
                    <td>{{ row.Descricao}}</td>
                    <td>{{ row.Marca}}</td>
                    <td>{{ row.Familia}}</td>
                    <td>{{ row.SubFamilia }}</td>
                    <td>
                        <v-text-field
                                v-model="row.Peso"
                                auto-grow
                                outlined
                                dense
                                hide-details
                                rows="1"
                                row-height="5"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-text-field
                                v-model="row.CDU_INTSTAT"
                                auto-grow
                                outlined
                                dense
                                hide-details
                                rows="1"
                                row-height="5"
                        ></v-text-field>
                    </td>
                    <td class="text-center">
                        <v-btn small color="green" @click="sugestao(row)"
                               :loading="loadingSugestao && selected === row.Artigo">Sugestão
                        </v-btn>
                    </td>
                    <td class="text-center">
                        <v-btn small color="red" @click="guardar(row)">Guardar</v-btn>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
        </div>

        <v-dialog v-model="dialog" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    Selecionar Código Intrastat
                </v-card-title>
                <v-card-text>
                    <v-list nav dense>
                        <v-list-item-group color="success">
                            <v-list-item v-for="sugestao in sugestoes" :key="sugestao.CDU_INTSTAT" @click="inserirCodigo(sugestao.CDU_INTSTAT)">
                                <v-list-item-content>
                                    <v-list-item-title v-text="sugestao.CDU_INTSTAT"></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title v-text="sugestao.contagem"></v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <v-list-item-title
                                            v-text="Number(sugestao.percentagem).toFixed(2)+ '%'"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data: () => ({
            dialog: false,
            loading: true,
            loadingSugestao: false,
            selected: '',
            rows: [],
            sugestoes: [],
            artigos: [],
            filters: {
                date: new Date().toISOString().substr(0, 7),
                agrupar: 1
            },
            filtersYesOrNo: [
                {TEXT: i18n.t('yes'), VALUE: 1},
                {TEXT: i18n.t('no'), VALUE: 0},
            ],
            date: new Date().toISOString().substr(0, 7),
            menu2: false,
            menu: false,
            modal: false,
        }),
        computed: {},
        created() {
            document.title = 'Intrastat';
            this.initialize()
        },
        methods: {
            initialize() {
                this.loading = true;
                this.artigos = [];
                let request = {
                    url: '/api/staff/primavera/intrastat',
                    data: {
                        filters: this.filters,
                        artigos: this.artigos,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.rows = responseData.rows;
                            this.artigos = responseData.artigos;
                        } else {

                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            download() {
                let request = {
                    url: '/api/staff/primavera/intrastat/download/' + this.filters.date,
                };
                ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/csv'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'Intrastat_' + Date.now().toString() + '.csv';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            guardar(artigo) {
                let request = {
                    url: '/api/staff/primavera/intrastat/guardar',
                    data: {
                        artigo: artigo,
                        filters: this.filters,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.selected = '';
                            this.artigos = responseData.artigos;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Gravado com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            sugestao(filter) {
                this.selected = filter.Artigo;
                this.loadingSugestao = true;
                this.sugestoes = [];
                let request = {
                    url: '/api/staff/primavera/intrastat/sugestao',
                    data: {
                        filter: filter,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.dialog = true;
                            this.sugestoes = responseData.data;
                            this.loadingSugestao = false;
                        }
                    })
                    .catch(() => {
                        this.loadingSugestao = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            inserirCodigo(cduintrastat) {
                this.dialog = false;
                this.artigos.forEach((artigo) => {
                    if (artigo.Artigo === this.selected) {
                        artigo.CDU_INTSTAT = cduintrastat;
                    }
                });
            },
        }
    }
</script>
