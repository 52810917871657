<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Histórico Encomendas Amazon"
                        :subtitle="'A mostrar '+orders.length +' de '+totalOrders+' encomendas.'"
                ></page-title>
            </v-col>
            <v-col cols="3">
                <v-text-field label="Importar Custos" @click='pickFile' v-model='imageName' prepend-icon='attach_file'></v-text-field>
                <input
                        type="file"
                        style="display: none"
                        ref="image"
                        accept="text/plain"
                        @change="onFilePicked"
                >
            </v-col>
            <v-col cols="1">
                <v-btn @click="importFile()">Importar</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th width="200">Origem</th>
                        <th style="width: 40%">Artigos</th>
                        <th style="width: 12%">Portes</th>
                        <th>Total</th>
                        <th>Cliente</th>
                        <th>Documentos</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="filters_row">
                        <th>
                            <v-text-field
                                    v-model="filters.amazonOrderId"
                                    outlined
                                    single-line
                                    @keyup.enter="getOrders"
                                    hide-details
                                    dense
                            ></v-text-field>
                        </th>
                        <th>
                            <v-text-field
                                    v-model="filters.product"
                                    outlined
                                    single-line
                                    @keyup.enter="getOrders"
                                    hide-details
                                    dense
                            ></v-text-field>
                        </th>
                        <th></th>
                        <th></th>
                        <th>
                            <v-text-field
                                    v-model="filters.name"
                                    outlined
                                    single-line
                                    @keyup.enter="getOrders"
                                    hide-details
                                    dense
                            ></v-text-field>
                        </th>
                        <th>

                        </th>
                    </tr>
                    <tr v-for="order in orders">
                        <td class="text-center">
                            <strong>{{ order.salesChannel }}</strong> <br>
                            {{ order.amazonOrderId }} <br>
                            {{ order.reportingDate }} <br>
                            FBA - {{ order.fulfillmentCenterId }} {{ order.shipFromCountry }}
                        </td>
                        <td>
                            <v-simple-table style="background-color: transparent !important;">
                    <thead>
                    <tr>
                        <th style="width: 62%">Código</th>
                        <th class="text-center" style="width: 5%">Qty.</th>
                        <th class="text-center" style="width: 12%">Preço</th>
                        <th class="text-center" style="width: 10%">IVA</th>
                        <th class="text-center" style="width: 5%">Promoção</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="product in order.products">
                        <td><strong>{{ product.sku }}</strong> <br>{{ product.productName }}</td>
                        <td class="text-center">{{ product.quantityShipped }}</td>
                        <td class="text-center">{{ $2decimal(product.itemPrice) }} {{ product.currency }}</td>
                        <td class="text-center">
                            {{ $2decimal(product.itemTax) }} {{ product.currency }}
                        </td>
                        <td class="text-center">{{ $2decimal(product.itemPromotionDiscount) }} {{
                                product.currency
                            }}
                        </td>
                        <!--                                    <td class="text-center" style="white-space: nowrap">{{ $2decimal(parseFloat(product.itemPrice) + parseFloat(product.itemTax)) }} {{ product.currency }}</td>-->
                    </tr>
                    </tbody>
                </v-simple-table>
                </td>
                <td>
                    <v-simple-table style="background-color: transparent !important;">
                        <thead>
                        <tr>
                            <th>Portes</th>
                            <th>IVA</th>
                            <th>Desconto</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ $2decimal(order.shippingPrice) }} {{ order.currency }}</td>
                            <td>{{ $2decimal(order.shippingTax) }} {{ order.currency }}</td>
                            <td>{{ $2decimal(order.shipPromotionDiscount) }} {{ order.currency }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </td>
                <td>{{ $2decimal(totalOrder(order)) }} {{ order.currency }}</td>
                <td>
                    <strong>{{ order.buyerName }}</strong><br>
                    <span>{{ order.shipAddress1 }}</span><br>
                    <span v-if="order.shipAddress2">{{ order.shipAddress2 }}<br></span>
                    <span v-if="order.shipAddress3">{{ order.shipAddress3 }}<br></span>
                    {{ order.shipPostalCode }} - {{ order.shipCity }} <br>
                    <strong>{{ order.shipCountry }}</strong> <br>
                    <strong>NIF: {{ order.nif || 999999990 }}</strong>
                </td>
                <td>
                    <v-simple-table style="background-color: transparent !important;">
                        <thead>
                        <tr>
                            <th>Documento</th>
                            <th>Merc.</th>
                            <th>IVA</th>
                            <th>Outros</th>
                            <th>Total</th>
                            <th>Anul.</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="invoice in order.invoices">
                            <td style="white-space: nowrap">{{ invoice.Documento }}</td>
                            <td>{{ invoice.TotalMerc }} {{ order.currency }}</td>
                            <td>{{ invoice.TotalIva }} {{ order.currency }}</td>
                            <td>{{ invoice.TotalOutros }} {{ order.currency }}</td>
                            <td :style="{'background-color': $2decimal(totalOrder(order)) != invoice.TotalDocumento ? '#ffb8c2' : '' }">
                                {{ invoice.TotalDocumento }} {{ order.currency }}
                            </td>
                            <td class="text-center">
                                <i class="fas fa-check" style="color: green" v-if="invoice.Anulado == 1"></i>
                                <i class="fas fa-times" style="color: red" v-if="invoice.Anulado == 0"></i>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </td>
                </tr>
                </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="getOrders"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: function () {
        return {
            orders: [],
            pagination: {
                rowsPerPage: 100,
                page: 1
            },
            totalOrders: 0,
            itemPerPage: [
                {text: '50', value: 50},
                {text: '100', value: 100},
                {text: '250', value: 250},
                {text: '500', value: 500}
            ],
            imageName: '',
            imageUrl: '',
            imageFile: '',
            filters: {
                amazonOrderId: '',
                product: '',
                document: '',
                name: ''
            },
        }
    },
    created() {
        this.getOrders();
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.totalOrders / this.pagination.rowsPerPage)
        }
    },
    methods: {
        getOrders() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/bikeplanet/orders',
                data: {
                    pagination: this.pagination,
                    filters: this.filters
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.orders = responseData.orders;
                            this.totalOrders = responseData.totalOrders;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        totalOrder(order) {
            let someProducts = 0;
            order.products.forEach(function (product) {
                someProducts += parseFloat(product.itemPrice) + parseFloat(product.itemTax) + parseFloat(product.itemPromotionDiscount);
            });
            if (parseFloat(order.shippingPrice) + parseFloat(order.shipPromotionDiscount) > 0) {
                someProducts += parseFloat(order.shippingPrice) + parseFloat(order.shippingTax) + parseFloat(order.shipPromotionDiscount);
            }

            return someProducts;
        },
        pickFile() {
            this.$refs.image.click()
        },
        onFilePicked(e) {
            const files = e.target.files
            if (files[0] !== undefined) {
                this.imageName = files[0].name
                if (this.imageName.lastIndexOf('.') <= 0) {
                    return
                }
                const fr = new FileReader()
                fr.readAsDataURL(files[0])
                fr.addEventListener('load', () => {
                    this.imageUrl = fr.result
                    this.imageFile = files[0] // this is an image file that can be sent to server...
                })
            } else {
                this.imageName = ''
                this.imageFile = ''
                this.imageUrl = ''
            }
        },
        importFile() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/bikeplanet/disbursements/import',
                file: this.imageFile
            }

            ApiWS.dispatch('makeFile', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Ficheiro importado com sucesso!',
                                type: 'success'
                            })
                        } else if (responseData.status === 'WAR') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.message,
                                type: 'warning'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao importar ficheiro!',
                                type: 'error'
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        }
    }
}
</script>
