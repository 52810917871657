<style>
.selectedPage {
    font-weight: bold;
    color: blue;
    font-size: 21px;
}

.selectedPage:hover {
    cursor: default !important;
}

.selectablePage {
    margin-left: 15px;
    text-align: center;
    display: inline-block;
    width: 1rem;
}

.selectablePage:hover {
    cursor: pointer;
    font-size: 21px;
    color: #5a5aff;
}

</style>
<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col class="col-4">
                <page-title
                        title="Lista de encomendas edifact"
                        :subtitle="$t('show') + ' ' + (pagination.per_page*pagination.page >= orders.length ? (orders.length - pagedList.length + ' - ' + orders.length)  : (((pagedList.length*pagination.page)-pagination.per_page) + ' - ' + pagedList.length*pagination.page)) + ' de ' +  orders.length + ' encomendas'"
                ></page-title>
            </v-col>
            <v-col class="text-center pt-7 col-6">
                <v-pagination
                        v-model="pagination.page"
                        :length="totalPages"
                ></v-pagination>
                <!--        <template v-if="totalPages > 0">
                          <v-btn tile small :disabled="pagination.page <= 1" @click="pagination.page&#45;&#45;">
                            <<
                          </v-btn>
                          <template v-if="totalPages >= 7">
                          <u @click="pagination.page = 1"
                              class="selectablePage"
                             :class="{selectedPage: pagination.page == 1}">1</u>
                          <span style="margin-left:15px">...</span>
                          </template>
                          <template v-if="totalPages > 0" v-for="(page) in totalPages">
                            <u
                                @click="pagination.page = page"
                                :class="{selectedPage: page==pagination.page}"
                                v-show="totalPages < 7 || (((pagination.page+4 > totalPages && page >  pagination.page-4-((pagination.page+4)-totalPages))
                                      || (pagination.page-4 <= 0 && page <  pagination.page+4+Math.abs(pagination.page-4)+1)
                                      || ((page > (pagination.page-4)) && (( page < (pagination.page+4)) && pagination.page+4 <= totalPages))
                                      ) && page != 1 && page != totalPages)"
                               class="selectablePage">
                              {{ page }}
                            </u>
                            &lt;!&ndash;          <span v-show="counter < 6 " style="margin-left: 5px">{{page}}</span>&ndash;&gt;
                          </template>
                          <template v-if="totalPages >= 7">
                          <span style="margin-left:15px">...</span>
                          <u @click="pagination.page = totalPages"
                              :class="{selectedPage: pagination.page == totalPages}"
                            class="selectablePage">
                            {{ totalPages }}
                          </u>
                            </template>
                          <v-btn style="margin-left:15px" tile small :disabled="pagination.per_page*pagination.page >= orders.length"
                                 @click="pagination.page++">
                            >>
                          </v-btn>
                        </template>-->
            </v-col>
            <v-col class="text-right col-2">
                <v-btn tile small class="ml-2 mb-2" @click="getOrderList()" color="info"><i
                        class="fas fa-sync-alt mr-1"></i>Atualizar
                </v-btn>
            </v-col>
        </v-row>

        <!-- LISTA -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th width="70" class="text-center" @click="orderList('messageId')">ID</th>
                            <th width="70" class="text-center" @click="orderList('clientId')">Cliente</th>
                            <th width="80" class="text-center" @click="orderList('orderDate')">Data</th>
                            <th width="80" class="text-center" @click="orderList('earliestDeliveryDate')">A partir de
                            </th>
                            <th width="180" class="text-center" @click="orderList('latestDeliveryDate')">Data limite
                            </th>
                            <th width="80" class="text-center" @click="orderList('taxIva')">IVA(%)</th>
                            <th width="100" class="text-center" @click="orderList('totalIva')">IVA(€)</th>
                            <th width="100" class="text-center" @click="orderList('totalPrice')">Valor Liquido</th>
                            <th width="150" class="text-center" @click="orderList('totalProductsRequested')">Quantidade
                                Produtos
                            </th>
                            <th class="text-center" width="80" @click="orderList('status')">Estado</th>
                            <th width="130">ECL</th>
                            <th width="130">GT</th>
                            <th width="130">FA</th>
                            <th width="80"></th>
                            <th width="80"></th>

                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                    v-model="filters.id.value"
                                    outlined
                                    dense
                                    hide-details
                                    @keypress.enter="getOrderList"
                            ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.client.value"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="getOrderList"
                                ></v-text-field>
                            </td>
                            <td>
                                <input
                                        type="date"
                                        v-model="filters.orderDate.value"
                                        @change="getOrderList"
                                />

                            <td>
                                <input
                                        type="date"
                                        v-model="filters.earliestDate.value"
                                        @change="getOrderList"
                                />
                            </td>
                            <td>

                            </td>
                            <td>
                            </td>
                            <td>
                            </td>

                            <td></td>
                            <td>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.status.value"
                                        :items="availableStatus"
                                        item-text="type"
                                        item-value="value"
                                        outlined
                                        dense
                                        hide-details
                                        @change="getOrderList"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.ecl.value"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="getOrderList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.deliveryNote.value"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="getOrderList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.Invoice.value"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="getOrderList"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="15">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="order in pagedList">
                            <td class="text-center">{{ order.messageId }}</td>
                            <td class="text-center">{{ order.clientId }}</td>
                            <td class="text-center">{{ order.orderDate }}</td>
                            <td class="text-center">{{ order.earliestDeliveryDate }}</td>
                            <td class="text-center">{{ order.latestDeliveryDate }}</td>
                            <td class="text-center">{{ order.taxIVA }}%</td>
                            <td class="text-center">{{ order.totalIVA }}€</td>
                            <td class="text-center">{{ order.totalPrice }}€</td>
                            <td class="text-center">{{ order.totalProductsRequested }}</td>
                            <td class="text-center">{{
                                    order.status == "created" ? "Por confirmar" : order.status
                                }}
                            </td>
                            <td class="text-center">{{ order.ECL }} <i class="far fa-file-pdf" style="color: red; cursor: pointer"
                                                                       @click="downloadPDF(splitStrings(order.ECL,0), 'ECL', splitStrings(order.ECL,1))"></i></td>
                            <td class="text-center">{{ order.DeliveryNote }} <i class="far fa-file-pdf" style="color: red; cursor: pointer"
                                                                                @click="downloadPDF(splitStrings(order.DeliveryNote,0), 'GT', splitStrings(order.DeliveryNote,1))"></i></td>
                            <td class="text-center">{{ order.Invoice }} <i class="far fa-file-pdf" style="color: red; cursor: pointer"
                                                                           @click="downloadPDF(splitStrings(order.Invoice,0), 'FA', splitStrings(order.Invoice,1))"></i></td>
                            <td>
                                <!--                <v-btn small tile color="error" @click="closePick(order.id)" v-if="$writePermission('orders/pick')">
                                                  Arquivar
                                                </v-btn>-->
                            </td>
                            <td>
                                <v-btn tile small color="warning"
                                       @click="downloadDialog(order)">Ficheiros
                                </v-btn>
                            </td>
                            <td>
                                <v-btn tile small color="primary"
                                       :to="'/orders/edifact/' + order.messageId + '?status=' + order.status">Analisar
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogDowload" @close="selectedMessageId = null" persistent max-width="400">
            <v-card v-if="selectedOrder != null">
                <v-card-title>
                    Transferência de ficheiros
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row v-if="selectedOrder.filename != null">
                            <v-col>
                                <v-btn tile small color="warning"
                                       @click="downloadFile('request')">
                                    Ficheiro do pedido
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedOrder.ECL != null">
                            <v-col>
                                <v-btn tile small color="warning"
                                       @click="downloadFile('confirmation')">
                                    Confirmação da encomenda
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedOrder.DeliveryNote != null">
                            <v-col>
                                <v-btn tile small color="warning"
                                       @click="downloadFile('delivery')">
                                    Ficheiro de envio
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="selectedOrder.Invoice != null">
                            <v-col>
                                <v-btn tile small color="warning"
                                       @click="downloadFile('invoice')">
                                    Ficheiro de fatura
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogDowload = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </v-container>
</template>

<script>

import _ from "lodash";

export default {
    data: function () {
        return {
            loading: false,
            orders: [],
            dialogDowload: false,
            selectedOrder: null,
            ordersOriginal: [],
            selectedPick: {},
            availableStatus: [
                {type: "Todos", value: ""},
                {type: "Por Confirmar", value: "created"},
                {type: "Faturado", value: "faturado"},
                {type: "Confirmado", value: "confirmado"},
                {type: "Cancelado", value: "canceled"}
            ],
            filters: {
                id: {value: '', exactValue: false},
                ecl: {value: '', exactValue: false},
                deliveryNote: {value: '', exactValue: false},
                Invoice: {value: '', exactValue: false},
                client: {value: '', exactValue: false},
                status: {value: "", exactValue: false},
                orderDate: {value: null, exactValue: true},
                earliestDate: {value: null, exactValue: true}
            },
            dialogClosePick: false,
            closePickId: '',
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
            products: [],
            currentRecords: "0",
            countPerPageList: [50, 100, 200, 350, 500],

            currentPageOffset: 0,
            currentPage: 1,

            pagination: {
                page: 1,
                per_page: 50
            },

            selectedHeader: "orderDate",

            headers: {
                "messageId": "asc",
                "clientId": "asc",
                "orderDate": "desc",
                "earliestDeliveryDate": "asc",
                "latestDeliveryDate": "asc",
                "taxIVA": "asc",
                "totalIVA": "asc",
                "totalPrice": "asc",
                "totalProductsRequested": "desc",
                "status": "asc"
            },
        }
    },
    computed: {
        totalPages: function () {
            return Math.ceil(this.orderedList.length / this.pagination.per_page);
        },
        pagedList: function () {
            return this.orderedList.slice((this.pagination.page - 1) * this.pagination.per_page, this.pagination.page * this.pagination.per_page);
        },
        orderedList: function () {
            return _.orderBy(this.orders, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    },
    created() {
        document.title = 'Edifact | STAFF'
        this.getOrderList();
    },
    methods: {
        splitStrings(string,index){
            var aux = [];
            aux = string.split("/");
            return aux[index];
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;
        },
        getOrderList() {
            this.loading = true;
            this.orders = [];

            this.ordersOriginal = [];

            let request = {
                url: '/api/staff/edifact?filters=' + btoa(JSON.stringify(this.filters)) //+ '&offset=' + this.currentPageOffset + '&count=' + this.countPerPage
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.orders = responseData.orders;
                            this.ordersOriginal = responseData.orders;
                            //this.filtersPickingList();
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    })
        },
        downloadDialog(order){
            this.dialogDowload = true;
            this.selectedOrder = order;
        },
        downloadPDF(serie, tipodoc, numdoc) {
            let request = {
                url: api.orderPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let request = {
                                url: api.orderDownloadPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
                            }
                            ApiWS.dispatch('makeDownload', request)
                                    .then(response => {
                                        var file = new Blob([response.data], {
                                            type: 'application/pdf'
                                        });
                                        var fileURL = URL.createObjectURL(file);
                                        var a = document.createElement('a');
                                        a.href = fileURL;
                                        a.target = '_blank';
                                        a.download = serie + '_' + tipodoc + '_' + numdoc + '.pdf';
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                    .catch(response => {
                                        Alert.dispatch('show', {
                                            snackbar: true,
                                            text: 'Erro de servidor!',
                                            type: 'error'
                                        })
                                    })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar PDF!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        downloadFile(type){
            this.loading = true;
            let selectedOrder = this.selectedOrder
            let request = {
                url: api.edifactGlobal() + '/' + selectedOrder.messageId + '/downloads/' + type + (type == 'request' ? ('?name=' + selectedOrder.filename) : '')
            }
            ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/text'
                        });
                        //xhr.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';

                        let filename="file"
                        switch (type) {
                            case "request":
                                filename = selectedOrder.filename != null ? selectedOrder.filename : Date.now().toString() + '.txt';
                                break;
                            case "confirmation":
                                filename = "ORDRSP_" + selectedOrder.messageId + ".EDI";
                                break;
                            case "delivery":
                                filename = "DESADV_" + selectedOrder.messageId + ".EDI";
                                break;
                            case "invoice":
                                filename = "INVOIC_" + selectedOrder.messageId + ".EDI";
                                break;
                        }
                        a.download = filename;

                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        this.loading = false;
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loading = false;
                    })

        }
    }
}
</script>
