<style>

.infos {
    .shipping-info {
        font-size: 14px;
    }

    .infos-right {
        display: flex;
        flex-direction: column;
        padding: 0;
    }
}

.input-barcode {
    border-radius: 0;
    background-color: #E3F2FD;

    .v-input__slot {
        background-color: #E3F2FD;
        font-size: 20px;
        padding: 8px 16px;
        width: 100%;
    }
}

.page-content {
    .content-title {
        text-decoration: underline;
        text-decoration-color: rgba(219, 8, 18, 1);
    }

    .products-table {
        border: 1px solid rgba(0, 0, 0, 0.1);
    }

    .table-header {
        background-color: black;

        .table-title {
            color: white;
        }
    }

    .box-quantity-input {
        background: white !important;
        border-radius: 0;
        font-size: 12px;

        .v-input__slot {
            min-height: 30px !important;
        }
    }


    .status-row {
        padding: 0 !important;

        .quantity-success {
            //background-color: rgba(76, 175, 80, 0.2);
            background-color: rgba(0, 255, 25, 0.40);
            padding: 4px !important;
            border-radius: 16px;
        }

        .quantity-to-do {
            background-color: rgba(255, 82, 82, 0.4);
            padding: 4px;
            border-radius: 16px;
        }
    }

    .product-table-line {
        &:hover {
            background: rgba(0, 150, 255, 0.4) !important;
        }
    }

    .active-line{
        box-shadow: 0 0 4px black;
        td{
            &:first-child{
                border-left: solid 2px black !important;
            }
            &:last-child{
                border-right: solid 2px black !important;
            }
            border-top: 2px solid black !important;
            border-bottom: 2px solid black !important;
        }
    }

    .invalid-line{
        td{
            &:first-child{
                border-left: solid 1px red !important;
            }
            &:last-child{
                border-right: solid 1px red !important;
            }
            border-top: 1px solid red !important;
            border-bottom: 1px solid red !important;
        }
    }

    .selected-box{
        background: rgb(255, 255, 153) !important;
    }

    .table-text {
        font-size: 12px !important;
    }

    .box-buttons {
        border-radius: 0;
    }
}


</style>
<template>
    <v-container fluid>

        <!-- PAGE HEADER -->
        <v-row class="content-header">
            <v-col cols="4">
                <page-title
                        title="Configurar packing list"
                        :subtitle="'Documento: ' + docType + '/' + docNumber"
                >
                </page-title>
            </v-col>
            <v-col>
                <v-text-field outlined dense hide-details class="input-barcode" type="text" ref="barcode"
                              placeholder="Cód. Barras" label="Cód. Barras"
                              @focus="$event.target.select()"
                              @keypress.enter="focusInput('quantity')">
                </v-text-field>
            </v-col>
            <v-col>
                <v-text-field outlined dense hide-details class="input-barcode" type="text" ref="quantity"
                              placeholder="Quantidade" label="Quantidade"
                              @focus="$event.target.select()" @keypress.enter="pickProduct">
                </v-text-field>
            </v-col>
            <v-col style="justify-content: flex-end;align-items:center;display: flex">
                <v-btn tile small :color="predefineQuantity === true ? 'success' : ''" @click="btnPredefineQuantity()">
                    Quantidade 1
                </v-btn>
                <v-btn small dense tile color="error" @click="dialogClearBox = true">
                    limpar caixa
                </v-btn>
            </v-col>
        </v-row>

        <!-- LOADING -->
        <template v-if="loading">
            <v-row class="mt-12">
                <v-col class="text-center">
                    <i class="fas fa-cog fa-spin fa-3x"></i>
                </v-col>
            </v-row>
        </template>

        <template v-if="!loading">
            <!-- INFOS -->
            <v-row class="infos pb-3">
                <v-col class="pb-0" style="display: flex; justify-content: space-around;">
                    <p class="mb-0 shipping-info">
                        <strong>Doc:</strong>
                        {{ docType }}/{{ docNumber }}
                    </p>
                    <p class="mb-0 shipping-info"><strong> Cliente: </strong>{{ shippingDoc.Entidade }} -
                        {{ shippingDoc.Nome }}</p>
                    <p class="mb-0 shipping-info"><strong>Total: </strong><span
                            style="font-size: 15px"> {{ shippingDoc.TotalMerc }}</span>€</p>
                </v-col>

                <!--  USE THIS COL FOR DEBUG    -->
                <!--                <v-col class="infos-right mr-4 pb-0">-->
                <!--                    <v-text-field outlined dense hide-details class="pt-1 input-barcode" type="text" ref="barcode"-->
                <!--                                  placeholder="Cód. Barras" label="Cód. Barras"-->
                <!--                                  @keypress.enter="focusInput('quantity')">-->
                <!--                    </v-text-field>-->
                <!--                    <v-text-field outlined dense hide-details class="pt-1 input-barcode" type="text" ref="quantity"-->
                <!--                                  placeholder="Quantidade" label="Quantidade"-->
                <!--                                  @focus="$event.target.select()" @keypress.enter="pickProduct">-->
                <!--                    </v-text-field>-->
                <!--                    &lt;!&ndash; PICKING &ndash;&gt;-->
                <!--                    &lt;!&ndash;                        {{selectedBox}}&ndash;&gt;-->
                <!--                </v-col>-->
            </v-row>

            <!-- PAGE CONTENT -->
            <v-divider class="mt-2"/>
            <v-row class="mt-0 page-content">
                <v-col>
                    <!-- ACTIONS -->
                    <v-row>
                        <v-col class="pb-0">
                            <h4 class="pl-1 content-title">LISTA DE ARTIGOS
                            </h4>
                            <h5 class="pl-1 font-weight-medium">total produtos: <strong>{{totalProducts}}</strong> / total volumes: <strong>{{numBoxes}}</strong></h5>
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn x-small color="warning" v-bind="attrs" v-on="on" @click="fillBox()">
                                        preencher caixa
                                    </v-btn>
                                </template>
                                <span>Inserir todos os artigos na caixa</span>
                            </v-tooltip>

                        </v-col>
                        <v-col class="pb-0" style="display: flex; justify-content: end; align-items: center;">
                            <v-btn outlined small dense color="red" elevation="2" class="mr-2 box-buttons"
                                   :disabled="parseInt(numBoxes) === 1"
                                   @click="removeBox">
                                <v-icon size="20">mdi-minus</v-icon>
                                Caixa
                            </v-btn>
                            <v-btn outlined small dense color="green" elevation="2" class="mr-2 box-buttons"
                                   @click="addBox">
                                <v-icon size="20">mdi-plus</v-icon>
                                Caixa
                            </v-btn>
<!--                            <v-btn tile small dense color="info" class="mr-2" @click="validateBoxes">-->
<!--                                Validar-->
<!--                            </v-btn>-->
                            <v-btn tile small dense color="success" :disabled="!isPrintValid" @click="printPackingList">
                                IMPRIMIR
                            </v-btn>
                        </v-col>
                    </v-row>

                    <!-- PRODUCTS TABLE -->
                    <v-row class="mt-3">
                        <v-col>
                            <template>
                                <v-simple-table class="products-table" dense>
                                    <template v-slot:default>
                                        <thead class="table-header">
                                        <tr>
                                            <th class="text-left" style="width: 200px">
                                                <h3 class="table-title">Artigo</h3>
                                            </th>
                                            <th class="text-left" style="width: 150px">
                                                <h3 class="table-title">EAN</h3>
                                            </th>
                                            <th class="text-left">
                                                <h3 class="table-title">Descrição</h3>
                                            </th>
                                            <th class="text-center">
                                                <h3 class="table-title">Quantidade</h3>
                                            </th>
                                            <!-- fazer for each por numero de volumes, para saber qnts caixas são -->
                                            <th class="text-center" style="width: 100px; cursor: pointer"
                                                v-for="box in parseInt(numBoxes)" :key="box"
                                                @click="selectedBox = box">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <h3 class="table-title" v-bind="attrs"
                                                            v-on="on">
                                                            <v-icon size="22"  :color="box === selectedBox ? 'green' : '#e3ba92'">
                                                                {{
                                                                    box === selectedBox ? 'mdi-package-variant' : 'mdi-package-variant-closed'
                                                                }}
                                                            </v-icon>
                                                            Cx.{{ box }}
                                                        </h3>
                                                    </template>
                                                    <span>Abrir Caixa</span>
                                                </v-tooltip>
                                            </th>

                                            <!-- IDEIA COLUNA QUE MOSTRA SÓ ICON SE JÁ ESTÁ CONF A QUANTIDADE | se a qnt das4 caixas for igual á qnt total mostra logo verde -->
                                            <th width="30px">
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody class="table-content">
                                        <tr
                                                v-for="(product, index) in shippingProducts"
                                                :key="product.Artigo"
                                                :style="[index % 2 === 0 ? {'background': 'rgba(0,0,0,0.05)'} : null, checkBoxesSum(product) === product.Quantidade ? {'background': 'rgba(0, 255, 25, 0.30) !important'} : (checkBoxesSum(product) < product.Quantidade && checkBoxesSum(product) > 0 ? {'background': 'rgba(255, 255, 0, 0.25) '} : null)]"
                                                :class="[product.EAN === lastPick ? 'product-table-line active-line' : 'product-table-line', (invalidProducts.indexOf(product.Artigo) !== -1 || product['box_'+selectedBox] > product.Quantidade) ? 'invalid-line' : null]"
                                                :id="'row_'+product.EAN"
                                        >
                                            <td class="table-text">
                                                {{ product.Artigo }}
                                            </td>
                                            <td class="table-text" width="150px">{{ product.EAN }}</td>
                                            <td class="table-text">{{ product.Descricao }}</td>
                                            <td class="text-center table-text" width="100px"
                                                style="border-right: 1px solid black">{{ product.Quantidade }}
                                            </td>
                                            <!-- fazer for each com inputs por numero de volumes, para saber como é distribuida a quantidade pelas caixas -->

                                            <td v-for="box in numBoxes" :key="product.EAN+'_box_'+box">
                                                <v-text-field
                                                        :id="product.EAN+'_box_'+box"
                                                        :ref="product.EAN+'_box_'+box"
                                                        :class="[box === selectedBox ? 'box-quantity-input selected-box' : 'box-quantity-input']"
                                                        type="number"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        hide-spin-buttons
                                                        v-model="product['box_'+box]"
                                                        @focusout="checkEmpty(product, box)"
                                                        @keyup="checkBoxesSum(product);"
                                                        :disabled="box !== selectedBox"
                                                        :error="product['box_'+box] > product.Quantidade"
                                                ></v-text-field>
                                            </td>

                                            <td class="status-row text-center">
                                                <v-icon
                                                        v-if="checkBoxesSum(product) === product.Quantidade"
                                                        color="success"
                                                        class="quantity-success"
                                                        size="14"
                                                >
                                                    mdi-check-bold
                                                </v-icon>
                                                <v-icon
                                                        v-else
                                                        color="error"
                                                        class="quantity-to-do"
                                                        size="14"
                                                >
                                                    mdi-close
                                                </v-icon>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </template>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </template>

        <v-dialog persistent v-model="dialogClearBox" width="600">
            <v-card>
                <v-card-title style="background-color: black; color: white; padding: 0; padding-left: 12px; font-size: 14px">Limpar caixa</v-card-title>
                <v-card-text class="pa-4">
                    <h2 class="text-center mb-4">Tem a certeza que pretende esvazir a caixa <strong style="color: red">'{{selectedBox}}'</strong> ? </h2>
                    <v-row>
                        <v-col class="mt-4" style="display: flex; justify-content: space-around">
                            <v-btn width="100" small color="error" @click="dialogClearBox = false">NÃO</v-btn>
                            <v-btn width="100" small color="success" @click="clearBox()">SIM</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>

            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
export default {
    data: function () {
        return {
            loading: true,
            language: '',
            docNumber: '',
            docType: '',
            isValid: false,
            shippingDoc: {},
            invalidProducts: [],
            shippingProducts: [],
            numBoxes: 1,
            selectedBox: 1,
            totalProducts: 0,
            predefineQuantity: false,
            lastPick: '',
            dialogClearBox: false
        }
    },
    created() {
        document.title = this.$route.params.docNumber + ' - Packlist | STAFF';
        this.docType = this.$route.params.docType;
        this.docNumber = this.$route.params.docNumber;
        this.language = User.getters.getLanguage;
        this.getShippingInfos();
    },
    methods: {
        getShippingInfos() {

            let request = {url: api.ordersShippingPackingList() + this.docType + '/' + this.docNumber}

            ApiWS.dispatch('makeGet', request)
                    .then((response) => {
                        let responseData = response.data

                        if (responseData.status === 'OK') {
                            //grab all the info needed
                            this.shippingDoc = responseData.shippingDoc;

                            //prepare each product to receive qnt info of each box
                            this.shippingProducts = responseData.shippingProducts;
                            this.addBoxesToProducts();

                            //Cal total products
                            for (const product of this.shippingProducts) {
                                this.totalProducts += parseInt(product.Quantidade);
                            }

                            this.$nextTick(() => this.$refs.barcode.focus());
                        }

                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    })
        },
        addBoxesToProducts() {
            this.shippingProducts = this.shippingProducts.map((product) => {
                return {
                    ...product,
                    ['box_' + this.numBoxes]: '0'
                }
            })
        },
        removeBoxFromProducts() {
            for (const product of this.shippingProducts) {
                delete product['box_' + this.numBoxes]
            }
        },
        validateBoxes() {
            this.invalidProducts = []

            for (const product of this.shippingProducts) {
                let auxTotal = 0
                // parseInt(product.box_1) + parseInt(product.box_2) + parseInt(product.box_3) + parseInt(product.box_4)
                for (let i = 1; i <= this.numBoxes; i++) {
                    auxTotal += parseInt(product['box_' + i]);
                }

                if (product.Quantidade != auxTotal) {
                    //Invalid
                    this.invalidProducts.push(product.Artigo);
                    this.isValid = false;
                }
            }

            if (this.invalidProducts.length === 0) {
                //Valid
                let alert = {
                    snackbar: true,
                    text: 'Sucesso - Pronto para imprimir',
                    type: 'success'
                }
                Alert.dispatch('show', alert)

                this.isValid = true;
            } else {
                //Invalid
                let alert = {
                    snackbar: true,
                    text: 'Erro - Quantidades inválidas',
                    type: 'error'
                }

                Alert.dispatch('show', alert)
                this.isValid = false;
            }
        },
        checkEmpty(product, index) {
            if (product['box_' + index] !== 0) {
                product['box_' + index] = product['box_' + index].replace(/^0+/, '')
            }
            if (product['box_' + index] === '' || product['box_' + index] < 0) {
                product['box_' + index] = 0;
            }
        },
        checkBoxesSum(product) {
            let total = 0;
            for (let i = 1; i <= this.numBoxes; i++) {
                total += parseInt(product['box_' + i]) || 0; // Considera 0 se o valor for undefined ou inválido
            }
            return total;
        },
        printPackingList() {
            this.loading = true;
            const data = []
            data['numDoc'] = this.docNumber;
            data['typeDoc'] = this.docType;
            data['numBoxes'] = this.numBoxes;
            data['boxes'] = [];

            //Divide products quantity by boxes
            for (let i = 1; i <= this.numBoxes; i++) {
                const auxName = 'box_' + i;
                const auxValues = this.shippingProducts.filter((product) => {
                    if (parseInt(product[auxName]) > 0) {
                        return product
                    }
                })

                const boxProducts = []
                for (const product of auxValues) {
                    const productToSend = {
                        artigo: product.Artigo,
                        EAN: product.EAN,
                        descricao: product.Descricao,
                        quantity: product[auxName]
                    }
                    boxProducts.push(productToSend)
                }
                data[auxName] = boxProducts;

                data['boxes'].push(data[auxName])
            }

            //Send info to API
            // console.log(data)
            let request = {
                url: api.ordersShippingPackingListPrint(),
                data: {
                    typeDoc: data['typeDoc'],
                    numDoc: data['numDoc'],
                    boxes: data['boxes'],
                    client: this.shippingDoc.Entidade + ' - ' + this.shippingDoc.Nome
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then((response) => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            //Tudo ok
                            let alert = {
                                snackbar: true,
                                text: 'Sucesso - Imprimindo...',
                                type: 'success'
                            }
                            Alert.dispatch('show', alert)

                            // console.log(responseData)
                        }

                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    })

        },
        addBox() {
            this.numBoxes += 1;
            this.selectedBox = this.numBoxes;
            //Refactor the products array
            this.addBoxesToProducts();
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        removeBox() {
            if (parseInt(this.numBoxes) === 1) {
                return;
            }
            //Refactor the products array
            this.removeBoxFromProducts();
            this.numBoxes -= 1;
            this.selectedBox = this.numBoxes;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        focusInput(ref) {
            if (!this.predefineQuantity){
                this.$refs.quantity.internalValue = 1;
                this.$nextTick(() => this.$refs[ref].focus());
            }else{
                this.pickProduct();
            }
        },
        btnPredefineQuantity() {
            if (!this.predefineQuantity) {
                this.predefineQuantity = true;
                this.$refs.quantity.internalValue = 1;
            } else {
                this.predefineQuantity = false;
                this.$refs.quantity.internalValue = '';
            }
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        pickProduct() {
            let barCode = this.$refs.barcode.internalValue;
            let quantity = this.$refs.quantity.internalValue;

            //check if product is present on the list
            let auxProduct = this.shippingProducts.find((pro, index) => {
                let possibleEANs = [pro.EAN, pro.CDU_EAN, pro.CDU_REFFORNECEDOR, pro.CodBarras];

                if (possibleEANs.includes(barCode)){
                    return this.shippingProducts[index]
                }
                return undefined
            })

            //NOK - product not found
            if (auxProduct === undefined) {
                let alert = {
                    snackbar: true,
                    text: 'Error - Produto não encontrado',
                    type: 'error'
                }
                Alert.dispatch('show', alert)

                var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                audio.play();

                this.$refs.barcode.internalValue = '';
                this.$nextTick(() => this.$refs.barcode.focus());

                return;
            }

            //NOK - max quantity
            let actualQuantity = this.$refs[auxProduct.EAN + '_box_' + this.selectedBox][0].internalValue
            if(parseInt(quantity) + parseInt(actualQuantity) > auxProduct.Quantidade ){
                let alert = {
                    snackbar: true,
                    text: 'Error - quantidade total atingida',
                    type: 'error'
                }
                Alert.dispatch('show', alert)

                var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                audio.play();

                return;
            }

            //make sound
            var audio = new Audio(this.urlApi + '/beep-07.mp3'); // path to file
            audio.play();

            //select product box and assign quantity value
            this.lastPick = auxProduct.EAN;
            this.$refs[auxProduct.EAN + '_box_' + this.selectedBox][0].internalValue = parseInt(quantity) + parseInt(actualQuantity);
            document.getElementById(auxProduct.EAN + '_box_' + this.selectedBox).scrollIntoView({ behavior: 'smooth', block: 'start' })
            //clear barcode
            this.$refs.barcode.internalValue = '';
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        clearBox() {
            this.$refs.barcode.internalValue = '';
            this.$refs.quantity.internalValue = '';
            this.predefineQuantity = false;

            this.shippingProducts.map((product) => {
                product['box_'+this.selectedBox] = 0;
            })

            this.dialogClearBox = false;
            this.$nextTick(() => this.$refs['barcode'].focus());
        },
        fillBox(){
            for (const product of this.shippingProducts) {
                product['box_1'] = product.Quantidade
            }
            this.$nextTick(() => this.$refs.barcode.focus());
        }
    },
    computed: {
        urlApi() {
            return process.env.VUE_APP_API_URL;
        },
        isPrintValid() {
            this.invalidProducts = [];
            this.completedProduct = [];

            for (const product of this.shippingProducts) {
                let auxTotal = 0
                // parseInt(product.box_1) + parseInt(product.box_2) + parseInt(product.box_3) + parseInt(product.box_4)
                for (let i = 1; i <= this.numBoxes; i++) {
                    auxTotal += parseInt(product['box_' + i]);
                }

                // console.log(auxTotal + ' - ' + product.Quantidade)
                if (auxTotal > product.Quantidade) {
                    //Invalid
                    this.invalidProducts.push(product.Artigo);
                }

                if (auxTotal === product.Quantidade){
                    //complete
                    this.completedProduct.push(product.Artigo)
                }
            }

            if (this.invalidProducts.length !== 0 || this.completedProduct.length !== this.shippingProducts.length) {
                //Invalid
                // let alert = {
                //     snackbar: true,
                //     text: 'Erro - Quantidades inválidas',
                //     type: 'error'
                // }
                //
                // Alert.dispatch('show', alert)
                return false;
            } else {
                //Valid
                let alert = {
                    snackbar: true,
                    text: 'Sucesso - Pronto para imprimir',
                    type: 'success'
                }
                Alert.dispatch('show', alert)

                return true;
            }
        },
    }
}
</script>