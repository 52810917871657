<template>
    <div style="float: left;">
        <v-card dark width="200" class="mr-3 mb-3" color="orange lighten-2" href="/orders/shippedHistory?filter=ctt">
            <v-list-item>
                <v-list-item-title class="headline">CTT</v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <div class="overline" style="height: 30px; line-height: 15px;">REEMBOLSOS PENDENTES</div>
                </v-list-item-content>
                <v-list-item-avatar tile size="40">
                    <span class="headline">{{ value }}</span>
                </v-list-item-avatar>
            </v-list-item>
        </v-card>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                loading: true,
                value: 0
            }
        },
        created() {
            this.initialize()
        },
        methods: {
            initialize() {
                this.loading = true;
                let request = {url: '/api/staff/dashboard/widget/pendingRefunds/ctt'};
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.value = responseData.value;
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
        }
    }
</script>
