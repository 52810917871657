<template>
    <div>
        <DashboardSuper v-if="profile === 1"></DashboardSuper>
        <DashboardAdmin v-if="profile === 2"></DashboardAdmin>
        <DashboardLogistics v-if="profile === 5"></DashboardLogistics>
        <DashboardBrandManager v-if="profile === 6 || profile === 14"></DashboardBrandManager>
        <DashboardCommercialCommissioners v-if="profile === 7"></DashboardCommercialCommissioners>
        <DashboardCommercialBicimax v-if="profile === 8"></DashboardCommercialBicimax>
        <DashboardInternalCommercial v-if="profile === 9"></DashboardInternalCommercial>
        <DashboardDesign v-if="profile === 10"></DashboardDesign>
        <DashboardWorkshopManager v-if="profile === 11"></DashboardWorkshopManager>
        <DashboardAdministrative v-if="profile === 12"></DashboardAdministrative>
        <DashboardFinancial v-if="profile === 16"></DashboardFinancial>
        <DashboardKeyAccount v-if="profile === 18"></DashboardKeyAccount>
    </div>
</template>

<script>
    import DashboardSuper from "./Dashboard/DashboardSuper";
    import DashboardAdmin from "./Dashboard/DashboardAdmin";
    import DashboardLogistics from "./Dashboard/DashboardLogistics";
    import DashboardBrandManager from "./Dashboard/DashboardBrandManager";
    import DashboardCommercialCommissioners from "./Dashboard/DashboardCommercialCommissioners";
    import DashboardCommercialBicimax from "./Dashboard/DashboardCommercialBicimax";
    import DashboardInternalCommercial from "./Dashboard/DashboardInternalCommercial";
    import DashboardDesign from "./Dashboard/DashboardDesign";
    import DashboardWorkshopManager from "./Dashboard/DashboardWorkshopManager";
    import DashboardAdministrative from "./Dashboard/DashboardAdministrative";
    import DashboardFinancial from "@/components/Dashboard/DashboardFinancial";
    import DashboardKeyAccount from "@/components/Dashboard/DashboardKeyAccount.vue";

    export default {
        components: {
            DashboardKeyAccount,
            DashboardFinancial,
            DashboardAdministrative,
            DashboardWorkshopManager,
            DashboardDesign,
            DashboardInternalCommercial,
            DashboardCommercialBicimax,
            DashboardCommercialCommissioners,
            DashboardBrandManager,
            DashboardLogistics,
            DashboardSuper,
            DashboardAdmin
        },
        data: () => ({}),
        created() {
            document.title = 'Dashboard | STAFF'
        },
        computed: {
            profile() {
                return User.getters.getProfileId
            }
        }
    }
</script>
