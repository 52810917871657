<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title title="VFAs"></page-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>{{ $t('supplier') }}</th>
                            <th>Documento</th>
                            <th>Num. Doc. Externo</th>
                            <th>{{ $t('dateOfDocument') }}</th>
                            <th class="text-center">Total Referências</th>
                            <th class="text-center">Total {{ $t('products') }}</th>
                            <th width="1" class="text-center">Moeda</th>
                            <th class="text-right">Valor Documento</th>
                            <th width="1"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="vfa in VFAs">
                            <td>{{ vfa.Nome }}</td>
                            <td>{{ vfa.Documento }}</td>
                            <td>{{ vfa.NumDocExterno != null ? vfa.NumDocExterno : vfa.NumDocExterno_2 }}</td>
                            <td>{{ vfa.DataDoc }}</td>
                            <td class="text-center">{{ vfa.TotalReferencias }}</td>
                            <td class="text-center">{{ vfa.TotalArtigos }}</td>
                            <td class="text-center">{{ vfa.Moeda }}</td>
                            <td class="text-right">
                                <template v-if="vfa.Moeda === 'USD'">
                                    {{ $dollar(vfa.TotalDocumento) }}
                                </template>
                                <template v-else>
                                    {{ $money(vfa.TotalDocumento) }}
                                </template>
                            </td>
                            <td>
                                <v-btn tile small  color="primary" :to="'/purchases/VFAs/'+vfa.NumDoc">
                                    Detalhe
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: function () {
        return {
            VFAs: []
        }
    },
    created() {
        document.title = 'VFAs | STAFF';
        this.getVFAs();
    },
    methods: {
        getVFAs() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/VFAs'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.VFAs = responseData.VFAs;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        }
    }
}
</script>
