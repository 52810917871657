<link rel="stylesheet" href="vue-virtual-scroller/dist/vue-virtual-scroller.css"/>
<template>
    <v-container fluid>
        <!-- PAGE TITLE -->
        <v-row>
            <v-col cols="12" md="3">
                <page-title
                        :title="$t('management')+' '+$t('products')"
                        :subtitle="'A mostrar '+products.length+' '+$t('products')"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small @click="getWarehouse('ALL')" class="mr-2">Todos</v-btn>
                <template v-for="warehouse in filtersWarehouse">
                    <v-tooltip bottom :key="warehouse.name">
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small tile @click="getWarehouse(warehouse.name)"
                                   :color="warehouse.filter == true ? 'success' : ''">
                                {{ warehouse.name }}
                            </v-btn>
                        </template>
                        <span>{{ warehouse.desc }}</span>
                    </v-tooltip>
                </template>
                <v-btn tile small class="ml-2" color="error" @click="clearFilters()">{{ $t('clearFilters') }}</v-btn>
            </v-col>
        </v-row>
        <!-- DATE FILTERS -->
        <v-row>
            <v-col cols="7">
                <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="filters.dataInicial"
                                label="Data Inicial"
                                dense
                                outlined
                                readonly
                                v-on="on"
                                style="width: 200px; float: left"
                                hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.dataInicial" @input="menu2 = false"
                                   @change="changeDate()"></v-date-picker>
                </v-menu>
                <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="filters.dataFinal"
                                label="Data Final"
                                readonly
                                dense
                                outlined
                                v-on="on"
                                style="width: 200px; float: left"
                                hide-details
                                class="ml-4"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.dataFinal" @input="menu3 = false"></v-date-picker>
                </v-menu>
                <!--        <v-btn :disabled="!allowUpdateWarehouse && !allowUpdateDates" tile small class="ml-2" color="info" :loading="loading" @click="updatePage">Actualizar</v-btn>-->
                <v-btn tile small class="ml-2" color="info" :loading="loading" @click="updatePage">Actualizar</v-btn>
            </v-col>
            <v-col class="text-right">
                <v-btn tile color="info" small @click="exportXls()">Download XLSX</v-btn>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small v-if="pagination.page > 1" @click="pagination.page--">Anterior</v-btn>
                <v-btn tile small outlined disabled class="ma-1">{{ pagination.page }}</v-btn>
                <v-btn tile small v-if="pagination.per_page*pagination.page < products.length"
                       @click="pagination.page++">{{ $t('next') }}
                </v-btn>
            </v-col>
        </v-row>
        <!-- PRODUCTS TABLE -->
        <v-row v-show="showPage">
            <v-col>
                <v-simple-table class="staff_table table_fixed_header">
                    <thead>
                    <tr>
                        <th style="cursor: pointer;width: 8vw" @click="orderList('Artigo')">
                            <!--  <th style="cursor: pointer;" @click="setOrder('Artigo', filters.orderSentido)">-->
                            {{ $t('product') }}
                            <i v-if="selectedHeader == 'Artigo'" class="fas"
                               :class="[headers['Artigo'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                        </th>
                        <th style="cursor: pointer;width: 23vw" @click="orderList('Descricao')">{{ $t('description') }} <i
                                v-if="selectedHeader == 'Descricao'" class="fas"
                                :class="[headers['Descricao'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                        <th style="cursor: pointer;width: 7vw" @click="orderList('CodBarras')">Cod. Barras<i
                                v-if="selectedHeader == 'CodBarras'" class="fas"
                                :class="[headers['CodBarras'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                        <th style="cursor: pointer;width: 6vw" @click="orderList('Marca')">Marca<i
                                v-if="selectedHeader == 'Marca'" class="fas"
                                :class="[headers['Marca'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                        <th style="cursor: pointer;width: 6vw" @click="orderList('Familia')">Família<i
                                v-if="selectedHeader == 'Familia'" class="fas"
                                :class="[headers['Familia'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                        <th style="width: 4vw">B2B</th>
                        <th style="width: 4vw">
                            Esgotado
                        </th>
                        <th style="cursor: pointer; white-space: nowrap;width: 3vw" class="text-center" @click="orderList('STKActual')">
                            <!--                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('STKActual', filters.orderSentido)">-->
                            Stock
                            <i v-if="selectedHeader == 'STKActual'" class="fas"
                               :class="[headers['STKActual'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                        </th>
                        <th style="cursor: pointer; white-space: nowrap;width: 4vw" @click="orderList('ENC_CLIENTE')">
                            <!--                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('ENC_CLIENTE', filters.orderSentido)">-->
                            Enc. Cliente
                            <i v-if="selectedHeader == 'ENC_CLIENTE'" class="fas"
                               :class="[headers['ENC_CLIENTE'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                        </th>
                        <th style="cursor: pointer; white-space: nowrap;width: 6vw" @click="orderList('ENC_FORN')">
                            <!--                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('ENC_FORN', filters.orderSentido)">-->

                            Enc. {{ $t('provider') }}
                            <i v-if="selectedHeader == 'ENC_FORN'" class="fas"
                               :class="[headers['ENC_FORN'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                        </th>
                        <th style="cursor: pointer; white-space: nowrap;width: 4vw" @click="orderList('disponivel')">
                            <!--                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('disponivel', filters.orderSentido)">-->
                            {{ $t('available') }}
                            <i v-if="selectedHeader == 'disponivel'" class="fas"
                               :class="[headers['disponivel'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                        </th>
                        <th style="cursor: pointer; white-space: nowrap;width: 4vw" @click="orderList('VENDAS')">
                            <!--                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('VENDAS', filters.orderSentido)">-->
                            {{ $t('sales') }} ARM
                            <i v-if="selectedHeader == 'VENDAS'" class="fas"
                               :class="[headers['VENDAS'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                        </th>
                        <th style="cursor: pointer; white-space: nowrap;width: 4vw" @click="orderList('VENDAS3')">
                            <!--                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('VENDAS3', filters.orderSentido)">-->
                            Vendas SC
                            <i v-if="selectedHeader == 'VENDAS3'" class="fas"
                               :class="[headers['VENDAS3'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                        </th>
                    </tr>
                    <tr class="filters_row">
                        <td>
                            <v-text-field
                                    v-model="filtersNew.Artigo.value"
                                    dense
                                    outlined
                                    hide-details
                                    v-on:keyup.enter="filterNew"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filtersNew.Descricao.value"
                                    dense
                                    outlined
                                    hide-details
                                    v-on:keyup.enter="filterNew"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filtersNew.CodBarras.value"
                                    dense
                                    outlined
                                    hide-details
                                    v-on:keyup.enter="filterNew"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-select
                                    v-model="filtersNew.Marca.value"
                                    dense
                                    outlined
                                    hide-details
                                    @change="filterNew"
                                    :items="brands"
                                    item-text="TEXT"
                                    item-value="VALUE"
                            ></v-select>
                        </td>
                        <td>
                            <v-select
                                    v-model="filtersNew.Familia.value"
                                    dense
                                    outlined
                                    hide-details
                                    @change="filterNew"
                                    :items="families"
                                    item-value="VALUE"
                                    item-text="TEXT"
                            ></v-select>
                        </td>
                        <td>
                            <v-select
                                    v-model="filtersNew.CDU_B2B_SYNC.value"
                                    :items="filtersYesNo"
                                    outlined
                                    dense
                                    @change="filterNew"
                                    item-text="TEXT"
                                    item-value="VALUE"
                                    hide-details
                                    style="width: 80px"
                            ></v-select>
                        </td>
                        <td>
                            <v-select
                                    v-model="filtersNew.CDU_B2BEsgotado.value"
                                    :items="filtersYesNo"
                                    outlined
                                    dense
                                    @change="filterNew"
                                    item-text="TEXT"
                                    item-value="VALUE"
                                    hide-details
                                    style="width: 80px"
                            ></v-select>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody style="max-height: 800px; overflow: auto">
                    <tr v-if="loading">
                        <td colspan="12">
                            <v-progress-linear indeterminate></v-progress-linear>
                        </td>
                    </tr>
                    <tr v-for="product in pagedProducts" :key="product.Artigo">
                        <td style="min-width: 8vw;">{{ product.Artigo }}</td>
                        <td style="min-width: 23vw;">{{ product.Descricao }}</td>
                        <td style="min-width: 7vw;">{{ product.CodBarras }}</td>
                        <td style="min-width: 6vw;">{{ product.Marca }}</td>
                        <td style="min-width: 6vw;">{{ product.Familia }}</td>
                        <td class="text-center" style="min-width: 4vw;padding-right: 0">
                            <i class="fas fa-check" style="color: green" v-if="product.CDU_B2B_SYNC == '1'"></i>
                            <i class="fas fa-times" style="color: red" v-if="product.CDU_B2B_SYNC == '0'"></i>
                        </td>
                        <td class="text-center" style="min-width: 4vw;padding-right: 0">
                            <i class="fas fa-check" style="color: green" v-if="product.CDU_B2BEsgotado == '1'"></i>
                            <i class="fas fa-times" style="color: red" v-else></i>
                        </td>
                        <td class="text-center" style="min-width: 3vw">{{ product.STKActual }}</td>
                        <td class="text-center" style="min-width: 4vw">
                            <span v-if="product.ENC_CLIENTE === null">{{ product.ENC_CLIENTE || 0 }}</span>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" @mouseover="tooltipOrders(product.Artigo)">{{
                                            product.ENC_CLIENTE || 0
                                        }}</span>
                                </template>
                                <span>
                                        <v-row v-if="ordersTooltip.length != 0 && !loadingTooltips">
                                            <v-col>{{ $t('order') }}</v-col>
                                            <v-col>{{ $t('quantity') }}</v-col>
                                        </v-row>
                                        <v-row v-if="ordersTooltip.length == 0 && !loadingTooltips">
                                            <v-col>
                                            <span>Nenhuma encomenda</span>
                                              </v-col>
                                          </v-row>
                                    </span>
                                <span v-if="loadingTooltips">A carregar informação...</span>
                                <span v-for="order in ordersTooltip">
                                        <v-row>
                                            <v-col class="py-1 text-center">{{ order.NumDoc }}</v-col>
                                            <v-col class="py-1 text-center">{{ order.Quantidade }}</v-col>
                                        </v-row>
                                    </span>
                            </v-tooltip>
                        </td>
                        <td class="text-center" style="min-width: 6vw">
                            <span v-if="product.ENC_FORN === null">{{ product.ENC_FORN || 0 }}</span>
                            <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" @mouseover="tooltipPurchases(product.Artigo)">{{
                                            product.ENC_FORN || 0
                                        }}</span>
                                </template>
                                <span>
                                        <v-row v-if="purchasesTooltip.length != 0 && !loadingTooltips">
                                            <v-col class="py-1" style="font-size: 12px"
                                                   align-self="center">Compra</v-col>
                                            <v-col class="py-1" style="font-size: 12px" align-self="center">{{
                                                    $t('quantity')
                                                }}</v-col>
                                            <v-col class="py-1" style="font-size: 12px">{{ $t('arrivalDate') }}</v-col>
                                        </v-row>
                                        <v-row v-if="purchasesTooltip.length == 0 && !loadingTooltips">
                                          <v-col>
                                          <span>Nenhuma encomenda</span>
                                            </v-col>
                                        </v-row>
                                    </span>
                                <span v-if="loadingTooltips">A carregar informação...</span>
                                <span v-for="purchase in purchasesTooltip">
                                        <v-row>
                                            <v-col class="py-1 text-center" style="font-size: 12px">{{
                                                    purchase.NumDoc
                                                }}</v-col>
                                            <v-col class="py-1 text-center" style="font-size: 12px">{{
                                                    purchase.Quantidade
                                                }}</v-col>
                                            <v-col class="py-1 text-center" style="font-size: 12px">{{
                                                    purchase.DataEntrega
                                                }}</v-col>
                                        </v-row>
                                    </span>
                            </v-tooltip>
                        </td>
                        <td class="text-center" style="min-width: 4vw">{{ product.disponivel || 0 }}</td>
                        <td class="text-center" style="min-width: 4vw">{{ product.VENDAS || 0 }}</td>
                        <td class="text-center" style="min-width: 4vw">{{ product.VENDAS3 || 0 }}</td>
                    </tr>


                    <!--          <tr v-for="product in orderedProducts" :key="product.Artigo">
                                <td>{{ product.Artigo }}</td>
                                <td>{{ product.Descricao }}</td>
                                <td>{{ product.CodBarras }}</td>
                                <td>{{ product.Marca }}</td>
                                <td>{{ product.Familia }}</td>
                                <td class="text-center">
                                  <i class="fas fa-check" style="color: green" v-if="product.CDU_B2B_SYNC == '1'"></i>
                                  <i class="fas fa-times" style="color: red" v-if="product.CDU_B2B_SYNC == '0'"></i>
                                </td>
                                <td class="text-center">{{ product.STKActual }}</td>
                                <td class="text-center">
                                  <span v-if="product.ENC_CLIENTE === null">{{ product.ENC_CLIENTE || 0 }}</span>
                                  <v-tooltip top v-else>
                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" @mouseover="tooltipOrders(product.Artigo)">{{
                                                            product.ENC_CLIENTE || 0
                                                          }}</span>
                                    </template>
                                    <span>
                                                            <v-row>
                                                                <v-col>{{ $t('order') }}</v-col>
                                                                <v-col>{{ $t('quantity') }}</v-col>
                                                            </v-row>
                                                        </span>
                                    <span v-for="order in ordersTooltip">
                                                            <v-row>
                                                                <v-col class="py-1 text-center">{{ order.NumDoc }}</v-col>
                                                                <v-col class="py-1 text-center">{{ order.Quantidade }}</v-col>
                                                            </v-row>
                                                        </span>
                                  </v-tooltip>
                                </td>
                                <td class="text-center">
                                  <span v-if="product.ENC_FORN === null">{{ product.ENC_FORN || 0 }}</span>
                                  <v-tooltip top v-else>
                                    <template v-slot:activator="{ on }">
                                                        <span v-on="on" @mouseover="tooltipPurchases(product.Artigo)">{{
                                                            product.ENC_FORN || 0
                                                          }}</span>
                                    </template>
                                    <span>
                                                            <v-row>
                                                                <v-col class="py-1" style="font-size: 12px" align-self="center">Compra</v-col>
                                                                <v-col class="py-1" style="font-size: 12px" align-self="center">{{
                                                                    $t('quantity')
                                                                  }}</v-col>
                                                                <v-col class="py-1" style="font-size: 12px">{{ $t('arrivalDate') }}</v-col>
                                                            </v-row>
                                                        </span>
                                    <span v-for="purchase in purchasesTooltip">
                                                            <v-row>
                                                                <v-col class="py-1 text-center" style="font-size: 12px">{{
                                                                    purchase.NumDoc
                                                                  }}</v-col>
                                                                <v-col class="py-1 text-center" style="font-size: 12px">{{
                                                                    purchase.Quantidade
                                                                  }}</v-col>
                                                                <v-col class="py-1 text-center" style="font-size: 12px">{{
                                                                    purchase.DataEntrega
                                                                  }}</v-col>
                                                            </v-row>
                                                        </span>
                                  </v-tooltip>
                                </td>
                                <td class="text-center">{{ product.disponivel || 0 }}</td>
                                <td class="text-center">{{ product.VENDAS || 0 }}</td>
                                <td class="text-center">{{ product.VENDAS3 || 0 }}</td>
                              </tr>-->
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script src="vue-virtual-scroller/dist/vue-virtual-scroller.min.js"></script>
<script>
import i18n from "../../plugins/i18n";
import _ from "lodash";

export default {
    data: function () {
        return {
            tooltipsSynced: false,
            allowUpdateWarehouse: true,
            allowUpdateDates: true,
            loading: false,
            loadingTooltips: false,
            showPage: false,
            products: [],
            filtersWarehouse: [],
            filtersWarehouseAux: [],
            filtersAllWarehouse: true,
            reload: false,
            brands: [],
            families: [],
            pagination: {
                page: 1,
                per_page: 250
            },
            originalStartDate: null,
            originalEndDate: null,
            originalWarehouses: null,
            filters: {
                Artigo: '',
                Descricao: '',
                CodBarras: '',
                Marca: '',
                Familia: '',
                dataInicial: '',
                dataFinal: '',
                orderSentido: true,
                orderColuna: 'Artigo',
                B2B: ''
            },
            filWarehouse: {
                Filter: "'A1'"
            },
            query: '',
            menu2: false,
            menu3: false,
            filtersYesNo: [
                {TEXT: 'Todos', VALUE: ''},
                {TEXT: 'Não', VALUE: '0'},
                {TEXT: 'Sim', VALUE: '1'}
            ],
            purchasesByProduct: [],
            ordersByProduct: [],
            purchasesTooltip: [],
            ordersTooltip: [],
            paginatedProducts: [],
            filtersNew: {
                Artigo: {value: '', exact: false},
                Descricao: {value: '', exact: false},
                CodBarras: {value: '', exact: false},
                Marca: {value: '', exact: true},
                Familia: {value: '', exact: true},
                CDU_B2B_SYNC: {value: '', exact: true},
                CDU_B2BEsgotado: {value: '', exact: true},
            },
            originalProducts: [],
            selectedHeader: "Artigo",
            headers: {
                "Artigo": "asc",
                "Descricao": "asc",
                "CodBarras": "asc",
                "Marca": "asc",
                "Familia": "asc",
                "STKActual": "asc",
                "ENC_CLIENTE": "asc",
                "ENC_FORN": "asc",
                "disponivel": "desc",
                "VENDAS": "asc",
                "VENDAS3": "asc"
            },
        }
    },
    computed: {
        /*allowUpdate2: function(){
           if(
                this.originalStartDate != this.filters.dataInicial ||
               this.originalEndDate != this.filters.dataFinal ||

           )
        },*/
        pagedProducts: function () {
            return this.orderedProducts.slice((this.pagination.page - 1) * this.pagination.per_page, this.pagination.page * this.pagination.per_page);
        },
        orderedProducts: function () {
            return _.orderBy(this.products, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    },
    created() {
        document.title = i18n.t('management') + ' ' + i18n.t('products')
        let currentYear = new Date().getFullYear()

        //if (this.$cookies.isKey('stockProducts_filters_2')) {
        //  this.filters = this.$cookies.get('stockProducts_filters_2')
        //}

        // if (this.filtersWarehouse.length > 0) {
        //     this.filtersWarehouseAux = this.filtersWarehouse
        //     this.reload = true
        // }


        // this.filters.dataInicial = currentYear + '-01-01'
        this.filters.dataInicial = '2021-01-01'
        this.filters.dataFinal = currentYear + 1 + '-12-31'

        this.allowUpdate = true;
        //this.getProducts()

    },
    methods: {
        updatePage() {
            this.showPage = true;
            this.getProducts();
            if (!this.tooltipsSynced)
                this.getTooltips();
        },
        filterNew() {
            let requestedFilters = [];

            Object.entries(this.filtersNew).forEach(([filter, value]) => {
                if (value.value != "")
                    requestedFilters[requestedFilters.length] = filter
            });

            if (requestedFilters.length == 0) {
                this.products = this.originalProducts;
            } else {
                requestedFilters.forEach((filter, index) => {
                    if (index == 0) {
                        this.products = this.originalProducts.filter((product) => this.filtersNew[filter].exact ? product[filter] == this.filtersNew[filter].value : product[filter].toUpperCase().includes(this.filtersNew[filter].value.toUpperCase()));
                    } else {
                        this.products = this.products.filter((product) => this.filtersNew[filter].exact ? product[filter] == this.filtersNew[filter].value : product[filter].toUpperCase().includes(this.filtersNew[filter].value.toUpperCase()));
                    }
                });
            }
        },
        changeDate() {
            this.allowUpdateDates = new Date(this.filters.dataInicial).getTime() != new Date(this.originalStartDate).getTime() || new Date(this.filters.dataFinal).getTime() != new Date(this.originalEndDate).getTime();
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;
        },
        getTooltips() {
            this.loadingTooltips = true;
            this.tooltipsSynced = true;
            let request = {
                url: api.brandsStock() + "/tooltips",
                data: {
                    filters: this.filters,
                }
            }
            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data
                    if (responseData.status === 'OK') {
                        this.purchasesByProduct = responseData.purchasesByProduct;
                        this.ordersByProduct = responseData.ordersByProduct;
                        this.loadingTooltips = false;
                    }
                })
                .catch(response => {
                    this.loadingTooltips = false;
                    this.tooltipsSynced = false;
                })

        },

        getProducts() {
            this.loading = true
            this.products = []

            let request = {
                url: api.brandsStock(),
                data: {
                    filters: this.filters,
                    pagination: this.pagination,
                    filtersWarehouse: this.filWarehouse
                }
            }

            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data
                    if (responseData.status === 'OK') {
                        this.products = responseData.products
                        this.originalProducts = responseData.products
                        this.brands = responseData.brands
                        /*this.purchasesByProduct = responseData.purchasesByProduct;
                        this.ordersByProduct = responseData.ordersByProduct;*/
                        this.allowUpdateWarehouse = false;
                        this.allowUpdateDates = false;
                        if (this.reload === false) {
                            responseData.warehouse.forEach((warehouse, index) => {
                                this.filtersWarehouse.splice(index, 1, {
                                        name: warehouse.name,
                                        desc: warehouse.desc,
                                        filter: false
                                    }
                                );

                            });
                            this.filtersWarehouse[0].filter = true;
                            /* this.filtersWarehouse = responseData.warehouse
                             for (let i = 0; i < this.filtersWarehouse.length; i++) {
                               this.filtersWarehouse[i].filter = i === 0
                             }*/
                            this.reload = true
                        } else {
                            if (this.filtersWarehouseAux.length > 0) {
                                this.filtersWarehouse = this.filtersWarehouseAux
                            }
                        }
                        //  console.log(this.filtersWarehouse);
                        this.originalWarehouses = this.filtersWarehouse.map(a => {
                            return {...a}
                        })

                        this.originalStartDate = this.filters.dataInicial;
                        this.originalEndDate = this.filters.dataFinal;
                        this.getFamilies()
                        this.filterNew();
                    }
                    this.loading = false
                })
                .catch(response => {
                    this.loading = false
                })
        },
        getFamilies() {
            let request = {
                url: api.productsFamilies(),
                data: {
                    brand: this.filters.Marca
                }
            }

            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data
                    if (responseData.status === 'OK') {
                        this.families = responseData.families
                    }
                })
                .catch(response => {

                })
        },
        getWarehouse(warehouse) {
            let i;
            if (warehouse === 'ALL') {
                for (i = 0; i < this.filtersWarehouse.length; i++) {
                    this.filtersWarehouse[i].filter = true
                }
                this.filtersAllWarehouse = true
                this.filWarehouse.Filter = ''
            } else if (warehouse !== 'ALL') {
                if (this.filtersAllWarehouse === true) {
                    for (i = 0; i < this.filtersWarehouse.length; i++) {
                        this.filtersWarehouse[i].filter = false
                    }
                    this.filtersAllWarehouse = false
                }
                this.query = '';
                let soma = 0
                for (i = 0; i < this.filtersWarehouse.length; i++) {
                    if (warehouse == this.filtersWarehouse[i].name && this.filtersWarehouse[i].filter == false && this.filtersAllWarehouse == false) {
                        this.filtersWarehouse[i].filter = true;
                    } else if (warehouse == this.filtersWarehouse[i].name && this.filtersWarehouse[i].filter == true && this.filtersAllWarehouse == false) {
                        this.filtersWarehouse[i].filter = false;
                    }
                    if (this.filtersWarehouse[i].filter == true) {
                        this.query += "'" + this.filtersWarehouse[i].name + "',";
                    } else {
                        soma++;
                    }
                }
                /*let soma = 0
                for (i = 0; i < this.filtersWarehouse.length; i++) {
                  if (this.filtersWarehouse[i].filter == true) {
                    this.query += "'" + this.filtersWarehouse[i].name + "',"
                  } else {
                    soma++
                  }
                }*/
                if (soma == this.filtersWarehouse.length) {
                    this.reload = false
                    this.filtersAllWarehouse = true
                }
                this.filWarehouse.Filter = this.query.substring(0, this.query.length - 1)
            }

            this.filtersWarehouseAux = this.filtersWarehouse
            this.allowUpdateWarehouse = JSON.stringify(this.originalWarehouses) !== JSON.stringify(this.filtersWarehouse);

            //this.getProducts()
        },
        /*setOrder(column, order) {
          this.filters.orderColuna = column;
          this.filters.orderSentido = !order;
          this.getProducts()
        },
        nextPage() {
          this.pagination.page++;
        },
        previousPage() {
          this.pagination.page--
          this.getProducts()
        },*/
        filter() {
            //this.$cookies.set("stockProducts_filters_2", this.filters);
            this.pagination.page = 1
            this.getProducts()
            this.getTooltips();
        },
        clearFilters() {
            let currentYear = new Date().getFullYear()
            this.filters = {
                Artigo: '',
                Descricao: '',
                CodBarras: '',
                Marca: '',
                Familia: '',
                dataInicial: '',
                dataFinal: new Date().toISOString().substr(0, 10),
                orderSentido: true,
                orderColuna: 'Artigo',
                B2B: ''
            }
            this.filtersNew = {
                Artigo: {value: '', exact: false},
                Descricao: {value: '', exact: false},
                CodBarras: {value: '', exact: false},
                Marca: {value: '', exact: true},
                Familia: {value: '', exact: true},
                CDU_B2B_SYNC: {value: '', exact: true},
                CDU_B2BEsgotado: {value: '', exact: true},
            }

            // this.filters.dataInicial = currentYear + '-01-01'
            this.filters.dataInicial = '2021-01-01'
            this.filters.dataFinal = currentYear + 1 + '-12-31'

            this.pagination.page = 1
            this.products = this.originalProducts;
            return;
            this.$cookies.set("stockProducts_filters_2", this.filters);
            this.getProducts()
        },
        tooltipPurchases(product) {
            this.purchasesTooltip = [];
            this.purchasesByProduct.forEach((purchase) => {
                if (purchase.Artigo === product) {
                    this.purchasesTooltip.push(purchase);
                }
            })
        },
        tooltipOrders(product) {
            this.ordersTooltip = [];
            this.ordersByProduct.forEach((order) => {
                if (order.Artigo === product) {
                    this.ordersTooltip.push(order);
                }
            })
        },
        exportXls() {
            let request = {
                url: '/api/staff/products/export-stock',
                data: {
                    dataInicial: this.filters.dataInicial,
                    dataFinal: this.filters.dataFinal,
                    products: this.products,

                }
            };
            ApiWS.dispatch('makePostDownload', request)
                .then(response => {

                    var file = new Blob([response.data], {
                        type: 'application/vnd.ms-excel'
                    });
                    var fileURL = URL.createObjectURL(file);
                    var a = document.createElement('a');
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = 'products_list.xlsx';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    // loader.hide();
                })
                .catch(response => {
                    // loader.hide();
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })


        }
    }
}
</script>
