<style scoped>
:global(.tooltip-activator-option) {
    padding: 0 !important;
}
</style>
<style>
.option-card {
    background-color: rgba(0, 0, 0, .08);

    .v-input {
        .v-input__slot {
            background-color: white !important;
            min-height: 30px !important;
        }
    }
}

.table-question-card {
    background-color: rgba(0, 0, 0, .3) !important;
    margin: 10px 0;

    .v-input {
        .v-input__slot {
            background-color: white !important;
            min-height: 30px !important;
        }
    }
}

#delete-question {
    &:hover {
        background-color: #F44336 !important;
        border-color: #F44336 !important;

        .not-hover {
            display: none !important;
        }

        .hover {
            display: block !important;
        }
    }

    .v-btn--disabled {
        color: rgba(244, 67, 54, 0.26);
    }
}

#select-activator-option {
    &:hover {
        background-color: #ADD8E6FF !important;
        border-color: #4682b4 !important;

        .not-hover {
            display: none !important;
        }

        .hover {
            display: block !important;
        }
    }
}

.info-text {
    float: right;
    padding: 0 6px;
    border-radius: 10px;
    border: 1px solid #4682b4;
    background-color: lightblue;
    color: #4682b4;
    font-size: 11px;
}

#add-question {
    padding: 4px;
    //color: black !important;

    &:hover {
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
        color: white !important;

        .not-hover {
            display: none !important;
        }

        .hover {
            display: block !important;
        }
    }
}

.info-question-btn {
    color: #2196f3;

    &:hover {
        background-color: #2196f3 !important;
        border-color: #2196f3 !important;

        .v-btn__content, i {
            color: white !important;
        }
    }
}

.form-group-question-text {
    .v-input__prepend-inner {
        i {
            font-size: 14px;
        }
    }
}

.col-actions-question {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    label {
        font-size: 12px;
        font-weight: bold !important;
        color: black !important;
    }

    .v-input--selection-controls__input {
        margin-right: 4px !important;
    }

}

.table-row-question-step {
    &:hover {
        cursor: pointer;
    }
}
</style>
<template>
    <v-card v-if="!loading">
        <v-card-title v-if="question.id" class="text-h5"
                      style="background-color: black; color: white; margin-bottom: 30px;">
            Questão {{ question.id }}
            <v-spacer/>
            <v-btn tile small color="white"
                   depressed outlined
                   id="delete-question"
                   class="float-right ml-2 white--text "
                   @click="openConfirmationDelete(question.id, 'question')">
                APAGAR
                <v-icon class="not-hover" size="16">mdi-delete</v-icon>
                <v-icon class="hover" style="display: none" size="16">mdi-delete-empty</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-title v-else class="text-h5" style="background-color: black; color: white; margin-bottom: 30px;">
            Nova Questão
        </v-card-title>
        <v-card-text>
            <!-- ROW WITH INFOS OF QUESTION -->
            <v-row class="text-center mb-0 pt-2 pb-2 pl-2"
                   style="background-color: rgba(0,0,0,.08);margin: -16px;">
                <v-col class="pt-0 pb-0"
                       style="display: flex; justify-content: space-around;; align-items: center">
                    <!-- IS REQUIRED ? -->
                    <div class="info-question ml-2 mr-2">
                        Obrigatória:
                        <v-btn-toggle
                                class="ml-1"
                                dense
                                v-model="editedQuestion.required ? 1 : 0"
                                mandatory
                                @change="editedQuestion.required = !editedQuestion.required;validate()"
                        >
                            <v-btn solo outlined>
                                <v-icon color="red">mdi-close-circle</v-icon>
                            </v-btn>
                            <v-btn solo outlined>
                                <v-icon color="success">mdi-checkbox-marked-circle</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </div>
                    <v-divider color="grey" vertical></v-divider>
                    <!-- TYPE OF QUESTION -->
                    <div class="info-question ml-2 mr-2">
                        Tipo de questão:
                        <v-select
                                v-model="editedQuestion.typeOfQuestion"
                                class="ml-1"
                                :items="questionTypes"
                                item-text="text"
                                item-value="value"
                                style="max-width: 192px;font-size: 12px"
                                :disabled="!newQuestion"
                                solo outlined
                                dense
                                hide-details
                        ></v-select>
                    </div>
                    <v-divider color="grey" vertical></v-divider>
                    <!-- POSITION ON STEP -->
                    <div class="info-question ml-2 mr-2">
                        Posição no step:
                        <v-text-field style="font-weight: normal; max-width: 50px;"
                                      v-model="editedQuestion.orderOnStep" dense solo
                                      type="text"
                                      @keyup="validate()"
                                      @change="validate()"
                                      :error="editedQuestion.orderOnStep == '' || parseInt(editedQuestion.orderOnStep) <= 0"
                                      class="form-question-number ml-1"
                                      outlined
                                      hide-details></v-text-field>
                        <!--                        {{editedQuestion.orderOnStep == '' || parseInt(editedQuestion.orderOnStep) <= 0}}-->
                    </div>
                    <v-divider color="grey" vertical></v-divider>
                    <!-- ACTIVATOR QUESTION OPTION -->
                    <!-- selecione a opção necessária para ativar a questão -->
                    <div class="info-question ml-2 mr-2">
                        <span v-if="editedQuestion.required_option != undefined">Opção ativadora:</span>
                        <v-tooltip content-class="tooltip-activator-option" bottom
                                   v-if="editedQuestion.required_option != undefined" style="padding: 0 !important;">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        small tile color="info"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    {{ editedQuestion.required_option }}
                                </v-btn>
                            </template>
                            <div>
                                <template v-for="question in stepEdited.questions" v-if="question.options">
                                    <v-card class="pa-0 mt-0"
                                            v-if="question.options[question.options.findIndex(option => option.id == editedQuestion.required_option)]">
                                        <v-card-title
                                                style="background-color: black;color: white;font-size: 14px;padding: 0 12px;">
                                            <!-- Pergunta que contém a questão obrigatória -->
                                            {{ question.id }} - {{ question.headers[0].value }}<br>
                                        </v-card-title>
                                        <v-card-text>
                                            <!-- Opções-->
                                            <p class="mb-1 mt-2 pl-2"
                                               :style="[option.id == editedQuestion.required_option ? {'font-weight': 'bold'} : '']"
                                               v-for="option in question.options">
                                                - {{ option.translations[0].value }}<span
                                                    v-if="option.id == editedQuestion.required_option"
                                                    style="font-size: 10px;color: green"> (opção selecionada)</span>
                                            </p>
                                        </v-card-text>
                                    </v-card>

                                </template>
                            </div>
                        </v-tooltip>
                        <v-row class="ma-0" style="max-width: 320px">
                            <v-col cols="12" class="pa-0">
                                <v-btn tile small color="black"
                                       depressed outlined
                                       :disabled="question.id == undefined"
                                       @click="selectAtivatorOption = true"
                                       id="select-activator-option"
                                       class="float-right ml-2 mb-1 black--text ">
                                    {{ editedQuestion.required_option == undefined ? 'Selecionar' : 'Alterar' }}
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="pa-0">
                                <p class="mb-0 info-text">
                                    <v-icon size="20" color="#4682b4" class="pr-1">mdi-information-outline</v-icon>
                                    selecione qual a opção que irá ativar esta questão
                                </p>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>

            <!-- QUESTION AND OPTIONS -->
            <v-card-text class="form_step_question pt-0">

                <!-- CREATE ONE FOR EACH TYPE OF QUESTION -->
                <v-row>
                    <!-- PT QUESTIONS DESC -->
                    <v-col class="col-md-6"
                           style="border-right: 1px solid;padding-top: 0; margin-top: 12px;margin-bottom: 4px;">
                        <v-list-item-content class="pt-0">
                            <flag iso="pt" style="font-size: 30px" v-bind:squared=false class="float-right mt-1"></flag>

                            <div v-if="editedQuestion.typeOfQuestion == 'text-field' || editedQuestion.typeOfQuestion == 'text-area'"
                                 class="question-options">
                                <v-list-item-title class="subtitle-2 font-weight-bold ">
                                    Pergunta:
                                    <!-- FOR EACH HEADER (ONE PER LANGUAGE, SHOW LINGUAGEM CORRESPONDENTE) -->
                                    <template v-for="header in editedQuestion.headers">
                                        <v-text-field style="font-weight: normal" class="pl-4"
                                                      v-if="header.language == 'pt'"
                                                      v-model="header.value" outlined
                                                      :error="header.value == ''"
                                                      dense
                                                      @keyup="validate()"
                                                      hide-details></v-text-field>
                                    </template>
                                </v-list-item-title>

                            </div>

                            <div v-if="editedQuestion.typeOfQuestion == 'radio' || editedQuestion.typeOfQuestion == 'select' ||  editedQuestion.typeOfQuestion == 'checkbox'"
                                 class="question-options">

                                <v-list-item-title class="subtitle-2 font-weight-bold ">
                                    Pergunta:
                                    <!-- FOR EACH HEADER (ONE PER LANGUAGE, SHOW LINGUAGEM CORRESPONDENTE) -->
                                    <template v-for="header in editedQuestion.headers">
                                        <v-text-field style="font-weight: normal" class="pl-4"
                                                      v-if="header.language == 'pt'"
                                                      v-model="header.value" outlined
                                                      :error="header.value == ''"
                                                      dense
                                                      @keyup="validate()"
                                                      hide-details></v-text-field>
                                    </template>
                                </v-list-item-title>

                                <h4 class="mt-3 mb-1">Opções:</h4>
                                <v-list-item>
                                    <v-row>
                                        <v-col class="col-md-6" v-if="editedQuestion.options != undefined"
                                               v-for="option in editedQuestion.options" :key="option.id">
                                            <v-card v-if="option != undefined">
                                                <v-card-text class="option-card">
                                                    <v-row>
                                                        <v-col class="pb-1"
                                                               style="display: flex; align-items: center">
                                                            Posição:
                                                            <v-text-field
                                                                    style="font-weight: normal; max-width: 50px"
                                                                    v-model="option.orderOption"
                                                                    class="form-question-number"
                                                                    dense outlined
                                                                    @keyup="validate()"
                                                                    hide-details></v-text-field>
                                                        </v-col>
                                                        <v-col class="pb-1"
                                                               style="display: flex; justify-content: right">
                                                            <v-btn tile small color="white"
                                                                   depressed text
                                                                   class="float-right ml-2 white--text pr-0 pl-0"
                                                                   @click="openConfirmationDelete(option.id, 'option')">
                                                                <v-icon color="red">mdi-delete</v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col class="pt-1"
                                                               style="display: flex; align-items: center">
                                                            <template
                                                                    v-for="optionLang in option.translations">

                                                                <v-text-field
                                                                        style="font-weight: normal"
                                                                        v-if="optionLang.language == 'pt'"
                                                                        v-model="optionLang.value"
                                                                        outlined
                                                                        dense
                                                                        @keyup="validate()"
                                                                        hide-details></v-text-field>
                                                            </template>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>

                                        <!-- COL NOVA OPÇÃO DE RESPOSTA -->
                                        <v-col class="col-md-6" style="display: flex;align-items: center">
                                            <v-btn tile small color="success"
                                                   class="float-right ml-2 white--text"
                                                   @click="addOptionToQuestion(editedQuestion.id)">
                                                Nova Opção
                                            </v-btn>
                                        </v-col>
                                    </v-row>

                                </v-list-item>
                            </div>

                            <div v-if="editedQuestion.typeOfQuestion == 'group-radio'">
                                <!-- MAIN QUESTION -->
                                <v-list-item-title class="subtitle-2 font-weight-bold ">
                                    Pergunta:
                                    <!-- FOR EACH HEADER (ONE PER LANGUAGE, SHOW LINGUAGEM CORRESPONDENTE) -->
                                    <template v-for="tableHeader in editedQuestion.tableGroupHeader[0].headers">
                                        <v-text-field style="font-weight: normal" class="pl-4"
                                                      v-if="tableHeader.language == 'pt'"
                                                      v-model="tableHeader.value" outlined
                                                      :error="tableHeader.value == ''"
                                                      dense
                                                      @keyup="validate()"
                                                      hide-details></v-text-field>
                                    </template>
                                    <span style="color: red;float: right;font-size: 11px"
                                          v-if="editedQuestion.tableGroupHeader[0].id == 'new'">Deve guardar a questão principal, para puder adicionar questões!</span>
                                </v-list-item-title>

                                <!-- NEW QUESTIONS OF GROUP-RADIO -->
                                <!-- QUESTIONS OF GROUP-RADIO -->
                                <v-divider class="mt-2 mb-2"></v-divider>
                                <v-row>
                                    <v-col>
                                        <h4 class="mt-3 mb-1">Questões</h4>
                                    </v-col>
                                    <v-col class="col-actions-question">
                                        <v-btn tile small color="info"
                                               depressed outlined
                                               class="float-right ml-2 info-question-btn"
                                               @click="addQuestionToTableQuestions(questionsTableGroup)"
                                               :disabled="editedQuestion.tableGroupHeader[0].id == 'new'">
                                            <v-icon size="18">
                                                mdi-plus
                                            </v-icon>
                                            QUESTÃO
                                        </v-btn>
                                        <v-btn tile small color="info"
                                               depressed outlined
                                               class="float-right ml-2 info-question-btn"
                                               @click="addOptionToQuestionTable(questionsTableGroup[0], 0)"
                                               :disabled="editedQuestion.tableGroupHeader[0].id == 'new'">
                                            <v-icon size="18">
                                                mdi-plus
                                            </v-icon>
                                            Opção
                                        </v-btn>
                                        <v-btn tile small color="black"
                                               depressed outlined
                                               id="add-question"
                                               class="float-right ml-2 black--text "
                                               @click="saveQuestionTable(questionsTableGroup)"
                                               :disabled="isEdited"
                                        >
                                            GUARDAR
                                            <v-icon class="not-hover" size="18">mdi-check</v-icon>
                                            <v-icon class="hover" style="display: none" size="18">
                                                mdi-check
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                  </v-row>
                                <v-row class="mt-0" v-if="editedQuestion.tableGroupHeader[0].id != 'new'">
                                    <v-col>
                                        <v-simple-table>
                                            <tbody>
                                            <tr v-for="(tableQuestion, index) in questionsTableGroup">
                                                <td>
                                                    <template v-for="tableQuestionHeader in tableQuestion.headers">
                                                        <v-text-field style="font-weight: normal"
                                                                      class="form-group-question-text"
                                                                      v-if="tableQuestionHeader.language == 'pt'"
                                                                      v-model="tableQuestionHeader.value"
                                                                      dense prepend-inner-icon="mdi-help-circle"
                                                                      @keyup="validate()"
                                                                      hide-details></v-text-field>
                                                    </template>
                                                </td>
                                                <td width="5%">
                                                    <v-btn tile small color="black"
                                                           depressed outlined
                                                           id="delete-question"
                                                           class="float-right ml-2 black--text"
                                                           @click="deleteQuestionTable(tableQuestion.id)">
                                                        <v-icon class="not-hover" size="16">mdi-delete</v-icon>
                                                        <v-icon class="hover"
                                                                style="display: none;min-width: 18px !important;"
                                                                size="16">mdi-delete-empty
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>

                                <!-- COMUN OPTIONS OF GROUP-RADIO QUESTIONS -->
                                <v-row class="mt-0" v-if="editedQuestion.tableGroupHeader[0].id != 'new'">
                                    <v-col>
                                        <h4 class="mt-3 mb-1">Opções</h4>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0" v-if="editedQuestion.tableGroupHeader[0].id != 'new'">
                                    <v-col class="col-md-6"
                                           v-for="tableQuestionOption in questionsTableGroup[0].options"
                                            :key="tableQuestionOption.id">
                                        <v-card>
                                            <v-card-text class="option-card">
                                                <v-row>
                                                    <v-col class="pb-1"
                                                           style="display: flex; align-items: center">
                                                        Posição:
                                                        <v-text-field
                                                                style="font-weight: normal; max-width: 50px"
                                                                v-model="tableQuestionOption.orderOption"
                                                                class="form-question-number"
                                                                dense outlined
                                                                @keyup="validate()"
                                                                hide-details></v-text-field>
                                                    </v-col>
                                                    <v-col class="pb-1"
                                                           style="display: flex; justify-content: right">
                                                        <v-btn tile small color="white"
                                                               depressed text
                                                               class="float-right ml-2 white--text pr-0 pl-0"
                                                               @click="openConfirmationDelete(tableQuestionOption.id, 'optionQuestion-group-radio', questionsTableGroup[0])">
                                                            <v-icon color="red">mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col class="pt-1"
                                                           style="display: flex; align-items: center">
                                                        <template
                                                                v-for="optionLang in tableQuestionOption.translations">

                                                            <v-text-field
                                                                    style="font-weight: normal"
                                                                    v-if="optionLang.language == 'pt'"
                                                                    v-model="optionLang.value"
                                                                    outlined
                                                                    dense
                                                                    @keyup="validate()"
                                                                    hide-details></v-text-field>
                                                        </template>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>


                                <!-- OLD QUESTIONS OF GROUP-RADIO (HEADER+OPTIONS) -->
                                <!--                                <v-row>-->
                                <!--                                    <v-col>-->
                                <!--                                        <h4 class="mt-3 mb-1">Questões</h4>-->
                                <!--                                    </v-col>-->
                                <!--                                    <v-col class="col-actions-question">-->
                                <!--                                        <v-btn tile small color="success"-->
                                <!--                                               class="float-right ml-2 white&#45;&#45;text"-->
                                <!--                                               @click="addQuestionToTableQuestions(questionsTableGroup)"-->
                                <!--                                               :disabled="editedQuestion.tableGroupHeader[0].id == 'new'">-->
                                <!--                                            ADICIONAR QUESTÃO-->
                                <!--                                        </v-btn>-->
                                <!--                                    </v-col>-->
                                <!--                                </v-row>-->
                                <!--                                <span style="color: red;float: right;font-size: 11px"-->
                                <!--                                      v-if="editedQuestion.tableGroupHeader[0].id == 'new'">Deve guardar a questão principal, para puder adicionar questões!</span>-->
                                <!--                                &lt;!&ndash; CARD COM CADA QUESTÃO DO GROUP RADIO &ndash;&gt;-->
                                <!--                                <v-card class="table-question-card ml-4" v-if="questionsTableGroup != null"-->
                                <!--                                        v-for="(tableQuestion, index) in questionsTableGroup">-->
                                <!--                                    <v-card-text>-->
                                <!--                                        <v-list-item-title class="subtitle-2 font-weight-bold ">-->
                                <!--                                            <v-row>-->
                                <!--                                                <v-col>-->
                                <!--                                                    <span v-if="debug"-->
                                <!--                                                          style="background-color: red;color: white;padding: 5px">{{-->
                                <!--                                                            tableQuestion.id-->
                                <!--                                                        }}</span>-->
                                <!--                                                    Pergunta:-->
                                <!--                                                    <v-btn tile small color="white"-->
                                <!--                                                           depressed outlined-->
                                <!--                                                           id="add-question"-->
                                <!--                                                           class="float-right ml-2 white&#45;&#45;text "-->
                                <!--                                                           @click="saveQuestionTable(tableQuestion)"-->
                                <!--                                                           :disabled="isEdited"-->
                                <!--                                                    >-->
                                <!--                                                        GUARDAR-->
                                <!--                                                        <v-icon class="not-hover" size="18">mdi-plus</v-icon>-->
                                <!--                                                        <v-icon class="hover" style="display: none" size="16">-->
                                <!--                                                            mdi-check-->
                                <!--                                                        </v-icon>-->
                                <!--                                                    </v-btn>-->
                                <!--                                                    <v-btn tile small color="white"-->
                                <!--                                                           depressed outlined-->
                                <!--                                                           id="delete-question"-->
                                <!--                                                           class="float-right ml-2 white&#45;&#45;text "-->
                                <!--                                                           style="margin-bottom: 6px;"-->
                                <!--                                                           @click="deleteQuestionTable(tableQuestion.id)">-->
                                <!--                                                        REMOVER-->
                                <!--                                                        <v-icon class="not-hover" size="16">mdi-delete</v-icon>-->
                                <!--                                                        <v-icon class="hover"-->
                                <!--                                                                style="display: none;min-width: 18px !important;"-->
                                <!--                                                                size="16">mdi-delete-empty-->
                                <!--                                                        </v-icon>-->
                                <!--                                                    </v-btn>-->
                                <!--                                                </v-col>-->
                                <!--                                            </v-row>-->
                                <!--                                            &lt;!&ndash; FOR EACH HEADER (ONE PER LANGUAGE, SHOW LINGUAGEM CORRESPONDENTE) &ndash;&gt;-->
                                <!--                                            <template v-for="tableQuestionHeader in tableQuestion.headers">-->
                                <!--                                                <v-text-field style="font-weight: normal" class="pl-4"-->
                                <!--                                                              v-if="tableQuestionHeader.language == 'pt'"-->
                                <!--                                                              v-model="tableQuestionHeader.value" outlined-->
                                <!--                                                              dense-->
                                <!--                                                              @keyup="validate()"-->
                                <!--                                                              hide-details></v-text-field>-->
                                <!--                                            </template>-->
                                <!--                                        </v-list-item-title>-->


                                <!--                                        <h4 class="mt-3 mb-1" v-if="!(groupRadioUseSameOptions && index != 0 )">Opções:</h4>-->
                                <!--                                        <v-list-item v-if="!(groupRadioUseSameOptions && index != 0 )">-->
                                <!--                                            <v-row>-->
                                <!--                                                <v-col class="col-md-6"-->
                                <!--                                                       v-for="tableQuestionOption in tableQuestion.options" >-->
                                <!--                                                    <v-card>-->
                                <!--                                                        <v-card-text class="option-card">-->
                                <!--                                                            <v-row>-->
                                <!--                                                                <v-col class="pb-1"-->
                                <!--                                                                       style="display: flex; align-items: center">-->
                                <!--                                                                    Posição:-->
                                <!--                                                                    <v-text-field-->
                                <!--                                                                            style="font-weight: normal; max-width: 50px"-->
                                <!--                                                                            v-model="tableQuestionOption.orderOption"-->
                                <!--                                                                            class="form-question-number"-->
                                <!--                                                                            dense outlined-->
                                <!--                                                                            @keyup="validate()"-->
                                <!--                                                                            hide-details></v-text-field>-->
                                <!--                                                                </v-col>-->
                                <!--                                                                <v-col class="pb-1"-->
                                <!--                                                                       style="display: flex; justify-content: right">-->
                                <!--                                                                    <v-btn tile small color="white"-->
                                <!--                                                                           depressed text-->
                                <!--                                                                           class="float-right ml-2 white&#45;&#45;text pr-0 pl-0"-->
                                <!--                                                                           @click="openConfirmationDelete(tableQuestionOption.id, 'optionQuestion-group-radio', tableQuestion)">-->
                                <!--                                                                        <v-icon color="red">mdi-delete</v-icon>-->
                                <!--                                                                    </v-btn>-->
                                <!--                                                                </v-col>-->
                                <!--                                                            </v-row>-->
                                <!--                                                            <v-row>-->
                                <!--                                                                <v-col class="pt-1"-->
                                <!--                                                                       style="display: flex; align-items: center">-->
                                <!--                                                                    <template-->
                                <!--                                                                            v-for="optionLang in tableQuestionOption.translations">-->

                                <!--                                                                        <v-text-field-->
                                <!--                                                                                style="font-weight: normal"-->
                                <!--                                                                                v-if="optionLang.language == 'pt'"-->
                                <!--                                                                                v-model="optionLang.value"-->
                                <!--                                                                                outlined-->
                                <!--                                                                                dense-->
                                <!--                                                                                @keyup="validate()"-->
                                <!--                                                                                hide-details></v-text-field>-->
                                <!--                                                                    </template>-->
                                <!--                                                                </v-col>-->
                                <!--                                                            </v-row>-->
                                <!--                                                        </v-card-text>-->
                                <!--                                                    </v-card>-->
                                <!--                                                </v-col>-->
                                <!--                                                &lt;!&ndash; COL NOVA OPÇÃO DE RESPOSTA &ndash;&gt;-->
                                <!--                                                <v-col class="col-md-6" style="display: flex;align-items: center">-->
                                <!--                                                    <v-btn tile small color="success"-->
                                <!--                                                           class="float-right ml-2 white&#45;&#45;text"-->
                                <!--                                                           @click="addOptionToQuestionTable(tableQuestion, index)">-->
                                <!--                                                        Nova Opção-->
                                <!--                                                    </v-btn>-->
                                <!--                                                </v-col>-->
                                <!--                                            </v-row>-->
                                <!--                                        </v-list-item>-->
                                <!--                                    </v-card-text>-->
                                <!--                                </v-card>-->
                            </div>

                        </v-list-item-content>
                    </v-col>

                    <!-- ES QUESTIONS DESC -->
                    <v-col class="col-md-6">
                        <v-list-item-content class="pt-0">
                            <flag iso="es" style="font-size: 30px" v-bind:squared=false class="float-right mt-1"></flag>

                            <div v-if="editedQuestion.typeOfQuestion == 'text-field' || editedQuestion.typeOfQuestion == 'text-area'"
                                 class="question-options">
                                <v-list-item-title class="subtitle-2 font-weight-bold ">
                                    Pergunta:
                                    <!-- FOR EACH HEADER (ONE PER LANGUAGE, SHOW LINGUAGEM CORRESPONDENTE) -->
                                    <template v-for="header in editedQuestion.headers">
                                        <v-text-field style="font-weight: normal" class="pl-4"
                                                      v-if="header.language == 'es'"
                                                      v-model="header.value" outlined
                                                      :error="header.value == ''"
                                                      dense
                                                      @keyup="validate()"
                                                      hide-details></v-text-field>
                                    </template>
                                </v-list-item-title>

                            </div>

                            <div v-if="editedQuestion.typeOfQuestion == 'radio' || editedQuestion.typeOfQuestion == 'select' || editedQuestion.typeOfQuestion == 'checkbox' "
                                 class="question-options">

                                <v-list-item-title class="subtitle-2 font-weight-bold ">
                                    Pergunta:
                                    <!-- FOR EACH HEADER (ONE PER LANGUAGE, SHOW LINGUAGEM CORRESPONDENTE) -->
                                    <template v-for="header in editedQuestion.headers">
                                        <v-text-field style="font-weight: normal" class="pl-4"
                                                      v-if="header.language == 'es'"
                                                      v-model="header.value" outlined
                                                      :error="header.value == ''"
                                                      dense
                                                      @keyup="validate()"
                                                      hide-details></v-text-field>
                                    </template>
                                </v-list-item-title>

                                <h4 class="mt-3 mb-1">Opções:</h4>
                                <v-list-item>
                                    <v-row>
                                        <v-col class="col-md-6" v-if="editedQuestion.options != undefined"
                                               v-for="option in editedQuestion.options">
                                            <v-card v-if="option != undefined">
                                                <v-card-text class="option-card">
                                                    <v-row>
                                                        <v-col class="pb-1"
                                                               style="display: flex; align-items: center">
                                                            Posição:
                                                            <v-text-field
                                                                    style="font-weight: normal; max-width: 50px"
                                                                    v-if="option.orderOption != undefined"
                                                                    v-model="option.orderOption"
                                                                    class="form-question-number"
                                                                    dense outlined
                                                                    @keyup="validate()"
                                                                    hide-details></v-text-field>

                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col class="pt-1"
                                                               style="display: flex; align-items: center">
                                                            <template
                                                                    v-for="optionLang in option.translations">

                                                                <v-text-field
                                                                        style="font-weight: normal"
                                                                        v-if="optionLang.language == 'es'"
                                                                        v-model="optionLang.value"
                                                                        outlined
                                                                        dense
                                                                        @keyup="validate()"
                                                                        hide-details></v-text-field>
                                                            </template>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-list-item>
                            </div>

                            <div v-if="editedQuestion.typeOfQuestion == 'group-radio'">
                                <!-- MAIN QUESTION -->
                                <v-list-item-title class="subtitle-2 font-weight-bold ">
                                    Pergunta:
                                    <!-- FOR EACH HEADER (ONE PER LANGUAGE, SHOW LINGUAGEM CORRESPONDENTE) -->
                                    <template v-for="tableHeader in editedQuestion.tableGroupHeader[0].headers">
                                        <v-text-field style="font-weight: normal" class="pl-4"
                                                      v-if="tableHeader.language == 'es'"
                                                      v-model="tableHeader.value" outlined
                                                      :error="tableHeader.value == ''"
                                                      dense
                                                      @keyup="validate()"
                                                      hide-details></v-text-field>
                                    </template>
                                </v-list-item-title>

                                <!-- NEW QUESTIONS OF GROUP-RADIO -->
                                <v-divider class="mt-2 mb-2"></v-divider>
                                <v-row>
                                    <v-col>
                                        <h4 class="mt-3 mb-1">Questões</h4>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0" v-if="editedQuestion.tableGroupHeader[0].id != 'new'">
                                    <v-col>
                                        <v-simple-table>
                                            <tbody>
                                            <tr v-for="(tableQuestion, index) in questionsTableGroup">
                                                <td>
                                                    <template v-for="tableQuestionHeader in tableQuestion.headers">
                                                        <v-text-field style="font-weight: normal"
                                                                      class="form-group-question-text"
                                                                      v-if="tableQuestionHeader.language == 'es'"
                                                                      v-model="tableQuestionHeader.value"
                                                                      dense prepend-inner-icon="mdi-help-circle"
                                                                      @keyup="validate()"
                                                                      hide-details></v-text-field>
                                                    </template>
                                                </td>
                                                <td width="5%">
                                                    <v-btn tile small color="black"
                                                           depressed outlined
                                                           id="delete-question"
                                                           class="float-right ml-2 black--text"
                                                           @click="deleteQuestionTable(tableQuestion.id)">
                                                        <v-icon class="not-hover" size="16">mdi-delete</v-icon>
                                                        <v-icon class="hover"
                                                                style="display: none;min-width: 18px !important;"
                                                                size="16">mdi-delete-empty
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>

                                <!-- COMUN OPTIONS OF GROUP-RADIO QUESTIONS -->
                                <v-row class="mt-0" v-if="editedQuestion.tableGroupHeader[0].id != 'new'">
                                    <v-col>
                                        <h4 class="mt-3 mb-1">Opções</h4>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-0" v-if="editedQuestion.tableGroupHeader[0].id != 'new'">
                                    <v-col class="col-md-6"
                                           v-for="tableQuestionOption in questionsTableGroup[0].options">
                                        <v-card>
                                            <v-card-text class="option-card">
                                                <v-row>
                                                    <v-col class="pb-1"
                                                           style="display: flex; align-items: center">
                                                        Posição:
                                                        <v-text-field
                                                                style="font-weight: normal; max-width: 50px"
                                                                v-model="tableQuestionOption.orderOption"
                                                                class="form-question-number"
                                                                dense outlined
                                                                @keyup="validate()"
                                                                hide-details></v-text-field>
                                                    </v-col>
                                                    <v-col class="pb-1"
                                                           style="display: flex; justify-content: right">
                                                        <v-btn tile small color="white"
                                                               depressed text
                                                               class="float-right ml-2 white--text pr-0 pl-0"
                                                               @click="openConfirmationDelete(tableQuestionOption.id, 'optionQuestion-group-radio', tableQuestion)">
                                                            <v-icon color="red">mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col class="pt-1"
                                                           style="display: flex; align-items: center">
                                                        <template
                                                                v-for="optionLang in tableQuestionOption.translations">

                                                            <v-text-field
                                                                    style="font-weight: normal"
                                                                    v-if="optionLang.language == 'es'"
                                                                    v-model="optionLang.value"
                                                                    outlined
                                                                    dense
                                                                    @keyup="validate()"
                                                                    hide-details></v-text-field>
                                                        </template>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>


                                <!-- OLD QUESTIONS OF GROUP RADIO (HEADER+OPTIONS) -->
                                <!--                                <v-row>-->
                                <!--                                    <v-col>-->
                                <!--                                        <h4 class="mt-3 mb-1">Questões</h4>-->
                                <!--                                    </v-col>-->
                                <!--                                </v-row>-->
                                <!--                                <v-card class="table-question-card ml-4" v-if="editedQuestion"-->
                                <!--                                        v-for="(tableQuestion, index) in questionsTableGroup">-->
                                <!--                                    <v-card-text>-->
                                <!--                                        <v-list-item-title class="subtitle-2 font-weight-bold ">-->
                                <!--                                            <v-row>-->
                                <!--                                                <v-col style="min-height: 58px;">-->
                                <!--                                                    <span v-if="debug"-->
                                <!--                                                          style="background-color: red;color: white;padding: 5px">{{-->
                                <!--                                                            tableQuestion.id-->
                                <!--                                                        }}</span>-->
                                <!--                                                    Pergunta:-->
                                <!--                                                </v-col>-->
                                <!--                                            </v-row>-->

                                <!--                                            &lt;!&ndash; FOR EACH HEADER (ONE PER LANGUAGE, SHOW LINGUAGEM CORRESPONDENTE) &ndash;&gt;-->
                                <!--                                            <template v-for="tableQuestionHeader in tableQuestion.headers">-->
                                <!--                                                <v-text-field style="font-weight: normal" class="pl-4"-->
                                <!--                                                              v-if="tableQuestionHeader.language == 'es'"-->
                                <!--                                                              v-model="tableQuestion.headers[1].value" outlined-->
                                <!--                                                              dense-->
                                <!--                                                              @keyup="validate()"-->
                                <!--                                                              hide-details></v-text-field>-->
                                <!--                                            </template>-->
                                <!--                                        </v-list-item-title>-->


                                <!--                                        <h4 class="mt-3 mb-1"  v-if="!(groupRadioUseSameOptions && index != 0 )">Opções:</h4>-->
                                <!--                                        <v-list-item v-if="!(groupRadioUseSameOptions && index != 0 )">-->
                                <!--                                            <v-row>-->
                                <!--                                                <v-col class="col-md-6"-->
                                <!--                                                       v-for="tableQuestionOption in tableQuestion.options">-->
                                <!--                                                    <v-card>-->
                                <!--                                                        <v-card-text class="option-card">-->
                                <!--                                                            <v-row>-->
                                <!--                                                                <v-col class="pb-1"-->
                                <!--                                                                       style="display: flex; align-items: center">-->
                                <!--                                                                    Posição:-->
                                <!--                                                                    <v-text-field-->
                                <!--                                                                            style="font-weight: normal; max-width: 50px"-->
                                <!--                                                                            v-model="tableQuestionOption.orderOption"-->
                                <!--                                                                            class="form-question-number"-->
                                <!--                                                                            dense outlined-->
                                <!--                                                                            @keyup="validate()"-->
                                <!--                                                                            hide-details></v-text-field>-->
                                <!--                                                                </v-col>-->
                                <!--                                                            </v-row>-->
                                <!--                                                            <v-row>-->
                                <!--                                                                <v-col class="pt-1"-->
                                <!--                                                                       style="display: flex; align-items: center">-->
                                <!--                                                                    <template-->
                                <!--                                                                            v-for="optionLang in tableQuestionOption.translations">-->

                                <!--                                                                        <v-text-field-->
                                <!--                                                                                style="font-weight: normal"-->
                                <!--                                                                                v-if="optionLang.language == 'es'"-->
                                <!--                                                                                v-model="optionLang.value"-->
                                <!--                                                                                outlined-->
                                <!--                                                                                dense-->
                                <!--                                                                                @keyup="validate()"-->
                                <!--                                                                                hide-details></v-text-field>-->
                                <!--                                                                    </template>-->
                                <!--                                                                </v-col>-->
                                <!--                                                            </v-row>-->
                                <!--                                                        </v-card-text>-->
                                <!--                                                    </v-card>-->
                                <!--                                                </v-col>-->
                                <!--                                                <v-col v-if="(tableQuestion.options.length % 2 == 0)" class="col-md-6"-->
                                <!--                                                       style="min-height: 52px"></v-col>-->
                                <!--                                            </v-row>-->
                                <!--                                        </v-list-item>-->

                                <!--                                    </v-card-text>-->
                                <!--                                </v-card>-->
                            </div>

                        </v-list-item-content>
                    </v-col>
                </v-row>

            </v-card-text>

        </v-card-text>
        <v-card-actions style="background-color: rgba(0,0,0,.06);">
            <v-spacer></v-spacer>
            <v-btn
                    tile small color="red"
                    class="float-right ml-2 white--text"
                    @click="closeModal(false)"
            >
                CANCELAR
            </v-btn>
            <v-btn
                    tile small color="success"
                    @click="saveQuestion()"
                    :disabled="isEdited"
            >
                GUARDAR
            </v-btn>
        </v-card-actions>

        <!-- DIALOG TO CONFIRM DELETE -->
        <v-dialog
                v-model="confirmationDeleteOption"
                persistent
                max-width="290"
        >
            <v-card>
                <v-card-title>Confirmação</v-card-title>
                <v-card-text>
                    Tem a certeza que pretende remover a
                    {{ typeToDelete == 'optionQuestion-group-radio' ? 'opção' : typeToDelete }} <span
                        v-if="optionToDelete != 0"> com id <strong>{{ optionToDelete }}</strong> </span> ?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="green darken-1"
                            text
                            @click="confirmationDeleteOption = false"
                    >
                        CANCELAR
                    </v-btn>
                    <!--                                                                        @click="removeOption(option.id == undefined ? 'new' : option.id)"-->
                    <v-btn
                            color="green darken-1"
                            text
                            @click="removeOption(optionToDelete != 0 ? optionToDelete : 'new')"
                    >
                        CONFIRMAR
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- DIALOG TO SELECT ACTIVATOR OPTION -->
        <v-dialog v-model="selectAtivatorOption"
                  persistent
                  max-width="70%">
            <v-card>
                <v-card-text class="pb-2">
                    <!--STEP QUESTIONS -->
                    <v-simple-table v-if="selectedAtivatorQuestion == null" class="staff_table pt-3">
                        <thead>
                        <tr>
                            <th colspan="4" style="border-bottom: 1px solid black;"><h3>Questões</h3></th>
                        </tr>
                        <tr>
                            <th style="width: 30px;" class="text-center">ID</th>
                            <th style="width: 30px;">Posição</th>
                            <th class="text-center" style="width: 150px;">Tipo</th>
                            <th>Pergunta</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="stepEdited.questions && editedQuestion.id != question.id"
                            v-for="question in stepEdited.questions"
                            class="table-row-question-step" @click="setQuestionOfActivatorOption(question)">
                            <td class="text-center">{{ question.id }}</td>
                            <td class="text-center">{{ question.orderOnStep }}</td>
                            <td class="text-center">{{ question.typeOfQuestion }}</td>
                            <td v-if="question.typeOfQuestion == 'group-radio'">
                                <flag iso="pt" v-bind:squared=false></flag>
                                PT -
                                {{
                                    question.tableGroupHeader[0].headers[question.tableGroupHeader[0].headers.findIndex(header => header.language == 'pt')].value
                                }}
                                /
                                <flag iso="es" v-bind:squared=false></flag>
                                ES -
                                {{
                                    question.tableGroupHeader[0].headers[question.tableGroupHeader[0].headers.findIndex(header => header.language == 'es')].value
                                }}
                            </td>
                            <td v-else>
                                <flag iso="pt" v-bind:squared=false></flag>
                                PT -
                                {{
                                    question.headers[question.headers.findIndex(header => header.language == 'pt')].value
                                }}
                                /
                                <flag iso="es" v-bind:squared=false></flag>
                                ES -
                                {{
                                    question.headers[question.headers.findIndex(header => header.language == 'es')].value
                                }}
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                    <!-- QUESTION OPTIONS -->
                    <v-simple-table v-else class="staff_table pt-3">
                        <thead>
                        <tr>
                            <th colspan="4" style="border-bottom: 1px solid black;"><h3>Opções</h3></th>
                        </tr>
                        <tr>
                            <th style="width: 5%;" class="text-center">ID</th>
                            <th style="width: 10%;" class="text-center">Posição</th>
                            <th style="width: 80%;">Opção</th>
                            <th style="width: 5%"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="selectedAtivatorQuestion.options" v-for="option in selectedAtivatorQuestion.options">
                            <td class="text-center">{{ option.id }}</td>
                            <td class="text-center">{{ option.orderOption }}</td>
                            <td>
                                <flag iso="pt" v-bind:squared=false></flag>
                                PT -
                                {{
                                    option.translations[option.translations.findIndex(header => header.language == 'pt')].value
                                }}
                                /
                                <flag iso="es" v-bind:squared=false></flag>
                                ES -
                                {{
                                    option.translations[option.translations.findIndex(header => header.language == 'es')].value
                                }}
                            </td>
                            <td>
                                <v-btn @click="saveActivatorOption(option.id)"
                                       :disabled="option.id == editedQuestion.required_option"
                                       style="min-width: 112px"
                                       tile small color="info">
                                    {{ option.id == editedQuestion.required_option ? 'Selecionado' : 'Selecionar' }}
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn v-if="selectedAtivatorQuestion == null" @click="selectAtivatorOption = false"
                           tile small color="black"
                           class="float-right ml-2 white--text">
                        Cancelar
                    </v-btn>
                    <v-btn v-else @click="selectedAtivatorQuestion = null"
                           tile small color="black"
                           class="float-right ml-2 white--text">
                        <v-icon size="18px">mdi-arrow-left</v-icon>
                        Voltar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
import axios from "axios"
import {isObject} from "lodash";

export default {
    props: {
        question: {
            require: true
        },
        editedQuestion: {
            require: true
        },
        newQuestion: {
            require: true
        },
        stepEdited: {
            require: true
        }
    },
    data: function () {
        return {
            debug: false,
            questionTypes: [
                {text: 'escolha multipla', value: 'radio'},
                {text: 'multiplas respostas', value: 'checkbox'},
                {text: 'seleção', value: 'select'},
                {text: 'grupo questões', value: 'group-radio'},
                {text: 'resposta aberta', value: 'text-field'},
                {text: 'resposta aberta longa', value: 'text-area'},
            ],
            questionsTableGroup: [],
            originalQuestionsTableGroup: [],
            isEdited: true,
            loading: true,
            emptyOption: {
                //Falta o id e formQuestionId
                id: null,
                orderOption: '',
                formQuestionId: '',
                translations: [
                    //Falta o id
                    {language: 'pt', questionOptionId: null, value: ''},
                    {language: 'es', questionOptionId: null, value: ''}
                ]
            },
            newEmptyQuestion: {
                headers: [
                    {formQuestionId: null, language: 'pt', value: ''},
                    {formQuestionId: null, language: 'es', value: ''}
                ],
                options: [],
                orderOnStep: '',
                required: true,
                typeOfQuestion: "radio"
            },
            confirmationDeleteOption: false,
            optionToDelete: 0,
            typeToDelete: null,
            selectedOptionTableGRQuestion: null,
            selectedTableQuestions: null,
            selectAtivatorOption: false,
            selectedAtivatorQuestion: null,
            groupRadioUseSameOptions: false
        }
    },
    mounted() {
        this.formId = this.$route.params.id;
        // console.log(this.editedQuestion)

        if (this.editedQuestion && this.editedQuestion.typeOfQuestion == 'group-radio') {
            this.loading = true
            if (this.editedQuestion.tableGroupQuestions == undefined) {
                this.editedQuestion.tableGroupQuestions = JSON.stringify([]);
            }
            this.questionsTableGroup = this.editedQuestion.tableGroupHeader[0].id == 'new' ? this.editedQuestion.tableGroupQuestions : JSON.parse(this.editedQuestion.tableGroupQuestions);
            this.originalQuestionsTableGroup = JSON.parse(JSON.stringify(this.editedQuestion.tableGroupQuestions));
            this.originalQuestionsTableGroup = JSON.parse(this.originalQuestionsTableGroup);
        }

        this.loading = false;
    },
    methods: {
        closeModal(reload) {
            this.isEdited = true;

            if (this.editedQuestion && this.editedQuestion.typeOfQuestion == 'group-radio') {
                if (this.editedQuestion.tableGroupQuestions == undefined) {
                    this.editedQuestion.tableGroupQuestions = JSON.stringify([]);
                }
                this.questionsTableGroup = this.editedQuestion.tableGroupHeader[0].id == 'new' ? this.editedQuestion.tableGroupQuestions : JSON.parse(this.editedQuestion.tableGroupQuestions);
            }

            this.$emit('close-modal', false);
            if (reload) {
                this.$emit('get-form', false);
            }
        },
        validate() {
            this.isEdited = true;

            if (this.editedQuestion.required != this.question.required) {
                this.isEdited = false;
            }
            if (this.editedQuestion.typeOfQuestion != this.question.typeOfQuestion) {
                this.isEdited = false
            }
            if (this.editedQuestion.orderOnStep != this.question.orderOnStep) {
                this.isEdited = false
            }

            if (this.editedQuestion.typeOfQuestion == 'group-radio') {

                setTimeout(function (){
                    //waiting
                }, 1000)

                for (let i = 0; i < (this.editedQuestion.tableGroupHeader[0].headers).length; i++) {
                    // MainQuestion
                    if (this.editedQuestion.tableGroupHeader[0].headers[i].value != this.question.tableGroupHeader[0].headers[i].value) {
                        this.isEdited = false
                    }
                    if (this.editedQuestion.tableGroupHeader[0].headers[i].value == '') {
                        this.isEdited = true;
                    }
                }

                //Questions of tableGroup
                // for (let j = 0; j < JSON.parse(this.editedQuestion.tableGroupQuestions).length; j++) {
                if (this.editedQuestion.tableGroupHeader[0].id != 'new') {
                    for (let j = 0; j < this.questionsTableGroup.length; j++) {

                        //Validar alterações headers e options das questões
                        //HeaderPT
                        // console.log(JSON.parse(this.question.tableGroupQuestions)[j])
                        // console.log(this.questionsTableGroup[j])

                        let originalHeaders = this.originalQuestionsTableGroup[j].headers;
                        if (this.questionsTableGroup[j].headers[0].value != originalHeaders[0].value) {
                            this.isEdited = false
                        }
                        //HeaderES
                        if (this.questionsTableGroup[j].headers[1].value != originalHeaders[1].value) {
                            this.isEdited = false
                        }


                        //Options
                        for (let k = 0; k < this.questionsTableGroup[j].options.length; k++) {

                            //Se null é nova questão
                            if (this.questionsTableGroup[j].options[k].id != null) {
                                let originalOptions = this.originalQuestionsTableGroup[j].options

                                if (this.questionsTableGroup[j].options[k].orderOption != originalOptions[k].orderOption) {
                                    this.isEdited = false
                                }
                                //PT
                                if (this.questionsTableGroup[j].options[k].translations[0].value != originalOptions[k].translations[0].value) {
                                    this.isEdited = false
                                }
                                //ES
                                if (this.questionsTableGroup[j].options[k].translations[1].value != originalOptions[k].translations[1].value) {
                                    this.isEdited = false
                                }
                            } else {
                                this.isEdited = false;
                            }
                        }
                    }
                }


                // console.log(JSON.parse(this.editedQuestion.tableGroupQuestions).length);
            } else {
                //Headers PT/ES
                if (this.editedQuestion.headers[0].value != this.question.headers[0].value) {
                    this.isEdited = false
                }
                if (this.editedQuestion.headers[1].value != this.question.headers[1].value) {
                    this.isEdited = false
                }

                //text-field/text-area não têm opções
                if (this.editedQuestion.typeOfQuestion != 'text-field' && this.editedQuestion.typeOfQuestion != 'text-area') {
                    //Options texts PT/ES
                    if (this.editedQuestion.options) {
                        for (let i = 0; i < this.editedQuestion.options.length; i++) {
                            if (this.editedQuestion.options[i].orderOption != this.question.options[i].orderOption) {
                                this.isEdited = false
                            }
                            //PT
                            if (this.editedQuestion.options[i].translations[0].value != this.question.options[i].translations[0].value) {
                                this.isEdited = false
                            }
                            //ES
                            if (this.editedQuestion.options[i].translations[1].value != this.question.options[i].translations[1].value) {
                                this.isEdited = false
                            }
                        }
                    }
                }

                //VALIDATE EMPTY FIELDS
                if (this.editedQuestion.headers[0].value == '' || this.editedQuestion.headers[1].value == '') {
                    this.isEdited = true;
                }

            }

            //Empty fields
            if (this.editedQuestion.orderOnStep == '' || parseInt(this.editedQuestion.orderOnStep) <= 0) {
                this.isEdited = true
            }
        },
        addOptionToQuestion(questionId) {
            var auxNewOption = JSON.parse(JSON.stringify(this.emptyOption))
            auxNewOption.orderOption = (this.editedQuestion.options.length + 1)
            auxNewOption.formQuestionId = questionId
            this.isEdited = false;

            this.editedQuestion.options.push(auxNewOption)
            this.question.options.push(this.emptyOption)
            // console.log(this.editedQuestion.options)
        },
        addOptionToQuestionTable(question, questionIndex) {
            var auxNewOption = JSON.parse(JSON.stringify(this.emptyOption))
            auxNewOption.orderOption = (question.options.length + 1)
            auxNewOption.formQuestionId = question.id

            question.options.push(auxNewOption)

            // Original object
            var auxOriginalQuestion = this.originalQuestionsTableGroup[questionIndex]
            // console.log(JSON.parse(this.editedQuestion.tableGroupQuestions))
            var auxEmptyOption = JSON.parse(JSON.stringify(this.emptyOption))
            auxEmptyOption.orderOption = (question.options.length)
            auxEmptyOption.formQuestionId = question.id

            auxOriginalQuestion.options.push(auxEmptyOption);

            this.isEdited = false;
        },
        removeOption(optionId) {
            //Nova option, remover apenas ultimo valor do array
            if (optionId == 'new') {
                if (this.typeToDelete != 'optionQuestion-group-radio') {
                    this.editedQuestion.options.pop();
                    this.confirmationDeleteOption = false;
                } else {
                    this.selectedOptionTableGRQuestion.options.pop();
                    this.confirmationDeleteOption = false;
                }
            } else {
                //Chamar pop-up se tem a certeza que pretende apagar e apagar option pelo ID
                if (this.typeToDelete == 'option') {
                    this.deleteQuestionOption(optionId);
                }
                if (this.typeToDelete == 'question') {
                    this.deleteQuestion(optionId);
                }
                if (this.typeToDelete == 'optionQuestion-group-radio') {
                    this.deleteQuestionOption(optionId);
                }
            }
        },
        openConfirmationDelete(optionId, type, option = null) {
            this.confirmationDeleteOption = true
            this.typeToDelete = type;
            if (type == 'optionQuestion-group-radio') {
                this.selectedOptionTableGRQuestion = option;
            }
            if (optionId != undefined) {
                this.optionToDelete = optionId;
            } else {
                this.optionToDelete = 0;
            }
        },
        addQuestionToTableQuestions(tableQuestions) {
            this.selectedTableQuestions = tableQuestions;
            var auxNewQuestion = JSON.parse(JSON.stringify(this.newEmptyQuestion));
            auxNewQuestion.id = "new"
            auxNewQuestion.formId = this.question.formId;
            auxNewQuestion.formStepId = this.question.formStepId;
            auxNewQuestion.orderOnStep = tableQuestions.length + 1;

            tableQuestions.push(auxNewQuestion);

            //Inject newQuestion on original
            var auxOriginalNewQuestion = JSON.parse(JSON.stringify(this.newEmptyQuestion));
            auxOriginalNewQuestion.id = "new"
            auxOriginalNewQuestion.formId = this.question.formId;
            auxOriginalNewQuestion.formStepId = this.question.formStepId;
            auxOriginalNewQuestion.orderOnStep = tableQuestions.length;

            // var originalTableGroupQuestions = JSON.parse(this.question.tableGroupQuestions)
            // originalTableGroupQuestions.push(auxOriginalNewQuestion);
            //NOVVO O CORRECTO
            this.originalQuestionsTableGroup.push(auxOriginalNewQuestion);

            this.isEdited = false;
            // console.log(JSON.parse(this.question.tableGroupQuestions));
        },
        saveQuestion() {
            this.validate()
            if (!this.isEdited) {
                //Save - Do update on DB
                let formID = this.editedQuestion.formId
                let questionID = 0;
                if (this.newQuestion) {
                    questionID = 'new'
                } else {
                    questionID = this.editedQuestion.id
                }

                this.editedQuestion.tableGroupId = null;
                if (this.editedQuestion.typeOfQuestion == "group-radio") {
                    if (this.newQuestion) {
                        this.editedQuestion.tableGroupId = 'new';
                    } else {
                        this.editedQuestion.tableGroupId = this.editedQuestion.tableGroupHeader[0].id;
                    }
                }

                let body = {
                    question: this.editedQuestion,
                    formID: formID
                }

                let loader = this.$loading.show()
                axios.post(api.formSaveQuestion(formID, questionID), body)
                        .then(response => {
                            loader.hide()
                            let alert = {
                                snackbar: true,
                                text: 'Sucesso!',
                                type: 'success'
                            }
                            // console.log(response)

                            if (response.status == 200 || response.data.status == 'OK') {
                                //Question updated with success
                                this.closeModal(true)

                            } else {
                                alert = {
                                    snackbar: true,
                                    text: 'Erro!',
                                    type: 'error'
                                }
                            }

                            Alert.dispatch('show', alert)
                        })
                        .catch(response => {
                            loader.hide()
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                            Alert.dispatch('show', alert)
                        })

            }

        },
        saveQuestionTable(questionsTableGroup) {
            //Save - Do update on DB
            let formID = this.formId
            let questionID = 0;

            //Fazer forEach para percorrer todas as questão da questão group-radio
            let loader = this.$loading.show()
            for (let questionEditedKey in questionsTableGroup) {
                if (questionEditedKey >= 0) {

                    let questionEdited = questionsTableGroup[questionEditedKey];

                    if (this.newQuestion) {
                        questionID = 'new'
                    } else {
                        questionID = questionEdited.id
                    }
                    questionEdited.tableGroupId = this.question.tableGroupHeader[0].id;


                    //Prepare question options. Copy values from questions.Options[0]
                    for (let i = 0; i < questionsTableGroup[0].options.length; i++) {
                        //Igualar valores de PT/ES
                        questionsTableGroup[questionEditedKey].options[i].orderOption = questionsTableGroup[0].options[i].orderOption;

                        questionsTableGroup[questionEditedKey].options[i].translations[questionsTableGroup[questionEditedKey].options[i].translations.findIndex((object) => {
                            return object.language == 'pt'
                        })].value = questionsTableGroup[0].options[i].translations[questionsTableGroup[0].options[i].translations.findIndex((object) => {
                            return object.language == 'pt'
                        })].value

                        questionsTableGroup[questionEditedKey].options[i].translations[questionsTableGroup[questionEditedKey].options[i].translations.findIndex((object) => {
                            return object.language == 'es'
                        })].value = questionsTableGroup[0].options[i].translations[questionsTableGroup[0].options[i].translations.findIndex((object) => {
                            return object.language == 'es'
                        })].value

                    }


                    let body = {
                        question: questionEdited,
                        formID: formID
                    }

                    axios.post(api.formSaveQuestion(formID, questionID), body)
                            .then(response => {
                                if (questionEditedKey == (questionsTableGroup.length - 1)) {
                                    //last question
                                    loader.hide()

                                    let alert = {
                                        snackbar: true,
                                        text: 'Sucesso!',
                                        type: 'success'
                                    }

                                    if (response.status == 200) {
                                        //Question updated with success
                                        this.closeModal(true)

                                    } else {
                                        alert = {
                                            snackbar: true,
                                            text: 'Erro!',
                                            type: 'error'
                                        }
                                    }

                                    Alert.dispatch('show', alert)
                                }
                            })
                            .catch(response => {
                                loader.hide()
                                alert = {
                                    snackbar: true,
                                    text: 'Erro!',
                                    type: 'error'
                                }
                                Alert.dispatch('show', alert)
                            })
                }
            }


        },
        deleteQuestionOption(optionId) {
            let loader = this.$loading.show()
            axios.delete(api.formDeleteQuestionOption(this.editedQuestion.formId, this.editedQuestion.id, optionId))
                    .then(response => {
                        loader.hide()
                        let alert = {
                            snackbar: true,
                            text: 'Opção removida com sucesso!',
                            type: 'success'
                        }

                        if (response.status == 200) {
                            //Question updated with success
                            this.closeModal(true)

                        } else {
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                        }

                        Alert.dispatch('show', alert)
                    })
                    .catch(response => {
                        loader.hide()
                        alert = {
                            snackbar: true,
                            text: 'Erro!',
                            type: 'error'
                        }
                        Alert.dispatch('show', alert)
                    })
        },
        deleteQuestionTable(questionID) {
            if (questionID == 'new') {
                this.selectedTableQuestions.pop();
                this.originalQuestionsTableGroup.pop();
                if (this.selectedTableQuestions.length == this.originalQuestionsTableGroup.length) {
                    this.isEdited = true
                }
            } else {
                let loader = this.$loading.show()
                axios.delete(api.formDeleteQuestion(this.editedQuestion.formId, questionID))
                        .then(response => {
                            loader.hide()
                            let alert = {
                                snackbar: true,
                                text: 'Questão removida com sucesso!',
                                type: 'success'
                            }

                            if (response.status == 200) {
                                //Question updated with success
                                this.closeModal(true)

                            } else {
                                alert = {
                                    snackbar: true,
                                    text: 'Erro!',
                                    type: 'error'
                                }
                            }

                            Alert.dispatch('show', alert)
                        })
                        .catch(response => {
                            loader.hide()
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                            Alert.dispatch('show', alert)
                        })
            }

        },
        deleteQuestion(questionId) {
            let loader = this.$loading.show()
            axios.delete(api.formDeleteQuestion(this.editedQuestion.formId, this.editedQuestion.id))
                    .then(response => {
                        loader.hide()
                        let alert = {
                            snackbar: true,
                            text: 'Questão removida com sucesso!',
                            type: 'success'
                        }

                        if (response.status == 200) {
                            //Question updated with success
                            this.closeModal(true)

                        } else {
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                        }

                        Alert.dispatch('show', alert)
                    })
                    .catch(response => {
                        loader.hide()
                        alert = {
                            snackbar: true,
                            text: 'Erro!',
                            type: 'error'
                        }
                        Alert.dispatch('show', alert)
                    })
        },
        setQuestionOfActivatorOption(question) {
            this.selectedAtivatorQuestion = question;
        },
        saveActivatorOption(optionId) {
            //Make request to save this optionId
            let formID = this.editedQuestion.formId
            let questionID = this.editedQuestion.id

            let body = {
                requiredOption: optionId
            }

            let loader = this.$loading.show()
            this.selectedAtivatorQuestion = null

            axios.post(api.formSaveQuestionActivatorOption(formID, questionID), body)
                    .then(response => {
                        loader.hide()
                        let alert = {
                            snackbar: true,
                            text: 'Opção ativadora selecionada com sucesso!',
                            type: 'success'
                        }
                        // console.log(response)

                        if (response.status == 200 || response.data.status == 'OK') {
                            //Question updated with success
                            this.closeModal(true)

                        } else {
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                        }

                        Alert.dispatch('show', alert)
                    })
                    .catch(response => {
                        loader.hide()
                        alert = {
                            snackbar: true,
                            text: 'Erro!',
                            type: 'error'
                        }
                        Alert.dispatch('show', alert)
                    })
        }
    },
    computed: {}
}

</script>