<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title :title="'Upload '+$t('images')"></page-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        components: {
            vueDropzone: vue2Dropzone
        },
        data: function () {
            return {
                dropzoneOptions: {
                    url: AppModel.getters.getUrlAPI + '/api/staff/products/images',
                    thumbnailWidth: 150,
                    maxFilesize: 16,
                    acceptedFiles: "image/png,image/jpeg",
                    headers: {"Authorization": "Bearer " + User.getters.getAccessToken},
                    uploadMultiple: true,
                    parallelUploads: 6
                    // init: function () {
                    //     this.on("queuecomplete", function (file) {
                    //         location.reload()
                    //     })
                    // }
                }
            }
        }
    }
</script>
