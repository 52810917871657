<template>
    <v-card flat>

        <v-row no-gutters>

            <!-- BASE SETTINGS -->
            <v-col class="ma-2">
                <v-card>

                    <v-toolbar color="primary" dense dark>
                        <v-toolbar-title class="subtitle-1">DEFINIÇÕES DA CAMPANHA</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn tile small  v-if="!campaign.pristine" color="success" class="float-right" :disabled="saving" :loading="saving" @click="save">Guardar</v-btn>
                    </v-toolbar>

                    <v-row v-if="loading">
                        <v-col class="text-center">
                            <v-progress-circular
                                    class="ma-12"
                                    :size="100"
                                    :width="2"
                                    color="blue darken-4"
                                    indeterminate
                            ></v-progress-circular>
                        </v-col>
                    </v-row>

                    <v-row no-gutters v-if="!loading" style="min-height: 400px">

                        <!-- BASE SETTINGS -->
                        <v-col class="ma-2">

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        NOME
                                        <v-text-field v-model="campaign.title" outlined dense hide-details @keyup="validate"></v-text-field>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Tipo
                                        <div>
                                            <v-btn tile small  :color="campaign.type==='promotion' ? 'primary' : ''" @click="setType('promotion')">Promoção</v-btn>
                                            <v-btn tile small  :color="campaign.type==='pre-order' ? 'primary' : ''" @click="setType('pre-order')">Pré-encomenda</v-btn>
                                            <v-btn tile small  :color="campaign.type==='quantity' ? 'primary' : ''" @click="setType('quantity')">Quantidade</v-btn>
                                            <v-btn tile small  :color="campaign.type==='coupon' ? 'primary' : ''" @click="setType('coupon')">Cupão</v-btn>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="campaign.type!=='coupon'">
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Quem pode encomendar
                                        <div>
                                            <v-btn tile small  :color="campaign.b2b_visualization === 'partner' ? 'primary' : ''" @click="setVisualization('partner')">Comercial</v-btn>
                                            <v-btn tile small  :color="campaign.b2b_visualization === 'all' ? 'primary' : ''" @click="setVisualization('all')">Comercial + Cliente</v-btn>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="campaign.type!=='pre-order'">
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Múltiplas encomendas
                                        <div>
                                            <v-btn tile small  :color="campaign.multiple_orders ? 'success' : 'default'" @click="setMultipleOrders(true)">Sim</v-btn>
                                            <v-btn tile small  :color="campaign.multiple_orders ? 'default' : 'error'" @click="setMultipleOrders(false)">Não</v-btn>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="campaign.type==='pre-order'">
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Alterar datas das linhas
                                        <div>
                                            <v-btn tile small  :color="campaign.fixed_dates ? 'success' : ''" @click="setFixedDates(true)">Sim</v-btn>
                                            <v-btn tile small  :color="campaign.fixed_dates ? '' : 'error'" @click="setFixedDates(false)">Não</v-btn>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item v-if="campaign.type==='promotion'">
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Apenas artigos com stock
                                        <div>
                                            <v-btn tile small  :color="campaign.stock_only ? 'success' : ''" @click="setStockOnly(true)">Sim</v-btn>
                                            <v-btn tile small  :color="campaign.stock_only ? '' : 'error'" @click="setStockOnly(false)">Não</v-btn>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>

                        <v-col class="ma-2">

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Estado
                                        <div>
                                            <v-btn tile small  :color="campaign.status ? 'success' : ''" @click="setStatus(true)">Activa</v-btn>
                                            <v-btn tile small  :color="campaign.status ? '' : 'error'" @click="setStatus(false)">Inactiva</v-btn>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Ordem
                                        <v-text-field v-model="campaign.order" outlined dense hide-details @keyup="validate"></v-text-field>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Cor Principal
                                        <v-text-field v-model="campaign.color1" outlined dense hide-details @keyup="validate"></v-text-field>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Cor Secundária
                                        <v-text-field v-model="campaign.color2" outlined dense hide-details @keyup="validate"></v-text-field>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>

                        <!-- DATAS -->
                        <v-col class="ma-2" sm="auto">
                            <v-row no-gutters>
                                <v-col class="mx-2">
                                    <v-date-picker v-model="campaign.start_ts" :locale="locale" @change="validate"></v-date-picker>
                                </v-col>
                                <v-col>
                                    <v-date-picker v-model="campaign.end_ts" :locale="locale" @change="validate"></v-date-picker>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>

                    <v-row no-gutters v-if="!loading">

                        <!-- DESCRIPTIONS PT -->
                        <v-col class="ma-2">
                            <v-card>
                                <v-toolbar color="primary" dark dense>
                                    <v-toolbar-title class="subtitle-1">INFORMAÇÃO PT</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <flag iso="pt" v-bind:squared=false class="float-right"></flag>
                                </v-toolbar>
                                <v-card-text>

                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Nome PT</v-list-item-title>
                                            <v-text-field v-model="campaign.name_pt" outlined dense hide-details @keyup="validate"></v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Legenda PT</v-list-item-title>
                                            <v-text-field v-model="campaign.subtitle_pt" outlined dense hide-details @keyup="validate"></v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Descrição PT</v-list-item-title>
                                            <v-textarea v-model="campaign.description_pt" outlined dense hide-details @keyup="validate" auto-grow></v-textarea>
                                        </v-list-item-content>
                                    </v-list-item>

                                </v-card-text>
                            </v-card>
                        </v-col>

                        <!-- DESCRIPTIONS ES -->
                        <v-col class="ma-2">
                            <v-card>
                                <v-toolbar color="primary" dark dense>
                                    <v-toolbar-title class="subtitle-1">INFORMAÇÃO ES</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <flag iso="es" v-bind:squared=false class="float-right"></flag>
                                </v-toolbar>
                                <v-card-text>

                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Nome ES</v-list-item-title>
                                            <v-text-field v-model="campaign.name_es" outlined dense hide-details @keyup="validate"></v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Legenda ES</v-list-item-title>
                                            <v-text-field v-model="campaign.subtitle_es" outlined dense hide-details @keyup="validate"></v-text-field>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Descrição ES</v-list-item-title>
                                            <v-textarea v-model="campaign.description_es" outlined dense hide-details @keyup="validate" auto-grow></v-textarea>
                                        </v-list-item-content>
                                    </v-list-item>

                                </v-card-text>
                            </v-card>
                        </v-col>

                    </v-row>

                </v-card>
            </v-col>

        </v-row>

    </v-card>
</template>
<script>
    export default {
        props: {
            campaign: {}
        },
        data() {
            return {
                locale: '',
                saving: false,
                loading: true,
                campaignOriginal: {}
            }
        },
        mounted() {
            this.locale = (User.getters.getLanguage + '-' + User.getters.getLanguage).toLocaleLowerCase();
        },
        created() {
            this.getCampaign();
        },
        methods: {
            getCampaign() {
                let request = {url: api.b2bCampaigns() + '/' + this.$route.params.id};
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.campaignOriginal = responseData.campaign;
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao carregar a campanha!',
                                type: 'error'
                            })
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            setType(value) {
                this.campaign.type = value;
                this.validate();
            },
            setStatus(value) {
                this.campaign.status = value;
                this.validate();
            },
            setVisualization(value) {
                this.campaign.b2b_visualization = value;
                this.validate();
            },
            setMultipleOrders(value) {
                this.campaign.multiple_orders = value;
                this.validate();
            },
            setFixedDates(value) {
                this.campaign.fixed_dates = value;
                this.validate();
            },
            setStockOnly(value) {
                this.campaign.stock_only = value;
                this.validate();
            },
            validate() {
                this.campaign.pristine = true;
                if (this.campaign.title == '' || this.campaign.title != this.campaignOriginal.title) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.status != this.campaignOriginal.status) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.type == '' || this.campaign.type != this.campaignOriginal.type) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.b2b_visualization != this.campaignOriginal.b2b_visualization) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.multiple_orders != this.campaignOriginal.multiple_orders) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.fixed_dates != this.campaignOriginal.fixed_dates) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.order != this.campaignOriginal.order) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.color1 != this.campaignOriginal.color1) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.color2 != this.campaignOriginal.color2) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.stock_only != this.campaignOriginal.stock_only) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.start_ts != this.campaignOriginal.start_ts) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.end_ts != this.campaignOriginal.end_ts) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.name_pt != this.campaignOriginal.name_pt) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.name_es != this.campaignOriginal.name_es) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.subtitle_pt != this.campaignOriginal.subtitle_pt) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.subtitle_es != this.campaignOriginal.subtitle_es) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.description_pt != this.campaignOriginal.description_pt) {
                    this.campaign.pristine = false;
                }
                if (this.campaign.description_es != this.campaignOriginal.description_es) {
                    this.campaign.pristine = false;
                }
            },
            save() {
                this.saving = true;
                let request = {
                    url: api.b2bCampaignsSaveBasic(),
                    data: {
                        campaign: this.campaign
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.campaignOriginal = responseData.campaign;
                            this.campaign = Object.assign({}, responseData.campaign);
                            this.campaign.pristine = true;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Alterações guardadas com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao guardar as alterações!',
                                type: 'error'
                            })
                        }
                        this.saving = false;
                    })
                    .catch(() => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            }
        }
    }
</script>
