<style>
.setCursor input {
    cursor: pointer;
}

.brandRow {

    background-color: #e8e8e8;
}

.brandRow:nth-child(2n) {
    background-color: #f7f7f7;
}

table {
    overflow: hidden;
}

.bounce-enter-active {
    animation: bounce-in .8s;
}

.bounce-leave-active {
    animation: bounce-in .8s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.v-card .row .no-gutters:nth-child(2n) {
    background-color: #ffffff;
}

.v-card .row .no-gutters {
    background-color: #f5f5f5;
}

.levelRow:nth-child(2n) {
    background-color: #ffffff !important;
}

.levelRow {
    background-color: #f5f5f5 !important;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #1976d2 !important;
}

.v-card .row .no-gutters {
    min-height: 7vw;
}

.addSign:hover {
    border-color: #1976d296 !important;
    color: #1976d285 !important;
    cursor: pointer;
}

.addSign:active {
    font-size: 56px !important;
}

.addSignGift:active {
    font-size: 36px !important;
}

.inputGiftQuantity input {
    text-align: center
}

</style>
<template>
    <v-card flat>

        <v-row no-gutters>

            <!-- DETAILS -->
            <v-col class="ma-2">
                <v-card>

                    <v-toolbar color="primary" dense dark>
                        <v-toolbar-title class="subtitle-1">NIVEIS DA CAMPANHA</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn tile small v-if="saveEnabled" color="success" class="float-right" :disabled="saving"
                               :loading="saving" @click="save">Guardar
                        </v-btn>
                    </v-toolbar>

                    <v-row v-if="loading">
                        <v-col class="text-center">
                            <v-progress-circular
                                class="ma-12"
                                :size="100"
                                :width="2"
                                color="blue darken-4"
                                indeterminate
                            ></v-progress-circular>
                        </v-col>
                    </v-row>

                    <v-row class="levelRow" align="center" style="min-height: 0"
                           v-for="(campaignLevel, campaignLevelIndex) in campaignLevels"
                           :key="campaignLevelIndex" no-gutters v-if="!loading">

                        <v-col class="ma-4" style="flex-grow: 0;">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>

                                        <div>
                                            <v-btn tile small :color="'error'"
                                                   @click="removeLevel(campaignLevelIndex)">Remover
                                            </v-btn>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                        <v-col cols="2" class="ma-4" style="flex-grow: 0;">
                            <v-container>
                                <v-row class="pb-2">
                                    <v-btn tile small style="width: 100%;" :color="'primary'"
                                           @click="showConditions(campaignLevel.id, campaignLevelIndex)">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="1">
                                                    <i style="color:yellow;padding-right: 4px; font-size: 16px;"
                                                       class="fas fa-tasks"></i>
                                                </v-col>
                                                <v-col cols="2">
                                                    <span>Condições</span>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-btn>

                                </v-row>
<!--                                <v-row class="pb-2">-->
<!--                                    <v-btn tile small style="width: 100%;" :color="'primary'"-->
<!--                                           @click="showClimbers(campaignLevel.id, campaignLevelIndex)">-->
<!--                                        <v-container>-->
<!--                                            <v-row>-->
<!--                                                <v-col cols="1">-->
<!--                                                    <i style="color:yellow;padding-left: 4px; font-size: 16px; transform: scaleX(-1)"-->
<!--                                                       class="fas fa-sort-amount-up-alt"></i>-->
<!--                                                </v-col>-->
<!--                                                <v-col cols="2">-->
<!--                                                    <span>Climber</span>-->
<!--                                                </v-col>-->
<!--                                            </v-row>-->
<!--                                        </v-container>-->
<!--                                    </v-btn>-->
<!--                                </v-row>-->
                                <v-row>
                                    <v-btn style="width: 100%;" tile small :color="'primary'"
                                           @click="loadLevelGifts(campaignLevel.id)">
                                        <v-container>
                                            <v-row>
                                                <v-col cols="1">
                                                    <i style="color:#a0ed8d;padding-right: 4px; font-size: 16px;"
                                                       class="fas fa-gift"></i>
                                                </v-col>
                                                <v-col cols="2">
                                                    <span>Gift</span>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-btn>
                                </v-row>
                            </v-container>
                        </v-col>

                        <!--                        <v-col class="ma-4" style="flex-grow: 0;">
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <div>
                                                                    <v-btn tile small :color="'primary'"
                                                                           @click="showClimbers(campaignLevel.id, campaignLevelIndex)">
                                                                        <i style="color:yellow;padding-left: 4px; font-size: 16px; transform: scaleX(-1)"
                                                                           class="fas fa-sort-amount-up-alt"></i>
                                                                        Climber

                                                                    </v-btn>
                                                                </div>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-col>
                                                <v-col class="ma-4" style="flex-grow: 0;">
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <div>
                                                                    <v-btn tile small :color="'primary'"
                                                                           @click="loadLevelGifts(campaignLevel.id)">
                                                                        <i style="color:#a0ed8d;padding-right: 4px; font-size: 16px;"
                                                                           class="fas fa-gift"></i>
                                                                        Gift

                                                                    </v-btn>
                                                                </div>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-col>-->


                        <v-col class="ma-1">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        NOME
                                        <v-text-field v-model="campaignLevel.name" outlined dense hide-details
                                                      @keyup="validate(campaignLevelIndex)"></v-text-field>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>

                        <v-col class="ma-1">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Tipo
                                        <div>
                                            <v-btn tile small
                                                   :color="campaignLevel.discountType==='FINAL' ? 'primary' : ''"
                                                   @click="campaignLevel.discountType='FINAL';validate(campaignLevelIndex)">
                                                FINAL
                                            </v-btn>
                                            <v-btn tile small
                                                   :color="campaignLevel.discountType==='INCREMENTAL' ? 'primary' : ''"
                                                   @click="campaignLevel.discountType='INCREMENTAL';validate(campaignLevelIndex)">
                                                INCREMENTAL
                                            </v-btn>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                        <v-col class="ma-1">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Marca
                                        <div>
                                            <v-btn tile small :color="'primary'"
                                                   @click="showBrands(campaignLevel.id, campaignLevelIndex)">
                                                MARCAS
                                            </v-btn>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                        <v-col cols="1" class="ma-1">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Nivel
                                        <div>
                                            <v-select
                                                :items="campaignAssignedLevels"
                                                hide-details
                                                outlined
                                                dense
                                                v-model="campaignLevel.discountLevel"
                                                @input="validate(campaignLevelIndex)"
                                            ></v-select>
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                        <v-col class="ma-1">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                        Desconto
                                        <div>
                                            <v-text-field
                                                v-model.number="campaignLevel.discountValue"
                                                hide-details
                                                outlined
                                                dense
                                                single-line
                                                type="number"
                                                min="1"
                                                max="100"
                                                @keyup="validate(campaignLevelIndex)"
                                                @click="validate(campaignLevelIndex)"
                                            />
                                        </div>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>

                    </v-row>


                </v-card>
            </v-col>

        </v-row>
        <v-row>
            <v-col class="d-flex justify-center">
                <i @click="newLevel()" class="fas fa-plus addSign"
                   style="    color: rgb(218, 218, 218);background-color: whitesmoke;font-size: 60px;border-radius: 30px;border-color: rgb(218, 218, 218);border-style: dashed;"></i>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogClimber" max-width="500">
            <v-card>
                <v-card-title style="margin-bottom: 2vh;">Climbers do nível</v-card-title>
                <v-card-text>
                    <div>
                        <div>
                            <v-row>
                                <v-col>

                                    <h4>Secção </h4>
                                    <br>
                                    <v-text-field
                                        label="Secção"
                                        style="width:100px"
                                        v-model="climberSection" outlined dense hide-details></v-text-field>
                                </v-col>

                                <v-col>
                                    <h3 style="display:inline; margin-right:2vw">Condições </h3>
                                    <br>
                                    <br>
                                    <v-btn tile small :color="climberUseConditions==true ? 'primary' : ''"
                                           @click="climberUseConditions=true">Sim
                                    </v-btn>
                                    <v-btn tile small :color="climberUseConditions==false ? 'error' : ''"
                                           @click="climberUseConditions=false">Não
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <h3 style="display:inline; margin-right:2vw">Ativo</h3>
                                    <br>
                                    <br>
                                    <v-btn tile small :color="climberActive==true ? 'primary' : ''"
                                           @click="climberActive=true">Sim
                                    </v-btn>
                                    <v-btn tile small :color="climberActive==false ? 'error' : ''"
                                           @click="climberActive=false">Não
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <br><br>
                            <v-row>
                                <v-col>
                                    <h3>Valor ativação</h3>
                                    <br>
                                    <v-text-field
                                        label="Valor ativação" suffix="€"
                                        type="number" v-model.number="climberValue" outlined dense
                                        hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <br><br>
                            <v-row>

                            </v-row>


                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile small @click="dialogClimber=false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="error" :disabled="!hasClimber" @click="dialogConfirmClimber=true"
                           :loading="saving">Remover
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="updateClimber()" :loading="saving">Confirmar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConditions" max-width="500">
            <v-card>
                <v-card-title style="margin-bottom: 2vh;">Condições do nível</v-card-title>
                <v-card-text>
                    <div>
                        <div>
                            <v-btn tile small :color="parseInt(levelConditions.conditionTypeId)===1 ? 'primary' : ''"
                                   @click="levelConditions.conditionTypeId=1;checkConditionValue();">Valor
                            </v-btn>
                            <v-btn tile small :color="parseInt(levelConditions.conditionTypeId)===2 ? 'primary' : ''"
                                   @click="levelConditions.conditionTypeId=2;checkConditionValue();">Quantidade
                            </v-btn>
                        </div>
                        <br>
                        <div>
                            <v-text-field
                                :prefix="levelConditions.mode === '=' ? 'Igual a: ' : levelConditions.mode === '>' ? 'Acima de: ' : levelConditions.mode === '<' ? 'Abaixo de: ' : levelConditions.mode === 'multi' ? 'Múltiplos de:' : 'Entre:'"
                                :suffix="parseInt(levelConditions.conditionTypeId)===1 ? '€' : 'Unidades'"
                                type="number" v-model.number="conditionValue" outlined dense hide-details
                                @change="verifyAmount()"></v-text-field>
                            <transition name="bounce">
                                <span style="margin-top: 1vw; color:red;" v-show="levelConflict">Conflito entre níveis! (O valor foi corrigido automaticamente)</span>
                            </transition>
                            <transition name="bounce">
                                <v-text-field style="margin-top: 1vw;" v-show="levelConditions.mode === 'BETWEEN'"
                                              prefix="E: "
                                              :suffix="levelConditions.conditionTypeId===1 ? '€' : 'Unidades'"
                                              v-model="levelConditions.betweenValue" outlined dense
                                              hide-details></v-text-field>
                            </transition>
                        </div>
                        <div>
                            <input type="radio" value=">" v-model="levelConditions.mode" @change="verifyAmount()"> Acima
                            <input type="radio" value="<" v-model="levelConditions.mode" @change="verifyAmount()">
                            Abaixo
<!--                            <input type="radio" value="BETWEEN" v-model="levelConditions.mode" @change="verifyAmount()"-->
<!--                                   disabled>-->
<!--                            Entre-->
<!--                            <template v-if="levelConditions.conditionTypeId==2">-->
<!--                                <input type="radio" value="multi" v-model="levelConditions.mode"-->
<!--                                       @change="verifyAmount()">-->
<!--                                Múltiplos-->
<!--                            </template>-->
                            <input type="radio" value="=" v-model="levelConditions.mode" @change="verifyAmount()"> Igual
                        </div>
                        <br>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile small @click="closeDialogConfirm()">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="error" :disabled="!hasCondition"
                           @click="deleteCondition(levelConditions.id)" :loading="saving">Remover
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="conditionValue == 0 || conditionValue == '' || conditionValue == null" tile small
                           color="success" @click="updateConditions(levelConditions.id)" :loading="saving">
                        Confirmar
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogConfirm" max-width="500">
            <v-card>
                <v-card-title>{{ $t('confirmChange') }}</v-card-title>
                <v-card-text>
                    Tem a certeza que deseja eliminar o nivel
                    <br>
                    <strong>{{ deletingLevel.name }}?</strong>
                </v-card-text>
                <v-card-actions>
                    <v-btn tile small @click="closeDialogConfirm()">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="confirmDelete()">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogConfirmClimber" max-width="500">
            <v-card>
                <v-card-title>{{ $t('confirmChange') }}</v-card-title>
                <v-card-text>
                    Tem a certeza que deseja remover o climber?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile small @click="dialogConfirmClimber=false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="confirmDeleteClimber()">Confirmar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialogConfirmCondition" max-width="500">
            <v-card>
                <v-card-title>{{ $t('confirmChange') }}</v-card-title>
                <v-card-text>
                    Tem a certeza que deseja remover a condição?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile small @click="dialogConfirmCondition=false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="confirmDeleteCondition()">Confirmar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogBrands" scrollable max-width="600">
            <v-card>
                <v-card-title class="headline darken-1">
                    <v-row no-gutters>
                        <v-col class="text-uppercase">
                            <v-icon dark style="float: right;" @click="dialogBrands = false">close</v-icon>
                            <span style="color:white">Atribuir Marca</span>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-simple-table dense>
                        <thead>
                        <tr>
                            <th class="text-left" style="max-width: 300px;    width: 13vw;">Marca</th>
                            <th class="text-center">Nivel</th>
                            <th></th>
                            <th>
                                <v-btn tile small color="primary" class="float-right"
                                       @click="showAddLevel=true">Adicionar
                                </v-btn>
                            </th>
                        </tr>
                        <tr>
                            <th class="pa-1" style="max-width:2vw">
                                <!--                NOT WORKING, don't bother until requested-->


                                <!--                <v-text-field v-model="filterBrandName" label="Filtrar marca" outlined hide-details dense
                                                              @keyup="filterBrands"></v-text-field>-->
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <transition name="bounce">
                            <tr v-if="showAddLevel">
                                <td class="pa-4">
                                    <v-combobox class="setCursor"
                                                hide-details="auto"
                                                dense
                                                outlined
                                                :items="brandNames"
                                                label="Marca"
                                                v-model="selectedBrandAdd"
                                                @change="loadLevels()"
                                    ></v-combobox>
                                </td>
                                <td>
                                    <v-combobox class="setCursor"
                                                hide-details="auto"
                                                dense
                                                outlined
                                                label="Nivel"
                                                :disabled="blockPickLevel"
                                                :items="brandLevels"
                                                v-model="selectedLevel"
                                                @change="blockSaveLevel = false"
                                    ></v-combobox>
                                </td>
                                <td>
                                    <v-btn :disabled="blockSaveLevel" tile x-small color="success" class="float-right"
                                           @click="saveBrand()">Guardar
                                    </v-btn>
                                </td>
                                <td>
                                    <v-btn tile x-small color="error" class="float-right"
                                           @click="cancelAddLevel()">Cancelar
                                    </v-btn>
                                </td>
                            </tr>
                        </transition>
                        <tr class="brandRow" v-for="(item,index) in levelBrands" :key="index">

                            <td>
                                <span>{{ item.brand }}</span>
                                <!--                <span v-if="item.selected==true">(<u><small style="color:green">Associado</small>)</u></span>-->
                            </td>
                            <td class="text-center"><b><u>{{ item.brandLevel }}</u></b></td>
                            <td></td>
                            <td class="pa-3 text-right">
                                <v-btn
                                    x-small
                                    fab
                                    :color="item.selected == false ? 'success' : 'error'"
                                    class="ma-1"
                                    :disabled="saving && selectedBrand===index"
                                    :loading="saving && selectedBrand===index"
                                    @click="removeBrand(item.id,index)">
                                    <v-icon v-if="item.selected==false">add</v-icon>
                                    <v-icon v-else>remove</v-icon>
                                </v-btn>
                            </td>

                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="pa-3">
                    <!--          <p class="ma-0">A mostrar 1-{{ items.length }} de {{ itemsFiltered.length }} clientes. (Total {{ clients.length }})</p>-->
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="giftForm" max-width="500" @click:outside="this.selectedLevel=null">
            <v-card>
                <v-card-title>Configurar oferta</v-card-title>
                <v-card-text>
                    <v-container>
                        <template v-if="levelGifts.length > 0">
                            <v-row>
                                <v-col>
                                    <strong>
                                        Artigo
                                    </strong>
                                </v-col>
                                <v-col class="text-center">
                                    <strong>
                                        Quantidade
                                    </strong>
                                </v-col>
                            </v-row>
                            <hr style="margin-bottom:10px;">
                            <v-row v-for="(product, productIndex) in levelGifts" :key="product.id">
                                <v-container>
                                    <v-row>
                                        <v-col>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="1">
                                                        <i class="fas fa-minus-circle"
                                                           style="color: red; cursor:pointer"
                                                           @click="removeGift(product, productIndex)"></i>
                                                    </v-col>
                                                    <v-col>
                                                        <strong>
                                                            {{ product.productId }}
                                                        </strong>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                        <v-col align="center" justify="center">
                                            <v-container>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field class="inputGiftQuantity" style="width:50px;"
                                                                      type="number" min="1"
                                                                      v-model.number="product.quantity" dense
                                                                      hide-details
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="1">
                                                        <v-progress-circular indeterminate
                                                                             v-if="product.updating == true" :size="16"
                                                                             :width="2"
                                                                             color="primary"></v-progress-circular>
                                                        <template v-else>
                                                            <template
                                                                v-if="(product.quantity != product.originalQuantity) && product.error == false">
                                                                <v-tooltip right>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                      <span v-bind="attrs" v-on="on">
                                                                        <v-icon color="warning" size="16px">warning</v-icon>
                                                                      </span>
                                                                    </template>
                                                                    <span style="color:#fb8c00">Não guardado!</span>
                                                                </v-tooltip>
                                                            </template>
                                                            <v-icon v-else-if="product.updated==true" size="16px"
                                                                    color="success">mdi-checkbox-marked-circle
                                                            </v-icon>
                                                            <v-icon v-else-if="product.error==true" size="16px"
                                                                    color="error">fa-times
                                                            </v-icon>
                                                        </template>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                    </v-row>
                                    <hr v-if="productIndex+1 != levelGifts.length" style="opacity:0.175">
                                </v-container>
                            </v-row>

                        </template>
                        <template v-else>
                            <span>Não existem produtos de oferta no nivel atual</span>
                        </template>
                        <v-row class="d-flex justify-center">
                            <i @click="giftSelection=true" class="fas fa-plus addSign addSignGift"
                               style="margin-top:20px;color: rgb(218, 218, 218);background-color: whitesmoke;font-size: 40px;border-radius: 30px;border-color: rgb(218, 218, 218);border-style: dashed;"></i>
                        </v-row>
                    </v-container>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile small @click="giftForm=false; this.selectedLevel = null">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small :disabled="disableSaveGifts" color="success" @click="saveGifts()">Guardar
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogConfirmGift" max-width="500">
            <v-card>
                <v-card-title>{{ $t('confirmChange') }}</v-card-title>
                <v-card-text>
                    Tem a certeza que deseja remover o produto {{ giftDelete.productId }} da lista de ofertas?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile small @click="dialogConfirmGift=false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="confirmDeleteGift()">Confirmar</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ProductListSelection v-if="giftSelection" v-on:close-list="giftSelection=false"
                              v-on:confirm-selection="confirmSelection"/>

        <!--ADD BRANDS/ LIST TO ADD-->
        <!--    <v-dialog v-model="dialogAddBrands" scrollable max-width="600">
              <v-card>
                <v-card-title class="headline darken-1" >
                  <v-row no-gutters>
                    <v-col class="text-uppercase">
                      <v-icon dark style="float: right;" @click="dialogBrands = false">close</v-icon>
                      <span style="color:white" >Atribuir Marca</span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-card-text>
                  <v-simple-table dense>
                    <thead>
                    <tr>
                      <th class="text-left" style="max-width: 300px;    width: 13vw;">Marca</th>
                      <th class="text-center">Nivel</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th class="pa-1" style="max-width:2vw">
                        <v-text-field v-model="filterBrandName" outlined hide-details dense @keyup="filterBrands"></v-text-field>
                      </th>
                      <th></th>
                      <th class="pa-3 text-right">
                        <v-btn tile small color="primary" class="float-right"
                               @click="dialogAddBrands=false;">Cancelar</v-btn>
                      </th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr v-for="(item,index) in allBrands" :key="index">

                      <td>
                        <span>{{ item.text }}</span>
                        &lt;!&ndash;                <span v-if="item.selected==true">(<u><small style="color:green">Associado</small>)</u></span>&ndash;&gt;
                      </td>
                      <td class="text-center"><b><u>{{ item.brandLevel }}</u></b></td>
                      <td class="pa-3 text-right">
                        <v-btn
                            x-small
                            fab
                            color="success"
                            class="ma-1"
                            :disabled="saving && selectedBrand===index"
                            :loading="saving && selectedBrand===index"
                            @click="saveBrand(index, cardTitleClass==='green')">
                          <v-icon v-if="item.selected==false">add</v-icon>
                          <v-icon v-else>remove</v-icon>
                        </v-btn>
                      </td>

                    </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <v-card-actions class="pa-3">
                  &lt;!&ndash;          <p class="ma-0">A mostrar 1-{{ items.length }} de {{ itemsFiltered.length }} clientes. (Total {{ clients.length }})</p>&ndash;&gt;
                </v-card-actions>
              </v-card>
            </v-dialog>-->

    </v-card>
</template>


<script>
import ProductListSelection from '@/components/Products/ProductListSelection.vue';
import _ from "lodash";

export default {
    components: {
        ProductListSelection
    },
    //props: {
    //  campaign: {}
    //},
    data() {
        return {
            giftSelection: false,
            manualBlockGiftSave: false,
            giftForm: false,
            levelGifts: [],
            originalLevelGifts: [],
            giftDelete: {},
            dialogConfirmGift: false,
            locale: '',
            saving: false,
            dialogConfirm: false,
            dialogConfirmCondition: false,
            dialogConditions: false,
            conditionDelete: null,
            hasCondition: false,
            dialogClimber: false,
            dialogConfirmClimber: false,
            hasClimber: false,
            dialogBrands: false,
            // dialogAddBrands: false,
            loading: true,
            campaignLevels: [],
            campaignLevelsOriginal: [],
            campaignAssignedLevels: [],
            updateLevels: {},
            conditionValue: 0,
            saveEnabled: false,
            deletingLevel: {},
            levelConditions: {},
            levelConflict: false,
            updatingLevelIndex: null,
            levelBrands: [],
            allBrands: [],
            brandNames: [],
            selectedBrandAdd: null,
            selectedLevel: null,
            brandLevels: [],
            filterBrandName: '',
            selectedBrand: null,
            blockPickLevel: true,
            blockSaveLevel: true,
            showAddLevel: false,
            campaignLevel: null,
            climberValue: 0,
            climberSection: "",
            climberUseConditions: false,
            climberActive: false,
            climberId: null
        }
    },
    watch: {
        dialogBrands(val) {
            if (val == false)
                this.cancelAddLevel();
        }
    },
    mounted() {
        this.locale = (User.getters.getLanguage + '-' + User.getters.getLanguage).toLocaleLowerCase();
    },
    created() {
        this.campaignId = this.$route.params.id;


        if (this.campaignId > 0) {
            this.getCampaignLevels();
        } else {
            this.loading = false;
        }
    },
    computed: {
        disableSaveGifts() {
            return JSON.stringify(this.levelGifts) === JSON.stringify(this.originalLevelGifts) || this.manualBlockGiftSave === true;
        }
    },
    methods: {
        cancelAddLevel() {
            this.selectedBrandAdd = null;
            this.blockPickLevel = true;
            this.blockSaveLevel = true;
            this.selectedLevel = null;
            this.showAddLevel = false;
        },
        loadLevels() {
            let arrKeys = Object.keys(this.allBrands);

            let brandsAndLevels = arrKeys.filter((e) =>
                e.substring(0, e.lastIndexOf("_")) == this.selectedBrandAdd
            );
            this.brandLevels = [];
            for (let i = 0; i < brandsAndLevels.length; i++) {
                this.brandLevels[i] = brandsAndLevels[i].substring(brandsAndLevels[i].lastIndexOf("_") + 1, brandsAndLevels[i].length);
            }

            this.brandLevels.sort((a, b) => a - b);
            this.blockPickLevel = false;
        },
        removeBrand(brandLevelId, index) {
            let request = {
                url: api.b2bCampaignsUpdateConditions() + this.campaignId + '/levels/' + this.campaignLevel + '/brands/' + brandLevelId,
            };
            ApiWS.dispatch('makeDelete', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        //this.getBrands(this.campaignLevel);
                        this.allBrands[this.levelBrands[index].brand + "_" + this.levelBrands[index].brandLevel] = {
                            'text': this.levelBrands[index].brand,
                            'brandLevel': this.levelBrands[index].brandLevel,
                            'selected': false
                        };

                        if (!this.brandNames.includes(this.levelBrands[index].brand)) {
                            this.brandNames.push(this.levelBrands[index].brand);
                            this.brandNames.sort();
                        }
                        this.levelBrands.splice(index, 1);
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Nivel de marca removido com sucesso!',
                            type: 'success'
                        });
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao remover o nivel de marca!',
                            type: 'error'
                        })
                    }
                })
                .catch(() => {
                    this.saving = false;
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        saveBrand() {

            let request = {
                url: api.b2bCampaignsUpdateConditions() + this.campaignId + '/levels/' + this.campaignLevel + '/brands',
                data: {
                    'brand': this.selectedBrandAdd,
                    'brandLevel': this.selectedLevel
                }
            };
            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data.data;
                    if (responseData.status === 'OK') {
                        this.cancelAddLevel();
                        this.getBrands(this.campaignLevel);
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Nivel de marca adicionado com sucesso!',
                            type: 'success'
                        });
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao adicionar o nivel de marca!',
                            type: 'error'
                        })
                    }
                })
                .catch(() => {
                    this.saving = false;
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        getBrands(levelId) {
            let request = {
                url: api.b2bCampaignsUpdateConditions() + this.campaignId + '/levels/' + levelId + '/brands'
            };

            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;

                    if (responseData.status === 'OK') {
                        this.levelBrands = responseData.data.levelBrands
                        this.allBrands = responseData.data.allBrands;
                        this.brandNames = responseData.data.brandNames;
                        /*Alert.dispatch('show', {
                          snackbar: true,
                          text: 'Alterações guardadas com sucesso!',
                          type: 'success'
                        })*/

                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao guardar as alterações!',
                            type: 'error'
                        })
                    }
                    this.saving = false;
                })
                .catch(() => {
                    this.saving = false;
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })

        },
        filterBrands() {
            this.itemsFiltered = this.brandNames;
            if (this.filterBrandName !== '') {
                this.itemsFiltered = this.itemsFiltered.filter(item => item.toUpperCase().includes(this.filterBrandName.toUpperCase()))
            }
            this.items = this.itemsFiltered.slice(0, 100);
        },
        updateClimber() {
            let levelId = this.updatingLevelIndex;

            let request = {
                url: api.b2bCampaignsUpdateConditions() + this.campaignId + '/levels/' + levelId + '/climber/' + this.climberId,
                data: {
                    climberValue: this.climberValue,
                    climberSection: this.climberSection,
                    climberUseConditions: this.climberUseConditions,
                    climberActive: this.climberActive
                }
            };

            ApiWS.dispatch('makePut', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.saveEnabled = false;

                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Alterações guardadas com sucesso!',
                            type: 'success'
                        })
                        this.dialogClimber = false;
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao guardar as alterações!',
                            type: 'error'
                        })
                    }
                    this.saving = false;
                })
                .catch(() => {
                    this.saving = false;
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        deleteCondition(conditionId) {
            this.dialogConfirmCondition = true;
            this.conditionDelete = conditionId;
        },
        updateConditions(levelId) {
            levelId = this.updatingLevelIndex;
            if (this.levelConditions.conditionTypeId == 1) {
                this.levelConditions.amount = this.conditionValue;
                this.levelConditions.quantity = null;
            } else if (this.levelConditions.conditionTypeId == 2) {
                this.levelConditions.quantity = this.conditionValue;
                this.levelConditions.amount = null;
            }

            let request = {
                url: api.b2bCampaignsUpdateConditions() + this.campaignId + '/levels/' + levelId + '/conditions',
                data: {
                    levelConditions: this.levelConditions
                }
            };

            ApiWS.dispatch('makePut', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.resetOriginal();
                        this.updateLevels = {};
                        this.saveEnabled = false;

                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Alterações guardadas com sucesso!',
                            type: 'success'
                        })
                        this.dialogConditions = false;

                        this.updatingLevelIndex = null;
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao guardar as alterações!',
                            type: 'error'
                        })
                    }
                    this.saving = false;
                })
                .catch(() => {
                    this.saving = false;
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })

        },
        verifyAmount() {

            if (this.levelConditions.nextLevel == null || Object.keys(this.levelConditions.nextLevel).length === 0)
                return;

            let maxAmount = this.levelConditions.conditionTypeId === 1 ? this.levelConditions.nextLevel.amount : this.levelConditions.nextLevel.quantity;
            let minAmount = this.levelConditions.conditionTypeId === 1 ? this.levelConditions.previousLevel.amount : this.levelConditions.previousLevel.quantity;

            let originalConditionValue = this.conditionValue;
            this.conditionValue = this.conditionValue < minAmount ? minAmount + 1 : this.conditionValue > maxAmount ? maxAmount - 1 : this.conditionValue;


            if (originalConditionValue !== this.conditionValue) {
                this.levelConflict = true;
                setTimeout(() => {
                    this.levelConflict = false;
                }, 2000);
            }

        },
        checkConditionValue() {
            this.conditionValue = parseInt(this.levelConditions.conditionTypeId) === 1 ? (this.levelConditions.amount == null ? null : this.levelConditions.amount) : (this.levelConditions.quantity == null ? null : this.levelConditions.quantity);
        },
        showBrands(levelId, levelIndex) {
            if (levelId == 0) {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Guarde o nivel primeiro!',
                    type: 'warning'
                })
                return;
            }
            this.cancelAddLevel();
            this.campaignLevel = levelId;
            this.getBrands(levelId);
            this.dialogBrands = true;
        },
        showClimbers(levelId, levelIndex) {
            if (levelId == 0) {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Guarde o nivel primeiro!',
                    type: 'warning'
                })
                return;
            }
            this.updatingLevelIndex = this.campaignLevels[levelIndex].id;
            let request = {
                url: api.b2bCampaignsUpdateConditions() + this.campaignId + '/levels/' + levelId + '/climber'
            };
            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        if (responseData.data != null) {
                            this.climberValue = responseData.data.amount;
                            this.climberSection = responseData.data.section;
                            this.climberUseConditions = responseData.data.useCondition;
                            this.climberActive = responseData.data.active;
                            this.climberId = responseData.data.id;
                            this.hasClimber = true;
                        } else {
                            this.climberValue = 0;
                            this.climberSection = "";
                            this.climberUseConditions = false;
                            this.climberActive = false;
                            this.climberId = null;
                            this.hasClimber = false;
                        }
                    }
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })

            this.dialogClimber = true;
        },
        showConditions(levelId, levelIndex) {
            if (levelId == 0) {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Guarde o nivel primeiro!',
                    type: 'warning'
                })
                return;
            }
            let selectedLevel = this.campaignLevels[levelIndex].discountLevel;
            this.updatingLevelIndex = this.campaignLevels[levelIndex].id;
            let nextLevel = this.campaignLevels.filter(campaignLevel => campaignLevel.discountLevel - 1 === selectedLevel);

            if (nextLevel.length === 1) {
                nextLevel = nextLevel[0].id;
            } else {
                nextLevel = null;
            }

            let previousLevel = this.campaignLevels.filter(campaignLevel => campaignLevel.discountLevel + 1 === selectedLevel);
            if (previousLevel.length === 1) {
                previousLevel = previousLevel[0].id;
            } else {
                previousLevel = null;
            }


            let request = {
                url: api.b2bCampaignsUpdateConditions() + this.campaignId + '/levels/' + levelId + '/conditions?nextLevel=' + nextLevel + '&previousLevel=' + previousLevel
            };
            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;

                    if (responseData.status === 'OK') {
                        this.levelConditions = Object.assign({}, responseData.data);
                        this.hasCondition = true;
                        if (responseData.data.length == 0) {
                            this.levelConditions = {
                                conditionTypeId: 1,
                                mode: '>',
                            }
                            this.conditionValue = 0;
                            this.hasCondition = false;
                        } else
                            this.checkConditionValue();
                    }
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })

            this.dialogConditions = true;
        },
        removeLevel(levelIndex) {
            this.deletingLevel = this.campaignLevels[levelIndex];
            this.deletingLevel.levelIndex = levelIndex;
            if (this.campaignLevels[levelIndex].id == 0)
                this.confirmDelete();
            else
                this.dialogConfirm = true;

        },
        confirmDeleteClimber() {
            let request = {
                url: api.b2bCampaignsUpdateConditions() + this.campaignId + '/levels/' + this.campaignLevel + '/climber/' + this.climberId
            };
            ApiWS.dispatch('makeDelete', request)
                .then(response => {
                    let responseData = response.data;

                    if (responseData.status === 'OK') {
                        //this.getBrands(this.campaignLevel);

                        this.dialogClimber = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Climber removido com sucesso!',
                            type: 'success'
                        });
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao remover o climber!',
                            type: 'error'
                        })
                    }
                    this.dialogConfirmClimber = false;
                })
                .catch(() => {
                    this.saving = false;
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })

        },
        confirmDeleteCondition() {
            let request = {
                url: api.b2bCampaignsUpdateConditions() + this.campaignId + '/levels/' + this.campaignLevel + '/conditions/' + this.conditionDelete
            };
            ApiWS.dispatch('makeDelete', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        //this.getBrands(this.campaignLevel);
                        this.conditionDelete = null;
                        this.dialogConditions = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Condição removida com sucesso!',
                            type: 'success'
                        });
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao remover a condição!',
                            type: 'error'
                        })
                    }
                    this.dialogConfirmCondition = false;
                })
                .catch(() => {
                    this.saving = false;
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })

        },
        confirmDelete() {
            let loader = this.$loading.show();
            if (this.deletingLevel.id === 0) {
                this.campaignLevels.splice(this.deletingLevel.levelIndex, 1);
                this.resetOriginal();
                loader.hide();
                this.dialogConfirm = false;
                return;
            }

            let request = {
                url: api.b2bCampaignsUpdateLevels() + this.campaignId + '/levels/' + this.deletingLevel.id,
            };
            ApiWS.dispatch('makeDelete', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.campaignLevels.splice(this.deletingLevel.levelIndex, 1);
                        this.resetOriginal();
                    }
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                })

            this.dialogConfirm = false;

        },
        closeDialogConfirm() {
            this.deletingLevel = [];
            this.dialogConfirm = false;
            this.levelConditions = {};
            this.dialogConditions = false;

        },
        resetOriginal() {
            for (let i = 0; i < this.campaignLevels.length; i++) {
                this.campaignLevelsOriginal[i] = Object.assign({}, this.campaignLevels[i]);
            }
        },
        getCampaignLevels() {
            let request = {
                url: '/api/staff/campaigns/' + this.campaignId + '/levels'
            };
            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.campaignLevels = responseData.levels;
                        this.resetOriginal();

                        this.campaignAssignedLevels = responseData.currentLevels;
                        this.loading = false;
                    }
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        newLevel() {

            let nextDiscountLevel = this.campaignAssignedLevels.length + 1;
            let newLevel = {
                'id': 0,
                'name': 'Novo nivel',
                'discountType': 'FINAL',
                'discountValue': '1',
                'discountLevel': nextDiscountLevel
            };
            this.campaignAssignedLevels.push(nextDiscountLevel);
            this.campaignLevels.push(newLevel);
            this.campaignLevelsOriginal[nextDiscountLevel - 1] = Object.assign({}, this.campaignLevels[nextDiscountLevel - 1]);
            this.updateLevels[nextDiscountLevel] = newLevel;
            this.saveEnabled = true;
        },
        validate(levelIndex) {

            let levelsPristine = true;

            if (this.campaignLevels[levelIndex].name !== this.campaignLevelsOriginal[levelIndex].name) {
                levelsPristine = false;
            }
            if (this.campaignLevels[levelIndex].discountType !== this.campaignLevelsOriginal[levelIndex].discountType) {
                levelsPristine = false;
            }
            if (this.campaignLevels[levelIndex].discountLevel !== this.campaignLevelsOriginal[levelIndex].discountLevel) {
                levelsPristine = false;
            }
            if (this.campaignLevels[levelIndex].discountValue !== this.campaignLevelsOriginal[levelIndex].discountValue) {
                levelsPristine = false;
            }

            if (!levelsPristine && !this.updateLevels.hasOwnProperty(levelIndex) && this.campaignLevels[levelIndex].id != 0)
                this.updateLevels[levelIndex] = this.campaignLevels[levelIndex];

            else if (levelsPristine && this.updateLevels.hasOwnProperty(levelIndex))
                delete this.updateLevels[levelIndex];


            this.saveEnabled = Object.keys(this.updateLevels).length > 0;


        },
        save() {
            this.saving = true;
            let request = {
                url: api.b2bCampaignsUpdateLevels() + this.campaignId + '/levels',
                data: {
                    campaignLevels: this.updateLevels
                }
            };
            ApiWS.dispatch('makePut', request)
                .then(response => {
                    let responseData = response.data;

                    if (responseData.status === 'OK') {
                        this.resetOriginal();
                        this.updateLevels = {};
                        this.saveEnabled = false;
                        this.getCampaignLevels();

                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Alterações guardadas com sucesso!',
                            type: 'success'
                        })
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao guardar as alterações!',
                            type: 'error'
                        })
                    }
                    this.saving = false;
                })
                .catch(() => {
                    this.saving = false;
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        confirmSelection: function (selectedProducts) {
            this.giftSelection = false;

            let selectedGifts = selectedProducts.map((product) => {
                let giftExists = this.levelGifts.find((gift) => {
                    return gift.productId == product.Artigo;
                });
                if (typeof giftExists === 'undefined') {
                    return {
                        productId: product.Artigo,
                        quantity: 1,
                        updated: true,
                        updating: false,
                        error: false,
                        new: true,
                        originalQuantity: 0
                    }
                }
            }).filter(product => typeof product !== 'undefined');

            this.levelGifts = this.levelGifts.concat(selectedGifts);
            this.levelGifts = _.orderBy(this.levelGifts, 'productId');
        },

        loadLevelGifts(levelId) {
            if (levelId == 0) {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Guarde o nivel primeiro!',
                    type: 'warning'
                })
                return;
            }

            this.selectedLevel = levelId;

            let loader = this.$loading.show();
            let request = {
                url: api.b2bCampaignsUpdateLevels() + this.campaignId + '/levels/' + levelId + '/gifts'
            };
            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        if (responseData.data.length > 0) {
                            this.levelGifts = responseData.data;
                            this.originalLevelGifts = JSON.parse(JSON.stringify(responseData.data));
                        }
                    }
                    loader.hide();
                    this.giftForm = true;
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        removeGift(product, productIndex) {
            if (typeof product.new == false)
                this.levelGifts.splice(productIndex, 1);
            else {
                this.giftDelete = product;
                this.giftDelete.index = productIndex;

                this.dialogConfirmGift = true;
            }
        },
        confirmDeleteGift() {
            let loader = this.$loading.show();
            let request = {
                url: api.b2bCampaignsUpdateLevels() + this.campaignId + '/levels/' + this.giftDelete.campaignLevelId + '/gifts/' + this.giftDelete.id
            };
            ApiWS.dispatch('makeDelete', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.levelGifts.splice(this.giftDelete.index, 1);
                        this.giftDelete = {};

                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Oferta removida com sucesso!',
                            type: 'success'
                        });
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao remover oferta!',
                            type: 'error'
                        })
                    }
                    loader.hide();
                    this.dialogConfirmGift = false;
                })
                .catch(() => {
                    this.saving = false;
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                    loader.hide();
                })

        },
        saveGifts() {

            if (this.disableSaveGifts == true || this.levelGifts.length == 0)
                return;

            this.manualBlockGiftSave = true;

            this.levelGifts.forEach((product) => {
                if (product.originalQuantity == product.quantity)
                    return;

                product.updating = true;
            });

            let request = {
                url: api.b2bCampaignsUpdateLevels() + this.campaignId + '/levels/' + this.selectedLevel + '/gifts/',
                data: {
                    products: this.levelGifts
                }
            };

            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.levelGifts = responseData.data;
                        //this.originalLevelGifts = JSON.parse(JSON.stringify(responseData.data));
                        let allValid = true;
                        this.levelGifts.forEach((product) => {
                            //product.updating = false;
                            if (product.updated == true) {
                                setTimeout(() => {
                                    product.updated = false;
                                }, 3000);
                            } else if (product.error == true) {
                                allValid = false;
                                setTimeout(() => {
                                    product.new = false;
                                    product.updated = false;
                                    product.error = false;
                                },3000);
                            }
                        });

                        if (allValid == true) {
                            setTimeout(() => {
                                this.originalLevelGifts = JSON.parse(JSON.stringify(this.levelGifts));
                            }, 3000);
                        }
                        this.manualBlockGiftSave = false;

                    }
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                });
        }
    }
}
</script>
