import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const Product = new Vuex.Store({
    actions: {
        getProducts(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    pagination: {
                        page: filters.pagination.page,
                        per_page: filters.pagination.per_page
                    },
                    filters: filters.filters
                }
                axios.post(api.products(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getFamilies(context, brand) {
            return new Promise((resolve, reject) => {
                let data = {
                    brand: brand
                }

                axios.post(api.productsFamilies(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSubFamilies(context, family) {
            return new Promise((resolve, reject) => {
                let data = {
                    family: family
                }

                axios.post(api.productsSubFamilies(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getTypes(context, filters) {
            return new Promise((resolve, reject) => {
                axios.post(api.productsTypes(), filters)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        changeVisibility(context, products) {
            return new Promise((resolve, reject) => {
                axios.post(api.productsChangeVisibility(), products)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        cancel(context, products) {
            return new Promise((resolve, reject) => {
                axios.post(api.productsCancel(), products)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    }
})

export default Product
