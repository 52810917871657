<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Mandatos"
                        :subtitle="'A mostrar ' + mandates.length + ' de ' + mandatesOriginal.length + ' mandatos.'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  color="primary" class="mr-3" @click="generateNewMandates()" v-if="writePermission('debits/debits')">Gerar Novos Mandatos</v-btn>
                <!--                <v-btn color="warning" class="mr-3" @click="dialogEditEmail = true">Editar email</v-btn>-->
                <v-btn tile small  color="error" @click="clearFilters()">{{ $t('clearFilters') }}</v-btn>
            </v-col>
        </v-row>

        <!-- TABLE -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th @click="updateSort('Mandato', 'string')">
                                Mandato <i class="fas fa-arrow-up" v-if="sort.field==='Mandato' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='Mandato' && sort.reverse"></i>
                            </th>
                            <th @click="updateSort('Nome', 'string')">
                                Nome <i class="fas fa-arrow-up" v-if="sort.field==='Nome' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='Nome' && sort.reverse"></i>
                            </th>
                            <th @click="updateSort('CDU_LOGIN', 'string')">
                                Email <i class="fas fa-arrow-up" v-if="sort.field==='CDU_LOGIN' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='CDU_LOGIN' && sort.reverse"></i>
                            </th>
                            <th @click="updateSort('IBAN', 'string')">
                                IBAN <i class="fas fa-arrow-up" v-if="sort.field==='IBAN' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='IBAN' && sort.reverse"></i>
                            </th>
                            <th @click="updateSort('SWIFT', 'string')">
                                SWIFT <i class="fas fa-arrow-up" v-if="sort.field==='SWIFT' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='SWIFT' && sort.reverse"></i>
                            </th>
                            <th style="width: 100px" class="text-center" @click="updateSort('Enviado', 'number')">
                                Enviado <i class="fas fa-arrow-up" v-if="sort.field==='Enviado' && !sort.reverse"></i>
                                <i class="fas fa-arrow-down" v-if="sort.field==='Enviado' && sort.reverse"></i>
                            </th>
                            <th style="width: 1px" class="text-center">Ver</th>
                            <th style="width: 1px" class="text-center">Enviar</th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field v-model="filters.mandate" outlined hide-details @keyup="updateTable()"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model="filters.name" outlined hide-details @keyup="updateTable()"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model="filters.email" outlined hide-details @keyup="updateTable()"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model="filters.iban" outlined hide-details @keyup="updateTable()"></v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model="filters.swift" outlined hide-details @keyup="updateTable()"></v-text-field>
                            </td>
                            <td>
                                <v-select v-model="filters.send" :items="filtersYesNo" item-text="TEXT" item-value="VALUE" outlined hide-details @change="updateTable"></v-select>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="8">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="mandate in mandates">
                            <td>{{ mandate.Mandato }}</td>
                            <td>{{ mandate.Nome }}</td>
                            <td>{{ mandate.CDU_LOGIN }}</td>
                            <td>{{ mandate.IBAN }}</td>
                            <td>{{ mandate.SWIFT }}</td>
                            <td class="text-center">
                                <i v-if="mandate.Enviado === 1" class="fas fa-check-square fa-lg" style="color: green"></i>
                                <i v-if="mandate.Enviado !== 1" class="fas fa-minus-square fa-lg" style="color: red"></i>
                            </td>
                            <td>
                                <v-btn tile small  color="warning" @click="show(mandate)">Visualizar</v-btn>
                            </td>
                            <td>
                                <v-btn tile small  color="success" @click="toggleModalSendEmail(mandate)" v-if="writePermission('debits/debits')">
                                    Enviar
                                </v-btn>
                            </td>
                        </tr>
                        <tr v-if="mandates.length === 0">
                            <td colspan="6">Sem resultados</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
                <v-pagination
                        class="mt-2"
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="getMandates"
                ></v-pagination>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialogPreview" persistent max-width="700">
                <v-card>
                    <v-card-title>
                        {{ mandate.Mandato }} / {{ mandate.Nome }}
                    </v-card-title>
                    <v-card-text>
                        <div id="dialog_preview">
                            <div class="box box2" cellspacing="0" style="width: 100%; font-size: 11pt; margin-top:10px;">
                                <!--                                <div class="content">-->
                                <v-row>
                                    <v-col md="5" class="name">
                                        <img style="width: 80%;" src="../../assets/img/logotipo2.png" alt="Logo"><br>

                                        <p>
                                            <b>BICIMAX ARTIGOS DESPORTIVOS S.A.</b><br> Rua José Afonso 161 Fração B<br> Comeiras<br> 2430-402 Marinha Grande<br> Portugal
                                        </p>
                                    </v-col>
                                    <v-col md="1" class="name"></v-col>
                                    <v-col md="6" class="reference">
                                        <h4><strong>Autorização de Débito Directo SEPA CORE</strong></h4>

                                        <div id="preview_mandato" class="info2 btn-block">{{mandate.Mandato}}</div>
                                        <div style=" margin-top: 8px;">
                                            <span style="text-align:center;">Referência da autorização (ADD)</span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <!--                                </div>-->
                                <hr>
                                <v-row>
                                    <v-col md="3">
                                        <h1>Identificação do Devedor<br><em>Debtor identification<br>Identificación de Deudor</em>
                                        </h1>
                                    </v-col>
                                    <v-col md="9" class="data">
                                        <div id="preview_nome" class="info2 btn-block">{{mandate.Nome}}</div>
                                        <div class="push-right">
                                            <span>Nome do Devedor / <em>Name of the debtor</em> / <em>Nombre del Deudor</em></span>
                                        </div>

                                        <div id="preview_nome_comercial" class="info2 btn-block">
                                            {{mandate.NomeFiscal}}
                                        </div>
                                        <div class="push-right">
                                            <span>Nome Comercial do Devedor / <em>Comercial Name of the debtor</em> / <em>Nombre Comercial del Deudor</em></span>
                                        </div>

                                        <div id="preview_nif" class="info2 btn-block">{{mandate.NumContrib}}</div>
                                        <div class="push-right">
                                            <span>Número de Identificação Fiscal / <em>Fiscal Number</em> / <em>Número de Identificación Fiscal</em></span>
                                        </div>

                                        <div id="preview_morada" class="info2 btn-block">{{mandate.Fac_Mor}}</div>
                                        <div class="push-right">
                                            <span>Nome da rua e número / <em>Street name and number</em> / <em>Calle y número</em></span>
                                        </div>

                                        <div id="preview_cp_cploc" class="info2 btn-block">{{mandate.Fac_Cp}} {{mandate.Fac_Cploc}}
                                        </div>
                                        <div class="push-right">
                                            <span>Código Postal e Cidade / <em>Postal code and City</em> / <em>Código Postal y Ciudad</em></span>
                                        </div>

                                        <div id="preview_pais" class="info2 btn-block">España</div>
                                        <div class="push-right">
                                            <span>País / <em>Country</em> / <em>País</em></span>
                                        </div>

                                        <div id="preview_iban" class="info2 btn-block">{{mandate.IBAN}}</div>
                                        <div class="push-right">
                                            <span>IBAN / <em>IBAN</em> / <em>IBAN</em></span>
                                        </div>

                                        <div id="preview_swift" class="info2 btn-block">{{mandate.SWIFT}}</div>
                                        <div class="push-right">
                                            <span>SWIFT BIC / <em>SWIFT BIC</em> / <em>SWIFT BIC</em></span>
                                        </div>
                                    </v-col>
                                </v-row>
                                <hr class="clear">
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small  @click="dialogPreview = false" style="margin-left: auto">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialogEditEmail" persistent max-width="720">
                <v-card>
                    <v-card-title>
                        Editar Email Mandato
                    </v-card-title>
                    <v-card-text>
                        <v-textarea
                                solo
                                v-model="emailMandate"
                                rows="10"
                        ></v-textarea>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialogSendEmail" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{mandate.Mandato}}<br>{{mandate.Nome}}
                    </v-card-title>
                    <v-card-text>
                        <p>Enviar mandato para o email:</p>
                        <v-text-field
                                v-model="mandate.CDU_LOGIN"
                                outlined
                                hide-details
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small  @click="dialogSendEmail = false">Cancelar</v-btn>
                        <v-btn tile small  ref="btnSendEmail" color="success" @click="sendEmail()" style="margin-left: auto">
                            Enviar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<style src="../../assets/css/mandates.css"></style>

<script>
    export default {
        data: () => ({
            loading: false,
            mandates: [],
            mandatesWithFilters: [],
            mandatesOriginal: [],
            mandate: {},
            emailMandate: '',
            dialogPreview: false,
            dialogEditEmail: false,
            dialogSendEmail: false,
            pagination: {
                per_page: 50,
                page: 1
            },
            filters: {
                mandate: '',
                name: '',
                email: '',
                iban: '',
                swift: '',
                send: ''
            },
            sort: {
                field: 'Mandato',
                reverse: false,
                type: 'string'
            },
            filtersYesNo: [
                {TEXT: 'Todos', VALUE: ''},
                {TEXT: 'Não', VALUE: 0},
                {TEXT: 'Sim', VALUE: 1}
            ]
        }),
        created() {
            this.getMandates()
        },
        methods: {
            getMandates() {
                this.loading = true
                let request = {
                    url: api.mandates(),
                    data: {
                        pagination: this.pagination,
                        filters: this.filters
                    }
                }
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.mandatesOriginal = responseData.mandates
                            this.emailMandate = responseData.emailMandate
                            this.updateTable()
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao obter mandatos!',
                                type: 'error'
                            })
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loading = false
                    })
            },
            updateTable() {
                this.mandates = this.mandatesOriginal

                //Filters
                if (this.filters.mandate !== '') {
                    this.mandates = this.mandates.filter(line => line.Mandato.toUpperCase().includes(this.filters.mandate.toUpperCase()))
                }
                if (this.filters.name !== '') {
                    this.mandates = this.mandates.filter(line => line.Nome.toUpperCase().includes(this.filters.name.toUpperCase()))
                }
                if (this.filters.email !== '') {
                    this.mandates = this.mandates.filter(line => line.CDU_LOGIN.toLowerCase().includes(this.filters.email.toLowerCase()))
                }
                if (this.filters.iban !== '') {
                    this.mandates = this.mandates.filter(line => line.IBAN.toLowerCase().includes(this.filters.iban.toLowerCase()))
                }
                if (this.filters.swift !== '') {
                    this.mandates = this.mandates.filter(line => line.SWIFT.toLowerCase().includes(this.filters.swift.toLowerCase()))
                }
                if (this.filters.send !== '') {
                    this.mandates = this.mandates.filter(line => line.Enviado === this.filters.send)
                }

                //Sort
                this.mandates.sortAttr(this.sort.field, this.sort.reverse, this.sort.type)

                let pageIndex = this.pagination.page === 1 ? this.pagination.page - 1 : (this.pagination.page - 1) * this.pagination.per_page
                let pageCount = this.pagination.page === 1 ? this.pagination.per_page : (this.pagination.page - 1) * this.pagination.per_page + this.pagination.per_page
                this.mandatesWithFilters = this.mandates
                this.mandates = this.mandates.slice(pageIndex, pageCount);
            },
            updateSort(field, type) {
                if (this.sort.field === field) {
                    this.sort.reverse = !this.sort.reverse
                }
                this.sort.field = field
                this.sort.type = type

                this.updateTable()
                // this.lines.sortAttr(this.sort.field, this.sort.reverse, this.sort.type)
            },
            show(mandate) {
                this.mandate = mandate
                this.dialogPreview = true
            },
            clearFilters() {
                this.filters = {
                    mandate: '',
                    name: '',
                    email: '',
                    iban: '',
                    swift: '',
                    send: ''
                }
                this.updateTable()
            },
            generateNewMandates() {
                let request = {
                    url: api.mandatesNew()
                }
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            if (responseData.addedMandates > 0) {
                                this.getMandates()
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Foram gerados ' + responseData.addedMandates + ' novo(s) mandato(s)',
                                    type: 'success'
                                })
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Não há clientes para gerar mandatos',
                                    type: 'warning'
                                })
                            }
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar mandatos!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            toggleModalSendEmail(mandate) {
                this.dialogSendEmail = !this.dialogSendEmail
                this.mandate = mandate
            },
            sendEmail() {
                let request = {
                    url: api.mandatesSend(),
                    data: {
                        mandate: this.mandate
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.mandatesOriginal = responseData.mandates
                            this.updateTable()
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Email enviado com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao enviar email!',
                                type: 'error'
                            })
                        }
                        this.dialogSendEmail = false
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.dialogSendEmail = false
                    })
            },
            writePermission(permission) {
                return User.getters.writePermission(permission)
            }
        },
        computed: {
            getTotalPages() {
                return Math.ceil(this.mandatesWithFilters.length / this.pagination.per_page)
            }
        }
    }
</script>
