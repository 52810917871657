<style>
.notFound {
  background-color: #ff9898 !important;
}

</style>
<template>

  <v-container fluid>

    <!-- HEADER -->
    <v-row class="content-header">
      <v-col>
        <page-title
            back
            title="Lista de artigos da encomenda"
            :subtitle="$t('show') + ' ' + products.length + ' de ' + productsOriginal.length + ' artigos'"
        ></page-title>
      </v-col>
      <v-col class="text-right">
        <v-btn :disabled="orderStatus!='created'" tile small class="ml-2 mb-2" @click="confirmOrder()" color="info"><i
            class="fas fa-check mr-1"></i>Confirmar
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-textarea
            solo
            v-model="orderNotes"
            name="orderNotes"
            label="Comentários"
        ></v-textarea>
      </v-col>
    </v-row>

    <!-- LISTA -->
    <v-row>
      <v-col>
        <div class="staff_v-card">
          <v-simple-table class="staff_table">
            <thead>
            <tr class="titles_row">
              <th width="60" class="text-center">ID</th>
              <th width="200" class="text-center">EAN</th>
              <th width="80" class="text-center">Artigo</th>
              <th width="320" class="text-center">Descrição</th>
              <th width="80" class="text-center">Requeridos</th>
              <th width="80" class="text-center">Confirmados</th>
              <th width="70" class="text-center">Em Stock</th>
              <th width="70" class="text-center">Preço</th>
              <th width="70" class="text-center">IVA</th>
              <th class="text-center" width="80">Preço Bicimax</th>
              <th width="80">Data entrega</th>
              <th width="20"></th>
              <th width="20"></th>
              <th width="20"></th>
              <th width="20"></th>

            </tr>
            <tr class="filters_row">
              <td></td>
              <td></td>
              <td>
              <td>
              </td>
              <td>
              </td>
              <td>
              </td>
              <td>
              </td>
              <td>
              </td>

              <td></td>
              <td>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="15">
                <v-progress-linear indeterminate></v-progress-linear>
              </td>
            </tr>
            <template v-for="product in products">
              <tr :class="{ 'notFound': product.sku == null }">
                <td class="text-center">{{ product.messageId }}</td>
                <td class="text-center">
                  <span v-if="product.sku != null">{{ product.productEan }}</span>
                  <span v-else>
                    <input
                        type="text"
                        class="input-large"
                        style="text-align: center;background-color: white;border: solid 1px black;"
                        v-model="product.productEan"
                    />
                    <button class="btn btn-small" @click="checkProduct(product);">
                      <i style="color: #22a322; font-size: medium; background-color: #d7d7bd; border-radius: 20px; margin-left: 5px;" class="fa fa-check text-success"></i>
                    </button>
                    </span>
                </td>
                <td class="text-center">{{ product.sku }}</td>
                <td class="text-center">{{ product.productDescription }}</td>
                <td class="text-center">{{ product.requestedQuantity }}</td>
                <td class="text-center">
                  <v-text-field
                      :disabled="orderStatus!='created' || product.sku == null"
                      v-model="product.confirmedQuantity"
                      hide-details
                      outlined
                      class="staff_text-field_small text-center"
                      style="width: 60px;"
                      single-line
                      type="number"
                      @click="checkConfirmedValue(product)"
                      @change="checkConfirmedValue(product)"
                  />
                  <!--                {{ product.confirmedQuantity }}-->
                </td>
                <td class="text-center">{{ product.inStockQuantity }}</td>
                <td class="text-center">{{ product.grossPrice }}€</td>
                <td class="text-center">{{ product.realTax }}%</td>
                <td class="text-center">{{ product.realPrice }}€</td>
                <td class="text-center">
                  <input :disabled="orderStatus!='created' || product.sku == null" type="date"
                         v-model="product.expectedDeliveryDate" value="2020-01-20"/>
                </td>
                <td class="text-center"></td>

                <td>
                </td>
                <td>
                </td>

              </tr>
              <tr v-for="(manipulo,index) in product.manipulos">
                <td class="text-center">
                  <v-checkbox style="margin-left:1.5vw" hide-details v-model="product.selectedManipulo" :value="index+1"
                              @change="product.selectedManipulo = $event;"></v-checkbox>
                </td>
                <td class="text-center">Manipulo <small>({{ manipulo.brand }})</small></td>
                <td class="text-center">{{ manipulo.productId }}</td>
                <td class="text-center">{{ manipulo.description }}</td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>

                <td>

                </td>
                <td>

                </td>

              </tr>
            </template>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>

export default {
  data: function () {
    return {
      loading: false,
      products: [],
      messageId: null,
      orderNotes: null,
      requestedDeliveryDate: null,
      productsOriginal: [],
      selectedPick: {},
      filters: {
        typeClient: '',
        client: '',
        name: '',
        modePag: '',
        condPag: '',
        salesman: '',
        state: ['created', 'confirmado']
      },
      dialogClosePick: false,
      closePickId: '',
      responseDialog: {
        open: false,
        loading: false,
        error: false,
        title: '',
        message: '',
        submessage: ''
      },

    }
  },
  created() {
    document.title = 'Edifact | STAFF'
    this.messageId = this.$route.params.id;
    this.orderStatus = this.$route.query.status;
    if (this.messageId) {
      this.getProductsList();
    } else {
      this.loading = false;
    }
  },
  methods: {
    getProductsList() {
      this.loading = true;
      this.products = [];
      this.productsOriginal = [];
      let today = new Date(new Date(Date.now()).setHours(0, 0, 0, 0));
      let slicedToday = today.toISOString().slice(0, 10);
      let request = {
        url: '/api/staff/edifact/' + this.messageId + "/content"
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.products = responseData.products;
              this.requestedDeliveryDate = responseData.requestedDeliveryDate;
              this.productsOriginal = responseData.products;

              if (this.orderStatus == 'created') {
                if (new Date(this.requestedDeliveryDate).getTime() < today.getTime())
                  this.requestedDeliveryDate = slicedToday;
                for (let i = 0; i < this.products.length; i++) {

                  this.products[i].confirmedQuantity = this.products[i].requestedQuantity;
                  if (this.products[i].inStockQuantity <= 0)
                    this.products[i].confirmedQuantity = 0;
                  else if (this.products[i].inStockQuantity < this.products[i].requestedQuantity)
                    this.products[i].confirmedQuantity = this.products[i].inStockQuantity;

                  if (this.products[i].expectedDeliveryDate == null || new Date(this.products[i].expectedDeliveryDate).getTime() < today.getTime())
                    this.products[i].expectedDeliveryDate = slicedToday;
                }
              }

            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          })
    },
    checkConfirmedValue(product) {
      if (product.confirmedQuantity > product.requestedQuantity) {
        product.confirmedQuantity = product.requestedQuantity;
      }
      if (product.confirmedQuantity < 0 || product.confirmedQuantity == '') {
        product.confirmedQuantity = 0;
      }
      //if(product.confirmedQuantity > product.inStockQuantity)
      //  product.confirmedQuantity = product.inStockQuantity > 0 ? product.inStockQuantity : 0;


    },
    confirmOrder() {
      let loader = this.$loading.show();

      let request = {
        url: '/api/staff/edifact/' + this.messageId,
        data: {
          products: this.products,
          orderNotes: this.orderNotes
        }
      }

      ApiWS.dispatch('makePut', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Encomenda confirmada com sucesso!',
                type: 'success'
              })
              this.$router.push('/orders/edifact');
            }
            loader.hide();
          })
          .catch(() => {
            loader.hide();
          })
    },
    checkProduct(product) {
      if(product.sku != null)
        return;

      let loader = this.$loading.show();

      let request = {
        url: '/api/staff/edifact/' + this.messageId + "/" + product.id,
        data: {
          product: product,
        }
      }
      ApiWS.dispatch('makePatch', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.getProductsList();
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Produto inserido com sucesso!',
                type: 'success'
              })
            }else{
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Problemas ao inserir produto!',
                type: 'error'
              })
            }
            loader.hide();
          })
          .catch(() => {
            loader.hide();
          })
    }
  },
}
</script>
