<style scoped>
.v-input__append-inner {
    margin-top: 5px !important;
}

.staff_table tr .v-btn {
    display: none;
}

.staff_table tr:hover .v-btn {
    display: block;
}

.cursorHover {
    cursor: pointer;
}

.cursorHover:hover{
    text-decoration: underline ;
    text-decoration-color: #c80b0e;
}

</style>

<template>
    <v-container fluid>

        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Pendentes de Liquidação"
                        :subtitle="$t('show') + ' ' + lines.length + ' de ' + originalLines.length + ' documentos'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small class="ml-2 mb-2" @click="resetFilters()" color="error">Limpar Filtros
                </v-btn>
                <v-btn tile small class="ml-2 mb-2" @click="update()" color="info">Atualizar</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 75px;">DataDoc</th>
                            <th style="width: 75px;">DataVenc</th>
                            <th style="width: 40px;">Pais</th>
                            <th style="width: 75px;">Entidade</th>
                            <th>Nome</th>
                            <th style="width: 45px;">Tipo</th>
                            <th style="width: 90px;">NumDoc</th>
                            <th style="width: 90px;">ValorTotal</th>
                            <th style="width: 90px;">ValorPendente</th>
                            <th style="width: 20px;">DescPag</th>
                            <th style="width: 200px;">CondPag</th>
                            <th style="width: 80px;">ModoPag</th>
                            <th style="width: 45px;">IBAN</th>
                            <th style="width: 45px;">SWIFT</th>
                            <th style="width: 20px;"></th>
                            <th style="width: 20px;"></th>
                            <th style="width: 20px;"></th>
                            <th>Comentários</th>
                            <th>OBS Emissor</th>
                            <th style="width: 65px;"></th>
                        </tr>
                        <tr class="filters_row">
                            <th>
                                <v-text-field outlined hide-details v-model="filters.DataDoc"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.DataVenc"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-select
                                        dense
                                        v-model="filters.Pais"
                                        :items="paises"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterLines()"
                                ></v-select>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Entidade"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Nome"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-select
                                        dense
                                        v-model="filters.TipoDoc"
                                        :items="tiposDoc"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterLines()"
                                ></v-select>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.NumDoc"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.ValorTotal"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.ValorPendente"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-select
                                        dense
                                        v-model="filters.DescPag"
                                        :items="filtersYesNo"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterLines()"
                                ></v-select>
                            </th>
                            <th>
                                <v-select
                                        dense
                                        v-model="filters.CondPag"
                                        :items="condsPag"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterLines()"
                                ></v-select>
                            </th>
                            <th>
                                <v-select
                                        dense
                                        v-model="filters.ModoPag"
                                        :items="modosPag"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterLines()"
                                ></v-select>
                            </th>
                            <th>
                                <v-select
                                        dense
                                        v-model="filters.iban"
                                        :items="filtersYesNo"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterLines()"
                                ></v-select>
                            </th>
                            <th>
                                <v-select
                                        dense
                                        v-model="filters.swift"
                                        :items="filtersYesNo"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterLines()"
                                ></v-select>
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>

                        </thead>
                        <tbody v-if="loading">
                        <tr>
                            <td colspan="21">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-if="!loading">
                        <tr v-for="line in lines">
                            <td>{{ line.DataDoc.substring(0, 10) }}</td>
                            <td>{{ line.DataVenc.substring(0, 10) }}</td>
                            <td class="text-center">{{ line.Pais }}</td>
                            <td>
                                <div @click="selectEntidade(line.Entidade)" style="cursor: pointer">
                                    {{ line.Entidade }}
                                </div>
                            </td>
                            <td>
                                <i v-show="line.TipoCred === '2'" class="fas fa-minus-circle" style="color: red"></i>
                                <span class="cursorHover" @click="openDialogEdit(line)">{{ line.Nome }}</span>
                                <a :href="'/clients/clients/'+line.Entidade" target="_blank" class="ml-3"><i
                                        class="fas fa-fw fa-external-link-alt"></i></a>
                            </td>
                            <td class="text-center">{{ line.TipoDoc }}</td>
                            <td>{{ line.NumDoc }}
                                <i class="far fa-file-pdf" style="color: red; cursor: pointer"
                                   @click="downloadPDF(line.Serie, line.TipoDoc, line.NumDoc)"></i>
                            </td>
                            <td class="text-right">{{ $money(line.ValorTotal) }}</td>
                            <td class="text-right">{{ $money(line.ValorPendente) }}</td>
                            <td class="text-center">
                                <i v-if="line.DescPag" class="fas fa-check green--text"></i>
                            </td>
                            <td>{{ line.Descricao }}</td>
                            <td>{{ line.ModoPag }}</td>
                            <td class="text-center">
                                <i v-show="line.ModoPag === 'DEBDI' && line.IBAN.length !== 24"
                                   class="fas fa-exclamation-triangle" style="color: red"></i>
                            </td>
                            <td class="text-center">
                                <i v-show="line.ModoPag === 'DEBDI' && (line.SWIFT.length < 8 || line.SWIFT.length > 11)"
                                   class="fas fa-exclamation-triangle" style="color: red"
                                   @click="openDialogEdit(line)"></i>
                            </td>
                            <td>
                                <i v-if="line.Notas" class="fab fa-product-hunt indigo--text"
                                   @click="openDialogEdit(line)"></i>
                            </td>
                            <td>
                                <i v-if="line.CDU_Observacoes" class="fas fa-file-powerpoint red--text"
                                   @click="openDialogEdit(line)"></i>
                            </td>
                            <td>
                                <i v-if="line.cheque" class="fas fa-money-check-alt red--text"
                                   @click="openDialogEdit(line)"></i>
                            </td>
                            <td>{{ line.CDU_Observacoes != null ? line.CDU_Observacoes : line.CDU_Comentario }}</td>
                            <td>{{ line.OBS_DOC }}</td>
                            <td style="width: 70px"><v-btn tile x-small class="info" @click="openDialogEdit(line)">Obser.</v-btn></td>
                            <td style="width: 70px">
                                <v-btn tile x-small class="info" @click="openCommentsDialog(line)">Notas</v-btn>
                            </td>

                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">
            <v-dialog v-model="dialogEdit" persistent width="990">
                <v-card>
                    <v-toolbar class="mb-3" flat dense dark color="primary">
                        <v-toolbar-title class="text-uppercase">
                            Observações Cliente
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pb-0">
                        <v-row>
                            <v-col>
                                <p>
                                    <strong>Cliente: </strong> <i v-show="editLine.TipoCred === '2'"
                                                                  class="fas fa-minus-circle" style="color: red"></i>
                                    {{ editLine.Entidade }} - {{ editLine.Nome }}
                                </p>
                                <p>
                                    <strong>Bloqueado: </strong>
                                    <v-btn tile x-small @click="editLine.TipoCred = '1'"
                                           :class="{'success': editLine.TipoCred === '1'}">Não
                                    </v-btn>
                                    <v-btn tile x-small @click="editLine.TipoCred = '2'"
                                           :class="{'error': editLine.TipoCred === '2'}">Sim
                                    </v-btn>
                                </p>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        label="IBAN"
                                        dense
                                        :error="editLine.ModoPag === 'DEBDI' && editLine.IBAN && editLine.IBAN.length !== 24"
                                        outlined
                                        hide-details
                                        v-model="editLine.IBAN"
                                        class="mb-4"
                                >
                                    <template v-slot:append-outer>
                                        <v-icon @click="goToIbanCalculator(editLine.IBAN)">mdi-calculator</v-icon>
                                    </template>
                                </v-text-field>
                                <v-text-field
                                        label="SWIFT"
                                        dense
                                        :error="editLine.ModoPag === 'DEBDI' && editLine.SWIFT && (editLine.SWIFT.length < 8 || editLine.SWIFT.length > 11)"
                                        outlined
                                        hide-details
                                        v-model="editLine.SWIFT"
                                        class="mb-4"
                                >
                                    <template v-slot:append-outer>
                                        <v-icon @click="goToIbanCalculator(editLine.IBAN)">mdi-calculator</v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p class="mb-0">
                                    <strong>Notas do Cliente: </strong>
                                    <v-textarea
                                            v-model="editLine.Notas"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-textarea>
                                </p>
                            </v-col>
                            <v-col>
                                <p class="mb-0">
                                    <strong>Observações do Documento: </strong>
                                    <v-textarea
                                            v-model="editLine.CDU_Observacoes"
                                            outlined
                                            dense
                                            hide-details
                                    ></v-textarea>
                                </p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-3">
                        <v-btn tile small @click="dialogEdit = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="confirmDialogEdit()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>


        <v-row justify="center">
            <v-dialog v-model="commentsDialog" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Adicionar comentário
                    </v-card-title>
                    <v-card-text>
                        <v-textarea
                                label = "Comentarios"
                                :value="commentData.comment" @blur="commentData.comment = $event.target.value"
                                outlined
                                dense
                                hide-details>

                        </v-textarea>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="commentsDialog = false">
                            Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="saveComment" color="success" :loading="loading">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </v-container>
</template>

<script>
export default {
    data: function () {
        return {
            commentData: {
                line: null,
                id: null,
                comment: ''
            },
            commentsDialog: false,
            loading: false,
            exporting: false,
            menu: false,
            dialogEdit: false,
            editLine: {},
            lines: [],
            paises: [],
            modosPag: [],
            condsPag: [],
            tiposDoc: [],
            originalLines: [],
            totalLines: [],
            filters: {
                DataDoc: '',
                DataVenc: '',
                Pais: '',
                Entidade: '',
                Nome: '',
                ModoPag: '',
                DescPag: '',
                CondPag: '',
                TipoDoc: '',
                NumDoc: '',
                ValorTotal: '',
                ValorPendente: '',
                iban: '',
                swift: '',
            },
            filtersYesNo: [
                {value: '', text: ''},
                {value: '0', text: 'Não'},
                {value: '1', text: 'Sim'},
            ]
        }
    },
    created() {
        document.title = 'Pendentes de Liquidação';
        this.update();
    },
    methods: {
        update() {
            this.loading = true
            ApiWS.dispatch('makeGet', {url: '/api/staff/accounts/pending'})
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.originalLines = responseData.lines;
                            this.modosPag = [...new Set(this.originalLines.map(item => ({
                                value: item.ModoPag,
                                text: item.ModoPag
                            })))];
                            this.modosPag.push('');
                            this.modosPag.sort();
                            this.condsPag = [...new Set(this.originalLines.map(item => ({
                                value: item.CondPag,
                                text: item.Descricao
                            })))];
                            this.condsPag.push('');
                            this.condsPag.sort();
                            this.tiposDoc = [...new Set(this.originalLines.map(item => ({
                                value: item.TipoDoc,
                                text: item.TipoDoc
                            })))];
                            this.tiposDoc.push('');
                            this.tiposDoc.sort();
                            this.paises = [...new Set(this.originalLines.map(item => ({
                                value: item.Pais,
                                text: item.Pais
                            })))];
                            this.paises.push('');
                            this.paises.sort();
                        }
                        this.filterLines();
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
        },
        resetFilters() {
            this.filters = {
                DataDoc: '',
                DataVenc: '',
                Pais: '',
                Entidade: '',
                Nome: '',
                ModoPag: '',
                DescPag: '',
                CondPag: '',
                TipoDoc: '',
                NumDoc: '',
                ValorTotal: '',
                ValorPendente: '',
                iban: '',
                swift: '',
            };
            this.filterLines();
        },
        selectEntidade(entidade) {
            this.filters.Entidade = entidade
            this.filterLines()
        },
        openDialogEdit(line) {
            this.editLine = Object.assign({}, line);
            this.dialogEdit = true;
        },
        confirmDialogEdit() {
            this.dialogEdit = false;
            let request = {
                url: '/api/staff/accounts/pending/update',
                data: {
                    line: this.editLine
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Actualizações gravadas com sucesso!',
                                type: 'success'
                            })
                            this.update();
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.data,
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        filterLines() {
            this.lines = this.originalLines;
            if (this.filters.DataDoc !== '') {
                // this.lines = this.lines.filter(line => line.DataDoc.includes(this.filters.DataDoc));
                this.lines = this.lines.filter(line => line.DataVenc >= this.filters.DataDoc);
            }
            if (this.filters.DataVenc !== '') {
                // this.lines = this.lines.filter(line => line.DataVenc.includes(this.filters.DataVenc));
                this.lines = this.lines.filter(line => line.DataVenc <= this.filters.DataVenc);
            }
            if (this.filters.Pais !== '') {
                this.lines = this.lines.filter(line => line.Pais === this.filters.Pais);
            }
            if (this.filters.Entidade !== '') {
                this.lines = this.lines.filter(line => line.Entidade.toUpperCase() === this.filters.Entidade.toUpperCase());
            }
            if (this.filters.Nome !== '') {
                this.lines = this.lines.filter(line => line.Nome.toUpperCase().includes(this.filters.Nome.toUpperCase()));
            }
            if (this.filters.ModoPag !== '') {
                this.lines = this.lines.filter(line => line.ModoPag === this.filters.ModoPag);
            }
            if (this.filters.CondPag !== '') {
                this.lines = this.lines.filter(line => line.CondPag === this.filters.CondPag);
            }
            if (this.filters.DescPag !== '') {
                this.lines = this.lines.filter(line => this.filters.DescPag === '0' ? line.DescPag === 0 : line.DescPag > 0);
            }
            if (this.filters.TipoDoc !== '') {
                this.lines = this.lines.filter(line => line.TipoDoc === this.filters.TipoDoc);
            }
            if (this.filters.NumDoc !== '') {
                this.lines = this.lines.filter(line => line.NumDoc.toString().includes(this.filters.NumDoc));
            }
            if (this.filters.ValorTotal !== '') {
                this.lines = this.lines.filter(line => this.$2decimal(line.ValorTotal) === this.$2decimal(this.filters.ValorTotal));
            }
            if (this.filters.ValorPendente !== '') {
                this.lines = this.lines.filter(line => this.$2decimal(line.ValorPendente) === this.$2decimal(this.filters.ValorPendente));
            }
            if (this.filters.iban !== '') {
                this.lines = this.lines.filter(line => this.filters.iban === '0' ?
                        !(line.ModoPag === 'DEBDI' && line.IBAN.length !== 24) :
                        line.ModoPag === 'DEBDI' && line.IBAN.length !== 24);
            }
            if (this.filters.swift !== '') {
                this.lines = this.lines.filter(line => this.filters.swift === '0' ?
                        !(line.ModoPag === 'DEBDI' && (line.SWIFT.length < 8 || line.SWIFT.length > 11)) :
                        line.ModoPag === 'DEBDI' && (line.SWIFT.length < 8 || line.SWIFT.length > 11));
            }
        },
        downloadPDF(serie, tipodoc, numdoc) {
            let request = {
                url: api.orderPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let request = {
                                url: api.orderDownloadPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
                            }
                            ApiWS.dispatch('makeDownload', request)
                                    .then(response => {
                                        var file = new Blob([response.data], {
                                            type: 'application/pdf'
                                        });
                                        var fileURL = URL.createObjectURL(file);
                                        var a = document.createElement('a');
                                        a.href = fileURL;
                                        a.target = '_blank';
                                        a.download = serie + '_' + tipodoc + '_' + numdoc + '.pdf';
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                    .catch(response => {
                                        Alert.dispatch('show', {
                                            snackbar: true,
                                            text: 'Erro de servidor!',
                                            type: 'error'
                                        })
                                    })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar PDF!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        goToIbanCalculator(iban) {
            window.open("https://www.ibancalculator.com/validate/" + iban, '_blank');
        },
        openCommentsDialog(line) {
            this.commentData.comment = line.CDU_Observacoes;
            this.commentData.id = line.IdHistorico;
            this.commentData.line = line;
            this.commentsDialog = true;
        },
        saveComment() {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/accounts/pending/comment',
                data: {
                    pendingId: this.commentData.id,
                    comment: this.commentData.comment
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.commentData.line.CDU_Comentario = this.commentData.comment
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Comentário guardado!',
                                type: 'success'
                            })
                        }
                        this.commentsDialog = false;
                        this.commentData = {
                            line: null,
                            id: null,
                            comment: ''
                        }
                        loader.hide();
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.commentData = {
                            line: null,
                            id: null,
                            comment: ''
                        }
                        loader.hide();
                        this.commentsDialog = false;
                    })

        },
        test(event){
            console.log(event);
        }

    }
}

</script>
