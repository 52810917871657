<style scoped>
    .back_btn{
        float: left;
    }
    .staff_subtitle {
        margin-bottom: 0 !important;
        margin-top: -6px;
        font-size: 14px;
        color: gray;
    }
</style>
<template>
    <div style="min-height: 44px">
        <v-btn v-if="back" small tile color="default" class="back_btn mt-2 mr-3" @click="$router.go(-1)">
            <v-icon>arrow_back</v-icon>
        </v-btn>
        <h6 class="title text-uppercase" style="min-height: 32px">{{ title }}</h6>
        <p class="staff_subtitle">{{ subtitle }}</p>
    </div>
</template>
<script>
    export default {
        name: 'page-title',
        props: {
            title: String,
            subtitle: String,
            back: {
                default: false,
                type: Boolean
            }
        },
    }
</script>
