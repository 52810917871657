<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th>NumDoc</th>
                        <th>Entidade</th>
                        <th>Nome</th>
                        <th>Artigo</th>
                        <th>Descricao</th>
                        <th>Quantidade</th>
                        <th>Data Entrega</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="filters_row">
                        <td></td>
                        <td>
                            <v-text-field
                                    v-model="filters.entity"
                                    outlined
                                    hide-details
                                    dense
                                    @keyup.enter="filter()"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.name"
                                    outlined
                                    hide-details
                                    dense
                                    @keyup.enter="filter()"
                            ></v-text-field>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr v-for="order in orders">
                        <td>{{ order.numdoc }}</td>
                        <td>{{ order.entidade }}</td>
                        <td>{{ order.nome }}</td>
                        <td>{{ order.artigo }}</td>
                        <td>{{ order.descricao }}</td>
                        <td>{{ order.Quantidade }}</td>
                        <td>
                            <v-btn tile small outlined @click="toggleDialogArriveDate(order)">
                                {{ order.DataEntrega }}
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialogArriveDate" persistent max-width="500">
                <v-card>
                    <v-card-title>
                        Atualizar {{ $t('arrivalDate') }}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0">
                                Atualizar {{ $t('arrivalDate') }} do {{ $t('product') }} {{ inputDialog.product }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="px-0 pb-0">
                                <v-text-field
                                    v-model="inputDialog.arriveDate"
                                    dense
                                    outlined
                                    hide-details
                                    style="width: 200px; float: left"
                                    clear-icon="ml-4"
                                ></v-text-field>
<!--                                <v-menu-->
<!--                                        v-model="menu4"-->
<!--                                        :close-on-content-click="false"-->
<!--                                        :nudge-right="40"-->
<!--                                        transition="scale-transition"-->
<!--                                        offset-y-->
<!--                                        min-width="290px"-->
<!--                                >-->
<!--                                    <template v-slot:activator="{ on }">-->
<!--                                        <v-text-field-->
<!--                                                v-model="inputDialog.arriveDate"-->
<!--                                                :label="$t('arrivalDate')"-->
<!--                                                readonly-->
<!--                                                dense-->
<!--                                                outlined-->
<!--                                                hide-details-->
<!--                                                v-on="on"-->
<!--                                                style="width: 200px; float: left"-->
<!--                                                class="ml-4"-->
<!--                                        ></v-text-field>-->
<!--                                    </template>-->
<!--                                    <v-date-picker v-model="inputDialog.arriveDate" locale="pt-pt"></v-date-picker>-->
<!--                                </v-menu>-->
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogArriveDate = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="updateDateArrive" color="success">Atualizar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
    data: function () {
        return {
            orders: [],
            ordersOriginal: [],
            filters: {
                entity: '',
                name: ''
            },
            inputDialog: {
                product: '',
                arriveDate: '',
                id: ''
            },
            dialogArriveDate: false,
            menu4: false
        }
    },
    created() {
        this.getOrders();
    },
    methods: {
        getOrders() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/orders/2023'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.orders = responseData.orders;
                            this.ordersOriginal = responseData.orders;
                            this.filter();
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        toggleDialogArriveDate(line) {
            this.inputDialog.product = line.artigo;
            this.inputDialog.arriveDate = line.DataEntrega;
            this.inputDialog.id = line.id;
            this.dialogArriveDate = true;
        },
        updateDateArrive() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/orders/2023',
                data: {
                    order: this.inputDialog
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Data atualizada com sucesso!',
                                type: 'success'
                            })
                            this.dialogArriveDate = false
                            this.getOrders();
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        filter() {
            this.orders = this.ordersOriginal;

            if (this.filters.entity) {
                this.orders = this.orders.filter(order => order.entidade.includes(this.filters.entity));
            }
            if (this.filters.name) {
                this.orders = this.orders.filter(order => order.nome.includes(this.filters.name));
            }
        }
    }
}
</script>
