<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row>
            <v-col cols="12" md="6">
                <page-title title="Clientes Problemáticos"
                            :subtitle="$t('show')+' ' + getClientsStartPage + '-' +getClientsEndPage+ ' de ' + totalClients +' clientes'"></page-title>
            </v-col>
        </v-row>
        <!-- CLIENTS TABLE -->
        <div class="staff_v-card">
            <v-simple-table class="staff_table">
                <thead>
                <tr>
                    <th width="1px">Cliente</th>
                    <th>Nome</th>
                    <th width="1px">País</th>
                    <th width="1px">Zona</th>
                    <th width="1px">ZonaComercial</th>
                    <th width="1px" class="text-center">IBAN</th>
                    <th width="1px" class="text-center">SWIFT</th>
                    <th width="1px">Conta</th>
                    <th width="1px">ModoPag</th>
                    <th width="1px">MoradaAlt</th>
                    <th width="1px">MA_Pais</th>
                    <th width="1px">CDU_Zona</th>
                    <th width="1px">AcessoB2B</th>
                    <th width="1px" class="text-center">Email</th>
                    <th width="1px">TipoTerceiros</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="19">
                        <v-progress-linear indeterminate></v-progress-linear>
                    </td>
                </tr>
                <tr v-for="client in clients">
                    <td>
                        <a :href="'/clients/clients/' + client.Cliente" target="_blank">{{ client.Cliente }}</a>
                    </td>
                    <td>{{ client.Nome }}</td>
                    <td>{{ client.Pais }}</td>
                    <td class="text-center">{{ client.Zona }}</td>
                    <td v-bind:class="{'red lighten-4':client.CDU_ZonaComercial === 0}" class="text-center">{{ client.CDU_ZonaComercial }}
                    </td>
                    <td width="1px" v-bind:class="{'red lighten-4':( client.CDU_MODPAG == 2 || client.CDU_MODPAG == 6 || client.CDU_MODPAG == 13 ) && ( client.IBAN === null || client.IBAN.length < 23 )}">{{ client.IBAN }}</td>
                    <td width="1px" v-bind:class="{'red lighten-4':(client.CDU_MODPAG == 2 || client.CDU_MODPAG == 6 || client.CDU_MODPAG == 13) && ( client.SWIFT === null ||client.SWIFT.length < 6)}">{{ client.SWIFT }}</td>
                    <td class="text-center">{{ client.Conta }}</td>
                    <td class="text-center">{{ client.CDU_MODPAG }}</td>
                    <td v-bind:class="{'red lighten-4':(client.MoradaAlternativa !== null && client.CDU_Zona === null) || (client.MoradaAlternativa !== null && client.MA_Pais === null)}" class="text-center">{{ client.MoradaAlternativa }}</td>
                    <td v-bind:class="{'red lighten-4':client.MoradaAlternativa !== null && client.MA_Pais === null}" class="text-center">{{ client.MA_Pais }}</td>
                    <td v-bind:class="{'red lighten-4':client.CDU_Zona === null && client.MoradaAlternativa !== null}" class="text-center">{{ client.CDU_Zona }}</td>
                    <td v-bind:class="{'red lighten-4':client.CDU_ACESSO_B2B === '2' && client.CDU_LOGIN === null}" class="text-center">{{ client.CDU_ACESSO_B2B }}</td>
                    <td v-bind:class="{'red lighten-4':client.CDU_ACESSO_B2B === '2' && client.CDU_LOGIN === null}">{{ client.CDU_LOGIN }}</td>
                    <td v-bind:class="{'red lighten-4':client.TipoTerceiro === null}">{{ client.TipoTerceiro }}</td>
                </tr>
                </tbody>
            </v-simple-table>
        </div>
        <v-row>
            <v-col cols="4">
                <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        dense
                        outlined
                        hide-details
                        @change="changePagination"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="float: left; width: 100px;"
                ></v-select>
            </v-col>
            <v-col class="text-right">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="initialize"
                        style="justify-content: flex-end;"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data: () => ({
            clients: [],
            totalClients: 0,
            loading: false,
            pagination: {
                per_page: 25,
                page: 1
            },
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
        }),
        computed: {
            getTotalPages() {
                return Math.ceil(this.totalClients / this.pagination.per_page)
            },
            getClientsStartPage() {
                return Math.ceil(this.clients.length * this.pagination.page - this.clients.length + 1)
            },
            getClientsEndPage() {
                return Math.ceil(this.clients.length * this.pagination.page)
            }
        },
        created() {
            document.title = 'Clientes Problematicos';
            this.initialize()
        },
        methods: {
            initialize() {
                this.loading = true;
                this.clients = [];
                let request = {
                    url: '/api/staff/clients/problems',
                    data: {
                        pagination: this.pagination,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.clients = responseData.clients;
                            this.totalClients = responseData.totalClients;
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            changePagination(){
                this.pagination.page = 1;
                this.initialize()
            }
        }
    }
</script>
