import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const ApiWS = new Vuex.Store({
    actions: {
        makeGet(context, request) {
            return new Promise((resolve, reject) => {
                axios.get(request.url)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        makePost(context, request) {
            return new Promise((resolve, reject) => {
                axios.post(request.url, request.data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        makeDownloadPost(context, request) {
            return new Promise((resolve, reject) => {
                axios.post(request.url, request.data,{responseType: 'blob'})
                    .then(response => {
                        if(response.headers['content-type'] === 'application/json')
                            reject(response)
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        makeDownload(context, request) {
            return new Promise((resolve, reject) => {
                axios.get(request.url, {responseType: 'blob'})
                    .then(response => {
                        if(response.headers['content-type'] === 'application/json')
                            reject(response)
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        makePostDownload(context, request) {
            return new Promise((resolve, reject) => {
                axios.post(request.url, request.data, {responseType: 'blob'})
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        makeFile(context, request) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('file', request.file);
                formData.append('data', request.data);
                axios.post(request.url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        makePut(context, request) {
            return new Promise((resolve, reject) => {
                axios.put(request.url, request.data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        makePatch(context, request) {
            return new Promise((resolve, reject) => {
                axios.patch(request.url, request.data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        makeDelete(context, request) {
            return new Promise((resolve, reject) => {
                axios.delete(request.url, request.data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    }
})

export default ApiWS
