<style>

.form-main-infos {
    min-height: 135px;

    #info-stats-card-answered {
        min-height: 150px;
        align-content: center;
        border-bottom: 2px solid rgb(26, 173, 36) !important;
        transition: box-shadow .3s ease, transform .2s ease;
        padding: 10px 0;

        h2 {
            padding-top: 6px;
        }

        .icon-container {
            padding: 6px;
            background-color: rgba(26, 173, 36, .1);

            i {
                color: rgb(26, 173, 36);
            }
        }

        &:hover {
            border: 1px solid rgb(26, 173, 36);
            transform: scale(1.01);
            box-shadow: 0 8px 50px #23232333;

        }
    }

    #info-stats-card-postponed {
        min-height: 150px;
        align-content: center;
        border-bottom: 2px solid rgb(255, 215, 0) !important;
        transition: box-shadow .3s ease, transform .2s ease;
        padding: 10px 0;

        h2 {
            padding-top: 6px;
        }

        .icon-container {
            padding: 6px;
            background-color: rgba(255, 215, 0, .1);
            display: flex;
            justify-content: center;

            i {
                color: rgb(255, 215, 0);
            }
        }

        &:hover {
            border: 1px solid rgb(255, 215, 0);
            transform: scale(1.01);
            box-shadow: 0 8px 50px #23232333;

        }
    }

    #info-stats-card-to-answer {
        min-height: 150px;
        align-content: center;
        border-bottom: 2px solid rgb(255, 0, 0) !important;
        transition: box-shadow .3s ease, transform .2s ease;
        padding: 10px 0;

        h2 {
            padding-top: 6px;
        }

        .icon-container {
            padding: 6px;
            background-color: rgb(255, 0, 0, .1);
            display: flex;
            justify-content: center;

            i {
                color: rgb(255, 0, 0);
            }
        }

        &:hover {
            border: 1px solid rgb(255, 0, 0);
            transform: scale(1.01);
            box-shadow: 0 8px 50px #23232333;

        }
    }

    #info-stats-card-see-questions {
        min-height: 150px;
        align-content: center;
        border-bottom: 2px solid rgb(0, 191, 255) !important;
        transition: box-shadow .3s ease, transform .2s ease;
        padding: 10px 0;

        h2 {
            padding-top: 6px;
        }

        .icon-container {
            padding: 6px;
            background-color: rgb(0, 191, 255, .1);
            display: flex;
            justify-content: center;

            i {
                color: rgb(0, 191, 255);
            }
        }

        &:hover {
            cursor: pointer;
            border: 1px solid rgb(0, 191, 255);
            transform: scale(1.01);
            box-shadow: 0 8px 50px #23232333;

        }
    }
}

.table-responses-title {
    display: flex;
    align-items: center;
    min-width: 340px;

    .icon-container {
        padding: 6px;
        background-color: rgba(26, 173, 36, .1);
        border: 1px solid rgba(26, 173, 36);

        i {
            color: rgb(26, 173, 36);
        }
    }
}

.table-user-responses-row {
    &:hover {
        cursor: pointer;
    }
}

.table-postponed-title {
    display: flex;
    align-items: center;
    min-width: 340px;

    .icon-container {
        padding: 6px;
        background-color: rgba(255, 215, 0, .1);
        border: 1px solid rgb(255, 215, 0);

        i {
            color: rgb(255, 215, 0);
        }
    }
}

.table-clients-not-responses-title {
    display: flex;
    align-items: center;
    min-width: 340px;

    .icon-container {
        padding: 6px;
        background-color: rgba(255, 0, 0, .1);
        border: 1px solid rgb(255, 0, 0);

        i {
            color: rgb(255, 0, 0);
        }
    }
}

.question-stat-container {
    max-width: 1250px;
    min-width: 1250px;
    padding: 12px;
    border-radius: 10px;

    .chart-button {
        margin-bottom: -43px;
        z-index: 1;
        border-radius: 0;
    }

    &:hover {
        box-shadow: 0 0 4px rgba(0, 0, 0, .3);
    }
}

#client-response-postponed {
    animation: rotatePostponed 5s infinite;
}

.form-preview-button {
    background-color: gold !important;
    border: 2px solid gold !important;
    color: black !important;
    min-width: 150px !important;

    &:hover {
        background-color: rgba(0, 0, 0, .06) !important;
    }
}

.postponed_qnt_badge {
    .v-badge__wrapper {
        .v-badge__badge {
            max-height: 18px;
            min-width: 18px;
            font-size: 10px;
        }
    }
}
.toolbar-info-data-dates {
    display: flex;
    justify-content: flex-end;
    background-color: rgba(0, 0, 0, .2) !important;
}

@media only screen and (max-width: 768px) {
    .toolbar-info-data-dates {
        height: 35px !important;

        > .v-toolbar__content {
            height: 35px !important;
        }
    }

    .table-clients-toolbar {
        height: 95px !important;
        .v-toolbar__content{
            height: 95px !important;
            display: flex;
            flex-direction: column;

            .table-responses-title, .table-postponed-title, .table-clients-not-responses-title{
                padding-top: 6px;
            }
            .v-input{
                padding-top: 6px;
            }

            .spacer{
                display: none;
            }

            .table-clients-toolbar-ordenation {
                display: none;
            }
        }


    }

    .question-stat-container {
        max-width: 100%;
        min-width: 100%;
    }
}

@keyframes rotatePostponed {
    50% {
        transform: rotate(360deg);
    }
}

@keyframes rotBGimg {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>
<template>

    <v-container fluid v-if="form && !loading">
        <v-row>
            <!-- INFO WHAT FORM IS EDITING -->
            <v-col cols="12" md="8"
                   style="display: flex; flex-direction: column; justify-content: center;border-bottom: 1px solid rgba(0,0,0,.05);">
                <p class="mb-0 pt-2" style="font-weight: bold; font-size: 16px">
                    <!--                    <span style="color: rgba(0,0,0,.5)">{{ form.platform }}</span>/<span-->
                    <!--                        style="color: rgba(0,0,0,.3)">{{ form.page }}</span>-->
                </p>
                <h2>
                    {{ form.name }}<span style="font-weight: normal"> - Estatistica</span>
                </h2>
                <p class="mb-0" style="font-size: 12px"> De <span
                        style="color: green;font-weight: bold">{{ form.startDate.substring(0, 10) }}</span> até <span
                        style="color: red;font-weight: bold">{{ form.endDate.substring(0, 10) }}</span></p>
            </v-col>
            <v-col cols="12" md="4"
                   style="display: flex; justify-content: center; align-items:center;border-bottom: 1px solid rgba(0,0,0,.05);">
                <v-spacer></v-spacer>
                <v-btn class="form-preview-button mr-2" style="max-width: 100px"
                       tile small target="_blank" :href="'https://b2b.bicimax.com/forms-preview/' + formId">
                    <v-icon size="20" class="pr-2">mdi-eye</v-icon>
                    VISUALIZAR
                </v-btn>
            </v-col>
        </v-row>

        <v-row class="form-main-infos">
            <!-- CARDS WITH MAIN STATS (NUM RESPONSES/ NUM ADIAMENTOS / % DE INQUERITOS RESPONDIDOS) -->
            <!-- TOTAL RESPOSTAS -->
            <v-col cols="12" md="2">
                <v-card id="info-stats-card-answered"
                        :style="[showUserResponses ? {'border': '1px solid rgba(26, 173, 36)'} : '']"
                        @click="showResponses()">
                    <v-row class="ma-0">
                        <v-col style="display: flex">
                            <div class="icon-container">
                                <v-icon size="24">
                                    mdi-check
                                </v-icon>
                            </div>
                            <h2 class="pl-2" style="width: fit-content; display: flex; align-items: center;">
                                <span style="min-width: 70px">{{ form.totalResponses }}</span>
                                <v-divider vertical class="pl-2"></v-divider>
                                <span class="pl-2" style="font-size: 15px;margin-top: 10px;"><span
                                        style="color: #1AAD24FF">{{
                                        ((form.totalResponses * 100) / form.totalUsers).toFixed(1)
                                    }}</span>% users</span>
                            </h2>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col class="pb-0 pt-0">
                            <p class="mb-0" style="font-size:12px;width: fit-content">TOTAL RESPOSTAS</p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <!-- TOTAL AGUARDAR RESPOSTA(POR RESPONDER) -->
            <v-col cols="12" md="2">
                <v-card id="info-stats-card-to-answer"
                        :style="[showUserNotResponse ? {'border': '1px solid rgba(255, 0, 0)'} : '']"
                        @click="showNotResponse">
                    <v-row class="ma-0">
                        <v-col style="display: flex">
                            <div class="icon-container">
                                <v-icon size="24">
                                    mdi-close
                                </v-icon>
                            </div>
                            <h2 class="pl-2" style="width: fit-content">
                                <!--                                {{ unansweredForms }}-->
                                <!--                                (total users que ainda não responderam/adiaram)-->
                                <span style="min-width: 70px">{{ unansweredForms }}</span>
                                <v-divider vertical class="pl-2"></v-divider>
                                <span class="pl-2" style="font-size: 15px;margin-top: 10px;"><span
                                        style="color: #FF0000FF">{{
                                        ((unansweredForms * 100) / form.totalUsers).toFixed(1)
                                    }}</span>% users</span>
                            </h2>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col class="pb-0 pt-0">
                            <p class="mb-0" style="font-size:12px;width: fit-content">TOTAL POR RESPONDER</p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <!-- TOTAL ADIADOS -->
            <v-col cols="12" md="2">
                <v-card id="info-stats-card-postponed"
                        :style="[showUserPostponed ? {'border': '1px solid rgb(255, 215, 0)'} : '']"
                        @click="showPostponed()">
                    <v-row class="ma-0">
                        <v-col style="display: flex">
                            <div class="icon-container">
                                <v-icon size="24">
                                    mdi-account-clock
                                </v-icon>
                            </div>
                            <h2 class="pl-2" style="width: fit-content">
                                <!-- totalPostponed -->
                                {{ form.totalPostponed }}
                            </h2>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col class="pb-0 pt-0">
                            <p class="mb-0" style="font-size:12px;width: fit-content">TOTAL ADIADOS</p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <!-- VER QUESTÕES -->
            <v-col cols="12" md="2">
                <v-card id="info-stats-card-see-questions"
                        :style="[showFormQuestions ? {'border': '1px solid rgb(0, 191, 255)'} : '']"
                        :disabled="form.totalResponses == 0"
                        @click="showFormQuestionsStats">
                    <v-row class="ma-0">
                        <v-col style="display: flex">
                            <div class="icon-container">
                                <v-icon size="24">
                                    mdi-help
                                </v-icon>
                            </div>
                            <h2 class="pl-2" style="width: fit-content">
                                <!-- totalPostponed -->
                                Questões
                            </h2>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col class="pb-0 pt-0">
                            <p class="mb-0" style="font-size:12px;width: fit-content">Ver todas as questões</p>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <!-- Média de respostas por dia -->
            <v-col cols="12" md="4">
                <v-card>
                    <GChart
                            :settings="{ packages: ['corechart', 'table', 'map'] }"
                            class="question-chart"
                            id="responses-per-day-chart"
                            style="max-width: 100%"
                            type="LineChart"
                            :data="numResponsesPerDay"
                            :options="chartOptionsMedium"
                    />
                    <!--                    {{numResponsesPerDay}}-->
                </v-card>
            </v-col>

        </v-row>

        <!-- TABLE WITH USERS ANSWERED  -->
        <v-row v-if="showUserResponses">
            <v-col>
                <v-data-iterator
                        v-if="usersResponses != null"
                        :items="usersResponses"
                        :items-per-page.sync="itemsPerPage"
                        :page.sync="page"
                        :search="search"
                        :sort-by="sortBy.toLowerCase()"
                        :sort-desc="sortDesc"
                        hide-default-footer
                        class="mb-4"
                >
                    <template v-slot:header>
                        <v-toolbar
                                class="table-clients-toolbar"
                                dense
                                dark
                                color=" darken-3"
                                style="background-color: rgba(0, 0, 0, .5);"
                        >
                            <div class="table-responses-title">
                                <div class="icon-container mr-2">
                                    <v-icon size="24">
                                        mdi-check
                                    </v-icon>
                                </div>
                                <h3 class="mr-3">Lista de respostas</h3>
                            </div>
                            <v-spacer></v-spacer>
                            <v-text-field
                                    v-model="search"
                                    clearable
                                    flat
                                    solo-inverted
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    label="Procurar"
                                    dense
                            ></v-text-field>
                            <template v-if="$vuetify.breakpoint.mdAndUp" class="table-clients-toolbar-ordenation">
                                <v-spacer></v-spacer>
                                <v-select
                                        v-model="sortBy"
                                        flat
                                        solo-inverted
                                        hide-details
                                        :items="orderKeys"
                                        prepend-inner-icon="mdi-sort"
                                        label="Ordenar por"
                                        dense
                                        style="max-width: 200px;"
                                        class="mr-4"
                                ></v-select>
                                <!--                                <v-spacer></v-spacer>-->
                                <v-btn-toggle
                                        v-model="sortDesc"
                                        mandatory
                                        dense
                                >
                                    <v-btn
                                            depressed
                                            color="green"
                                            :value="false"
                                    >
                                        <v-icon>mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn
                                            depressed
                                            color="red"
                                            :value="true"
                                    >
                                        <v-icon>mdi-arrow-down</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </template>
                        </v-toolbar>
                        <v-toolbar min-height="20" dense height="20" class="mt-0 toolbar-info-data-dates"
                                   color=" darken-3">
                            <span style="font-size: 11px; color: #cf0a10">Os dados exibidos correspondem ao intervalo entre as datas <strong>{{
                                    yearAgoDate
                                }}</strong> e <strong>{{ currentDate }}.</strong></span>
                        </v-toolbar>
                    </template>

                    <template v-slot:default="props">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-center" :class="{ 'red--text': sortBy === 'entity' }" width="8%">
                                        Entidade
                                    </th>
                                    <th class="text-left" :class="{ 'red--text': sortBy === 'name' }" width="40%">
                                        Name
                                    </th>
                                    <th class="text-left" :class="{ 'red--text': sortBy === 'email' }">
                                        Email
                                    </th>
                                    <th class="text-center" :class="{ 'red--text': sortBy === 'totalEncomendas' }">
                                        Encomendas
                                    </th>
                                    <th class="text-center" :class="{ 'red--text': sortBy === 'totalFox' }">
                                        Serviços Fox
                                    </th>
                                    <th class="text-center" :class="{ 'red--text': sortBy === 'totalLefty' }">
                                        Serviços Lefty
                                    </th>
                                    <th class="text-center" :class="{ 'red--text': sortBy === 'totalBicimax' }">
                                        Serviços Bicimax
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        class="table-user-responses-row"
                                        v-for="item in props.items"
                                        v-if="item['entity'] != 0"
                                        @click="openUserFormResponses(item)"
                                >
                                    <td
                                            v-for="(key, index) in filteredKeys"
                                            :style="[key =='totalEncomendas' || key=='entity' || key=='totalFox' || key=='totalLefty' || key=='totalBicimax' ? {'text-align': 'center'} : '']"
                                            :key="index">
                                        {{ item[key] }}
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td style="text-align: center" colspan="7">
                                        <span style="font-weight: bold">{{ props.items[0].email }}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>

                    <template v-slot:footer>
                        <v-row
                                class="mt-1 mr-0 ml-0 pr-1"
                                align="center"
                                justify="center"
                        >
                            <v-col cols="12" style="display: flex; align-items: center; justify-content: space-between;" class="pt-0 pb-0">
                                <div class="list-page-length">
                                    <span class="grey--text" style="font-size: 12px">Clientes por página:</span>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                    dark
                                                    text
                                                    small
                                                    color="black"
                                                    v-bind="attrs"
                                                    v-on="on"
                                            >
                                                {{ itemsPerPage }}
                                                <v-icon>mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                    v-for="(number, index) in itemsPerPageArray"
                                                    :key="index"
                                                    @click="updateItemsPerPage('responded',number)"
                                            >
                                                <v-list-item-title>{{ number }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </div>

                                <div class="list-page-number">

                                    <span
                                            class="mr-4 grey--text"
                                            style="font-size: 12px"
                                    >
                                    Página {{ page }} de {{ numberOfPages }}
                                  </span>
                                    <v-btn
                                            fab
                                            x-small
                                            dark
                                            color="black darken-3"
                                            class="mr-1"
                                            @click="formerPage"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-btn
                                            fab
                                            dark
                                            x-small
                                            color="black darken-3"
                                            class="ml-1"
                                            @click="nextPage"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-col>
        </v-row>

        <!-- TABLE WITH LIST OF CLIENTS THAT RESPOND OR POSTPONED THE FORM -->
        <v-row v-if="showUserPostponed">
            <v-col>
                <v-data-iterator
                        :items="usersPostponed"
                        :items-per-page.sync="itemsPerPageAdiado"
                        :page.sync="pageAdiado"
                        :search="searchAdiado"
                        hide-default-footer
                        class="mb-4"
                >
                    <template v-slot:header>
                        <v-toolbar
                                dense
                                dark
                                color="darken-3"
                                class="mb-1 table-clients-toolbar"
                                style="background-color: rgba(0, 0, 0, .5);"
                        >
                            <div class="table-postponed-title">
                                <div class="icon-container mr-2">
                                    <v-icon size="24">
                                        mdi-account-clock
                                    </v-icon>
                                </div>
                                <h3 class="mr-3">Lista de adiados</h3>
                            </div>
                            <v-spacer></v-spacer>
                            <v-text-field
                                    v-model="searchAdiado"
                                    clearable
                                    flat
                                    solo-inverted
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    label="Procurar"
                                    dense
                            ></v-text-field>
                        </v-toolbar>
                    </template>

                    <template v-slot:default="props">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-center"
                                        width="8%">
                                        Entidade
                                    </th>
                                    <th class="text-left" width="40%">
                                        Name
                                    </th>
                                    <th class="text-left">
                                        Email
                                    </th>
                                    <th class="text-center">
                                        Data
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="item in props.items"
                                        v-if="usersPostponed[0].entity != 0"
                                >
                                    <td v-for="(key, index) in filteredKeysAdiados"
                                        :style="[key =='data' || key=='entity' ? {'text-align': 'center'} : '']"
                                        :key="index">

                                        <span :style="[key == 'data' && ((item[key].substring(0, 10) + ' ' + item[key].substring(11, 19)) > oneDayLessDate) ? {'color': 'tomato'} : '']">
                                            {{
                                                key == 'data' ? (item[key].substring(0, 10) + ' ' + item[key].substring(11, 19)) : item[key]
                                            }}
                                        </span>
                                        <v-badge v-bind="attrs" v-on="on" inline v-if="key == 'data'"
                                                 :content="item['vezes_adiado']+'x'"
                                                 :color="item['vezes_adiado'] == 1 ? 'success' : item['vezes_adiado'] > 1 && item['vezes_adiado'] < 4 ? 'warning' : 'error'">
                                        </v-badge>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td style="text-align: center" colspan="7">
                                        <span style="font-weight: bold">{{ props.items[0].email }}</span>
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>

                    <template v-slot:footer>
                        <v-row
                                class="mt-1"
                                align="center"
                                justify="center"
                        >
                            <v-col style="display: flex; align-items: center" class="pt-0 pb-0">
                                <span class="grey--text" style="font-size: 12px">clientes por página:</span>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                dark
                                                text
                                                small
                                                color="black"
                                                v-bind="attrs"
                                                v-on="on"
                                        >
                                            {{ itemsPerPageAdiado }}
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                                v-for="(number, index) in itemsPerPageArray"
                                                :key="index"
                                                @click="updateItemsPerPage('postponed', number)"
                                        >
                                            <v-list-item-title>{{ number }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-spacer></v-spacer>

                                <span
                                        class="mr-4 grey--text"
                                        style="font-size: 12px"
                                >
                                Página {{ pageAdiado }} de {{ numberOfPagesAdiados }}
                              </span>
                                <v-btn
                                        fab
                                        x-small
                                        dark
                                        color="black darken-3"
                                        class="mr-1"
                                        @click="formerPageAdiado"
                                >
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-btn
                                        fab
                                        dark
                                        x-small
                                        color="black darken-3"
                                        class="ml-1"
                                        @click="nextPageAdiado"
                                >
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-col>
        </v-row>

        <!-- TABLE WITH USERS THAT NOT RESPOND -->
        <v-row v-if="showUserNotResponse">
            <v-col>
                <v-data-iterator
                        :items="usersNotResponses"
                        :items-per-page.sync="itemsPerPageNotResponse"
                        :page.sync="pageNotResponse"
                        :search="searchNotResponse"
                        :sort-by="sortByNotResponse.toLowerCase()"
                        :sort-desc="sortDescNotResponse"
                        hide-default-footer
                        class="mb-4"
                >
                    <template v-slot:header>
                        <v-toolbar
                                dense
                                dark
                                class="table-clients-toolbar"
                                color=" darken-3"
                                style="background-color: rgba(0, 0, 0, .5);"
                        >
                            <div class="table-clients-not-responses-title">
                                <div class="icon-container mr-2">
                                    <v-icon size="24">
                                        mdi-close
                                    </v-icon>
                                </div>
                                <h3 class="mr-3">Lista de clientes por responder</h3>
                            </div>
                            <v-spacer></v-spacer>
                            <v-text-field
                                    v-model="searchNotResponse"
                                    clearable
                                    flat
                                    solo-inverted
                                    hide-details
                                    prepend-inner-icon="mdi-magnify"
                                    label="Procurar"
                                    dense
                            ></v-text-field>
                            <template v-if="$vuetify.breakpoint.mdAndUp">
                                <v-spacer></v-spacer>
                                <v-select
                                        v-model="sortByNotResponse"
                                        flat
                                        solo-inverted
                                        hide-details
                                        :items="orderKeys"
                                        prepend-inner-icon="mdi-sort"
                                        label="Ordenar por"
                                        dense
                                        style="max-width: 200px;"
                                        class="mr-4"
                                ></v-select>
                                <!--                                <v-spacer></v-spacer>-->
                                <v-btn-toggle
                                        v-model="sortDescNotResponse"
                                        mandatory
                                        dense
                                >
                                    <v-btn
                                            depressed
                                            color="green"
                                            :value="false"
                                    >
                                        <v-icon>mdi-arrow-up</v-icon>
                                    </v-btn>
                                    <v-btn
                                            depressed
                                            color="red"
                                            :value="true"
                                    >
                                        <v-icon>mdi-arrow-down</v-icon>
                                    </v-btn>
                                </v-btn-toggle>
                            </template>
                        </v-toolbar>
                        <v-toolbar min-height="20" dense height="20" class="mt-0 toolbar-info-data-dates"
                                   color=" darken-3">
                            <span style="font-size: 11px; color: #cf0a10">Os dados exibidos correspondem ao intervalo entre as datas <strong>{{
                                    yearAgoDate
                                }}</strong> e <strong>{{ currentDate }}.</strong></span>
                        </v-toolbar>
                    </template>

                    <template v-slot:default="props">
                        <v-simple-table dense>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-center" :class="{ 'red--text': sortByNotResponse === 'entity' }"
                                        width="8%">
                                        Entidade
                                    </th>
                                    <th class="text-left" :class="{ 'red--text': sortByNotResponse === 'name' }"
                                        width="40%">
                                        Name
                                    </th>
                                    <th class="text-left" :class="{ 'red--text': sortByNotResponse === 'email' }"
                                        width="20%">
                                        Email
                                    </th>
                                    <th class="text-center"
                                        :class="{ 'red--text': sortByNotResponse === 'totalEncomendas' }">
                                        Encomendas
                                    </th>
                                    <th class="text-center" :class="{ 'red--text': sortByNotResponse === 'totalFox' }">
                                        Serviços Fox
                                    </th>
                                    <th class="text-center"
                                        :class="{ 'red--text': sortByNotResponse === 'totalLefty' }">
                                        Serviços Lefty
                                    </th>
                                    <th class="text-center"
                                        :class="{ 'red--text': sortByNotResponse === 'totalBicimax' }">
                                        Serviços Bicimax
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="item in props.items"

                                >
                                    <td v-for="(key, index) in filteredKeysNotResponse"
                                        :style="[key =='totalEncomendas' || key=='entity' || key=='totalFox' || key=='totalLefty' || key=='totalBicimax' ? {'text-align': 'center'} : '']"
                                        :key="index">
                                        {{ item[key] }}
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>

                    <template v-slot:footer>
                        <v-row
                                class="mt-1"
                                align="center"
                                justify="center"
                        >
                            <v-col style="display: flex; align-items: center" class="pt-0 pb-0">
                                <span class="grey--text" style="font-size: 12px">clientes por página:</span>
                                <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                dark
                                                text
                                                small
                                                color="black"
                                                v-bind="attrs"
                                                v-on="on"
                                        >
                                            {{ itemsPerPageNotResponse }}
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                                v-for="(number, index) in itemsPerPageArray"
                                                :key="index"
                                                @click="updateItemsPerPage('noResponse', number)"
                                        >
                                            <v-list-item-title>{{ number }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                                <v-spacer></v-spacer>

                                <span
                                        class="mr-4 grey--text"
                                        style="font-size: 12px"
                                >
                                Página {{ pageNotResponse }} de {{ numberOfPagesNotResponse }}
                              </span>
                                <v-btn
                                        fab
                                        x-small
                                        dark
                                        color="black darken-3"
                                        class="mr-1"
                                        @click="formerPageNotResponse"
                                >
                                    <v-icon>mdi-chevron-left</v-icon>
                                </v-btn>
                                <v-btn
                                        fab
                                        dark
                                        x-small
                                        color="black darken-3"
                                        class="ml-1"
                                        @click="nextPageNotResponse"
                                >
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-col>
        </v-row>

        <!-- ALL QUESTIONS AND GRAPHS -->
        <v-row v-if="showFormQuestions">
            <v-col cols="12" v-for="question in formQuestions" :key="question.id"
                   style="display: flex;justify-content: center">

                <div class="question-stat-container"
                     v-if="(question.typeOfQuestion == 'radio' || question.typeOfQuestion == 'select' || question.typeOfQuestion == 'checkbox') && question.tableGroupId == null">

                    <h4 style="max-width: fit-content">{{ question.stepOrder }}.{{ question.orderOnStep }} -
                        {{ question.header }}</h4>
                    <v-btn class="chart-button" tile raised x-small color="yellow" @click="question.typeChart = 'bar'">
                        Barras
                    </v-btn>
                    <v-btn class="chart-button" tile raised x-small color="blue"
                           @click="question.typeChart = 'circular'">Circular
                    </v-btn>
                    <v-btn class="chart-button" tile raised x-small color="green" @click="question.typeChart = 'line'">
                        Linear
                    </v-btn>
                    <v-btn class="chart-button" tile raised x-small color="orange" @click="question.typeChart = 'area'">
                        Area
                    </v-btn>

                    <!-- ColumnChart / PieChart / LineChart / AreaChart -->
                    <GChart
                            class="question-chart"
                            v-if="question.chart != ''"
                            :type="question.typeChart == 'bar' ? 'ColumnChart' : question.typeChart == 'circular' ? 'PieChart' : question.typeChart == 'line' ? 'LineChart' : 'AreaChart'"
                            :data="question.chart"
                            :options="chartOptions"
                    />
                </div>
                <div class="question-stat-container" v-if="question.typeOfQuestion == 'group-radio'">
                    <!-- GROUP -->
                    <h4 style="max-width: fit-content" class="pb-2">{{ question.stepOrder }}.{{ question.orderOnStep }}
                        - {{ question.tableGroupHeader[0].header }}</h4>
                    <GChart
                            class="question-chart"
                            v-if="question.chart != ''"
                            type="ColumnChart"
                            :data="question.chart"
                            :options="chartOptions"
                    />
                </div>
                <div class="question-stat-container"
                     v-if="(question.typeOfQuestion == 'text-field' || question.typeOfQuestion == 'text-area') && question.responsesList">
                    <h4 style="max-width: fit-content">{{ question.stepOrder }}.{{ question.orderOnStep }} -
                        {{ question.header }}</h4>

                    <!-- LISTA DE RESPOSTAS -->
                    <v-simple-table dense>
                        <thead style="background-color: black">
                        <tr>
                            <th style="color: white">{{ question.responsesList.length }} Respostas</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="response in (question.responsesList)" v-if="question.responsesList.length > 0">
                            <td style="display: flex; align-items: center;height: 100%">
                                <span style="font-size: 12px">{{ response.response }}</span>
                                <v-spacer/>
                                <v-tooltip
                                        left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                        >
                                            <v-icon color="info lighten-1">
                                                mdi-help-circle
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>email: <span style="font-weight: bold">{{ response.email }}</span></span>
                                </v-tooltip>
                            </td>
                        </tr>
                        <tr v-else>
                            <td>
                                Ainda sem respostas
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>


                </div>

            </v-col>
        </v-row>

        <!-- MODAL WITH USER FORM RESPONSES -->
        <v-dialog
                v-model="showUserResponsesModal"
                persistent
                max-width="75%"
        >
            <!-- COMPONENT TO USER RESPONSES-->
            <FormUserResponses :user="selectedUserResponses" :formID="formId"
                               v-on:close-modal="closeModalUserResponse"></FormUserResponses>
        </v-dialog>

    </v-container>

</template>
<script>
import axios from "axios";
import FormUserResponses from "@/components/Formularios/FormUserResponses.vue";

//Get form stats by id of form (responses + postpone)
export default {
    components: {
        FormUserResponses
    },
    data: function () {
        return {
            loading: true,
            formId: null,
            form: {},
            totalsServices: {},
            showUserResponses: true,
            showUserPostponed: false,
            showUserNotResponse: false,
            showFormQuestions: false,
            usersResponses: [],
            usersPostponed: [],
            usersNotResponses: [],
            formQuestions: [],
            itemsPerPageArray: [50, 100, 200],
            search: '',
            searchAdiado: '',
            searchNotResponse: '',
            filter: {},
            filterAdiado: {},
            filterNotResponse: {},
            sortDesc: false,
            sortDescAdiado: true,
            sortDescNotResponse: false,
            page: 1,
            itemsPerPage: 50,
            pageAdiado: 1,
            itemsPerPageAdiado: 50,
            pageNotResponse: 1,
            itemsPerPageNotResponse: 50,
            sortBy: 'entity',
            sortByAdiado: 'data',
            sortByNotResponse: 'entity',
            oneDayLessDate: new Date(Date.now() - 86400000).toISOString().substring(0, 10) + ' ' + new Date(Date.now() - 86400000).toISOString().substring(11, 19),
            keys: [
                'entity',
                'name',
                'email',
                'totalEncomendas',
                'totalFox',
                'totalLefty',
                'totalBicimax'
            ],
            orderKeys: [
                'entity',
                'name',
                'email',
            ],
            keysAdiado: [
                'entity',
                'name',
                'email',
                'data'
            ],
            keysNotResponse: [
                'entity',
                'name',
                'email',
                'totalEncomendas',
                'totalFox',
                'totalLefty',
                'totalBicimax'
            ],
            orderKeysNotResponse: [
                'entity',
                'name',
                'email',
            ],
            chartOptions: {},
            chartOptionsMedium: {
                'title': 'Respostas por dia',
                'height': 150
            },
            showUserResponsesModal: false,
            selectedUserResponses: {},
            numResponsesPerDay: []
        }
    },
    mounted() {
        this.formId = this.$route.params.id;
        this.getFormStats();
    },
    created() {
        document.title = 'Questionario ' + this.$route.params.id + ' - Estatistica';
    },
    computed: {
        numberOfPages() {
            return Math.ceil(this.usersResponses.length / this.itemsPerPage)
        },
        filteredKeys() {
            return this.keys.filter(key => key !== 'Name')
        },

        numberOfPagesAdiados() {
            return Math.ceil(this.usersPostponed.length / this.itemsPerPage)
        },
        filteredKeysAdiados() {
            return this.keysAdiado.filter(key => key !== 'Name')
        },

        numberOfPagesNotResponse() {
            return Math.ceil(this.usersNotResponses.length / this.itemsPerPage)
        },
        filteredKeysNotResponse() {
            return this.keysNotResponse.filter(key => key !== 'Name')
        },

        currentDate() {
            const year = new Date().getFullYear();
            const month = String(new Date().getMonth() + 1).padStart(2, '0');
            const day = String(new Date().getDate()).padStart(2, '0');
            return year + '/' + month + '/' + day;
        },
        yearAgoDate() {
            const auxDate = new Date();
            auxDate.setFullYear(auxDate.getFullYear() - 1);
            const year = auxDate.getFullYear();
            const month = String(auxDate.getMonth() + 1).padStart(2, '0');
            const day = String(auxDate.getDate()).padStart(2, '0');
            return year + '/' + month + '/' + day;
        },

        unansweredForms() {
            return parseInt(this.form.totalUsers) - (parseInt(this.form.totalResponses))
        }
    },
    methods: {
        getFormStats() {
            let loader = this.$loading.show();

            axios.get(api.formStats(this.formId))
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {

                            this.form = responseData.data[0]
                            this.totalsServices = responseData.totals;
                            this.usersResponses = JSON.parse(this.form.userResponses);
                            this.usersPostponed = JSON.parse(this.form.userPostponed);
                            this.usersNotResponses = JSON.parse(this.form.userNotResponses);
                            this.formQuestions = JSON.parse(this.form.formQuestions);

                            //Save num responses -> and pass array of objects to array of arrays
                            this.numResponsesPerDay = JSON.parse(this.form.responsesPerDay);
                            this.numResponsesPerDay = this.numResponsesPerDay.map(obj => Object.values(obj));
                            //put headers on first position
                            let headers = ['Dia', 'Respostas'];
                            this.numResponsesPerDay.unshift(headers);


                            //Fill object of usersResponses with respective totals
                            if (this.usersResponses != null) {
                                this.fillWithTotals(this.usersResponses, this.totalsServices);
                            } else {
                                //No responses
                                this.usersResponses = [];
                                this.usersResponses.push({
                                    "email": "Sem respostas",
                                    "entity": "0",
                                    "name": "empty",
                                    "totalEncomendas": 0,
                                    "totalFox": 0,
                                    "totalLefty": 0,
                                    "totalBicimax": 0
                                })
                            }

                            if (this.usersPostponed == null) {
                                //No postponed
                                this.usersPostponed = [];
                                this.usersPostponed.push({
                                    "name": "empty",
                                    "entity": "0",
                                    "email": "Sem adiados",
                                    "data": "empty"
                                })
                            }

                            this.fillWithTotals(this.usersNotResponses, this.totalsServices);


                            this.loading = false;
                        }
                        loader.hide()
                    })
                    .catch(response => {
                        loader.hide()
                    })
        },
        fillWithTotals(arrayToFill, arrayTotals) {
            //Por cada user
            for (let user of arrayToFill) {
                user.totalFox = 0
                user.totalLefty = 0
                user.totalBicimax = 0

                //percorremos array com todos os totais e verficamos email
                for (let userSC of arrayTotals) {
                    if (user.email == userSC.email) {
                        user.totalFox = userSC.totalFox
                        user.totalLefty = userSC.totalLefty
                        user.totalBicimax = userSC.totalBicimax
                    }
                }
            }
        },
        nextPage() {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage() {
            if (this.page - 1 >= 1) this.page -= 1
        },
        updateItemsPerPage(table, number) {
            if (table == 'responded') {
                this.itemsPerPage = number
            } else if (table == 'postponed') {
                this.itemsPerPageAdiado = number
            } else {
                this.itemsPerPageNotResponse = number
            }
        },
        nextPageAdiado() {
            if (this.pageAdiado + 1 <= this.numberOfPagesAdiados) this.pageAdiado += 1
        },
        formerPageAdiado() {
            if (this.pageAdiado - 1 >= 1) this.pageAdiado -= 1
        },

        nextPageNotResponse() {
            if (this.pageNotResponse + 1 <= this.numberOfPagesNotResponse) this.pageNotResponse += 1
        },
        formerPageNotResponse() {
            if (this.pageNotResponse - 1 >= 1) this.pageNotResponse -= 1
        },

        showResponses() {
            this.showUserPostponed = false;
            this.showUserNotResponse = false;
            this.showFormQuestions = false;
            this.showUserResponses = true;
        },
        showPostponed() {
            this.showUserResponses = false;
            this.showUserNotResponse = false;
            this.showFormQuestions = false;
            this.showUserPostponed = true;
        },
        showNotResponse() {
            this.showUserResponses = false;
            this.showUserPostponed = false;
            this.showFormQuestions = false;
            this.showUserNotResponse = true;
        },
        showFormQuestionsStats() {
            this.showUserResponses = false;
            this.showUserPostponed = false;
            this.showUserNotResponse = false;
            this.showFormQuestions = true;
        },

        //Function to open the modal with user formResponses
        openUserFormResponses(user) {
            this.showUserResponsesModal = true;
            this.selectedUserResponses = user;
        },
        closeModalUserResponse() {
            this.showUserResponsesModal = false;
            this.selectedUserResponses = {};
        }
    }
}

</script>