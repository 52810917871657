<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title :title="$t('sales')"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  @click="print">Imprimir</v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <template v-if="loading">
            <v-row class="mt-12">
                <v-col class="text-center">
                    <i class="fas fa-cog fa-spin fa-3x"></i>
                </v-col>
            </v-row>
        </template>

        <template v-if="!loading">
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <v-simple-table class="staff_table">
                                <thead>
                                <tr>
                                    <th>Marcas</th>
                                    <th>Famílias</th>
                                    <th>Países</th>
                                    <th>Vendedores</th>
                                    <th>{{ $t('sections') }}</th>
                                    <th>Zona Comercial</th>
                                    <th>Clientes</th>
                                    <th>{{ $t('products') }}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <v-autocomplete
                                                v-model="filters.brands"
                                                outlined
                                                hide-details
                                                :items="brands"
                                                item-text="Marca"
                                                item-value="Marca"
                                                multiple
                                                class="staff_select"
                                                dense
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                                v-model="filters.families"
                                                outlined
                                                hide-details
                                                :items="families"
                                                item-value="Familia"
                                                item-text="Descricao"
                                                multiple
                                                class="staff_select"
                                                dense
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                                v-model="filters.countries"
                                                outlined
                                                hide-details
                                                :items="countries"
                                                item-value="Pais"
                                                item-text="Descricao"
                                                multiple
                                                class="staff_select"
                                                dense
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                                v-model="filters.sellers"
                                                outlined
                                                hide-details
                                                :items="sellers"
                                                item-value="Vendedor"
                                                item-text="Nome"
                                                multiple
                                                class="staff_select"
                                                dense
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                                v-model="filters.sections"
                                                outlined
                                                hide-details
                                                :items="seccoes"
                                                item-value="Seccao"
                                                item-text="Nome"
                                                multiple
                                                class="staff_select"
                                                dense
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-autocomplete
                                                v-model="filters.zonaComercial"
                                                outlined
                                                hide-details
                                                :items="zonasComerciais"
                                                item-text="CDU_Descricao"
                                                item-value="CDU_Id"
                                                multiple
                                                class="staff_select"
                                                dense
                                        ></v-autocomplete>
                                    </td>
                                    <td>
                                        <v-btn tile @click="toggleDialogFilterClients">Selecionar</v-btn>
                                    </td>
                                    <td>
                                        <v-btn tile @click="toggleDialogFilterProducts">Selecionar</v-btn>
                                    </td>
                                    <td>
                                        <v-btn tile color="success" @click="getSales">Aplicar</v-btn>
                                    </td>
                                    <td>
                                        <v-btn tile color="error" @click="clearFilters">{{ $t('clearFilters') }}</v-btn>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                            <strong v-if="nameClientsSelected.length > 0" style="font-size: 11px">Clientes selecionados:
                                {{
                                    nameClientsSelected.join(', ')
                                }}</strong>
                            <strong v-if="nameProductsSelected.length > 0" style="font-size: 11px">{{ $t('products') }}
                                selecionados: {{
                                    nameProductsSelected.slice(0, 3).join(', ')
                                }}</strong>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <div id="printMe">
                <v-row>
                    <v-col>
                        <v-card>
                            <v-toolbar flat dense dark color="primary">
                                <v-toolbar-title class="text-uppercase">{{ $t('salesInValue') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <GChart
                                        type="ColumnChart"
                                        :data="chartData"
                                        :options="chartOptions"
                                />
                                <v-simple-table class="staff_table" style="border-collapse: collapse;">
                                    <thead>
                                    <tr>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            {{ $t('month') }}
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            {{ years[0] }}
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            %
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            {{ years[1] }}
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            %
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            {{ years[2] }}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="sale in salesValue">
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{ sale.label }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{ sale.label }}</td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{ $money(sale.valueYear1) }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{ $money(sale.valueYear1) }}</td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong v-if="sale.valueYear1 > 0">{{ ((sale.valueYear2 - sale.valueYear1) / sale.valueYear1 * 100).toFixed(2) }}%</strong>
                                            <strong v-if="sale.valueYear1 === 0">-</strong>
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            <span v-if="sale.valueYear1 > 0">{{ ((sale.valueYear2 - sale.valueYear1) / sale.valueYear1 * 100).toFixed(2) }}%</span>
                                            <span v-if="sale.valueYear1 === 0">-</span>
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{ $money(sale.valueYear2) }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{ $money(sale.valueYear2) }}
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{
                                                    ((sale.valueYear3 - sale.valueYear2) / sale.valueYear2 *
                                                            100).toFixed(2)
                                                }}%</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{
                                                ((sale.valueYear3 - sale.valueYear2) / sale.valueYear2 *
                                                        100).toFixed(2)
                                            }}%
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{ $money(sale.valueYear3) }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{ $money(sale.valueYear3) }}
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>TOTAL</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{ $money(totalValueSales[0]) }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{((totalValueSales[1] - totalValueSales[0]) / totalValueSales[0] * 100).toFixed(2)}}%</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{ $money(totalValueSales[1]) }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{
                                                    ((totalValueSales[2] - totalValueSales[1]) / totalValueSales[1]
                                                            * 100).toFixed(2)
                                                }}%</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{ $money(totalValueSales[2]) }}</strong></td>
                                    </tr>
                                    </tfoot>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-toolbar flat dense dark color="primary">
                                <v-toolbar-title class="text-uppercase">{{ $t('salesInQuantity') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <GChart
                                        type="ColumnChart"
                                        :data="chartDataQuantity"
                                        :options="chartOptions"
                                />
                                <v-simple-table class="staff_table" style="border-collapse: collapse;">
                                    <thead>
                                    <tr>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            {{ $t('month') }}
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            {{ years[0] }}
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            %
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            {{ years[1] }}
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            %
                                        </th>
                                        <th class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            {{ years[2] }}
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="sale in salesQuantity">
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{ sale.label }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{ sale.label }}
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{ sale.valueYear1 }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{ sale.valueYear1 }}
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong v-if="sale.valueYear1 > 0">{{ ((sale.valueYear2 - sale.valueYear1) / sale.valueYear1 * 100).toFixed(2) }}%</strong>
                                            <strong v-if="sale.valueYear1 === 0">-</strong>
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            <span v-if="sale.valueYear1 > 0">{{ ((sale.valueYear2 - sale.valueYear1) / sale.valueYear1 * 100).toFixed(2) }}%</span>
                                            <span v-if="sale.valueYear1 === 0">-</span>
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{ sale.valueYear2 }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{ sale.valueYear2 }}
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{
                                                    ((sale.valueYear3 - sale.valueYear2) / sale.valueYear2 *
                                                            100).toFixed(2)
                                                }}%</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{
                                                ((sale.valueYear3 - sale.valueYear2) / sale.valueYear2 *
                                                        100).toFixed(2)
                                            }}%
                                        </td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type === 'total'">
                                            <strong>{{ sale.valueYear3 }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px"
                                            v-if="sale.type !== 'total'">
                                            {{ sale.valueYear3 }}
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>TOTAL</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{ totalQuantitySales[0] }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{
                                                    ((totalQuantitySales[1] - totalQuantitySales[0]) /
                                                            totalQuantitySales[0] * 100).toFixed(2)
                                                }}%</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{ totalQuantitySales[1] }}</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{
                                                    ((totalQuantitySales[2] - totalQuantitySales[1]) /
                                                            totalQuantitySales[1] * 100).toFixed(2)
                                                }}%</strong></td>
                                        <td class="text-center pa-0"
                                            style="border: 1px solid #999; font-size: 11px; height: 28px">
                                            <strong>{{ totalQuantitySales[2] }}</strong></td>
                                    </tr>
                                    </tfoot>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>

            <v-row justify="center">
                <v-dialog v-model="dialogFilterClient" persistent max-width="1000">
                    <v-card>
                        <v-toolbar flat dense dark color="success" class="mb-4">
                            <v-toolbar-title class="text-uppercase">Clientes</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogFilterClient = false">
                                <v-icon dark>close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <template v-if="loadingClients">
                                <v-row class="mt-12">
                                    <v-col class="text-center">
                                        <i class="fas fa-cog fa-spin fa-3x"></i>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-if="!loadingClients">
                                <v-row>
                                    <v-col class="pt-0">
                                        Clientes selecionados: {{ totalClientsSelected }} de {{
                                            this.filteredClients.length
                                        }}/{{ this.allClients.length }}
                                    </v-col>
                                    <v-col class="pt-0 text-right">
                                        <v-btn color="error" x-small @click="clearFiltersClients">{{
                                                $t('clearFilters')
                                            }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-simple-table fixed-header class="staff_table" height="450px">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th @click="updateSortClients('Cliente', 'string')">
                                                    Cliente
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortClients.field==='Cliente' && !sortClients.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortClients.field==='Cliente' && sortClients.reverse"></i>
                                                </th>
                                                <th @click="updateSortClients('Nome', 'string')">
                                                    {{ $t('name') }}
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortClients.field==='Nome' && !sortClients.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortClients.field==='Nome' && sortClients.reverse"></i>
                                                </th>
                                                <th @click="updateSortClients('Pais', 'string')">
                                                    País
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortClients.field==='Pais' && !sortClients.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortClients.field==='Pais' && sortClients.reverse"></i>
                                                </th>
                                                <th @click="updateSortClients('NumContrib', 'string')">
                                                    NIF
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortClients.field==='NumContrib' && !sortClients.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortClients.field==='NumContrib' && sortClients.reverse"></i>
                                                </th>
                                                <th @click="updateSortClients('Vendedor', 'string')">
                                                    Vendedor
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortClients.field==='Vendedor' && !sortClients.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortClients.field==='Vendedor' && sortClients.reverse"></i>
                                                </th>
                                                <th @click="updateSortClients('CDU_PROFILE', 'number')">
                                                    Perfil
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortClients.field==='CDU_PROFILE' && !sortClients.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortClients.field==='CDU_PROFILE' && sortClients.reverse"></i>
                                                </th>
                                            </tr>
                                            <tr class="filters_row">
                                                <td>
                                                    <span style="cursor: pointer; margin-left: 2px">
                                                        <i v-if="!checkboxAll" class="far fa-square fa-lg"
                                                           @click="selectAll()"></i>
                                                        <i v-if="checkboxAll" class="fas fa-check-square fa-lg"
                                                           style="color:#1976d2" @click="unselectAll()"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersClients.client"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterClients"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersClients.name"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterClients"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersClients.country"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterClients"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersClients.nif"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterClients"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersClients.salesman"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterClients"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersClients.profile"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterClients"
                                                    ></v-text-field>
                                                </td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="client in filteredClients">
                                                <td>
                                                    <span style="cursor: pointer">
                                                        <i v-if="!client.selected" class="far fa-square fa-lg"
                                                           @click="selectClient(client, true)"></i>
                                                        <i v-if="client.selected" class="fas fa-check-square fa-lg"
                                                           style="color:#1976d2"
                                                           @click="selectClient(client, false)"></i>
                                                    </span>
                                                </td>
                                                <td>{{ client.Cliente }}</td>
                                                <td>{{ client.Nome }}</td>
                                                <td>
                                                    {{ client.Pais }}
                                                    <flag :iso="client.Pais" v-bind:squared=false></flag>
                                                </td>
                                                <td>{{ client.NumContrib }}</td>
                                                <td>{{ client.Vendedor }}</td>
                                                <td>{{ client.CDU_PROFILE }}</td>
                                            </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-pagination
                                                v-model="paginationClients.page"
                                                :length="getTotalPages"
                                                :total-visible="7"
                                                @input="filterClients"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="dialogFilterClient = false">Cancelar</v-btn>
                            <v-btn color="success" @click="setFilterClients()" style="margin-left: auto">
                                Selecionar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogFilterProduct" persistent max-width="1000">
                    <v-card>
                        <v-toolbar flat dense dark color="warning" class="mb-4">
                            <v-toolbar-title class="text-uppercase">{{ $t('products') }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="dialogFilterProduct = false">
                                <v-icon dark>close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <template v-if="loadingProducts">
                                <v-row class="mt-12">
                                    <v-col class="text-center">
                                        <i class="fas fa-cog fa-spin fa-3x"></i>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-if="!loadingProducts">
                                <v-row>
                                    <v-col class="pt-0">
                                        {{ $t('products') }} selecionados: {{ totalProductsSelected }} de {{
                                            this.filteredProducts.length
                                        }}/{{ this.allProducts.length }}
                                    </v-col>
                                    <v-col class="pt-0 text-right">
                                        <v-btn color="error" x-small @click="clearFiltersProducts">{{
                                                $t('clearFilters')
                                            }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <v-simple-table fixed-header class="staff_table" height="450px">
                                            <thead>
                                            <tr>
                                                <th></th>
                                                <th @click="updateSortProducts('Artigo', 'string')">
                                                    {{ $t('product') }}
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortProducts.field==='Artigo' && !sortProducts.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortProducts.field==='Artigo' && sortProducts.reverse"></i>
                                                </th>
                                                <th @click="updateSortProducts('Descricao', 'string')">
                                                    {{ $t('description') }}
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortProducts.field==='Descricao' && !sortProducts.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortProducts.field==='Descricao' && sortProducts.reverse"></i>
                                                </th>
                                                <th @click="updateSortProducts('Marca', 'string')">
                                                    Marca
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortProducts.field==='Marca' && !sortProducts.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortProducts.field==='Marca' && sortProducts.reverse"></i>
                                                </th>
                                                <th @click="updateSortProducts('Familia', 'string')">
                                                    Familia
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortProducts.field==='Familia' && !sortProducts.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortProducts.field==='Familia' && sortProducts.reverse"></i>
                                                </th>
                                                <th @click="updateSortProducts('SubFamilia', 'string')">
                                                    SubFamilia
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortProducts.field==='SubFamilia' && !sortProducts.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortProducts.field==='SubFamilia' && sortProducts.reverse"></i>
                                                </th>
                                                <th @click="updateSortProducts('STKActual', 'number')">
                                                    STKActual
                                                    <i class="fas fa-arrow-up"
                                                       v-if="sortProducts.field==='STKActual' && !sortProducts.reverse"></i>
                                                    <i class="fas fa-arrow-down"
                                                       v-if="sortProducts.field==='STKActual' && sortProducts.reverse"></i>
                                                </th>
                                            </tr>
                                            <tr class="filters_row">
                                                <td>
                                                    <span style="cursor: pointer; margin-left: 2px">
                                                        <i v-if="!checkboxAllProducts" class="far fa-square fa-lg"
                                                           @click="selectAllProducts()"></i>
                                                        <i v-if="checkboxAllProducts" class="fas fa-check-square fa-lg"
                                                           style="color:#1976d2" @click="unselectAllProducts()"></i>
                                                    </span>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersProducts.product"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterProducts"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersProducts.description"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterProducts"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersProducts.brand"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterProducts"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersProducts.family"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterProducts"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersProducts.subFamily"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterProducts"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                            v-model="filtersProducts.stkActual"
                                                            outlined
                                                            dense
                                                            hide-details
                                                            @keyup="filterProducts"
                                                    ></v-text-field>
                                                </td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="product in filteredProducts">
                                                <td>
                                                    <span style="cursor: pointer">
                                                        <i v-if="!product.selected" class="far fa-square fa-lg"
                                                           @click="selectProduct(product, true)"></i>
                                                        <i v-if="product.selected" class="fas fa-check-square fa-lg"
                                                           style="color:#1976d2"
                                                           @click="selectProduct(product, false)"></i>
                                                    </span>
                                                </td>
                                                <td>{{ product.Artigo }}</td>
                                                <td>{{ product.Descricao }}</td>
                                                <td>{{ product.Marca }}</td>
                                                <td>{{ product.Familia }}</td>
                                                <td>{{ product.SubFamilia }}</td>
                                                <td>{{ product.STKActual }}</td>
                                            </tr>
                                            </tbody>
                                        </v-simple-table>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-pagination
                                                v-model="paginationProducts.page"
                                                :length="getTotalPagesProducts"
                                                :total-visible="7"
                                                @input="filterProducts"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="dialogFilterProduct = false">Cancelar</v-btn>
                            <v-btn color="success" @click="setFilterProducts()" style="margin-left: auto">
                                Selecionar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>

        </template>
    </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'

export default {
    data: function () {
        return {
            loading: false,
            loadingClients: false,
            loadingProducts: false,
            dialogFilterClient: false,
            dialogFilterProduct: false,
            sales: {},
            salesValue: [],
            salesQuantity: [],
            brands: {},
            families: {},
            subFamilies: {},
            countries: {},
            sellers: {},
            seccoes: {},
            filters: {
                brands: [],
                families: [],
                subFamilies: [],
                countries: [],
                sellers: [],
                sections: [],
                clients: [],
                products: [],
                zonaComercial: []
            },
            chartData: [],
            chartDataQuantity: [],
            chartOptions: {},
            years: [],
            totalValueSales: [],
            totalQuantitySales: [],
            filtersClients: {
                client: '',
                name: '',
                country: '',
                nif: '',
                salesman: '',
                profile: ''
            },
            allClients: [],
            filteredClients: [],
            checkboxAll: false,
            paginationClients: {
                per_page: 500,
                page: 1
            },
            totalFilteredClients: 0,
            totalClientsSelected: 0,
            filtersProducts: {
                product: '',
                description: '',
                family: '',
                subFamily: '',
                brand: '',
                stkActual: ''
            },
            allProducts: [],
            filteredProducts: [],
            checkboxAllProducts: false,
            paginationProducts: {
                per_page: 500,
                page: 1
            },
            totalFilteredProducts: 0,
            totalProductsSelected: 0,
            sortClients: {
                field: 'Cliente',
                reverse: false,
                type: 'string'
            },
            sortProducts: {
                field: 'Artigo',
                reverse: false,
                type: 'string'
            },
            nameProductsSelected: [],
            zonasComerciais: []
        }
    },
    created() {
        document.title = 'Comercial ' + i18n.t('sales');
        this.getSales()
    },
    methods: {
        getSales() {
            this.loading = true;
            let request = {
                url: '/api/staff/commercial/sales',
                data: {
                    filters: this.filters
                }
            };

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.sales = responseData.sales;
                            this.brands = responseData.brands;
                            this.families = responseData.families;
                            this.subFamilies = responseData.subFamilies;
                            this.countries = responseData.countries;
                            this.sellers = responseData.sellers;
                            this.seccoes = responseData.seccoes;
                            this.zonasComerciais = responseData.zonasComerciais;

                            this.drawMap('value');
                            this.drawMap('quantity');
                        }
                        this.loading = false;
                    })
                    .catch(response => {
                        this.loading = false;
                    })
        },
        drawMap(type) {
            let sales = this.sales;

            //criacao de uma hashtable indexada ao ano e ao mes
            let salesHash = {};
            for (let i = 0; i < sales.length; i++) {
                if (typeof (salesHash[sales[i].ANO]) == 'undefined') {
                    salesHash[sales[i].ANO] = [];
                }
                let index = parseInt(sales[i].MES) - 1;
                if (type === 'value') {
                    // salesHash[sales[i].ANO][index] = parseInt(sales[i].TOTALLIQUIDO);
                    salesHash[sales[i].ANO][index] = parseFloat(sales[i].TOTALLIQUIDO);
                }
                if (type === 'quantity') {
                    salesHash[sales[i].ANO][index] = parseInt(sales[i].QUANTIDADE);
                }
            }

            //verificar se o array de dados vem completo, caso nao venha, completar a estrutura
            if (Object.keys(salesHash).length < 3) {
                let tempSalesHash = {};
                let currentYear = new Date().getFullYear();
                if (typeof (Object.keys(salesHash)[0]) == 'undefined') {
                    salesHash[currentYear] = [];
                }
                for (let i = 0; i < 3; i++) {
                    if (typeof (Object.keys(salesHash)[i]) != 'undefined') {
                        let key = Object.keys(salesHash)[i];
                        tempSalesHash[key] = salesHash[key];
                    } else {
                        let index = parseInt(Object.keys(tempSalesHash)[i - 1]) + 1;
                        tempSalesHash[index] = [];
                    }
                }
                salesHash = tempSalesHash;
            }

            //obter a listagem de anos possiveis das vendas
            this.years = [];
            for (let i = 0; i < 3; i++) {
                this.years[this.years.length] = Object.keys(salesHash)[i];
            }

            //remover os dois anos de referencia mais antigos
            //this.years.splice(0, 2);
            //console.log(this.years);

            //calculo dos totais por ano
            if (type === 'value') {
                this.totalValueSales = [0, 0, 0];
            }
            if (type === 'quantity') {
                this.totalQuantitySales = [0, 0, 0];
            }
            let yearIndex = 0;
            for (let year in salesHash) {
                //preenchimento dos valores em falta para cada ano
                for (let i = 0; i < 12; i++) {
                    if (typeof (salesHash[year][i]) == 'undefined') {
                        salesHash[year][i] = 0;
                    }
                }
                //calculo dos totais por ano
                for (let i = 0; i < salesHash[year].length; i++) {
                    if (type === 'value') {
                        this.totalValueSales[yearIndex] += salesHash[year][i];
                    }
                    if (type === 'quantity') {
                        this.totalQuantitySales[yearIndex] += salesHash[year][i];
                    }
                }
                yearIndex++;
            }

            let year1 = Object.keys(salesHash)[0];
            let year2 = Object.keys(salesHash)[1];
            let year3 = Object.keys(salesHash)[2];


            if (type === 'value') {
                this.chartData = [
                    ['Meses', year1, year2, year3],
                    ['Jan', salesHash[year1][0], salesHash[year2][0], salesHash[year3][0]],
                    ['Fev', salesHash[year1][1], salesHash[year2][1], salesHash[year3][1]],
                    ['Mar', salesHash[year1][2], salesHash[year2][2], salesHash[year3][2]],
                    ['Abr', salesHash[year1][3], salesHash[year2][3], salesHash[year3][3]],
                    ['Mai', salesHash[year1][4], salesHash[year2][4], salesHash[year3][4]],
                    ['Jun', salesHash[year1][5], salesHash[year2][5], salesHash[year3][5]],
                    ['Jul', salesHash[year1][6], salesHash[year2][6], salesHash[year3][6]],
                    ['Ago', salesHash[year1][7], salesHash[year2][7], salesHash[year3][7]],
                    ['Set', salesHash[year1][8], salesHash[year2][8], salesHash[year3][8]],
                    ['Out', salesHash[year1][9], salesHash[year2][9], salesHash[year3][9]],
                    ['Nov', salesHash[year1][10], salesHash[year2][10], salesHash[year3][10]],
                    ['Dez', salesHash[year1][11], salesHash[year2][11], salesHash[year3][11]]
                ];
            }

            if (type === 'quantity') {
                this.chartDataQuantity = [
                    ['Meses', year1, year2, year3],
                    ['Jan', salesHash[year1][0], salesHash[year2][0], salesHash[year3][0]],
                    ['Fev', salesHash[year1][1], salesHash[year2][1], salesHash[year3][1]],
                    ['Mar', salesHash[year1][2], salesHash[year2][2], salesHash[year3][2]],
                    ['Abr', salesHash[year1][3], salesHash[year2][3], salesHash[year3][3]],
                    ['Mai', salesHash[year1][4], salesHash[year2][4], salesHash[year3][4]],
                    ['Jun', salesHash[year1][5], salesHash[year2][5], salesHash[year3][5]],
                    ['Jul', salesHash[year1][6], salesHash[year2][6], salesHash[year3][6]],
                    ['Ago', salesHash[year1][7], salesHash[year2][7], salesHash[year3][7]],
                    ['Set', salesHash[year1][8], salesHash[year2][8], salesHash[year3][8]],
                    ['Out', salesHash[year1][9], salesHash[year2][9], salesHash[year3][9]],
                    ['Nov', salesHash[year1][10], salesHash[year2][10], salesHash[year3][10]],
                    ['Dez', salesHash[year1][11], salesHash[year2][11], salesHash[year3][11]]
                ];
            }

            let salesArray = [
                {
                    label: 'Jan',
                    type: '',
                    valueYear1: salesHash[year1][0],
                    valueYear2: salesHash[year2][0],
                    valueYear3: salesHash[year3][0]
                },
                {
                    label: 'Fev',
                    type: '',
                    valueYear1: salesHash[year1][1],
                    valueYear2: salesHash[year2][1],
                    valueYear3: salesHash[year3][1]
                },
                {
                    label: 'Mar',
                    type: '',
                    valueYear1: salesHash[year1][2],
                    valueYear2: salesHash[year2][2],
                    valueYear3: salesHash[year3][2]
                },
                {
                    label: 'Total 1º Trim.',
                    type: 'total',
                    valueYear1: (salesHash[year1][0] + salesHash[year1][1] + salesHash[year1][2]),
                    valueYear2: (salesHash[year2][0] + salesHash[year2][1] + salesHash[year2][2]),
                    valueYear3: (salesHash[year3][0] + salesHash[year3][1] + salesHash[year3][2])
                },
                {
                    label: 'Abr',
                    type: '',
                    valueYear1: salesHash[year1][3],
                    valueYear2: salesHash[year2][3],
                    valueYear3: salesHash[year3][3]
                },
                {
                    label: 'Mai',
                    type: '',
                    valueYear1: salesHash[year1][4],
                    valueYear2: salesHash[year2][4],
                    valueYear3: salesHash[year3][4]
                },
                {
                    label: 'Jun',
                    type: '',
                    valueYear1: salesHash[year1][5],
                    valueYear2: salesHash[year2][5],
                    valueYear3: salesHash[year3][5]
                },
                {
                    label: 'Total 2º Trim.',
                    type: 'total',
                    valueYear1: (salesHash[year1][3] + salesHash[year1][4] + salesHash[year1][5]),
                    valueYear2: (salesHash[year2][3] + salesHash[year2][4] + salesHash[year2][5]),
                    valueYear3: (salesHash[year3][3] + salesHash[year3][4] + salesHash[year3][5])
                },
                {
                    label: 'Jul',
                    type: '',
                    valueYear1: salesHash[year1][6],
                    valueYear2: salesHash[year2][6],
                    valueYear3: salesHash[year3][6]
                },
                {
                    label: 'Ago',
                    type: '',
                    valueYear1: salesHash[year1][7],
                    valueYear2: salesHash[year2][7],
                    valueYear3: salesHash[year3][7]
                },
                {
                    label: 'Set',
                    type: '',
                    valueYear1: salesHash[year1][8],
                    valueYear2: salesHash[year2][8],
                    valueYear3: salesHash[year3][8]
                },
                {
                    label: 'Total 3º Trim.',
                    type: 'total',
                    valueYear1: (salesHash[year1][6] + salesHash[year1][7] + salesHash[year1][8]),
                    valueYear2: (salesHash[year2][6] + salesHash[year2][7] + salesHash[year2][8]),
                    valueYear3: (salesHash[year3][6] + salesHash[year3][7] + salesHash[year3][8])
                },
                {
                    label: 'Out',
                    type: '',
                    valueYear1: salesHash[year1][9],
                    valueYear2: salesHash[year2][9],
                    valueYear3: salesHash[year3][9]
                },
                {
                    label: 'Nov',
                    type: '',
                    valueYear1: salesHash[year1][10],
                    valueYear2: salesHash[year2][10],
                    valueYear3: salesHash[year3][10]
                },
                {
                    label: 'Dez',
                    type: '',
                    valueYear1: salesHash[year1][11],
                    valueYear2: salesHash[year2][11],
                    valueYear3: salesHash[year3][11]
                },
                {
                    label: 'Total 4º Trim.',
                    type: 'total',
                    valueYear1: (salesHash[year1][9] + salesHash[year1][10] + salesHash[year1][11]),
                    valueYear2: (salesHash[year2][9] + salesHash[year2][10] + salesHash[year2][11]),
                    valueYear3: (salesHash[year3][9] + salesHash[year3][10] + salesHash[year3][11])
                }
            ];

            if (type === 'value') {
                this.salesValue = salesArray;
            }
            if (type === 'quantity') {
                this.salesQuantity = salesArray;
            }
        },
        toggleDialogFilterClients() {
            if (this.allClients.length === 0) {
                this.getAllClients();
            } else {
                this.filterClients();
            }
            this.dialogFilterClient = !this.dialogFilterClient;
        },
        toggleDialogFilterProducts() {
            if (this.allProducts.length === 0) {
                this.getAllProducts();
            } else {
                this.filterProducts();
            }
            this.dialogFilterProduct = !this.dialogFilterProduct;
        },
        getAllClients() {
            this.loadingClients = true;
            let request = {
                url: '/api/staff/commercial/clients'
            };

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.allClients = responseData.clients;
                            this.filterClients();
                        }
                        this.loadingClients = false;
                    })
                    .catch(response => {
                        this.loadingClients = false;
                    })
        },
        getAllProducts() {
            this.loadingProducts = true;
            let request = {
                url: '/api/staff/commercial/products'
            };

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.allProducts = responseData.products;
                            this.filterProducts();
                        }
                        this.loadingProducts = false;
                    })
                    .catch(response => {
                        this.loadingProducts = false;
                    })
        },
        filterClients() {
            this.filteredClients = this.allClients;

            this.filteredClients.sortAttr(this.sortClients.field, this.sortClients.reverse, this.sortClients.type);

            if (this.filtersClients.client !== '') {
                this.filteredClients = this.filteredClients.filter(line => line.Cliente.toUpperCase().includes(this.filtersClients.client.toUpperCase()));
            }
            if (this.filtersClients.name !== '') {
                this.filteredClients = this.filteredClients.filter(line => line.Nome.toUpperCase().includes(this.filtersClients.name.toUpperCase()));
            }
            if (this.filtersClients.country !== '') {
                this.filteredClients = this.filteredClients.filter(line => line.Pais !== null && line.Pais.toUpperCase().includes(this.filtersClients.country.toUpperCase()));
            }
            if (this.filtersClients.nif !== '') {
                this.filteredClients = this.filteredClients.filter(line => line.NumContrib !== null && line.NumContrib.toUpperCase().includes(this.filtersClients.nif.toUpperCase()));
            }
            if (this.filtersClients.salesman !== '') {
                this.filteredClients = this.filteredClients.filter(line => line.Vendedor !== null && line.Vendedor.toUpperCase().includes(this.filtersClients.salesman.toUpperCase()));
            }
            if (this.filtersClients.profile !== '') {
                this.filteredClients = this.filteredClients.filter(line => line.CDU_PROFILE.toUpperCase().includes(this.filtersClients.profile.toUpperCase()));
            }
            let pageIndex = this.paginationClients.page === 1 ? this.paginationClients.page - 1 : (this.paginationClients.page - 1) * this.paginationClients.per_page;
            let pageCount = this.paginationClients.page === 1 ? this.paginationClients.per_page : (this.paginationClients.page - 1) * this.paginationClients.per_page + this.paginationClients.per_page;
            this.totalFilteredClients = this.filteredClients.length;
            this.filteredClients = this.filteredClients.slice(pageIndex, pageCount);
        },
        filterProducts() {
            this.filteredProducts = this.allProducts;

            this.filteredProducts.sortAttr(this.sortProducts.field, this.sortProducts.reverse, this.sortProducts.type);

            if (this.filtersProducts.product !== '') {
                this.filteredProducts = this.filteredProducts.filter(line => line.Artigo.toUpperCase().includes(this.filtersProducts.product.toUpperCase()));
            }
            if (this.filtersProducts.description !== '') {
                this.filteredProducts = this.filteredProducts.filter(line => line.Descricao.toUpperCase().includes(this.filtersProducts.description.toUpperCase()));
            }
            if (this.filtersProducts.family !== '') {
                this.filteredProducts = this.filteredProducts.filter(line => line.Familia.toUpperCase().includes(this.filtersProducts.family.toUpperCase()));
            }
            if (this.filtersProducts.subFamily !== '') {
                this.filteredProducts = this.filteredProducts.filter(line => line.SubFamilia.toUpperCase().includes(this.filtersProducts.subFamily.toUpperCase()));
            }
            if (this.filtersProducts.brand !== '') {
                this.filteredProducts = this.filteredProducts.filter(line => line.Marca.toUpperCase().includes(this.filtersProducts.brand.toUpperCase()));
            }
            if (this.filtersProducts.stkActual !== '') {
                this.filteredProducts = this.filteredProducts.filter(line => line.STKActual === this.filtersProducts.stkActual);
            }

            let pageIndex = this.paginationProducts.page === 1 ? this.paginationProducts.page - 1 : (this.paginationProducts.page - 1) * this.paginationProducts.per_page;
            let pageCount = this.paginationProducts.page === 1 ? this.paginationProducts.per_page : (this.paginationProducts.page - 1) * this.paginationProducts.per_page + this.paginationProducts.per_page;
            this.totalFilteredProducts = this.filteredProducts.length;
            this.filteredProducts = this.filteredProducts.slice(pageIndex, pageCount);
        },
        selectAll() {
            this.checkboxAll = true;
            this.filteredClients.forEach(function (client) {
                client.selected = true;
            });
            this.totalClientsSelected = 0;
            for (let i = 0; i < this.allClients.length; i++) {
                for (let j = 0; j < this.filteredClients.length; j++) {
                    if (this.allClients[i].Cliente === this.filteredClients[j].Cliente && this.filteredClients[j].selected === true) {
                        this.allClients[i].selected = true;
                    }
                }
                if (this.allClients[i].selected === true) {
                    this.totalClientsSelected++;
                }
            }
        },
        selectAllProducts() {
            this.checkboxAllProducts = true;
            this.filteredProducts.forEach(function (product) {
                product.selected = true;
            });
            this.totalProductsSelected = 0;
            for (let i = 0; i < this.allProducts.length; i++) {
                for (let j = 0; j < this.filteredProducts.length; j++) {
                    if (this.allProducts[i].Artigo === this.filteredProducts[j].Artigo && this.filteredProducts[j].selected === true) {
                        this.allProducts[i].selected = true;
                    }
                }
                if (this.allProducts[i].selected === true) {
                    this.totalProductsSelected++;
                }
            }
        },
        unselectAll() {
            this.checkboxAll = false;
            this.filteredClients.forEach(function (client) {
                client.selected = false;
            });
            this.totalClientsSelected = 0;
            for (let i = 0; i < this.allClients.length; i++) {
                for (let j = 0; j < this.filteredClients.length; j++) {
                    if (this.allClients[i].Cliente === this.filteredClients[j].Cliente && this.filteredClients[j].selected === false) {
                        this.allClients[i].selected = false;
                    }
                }
                if (this.allClients[i].selected === true) {
                    this.totalClientsSelected++;
                }
            }
        },
        unselectAllProducts() {
            this.checkboxAllProducts = false;
            this.filteredProducts.forEach(function (product) {
                product.selected = false;
            });
            this.totalProductsSelected = 0;
            for (let i = 0; i < this.allProducts.length; i++) {
                for (let j = 0; j < this.filteredProducts.length; j++) {
                    if (this.allProducts[i].Artigo === this.filteredProducts[j].Artigo && this.filteredProducts[j].selected === false) {
                        this.allProducts[i].selected = false;
                    }
                }
                if (this.allProducts[i].selected === true) {
                    this.totalProductsSelected++;
                }
            }
        },
        selectClient(client, selected) {
            client.selected = selected;
            this.totalClientsSelected = 0;
            for (let i = 0; i < this.allClients.length; i++) {
                if (this.allClients[i].Cliente === client.Cliente) {
                    this.allClients[i].selected = selected;
                }
                if (this.allClients[i].selected === true) {
                    this.totalClientsSelected++;
                }
            }
        },
        selectProduct(product, selected) {
            product.selected = selected;
            this.totalProductsSelected = 0;
            for (let i = 0; i < this.allProducts.length; i++) {
                if (this.allProducts[i].Artigo === product.Artigo) {
                    this.allProducts[i].selected = selected;
                }
                if (this.allProducts[i].selected === true) {
                    this.totalProductsSelected++;
                }
            }
        },
        setFilterClients() {
            this.filters.clients = [];
            for (let i = 0; i < this.allClients.length; i++) {
                if (this.allClients[i].selected === true) {
                    this.filters.clients.push(this.allClients[i].Cliente);
                }
            }
            this.dialogFilterClient = false;
            this.getSales();
        },
        setFilterProducts() {
            this.filters.products = [];
            this.nameProductsSelected = [];
            for (let i = 0; i < this.allProducts.length; i++) {
                if (this.allProducts[i].selected === true) {
                    this.filters.products.push(this.allProducts[i].Artigo);
                    this.nameProductsSelected.push(this.allProducts[i].Descricao);
                }
            }
            this.dialogFilterProduct = false;
            this.getSales();
        },
        clearFiltersClients() {
            this.filtersClients = {
                client: '',
                name: '',
                country: '',
                nif: '',
                salesman: '',
                profile: ''
            }
            this.paginationClients.page = 1;
            this.filterClients()
        },
        clearFiltersProducts() {
            this.filtersProducts = {
                product: '',
                description: '',
                family: '',
                subFamily: '',
                brand: '',
                stkActual: ''
            };
            this.paginationProducts.page = 1;
            this.filterProducts();
        },
        updateSortClients(field, type) {
            if (this.sortClients.field === field) {
                this.sortClients.reverse = !this.sortClients.reverse;
            }
            this.sortClients.field = field;
            this.sortClients.type = type;

            this.filterClients();
        },
        updateSortProducts(field, type) {
            if (this.sortProducts.field === field) {
                this.sortProducts.reverse = !this.sortProducts.reverse;
            }
            this.sortProducts.field = field;
            this.sortProducts.type = type;

            this.filterProducts();
        },
        print() {
            this.$htmlToPaper('printMe');
        },
        clearFilters() {
            this.filters = {
                brands: [],
                families: [],
                subFamilies: [],
                countries: [],
                sellers: [],
                sections: [],
                clients: [],
                products: [],
                zonaComercial: []
            }
            this.allClients.forEach(function (client) {
                client.selected = false;
            });
            this.allProducts.forEach(function (product) {
                product.selected = false;
            });
            this.getSales();
        }
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.totalFilteredClients / this.paginationClients.per_page)
        },
        nameClientsSelected() {
            let names = [];
            for (let i = 0; i < this.allClients.length; i++) {
                for (let j = 0; j < this.filters.clients.length; j++) {
                    if (this.allClients[i].Cliente === this.filters.clients[j]) {
                        names.push(this.allClients[i].Nome);
                    }
                }
            }
            return names;
        },
        getTotalPagesProducts() {
            return Math.ceil(this.totalFilteredProducts / this.paginationProducts.per_page)
        }
    }
}
</script>
