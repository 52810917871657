<template>
    <v-container fluid>

        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="$t('profiles')"
                        :subtitle="$t('show') + ' ' + profiles.length + ' perfis'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-dialog v-model="dialog" max-width="460px">
                    <template v-slot:activator="{ on }" v-if="writePermission('administration/profiles')">
                        <v-btn tile small  color="info" v-on="on">
                            {{ $t('addItem') }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="headline blue white--text text-uppercase">
                            {{ formTitle }}
                        </v-card-title>
                        <v-card-text class="pt-3">
                            <v-form ref="form">
                                <v-container grid-list-md>
                                    <v-layout wrap>
                                        <v-flex xs12 sm6 md12>
                                            <v-text-field
                                                    outlined
                                                    v-model="editedItem.profile"
                                                    :label="'Perfil'"
                                                    :rules="[rules.required]"
                                                    name="profile">
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn tile small  @click.prevent="close">Cancelar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn tile small  color="success" @click.prevent="save">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Perfil</th>
                            <th>Criado</th>
                            <th>Actualizado</th>
                            <th class="text-center" style="width: 70px;">Editar</th>
                            <th class="text-center" style="width: 70px;">Permissões</th>
                            <th class="text-center" style="width: 70px;">Remover</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in profiles"
                            style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;"
                        >
                            <td>{{ item.id }}</td>
                            <td>{{ item.profile }}</td>
                            <td>{{ item.created_at.substring(0, 19).replace('T', ' ') }}</td>
                            <td>{{ item.updated_at.substring(0, 19).replace('T', ' ') }}</td>
                            <td class="text-center">
                                <v-btn x-small tile outlined color="info" @click="editItem(item)">
                                    <i class="fas fa-pencil-alt"></i>
                                </v-btn>
                            </td>
                            <td class="text-center">
                                <v-btn x-small tile outlined color="success" @click="editPermission(item)">
                                    <i class="fas fa-shield-alt"></i>
                                </v-btn>
                            </td>
                            <td class="text-center">
                                <v-btn x-small tile outlined color="error" @click="deleteItem(item)">
                                    <i class="fas fa-times"></i>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-row>

            <v-layout row justify-center>
                <v-dialog v-model="dialogCancel" persistent max-width="460">
                    <v-card>
                        <v-card-title class="headline red white--text text-uppercase">
                            REMOVER PERFIL
                        </v-card-title>
                        <v-card-text class="pa-3 text-center">Deseja remover o perfil? <br><strong>{{
                                itemCancel.profile
                            }}</strong></v-card-text>
                        <v-card-actions>
                            <v-btn tile small  @click="dialogCancel = false">Cancelar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn tile small  color="error" @click="remove()">REMOVER</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>

            <v-layout row justify-center>
                <v-dialog v-model="dialogPermission" persistent scrollable max-width="600">
                    <v-card>
                        <v-card-title class="headline green white--text text-uppercase">
                            {{ editedItem.profile }}
                        </v-card-title>
                        <v-card-text>
                            <v-simple-table dense>
                                <thead>
                                <tr>
                                    <th>Permissão</th>
                                    <th class="text-center" style="width: 70px;">Leitura</th>
                                    <th class="text-center" style="width: 70px;">Escrita</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in permissions">
                                    <td>{{ item.permission }}</td>
                                    <td>
                                        <v-simple-checkbox :ripple="false" color="primary" v-model="item.read"
                                                           @input="savePermission('read', item.id, item.read)"></v-simple-checkbox>
                                    </td>
                                    <td>
                                        <v-simple-checkbox :ripple="false" color="primary" v-model="item.write"
                                                           @input="savePermission('write', item.id, item.write)"></v-simple-checkbox>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                        <v-card-actions class="pa-3">
                            <v-btn tile small  @click="dialogPermission = false">{{ $t('close') }}</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-layout>

        </v-row>

    </v-container>

</template>

<script>
import i18n from '../../plugins/i18n'

export default {
    data: function () {
        return {
            show1: false,
            dialog: false,
            dialogCancel: false,
            dialogPermission: false,
            headers: [],
            headersPermission: [],
            profiles: [],
            editedIndex: -1,
            editedItem: {
                id: '',
                profile: ''
            },
            defaultItem: {
                id: '',
                profile: ''
            },
            pagination: {
                rowsPerPage: 25
            },
            rules: {
                required: value => !!value || value === 0 || i18n.t('required')
            },
            itemCancel: {},
            permissions: []
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? i18n.t('addItem') : 'Editar'
        }
    },
    created() {
        document.title = i18n.t('profiles')
        this.initialize()
    },
    methods: {
        initialize() {
            Profile.dispatch('getProfiles')
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.profiles = responseData.data
                            this.headers = responseData.headers
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro obter perfis',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
        },
        editItem(item) {
            this.editedIndex = this.profiles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        deleteItem(item) {
            this.itemCancel = item
            this.dialogCancel = true
            // const index = this.permissions.indexOf(item)
            // confirm('Are you sure you want to delete this item?') && this.users.splice(index, 1)
        },
        close() {
            this.dialog = false
            setTimeout(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
                this.$refs.form.resetValidation()
            }, 300)
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.editedIndex > -1) {
                    Profile.dispatch('updateProfile', this.editedItem)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    let alert = {
                                        snackbar: true,
                                        text: 'Perfil alterada com sucesso',
                                        type: 'success'
                                    }

                                    Alert.dispatch('show', alert)

                                    this.close()

                                    this.initialize()
                                } else {
                                    let alert = {
                                        snackbar: true,
                                        text: responseData.data,
                                        type: 'error'
                                    }

                                    Alert.dispatch('show', alert)
                                }
                            })
                            .catch(response => {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao alterar perfil',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            })
                } else {
                    Profile.dispatch('saveProfile', this.editedItem)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    let alert = {
                                        snackbar: true,
                                        text: 'Perfil adicionada com sucesso',
                                        type: 'success'
                                    }

                                    Alert.dispatch('show', alert)

                                    this.close()

                                    this.initialize()
                                } else {
                                    let alert = {
                                        snackbar: true,
                                        text: responseData.data,
                                        type: 'error'
                                    }

                                    Alert.dispatch('show', alert)
                                }
                            })
                            .catch(response => {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao adicionar perfil',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            })
                }
                // this.close()
            }
        },
        remove() {
            Profile.dispatch('removeProfile', this.itemCancel)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let alert = {
                                snackbar: true,
                                text: 'Perfil removida com sucesso',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)

                            this.dialogCancel = false;

                            this.initialize()
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao remover perfil',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao remover perfil',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        editPermission(item) {
            this.editedIndex = this.profiles.indexOf(item)
            this.editedItem = Object.assign({}, item)
            Profile.dispatch('getPermissions', this.editedItem)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.permissions = responseData.permission
                            this.headersPermission = responseData.headersPermission
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao obter permissões',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao obter permissões',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
            this.dialogPermission = true
        },
        savePermission(type, permissionId, permission) {
            let data = {
                type: type,
                id: permissionId,
                permission: permission,
                profileId: this.editedItem.id
            }
            Profile.dispatch('updatePermission', data)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.permissions = responseData.permission
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao salvar permissão',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao salvar permissão',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        }
    }
}
</script>
