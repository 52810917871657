<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <OpenProduct></OpenProduct>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableOrdersB2B></TableOrdersB2B>
            </v-col>
            <v-col>
                <TableServiceCenter></TableServiceCenter>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import TableOrdersB2B from "./Widgets/TableOrdersB2B";
    import TableServiceCenter from "./Widgets/TableServiceCenter";
    import OpenProduct from "@/components/Dashboard/Widgets/OpenProduct";

    export default {
        components: {
            OpenProduct,
            TableServiceCenter,
            TableOrdersB2B,
        },
        computed: {
            profile() {
                return User.getters.getProfileId
            }
        }
    }
</script>
