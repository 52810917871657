<style scoped>
td {
    white-space: nowrap;
}

td.description {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tdOrderNumber {
    border-right-style: solid;
    border-right-color: #dddddd;
}

.chip-update {
    background-color: dodgerblue !important;
}

.chip-delete {
    background-color: orangered !important;
}

.chip-insert {
    background-color: mediumseagreen !important;
}

.chip-fix {
    background-color: #ff9800 !important;
}

</style>
<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="'Fornecedor - ' + supplier"
                        :subtitle="'A mostrar ' + openorderLinesCount + ' linhas pendentes.'"
                        :back="true"
                ></page-title>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-row class="mb-0">
                    <!--          <v-col class="text-center"><h4>EXCEL</h4></v-col>-->
                    <v-col class="text-center">
                        <v-btn x-small tile outlined color="primary" class="mr-2"
                               @click="collapseAll()">
                            <v-icon x-small>unfold_less</v-icon>
                        </v-btn>
                        <v-btn x-small tile outlined color="primary" class="mr-2" @click="showAll()">
                            <v-icon x-small>unfold_more</v-icon>
                        </v-btn>
                    </v-col>
                    <!--          <v-col class="text-center"><h4>PRIMAVERA</h4></v-col>-->
                </v-row>
            </v-col>
        </v-row>

        <!--TODO Todo just so it stays green and gets your attention, Pending orders here -->
        <v-row>
            <v-col cols="11" class="text-center">
                <h5>Pendentes</h5>
            </v-col>
            <v-col cols="1">
                <v-btn icon small class="ml-2" color="grey"
                       @click="collapsePending(true)">
                    <v-icon>unfold_less</v-icon>
                </v-btn>
                <v-btn icon small class="ml-2" color="grey"
                       @click="collapsePending(false)">
                    <v-icon>unfold_more</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <hr>
        <br>
        <v-row v-for="(order, orderIndex) in openorder" v-if="openorder.length > 0" :key="'order_' + orderIndex">
            <v-col>
                <div class="staff_v-card mb-3">
                    <v-row>
                        <v-col cols="1">
                            <v-btn v-if="!order.collapse" icon small class="ml-2" color="grey"
                                   @click="order.collapse=true">
                                <v-icon>unfold_less</v-icon>
                            </v-btn>
                            <v-btn v-if="order.collapse" icon small class="ml-2" color="grey"
                                   @click="order.collapse=false">
                                <v-icon>unfold_more</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn v-if="order.doc_number == null || order.doc_number.NumDoc != 'processing'" tile x-small
                                   class="ml-2 btn btn-danger"
                                   color="primary"
                                   @click="recheckProducts(order.id)"
                            >Atualizar
                            </v-btn>
                            <v-btn v-if="order.doc_number == null || order.doc_number.NumDoc != 'processing'" tile x-small
                                   class="ml-2 btn btn-danger"
                                   color="error"
                                   @click="deleteOpenOrder(order, orderIndex, true)"
                            >Eliminar
                            </v-btn>
                        </v-col>
                        <v-col v-if="order.collapse" cols="3">
                            <h5 class="pl-3">{{ processState(order) }} <small
                                    class="pl-2">({{ (typeof order.lines !== 'undefined' ? order.lines.length : 0) }}
                                linhas)</small></h5>
                        </v-col>
                        <v-col cols="4" :offset="order.collapse? 2 : 5" class="text-right">
                            <v-chip label small :color="order.doc_number != null && order.doc_number.NumDoc != 'error' ? 'success' : 'error'">
                                {{ processState(order) }}
                            </v-chip>
                            <v-btn v-if="order.doc_number == null" tile small class="ml-2"
                                   color="info"
                                   @click="update(order)">CRIAR ENCOMENDA E INSERIR LINHAS
                            </v-btn>
                        </v-col>

                    </v-row>
                    <v-simple-table v-if="!order.collapse" class="staff_table mt-2">
                        <thead>
                        <tr>
                            <td colspan="8" style="background-color: #dddddd">
                                <h3 class="pl-3">{{ processState(order) }}
                                    <small>({{ (typeof order.lines !== 'undefined' ? order.lines.length : 0) }}
                                        linhas)</small></h3>
                            </td>
                        </tr>
                        <tr>
                            <th width="50">Order</th>
                            <th width="100">Part</th>
                            <th width="100">EAN</th>
                            <th>Description</th>
<!--                            <th width="10">N.Linha</th>-->
                            <th width="100" class="text-center">Qty. Pedida</th>
                            <th width="150" class="text-center">Qty. Por receber</th>
                            <th width="80" class="text-right">Price</th>
                            <th width="90" class="text-center">Date</th>

                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(line, lineIndex) in order.lines" :key="'line_' + lineIndex" :style="{color: line.product_id===null ? 'red' : ''}">
                            <td class="tdOrderNumber">{{ line.supplier_order_number }}</td>

                            <td>{{ line.product_id === null ? line.product_id_file : line.product_id }}</td>
                            <td>{{
                                    line.ean === null ? (line.ean_file === null ? "----" : line.ean_file) : line.ean
                                }}
                            </td>
                            <td class="description">{{ line.description_file }}</td>
                            <td class="description text-center">{{ line.quantity }}</td>
                            <td class="text-center">{{ line.quantity - line.transformed_quantity }}</td>
                            <td class="text-right">{{ $dollar(line.price) }}</td>
                            <td class="text-center">{{ line.arrivalDate }}</td>

                            <!-- TODO Check if this is needed, or only check for product with multiple info on upload, and put the fix = true -->
                            <template v-if="line.duplicatedProducts != null">
                                <td style="border-left: 1px solid #999"></td>
                                <td style="background-color:rgb(255 152 0 / 61%)">
                                    <i style="color:red" class="fas fa-exclamation-triangle"></i>
                                </td>
                                <td style="background-color: rgb(255 152 0 / 61%)">
                                    <v-btn color="warning" tile small
                                           @click="popupDuplicatedInfo(line.duplicatedProducts, line)">
                                        Corrigir
                                    </v-btn>
                                </td>
                                <td style="font-weight:bold; color:red;background-color:rgb(255 152 0 / 61%)"
                                    class="description"
                                    colspan="2">Informacao duplicada detetada
                                </td>
                                <td style="background-color:rgb(255 152 0 / 61%)" colspan="2">
                                    <span
                                            class="red&#45;&#45;text"
                                            style="font-weight: bold">
                                        <i style="color:red" class="fas fa-exclamation-triangle"></i>
                                    </span>
                                </td>
                            </template>

                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>

            </v-col>
        </v-row>


        <!--TODO Todo just so it stays green and gets your attention, Orders on  the way are here -->
        <v-row>
            <v-col cols="11" class="text-center">
                <h5>Em transito</h5>
            </v-col>
            <v-col cols="1">
                <v-btn icon small class="ml-2" color="grey"
                       @click="collapseAll(false)">
                    <v-icon>unfold_less</v-icon>
                </v-btn>
                <v-btn icon small class="ml-2" color="grey"
                       @click="showAll(false)">
                    <v-icon>unfold_more</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <hr>
        <br>
        <v-row>
            <v-col>
                <div v-for="(order, orderIndex) in orders" class="staff_v-card mb-3" :key="'order2_' + orderIndex">
                    <v-row>
                        <v-col cols="1">
                            <v-btn v-if="!order.collapse" icon small class="ml-2" color="grey"
                                   @click="order.collapse=true">
                                <v-icon>unfold_less</v-icon>
                            </v-btn>
                            <v-btn v-if="order.collapse" icon small class="ml-2" color="grey"
                                   @click="order.collapse=false">
                                <v-icon>unfold_more</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="2">
                            <v-btn v-if="order.doc_number == null || order.doc_number.NumDoc != 'processing'" tile x-small
                                   class="ml-2 btn btn-danger"
                                   color="primary"
                                   @click="recheckProducts(order.id)"
                            >Atualizar
                            </v-btn>
                            <v-btn v-if="order.doc_number == null || order.doc_number.NumDoc != 'processing'" tile x-small
                                   class="ml-2 btn btn-danger"
                                   color="error"
                                   @click="deleteOpenOrder(order, orderIndex, false)"
                            >Eliminar
                            </v-btn>
                        </v-col>
                        <v-col v-if="order.collapse" cols="3">
                            <h5 class="pl-3">{{ processState(order) }} <small class="pl-2">({{ order.lines.length }}
                                linhas)</small></h5>
                        </v-col>
                        <v-col cols="4" :offset="order.collapse? 2 : 5" class="text-right">
                            <v-chip label small :color="order.doc_number != null && order.doc_number.NumDoc != 'error' ? 'success' : 'error'">
                                {{ processState(order) }}
                            </v-chip>
                            <v-btn v-if="order.doc_number == null && order.link_ecfs == 1" tile small class="ml-2"
                                   color="info"
                                   @click="update(order, true)">MATCH APENAS
                            </v-btn>
                            <v-btn v-if="order.doc_number == null" tile small class="ml-2"
                                   color="info"
                                   @click="update(order)">CRIAR ENCOMENDA E INSERIR LINHAS
                            </v-btn>
                        </v-col>

                    </v-row>
                    <v-simple-table v-if="!order.collapse" class="staff_table mt-2">
                        <thead>
                        <tr>
                            <td colspan="7" style="background-color: #dddddd; text-align: center">
                                <h3 class="pl-3">Informação do ficheiro <small>({{ order.lines.length }}
                                    linhas)</small></h3>
                            </td>
                            <td colspan="4" style="border-left: #777777 solid 2px; background-color: #dddddd; text-align:center">
                                <h3 class="pl-3">Informação do Match </h3>
                            </td>
                        </tr>
                        <tr>
                            <th width="50">Order</th>
                            <th width="100">Part</th>
                            <th width="100">EAN</th>
                            <th >Description</th>
                            <th width="30">Qty</th>
                            <th width="80" class="text-right">Price</th>
                            <th width="90" class="text-center">Date</th>
                            <th class="text-center" style="border-left: #777777 solid 2px;">ECF's</th>
                            <th class="text-center">Linhas</th>
                            <th class="text-center">Quantidades</th>
                            <th class="text-center">Não encontrados</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr v-for="(line, lineIndex) in order.lines" :style="{color: line.product_id===null ? 'red' : ''}" :key="'line2_' + lineIndex">
                            <td class="tdOrderNumber">{{ line.supplier_order_number }}</td>

                            <td>{{ line.product_id === null ? line.product_id_file : line.product_id }}</td>
                            <td>{{
                                    line.ean === null ? (line.ean_file === null ? "----" : line.ean_file) : line.ean
                                }}
                            </td>
                            <td class="description">{{ line.description_file }}</td>
                            <td class="text-center">{{ line.quantity }}</td>
                            <td class="text-right">{{ $dollar(line.price) }}</td>
                            <td class="text-center">{{ line.arrivalDate }}</td>
                            <td class="text-center" style="border-left: #777777 solid 2px;">{{ line.matched_ecf != "" ? line.matched_ecf : '---'}}</td>
                            <td class="text-center">{{ line.matched_line != "" ? line.matched_line : '---' }}</td>
                            <td class="text-center">{{ line.matched_quantities != "" ? line.matched_quantities : '---' }}</td>
                            <td class="text-center" :style="{ 'background-color': line.valid == false ? 'orange' : '' }">{{ line.valid == true ? 0 : line.matched_quantities != '' ? line.quantity - line.matched_quantities.match(/\d+/g).reduce(function(a,b) {return +a + +b;}) : line.quantity }}</td>

                            <!-- TODO Check if this is needed, or only check for product with multiple info on upload, and put the fix = true -->
                            <template v-if="line.duplicatedProducts != null && order.shipped==0">
                                <td style="border-left: 1px solid #999"></td>
                                <td style="background-color:rgb(255 152 0 / 61%)">
                                    <i style="color:red" class="fas fa-exclamation-triangle"></i>
                                </td>
                                <td style="background-color: rgb(255 152 0 / 61%)">
                                    <v-btn color="warning" tile small
                                           @click="popupDuplicatedInfo(line.duplicatedProducts, line)">
                                        Corrigir
                                    </v-btn>
                                </td>
                                <td style="font-weight:bold; color:red;background-color:rgb(255 152 0 / 61%)"
                                    class="description"
                                    colspan="2">Informacao duplicada detetada
                                </td>
                                <td style="background-color:rgb(255 152 0 / 61%)" colspan="2"><span
                                        class="red&#45;&#45;text"
                                        style="font-weight: bold">
                  <i style="color:red" class="fas fa-exclamation-triangle"></i>
                </span></td>
                            </template>

                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>

            </v-col>
        </v-row>

        <v-dialog v-model="showPending" max-width="600">
            <v-card>
                <v-card-title>Encomendas pendentes</v-card-title>
                <v-card-text>
                    Foram encontradas encomendas pendentes no Primavera que não se encontram no ficheiro carregado.
                    <br><br>
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th class="pl-15">
                                Documento Externo
                            </th>
                            <th>
                                Documento Primavera
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="yellow lighten-4" v-for="(pendingOrder, pendingOrderIndex) in pendingOrders" :key="'pending_' + pendingOrderIndex">
                            <td class="pl-5">
                                <i style="color:orange" class="fas fa-exclamation-triangle"></i><span
                                    class="ml-10">{{ pendingOrder.externalDoc }}</span>
                            </td>
                            <td>
                                <span>{{ pendingOrder.document }}</span>
                            </td>
                        </tr>
                        <tr class="light-green lighten-3" v-for="(order, orderIndex) in orders" :key="'order3_' + orderIndex">
                            <td class="pl-5">
                                <i style="color:green" class="fas fa-check-circle"></i><span class="ml-10">{{
                                    order.numDocExterno
                                }}</span>
                            </td>
                            <td>
                                <span>{{ order.numDoc }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
                <v-card-actions class="justify-center">
                    <v-btn tile small color="primary" @click="showPending=false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogConfirmDelete" max-width="500">
            <v-card>
                <v-card-title>{{ $t('confirmChange') }}</v-card-title>
                <v-card-text>
                    Eliminar openorder?
                    <br>
                    <strong>{{ dialogOrder }}</strong>
                </v-card-text>
                <v-card-actions>
                    <v-btn tile small @click="closeDialogDelete()">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="error" @click="confirmDelete()">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAssociate" max-width="500">
            <v-card>
                <v-card-title>{{ $t('confirmChange') }}</v-card-title>
                <v-card-text>
                    <v-radio-group
                            v-model="associateWithPendings"
                            mandatory
                    >
                        <v-radio
                                label="Associar a ECF de pendentes existente"
                                :value="true"
                        ></v-radio>
                        <v-radio
                                label="Criar ECF adicional"
                                :value="false"
                        ></v-radio>
                    </v-radio-group>
                </v-card-text>
                <v-card-actions>
                    <v-btn tile small @click="closeDialogAssociate()">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="createAssociate()">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConfirm" max-width="500">
            <v-card>
                <v-card-title>{{ $t('confirmChange') }}</v-card-title>
                <v-card-text>
                    {{ $t('activateProduct') }}
                    <br>
                    <strong>{{ dialogProduct }}</strong>
                </v-card-text>
                <v-card-actions>
                    <v-btn tile small @click="closeDialogConfirm()">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="confirmChange()">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <ProductEditing v-if="dialogDuplicatedProducts" v-on:close-editing="dialogDuplicatedProducts = false"
                        :product="productToFix"/>
    </v-container>

</template>

<script>
import ProductEditing from '@/components/Products/ProductEditing.vue';

export default {
    data: () => ({
        id: 0,
        uploading: false,
        orderDelete: {},
        orderDeleteIndex: null,
        orderDeleteIsPending: null,
        dialogConfirm: false,
        dialogConfirmDelete: false,
        dialogProduct: null,
        dialogOrder: null,
        subtitle: '',
        supplier: '',
        showPending: false,
        pendingOrders: [],
        openorder: {},
        orders: [],
        processingOrders: [],
        totalLines: 0,
        totalProducts: 0,
        resultInsert: {},
        resultUpdate: {},
        resultDelete: {},
        fileName: '',
        fileUrl: '',
        fileFile: '',
        duplicatedProducts: [],
        duplicatedProductsOriginal: [],
        dialogDuplicatedProducts: false,
        productToFix: null,
        duplicatedSyncMessage: "",
        openorderLinesCount: 0,
        associateWithPendings: true,
        dialogAssociate: false,
       /* associatedOrder: {}*/
    }),
    private: {
        checkSync: null
    },
    components: {
        ProductEditing
    },
    created() {
        document.title = 'Open Order Detail';
        this.supplier = this.$route.params.id;
        this.getOpenOrderDetail();
    },
    methods: {
        processState(order) {
            if (order.doc_number == null)
                return 'Por criar';
            else if (order.doc_number.NumDoc == 'processing')
                return 'A processar';
            else if (order.doc_number.NumDoc != 'processing' && order.doc_number.NumDoc != 'error' )
                return order.doc_number.TipoDoc + ' ' + order.doc_number.Serie + '/' + order.doc_number.NumDoc;
            else
                return 'Erro';
        },
        getOpenOrderDetail() {
            let loader = this.$loading.show();
            let request = {url: '/api/staff/purchases/import/' + this.supplier}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.openorderLinesCount = responseData.openorderLinesCount;
                            this.openorder = responseData.openorder;
                            this.orders = Object.values(responseData.orders);
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        collapseAll(includedPending = true) {
            this.orders.forEach(function (order) {
                order.collapse = true;
            });
            if (includedPending == true)
                this.openorder.forEach(function (order) {
                    order.collapse = true;
                });
        },
        collapsePending(collapse) {
            this.openorder.forEach(function (order) {
                order.collapse = collapse;
            });
        },
        showAll(includedPending = true) {
            this.orders.forEach(function (order) {
                order.collapse = false;
            });
            if (includedPending == true)
                this.openorder.forEach(function (order) {
                    order.collapse = false;
                });
        },
        processSynced(syncedOrder) {
            let foundProcessingOrder = this.processingOrders.findIndex(processingOrder => processingOrder.id == syncedOrder.id)

            if (foundProcessingOrder == -1)
                return;
            this.processingOrders[foundProcessingOrder].syncing = false;
            this.processingOrders[foundProcessingOrder].action = syncedOrder.action;
            this.processingOrders[foundProcessingOrder].collapse = true;
            this.processingOrders[foundProcessingOrder].lines = syncedOrder.lines;
            this.processingOrders[foundProcessingOrder].numDoc = syncedOrder.numDoc;
            this.processingOrders[foundProcessingOrder].numRows = syncedOrder.numRows;
            this.processingOrders[foundProcessingOrder].numRowsPri = syncedOrder.numRowsPri;
            this.processingOrders[foundProcessingOrder].missing = syncedOrder.missing;
            this.processingOrders[foundProcessingOrder].duplicated = syncedOrder.duplicated;
            this.processingOrders[foundProcessingOrder].insert = syncedOrder.insert;
            this.processingOrders[foundProcessingOrder].update = syncedOrder.update;
            this.processingOrders[foundProcessingOrder].delete = syncedOrder.delete;

            this.processingOrders.splice(foundProcessingOrder, 1);
        },
        removeSyncErrors(syncedOrder) {

            let foundOrder = this.orders.findIndex(processingOrder => processingOrder.id == syncedOrder.id)
            let foundProcessingOrder = this.processingOrders.findIndex(processingOrder => processingOrder.id == syncedOrder.id)

            if (foundOrder == -1)
                return;

            this.orders[foundOrder].syncing = false;
            this.orders[foundOrder].action = syncedOrder.action;
            this.orders[foundOrder].collapse = false;
            this.orders[foundOrder].numDoc = syncedOrder.numDoc;
            this.orders[foundOrder].numRows = syncedOrder.numRows;
            this.orders[foundOrder].numRowsPri = syncedOrder.numRowsPri;
            this.orders[foundOrder].missing = syncedOrder.missing;
            this.orders[foundOrder].duplicated = syncedOrder.duplicated;
            this.orders[foundOrder].insert = syncedOrder.insert;
            this.orders[foundOrder].update = syncedOrder.update;
            this.orders[foundOrder].delete = syncedOrder.delete;

            this.processingOrders.splice(foundProcessingOrder, 1);

        },
        checkProcessingOrders() {
            if (this.processingOrders && !this.processingOrders.length) {
                clearInterval(this.checkSync);
                this.checkSync = null;
                return;
            }

            let request = {
                url: '/api/staff/purchases/import/processing',
                data: {
                    processingOrders: this.processingOrders,
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;

                        if (responseData.status == "OK") {
                            if (responseData.data.processedOrders.length) {
                                responseData.data.processedOrders.forEach((order) =>
                                        this.processSynced(order)
                                );
                            }
                            if (responseData.data.syncErrorOrders.length) {
                                responseData.data.syncErrorOrders.forEach((order) =>
                                        this.removeSyncErrors(order)
                                );
                            }
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao verificar compras em processo!',
                                type: 'error'
                            })
                        }

                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao verificar compras em processo!',
                            type: 'error'
                        })
                    })
        },

        update(order, matchOnly = false) {
            order.collapse = true;
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/import/update',
                data: {
                    id: order.id,
                    matchOnly: matchOnly
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                                let responseData = response.data;

                                if (responseData.status === 'OK') {
                                    let alert = {
                                        snackbar: true,
                                        text: "OK",
                                        type: 'success'
                                    };
                                    Alert.dispatch('show', alert);
                                    if(matchOnly == true)
                                        this.getOpenOrderDetail();
                                    else
                                        order.doc_number = {"NumDoc":"processing"};
                                    this.dialogAssociate = false;
                                } else {
                                    //this.processingOrders.pop();
                                    //order.syncing = false;
                                    order.collapse = false;
                                    let alert = {
                                        snackbar: true,
                                        text: responseData.data,
                                        type: 'error'
                                    };
                                    Alert.dispatch('show', alert);
                                }
                                loader.hide();
                            }
                    )
                    .catch((error) => {
                        let errorData = error.response.data;
                        let alert = {
                            snackbar: true,
                            text: errorData.message,
                            type: 'error'
                        };
                        Alert.dispatch('show', alert);
                        loader.hide();
                    })
        },
        confirmDelete() {
            if(this.orderDelete.doc_number != null && this.orderDelete.doc_number.NumDoc == 'processing')
                return;

            let request = {
                url: '/api/staff/purchases/' + this.orderDelete.id,
            };
            let loader = this.$loading.show();
            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            if(this.orderDeleteIsPending == true)
                                this.openorder.splice(this.orderDeleteIndex, 1);
                            else
                                this.orders.splice(this.orderDeleteIndex, 1);

                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Documento removido com sucesso',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao remover compra!',
                                type: 'error'
                            })
                        }
                        loader.hide();
                        this.closeDialogDelete();
                    })
                    .catch(() => {
                        loader.hide();
                        this.closeDialogDelete();
                    })
        },
        deleteOpenOrder(openorder, orderIndex, isPending) {
            if (openorder.doc_number != null && openorder.doc_number.NumDoc == 'processing') {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Impossivel eliminar encomendas em processamento!',
                    type: 'error'
                })
                return;
            }
            this.orderDelete = openorder;
            this.orderDeleteIndex = orderIndex;
            this.orderDeleteIsPending = isPending;
            this.dialogConfirmDelete = true;
        },
        getProblems() {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/purchases/import/' + this.openorder.id + '/problems'
            };
            ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = this.openorder.file + '_problems.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        loader.hide();
                    })
                    .catch(response => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        activateProduct(orderIndex, lineIndex) {
            console.log("TEST", this.orders[orderIndex].lines[lineIndex]);
            return;
            if (!line.anulado)
                return;
            this.dialogProduct = line.product,
                    this.dialogConfirm = true;

        },
        closeDialogConfirm() {
            this.dialogConfirm = false;
        },
        closeDialogDelete() {
            this.dialogConfirmDelete = false;
            this.orderDelete = {};
            this.orderDeleteIndex = null;
            this.orderDeleteIsPending = null;
        },
        confirmChange() {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/purchases/activate-product/' + this.dialogProduct
            };
            ApiWS.dispatch('makePut', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {

                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })

            this.dialogConfirm = false;

        },
        syncOrder(orderId) {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/purchases/sync/' + orderId
            };
            ApiWS.dispatch('makePut', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.$router.push('/purchases/OpenOrders');
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })

            this.dialogConfirm = false;

        },
        checkDuplicatedProducts(product) {
            let loader = this.$loading.show();
            this.duplicatedSyncMessage = "";
            let request = {
                url: '/api/staff/products/inconsistencies/' + btoa(product.product),
            };

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.duplicatedProducts = responseData.data.duplicatedInfo[product.product];
                            this.duplicatedProductsOriginal = JSON.stringify(this.duplicatedProducts);
                            this.duplicatedProductsOriginal = JSON.parse(this.duplicatedProductsOriginal);
                            if (this.dialogDuplicatedProducts == false)
                                this.dialogDuplicatedProducts = true;
                        } else {
                            this.duplicatedSyncMessage = "Ocorreram erros ao sincronizar problemas, por favor feche e volte a abrir esta janela."
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        popupDuplicatedInfo(duplicatedProducts, productToFix) {
            this.productToFix = productToFix.product_id;
            this.dialogDuplicatedProducts = true;
            this.checkDuplicatedProducts(productToFix);


        },
        saveProductInfo(product, productOriginal, productToCheck) {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/products/field',
                data: {
                    products: [product.productId]
                }
            };

            let snackMessage = "";
            let snackType = "success";

            let promiseEan = {text: null, success: true};
            let promiseBarcode = {text: null, success: true};
            let promiseSuppliercode = {text: null, success: true};

            if (product.ean != productOriginal.ean) {
                request.data.field = 'CDU_EAN';
                request.data.value = product.ean;

                promiseEan = ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                return {text: "EAN", success: true};
                            } else
                                return {text: null, success: false};

                        })
                        .catch(() => {

                        })
            }
            if (product.barcode != productOriginal.barcode) {
                request.data.field = 'CodBarras';
                request.data.value = product.barcode;

                promiseBarcode = ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                return {text: "Código Barras", success: true};
                            } else
                                return {text: null, success: false};
                            //loader.hide();
                        })
                        .catch(() => {
                            return {text: null, success: false};
                        })
            }
            if (product.supplierCode != productOriginal.supplierCode) {
                request.data.field = 'CDU_REFFORNECEDOR';
                request.data.value = product.supplierCode;

                promiseSuppliercode = ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                return {text: "Ref. Fornecedor", success: true};
                            } else
                                return {text: null, success: false};
                            //loader.hide();
                        })
                        .catch(() => {
                            return {text: null, success: false};
                        })
            }
            let counter = 0;
            let valid = false;
            Promise.all([promiseEan, promiseBarcode, promiseSuppliercode]).then((values) => {
                for (let i = 0; i < values.length; i++) {
                    counter++;
                    if (values[i].text != null) {
                        valid = true;
                        if (snackMessage != "")
                            snackMessage += '/';
                        snackMessage += values[i].text;
                    }
                    if (values[i].success == false) {
                        snackType = "warning";
                        switch (counter) {
                            case 1:
                                product.ean = productOriginal.ean;
                                break;
                            case 2:
                                product.barcode = productOriginal.barcode;
                                break;
                            default:
                                product.supplierCode = productOriginal.supplierCode;
                        }
                    }
                }
                if (counter == 3) {
                    if (valid == true) {
                        if (snackType == 'success')
                            snackMessage += " atualizado com sucesso.";
                        else
                            snackMessage = "Apenas " + snackMessage + " atualizado com sucesso.";
                    } else {
                        snackMessage = "Ocorreu um problema ao atualizar os valores";
                        snackType = "error";
                    }
                }
            }).then(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: snackMessage,
                            type: snackType
                        });
                        loader.hide();
                        if (snackType == "success") {
                            productToCheck.product = productToCheck.productId;
                            this.checkDuplicatedProducts(productToCheck);
                        }
                    }
            );
        },
        recheckProducts(orderId){
            let loader = this.$loading.show();
            this.duplicatedSyncMessage = "";
            let request = {
                url: '/api/staff/purchases/recheck/' + orderId,
            };

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;

                        if (response.status === 200) {
                            this.getOpenOrderDetail();
                        } else if(response.status !== 200 && response.status !== 204) {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao verificar produtos em falta!',
                                type: 'error'
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        }
       /* showDialogAssociate(order){
            this.associatedOrder = order;
            this.dialogAssociate = true;
        },*/
        /*createAssociate(){
            this.update(this.associatedOrder, this.associateWithPendings);
        },
        closeDialogAssociate(){
            this.associatedOrder = {};
            this.dialogAssociate = false;
            this.associateWithPendings = true;
        }*/
    }
}
</script>
