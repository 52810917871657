<template>
  <v-container fluid>

    <!-- HEADER -->
    <v-row class="content-header">
      <v-col cols="12" md="6">
        <page-title title="Histórico de Recolhas"></page-title>
      </v-col>
      <v-col class="text-right">
      </v-col>
    </v-row>

    <!-- PICKUPS WITH NO TRACKING NUMBER -->
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar class="mb-3" flat dense dark color="success">
            <v-toolbar-title class="text-uppercase">{{ new Date().toJSON().slice(0, 10) }} | Total de
              Recolhas Pendentes de Criar:
              {{ pickupsNoTracking.length }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-simple-table class="staff_table">
              <thead>
              <tr>
                <th class="text-center">Pré-registo</th>
                <th class="text-center">Cod. Cliente</th>
                <th class="text-center">Nome</th>
                <!--                  <th>Id Tipo Serviço</th>-->
                <th class="text-center">Marca</th>
                <th class="text-center">Transportadora</th>
                <th class="text-center">Data Criação</th>
                <th class="text-center">Conteúdo Req.</th>
                <!--                  <th class="text-center">Data Criação</th>-->
                <!--                  <th class="text-center">Cancelar Recolha</th>-->
              </tr>
              <tr class="filters_row">
                <td>
                  <v-text-field
                      v-model="filtersNoTracking.id"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickupsWithoutTracking()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filtersNoTracking.idCliente"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickupsWithoutTracking()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filtersNoTracking.nomeCliente"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickupsWithoutTracking()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filtersNoTracking.brand"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickupsWithoutTracking()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filtersNoTracking.pickupCourier"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickupsWithoutTracking()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filtersNoTracking.created_at"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickupsWithoutTracking()"
                  ></v-text-field>
                </td>
                <td></td>
                <!--                  <td></td>-->
              </tr>
              </thead>
              <tbody>
              <tr v-if="loadingNoTracking">
                <td colspan="10">
                  <v-progress-linear indeterminate></v-progress-linear>
                </td>
              </tr>
              <tr v-for="pickup in pickupsNoTracking">
                <td>{{ pickup.id }}</td>
                <td>{{ pickup.idCliente }}</td>
                <td>{{ pickup.nomeCliente }}</td>
                <!--                  <td>{{ pickup.service_type_id }}</td>-->
                <td>{{ pickup.brand }}</td>
                <!--                  <td>{{ pickup.clientCode }}</td>-->
                <!--                  <td>{{ pickup.clientName }}</td>-->
                <!--                  <td>{{ pickup.observation }}</td>-->
                <td>{{ pickup.pickupCourier }}</td>
                <td>{{ pickup.created_at }}</td>
                <td class="text-center">
                  <v-btn color="primary" v-if="writePermission('orders/shipped')" small
                         @click="redoPickup(pickup)"
                  >
                    Criar Recolha
                  </v-btn>
                </td>
                <!--                  <td class="text-center">-->
                <!--                    <v-btn v-if="writePermission('orders/shipped')" color="error" small-->
                <!--                           @click="cancelPickup(pickup)"-->
                <!--                    >-->
                <!--                      <i class="fas fa-times"></i>-->
                <!--                    </v-btn>-->
                <!--                  </td>-->
              </tr>
              </tbody>
            </v-simple-table>
            <v-row>
              <v-col class="text-left">
                <v-select
                    v-model="pagination.per_page"
                    :items="filtersPerPage"
                    outlined
                    single-line
                    hide-details
                    @change="updatePerPage"
                    item-text="TEXT"
                    item-value="VALUE"
                    class="staff_select ml-2 mb-2"
                    style="width: 90px"
                ></v-select>
              </v-col>
              <v-col class="text-right">
                <v-btn tile small :disabled="pagination.page <= 1  || loadingNoTracking" class="ma-1"
                       @click="updatePage('anterior')">Anterior
                </v-btn>
                <v-btn tile small color="secondary" class="ma-1" style="min-width: 34px;">{{ pagination.page }}</v-btn>
                <v-btn tile small :disabled="pickupsNoTracking.length !== pagination.per_page || loadingNoTracking"
                       class="ma-1" @click="updatePage('seguinte')">Seguinte
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <!-- MAIN CONTENT -->
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar class="mb-3" flat dense dark color="primary">
            <v-toolbar-title class="text-uppercase">{{ new Date().toJSON().slice(0, 10) }} | Total de
              Recolhas:
              {{ pickups.length }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!--            <v-btn small outlined @click="printManifestoSEUR()">Imprimir Manifesto SEUR</v-btn>-->
          </v-toolbar>
          <v-card-text>
            <v-simple-table class="staff_table">
              <thead>
              <tr>
                <th>Transp.</th>
                <th>Referência</th>
                <th>Estado</th>
                <th>Ref. Transp.</th>
                <th>Cod. Cliente</th>
                <th>Nome do Cliente</th>
                <th>Observação</th>
                <th>Notas</th>
                <th>Data Criação</th>
                <th class="text-center">Cancelar Recolha</th>
              </tr>
              <tr class="filters_row">
                <td>
                  <v-text-field
                      v-model="filters.courier"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickups()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.reference"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickups()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.status"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickups()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.trackingNumber"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickups()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.clientCode"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickups()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.clientName"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickups()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.observation"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickups()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.notes"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickups()"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                      v-model="filters.created_at"
                      outlined
                      hide-details
                      single-line
                      @keypress.enter="updatePickups()"
                  ></v-text-field>
                </td>
                <td></td>
              </tr>
              </thead>
              <tbody>
              <tr v-if="loading">
                <td colspan="10">
                  <v-progress-linear indeterminate></v-progress-linear>
                </td>
              </tr>
              <tr v-for="pickup in pickups">
                <td>{{ pickup.courier }}</td>
                <td>{{ pickup.reference }}</td>
                <td>{{ pickup.status }}</td>
                <td>{{ pickup.trackingNumber }}</td>
                <td>{{ pickup.clientCode }}</td>
                <td>{{ pickup.clientName }}</td>
                <td>{{ pickup.observation }}</td>
                <td>{{ pickup.notes }}</td>
                <td>{{ pickup.created_at }}</td>
                <td class="text-center">
                  <v-btn v-if="pickups.courier !== 'REDUR' && writePermission('orders/shipped')" color="error" small
                         @click="cancelPickup(pickup, true)"
                  >
                    <i class="fas fa-times"></i>
                  </v-btn>
                </td>
              </tr>
              </tbody>
            </v-simple-table>
            <v-row>
              <v-col class="text-left">
                <v-select
                    v-model="pagination.per_page"
                    :items="filtersPerPage"
                    outlined
                    single-line
                    hide-details
                    @change="updatePerPage"
                    item-text="TEXT"
                    item-value="VALUE"
                    class="staff_select ml-2 mb-2"
                    style="width: 90px"
                ></v-select>
              </v-col>
              <v-col class="text-right">
                <v-btn tile small :disabled="pagination.page <= 1  || loading" class="ma-1"
                       @click="updatePage('anterior')">Anterior
                </v-btn>
                <v-btn tile small color="secondary" class="ma-1" style="min-width: 34px;">{{ pagination.page }}</v-btn>
                <v-btn tile small :disabled="pickups.length !== pagination.per_page || loading" class="ma-1"
                       @click="updatePage('seguinte')">Seguinte
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog v-model="dialogConfirm" persistent max-width="500">
        <v-card>
          <v-card-title>Exclusão de Recolha</v-card-title>
          <v-card-text>
            Tem a certeza que deseja eliminar a recolha
            <strong>{{ currentPickup.reference }}?</strong>
          </v-card-text>
          <v-card-actions>
            <v-btn tile small @click="cancelPickup('',false)">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile small color="success" @click="cancelPickupConfirmation(currentPickup)">Confirmar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-dialog v-model="dialogNewPickup" persistent max-width="720">
        <v-card>
          <v-card-title>
            Dados da Recolha
          </v-card-title>
          <v-card-text style="color: #ce0000">
            Erro: {{pickupError}}
          </v-card-text>
          <v-card-text>
<!--            <v-form ref="form" >-->
              <v-row>
                <v-col>
                  <strong>Referência: {{ pickupData.info.reference }}</strong>
                </v-col>
                <v-col class="text-right">
                </v-col>
              </v-row>
            <br>
              <!-- Client -->
              <p class="ma-0 text-center" ><strong>Dados do Cliente: </strong></p>
              <v-text-field dense v-model="pickupData.client.clientCode" label="Código do cliente" outlined></v-text-field>
              <v-text-field dense counter="35" v-model="pickupData.client.clientName" label="Nome do cliente" outlined></v-text-field>
              <v-text-field dense v-model="pickupData.client.clientMail" label="E-mail" outlined></v-text-field>
              <v-text-field dense v-model="pickupData.client.phoneNumber" label="Número do telefone" outlined></v-text-field>

              <!-- Address -->
              <p class="ma-0"><strong>Dados da Morada: </strong></p>
              <v-text-field dense counter="60" v-model="pickupData.pickupAddress.address" outlined
                            label="Morada"></v-text-field>
              <v-text-field dense v-model="pickupData.pickupAddress.city"  outlined
                            label="Localidade"></v-text-field>
              <v-text-field dense v-model="pickupData.pickupAddress.zipCode" outlined
                            label="Código Postal"></v-text-field>
              <v-text-field v-if="pickupData.info.courier" dense v-model="pickupData.pickupAddress.cpLocality" outlined
                            label="Código Postal Localidade"></v-text-field>
              <v-text-field v-if="pickupData.info.courier" dense v-model="pickupData.pickupAddress.country" outlined
                            label="Pais"></v-text-field>

              <!-- Date -->
              <p class="ma-0"><strong>Data e Horários: </strong></p>
              <v-text-field dense v-model="pickupData.dateInfo.date" label="Data da Recolha" outlined></v-text-field>
              <v-text-field dense v-model="pickupData.dateInfo.initialTime" label="Hora Inicial" outlined></v-text-field>
              <v-text-field dense v-model="pickupData.dateInfo.finalTime" label="Hora Final" outlined></v-text-field>

              <!-- Info -->
              <p class="ma-0"><strong>Informações Adicionais: </strong></p>
              <v-text-field dense v-model="pickupData.info.courier" label="Transportadora" outlined></v-text-field>
              <v-text-field dense v-model="pickupData.info.observation" label="Observação" outlined></v-text-field>

          </v-card-text>
          <v-card-actions>
            <v-btn tile small @click="dialogNewPickup=false">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile small color="success" @click="confirmPickup()">Criar Recolha</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>

    </v-row>
  </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'

export default {
  data: () => ({
    dialogConfirm: false,
    currentPickup: '',
    loading: false,
    pickups: [],
    pickupsNoTracking: [],
    loadingNoTracking: false,
    newPickup: '',
    dialogNewPickup: '',
    pickupError: '',
    pickupData: {
      client:{
        clientCode: '',
        clientName: '',
        clientMail: '',
        phoneNumber: '',
      },
      pickupAddress: {
        address: '',
        city: '',
        zipCode: '',
        country: '',
        cpLocality: '',
      },
      info: {
        courier: '',
        observation: '',
        reference: '',
        quantity: '',
        weight: ''
      },
      dateInfo: {
        date: '',
        initialTime: '',
        finalTime: '',
      }

    },
    address: {
      Address: '',
      Address2: '',
      Cp: '',
      Locality: '',
      CpLocality: '',
      Country: '',
    },
    shipping: {
      volumes: '',
      weight: '',
      courier: '',
      reference: '',
      observation: '',
      idRegistration: ''
    },
    client: {
      Cliente: '',
      Nome: '',
      Fac_Tel: '',
      email: '',
    },
    rules: {
      required: value => !!value || value === '0' || i18n.t('required'),
    },
    filtersNoTracking: {
      id: '',
      idCliente: '',
      nomeCliente: '',
      brand: '',
      pickupCourier: '',
      created_at: ''
    },
    filters: {
      courier: '',
      reference: '',
      status: '',
      trackingNumber: '',
      clientCode: '',
      clientName: '',
      observation: '',
      notes: '',
      created_at: '',
    },
    pagination: {
      per_page: 250,
      page: 1
    },
    filtersPerPage: [
      {TEXT: '25', VALUE: 25},
      {TEXT: '50', VALUE: 50},
      {TEXT: '100', VALUE: 100},
      {TEXT: '250', VALUE: 250},
      {TEXT: '500', VALUE: 500}
    ],
  }),
  created() {
    document.title = 'Histórico de Recolhas'
    this.initialize()
  },
  methods: {
    initialize() {
      this.getUndeliveredPickups()
      this.getPickupsWithoutTracking()
    },
    getUndeliveredPickups() {
      this.loading = true
      this.pickups = []

      let request = {
        url: '/api/staff/orders/pickups',
        data: {
          filters: this.filters,
          pagination: this.pagination
        }
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {

            let responseData = response.data
            if (responseData.status === 'OK') {
              this.pickups = responseData.data
            }
            this.loading = false
          })
          .catch(response => {
            this.loading = false
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    getPickupsWithoutTracking() {
      this.loadingNoTracking = true
      this.pickupsNoTracking = []

      let request = {
        url: '/api/staff/orders/pickupsWithoutTracking',
        data: {
          filters: this.filtersNoTracking,
          pagination: this.pagination
        }
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {

            let responseData = response.data
            if (responseData.status === 'OK') {
              this.pickupsNoTracking = responseData.pickupsNoTracking
              this.loadingNoTracking = false
              // Alert.dispatch('show', {
              //   snackbar: true,
              //   text: this.pickups,
              //   type: 'error'
              // })
            }
          })
          .catch(response => {
            this.loadingNoTracking = false
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    writePermission(permission) {
      return User.getters.writePermission(permission)
    },
    cancelPickup(pickup, input) {
      this.dialogConfirm = input;
      this.currentPickup = pickup;
    },
    cancelPickupConfirmation(pickup) {
      this.loading = true
      let request = {
        url: '/api/staff/orders/unmarkAsShipped',
        data: {
          orderShipped: pickup
        }
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {
              this.getUndeliveredPickups()
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Recolha eliminada com sucesso!',
                type: 'success'
              })
            } else if (responseData.status === 'WARN') {
              Alert.dispatch('show', {
                snackbar: true,
                text: responseData.data,
                type: 'error'
              })
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao eliminar a recolha!',
                type: 'error'
              })
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            this.loading = false
          })
      this.dialogConfirm = false;
      this.currentPickup = '';
      this.loading = false
    },
    updatePickups() {
      this.getUndeliveredPickups();
    },
    updatePerPage() {
      this.pagination.page = 1
      this.initialize()
    },
    updatePage(option) {
      if (option === 'anterior') {
        this.pagination.page = this.pagination.page - 1
      } else {
        this.pagination.page = this.pagination.page + 1
      }
      this.initialize()
    },
    updatePickupsWithoutTracking() {
      this.getPickupsWithoutTracking();
    },
    redoPickup(pickup){
      this.currentPickup = pickup
      ApiWS.dispatch('makeGet', {url: '/api/staff/clients/pickup-error/' + pickup.id})
          .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {

              this.pickupError=  responseData.response;
              this.pickupData= responseData.request;
              this.dialogNewPickup = true;
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro de servidor!',
                type: 'error'
              })
            }

          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    confirmPickup() {

      this.mapData();

      let request = {
        url: '/api/staff/clients/0/makeShipping',
        data: {
          client: this.client,
          shipping: this.shipping,
          dates: this.pickupData.dateInfo
        }
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.updatePreRegistration(responseData.data)
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao recriar a recolha!',
                type: 'error'
              })
            }
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    mapData() {
      this.address.Address= this.pickupData.pickupAddress.address;
      this.address.Address2= '';
      this.address.Cp= this.pickupData.pickupAddress.zipCode;
      this.address.Locality= this.pickupData.pickupAddress.city;
      this.address.CpLocality= this.pickupData.pickupAddress.cpLocality;
      this.address.Country= this.pickupData.pickupAddress.country;

      this.shipping.volumes= this.pickupData.info.quantity;
      this.shipping.weight= this.pickupData.info.weight;
      this.shipping.courier= this.pickupData.info.courier;
      this.shipping.reference= this.pickupData.info.reference;
      this.shipping.observation= this.pickupData.info.reference;
      this.shipping.idRegistration = this.currentPickup.id;
      this.shipping.address =  this.address;

      this.client.Cliente= this.pickupData.client.clientCode;
      this.client.Nome= this.pickupData.client.clientName;
      this.client.Fac_Tel= this.pickupData.client.phoneNumber;
      this.client.email= this.pickupData.client.clientMail;
    },
    updatePreRegistration (data) {

      let request = {
        url: '/api/staff/clients/update-registration',
        data: {data: data}
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.dialogNewPickup = false;
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Recolha criada com sucesso!',
                type: 'success'
              })
              this.initialize();
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao recriar a recolha!',
                type: 'error'
              })
            }
          })
          .catch(() => {
            return false;
          })
    }
  },
  computed: {
    urlAPI() {
      return process.env.VUE_APP_API_URL
    },
    getTotalPages() {
      return Math.ceil(this.totalOrdersToSend / this.pagination.per_page)
    }
  }
}
</script>
