<template>
  <v-container fluid>
    <!-- PAGE TITLE -->
    <v-row>
      <!--      <v-col cols="12" md="3">-->
      <v-col>
        <page-title
            :title="$t('management')+' '+$t('products') + ' duplicados'"
            :subtitle="'A mostrar '+products.length+' '+$t('products')"
        ></page-title>
      </v-col>
      <v-col class="text-right">
        <v-btn tile small :disabled="totalSelected == 0" class="ml-2 mb-2" @click="cancelSelectedProducts()"
               color="error"><i class="fas fa-times mr-1"></i>Anular selecionados
        </v-btn>
<!--        <v-btn tile small class="ml-2 mb-2" @click="getPickingList()" color="info"><i class="fas fa-sync-alt mr-1"></i>Atualizar
        </v-btn>-->
      </v-col>
    </v-row>
    <!-- DATE FILTERS -->
    <v-row>
      <v-col class="text-right">
        <v-btn tile small v-if="pagination.page > 1" @click="pagination.page--">Anterior</v-btn>
        <v-btn tile small outlined disabled class="ma-1">{{ pagination.page }}</v-btn>
        <v-btn tile small v-if="pagination.per_page*pagination.page < products.length" @click="pagination.page++">
          {{ $t('next') }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- PRODUCTS TABLE -->
    <v-row>
      <v-col>
        <v-simple-table class="staff_table">
          <thead>
          <tr>
            <th class="text-center">({{ totalSelected }})</th>
            <th style="cursor: pointer;" @click="orderList('productId')">
              {{ $t('product') }}
              <i v-if="selectedHeader == 'productId'" class="fas"
                 :class="[headers['productId'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
            </th>
            <th style="cursor: pointer; white-space: nowrap;" @click="orderList('brand')">
              Marca
              <i v-if="selectedHeader == 'brand'" class="fas"
                 :class="[headers['brand'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
            </th>
            <th style="cursor: pointer">Descrição</th>
            <th style="cursor: pointer;" @click="orderList('ean')">EAN <i v-if="selectedHeader == 'ean'" class="fas"
                                                                          :class="[headers['ean'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
            </th>
            <th style="cursor: pointer;" @click="orderList('barcode')">Cod. Barras<i v-if="selectedHeader == 'barcode'"
                                                                                     class="fas"
                                                                                     :class="[headers['barcode'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
            </th>
            <th style="cursor: pointer;" @click="orderList('supplierCode')">Ref. Fornecedor<i
                v-if="selectedHeader == 'supplierCode'" class="fas"
                :class="[headers['supplierCode'] == 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
            <th style="cursor: pointer;">Tipo</th>
            <th style="cursor: pointer; white-space: nowrap;"></th>
          </tr>
          <tr class="filters_row">
            <td></td>
            <td>
              <v-text-field
                  v-model="filtersNew.productId.value"
                  dense
                  outlined
                  hide-details
                  v-on:keyup.enter="filterNew"
              ></v-text-field>
            </td>
            <td>
              <v-select
                  v-model="filtersNew.brand.value"
                  :items="availableBrands"
                  outlined
                  dense
                  @change="filterNew"
                  item-text="TEXT"
                  item-value="VALUE"
                  hide-details
                  style="width: 80px"
              ></v-select>
            </td>
            <td></td>
            <td>
              <v-text-field
                  v-model="filtersNew.ean.value"
                  dense
                  outlined
                  hide-details
                  v-on:keyup.enter="filterNew"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filtersNew.barcode.value"
                  dense
                  outlined
                  hide-details
                  v-on:keyup.enter="filterNew"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                  v-model="filtersNew.supplierCode.value"
                  dense
                  outlined
                  hide-details
                  v-on:keyup.enter="filterNew"
              ></v-text-field>
            </td>
            <td>
              <v-select
                  v-model="filtersNew.evaluator.value"
                  :items="filtersSelect"
                  outlined
                  dense
                  @change="filterNew"
                  item-text="TEXT"
                  item-value="VALUE"
                  hide-details
                  style="width: 80px"
              ></v-select>
            </td>
            <td></td>
          </tr>
          </thead>
          <tbody v-if="products.length > 0 || loading == true">
          <tr v-if="loading">
            <td colspan="12">
              <v-progress-linear indeterminate></v-progress-linear>
            </td>
          </tr>
          <tr v-for="product in pagedProducts" :key="product.productId">
            <td class="text-center d-flex">
              <v-checkbox
                  class="justify-center"
                  v-model="product.selected"
                  @click="productSelected(product)"
              ></v-checkbox>
            </td>
            <td>{{ product.productId }}</td>
            <td>{{ product.brand }}</td>
            <td>{{ product.description }}</td>
            <td>{{ product.ean }}</td>
            <td>{{ product.barcode }}</td>
            <td>{{ product.supplierCode }}</td>
            <td></td>
            <td>
              <v-btn color="warning" tile small @click="popupDuplicatedInfo(product)">
                Corrigir
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
        <v-banner v-if="loading == false && products.length == 0">
          <v-icon style="color: #04ff04;">mdi-thumb-up</v-icon>
          <span
              style="vertical-align: bottom;margin-left: 1vw;border-bottom-style: groove;border-bottom-color: #04ff04;">
              Não existêm produtos com informações duplicadas
            </span>
        </v-banner>
      </v-col>
    </v-row>
    <ProductEditing v-if="dialogDuplicatedProducts" v-on:close-editing="getProducts();dialogDuplicatedProducts = false"
                    :product="productToFix"/>


    <v-dialog v-model="dialogConfirm" persistent max-width="400">
      <v-card>
        <v-card-title>
          {{ $t('cancel') }} {{ $t('product') }}
        </v-card-title>
        <v-card-text>
          <strong>{{ $t('confirmCancelProducts') }}:</strong>
          <!--        <span> {{ productToCancel.productId + ' - ' + productToCancel.brandId + ' - ' + productToCancel.name }}?</span>-->
          <hr style="border: 1px solid black;">
          <br>
          <template v-for="product in selectedProducts">
            <v-row :key="product.productId">
              <v-col cols="11">
                <small>
                  {{ product.productId + ' - ' + product.brand + ' - ' + product.description }}
                </small>
              </v-col>
              <v-col cols="1">
                <v-progress-circular
                    :key="product.productId"
                    v-if="product.loading==='loading'"
                    indeterminate
                    color="lightgray"
                    :size="20"
                    :width="2"
                ></v-progress-circular>
                <template v-else-if="product.loading==='canceled'">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                  <span
                      v-bind="attrs"
                      v-on="on"
                  >
                     <i
                         style="font-size: x-large;color: green" class="fas fa-check-circle buttonSaveInfo"></i>
                  </span>
                    </template>
                    <span style="color:#31ff31">{{ product.message }}</span>
                  </v-tooltip>

                </template>
                <template v-else-if="product.loading==='error'">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                          v-bind="attrs"
                          v-on="on"
                      ><i
                          style="font-size: x-large;color: red" class="fas fa-times-circle buttonSaveInfo"></i></span>
                    </template>
                    <span style="color:#ff973e">{{ product.message }}</span>
                  </v-tooltip>

                </template>
              </v-col>

            </v-row>
            <hr style="border-top: 1px dotted lightgray;">
            <br>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogConfirm = false, getProducts()">Fechar</v-btn>
          <v-btn v-if="allowConfirm == true" color="success" style="margin-left: auto" @click="confirmCancel()">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ProductEditing from '@/components/Products/ProductEditing.vue';
import _ from "lodash";
import i18n from "../../plugins/i18n";

export default {
  data: function () {
    return {
      loading: false,
      products: [],
      productToFix: null,
      dialogConfirm: false,
      totalSelected: 0,
      dialogDuplicatedProducts: false,
      allowConfirm: true,
      pagination: {
        page: 1,
        per_page: 250
      },
      selectedProducts: [],
      paginatedProducts: [],
      filtersNew: {
        productId: {value: null, exact: false},
        ean: {value: null, exact: false},
        barcode: {value: null, exact: false},
        supplierCode: {value: null, exact: false},
        evaluator: {value: null, exact: true, bigger: true},
        brand: {value: null, exact: false},
      },
      originalProducts: [],
      selectedHeader: "productId",
      headers: {
        "productId": "asc",
        "ean": "asc",
        "barcode": "asc",
        "supplierCode": "asc",
        "brand": "asc",
      },
      filtersSelect: [
        {TEXT: 'Todos', VALUE: null},
        {TEXT: 'Nulos', VALUE: 0},
        {TEXT: 'Duplicados', VALUE: 1}
      ],
    }
  },
  computed: {
    availableBrands: function () {
      let brands = [...new Set(this.originalProducts.flatMap(product => {
        return product.brand;
        // else
        // return [];
      }))];
      brands = brands.sort().flatMap((product) => {
        if (product != "")
          return {TEXT: product, VALUE: product};
        else
          return [];
      })
      brands.splice(0, 0, {TEXT: 'Todas', VALUE: ''})
      return brands;
    },
    pagedProducts: function () {
      return this.orderedProducts.slice((this.pagination.page - 1) * this.pagination.per_page, this.pagination.page * this.pagination.per_page);
    },
    orderedProducts: function () {
      return _.orderBy(this.products, this.selectedHeader, this.headers[this.selectedHeader]);
    }
  },
  created() {
    document.title = i18n.t('management') + ' ' + i18n.t('products') + ' duplicados';
    this.getProducts();
  },
  components: {
    ProductEditing
  },
  methods: {
    filterNew() {
      let requestedFilters = [];
      Object.entries(this.filtersNew).forEach(([filter, value]) => {
        if (value.value != null) {
          requestedFilters[requestedFilters.length] = filter
        }
      });


      if (requestedFilters.length == 0) {
        this.products = this.originalProducts;
      } else {
        requestedFilters.forEach((filter, index) => {
          if (index == 0) {
            this.products = this.originalProducts.filter((product) => this.filtersNew[filter].exact ? (typeof this.filtersNew[filter].bigger !== undefined && this.filtersNew[filter].value !== 0 ? product[filter] >= this.filtersNew[filter].bigger : product[filter] == this.filtersNew[filter].value) : product[filter].toUpperCase().includes(this.filtersNew[filter].value.toUpperCase()));
          } else {
            this.products = this.products.filter((product) => this.filtersNew[filter].exact ? (typeof this.filtersNew[filter].bigger !== undefined && this.filtersNew[filter].value !== 0 ? product[filter] >= this.filtersNew[filter].bigger : product[filter] == this.filtersNew[filter].value) : product[filter].toUpperCase().includes(this.filtersNew[filter].value.toUpperCase()));
          }
        });
      }
    },
    orderList(col) {
      if (col == this.selectedHeader)
        this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
      else
        this.headers[this.selectedHeader] = "desc";

      this.selectedHeader = col;
      this.pagination.page = 1;
    },
    getProducts() {
      this.loading = true
      this.products = []

      let request = {
        url: api.products() + '/inconsistencies',
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.products = responseData.products
              this.originalProducts = responseData.products

              this.filterNew();
            }
            this.loading = false
          })
          .catch(response => {
            this.loading = false
          })
    },
    productSelected(product) {
      if (product.selected == true)
        this.totalSelected++;
      else
        this.totalSelected--;
    },
    confirmCancel() {
      this.allowConfirm = false;
      let promises = [];
      this.selectedProducts.forEach((product, index) => {
        let request = {
          url: '/api/staff/products/' + product.productId,
        };
        this.selectedProducts[index].loading = 'loading';
        this.selectedProducts.push();
        //promises.push(ApiWS.dispatch('makeDelete', request).then(()=>console.log('test')));
        promises.push(ApiWS.dispatch('makeDelete', request).then(response => {
              let responseData = response.data;
              product.message = responseData.message;
              if (responseData.status === 'OK') {
                product.loading = 'canceled';
                product.selected = false;
                this.totalSelected--;
              } else {
                product.loading = 'error';
              }
              this.selectedProducts.push();
            })
            .catch(() => {


            }));
      });

      /************* After all calls are made *********************/

     // Promise.all(promises).then(() => {

     // });
      /*Promise.all(promises).then(responses => {
        console.log(responses);
        for(let i=0;i<responses.length;i++){
          let responseData = responses[i].data;

          this.selectedProducts[i].message = responseData.message;
          if (responseData.status === 'OK') {
            this.selectedProducts[i].loading = 'canceled';
          } else {
            this.selectedProducts[i].loading = 'error';
            this.selectedProducts[i].selected = false;
          }
          this.selectedProducts.push();
        }

      }).catch(() => {

          });*/
    },
    cancelSelectedProducts() {
      this.allowConfirm = true;
      this.selectedProducts = this.pagedProducts.filter((product) => {
        product.loading = 'none';
        product.message = '';
        return product.selected == true
      });
      this.dialogConfirm = true
    },
    clearFilters() {
      this.filters = {
        productId: '',
        ean: '',
        barcode: '',
        supplierCode: '',
      }
      this.filtersNew = {
        productId: {value: '', exact: false},
        ean: {value: '', exact: false},
        barcode: {value: '', exact: false},
        supplierCode: {value: '', exact: true}
      }

      this.pagination.page = 1
      this.products = this.originalProducts;
    },
    popupDuplicatedInfo(productToFix) {
      this.productToFix = productToFix.productId;
      this.dialogDuplicatedProducts = true;
    },
  }
}
</script>

<style scoped>

</style>
