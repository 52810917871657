<style>
.form-question-number {
    input {
        text-align: center;
    }
}

.info-question {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
}

.forms-toolbar {
    background-color: black !important;
    border: 2px solid #F44336 !important;
}

.forms-toolbar-2 {
    background-color: rgba(0, 0, 0, 0.6) !important;
    border: 1px solid #F44336 !important;
}

.form-tabs {
    .v-tab {
        color: #F44336 !important;
    }

    .v-slide-group__content {
        color: #F44336 !important;
    }

    .v-tab--active {
        background-color: #F44336 !important;
        color: white !important;
    }
}

.form-add-button {
    background-color: #4caf50 !important;
    border: 2px solid #4caf50 !important;
    color: white !important;
    min-width: 150px !important;

    &:hover {
        background-color: black !important;

        .not-hover {
            display: none !important;
        }

        .hovered {
            display: block !important;
        }
    }
}

.form-remove-button {
    background-color: #F44336 !important;
    border: 2px solid #F44336 !important;
    color: white !important;
    min-width: 150px !important;

    &:hover {
        background-color: black !important;

        .not-hover {
            display: none !important;
        }

        .hovered {
            display: block !important;
        }
    }
}

.form-preview-button {
    background-color: gold !important;
    border: 2px solid gold !important;
    color: black !important;
    min-width: 150px !important;

    &:hover {
        background-color: rgba(0, 0, 0, .06) !important;
    }
}

.form-platform-info {
    .v-list-item__content {
        background-color: rgba(0, 0, 0, 0.06);
        margin-bottom: 8px;
        padding-bottom: 0px;

        .v-input__slot {
            background-color: white;
            font-size: 12px;
        }
    }
}

#delete-platform {
    min-width: 35px;
    padding: 4px;
    margin-right: 6px;
    color: black !important;

    &:hover {
        background-color: #F44336 !important;
        border-color: #F44336 !important;
        color: white !important;

        .not-hover {
            display: none !important;
        }

        .hover {
            display: block !important;
        }
    }
}

#add-platform {
    min-width: 100%;
    padding: 4px;
    color: black !important;

    &:hover {
        background-color: #4caf50 !important;
        border-color: #4caf50 !important;
        color: white !important;

        .not-hover {
            display: none !important;
        }

        .hover {
            display: block !important;
        }
    }
}

.info-steps {
    display: flex;
    margin-bottom: 0;
    padding: 12px 12px 6px 12px;
    background-color: lightblue;
    color: #4682b4;
    border: 1px solid #4682b4;
    font-size: 14px;
}

.form-date-picker {
    .v-picker__body {
        border: 1px solid;
    }
}

</style>
<template>
    <v-container fluid v-if="form && !loading">
        <v-row>
            <!-- INFO WHAT FORM IS EDITING -->
            <v-col style="display: flex; justify-content: center;border-bottom: 1px solid rgba(0,0,0,.05);">
                <p class="mb-0 pt-2" style="font-weight: bold; font-size: 16px">
                    <!--                    <span style="color: rgba(0,0,0,.5)">{{ form.platform }}</span>/<span-->
                    <!--                        style="color: rgba(0,0,0,.3)">{{ form.page }}</span>-->
                </p>
                <h2>
                    {{ originalForm.id + ' - ' + originalForm.name }}
                </h2>
                <v-spacer></v-spacer>
                <v-btn class="form-preview-button mr-2" style="max-width: 100px"
                       tile small target="_blank" :href="'https://b2b.bicimax.com/forms-preview/' + formId">
                    <v-icon size="20" class="pr-2">mdi-eye</v-icon>
                    VISUALIZAR
                </v-btn>
            </v-col>
        </v-row>


        <!-- DETAILS - FORM INFO -->
        <v-col class="ma-2">
            <v-card>

                <v-toolbar class="forms-toolbar" dense dark>
                    <v-toolbar-title class="subtitle-1">DEFINIÇÕES DO FORMULÁRIO</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success"
                           class="float-right" :disabled="!formInfoIsEdited"
                           @click="saveFormInfo()">Guardar
                    </v-btn>
                </v-toolbar>

                <v-row v-if="loading">
                    <v-col class="text-center">
                        <v-progress-circular
                                class="ma-12"
                                :size="100"
                                :width="2"
                                color="blue darken-4"
                                indeterminate
                        ></v-progress-circular>
                    </v-col>
                </v-row>

                <v-row no-gutters v-if="!loading" style="min-height: 400px">

                    <!-- BASE SETTINGS -->
                    <v-col class="ma-2">

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                    TITULO
                                    <v-text-field style="font-weight: normal" v-model="form.name"
                                                  :error="form.name == ''" outlined dense
                                                  @keyup="validateFormInfos" hide-details></v-text-field>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                    <p class="mb-0" style="display: flex;align-items: self-end;">
                                        Info formulário
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                        color="primary"
                                                        class="pl-1"
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        size="20"
                                                >
                                                    mdi-information
                                                </v-icon>
                                            </template>
                                            <span>Pequena informação sobre o tema <br>e objetivo do questionário.</span>
                                        </v-tooltip>
                                    </p>
                                    <v-textarea style="font-weight: normal" v-model="form.infoForm"
                                                :error="form.infoForm == ''" outlined dense
                                                @keyup="validateFormInfos" hide-details></v-textarea>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-col>

                    <!-- ESTADO/PLATAFORMAS -->
                    <v-col class="ma-2">

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                    <p class="mb-0" style="display: flex;align-items: self-end;">
                                        Estado
                                        <v-tooltip right>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon
                                                        color="primary"
                                                        class="pl-1"
                                                        dark
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        size="20"
                                                >
                                                    mdi-information
                                                </v-icon>
                                            </template>
                                            <span>Questionário está <strong>ativo</strong> ou <strong>inativo</strong> para os clientes</span>
                                        </v-tooltip>
                                    </p>
                                    <div>
                                        <v-btn tile small :color="form.active == '1' ? 'success' : ''"
                                               @click="form.active = !form.active;validateFormInfos();">Activo
                                        </v-btn>
                                        <v-btn tile small :color="form.active == '1' ? '' : 'error'"
                                               @click="form.active = !form.active;validateFormInfos();">Inactivo
                                        </v-btn>
                                    </div>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase"
                                               style="height: 48px;display: flex;">
                                <p class="mb-0" style="display: flex;align-items: self-end;">
                                    Plataforma/Páginas
                                    <v-tooltip right>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon
                                                    color="primary"
                                                    class="pl-1"
                                                    dark
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    size="20"
                                            >
                                                mdi-information
                                            </v-icon>
                                        </template>
                                        <span>Em que plataforma interna irá aparecer, <br> e em que página da mesma.</span>
                                    </v-tooltip>
                                </p>
                            </v-list-item-title>
                        </v-list-item>
                        <div class="form-platform-info" v-for="(platform,index) in platforms">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-col cols="5" class="pt-0 mb-0">
                                        <v-list-item-title class="subtitle-2 text-uppercase">
                                            Plataforma
                                            <!--                                            <v-text-field style="font-weight: normal" v-model="platform.platform"-->
                                            <!--                                                          outlined dense-->
                                            <!--                                                          hide-details></v-text-field>-->
                                            <v-select
                                                    :items="availablePlatforms"
                                                    item-value="value"
                                                    item-text="title"
                                                    v-model="platform.platform"
                                                    @change="validateFormInfos"
                                                    dense
                                                    outlined
                                                    hide-details
                                            ></v-select>
                                        </v-list-item-title>
                                    </v-col>
                                    <v-col cols="6" class="pt-0 mb-0">
                                        <v-list-item-title class="subtitle-2 text-uppercase">
                                            Página
                                            <v-select
                                                    :items="availablePages"
                                                    item-value="value"
                                                    item-text="title"
                                                    v-model="platform.page"
                                                    @change="validateFormInfos"
                                                    dense
                                                    outlined
                                                    hide-details
                                            ></v-select>
                                        </v-list-item-title>
                                    </v-col>
                                    <v-col cols="1" class="pa-0">
                                        <v-btn tile small
                                               depressed outlined
                                               id="delete-platform"
                                               class="float-right ml-2 white--text "
                                               @click="deletePlatform(platform.id)">
                                            <v-icon class="not-hover" size="16">mdi-delete</v-icon>
                                            <v-icon class="hover" style="display: none" size="16">mdi-delete-empty
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                        </div>

                        <!-- SE FOR ULTIMO ELEMENTO ACRESCENTA -->
                        <div class="form-platform-info">
                            <v-list-item>
                                <v-list-item-content class="pt-0">
                                    <v-col cols="12" class="pa-0">
                                        <v-btn tile small
                                               depressed outlined
                                               id="add-platform"
                                               class="float-right ml-2 white--text "
                                               @click="addPlatform()">
                                            ADICIONAR PLATAFORMA
                                            <v-icon class="not-hover" size="18">mdi-plus</v-icon>
                                            <v-icon class="hover" style="display: none" size="16">mdi-pencil-plus
                                            </v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-list-item-content>
                            </v-list-item>
                        </div>

                    </v-col>

                    <!-- DATAS -->
                    <v-col class="ma-2" sm="auto">
                        <v-row no-gutters style="background-color: white">
                            <v-col class="mx-2" v-if="form.startDate != undefined">
                                <p class="mb-0 text-center" style="font-weight: 700;font-size: 0.875rem">DATA INICIO</p>
                                <v-date-picker class="form-date-picker" color="#F44336" @change="validateFormInfos"
                                               v-model="editedFormStartDate"></v-date-picker>
                            </v-col>
                            <v-col v-if="form.endDate != undefined">
                                <p class="mb-0 text-center" style="font-weight: 700;font-size: 0.875rem">DATA FIM</p>
                                <v-date-picker class="form-date-picker" color="#F44336" @change="validateFormInfos"
                                               v-model="editedFormEndDate"></v-date-picker>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>
            </v-card>
        </v-col>

        <!-- STEPS - FORM STEPS -->
        <v-col class="ma-2">
            <v-card>

                <v-toolbar class="forms-toolbar" dense dark>
                    <v-toolbar-title class="subtitle-1">PASSOS DO FORMULÁRIO</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="saveStepInfo"
                           class="float-right" :disabled="!isEdited">Guardar
                    </v-btn>
                    <v-btn tile small color="red"
                           class="float-right ml-2" @click="resetValues">Cancelar
                    </v-btn>
                </v-toolbar>

                <!-- TABS - 1 TAB FOR EACH STEP -->
                <v-row>
                    <v-col>
                        <div class="info-steps">
                            <v-icon class="pr-2" color="#4682b4">mdi-information</v-icon>
                            <p class="mb-0">Secção responsável pela gestão de cada passo do questionário.<br> Pode
                                selecionar o passo que pertende editar, criar um novo passo e ver todas as questões
                                associadas ao mesmo.</p>
                        </div>
                        <v-tabs class="form-tabs" v-model="tabSelected">
                            <v-tab v-for="step of steps" :key="step.id" :disabled="isEdited"
                                   @click="updateSelectedStep(step.stepNumber)">
                                {{ step.stepNumber }}
                            </v-tab>

                            <p v-if="isEdited"
                               style="display: flex;margin-bottom: 0;align-items: center;color: red;font-size: 12px">
                                <strong>ATENÇÃO! </strong>- Alterações efetuadas, deve guardar para que tomem efeito.
                            </p>

                            <v-spacer></v-spacer>
                            <v-btn class="form-add-button mr-2" style="margin-top: auto;margin-bottom: auto;"
                                   tile small
                                   @click="addTab" :disabled="isEdited">
                                <v-icon class="not-hover" size="20">mdi-plus</v-icon>
                                <v-icon class="hovered" size="16" style="display: none">mdi-pencil-plus</v-icon>
                                Add Step
                            </v-btn>
                            <v-btn class="form-remove-button mr-2" style="margin-top: auto;margin-bottom: auto;"
                                   tile small
                                   :disabled="this.steps.length != this.stepSelected.stepNumber || stepEdited.questions != undefined"
                                   @click="deleteStep(stepEdited.id)">
                                <v-icon class="not-hover" size="18">mdi-delete</v-icon>
                                <v-icon class="hovered" size="17" style="display: none">mdi-delete-empty</v-icon>
                                APAGAR STEP
                            </v-btn>
                        </v-tabs>

                        <!-- CALL STEP COMPONENT FOR EACH STEP --->
                        <!--                        <FormStep :step="stepSelected"></FormStep>-->
                        <v-row no-gutters v-if="!loading && stepEdited != undefined">
                            <!--                            {{ stepSelected }} / {{ stepEdited }}-->

                            <!-- QUESTIONS OF STEP PT -->
                            <v-col class="ma-2">
                                <v-card>
                                    <v-toolbar class="forms-toolbar-2" dark dense>
                                        <v-toolbar-title class="subtitle-1">TITULO E DESCRICAO - PT</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <flag iso="pt" v-bind:squared=false class="float-right"></flag>
                                    </v-toolbar>

                                    <v-card-text class="pb-0" v-if="stepEdited">
                                        <!-- STEP TITLE -->
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                                    <p class="mb-0" style="display: flex;align-items: self-end;">
                                                        Step Title:
                                                        <v-tooltip right>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                        color="primary"
                                                                        class="pl-1"
                                                                        dark
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        size="20"
                                                                >
                                                                    mdi-information
                                                                </v-icon>
                                                            </template>
                                                            <span>Titulo correspondente ao passo selecionado.</span>
                                                        </v-tooltip>
                                                    </p>
                                                    <v-text-field style="font-weight: normal"
                                                                  v-model="stepEdited.stepDescription[0].title" outlined
                                                                  :error="stepEdited.stepDescription[0].title == ''"
                                                                  dense
                                                                  @keyup="validate()"
                                                                  hide-details></v-text-field>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <!-- STEP VALUE (description) -->
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                                    <p class="mb-0" style="display: flex;align-items: self-end;">
                                                        Step description:
                                                        <v-tooltip right>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon
                                                                        color="primary"
                                                                        class="pl-1"
                                                                        dark
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        size="20"
                                                                >
                                                                    mdi-information
                                                                </v-icon>
                                                            </template>
                                                            <span>Descrição a mostrar ao cliente sobre o passo selecionado.</span>
                                                        </v-tooltip>
                                                    </p>
                                                    <v-textarea style="font-weight: normal"
                                                                v-model="stepEdited.stepDescription[0].value" outlined
                                                                :error="stepEdited.stepDescription[0].value == ''"
                                                                dense
                                                                @keyup="validate()"
                                                                hide-details></v-textarea>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-card-text>
                                </v-card>
                            </v-col>


                            <!-- QUESTIONS OF STEP ES -->
                            <v-col class="ma-2">
                                <v-card>
                                    <v-toolbar class="forms-toolbar-2" dark dense>
                                        <v-toolbar-title class="subtitle-1">TITULO E DESCRICAO - ES</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <flag iso="es" v-bind:squared=false class="float-right"></flag>
                                    </v-toolbar>

                                    <v-card-text class="pb-0" v-if="stepEdited">
                                        <!-- STEP TITLE -->
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                                    <p class="mb-0"
                                                       style="display: flex;align-items: self-end;min-height: 20px">
                                                        Step Title:
                                                    </p>
                                                    <v-text-field style="font-weight: normal"
                                                                  v-model="stepEdited.stepDescription[1].title"
                                                                  :error="stepEdited.stepDescription[1].title == ''"
                                                                  outlined dense
                                                                  @keyup="validate()"
                                                                  hide-details></v-text-field>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <!-- STEP VALUE (description) -->
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                                    Step description:
                                                    <v-textarea style="font-weight: normal"
                                                                v-model="stepEdited.stepDescription[1].value" outlined
                                                                :error="stepEdited.stepDescription[1].value == ''"
                                                                dense
                                                                @keyup="validate()"
                                                                hide-details></v-textarea>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>

                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-col class="col-md-12">
                            <v-card>
                                <v-toolbar class="forms-toolbar-2" dark dense>
                                    <v-toolbar-title class="subtitle-1">QUESTÕES</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn tile small
                                           class="form-add-button float-right"
                                           :disabled="stepEdited.id == undefined"
                                           @click="addQuestion">
                                        <v-icon class="not-hover" size="20">mdi-plus</v-icon>
                                        <v-icon class="hovered" size="16" style="display: none">mdi-pencil-plus</v-icon>
                                        Adicionar
                                    </v-btn>
                                </v-toolbar>

                                <v-card-text>
                                    <div class="info-steps mb-2">
                                        <v-icon class="pr-2" color="#4682b4">mdi-information</v-icon>
                                        <p class="mb-0">Lista de questões associadas ao passo selecionado. Pode
                                            adicionar novas questões ou alterar as questões abaixo listadas.
                                            <br>
                                            <strong>Posição</strong> -> ordem da questão no passo;
                                            <strong>Tipo</strong> -> tipo de questão;
                                            <strong>Obrigatório</strong> -> questão de resposta obrigatória;
                                        </p>
                                    </div>
                                    <!-- SHOW LIST OF QUESTIONS -->
                                    <v-simple-table class="staff_table">
                                        <thead>
                                        <tr>
                                            <th style="width: 50px;" class="text-center">ID</th>
                                            <th style="width: 50px;">Posição</th>
                                            <th class="text-center" style="width: 100px;">Tipo</th>
                                            <th class="text-center" style="width: 100px;">
                                                <p class="mb-0" style="display: flex;align-items: center;">
                                                    Q.Acitvadora
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon
                                                                    color="primary"
                                                                    class="pl-1"
                                                                    dark
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    size="20"
                                                            >
                                                                mdi-information
                                                            </v-icon>
                                                        </template>
                                                        <span>Pergunta necessita de uma resposta especifica <br> do cliente para responder á questão.</span>
                                                    </v-tooltip>
                                                </p>
                                            </th>
                                            <th class="text-center" style="width: 6%">Obrigatório</th>
                                            <th>Pergunta</th>
                                            <th style="width: 5%"></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-if="stepEdited.questions" v-for="question in stepEdited.questions">
                                            <td class="text-center">{{ question.id }}</td>
                                            <td class="text-center">{{ question.orderOnStep }}</td>
                                            <td class="text-center">{{ question.typeOfQuestion }}</td>
                                            <td class="text-center">
                                                <v-icon size="18" color="info"
                                                        v-if="question.required_option != undefined">mdi-marker-check
                                                </v-icon>
                                            </td>
                                            <td class="text-center">
                                                <v-icon v-if="!question.required" color="red" size="18">
                                                    mdi-close-circle
                                                </v-icon>
                                                <v-icon v-if="question.required" color="success" size="18">
                                                    mdi-checkbox-marked-circle
                                                </v-icon>
                                            </td>
                                            <td v-if="question.typeOfQuestion == 'group-radio'">
                                                <flag iso="pt" v-bind:squared=false></flag>
                                                PT -
                                                {{
                                                    question.tableGroupHeader[0].headers[question.tableGroupHeader[0].headers.findIndex(header => header.language == 'pt')].value
                                                }}
                                                /
                                                <flag iso="es" v-bind:squared=false></flag>
                                                ES -
                                                {{
                                                    question.tableGroupHeader[0].headers[question.tableGroupHeader[0].headers.findIndex(header => header.language == 'es')].value
                                                }}
                                            </td>
                                            <td v-else>
                                                <flag iso="pt" v-bind:squared=false></flag>
                                                PT -
                                                {{
                                                    question.headers[question.headers.findIndex(header => header.language == 'pt')].value
                                                }}
                                                /
                                                <flag iso="es" v-bind:squared=false></flag>
                                                ES -
                                                {{
                                                    question.headers[question.headers.findIndex(header => header.language == 'es')].value
                                                }}
                                            </td>

                                            <td class="text-right">
                                                <!-- BUTTON THAT CALL POP-UP TO EDIT QUESTION -->
                                                <v-btn @click="OpenDialogEditQuestion(question)"
                                                       tile small color="warning">
                                                    Editar
                                                </v-btn>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>

                                    <v-dialog
                                            v-model="editingQuestion"
                                            persistent
                                            max-width="90%"
                                    >
                                        <!-- COMPONENT TO QUESTION DESCRIPTION -->
                                        <FormStepQuestion :question="editedQuestion" :editedQuestion="clonedQuestion"
                                                          :newQuestion="false" :stepEdited="stepEdited"
                                                          @close-modal="editingQuestion = false"
                                                          @get-form="getForm()"></FormStepQuestion>

                                    </v-dialog>

                                    <v-dialog
                                            v-model="editNewQuestion"
                                            persistent
                                            max-width="90%"
                                    >
                                        <!-- COMPONENT TO QUESTION DESCRIPTION -->
                                        <FormStepQuestion :question="newEmptyQuestion"
                                                          :editedQuestion="clonedNewQuestion"
                                                          :newQuestion="true" :stepEdited="stepEdited"
                                                          @close-modal="editNewQuestion = false"
                                                          @get-form="getForm()"></FormStepQuestion>

                                    </v-dialog>

                                </v-card-text>
                            </v-card>
                        </v-col>


                        <!--                        <div style="background-color: black;color: white">-->
                        <!--                            tab -> {{tabSelected}} | step -> {{stepSelected}} | stepEdited -> {{stepEdited}}-->
                        <!--                        </div>-->

                        <!-- IDEA 2 -->
                        <!--                        <v-stepper v-model="tabSelected" vertical>-->
                        <!--                            <v-stepper-step-->
                        <!--                                    v-for="step of steps"-->
                        <!--                                    :key="step.id"-->
                        <!--                                    :complete="step.stepNumber < tabSelected"-->
                        <!--                                    :step="step.stepNumber"-->
                        <!--                            >-->
                        <!--                                {{ step.stepNumber }}-->
                        <!--                                <small>{{ step.title }}</small>-->
                        <!--                            </v-stepper-step>-->

                        <!--                            &lt;!&ndash; CONTENT FOR EACH STEP &ndash;&gt;-->
                        <!--                            <v-stepper-content v-for="step of steps" :step="step.stepNumber - 1">-->
                        <!--                                <v-row no-gutters v-if="!loading && stepEdited != undefined">-->
                        <!--                                    &lt;!&ndash;                            {{ stepSelected }} / {{ stepEdited }}&ndash;&gt;-->

                        <!--                                    &lt;!&ndash; QUESTIONS OF STEP PT &ndash;&gt;-->
                        <!--                                    <v-col class="ma-2">-->
                        <!--                                        <v-card>-->
                        <!--                                            <v-toolbar class="forms-toolbar-2" dark dense>-->
                        <!--                                                <v-toolbar-title class="subtitle-1">STEP INFO PT</v-toolbar-title>-->
                        <!--                                                <v-spacer></v-spacer>-->
                        <!--                                                <flag iso="pt" v-bind:squared=false class="float-right"></flag>-->
                        <!--                                            </v-toolbar>-->

                        <!--                                            <v-card-text class="pb-0" v-if="stepEdited">-->
                        <!--                                                &lt;!&ndash; STEP TITLE &ndash;&gt;-->
                        <!--                                                <v-list-item>-->
                        <!--                                                    <v-list-item-content>-->
                        <!--                                                        <v-list-item-title-->
                        <!--                                                                class="subtitle-2 font-weight-bold text-uppercase">-->
                        <!--                                                            Step Title:-->
                        <!--                                                            <v-text-field style="font-weight: normal"-->
                        <!--                                                                          v-model="stepEdited.stepDescription[0].title"-->
                        <!--                                                                          outlined-->
                        <!--                                                                          :error="stepEdited.stepDescription[0].title == ''"-->
                        <!--                                                                          dense-->
                        <!--                                                                          @keyup="validate()"-->
                        <!--                                                                          hide-details></v-text-field>-->
                        <!--                                                        </v-list-item-title>-->
                        <!--                                                    </v-list-item-content>-->
                        <!--                                                </v-list-item>-->

                        <!--                                                &lt;!&ndash; STEP VALUE (description) &ndash;&gt;-->
                        <!--                                                <v-list-item>-->
                        <!--                                                    <v-list-item-content>-->
                        <!--                                                        <v-list-item-title-->
                        <!--                                                                class="subtitle-2 font-weight-bold text-uppercase">-->
                        <!--                                                            Step description:-->
                        <!--                                                            <v-textarea style="font-weight: normal"-->
                        <!--                                                                        v-model="stepEdited.stepDescription[0].value"-->
                        <!--                                                                        outlined-->
                        <!--                                                                        :error="stepEdited.stepDescription[0].value == ''"-->
                        <!--                                                                        dense-->
                        <!--                                                                        @keyup="validate()"-->
                        <!--                                                                        hide-details></v-textarea>-->
                        <!--                                                        </v-list-item-title>-->
                        <!--                                                    </v-list-item-content>-->
                        <!--                                                </v-list-item>-->

                        <!--                                            </v-card-text>-->
                        <!--                                        </v-card>-->
                        <!--                                    </v-col>-->


                        <!--                                    &lt;!&ndash; QUESTIONS OF STEP ES &ndash;&gt;-->
                        <!--                                    <v-col class="ma-2">-->
                        <!--                                        <v-card>-->
                        <!--                                            <v-toolbar class="forms-toolbar-2" dark dense>-->
                        <!--                                                <v-toolbar-title class="subtitle-1">STEP INFO ES</v-toolbar-title>-->
                        <!--                                                <v-spacer></v-spacer>-->
                        <!--                                                <flag iso="es" v-bind:squared=false class="float-right"></flag>-->
                        <!--                                            </v-toolbar>-->

                        <!--                                            <v-card-text class="pb-0" v-if="stepEdited">-->
                        <!--                                                &lt;!&ndash; STEP TITLE &ndash;&gt;-->
                        <!--                                                <v-list-item>-->
                        <!--                                                    <v-list-item-content>-->
                        <!--                                                        <v-list-item-title-->
                        <!--                                                                class="subtitle-2 font-weight-bold text-uppercase">-->
                        <!--                                                            Step Title:-->
                        <!--                                                            <v-text-field style="font-weight: normal"-->
                        <!--                                                                          v-model="stepEdited.stepDescription[1].title"-->
                        <!--                                                                          :error="stepEdited.stepDescription[1].title == ''"-->
                        <!--                                                                          outlined dense-->
                        <!--                                                                          @keyup="validate()"-->
                        <!--                                                                          hide-details></v-text-field>-->
                        <!--                                                        </v-list-item-title>-->
                        <!--                                                    </v-list-item-content>-->
                        <!--                                                </v-list-item>-->

                        <!--                                                &lt;!&ndash; STEP VALUE (description) &ndash;&gt;-->
                        <!--                                                <v-list-item>-->
                        <!--                                                    <v-list-item-content>-->
                        <!--                                                        <v-list-item-title-->
                        <!--                                                                class="subtitle-2 font-weight-bold text-uppercase">-->
                        <!--                                                            Step description:-->
                        <!--                                                            <v-textarea style="font-weight: normal"-->
                        <!--                                                                        v-model="stepEdited.stepDescription[1].value"-->
                        <!--                                                                        outlined-->
                        <!--                                                                        :error="stepEdited.stepDescription[1].value == ''"-->
                        <!--                                                                        dense-->
                        <!--                                                                        @keyup="validate()"-->
                        <!--                                                                        hide-details></v-textarea>-->
                        <!--                                                        </v-list-item-title>-->
                        <!--                                                    </v-list-item-content>-->
                        <!--                                                </v-list-item>-->

                        <!--                                            </v-card-text>-->
                        <!--                                        </v-card>-->
                        <!--                                    </v-col>-->
                        <!--                                </v-row>-->

                        <!--                                <v-col class="col-md-12">-->
                        <!--                                    <v-card>-->
                        <!--                                        <v-toolbar class="forms-toolbar-2" dark dense>-->
                        <!--                                            <v-toolbar-title class="subtitle-1">QUESTÕES</v-toolbar-title>-->
                        <!--                                            <v-spacer></v-spacer>-->
                        <!--                                            <v-btn tile small-->
                        <!--                                                   class="form-add-button float-right"-->
                        <!--                                                   :disabled="stepEdited.id == undefined"-->
                        <!--                                                   @click="addQuestion">-->
                        <!--                                                <v-icon class="not-hover" size="20">mdi-plus</v-icon>-->
                        <!--                                                <v-icon class="hovered" size="16" style="display: none">-->
                        <!--                                                    mdi-pencil-plus-->
                        <!--                                                </v-icon>-->
                        <!--                                                Adicionar-->
                        <!--                                            </v-btn>-->
                        <!--                                        </v-toolbar>-->

                        <!--                                        <v-card-text>-->
                        <!--                                            &lt;!&ndash; SHOW LIST OF QUESTIONS &ndash;&gt;-->
                        <!--                                            <v-simple-table class="staff_table">-->
                        <!--                                                <thead>-->
                        <!--                                                <tr>-->
                        <!--                                                    <th style="width: 50px;" class="text-center">ID</th>-->
                        <!--                                                    <th style="width: 50px;">Posição</th>-->
                        <!--                                                    <th class="text-center" style="width: 100px;">Tipo</th>-->
                        <!--                                                    <th class="text-center" style="width: 100px;">Q.Acitvadora</th>-->
                        <!--                                                    <th class="text-center" style="width: 6%">Obrigatório</th>-->
                        <!--                                                    <th>Pergunta</th>-->
                        <!--                                                    <th style="width: 5%"></th>-->
                        <!--                                                </tr>-->
                        <!--                                                </thead>-->
                        <!--                                                <tbody>-->
                        <!--                                                <tr v-if="stepEdited.questions"-->
                        <!--                                                    v-for="question in stepEdited.questions">-->
                        <!--                                                    <td class="text-center">{{ question.id }}</td>-->
                        <!--                                                    <td class="text-center">{{ question.orderOnStep }}</td>-->
                        <!--                                                    <td class="text-center">{{ question.typeOfQuestion }}</td>-->
                        <!--                                                    <td class="text-center">-->
                        <!--                                                        <v-icon size="18" color="info"-->
                        <!--                                                                v-if="question.required_option != undefined">-->
                        <!--                                                            mdi-marker-check-->
                        <!--                                                        </v-icon>-->
                        <!--                                                    </td>-->
                        <!--                                                    <td class="text-center">-->
                        <!--                                                        <v-icon v-if="!question.required" color="red" size="18">-->
                        <!--                                                            mdi-close-circle-->
                        <!--                                                        </v-icon>-->
                        <!--                                                        <v-icon v-if="question.required" color="success" size="18">-->
                        <!--                                                            mdi-checkbox-marked-circle-->
                        <!--                                                        </v-icon>-->
                        <!--                                                    </td>-->
                        <!--                                                    <td v-if="question.typeOfQuestion == 'group-radio'">-->
                        <!--                                                        <flag iso="pt" v-bind:squared=false></flag>-->
                        <!--                                                        PT - -->
                        <!--                                                        {{-->
                        <!--                                                            question.tableGroupHeader[0].headers[question.tableGroupHeader[0].headers.findIndex(header => header.language == 'pt')].value-->
                        <!--                                                        }}-->
                        <!--                                                        /-->
                        <!--                                                        <flag iso="es" v-bind:squared=false></flag>-->
                        <!--                                                        ES - -->
                        <!--                                                        {{-->
                        <!--                                                            question.tableGroupHeader[0].headers[question.tableGroupHeader[0].headers.findIndex(header => header.language == 'es')].value-->
                        <!--                                                        }}-->
                        <!--                                                    </td>-->
                        <!--                                                    <td v-else>-->
                        <!--                                                        <flag iso="pt" v-bind:squared=false></flag>-->
                        <!--                                                        PT - -->
                        <!--                                                        {{-->
                        <!--                                                            question.headers[question.headers.findIndex(header => header.language == 'pt')].value-->
                        <!--                                                        }}-->
                        <!--                                                        /-->
                        <!--                                                        <flag iso="es" v-bind:squared=false></flag>-->
                        <!--                                                        ES - -->
                        <!--                                                        {{-->
                        <!--                                                            question.headers[question.headers.findIndex(header => header.language == 'es')].value-->
                        <!--                                                        }}-->
                        <!--                                                    </td>-->

                        <!--                                                    <td class="text-right">-->
                        <!--                                                        &lt;!&ndash; BUTTON THAT CALL POP-UP TO EDIT QUESTION &ndash;&gt;-->
                        <!--                                                        <v-btn @click="OpenDialogEditQuestion(question)"-->
                        <!--                                                               tile small color="warning">-->
                        <!--                                                            Editar-->
                        <!--                                                        </v-btn>-->
                        <!--                                                    </td>-->
                        <!--                                                </tr>-->
                        <!--                                                </tbody>-->
                        <!--                                            </v-simple-table>-->

                        <!--                                            <v-dialog-->
                        <!--                                                    v-model="editingQuestion"-->
                        <!--                                                    persistent-->
                        <!--                                                    max-width="90%"-->
                        <!--                                            >-->
                        <!--                                                &lt;!&ndash; COMPONENT TO QUESTION DESCRIPTION &ndash;&gt;-->
                        <!--                                                <FormStepQuestion :question="editedQuestion"-->
                        <!--                                                                  :editedQuestion="clonedQuestion"-->
                        <!--                                                                  :newQuestion="false" :stepEdited="stepEdited"-->
                        <!--                                                                  @close-modal="editingQuestion = false"-->
                        <!--                                                                  @get-form="getForm()"></FormStepQuestion>-->

                        <!--                                            </v-dialog>-->

                        <!--                                            <v-dialog-->
                        <!--                                                    v-model="editNewQuestion"-->
                        <!--                                                    persistent-->
                        <!--                                                    max-width="90%"-->
                        <!--                                            >-->
                        <!--                                                &lt;!&ndash; COMPONENT TO QUESTION DESCRIPTION &ndash;&gt;-->
                        <!--                                                <FormStepQuestion :question="newEmptyQuestion"-->
                        <!--                                                                  :editedQuestion="clonedNewQuestion"-->
                        <!--                                                                  :newQuestion="true" :stepEdited="stepEdited"-->
                        <!--                                                                  @close-modal="editNewQuestion = false"-->
                        <!--                                                                  @get-form="getForm()"></FormStepQuestion>-->

                        <!--                                            </v-dialog>-->

                        <!--                                        </v-card-text>-->
                        <!--                                    </v-card>-->
                        <!--                                </v-col>-->

                        <!--                                &lt;!&ndash; OPTIONAL: BUTTONS FOR NAVIGATION &ndash;&gt;-->
                        <!--                                <div>-->
                        <!--                                    <v-btn-->
                        <!--                                            color="primary"-->
                        <!--                                            @click="tabSelected + 1"-->
                        <!--                                    >-->
                        <!--                                        Continue-->
                        <!--                                    </v-btn>-->
                        <!--                                    <v-btn text @click="tabSelected - 1">-->
                        <!--                                        Cancel-->
                        <!--                                    </v-btn>-->
                        <!--                                </div>-->
                        <!--                            </v-stepper-content>-->
                        <!--                        </v-stepper>-->

                    </v-col>
                </v-row>


            </v-card>
        </v-col>

    </v-container>

</template>
<script>
import axios from "axios";
import {formatDate} from "lang/lib/time";
import Alert from "@/models/Alert";
import FormStepQuestion from "@/components/Formularios/FormStepQuestion.vue";

export default {
    components: {FormStepQuestion},
    data: function () {
        return {
            loading: true,
            formId: null,
            form: {},
            originalForm: {},
            formInfoIsEdited: false,
            editedFormStartDate: '',
            editedFormEndDate: '',
            steps: {},
            stepSelected: {},
            stepEdited: {},
            platforms: {},
            originalPlatforms: {},
            isEdited: false,
            originalSteps: {},
            editedFormSteps: [],
            tabSelected: 0,
            lang: null,
            questions: {},
            stepQuestionsPT: {},
            stepQuestionsES: {},
            editingQuestion: false,
            editedQuestion: {},
            clonedQuestion: {},
            newEmptyStep: {
                questions: [],
                stepDescription: [
                    {language: 'pt', title: '', value: ''},
                    {language: 'es', title: '', value: ''}
                ],
                stepNumber: null
            },
            newEmptyQuestion: {
                headers: [
                    {formQuestionId: null, language: 'pt', value: ''},
                    {formQuestionId: null, language: 'es', value: ''}
                ],
                options: [],
                orderOnStep: '',
                required: false,
                typeOfQuestion: "text-field",
                tableGroupHeader: [
                    {
                        id: 'new',
                        headers: [
                            {language: 'pt', value: ''},
                            {language: 'es', value: ''}
                        ]
                    }
                ],
                tableGroupQuestions: []
            },
            newEmptyPlatform: {
                formId: 'new',
                platform: '',
                page: ''
            },
            editNewQuestion: false,
            clonedNewQuestion: {},
            availablePlatforms: [
                {"title": "b2b", "value": "b2b"}
            ],
            availablePages: [
                {"title": "Service Center", "value": "service-center", "platform": "b2b"},
                {"title": "Index", "value": "index", "platform": "b2b"}
            ]
        }
    },
    mounted() {
        this.formId = this.$route.params.id;
        this.getForm();
    },
    methods: {
        formatDate,
        //Get form info
        getForm() {
            let loader = this.$loading.show();
            axios.get(api.form(this.formId))
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.originalForm = responseData.data[0]
                            this.form = JSON.parse(JSON.stringify(this.originalForm))
                            // console.log(this.form)

                            //dates
                            this.editedFormStartDate = this.form.startDate.substr(0, 10);
                            this.editedFormEndDate = this.form.endDate.substr(0, 10);

                            //create object with steps
                            if (this.form.steps == null) {
                                let newStep = JSON.parse(JSON.stringify(this.newEmptyStep));
                                let newOriginalStep = JSON.parse(JSON.stringify(this.newEmptyStep));
                                newStep.stepNumber = 1
                                newOriginalStep.stepNumber = 1

                                this.steps = [];
                                this.steps.push(newStep);
                                this.originalForm.steps = [];
                                this.originalForm.steps.push(newOriginalStep);

                                this.updateSelectedStep(newStep.stepNumber)
                                this.isEdited = true;
                            } else {
                                this.steps = JSON.parse(this.originalForm.steps);
                            }
                            this.platforms = JSON.parse(this.form.platforms)
                            this.originalPlatforms = JSON.parse(this.originalForm.platforms)
                            this.updateSelectedStep('0');

                            this.loading = false;
                        }
                        loader.hide()
                    })
                    .catch(response => {
                        loader.hide()
                    })
        },
        updateSelectedStep(tab, cancelButton = false) {

            if (tab != this.stepSelected.stepNumber) {

                if (!this.isEdited) {
                    this.tabSelected = tab;
                    // this.stepSelected = this.steps[tab]
                    this.stepSelected = this.steps[tab != 0 ? (tab - 1) : tab]
                    this.stepEdited = JSON.parse(JSON.stringify(this.stepSelected));

                } else {
                    // console.log("tab clicked: " + tab + '\n stepSelected: ' + this.stepSelected.stepNumber)
                    if (!cancelButton) {

                        //Means that step have changes, so don't let user go away.
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Tem alterações não guardadas no step ' + this.stepEdited.id + ' !',
                            type: 'error'
                        })
                    }
                    this.tabSelected = (this.stepSelected.stepNumber - 1);
                }
            }
        },
        validate() {
            var originalPT = this.stepSelected.stepDescription[0];
            var originalES = this.stepSelected.stepDescription[1];
            var auxPT = this.stepEdited.stepDescription[0];
            var auxES = this.stepEdited.stepDescription[1];

            this.isEdited = false;

            if (auxPT.title != originalPT.title || auxPT.value != originalPT.value) {
                this.isEdited = true;
            }

            if (auxES.title != originalES.title || auxES.value != originalES.value) {
                this.isEdited = true;
            }

            //Validate empty fields of step
            if (auxPT.title == '' || auxES.title == '' || auxPT.value == '' || auxES.value == '') {
                this.isEdited = false;
            }
        },
        validateFormInfos() {
            this.formInfoIsEdited = false;

            //Texts & State
            if (this.form.name != this.originalForm.name) {
                this.formInfoIsEdited = true;
            }
            if (this.form.infoForm != this.originalForm.infoForm) {
                this.formInfoIsEdited = true;
            }
            if (this.form.active != this.originalForm.active) {
                this.formInfoIsEdited = true;
            }
            //Dates
            if (this.editedFormStartDate != this.originalForm.startDate.substr(0, 10)) {
                this.formInfoIsEdited = true;
            }
            if (this.editedFormEndDate != this.originalForm.endDate.substr(0, 10)) {
                this.formInfoIsEdited = true;
            }
            //Platform
            if (this.platforms != undefined) {
                if (this.platforms.length != this.originalPlatforms.length) {
                    //Qnd se insere nova plataforma
                    this.formInfoIsEdited = true;
                }
                if (this.platforms.length > 0) {
                    for (let i = 0; i < this.originalPlatforms.length; i++) {
                        if (this.platforms[i].platform != this.originalPlatforms[i].platform) {
                            this.formInfoIsEdited = true;
                        }
                        if (this.platforms[i].page != this.originalPlatforms[i].page) {
                            this.formInfoIsEdited = true;
                        }
                    }
                }
            }

            //Validate empty fields
            if (this.form.name == '' || this.form.infoForm == '') {
                this.formInfoIsEdited = false;
            }

        },
        resetValues() {

            //Significa que é um step novo
            if (this.stepEdited.id == undefined) {

                //remove the new empty step
                this.$delete(this.steps, (this.steps.length - 1))

                //Update selected to last step and validate
                this.stepEdited = JSON.parse(JSON.stringify(this.steps[this.steps.length - 1]));
                this.isEdited = false;

                this.updateSelectedStep((this.steps.length), true)

                // console.log(this.stepEdited)
                // console.log(this.stepSelected)

                this.validate()

            } else {
                //Make a new clone of selected step
                this.stepEdited = JSON.parse(JSON.stringify(this.stepSelected));

                //Call update and validate
                this.updateSelectedStep((this.stepEdited.stepNumber), true)
                this.validate()
            }

        },
        OpenDialogEditQuestion(question) {
            // console.log(question)
            this.editingQuestion = true
            this.editedQuestion = question
            this.clonedQuestion = JSON.parse(JSON.stringify(this.editedQuestion))
        },

        //Create the new tab
        addTab() {
            let newStep = JSON.parse(JSON.stringify(this.newEmptyStep));
            newStep.stepNumber = (this.steps[this.steps.length - 1].stepNumber + 1)

            this.steps.push(newStep)
            this.updateSelectedStep(newStep.stepNumber)
            this.isEdited = true;
        },
        //Save new Step
        saveStepInfo() {
            //step to send to backoffice and INPUT new step on DB
            // console.log(this.stepEdited)
            let body = {
                stepInfo: this.stepEdited,
            }

            //Se for um novo step
            if (this.stepEdited.id == undefined) {
                this.stepEdited.id = 'new'
            }

            let loader = this.$loading.show()
            axios.post(api.formSaveStepInfo(this.formId, this.stepEdited.id), body)
                    .then(response => {
                        loader.hide()
                        let alert = {
                            snackbar: true,
                            text: 'Sucesso!',
                            type: 'success'
                        }

                        if (response.status == 200) {
                            //Question updated with success
                            this.isEdited = false;
                            this.getForm();

                        } else {
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                        }

                        Alert.dispatch('show', alert)
                    })
                    .catch(response => {
                        loader.hide()
                        alert = {
                            snackbar: true,
                            text: 'Erro!',
                            type: 'error'
                        }
                        Alert.dispatch('show', alert)
                    })

        },
        //Add Question
        addQuestion() {
            //OpenModal with empty fields to create a new question
            this.editNewQuestion = true
            this.clonedNewQuestion = JSON.parse(JSON.stringify(this.newEmptyQuestion))
            this.clonedNewQuestion.formId = parseInt(this.form.id)
            this.clonedNewQuestion.formStepId = this.stepSelected.id
        },
        //Add Platform to Form
        addPlatform() {
            let newPlatform = JSON.parse(JSON.stringify(this.newEmptyPlatform));

            newPlatform.id = 'new';
            newPlatform.formId = this.formId;

            if (this.platforms == null) {
                this.platforms = [];

                let newOriginalPlatform = JSON.parse(JSON.stringify(this.newEmptyPlatform));
                this.originalPlatforms = []
                this.originalPlatforms.push(newOriginalPlatform)
            }
            this.platforms.push(newPlatform)
            this.formInfoIsEdited = true
        },
        deletePlatform(platformId) {
            //é uma nova platform que ainda não está na db
            if (platformId == 'new') {
                this.platforms.pop();
                this.validateFormInfos();
            } else {
                let loader = this.$loading.show()
                axios.delete(api.formDeletePlatform(this.formId, platformId))
                        .then(response => {
                            loader.hide()
                            let alert = {
                                snackbar: true,
                                text: 'Platform removido com sucesso!',
                                type: 'success'
                            }

                            if (response.status == 200) {
                                //Question updated with success
                                this.getForm();

                            } else {
                                alert = {
                                    snackbar: true,
                                    text: 'Erro!',
                                    type: 'error'
                                }
                            }

                            Alert.dispatch('show', alert)
                        })
                        .catch(response => {
                            loader.hide()
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                            Alert.dispatch('show', alert)
                        })
            }
        },
        //Make request to update form definitions(Nome/Info/Estado/Plataforma+Páginas/startDate/endDate)
        deleteStep(stepId) {

            let loader = this.$loading.show()
            axios.delete(api.formDeleteStep(this.formId, stepId))
                    .then(response => {
                        loader.hide()
                        let alert = {
                            snackbar: true,
                            text: 'Step removido com sucesso!',
                            type: 'success'
                        }

                        if (response.status == 200) {
                            //Question updated with success
                            this.getForm();
                            this.isEdited = false;

                        } else {
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                        }

                        Alert.dispatch('show', alert)
                    })
                    .catch(response => {
                        loader.hide()
                        alert = {
                            snackbar: true,
                            text: 'Erro!',
                            type: 'error'
                        }
                        Alert.dispatch('show', alert)
                    })
        },
        saveFormInfo() {
            let newFormValues = {
                "name": this.form.name,
                "infoForm": this.form.infoForm,
                "numSteps": this.steps.length,
                "active": this.form.active,
                "startDate": this.editedFormStartDate,
                "endDate": this.editedFormEndDate,
                "platforms": this.platforms == undefined ? [] : this.platforms
            }

            let body = {
                formInfo: newFormValues,
            }
            let loader = this.$loading.show()
            axios.post(api.formSaveInfo(this.formId), body)
                    .then(response => {
                        loader.hide()
                        let alert = {
                            snackbar: true,
                            text: 'Sucesso!',
                            type: 'success'
                        }

                        if (response.status == 200) {
                            //Question updated with success
                            this.formInfoIsEdited = false;
                            this.getForm();

                        } else {
                            alert = {
                                snackbar: true,
                                text: 'Erro!',
                                type: 'error'
                            }
                        }

                        Alert.dispatch('show', alert)
                    })
                    .catch(response => {
                        loader.hide()
                        alert = {
                            snackbar: true,
                            text: 'Erro!',
                            type: 'error'
                        }
                        Alert.dispatch('show', alert)
                    })
        }
    },
    computed: {},
    created() {
        document.title = 'Questionario ' + this.$route.params.id;
    }
}
</script>