<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="banner.name"
                        :subtitle="banner.id"
                        :back="true"
                ></page-title>
            </v-col>
            <v-col class="text-right">
<!--                <v-btn tile color="primary" class="mr-4" @click="syncProduct()" v-if="writePermission('products/product')">
                    <i class="fas fa-sync mr-1"></i>Sincronizar
                </v-btn>-->
            </v-col>
        </v-row>
        <!-- HEADER -->
        <!-- CONTENT -->
        <v-row v-if="Object.keys(banner).length > 0">
            <v-col>
                <v-tabs background-color="transparent" slider-color="rgba(192, 13, 13, 1)" color="rgba(192, 13, 13, 1)">
                    <v-tab @click="activeTab = 1" :style="[activeTab === 1 ? {'background-color': 'white'} : '']">
                        Imformação
                    </v-tab>
                    <v-tab @click="activeTab = 2" :style="[activeTab === 2 ? {'background-color': 'white'} : '']">
                        Calendarização
                    </v-tab>
                    <v-tab @click="activeTab = 3" :style="[activeTab === 3 ? {'background-color': 'white'} : '']">
                        Imagens
                    </v-tab>
                    <v-tab @click="activeTab = 4" :style="[activeTab === 4 ? {'background-color': 'white'} : '']">
                        Perfis
                    </v-tab>
                </v-tabs>
                <BasicSettings v-if="activeTab === 1" :bannerId="bannerId" :banner="banner"></BasicSettings>
                <Schedule v-if="activeTab === 2" :bannerId="bannerId" :banner="banner"></Schedule>
                <Images v-if="activeTab === 3" :banner="banner"></Images>
                <Profiles v-if="activeTab === 4" :bannerId="bannerId" :banner="banner"></Profiles>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BasicSettings from "./Banner/BasicSettings";
import Images from "./Banner/Images";
import Schedule from "@/components/B2B/Banner/Schedule.vue";
import Profiles from "./Banner/Profiles";
export default {
    components: {Schedule, BasicSettings,Images,Profiles},
    name: "BannerID",
    data: function (){
        return {
            banner: {},
            bannerId: null,
            activeTab: 1
        }
    },
    created() {
        document.title = 'BannerID | STAFF';
        this.bannerIdUrl = this.$route.params.id;
        this.getBanner();
    },
    methods: {
        getBanner() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/bannersB2B/'+this.bannerIdUrl
            }
            ApiWS.dispatch('makeGet',request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.banner = responseData.banner[0];
                            this.banner.id = this.banner.id.toString();
                            this.banner.created_at = this.banner.created_at.slice(0,-4);
                            this.banner.updated_at = this.banner.updated_at.slice(0,-4);
                            loader.hide();
                        }
                        if (responseData.status === "NOK") {
                            loader.hide();
                        }
                    }).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
                loader.hide();
            })
        }
    }
}
</script>

<style scoped>

</style>