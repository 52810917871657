<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title title="Novo Cliente" subtitle=""></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  color="default" class="mr-3" @click="cancel()">CANCELAR</v-btn>
                <v-btn tile small  color="success" @click="save()">GUARDAR</v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <template>
            <v-stepper class="mt-3" v-model="e1">

                <v-stepper-header>
                    <v-stepper-step :complete="e1 > 1" step="1">Dados do Cliente</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 2" step="2">Dados Fiscais</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="e1 > 3" step="3">Morada Faturação</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="4">Morada de Envio</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                    <v-stepper-content step="1">

                        <v-row>
                            <v-col cols="3" class="text-right">
                                <v-btn text> Código Cliente</v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                        v-model="title"
                                        dense
                                        counter="25"
                                        outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="3" class="text-right">
                                <v-btn text> Código Cliente</v-btn>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="title"
                                        dense
                                        counter="25"
                                        outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>


                        <!--                        <v-btn text @click="e1 = 1">Anterior</v-btn>-->
                        <v-btn color="primary" @click="e1 = 2">Próximo</v-btn>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-card
                                class="mb-12"
                                color="grey lighten-1"
                                height="200px"
                        ></v-card>

                        <v-btn text @click="e1 = 1">Anterior</v-btn>
                        <v-btn color="primary" @click="e1 = 3">Próximo</v-btn>

                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-card
                                class="mb-12"
                                color="grey lighten-1"
                                height="200px"
                        ></v-card>

                        <v-btn
                                color="primary"
                                @click="e1 = 1"
                        >
                            Continue
                        </v-btn>

                        <v-btn text>
                            Cancel
                        </v-btn>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </template>

        <v-row>
            <v-col>
                <div class="staff_v-card">


                    <v-row>
                        <v-col cols="3" class="text-right">
                            <p>
                                Código Cliente
                            </p>
                        </v-col>
                        <v-col>
                            <v-text-field
                                    v-model="title"
                                    dense
                                    counter="25"
                                    outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3" class="text-right">
                            <p>
                                Nome Cliente
                            </p>
                        </v-col>
                        <v-col>
                            <v-text-field
                                    v-model="title"
                                    dense
                                    counter="25"
                                    outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>

                </div>
            </v-col>
        </v-row>

    </v-container>

</template>

<script>
import i18n from '../../plugins/i18n'

export default {
    data: function () {
        return {
            e1: 1,
            client: {}
        }
    },
    created() {
        document.title = 'Novo Cliente | STAFF';
    },
    methods: {
        save() {

        },
        cancel() {
            this.$router.push('/clients/clients')
        },
    }
}
</script>



