<template>
    <div style="float: left;">
        <v-card dark class="mr-3 mb-3" color="red">
            <v-list-item>
                <v-list-item-title class="headline">{{ $t('product') }}</v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-text-field
                            v-model="barcode"
                            outlined
                            dense
                            hide-details
                            append-icon="arrow_right_alt"
                            @click:append="getProduct"
                            @keypress.enter="getProduct"
                            :loading="loading"
                    ></v-text-field>
                </v-list-item-content>
            </v-list-item>
        </v-card>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            loading: false,
            barcode: ''
        }
    },
    methods: {
        getProduct() {
            this.loading = true;

            let request = {
                url: '/api/staff/dashboard/widget/product/'+this.barcode
            }

            ApiWS.dispatch('makeGet', request)
            .then(response => {
                let responseData = response.data;
                if (responseData.status === 'OK') {
                    this.$router.push('/products/products/' + responseData.product);
                } else {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Artigo não existe!',
                        type: 'error'
                    })
                }
                this.loading = false;
            })
            .catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
            })
        }
    }
}
</script>
