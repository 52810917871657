<template>
    <v-dialog v-model="showSelf" persistent max-width="984" @click:outside="closeDialogParent">
        <v-card>
            <v-btn
                    color="grey lighten-3"
                    dark
                    fab
                    top
                    absolute
                    right
                    @click="closeDialogParent"
            >
                <span class="buttonClose">x</span>
            </v-btn>
            <v-container class="pa-16">
                <v-row>
                    <v-col cols="3">
                        <v-container>
                            <v-row>
                                <v-col>
                                    <h5>Fornecedor</h5>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-autocomplete
                                            dense
                                            v-model="form.supplier"
                                            :items="suppliers"
                                            item-value="VALUE"
                                            item-text="TEXT"
                                            :label="$t('supplier')"
                                            outlined
                                            hide-details
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                    <v-col offset="1" cols="5" >
                        <v-container>
                            <v-row>
                                <v-col>
                                    <label><h5>Ficheiro/s fatura</h5></label>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-file-input
                                            small-chips
                                            dense
                                            tile
                                            outlined
                                            multiple
                                            v-model='form.uploadfiles'
                                            label="Seleccione o/os ficheiros"
                                    ></v-file-input>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                    <v-col offset="1" cols="2" id="dialog-import" >
                        <v-btn color="primary"
                               class="pull-right"
                               tile
                               @click="openConfirmDialog()"
                               :loading="uploading">
                            Importar
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <strong style="color:red"> {{ errorMessage }} </strong>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-container>
                            <v-row style="margin-bottom:2vh">
                                <img src="@/assets/img/headers_open_orders.jpg" width="850" class="img-fluid" alt="Headers" />
                            </v-row>
                            <v-row>
                                <h4 style="margin-right:30px">Nome dos cabecalhos a utilizar:</h4><br>
                            </v-row>
                            <v-row>
                                <small style="text-decoration: underline">A ordem das colunas não é importante</small>
                            </v-row>
                            <v-row style="font-size:small">
                                <v-col cols="3">
                                    <v-container>
                                        <v-row>
                                            <h4 style="margin-right:30px">"Order Number"</h4>
                                        </v-row>
                                        <v-row>
                                            <span>Número da encomenda:</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="3">
                                    <v-container>
                                        <v-row>
                                            <h4 style="margin-right:30px">"Part Number"</h4>
                                        </v-row>
                                        <v-row>
                                            <span>Id do produto</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col  cols="3">
                                    <v-container>
                                        <v-row>
                                            <h4 style="margin-right:30px">"Part Description"</h4>
                                        </v-row>
                                        <v-row>
                                            <span>Descricao</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col  cols="3">
                                    <v-container>
                                        <v-row>
                                            <h4 style="margin-right:30px">"Qty"</h4>
                                        </v-row>
                                        <v-row>
                                            <span>Quantidade</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="3">
                                    <v-container>
                                        <v-row>
                                            <h4 style="margin-right:30px">"EAN"</h4>
                                        </v-row>
                                        <v-row>
                                            <span>EAN</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="3">
                                    <v-container>
                                        <v-row>
                                            <h4 style="margin-right:30px">"Schedule Ship Date"</h4>
                                        </v-row>
                                        <v-row>
                                            <span>Data de entrega</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="3">
                                    <v-container>
                                        <v-row>
                                            <h4 style="margin-right:30px">"Unit Price"</h4>
                                        </v-row>
                                        <v-row>
                                            <span>Preco</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                                <v-col cols="3">
                                    <v-container>
                                        <v-row>
                                            <strong style="color: red; text-decoration: underline">Apenas nas faturas</strong>
                                        </v-row>
                                        <v-row>
                                            <h4 style="margin-right:30px">"Delivery"</h4>
                                        </v-row>
                                        <v-row>
                                            <span>Número da fatura</span>
                                        </v-row>
                                    </v-container>
                                </v-col>
                            </v-row>
                            <v-row>

                            </v-row>
                            <v-row>

                            </v-row>
                            <v-row>

                            </v-row>
                            <v-row>

                            </v-row>
                            <v-row>

                            </v-row>
                            <v-row>

                            </v-row>
                            <v-row>

                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions class="text-center">
                <v-spacer></v-spacer>
                <v-col>
                    <v-btn
                            color="primary"
                    >Cancelar
                    </v-btn>
                </v-col>
            </v-card-actions>
        </v-card>
        <v-dialog v-model="dialogConfirmImport" persistent max-width="500">
            <v-card v-show="fetchingInfo == false">
                <v-card-title>{{ processing ? "Aguarde..." : $t('confirmChange') }}</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <strong>{{ processing ? 'A processar' : 'Confirmar importacao dos ficheiros selecionados?' }}</strong>
                        </v-row>
                        <v-row>
                            <span style="color: darkorange">A importar {{  totalPending }} ficheiros pendentes e {{  totalInTransit }} ficheiros em transito</span>
                        </v-row>
                        <v-row class="mt-4" v-if="totalInTransit > 0">
                            <v-radio-group
                                    v-model="associateToEcf"
                                    mandatory
                            >
                                <v-radio
                                        label="Associar linhas em transito(fatura) a linhas de pendentes"
                                        :value="true"
                                ></v-radio>
                                <v-radio
                                        label="Criar documento em transito(fatura) individual"
                                        :value="false"
                                ></v-radio>
                            </v-radio-group>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions v-if="processing == false">
                    <v-btn tile small @click="dialogConfirmImport=false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" @click="importFiles">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>


<script>

import axios from "axios";

export default {
    data: () => ({
        fetchingInfo: false,
        processing: false,
        showSelf: true,

        errorMessage: "",
        loaded: false,

        uploading: false,

        form: {
            supplier: null,
            uploadfiles: [],
        },
        suppliers: [],
        dialogConfirmImport: false,
        totalPending: 0,
        totalInTransit: 0,
        associateToEcf: false,
        /** each file uploaded [file0, file1, file2] is pending or in transit.
         * isPendingList indicates if each file is pending(true) or transit(false)  [false, true, false]
         * example: [file0(pending), file1(invoice), file2(pending)] -> [true, false, true] **/
        isPendingList: []
        //replacePendingProductsFile: false
    }),
    name: "OpenOrderImporter",
    created() {
        this.getSuppliers();
    },
    computed: {},

    methods: {
        closeDialogParent() {
            this.$emit('close-importer');
        },
        validForm() {
            return this.form.supplier != null && (this.form.uploadfiles.length > 0);// && this.form.uploadfiles != null;
        },
        setErrorMessage(message) {
            this.errorMessage = message;
            setTimeout(() => this.errorMessage = "", 3000);
        },
        importFiles() {
            if (this.validForm() === false)
                return;

            let loader = this.$loading.show();
            this.processing = true;

            let url = '/api/staff/purchases/importV2/' + this.form.supplier;
            let formData = new FormData();

            for (let i = 0; i < this.form.uploadfiles.length; i++) {
                formData.append('uploadfiles' + i, this.form.uploadfiles[i]);
            }
            formData.append('associateToEcf', this.associateToEcf);
            formData.append('isPendingList', this.isPendingList);

            axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                let responseData = response.data;
                if (responseData.status === 'OK') {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Ficheiros carregados com sucesso!',
                        type: 'success'
                    });
                    this.closeDialogParent();
                } else {
                    //this.errorMessage = "There was an error on the API";
                    this.setErrorMessage(responseData.data);
                }
                loader.hide();
                this.closeConfirmDialog();
                this.processing = false;
            }).catch(() => {
                this.setErrorMessage("There was an error on the API");
                this.closeConfirmDialog();
                this.processing = false;
                 loader.hide();
            })
        },
        getSuppliers() {
            let loader = this.$loading.show();
            let request = {url: '/api/staff/purchases/getSuppliers'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.suppliers = responseData.suppliers;
                        }else{
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Impossivel carregar fornecedores!',
                                type: 'error'
                            });
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        closeConfirmDialog(){
            this.dialogConfirmImport = false;
        },
        openConfirmDialog(){
            if(this.validForm() == false)
                return;

            this.fetchingInfo = true;
            let loader = this.$loading.show();
            let url = '/api/staff/purchases/check-files';
            let formData = new FormData();

            for (let i = 0; i < this.form.uploadfiles.length; i++) {
                formData.append('uploadfiles' + i, this.form.uploadfiles[i]);
            }

            axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.totalPending = responseData.data.pendingCount;
                            this.totalInTransit = responseData.data.inTransitCount;
                            this.isPendingList = responseData.data.isPending;
                            if(this.totalInTransit > 0)
                                this.associateToEcf = true;
                            else
                                this.associateToEcf = false;
                        }else{
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao verificar ficheiros!',
                                type: 'error'
                            });

                        }
                        this.fetchingInfo = false;
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
            this.dialogConfirmImport = true
        }
    }
}
</script>

<style scoped>

#dialog-import{
    align-self: center;
    margin-top: 2vh
}

.buttonClose {
    margin-top: 24px;
    color: #a79090;
    font-size: 22px;
    font-weight: 1000;
}
</style>
