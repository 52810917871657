<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col cols="12" md="3">
                <page-title
                        back
                        :title="$t('addItem')+' '+ $t('products')+ ' a Compra'"
                        :subtitle="'A mostrar '+products.length+' '+$t('products')"
                ></page-title>
            </v-col>
            <v-col class="text-right" align-self="center">
                <v-btn tile small  @click="getWarehouse('ALL')" class="mr-2">Todos</v-btn>
                <template v-for="warehouse in filtersWarehouse">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small tile @click="getWarehouse(warehouse.name)" :color="warehouse.filter === true ? 'success' : ''">
                                {{ warehouse.name }}
                            </v-btn>
                        </template>
                        <span>{{ warehouse.desc }}</span>
                    </v-tooltip>
                </template>
                <v-btn tile small  class="ml-2" color="info" :loading="loading" @click="getAddPurchaseLines()">
                    Actualizar
                </v-btn>
                <v-btn tile small  class="ml-2" color="error" @click="clearFilters()">{{ $t('clearFilters') }}</v-btn>
            </v-col>
        </v-row>
        <!-- DATE FILTERS -->
        <v-row>
            <v-col cols="4">
                <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="filters.dataInicial"
                                label="Data Inicial"
                                dense
                                outlined
                                readonly
                                v-on="on"
                                style="width: 200px; float: left"
                                hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.dataInicial" locale="pt-pt" @input="menu2 = false"></v-date-picker>
                </v-menu>
                <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="filters.dataFinal"
                                label="Data Final"
                                readonly
                                dense
                                outlined
                                v-on="on"
                                style="width: 200px; float: left"
                                hide-details
                                class="ml-4"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="filters.dataFinal" locale="pt-pt" @input="menu3 = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="4">
                <strong>{{ purchase.Documento }}:</strong> {{ purchase.Entidade }} - {{ purchase.Nome }}<br>
                <strong>{{ $t('dateOfDocument') }}:</strong> {{ purchase.DataDoc }}
            </v-col>
            <v-col cols="2">
                <v-menu
                        v-model="menu4"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="arriveDate"
                                :label="$t('arrivalDate')"
                                readonly
                                dense
                                outlined
                                v-on="on"
                                style="width: 200px; float: left"
                                hide-details
                                class="ml-4"
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="arriveDate" locale="pt-pt" @input="setArriveDateAll()"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  v-if="pagination.page > 1" @click="previousPage()">Anterior</v-btn>
                <v-btn tile small  outlined disabled class="ma-1">{{ pagination.page }}</v-btn>
                <v-btn tile small  v-if="products.length === pagination.per_page" @click="nextPage()">{{ $t('next') }}
                </v-btn>
            </v-col>
        </v-row>
        <!-- PRODUCTS TABLE -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-row>
                        <v-col class="pt-0">
                            <v-text-field
                                    v-model="filters.Geral"
                                    outlined
                                    dense
                                    hide-details
                                    @focus="$event.target.select()"
                                    @keypress.enter="filter('Geral')"
                                    label="Pesquisa Geral"
                                    style="width: 300px"
                            ></v-text-field>
                        </v-col>
                        <v-col align-self="center" class="pt-0">
                            Total Referências: {{ productsToAdd.length }} | Total {{ $t('products') }}: {{ totalProductsAdd }}
                        </v-col>
                        <v-col class="text-right pt-0" align-self="center">
                            <v-btn tile small  color="success" class="mr-2" :disabled="productsToAdd.length === 0" @click="addLinesPri">Atualizar Linhas</v-btn>
                        </v-col>
                    </v-row>
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th @click="setOrder('Artigo', filters.orderSentido)">
                                {{ $t('product') }}
                                <i v-if="filters.orderSentido == false && filters.orderColuna == 'Artigo'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderSentido == true && filters.orderColuna == 'Artigo'" class="fas fa-angle-up"></i>
                            </th>
                            <th>{{ $t('description') }}</th>
                            <th>Cód. Barras Pri</th>
                            <th>EAN</th>
                            <th>Ref. Forn.</th>
                            <th>Marca</th>
                            <th>Familia</th>
                            <th>SubFamilia</th>
                            <th class="text-center">B2B</th>
                            <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('STKActual', filters.orderSentido)">
                                Stock
                                <i v-if="filters.orderSentido == false && filters.orderColuna == 'STKActual'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderSentido == true && filters.orderColuna == 'STKActual'" class="fas fa-angle-up"></i>
                            </th>
                            <th style="cursor: pointer;" @click="setOrder('ENC_CLIENTE', filters.orderSentido)">
                                Enc. Cliente
                                <i v-if="filters.orderSentido == false && filters.orderColuna == 'ENC_CLIENTE'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderSentido == true && filters.orderColuna == 'ENC_CLIENTE'" class="fas fa-angle-up"></i>
                            </th>
                            <th style="cursor: pointer;" @click="setOrder('ENC_FORN', filters.orderSentido)">
                                Enc. Forn.
                                <i v-if="filters.orderSentido == false && filters.orderColuna == 'ENC_FORN'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderSentido == true && filters.orderColuna == 'ENC_FORN'" class="fas fa-angle-up"></i>
                            </th>
                            <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('disponivel', filters.orderSentido)">
<!--                                {{ $t('available') }}-->
                                Disp.
                                <i v-if="filters.orderSentido == false && filters.orderColuna == 'disponivel'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderSentido == true && filters.orderColuna == 'disponivel'" class="fas fa-angle-up"></i>
                            </th>
                            <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('VENDAS', filters.orderSentido)">
                                {{ $t('sales') }}
                                <i v-if="filters.orderSentido == false && filters.orderColuna == 'VENDAS'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderSentido == true && filters.orderColuna == 'VENDAS'" class="fas fa-angle-up"></i>
                            </th>
                            <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('Total_garantias', filters.orderSentido)">
                                Garantias
                                <i v-if="filters.orderSentido == false && filters.orderColuna == 'Total_garantias'" class="fas fa-angle-down"></i>
                                <i v-if="filters.orderSentido == true && filters.orderColuna == 'Total_garantias'" class="fas fa-angle-up"></i>
                            </th>
                            <th>{{ $t('quantity') }}</th>
                            <th>{{ $t('arrivalDate') }}</th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                        v-model="filters.Artigo"
                                        dense
                                        outlined
                                        hide-details
                                        @keypress.enter="filter('Artigo')"
                                        @focus="$event.target.select()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.Descricao"
                                        dense
                                        outlined
                                        hide-details
                                        @keypress.enter="filter('Descricao')"
                                        @focus="$event.target.select()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.CodBarras"
                                        dense
                                        outlined
                                        hide-details
                                        @keypress.enter="filter('CodBarras')"
                                        @focus="$event.target.select()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.EAN"
                                        dense
                                        outlined
                                        hide-details
                                        @keypress.enter="filter('EAN')"
                                        @focus="$event.target.select()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.RefFornecedor"
                                        dense
                                        outlined
                                        hide-details
                                        @keypress.enter="filter('RefFornecedor')"
                                        @focus="$event.target.select()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.Marca"
                                        dense
                                        outlined
                                        hide-details
                                        @change="filter('Marca')"
                                        :items="brands"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.Familia"
                                        dense
                                        outlined
                                        hide-details
                                        @change="filter('Familia')"
                                        :items="families"
                                        item-value="VALUE"
                                        item-text="TEXT"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.SubFamilia"
                                        dense
                                        outlined
                                        hide-details
                                        @change="filter('SubFamilia')"
                                        :items="subFamilies"
                                        item-value="VALUE"
                                        item-text="TEXT"
                                ></v-select>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.B2B"
                                        :items="filtersYesNo"
                                        outlined
                                        dense
                                        @change="filter('B2B')"
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                        style="width: 80px"
                                ></v-select>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="product in products">
                            <td>{{ product.Artigo }}</td>
                            <td>{{ product.Descricao }}</td>
                            <td>{{ product.CodBarras }}</td>
                            <td>{{ product.CDU_EAN }}</td>
                            <td>{{ product.CDU_REFFORNECEDOR }}</td>
                            <td>{{ product.Marca }}</td>
                            <td>{{ product.Familia }}</td>
                            <td>{{ product.SubFamilia }}</td>
                            <td class="text-center">
                                <i class="fas fa-check" style="color: green" v-if="product.CDU_B2B_SYNC == '1'"></i>
                                <i class="fas fa-times" style="color: red" v-if="product.CDU_B2B_SYNC == '0'"></i>
                            </td>
                            <td class="text-center">{{ product.STKActual }}</td>
                            <td class="text-center">
                                <span v-if="product.ENC_CLIENTE === null">{{ product.ENC_CLIENTE || 0 }}</span>
                                <v-tooltip top v-else>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" @mouseover="tooltipOrders(product.Artigo)">{{ product.ENC_CLIENTE || 0 }}</span>
                                    </template>
                                    <span>
                                        <v-row>
                                            <v-col>{{ $t('order') }}</v-col>
                                            <v-col>{{ $t('quantity') }}</v-col>
                                        </v-row>
                                    </span>
                                    <span v-for="order in ordersTooltip">
                                        <v-row>
                                            <v-col class="py-0 text-center">{{ order.NumDoc }}</v-col>
                                            <v-col class="py-0 text-center">{{ order.Quantidade }}</v-col>
                                        </v-row>
                                    </span>
                                </v-tooltip>
                            </td>
                            <td class="text-center">
                                <span v-if="product.ENC_FORN === null">{{ product.ENC_FORN || 0 }}</span>
                                <v-tooltip top v-else>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" @mouseover="tooltipPurchases(product.Artigo)">{{ product.ENC_FORN || 0 }}</span>
                                    </template>
                                    <span>
                                        <v-row>
                                            <v-col class="py-1" style="font-size: 12px" align-self="center">Compra</v-col>
                                            <v-col class="py-1" style="font-size: 12px" align-self="center">{{ $t('quantity') }}</v-col>
                                            <v-col class="py-1" style="font-size: 12px">{{ $t('arrivalDate') }}</v-col>
                                        </v-row>
                                    </span>
                                    <span v-for="purchase in purchasesTooltip">
                                        <v-row>
                                            <v-col class="py-0 text-center" style="font-size: 12px">{{ purchase.NumDoc }}</v-col>
                                            <v-col class="py-0 text-center" style="font-size: 12px">{{ purchase.Quantidade }}</v-col>
                                            <v-col class="py-0 text-center" style="font-size: 12px">{{ purchase.DataEntrega }}</v-col>
                                        </v-row>
                                    </span>
                                </v-tooltip>
                            </td>
                            <td class="text-center">{{ product.disponivel || 0 }}</td>
                            <td class="text-center">{{ product.VENDAS || 0 }}</td>
                            <td class="text-center">{{ product.Total_garantias || 0 }}</td>
                            <td>
                                <v-text-field
                                        v-model="product.QtyOrder"
                                        outlined
                                        dense
                                        hide-details
                                        style="width: 60px"
                                        @input="setValue(product)"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-menu
                                        v-model="product.MenuDate"
                                        :close-on-content-click="true"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="product.DataEntrega"
                                                readonly
                                                dense
                                                outlined
                                                v-on="on"
                                                style="width: 130px; float: left"
                                                hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="product.DataEntrega" locale="pt-pt" @input="setArriveDate(product)"></v-date-picker>
                                </v-menu>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-right">
                <v-btn tile small  v-if="pagination.page > 1" @click="previousPage()">Anterior</v-btn>
                <v-btn tile small  outlined disabled class="ma-1">{{ pagination.page }}</v-btn>
                <v-btn tile small  v-if="products.length === pagination.per_page" @click="nextPage()">{{ $t('next') }}
                </v-btn>
            </v-col>
        </v-row>

        <v-row justify="center">
            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

import i18n from "@/plugins/i18n";

export default {
    data: function () {
        return {
            loading: false,
            pagination: {
                per_page: 250,
                page: 1
            },
            products: [],
            purchases: [],
            filters: {
                Artigo: '',
                Descricao: '',
                CodBarras: '',
                EAN: '',
                RefFornecedor: '',
                Marca: '',
                Familia: '',
                SubFamilia: '',
                dataInicial: '',
                dataFinal: new Date().toISOString().substr(0, 10),
                orderSentido: true,
                orderColuna: 'Artigo',
                B2B: '',
                Geral: ''
            },
            filWarehouse: {
                Filter: "'A1'"
            },
            filtersWarehouse: [],
            filtersWarehouseAux: [],
            filtersAllWarehouse: true,
            reload: false,
            menu2: false,
            menu3: false,
            menu4: false,
            families: [],
            brands: [],
            subFamilies: [],
            filtersYesNo: [
                {TEXT: 'Todos', VALUE: ''},
                {TEXT: 'Não', VALUE: '0'},
                {TEXT: 'Sim', VALUE: '1'}
            ],
            purchaseSelected: '',
            purchase: {},
            purchaseLines: [],
            arriveDate: '',
            productsToAdd: [],
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
            purchasesByProduct: [],
            ordersByProduct: [],
            purchasesTooltip: [],
            ordersTooltip: []
        }
    },
    created() {
        document.title = i18n.t('addItem') + ' ' + i18n.t('products') + ' a Compra | STAFF';
        this.purchaseSelected = this.$route.params.id;
        let currentYear = new Date().getFullYear();
        if (this.$cookies.isKey('addPurchaseLines_filtersWarehouse')) {
            this.filtersWarehouse = this.$cookies.get('addPurchaseLines_filtersWarehouse')
        }
        if (this.$cookies.isKey('addPurchaseLines_filters_2')) {
            this.filtersWarehouse = this.$cookies.get('addPurchaseLines_filters_2')
        }

        if (this.filtersWarehouse.length > 0) {
            this.filtersWarehouseAux = this.filtersWarehouse
            this.reload = true
        }

        this.filters.dataInicial = currentYear + '-01-01';
        this.getAddPurchaseLines();
    },
    methods: {
        getAddPurchaseLines() {
            this.loading = true;
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/getAddLines',
                data: {
                    filters: this.filters,
                    pagination: this.pagination,
                    filtersWarehouse: this.filWarehouse
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.purchases = responseData.purchases;
                            this.products = responseData.products;
                            this.brands = responseData.brands;
                            this.purchasesByProduct = responseData.purchasesByProduct;
                            this.ordersByProduct = responseData.ordersByProduct;

                            if (this.reload === false) {
                                this.filtersWarehouse = responseData.warehouse;
                                if (this.filtersWarehouse.length > 0) {
                                    this.filtersWarehouse[0].filter = true;
                                }
                                // for (let i = 0; i < this.filtersWarehouse.length; i++) {
                                //     this.filtersWarehouse[i].filter = true;
                                // }

                                this.reload = true;
                            } else {
                                if (this.filtersWarehouseAux.length > 0) {
                                    this.filtersWarehouse = this.filtersWarehouseAux;
                                }
                            }

                            this.getFamilies();
                            this.getSubFamilies();

                            for (let i = 0; i < this.products.length; i++) {
                                this.products[i].MenuDate = false;
                                if (this.arriveDate !== '' && this.products[i].DataEntrega === null) {
                                    this.products[i].DataEntrega = this.arriveDate;
                                }
                            }

                            if (this.purchaseSelected !== '' && this.purchaseLines.length === 0) {
                                this.getPurchasesLines();
                            } else {
                                if (this.purchaseLines.length > 0) {
                                    this.products.forEach((product) => {
                                        this.purchaseLines.forEach((purchase) => {
                                            if (product.Artigo === purchase.Artigo) {
                                                product.QtyOrder = purchase.Quantidade;
                                                product.DataEntrega = purchase.DataEntrega;
                                            }
                                        })
                                    })
                                }
                            }

                            if (this.productsToAdd.length > 0) {
                                this.products.forEach((product) => {
                                    this.productsToAdd.forEach((productToAdd) => {
                                        if (product.Artigo === productToAdd.product) {
                                            product.QtyOrder = productToAdd.qty;
                                            product.DataEntrega = productToAdd.arriveDate;
                                        }
                                    })
                                })
                            }
                        }
                        this.loading = false;
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loading = false;
                        loader.hide();
                    })
        },
        getFamilies() {
            let request = {
                url: api.productsFamilies(),
                data: {
                    brand: this.filters.Marca
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.families = responseData.families
                        }
                    })
                    .catch(response => {

                    })
        },
        getSubFamilies() {
            Product.dispatch('getSubFamilies', this.filters.Familia)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.subFamilies = responseData.subFamilies
                        }
                    })
                    .catch(response => {

                    })
        },
        getPurchasesLines() {
            let request = {
                url: '/api/staff/purchases/' + this.purchaseSelected
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.purchase = responseData.purchase;
                            this.purchaseLines = responseData.purchaseLines;

                            this.products.forEach((product) => {
                                this.purchaseLines.forEach((purchase) => {
                                    if (product.Artigo === purchase.Artigo) {
                                        product.QtyOrder = purchase.Quantidade;
                                        product.DataEntrega = purchase.DataEntrega;
                                    }
                                })
                            })

                            this.purchaseLines.forEach((purchase) => {
                                this.productsToAdd.forEach((product, index) => {
                                    if (purchase.Artigo === product.product) {
                                        this.productsToAdd.splice(index, 1);
                                    }
                                })
                                this.productsToAdd.push({
                                    product: purchase.Artigo,
                                    qty: purchase.Quantidade,
                                    arriveDate: purchase.DataEntrega
                                })
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        getWarehouse(warehouse) {
            let i;
            if (warehouse === 'ALL') {
                for (i = 0; i < this.filtersWarehouse.length; i++) {
                    this.filtersWarehouse[i].filter = true
                }
                this.filtersAllWarehouse = true
                this.filWarehouse.Filter = ''
            } else if (warehouse !== 'ALL') {
                if (this.filtersAllWarehouse === true) {
                    for (i = 0; i < this.filtersWarehouse.length; i++) {
                        this.filtersWarehouse[i].filter = false
                    }
                    this.filtersAllWarehouse = false
                }
                this.query = '';
                for (i = 0; i < this.filtersWarehouse.length; i++) {
                    if (warehouse == this.filtersWarehouse[i].name && this.filtersWarehouse[i].filter == false && this.filtersAllWarehouse == false) {
                        this.filtersWarehouse[i].filter = true
                    } else if (warehouse == this.filtersWarehouse[i].name && this.filtersWarehouse[i].filter == true && this.filtersAllWarehouse == false) {
                        this.filtersWarehouse[i].filter = false
                    }
                }
                let soma = 0
                for (i = 0; i < this.filtersWarehouse.length; i++) {
                    if (this.filtersWarehouse[i].filter == true) {
                        this.query += "'" + this.filtersWarehouse[i].name + "',"
                    } else {
                        soma++
                    }
                }
                if (soma == this.filtersWarehouse.length) {
                    this.reload = false
                    this.filtersAllWarehouse = true
                }
                this.filWarehouse.Filter = this.query.substring(0, this.query.length - 1)
            }

            this.filtersWarehouseAux = this.filtersWarehouse
            this.getAddPurchaseLines()
        },
        setOrder(column, order) {
            this.filters.orderColuna = column;
            this.filters.orderSentido = !order;
            this.getAddPurchaseLines()
        },
        nextPage() {
            this.pagination.page++
            this.getAddPurchaseLines()
        },
        previousPage() {
            this.pagination.page--
            this.getAddPurchaseLines()
        },
        filter(column) {
            if (column === 'Marca') {
                this.filters.Familia = '';
                this.filters.SubFamilia = '';
            }
            if (column === 'Familia') {
                this.filters.SubFamilia = '';
            }
            this.$cookies.set("addPurchaseLines_filters_2", this.filters);
            this.pagination.page = 1
            this.getAddPurchaseLines()
        },
        clearFilters() {
            let currentYear = new Date().getFullYear()
            this.filters = {
                Artigo: '',
                Descricao: '',
                CodBarras: '',
                EAN: '',
                RefFornecedor: '',
                Marca: '',
                Familia: '',
                SubFamilia: '',
                dataInicial: '',
                dataFinal: new Date().toISOString().substr(0, 10),
                orderSentido: true,
                orderColuna: 'Artigo',
                B2B: '',
                Geral: ''
            }
            this.filters.dataInicial = currentYear + '-01-01'
            this.pagination.page = 1
            this.getAddPurchaseLines();
        },
        setValue(product) {
            this.productsToAdd.forEach((productToAdd, index) => {
                if (product.Artigo === productToAdd.product) {
                    this.productsToAdd.splice(index, 1);
                }
            })

            if (product.QtyOrder > 0 && product.QtyOrder !== '') {
                if (product.DataEntrega === '') {
                    this.productsToAdd.push({
                        product: product.Artigo,
                        qty: product.QtyOrder
                    })
                } else {
                    this.productsToAdd.push({
                        product: product.Artigo,
                        qty: product.QtyOrder,
                        arriveDate: product.DataEntrega
                    })
                }
            }
        },
        setArriveDate(product) {
            this.productsToAdd.forEach((productToAdd, index) => {
                if (product.Artigo === productToAdd.product) {
                    this.productsToAdd.splice(index, 1);
                }
            })

            if (product.QtyOrder > 0 && product.QtyOrder !== '') {
                this.productsToAdd.push({
                    product: product.Artigo,
                    qty: product.QtyOrder,
                    arriveDate: product.DataEntrega
                })
            }
        },
        setArriveDateAll() {
            this.products.forEach((product) => {
                product.DataEntrega = this.arriveDate;
            })
            this.productsToAdd.forEach((product) => {
                product.arriveDate = this.arriveDate;
            })

            this.menu4 = false;
        },
        addLinesPri() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/addLinesECF',
                data: {
                    doc: {
                        TipoDoc: this.purchase.TipoDoc,
                        NumDoc: this.purchase.NumDoc,
                        Serie: this.purchase.Serie
                    },
                    lines: this.productsToAdd
                }
            }

            ApiWS.dispatch('makePost', request)
            .then(response => {
                let responseData = response.data;
                if (responseData.status === 'OK') {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Linhas adicionadas com sucesso!',
                        type: 'success'
                    })
                } else {
                    this.responseDialog.open = true;
                    this.responseDialog.error = true;
                    this.responseDialog.title = 'Erro ao Atualizar Linhas!';
                    this.responseDialog.message = responseData.data;
                    this.responseDialog.loading = false;
                }
                loader.hide();
            })
            .catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
                loader.hide();
            })
        },
        tooltipPurchases(product) {
            this.purchasesTooltip = [];
            this.purchasesByProduct.forEach((purchase) => {
                if (purchase.Artigo === product) {
                    this.purchasesTooltip.push(purchase);
                }
            })
        },
        tooltipOrders(product) {
            this.ordersTooltip = [];
            this.ordersByProduct.forEach((order) => {
                if (order.Artigo === product) {
                    this.ordersTooltip.push(order);
                }
            })
        }
    },
    computed: {
        totalProductsAdd() {
            let count = 0;
            this.productsToAdd.forEach((product) => {
                count += parseInt(product.qty);
            })

            return count;
        }
    }
}
</script>
