<template>
    <div>
        <v-row>
            <v-col>
                <v-card tile min-height="300">
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th>Descricao</th>
                                <th>Artigo</th>
                                <th>Ref Fornecedor</th>
                                <th>CodBarras</th>
                                <th class="text-center">EAN-13</th>
                                <th class="text-center">B2B</th>
                                <th class="text-center">Oficina</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="row in rows">
                                <td>{{ row.Descricao }}</td>
                                <td>{{ row.Artigo }}</td>
                                <td>
                                    <v-text-field dense outlined hide-details
                                                  v-model="row.CDU_REFFORNECEDOR"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field dense outlined hide-details
                                                  v-model="row.CodBarras"></v-text-field>
                                </td>
                                <td>
                                    <v-text-field dense outlined hide-details
                                                  v-model="row.CDU_EAN"></v-text-field>
                                </td>
                                <td class="text-center">
                                    <i class="fas fa-check" style="color: green" v-if="row.CDU_B2B_SYNC == '1'"></i>
                                    <i class="fas fa-times" style="color: red" v-if="row.CDU_B2B_SYNC == '0'"></i>
                                </td>
                                <td class="text-center">{{ row.CDU_FOX_SYNC }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: () => ({
        loading: false,
        rows: [],
    }),
    mounted() {
        this.getRows()
    },
    methods: {
        getRows() {
            ApiWS.dispatch('makeGet', {url: '/api/staff/products/problems/refDupes'})
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.rows = responseData.rows
                        } else {

                        }
                    })
                    .catch(() => {

                    })
        },
    },
}
</script>
