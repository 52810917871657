<template>
    <v-card flat>

        <v-row no-gutters>
            <v-col class="ma-2">
                <v-card>

                    <v-toolbar color="primary" dense dark>
                        <v-toolbar-title class="subtitle-1">HISTÓRICO DA CAMPANHA</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-list-item v-if="loading">
                        <v-progress-linear height="2" indeterminate color="blue darken-4"></v-progress-linear>
                    </v-list-item>

                    <v-row no-gutters v-if="!loading">
                        <v-col class="ma-2">
                            <v-simple-table class="staff_table">
                                <thead>
                                <tr>
                                    <th>Cliente</th>
                                    <th>Nome</th>
                                    <th class="text-center">Nível</th>
                                    <th class="text-center">Cupão</th>
                                    <th width="130" class="text-center">Data</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="item in history">
                                    <td>
                                        {{ item.clientId }}
                                        <v-btn small icon :href="'/clients/clients/' + item.clientId" target="_blank">
                                            <i class="fas fa-external-link-alt" style="color: #1867c0;"></i>
                                        </v-btn>
                                    </td>
                                    <td>{{ item.clientName }}</td>
                                    <td class="text-center">{{ item.discountLevel }}</td>
                                    <td class="text-center">{{ item.notes }}</td>
                                    <td class="text-right">{{ item.created_at }}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>

        </v-row>

    </v-card>
</template>
<script>
    export default {
        props: {
            id: {},
        },
        data() {
            return {
                loading: false,
                history: [],
            }
        },
        created() {
            this.loading = true;
            this.getHistory();
        },
        methods: {
            getHistory() {
                let request = {url: api.b2bCampaigns() + '/' + this.$route.params.id + '/history'};
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.history = responseData.history;
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
        }
    }
</script>
