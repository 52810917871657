import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":"","grid-list-xl":""}},[_c(VLayout,{attrs:{"row":"","justify-center":""}},[_c(VFlex,{attrs:{"xs4":""}},[_c(VCard,[_c(VImg,{attrs:{"src":"https://cdn.vuetifyjs.com/images/cards/desert.jpg","aspect-ratio":"2.75"}}),_c(VCardTitle,[_c('div',{staticClass:"headline"},[_vm._v("Tabela de Preços Service Center - PT")])]),_c(VCardText,[_c(VTextField,{attrs:{"value":_vm.pricePT.value,"label":"Nome PDF","readonly":""}}),_c(VTextField,{attrs:{"value":_vm.pricePT.updated_at,"label":"Ultima atualização","readonly":""}}),_c(VTextField,{attrs:{"label":"Selecionar PDF","prepend-icon":"attach_file"},on:{"click":_vm.pickFile},model:{value:(_vm.imageName),callback:function ($$v) {_vm.imageName=$$v},expression:"imageName"}}),_c('input',{ref:"image",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"application/pdf"},on:{"change":_vm.onFilePicked}})],1),_c(VCardActions,{staticClass:"pa-3"},[_c(VBtn,{on:{"click":_vm.savePTPrice}},[_vm._v("Guardar")])],1)],1)],1),_c(VFlex,{attrs:{"xs4":""}},[_c(VCard,[_c(VImg,{attrs:{"src":"https://cdn.vuetifyjs.com/images/cards/desert.jpg","aspect-ratio":"2.75"}}),_c(VCardTitle,[_c('div',{staticClass:"headline"},[_vm._v("Tabla de precios Service Center - ES")])]),_c(VCardText,[_c(VTextField,{attrs:{"value":_vm.priceES.value,"label":"Nombre PDF","readonly":""}}),_c(VTextField,{attrs:{"value":_vm.priceES.updated_at,"label":"Última actualización","readonly":""}}),_c(VTextField,{attrs:{"label":"Selecionar PDF","prepend-icon":"attach_file"},on:{"click":_vm.pickFile2},model:{value:(_vm.imageName2),callback:function ($$v) {_vm.imageName2=$$v},expression:"imageName2"}}),_c('input',{ref:"image2",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"application/pdf"},on:{"change":_vm.onFilePicked2}})],1),_c(VCardActions,[_c(VBtn,{on:{"click":_vm.saveESPrice}},[_vm._v("Guardar")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }