<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Lista de Pickings"
                        :subtitle="$t('show') + ' ' + pickingList.length + ' de ' + pickingListOriginal.length + ' Picks'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  class="ml-2 mb-2" @click="getPickingList()" color="info"><i class="fas fa-sync-alt mr-1"></i>Atualizar</v-btn>
            </v-col>
        </v-row>

        <!-- LISTA -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th width="1">ID</th>
                            <th width="80">Data</th>
                            <th width="80">Tipo Cliente</th>
                            <th width="80">Cliente</th>
                            <th>{{ $t('name') }}</th>
                            <th width="100" class="text-center">Modo Pagamento</th>
                            <th width="150">Condições Pagamento</th>
                            <th width="70" class="text-center">Vendedor</th>
                            <th width="60" class="text-center">Origem</th>
                            <th width="100">Total de Artigos</th>
                            <th class="text-center" width="80">Utilizador</th>
                            <th width="85">Estado</th>
                            <th width="100"></th>
                            <th width="100"></th>
                            <th width="100"></th>
                        </tr>
                        <tr class="filters_row">
                            <td></td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.typeClient"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="filtersPickingList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.client"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="filtersPickingList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="filtersPickingList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.modePag"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="filtersPickingList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.condPag"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="filtersPickingList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.salesman"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="filtersPickingList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.source"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="filtersPickingList"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="15">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="pick in pickingList">
                            <td>{{ pick.id }}</td>
                            <td>{{ pick.created_at.substring(0,10) }}</td>
                            <td>{{ pick.tipoCliente }}</td>
                            <td>{{ pick.cliente }}</td>
                            <td>{{ pick.nome }}</td>
                            <td class="text-center">{{ pick.modoPagamento }}</td>
                            <td>{{ pick.Descricao }}</td>
                            <td class="text-center">{{ pick.vendedor }}</td>
                            <td class="text-center">{{ pick.origem }}</td>
                            <td class="text-center">{{ pick.TotalArtigos }}</td>
                            <td class="text-center">{{ pick.utilizador }}</td>
                            <td>{{ pick.status }}</td>
                            <td>
                                <v-btn block small tile @click="print(pick)">
                                    Imprimir
                                </v-btn>
                            </td>
                            <td>
                                <v-btn block small tile color="primary" :to="'/orders/pick/'+pick.id" :disabled="salesmanId !== pick.utilizador && pick.utilizador !== null"
                                       v-if="pick.status !== 'Conferido'">Pick
                                </v-btn>
                                <v-btn block small tile color="success" :to="'/orders/pick/invoice/'+pick.id" :disabled="salesmanId !== pick.utilizador && pick.utilizador !== null"
                                       v-if="pick.status === 'Conferido'">
                                    Faturar
                                </v-btn>
                            </td>
                            <td>
                                <v-btn small tile color="error" @click="closePick(pick.id)" v-if="$writePermission('orders/pick')">
                                    Arquivar
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <!-- IMPRIMIR -->
        <v-row style="display: none">
            <v-col>
                <div id="printMe">
                    <table style="border: 1px solid gray; border-collapse: collapse; width: 100%">
                        <thead>
                        <tr>
                            <th colspan="2" style="padding: 30px 10px; border: 1px solid gray; font-size: 24px;">{{ selectedPick.id }}</th>
                            <th colspan="7" style="padding: 30px 10px; border: 1px solid gray; font-size: 24px; text-align: right">
                                {{ selectedPick.cliente }}<br>
                                {{ selectedPick.nome }}
                            </th>
                        </tr>
                        </thead>
                        <thead>
                        <tr>
                            <th style="border: 1px solid gray; text-align: center">Stock A1</th>
                            <th style="border: 1px solid gray; text-align: center">Stock</th>
                            <th style="border: 1px solid gray">{{ $t('product') }}</th>
                            <th style="border: 1px solid gray">CodBarras</th>
                            <th style="border: 1px solid gray">CodBarras CX</th>
                            <th style="border: 1px solid gray; text-align: center">Quantidade</th>
                            <th style="border: 1px solid gray">{{ $t('description') }}</th>
                            <th style="border: 1px solid gray">Uni</th>
                            <th style="border: 1px solid gray">Lote</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="order in products">
                            <tr>
                                <td style="border: 1px solid gray; text-align: center">{{ order.stock === 0 ? '' : order.stock }}</td>
                                <td style="border: 1px solid gray; text-align: center">{{ Math.round(order.stockTotal) === 0 ? '' : Math.round(order.stockTotal) }}</td>
                                <td style="border: 1px solid gray">{{ order.artigo }}</td>
                                <td style="border: 1px solid gray">{{ order.codBarras }}</td>
                                <td style="border: 1px solid gray">{{ order.boxBarcode }}</td>
                                <td style="border: 1px solid gray; text-align: center">{{ order.quantity }}</td>
                                <td style="border: 1px solid gray">{{ order.descricao }}</td>
                                <td style="border: 1px solid gray">{{ order.unidade }}</td>
                                <td style="border: 1px solid gray">
                                    <v-simple-table class="staff_table" v-if="order.Lotes">
                                    <tbody>
                                    <tr v-for="lote in JSON.parse(order.Lotes)">
                                        <td style="border: 1px solid gray">{{ lote.Lote }}</td>
                                        <td style="border: 1px solid gray">{{ lote.Validade }}</td>
                                        <td style="border: 1px solid gray">{{ lote.Stock }}</td>
                                    </tr>
                                    </tbody>
                                    </v-simple-table>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row>

            <!-- Close Pick-->
            <v-dialog v-model="dialogClosePick" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Arquivar Picking
                    </v-card-title>
                    <v-card-text>
                        Deseja arquivar o picking?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small  @click="dialogClosePick = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  color="error" @click="closePickConfirm()">Arquivar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>

    </v-container>
</template>

<script>

export default {
    data: function () {
        return {
            loading: false,
            pickingList: [],
            pickingListOriginal: [],
            selectedPick: {},
            filters: {
                typeClient: '',
                client: '',
                name: '',
                modePag: '',
                condPag: '',
                salesman: '',
                source: ''
            },
            dialogClosePick: false,
            closePickId: '',
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
            products: []
        }
    },
    created() {
        document.title = 'Picking | STAFF'
        this.getPickingList();
    },
    methods: {
        getPickingList() {
            this.loading = true;
            this.pickingList = [];
            this.pickingListOriginal = [];

            let request = {
                url: '/api/staff/picks'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pickingList = responseData.pickingList;
                            this.pickingListOriginal = responseData.pickingList;
                            this.filtersPickingList();
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    })
        },
        filtersPickingList() {
            this.pickingList = this.pickingListOriginal;

            if (this.filters.typeClient !== '') {
                this.pickingList = this.pickingList.filter(pick => pick.tipoCliente.includes(this.filters.typeClient));
            }
            if (this.filters.client !== '') {
                this.pickingList = this.pickingList.filter(pick => pick.cliente.includes(this.filters.client));
            }
            if (this.filters.name !== '') {
                this.pickingList = this.pickingList.filter(pick => pick.nome.toLowerCase().includes(this.filters.name.toLowerCase()));
            }
            if (this.filters.modePag !== '') {
                this.pickingList = this.pickingList.filter(pick => pick.modoPagamento.toUpperCase().includes(this.filters.modePag.toUpperCase()));
            }
            if (this.filters.condPag !== '') {
                this.pickingList = this.pickingList.filter(pick => pick.Descricao.toLowerCase().includes(this.filters.condPag.toLowerCase()));
            }
            if (this.filters.salesman !== '') {
                this.pickingList = this.pickingList.filter(pick => pick.vendedor = this.filters.salesman);
            }
            if (this.filters.source !== '') {
                this.pickingList = this.pickingList.filter(pick => pick.origem = this.filters.source);
            }
        },
        closePick(pickId) {
            this.closePickId = pickId;
            this.dialogClosePick = true;
        },
        closePickConfirm() {
            this.dialogClosePick = false;
            this.responseDialog.open = true;
            this.responseDialog.title = 'Arquivar Picking';
            this.responseDialog.loading = true;

            let request = {
                url: '/api/staff/pick/' + this.closePickId + '/cancel'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.responseDialog.message = 'Picking arquivado com sucesso!'
                            this.responseDialog.error = false;
                            this.getPickingList();
                        } else {
                            this.responseDialog.message = 'Erro ao arquivar picking!'
                            this.responseDialog.error = true;
                        }
                        this.responseDialog.loading = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        print(pick) {
            this.selectedPick = pick;
            this.products = [];

            let request = {
                url: '/api/staff/pick/' + pick.id + '/products'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.products = responseData.products;

                            setTimeout(() => {
                                const options = {
                                    name: '_blank',
                                    specs: [
                                        'fullscreen=yes',
                                        'titlebar=yes',
                                        'scrollbars=yes'
                                    ],
                                    styles: [
                                        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                                        '../../assets/css/print.css'
                                    ]
                                }

                                this.$htmlToPaper('printMe', options);
                            }, 300)
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
    },
    computed: {
        salesmanId() {
            return User.getters.getSalesmanId;
        }
    }
}
</script>
