<style scoped>
.back_btn {
    float: left;
}

.staff_subtitle {
    margin-bottom: 0 !important;
    margin-top: -6px;
    font-size: 14px;
    color: gray;
}

.row-last-pick td {
    border-top: solid 2px black !important;
    border-bottom: solid 2px black !important;
}

.row-last-pick td:last-child {
    border-right: solid 2px black !important;
}

.row-last-pick td:first-child {
    border-left: solid 2px black !important;
}

.row-yellow td,
.row-yellow th {
    background-color: rgba(255, 255, 0, 0.25);
}

.row-green td,
.row-green th {
    background-color: rgba(0, 255, 25, 0.40);
}

.pick-table tr td {
    line-height: 28px;
    font-size: 11px !important;
}

.pick-table tr:first-child td {
    font-size: 11px !important;
    border-top: solid 2px black !important;
    border-bottom: solid 2px black !important;
}

.pick-table tr:first-child td:last-child {
    border-right: solid 2px black !important;
}

.pick-table tr:first-child td:first-child {
    border-left: solid 2px black !important;
}


.pick-barcode {
    background-color: #E3F2FD;
    font-size: 20px;
    padding: 8px 16px;
    width: 100%;
    border: 1px solid #000000;
}

</style>
<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col cols="4">
                <v-btn tile small  color="default" class="back_btn mt-2 mr-3"
                       @click="$router.go(-1)">
                    <v-icon>arrow_back</v-icon>
                </v-btn>
                <h6 class="title text-uppercase" style="min-height: 32px">{{ 'Picking ' + pickOrderId }}</h6>
                <p class="staff_subtitle">{{ pickOrder.cliente + ' - ' + pickOrder.nome }}</p>
            </v-col>
            <v-col>
                <!--                <v-text-field-->
                <!--                        ref="barcode"-->
                <!--                        hide-details-->
                <!--                        dense-->
                <!--                        label="Cód. Barras"-->
                <!--                        outlined-->
                <!--                        @keypress.enter="focusInput('quantity')"-->
                <!--                        style="background-color: #E3F2FD;"-->
                <!--                ></v-text-field>-->
                <input type="text" ref="barcode" class="pick-barcode" placeholder="Cód. Barras" @keypress.enter="focusInput('quantity')">
            </v-col>
            <v-col>
                <!--                <v-text-field-->
                <!--                        ref="quantity"-->
                <!--                        hide-details-->
                <!--                        dense-->
                <!--                        label="Quantidade"-->
                <!--                        outlined-->
                <!--                        @keypress.enter="pickProduct"-->
                <!--                        style="background-color: #E3F2FD;"-->
                <!--                ></v-text-field>-->
                <input type="text" ref="quantity" class="pick-barcode" placeholder="Quantidade" @focus="$event.target.select()" @keypress.enter="pickProduct">
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  :color="predefineQuantity === true ? 'success' : ''" @click="btnPredefineQuantity()">
                    Quantidade 1
                </v-btn>
                <v-btn tile small  class="ml-2" color="error" @click="dialogResetPick = true">Limpar Picking
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <!-- ÚLTIMO ARTIGO PICADO -->
            <v-col>
                <v-card tile min-height="212px">
                    <v-card-title class="pt-2 pb-0">
                        <p class="subtitle-1 mb-0 black--text">ARTIGO PICADO</p>
                        <!--                        <v-spacer></v-spacer>-->
                        <!--                        <v-btn tile small  class="mr-2" v-if="lastProductPick.product !== ''" @click="openDialogBoxQuantity()">-->
                        <!--                            CAIXA-->
                        <!--                        </v-btn>-->
                        <!--                        <v-btn tile small  v-if="lastProductPick.product !== ''" @click="addSerialNumberLastProduct()">-->
                        <!--                            Num. Série-->
                        <!--                        </v-btn>-->
                    </v-card-title>
                    <v-container>
                        <v-simple-table dense>
                            <tr>
                                <td>
                                    <v-img height="140" width="140" :src="lastProductPick.img"
                                           v-if="lastProductPick.img"></v-img>
                                </td>
                                <td class="text-center">
                                    <p class="font-weight-bold">{{ lastProductPick.product }}</p>
                                    <small>{{ lastProductPick.description }}</small>
                                </td>
                            </tr>
                        </v-simple-table>
                    </v-container>
                </v-card>
            </v-col>

            <!-- LISTA ULTIMOS ARTIGOS -->
            <v-col>
                <v-card tile min-height="212px">
                    <v-card-title class="pt-2 pb-0">
                        <p class="subtitle-1 mb-0 black--text">ÚLTIMOS PICKS</p>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table pick-table">
                            <tbody>
                            <tr v-for="product in lastProductsPick">
                                <td>{{ product.product }}</td>
                                <td>{{ product.description }}</td>
                                <td>{{ product.qtyPick }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- TOTAIS ARTIGOS -->
            <v-col>
                <v-card tile min-height="212px">
                    <v-card-title class="pt-2 pb-0">
                        <p class="subtitle-1 mb-0 black--text">TOTAIS</p>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <tbody>
                            <tr>
                                <td class="text-center">Ref.</td>
                                <td class="text-center">Por Picar</td>
                                <td class="text-center">Pistola</td>
                                <td class="text-center">Manual</td>
                                <th class="text-center">TOTAL</th>
                            </tr>
                            <tr>
                                <td class="text-center">{{ totalProductsByReference }}</td>
                                <td class="text-center">{{ totalProductsNotPick }}</td>
                                <td class="text-center">{{ totalProductsPick }}</td>
                                <td class="text-center">{{ totalProductsPickManual }}</td>
                                <th class="text-center">{{ totalProductsPick + totalProductsPickManual }}</th>
                            </tr>
                            </tbody>
                        </v-simple-table>
                        <div class="text-center mt-9">
                            <v-btn v-if="totalProductsPick + totalProductsPickManual > 0" @click="confirmPickECF">
                                Finalizar
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card tile>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th>{{ $t('product') }}</th>
                                <th>Cód.Barras</th>
                                <th>{{ $t('description') }}</th>
                                <!--                                <th>Num. Série</th>-->
                                <th class="text-center">Stock A1</th>
                                <th class="text-center">Stock</th>
                                <th class="text-center">{{ $t('quantity') }}</th>
                                <th class="text-center">Pick</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="product in products"
                                :class="{ 'row-last-pick' : product.id == lastPick }">
                                <td>
                                    {{ product.artigo }}
                                    <v-btn icon small :href="'/products/products/'+product.artigo" target="_blank"
                                           color="blue darken-2">
                                        <v-icon small>launch</v-icon>
                                    </v-btn>
                                </td>
                                <td>
                                    <v-btn icon small v-if="product.tipoLinha === 10"
                                           @click="dialogPrintEanOpen(product.artigo)">
                                        <v-icon small>print</v-icon>
                                    </v-btn>
                                    {{ product.codBarras }}
                                </td>
                                <td>{{ product.descricao }}</td>
                                <!--                                <td>-->
                                <!--                                    {{ product.numSerie.join(', ') }}-->
                                <!--                                    <v-btn icon small v-if="product.tipoLinha === 10">-->
                                <!--                                        <v-icon small>add</v-icon>-->
                                <!--                                    </v-btn>-->
                                <!--                                </td>-->
                                <td class="text-center"
                                    style="cursor: pointer">
                                    {{ product.stock }}
                                </td>
                                <td class="text-center"
                                    style="cursor: pointer">
                                    {{ Math.round(product.stockTotal) }}
                                </td>
                                <td class="text-center">{{ product.quantity }}</td>
                                <td class="text-center">
                                    <v-btn outlined small @click="toggleDialogPickManual(product)">
                                        {{ product.codBarrasPick + product.manualPick }}
                                    </v-btn>
                                </td>
                                <td class="text-center">
                                    <v-btn icon small @click="toggleDialogResetPick(product)">
                                        <v-icon small>event_busy</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <!--                    <v-card-actions>-->
                    <!--                        <v-spacer></v-spacer>-->
                    <!--                        <v-btn tile @click="confirmPickECF">Confirmar</v-btn>-->
                    <!--                    </v-card-actions>-->
                </v-card>
            </v-col>
        </v-row>


        <v-row justify="center">
            <v-dialog v-model="dialogEanInvalid" persistent max-width="400">
                <v-card>
                    <v-card-title>Artigo com ean inválido!</v-card-title>
                    <v-card-text>
                        O artigo com o código de barras {{ inputProductNotExist.barcode }} não existe no primavera.
                        <p class="mb-0"><strong>Deseja atualizar o ean do artigo?</strong></p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small  ref="btnCloseEanInvalid" @click="closeDialogChooseProduct">Fechar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <!--                        <v-btn tile small  @click="toggleDialogChooseProduct" color="error">Atualizar</v-btn>-->
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPickManual" persistent max-width="300">
                <v-card>
                    <v-card-title>Picking Manual</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="inputPickManual.quantity"
                                label="Quantidade"
                                dense
                                hide-details
                                outlined
                                ref="inputPick"
                                @focus="$event.target.select()"
                                @keypress.enter="validatePickManual"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small  @click="cancelPickManual">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  @click="validatePickManual" :disabled="inputPickManual.quantity === ''"
                               color="success">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn tile small  v-if="!responseDialog.loading" @click="closeResponseDialog()">
                            Fechar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPrintEan" persistent max-width="400">
                <v-card>
                    <v-card-title>Imprimir Etiqueta do Artigo <br> {{ inputPrintEan.product }}</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-btn tile block :color="inputPrintEan.option === 'barcode' ? 'success' : ''"
                                       @click="inputPrintEan.option = 'barcode'">Código de Barras
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn tile block :color="inputPrintEan.option === 'EAN' ? 'success' : ''"
                                       @click="inputPrintEan.option = 'EAN'">EAN
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-text-field
                                v-model="inputPrintEan.quantity"
                                outlined
                                dense
                                hide-details
                                label="Quantidade"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile @click="dialogPrintEanClose">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile color="success" @click="dialogPrintEanConfirm">Imprimir</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- RESET PICK-->
            <v-dialog v-model="dialogResetPick" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Limpar Picking
                    </v-card-title>
                    <v-card-text>
                        Deseja reiniciar o picking?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small  @click="closeResetDialog">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  color="error" @click="closeResetConfirm()">Limpar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogResetPickProduct" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Limpar Picking do artigo
                    </v-card-title>
                    <v-card-text>
                        Deseja reiniciar o picking do artigo?<br>
                        <strong>{{ inputResetPick.product }}</strong>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small  @click="resetProductCancel()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  color="error" @click="closeResetProductConfirm()">Limpar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: function () {
        return {
            pickOrderId: '',
            pickOrder: {},
            products: [],
            lastProductPick: {
                product: '',
                description: '',
                img: ''
            },
            lastProductsPick: [],
            dialogEanInvalid: false,
            dialogPickManual: false,
            dialogPrintEan: false,
            dialogResetPickProduct: false,
            dialogResetPick: false,
            inputProductNotExist: {
                barcode: '',
                product: '',
                description: '',
                img: ''
            },
            lastPick: '',
            inputPickManual: {
                id: '',
                idLine: '',
                product: '',
                description: '',
                quantity: '',
                barcodePick: '',
                orderQuantity: ''
            },
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                quantity: false,
                title: '',
                message: '',
                submessage: ''
            },
            inputPrintEan: {
                product: '',
                option: '',
                quantity: 1
            },
            inputResetPick: {
                idLine: '',
                product: ''
            },
            totalProductsByReference: 0,
            predefineQuantity: false
        }
    },
    created() {
        this.pickOrderId = this.$route.params.id;
        document.title = 'Picking Compras - ' + this.pickOrderId + ' | STAFF';
        this.getPickOrder();
    },
    computed: {
        totalProductsPick() {
            let count = 0;
            this.products.forEach(function (product) {
                count = count + product.codBarrasPick;
            });
            return count;
        },
        totalProductsPickManual() {
            let count = 0;
            this.products.forEach(function (product) {
                count = count + product.manualPick;
            });
            return count;
        },
        totalProductsNotPick() {
            let count = 0;
            this.products.forEach(function (product) {
                count = count + product.quantity;
            });

            return count - this.totalProductsPick - this.totalProductsPickManual;
        },
        validBtnClosePick() {
            return this.inputClosePick.volumes === '' || this.inputClosePick.weight === ''
        },
        urlApi() {
            return process.env.VUE_APP_API_URL;
        },
        pickCompleted() {
            return this.totalProductsNotPick === 0 && this.totalProductsPick + this.totalProductsPickManual > 0
        }
    },
    methods: {
        getPickOrder() {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/purchases/pick/' + this.pickOrderId
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pickOrder = responseData.pickOrder;
                            this.products = responseData.products;
                            this.totalProductsByReference = responseData.totalProductsByReference;
                            this.$nextTick(() => this.$refs.barcode.focus());
                        } else if (responseData.status === 'WAR') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        focusInput(ref) {
            // if (ref !== 'input_search') {
            //     this.$nextTick(() => this.$refs[ref][0].focus());
            // } else {
            if (!this.predefineQuantity) {
                this.$nextTick(() => this.$refs[ref].focus());
            } else {
                this.pickProduct();
            }
            // }
        },
        pickProduct() {
            let request = {
                url: '/api/staff/purchases/pick/barcode',
                data: {
                    id: this.pickOrderId,
                    barcode: this.$refs.barcode.value,
                    quantity: this.$refs.quantity.value
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.$refs.barcode.value = '';
                            this.products = responseData.products;
                            this.lastPick = responseData.lastPick;
                            this.lastProductPick = responseData.lastProductPick;
                            if (this.lastProductsPick.length >= 5) {
                                this.lastProductsPick.pop();
                            }
                            this.lastProductsPick.unshift({
                                product: this.lastProductPick.product,
                                description: this.lastProductPick.description,
                                qtyPick: this.$refs.quantity.value
                            });
                            if (!this.predefineQuantity) {
                                this.$refs.quantity.value = '';
                            }
                            var audio = new Audio(this.urlApi + '/beep-07.mp3'); // path to file
                            audio.play();
                            this.$nextTick(() => this.$refs.barcode.focus());
                        } else if (responseData.status === 'WAR') {
                            this.inputProductNotExist.barcode = this.$refs.barcode.value;
                            this.productsPri = responseData.products;
                            this.productsPriOriginal = responseData.products;
                            this.dialogEanInvalid = true;
                            this.$refs.barcode.value = '';
                            var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                            audio.play();
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                            audio.play();
                        }
                    })
                    .catch(() => {
                        var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                        audio.play();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        closeDialogChooseProduct() {
            this.dialogEanInvalid = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        toggleDialogPickManual(product) {
            this.inputPickManual.id = this.pickOrderId;
            this.inputPickManual.idLine = product.id;
            this.inputPickManual.product = product.artigo;
            this.inputPickManual.description = product.descricao;
            this.inputPickManual.quantity = product.codBarrasPick + product.manualPick;
            this.inputPickManual.barcodePick = product.codBarrasPick;
            this.inputPickManual.orderQuantity = product.quantity;

            this.dialogPickManual = true;
            setTimeout(() => {
                this.$nextTick(() => this.$refs.inputPick.focus());
            }, 200);
        },
        cancelPickManual() {
            this.dialogPickManual = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        validatePickManual() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/manual',
                data: {
                    pick: this.inputPickManual
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.products = responseData.products;

                            this.lastPick = this.inputPickManual.idLine;
                            this.lastProductPick = responseData.lastProductPick;

                            if (this.lastProductsPick.length >= 7) {
                                this.lastProductsPick.pop();
                            }
                            this.lastProductsPick.unshift({
                                product: this.lastProductPick.product,
                                description: this.lastProductPick.description
                            });
                            this.dialogPickManual = false;
                            this.$nextTick(() => this.$refs.barcode.focus());

                        } else if (responseData.status === 'WAR') {
                            this.responseDialog.open = true;
                            this.responseDialog.error = true;
                            this.responseDialog.message = '';
                            this.responseDialog.title = 'Quantidade Inválida!';
                            this.responseDialog.message = responseData.data;

                            this.dialogPickManual = false;
                            var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                            audio.play();
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        closeResponseDialog() {
            this.responseDialog.open = false
            this.$refs.barcode.value = '';
            this.$nextTick(() => this.$refs.barcode.focus())
        },
        dialogPrintEanOpen(product) {
            this.inputPrintEan = {
                product: product,
                option: 'barcode',
                quantity: 1
            }
            this.dialogPrintEan = true
        },
        dialogPrintEanClose() {
            this.dialogPrintEan = false
            this.$nextTick(() => this.$refs.barcode.focus())
        },
        dialogPrintEanConfirm() {
            for (let i = 0; i < parseInt(this.inputPrintEan.quantity); i++) {
                this.printLabelEan()
            }
            this.dialogPrintEan = false
            this.$nextTick(() => this.$refs.barcode.focus())
        },
        printLabelEan() {
            let request = {
                url: '/api/staff/pick/printBarcodeEan',
                data: {
                    product: this.inputPrintEan
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Etiqueta enviada para a impressora!',
                                type: 'success'
                            })
                            this.$nextTick(() => this.$refs.barcode.focus())
                        } else if (responseData.status === 'WAR') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Não foi encontrado nenhum artigo com este código EAN!',
                                type: 'warning'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro a imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        toggleDialogResetPick(product) {
            this.inputResetPick.idLine = product.id;
            this.inputResetPick.product = product.artigo;
            this.dialogResetPickProduct = true
        },
        resetProductCancel() {
            this.dialogResetPickProduct = false
            this.$nextTick(() => this.$refs.barcode.focus())
        },
        closeResetProductConfirm() {
            this.dialogResetPickProduct = false;
            this.responseDialog.open = true;
            this.responseDialog.title = 'Limpar Picking do artigo';
            this.responseDialog.loading = true;

            let request = {
                url: '/api/staff/pick/' + this.pickOrderId + '/reset/' + this.inputResetPick.idLine
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.responseDialog.message = 'Picking do artigo reiniciado com sucesso!';
                            this.responseDialog.error = false;
                            this.getPickOrder();
                        } else {
                            this.responseDialog.message = 'Erro ao reiniciar o picking do artigo!';
                            this.responseDialog.error = true;
                        }
                        this.responseDialog.loading = false;
                        this.responseDialog.quantity = false;
                        this.lastPick = '';
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.responseDialog.message = 'Erro de servidor!';
                        this.responseDialog.error = true;
                        this.responseDialog.loading = false;
                    })
        },
        closeResetDialog() {
            this.dialogResetPick = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        closeResetConfirm() {
            this.dialogResetPick = false;
            this.responseDialog.open = true;
            this.responseDialog.title = 'Limpar Picking';
            this.responseDialog.loading = true;

            let request = {
                url: '/api/staff/pick/' + this.pickOrderId + '/reset'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.responseDialog.message = 'Picking reiniciado com sucesso!';
                            this.responseDialog.error = false;
                            this.getPickOrder();
                        } else {
                            this.responseDialog.message = 'Erro ao reiniciar o picking!';
                            this.responseDialog.error = true;
                        }
                        this.responseDialog.loading = false;
                        this.responseDialog.quantity = false;
                        this.lastPick = '';
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.responseDialog.message = 'Erro de servidor!';
                        this.responseDialog.error = true;
                        this.responseDialog.quantity = false;
                        this.responseDialog.loading = false;
                    })
        },
        confirmPickECF() {
            this.$router.push('/purchases/pick/invoice/' + this.pickOrderId);
        },
        btnPredefineQuantity() {
            if (!this.predefineQuantity) {
                this.predefineQuantity = true;
                this.$refs.quantity.value = 1;
            } else {
                this.predefineQuantity = false;
                this.$refs.quantity.value = '';
            }
        }
    }
}
</script>
