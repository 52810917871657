<template>
    <v-container fluid>
        <PendingOrders></PendingOrders>
        <v-row>
            <v-col>
                <PendingDPD></PendingDPD>
                <PendingCTT></PendingCTT>
                <PendingNACEX></PendingNACEX>
            </v-col>
            <v-col>
                <PendingREDUR></PendingREDUR>
                <OpenProduct></OpenProduct>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <TableOrdersB2B></TableOrdersB2B>
            </v-col>
            <v-col>
                <TableServiceCenter></TableServiceCenter>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <last-sales></last-sales>
            </v-col>
            <v-col sm="6">
                <last-orders></last-orders>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import TableOrdersB2B from "./Widgets/TableOrdersB2B";
    import PendingOrders from "./Widgets/PendingOrders";
    import TableServiceCenter from "./Widgets/TableServiceCenter";
    import PendingDPD from "./Widgets/PendingDPD";
    import PendingCTT from "./Widgets/PendingCTT";
    import OpenProduct from "@/components/Dashboard/Widgets/OpenProduct";
    import PendingREDUR from "@/components/Dashboard/Widgets/PendingREDUR";
    import PendingNACEX from "@/components/Dashboard/Widgets/PendingNACEX";
    import LastSales from "./Widgets/LastSales";
    import LastOrders from "./Widgets/LastOrders";

    export default {
        components: {
            OpenProduct,
            TableServiceCenter,
            TableOrdersB2B,
            PendingOrders,
            PendingDPD,
            PendingCTT,
            PendingREDUR,
            PendingNACEX,
            LastOrders,
            LastSales,
        },
    }
</script>
