import { VCard } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"float":"left"}},[_c(VCard,{staticClass:"mr-3 mb-3",attrs:{"dark":"","color":"red"}},[_c(VListItem,[_c(VListItemTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('product')))])],1),_c(VListItem,[_c(VListItemContent,[_c(VTextField,{attrs:{"outlined":"","dense":"","hide-details":"","append-icon":"arrow_right_alt","loading":_vm.loading},on:{"click:append":_vm.getProduct,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getProduct.apply(null, arguments)}},model:{value:(_vm.barcode),callback:function ($$v) {_vm.barcode=$$v},expression:"barcode"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }