<template>
  <v-dialog v-model="showSelf" persistent max-width="984" @click:outside="closeDialogParent">
    <v-card>
      <v-btn
          color="grey lighten-3"
          dark
          fab
          top
          absolute
          right
          @click="closeDialogParent"
      >
        <span class="buttonClose">x</span>
      </v-btn>
      <v-container class="pa-16">
        <v-row v-if="uploaded == false" align-content="center" justify="center">

          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-combobox
                    dense
                    outlined
                    label="Coluna com identificação"
                    v-model="form.idColumn"
                    :items="availableColumns"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row align="center"  v-if="ignoreQuantity == false" justify="center">
              <v-col cols="6">
                <v-combobox
                    dense
                    outlined
                    label="Coluna com Quantidade"
                    v-model="form.quantityColumn"
                    :items="availableColumns"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-combobox
                    dense
                    outlined
                    label="Linha onde começam os dados"
                    v-model="form.startingRow"
                    :items="availableRows"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-file-input
                    v-model="form.file"
                    dense
                    outlined
                    truncate-length="15"
                    accept=".xlsx, .xls, .csv"
                    label="Carregue o ficheiro"
                    ngf-max-size="10MB"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" style="text-align:center">
                <v-btn class="btn btn-small primary" :disabled="validForm() === false" @click="checkFile()">
                  Analizar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

        </v-row>
        <v-row v-if="uploaded == false" align-content="center" justify="center">
          <strong style="color:red"> {{ errorMessage }} </strong>
        </v-row>
        <v-row v-else align-content="center" justify="center">
          <v-container>
            <v-row>
              <v-col cols="1" class="text-center">
                <span>({{ totalSelected }})</span>
                <v-checkbox
                    v-model="checkboxAll"
                    @click="selectAll()"
                >


                </v-checkbox>
              </v-col>
              <v-col cols="3" class="text-center">{{ $t('product') }}</v-col>
              <v-col cols="5" class="text-center">{{ $t('description') }}</v-col>
              <v-col cols="2" v-if="ignoreQuantity == false" class="text-center">{{ $t('quantity') }}</v-col>
              <v-col cols="1" class="text-center">Match OK</v-col>
            </v-row>
            <v-row v-for="(line, index) in notFoundProducts" class="error productBorders">
              <v-col cols="1" class="text-center"></v-col>
              <v-col cols="3">
                <v-container>
                  <v-row>
                    <v-text-field
                        solo
                        dense
                        outlined
                        label="EAN"
                        v-model="line.ean"
                    />
                    <button class="btn btn-small" @click="toggleModalLoadAll(line,index)">
                      <i class="fa fa-check text-success"></i>
                    </button>
                  </v-row>
                </v-container>
              </v-col>
              <v-col cols="5"></v-col>
              <v-col cols="2" v-if="ignoreQuantity == false" class="text-center">{{ line.quantity }}</v-col>
              <v-col cols="1" class="text-center"><span>{{ $t('no') }}</span></v-col>
            </v-row>
            <template v-for="(line, index) in validProducts">
              <v-row class="productBorders pa-3"
                     :class="{'isParentProduct' : line.extras.length}">
                <v-col cols="1" class="text-center" v-if="line.extras.length == 0 || ignoreQuantity == true">
                  <div>
                    <v-checkbox
                        class="justify-center"
                        v-model="line.selected"
                    ></v-checkbox>
                    <!--                    <i v-if="line.selected == false"
                                           @click="line.selected = true;selectionCounter(1);productsTotalCounter(line.quantity)"
                                           class="fa fa-square-o fa-lg"></i>
                                        <span>
                                                                          <i v-if="line.selected == true" class="fa fa-check-square-o fa-lg"
                                                                             style="color:#1976d2"
                                                                             @click="line.selected = false;selectionCounter(-1);productsTotalCounter(-line.quantity);"></i>
                                                                      </span>-->
                  </div>
                </v-col>
                <v-col cols="3">
                  {{ line.productId }}
                </v-col>
                <v-col :cols="(line.extras.length == 0 || ignoreQuantity == true) ? 5 : 6" class="text-center">
                  {{ line.name }}
                </v-col>
                <v-col cols="2" v-if="ignoreQuantity == false" class="text-center">
                  {{ line.quantity }}
                </v-col>
                <v-col cols="1" class="text-center">
                  {{ $t('yes') }}
                </v-col>
                <v-container class="productBorders" v-if="line.extras.length > 0 && ignoreQuantity == false">
                  <template v-for="(extra,extraIndex) in line.extras">
                    <v-row class="pa-3">
                      <v-col cols="1" class="text-center">
                        <span style="cursor: pointer">
                           <v-checkbox
                               :disabled="extra.quantity<=0"
                               class="justify-center"
                               v-model="extra.selected">
      <!--                         @click=selectedExtraProduct(extra,extraIndex,index)-->
                           </v-checkbox>
                          <!--                      <i v-if="extra.selected == false"
                                                   @click="selectedExtraProduct(extra, true, 1, extra.quantity);"
                                                   class="fa fa-square-o fa-lg"></i>
                                                <i v-if="extra.selected == true" class="fa fa-check-square-o fa-lg"
                                                   style="color:#1976d2"
                                                   @click="selectedExtraProduct(extra,false,-1,-extra.quantity);"></i>-->
                        </span>
                      </v-col>
                      <v-col cols="3">
                        <strong>{{ line.productId }}</strong> (<span
                          style="text-decoration: underline">{{ extra.productId }}</span>)
                      </v-col>
                      <v-col cols="6">
                        {{ extra.description }}
                      </v-col>
                      <v-col cols="2" class="text-center">
                        <v-text-field
                            v-model.number="extra.quantity"
                            solo
                            hide-details
                            style="width: 60px;text-align: center;"
                            type="number" size="4" maxlength="4"
                            @change="extraQuantityChange(line, extra)"
                        />
                      </v-col>
                    </v-row>
                    <hr style="border-top: 1px dotted lightgray;">
                  </template>
                </v-container>
              </v-row>
              <hr style="border-top: 1px solid lightgray;">
            </template>
          </v-container>
        </v-row>
      </v-container>
      <v-card-actions v-if="uploaded==true" class="text-center">
        <v-col>
          <span>Quantidade selecionada: {{ totalQuantitySelected }}</span>
          <v-spacer></v-spacer>
        </v-col>
        <v-col>
          <v-btn color="primary"
                 @click="confirmSelection()">Confirmar
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

import axios from "axios";

export default {
  data: () => ({
    showSelf: true,
    availableColumns: [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T'
    ],
    availableRows: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15
    ],
    checkboxAll: false,
    totalQuantitySelected: 0,
    uploaded: false,
    validProducts: [],
    notFoundProducts: [],
    form: {
      idColumn: null,
      quantityColumn: null,
      startingRow: null,
      file: null
    },
    errorMessage: "",
    loaded: false
  }),
  name: "ProductImporter",
  props: {
    ignoreQuantity: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    totalSelected() {
      this.totalQuantitySelected = 0;
      return this.validProducts.reduce((total, product) => {
        let currentSum = 0;
        if (product.selected == true) {
          currentSum += 1;
          this.totalQuantitySelected += product.quantity;
        }
        let alreadyCounted = false;
        for (let i = 0; i < product.extras.length; i++) {
          if (product.extras[i].selected == true) {
            if (alreadyCounted == false)
              currentSum += 1;
            this.totalQuantitySelected += product.extras[i].quantity;
            alreadyCounted = true;
          }
        }
        return total + currentSum;
      }, 0);

    },
  },
  methods: {
    validForm() {
      return this.form.idColumn != null && ((this.ignoreQuantity == false && this.form.quantityColumn != null) || this.ignoreQuantity == true) && this.form.startingRow != null && this.form.file != null;
    },
    setErrorMessage(message) {
      this.errorMessage = message;
      setTimeout(() => this.errorMessage = "", 3000);
    },
    selectAll() {
      let hasManipulos = false;

      this.validProducts.forEach((line) => {
        if (line.extras.length) {
          line.extras.forEach((extra) => {
            hasManipulos = true;
            if (parseInt(extra.quantity) > 0) {
              extra.selected = this.checkboxAll;
            }
          });
        } else {
          line.selected = this.checkboxAll;
        }
      });
      if (this.checkboxAll == true && hasManipulos == true)
        alert('Existem produtos com manipulos por selecionar.');
    },
    checkFile() {
      if (this.validForm() === false)
        return;

      let loader = this.$loading.show();

      if(this.ignoreQuantity == true)
        this.form.quantityColumn = "A";

      let url = '/api/staff/products/import';
      let formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('idColumn', this.form.idColumn);
      formData.append('quantityColumn', this.form.quantityColumn);
      formData.append('startingRow', this.form.startingRow);
      formData.append('ignoreQuantity', this.ignoreQuantity == true ? 1 : 0);
      axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        let responseData = response.data;

        if (responseData.status === 'OK') {
          this.validProducts = responseData.validProducts;
          this.notFoundProducts = responseData.notFoundProducts;
          if (this.validProducts == 0 && this.validProducts == 0) {
            this.setErrorMessage("Nenhum produto encontrado, verifique as colunas selecionadas.");
          } else
            this.uploaded = true;
        } else {
          this.errorMessage = "There was an error on the API";

        }
        loader.hide();
      })
          .catch(() => {
            this.errorMessage = "There was an error on the API";
            loader.hide();
          })
    },
    productsTotalCounter(value) {
      this.totalQuantitySelected += value;
    },
    selectedExtraProduct(extra, extraIndex, productIndex) {
      if (extra.quantity > 0) {
        if (extra.selected = true) {
          this.selectionCounter(1);
          this.productsTotalCounter(extra.quantity);
        } else {
          this.selectionCounter(-1);
          this.productsTotalCounter(-extra.quantity);
        }

      } else {
        extra.selected = false;
        this.validProducts[productIndex].extras.push();
        alert('Quantidade tem de ser maior que 0');
      }
    },
    unselectAll() {
      this.checkboxAll = false;
      this.validProducts.forEach((line) => {
        if (line.extras.length) {
          line.extras.forEach((extra) => {
            extra.selected = false;
          });
        } else
          line.selected = false;
      });
    },
    extraQuantityChange(line, extra) {
      extra.quantity = parseInt(extra.quantity);
      if (isNaN(extra.quantity))
        extra.quantity = 0;
      let totalItems = 0;
      line.extras.forEach((extra) => totalItems += extra.quantity);
      if (totalItems > line.quantity) {
        extra.quantity = 0;
        extra.selected = false;
        alert('Quantidade dos manipulos excede a quantidade do produto');
      } else if (extra.quantity < 0) {
        extra.quantity = 0;
        extra.selected = false;
        alert('Quantidade não pode ser menor que 0');
      } else
        extra.selected = true;
    },
    confirmSelection() {
      let temporarySelectedProducts = this.validProducts.filter((product) => {
        if (product.selected == true)
          return true;

        for (let i = 0; i < product.extras.length; i++) {
          if (product.extras[i].selected == true)
            return true;
        }
        return false;
      });

      let selectedProducts = JSON.parse(JSON.stringify(temporarySelectedProducts));

      for (let i = 0; i < selectedProducts.length; i++) {

        if (selectedProducts[i].extras.length > 0) {
          //let temporaryExtras = selectedProducts[i].extras.filter(extra => extra.selected == true);
          selectedProducts[i].extras = selectedProducts[i].extras.filter(extra => extra.selected == true);

        }
      }

      this.$emit('confirm-selection', selectedProducts);

    },
    closeDialogParent() {
      this.$emit('close-importer');
    },
  }
}
</script>

<style scoped>
.buttonClose {
  margin-top: 24px;
  color: #a79090;
  font-size: 22px;
  font-weight: 1000;
}
</style>
