<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Campanhas"
                        :subtitle="'A mostrar ' + campaigns.length + ' campanhas.'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small class="ml-2" color="success" :to="'/b2b/campaigns2/0'">Nova Campanha</v-btn>
                <v-btn tile small class="ml-2" color="info" @click="getCampaigns()">Actualizar</v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 30px;">ID</th>
                            <th style="width: 150px;">Tipo</th>
                            <th>Nomes</th>
                            <th>Descrição</th>
                            <th>Data de Ínicio</th>
                            <th>Data de Fim</th>
                            <th style="width: 100px;">Estado</th>
                            <th style="width: 80px;"></th>
                        </tr>
                        <!-- FILTERS -->
                        <tr class="filters_row">
                            <td></td>
                            <td>
                                <v-select
                                        v-model="filters.type"
                                        :items="campaignTypes"
                                        item-text="type"
                                        item-value="id"
                                        outlined
                                        dense
                                        hide-details
                                        @change="getCampaigns()"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.title"
                                        dense
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="getCampaigns()"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        dense
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup.enter="getCampaigns()"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                                <v-select
                                        v-model="filters.active"
                                        :items="options"
                                        outlined
                                        dense
                                        hide-details
                                        @change="getCampaigns()"
                                ></v-select>
                            </td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="12">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="item in campaigns">
                            <td>{{ item.id }}</td>
                            <td>{{ $campaignType(item.campaignTypeId, campaignTypes) }}</td>
                            <td>{{ item.title }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.dateStart }}</td>
                            <td>{{ item.dateEnd }}</td>
                            <td>
                                <template v-if="item.active == 1">Activa</template>
                                <template v-else>Inactiva</template>
                            </td>
                            <td class="text-right">
                                <v-btn tile small color="primary" :to="'/b2b/campaigns2/'+item.id+'?name=' + item.name">Editar</v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    data: () => ({
        campaigns: [],
        campaignTypes: [],
        options: [
            {text: 'Todos', value: ''},
            {text: 'Activa', value: '1'},
            {text: 'Inactiva', value: '0'},
        ],
        filters: {
            type: '',
            active: '',
            name: ''
        },
    }),
    created() {
        this.getCampaigns();
    },
    methods: {
        getCampaigns() {
            this.loading = true;
            this.campaigns = [];
            let request = {
                url: "/api/staff/campaigns",
                data: {
                    filters: this.filters
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.campaigns = responseData.campaigns;
                            this.campaignTypes = responseData.campaignTypes;
                        }
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
        },
    }
}
</script>
