import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const Alert = new Vuex.Store({
    state: {
        snackbar: false,
        text: '',
        type: ''
    },
    actions: {
        show(context, alert) {
            context.commit('updateAlert', alert)
        }
    },
    mutations: {
        updateAlert(state, alert) {
            state.text = alert.text
            state.type = alert.type
            state.snackbar = alert.snackbar
        }
    }
})

export default Alert
