<style scoped>
.v-input__append-inner {
    margin-top: 5px !important;
}

/*.staff_table tr .v-btn {
  display: none;
}*/

/*.staff_table tr:hover .v-btn {
  display: block;
}*/

</style>

<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title title="PDF Compras"
                            :subtitle="$t('show') + ' ' + lines.length + ' de ' + originalLines.length + ' documentos'"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small class="ml-2 mb-2" @click="resetFilters()" color="error">Limpar Filtros</v-btn>
                <!--        <v-btn tile small class="ml-2 mb-2" @click="update()" color="info">Atualizar</v-btn>-->
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card mb-3">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 400px; cursor: pointer;" @click="orderList('Nome')" class="text-center">
                                Nome
                                <i v-if="selectedHeader === 'Nome'" class="fas"
                                   :class="[headers['Nome'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('Serie')" class="text-center">
                                Serie
                                <i v-if="selectedHeader === 'Serie'" class="fas"
                                   :class="[headers['Serie'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 75px; cursor: pointer;" @click="orderList('TipoDoc')" class="text-center">
                                Tipo
                                Documento <i v-if="selectedHeader === 'TipoDoc'" class="fas"
                                             :class="[headers['TipoDoc'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 200px; cursor: pointer;" @click="orderList('NumDoc')" class="text-center">
                                Num. Documento
                                <i v-if="selectedHeader === 'NumDoc'" class="fas"
                                   :class="[headers['NumDoc'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 200px; cursor: pointer;" @click="orderList('Entidade')"
                                class="text-center">Entidade <i
                                    v-if="selectedHeader === 'Entidade'" class="fas"
                                    :class="[headers['Entidade'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                            <th style="width: 200px; cursor: pointer;" @click="orderList('DataDoc')"
                                class="text-center">Data do
                                Documento <i
                                        v-if="selectedHeader === 'DataDoc'" class="fas"
                                        :class="[headers['DataDoc'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th style="width: 200px; cursor: pointer;" @click="orderList('TotalDocumento')"
                                class="text-center">Total
                                do Documento <i
                                        v-if="selectedHeader === 'TotalDocumento'" class="fas"
                                        :class="[headers['TotalDocumento'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                            </th>
                            <th class="text-center">Estado do Ficheiro</th>
                        </tr>
                        <tr class="filters_row">
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Nome"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Serie"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.TipoDoc"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.NumDoc"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Entidade"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.DataDoc"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.TotalDocumento"
                                              @change="updateTable()"></v-text-field>
                            </th>
                            <th>
                                <v-select
                                        v-model="filters.HasFile"
                                        :items="fileStatusValues"
                                        @change="updateTable()"
                                        outlined
                                        dense
                                        item-text="TEXT"
                                        item-value="VALUE"
                                        hide-details
                                ></v-select>
                            </th>
                            <!--              <th>
                                            <v-text-field outlined hide-details v-model="filters.Id"
                                                          @change="updateTable()"></v-text-field>
                                          </th>-->
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="line in pagedLines">
                            <td>{{ line.Nome }}</td>
                            <td class="text-center">{{ line.Serie }}</td>
                            <td class="text-center">{{ line.TipoDoc }}</td>
                            <td class="text-center" width="11%">{{ line.NumDoc }}</td>
                            <td class="text-center" width="10%">{{ line.Entidade }}</td>
                            <td class="text-center">{{ line.DataDoc }}</td>
                            <td class="text-right pr-4" width="10%">
                                {{ line.Moeda == 'EUR' ? $money(line.TotalDocumento) : $dollar(line.TotalDocumento) }}
                            </td>
                            <!--              <td>{{ line.Id }}</td>-->
                            <td class="text-center" width="12%">
                                <v-btn tile small color="primary" class="mr-3" @click="toggleDialogVisibility(line.Id)"
                                       v-if="line.HasFile == '0'">
                                    UPLOAD
                                </v-btn>
                                <v-btn tile small color="error" v-if="line.HasFile == '1'" @click="toggleDelete(line.pdfId)">
                                    REMOVER
                                </v-btn>
                                <!--                <v-btn color="primary" @click="document.getElementById('uploader').click()">
                                                  Upload
                                                  <v-file-input hide-input accept=".pdf" v-model="chosenFile" id="uploader"></v-file-input>
                                                </v-btn>-->
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
                <v-row justify="center">
                    <v-dialog v-model="dialogVisibility" persistent max-width="950">
                        <v-card>
                            <v-card-title>
                                Upload de Ficheiro
                            </v-card-title>
                            <v-card-text>
                            <v-row>
                                <v-col>
                                    <vue-dropzone v-if="description" class="ma-5" ref="myVueDropzone" id="dropzone"
                                                  :key="dropzoneOptions.url" :options="dropzoneOptions"></vue-dropzone>
                                    <!--                      <v-file-input  accept=".pdf" v-model="chosenFile" label="Escolha o Ficheiro..." id="uploader"></v-file-input>
                                                        <input type="file" id="file" name="file"/>
                                                        <v-btn tile small class="ml-2 mb-2" @click="debugUpload()" color="error">debugUpload</v-btn>
                                                        <v-btn tile small class="ml-2 mb-2" @click="sendToAPI()" color="error">Send to API</v-btn>-->
                                </v-col>
                            </v-row>
                            <v-textarea class="ml-5 mr-5" outlined dense v-model="description" placeholder="Descrição"
                                        @keyup="updateDescription()"></v-textarea>
                            </v-card-text>
                            <v-card-actions class="pa-3">
                                <v-spacer></v-spacer>
                                <v-btn color="error" @click="closeDialogAndUpdateList()">Fechar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>

            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-select
                        v-model="pagination.rowsPerPage"
                        :items="filtersPerPage"
                        dense
                        outlined
                        hide-details
                        item-text="TEXT"
                        item-value="VALUE"
                        style="float: left; width: 100px;"
                ></v-select>
            </v-col>
            <v-col class="text-right">
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        style="justify-content: flex-end;"
                ></v-pagination>
            </v-col>
        </v-row>
        <!--    DELETE DIALOG-->
        <v-dialog v-model="deleteDialog" overlay-color="white" overlay-opacity="0.5" persistent
                  max-width="530">
            <v-card>
                <v-card-title  class="red--text">
                    Tem a certeza que quer remover o PDF da fatura?
                </v-card-title>
                <v-card-actions>
                    <v-btn color="error" @click="deleteDialog = false">Não</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="removePDF()">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--    DELETE DIALOG-->
    </v-container>
</template>

<script>

import _ from "lodash";
import FileUpload from 'v-file-upload'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    data: function () {
        return {
            document,
            chosenFile: null,
            pdf: null,
            PDFs: [],
            description: '',
            lines: [],
            originalLines: [],
            total: [],
            types: [],
            typeDescriptions: [],
            creators: [],
            deleteDialog: false,
            filters: {
                Nome: '',
                Serie: '',
                TipoDoc: '',
                NumDoc: '',
                DataDoc: '',
                Entidade: '',
                TotalDocumento: '',
                Id: '',
                Moeda: '',
                HasFile: '',
                pdfId: ''
            },
            fileStatusValues: [
                {TEXT: 'Todos', VALUE: ''},
                {TEXT: 'Sem Ficheiro', VALUE: false},
                {TEXT: 'Com Ficheiro', VALUE: true}
            ],
            selectedHeader: "DataDoc",
            headers: {
                "Nome": 'asc',
                "Serie": 'asc',
                "TipoDoc": 'asc',
                "NumDoc": 'asc',
                "DataDoc": 'asc',
                "Entidade": 'asc',
                "TotalDocumento": 'asc',
                "Id": 'asc',
                "HasFile": 'asc'
            },
            pagination: {
                rowsPerPage: 25,
                page: 1
            },
            itemPerPage: [
                {text: '50', value: 50},
                {text: '100', value: 100},
                {text: '250', value: 250},
                {text: '500', value: 500}
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            dialogVisibility: false,
            dropzoneOptions: {
                url: '',
                thumbnailWidth: 50,
                maxFilesize: 50,
                acceptedFiles: "application/pdf",
                headers: {"Authorization": "Bearer " + User.getters.getAccessToken},
                uploadMultiple: true,
                parallelUploads: 6
            },
            selectedDocInfo: {
                Id: ''
            }
        }
    },
    created() {
        document.title = 'PDFs | STAFF';
        this.getPDFs();
    },
    methods: {
        updateDescription() {
            this.dropzoneOptions.url = AppModel.getters.getUrlAPI + '/api/staff/accounts/pdf-upload?Id=' + this.selectedDocInfo.Id + '&description=' + this.description + '&brand=bicimax'
            this.$set(this.dropzoneOptions, 'url', AppModel.getters.getUrlAPI + '/api/staff/accounts/pdf-upload?Id=' + this.selectedDocInfo.Id + '&description=' + this.description + '&brand=bicimax');
        },
        getPDFs() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/accounts/pdfsList/bicimax'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            // this.PDFs = responseData.data;
                            this.originalLines = responseData.data;
                            //this.lines = responseData.data;
                            this.lines = responseData.data.map(((x) => x));
                            this.total = this.originalLines.length;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        closeDialogAndUpdateList(){
            this.dialogVisibility = false;
            this.getPDFs();
        },
        removePDF(){
            let request = {
                url: '/api/staff/accounts/pdfs/' + this.idToDelete + '/bicimax'
            }
            this.loading = true
            let loader = this.$loading.show();

            ApiWS.dispatch('makeDelete', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Eliminado com sucesso!',
                                type: 'success'
                            })
                            loader.hide()
                            this.getPDFs();
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.deleteDialog = !this.deleteDialog;
            loader.hide()
        },
        toggleDelete(id) {
            this.deleteDialog = !this.deleteDialog;
            this.idToDelete = id;
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;

        },
        updateTable() {
            //this.lines = this.originalLines
            //
            this.lines = this.originalLines.map(((x) => x))

            if (this.filters.Nome !== '') {
                this.lines = this.lines.filter(line => line.Nome.includes(this.filters.Nome))
            }
            if (this.filters.Serie !== '') {
                this.lines = this.lines.filter(line => line.Serie.includes(this.filters.Serie))
            }
            if (this.filters.TipoDoc !== '') {
                this.lines = this.lines.filter(line => line.TipoDoc.includes(this.filters.TipoDoc))
            }
            if (this.filters.NumDoc !== '') {
                this.lines = this.lines.filter(line => line.NumDoc == this.filters.NumDoc)
            }
            if (this.filters.Entidade !== '') {
                this.lines = this.lines.filter(line => line.Entidade == this.filters.Entidade)
            }
            if (this.filters.DataDoc !== '') {
                this.lines = this.lines.filter(line => line.DataDoc != null && line.DataDoc.includes(this.filters.DataDoc))
            }
            if (this.filters.TotalDocumento !== '') {
                this.lines = this.lines.filter(line =>
                        this.$2decimal(line.TotalDocumento) === this.$2decimal(this.filters.TotalDocumento))
            }
            if (this.filters.HasFile !== '') {
                this.lines = this.lines.filter(line => line.HasFile == this.filters.HasFile)
            }

            this.total = this.lines.length
            this.pagination.page = 1;

        },
        resetFilters() {
            this.filters = _.mapValues(this.filters, () => '')
            this.updateTable()
        },
        toggleDialogVisibility(Id) {
            this.selectedDocInfo.Id = Id;

            // this.dropzoneOptions.url = AppModel.getters.getUrlAPI + '/api/staff/accounts/pdf-upload?serie='+this.selectedDocInfo.serie+'&numDoc='+this.selectedDocInfo.numDoc+'&tipoDoc='+this.selectedDocInfo.tipoDoc
            //this.$refs.myVueDropzone[0].options.url = AppModel.getters.getUrlAPI + '/api/staff/accounts/pdf-upload?serie='+this.selectedDocInfo.serie+'&numDoc='+this.selectedDocInfo.numDoc+'&tipoDoc='+this.selectedDocInfo.tipoDoc
            this.dropzoneOptions.url = AppModel.getters.getUrlAPI + '/api/staff/accounts/pdf-upload?Id=' + this.selectedDocInfo.Id + '&description=' + this.description
            this.$set(this.dropzoneOptions, 'url', AppModel.getters.getUrlAPI + '/api/staff/accounts/pdf-upload?Id=' + this.selectedDocInfo.Id + '&description=' + this.description);
            //console.log(this.$refs.myVueDropzone);
            this.dialogVisibility = !this.dialogVisibility;
            this.description = '';
        },
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.pagination.rowsPerPage)
        },
        pagedLines: function () {
            return this.orderedLines.slice((this.pagination.page - 1) * this.pagination.rowsPerPage, this.pagination.page * this.pagination.rowsPerPage);
        },
        orderedLines: function () {
            return _.orderBy(this.lines, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    }
}
</script>
