import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const ClientModel = new Vuex.Store({
    actions: {
        getClients(context, filters) {
            return new Promise((resolve, reject) => {
                axios.post(api.clients(), filters)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getDirectDebits(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.clientsDirectDebits())
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    }
})

export default ClientModel
