<style scoped>
    tr:hover td {
        background-color: rgba(0, 0, 0, 0.12);
    }
</style>

<template>
    <v-container>
        <v-app-bar flat color="transparent">
            <v-toolbar-title> {{ $t('coupons') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on }" v-if="writePermission('b2b/coupons')">
                    <v-btn color="primary" dark class="mb-2 translation" v-on="on">
                        {{$t('addItem')}}
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <v-container grid-list-md>
                                <v-layout wrap>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field v-model="editedItem.cupon" :label="$t('coupon')" :rules="[rules.required]" name="coupon"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field v-model="editedItem.desconto" :label="$t('discount')" :rules="[rules.required]" name="discount"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md4>
                                        <v-text-field v-model="editedItem.entidade" :label="$t('entity')" name="entidade"></v-text-field>
                                    </v-flex>
                                    <v-flex xs12 sm6 md6>
                                        <v-menu
                                                v-model="menu2"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                full-width
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="editedItem.dataInicio"
                                                        label="Data Inicio"
                                                        prepend-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editedItem.dataInicio" locale="pt-pt" :show-current="currentDate" @input="menu2 = false"></v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                    <v-flex xs12 sm6 md6>
                                        <v-menu
                                                v-model="menu3"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                full-width
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="editedItem.dataFim"
                                                        label="Data Final"
                                                        prepend-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="editedItem.dataFim" locale="pt-pt" :show-current="currentDate" @input="menu3 = false"></v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click.prevent="close">Cancelar</v-btn>
                        <v-btn color="blue darken-1" text @click.prevent="save">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-app-bar>
        <v-data-table
                :headers="headers"
                :items="coupons"
                class="elevation-1"
                :items-per-page="25"
                :footer-props="{
                    'items-per-page-options': [25,50,100,-1]
                }"
        >
            <template v-slot:body="{ items }">
                <tr v-for="item in items" :key="item.id"
                    style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;"
                >
                    <td>{{ item.id }}</td>
                    <td class="text-xs-center">{{ item.cupon }}</td>
                    <td class="text-xs-center">{{ item.campanha }}</td>
                    <td class="text-xs-center">{{ item.entidade }}</td>
                    <td class="text-xs-center">{{ item.desconto }}</td>
                    <td class="text-xs-center">{{ item.total }}</td>
                    <td class="text-xs-center">{{ item.dataInicio }}</td>
                    <td class="text-xs-center">{{ item.dataFim }}</td>
                    <td class="justify-center layout px-0">
                        <template v-if="writePermission('b2b/coupons')">
                            <v-icon
                                    small
                                    @click="deleteItem(item)"
                            >
                                delete
                            </v-icon>
                        </template>
                    </td>
                </tr>
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
        </v-data-table>
        <v-layout row justify-center>
            <v-dialog v-model="dialogCancel" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">{{$t('removeCoupon')}}?</v-card-title>
                    <v-card-text>{{$t('wantRemoveCoupon')}} {{ itemCancel.cupon }}?</v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" flat @click="dialogCancel = false">{{$t('no')}}</v-btn>
                        <v-btn color="red darken-1" flat @click="removeCoupon()">{{$t('yes')}}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data: () => ({
            dialog: false,
            dialogCancel: false,
            headers: [],
            coupons: [],
            editedIndex: -1,
            editedItem: {
                cupon: '',
                desconto: '',
                entidade: '',
                dataInicio: new Date().toISOString().substr(0, 10),
                dataFim: new Date().toISOString().substr(0, 10)
            },
            defaultItem: {
                cupon: '',
                desconto: '',
                entidade: '',
                dataInicio: new Date().toISOString().substr(0, 10),
                dataFim: new Date().toISOString().substr(0, 10)
            },
            rules: {
                required: value => !!value || value === 0 || i18n.t('required')
            },
            itemCancel: {},
            menu2: false,
            menu3: false,
            currentDate: new Date().toISOString().substr(0, 10)
        }),
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? i18n.t('addItem') : 'Editar'
            }
        },
        watch: {
            dialog(val) {
                val || this.close()
            }
        },
        created() {
            document.title = i18n.t('coupons')
            this.initialize()
        },
        methods: {
            initialize() {
                B2B.dispatch('getCoupons')
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.coupons = responseData.coupons
                            this.headers = responseData.headers
                        }
                    })
            },
            deleteItem(item) {
                this.itemCancel = item
                this.dialogCancel = true
                // const index = this.permissions.indexOf(item)
                // confirm('Are you sure you want to delete this item?') && this.users.splice(index, 1)
            },
            close() {
                this.dialog = false
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                    this.$refs.form.resetValidation()
                }, 300)
            },
            save() {
                if (this.$refs.form.validate()) {
                    if (this.editedIndex > -1) {
                        // Permission.dispatch('updatePermission', this.editedItem)
                        //     .then(response => {
                        //         let responseData = response.data
                        //         if (responseData.status === 'OK') {
                        //             this.close()
                        //
                        //             this.initialize()
                        //         } else {
                        //             let alert = {
                        //                 snackbar: true,
                        //                 text: responseData.data,
                        //                 type: 'error'
                        //             }
                        //
                        //             Alert.dispatch('show', alert)
                        //         }
                        //     })
                        //     .catch(response => {
                        //         let alert = {
                        //             snackbar: true,
                        //             text: 'Erro ao alterar permissão',
                        //             type: 'error'
                        //         }
                        //
                        //         Alert.dispatch('show', alert)
                        //     })
                    } else {
                        B2B.dispatch('saveCoupon', this.editedItem)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    let alert = {
                                        snackbar: true,
                                        text: 'Cupão adicionada com sucesso',
                                        type: 'success'
                                    }

                                    Alert.dispatch('show', alert)

                                    this.close()

                                    this.coupons = responseData.coupons
                                } else if (responseData.status === 'warning') {
                                    let alert = {
                                        snackbar: true,
                                        text: 'Cupão já existente!',
                                        type: 'warning'
                                    }

                                    Alert.dispatch('show', alert)
                                } else {
                                    let alert = {
                                        snackbar: true,
                                        text: responseData.data,
                                        type: 'error'
                                    }

                                    Alert.dispatch('show', alert)
                                }
                            })
                            .catch(response => {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao adicionar cupão',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            })
                    }
                    // this.close()
                }
            },
            removeCoupon() {
                B2B.dispatch('removeCoupon', this.itemCancel)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let alert = {
                                snackbar: true,
                                text: 'Cupão removido com sucesso',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)

                            this.dialogCancel = false;

                            this.coupons = responseData.coupons
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao remover cupão',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao remover cupão',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
            },
            writePermission(permission) {
                return User.getters.writePermission(permission)
            }
        }
    }

</script>
