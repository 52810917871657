import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_c('div',{staticClass:"text-center"},[(_vm.loading)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary","size":"100"}}):_vm._e(),(!_vm.loading)?_c('div',[(_vm.error)?_c('i',{staticClass:"fas fa-times fa-5x",staticStyle:{"color":"red"}}):_vm._e(),(!_vm.error)?_c('i',{staticClass:"fas fa-check fa-5x",staticStyle:{"color":"green"}}):_vm._e(),_c('p',{staticClass:"headline"},[_vm._v(_vm._s(_vm.message))]),(_vm.alert)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")]):_vm._e()],1):_vm._e()],1)]),_c(VCardActions,{staticClass:"pa-3"},[_c(VSpacer),(!_vm.loading)?_c(VBtn,{attrs:{"color":"success"},on:{"click":function($event){_vm.openComputed = false}}},[_vm._v("OK")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }