<style scoped>
#supplier-id-details {
    border: #cc0b0f solid;
    border-radius: 13px;
    padding: 3px;
    background-color: whitesmoke;
}

#supplier-id-details:hover {
    cursor: pointer;
}

.clear-filters-link {
    color: #1976d2;
    cursor: pointer;
}

</style>

<template>
    <v-container fluid>
        <template v-if="loaded == true">
            <v-row class="content-header">
                <v-col>
                    <h6 class="title text-uppercase" style="min-height: 32px;">Fornecedor - <span
                            @click="dialogSelectSupplier = true" id="supplier-id-details">{{ supplier }}</span></h6>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-row class="mb-0">
                        <!--          <v-col class="text-center"><h4>EXCEL</h4></v-col>-->
                        <v-col class="text-center">
                            <v-btn x-small tile outlined color="primary" class="mr-2"
                                   @click="collapseAll(true)">
                                <v-icon x-small>unfold_less</v-icon>
                            </v-btn>
                            <v-btn x-small tile outlined color="primary" class="mr-2" @click="collapseAll(false)">
                                <v-icon x-small>unfold_more</v-icon>
                            </v-btn>
                        </v-col>
                        <!--          <v-col class="text-center"><h4>PRIMAVERA</h4></v-col>-->
                    </v-row>
                </v-col>
            </v-row>

            <!--TODO Todo just so it stays green and gets your attention, Pending orders here -->
            <v-row>
                <v-col cols="5" class="text-right" style="align-self: center;">
                    <h5>Pendentes ({{ pendingOrders.length }} linhas)</h5>
                </v-col>
                <v-col cols="5" class="text-left">
                    <v-btn v-if="pendingOrders.length > 0" x-small tile color="primary" class="mr-2"
                           @click="exportOrders(true)">
                        EXPORTAR
                    </v-btn>
                </v-col>
                <v-col cols="1">
                    <small class="clear-filters-link" @click="resetFilters()">Limpar filtros</small>
                </v-col>
                <v-col cols="1">
                    <v-btn icon small class="ml-2" color="grey"
                           @click="collapsePending(true)">
                        <v-icon>unfold_less</v-icon>
                    </v-btn>
                    <v-btn icon small class="ml-2" color="grey"
                           @click="collapsePending(false)">
                        <v-icon>unfold_more</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <hr>
            <br>
            <v-row>
                <v-col>
                    <div class="staff_v-card mb-3" v-if="pendingOrders.length == 0" v-show="pendingCollapse == false">
                        Este fornecedor não tem encomendas pendentes
                    </div>
                    <div v-else class="staff_v-card mb-3" v-show="pendingCollapse == false">
                        <v-simple-table class="staff_table mt-2">
                            <thead>
                            <tr>
                                <th width="50">Order</th>
                                <th width="100">Num.Doc</th>
                                <th width="100">Part</th>
                                <th width="100">Ref.Fornecedor</th>
                                <th width="100">EAN</th><!--<th width="100">EAN</th>-->
                                <th>Description</th>
                                <!--                            <th width="10">N.Linha</th>-->
                                <th width="100" class="text-center">Quantidade</th>
                                <th width="80" class="text-center">Price</th>
                                <th width="90" class="text-center">Date</th>

                            </tr>
                            <tr class="filters_row">
                                <td>
                                    <v-text-field
                                            :value="filtersPending.CDU_OrderNumber.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'CDU_OrderNumber')"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filtersPending.NumDoc.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'NumDoc')"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filtersPending.Artigo.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'Artigo')"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filtersPending.CDU_REFFORNECEDOR.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'CDU_REFFORNECEDOR')"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filtersPending.CDU_EAN.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'CDU_EAN')"
                                    ></v-text-field>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <v-text-field
                                            :value="filtersPending.DataEntrega.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'DataEntrega')"
                                    ></v-text-field>
                                </td>
                            </tr>
                            </thead>
                            <tbody>

                            <tr v-for="(order, orderIndex) in pendingOrders" v-if="pendingOrders.length > 0">
                                <td class="tdOrderNumber">{{ order.CDU_OrderNumber }}</td>
                                <td class="tdOrderNumber">{{ order.TipoDoc + ' ' + order.NumDoc}}</td>
                                <td>{{ order.Artigo }}</td>
                                <td>{{ order.CDU_REFFORNECEDOR }}</td>
                                <td>{{ order.CDU_EAN }}</td>
                                <td class="description">{{ order.Descricao }}</td>
                                <td class="description text-center">{{ order.Quantidade }}</td>
                                <td class="text-center">{{ $dollar(order.PrecUnit) }}</td>
                                <td class="text-center">{{ order.DataEntrega }}</td>

                            </tr>
                            </tbody>
                        </v-simple-table>
                    </div>

                </v-col>
            </v-row>

            <!--TODO Todo just so it stays green and gets your attention, In transit orders here -->
            <v-row>
                <v-col cols="5" class="text-right" style="align-self: center;">
                    <h5>Em transito ({{ transitOrders.length }} linhas)</h5>
                </v-col>
                <v-col cols="5" class="text-left">

                    <v-btn v-if="transitOrders.length > 0" x-small tile color="primary"
                           @click="exportOrders(false)">
                        EXPORTAR
                    </v-btn>
                </v-col>
                <v-col cols="1">
                    <small class="clear-filters-link" @click="resetFilters(false)">Limpar filtros</small>
                </v-col>
                <v-col cols="1">
                    <v-btn icon small class="ml-2" color="grey"
                           @click="collapseTransit(true)">
                        <v-icon>unfold_less</v-icon>
                    </v-btn>
                    <v-btn icon small class="ml-2" color="grey"
                           @click="collapseTransit(false)">
                        <v-icon>unfold_more</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <hr>
            <br>
            <v-row>
                <v-col>
                    <div class="staff_v-card mb-3" v-if="transitOrders.length == 0" v-show="transitCollapse == false">
                        Este fornecedor não tem encomendas em transito
                    </div>
                    <div class="staff_v-card mb-3" v-else v-show="transitCollapse == false">
                        <v-simple-table class="staff_table mt-2">
                            <thead>
                            <tr>
                                <th width="50">Order</th>
                                <th width="50">Delivery</th>
                                <th width="100">Num.Doc</th>
                                <th width="100">Part</th>
                                <th width="100">Ref.Fornecedor</th>
                                <th width="100">EAN</th>
                                <th>Description</th>
                                <!--                            <th width="10">N.Linha</th>-->
                                <th width="100" class="text-center">Quantidade</th>
                                <th width="80" class="text-right">Price</th>
                                <th width="90" class="text-center">Date</th>

                            </tr>
                            <tr class="filters_row">
                                <td>
                                    <v-text-field
                                            :value="filtersTransit.CDU_OrderNumber.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'CDU_OrderNumber', false)"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filtersTransit.CDU_DeliveryNumber.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'CDU_DeliveryNumber', false)"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filtersTransit.NumDoc.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'NumDoc', false)"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filtersTransit.Artigo.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'Artigo', false)"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filtersTransit.CDU_REFFORNECEDOR.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'CDU_REFFORNECEDOR', false)"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            :value="filtersTransit.CDU_EAN.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'CDU_EAN', false)"
                                    ></v-text-field>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <v-text-field
                                            :value="filtersTransit.DataEntrega.value"
                                            dense
                                            outlined
                                            hide-details
                                            v-on:keyup.enter="updateValue($event, 'DataEntrega', false)"
                                    ></v-text-field>
                                </td>
                            </tr>
                            </thead>
                            <tbody>

                            <tr v-for="(order, orderIndex) in transitOrders" v-if="transitOrders.length > 0">
                                <td class="tdOrderNumber">{{ order.CDU_OrderNumber }}</td>
                                <td>{{ order.CDU_DeliveryNumber }}</td>
                                <td>{{ order.TipoDoc + ' ' + order.NumDoc }}</td>
                                <td>{{ order.Artigo }}</td>
                                <td>{{ order.CDU_REFFORNECEDOR }}</td>
                                <td>{{ order.CDU_EAN }}</td>
                                <td class="description">{{ order.Descricao }}</td>
                                <td class="description text-center">{{ order.Quantidade }}</td>
                                <td class="text-right">{{ $dollar(order.PrecUnit) }}</td>
                                <td class="text-center">{{ order.DataEntrega }}</td>

                            </tr>
                            </tbody>
                        </v-simple-table>
                    </div>

                </v-col>
            </v-row>
        </template>

        <v-dialog v-model="dialogSelectSupplier" persistent max-width="500">
            <v-card>
                <v-card-title>Selecione fornecedor</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <h3> Qual o fornecedor que deseja consultar</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="7">
                                <v-autocomplete
                                        dense
                                        v-model="supplier"
                                        :items="suppliers"
                                        item-value="VALUE"
                                        item-text="TEXT"
                                        :label="$t('supplier')"
                                        outlined
                                        hide-details
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn tile small @click="dialogSelectSupplier = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn tile small color="success" :disabled="supplier == null" @click="selectSupplier()">Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>

</template>

<script>

export default {
    data: () => ({
        id: 0,
        loaded: false,
        pendingCollapse: false,
        transitCollapse: false,
        supplier: null,
        suppliers: [],
        showPending: false,
        pendingOrders: [],
        originalPendingOrders: [],
        transitOrders: [],
        originalTransitOrders: [],
        dialogSelectSupplier: true,
        filtersPending: {
            Artigo: {value: null, exact: false},
            CDU_OrderNumber: {value: null, exact: false},
            CDU_REFFORNECEDOR: {value: null, exact: false},
            CDU_EAN: {value: null, exact: false},
            DataEntrega: {value: null, exact: false},
            NumDoc: { value: null, exact: false}
        },
        filtersTransit: {
            Artigo: {value: null, exact: false},
            CDU_OrderNumber: {value: null, exact: false},
            CDU_REFFORNECEDOR: {value: null, exact: false},
            CDU_EAN: {value: null, exact: false},
            DataEntrega: {value: null, exact: false},
            CDU_DeliveryNumber: {value: null, exact: false},
            NumDoc: { value: null, exact: false }
        },
        /* associatedOrder: {}*/
    }),
    private: {},
    components: {},
    created() {
        document.title = 'Open Order Detail';
        this.getSuppliers();
        //this.getOpenOrderDetail();
    },
    methods: {
        filterValuesPending() {
            let requestedFilters = [];
            Object.entries(this.filtersPending).forEach(([filter, value]) => {
                if (value.value != null) {
                    requestedFilters[requestedFilters.length] = filter
                }
            });

            if (requestedFilters.length == 0) {
                this.pendingOrders = this.originalPendingOrders;
            } else {
                requestedFilters.forEach((filter, index) => {
                    if (index == 0) {
                        this.pendingOrders = this.originalPendingOrders.filter((order) => this.filtersPending[filter].exact ? (typeof this.filtersPending[filter].bigger !== undefined && this.filtersPending[filter].value !== 0 ? order[filter] >= this.filtersPending[filter].bigger : order[filter] == this.filtersPending[filter].value) : order[filter].toUpperCase().includes(this.filtersPending[filter].value.toUpperCase()));
                    } else {
                        this.pendingOrders = this.pendingOrders.filter((order) => this.filtersPending[filter].exact ? (typeof this.filtersPending[filter].bigger !== undefined && this.filtersPending[filter].value !== 0 ? order[filter] >= this.filtersPending[filter].bigger : order[filter] == this.filtersPending[filter].value) : order[filter].toUpperCase().includes(this.filtersPending[filter].value.toUpperCase()));
                    }
                });
            }
        },
        filterValuesTransit() {
            let requestedFilters = [];
            Object.entries(this.filtersTransit).forEach(([filter, value]) => {
                if (value.value != null) {
                    requestedFilters[requestedFilters.length] = filter
                }
            });


            if (requestedFilters.length == 0) {
                this.transitOrders = this.originalTransitOrders;
            } else {
                requestedFilters.forEach((filter, index) => {
                    if (index == 0) {
                        this.transitOrders = this.originalTransitOrders.filter((order) => this.filtersTransit[filter].exact ? (typeof this.filtersTransit[filter].bigger !== undefined && this.filtersTransit[filter].value !== 0 ? order[filter] >= this.filtersTransit[filter].bigger : order[filter] == this.filtersTransit[filter].value) : order[filter].toUpperCase().includes(this.filtersTransit[filter].value.toUpperCase()));
                    } else {
                        this.transitOrders = this.transitOrders.filter((order) => this.filtersTransit[filter].exact ? (typeof this.filtersTransit[filter].bigger !== undefined && this.filtersTransit[filter].value !== 0 ? order[filter] >= this.filtersTransit[filter].bigger : order[filter] == this.filtersTransit[filter].value) : order[filter].toUpperCase().includes(this.filtersTransit[filter].value.toUpperCase()));
                    }
                });
            }
        },
        processState(order) {
            if (order.doc_number == null)
                return 'Por criar';
            else if (order.doc_number == 'processing')
                return 'A processar';
            else if (typeof order.doc_number === 'object')
                return order.doc_number.TipoDoc + ' ' + order.doc_number.Serie + '/' + order.doc_number.NumDoc;
            else if (order.doc_number == 'error')
                return 'Erro';
        },
        getOpenOrderDetail() {
            let loader = this.$loading.show();
            let request = {url: '/api/staff/purchases/suppliers/' + this.supplier}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pendingOrders = responseData.pendingOrders;
                            this.originalPendingOrders = responseData.pendingOrders;

                            this.transitOrders = responseData.transitOrders;
                            this.originalTransitOrders = responseData.transitOrders;
                            //this.orders = Object.values(responseData.orders);
                            this.dialogSelectSupplier = false;
                            this.loaded = true;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        getSuppliers() {
            let loader = this.$loading.show();
            let request = {url: '/api/staff/purchases/getSuppliers'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.suppliers = responseData.suppliers;
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Impossivel carregar fornecedores!',
                                type: 'error'
                            });
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        selectSupplier() {
            this.getOpenOrderDetail();
            //this.dialogSelectSupplier = false;
        },
        exportOrders(pending) {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/purchases/suppliers/export',
                data: {
                    supplier: this.supplier,
                    pending: pending,
                }
            };
            ApiWS.dispatch('makePostDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'pendingorders.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        loader.hide();
                    }).catch(() => {
                loader.hide();
            })
            /*ApiWS.dispatch('makePostDownload', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            console.log(responseData);
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })*/
        },
        collapsePending(collapse) {
            this.pendingCollapse = collapse;
        },
        collapseTransit(collapse) {
            this.transitCollapse = collapse;
        },
        collapseAll(collapse) {
            this.pendingCollapse = collapse;
            this.transitCollapse = collapse;
        },
        resetFilters(isPending = true) {
            if (isPending) {
                this.filtersPending = {
                    Artigo: {value: null, exact: false},
                    CDU_OrderNumber: {value: null, exact: false},
                    CDU_REFFORNECEDOR: {value: null, exact: false},
                    CDU_EAN: {value: null, exact: false},
                    DataEntrega: {value: null, exact: false},
                    CDU_DeliveryNumber: {value: null, exact: false},
                    NumDoc: { value: null, exact: false }
                };
                this.filterValuesPending();
            } else {
                this.filtersTransit = {
                    Artigo: {value: null, exact: false},
                    CDU_OrderNumber: {value: null, exact: false},
                    CDU_REFFORNECEDOR: {value: null, exact: false},
                    CDU_EAN: {value: null, exact: false},
                    DataEntrega: {value: null, exact: false},
                    CDU_DeliveryNumber: {value: null, exact: false},
                    NumDoc: { value: null, exact: false }
                };
                this.filterValuesTransit();
            }
        },
        updateValue(event, target, isPending = true){
            if(isPending) {
                this.filtersPending[target].value = event.target.value;
                this.filterValuesPending();
            }else {
                this.filtersTransit[target].value = event.target.value;
                this.filterValuesTransit();
            }
        }
    }
}
</script>
