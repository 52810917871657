import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"700"},on:{"click:outside":_vm.closeDialogParent},model:{value:(_vm.loaded),callback:function ($$v) {_vm.loaded=$$v},expression:"loaded"}},[_c(VCard,[_c(VCardTitle,[_c('span',[_vm._v("Produtos detetados com informações duplicadas")])]),_c(VBtn,{attrs:{"color":"grey lighten-3","dark":"","fab":"","top":"","absolute":"","right":""},on:{"click":_vm.closeDialogParent}},[_c('span',{staticClass:"buttonClose"},[_vm._v("x")])]),_c(VCardText,[_c(VContainer,[_c(VRow,{directives:[{name:"show",rawName:"v-show",value:(_vm.duplicatedSyncMessage!=''),expression:"duplicatedSyncMessage!=''"}],staticClass:"staff_v-card mb-3",staticStyle:{"color":"red","background-color":"#ffc06342","font-weight":"bold"}},[_c('span',[_vm._v(_vm._s(_vm.duplicatedSyncMessage))]),_c('small',[_vm._v(_vm._s(_vm.duplicatedSyncSmallMessage))])]),_vm._l((_vm.duplicatedProducts),function(product,productIndex){return _c(VRow,{key:product.productId,staticClass:"staff_v-card mb-3",staticStyle:{"background-color":"transparent"}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c('small',[_vm._v("Produto")]),_c('br'),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(product.productId + ' - ' + product.brandId + ' - ' + product.name)+" ")]),_c('hr')]),_c(VCol,{attrs:{"cols":"2"}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":[function($event){},function($event){_vm.dialogConfirm = true, _vm.productToCancel = product}]}},[_c('i',{staticClass:"fas fa-times-circle buttonSaveInfo",staticStyle:{"align-self":"center","font-size":"xx-large","color":"red"}}),_c('br'),_c('small',[_vm._v("anular")])])])],1),_c(VRow,[_c(VCol,[_c(VTextField,{class:{isDuplicate:
                                        (product.evaluator == 1 ||
                                          product.evaluator == 4 ||
                                          product.evaluator == 6 ||
                                          product.evaluator == 9)
                            },attrs:{"prepend-icon":(product.evaluator == 1 ||
                                          product.evaluator == 4 ||
                                          product.evaluator == 6 ||
                                          product.evaluator == 9) ? 'mdi-wrench' : undefined,"hint":"Insira o EAN do produto","label":"EAN"},model:{value:(product.ean),callback:function ($$v) {_vm.$set(product, "ean", $$v)},expression:"product.ean"}})],1),_c(VCol,[_c(VTextField,{class:{isDuplicate:
                                        (product.evaluator == 3 ||
                                          product.evaluator == 4 ||
                                          product.evaluator == 8 ||
                                          product.evaluator == 9)
                            },attrs:{"prepend-icon":(product.evaluator == 3 ||
                                          product.evaluator == 4 ||
                                          product.evaluator == 8 ||
                                          product.evaluator == 9) ? 'mdi-wrench' : undefined,"hint":"Insira o código de barras do produto","label":"Código de Barras"},model:{value:(product.barcode),callback:function ($$v) {_vm.$set(product, "barcode", $$v)},expression:"product.barcode"}})],1),_c(VCol,[_c(VTextField,{class:{isDuplicate:
                                        (product.evaluator == 5 ||
                                          product.evaluator == 6 ||
                                          product.evaluator == 8 ||
                                          product.evaluator == 9)
                            },attrs:{"prepend-icon":(product.evaluator == 5 ||
                                          product.evaluator == 6 ||
                                          product.evaluator == 8 ||
                                          product.evaluator == 9) ? 'mdi-wrench' : undefined,"hint":"Insira a referência de fornecedor do produto","label":"Ref. Fornecedor"},model:{value:(product.supplierCode),callback:function ($$v) {_vm.$set(product, "supplierCode", $$v)},expression:"product.supplierCode"}})],1),_c(VCol,{staticStyle:{"align-self":"center","font-size":"xx-large"},attrs:{"cols":"1"}},[(product.ean != _vm.duplicatedProductsOriginal[productIndex].ean || product.barcode != _vm.duplicatedProductsOriginal[productIndex].barcode || product.supplierCode != _vm.duplicatedProductsOriginal[productIndex].supplierCode)?_c('i',{staticClass:"fas fa-check-circle buttonSaveInfo",staticStyle:{"color":"green"},on:{"click":function($event){return _vm.saveProductInfo(product, _vm.duplicatedProductsOriginal[productIndex], _vm.duplicatedProducts[0])}}}):_vm._e()])],1)],1)],1)})],2)],1),_c(VCardActions,[_c(VBtn,{attrs:{"tile":"","small":""},on:{"click":_vm.closeDialogParent}},[_vm._v("Fechar")])],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.dialogConfirm),callback:function ($$v) {_vm.dialogConfirm=$$v},expression:"dialogConfirm"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" "+_vm._s(_vm.$t('product'))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('confirmCancelProduct'))+" "),_c('hr'),_c('br'),_c('span',[_vm._v(" "+_vm._s(_vm.productToCancel.productId + ' - ' + _vm.productToCancel.brandId + ' - ' + _vm.productToCancel.name)+"?")])]),_c(VCardActions,[_c(VBtn,{on:{"click":function($event){return _vm.closeDialogConfirm()}}},[_vm._v("Cancelar")]),_c(VBtn,{staticStyle:{"margin-left":"auto"},attrs:{"color":"success"},on:{"click":function($event){return _vm.cancelProduct()}}},[_vm._v("Confirmar")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }