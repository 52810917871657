<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Histórico Campanhas"
                ></page-title>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nome</th>
                            <th>Cliente</th>
                            <th>Nivel</th>
                            <th>Tipo</th>
                            <th>Criado</th>
                            <th>Atualizado</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr v-for="his in history">
                                <td>{{ his.campaignId }}</td>
                                <td>{{ his.campaignName }}</td>
                                <td>{{ his.clientId }} - {{ his.clientName }}</td>
                                <td>{{ his.discountLevel }}</td>
                                <td>{{ his.notes }}</td>
                                <td>{{ his.created_at }}</td>
                                <td>{{ his.updated_at }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            history: [],
        }),
        created() {
            this.getHistory();
        },
        methods: {
            getHistory() {
                let loader = this.$loading.show();
                let request = {url: api.b2bCampaigns() + '/history'};
                ApiWS.dispatch('makeGet', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.history = responseData.history;
                            }
                            this.loading = false;
                            loader.hide();
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide();
                        })
            },
        }
    }
</script>
