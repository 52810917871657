<template>
    <v-container fluid>

        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="$t('users')"
                        :subtitle="$t('show') + ' ' + users.length + ' ' + $t('users')"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-dialog v-model="dialog" max-width="600px">
                    <template v-slot:activator="{ on }">
                        <v-btn tile small  color="info" class="ml-2 mb-2" v-on="on">Adicionar</v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-form
                                    ref="form"
                            >
                                <v-container grid-list-md>
                                    <v-layout wrap>
                                        <v-flex xs12 sm6 md2>
                                            <v-text-field v-model="editedItem.cod" label="Código" :rules="[rules.required]" name="cod" @change="changeCodUser" :readonly="editedIndex > -1"></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 sm6 md4>
                                            <v-text-field v-model="editedItem.name" label="Nome" :rules="[rules.required]" name="name"></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 sm6 md6>
                                            <v-text-field v-model="editedItem.email" label="Email" :rules="[rules.required, rules.email]" name="email"></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 sm6 md4>
                                            <v-text-field v-model="editedItem.password" label="Password" :rules="[rules.password]" name="password" @click:append="show1 = !show1" :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'text' : 'password'" prepend-icon="lock"></v-text-field>
                                        </v-flex>
                                        <v-flex xs12 sm6 md4>
                                            <v-select
                                                    v-model="editedItem.B2BAccessCountry"
                                                    :items="accessCountry"
                                                    :label="$t('userAccessCountry')"
                                                    :rules="[rules.required]"
                                                    name="B2BAccessCountry"
                                            >
                                            </v-select>
                                        </v-flex>
                                        <v-flex xs12 sm6 md4>
                                            <v-select
                                                    v-model="editedItem.B2BAccessSeller"
                                                    :items="accessSeller"
                                                    :label="$t('userAccessSeller')"
                                                    :rules="[rules.required]"
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12 sm6 md4>
                                            <v-select
                                                    v-model="editedItem.profile_id"
                                                    :items="profiles"
                                                    item-text="profile"
                                                    item-value="id"
                                                    label="Perfil"
                                                    :rules="[rules.required]"
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12 sm6 md4>
                                            <v-select
                                                    v-model="editedItem.language"
                                                    :items="language"
                                                    label="Idioma"
                                                    :rules="[rules.required]"
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12 sm6 md4>
                                            <v-select
                                                    v-model="editedItem.active_id"
                                                    :items="active"
                                                    item-value="value"
                                                    label="Activo"
                                                    :rules="[rules.required]"
                                            ></v-select>
                                        </v-flex>
                                        <v-flex xs12 sm6 md4>
                                            <v-text-field v-model="editedItem.phone" label="Phone" name="phone"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-form>
                        </v-card-text>

                        <v-card-actions class="pa-3">
                            <v-btn tile small  @click.prevent="close">Cancelar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn tile small  color="success" @click.prevent="save">Guardar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Idioma</th>
                            <th>Acesso Pais</th>
                            <th>Acesso Vendedor</th>
                            <th>Activo</th>
                            <th>Perfil</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in users"
                            style="border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;"
                        >
                            <td>{{ item.id }}</td>
                            <td>{{ item.cod }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.email }}</td>
                            <td>{{ item.language }}</td>
                            <td>{{ item.B2BAccessCountry }}</td>
                            <td>{{ item.B2BAccessSeller }}</td>
                            <td>{{ item.active }}</td>
                            <td>{{ item.profile }}</td>
                            <td>
                                <v-btn x-small tile outlined color="info" @click="editItem(item)">
                                    <i class="fas fa-pencil-alt"></i>
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data: () => ({
            show1: false,
            dialog: false,
            headers: [],
            users: [],
            profiles: [],
            language: [
                'PT', 'ES'
            ],
            accessCountry: [
                'ALL', 'PT', 'ES'
            ],
            accessSeller: [
                'ALL', 'PT', 'ES'
            ],
            active: [
                {text: i18n.t('no'), value: 0},
                {text: i18n.t('yes'), value: 1}
            ],
            editedIndex: -1,
            editedItem: {
                cod: '',
                name: '',
                email: '',
                B2BAccessCountry: '',
                B2BAccessSeller: '',
                active: '',
                active_id: '',
                profile_id: '',
                password: '',
                language: '',
                phone: ''
            },
            defaultItem: {
                cod: '',
                name: '',
                email: '',
                B2BAccessCountry: '',
                B2BAccessSeller: '',
                active: '',
                active_id: '',
                profile_id: '',
                password: '',
                language: '',
                phone: ''
            },
            pagination: {
                rowsPerPage: 25
            },
            rules: {
                required: value => !!value || value === 0 || i18n.t('required'),
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(value) || 'E-mail inválido'
                },
                password: value => value.length >= 6 || (value === '') || 'Mínimo 6 caracteres'
            }
        }),
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? i18n.t('addItem') : 'Editar'
            }
        },
        watch: {
            dialog(val) {
                val || this.close()
            }
        },
        created() {
            document.title = i18n.t('users')
            this.initialize()
        },
        methods: {
            initialize() {
                User.dispatch('getUsers')
                    .then(response => {
                        this.users = response.data
                        this.headers = response.headers
                        this.profiles = response.profiles
                    })
                    .catch(response => {
                        // console.log('response error', response)
                    })
            },
            editItem(item) {
                this.accessSeller.push(item.cod)
                this.editedIndex = this.users.indexOf(item)
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },
            deleteItem(item) {
                const index = this.users.indexOf(item)
                confirm('Are you sure you want to delete this item?') && this.users.splice(index, 1)
            },
            close() {
                this.accessSeller = [
                    'ALL', 'PT', 'ES'
                ]
                this.dialog = false
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                    this.$refs.form.resetValidation()
                }, 300)
            },
            save() {
                if (this.$refs.form.validate()) {
                    this.accessSeller = [
                        'ALL', 'PT', 'ES'
                    ]
                    if (this.editedIndex > -1) {
                        User.dispatch('updateUser', this.editedItem)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    let alert = {
                                        snackbar: true,
                                        text: 'Utilizador alterado com sucesso',
                                        type: 'success'
                                    }

                                    Alert.dispatch('show', alert)

                                    this.close()

                                    this.initialize()
                                } else {
                                    let alert = {
                                        snackbar: true,
                                        text: responseData.data,
                                        type: 'error'
                                    }

                                    Alert.dispatch('show', alert)
                                }
                            })
                            .catch(response => {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao alterar utilizador',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            })
                        // Object.assign(this.users[this.editedIndex], this.editedItem)
                    } else {
                        // this.users.push(this.editedItem)
                        User.dispatch('saveUser', this.editedItem)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    let alert = {
                                        snackbar: true,
                                        text: 'Utilizador adicionado com sucesso',
                                        type: 'success'
                                    }

                                    Alert.dispatch('show', alert)

                                    this.close()

                                    this.initialize()
                                } else {
                                    let alert = {
                                        snackbar: true,
                                        text: responseData.data,
                                        type: 'error'
                                    }

                                    Alert.dispatch('show', alert)
                                }
                            })
                            .catch(response => {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao adicionar utilizador',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            })
                    }
                    // this.close()
                }
            },
            changeCodUser() {
                this.accessSeller = [
                    'ALL', 'PT', 'ES'
                ]
                this.accessSeller.push(this.editedItem.cod)
            }
        }
    }
</script>
