<template>
    <div>
        <v-card tile min-height="300">
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th>Imagem</th>
                                <th>{{ $t('product') }}</th>
                                <th>Ref. {{ $t('supplier') }}</th>
                                <th>CodBarras PRI</th>
                                <th>EAN-13</th>
                                <th>Stock</th>
                                <th>{{ $t('boxQty') }}</th>
                                <th>RubDim1</th>
                                <th>RubDim2</th>
                                <th>RubDim3</th>
                                <th class="text-center">PVP1</th>
                                <th class="text-center">PVP2</th>
                                <th class="text-center">PVP PT</th>
                                <th class="text-center">PVP ES</th>
                                <th>Sync</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="dimension in dimensions">
                                <td>
                                    <v-img
                                            v-if="dimension.File"
                                            :src="'https://static.bicimax.com/thumbs/'+dimension.File"
                                            style="border: 1px darkgray solid; cursor: pointer;"
                                            width="64"
                                            contain
                                            @click="openDialogUploadImage(dimension.Artigo)"
                                    ></v-img>
                                    <v-img
                                            v-if="!dimension.File"
                                            :src="require('../../../assets/img/picture-add.jpg')"
                                            contain
                                            width="64"
                                            style="border: 1px darkgray solid; cursor: pointer;"
                                            @click="openDialogUploadImage(dimension.Artigo)"
                                    ></v-img>
                                </td>
                                <td>
                                    {{ dimension.Artigo }}
                                    <br>
                                    <v-btn icon small
                                           @click="printLabel(dimension.Artigo, 'product')">
                                        <v-icon small>print</v-icon>
                                    </v-btn>
                                    <v-btn icon small
                                           @click="printLabel(dimension.Artigo, 'product', 'big')">
                                        <v-icon medium>print</v-icon>
                                    </v-btn>
                                    <v-btn small outlined color="primary" class="ml-1" @click="showBoxes(dimension)">
                                        <small>Caixas</small>
                                    </v-btn>
                                    <br>
                                    {{ dimension.Descricao }}
                                </td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary"
                                           @click="toggleDialogChange('CDU_REFFORNECEDOR', dimension.CDU_REFFORNECEDOR, dimension.Artigo)"
                                           :disabled="!$writePermission('products/product_basicSettings')">
                                        {{ dimension.CDU_REFFORNECEDOR }}
                                    </v-btn>
                                    <br>
                                    <v-btn icon small
                                           @click="printLabel(dimension.Artigo, 'refSupplier')">
                                        <v-icon small>print</v-icon>
                                    </v-btn>
                                    <v-btn icon small
                                           @click="printLabel(dimension.Artigo, 'refSupplier', 'big')">
                                        <v-icon medium>print</v-icon>
                                    </v-btn>
                                </td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary"
                                           @click="toggleDialogChange('CodBarras', dimension.CodBarras, dimension.Artigo)"
                                           :disabled="!$writePermission('products/product_basicSettings')">
                                        {{ dimension.CodBarras }}
                                    </v-btn>
                                    <br>
                                    <v-btn icon small
                                           @click="printLabel(dimension.Artigo, 'barcode')">
                                        <v-icon small>print</v-icon>
                                    </v-btn>
                                    <v-btn icon small
                                           @click="printLabel(dimension.Artigo, 'barcode', 'big')">
                                        <v-icon medium>print</v-icon>
                                    </v-btn>
                                </td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary"
                                           @click="toggleDialogChange('CDU_EAN', dimension.CDU_EAN, dimension.Artigo)"
                                           :disabled="!$writePermission('products/product_basicSettings')">
                                        {{ dimension.CDU_EAN }}
                                    </v-btn>
                                    <br>
                                    <v-btn icon small
                                           @click="printLabel(dimension.Artigo, 'ean')">
                                        <v-icon small>print</v-icon>
                                    </v-btn>
                                    <v-btn icon small
                                           @click="printLabel(dimension.Artigo, 'ean', 'big')">
                                        <v-icon medium>print</v-icon>
                                    </v-btn>
                                </td>
                                <td class="text-center">{{ $integer(dimension.StkActual) }}</td>
                                <td>
                                    <v-btn small outlined color="primary"
                                           @click="toggleDialogChange('QtdEconomica', dimension.QtdEconomica, dimension.Artigo)"
                                           :disabled="!$writePermission('products/product_changePVP')">
                                        {{ dimension.QtdEconomica }}
                                    </v-btn>
                                </td>
                                <td class="text-center">{{ dimension.RubDim1 }}</td>
                                <td class="text-center">{{ dimension.RubDim2 }}</td>
                                <td class="text-center">{{ dimension.RubDim3 }}</td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary"
                                           @click="toggleDialogChange('PVP1', dimension.PVP1, dimension.Artigo)"
                                           :disabled="!$writePermission('products/product_changePVP')">
                                        {{ $money(dimension.PVP1) }}
                                    </v-btn>
                                </td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary"
                                           @click="toggleDialogChange('PVP2', dimension.PVP2, dimension.Artigo)"
                                           :disabled="!$writePermission('products/product_changePVP')">
                                        {{ $money(dimension.PVP2) }}
                                    </v-btn>
                                </td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary"
                                           @click="toggleDialogChange('CDU_PVP_PT', dimension.CDU_PVP_PT, dimension.Artigo)"
                                           :disabled="!$writePermission('products/product_changePVP')">
                                        {{ $money(dimension.CDU_PVP_PT) }}
                                    </v-btn>
                                </td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary"
                                           @click="toggleDialogChange('CDU_PVP_ES', dimension.CDU_PVP_ES, dimension.Artigo)"
                                           :disabled="!$writePermission('products/product_changePVP')">
                                        {{ $money(dimension.CDU_PVP_ES) }}
                                    </v-btn>
                                </td>
                                <td>
                                    <span v-if="$writePermission('products/product_sync')">
                                        <v-btn tile small :color="dimension.CDU_B2B_SYNC === 1 ? 'success' : 'error'"
                                               @click="updateVisibility(dimension.Artigo, 'B2B', dimension.CDU_B2B_SYNC)">
                                            B2B
                                        </v-btn>
                                        <v-btn tile small :color="dimension.CDU_SHOP_SYNC === 1 ? 'success' : 'error'"
                                               @click="updateVisibility(dimension.Artigo, 'STORE', dimension.CDU_SHOP_SYNC)">
                                            STORE
                                        </v-btn>
                                        <v-btn tile small :color="dimension.CDU_FOX_SYNC === 1 ? 'success' : 'error'"
                                               @click="updateVisibility(dimension.Artigo, 'FOX', dimension.CDU_FOX_SYNC)">
                                            FOX
                                        </v-btn>
                                        <v-btn tile small :color="dimension.CDU_B2C_SYNC === 1 ? 'success' : 'error'"
                                               @click="updateVisibility(dimension.Artigo, 'B2C', dimension.CDU_B2C_SYNC)">
                                            B2C
                                        </v-btn>
                                        <v-btn tile small v-if="$writePermission('products/products_nozama')"
                                               :color="dimension.CDU_NOZAMA_SYNC === 1 ? 'success' : 'error'"
                                               @click="updateVisibility(dimension.Artigo, 'NOZAMA', dimension.CDU_NOZAMA_SYNC)">
                                            NOZAMA
                                        </v-btn>
                                        <v-btn tile small
                                               :color="dimension.CDU_BIKEPLANET_SYNC === 1 ? 'success' : 'error'"
                                               @click="updateVisibility(dimension.Artigo, 'BIKEPLANET', dimension.CDU_BIKEPLANET_SYNC)">
                                            BIKEPLANET
                                        </v-btn>
                                    </span> <span v-if="!$writePermission('products/product_sync')">
                                        <v-chip label style="height: 25px" class="mr-1"
                                                :color="dimension.CDU_B2B_SYNC === 1 ? 'success' : 'error'">B2B</v-chip>
                                        <v-chip label style="height: 25px" class="mr-1"
                                                :color="dimension.CDU_SHOP_SYNC === 1 ? 'success' : 'error'">STORE</v-chip>
                                        <v-chip label style="height: 25px" class="mr-1"
                                                :color="dimension.CDU_FOX_SYNC === 1 ? 'success' : 'error'">FOX</v-chip>
                                        <v-chip label style="height: 25px"
                                                :color="dimension.CDU_B2C_SYNC === 1 ? 'success' : 'error'">B2C</v-chip>
                                        <v-chip label style="height: 25px"
                                                v-if="$readPermission('products/products_nozama')"
                                                :color="dimension.CDU_NOZAMA_SYNC === 1 ? 'success' : 'error'">NOZAMA</v-chip>
                                        <v-chip label style="height: 25px" class="ml-1"
                                                :color="dimension.CDU_BIKEPLANET_SYNC === 1 ? 'success' : 'error'">BIKEPLANET</v-chip>
                                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-row justify="center">

            <v-dialog v-model="dialogSelectImage" persistent max-width="500">
                <staff-dialog :title="'Inserir imagem '+productDimension">
                    <template slot="content">
                        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                    </template>
                    <template slot="action" class="text-right">
                        <v-btn color="error" @click="dialogSelectImage = false">{{ $t('close') }}</v-btn>
                    </template>
                </staff-dialog>
            </v-dialog>

            <v-dialog v-model="dialogChange" width="400">
                <v-card>
                    <v-toolbar dense dark color="info">
                        <v-toolbar-title class="text-uppercase">{{ $t('change') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogChange = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field ref="inputValue" :label="input.label" v-model="input.value" hide-details
                                              @keydown.enter="savePVP('one')"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn v-if="dimensions.length > 1" tile small color="primary" @click="savePVP('all')">Guardar
                            todos
                        </v-btn>
                        <v-btn tile small color="success" @click="savePVP('one')">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="showBoxesDialog" persistent max-width="984" @click:outside="showBoxesDialog=false">
                <ProductBoxQuantityForm v-if="showBoxesDialog" v-on:close-box-form="showBoxesDialog=false" :products="boxProduct"
                                        :singleProductMode="true"/>
            </v-dialog>

            <v-dialog v-model="dialogPrint" width="400">
                <v-card>
                    <v-toolbar dense dark color="info">
                        <v-toolbar-title class="text-uppercase">{{ $t('quantity') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogPrint = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row class="mt-4">
                            <v-col offset="4" cols="3">
                                <v-text-field
                                        v-model.number="labelQuantity"
                                        hide-details
                                        type="number"
                                        min="1"
                                        max="5000"
                                        label="Quantidade"
                                        @change="limitNumber()"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogPrint=false">
                            Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="primary" @click="printLabelCall()">
                            Imprimir
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import i18n from '../../../plugins/i18n'
import ProductBoxQuantityForm from '@/components/Products/AddBoxQuantity.vue';

export default {
    props: {
        productId: String,
    },
    components: {
        vueDropzone: vue2Dropzone,
        ProductBoxQuantityForm
    },
    data: function () {
        return {
            labelOption: null,
            labelProduct: null,
            labelSize: null,
            labelQuantity: 1,
            dialogPrint: false,
            boxProduct: [],
            showBoxesDialog: false,
            dimensions: [],
            dimensionSelect: [],
            dialogSelectImage: false,
            dialogChange: false,
            images: [],
            productDimension: '',
            dropzoneOptions: {
                url: AppModel.getters.getUrlAPI + '/api/staff/products/' + this.productDimension + '/images',
                thumbnailWidth: 150,
                maxFilesize: 16,
                acceptedFiles: "image/png,image/jpeg",
                headers: {"Authorization": "Bearer " + User.getters.getAccessToken},
                init: function () {
                    this.on("queuecomplete", function (file) {
                        location.reload()
                    })
                }
            },
            input: {
                label: '',
                value: '',
                product: '',
                field: ''
            }
        }
    },
    mounted() {
        this.getDimensions()
    },
    methods: {
        getDimensions() {
            let request = {
                url: api.products() + '/' + this.productId + '/dimensions'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dimensions = responseData.dimensions
                            this.images = responseData.images
                        }
                    })
                    .catch(response => {

                    })
        },
        updateVisibility(product, option, visibility) {
            let request = {
                url: api.productsChangeVisibility(),
                data: {
                    products: [
                        product
                    ],
                    visibility: {
                        'B2B': option === 'B2B',
                        'B2C': option === 'B2C',
                        'FOX': option === 'FOX',
                        'STORE': option === 'STORE',
                        'NOZAMA': option === 'NOZAMA',
                        'BIKEPLANET': option === 'BIKEPLANET'
                    },
                    option: visibility === 1 ? 'hide' : 'show'
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Visibilidade alterada com sucesso!',
                                type: 'success'
                            })
                            this.getDimensions()
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao alterar visibilidade!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro servidor!',
                            type: 'error'
                        })
                    })
        },
        openDialogUploadImage(dimensionId) {
            this.productDimension = dimensionId
            this.dropzoneOptions.url = AppModel.getters.getUrlAPI + '/api/staff/products/' + this.productDimension + '/images'
            this.dialogSelectImage = true
        },
        toggleDialogChange(option, value, product) {
            this.input.label = option;
            this.input.label = option === 'CDU_PVP_PT' ? 'PVP PT' : this.input.label;
            this.input.label = option === 'CDU_PVP_ES' ? 'PVP ES' : this.input.label;
            this.input.label = option === 'QtdEconomica' ? i18n.t('boxQty') : this.input.label;
            this.input.label = option === 'CDU_EAN' ? 'EAN-13' : this.input.label;
            this.input.label = option === 'CDU_REFFORNECEDOR' ? 'Ref. ' + i18n.t('supplier') : this.input.label;
            this.input.field = option;
            this.input.value = value;
            this.input.product = product;
            this.dialogChange = true;
        },
        savePVP(option) {
            let request = {
                url: api.productsUpdatePVPs(),
                data: {
                    input: this.input,
                    option: option
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.getDimensions()
                            this.dialogChange = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao atualizar PVP!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        printLabelCall() {
            let request = {
                url: '/api/staff/products/printLabel',
                data: {
                    product: this.labelProduct,
                    option: this.labelOption,
                    size: this.labelSize,
                    quantity: this.labelQuantity
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Etiqueta enviada para a impressora!',
                                type: 'success'
                            })
                            this.$nextTick(() => this.$refs.barcode.focus())
                            this.dialogPrint = false;
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro a imprimir etiqueta!',
                                type: 'error'
                            })
                            this.dialogPrint = false;
                        }

                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.dialogPrint = false;
                    })
            this.labelOption = null;
            this.labelProduct = null;
            this.labelSize = null;
        },
        printLabel(product, option, size = null) {
            this.labelOption = option;
            this.labelProduct = product;
            this.labelSize = size;
            this.dialogPrint = true;
        },
        showBoxes(dimension) {
            this.boxProduct = [dimension.Artigo];
            this.showBoxesDialog = true;
        },
        limitNumber() {
            if (this.labelQuantity < 1)
                this.labelQuantity = 1
            else if (this.labelQuantity > 5000)
                this.labelQuantity = 5000;
        }
    },
}
</script>
