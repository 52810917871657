<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title back :title="campaignName"></page-title>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col>
                <v-tabs v-model="activeTab">
                    <v-tab v-for="tab of tabs" :key="tab.index" @click="activeTab = tab.index">
                        {{ tab.name }}
                    </v-tab>
                </v-tabs>
                <CampaignDetails v-if="activeTab === 0" :campaign="campaign"></CampaignDetails>
                <CampaignLevels v-if="activeTab === 1" :campaign="campaign"></CampaignLevels>
                <CampaignClients v-if="activeTab === 2" :campaign="campaign"></CampaignClients>
                <CampaignProducts v-if="activeTab === 3" :campaign="campaign"></CampaignProducts>
                <CampaignHistory v-if="activeTab === 4" :campaign="campaign"></CampaignHistory>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import CampaignDetails from "./CampaignDetails";
import CampaignLevels from "./CampaignLevels";
import CampaignClients from "./CampaignClients";
import CampaignProducts from "./CampaignProducts";
import CampaignHistory from "./CampaignHistory";


export default {
    // components: {BasicInfo, Levels, Clients, Products, History},
    components: {CampaignClients, CampaignDetails,CampaignLevels, CampaignProducts, CampaignHistory},
    data: () => ({
        id: 0,
        language: '',
         campaignName: '',
        campaign: {},
        campaignOriginal: {},
        tabs: [
            {index: 0, name: 'DEFINIÇÕES BASE'},
            {index: 1, name: 'NÍVEIS'},
            {index: 2, name: 'CLIENTES'},
            {index: 3, name: 'ARTIGOS'},
            {index: 4, name: 'HISTÓRICO'},
        ],
        activeTab: 0,
        currentTab: 0,
        newTab: 0,
        modalChangeTab: false,
    }),
    created() {
    },
    mounted() {
        this.language = User.getters.getLanguage;
        this.campaignName = this.$route.query.name;

    },
    methods: {
      /*  getCampaign() {
            let request = {
                url: '/api/staff/campaigns/' + this.id
            };
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.campaign = responseData.campaign;
                            this.campaignOriginal = responseData.campaignOriginal;
                            this.campaign.subtitle = 'Configurações da campanha';
                            this.campaign.pristine = true;
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },*/
        changeToTab(current, next) {
            this.currentTab = current;
            this.newTab = next;
            if (!this.campaign.pristine) {
                this.modalChangeTab = true;
            } else {
                this.activeTab = next;
            }
        },
    }
}
</script>
