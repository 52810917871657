<template>
    <v-card flat>

        <v-row no-gutters>

            <!-- NÍVEIS -->
            <v-col md="3" class="ma-2">
                <v-card>
                    <v-toolbar color="primary" dense dark>
                        <v-toolbar-title class="subtitle-1">NÍVEIS</v-toolbar-title>
                    </v-toolbar>
                    <v-list-item v-if="loading">
                        <v-progress-linear height="2" indeterminate color="blue darken-4"></v-progress-linear>
                    </v-list-item>
                    <v-list class="pa-0" v-if="!loading" :disabled="addMode">
                        <v-list-item-group mandatory v-model="selected" active-class="blue--text">
                            <template v-for="level in levels">
                                <v-list-item :key="level.campaign_level_id" @click="selectLevel(level)">
                                    <template>
                                        <v-list-item-avatar>
                                            <span class="title font-weight-black">{{ level.level_order }}</span>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ level.level_name }}</v-list-item-title>
                                            <v-list-item-subtitle class="grey--text">
                                                <span v-if="level.basic">Nível Base - </span>
                                                <span v-if="!level.basic && level.type === 'min_products'">{{ level.min_products }} Artigos - </span>
                                                <span v-if="!level.basic && level.type === 'min_refs'">{{ level.min_refs }} Referências - </span>
                                                <span v-if="!level.basic && level.type === 'min_amount'">{{ level.min_amount }} € - </span> Desconto {{ level.discount_value }}%
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text v-if="level.status" class="green--text">Activo</v-list-item-action-text>
                                            <v-list-item-action-text v-else class="red--text">Inactivo</v-list-item-action-text>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                                <v-divider></v-divider>
                            </template>
                            <v-list-item v-if="!addMode" @click="addLevel">
                                <v-list-item-avatar>
                                    <v-icon class="green--text">add_circle</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>ADICIONAR</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-col>

            <!-- DEFINIÇÔES DO NÍVEL -->
            <v-col class="ma-2">
                <v-card>

                    <v-toolbar color="primary" dense dark>
                        <v-toolbar-title class="subtitle-1">DEFINIÇÕES DO NÍVEL</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn tile small  v-if="!pristine && !addMode" color="success" class="float-right" :disabled="!valid || saving" :loading="saving" @click="saveLevel('save')">Guardar</v-btn>
                    </v-toolbar>

                    <template v-if="level.campaign_level_id >= 0">
                        <v-row no-gutters>

                            <!-- DEFINIÇÔES DO NÍVEL -->
                            <v-col class="ma-2">

                                <v-list class="pa-0">

                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                                Estado
                                                <div>
                                                    <v-btn tile small  :color="level.status ? 'success' : ''" @click="setStatus(true)">Activa</v-btn>
                                                    <v-btn tile small  :color="level.status ? '' : 'error'" @click="setStatus(false)">Inactiva</v-btn>
                                                </div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item two-line>
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                                <span :class="{'red--text': level.level_name===''}">Nome</span>
                                                <div>
                                                    <v-text-field dense outlined hide-details v-model="level.level_name" @keyup="setName"></v-text-field>
                                                </div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>


                                    <v-list-item two-line v-if="!level.basic && campaign.type !== 'quantity'">
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                                <span :class="{'red--text': level.type===''}">Tipo de condição</span>
                                                <div>
                                                    <v-btn tile small  :color="level.type==='min_products' ? 'primary' : ''" @click="setType('min_products')">Artigos</v-btn>
                                                    <v-btn tile small  :color="level.type==='min_refs' ? 'primary' : ''" @click="setType('min_refs')">Referências</v-btn>
                                                    <v-btn tile small  :color="level.type==='min_amount' ? 'primary' : ''" @click="setType('min_amount')">Valor</v-btn>
                                                </div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item two-line v-if="!level.basic">
                                        <v-list-item-content>
                                            <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                                Valor mínimo para activar
                                                <div>
                                                    <v-text-field v-if="level.type === 'min_products'" dense outlined hide-details v-model="level.min_products" @keyup="validate"></v-text-field>
                                                    <v-text-field v-if="level.type === 'min_refs'" dense outlined hide-details v-model="level.min_refs" @keyup="validate"></v-text-field>
                                                    <v-text-field v-if="level.type === 'min_amount'" dense outlined hide-details v-model="level.min_amount" @keyup="validate"></v-text-field>
                                                    <v-text-field v-if="level.type === ''" dense outlined hide-details></v-text-field>
                                                </div>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>


                                </v-list>

                            </v-col>

                            <v-col class="ma-2">

                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                            Condição Base
                                            <div>
                                                <v-btn tile small  :color="level.basic ? 'primary' : ''" @click="setBasic(true)">Sim</v-btn>
                                                <v-btn tile small  :color="level.basic ? '' : 'primary'" @click="setBasic(false)">Não</v-btn>
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                            <span :class="{'red--text': level.discount_value<=0}">Valor do desconto</span>
                                            <div>
                                                <v-text-field dense outlined hide-details v-model="level.discount_value" @keyup="validate"></v-text-field>
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item two-line v-if="!level.basic">
                                    <v-list-item-content>
                                        <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                                            <span :class="{'red--text': level.discount_mode===''}">Modo do desconto</span>
                                            <div>
                                                <v-btn tile small  :color="level.discount_mode==='final' ? 'primary' : ''" @click="setMode('final')">Final</v-btn>
                                                <!--                                                <v-btn tile small  :color="level.discount_mode==='incremental' ? 'primary' : ''" @click="setMode('incremental')">Adicional</v-btn>-->
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </v-col>

                            <!-- MARCAS A APLICAR DESCONTO -->
                            <v-col class="ma-2" v-if="campaign.type === 'pre-order'">

                                <v-card class="mx-auto" v-if="!level.basic">

                                    <v-toolbar color="teal darken-1" dark dense>
                                        <v-toolbar-title class="subtitle-1">MARCAS A APLICAR DESCONTO</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn icon v-if="!loading && !addBrandDiscount && !editBrandDiscount" @click="toogleAddBrandDiscount">
                                            <v-icon>add_circle</v-icon>
                                        </v-btn>
                                        <v-btn icon v-if="cdus_update.length && !loading && !addBrandDiscount" :outlined="editBrandDiscount" @click="toogleEditBrandDiscount">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-list class="pa-0">

                                        <v-list-item class="pt-2" v-if="addBrandDiscount">
                                            <v-list-item-content>
                                                <v-select
                                                        :items="brands"
                                                        item-text="Marca"
                                                        item-value="Marca"
                                                        v-model="selectedBrand"
                                                        hide-details
                                                        label="MARCA"
                                                        outlined
                                                        :error="selectedBrand === ''"
                                                        dense
                                                        :disabled="saving"
                                                        class="mb-3"
                                                        @change="updateBrandLevels(selectedBrand)"
                                                >
                                                </v-select>
                                                <v-select
                                                        :items="brandsLevels"
                                                        item-text="Descricao"
                                                        item-value="CDU_Nivel"
                                                        v-model="selectedDiscount"
                                                        hide-details
                                                        label="NÍVEL DESCONTO"
                                                        outlined
                                                        :error="selectedDiscount === ''"
                                                        dense
                                                        :disabled="saving"
                                                >
                                                </v-select>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn-toggle borderless dense>
                                                    <v-btn @click="toogleAddBrandDiscount" :disabled="saving">
                                                        <v-icon>clear</v-icon>
                                                    </v-btn>
                                                    <v-btn color="success" class="white--text" :disabled="saving || selectedBrand === '' || selectedDiscount === ''" :loading="saving" @click="saveBrandDiscount('add', '')">
                                                        <v-icon>done</v-icon>
                                                    </v-btn>
                                                </v-btn-toggle>
                                            </v-list-item-action>
                                        </v-list-item>

                                        <template v-for="cdu in cdus_update">
                                            <v-divider></v-divider>
                                            <v-list-item>
                                                <template>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="cdu.marca"></v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-list-item-action-text class="green--text">Nível {{ cdu.desconto }}</v-list-item-action-text>
                                                    </v-list-item-action>
                                                    <v-list-item-action v-if="editBrandDiscount">
                                                        <v-btn
                                                                x-small
                                                                fab

                                                                color="error"
                                                                @click="saveBrandDiscount('delete', cdu.marca)"
                                                        >
                                                            <v-icon>clear</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                </template>
                                            </v-list-item>
                                        </template>

                                    </v-list>

                                </v-card>
                            </v-col>

                        </v-row>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="addMode" @click="cancelLevel()" :disabled="saving" small tile color="default">CANCELAR</v-btn>
                            <v-btn v-if="addMode" @click="saveLevel('save')" :disabled="!valid || saving" :loading="saving" small tile color="success">ADICIONAR</v-btn>
                            <v-btn v-else @click="saveLevel('delete')" :disabled="saving" :loading="saving" small tile color="error">REMOVER NÍVEL</v-btn>
                        </v-card-actions>

                    </template>

                    <v-row no-gutters v-else>
                        <v-card-text>
                            <div class="my-10">
                                <v-icon color="grey lighten-1" class="mr-4">arrow_back</v-icon>
                                <span>Selecciona um nível no menu lateral ou clica em <v-icon>add_circle</v-icon> para adicionar um nível</span>
                            </div>
                        </v-card-text>
                    </v-row>


                </v-card>
            </v-col>

        </v-row>

    </v-card>

</template>
<script>
    export default {
        props: {
            campaign: {},
        },
        data: () => ({
            addMode: false,
            selected: [1],
            pristine: true,
            valid: false,
            loading: false,
            saving: false,
            levelsOriginal: {},
            levels: [],
            levelOriginal: {},
            level: {},
            dialogAddLevel: false,
            cdus_update: [],
            editBrandDiscount: false,
            addBrandDiscount: false,
            selectedBrand: '',
            selectedDiscount: '',
            brands: [],
            brandsLevels: [],
        }),
        created() {
            this.getLevels();
        },
        methods: {

            getLevels() {
                this.loading = true;
                let request = {url: api.b2bCampaigns() + '/' + this.$route.params.id + '/levels'};
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.levelsOriginal = responseData.levelsOriginal;
                            this.levels = responseData.levels;
                            this.brands = responseData.brands;
                        }
                        this.addMode = false;
                        this.valid = false;
                        this.pristine = true;
                        this.loading = false;
                        this.saving = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        });
                        this.loading = false;
                    })
            },
            addLevel() {
                this.level = {
                    campaign_level_id: 0,
                    level_order: this.levels.length,
                    status: false,
                    basic: false,
                    level_name: '',
                    discount_type: 'percent',
                    discount_mode: 'final',
                    discount_value: 0,
                    type: '',
                    min_products: 0,
                    min_refs: 0,
                    min_amount: 0,
                    cdus_update: null,
                };
                this.addMode = true;
                this.levels.push(this.level);
                this.validate();
            },
            selectLevel(level) {
                this.level = level;
                if (this.level.cdus_update) {
                    this.cdus_update = JSON.parse(this.level.cdus_update);
                }else{
                    this.cdus_update = [];
                }
            },

            setStatus(value) {
                this.level.status = value;
                this.validate();
            },
            setBasic(value) {
                this.level.basic = value;
                this.validate();
            },
            setName() {
                this.level.level_name = this.level.level_name.toUpperCase();
                this.validate();
            },
            setType(value) {
                this.level.type = value;
                this.validate();
            },
            setMode(value) {
                this.level.discount_mode = value;
                this.validate();
            },

            validate() {
                this.pristine = true;
                if (this.levels.length !== this.levelsOriginal.length) {
                    this.pristine = false;
                } else {
                    for (let i = 0; i < this.levels.length; i++) {
                        if (this.levels[i].status != this.levelsOriginal[i].status) {
                            this.pristine = false;
                        }
                        if (this.levels[i].basic != this.levelsOriginal[i].basic) {
                            this.pristine = false;
                        }
                        if (this.levels[i].type != this.levelsOriginal[i].type) {
                            this.pristine = false;
                        }
                        if (this.levels[i].level_name != this.levelsOriginal[i].level_name) {
                            this.pristine = false;
                        }
                        if (this.levels[i].min_products != this.levelsOriginal[i].min_products) {
                            this.pristine = false;
                        }
                        if (this.levels[i].min_refs != this.levelsOriginal[i].min_refs) {
                            this.pristine = false;
                        }
                        if (this.levels[i].min_amount != this.levelsOriginal[i].min_amount) {
                            this.pristine = false;
                        }
                        if (this.levels[i].discount_mode != this.levelsOriginal[i].discount_mode) {
                            this.pristine = false;
                        }
                        if (this.levels[i].discount_value != this.levelsOriginal[i].discount_value) {
                            this.pristine = false;
                        }
                    }
                }

                this.valid = this.level.level_name !== '';
                if (this.level.discount_value <= 0 || this.level.discount_value > 100) {
                    this.valid = false;
                }
                if (!this.level.basic) {
                    if (this.level.type === '') {
                        this.valid = false;
                    }
                    if (this.level.type === 'min_products') {
                        if (this.level.min_products <= 0) {
                            this.valid = false;
                        }
                    }
                    if (this.level.type === 'min_refs') {
                        if (this.level.min_refs <= 0) {
                            this.valid = false;
                        }
                    }
                    if (this.level.type === 'min_amount') {
                        if (this.level.min_amount <= 0) {
                            this.valid = false;
                        }
                    }
                    if (this.level.discount_mode === '') {
                        this.valid = false;
                    }
                }

            },
            cancelLevel() {
                let index = -1;
                for (let i = 0; i < this.levels.length; i += 1) {
                    if (this.levels[i].campaign_level_id === this.level.campaign_level_id) {
                        index = i;
                    }
                }
                if (index !== -1) {
                    this.levels.splice(index, 1);
                    this.addMode = false;
                    this.level = {};
                    this.selected = 100;
                    this.getLevels();
                }
            },
            saveLevel(mode) {
                this.saving = true;
                let request = {
                    url: api.b2bCampaignsSaveLevel(),
                    data: {
                        mode: mode,
                        campaign_id: this.campaign.campaign_id,
                        level: this.level,
                        levels: this.levels,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pristine = true;
                            this.level = {};
                            this.levelsOriginal = responseData.levelsOriginal;
                            this.levels = responseData.levels;
                            this.selected = 100;
                            this.addMode = false;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Nível guardado com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao guardar as alterações!',
                                type: 'error'
                            })
                        }
                        this.saving = false;
                    })
                    .catch(() => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },

            toogleEditBrandDiscount() {
                this.addBrandDiscount = false;
                this.editBrandDiscount = !this.editBrandDiscount;
            },
            toogleAddBrandDiscount() {
                this.selectedBrand = '';
                this.selectedDiscount = '';
                this.editBrandDiscount = false;
                this.addBrandDiscount = !this.addBrandDiscount;
            },
            saveBrandDiscount(mode, brand) {
                this.addBrandDiscount = false;
                this.editBrandDiscount = false;
                if (mode === 'add'){
                    let cdu = {
                        marca:this.selectedBrand,
                        desconto:this.selectedDiscount,
                    };
                    this.cdus_update.push(cdu);
                }

                if (mode === 'delete'){

                    this.cdus_update = this.cdus_update.filter(function( cdu ) {
                        return cdu.marca !== brand;
                    });

                    // this.addBrandDiscount = false;
                    // let cdu = {
                    //     marca:this.selectedBrand,
                    //     desconto:this.selectedDiscount,
                    // };
                    // this.cdus_update.push(cdu);
                }

                this.level.cdus_update = JSON.stringify(this.cdus_update);
                this.saveLevel('save');
            },
            updateBrandLevels(brand) {
                let request = {url: api.b2bCampaigns() + '/brand/levels/' + brand};
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.brandsLevels = responseData.levels;
                        } else {
                            this.brandsLevels = [];
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        });
                        this.loading = false;
                    })

            }

        }
    }
</script>
