<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="12" md="6">
                <page-title title="Configurar Envios"></page-title>
            </v-col>
            <v-col class="text-right">
            </v-col>
        </v-row>

        <!-- MAIN CONTENT -->
        <v-row>
            <v-col cols="8">
                <v-card>
                    <v-toolbar class="mb-3" flat dense dark color="cyan darken-2">
                        <v-toolbar-title class="text-uppercase">Selecionar faturas</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn small outlined @click="toggleDialogRecoverDoc()">Recuperar Documento</v-btn>
                        <v-btn icon @click="getOrdersWaitingShipping()" :loading="loading">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr class="titles_row">
                                <th style="width: 90px">Entidade</th>
                                <th>Nome do Cliente</th>
                                <th style="width: 60px">TipoDoc</th>
                                <th style="width: 100px" class="text-center">NumDoc</th>
                                <th style="width: 120px" class="text-right">Valor Total</th>
                                <th style="width: 140px" class="text-right">Data Documento</th>
                                <th style="width: 1px"></th>
                            </tr>
                            <tr class="filters_row">
                                <td>
                                    <v-text-field
                                            v-model="filters.entity"
                                            outlined
                                            hide-details
                                            single-line
                                            @keyup.enter="getOrdersWaitingShipping"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filters.name"
                                            outlined
                                            hide-details
                                            single-line
                                            @keyup.enter="getOrdersWaitingShipping"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filters.typeDoc"
                                            outlined
                                            hide-details
                                            single-line
                                            @keyup.enter="getOrdersWaitingShipping"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filters.numDoc"
                                            outlined
                                            hide-details
                                            single-line
                                            @keyup.enter="getOrdersWaitingShipping"
                                    ></v-text-field>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="loading">
                                <td colspan="7">
                                    <v-progress-linear indeterminate></v-progress-linear>
                                </td>
                            </tr>
                            <tr v-for="order in ordersToSend"
                                :class="{'grey lighten-2': selectedOrdersIds.indexOf(order.id) >= 0}">
                                <td @click="selectOrder(order)">{{ order.client_code }}</td>
                                <td @click="selectOrder(order)">{{ order.client_name }}</td>
                                <td @click="selectOrder(order)" class="text-center">{{ order.doc_type }}</td>
                                <td @click="selectOrder(order)" class="text-center">{{ order.doc_number }}</td>
                                <td @click="selectOrder(order)" class="text-right" style="white-space: nowrap">{{
                                        formatPrice(order.order_total)
                                    }} €
                                </td>
                                <td @click="selectOrder(order)" class="text-right">{{ order.order_date }}</td>
                                <td class="text-center">
                                    <v-btn small @click="toggleDialogHideDoc(order.id)">
                                        <i class="fas fa-fw fa-ban"></i></v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                        <v-row>
                            <v-col :md="2">
                                <v-select
                                        v-model="pagination.per_page"
                                        :items="itemPerPage"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        label="Faturas por página"
                                        class="staff_select"
                                        @change="getOrdersWaitingShipping"
                                ></v-select>
                            </v-col>
                            <v-col>
                                <v-pagination
                                        v-model="pagination.page"
                                        :length="getTotalPages"
                                        :total-visible="7"
                                        @input="getOrdersWaitingShipping"
                                ></v-pagination>
                            </v-col>
                            <v-col :md="2" class="text-right">
                                <v-select
                                        v-model="filters.week"
                                        :items="filterWeek"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="getOrdersWaitingShipping"
                                        label="Intervalo de Tempo"
                                        class="staff_select"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card class="ml-2 mb-2">
                    <v-toolbar class="mb-3" flat dense dark color="orange darken-1">
                        <v-toolbar-title class="text-uppercase">Escolher transportadora</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-row style="justify-content: space-evenly ">
                            <v-col md="3" class="pa-1">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <v-img
                                            :src="urlAPI+'/assets/img/seur_auto.jpg'"
                                            contain
                                            class="ma-1"
                                            @click="configShippingSEUR2()"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="3" class="pa-2">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <v-img
                                            :src="urlAPI+'/assets/img/seur_bicicletas.jpg'"
                                            contain
                                            class="ma-1"
                                            @click="configShippingSEURBikes()"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="3" class="pa-2">
                                <v-card hover>
                                    <v-img
                                            :src="urlAPI+'/assets/img/seur_manual.jpg'"
                                            contain
                                            class="ma-1"
                                            @click="SEURManual()"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="3" class="pa-2" v-if="false">
                                <v-card hover class="mx-1">
                                    <v-img
                                            :src="urlAPI+'/assets/img/dhl.jpg'"
                                            contain
                                            class="ma-1"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="3" class="pa-2">
                                <v-card hover>
                                    <v-img
                                            :src="urlAPI+'/assets/img/ctt-manual.png'"
                                            contain
                                            class="ma-1"
                                            @click="CTTManual()"
                                    ></v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row style="justify-content: space-evenly ">
                            <v-col md="3" class="pa-2">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <v-img
                                            :src="urlAPI+'/assets/img/ctt.jpg'"
                                            contain
                                            class="ma-1"
                                            @click="configShippingCTT()"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="3" class="pa-2">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <v-img
                                            :src="urlAPI+'/assets/img/redur.jpg'"
                                            contain
                                            class="ma-1"
                                            @click="configShippingREDUR()"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="3" class="pa-2">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <v-img
                                            :src="urlAPI+'/assets/img/nacex.png'"
                                            contain
                                            @click="configShippingNACEX()"
                                            class="ma-1"
                                    ></v-img>
                                </v-card>
                            </v-col>
                            <v-col md="3" class="pa-2">
                                <v-card hover :disabled="!selectedOrders.length">
                                    <v-img
                                            :src="urlAPI+'/assets/img/delnext.png'"
                                            contain
                                            @click="configShippingDELNEXT()"
                                            class="ma-1"
                                    ></v-img>
                                </v-card>
                            </v-col>

                        </v-row>
                        <v-row class="mt-4 text-center">
                            <v-col>
                                <h2>{{ ref.code }}</h2>
                                <input type="hidden" id="ref-code" :value="ref.code">
                                <v-btn @click="copyRefToClipboard()">Copiar código</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-card class="ml-2">
                    <v-toolbar class="mb-3" flat dense dark color="green darken-2">
                        <!--                        <v-toolbar-title class="text-uppercase">Referência Deporvillage</v-toolbar-title>-->
                        <v-toolbar-title class="text-uppercase">IMPRIMIR ETIQUETAS</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        dense
                                        outlined
                                        clearable
                                        v-model="refDeporvillage"
                                        label="Ref. Deporvillage"
                                ></v-text-field>
                                <v-btn color="success" :loading="loadingRefDeporvillage" block
                                       @click="printLabelDeporvillage()">
                                    Imprimir
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        dense
                                        outlined
                                        clearable
                                        v-model="ean"
                                        label="EAN"
                                ></v-text-field>
                                <v-row>
                                    <v-col class="py-0">
                                        <v-btn color="warning" :loading="loadingEan" @click="printLabelEAN()">Imprimir
                                        </v-btn>
                                    </v-col>
                                    <!--                                    <v-col class="pa-0">-->
                                    <!--                                        <v-btn :loading="loadingEan" @click="downloadLabelEan()">Imagem</v-btn>-->
                                    <!--                                    </v-col>-->
                                    <v-col class="pa-0">
                                        <v-btn :loading="loadingEan" @click="downloadLabelRef()">Imp. Ref.</v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-toolbar class="mb-3" flat dense dark color="primary">
                        <v-toolbar-title class="text-uppercase">{{ new Date().toJSON().slice(0, 10) }} | Envios prontos:
                            {{ ordersSentToday.length }} | Total Volumes: {{ totalVolumes }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn small outlined @click="dialogManifestNACEX=true">Imprimir Manifesto NACEX</v-btn>
                        <v-btn small outlined @click="dialogManifestCTT=true">Imprimir Manifesto CTT</v-btn>
                        <v-btn small outlined @click="dialogManifestREDUR=true">Imprimir Manifesto REDUR</v-btn>
                        <!--  <v-btn small outlined @click="printManifesto('SEUR')">Imprimir Manifesto SEUR</v-btn>-->
                        <!--  <v-btn small outlined @click="dialogManifestSEUR=true">Imprimir Manifesto SEUR</v-btn>-->
                    </v-toolbar>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th>Transp.</th>
                                <th>Conta</th>
                                <th>Referência</th>
                                <th>Cod. Cliente</th>
                                <th>Nome do Cliente</th>
                                <th>Faturas</th>
                                <th class="text-center">Email</th>
                                <th class="text-center">Cancelar Envio</th>
                            </tr>
                            <tr class="filters_row">
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.courier"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-select
                                            v-model="filtersToday.account"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                            :items="filterAccount"
                                            item-text="TEXT"
                                            item-value="VALUE"
                                    ></v-select>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.reference"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.entity"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.name"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersToday.docs"
                                            outlined
                                            hide-details
                                            single-line
                                            @change="updateOrdersSent()"
                                    ></v-text-field>
                                </td>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-if="loadingShippedToday">
                                <td colspan="6">
                                    <v-progress-linear indeterminate></v-progress-linear>
                                </td>
                            </tr>
                            <tr v-for="shipped in ordersSentToday">
                                <td>{{ shipped.courier }}</td>
                                <td>{{
                                        shipped.account === seurCodesAccounts.auto.pt || shipped.account === seurCodesAccounts.auto.es || shipped.account === null ?
                                                'Normal' :
                                                (shipped.account === seurCodesAccounts.auto_sea.pt || shipped.account === seurCodesAccounts.auto_sea.es ? 'Marítimo' : 'Bicicletas')
                                    }}
                                </td>
                                <td>
                                    {{ shipped.reference }}
                                    <v-btn icon small
                                           @click="printLabel(shipped)">
                                        <v-icon small>print</v-icon>
                                    </v-btn>
                                </td>
                                <td>{{ shipped.clientCode }}</td>
                                <td>{{ shipped.clientName }}</td>
                                <td>{{ shipped.docs }}</td>
                                <td>
                                    <template v-if="shipped.courier == 'SEUR2'">
                                        <v-btn v-if="shipped.emailToDispatch == false" tile small color="success"
                                               @click="sendSeurEmail(shipped)">Enviar
                                        </v-btn>
                                        <span v-else>Enviado</span>
                                    </template>
                                </td>
                                <td class="text-center">
                                    <v-btn small v-if="writePermission('orders/shipped')" color="error"
                                           @click="unmarkOrderAsShipped(shipped)">
                                        <i class="fas fa-times"></i>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialogHideDoc" persistent max-width="290">
                <v-card>
                    <v-card-title>
                        Ocultar o documento
                    </v-card-title>
                    <v-card-text>
                        Tem a certeza que pretende ocultar o documento?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogHideDoc()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmHideDoc()" color="error">
                            Confirmo
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogRecoverDoc" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Recuperar documento
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="docRecover.type"
                                        outlined
                                        label="Tipo"
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="docRecover.serie"
                                        outlined
                                        label="Série"
                                        class="ml-2"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="docRecover.numDoc"
                                        outlined
                                        label="Número"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                Tem a certeza que pretende recuperar o documento?
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogRecoverDoc()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmRecoverDoc()" color="success">
                            Confirmo
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogConfigSEUR" persistent max-width="720">
                <v-card>
                    <v-card-title>
                        Configurar envio Seur
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <v-row>
                                <v-col>
                                    <strong>Tracking Number: {{ ref.code }}</strong>
                                </v-col>
                                <v-col class="text-right">
                                    <v-btn color="error" @click="buttonChangeAddress()"
                                           v-if="clientSelected.entity !== 'Envio manual'">
                                        Editar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <p><strong>Entidade: {{ clientSelected.entity }}</strong></p>
                            <p v-if="!changeAddress"><strong>Nome do cliente: {{ clientSelected.name }}</strong></p>
                            <v-text-field dense v-model="clientSelected.name" v-if="changeAddress"
                                          label="Nome do cliente" counter maxlength="21" outlined
                                          :rules="[rules.required]"></v-text-field>
                            <p><strong>Morada de Entrega: </strong></p>
                            <v-select
                                    :items="addresses"
                                    outlined
                                    single-line
                                    :rules="[rules.required]"
                                    v-model="inputConfigShip.address"
                                    v-if="!changeAddress"
                                    dense
                            >
                                <template v-slot:item="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                            </v-select>
                            <v-text-field dense v-model="inputConfigShip.address.Address" v-if="changeAddress" outlined
                                          label="Morada 1" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Address2" v-if="changeAddress" outlined
                                          label="Morada 2" counter maxlength="32" ></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Locality" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Cp" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Código Postal"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.CpLocality" v-if="changeAddress"
                                          outlined
                                          :rules="[rules.required]" label="Código Postal Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Country" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Pais"></v-text-field>
                            <v-row>
                                <v-col md="6">
                                    <p class="ma-0"><strong>Tipo de Transporte:</strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="1" label="Normal"></v-radio>
                                        <v-radio value="17" label="Marítimo"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <!--                                <v-col md="6">
                                                                    <p class="ma-0"><strong>Portes Debidos: </strong></p>
                                                                    <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>
                                                                        <v-radio value="0" :label="$t('no')"></v-radio>
                                                                        <v-radio value="1" :label="$t('yes')"></v-radio>
                                                                    </v-radio-group>
                                                                </v-col>-->
                            </v-row>
                            <p class="ma-0"><strong>Quantidade Caixas: </strong></p>
                            <v-row>
                                <v-col md="10">
                                    <v-btn-toggle v-model="inputConfigShip.quantity" :rules="[rules.required]">
                                        <v-btn value="1">1</v-btn>
                                        <v-btn value="2">2</v-btn>
                                        <v-btn value="3">3</v-btn>
                                        <v-btn value="4">4</v-btn>
                                        <v-btn value="5">5</v-btn>
                                        <v-btn value="6">6</v-btn>
                                        <v-btn value="7">7</v-btn>
                                        <v-btn value="8">8</v-btn>
                                        <v-btn value="9">9</v-btn>
                                        <v-btn value="10">10</v-btn>
                                    </v-btn-toggle>
                                </v-col>
                                <v-col md="2">
                                    <v-text-field dense :rules="[rules.required]" v-model="inputConfigShip.quantity"
                                                  outlined single-line
                                                  style="width: 50px; margin-left: auto"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.weight" placeholder="KG" label="Peso"
                                                  outlined
                                                  style="width: 150px" :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col md="6">
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col md="6">
                                    <p>
                                        Contra-Reembolso:
                                        <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                               @click="updatePayment('yes')">
                                            Sim
                                        </v-btn>
                                        <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                               @click="updatePayment('no')">
                                            Não
                                        </v-btn>
                                    </p>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.price" label="Preço a cobrar" outlined
                                                  style="width: 150px"
                                                  v-if="inputConfigShip.payment === 'yes'"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipSEUR()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingSEUR()" color="success" :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfigRedur" persistent max-width="720">
                <v-card>
                    <v-card-title>
                        Configurar envio Redur
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <v-row>
                                <v-col>
                                    <strong>Tracking Number: {{ ref.code }}</strong>
                                </v-col>
                                <v-col class="text-right">
                                    <v-btn color="error" @click="buttonChangeAddress()"
                                           v-if="clientSelected.entity !== 'Envio manual'">
                                        Editar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <p><strong>Entidade: {{ clientSelected.entity }}</strong></p>
                            <p v-if="!changeAddress"><strong>Nome do cliente: {{ clientSelected.name }}</strong></p>
                            <v-text-field dense v-model="clientSelected.name" counter maxlength="32" v-if="changeAddress"
                                          label="Nome do cliente" outlined
                                          :rules="[rules.required]"></v-text-field>
                            <p><strong>Morada de Entrega: </strong></p>
                            <v-select
                                    :items="addresses"
                                    outlined
                                    single-line
                                    :rules="[rules.required]"
                                    v-model="inputConfigShip.address"
                                    v-if="!changeAddress"
                                    dense
                            >
                                <template v-slot:item="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                            </v-select>
                            <v-text-field dense v-model="inputConfigShip.address.Address" v-if="changeAddress" outlined
                                          label="Morada 1" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Address2" v-if="changeAddress" outlined
                                          label="Morada 2" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Locality" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Cp" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Código Postal"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.CpLocality" v-if="changeAddress"
                                          outlined
                                          :rules="[rules.required]" label="Código Postal Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Country" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Pais"></v-text-field>
                            <v-row>
                                <!--                                <v-col md="6">-->
                                <!--                                    <p class="ma-0"><strong>Tipo de Transporte:</strong></p>-->
                                <!--                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>-->
                                <!--                                        <v-radio value="1" label="Normal"></v-radio>-->
                                <!--                                        <v-radio value="17" label="Marítimo"></v-radio>-->
                                <!--                                    </v-radio-group>-->
                                <!--                                </v-col>-->
                                <v-col md="6">
                                    <p class="ma-0"><strong>Portes Debidos: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>
                                        <v-radio value="0" :label="$t('no')"></v-radio>
                                        <v-radio value="1" :label="$t('yes')"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <p class="ma-0"><strong>Quantidade Caixas: </strong></p>
                            <v-row>
                                <v-col md="10">
                                    <v-btn-toggle v-model="inputConfigShip.quantity" :rules="[rules.required]">
                                        <v-btn value="1">1</v-btn>
                                        <v-btn value="2">2</v-btn>
                                        <v-btn value="3">3</v-btn>
                                        <v-btn value="4">4</v-btn>
                                        <v-btn value="5">5</v-btn>
                                        <v-btn value="6">6</v-btn>
                                        <v-btn value="7">7</v-btn>
                                        <v-btn value="8">8</v-btn>
                                        <v-btn value="9">9</v-btn>
                                        <v-btn value="10">10</v-btn>
                                    </v-btn-toggle>
                                </v-col>
                                <v-col md="2">
                                    <v-text-field dense :rules="[rules.required]" v-model="inputConfigShip.quantity"
                                                  outlined single-line
                                                  style="width: 50px; margin-left: auto"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.weight" placeholder="KG" label="Peso"
                                                  outlined
                                                  style="width: 150px" :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col md="6">
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col md="6">
                                    <p>
                                        Contra-Reembolso:
                                        <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                               @click="updatePayment('yes')">
                                            Sim
                                        </v-btn>
                                        <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                               @click="updatePayment('no')">
                                            Não
                                        </v-btn>
                                    </p>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.price" label="Preço a cobrar" outlined
                                                  style="width: 150px"
                                                  v-if="inputConfigShip.payment === 'yes'"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipRedur()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingRedur()" color="success"
                               :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfigNacex" persistent max-width="720">
                <v-card>
                    <v-card-title>
                        Configurar envio Nacex
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <v-row>
                                <v-col>
                                    <strong>Tracking Number: {{ ref.code }}</strong>
                                </v-col>
                                <v-col class="text-right">
                                    <v-btn color="error" @click="buttonChangeAddress()"
                                           v-if="clientSelected.entity !== 'Envio manual'">
                                        Editar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <p><strong>Entidade: {{ clientSelected.entity }}</strong></p>
                            <p v-if="!changeAddress"><strong>Nome do cliente: {{ clientSelected.name }}</strong></p>
                            <v-text-field dense v-model="clientSelected.name" v-if="changeAddress"
                                          label="Nome do cliente" outlined
                                          :rules="[rules.required]"></v-text-field>
                            <p><strong>Morada de Entrega: </strong></p>
                            <v-select
                                    :items="addresses"
                                    outlined
                                    single-line
                                    :rules="[rules.required]"
                                    v-model="inputConfigShip.address"
                                    v-if="!changeAddress"
                                    dense
                            >
                                <template v-slot:item="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                            </v-select>
                            <v-text-field dense v-model="inputConfigShip.address.Address" v-if="changeAddress" outlined
                                          label="Morada 1" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Address2" v-if="changeAddress" outlined
                                          label="Morada 2" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Locality" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Cp" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Código Postal"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.CpLocality" v-if="changeAddress"
                                          outlined
                                          :rules="[rules.required]" label="Código Postal Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Country" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Pais"></v-text-field>
                            <v-row>
                                <!--                                <v-col md="6">-->
                                <!--                                    <p class="ma-0"><strong>Tipo de Transporte:</strong></p>-->
                                <!--                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>-->
                                <!--                                        <v-radio value="1" label="Normal"></v-radio>-->
                                <!--                                        <v-radio value="17" label="Marítimo"></v-radio>-->
                                <!--                                    </v-radio-group>-->
                                <!--                                </v-col>-->
                                <v-col md="6">
                                    <p class="ma-0"><strong>Portes Debidos: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>
                                        <v-radio value="0" :label="$t('no')"></v-radio>
                                        <v-radio value="1" :label="$t('yes')"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="6">
                                    <p class="ma-0"><strong>Tipo de Serviço: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="NOR" :label="'Normal'"></v-radio>
                                        <v-radio value="21" :label="'Sabado'"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <p class="ma-0"><strong>Quantidade Caixas: </strong></p>
                            <v-row>
                                <v-col md="10">
                                    <v-btn-toggle v-model="inputConfigShip.quantity" :rules="[rules.required]">
                                        <v-btn value="1">1</v-btn>
                                        <v-btn value="2">2</v-btn>
                                        <v-btn value="3">3</v-btn>
                                        <v-btn value="4">4</v-btn>
                                        <v-btn value="5">5</v-btn>
                                        <v-btn value="6">6</v-btn>
                                        <v-btn value="7">7</v-btn>
                                        <v-btn value="8">8</v-btn>
                                        <v-btn value="9">9</v-btn>
                                        <v-btn value="10">10</v-btn>
                                    </v-btn-toggle>
                                </v-col>
                                <v-col md="2">
                                    <v-text-field dense :rules="[rules.required]" v-model="inputConfigShip.quantity"
                                                  outlined single-line
                                                  style="width: 50px; margin-left: auto"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.weight" placeholder="KG" label="Peso"
                                                  outlined
                                                  style="width: 150px" :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col md="6">
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col md="6">
                                    <p>
                                        Contra-Reembolso:
                                        <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                               @click="updatePayment('yes')">
                                            Sim
                                        </v-btn>
                                        <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                               @click="updatePayment('no')">
                                            Não
                                        </v-btn>
                                    </p>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.price" label="Preço a cobrar" outlined
                                                  style="width: 150px"
                                                  v-if="inputConfigShip.payment === 'yes'"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipNacex()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingNacex()" color="success"
                               :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfigCTT" persistent max-width="720">
                <v-card>
                    <v-card-title>
                        Configurar envio CTT
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <v-row>
                                <v-col>
                                    <strong>Tracking Number: {{ ref.code }}</strong>
                                </v-col>
                                <v-col class="text-right">
                                    <v-btn color="error" @click="buttonChangeAddress()"
                                           v-if="clientSelected.entity !== 'Envio manual'">
                                        Editar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <p><strong>Entidade: {{ clientSelected.entity }}</strong></p>
                            <p v-if="!changeAddress"><strong>Nome do cliente: {{ clientSelected.name }}</strong></p>
                            <v-text-field dense v-model="clientSelected.name" v-if="changeAddress"
                                          label="Nome do cliente" outlined
                                          :rules="[rules.required]"></v-text-field>
                            <v-text-field dense v-model="clientSelected.tel" v-if="changeAddress"
                                          label="Número do telefone" outlined></v-text-field>
                            <p><strong>Morada de Entrega: </strong></p>
                            <v-select
                                    :items="addresses"
                                    outlined
                                    single-line
                                    :rules="[rules.required]"
                                    v-model="inputConfigShip.address"
                                    v-if="!changeAddress"
                                    dense
                            >
                                <template v-slot:item="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                            </v-select>
                            <v-text-field dense v-model="inputConfigShip.address.Address" v-if="changeAddress" outlined
                                          label="Morada 1" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Address2" v-if="changeAddress" outlined
                                          label="Morada 2" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Locality" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Cp" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Código Postal"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.CpLocality" v-if="changeAddress"
                                          outlined
                                          :rules="[rules.required]" label="Código Postal Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Country" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Pais"></v-text-field>
                            <!--              <v-row>-->
                            <!--                <v-col md="6">-->
                            <!--                  <p class="ma-0"><strong>Portes Debidos: </strong></p>-->
                            <!--                  <v-radio-group v-model="inputConfigShip.portesDebidos" row mandatory>-->
                            <!--                    <v-radio value="0" :label="$t('no')"></v-radio>-->
                            <!--                    <v-radio value="1" :label="$t('yes')"></v-radio>-->
                            <!--                  </v-radio-group>-->
                            <!--                </v-col>-->
                            <!--              </v-row>-->
                            <v-row>
                                <v-col md="6">
                                    <p class="ma-0"><strong>Tipo de Serviço: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="EMSF010.01" :label="'PT Normal'"></v-radio>
                                        <v-radio value="ENCF008.01" :label="'PT Ilhas - Marítmo'"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <p class="ma-0"><strong>Quantidade Caixas: </strong></p>
                            <v-row>
                                <v-col md="10">
                                    <v-btn-toggle v-model="inputConfigShip.quantity" :rules="[rules.required]">
                                        <v-btn value="1">1</v-btn>
                                        <v-btn value="2">2</v-btn>
                                        <v-btn value="3">3</v-btn>
                                        <v-btn value="4">4</v-btn>
                                        <v-btn value="5">5</v-btn>
                                        <v-btn value="6">6</v-btn>
                                        <v-btn value="7">7</v-btn>
                                        <v-btn value="8">8</v-btn>
                                        <v-btn value="9">9</v-btn>
                                        <v-btn value="10">10</v-btn>
                                    </v-btn-toggle>
                                </v-col>
                                <v-col md="2">
                                    <v-text-field dense :rules="[rules.required]" v-model="inputConfigShip.quantity"
                                                  outlined single-line
                                                  style="width: 50px; margin-left: auto"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.weight" placeholder="KG" label="Peso"
                                                  outlined
                                                  style="width: 150px" :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col md="6">
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col md="6">
                                    <p>
                                        Contra-Reembolso:
                                        <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                               @click="updatePayment('yes')">
                                            Sim
                                        </v-btn>
                                        <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                               @click="updatePayment('no')">
                                            Não
                                        </v-btn>
                                    </p>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.price" label="Preço a cobrar" outlined
                                                  style="width: 150px"
                                                  v-if="inputConfigShip.payment === 'yes'"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipCTT()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingCTT()" color="success" :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogConfigDelnext" persistent max-width="720">
                <v-card>
                    <v-card-title>
                        Configurar envio Delnext
                    </v-card-title>
                    <v-card-text>
                        <v-form
                                ref="form"
                        >
                            <v-row>
                                <v-col>
                                    <strong>Tracking Number: {{ ref.code }}</strong>
                                </v-col>
                                <v-col class="text-right">
                                    <v-btn color="error" @click="buttonChangeAddress()"
                                           v-if="clientSelected.entity !== 'Envio manual'">
                                        Editar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <p><strong>Entidade: {{ clientSelected.entity }}</strong></p>
                            <p v-if="!changeAddress"><strong>Nome do cliente: {{ clientSelected.name }}</strong></p>
                            <v-text-field dense v-model="clientSelected.name" v-if="changeAddress"
                                          label="Nome do cliente" outlined
                                          :rules="[rules.required]"></v-text-field>
                            <p><strong>Morada de Entrega: </strong></p>
                            <v-select
                                    :items="addresses"
                                    outlined
                                    single-line
                                    :rules="[rules.required]"
                                    v-model="inputConfigShip.address"
                                    v-if="!changeAddress"
                                    dense
                            >
                                <template v-slot:item="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    {{ item.Address }} {{ item.Address2 }} {{ item.Locality }} {{ item.Cp }} {{
                                        item.CpLocality
                                    }} {{ item.Country }}
                                </template>
                            </v-select>
                            <v-text-field dense v-model="inputConfigShip.address.Address" v-if="changeAddress" outlined
                                          label="Morada 1" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Address2" v-if="changeAddress" outlined
                                          label="Morada 2" counter maxlength="32"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Locality" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Cp" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Código Postal"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.CpLocality" v-if="changeAddress"
                                          outlined
                                          :rules="[rules.required]" label="Código Postal Localidade"></v-text-field>
                            <v-text-field dense v-model="inputConfigShip.address.Country" v-if="changeAddress" outlined
                                          :rules="[rules.required]" label="Pais"></v-text-field>
                            <v-row>
                                <v-col md="6">
                                    <p class="ma-0"><strong>Tipo de Serviço: </strong></p>
                                    <v-radio-group v-model="inputConfigShip.type" row mandatory>
                                        <v-radio value="1" :label="'PT Ilhas - Marítmo'"></v-radio>
                                        <v-radio value="2" :label="'PT Ilhas - Avião'"></v-radio>
                                        <v-radio value="3" :label="'PT Continental e ES'"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                            <p class="ma-0"><strong>Quantidade Caixas: </strong></p>
                            <v-row>
                                <v-col md="10">
                                    <v-btn-toggle v-model="inputConfigShip.quantity" :rules="[rules.required]">
                                        <v-btn value="1">1</v-btn>
                                        <v-btn value="2">2</v-btn>
                                        <v-btn value="3">3</v-btn>
                                        <v-btn value="4">4</v-btn>
                                        <v-btn value="5">5</v-btn>
                                        <v-btn value="6">6</v-btn>
                                        <v-btn value="7">7</v-btn>
                                        <v-btn value="8">8</v-btn>
                                        <v-btn value="9">9</v-btn>
                                        <v-btn value="10">10</v-btn>
                                    </v-btn-toggle>
                                </v-col>
                                <v-col md="2">
                                    <v-text-field dense :rules="[rules.required]" v-model="inputConfigShip.quantity"
                                                  outlined single-line
                                                  style="width: 50px; margin-left: auto"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.weight" placeholder="KG" label="Peso"
                                                  outlined
                                                  style="width: 150px" :rules="[rules.required]"></v-text-field>
                                </v-col>
                                <v-col md="6">
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col md="6">
                                    <p>
                                        Contra-Reembolso:
                                        <v-btn tile :color="inputConfigShip.payment === 'yes' ? 'success' : ''"
                                               @click="updatePayment('yes')">
                                            Sim
                                        </v-btn>
                                        <v-btn tile :color="inputConfigShip.payment === 'no' ? 'error' : ''"
                                               @click="updatePayment('no')">
                                            Não
                                        </v-btn>
                                    </p>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field dense v-model="inputConfigShip.price" label="Preço a cobrar" outlined
                                                  style="width: 150px"
                                                  v-if="inputConfigShip.payment === 'yes'"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-text-field dense v-model="inputConfigShip.observation" label="Observações" outlined
                                          hide-details></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogConfigShipDelnext()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="createShippingDelnext()" color="success"
                               :disabled="inputConfigShip.payment === ''">
                            Criar Etiqueta
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogManifestNACEX" persistent max-width="500">
                <v-card>
                    <v-card-title>
                        Confirmar impressão do manifesto NACEX?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn @click="dialogManifestNACEX=false" color="error">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="printManifesto('NACEX'); dialogManifestNACEX=false" color="success">
                            Imprimir Manifesto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogManifestCTT" persistent max-width="500">
                <v-card>
                    <v-card-title>
                        Confirmar impressão do manifesto CTT?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn @click="dialogManifestCTT=false" color="error">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="printManifesto('CTT'); dialogManifestCTT=false" color="success">
                            Imprimir Manifesto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogManifestSEUR" persistent max-width="500">
                <v-card>
                    <v-card-title>
                        Confirmar impressão do manifesto SEUR?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn @click="dialogManifestSEUR=false" color="error">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="printManifesto('SEUR'); dialogManifestSEUR=false" color="success">
                            Imprimir Manifesto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogManifestREDUR" persistent max-width="500">
                <v-card>
                    <v-card-title>
                        Confirmar impressão do manifesto REDUR?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn @click="dialogManifestREDUR=false" color="error">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="printManifesto('REDUR'); dialogManifestREDUR=false" color="success">
                            Imprimir Manifesto
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'

export default {
    data: () => ({
        loading: false,
        loadingShippedToday: false,
        ordersToSend: [],
        totalOrdersToSend: 0,
        ordersSentToday: [],
        originOrdersSentToday: [],
        totalVolumes: 0,
        filters: {
            entity: '',
            name: '',
            typeDoc: '',
            numDoc: '',
            week: 1
        },
        selectedOrders: [],
        selectedOrdersIds: [],
        clientSelected: {
            entity: '',
            name: '',
            tel: '',
            email: ''
        },
        dialogHideDoc: false,
        dialogRecoverDoc: false,
        dialogConfigSEUR: false,
        dialogConfigRedur: false,
        dialogConfigNacex: false,
        dialogConfigCTT: false,
        dialogManifestCTT: false,
        dialogManifestSEUR: false,
        dialogManifestNACEX: false,
        dialogManifestREDUR: false,
        dialogConfigDelnext: false,
        selectedDoc: '',
        filterWeek: [
            {text: '1 Semana', value: 1},
            {text: '2 Semanas', value: 2},
            {text: '1 Mês', value: 4},
            {text: '2 Meses', value: 8},
            {text: '3 Meses', value: 12},
            {text: '4 Meses', value: 16}
        ],
        itemPerPage: [
            {text: '15', value: 15},
            {text: '25', value: 25},
            {text: '50', value: 50},
            {text: '100', value: 100}
        ],
        docRecover: {
            type: '',
            serie: '',
            numDoc: ''
        },
        courier: '',
        ref: {},
        addresses: [],
        clientDetail: [],
        inputConfigShip: {
            price: '',
            address: {
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            },
            payment: '',
            quantity: 0,
            weight: '',
            observation: '',
            type: '1',
            portesDebidos: 0,
            account: 'auto',
        },
        rules: {
            required: value => !!value || value === '0' || i18n.t('required'),
            // priceRequired: (value, payment) => {
            //     return false;
            //     if (payment === 'yes') {
            //         let number = Number(value);
            //         if (number <= 0) {
            //             return i18n.t('required')
            //         } else {
            //             return false;
            //         }
            //     } else {
            //         return false;
            //     }
            // }
        },
        changeAddress: false,
        loadingRefDeporvillage: false,
        refDeporvillage: '',
        loadingEan: false,
        ean: '',
        filtersToday: {
            courier: '',
            account: '',
            reference: '',
            entity: '',
            name: '',
            docs: ''
        },
        pagination: {
            per_page: 15,
            page: 1
        },
        seurCodesAccounts: {
            'auto': {
                'pt': '07287606',
                'es': '07287604'
            },
            'auto_sea': {
                'pt': '07287607',
                'es': '07287605'
            },
            'bikes': '07287612'
        },
        seurCodes: {
            'normal': 'auto',
            'bikes': '07287612',
            'sea': 'auto_sea',
        },
        filterAccount: [
            {'TEXT': 'Todos', 'VALUE': ''},
            {'TEXT': 'Normal', 'VALUE': 'auto'},
            {'TEXT': 'Bicicletas', 'VALUE': '07287612'},
            {'TEXT': 'Marítimo', 'VALUE': 'auto_sea'}
        ],

    }),
    created() {
        document.title = 'Configurar Envios'
        this.initialize()
    },
    methods: {
        initialize() {
            this.getOrdersWaitingShipping()
        },
        getOrdersWaitingShipping() {
            this.loading = true
            this.ordersToSend = []
            this.selectedOrdersIds = []
            this.selectedOrders = []
            this.originOrdersSentToday = [];
            this.ordersSentToday = [];

            let filters = {
                filters: this.filters,
                pagination: this.pagination
            }

            Order.dispatch('getOrdersWaitingShipping', filters)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.ordersToSend = responseData.waitingShipping
                            this.totalOrdersToSend = responseData.totalWaitingShipping
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })

            this.loadingShippedToday = true
            Order.dispatch('getOrdersShipped', 'flag_todayonly')
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.ordersSentToday = responseData.shipped
                            this.originOrdersSentToday = this.ordersSentToday
                            this.updateOrdersSent()

                            this.totalVolumes = 0
                            this.ordersSentToday.forEach(item => {
                                this.totalVolumes += parseInt(item.quantity)
                            })

                            if (isNaN(this.totalVolumes))
                                this.totalVolumes = 0

                        }else{
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        };
                        this.loadingShippedToday = false
                    })
                    /*.catch(response => {
                         Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingShippedToday = false
                    })*/
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        selectOrder(order) {
            var index = this.selectedOrdersIds.indexOf(order.id)
            if (index >= 0) {
                this.selectedOrdersIds.splice(index, 1)
                this.selectedOrders.splice(index, 1)
            } else {
                this.selectedOrders.push({
                    id: order.id,
                    type: order.doc_type,
                    number: order.doc_number,
                    serie: order.doc_serie,
                    total_doc: order.order_total,
                    vendedor: order.vendedor,
                    reference: order.referencia,
                    requisicao: order.requisicao
                })
                this.selectedOrdersIds.push(order.id)

                this.clientSelected = {
                    entity: order.client_code,
                    name: order.client_name,
                    tel: order.Fac_Tel,
                    email: order.CDU_Login,
                }
            }
        },
        toggleDialogHideDoc(id) {
            this.dialogHideDoc = !this.dialogHideDoc
            if (this.dialogHideDoc) {
                this.selectedDoc = id
            }
        },
        confirmHideDoc() {
            Order.dispatch('hideDoc', this.selectedDoc)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogHideDoc = false
                            this.selectedDoc = ''
                            this.getOrdersWaitingShipping()
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao ocultar documento!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao ocultar documento!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        toggleDialogRecoverDoc() {
            this.dialogRecoverDoc = !this.dialogRecoverDoc
        },
        confirmRecoverDoc() {
            Order.dispatch('recoverDoc', this.docRecover)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogRecoverDoc = false
                            this.docRecover = {
                                type: '',
                                serie: '',
                                numDoc: ''
                            }

                            this.selectedOrders = []
                            this.selectedOrdersIds = []
                            this.getOrdersWaitingShipping()

                            let alert = {
                                snackbar: true,
                                text: 'O documento foi recuperado!',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao recuperar documento!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao recuperar documento!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        configShippingSEUR2() {
            this.courier = 'SEUR2';
            this.createOrUpdateShippingPackageSEUR(this.seurCodes.normal);
        },
        configShippingSEURBikes() {
            this.courier = 'SEUR2';
            this.createOrUpdateShippingPackageSEUR(this.seurCodes.bikes);
        },
        createOrUpdateShippingPackageSEUR(account) {
            let order = {
                courier: this.courier,
                clientCode: this.clientSelected.entity,
                clientName: this.clientSelected.name,
                salesman: this.selectedOrders[0].vendedor
            };
            let loader = this.$loading.show();

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.toggleDialogConfigShipSEUR();
                            this.getDataCustomerToShip();
                            this.ref = responseData.ref;
                            this.inputConfigShip.account = account;
                        }
                        loader.hide();
                    })
                    .catch(response => {
                        loader.hide();
                    })
        },
        toggleDialogConfigShipSEUR() {
            this.dialogConfigSEUR = !this.dialogConfigSEUR;
            this.ref = {};
            this.inputConfigShip = {
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                account: '897'
            };

            if (!this.dialogConfigSEUR) {
                this.$refs.form.resetValidation()
            }

            this.changeAddress = false
        },
        toggleDialogConfigShipRedur() {
            this.dialogConfigRedur = !this.dialogConfigRedur;
            this.ref = {};
            this.inputConfigShip = {
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                account: ''
            };

            if (!this.dialogConfigRedur) {
                this.$refs.form.resetValidation();
            }

            this.changeAddress = false;
        },
        getDataCustomerToShip() {
            let order = {
                clientCode: this.clientSelected.entity,
                idDoc: this.selectedOrders[0].id
            }

            Order.dispatch('getCustomerDetail', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.addresses = responseData.addresses
                            this.clientDetail = responseData.client
                            if (this.addresses.length === 1) {
                                this.inputConfigShip.address = this.addresses[0]
                            }
                            if (this.clientSelected.entity === 'VD' || this.clientSelected.entity === 'VDSHOP' || this.clientSelected.entity === 'VDES' || this.clientSelected.entity === 'VDSHOPES') {
                                this.clientSelected.name = this.clientDetail.Nome
                            }
                        }
                    })
                    .catch(response => {

                    })
        },
        createShippingSEUR() {
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.clientSelected,
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: this.selectedOrders,
                    courier: this.courier
                }

                let loader = this.$loading.show();

                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.toggleDialogConfigShipSEUR();
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                this.getOrdersWaitingShipping()

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }
                                loader.hide();
                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                loader.hide();
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                loader.hide();
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio! ' + responseData.data.message,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            loader.hide();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        createShippingRedur() {
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.clientSelected,
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: this.selectedOrders,
                    courier: this.courier
                }
                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.toggleDialogConfigShipRedur();
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                this.getOrdersWaitingShipping()

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        buttonChangeAddress() {
            this.changeAddress = !this.changeAddress
        },
        configShippingCTT() {
            this.courier = 'CTT';
            let order = {
                courier: 'CTT',
                clientCode: this.clientSelected.entity,
                clientName: this.clientSelected.name,
                salesman: this.selectedOrders[0].vendedor,
                orders: this.selectedOrders
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.toggleDialogConfigShipCTT();
                            this.getDataCustomerToShip();
                            this.ref = responseData.ref;
                            this.inputConfigShip.account = '';
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        configShippingREDUR() {
            this.courier = 'REDUR';
            let order = {
                courier: 'REDUR',
                clientCode: this.clientSelected.entity,
                clientName: this.clientSelected.name,
                salesman: this.selectedOrders[0].vendedor,
                orders: this.selectedOrders
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.toggleDialogConfigShipRedur();
                            this.getDataCustomerToShip();
                            this.ref = responseData.ref;
                            this.inputConfigShip.account = '';

                            // this.filters = {
                            //     entity: '',
                            //     name: '',
                            //     typeDoc: '',
                            //     numDoc: '',
                            //     week: 1
                            // }
                            //
                            // this.pagination.page = 1
                            //
                            // this.getOrdersWaitingShipping()
                            //
                            // Alert.dispatch('show', {
                            //     snackbar: true,
                            //     text: 'Envio criado com sucesso!',
                            //     type: 'success'
                            // })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        configShippingDELNEXT() {
            this.courier = 'DELNEXT';
            let order = {
                courier: 'DELNEXT',
                clientCode: this.clientSelected.entity,
                clientName: this.clientSelected.name,
                salesman: this.selectedOrders[0].vendedor,
                orders: this.selectedOrders
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.toggleDialogConfigShipDelnext();
                            this.getDataCustomerToShip();
                            this.ref = responseData.ref;
                            this.inputConfigShip.account = '';
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        configShippingNACEX() {
            this.courier = 'NACEX';
            let order = {
                courier: 'NACEX',
                clientCode: this.clientSelected.entity,
                clientName: this.clientSelected.name,
                salesman: this.selectedOrders[0].vendedor,
                orders: this.selectedOrders
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.toggleDialogConfigShipNacex();
                            this.getDataCustomerToShip();
                            this.ref = responseData.ref;
                            this.inputConfigShip.account = '';
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar envio!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        toggleDialogConfigShipNacex() {
            this.dialogConfigNacex = !this.dialogConfigNacex;
            this.ref = {};
            this.inputConfigShip = {
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                account: '',
                type: 'NOR'
            };

            if (!this.dialogConfigNacex) {
                this.$refs.form.resetValidation();
            }

            this.changeAddress = false;
        },
        toggleDialogConfigShipDelnext() {
            this.dialogConfigDelnext = !this.dialogConfigDelnext;
            this.ref = {};
            this.inputConfigShip = {
                price: '',
                address: '',
                payment: '',
                quantity: '1',
                weight: '',
                observation: '',
                account: '',
                type: '3',
            };

            if (!this.dialogConfigDelnext) {
                this.$refs.form.resetValidation();
            }

            this.changeAddress = false;
        },
        createShippingNacex() {
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.clientSelected,
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: this.selectedOrders,
                    courier: this.courier
                }

                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.toggleDialogConfigShipNacex();
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                this.getOrdersWaitingShipping()

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        createShippingDelnext() {
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.clientSelected,
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: this.selectedOrders,
                    courier: this.courier
                }

                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.toggleDialogConfigShipDelnext();
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                this.getOrdersWaitingShipping()

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        toggleDialogConfigShipCTT() {
            this.dialogConfigCTT = !this.dialogConfigCTT;
            this.ref = {};
            this.inputConfigShip = {
                price: '',
                address: '',
                payment: '',
                quantity: 0,
                weight: '',
                observation: '',
                account: '',
                type: 'EMSF010.01'
            };

            if (!this.dialogConfigCTT) {
                this.$refs.form.resetValidation();
            }

            this.changeAddress = false;
        },
        createShippingCTT() {
            if (this.$refs.form.validate()) {
                let shipping = {
                    client: this.clientSelected,
                    shipping: this.inputConfigShip,
                    trackingCode: this.ref,
                    selectedOrders: this.selectedOrders,
                    courier: this.courier
                }


                Order.dispatch('createShippingLabel', shipping)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.toggleDialogConfigShipCTT();
                                this.filters = {
                                    entity: '',
                                    name: '',
                                    typeDoc: '',
                                    numDoc: '',
                                    week: 1
                                }

                                this.pagination.page = 1

                                this.getOrdersWaitingShipping()

                                let alert = {
                                    snackbar: true,
                                    text: 'Envio criado com sucesso!',
                                    type: 'success'
                                }

                                Alert.dispatch('show', alert)
                            } else if (responseData.status === 'WARN') {
                                let alert = {
                                    snackbar: true,
                                    text: responseData.data,
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            } else {
                                let alert = {
                                    snackbar: true,
                                    text: 'Erro ao criar envio!',
                                    type: 'error'
                                }

                                Alert.dispatch('show', alert)
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            }
        },
        copyRefToClipboard() {
            let refCodeToCopy = document.querySelector('#ref-code')
            refCodeToCopy.setAttribute('type', 'text')
            refCodeToCopy.select()

            document.execCommand('copy')

            refCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
        printManifesto(courier) {

            let request = {
                url: '/api/staff/orders/manifest',
                data: {
                    courier: courier
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {

                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let alert = {
                                snackbar: true,
                                text: 'A imprimir manifesto ' + courier,
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)
                        } else {
                            let alert = {
                                snackbar: true,
                                text: responseData.data.data,
                                type: 'error',
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        this.loading = false
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao imprimir manifesto!',
                            type: 'error'
                        })
                    })
        },
        printLabelDeporvillage() {
            this.loadingRefDeporvillage = true;
            let request = {
                url: api.orderPrintLabelDeporvillage(),
                data: {
                    barcode: this.refDeporvillage
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'A imprimir etiqueta Deporvillage!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro a imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                        this.loadingRefDeporvillage = false;
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingRefDeporvillage = false;
                    })
        },
        downloadLabelRef() {
            this.loadingEan = true;
            let request = {
                url: '/api/staff/order/printLabelRef',
                data: {
                    ref: this.ean
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'A imprimir etiqueta do Artigo!',
                                type: 'success'
                            })
                        } else if (responseData.status === 'WAR') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Não foi encontrado nenhum artigo com este código EAN!',
                                type: 'warning'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro a imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                        this.loadingEan = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingEan = false;
                    })
        },
        printLabelEAN() {
            this.loadingEan = true;
            let request = {
                url: api.orderPrintLabelEAN(),
                data: {
                    ean: this.ean
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'A imprimir etiqueta do Artigo!',
                                type: 'success'
                            })
                        } else if (responseData.status === 'WAR') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Não foi encontrado nenhum artigo com este código EAN!',
                                type: 'warning'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro a imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                        this.loadingEan = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingEan = false;
                    })
            // this.ean = ''
        },
        CTTManual() {
            this.courier = 'CTT'
            let order = {
                courier: this.courier,
                clientCode: '',
                clientName: '',
                salesman: ''
            }
            this.clientSelected.entity = 'Envio manual'
            this.clientSelected.name = ''
            //this.clientSelected.tel = ''

            this.inputConfigShip.address = {
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            }

            this.inputConfigShip.account = 'NULL';

            this.selectedOrders = []

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogConfigCTT = true
                            this.changeAddress = true
                            this.ref = responseData.ref
                        }
                    })
                    .catch(response => {

                    })
        },
        SEURManual() {
            this.courier = 'SEUR2'
            let order = {
                courier: this.courier,
                clientCode: '',
                clientName: '',
                salesman: ''
            }
            this.clientSelected.entity = 'Envio manual'
            this.clientSelected.name = ''

            this.inputConfigShip.address = {
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            }

            this.inputConfigShip.account = this.seurCodes.normal;

            this.selectedOrders = []

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogConfigSEUR = true
                            this.changeAddress = true
                            this.ref = responseData.ref
                        }
                    })
                    .catch(response => {

                    })
        },

        unmarkOrderAsShipped(order) {
            this.loadingShippedToday = true
            let request = {
                url: api.orderUnmarkAsShipped(),
                data: {
                    orderShipped: order
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.filtersToday = {
                                courier: '',
                                account: '',
                                reference: '',
                                entity: '',
                                name: '',
                                docs: ''
                            }

                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Envio eliminado com sucesso!',
                                type: 'success'
                            })
                            this.getOrdersWaitingShipping();
                        } else if (responseData.status === 'WARN') {
                            this.loadingShippedToday = false
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.data.response,
                                type: 'error'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao eliminar envio!',
                                type: 'error'
                            })
                            this.loadingShippedToday = false
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingShippedToday = false
                    })
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        updateOrdersSent() {
            this.ordersSentToday = this.originOrdersSentToday

            if (this.filtersToday.courier !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.courier.toLowerCase().includes(this.filtersToday.courier.toLowerCase().trim()))
            }
            if (this.filtersToday.account !== '') {
                if (this.filtersToday.account === 'auto')
                    this.ordersSentToday = this.ordersSentToday.filter(order =>
                            order.account === null ||
                            order.account.includes(this.seurCodesAccounts.auto.pt) ||
                            order.account.includes(this.seurCodesAccounts.auto.es)
                    )
                else if (this.filtersToday.account === 'auto_sea')
                    this.ordersSentToday = this.ordersSentToday.filter(order =>
                            order.account !== null && (
                                    order.account.includes(this.seurCodesAccounts.auto_sea.pt) ||
                                    order.account.includes(this.seurCodesAccounts.auto_sea.es))
                    );
                else
                    this.ordersSentToday = this.ordersSentToday.filter(order => order.account !== null &&
                            order.account.toLowerCase().includes(this.filtersToday.account.toLowerCase()))
            }
            if (this.filtersToday.reference !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.reference.toUpperCase().includes(this.filtersToday.reference.toUpperCase().trim()))
            }
            if (this.filtersToday.entity !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.clientCode.toUpperCase().includes(this.filtersToday.entity.toUpperCase()))
            }
            if (this.filtersToday.name !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.clientName.toUpperCase().includes(this.filtersToday.name.toUpperCase()))
            }
            if (this.filtersToday.docs !== '') {
                this.ordersSentToday = this.ordersSentToday.filter(order => order.docs !== '' && order.docs.toUpperCase().includes(this.filtersToday.docs.toUpperCase()))
            }

            this.totalVolumes = 0
            this.ordersSentToday.forEach(item => {
                this.totalVolumes += parseInt(item.quantity)
            })

            if (isNaN(this.totalVolumes))
                this.totalVolumes = 0

        },
        updatePayment(option) {
            this.inputConfigShip.payment = option
        },
        downloadLabelEan() {
            this.loadingEan = true;
            let request = {
                url: '/api/staff/order/imageLabelEan/' + this.ean
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            var a = document.createElement("a");
                            a.href = "data:image/svg+xml;charset=utf-8," + responseData.img;
                            a.download = this.ean + "_barcode.svg";
                            a.click();
                        }

                        // var file = new Blob([response.data], {
                        //     type: 'application/pdf'
                        // });
                        // var fileURL = URL.createObjectURL(file);
                        // var a = document.createElement('a');
                        // a.href = fileURL;
                        // a.target = '_blank';
                        // a.download = this.ean + '.pdf';
                        // document.body.appendChild(a);
                        // a.click();
                        // document.body.removeChild(a);

                        // Alert.dispatch('show', {
                        //     snackbar: true,
                        //     text: 'A imprimir etiqueta do Artigo!',
                        //     type: 'success'
                        // })
                        // } else if (responseData.status === 'WAR') {
                        //     Alert.dispatch('show', {
                        //         snackbar: true,
                        //         text: 'Não foi encontrado nenhum artigo com este código EAN!',
                        //         type: 'warning'
                        //     })
                        // } else {
                        //     Alert.dispatch('show', {
                        //         snackbar: true,
                        //         text: 'Erro a download Código Barras!',
                        //         type: 'error'
                        //     })
                        // }
                        this.loadingEan = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingEan = false;
                    })
        },
        printLabel(shipped) {
            let request = {
                url: '/api/staff/shipping/printLabel',
                data: {
                    shipped: shipped
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Envio re-impresso com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao imprimir etiqueta!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        sendSeurEmail(shipping) {
            let request = {
                url: '/api/staff/shipping/' + shipping.reference + '/email',
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Email de desenfalgamento enviado com sucesso!',
                                type: 'success'
                            });
                            shipping.emailToDispatch = true;
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao enviar email de desenfalgamento!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        }
    },
    computed: {
        urlAPI() {
            return process.env.VUE_APP_API_URL
        },
        getTotalPages() {
            return Math.ceil(this.totalOrdersToSend / this.pagination.per_page)
        }
    }
}
</script>
