<style>
.fade-enter-active {
  transition: opacity .5s;
}

.fade-leave-active {
  transition: opacity .1s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.groupDetails:last-of-type{
  margin-bottom: 10px;
}

.groupDetails {
  padding: 6px;
}

.groupDetails:hover {
  background-color: #f1eeee;
  cursor: pointer;
  border-radius: 20px;
}

.removeConditionIcon {
  color: red;
  border-color: black;
  padding: 2px;
  border: 1px solid grey;
  border-radius: 20px
}

.removeConditionIcon:hover {
  cursor: pointer;
  color: #ff00006e;
  border-color: black;
  border: 2px solid grey;
  border-radius: 20px
}


</style>

<template>
  <v-container fluid>

    <!-- HEADER -->
    <v-row class="content-header">
      <v-col>
        <page-title title="Newsletters"></page-title>
      </v-col>
    </v-row>

    <!-- CONTENT -->
    <template v-if="loading">
      <v-row class="mt-12">
        <v-col class="text-center">
          <i class="fas fa-cog fa-spin fa-3x"></i>
        </v-col>
      </v-row>
    </template>

    <template v-if="!loading">
      <v-row>
        <v-col>
          <v-card>
            <v-row class="pa-4 justify-center">
              <v-col v-for="(list,index) in mailchimpLists" :key="list.id" class="ma-2" cols="3">
                <v-card>
                  <v-card-title class="text-justify mb-2" style="background-color:#0a99dd; color:white">
                    <h5>{{ list.name }}</h5>
                  </v-card-title>
                  <v-card-text class="pa-5 groupDetailsContainer">
                    <v-card-subtitle v-if="list.conditions.length == 0">
                      <h4>Não existem condições configuradas.</h4>
                    </v-card-subtitle>
                    <template class="conditionsGroup"
                              v-for="(conditionGroup,conditionGroupIndex, counter) in list.conditions">
                      <v-btn
                          class="mt-2"
                          v-if="selectedList==null"
                          @click="deleteGroup.group = conditionGroupIndex, deleteGroup.listId = list.id, dialogDeleteGroup = true"
                          style="width:auto; border-radius: 50%;padding: 1vw"
                          height="25"
                          color="red"
                          dark
                          absolute
                          right
                          fab

                      >X</v-btn>
                      <div class="groupDetails"
                           @click="addGroupCondition(list, conditionGroupIndex, isNaN(counter) ? 0 : counter)">
                        <v-card-subtitle>
                          <h4>
                            Grupo {{ isNaN(counter) ? 1 : counter + 1 }}
                          </h4>
                        </v-card-subtitle>
                        <template v-for="(condition,conditionIndex, conditionsCounter) in conditionGroup">
                          <tr>
                            {{ condition.frontTitle.TEXT }}<small>{{ condition.frontTitle.TEXTNOTE.length > 0 ? (" (" + condition.frontTitle.TEXTNOTE + ")") : "" }}</small>
                            : (
                            <template v-for="(value, valueIndex) in condition.data">
                          <span style="font-size:10px">
                            {{ value.requiredValueText }}<span v-if="valueIndex+1 != condition.data.length">,</span>
                          </span>
                            </template>
                            )
                          </tr>
                          <span v-if="conditionsCounter+1 != Object.keys(conditionGroup).length">+</span>
                        </template>
                        <!--                      <v-btn
                                                  class="mt-2"
                                                  v-if="selectedList==null"
                                                  @click="addGroupCondition(list, index)"
                                                  style="left:40%"
                                                  width="40"
                                                  height="40"
                                                  color="green"
                                                  dark

                                                  bottom
                                                  fab
                                              >
                                                <v-icon>mdi-plus</v-icon>
                                              </v-btn>-->
                      </div>
                    </template>
                    <!--                    <template v-for="(condition,conditionIndex) in list.conditions">
                                          <v-card-subtitle>
                                            <h4>{{ condition.frontTitle.TEXT }} <small
                                                v-if="condition.frontTitle.TEXTNOTE != ''">({{ condition.frontTitle.TEXTNOTE }})</small>
                                            </h4>
                                          </v-card-subtitle>
                                          <v-row class="mb-2 text-center">
                                            <v-col class="pb-0" cols="6" v-for="(conditionValue, conditionValueIndex) in condition.data"
                                                   :key="conditionValue.requiredValue">
                                              '{{ conditionValue.requiredValueText }}'
                                              <i class="removeConditionIcon pa-1 fas fa-trash-alt ml-4"
                                                 @click="deleteCondition(conditionValue.pivot)"></i>
                                            </v-col>
                                          </v-row>
                                          <v-divider
                                          >

                                          </v-divider>
                                        </template>-->
                    <v-btn
                        class="mt-2"
                        v-if="selectedList==null"
                        @click="addGroupCondition(list, -1, -1)"
                        style="left:28%; width:auto; border-radius: 20%"
                        height="40"
                        color="green"
                        dark
                        absolute
                        fab
                        bottom

                    >
                      Novo Grupo
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-dialog v-if='dialogGroupCondition' v-model="dialogGroupCondition" max-width="500"
              @click:outside="resetValues()">
      <v-card>
        <v-card-title>{{ selectedList.name }}
          ({{ selectedGroupCount == -1 ? "Novo grupo" : "Grupo " + (selectedGroupCount + 1) }})
        </v-card-title>
        <v-card-text class="pa-5">
          <v-card-subtitle v-if="typeof selectedList.conditions[selectedGroup] === 'undefined'">
            <h4>Não existem condições configuradas.</h4>
          </v-card-subtitle>
          <div v-if="typeof selectedList.conditions[selectedGroup] !== 'undefined'">
            <template v-for="(condition,conditionIndex) in selectedList.conditions[selectedGroup]">
              <v-card-subtitle>
                <h4>{{ condition.frontTitle.TEXT }} <small
                    v-if="condition.frontTitle.TEXTNOTE != ''">({{ condition.frontTitle.TEXTNOTE }})</small></h4>
              </v-card-subtitle>
              <v-row class="mb-1 mt-1 text-center" style="margin-right: 1vw;">
                <v-col class="pb-0 mb-7" cols="6" v-for="(conditionValue, conditionValueIndex) in condition.data"
                       :key="conditionValue.requiredValue">
                  <v-row class="pa-0">
                    <v-col class="col-11 pa-0">'{{ conditionValue.requiredValueText }}'</v-col>
                  <v-col class="col-1 pa-0"><i class="removeConditionIcon fas fa-trash-alt"
                     @click="deleteCondition(conditionValue.pivot)"></i></v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-divider
              >

              </v-divider>
            </template>
          </div>
          <transition name="fade">
            <v-row class="mr-4 mt-10" v-if="clickedNewCondition && selectableConditions.length > 0">
              <v-combobox
                  class="mb-4"
                  style="cursor:pointer"
                  solo
                  outlined
                  dense
                  v-model="selectedCondition"
                  hide-details
                  :items="selectableConditions"
                  item-value="VALUE"
                  item-text="TEXT"
                  label="Condição"
              />
              <v-combobox
                  class="mb-4"
                  v-if="selectedCondition"
                  :disabled="selectableConditionValues.length == 0"
                  style="cursor:pointer"
                  solo
                  outlined
                  :multiple="selectedCondition.multi"
                  dense
                  v-model="selectedConditionValue"
                  hide-details
                  :items="selectableConditionValues"
                  item-value="VALUE"
                  item-text="TEXT"
                  :label="selectableConditionValues.length > 0 ? 'Valor' : 'Todos os valores foram atribuídos.'"
              />

              <!--                        <v-text-field
                                        v-if="selectedCondition"
                                        label="Valor"
                                        v-model = "selectedConditionValue"
                                        solo
                                        outlined
                                        dense
                                      ></v-text-field>-->

            </v-row>

          </transition>
          <v-row>
            <v-col v-if="clickedNewCondition && selectableConditions.length > 0" style="text-align:center">
              <v-btn
                  class="ml-4"
                  color="grey"
                  dark
                  small
                  @click="resetValuesNew()"
              >
                Cancel
              </v-btn>
              <v-btn
                  :disabled="selectedConditionValue==null"
                  class="ml-2"
                  height="30"
                  color="green"
                  small
                  @click="addCondition()"
              >
                <v-icon
                    color="white"
                >
                  mdi-checkbox-marked-circle
                </v-icon>
              </v-btn>
            </v-col>
            <v-btn
                v-if="!clickedNewCondition && selectableConditions.length > 0"
                class="mt-2"
                @click="clickNew(selectedList),clickedNewCondition=true"
                style="left:40%;"
                width="40"
                height="40"
                color="green"
                dark
                bottom
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>

          </v-row>
        </v-card-text>
        <v-card-actions style="margin-top:3vh;">
          <v-btn tile small @click="resetValues()">Fechar</v-btn>
          <v-spacer></v-spacer>
<!--          <v-btn tile small color="success" @click="selectedList=null, dialogGroupCondition=false">Confirmar</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteGroup" persistent max-width="400">
      <v-card>
        <v-card-title>
          Eliminar grupo
        </v-card-title>
        <v-card-text>
          Confirma que pretende eliminar o grupo?
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dialogDeleteGroup = false">Cancelar</v-btn>
          <v-btn color="success" style="margin-left: auto" @click="deleteGroupHandle">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import i18n from '../../plugins/i18n'

export default {
  data: function () {
    return {
      clickedNewCondition: false,
      dialogGroupCondition: false,
      selectedList: null,
      loading: false,
      mailchimpLists: [],
      deleteGroup: {
                      'listId' : '',
                      'group' : ''
                    },
      selectedCondition: null,
      selectedConditionValue: null,
      availableConditions: [],
      selectedGroup: null,
      dialogDeleteGroup: false,
      selectedGroupCount: 0
    }
  },
  created() {
    document.title = 'Marketing - Newsletter';
    this.loading = true;
    this.getConditions();
    this.getMailchimpLists();
  },
  computed: {
    selectableConditions() {
      let selectedDb = null;

      this.availableConditions.forEach((availableCondition) => {
        if (!availableCondition.TEXT.includes(' (' + availableCondition.TEXTNOTE + ')'))
          availableCondition.TEXT += ` (${availableCondition.TEXTNOTE})`;
      });

      if (typeof this.selectedList.conditions[this.selectedGroup] === 'undefined')
        return this.availableConditions;

      let conditionIndexes = Object.keys(this.selectedList.conditions[this.selectedGroup]);

      if(conditionIndexes.length > 0){
        selectedDb = this.selectedList.conditions[this.selectedGroup][conditionIndexes[0]].frontTitle.TEXTNOTE;
      }

      return this.availableConditions.filter((availableCondition) => {
       // console.log(hasB2b);
      /*if (availableCondition.TEXTNOTE != '' && !availableCondition.TEXT.includes(' (' + availableCondition.TEXTNOTE + ')'))
          availableCondition.TEXT += ' (' + availableCondition.TEXTNOTE + ')';*/
        if (availableCondition.multi == true) {
          if(selectedDb == null)
            return true;
          else if(availableCondition.TEXTNOTE == selectedDb){
            return true;
          }
        }

        for (let i = 0; i < conditionIndexes.length; i++) {
          if (this.selectedList.conditions[this.selectedGroup][conditionIndexes[i]].frontTitle.VALUE == availableCondition.VALUE)
              //if (this.selectedList.conditions[conditionIndexes[i]].frontTitle.VALUE == availableCondition.VALUE)
            return false;
        }
        return !(selectedDb != null && availableCondition.TEXTNOTE != selectedDb);


      });
    },
    selectableConditionValues() {
      //If condition is not set return all options
      if (typeof this.selectedList.conditions[this.selectedGroup] === 'undefined')
        return this.selectedCondition.options;

      if(typeof this.selectedList.conditions[this.selectedGroup][this.selectedCondition.VALUE] === 'undefined')
        return this.selectedCondition.options;

      let counterFounds = 0;
      let counterConditionsSet = this.selectedList.conditions[this.selectedGroup][this.selectedCondition.VALUE].data.length;

      return this.selectedCondition.options.filter((option) => {
        //if all set conditions where already found. return the rest without testing
        if (counterFounds == counterConditionsSet)
          return true;

        let conditionIndexes = Object.keys(this.selectedList.conditions[this.selectedGroup]);

        for (let i = 0; i < counterConditionsSet; i++) {
          if (this.selectedList.conditions[this.selectedGroup][this.selectedCondition.VALUE].data[i].requiredValue == option.VALUE) {
            counterFounds++;
            return false;
          }
        }
        return true;
      });
    }
  },
  methods: {
    deleteCondition(condition) {
      let request = {
        url: '/api/staff/marketing/mailchimp/conditions/' + btoa(JSON.stringify(condition)),
      }
      ApiWS.dispatch('makeDelete', request)
          .then(response => {

            let responseData = response.data;
            if (responseData.status == 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Condição removida com sucesso!',
                type: 'success'
              })
              this.getMailchimpLists();
              this.resetValues();
            }
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    getConditions() {
      let request = {
        url: '/api/staff/marketing/mailchimp/conditions'
      }
      ApiWS.dispatch('makeGet', request)
          .then(response => {

            let responseData = response.data;

            if (responseData.status == 'OK') {
              //  this.mailchimpLists = responseData.data.lists;
              this.availableConditions = Object.values(responseData.data.conditions);
            }
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    resetValuesNew() {
      this.selectedConditionValue = null;
      this.selectedCondition = null;
      this.clickedNewCondition = false
    },
    resetValues() {
      this.selectedConditionValue = null;
      this.selectedCondition = null;
      this.selectedList = null;
      this.dialogGroupCondition = false;
      this.clickedNewCondition = false;
      this.dialogDeleteGroup = false;
    },
    addGroupCondition(list, selectedGroup, selectedGroupCount) {
      this.selectedGroupCount = parseInt(selectedGroupCount);
      this.selectedList = list;
      this.selectedGroup = selectedGroup;

      /*if (this.selectableConditions.length == 0) {
        this.selectedList = null;
        Alert.dispatch('show', {
          snackbar: true,
          text: 'Esta lista já têm todas as configuradas condições possiveis!',
          type: 'warning'
        })
      } else {*/
      this.dialogGroupCondition = true;
      // }
    },
    clickNew(list) {
      this.selectedList = list;
      if (this.selectableConditions.length == 0) {
        this.selectedList = null;
        Alert.dispatch('show', {
          snackbar: true,
          text: 'Esta lista já têm todas as configuradas condições possiveis!',
          type: 'warning'
        })
      }
    },
    deleteGroupHandle(){
      let request = {
        url: '/api/staff/marketing/mailchimp/lists/' + this.deleteGroup.listId + '/groups/' + this.deleteGroup.group,
      }
      ApiWS.dispatch('makeDelete', request)
          .then(response => {

            let responseData = response.data;
            if (responseData.status == 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Grupo removida com sucesso!',
                type: 'success'
              })
              this.getMailchimpLists();
              this.resetValues();
            }
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    addCondition() {
      let request = {
        url: '/api/staff/marketing/mailchimp',
        data: {
          'id': this.selectedList.id,
          'condition': this.selectedCondition.VALUE,
          'group': this.selectedGroup != -1 ? this.selectedGroup : (this.selectedList.conditions.length > 0 ? parseInt(Object.keys(this.selectedList.conditions).at(-1))+1 : 0),
          'conditionValue': this.selectedConditionValue
        }
      }
      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data;

            if (responseData.status == 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Condição associada com sucesso!',
                type: 'success'
              })
              this.getMailchimpLists();
              this.resetValues();
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao associar condição!',
                type: 'error'
              })
            }
            //  loader.hide();
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })


          })
    },
    getMailchimpLists() {
      // let loader = this.$loading.show();
      let request = {
        url: '/api/staff/marketing/mailchimp'
      }
      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status == 'OK') {
              this.mailchimpLists = responseData.data.lists;
            }
            this.loading = false;
            //  loader.hide();
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            this.loading = false;
            //   loader.hide();
          })
    },
    outsideDialog() {
      this.selectedList = null;
      this.dialogGroupCondition = false;
    },
  }
}
</script>
