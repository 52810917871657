<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        :title="$t('salesZones')+' '+this.month+' / Secção 1'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-row>
                    <v-col>
                        <v-btn small @click="download" color="success">Download</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="3">
                <v-select
                        v-model="filters.year"
                        label="Ano"
                        :items="years"
                        @change="initialize"
                        hide-details
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="$writePermission('reports/filters') && salesman.length > 0">
                <v-select
                        v-model="filters.salesman_id"
                        label="Vendedor"
                        :items="salesman"
                        item-text="Nome"
                        item-value="Vendedor"
                        @change="initialize"
                        hide-details
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="brands.length > 0">
                <v-select
                        v-model="filters.brand"
                        label="Marca"
                        :items="brands"
                        item-value="VALUE"
                        item-text="TEXT"
                        @change="initialize"
                        hide-details
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>Zona Comercial</th>
                            <th class="text-right" style="background-color: #d9edf7">Janeiro</th>
                            <th class="text-right">Fevereiro</th>
                            <th class="text-right" style="background-color: #d9edf7">Março</th>
                            <th class="text-right">Abril</th>
                            <th class="text-right" style="background-color: #d9edf7">Maio</th>
                            <th class="text-right">Junho</th>
                            <th class="text-right" style="background-color: #d9edf7">Julho</th>
                            <th class="text-right">Agosto</th>
                            <th class="text-right" style="background-color: #d9edf7">Setembro</th>
                            <th class="text-right">Outubro</th>
                            <th class="text-right" style="background-color: #d9edf7">Novembro</th>
                            <th class="text-right">Dezembro</th>
                            <th class="text-right" style="background-color: #d9edf7">Total</th>
                            <th class="text-right" style="background-color: #d9edf7">%</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loadingZonaComercial">
                            <td colspan="27">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="sale in salesZonaComercial">
                            <td>{{ sale.CDU_CC }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.JAN) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.FEB) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.MAR) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.APR) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.MAY) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.JUN) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.JUL) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.AUG) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.SEP) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.OCT) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.NOV) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.DEC) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.CURRENT_YEAR) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $formatPercentage(sale.CURRENT_YEARP) }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>Zona</th>
                            <th class="text-right" style="background-color: #d9edf7">Janeiro</th>
                            <th class="text-right">Fevereiro</th>
                            <th class="text-right" style="background-color: #d9edf7">Março</th>
                            <th class="text-right">Abril</th>
                            <th class="text-right" style="background-color: #d9edf7">Maio</th>
                            <th class="text-right">Junho</th>
                            <th class="text-right" style="background-color: #d9edf7">Julho</th>
                            <th class="text-right">Agosto</th>
                            <th class="text-right" style="background-color: #d9edf7">Setembro</th>
                            <th class="text-right">Outubro</th>
                            <th class="text-right" style="background-color: #d9edf7">Novembro</th>
                            <th class="text-right">Dezembro</th>
                            <th class="text-right" style="background-color: #d9edf7">Total</th>
                            <th class="text-right" style="background-color: #d9edf7">%</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="27">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="sale in sales">
                            <td>{{ sale.CDU_CC }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.JAN) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.FEB) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.MAR) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.APR) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.MAY) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.JUN) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.JUL) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.AUG) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.SEP) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.OCT) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.NOV) }}</td>
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.DEC) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.CURRENT_YEAR) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $formatPercentage(sale.CURRENT_YEARP) }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data() {
            return {
                filters: {
                    year: new Date().getFullYear(),
                    salesman_id: '',
                    brand: ''
                },
                salesman: [],
                sales: [],
                salesZonaComercial: [],
                loading: false,
                loadingZonaComercial: false,
                years: [
                    new Date().getFullYear(),
                    new Date().getFullYear() - 1,
                    new Date().getFullYear() - 2,
                    new Date().getFullYear() - 3
                ],
                brands: []
            }
        },
        created() {
            this.initialize()
        },
        methods: {
            initialize() {
                this.getSalesZonaComercial();
                this.loading = true
                this.sales = []
                Reports.dispatch('getSalesZone', this.filters)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.salesman = responseData.salesman
                            this.sales = responseData.sales
                            this.brands = responseData.brands
                            this.loading = false
                        } else {
                            this.loading = false
                        }
                    })
                    .catch(response => {
                        this.loading = false
                    })
            },
            getSalesZonaComercial() {
                this.loadingZonaComercial = true;
                this.salesZonaComercial = [];
                let request = {
                    url: '/api/staff/reports/sales/zoneComercial',
                    data: {
                        filters: this.filters
                    }
                }

                ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.salesZonaComercial = responseData.salesZonaComercial;
                    }
                    this.loadingZonaComercial = false;
                })
                .catch(() => {
                    this.loadingZonaComercial = false;
                })
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            year(last) {
                return new Date().getFullYear() - last
            },
            download() {
                let request = {
                    url: '/api/staff/reports/sales/zone/download',
                    data: {
                        filters: this.filters
                    }
                }
                ApiWS.dispatch('makeDownloadPost', request)
                        .then(response => {
                            var file = new Blob([response.data], {
                                type: 'application/vnd.ms-excel'
                            })
                            var fileURL = URL.createObjectURL(file)
                            var a = document.createElement('a')
                            a.href = fileURL
                            a.target = '_blank'
                            a.download = 'Report_vendas_conf.xlsx'
                            document.body.appendChild(a)
                            a.click()
                            document.body.removeChild(a)
                            console.log(response.data)
                        })
                        .catch((error) => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
            },
        },
        computed: {
            month() {
                return new Date().getMonth() + 1
            }
        }
    }
</script>
