<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="client.Nome"
                        :subtitle="client.Cliente"
                        :back="true"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <!--                <v-btn tile color="primary" class="mr-4" @click="syncProduct()" v-if="writePermission('products/product')">
                                    <i class="fas fa-sync mr-1"></i>Sincronizar
                                </v-btn>-->
            </v-col>
        </v-row>
        <!-- HEADER -->
        <!-- CONTENT -->
        <v-row v-if="Object.keys(client).length > 0">
            <v-col>
                <v-tabs background-color="transparent" slider-color="rgba(192, 13, 13, 1)" color="rgba(192, 13, 13, 1)">
                    <v-tab @click="activeTab = 1" :style="[activeTab === 1 ? {'background-color': 'white'} : '']">
                        Imformação Geral
                    </v-tab>
                    <v-tab @click="activeTab = 2" :style="[activeTab === 2 ? {'background-color': 'white'} : '']">
                        INFORMAÇÃO FINANCEIRA
                    </v-tab>
                    <v-tab @click="activeTab = 3" :style="[activeTab === 3 ? {'background-color': 'white'} : '']">
                        OBSERVAÇÕES
                    </v-tab>
                    <v-tab @click="activeTab = 4" :style="[activeTab === 4 ? {'background-color': 'white'} : '']">
                        Logins
                    </v-tab>
                </v-tabs>

                <v-row>
                    <v-col>
                        <v-card v-if="activeTab === 1">
                            <v-toolbar flat dense dark color="primary">
                                <v-toolbar-title class="text-uppercase">INFORMAÇÃO GERAL</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="btnEditDetail()"
                                       v-if="writePermission('clients/client_detail') && dialogEditDetail === false">
                                    <v-icon dark>mdi-pencil</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <div><strong>Nome Fiscal: </strong>{{ client.NomeFiscal }}</div>
                                        <div><strong>NIF: </strong>{{ client.NumContrib }}</div>
                                        <div>
                                            <strong>Acesso B2B: </strong>
                                            <v-chip v-if="client.CDU_ACESSO_B2B === '1'" class="white--text" color="red" small label>
                                                Fechado
                                            </v-chip>
                                            <v-chip v-if="client.CDU_ACESSO_B2B === '2'" class="white--text" color="green" small label>
                                                Aberto
                                            </v-chip>
                                            <v-chip v-if="client.CDU_ACESSO_B2B === '3'" class="white--text" color="orange" small label>
                                                Suspenso
                                            </v-chip>
                                            <v-chip v-if="client.CDU_ACESSO_B2B === '4'" class="white--text" color="orange" small label>
                                                Cancelado
                                            </v-chip>
                                        </div>
                                        <div>
                                            <strong>Perfil de Visibilidade: </strong>{{
                                                showProfileName(client.CDU_PROFILE)
                                            }}
                                        </div>
                                        <div><strong>Website: </strong>{{ client.EnderecoWeb }}
                                            <small><a v-if="client.EnderecoWeb.length > 0" :href=" 'https://' + client.EnderecoWeb"
                                                      target="_blank" class="ml-1"><i class="fas fa-fw fa-external-link-alt"></i></a></small>
                                        </div>
                                        <div><strong>Email: </strong>
                                            <i v-if="!client.CDU_LOGIN && client.CDU_ACESSO_B2B !== '1'"
                                               class="fas fa-exclamation-triangle red--text"></i>
                                            {{ client.CDU_LOGIN }}
                                        </div>
                                        <div><strong>Password B2B: </strong>{{ client.CDU_PASSWD }}</div>
                                    </v-col>
                                    <v-col>
                                        <div><strong>Telefone 1: </strong>{{ client.Fac_Tel }}</div>
                                        <div><strong>Telefone 2: </strong>{{ client.Telefone2 }}</div>
                                        <div><strong>Telefone 3: </strong>{{ client.Fac_Fax }}</div>
                                        <div><strong>Formação FOX: </strong>{{ showYesOrNo(client.CDU_FORMACAO) }}</div>
                                        <div>
                                            <strong>Newsletter {{ $t('stores') }}: </strong>{{
                                                showYesOrNo(client.CDU_MAIL_CLIENTES)
                                            }}
                                        </div>
                                        <div>
                                            <strong>Newsletter Formação: </strong>{{ showYesOrNo(client.CDU_MAIL_SERVICE) }}
                                        </div>
                                        <div>
                                            <strong>Tipo Cliente: </strong>{{ showTipoTerceiro(client.TipoTerceiro) }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <hr class="my-3">
                                <v-row>
                                    <v-col>
                                        <strong>Token:</strong>
                                        <template v-if="tokenAPI">
                                            {{ tokenAPI }}
                                        </template>
                                        <v-btn class="ml-2" small v-if="!tokenAPI && $writePermission('clients/client_tokenAPI')"
                                               color="error" @click="makeTokenAPI">
                                            Gerar Token
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card class="mt-4"  v-if="activeTab === 2">
                            <v-toolbar flat dense dark color="orange">
                                <v-toolbar-title class="text-uppercase">INFORMAÇÃO FINANCEIRA</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="btnEditDetail2()"
                                       v-if="writePermission('clients/client_detail') && dialogEditDetail2 === false">
                                    <v-icon dark>mdi-pencil</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <div>
                                            <strong>Crédito: </strong>
                                            <v-chip v-if="client.TipoCred === '2'" class="white--text" color="red" small label>
                                                Suspenso
                                            </v-chip>
                                            <span v-if="client.TipoCred !== '2'">Não suspenso</span>
                                        </div>
                                        <div><strong>Vendedor: </strong>{{ client.Vendedor }}</div>
                                        <div>
                                            <strong>Zona Comercial: </strong> {{
                                                commercialZonesDescriptions[client.CDU_ZonaComercial]
                                            }}
                                            <i v-if="client.CDU_ZonaComercial === 0" class="fas fa-exclamation-triangle red--text"></i>
                                        </div>
                                        <div>
                                            <strong>Limite Crédito: </strong> {{ $money(client.LimiteCred) }}
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div><strong>IBAN: </strong>{{ client.IBAN }}
                                            <i v-if="(client.CDU_MODPAG == 2 || client.CDU_MODPAG == 6 || client.CDU_MODPAG == 13) && ( client.IBAN === null || client.IBAN.length < 23 )"
                                               class="fas fa-exclamation-triangle red--text"></i>
                                        </div>
                                        <div><strong>SWIFT: </strong>{{ client.SWIFT }}
                                            <i v-if="(client.CDU_MODPAG == 2 || client.CDU_MODPAG == 6 || client.CDU_MODPAG == 13) && ( client.SWIFT === null ||client.SWIFT.length < 6)"
                                               class="fas fa-exclamation-triangle red--text"></i>
                                        </div>
                                        <div>
                                            <strong>{{ $t('payment_conditions') }}: </strong>{{
                                                showPaymentConditionName(client.CDU_CONDPAG)
                                            }}
                                        </div>
                                        <div>
                                            <strong>{{ $t('pendingAmount') }}: </strong> {{ $money(client.TotalDeb) }}
                                        </div>
                                    </v-col>
                                    <v-col>
                                        <div>
                                            <strong>{{ $t('payment_mode') }}: </strong>{{
                                                showModeOfPaymentName(client.CDU_MODPAG)
                                            }}
                                        </div>
                                        <div>
                                            <strong>{{ $t('payment_mode_extra') }}: </strong>{{
                                                showYesOrNo(client.CDU_MOD_PAG_EXTRA)
                                            }}
                                        </div>
                                        <div><strong>{{ $t('payment_mode_fsc') }}: </strong>{{ client.CDU_MODPAGFSC }}</div>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card class="mt-4"  v-if="activeTab === 3">
                            <v-toolbar flat dense dark color="teal">
                                <v-toolbar-title class="text-uppercase">OBSERVAÇÕES</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="btnEditNotes()"
                                       v-if="writePermission('clients/client_notes') && notesReadOnly === true">
                                    <v-icon dark>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon @click="updateNotes()"
                                       v-if="writePermission('clients/client_notes') && notesReadOnly === false">
                                    <v-icon dark>save</v-icon>
                                </v-btn>
                                <v-btn icon @click="closeNotes()"
                                       v-if="writePermission('clients/client_notes') && notesReadOnly === false">
                                    <v-icon dark>close</v-icon>
                                </v-btn>
                            </v-toolbar>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                    <span v-if="notesReadOnly && client.Notas !== null && client.Notas.length > 0"
                                          style="white-space: pre; color: black;">{{
                                            client.Notas
                                        }}</span>
                                        <span v-else-if="notesReadOnly">Sem observações</span>
                                        <v-textarea
                                                v-if="!notesReadOnly"
                                                v-model="client.Notas"
                                                outlined
                                                hide-details
                                                ref="inputNotes"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card v-if="activeTab === 4" style="max-width: 250px">

                            <v-toolbar flat dense dark color="red">
                                <v-toolbar-title class="text-uppercase">Logins B2B</v-toolbar-title>
                            </v-toolbar>

                            <v-card-text>
                                <v-simple-table dense>
                                    <tr>
                                        <td><i class="fas fa-lock"></i></td>
                                        <td>Hoje:</td>
                                        <td>
                                            <span v-if="loginToday > 0">{{ $t('yes') }}</span>
                                            <span v-else>{{ $t('no') }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><i class="fas fa-lock"></i></td>
                                        <td>7 dias:</td>
                                        <td>{{ loginLastWeek }} vezes</td>
                                    </tr>
                                    <tr>
                                        <td><i class="fas fa-lock"></i></td>
                                        <td>30 dias:</td>
                                        <td>{{ loginLastMonth }} vezes</td>
                                    </tr>
                                </v-simple-table>
                            </v-card-text>

                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row v-if="Object.keys(client).length > 0">
            <v-col>
                <v-tabs background-color="transparent" slider-color="rgba(192, 13, 13, 1)" color="rgba(192, 13, 13, 1)">
                    <v-tab @click="activeTab2 = 1" :style="[activeTab2 === 1 ? {'background-color': 'white'} : '']">
                        COSEC
                    </v-tab>
                    <v-tab @click="activeTab2 = 2" :style="[activeTab2 === 2 ? {'background-color': 'white'} : '']">
                        {{$t('address')}}
                    </v-tab>
                    <v-tab @click="activeTab2 = 3" :style="[activeTab2 === 3 ? {'background-color': 'white'} : '']">
                        HISTÓRICO DE CAMPANHAS
                    </v-tab>
                    <v-tab @click="activeTab2 = 4" :style="[activeTab2 === 4 ? {'background-color': 'white'} : '']">
                        ENCOMENDAS PENDENTES
                    </v-tab>
                </v-tabs>
            <!-- COSEC GARANTIAS -->
            <v-row v-if="$readPermission('clients/client_cosec_guaranty')">
                <v-col>
                    <v-card v-if="activeTab2 === 1">
                        <v-toolbar flat dense dark color="orange">
                            <v-toolbar-title class="text-uppercase">Cosec Garantias</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="addCosecGuaranty()" v-if="$writePermission('clients/client_cosec_guaranty')">
                                <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text>
                            <v-simple-table class="staff_table" v-if="guarantees.length > 0">
                                <thead>
                                <tr>
                                    <th>{{ $t('creationDate') }}</th>
                                    <th>{{ $t('startValidityDate') }}</th>
                                    <th>{{ $t('endValidityDate') }}</th>
                                    <th>{{ $t('requestedAmount') }}</th>
                                    <th>{{ $t('grantedAmount') }}</th>
                                    <th>Tipo</th>
                                    <th>Estado</th>
                                    <th>{{ $t('observations') }}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="guaranty in guarantees">
                                    <td>{{ guaranty.creation_date | formatDate }}</td>
                                    <td>{{ guaranty.start_validity_date | formatDate }}</td>
                                    <td>{{ guaranty.end_validity_date | formatDate }}</td>
                                    <td>{{ $money(guaranty.requested_amount) }}</td>
                                    <td>{{ $money(guaranty.granted_amount) }}</td>
                                    <td>{{ guaranty.type_desc }}</td>
                                    <td>{{ guaranty.status_code_desc }}</td>
                                    <td>{{ guaranty.observations }}</td>
                                    <td>
                                        <v-btn icon @click="pdfCosecGuaranty(guaranty.guaranty_id)">
                                            <v-icon>picture_as_pdf</v-icon>
                                        </v-btn>
                                    </td>
                                    <td class="text-right">
                                        <v-btn icon @click="deleteCosecGuaranty(guaranty.guaranty_id)" color="error"
                                               v-if="guaranty.status_code !== 'AP'">
                                            <v-icon dark>clear</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                            <p v-if="guarantees.length === 0">Sem Garantias</p>
                        </v-card-text>
                    </v-card>
                    <v-card v-if="activeTab2 === 2">
                        <v-toolbar flat dense dark color="teal">
                            <v-toolbar-title class="text-uppercase">{{ $t('address') }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="addAddress()" v-if="writePermission('clients/client_address')">
                                <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                        </v-toolbar>

                        <v-card-text>
                            <v-simple-table class="staff_table">
                                <thead>
                                <tr>
                                    <th class="text-center" style="width: 100px">{{ $t('idAddress') }}</th>
                                    <th><span class="pl-3">{{ $t('dataAddress') }}</span></th>
                                    <th class="text-center">{{ $t('options') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="address in addresses">
                                    <td class="text-center">
                                        <i v-if="!address.Country || !address.Zone" class="fas fa-exclamation-triangle fa-2x red--text"></i>
                                        <div v-if="address.AlternativeAddress != 'SEDE'">
                                            {{ address.AlternativeAddress }}
                                        </div>
                                        <div v-if="address.AlternativeAddress == 'SEDE'">
                                            {{ $t('principalAddress') }}
                                        </div>
                                    </td>
                                    <td>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-subtitle v-if="address.Address">
                                                    {{ address.Address }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="address.Address2">
                                                    {{ address.Address2 }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    {{ address.Locality }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    {{ address.Cp }} {{ address.CpLocality }} {{ address.Country }}
                                                    <v-chip label small v-if="!address.Country" color="error">PAÍS
                                                    </v-chip>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="address.Zone">
                                                    <v-chip small label color="secondary">{{ address.ZoneDescription }}
                                                    </v-chip>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-else>
                                                    <v-chip label small color="error">ZONA</v-chip>
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="address.CDU_LATITUDE || address.CDU_LONGITUDE">
                                                    {{ address.CDU_LATITUDE }}, {{ address.CDU_LONGITUDE }}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle v-else>
                                                    <v-chip label small color="error">LATITUDE</v-chip>
                                                    ,
                                                    <v-chip label small color="error">LONGITUDE</v-chip>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </td>
                                    <td width="1" class="text-center">
                                        <v-btn tile small @click="btnEditAddress(address)" v-if="writePermission('clients/client_address')">
                                            Editar
                                        </v-btn>
                                        <v-btn tile small color="error"
                                               v-if="writePermission('clients/client_address') && address.AlternativeAddress != 'SEDE'"
                                               class="mt-2" @click="removeAddress(address)">
                                            {{ $t('remove') }}
                                        </v-btn>
                                        <v-btn tile small color="warning" class="mt-2" v-if="$writePermission('clients/client_shipping')"
                                               @click="makeShipping(address)">
                                            Recolha
                                        </v-btn>
                                        <v-img style="margin: auto; cursor: pointer" class="mt-2" height="25" width="25" aspect-ratio="1"
                                               :src="require('../../assets/img/maps_icon.png')" @click="openMaps(address)"></v-img>
                                        <!--                                        <span v-if="address.CDU_LATITUDE === null && address.CDU_LONGITUDE === null">{{ $t('noLocation') }}</span>-->
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                <!-- HISTÓRICO DE CAMPANHAS -->
                    <v-card v-if="activeTab2 === 3">
                        <v-toolbar flat dense dark color="amber darken-2">
                            <v-toolbar-title class="text-uppercase">Histórico De Campanhas</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-simple-table class="staff_table" v-if="campaignHistoric.length > 0">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>{{ $t('name') }}</th>
                                    <th>{{ $t('preOrder') }}</th>
                                    <th>Nivel</th>
                                    <th>Notas</th>
                                    <th>Criado</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="campaign in campaignHistoric">
                                    <td>{{ campaign.campaign_id }}</td>
                                    <td>{{ campaign.name }}</td>
                                    <td>
                                        <span v-if="campaign.preorder===1">{{ campaign.preorder }}</span>
                                        <span v-else>0</span>
                                    </td>
                                    <td>{{ campaign.nivel }}</td>
                                    <td>{{ campaign.cupon }}</td>
                                    <td>{{ campaign.created_at }}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                            <p v-if="campaignHistoric.length === 0">Sem histórico de campanhas</p>
                        </v-card-text>
                    </v-card>
            <!-- ENCOMENDAS PENDENTES -->
                    <v-card v-if="activeTab2 === 4">
                        <v-toolbar flat dense dark color="blue-grey darken-1">
                            <v-toolbar-title class="text-uppercase">Encomendas Pendentes</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-simple-table class="staff_table">
                                <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Entrega</th>
                                    <th>Artigo</th>
                                    <th>Descricao</th>
                                    <th>Cod. Barras</th>
                                    <th>Marca</th>
                                    <th class="text-center">QTD</th>
                                    <th class="text-right">PrecoLiquido</th>
                                    <th class="text-center">Desconto</th>
                                    <th class="text-center">StockA1</th>
                                    <th class="text-center">NumDoc</th>
                                    <th class="text-right">Origem</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="order in orders">
                                    <td>{{ order.DataEncomenda }}</td>
                                    <td>{{ order.DataEntrega }}</td>
                                    <td>{{ order.Artigo }}</td>
                                    <td>{{ order.Descricao }}</td>
                                    <td>{{ order.CodBarras }}</td>
                                    <td>{{ order.Marca }}</td>
                                    <td class="text-center">{{ order.QTD }}</td>
                                    <td class="text-right">{{ order.PrecoLiquido }}€</td>
                                    <td class="text-center">{{ order.Desconto }}%</td>
                                    <td class="text-center">
                                        <v-chip small :color="order.Stock > 0 ? 'success' : 'error'">{{
                                                parseFloat(order.StockA1)
                                            }}
                                        </v-chip>
                                    </td>
                                    <td class="text-center">{{ order.NumDoc }}</td>
                                    <td class="text-right">{{ order.Origem }}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                            <v-simple-table class="staff_table mt-2" style="border-top: 1px solid ">
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td class="text-right" style="width: 1px;"><strong>SUBTOTAL:</strong></td>
                                    <td class="text-right" style="width: 100px;">{{
                                            parseFloat(footerOrders.subTotal).toFixed(2)
                                        }}€
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-right">Pronto Pagamento:</td>
                                    <td>
                                        <v-text-field
                                                v-model="footerOrders.pp"
                                                outlined
                                                hide-details
                                                class="staff_text-field_small"
                                                @keyup="updateFooterOrders"
                                                style="width: 70px; margin-left: auto"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-right">{{ parseFloat(footerOrders.totalPP).toFixed(2) }}€</td>
                                </tr>
                                <tr>
                                    <td class="text-right">Taxa IVA:</td>
                                    <td>
                                        <v-text-field
                                                v-model="footerOrders.iva"
                                                outlined
                                                hide-details
                                                class="staff_text-field_small"
                                                @keyup="updateFooterOrders"
                                                style="width: 70px; margin-left: auto"
                                        ></v-text-field>
                                    </td>
                                    <td class="text-right">{{ parseFloat(footerOrders.totalIva).toFixed(2) }}€</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td class="text-right"><strong>TOTAL:</strong></td>
                                    <td class="text-right">{{ parseFloat(footerOrders.final).toFixed(2) }}€</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <!-- RELATÓRIOS APLICAÇÃO -->
            </v-col>
        </v-row>

        <v-row v-if="Object.keys(client).length > 0">
            <v-col>
                <v-tabs background-color="transparent" slider-color="rgba(192, 13, 13, 1)" color="rgba(192, 13, 13, 1)">
                    <v-tab @click="activeTab3 = 1" :style="[activeTab3 === 1 ? {'background-color': 'white'} : '']">
                        {{$t('reportsApp')}}
                    </v-tab>
                    <v-tab @click="activeTab3 = 2" :style="[activeTab3 === 2 ? {'background-color': 'white'} : '']">
                        CHAMADAS DO CLIENTE
                    </v-tab>
                    <v-tab @click="activeTab3 = 3" :style="[activeTab3 === 3 ? {'background-color': 'white'} : '']">
                        {{$t('sales')}}
                    </v-tab>
                    <v-tab @click="activeTab3 = 4" :style="[activeTab3 === 4 ? {'background-color': 'white'} : '']">
                        DEBITOS DIRETOS
                    </v-tab>
                    <v-tab @click="activeTab3 = 5" :style="[activeTab3 === 5 ? {'background-color': 'white'} : '']">
                        FATURAS
                    </v-tab>
                </v-tabs>

                <v-row>
                    <v-col>
                        <v-card v-if="activeTab3 === 1 && b2bCRMAction.length > 0 && $readPermission('clients/client_reportsApp')">
                            <v-toolbar flat dense dark color="red">
                                <v-toolbar-title class="text-uppercase">{{ $t('appReports') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-simple-table class="staff_table">
                                    <thead>
                                    <tr>
                                        <th>{{ $t('date') }} Visita</th>
                                        <th>{{ $t('created') }}</th>
                                        <th>Tipo Visita</th>
                                        <th>Vendedor</th>
                                        <th>{{ $t('sales') }} {{ $t('made') }}</th>
                                        <th>Cliente {{ $t('closed') }}</th>
                                        <th>Problemas {{ $t('financial') }}</th>
                                        <th>Notas</th>
                                        <th>{{ $t('options') }}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="report in b2bCRMAction">
                                        <td style="white-space: nowrap">{{ report.visited_at.split(' ')[0] }}</td>
                                        <td>{{ report.created_at }}</td>
                                        <td style="white-space: nowrap">{{ report.description }}</td>
                                        <td>{{ report.salesman_id }}</td>
                                        <td>
                                        <span style="color: green" v-if="report.saleaccomplished == 1">{{
                                                $t('yes')
                                            }}</span>
                                            <span v-if="report.saleaccomplished == 0">{{ $t('no') }}</span>
                                        </td>
                                        <td>
                                            <span style="color: red" v-if="report.clientclosed == 1">{{ $t('yes') }}</span>
                                            <span v-if="report.clientclosed == 0">{{ $t('no') }}</span>
                                        </td>
                                        <td>
                                        <span style="color: red" v-if="report.clientfinancialproblems == 1">{{
                                                $t('yes')
                                            }}</span>
                                            <span v-if="report.clientfinancialproblems == 0">{{ $t('no') }}</span>
                                        </td>
                                        <td>{{ report.notes }}</td>
                                        <td>
                                            <v-btn small color="secondary" :to="'/reports/reportsApp/'+report.id">{{
                                                    $t('details')
                                                }}
                                            </v-btn>
                                        </td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                        <v-card v-if="calls.length > 0 && activeTab3 === 2">
                            <v-toolbar flat dense dark color="teal darken-1">
                                <v-toolbar-title class="text-uppercase">Chamadas do Cliente</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-simple-table class="staff_table">
                                    <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Tipo Chamada</th>
                                        <th>Descrição</th>
                                        <th>Notas</th>
                                        <th>Utilizador</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="call in calls">
                                        <td>{{ call.calldate }}</td>
                                        <td v-if="language==='pt'">{{ call.name_pt }}</td>
                                        <td v-if="language==='es'">{{ call.name_es }}</td>
                                        <td v-if="language==='en'">{{ call.name_en }}</td>
                                        <td v-if="language==='pt'">{{ call.description_pt }}</td>
                                        <td v-if="language==='es'">{{ call.description_es }}</td>
                                        <td v-if="language==='en'">{{ call.description_en }}</td>
                                        <td>{{ call.notes }}</td>
                                        <td>{{ call.name_user }}</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-card-text>
                        </v-card>
                        <v-card v-if="activeTab3 === 3">
                            <v-toolbar flat dense dark color="blue-grey darken-1">
                                <v-toolbar-title class="text-uppercase">Vendas</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text>
                                <v-simple-table v-if="sales.length > 0" class="staff_table" height="550px" fixed-header>
                                    <thead>
                                    <tr>
                                        <th>{{ $t('date') }}</th>
                                        <th class="text-center">Documento</th>
                                        <th class="text-center">Numero</th>
                                        <th class="text-right">Valor</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="sale in sales">
                                        <td>{{ sale.DataDoc }}</td>
                                        <td class="text-center">{{ sale.TipoDoc }}</td>
                                        <td class="text-center">
                                            {{ sale.NumDocint }}
                                            <i class="far fa-file-pdf" style="color: red; cursor: pointer"
                                               @click="downloadPDF(sale.Serie, sale.TipoDoc, sale.NumDocint)"></i>
                                        </td>
                                        <td class="text-right">{{ $money(sale.ValorTotal) }}</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                                <span v-else>
                                {{ $t('no') }} existem vendas
                            </span>
                            </v-card-text>
                        </v-card>
                        <v-card v-if="activeTab3 === 4">
                            <v-toolbar flat dense dark color="primary">
                                <v-toolbar-title class="text-uppercase">debitos diretos</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-card-text>
                                <v-simple-table class="staff_table" v-if="directDebits.length > 0">
                                    <thead>
                                    <tr>
                                        <th>Ficheiro</th>
                                        <th>Data</th>
                                        <th>TipoDoc</th>
                                        <th>NumDoc</th>
                                        <th>Valor Cobrança</th>
                                        <th>Doc.Montante</th>
                                        <th>Vendedor</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="debit in directDebits">
                                        <td>{{ debit.Ficheiro }}</td>
                                        <td style="white-space: nowrap">{{ debit.Data }}</td>
                                        <td>{{ debit.Tipodoc }}</td>
                                        <td>{{ debit.NumDoc }}</td>
                                        <td>{{ debit.amount_to_pay }}</td>
                                        <td>{{ debit.document_amount }}</td>
                                        <td>{{ debit.Vendedor }}</td>
                                        <td>{{ debit.Status }}</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                                <span v-else>
                                {{ $t('no') }} existem débitos diretos
                            </span>
                            </v-card-text>
                        </v-card>
                        <v-card v-if="activeTab3 === 5">
                            <v-toolbar flat dense dark color="red">
                                <v-toolbar-title class="text-uppercase">Faturas</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <v-simple-table class="staff_table">
                                    <thead>
                                    <tr>
                                        <th style="width: 100px; cursor: pointer;" @click="orderList('Artigo')" class="text-center">Artigo
                                            <i v-if="selectedHeaderInvoices === 'Artigo'" class="fas"
                                               :class="[headersInvoices['Artigo'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
                                        </th>
                                        <th style="width: 75px; cursor: pointer;" @click="orderList('NumDoc')" class="text-center">NumDoc
                                            <i v-if="selectedHeaderInvoices === 'NumDoc'" class="fas"
                                               :class="[headersInvoices['NumDoc'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                                        <th style="width: 75px; cursor: pointer;" @click="orderList('Serie')" class="text-center">Serie
                                            <i v-if="selectedHeaderInvoices === 'Serie'" class="fas"
                                               :class="[headersInvoices['Serie'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                                        <th style="width: 75px; cursor: pointer;" @click="orderList('TipoDoc')" class="text-center">TipoDoc
                                            <i v-if="selectedHeaderInvoices === 'TipoDoc'" class="fas"
                                               :class="[headersInvoices['TipoDoc'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i></th>
                                    </tr>
                                    <tr class="filters_row">
                                        <th>
                                            <v-text-field outlined hide-details v-model="filtersInvoices.Artigo" @change="updateTable()"></v-text-field>
                                        </th>
                                        <th>
                                            <v-text-field outlined hide-details v-model="filtersInvoices.NumDoc" @change="updateTable()"></v-text-field>
                                        </th>
                                        <th>
                                            <v-text-field outlined hide-details v-model="filtersInvoices.Serie" @change="updateTable()"></v-text-field>
                                        </th>
                                        <th>
                                            <v-combobox
                                                    outlined
                                                    hide-details
                                                    :items="docTypesList"
                                                    variant="solo"
                                                    v-model="filtersInvoices.TipoDoc"
                                                    @change="updateTable()"
                                            ></v-combobox>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="line in pagedLines">
                                        <td class="text-center">{{line.Artigo}}</td>
                                        <td class="text-center">{{ line.NumDoc }} <i class="far fa-file-pdf" style="color: red; cursor: pointer"
                                                                                     @click="downloadPDF(line.Serie, line.TipoDoc, line.NumDoc)"></i> </td>
                                        <td class="text-center">{{ line.Serie }}</td>
                                        <td class="text-center">{{line.TipoDoc}}</td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                                <v-row>
                                    <v-col cols="4">
                                        <v-select
                                                v-model="pagination.rowsPerPage"
                                                :items="filtersPerPage"
                                                dense
                                                outlined
                                                hide-details
                                                item-text="TEXT"
                                                item-value="VALUE"
                                                style="float: left; width: 100px;"
                                                @change="pagination.page = 1"
                                        ></v-select>
                                    </v-col>
                                    <v-col class="text-right">
                                        <v-pagination
                                                v-model="pagination.page"
                                                :length="getTotalPages"
                                                :total-visible="7"
                                                style="justify-content: flex-end;"
                                        ></v-pagination>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <!-- DESCONTOS NAS MARCAS -->
        <v-row>
            <v-col>
                <v-card >
                    <v-toolbar flat dense dark color="red" class="mb-4">
                        <v-toolbar-title class="text-uppercase">{{ $t('discountsBrands') }}</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="pt-0">
                        <v-row>
                            <v-col :key="index" v-for="(chunk, index) in discounts" cols="3" class="py-0">
                                <div v-for="(value, brand) in chunk" class="py-1">
                                    <i class="fas fa-random" v-if="value.crossSelling == '1'"></i><span
                                        :style="[value.crossSelling == '1' ? {'color': 'green'} : '']"> {{
                                        brand
                                    }}:</span>
                                    <template v-if="writePermission('clients/client_discounts')">
                                        <br>
                                        <v-btn tile small :key="index" v-for="(level, index) in value.level"
                                               :color="colorButtonDiscount(index, value.currentLevel, value.maxLevel)"
                                               @click="updateDiscount(brand, index, value.group)">
                                            {{ level }}
                                        </v-btn>
                                    </template>
                                    <template v-else>
                                        <span> {{ value.level[value.currentLevel] }}</span>
                                    </template>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                Link Download Artigos CSV:
                                <a :href="'https://b2b.bicimax.com/products/download/csv?token='+linkToken"
                                   target="_blank">https://b2b.bicimax.com/products/download/csv?token={{
                                        linkToken
                                    }}</a>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                Link Download Artigos com EAN CSV:
                                <a :href="'https://b2b.bicimax.com/productsEAN13/download/csv?token='+linkToken"
                                   target="_blank">https://b2b.bicimax.com/productsEAN13/download/csv?token={{
                                        linkToken
                                    }}</a>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                Link Stock Artigos CSV:
                                <a :href="'https://b2b.bicimax.com/products/stock/csv?token='+linkToken"
                                   target="_blank">https://b2b.bicimax.com/products/stock/csv?token={{
                                        linkToken
                                    }}</a>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="pt-0">
                                Link Preços Artigos CSV:
                                <a :href="'https://b2b.bicimax.com/products-price/download/csv?token='+linkToken"
                                   target="_blank">https://b2b.bicimax.com/products-price/download/csv?token={{
                                        linkToken
                                    }}</a>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <!-- DESCONTOS NAS MARCAS -->
        <!-- DIALOGS -->
        <v-row justify="center">

            <!-- EDIT DETAIL 1 -->
            <v-dialog v-model="dialogEditDetail" persistent max-width="700">
                <v-card>
                    <v-toolbar flat dense dark color="primary" class="mb-4">
                        <v-toolbar-title class="text-uppercase">INFORMAÇÃO GERAL</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogEditDetail = false">
                            <v-icon dark>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="detail">
                            <v-row>
                                <v-col>
                                    <v-select
                                            v-model="editDetail.CDU_ACESSO_B2B"
                                            dense
                                            outlined
                                            label="Acesso ao B2B"
                                            :items="filtersB2BAccess"
                                            item-text="text"
                                            item-value="value"
                                            :disabled="!writePermission('clients/client_detail')"
                                            hide-details
                                    ></v-select>
                                    <v-select
                                            v-model="editDetail.CDU_PROFILE"
                                            :items="profiles"
                                            item-text="text"
                                            item-value="value"
                                            dense
                                            outlined
                                            label="Perfil de Visibilidade"
                                            :disabled="!writePermission('clients/client_restricted')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                    <v-text-field
                                            v-model="editDetail.EnderecoWeb"
                                            dense
                                            outlined
                                            label="Website"
                                            :disabled="!writePermission('clients/client_detail')"
                                            hide-details
                                            class="mt-4"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editDetail.CDU_LOGIN"
                                            dense
                                            outlined
                                            label="Email"
                                            :disabled="!writePermission('clients/client_restricted')"
                                            hide-details
                                            class="mt-4"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editDetail.CDU_PASSWD"
                                            dense
                                            outlined
                                            label="Password B2B"
                                            :disabled="!writePermission('clients/client_restricted')"
                                            hide-details
                                            class="mt-4"
                                    ></v-text-field>
                                    <v-select
                                            v-model="editDetail.TipoTerceiro"
                                            :items="tipoTerceiros"
                                            item-value="value"
                                            item-text="text"
                                            dense
                                            outlined
                                            label="Tipo Terceiro"
                                            :disabled="!writePermission('clients/client_restricted')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="editDetail.Fac_Tel"
                                            dense
                                            outlined
                                            label="Telefone 1"
                                            :disabled="!writePermission('clients/client_detail')"
                                            hide-details
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editDetail.Telefone2"
                                            dense
                                            outlined
                                            label="Telefone 2"
                                            :disabled="!writePermission('clients/client_detail')"
                                            hide-details
                                            class="mt-4"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editDetail.Fac_Fax"
                                            dense
                                            outlined
                                            label="Telefone 3"
                                            :disabled="!writePermission('clients/client_detail')"
                                            hide-details
                                            class="mt-4"
                                    ></v-text-field>
                                    <v-select
                                            v-model="editDetail.CDU_FORMACAO"
                                            :items="filtersYesNo"
                                            item-text="text"
                                            item-value="value"
                                            dense
                                            outlined
                                            label="Formação FOX"
                                            :disabled="!writePermission('clients/client_restricted')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                    <v-select
                                            v-model="editDetail.CDU_MAIL_CLIENTES"
                                            :items="filtersYesNo"
                                            item-value="value"
                                            item-text="text"
                                            dense
                                            outlined
                                            :label="'Listas Newsletter '+$t('stores')"
                                            :disabled="!writePermission('clients/client_newsletter')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                    <v-select
                                            v-model="editDetail.CDU_MAIL_SERVICE"
                                            :items="filtersYesNo"
                                            item-value="value"
                                            item-text="text"
                                            dense
                                            outlined
                                            label="Listas Newsletter formação técnica"
                                            :disabled="!writePermission('clients/client_newsletter')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogEditDetail = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="updateClient()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- EDIT DETAIL 2 -->
            <v-dialog v-model="dialogEditDetail2" persistent max-width="600">
                <v-card>
                    <v-toolbar flat dense dark color="orange" class="mb-4">
                        <v-toolbar-title class="text-uppercase">INFORMAÇÃO FINANCEIRA</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogEditDetail2 = false">
                            <v-icon dark>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="detail2">
                            <v-row>
                                <v-col>
                                    <v-select
                                            v-model="editDetail.Vendedor"
                                            :items="sellers"
                                            item-text="Nome"
                                            item-value="Vendedor"
                                            dense
                                            outlined
                                            label="Vendedor"
                                            :disabled="!([1, 2, 14].includes(profile)) || !writePermission('clients/client_restricted')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                    <v-text-field
                                            v-model="editDetail.IBAN"
                                            dense
                                            outlined
                                            label="IBAN"
                                            :disabled="!writePermission('clients/client_bank')"
                                            hide-details
                                            class="mt-4"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editDetail.SWIFT"
                                            dense
                                            outlined
                                            label="SWIFT"
                                            :disabled="!writePermission('clients/client_bank')"
                                            hide-details
                                            class="mt-4"
                                    ></v-text-field>
                                    <v-select
                                            v-model="editDetail.CDU_ZonaComercial"
                                            dense
                                            outlined
                                            label="Zona Comercial"
                                            :items="commercialZones"
                                            item-text="description"
                                            item-value="id"
                                            :disabled="!writePermission('clients/client_restricted')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                </v-col>
                                <v-col>
                                    <v-select
                                            v-model="editDetail.CDU_MODPAGFSC"
                                            :items="modesOfPaymentFSC"
                                            item-text="text"
                                            item-value="value"
                                            dense
                                            outlined
                                            :label="$t('payment_mode_fsc')"
                                            :disabled="!writePermission('clients/client_condPayment')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                    <v-select
                                            v-model="editDetail.CDU_CONDPAG"
                                            :items="paymentConditions"
                                            item-value="value"
                                            item-text="text"
                                            dense
                                            outlined
                                            :label="$t('payment_conditions')"
                                            :disabled="!writePermission('clients/client_condPayment')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                    <v-select
                                            v-model="editDetail.CDU_MODPAG"
                                            :items="modesOfPayment"
                                            item-text="text"
                                            item-value="value"
                                            dense
                                            outlined
                                            :label="$t('payment_mode')"
                                            :disabled="!writePermission('clients/client_condPayment')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                    <v-select
                                            v-model="editDetail.CDU_MOD_PAG_EXTRA"
                                            :items="filtersYesNo"
                                            item-value="value"
                                            item-text="text"
                                            dense
                                            outlined
                                            :label="$t('payment_mode_extra')"
                                            :disabled="!([1, 2].includes(profile)) || !writePermission('clients/client_condPayment')"
                                            hide-details
                                            class="mt-4"
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogEditDetail2 = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="updateClient()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- EDIT ADDRESS -->
            <v-dialog v-model="dialogEditAddress" persistent max-width="600">
                <v-card>
                    <v-toolbar flat dense dark color="teal">
                        <v-toolbar-title class="text-uppercase">{{ titleAddress }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogEditAddress = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-form class="mt-4" ref="address">
                            <v-row>
                                <v-col class="pa-1" cols="4">
                                    <v-text-field
                                            v-model="editAddress.AlternativeAddress"
                                            outlined
                                            dense
                                            label="ID"
                                            :readonly="editAddress.AlternativeAddress === 'SEDE'"
                                            :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pa-1" cols="12">
                                    <v-text-field
                                            v-model="editAddress.Address"
                                            dense
                                            outlined
                                            :label="$t('address1')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pa-1" cols="12">
                                    <v-text-field
                                            v-model="editAddress.Address2"
                                            dense
                                            outlined
                                            :label="$t('address2')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pa-1" cols="8">
                                    <v-text-field
                                            v-model="editAddress.Locality"
                                            dense
                                            outlined
                                            :label="$t('locality')"
                                            :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pa-1" cols="5">
                                    <v-text-field
                                            v-model="editAddress.Cp"
                                            dense
                                            outlined
                                            label="Código Postal"
                                            :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="pa-1" cols="7">
                                    <v-text-field
                                            v-model="editAddress.CpLocality"
                                            dense
                                            outlined
                                            :label="$t('locality') + ' CP'"
                                            :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pa-1" cols="4">
                                    <v-text-field
                                            v-model="editAddress.Country"
                                            dense
                                            outlined
                                            label="Código País"
                                            :rules="[rules.required]"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="pa-1" cols="8">
                                    <v-select
                                            v-model="editAddress.Zone"
                                            dense
                                            outlined
                                            label="Zona"
                                            :rules="[rules.required]"
                                            :items="zones"
                                            item-text="Descricao"
                                            item-value="zona"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pa-1" cols="6">
                                    <v-text-field
                                            v-model="editAddress.CDU_LATITUDE"
                                            dense
                                            outlined
                                            label="Latitude"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="pa-1" cols="6">
                                    <v-text-field
                                            v-model="editAddress.CDU_LONGITUDE"
                                            dense
                                            outlined
                                            label="Longitude"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogEditAddress = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" :loading="saving" @click="updateAddress()">{{
                                $t('save')
                            }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- REMOVE ADDRESS -->
            <v-dialog v-model="dialogRemoveAddress" persistent max-width="400">
                <v-card>
                    <v-toolbar flat dense dark color="red">
                        <v-toolbar-title class="text-uppercase">{{ $t('deleteAddress') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogRemoveAddress = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="mt-4">
                        <h4>
                                <span v-if="editAddress.AlternativeAddress != 'SEDE'">
                                    {{ $t('alternativeAddress') }}: {{ editAddress.AlternativeAddress }}
                                </span> <span v-if="editAddress.AlternativeAddress == 'SEDE'">
                                    {{
                                $t('principalAddress').charAt(0).toUpperCase() + $t('principalAddress').slice(1).toLowerCase()
                            }}
                                </span>
                        </h4>
                        <p>
                            {{ $t('address1') }}: {{ editAddress.Address }}<br> {{ $t('address2') }}: {{
                                editAddress.Address2
                            }}<br> {{ $t('locality') }}: {{ editAddress.Locality }}<br> Código
                            Postal: {{ editAddress.Cp }}<br> {{ $t('locality') }} CP: {{
                                editAddress.CpLocality
                            }}<br> País: {{ editAddress.Country }}
                        </p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogRemoveAddress = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="error" :loading="saving" @click="confirmDeleteAddress()">{{
                                $t('remove')
                            }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- SHOW MAP -->
            <v-dialog v-model="dialogMaps" max-width="600">
                <v-card>
                    <v-card-title>
                        {{ $t('customerLocation') }}
                        <div style="margin-left: auto">
                            <v-icon @click="dialogMaps = false">close</v-icon>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <GmapMap
                                :center="center"
                                :zoom="zoom"
                                map-type-id="terrain"
                                style="width: 555px; height: 500px"
                                @click="setPositionMaps"
                        >
                            <GmapMarker
                                    :key="index"
                                    v-for="(m, index) in markers"
                                    :position="m.position"
                                    :clickable="true"
                                    :draggable="true"
                                    @click="center=m.position"
                            />
                        </GmapMap>
                        <v-row>
                            <v-col class="pb-0">
                                <v-text-field
                                        v-model="editLocation.latitude"
                                        dense
                                        outlined
                                        label="Latitude"
                                        hide-details
                                        @keypress.enter="setPositionsMapsCoordinates"
                                        v-if="writePermission('clients/client_address')"
                                ></v-text-field>
                            </v-col>
                            <v-col class="pb-0">
                                <v-text-field
                                        v-model="editLocation.longitude"
                                        dense
                                        outlined
                                        label="Longitude"
                                        hide-details
                                        @keypress.enter="setPositionsMapsCoordinates"
                                        v-if="writePermission('clients/client_address')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogMaps = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" :loading="savingLocation" @click="updateLocation()">{{
                                $t('save')
                            }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Add Guaranty Cosec -->
            <v-dialog persistent v-model="dialogAddGuarantyCosec" max-width="600">
                <v-card>
                    <v-toolbar flat dense dark color="orange">
                        <v-toolbar-title class="text-uppercase">{{ $t('add') }} Garantia</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogAddGuarantyCosec = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-chip
                                        v-if="newGuarantyErrorMessage !== ''"
                                        class="ma-2"
                                        color="red"
                                        text-color="white"
                                        label
                                >
                                    {{ newGuarantyErrorMessage }}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <v-form class="mt-4" ref="guarantyCosec">
                            <v-row>
                                <v-col>
                                    Dinâmica:
                                    <v-radio-group v-model="newGuaranty.dynamic" mandatory row>
                                        <v-radio :label="$t('no')" value="false"></v-radio>
                                        <v-radio :label="$t('yes')" value="true"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-if="newGuaranty.dynamic === 'false'"
                                            v-model="newGuaranty.amount"
                                            hide-details
                                            dense
                                            outlined
                                            label="Valor"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="newGuaranty.observations"
                                            hide-details
                                            dense
                                            outlined
                                            :label="$t('observations')"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogAddGuarantyCosec = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" :loading="saving" @click="saveCosecGuaranty()"
                               :disabled="validateAddGuarantyCosec">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Delete Cosec Guaranty -->
            <v-dialog persistent v-model="dialogDeleteGuarantyCosec" max-width="400">
                <v-card>
                    <v-toolbar flat dense dark color="red">
                        <v-toolbar-title class="text-uppercase">{{ $t('delete') }} Garantia</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogDeleteGuarantyCosec = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col class="text-center">
                                <v-chip
                                        v-if="deleteGuarantyErrorMessage !== ''"
                                        class="ma-2"
                                        color="red"
                                        text-color="white"
                                        label
                                >
                                    {{ deleteGuarantyErrorMessage }}
                                </v-chip>
                            </v-col>
                        </v-row>
                        <p>Tem a certeza que deseja remover a garantia?</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogDeleteGuarantyCosec = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="error" :loading="saving" @click="confirmDeleteCosecGuaranty()">
                            {{ $t('delete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Recolha DPD -->
            <v-dialog v-model="dialogShippingDPD" persistent max-width="400">
                <v-card>
                    <v-card-title>Criar Recolha</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="inputShipping.volumes"
                                dense
                                label="Volumes"
                                outlined
                                @change="verifier()"
                        ></v-text-field>
                        <v-text-field
                                v-model="inputShipping.weight"
                                dense
                                label="Peso"
                                outlined
                                @change="verifier()"
                        ></v-text-field>
                        <v-text-field
                                v-model="inputShipping.observation"
                                dense
                                label="Observações"
                                hide-details
                                outlined
                                @change="verifier()"
                        ></v-text-field>

                        <v-row align="start" class="ma-2">
                            <v-col>
                                <v-card
                                        width="70"
                                        class="ma-2 active-class"
                                        style="margin: auto; border: 1px solid #555; border-radius: 10px"
                                        :class="{'select-courier' : inputShipping.courier === 'CTT'}"
                                        hover
                                        @click="inputShipping.courier= 'CTT'; verifier();"

                                >
                                    <v-img style="margin: auto; cursor: pointer" aspect-ratio="1"
                                           :src="urlAPI+'/assets/img/ctt.jpg'"></v-img>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card
                                        width="70"
                                        class="ma-2 active-class"
                                        style="border: 1px solid #555; border-radius: 10px;"
                                        :class="{'select-courier' : inputShipping.courier === 'REDUR'}"
                                        hover
                                        @click="inputShipping.courier= 'REDUR'; verifier();"
                                >
                                    <v-img style="margin: auto; cursor: pointer" aspect-ratio="1"
                                           :src="urlAPI+'/assets/img/redur.jpg'"></v-img>
                                </v-card>
                            </v-col>
                            <!--                :style="colorCardCourier(true) ? 'border: 1px solid #01BFFF;' : 'border: 1px solid #FFF;'"-->
                        </v-row>
                        <v-row align="start" class="ma-2">
                            <v-col>
                                <v-card
                                        width="70"
                                        class="ma-2 active-class"
                                        style="margin: auto; border: 1px solid #555; border-radius: 10px;"
                                        :class="{'select-courier' : inputShipping.courier === 'NACEX'}"
                                        hover
                                        @click="inputShipping.courier= 'NACEX'; verifier();"
                                >
                                    <v-img style="margin: auto; cursor: pointer" aspect-ratio="1"
                                           :src="urlAPI+'/assets/img/nacex.png'"></v-img>
                                </v-card>
                            </v-col>
                            <v-col>
                                <v-card
                                        width="70"
                                        class="ma-2 active-class"
                                        style="margin: auto; border: 1px solid #555; border-radius: 10px;"
                                        :class="{'select-courier' : inputShipping.courier === 'SEUR2'}"
                                        hover
                                        @click="inputShipping.courier= 'SEUR2'; verifier();"
                                >
                                    <v-img style="margin: auto; cursor: pointer" aspect-ratio="1"
                                           :src="urlAPI+'/assets/img/dpd_recolha.jpg'"></v-img>
                                </v-card>
                            </v-col>
                        </v-row>
                        <template v-if="inputShipping.courier === 'NACEX' || inputShipping.courier === 'CTT'">
                            <v-row>
                                <v-col class="text-center">
                                    <v-date-picker
                                            locale="en-in"
                                            style="border: 1px solid #555; border-radius: 8px;"
                                            :min="today"
                                            v-model="dates.date"
                                            no-title
                                            @change="verifier()"
                                    ></v-date-picker>
                                </v-col>
                            </v-row>
                            <v-row v-if="inputShipping.courier === 'NACEX'">
                                <v-col class="text-center">
                                    <v-select
                                            :items="times"
                                            label="Initial"
                                            v-model="dates.initialTime"
                                            @change="verifier()"
                                    ></v-select>
                                </v-col>

                                <v-col class="text-center">
                                    <v-select
                                            :items="times"
                                            label="Final"
                                            v-model="dates.finalTime"
                                            @change="verifier()"
                                    ></v-select>
                                </v-col>

                            </v-row>

                        </template>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn tile small @click="dialogShippingDPD = false">{{ $t('cancel') }}</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="verified" tile small color="success" @click="confirmShipping()">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";
import _ from "lodash";

export default {
    name: "ClientV2",
    data: function () {
        return {
            activeTab: 1,
            activeTab2: 1,
            activeTab3: 1,
            loading: false,
            savingLocation: false,
            saving: false,
            clientId: '',
            client: {},
            profiles: [],
            sellers: [],
            loginToday: [],
            loginLastWeek: [],
            loginLastMonth: [],
            modesOfPayment: [],
            modesOfPaymentFSC: [],
            paymentConditions: [],
            markers: [],
            discounts: [],
            invoices: [],
            docTypesList: [],
            originalLines: [],
            invoiceLines: [],
            total: [],
            crossSelling: '',
            isVIP: false,
            linkToken: '',
            addresses: [],
            clientDetailReadOnly: true,
            notesReadOnly: true,
            detail2ReadOnly: true,
            addressReadOnly: true,
            b2bAccess: {
                1: 'Fechado',
                2: 'Aberto',
                3: 'Suspenso',
                4: 'Cancelado'
            },
            filtersInvoices: {
                Artigo: '',
                NumDoc: '',
                Serie: '',
                TipoDoc: '',
            },
            pagination: {
                rowsPerPage: 25,
                page: 1
            },
            itemPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '40', VALUE: 40},
                {TEXT: '50', VALUE: 50},
                {TEXT: '65', VALUE: 65},
            ],
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '40', VALUE: 40},
                {TEXT: '50', VALUE: 50},
                {TEXT: '65', VALUE: 65},
            ],
            selectedHeaderInvoices: "Artigo",
            headersInvoices: {
                "Artigo": 'asc',
                "NumDoc": 'asc',
                "Serie": 'asc',
                "TipoDoc": 'asc',
            },
            filtersB2BAccess: [
                {text: 'Fechado', value: '1'},
                {text: 'Aberto', value: '2'},
                {text: 'Suspenso', value: '3'},
                {text: 'Cancelado', value: '4'}
            ],
            commercialZonesDescriptions: [],
            commercialZones: [],
            commercialZones2: {
                1: 'ES Norte',
                2: 'ES Cataluña y Levante',
                3: 'ES Centro',
                4: 'ES Sur',
                5: 'Portugal',
                6: 'ES Islas'
            },
            filtersCommercialZones: [
                {text: 'ES Norte', value: 1},
                {text: 'ES Cataluña y Levante', value: 2},
                {text: 'ES Centro', value: 3},
                {text: 'ES Sul', value: 4},
                {text: 'Portugal', value: 5},
                {text: 'ES Islas', value: 6},
            ],
            filtersYesNo: [
                {text: 'Não', value: 0},
                {text: 'Sim', value: 1}
            ],
            rules: {
                required: value => !!value || value === '0' || i18n.t('required'),
            },
            dialogEditAddress: false,
            dialogRemoveAddress: false,
            dialogEditDetail: false,
            dialogEditDetail2: false,
            dialogMaps: false,
            dialogAddGuarantyCosec: false,
            dialogDeleteGuarantyCosec: false,
            dialogShippingDPD: false,
            editAddress: {
                AlternativeAddress: '',
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            },
            editDetail: {},
            campaignHistoric: [],
            orders: [],
            footerOrders: {
                iva: 23,
                pp: 5,
                totalIva: 0.0,
                final: 0.0,
                subTotal: 0.0,
                products: 0,
                totalPP: 0.0
            },
            calls: [],
            language: '',
            sales: [],
            editAddressIndex: 0,
            center: {lat: 10, lng: 10},
            b2bCRMAction: [],
            zones: [],
            titleAddress: '',
            directDebits: [],
            tipoTerceiros: [],
            guarantees: [],
            newGuaranty: {
                dynamic: false,
                amount: '',
                buyerId: '',
                externalId: '',
                observations: ''
            },
            newGuarantyErrorMessage: '',
            guarantyIdToDelete: '',
            deleteGuarantyErrorMessage: '',
            editLocation: {
                id: '',
                latitude: '',
                longitude: ''
            },
            zoom: 16,
            tokenAPI: '',
            inputShipping: {
                volumes: '',
                weight: '',
                address: {},
                observation: '',
                courier: false,
            },
            disableButtonShipping: false,
            times: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
            dates: {
                day: 0,
                initialTime: '',
                finalTime: ''
            },
            today: null,
            day: null,
            verified: true
        }
    },
    created() {
        document.title = this.$route.params.id + ' - Cliente | STAFF';
        this.clientId = this.$route.params.id;
        this.language = User.getters.getLanguage;
        this.getClient();
        this.getInvoices();
    },
    computed: {
        getTotalPages() {
            return Math.ceil(this.total / this.pagination.rowsPerPage)
        },
        pagedLines: function () {
            return this.orderedLines.slice((this.pagination.page - 1) * this.pagination.rowsPerPage, this.pagination.page * this.pagination.rowsPerPage);
        },
        orderedLines: function () {
            return _.orderBy(this.invoiceLines, this.selectedHeaderInvoices, this.headersInvoices[this.selectedHeaderInvoices]);
        },
        profile() {
            return User.getters.getProfileId;
        },
        validateAddGuarantyCosec() {
            if (this.newGuaranty.dynamic === 'true') {
                return false;
            }
            return !(this.newGuaranty.dynamic === 'false' && this.newGuaranty.amount !== '');
        },
        urlAPI() {
            return process.env.VUE_APP_API_URL
        },
    },
    methods: {
        getClient() {
            this.loading = true
            let request = {
                url: api.clients() + '/' + this.clientId
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.client = responseData.client
                            this.profiles = responseData.profiles
                            this.sellers = responseData.sellers
                            this.loginToday = responseData.loginToday
                            this.loginLastWeek = responseData.loginLastWeek
                            this.loginLastMonth = responseData.loginLastMonth
                            this.modesOfPayment = responseData.modesOfPayment
                            this.modesOfPaymentFSC = responseData.modesOfPaymentFSC
                            this.paymentConditions = responseData.paymentConditions
                            this.discounts = responseData.discounts
                            this.linkToken = responseData.linkToken
                            this.addresses = responseData.addresses
                            this.campaignHistoric = responseData.campaignHistoric
                            this.orders = responseData.orders
                            this.calls = responseData.calls
                            this.sales = responseData.sales
                            this.isVIP = responseData.isVIP
                            this.crossSelling = responseData.crossSelling
                            this.b2bCRMAction = responseData.b2bCRMAction
                            this.zones = responseData.zones
                            this.directDebits = responseData.directDebits
                            this.tipoTerceiros = responseData.tipoTerceiros
                            this.guarantees = responseData.guarantees
                            this.tokenAPI = responseData.tokenAPI
                            this.commercialZones = responseData.commercialZones
                            this.commercialZonesDescriptions = responseData.commercialZonesDescriptions

                            this.updateFooterOrders()
                        } else if (responseData.status === 'PER') {
                            this.loginToday.push({count: 0})
                            this.loginToday.push({count: 0})
                            this.loginLastWeek.push({count: 0})
                            this.loginLastMonth.push({count: 0})
                            this.$router.push({name: "clients/clients"})
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    })
        },
        getInvoices(){
            let request = {
                url: '/api/staff/clients/invoices/' + this.clientId,
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.originalLines = responseData.invoices;
                            this.invoiceLines = responseData.invoices;
                            this.total = this.originalLines.length;
                            this.docTypesList = [... new Set(this.originalLines.map((item) => item.TipoDoc))]
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })

        },
        updateTable() {
            this.invoiceLines = this.originalLines

            if (this.filtersInvoices.Artigo !== '') {
                this.invoiceLines = this.invoiceLines.filter(line => line.Artigo != null && line.Artigo == this.filtersInvoices.Artigo)
            }
            if (this.filtersInvoices.NumDoc !== '') {
                this.invoiceLines = this.invoiceLines.filter(line => line.NumDoc != null && String(line).NumDoc.includes(this.filtersInvoices.NumDoc))
            }
            if (this.filtersInvoices.Serie !== '') {
                this.invoiceLines = this.invoiceLines.filter(line => line.Serie.includes(this.filtersInvoices.Serie))
            }
            if (this.filtersInvoices.TipoDoc !== '') {
                this.invoiceLines = this.invoiceLines.filter(line => line.TipoDoc == this.filtersInvoices.TipoDoc)
            }
            this.total = this.invoiceLines.length
            this.pagination.page = 1;

        },
        orderList(col) {
            if (col == this.selectedHeaderInvoices)
                this.headersInvoices[this.selectedHeaderInvoices] = this.headersInvoices[this.selectedHeaderInvoices] == "asc" ? "desc" : "asc";
            else
                this.headersInvoices[this.selectedHeaderInvoices] = "desc";

            this.selectedHeaderInvoices = col;
            this.pagination.page = 1;

        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        colorButtonDiscount(index, currentLevel, maxLevel) {
            let color = ''
            if (index == currentLevel && index != 0 && index == maxLevel) {
                color = 'success'
            }
            if (index == currentLevel && (index != maxLevel || index == 0)) {
                color = 'primary'
            }

            return color
        },
        updateDiscount(brand, index, group) {
            let request = {
                url: api.clientUpdateDiscount(),
                data: {
                    clientId: this.clientId,
                    brand: brand,
                    level: index,
                    group: group
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.discounts = responseData.discounts
                            this.crossSelling = responseData.crossSelling
                            this.isVIP = responseData.isVIP
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao atualizar desconto!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        btnEditAddress(address) {
            this.titleAddress = 'Editar ' + i18n.t('address1');
            this.editAddress = {
                AlternativeAddress: '',
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: '',
                CDU_LATITUDE: '',
                CDU_LONGITUDE: ''
            };
            this.editAddressIndex = 1;
            this.editAddress = Object.assign({}, address);
            this.dialogEditAddress = true
        },
        btnEditDetail() {
            this.editDetail = {};
            this.editDetail = Object.assign({}, this.client)
            this.dialogEditDetail = true
        },
        btnEditDetail2() {
            this.editDetail = {}
            this.editDetail = Object.assign({}, this.client)
            this.dialogEditDetail2 = true
        },
        updateAddress() {
            if (this.$refs.address.validate()) {
                this.saving = true;
                if (this.editAddressIndex === 1) {
                    let request = {
                        url: api.clientUpdateAddress(),
                        data: {
                            clientId: this.clientId,
                            address: this.editAddress
                        }
                    };

                    ApiWS.dispatch('makePost', request)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    this.addresses = responseData.addresses
                                    this.dialogEditAddress = false
                                } else {
                                    Alert.dispatch('show', {
                                        snackbar: true,
                                        text: 'Erro ao atualizar ' + i18n.t('address1') + '!',
                                        type: 'error'
                                    })
                                }
                                this.saving = false;
                            })
                            .catch(() => {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro de servidor!',
                                    type: 'error'
                                });
                                this.saving = false;
                            })
                } else {
                    let request = {
                        url: api.clientInsertAddress(),
                        data: {
                            clientId: this.clientId,
                            address: this.editAddress
                        }
                    };

                    ApiWS.dispatch('makePost', request)
                            .then(response => {
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    this.addresses = responseData.addresses
                                    this.dialogEditAddress = false
                                } else {
                                    Alert.dispatch('show', {
                                        snackbar: true,
                                        // text: 'Erro ao inserir ' + i18n.t('address1') + '!',
                                        text: JSON.stringify(responseData.message),
                                        type: 'error'
                                    })
                                }
                                this.saving = false;
                            })
                            .catch(() => {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro de servidor!',
                                    type: 'error'
                                });
                                this.saving = false;
                            })
                }
            }
        },
        showCommercialZone(zone) {
            return this.commercialZones[zone]
        },
        showProfileName(profile) {
            let profileName = ''
            this.profiles.forEach(function (element) {
                if (profile === element.value) {
                    profileName = element.text
                }
            })
            return profileName
        },
        showModeOfPaymentName(modePayment) {
            let modeOfPaymentName = ''
            this.modesOfPayment.forEach(function (element) {
                if (element.value === modePayment) {
                    modeOfPaymentName = element.text
                }
            })
            return modeOfPaymentName
        },
        showYesOrNo(paymentExtra) {
            let paymentExtraName = ''
            this.filtersYesNo.forEach(function (element) {
                if (paymentExtra === element.value) {
                    paymentExtraName = element.text
                }
            })
            return paymentExtraName
        },
        showPaymentConditionName(paymentCondition) {
            let paymentConditionName = ''
            this.paymentConditions.forEach(function (element) {
                if (paymentCondition === element.value) {
                    paymentConditionName = element.text
                }
            })
            return paymentConditionName
        },
        showTipoTerceiro(tipoTerceiro) {
            let tipoTerceiroName = ''
            this.tipoTerceiros.forEach(function (element) {
                if (tipoTerceiro === element.value) {
                    tipoTerceiroName = element.text
                }
            })
            return tipoTerceiroName
        },
        updateFooterOrders() {
            this.footerOrders.products = 0
            this.footerOrders.subTotal = 0.0
            let products = 0
            let subTotal = 0.0
            this.orders.forEach(function (order) {
                products += parseInt(order.QTD)
                subTotal += parseFloat(order.PrecoLiquido)
            })
            this.footerOrders.products = products
            this.footerOrders.subTotal = subTotal
            this.footerOrders.totalPP = (this.footerOrders.pp / 100) * this.footerOrders.subTotal * -1;
            this.footerOrders.totalIva = (this.footerOrders.iva / 100) * (this.footerOrders.subTotal + this.footerOrders.totalPP)
            this.footerOrders.final = this.footerOrders.subTotal + this.footerOrders.totalPP + this.footerOrders.totalIva
        },
        addAddress() {
            this.titleAddress = 'Adicionar ' + i18n.t('address1');
            this.editAddress = {
                AlternativeAddress: '',
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            }
            this.editAddressIndex = 0
            this.dialogEditAddress = true
        },
        removeAddress(address) {
            this.editAddress = {
                AlternativeAddress: '',
                Address: '',
                Address2: '',
                Locality: '',
                Cp: '',
                CpLocality: '',
                Country: ''
            }
            this.dialogRemoveAddress = true
            this.editAddress = address
        },
        confirmDeleteAddress() {
            this.saving = true;
            let request = {
                url: api.clientDeleteAddress(),
                data: {
                    clientId: this.clientId,
                    address: this.editAddress
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.addresses = responseData.addresses
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao inserir ' + i18n.t('address1') + '!',
                                type: 'error'
                            })
                        }
                        this.saving = false;
                        this.dialogRemoveAddress = false
                    })
                    .catch(() => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        });
                        this.dialogRemoveAddress = false
                    })
        },
        closeNotes() {
            this.notesReadOnly = true;
        },
        updateNotes() {
            let request = {
                url: api.clientUpdateNotes(),
                data: {
                    clientId: this.clientId,
                    notes: this.client.Notas
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.client = responseData.client
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Notas atualizadas com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao atualizar notas!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            this.notesReadOnly = true
        },
        btnEditNotes() {
            this.notesReadOnly = false
            this.$nextTick(() => this.$refs.inputNotes.focus())
        },
        downloadPDF(serie, tipodoc, numdoc) {
            let request = {
                url: api.orderPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let request = {
                                url: api.orderDownloadPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
                            }
                            ApiWS.dispatch('makeDownload', request)
                                    .then(response => {
                                        var file = new Blob([response.data], {
                                            type: 'application/pdf'
                                        });
                                        var fileURL = URL.createObjectURL(file);
                                        var a = document.createElement('a');
                                        a.href = fileURL;
                                        a.target = '_blank';
                                        a.download = serie + '_' + tipodoc + '_' + numdoc + '.pdf';
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                    .catch(response => {
                                        Alert.dispatch('show', {
                                            snackbar: true,
                                            text: 'Erro de servidor!',
                                            type: 'error'
                                        })
                                    })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar PDF!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        updateClient() {
            let request = {
                url: api.clientUpdate(),
                data: {
                    client: this.editDetail
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Cliente atualizado com sucesso!',
                                type: 'success'
                            })
                            this.dialogEditDetail2 = false
                            this.dialogEditDetail = false
                            this.getClient()
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao atualizar cliente!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        openMaps(address) {
            this.editLocation = {
                id: address.AlternativeAddress,
                latitude: address.CDU_LATITUDE,
                longitude: address.CDU_LONGITUDE
            }

            this.markers = []
            this.markers.push({
                position: {
                    lat: parseFloat(address.CDU_LATITUDE),
                    lng: parseFloat(address.CDU_LONGITUDE)
                }
            })

            if (address.CDU_LATITUDE != null || address.CDU_LONGITUDE != null) {
                this.zoom = 16;
                this.center = {
                    lat: parseFloat(address.CDU_LATITUDE),
                    lng: parseFloat(address.CDU_LONGITUDE)
                }
            } else {
                this.getLocation(address);
            }

            this.dialogMaps = true
        },
        addCosecGuaranty() {
            if (this.$refs.guarantyCosec !== undefined) {
                this.$refs.guarantyCosec.resetValidation();
            }
            this.newGuaranty = {
                dynamic: false,
                amount: '',
                buyerId: this.client.CDU_COSEC,
                externalId: this.clientId,
                observations: ''
            }
            this.dialogAddGuarantyCosec = true;
        },
        saveCosecGuaranty() {
            this.newGuarantyErrorMessage = '';
            this.saving = true;
            let request = {
                url: '/api/staff/clients/createCosecGuaranty',
                data: {
                    newGuaranty: this.newGuaranty
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            setTimeout(() => {
                                this.getClient();
                            }, 500);
                            this.dialogAddGuarantyCosec = false;
                        } else if (responseData.status === 'WAR') {
                            this.newGuarantyErrorMessage = responseData.data
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao adicionar garantia!',
                                type: 'error'
                            })
                        }
                        this.saving = false;
                    })
                    .catch(response => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        deleteCosecGuaranty(guaranty_id) {
            this.guarantyIdToDelete = guaranty_id;
            this.dialogDeleteGuarantyCosec = true;
        },
        confirmDeleteCosecGuaranty() {
            this.saving = true;
            this.deleteGuarantyErrorMessage = '';
            let request = {
                url: '/api/staff/clients/deleteCosecGuaranty/' + this.guarantyIdToDelete
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            setTimeout(() => {
                                this.getClient();
                            }, 500);
                            this.dialogDeleteGuarantyCosec = false;
                        } else if (responseData.status === 'WAR') {
                            this.deleteGuarantyErrorMessage = responseData.data;
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao eliminar garantia!',
                                type: 'error'
                            })
                        }
                        this.saving = false;
                    })
                    .catch(response => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        pdfCosecGuaranty(guaranty_id) {
            let request = {
                url: '/api/staff/clients/pdfCosecGuaranty/' + guaranty_id
            }

            ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/pdf'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = 'Cosec_Garantia_' + this.clientId + '_' + guaranty_id + '.pdf';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        setPositionMaps(event) {
            this.markers = []
            this.markers.push({
                position: {
                    lat: parseFloat(event.latLng.lat()),
                    lng: parseFloat(event.latLng.lng())
                }
            })

            this.center = {
                lat: parseFloat(event.latLng.lat()),
                lng: parseFloat(event.latLng.lng())
            }

            this.editLocation.latitude = event.latLng.lat();
            this.editLocation.longitude = event.latLng.lng();
        },
        setPositionsMapsCoordinates() {
            this.markers = [];
            this.markers.push({
                position: {
                    lat: parseFloat(this.editLocation.latitude),
                    lng: parseFloat(this.editLocation.longitude)
                }
            })

            this.center = {
                lat: parseFloat(this.editLocation.latitude),
                lng: parseFloat(this.editLocation.longitude)
            }
        },
        updateLocation() {
            this.savingLocation = true;
            let request = {
                url: '/api/staff/clients/updateLocation',
                data: {
                    clientId: this.clientId,
                    location: this.editLocation
                }
            };

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.addresses = responseData.addresses
                            this.dialogMaps = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao atualizar localização!',
                                type: 'error'
                            })
                        }
                        this.savingLocation = false;
                    })
                    .catch(response => {
                        this.savingLocation = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        getLocation(address) {
            let request = {
                url: '/api/staff/clients/getLocation',
                data: {
                    address: address
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.zoom = 16;
                            this.markers.push({
                                position: {
                                    lat: parseFloat(responseData.lat),
                                    lng: parseFloat(responseData.lng)
                                }
                            })

                            this.center = {
                                lat: parseFloat(responseData.lat),
                                lng: parseFloat(responseData.lng)
                            }
                        } else {
                            this.zoom = 6;
                            this.center = {
                                lat: parseFloat('40.416595'),
                                lng: parseFloat('-3.704624')
                            }
                        }

                        this.dialogMaps = true;
                    })
                    .catch(() => {
                        this.zoom = 6;
                        this.center = {
                            lat: parseFloat('40.416595'),
                            lng: parseFloat('-3.704624')
                        }
                        this.dialogMaps = true;
                    })
        },
        makeTokenAPI() {
            let request = {
                url: '/api/staff/clients/' + this.clientId + '/makeTokenAPI'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.tokenAPI = responseData.token;
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        makeShipping(address) {
            this.inputShipping.observation = '';
            this.inputShipping.volumes = '';
            this.inputShipping.weight = '';
            this.verified = true;

            this.day = new Date();
            this.day.setDate(this.day.getDate() + 1);
            this.today = this.day.toISOString();
            // this.dates.date = this.day.toISOString();

            this.inputShipping.courier = '';
            this.inputShipping.address = address;
            this.dialogShippingDPD = true;
        },
        confirmShipping() {
            let loader = this.$loading.show();
            let formattedDate = new Date(this.dates.date)

            let formattedDates = {
                date: formattedDate.toLocaleDateString(),
                initialTime: this.dates.initialTime,
                finalTime: this.dates.finalTime,
                day: this.dates.day
            }

            let request = {
                url: '/api/staff/clients/' + this.clientId + '/makeShipping',
                data: {
                    client: this.client,
                    shipping: this.inputShipping,
                    dates: formattedDates
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Recolha criada com sucesso!',
                                type: 'success'
                            })
                            this.dialogShippingDPD = false;
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar a recolha!',
                                type: 'error'
                            })
                            // this.dialogShippingDPD = false;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        verifyDates() {
            if (this.inputShipping.courier === 'NACEX')
                return this.datesComparator() && this.dates.initialTime < this.dates.finalTime &&
                        this.dates.finalTime !== '' && this.dates.initialTime !== '';
            else if (this.inputShipping.courier === 'CTT')
                return this.datesComparator();
            else
                return true;
        },
        verifyInputs() {
            return this.inputShipping.volumes !== '' && this.inputShipping.weight !== '' && this.inputShipping.courier !== ''
        },
        verifier() {
            this.verified = !(this.verifyDates() && this.verifyInputs())

//       console.log('DtComparat: ' + 'DtVerif:  ' + 'InpVerif: ' + 'verified: ' )
//       console.log(this.datesComparator(),this.verifyDates(), this.verifyInputs(),this.verified)
        },
        datesComparator() {
            return !isNaN(Date.parse(this.dates.date))
        }
    },
}
</script>

<style scoped>

</style>