import { render, staticRenderFns } from "./OpenOrderDetail.vue?vue&type=template&id=e606f522&scoped=true"
import script from "./OpenOrderDetail.vue?vue&type=script&lang=js"
export * from "./OpenOrderDetail.vue?vue&type=script&lang=js"
import style0 from "./OpenOrderDetail.vue?vue&type=style&index=0&id=e606f522&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e606f522",
  null
  
)

export default component.exports