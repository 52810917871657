import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"content-header"},[_c(VCol,[_c('page-title',{attrs:{"title":_vm.$t('nozamaTemplates'),"subtitle":"Exportação dos templates Nozama"}})],1)],1),_c(VRow,[_c(VCol,[_c(VCard,[_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"3","md":"3"}},[_c(VAutocomplete,{attrs:{"items":_vm.brands,"item-text":"TEXT","item-value":"VALUE","label":"Marca","hide-details":"","outlined":"","dense":""},on:{"change":_vm.getFamilies},model:{value:(_vm.inputExport.brand),callback:function ($$v) {_vm.$set(_vm.inputExport, "brand", $$v)},expression:"inputExport.brand"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"3","md":"3"}},[_c(VAutocomplete,{attrs:{"items":_vm.families,"item-value":"VALUE","item-text":"TEXT","label":"Familia","hide-details":"","outlined":"","dense":""},model:{value:(_vm.inputExport.family),callback:function ($$v) {_vm.$set(_vm.inputExport, "family", $$v)},expression:"inputExport.family"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"3","md":"3"}},[_c(VBtn,{attrs:{"color":"success","disabled":_vm.validateButtonExport},on:{"click":function($event){return _vm.exportNozama()}}},[_vm._v(" Exportar ")]),_c(VBtn,{staticClass:"ml-2",attrs:{"color":"primary","disabled":_vm.validateButtonExport},on:{"click":function($event){return _vm.exportImages()}}},[_vm._v(" Imagens ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }