<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Faturação Bikeplanet"
                        :subtitle="totalInvoices +' faturas pendentes'"
                ></page-title>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th width="200">Origem</th>
                        <th style="width: 39%">Artigos</th>
                        <th style="width: 12%">Portes</th>
                        <th>Embrulho</th>
                        <th>Total</th>
                        <th>Cliente</th>
                        <th width="95">Info</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="filters_row">
                        <td>
                            <v-text-field
                                    v-model="filters.amazonOrderId"
                                    outlined
                                    single-line
                                    @keyup.enter="filter"
                                    hide-details
                                    dense
                            ></v-text-field>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <template  v-for="invoice in pendingInvoices">
                        <tr v-if="differentDate(invoice)">
                            <td colspan="8" class="date_row">{{ invoice.date }}</td>
                        </tr>
                        <tr v-if="!invoice.hide">
                            <td class="text-center">
                                <strong>{{ invoice.salesChannel }}</strong> <br>
                                {{ invoice.amazonOrderId }} <br>
                                {{ invoice.reportingDate }} <br>
                                FBA - {{ invoice.fulfillmentCenterId }} {{ invoice.shipFromCountry }}
                            </td>
                            <td>
                                <v-simple-table style="background-color: transparent !important;">
                                <thead>
                                <tr>
                                    <th style="width: 62%">Código</th>
                                    <th class="text-center" style="width: 5%">Qty.</th>
                                    <th class="text-center" style="width: 12%">Preço</th>
                                    <th class="text-center" style="width: 10%">IVA</th>
                                    <th class="text-center" style="width: 5%">Promoção</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="product in invoice.products">
                                    <td><strong>{{ product.sku }}</strong> <br>{{ product.productName }}</td>
                                    <td class="text-center">{{ product.quantityShipped }}</td>
                                    <td class="text-center">{{ $2decimal(product.itemPrice) }} {{ product.currency }}</td>
                                    <td class="text-center" :style="{'background-color': product.itemTax == 0.00 && invoice.VATRate != 0 ? '#ffb8c2' : '' }">
                                        {{ $2decimal(product.itemTax) }} {{ product.currency }} <br> ({{ invoice.VATRate }}%)
                                    </td>
                                    <td class="text-center">{{ $2decimal(product.itemPromotionDiscount) }} {{
                                            product.currency
                                        }}
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                    </td>
                    <td>
                        <v-simple-table style="background-color: transparent !important;">
                            <thead>
                            <tr>
                                <th>Portes</th>
                                <th>IVA</th>
                                <th>Desconto</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{ $2decimal(invoice.shippingPrice) }} {{ invoice.currency }}</td>
                                <td>{{ $2decimal(invoice.shippingTax) }} {{ invoice.currency }} <br> ({{
                                        invoice.VATRate
                                    }}%)
                                </td>
                                <td>{{ $2decimal(invoice.shipPromotionDiscount) }} {{ invoice.currency }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </td>
                    <td>
                        <v-simple-table style="background-color: transparent !important;">
                            <thead>
                            <tr>
                                <th>Preço</th>
                                <th>IVA</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{ $2decimal(invoice.giftWrapPrice) }} {{ invoice.currency }}</td>
                                <td>{{ invoice.giftWrapTax }} {{ invoice.currency }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </td>
                    <td>{{ $2decimal(totalOrder(invoice)) }} {{ invoice.currency }}</td>
                    <td>
                        <strong>{{ invoice.buyerName }}</strong><br>
                        <span>{{ invoice.shipAddress1 }}</span><br>
                        <span v-if="invoice.shipAddress2">{{ invoice.shipAddress2 }}<br></span>
                        <span v-if="invoice.shipAddress3">{{ invoice.shipAddress3 }}<br></span>
                        {{ invoice.shipPostalCode }} - {{ invoice.shipCity }} <br>
                        <strong>{{ invoice.shipCountry }}</strong> <br>
                        <strong>NIF: {{ invoice.nif || 999999990 }}</strong>
                    </td>
                    <td>
                        <span>Origem: <strong>{{ invoice.shipFromCountry }}</strong></span><br>
                        <span>Destino: <strong>{{ invoice.shipCountry }}</strong></span><br>
                        <span>OSS:
                            <i class="fas fa-check" style="color: green" v-if="invoice.OSS == true"></i>
                            <i class="fas fa-times" style="color: red" v-if="invoice.OSS == false"></i>
                        </span> <br>
                        <v-select
                                class="staff_select_small"
                                label="DOC"
                                v-model="invoice.typeDoc"
                                hide-details
                                dense
                                outlined
                                :items="invoice.typeDocOption"
                                item-text="text"
                                item-value="value"
                        ></v-select>
                    </td>
                    <td class="text-center">
                        <v-btn small class="error" @click="recalculateValues(invoice)">Recalcular</v-btn>
                        <br>
                        <v-btn small style="margin-top: 10px" :disabled="invoice.shipFromCountry === 'N/A'" @click="makeInvoice(invoice)">
                            Faturar
                        </v-btn>
                        <!--                    <v-btn small style="margin-top: 10px" @click="toggleDialogAddInvoiceNumber()">Fatura manual</v-btn>-->
                    </td>
                    </tr>
                </template>
                </tbody>
                </v-simple-table>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialogAddInvoice" persistent max-width="400">
                <v-card>
                    <v-card-title class="headline">Inserir fatura manual</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="inputInvoice.typeDoc"
                                        label="Tipo Documento"
                                        outlined
                                        hide-details
                                        dense
                                ></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        v-model="inputInvoice.serie"
                                        label="Serie"
                                        outlined
                                        hide-details
                                        dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="inputInvoice.numDoc"
                                        label="Núm. Doc"
                                        outlined
                                        hide-details
                                        dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="toggleDialogAddInvoiceNumber()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmInvoiceNumber()" color="success">Confirmo</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogInfo" persistent max-width="400">
                <v-card>
                    <v-card-title>Faturação</v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <i v-if="dialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                            <i v-if="!dialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                            <p class="headline">{{ dialog.message }}</p>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="dialogInfo = false">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: function () {
        return {
            pendingInvoices: [],
            pendingInvoicesOriginal: [],
            totalInvoices: 0,
            filters: {
                amazonOrderId: ''
            },
            dialogAddInvoice: false,
            dialogInfo: false,
            inputInvoice: {
                serie: '',
                numDoc: '',
                typeDoc: ''
            },
            dialog: {
                error: false,
                message: ''
            }
        }
    },
    private: {
        currentDate: ''
    },
    created() {
        document.title = 'Faturação Amazon'
        this.getPendingInvoices();
    },
    methods: {
        getPendingInvoices() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/bikeplanet/invoice-amazon/pending'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pendingInvoices = responseData.pendingInvoices;
                            this.pendingInvoicesOriginal = responseData.pendingInvoices;
                            this.totalInvoices = responseData.totalInvoices;
                        }
                        loader.hide()
                    })
                    .catch(() => {
                        loader.hide()
                    })
        },
        filter() {
            this.pendingInvoices = this.pendingInvoicesOriginal;

            if (this.filters.amazonOrderId !== '') {
                this.pendingInvoices = this.pendingInvoices.filter(line => line.amazonOrderId.includes(this.filters.amazonOrderId));
            }
        },
        toggleDialogAddInvoiceNumber() {
            this.dialogAddInvoice = !this.dialogAddInvoice;
        },
        confirmInvoiceNumber(invoice) {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/bikeplanet/invoice-amazon/' + invoice.amazonOrderId + "/saveInvoice",
                data: {
                    invoiceInfo: this.inputInvoice
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pendingInvoices.forEach((invoiceFor) => {
                                if (invoiceFor.amazonOrderId === invoice.amazonOrderId) {
                                    invoiceFor.hide = true;
                                }
                            });
                            invoice.hide = true;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        makeInvoice(invoice) {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/bikeplanet/invoice-amazon/' + invoice.amazonOrderId + '/makeInvoice',
                data: {
                    invoice: invoice
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            invoice.hide = true;
                            this.dialog.message = responseData.data.Message;
                            this.dialog.error = false;
                        } else {
                            this.dialog.error = true;
                            this.dialog.message = responseData.data.Message;
                        }
                        this.dialogInfo = true;
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        recalculateValues(invoice) {
            if (invoice.VATRate != 0) {
                let IVA = parseFloat('1.' + invoice.VATRate);
                for (let i in invoice.products) {
                    if (invoice.products[i].itemTax == 0.0) {
                        let valueExVAT = Math.round((invoice.products[i].itemPrice / IVA) * 100) / 100;
                        invoice.products[i].itemTax = invoice.products[i].itemPrice - valueExVAT;
                        invoice.products[i].itemPrice = valueExVAT;
                    }
                }

                if (invoice.shippingTax == 0.0) {
                    if (invoice.shippingPrice - invoice.shipPromotionDiscount > 0) {
                        let shipValueExVAT = Math.round((invoice.shippingPrice / IVA) * 100) / 100;
                        let shipDiscountValueExVAT = Math.round((invoice.shipPromotionDiscount / IVA) * 100) / 100;
                        invoice.shippingTax = invoice.shippingPrice - shipValueExVAT;
                        invoice.shippingPrice = shipValueExVAT;
                        invoice.shipPromotionDiscount = shipDiscountValueExVAT;
                    }
                }

                if (invoice.giftWrapTax == 0.0) {
                    let giftWrapExVAT = Math.round((invoice.giftWrapPrice / IVA) * 100) / 100;
                    invoice.giftWrapTax = invoice.giftWrapPrice - giftWrapExVAT;
                    invoice.giftWrapPrice = giftWrapExVAT;
                }
            }
        },
        totalOrder(order) {
            let someProducts = 0;
            order.products.forEach(function (product) {
                someProducts += parseFloat(product.itemPrice) + parseFloat(product.itemTax) + parseFloat(product.itemPromotionDiscount);
            });
            if (parseFloat(order.shippingPrice) + parseFloat(order.shipPromotionDiscount) > 0) {
                someProducts += parseFloat(order.shippingPrice) + parseFloat(order.shippingTax) + parseFloat(order.shipPromotionDiscount);
            }
            someProducts += parseFloat(order.giftWrapPrice) + parseFloat(order.giftWrapTax);

            return someProducts;
        },
        differentDate(line) {
            if (line.date !== this.currentDate) {
                this.currentDate = line.date
                return true
            }
            return false
        },
    }
}

</script>
