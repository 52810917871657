<style>
.selectedPage {
  font-weight: bold;
  color: blue;
  font-size: 21px;
}
.selectedPage:hover{
  cursor:default !important;
}
.selectablePage{
  margin-left: 15px;
  text-align:center;
  display: inline-block;
  width: 1rem;
}
.selectablePage:hover{
  cursor:pointer;
  font-size: 21px;
  color: #5a5aff;
}

</style>
<template>
  <v-container fluid>

    <!-- HEADER -->
    <v-row class="content-header">
      <v-col class="col-8">
        <page-title
            title="Formações B2B - Localizações"
        ></page-title>
      </v-col>
      <v-col class="text-right col-2">
        <v-btn tile small class="mb-2" @click="cleanFilters()" color="error">Limpar Filtros</v-btn>
      </v-col>
      <v-col class="text-right col-2">
        <v-btn tile small class="mb-2" @click="dialogPlace=true;" color="info">Nova Localização</v-btn>
      </v-col>
    </v-row>

    <!-- Tabela Formações -->
    <v-row>
      <v-col>
        <div class="staff_v-card">
          <v-simple-table class="staff_table">
            <thead>
            <tr class="titles_row">
              <th style="width: 200px; cursor: pointer;" @click="orderList('id')" class="text-center">
                Id
                <i v-if="selectedHeader === 'id'" class="fas" :class="[headers['id'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('name')" class="text-center">
                Localização
                <i v-if="selectedHeader === 'name'" class="fas" :class="[headers['name'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('address')" class="text-center">
                Morada
                <i v-if="selectedHeader === 'address'" class="fas" :class="[headers['address'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('address2')" class="text-center">
                Morada 2
                <i v-if="selectedHeader === 'address2'" class="fas" :class="[headers['address2'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('zipCode')" class="text-center">
                Código Postal
                <i v-if="selectedHeader === 'zipCode'" class="fas" :class="[headers['zipCode'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('zipCodeLocation')" class="text-center">
                Localização do Cód. Postal
                <i v-if="selectedHeader === 'zipCodeLocation'" class="fas" :class="[headers['zipCodeLocation'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th style="cursor: pointer;" @click="orderList('created_at')" class="text-center">
                Data Criação
                <i v-if="selectedHeader === 'created_at'" class="fas" :class="[headers['created_at'] === 'asc' ? 'fa-angle-up' : 'fa-angle-down']"></i>
              </th>
              <th></th>
              <th></th>
            </tr>
            <tr class="filters_row">
              <td>
                <v-text-field
                    v-model="filters.id"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.name"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.address"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.address2"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.zipCode"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.zipCodeLocation"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                    v-model="filters.created_at"
                    outlined
                    hide-details
                    single-line
                    @keyup.enter="updateTable"
                ></v-text-field>
              </td>
              <td></td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr v-if="loading">
              <td colspan="3">
                <v-progress-linear indeterminate></v-progress-linear>
              </td>
            </tr>
            <tr v-for="place in pagedPlaces">
              <td class="text-center">{{ place.id }}</td>
              <td>{{ place.name }}</td>
              <td>{{ place.address }}</td>
              <td>{{ place.address2 }}</td>
              <td class="text-center">{{ place.zipCode }}</td>
              <td>{{ place.zipCodeLocation }}</td>
              <td >{{ place.created_at }}</td>
              <td class="text-center">
                <v-btn small v-if="writePermission('course/places')" color="primary"
                       @click="placeEdition(place)">
                  Editar
                </v-btn>
              </td>
              <td class="text-center">
                <v-btn small v-if="writePermission('course/places')" color="error"
                       @click="removingPlace(place)">
                  <i class="fas fa-times"></i>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogPlace" persistent max-width="720">
      <v-card>
        <v-card-title v-if="editPlace === false">
          <strong>Criar Nova Localização</strong>
        </v-card-title>
        <v-card-title v-if="editPlace === true">
          <strong>Editar Localização</strong>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">

<!--            <p class="mb-0"><strong>Nome Localização: </strong></p>-->
            <v-text-field class="mb-0" dense v-model="place.name" label="Nome Localização" outlined hide-details></v-text-field>

<!--            <p class="mt-7 mb-0"><strong>Morada: </strong></p>-->
            <v-text-field class="mt-7 mb-0" dense v-model="place.address" label="Morada" outlined hide-details></v-text-field>

<!--            <p class="mt-7  mb-0"><strong>Morada 2: </strong></p>-->
            <v-text-field class="mt-7 mb-0" dense v-model="place.address2" label="Morada 2" outlined hide-details></v-text-field>


<!--            <p class="mt-7 mb-0"><strong>Código Postal: </strong></p>-->
            <v-text-field class="mt-7 mb-0" dense v-model="place.zipCode" label="Código Postal" outlined hide-details></v-text-field>

<!--            <p class="mt-7 mb-0"><strong>Localização do Cód. Postal: </strong></p>-->
            <v-text-field class="mt-7 mb-0" dense v-model="place.zipCodeLocation" label="Localização do Cód. Postal" outlined hide-details></v-text-field>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cleanPlace" color="error">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="createPlace(place)" color="success" :disabled="placeDataValidator" v-if="editPlace === false">
            Criar Formação
          </v-btn>
          <v-btn @click="updatePlace(place)" color="success" :disabled="placeDataValidator" v-if="editPlace === true">
            Confimar Atualização
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRemovePlace" persistent max-width="400">
      <v-card>
        <v-card-title>
          Eliminar Localização
        </v-card-title>
        <v-card-text>
          Confirma que pretende eliminar a localização?
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" @click="dialogRemovePlace = false">Cancelar</v-btn>
          <v-btn color="success" style="margin-left: auto" @click="deletePlace(place)">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>


<script>
import _ from "lodash";

export default {
  data: () => ({
    menu2: false,
    menu3: false,
    loading: false,
    places: [],
    editPlace: false,
    total: 0,
    originalPlaces: [],
    pagination: {
      rowsPerPage: 250,
      page: 1
    },
    filters: {
      initialDate: '',
      finalDate: '',
      id: '',
      name: '',
      address: '',
      address2: '',
      zipCode: '',
      zipCodeLocation: '',
      created_at: ''
    },
    itemPerPage: [
      {text: '50', value: 50},
      {text: '100', value: 100},
      {text: '250', value: 250},
      {text: '500', value: 500}
    ],
    currentDate: new Date().toISOString().substr(0, 10),
    selectedHeader: "id",
    headers: {
      "id": "asc",
      "name": "asc",
      "address": "asc",
      "address2": "asc",
      "zipCode": "asc",
      "zipCodeLocation": "asc",
      "created_at": "desc",
    },
    dialogPlace: false,
    dialogRemovePlace: false,
    place: {
      name: '',
      address: '',
      address2: '',
      zipCode: '',
      zipCodeLocation: '',
      created_at: ''
    }
  }),
  mounted() {
    document.title = 'Formações - Localizações';
    this.initialize()
  },
  methods: {
    initialize() {
      this.places = [];
      this.loading = true;

      let request = {
        url: '/api/staff/courses/places',
        data: {
          pagination: this.pagination
        }
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              this.originalPlaces = responseData.data;
              this.places = responseData.data;
              this.total = this.places.length;
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
      this.loading = false
    },
    orderList(col){
      if(col == this.selectedHeader)
        this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
      else
        this.headers[this.selectedHeader] = "desc";

      this.selectedHeader = col;
      this.pagination.page=1;

    },
    renderDuration(duration) {
      var modDays = duration % 86400;
      var days = (duration - modDays) / 86400;
      var labelDays = '';
      if (days > 0) {
        labelDays = days + 'D ';
      }
      //obter as horas
      var modHours = modDays % 3600;
      var hours = (modDays - modHours) / 3600;
      //obter os minutos
      var modMinutes = modHours % 60;
      var minutes = (modHours - modMinutes) / 60;
      //os segundos sao o resto de tudo (modMinutes neste caso)
      return labelDays + hours + 'h ' + minutes + 'm ' + modMinutes + 's';
    },
    updateTable() {
      this.places= this.originalPlaces

      if (this.filters.initialDate !== '') {
        this.places = this.places.filter(place => place.created_at >= this.filters.initialDate + ' 00:00:00')
      }
      if (this.filters.finalDate !== '') {
        this.places = this.places.filter(place => place.created_at <= this.filters.finalDate + ' 23:59:59')
      }
      if (this.filters.id !== '') {
        this.places = this.places.filter(place => place.id == this.filters.id)
      }
      if (this.filters.name !== '') {
        this.places = this.places.filter(place =>
            place.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.name.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.address !== '') {
        this.places = this.places.filter(place =>
            place.address.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.address.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.address2 !== '') {
        this.places = this.places.filter(place =>
            place.address2.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.address2.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.zipCode !== '') {
        this.places = this.places.filter(place => place.zipCode.includes(this.filters.zipCode))
      }
      if (this.filters.zipCodeLocation !== '') {
        this.places = this.places.filter(place =>
            place.zipCodeLocation.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(
                this.filters.zipCodeLocation.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")))
      }
      if (this.filters.created_at !== '') {
        this.places = this.places.filter(place => place.created_at != null && place.created_at.includes(this.filters.created_at))
      }

      this.total = this.places.length

    },
    cleanFilters(){
      this.filters = _.mapValues(this.filters, () => '')
      this.updateTable()
    },
    cleanPlace(){
      this.place = _.mapValues(this.place, () => '')

      this.dialogRemovePlace = false
      this.dialogPlace=false
      this.editPlace= false
    },
    deletePlace(place) {

      let request = {
        url: '/api/staff/courses/places/'+place.id,
      }

      ApiWS.dispatch('makeDelete', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Registo eliminado com sucesso!',
                type: 'success'
              })

              this.cleanPlace()
              this.initialize()
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    createPlace(place) {
      let request = {
        url: '/api/staff/courses/places',
        data: place
      }

      ApiWS.dispatch('makePost', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Formação criada com sucesso!',
                type: 'success'
              })

              this.cleanPlace()

              this.initialize()
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    placeEdition(place){
      this.cleanPlace()
      Object.assign(this.place, place)

      this.editPlace = true
      this.dialogPlace = true
    },
    updatePlace(place) {

      let request = {
        url: '/api/staff/courses/places/' + place.id,
        data: this.place
      }

      ApiWS.dispatch('makePut', request)
          .then(response => {

            let responseData = response.data

            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Formação atualizada com sucesso!',
                type: 'success'
              })

              this.cleanPlace()
              this.initialize()
            }
          })
          .catch(response => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
    writePermission(permission) {
      return User.getters.writePermission(permission)
    },
    removingPlace(place) {
      Object.assign(this.place, place)
      this.dialogRemovePlace=true
    }
  },

  computed: {
    getTotalPages() {
      return Math.ceil(this.total / this.pagination.rowsPerPage)
    },
    pagedPlaces: function(){
      return this.orderedPlaces.slice((this.pagination.page-1)*this.pagination.rowsPerPage,this.pagination.page*this.pagination.rowsPerPage);
    },
    orderedPlaces: function () {
      return _.orderBy(this.places, this.selectedHeader,this.headers[this.selectedHeader]);
    },
    placeDataValidator: function () {
      return  !(this.place.name !== '' &&
          this.place.address !== '' &&
          this.place.address2 !== '' &&
          this.place.zipCode !== '' &&
          this.place.zipCodeLocation !== '' );
    }
  }
}
</script>
