<template>
    <v-container fluid>
      <v-row>
        <productsStock></productsStock>
      </v-row>
        <v-row>
            <v-col sm="6">
                <last-sales></last-sales>
            </v-col>
            <v-col sm="6">
                <last-orders></last-orders>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <pending-receipts></pending-receipts>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import PendingReceipts from "./Widgets/PendingReceipts";
    import LastSales from "./Widgets/LastSales";
    import LastOrders from "./Widgets/LastOrders";
    import ProductsStock from "./Widgets/ProductsStock";

    export default {
        components: {
            ProductsStock,
            LastOrders,
            LastSales,
            PendingReceipts
        },
        computed: {
            profile() {
                return User.getters.getProfileId
            }
        }
    }
</script>
