<style scoped>
    .staff_table th, .staff_table td {
        height: 32px;
        font-size: 10px;
        padding: 0 4px;
    }
</style>
<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        :title="$t('sales')+' Por Secção'"
                ></page-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="3">
                <v-select
                        v-model="filters.year"
                        label="Ano"
                        :items="years"
                        @change="initialize"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>Secção</th>
                            <th class="text-right" style="background-color: #d9edf7">Janeiro</th>
<!--                            <th class="text-right" style="background-color: #d9edf7">%</th>-->
                            <th class="text-right">Fevereiro</th>
<!--                            <th class="text-right">%</th>-->
                            <th class="text-right" style="background-color: #d9edf7">Março</th>
<!--                            <th class="text-right" style="background-color: #d9edf7">%</th>-->
                            <th class="text-right">Abril</th>
<!--                            <th class="text-right">%</th>-->
                            <th class="text-right" style="background-color: #d9edf7">Maio</th>
<!--                            <th class="text-right" style="background-color: #d9edf7">%</th>-->
                            <th class="text-right">Junho</th>
<!--                            <th class="text-right">%</th>-->
                            <th class="text-right" style="background-color: #d9edf7">Julho</th>
<!--                            <th class="text-right" style="background-color: #d9edf7">%</th>-->
                            <th class="text-right">Agosto</th>
<!--                            <th class="text-right">%</th>-->
                            <th class="text-right" style="background-color: #d9edf7">Setembro</th>
<!--                            <th class="text-right" style="background-color: #d9edf7">%</th>-->
                            <th class="text-right">Outubro</th>
<!--                            <th class="text-right">%</th>-->
                            <th class="text-right" style="background-color: #d9edf7">Novembro</th>
<!--                            <th class="text-right" style="background-color: #d9edf7">%</th>-->
                            <th class="text-right">Dezembro</th>
<!--                            <th class="text-right">%</th>-->
                            <th class="text-right" style="background-color: #d9edf7">Total</th>
                            <th class="text-right" style="background-color: #d9edf7">%</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="27">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="sale in salesBySection">
                            <td>{{ sale.CDU_CC }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.JAN) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $formatPercentage(sale.JANP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.FEB) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap">{{ $formatPercentage(sale.FEBP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.MAR) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $formatPercentage(sale.MARP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.APR) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap">{{ $formatPercentage(sale.APRP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.MAY) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $formatPercentage(sale.MAYP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.JUN) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap">{{ $formatPercentage(sale.JUNP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.JUL) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $formatPercentage(sale.JULP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.AUG) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap">{{ $formatPercentage(sale.AUGP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.SEP) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $formatPercentage(sale.SEPP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.OCT) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap">{{ $formatPercentage(sale.OCTP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.NOV) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $formatPercentage(sale.NOVP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap">{{ $money(sale.DEC) }}</td>
<!--                            <td class="text-right" style="white-space: nowrap">{{ $formatPercentage(sale.DECP) }}</td>-->
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $money(sale.CURRENT_YEAR) }}</td>
                            <td class="text-right" style="white-space: nowrap; background-color: #d9edf7">{{ $formatPercentage(sale.CURRENT_YEARP) }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        data: () => ({
            loading: false,
            headers: [],
            salesBySection: [],
            filters: {
                year: new Date().getFullYear()
            },
            years: [
                new Date().getFullYear(),
                new Date().getFullYear() - 1,
                new Date().getFullYear() - 2,
                new Date().getFullYear() - 3
            ]
        }),
        created() {
            document.title = i18n.t('sales') + ' Por Secção'
            this.initialize()
        },
        methods: {
            initialize() {
                this.loading = true
                this.salesByBrand = []
                Reports.dispatch('getSalesBySection', this.filters)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.headers = responseData.headers
                            this.salesBySection = responseData.salesBySection
                            this.loading = false
                        } else {
                            this.loading = false
                        }
                    })
                    .catch(response => {
                        this.loading = false
                    })
            },
            formatPercentage(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' %'
            }
        }
    }
</script>
