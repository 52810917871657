import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const Order = new Vuex.Store({
    actions: {
        getOrdersToday(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.ordersToday())
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getAllOrders(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.ordersAll())
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getOrdersShipped(context, flag) {
            return new Promise((resolve, reject) => {
                axios.get(api.ordersShipped() + '/' + flag)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        archiveShipped(context, item) {
            return new Promise((resolve, reject) => {
                let data = {
                    'shippedRef': item.reference
                }

                axios.post(api.orderShippedArchive(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        createInvoiceProForma(context, order) {
            return new Promise((resolve, reject) => {
                let data = {
                    client: order.client,
                    products: order.products
                }

                axios.post(api.orderCreateProForma(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        transferBikePlanet(context, order) {
            return new Promise((resolve, reject) => {
                let data = {
                    products: order.products
                }

                axios.post(api.orderTransferBikePlanet(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getOrdersWaitingShipping(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters.filters,
                    pagination: filters.pagination
                }

                axios.post(api.orderWaitingShipping(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getShippingBoxes(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.boxesShipping())
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        hideDoc(context, id) {
            return new Promise((resolve, reject) => {
                let data = {
                    id: id
                }

                axios.post(api.orderHideDoc(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        recoverDoc(context, doc) {
            return new Promise((resolve, reject) => {
                let data = {
                    doc: doc
                }

                axios.post(api.orderRecoverDoc(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getTrackingNumber(context, order) {
            return new Promise((resolve, reject) => {
                let data = {
                    courier: order.courier,
                    clientCode: order.clientCode,
                    clientName: order.clientName,
                    orders: order.orders
                }

                axios.post(api.orderTrackingNumber(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getCustomerDetail(context, order) {
            return new Promise((resolve, reject) => {
                let data = {
                    clientCode: order.clientCode,
                    idDoc: order.idDoc
                }

                axios.post(api.orderDetailClient(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        createShippingLabel(context, shipping) {
            return new Promise((resolve, reject) => {
                axios.post(api.orderCreateShippingLabel(), shipping)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getDetail(context, order) {
            return new Promise((resolve, reject) => {
                axios.post(api.orderDetail(), order)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getStocks(context, order) {
            return new Promise((resolve, reject) => {
                axios.post(api.orderStocks(), order)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        printManifestoSEUR(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.orderPrintManifestoSEUR())
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    }
})

export default Order
