import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading && _vm.reloadNeed)?_c(VCard,[_c(VCardTitle,{staticStyle:{"background-color":"black","color":"white","margin-bottom":"30px"}},[_c('span',{staticClass:"pr-1",staticStyle:{"color":"red"}},[_vm._v(_vm._s(_vm.user.entity))]),_vm._v(" - "+_vm._s(_vm.user.email)+" "),_c(VSpacer),_c(VBtn,{staticClass:"float-right ml-2 white--text",attrs:{"tile":"","small":"","color":"white","depressed":"","outlined":"","id":"delete-question"},on:{"click":function($event){return _vm.callCloseOnParent()}}},[_vm._v(" FECHAR "),_c(VIcon,{attrs:{"size":"20"}},[_vm._v("mdi-close")])],1)],1),(_vm.userResponses.length > 0)?_c(VCardText,_vm._l((_vm.userResponses),function(response,index){return _c(VRow,{staticClass:"pl-2 pb-1 form-question-user-response"},[_c(VCol,{staticClass:"pb-0 pt-0"},[_c('h4',{staticClass:"mt-2"},[_vm._v(_vm._s(response.header))]),_c('p',{staticClass:"pl-2 mb-0"},[_vm._v("R: "+_vm._s(response.response))]),(index != _vm.userResponses.length - 1)?_c(VDivider,{staticClass:"divider-aux mt-1"}):_vm._e()],1)],1)}),1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }