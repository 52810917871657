import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const Profile = new Vuex.Store({
    actions: {
        getProfiles(context) {
            return new Promise((resolve, reject) => {
                axios.get(api.profiles())
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        saveProfile(context, profile) {
            return new Promise((resolve, reject) => {
                let data = {
                    profile: {
                        profile: profile.profile
                    }
                }
                axios.post(api.profiles(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        updateProfile(context, profile) {
            return new Promise((resolve, reject) => {
                let data = {
                    profile: {
                        id: profile.id,
                        profile: profile.profile
                    }
                }
                axios.put(api.profiles(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        removeProfile(context, profile) {
            return new Promise((resolve, reject) => {
                axios.delete(api.profiles()+'/'+profile.id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getPermissions(context, profile) {
            return new Promise((resolve, reject) => {
                axios.get(api.profilesPermissions()+'/'+profile.id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        updatePermission(context, permission) {
            return new Promise((resolve, reject) => {
                let data = {
                    permission: permission
                }
                console.log(data)
                axios.post(api.profilesPermissions(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    }
})

export default Profile
