<template>
    <div>
        <v-card tile min-height="300">
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th>{{ $t('product') }}</th>
                                <th>{{ $t('description') }} {{ $t('product') }}</th>
                                <th>Código de Barras PRI</th>
                                <th>EAN-13</th>
                                <th class="text-center">Stock Mínimo</th>
                                <th class="text-center">Stock Reposição</th>
                                <th class="text-center">Qtd Economica</th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">A1</span>
                                        </template>
                                        <span>{{ descWarehouse('A1') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">A2</span>
                                        </template>
                                        <span>{{ descWarehouse('A2') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">A3</span>
                                        </template>
                                        <span>{{ descWarehouse('A3') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">A3.1</span>
                                        </template>
                                        <span>{{ descWarehouse('A3.1') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">A5</span>
                                        </template>
                                        <span>{{ descWarehouse('A5') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">F1</span>
                                        </template>
                                        <span>{{ descWarehouse('F1') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">G1</span>
                                        </template>
                                        <span>{{ descWarehouse('G1') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">T1</span>
                                        </template>
                                        <span>{{ descWarehouse('T1') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">V1</span>
                                        </template>
                                        <span>{{ descWarehouse('V1') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">V2</span>
                                        </template>
                                        <span>{{ descWarehouse('V2') }}</span>
                                    </v-tooltip>
                                </th>
                                <th class="text-center">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="line in stocks">
                                <td>{{ line.Artigo }}</td>
                                <td>{{ line.DescProduct }}</td>
                                <td>
<!--                                    <v-btn small outlined color="primary" @click="toggleDialogChangeStock('CodBarras', line.CodBarras, line.Artigo)" :disabled="!writePermission('products/product_stock')">-->
<!--                                        {{ line.CodBarras }}-->
<!--                                    </v-btn>-->
                                    {{ line.CodBarras }}
                                </td>
                                <td>
<!--                                    <v-btn small outlined color="primary" @click="toggleDialogChangeStock('CDU_EAN', line.CDU_EAN, line.Artigo)" :disabled="!writePermission('products/product_stock')">-->
<!--                                        {{ line.CDU_EAN }}-->
<!--                                    </v-btn>-->
                                    {{ line.CDU_EAN }}
                                </td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary" @click="toggleDialogChangeStock('stockMinimum', line.STKMinimo, line.Artigo)" :disabled="!writePermission('products/product_stock')">
                                        {{ line.STKMinimo }}
                                    </v-btn>
                                </td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary" @click="toggleDialogChangeStock('stockReposition', line.STKReposicao, line.Artigo)" :disabled="!writePermission('products/product_stock')">
                                        {{ line.STKReposicao }}
                                    </v-btn>
                                </td>
                                <td class="text-center">
                                    <v-btn small outlined color="primary" @click="toggleDialogChangeStock('qtyEconomica', line.QtdEconomica, line.Artigo)" :disabled="!writePermission('products/product_stock')">
                                        {{ line.QtdEconomica }}
                                    </v-btn>
                                </td>
                                <td class="text-center">{{ parseFloat(line.A1) }}</td>
                                <td class="text-center">{{ parseFloat(line.A2) }}</td>
                                <td class="text-center">{{ parseFloat(line.A3) }}</td>
                                <td class="text-center">{{ parseFloat(line.A3_1) }}</td>
                                <td class="text-center">{{ parseFloat(line.A5) }}</td>
                                <td class="text-center">{{ parseFloat(line.F1) }}</td>
                                <td class="text-center">{{ parseFloat(line.G1) }}</td>
                                <td class="text-center">{{ parseFloat(line.T1) }}</td>
                                <td class="text-center">{{ parseFloat(line.V1) }}</td>
                                <td class="text-center">{{ parseFloat(line.V2) }}</td>
                                <td class="text-center">{{ parseFloat(line.Total) }}</td>
                            </tr>
                            <tr>
                                <th colspan="7"></th>
                                <th class="text-center">{{ totalA1 }}</th>
                                <th class="text-center">{{ totalA2 }}</th>
                                <th class="text-center">{{ totalA3 }}</th>
                                <th class="text-center">{{ totalA3_1 }}</th>
                                <th class="text-center">{{ totalA5 }}</th>
                                <th class="text-center">{{ totalF1 }}</th>
                                <th class="text-center">{{ totalG1 }}</th>
                                <th class="text-center">{{ totalT1 }}</th>
                                <th class="text-center">{{ totalV1 }}</th>
                                <th class="text-center">{{ totalV2 }}</th>
                                <th class="text-center">{{ total }}</th>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialogChangeStock" width="400">
            <v-card>
                <v-toolbar dense dark color="info">
                    <v-toolbar-title class="text-uppercase">{{ $t('change') }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogChangeStock = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field :label="$t(optionChangeStock)" v-model="input.stock" hide-details></v-text-field>
                            <span v-if="(optionChangeStock == 'stockReposition') && (input.stock < 2)" style="color: red">Stock Reposição tem de ser no mínimo 2</span>
                            <span v-if="(optionChangeStock == 'stockMinimum') && (input.stock < 1)" style="color: red">Stock Mínimo tem de ser no mínimo 1</span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="stocks.length > 1" tile small color="primary" @click="saveStock('all')" :disabled="((optionChangeStock == 'stockReposition') && (input.stock < 2)) || ((optionChangeStock == 'stockMinimum') && (input.stock < 1)) ? true : false">Guardar todos</v-btn>
                    <v-btn tile small  color="success" @click="saveStock('one')" :disabled="((optionChangeStock == 'stockReposition') && (input.stock < 2)) || ((optionChangeStock == 'stockMinimum') && (input.stock < 1)) ? true : false">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>

    export default {
        props: {
            productId: String,
        },
        data: function () {
            return {
                product: {},
                stocks: [],
                descriptionWarehouse: [],
                dialogChangeStock: false,
                optionChangeStock: '',
                input: {
                    stock: '',
                    product: ''
                }
            }
        },
        mounted() {
            this.getProduct()
        },
        methods: {
            getProduct() {
                let request = {
                    url: api.products() + '/' + this.productId + '/stock'
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.product = responseData.product
                            this.stocks = responseData.stocks
                            this.descriptionWarehouse = responseData.descriptionWarehouse
                        }
                    })
            },
            descWarehouse(warehouse) {
                for (let i = 0; i < this.descriptionWarehouse.length; i++) {
                    if (warehouse === this.descriptionWarehouse[i].Armazem) {
                        return this.descriptionWarehouse[i].Descricao
                    }
                }
            },
            toggleDialogChangeStock(option, value, product) {
                this.dialogChangeStock = true
                this.optionChangeStock = option
                this.input.stock = value
                this.input.product = product
            },
            saveStock(option) {
                let request = {
                    url: api.productsUpdateStockMinRep(),
                    data: {
                        product: this.input.product,
                        stock: this.input.stock,
                        option: option,
                        optionStock: this.optionChangeStock
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.getProduct()
                            this.dialogChangeStock = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao atualizar stock!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            writePermission(permission) {
                return User.getters.writePermission(permission)
            }
        },
        computed: {
            labelStock() {
                if (optionChangeStock === 'minimo') {
                    return ''
                }
            },
            total() {
                let total = 0;
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.Total)
                })
                return total
            },
            totalA1() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.A1)
                })
                return total
            },
            totalA2() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.A2)
                })
                return total
            },
            totalA3() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.A3)
                })
                return total
            },
            totalA3_1() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.A3_1)
                })
                return total
            },
            totalA5() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.A5)
                })
                return total
            },
            totalF1() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.F1)
                })
                return total
            },
            totalG1() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.G1)
                })
                return total
            },
            totalT1() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.T1)
                })
                return total
            },
            totalV1() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.V1)
                })
                return total
            },
            totalV2() {
                let total = 0
                this.stocks.forEach(function (line) {
                    total += parseFloat(line.V2)
                })
                return total
            },
        }
    }
</script>
