<style scoped>
.v-input__append-inner {
    margin-top: 5px !important;
}

.staff_table tr .v-btn {
    display: none;
}

.staff_table tr:hover .v-btn {
    display: block;
}

</style>

<template>
    <v-container fluid>

        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Faturas de Oferta"
                        :subtitle="$t('show') + ' ' + lines.length + ' de ' + originalLines.length + ' documentos'"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  class="ml-2 mb-2" @click="resetFilters()" color="error">Limpar Filtros</v-btn>
                <v-btn tile small  class="ml-2 mb-2" @click="getLines()" color="info">Atualizar</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th style="width: 100px;">Data</th>
                            <th style="width: 100px;">Entidade</th>
                            <th>Nome</th>
                            <th style="width: 100px;">NumDoc</th>
<!--                            <th>Motivo</th>-->
                            <th class="text-right" style="width: 100px;">ValorTotal</th>
                            <th class="text-right" style="width: 100px;">ValorIva</th>
                            <th class="text-center" style="width: 100px;">Duplicado</th>
<!--                            <th class="text-center" style="width: 100px;">IVA</th>-->
                            <th class="text-center" style="width: 100px;">Enviar Email</th>
                        </tr>
                        <tr class="filters_row">
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Data"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Entidade"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.Nome"
                                              @change="filterLines()"></v-text-field>
                            </th>
                            <th>
                                <v-text-field outlined hide-details v-model="filters.NumDoc"
                                              @keyup="filterLines()"></v-text-field>
                            </th>
<!--                            <th>-->
<!--                                <v-text-field outlined hide-details v-model="filters.Motivo"-->
<!--                                              @keyup="filterLines()"></v-text-field>-->
<!--                            </th>-->
                            <th></th>
                            <th></th>
                            <th>
                                <v-select
                                        v-model="filters.Duplicado"
                                        :items="filtersDuplicado"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterLines"
                                ></v-select>
                            </th>
<!--                            <th>-->
<!--                                <v-select-->
<!--                                        v-model="filters.IVA"-->
<!--                                        :items="filtersDuplicado"-->
<!--                                        item-text="text"-->
<!--                                        item-value="value"-->
<!--                                        outlined-->
<!--                                        hide-details-->
<!--                                        @change="filterLines"-->
<!--                                ></v-select>-->
<!--                            </th>-->
                            <th></th>
                        </tr>

                        </thead>
                        <tbody v-if="loading">
                        <tr>
                            <td colspan="10">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        </tbody>
                        <tbody v-if="!loading">
                        <tr v-for="line in lines">
                            <td>{{ line.Data.substring(0, 10) }}</td>
                            <td>
                                <div @click="selectEntidade(line.Entidade)" style="cursor: pointer">
                                    {{ line.Entidade }}
                                </div>
                            </td>
                            <td>
                                <i v-show="line.TipoCred === '2'" class="fas fa-minus-circle" style="color: red"></i>
                                {{ line.Nome }}
                            </td>
                            <td>{{ line.NumDoc }}
                                <i class="far fa-file-pdf" style="color: red; cursor: pointer"
                                   @click="downloadPDF(line.Serie, line.TipoDoc, line.NumDoc)"></i>
                            </td>
<!--                            <td>{{ line.MotivoEstorno }} - {{ line.Descricao }}</td>-->
                            <td class="text-right">{{ $money(line.TotalDocumento) }}</td>
                            <td class="text-right">{{ $money(line.TotalIva) }}</td>
                            <td class="text-center">
                                <v-icon v-if="line.CDU_CabVar1 > 0" color="green">done</v-icon>
                                <v-icon v-if="line.CDU_CabVar1 === 0" color="red" @click="update('recebido',line)">
                                    cancel
                                </v-icon>
                            </td>
<!--                            <td class="text-center">-->
<!--                                <v-icon v-if="line.TotalIva < 0" color="green">done</v-icon>-->
<!--                                <v-icon v-if="line.TotalIva === 0" color="red">-->
<!--                                    cancel-->
<!--                                </v-icon>-->
<!--                            </td>-->
                            <td class="text-center">
                                {{ line.CDU_CabVar1ENC }}
                                <v-icon color="blue-grey" @click="update('email',line)">email</v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
export default {
    data: function () {
        return {
            loading: false,
            exporting: false,
            menu: false,
            lines: [],
            modosPag: [],
            condsPag: [],
            tiposDoc: [],
            originalLines: [],
            totalLines: [],
            filters: {
                Data: '',
                Entidade: '',
                Nome: '',
                NumDoc: '',
                Duplicado: 0,
                Motivo: '',
                IVA: 1
            },
            filtersDuplicado: [
                {text: 'Todos', value: ''},
                {text: 'Sim', value: 1},
                {text: 'Não', value: 0}
            ],
        }
    },
    created() {
        document.title = 'Notas de Crédito'
        this.getLines();
    },
    methods: {
        getLines() {
            this.loading = true
            ApiWS.dispatch('makeGet', {url: '/api/staff/accounts/gift-invoices'})
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.originalLines = responseData.lines;
                        }
                        this.filterLines();
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
        },
        update(action, line) {
            let request = {
                url: '/api/staff/accounts/credits/update',
                data: {
                    action: action,
                    line: line
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.getLines();
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.data,
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        resetFilters() {
            this.filters = {
                Data: '',
                Entidade: '',
                Nome: '',
                NumDoc: '',
                Duplicado: 0,
            };
            this.filterLines();
        },
        selectEntidade(entidade) {
            this.filters.Entidade = entidade
            this.filterLines()
        },
        filterLines() {
            this.lines = this.originalLines;
            if (this.filters.Data !== '') {
                this.lines = this.lines.filter(line => line.Data.includes(this.filters.Data));
            }
            if (this.filters.Entidade !== '') {
                this.lines = this.lines.filter(line => line.Entidade.toUpperCase() === this.filters.Entidade.toUpperCase());
            }
            if (this.filters.Nome !== '') {
                this.lines = this.lines.filter(line => line.Nome.toUpperCase().includes(this.filters.Nome.toUpperCase()));
            }
            // if (this.filters.Motivo !== '') {
            //     this.lines = this.lines.filter(line => line.MotivoEstorno != null && line.MotivoEstorno.toUpperCase().includes(this.filters.Motivo.toUpperCase()) || line.Descricao != null && line.Descricao.toUpperCase().includes(this.filters.Motivo.toUpperCase()));
            // }
            if (this.filters.Duplicado !== '') {
                this.lines = this.lines.filter(line => line.CDU_CabVar1 === this.filters.Duplicado);
            }
            // if (this.filters.IVA !== '') {
            //     if (this.filters.IVA === 0) {
            //         this.lines = this.lines.filter(line => line.TotalIva === 0);
            //     } else {
            //         this.lines = this.lines.filter(line => line.TotalIva !== 0);
            //     }
            // }
            if (this.filters.NumDoc !== '') {
                this.lines = this.lines.filter(line => line.NumDoc.toString().includes(this.filters.NumDoc));
            }
        },
        downloadPDF(serie, tipodoc, numdoc) {
            let request = {
                url: api.orderPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let request = {
                                url: api.orderDownloadPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
                            }
                            ApiWS.dispatch('makeDownload', request)
                                    .then(response => {
                                        var file = new Blob([response.data], {
                                            type: 'application/pdf'
                                        });
                                        var fileURL = URL.createObjectURL(file);
                                        var a = document.createElement('a');
                                        a.href = fileURL;
                                        a.target = '_blank';
                                        a.download = serie + '_' + tipodoc + '_' + numdoc + '.pdf';
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                    .catch(response => {
                                        Alert.dispatch('show', {
                                            snackbar: true,
                                            text: 'Erro de servidor!',
                                            type: 'error'
                                        })
                                    })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar PDF!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },

    }
}

</script>
