<style scoped>
table.table-docs {
    font-size: 12px;
    margin: 0;
    /*background-color: #d6e7f7;*/
    border: 1px solid #dddddd;
}

table.table-docs tr td {
    padding: 4px;
}

td.danger, th.danger {
    background-color: lightpink;
}

</style>
<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col cols="3">
                <page-title title="Reembolsos" subtitle="Processamento de recibos"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  :color="input.mode === 'SEUR' ? 'primary' : ''" @click="changeMode('SEUR')">
                    SEUR
                </v-btn>
                <v-btn tile small  :color="input.mode === 'REDUR' ? 'success' : ''" @click="changeMode('REDUR')">
                    REDUR
                </v-btn>
                <v-btn tile small  :color="input.mode === 'DELNEXT' ? 'success' : ''" @click="changeMode('DELNEXT')">
                    DELNEXT
                </v-btn>
                <v-btn tile small  :color="input.mode === 'CTT' ? 'success' : ''" @click="changeMode('CTT')">
                  CTT
                </v-btn>
                <v-btn tile small  :color="input.mode === 'CHEQUES' ? 'error' : ''" @click="changeMode('CHEQUES')">
                    CHEQUES
                </v-btn>

                <span class="ml-3">
                    <file-upload
                            v-if="input.mode==='DELNEXT'"
                            :url='urlDelnext'
                            btn-label="Importar DELNEXT"
                            :headers="headers"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            @success="onUploadSuccessRedur"
                            @error="onUploadErrorRedur"
                    ></file-upload>
                    <file-upload
                            v-if="input.mode==='REDUR'"
                            :url='urlRedur'
                            btn-label="Importar REDUR"
                            :headers="headers"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            @success="onUploadSuccessRedur"
                            @error="onUploadErrorRedur"
                    ></file-upload>
                    <file-upload
                            v-if="input.mode==='SEUR'"
                            :url='urlSeur'
                            btn-label="Importar SEUR"
                            :headers="headers"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            @success="onUploadSuccessSeur"
                            @error="onUploadErrorSeur"
                    ></file-upload>
                    <file-upload
                        v-if="input.mode==='CTT'"
                        :url='urlCtt'
                        btn-label="Importar CTT"
                        :headers="headers"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        @success="onUploadSuccessCtt"
                        @error="onUploadErrorCtt"
                    ></file-upload>
                    <v-btn tile small  v-if="input.mode==='CHEQUES'" color="error" width="170px" @click="printCheques()">Talão depósito</v-btn>
                </span>
            </v-col>
        </v-row>

        <!-- ORDERS TABLE -->
        <v-row>
            <v-col>
                <div class="staff_v-card">

                    <v-row>

                        <v-col cols="6" md="3">
                            <v-menu
                                    v-model="menuDate"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="input.date"
                                            label="Data Recibos"
                                            prepend-icon="event"
                                            dense
                                            outlined
                                            single-line
                                            readonly
                                            hide-details
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="input.date" @input="menuDate = false"></v-date-picker>
                                <v-date-picker v-model="input.date" @input="menuDate = false"></v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="6" md="3">
                            <v-text-field
                                    dense
                                    outlined
                                    single-line
                                    hide-details
                                    type="number"
                                    label="Total Recebido"
                                    v-model="input.amount"
                                    prepend-icon="euro_symbol"
                            ></v-text-field>
                        </v-col>

                        <v-spacer></v-spacer>

                        <v-col cols="6" md="3" class="text-right">
                            <v-btn v-if="input.mode==='CHEQUES'" small tile color="info" class="mr-3" @click="getCheques()">
                                Actualizar
                            </v-btn>
                            <v-btn
                                    v-if="totalSelected > 0"
                                    color="success"
                                    @click="makeReceipts()"
                                    :disabled="input.date === '' || input.amount === '' || parseFloat(input.amount) <= 0"
                            >Efetuar {{ totalSelected }} Recibos
                            </v-btn>
                        </v-col>

                    </v-row>

                    <v-simple-table class="staff_table">
                        <thead>
                        <!-- TABLE HEADERS -->
                        <tr>
                            <th width="1" class="text-center">
                                <input type="checkbox" v-model="checkboxAll" @click="selectAll()"/>
                            </th>
                            <th width="110px">Ref. Bicimax</th>
                            <th>
                                <span v-if="input.mode === 'SEUR'">Ref. SEUR</span>
                                <span v-if="input.mode === 'DELNEXT'">Ref. DELNEXT</span>
                                <span v-if="input.mode === 'CTT'">Ref. CTT</span>
                                <span v-if="input.mode === 'REDUR'">Albarán</span>
                                <span v-if="input.mode === 'CHEQUES'">Num. Cheque</span>
                            </th>
                            <th width="100px">Data</th>
                            <th>Nome</th>
                            <th width="100px">Cliente</th>
                            <th width="280px" style="padding: 0 5px">
                                <table width="280px" class="table-docs">
                                    <tr>
                                        <td width="100px">Documento</td>
                                        <td width="20px" class="text-right"></td>
                                        <td width="80px" class="text-right">Total</td>
                                        <td width="80px" class="text-right">Pendente</td>
                                    </tr>
                                </table>
                            </th>
                            <th width="40px"></th>
                            <th width="120px" class="text-right"
                                :class="{'danger': !compareValues(input.amount, totalDocuments)}">Total
                            </th>
                            <th width="120px" class="text-right"
                                :class="{'danger': !compareValues(input.amount, totalPending)}">Pendente
                            </th>
                            <th width="120px" class="text-right"
                                :class="{'danger': !compareValues(input.amount, totalSeur)}">Reembolso
                            </th>
                            <th width="120px" class="text-center">Recibo</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="payment in validPayments">
                            <td>
                                <input type="checkbox" v-model="payment.selected" @click="selectPayment(payment)"/>
                            </td>
                            <td>{{ payment.ref }}</td>
                            <td>
                                <span v-if="input.mode === 'SEUR'">{{ payment.number_exp }}</span>
                                <span v-if="input.mode === 'REDUR'">{{ payment.number_exp }}</span>
                                <span v-if="input.mode === 'CTT'">{{ payment.number_exp }}</span>
                                <span v-if="input.mode === 'DELNEXT'">{{ payment.number_exp }}</span>
                                <span v-if="input.mode === 'CHEQUES'">{{ payment.numCheque }}</span>
                            </td>
                            <td>{{ payment.data.substring(0,10) }}</td>
                            <td><span v-if="input.mode === 'SEUR'">{{
                                    payment.client_number
                                }}-</span>{{ payment.client_name }}
                            </td>
                            <td>
                                <template v-if="!payment.editEntidade">{{ payment.entidade }}</template>
                                <i v-if="payment.entidade === '' && !payment.editEntidade"
                                   class="fas fa-plus-circle blue--text" @click="payment.editEntidade = true"
                                   style="cursor: pointer"></i>
                                <v-text-field
                                        dense
                                        outlined
                                        single-line
                                        hide-details
                                        type="text"
                                        v-if="payment.editEntidade"
                                        v-model="payment.entidade"
                                        style="width: 100px; font-size: 12px"
                                ></v-text-field>
                            </td>
                            <td :class="{'danger': payment.docs.length == 0}">
                                <table width="280px" class="table-docs">
                                    <tr v-for="(doc,index) in payment.docs"
                                        :class="{'danger': doc.docType === 'GT' || doc.docType === 'GTG'}">
                                        <td width="20px">
                                            <i class="fas fa-minus-circle red--text" @click="removePendente(doc, index, payment)"
                                               style="cursor: pointer"></i>
                                        </td>
                                        <td width="100px">
                                            {{ doc.docType }} {{ doc.docNumber }}
                                        </td>
                                        <td width="20px" class="text-right">{{ doc.NumPrestacao }}</td>
                                        <td width="80px" class="text-right">{{ $money(doc.TotalDocumento) }}</td>
                                        <td width="80px" class="text-right">{{ $money(doc.ValorPendente) }}</td>
                                    </tr>
                                </table>
                            </td>
                            <td>
                                <i v-if="!compareValues(payment.totalPri, payment.reembolso) && payment.entidade "
                                   class="fas fa-plus-circle blue--text" @click="addDocument(payment)"
                                   style="cursor: pointer"></i>
                            </td>
                            <td class="text-right"
                                :class="{'danger': !compareValues(payment.reembolso, payment.totalPri)}">
                                {{ $money(payment.totalPri) }}
                            </td>
                            <td class="text-right"
                                :class="{'danger': !compareValues(payment.reembolso, payment.pendentePri)}">
                                {{ $money(payment.pendentePri) }}
                            </td>
                            <td class="text-right">{{ $money(payment.reembolso) }}</td>
                            <td class="text-center">
                                <template v-if="!payment.makingReceipt">
                                    <span :class="{'red--text':payment.recibo === 'ERRO'}">{{ payment.recibo }}</span>
                                </template>
                                <template v-if="payment.makingReceipt">
                                    <i class="fa fa-lg fa-cog fa-spin"></i>
                                </template>
                            </td>
                        </tr>
                        <tr v-if="validPayments.length >= 0">
                            <td colspan="8"></td>
                            <th class="text-right" :class="{'danger': !compareValues(input.amount, totalDocuments)}">
                                {{ $money(totalDocuments) }}
                            </th>
                            <th class="text-right" :class="{'danger': !compareValues(input.amount, totalPending)}">
                                {{ $money(totalPending) }}
                            </th>
                            <th class="text-right" :class="{'danger': !compareValues(input.amount, totalSeur)}">
                                {{ $money(totalSeur) }}
                            </th>
                            <td></td>
                        </tr>
                        </tbody>
                    </v-simple-table>

                </div>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">

            <!-- GENERIC DIALOG -->
            <v-dialog v-model="dialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ dialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">

                            <template v-if="dialog.loading">
                                <v-progress-circular indeterminate color="primary" size="100"></v-progress-circular>
                            </template>

                            <template v-if="dialog.progress">
                                <v-progress-circular rotate="-90" color="primary" size="100"
                                                     :value="dialog.progressCurrent">
                                    {{ dialog.progressStep }}/{{ dialog.progressTotal }}
                                </v-progress-circular>
                            </template>

                            <template v-if="!dialog.loading && !dialog.progress">
                                <i v-if="dialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!dialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ dialog.message }}</p>
                                <v-alert v-if="dialog.alert" type="error">
                                    {{ dialog.alertMessage }}
                                </v-alert>
                            </template>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="!dialog.loading && !dialog.progress" color="success" @click="dialog.open = false"
                               style="margin-left: auto">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- PENDENTES DIALOG -->
            <v-dialog v-model="pendentesDialog" persistent max-width="800">
                <v-card>
                    <v-card-title>
                        Adicionar documentos pendentes
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">

                            <v-progress-circular v-if="pendentesLoading" indeterminate color="primary"
                                                 size="100"></v-progress-circular>

                            <v-simple-table v-if="!pendentesLoading" style="background-color: transparent;">
                                <thead>
                                <tr>
                                    <th class="text-center">DataDoc</th>
                                    <th class="text-center">DataVenc</th>
                                    <th class="text-center">Documento</th>
                                    <th class="text-center"></th>
                                    <th class="text-center">Total</th>
                                    <th class="text-center">Pendente</th>
                                    <th width="50px"></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="doc in pendentes">
                                    <td class="text-center">{{ doc.DataDoc.substr(0, 10) }}</td>
                                    <td class="text-center">
                                        <span :class="{'red--text': isPast(doc.DataVenc)}">{{
                                                doc.DataVenc.substr(0, 10)
                                            }}</span>
                                    </td>
                                    <td class="text-center">{{ doc.TipoDoc }} {{ doc.NumDoc }}</td>
                                    <td class="text-right">{{ doc.NumPrestacao }}</td>
                                    <td class="text-right">{{ $money(doc.ValorTotal) }}</td>
                                    <td class="text-right">{{ $money(doc.ValorPendente) }}</td>
                                    <td>
                                        <v-btn small color="success" @click="addPendente(doc)">Adicionar</v-btn>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="default" @click="pendentesDialog = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text>Valor pendente: <strong>{{ remainingValue }}€</strong></v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </v-container>
</template>

<script>
import FileUpload from 'v-file-upload'

export default {
    components: {
        FileUpload
    },
    data: () => ({
        menuDate: false,
        input: {
            date: '', //new Date().toISOString().substr(0, 10),
            amount: '',
            mode: 'SEUR'
        },
        dialog: {
            open: false,
            loading: false,
            progress: false,
            error: false,
            alert: false,
            title: '',
            message: '',
            alertMessage: '',
            progressCurrent: 0,
            progressStep: 0,
            progressTotal: 0,
        },
        pendentes: [],
        pendentesLoading: false,
        pendentesDialog: false,
        totalDocuments: 0,
        totalPending: 0,
        totalSeur: 0,
        validPayments: [],
        urlSeur: '',
        urlRedur: '',
        urlCheques: '',
        urlDelnext: '',
        urlCtt: '',
        headers: {},
        filesUploaded: [],
        checkboxAll: false,
        makingReceipts: false,
        totalSelected: 0,
        linhasTotal: 0,
        linhasProcessadas: 0,
        linhasOK: 0,
        linhasNOK: 0,
        remainingValue: 0,
        selectedPayment: null,
    }),
    mounted() {
        document.title = 'Reembolsos';
        this.urlSeur = process.env.VUE_APP_API_URL + '/api/staff/accounts/payments/upload/xls/seur';
        this.urlRedur = process.env.VUE_APP_API_URL + '/api/staff/accounts/payments/upload/xls/redur';
        this.urlDelnext = process.env.VUE_APP_API_URL + '/api/staff/accounts/payments/upload/xls/delnext';
        this.urlCtt = process.env.VUE_APP_API_URL + '/api/staff/accounts/payments/upload/ctt';
        // this.urlCheques = process.env.VUE_APP_API_URL + api.paymentsUploadXlsCheques();
        this.headers = {'Authorization': 'Bearer ' + User.getters.getAccessToken};
    },
    methods: {
        changeMode(mode) {
            this.validPayments = []
            this.input.mode = mode
            this.checkboxAll = false
            this.totalSelected = 0
            this.input.amount = ''
            this.totalDocuments = 0
            this.totalPending = 0
            this.totalSeur = 0
            if (mode === 'CHEQUES') {
                this.input.date = new Date().toISOString().substr(0, 10);
                this.getCheques()
            }else{
                this.input.date = '';
            }
        },
        selectAll() {
            this.checkboxAll = !this.checkboxAll;
            this.validPayments.forEach((payment) => {
                payment.selected = this.checkboxAll;
            });
            this.validateCheckboxes();
        },
        selectPayment(payment) {
            payment.selected = !payment.selected;
            this.validateCheckboxes();
        },
        validateCheckboxes() {
            this.totalSelected = 0;
            this.validPayments.forEach((payment) => {
                if (payment.selected === true) {
                    this.totalSelected++;
                }
            });
        },
        onUploadSuccessSeur(response) {
            let responseData = response.target.response;
            if (responseData.status === 'OK') {
                this.checkboxAll = false;
                this.totalSelected = 0;
                this.input.amount = '';
                // this.input.date = new Date().toISOString().substr(0, 10);
                this.validPayments = responseData.lines;
                this.totalDocuments = responseData.totalDocumentos;
                this.totalPending = responseData.totalPendente;
                this.totalSeur = responseData.totalSeur;
            } else {
                this.newDialog('A processar o ficheiro XLS...');
                this.errorDialog(responseData.message)
            }
        },
        onUploadErrorSeur() {
            this.errorDialog('Ocorreu um erro ao importar o ficheiro');
        },
        onUploadSuccessRedur(response) {
            let responseData = response.target.response;
            if (responseData.status === 'OK') {
                this.checkboxAll = false;
                this.totalSelected = 0;
                this.input.amount = '';
                // this.input.date = new Date().toISOString().substr(0, 10);
                this.validPayments = responseData.lines;
                this.totalDocuments = responseData.totalDocumentos;
                this.totalPending = responseData.totalPendente;
                this.totalSeur = responseData.totalSeur;
            } else {
                this.newDialog('A processar o ficheiro XLS...');
                this.errorDialog(responseData.message)
            }
        },
        onUploadErrorRedur() {
            this.errorDialog('Ocorreu um erro ao importar o ficheiro');
        },
        onUploadSuccessCtt(response) {
          let responseData = response.target.response;
          if (responseData.status === 'OK') {
            this.checkboxAll = false;
            this.totalSelected = 0;
            this.input.amount = '';
            // this.input.date = new Date().toISOString().substr(0, 10);
            this.validPayments = responseData.lines;
            this.totalDocuments = responseData.totalDocumentos;
            this.totalPending = responseData.totalPendente;
            this.totalSeur = responseData.totalSeur;
          } else {
            this.newDialog('A processar o ficheiro...');
            this.errorDialog(responseData.message)
          }
        },
        onUploadErrorCtt() {
          this.errorDialog('Ocorreu um erro ao importar o ficheiro');
        },
        newDialog(title) {
            this.dialog.title = title;
            this.dialog.message = '';
            this.dialog.alertMessage = '';
            this.dialog.loading = true;
            this.dialog.progress = false;
            this.dialog.progressCurrent = 0;
            this.dialog.progressStep = 0;
            this.dialog.error = false;
            this.dialog.alert = false;
            this.dialog.open = true;
        },
        errorDialog(message) {
            this.dialog.message = message;
            this.dialog.loading = false;
            this.dialog.progress = false;
            this.dialog.error = true
        },
        compareValues(a, b) {
            let valA = (a / 1).toFixed(2).replace('.', ',');
            let valB = (b / 1).toFixed(2).replace('.', ',');
            return valA === valB;
        },
        isPast(date) {
            let compareDate = new Date(date);
            let now = new Date();
            now.setHours(0, 0, 0, 0);
            return compareDate < now;
        },
        makeReceipts() {
            this.linhasTotal = this.validPayments.length;
            this.linhasProcessadas = 0;
            this.linhasOK = 0;
            this.linhasNOK = 0;

            this.newDialog('A efectuar os Recibos...');
            this.dialog.loading = false;
            this.dialog.progress = true;
            this.dialog.progressTotal = this.totalSelected;
            this.makeReceipt();
        },
        makeReceipt() {
            let payment = this.validPayments[this.linhasProcessadas];
            if (this.linhasProcessadas < this.linhasTotal) {
                if (payment.selected) {
                    payment.makingReceipt = true;
                    let request = {
                        url: '/api/staff/accounts/payments/make/receipt',
                        data: {
                            payment: payment,
                            date: this.input.date,
                            amount: this.input.amount,
                            mode: this.input.mode
                        }
                    };
                    ApiWS.dispatch('makePost', request)
                            .then(response => {
                                let responseData = response.data;
                                if (responseData.status === 'OK') {
                                    payment.makingReceipt = false;
                                    payment.recibo = responseData.recibo;
                                    this.linhasOK++;
                                    this.linhasProcessadas++;
                                    this.dialog.progressStep++;
                                    this.dialog.progressCurrent = Math.ceil(this.dialog.progressStep / this.dialog.progressTotal * 100);
                                    this.makeReceipt()
                                } else {
                                    payment.makingReceipt = false;
                                    payment.recibo = 'ERRO';
                                    this.linhasNOK++;
                                    this.linhasProcessadas++;
                                    this.dialog.progressStep++;
                                    this.dialog.progressCurrent = Math.ceil(this.dialog.progressStep / this.dialog.progressTotal * 100);
                                    this.makeReceipt()
                                }
                            })
                            .catch(() => {
                                payment.makingReceipt = false;
                                payment.recibo = 'ERRO';
                                this.linhasNOK++;
                                this.linhasProcessadas++;
                                this.dialog.progressStep++;
                                this.dialog.progressCurrent = Math.ceil(this.dialog.progressStep / this.dialog.progressTotal * 100);
                                this.makeReceipt()
                            })
                } else {
                    this.linhasProcessadas++;
                    this.makeReceipt()
                }
            } else {
                if (this.linhasOK > 0) {
                    this.dialog.message = this.linhasOK + ' recibos efectuados.';
                    if (this.linhasNOK > 0) {
                        this.dialog.alertMessage = this.linhasNOK + ' pagamentos não foram processadas!';
                        this.dialog.alert = true;
                    }
                } else {
                    this.dialog.message = 'Nenhum recibo foi efectuado';
                    this.dialog.error = true;
                }
                this.dialog.progress = false;
            }
        },
        addDocument(line) {
            this.selectedPayment = line;
            this.remainingValue = (line.reembolso - line.totalPri).toFixed(2).replace('.', ',');
            this.pendentesDialog = true;
            this.pendentesLoading = true;
            let request = {
                url: '/api/staff/accounts/payments/pendentes',
                data: {
                    entidade: line.entidade
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pendentesLoading = false;
                            this.pendentes = responseData.pendentes;
                        } else {
                            this.pendentesLoading = false;
                        }
                    })
                    .catch(() => {
                        this.pendentesLoading = false;
                    })
        },
        removePendente(doc, docIndex, payment){
            let request = {
                url: '/api/staff/accounts/payments/' + doc.id,
            };
            let loader = this.$loading.show();
            ApiWS.dispatch('makeDelete', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Documento removido com sucesso',
                            type: 'success'
                        })
                        payment.docs.splice(docIndex,1);
                        if(payment.docs.length == 0){
                            payment.entidade = '';
                        }
                    }else{
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao remover documento!',
                            type: 'error'
                        })
                    }
                    loader.hide();
                })
                .catch(() => {
                    this.pendentesLoading = false
                })
        },
        addPendente(doc) {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/accounts/payments/add',
                data: {
                    doc: doc,
                    lines: this.validPayments,
                    payment: this.selectedPayment
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.validPayments = responseData.lines;
                            this.totalDocuments = responseData.totalDocumentos;
                            this.totalPending = responseData.totalPendente;
                            this.totalSeur = responseData.totalSeur;
                            this.pendentesDialog = false
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        this.pendentesLoading = false
                    })
        },

        getCheques() {
            let request = {
                url: '/api/staff/accounts/payments/cheques',
                data: {
                    date: this.input.date,
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.checkboxAll = false;
                            this.totalSelected = 0;
                            this.input.amount = '';
                            this.input.date = new Date().toISOString().substr(0, 10);
                            this.validPayments = responseData.lines;
                            this.totalDocuments = responseData.totalDocumentos;
                            this.totalPending = responseData.totalPendente;
                            this.totalSeur = responseData.totalSeur;
                            // this.$router.push('/orders/pick/' + responseData.id)
                        } else {
                            // this.loadingPick = false
                        }
                    })
                    .catch(() => {
                        // this.loadingPick = false
                    })

        },
        printCheques() {
            let request = {
                url: '/api/staff/accounts/payments/cheques/' + this.input.date
            }
            ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/pdf'
                        })
                        var fileURL = URL.createObjectURL(file)
                        var a = document.createElement('a')
                        a.href = fileURL
                        a.target = '_blank'
                        a.download = 'TL - BCP_Integração.pdf'
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
    }
}
</script>
