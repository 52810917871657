<style scoped>
    .v-data-table td, .v-data-table th {
        padding: 0 6px;
        height: 26px;
    }
    .v-icon.v-icon.fas {
        font-size: 16px;
    }
</style>
<template>
    <div>
        <v-card v-if="loading" height="280">
            <v-card-text class="text-center" style="height: 100%;">
                <i class="fas fa-cog fa-spin fa-3x" style="margin-top: 90px"></i>
            </v-card-text>
        </v-card>
        <v-card v-if="!loading">
            <v-card-title><i class="fas fa-shopping-cart fa-fw mr-2"></i>Últimas {{ $t('sales') }}</v-card-title>
            <v-card-text>
                <v-simple-table dense style="background-color: transparent;">
                    <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>{{ $t('name') }}</th>
                        <th class="text-center">Vendedor</th>
                        <th class="text-center">Zona Comercial</th>
                        <th>TipoDoc</th>
                        <th>NumDoc</th>
                        <th class="text-right">Valor Total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in lines">
                        <td style="white-space: nowrap">
                            {{ line.Cliente }}
                            <small><a :href="'/clients/clients/'+line.Cliente" target="_blank" class="ml-1"><i class="fas fa-fw fa-external-link-alt"></i></a></small>
                        </td>
                        <td>{{ line.Nome }}</td>
                        <td class="text-center">{{ line.Vendedor }}</td>
                        <td class="text-center">{{ line.CDU_ZonaComercial }}</td>
                        <td>{{ line.tipodoc }}</td>
                        <td style="white-space: nowrap">
                            {{ line.numdoc }}
                            <i class="far fa-file-pdf" style="color: red; cursor: pointer" @click="downloadPDF(line.Serie, line.tipodoc, line.numdoc)"></i>
                        </td>
                        <td style="white-space: nowrap" class="text-right">{{ $money(line.Total) }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                loading: true,
                lines: []
            }
        },
        created() {
            this.initialize()
        },
        methods: {
            initialize() {
                let request = {
                    url: api.dashboardWidgetLastSales()
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.lines = responseData.lines
                            this.loading = false
                        }
                    })
                    .catch(response => {

                    })
            },
            downloadPDF(serie, tipodoc, numdoc) {
                let request = {
                    url: api.orderPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let request = {
                                url: api.orderDownloadPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
                            }
                            ApiWS.dispatch('makeDownload', request)
                                .then(response => {
                                    var file = new Blob([response.data], {
                                        type: 'application/pdf'
                                    });
                                    var fileURL = URL.createObjectURL(file);
                                    var a = document.createElement('a');
                                    a.href = fileURL;
                                    a.target = '_blank';
                                    a.download = serie + '_' + tipodoc + '_' + numdoc + '.pdf';
                                    document.body.appendChild(a);
                                    a.click();
                                    document.body.removeChild(a);
                                })
                                .catch(response => {
                                    Alert.dispatch('show', {
                                        snackbar: true,
                                        text: 'Erro de servidor!',
                                        type: 'error'
                                    })
                                })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar PDF!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
        }
    }
</script>
