<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title back :title="$t('levelsDiscountsBrand')+' '+brand"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn small @click="toggleDialogAddLevel()">{{ $t('add') }}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>Marca</th>
                            <th>Nivel</th>
                            <th>Valor</th>
                            <th>País</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="5">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="level in levels">
                            <td>{{ level.CDU_Marca }}</td>
                            <td>{{ level.CDU_Nivel }}</td>
                            <td>{{ level.CDU_Valor }}</td>
                            <td>
                                <template v-if="level.CDU_Pais === 'ALL'">PT | ES</template>
                                <template v-if="level.CDU_Pais !== 'ALL'">{{ level.CDU_Pais }}</template>
                            </td>
                            <td class="text-right">
                                <template v-if="writePermission('products/brandsDiscount')">
                                    <v-btn class="mx-2" x-small tile outlined color="info" @click="editLevel(level)">
                                        <i class="fas fa-pencil-alt"></i>
                                    </v-btn>
                                    <v-btn v-if="level.CDU_Nivel !== 0" class="mx-2" x-small tile outlined color="error" @click="deleteLevel(level)">
                                        <i class="fas fa-times"></i>
                                    </v-btn>
                                </template>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialogEditLevel" persistent max-width="400">
                <v-card>
                    <v-card-title>Editar Nivel</v-card-title>
                    <v-card-text>
                        <v-form ref="editLevel">
                            <v-text-field
                                    v-model="input.nivel"
                                    dense
                                    outlined
                                    label="Nivel"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field
                                    v-model="input.valor"
                                    dense
                                    outlined
                                    label="Valor"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            <v-select
                                    v-model="input.pais"
                                    dense
                                    outlined
                                    label="Pais"
                                    :items="items"
                                    item-text="TEXT"
                                    item-value="VALUE"
                                    :rules="[rules.required]"
                            ></v-select>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogEditLevel = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="editLevelDiscount()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogConfirm" persistent max-width="500">
                <v-card>
                    <v-card-title>{{ $t('deleteLevel') }} {{ input.nivel }}</v-card-title>
                    <v-card-text>
                        {{ $t('messageDeleteLevel') }}
                        <div v-if="totalClients > 0">
                            <div class="red--text">
                                {{ $t('clientsCurrentLevel') }} {{ totalClients }}
                            </div>
                            <div>
                                <span class="red--text">{{ $t('changeLevelClients') }}</span>
                                <v-select
                                        v-model="input.levelClient"
                                        outlined
                                        dense
                                        :items="levels2"
                                        item-value="VALUE"
                                        item-text="TEXT"
                                ></v-select>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogConfirm = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="confirmChange()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAddLevel" persistent max-width="500">
                <v-card>
                    <v-card-title>{{ $t('addLevel') }}</v-card-title>
                    <v-card-text>
                        <v-form ref="addLevel">
                            <v-text-field
                                    v-model="input.nivel"
                                    dense
                                    outlined
                                    label="Nivel"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            <v-text-field
                                    v-model="input.valor"
                                    dense
                                    outlined
                                    label="Valor"
                                    :rules="[rules.required]"
                            ></v-text-field>
                            <v-select
                                    v-model="input.pais"
                                    dense
                                    outlined
                                    label="Pais"
                                    :items="items"
                                    item-text="TEXT"
                                    item-value="VALUE"
                                    :rules="[rules.required]"
                            ></v-select>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogAddLevel = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="addLevelDiscount()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
    import i18n from "../../plugins/i18n";

    export default {
        data: function () {
            return {
                loading: false,
                brand: '',
                levels: [],
                levels2: [],
                input: {
                    marca: '',
                    nivel: '',
                    pais: '',
                    valor: '',
                    id: '',
                    levelClient: ''
                },
                dialogEditLevel: false,
                dialogConfirm: false,
                dialogAddLevel: false,
                items: [
                    {VALUE: 'ALL', TEXT: 'Todos'},
                    {VALUE: 'PT', TEXT: 'PT'},
                    {VALUE: 'ES', TEXT: 'ES'}
                ],
                rules: {
                    required: value => value !== '' || 'Este campo é obrigatório.',
                    length: value => (value || '').length === 10 || 'Este campo tem de ter 10 caracteres'
                },
                totalClients: 0
            }
        },
        created() {
            document.title = i18n.t('levelsDiscountsBrand')
            this.brand = this.$route.params.brand
            this.getLevels()
        },
        methods: {
            getLevels() {
                this.loading = true
                let request = {
                    url: api.productsBrandsDiscounts() + '/' + this.brand
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.levels = responseData.levels
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })
            },
            writePermission(permission) {
                return User.getters.writePermission(permission)
            },
            editLevel(level) {
                this.input.marca = this.brand
                this.input.nivel = level.CDU_Nivel
                this.input.pais = level.CDU_Pais
                this.input.valor = level.CDU_Valor
                this.input.id = level.CDU_ID

                this.dialogEditLevel = true
            },
            editLevelDiscount() {
                if (this.$refs.editLevel.validate()) {
                    let request = {
                        url: api.productsBrandsDiscounts() + '/' + this.brand + '/update',
                        data: {
                            input: this.input
                        }
                    }

                    ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                this.getLevels()
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Nivel alterado com sucesso!',
                                    type: 'success'
                                })
                                this.dialogEditLevel = false
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao editar nivel!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
                }
            },
            deleteLevel(level) {
                this.input.nivel = level.CDU_Nivel
                this.input.pais = level.CDU_Pais
                this.input.id = level.CDU_ID
                this.getLevelsWithClients()
                this.dialogConfirm = true
            },
            getLevelsWithClients() {
                let request = {
                    url: api.productsBrandsDiscountsLevelsClients(),
                    data: {
                        marca: this.brand,
                        nivel: this.input.nivel,
                        pais: this.input.pais
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.totalClients = responseData.totalClients
                            this.levels2 = responseData.levels
                        }
                    })
                    .catch(response => {

                    })
            },
            confirmChange() {
                let request = {
                    url: api.productsBrandsDiscounts() + '/' + this.brand + '/delete',
                    data: {
                        level: this.input.nivel,
                        country: this.input.pais,
                        id: this.input.id,
                        levelClient: this.input.levelClient
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Nivel removido com sucesso!',
                                type: 'success'
                            })
                            this.getLevels()
                            this.dialogConfirm = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao remover o nivel!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            toggleDialogAddLevel() {
                this.input = {
                    marca: this.brand,
                    nivel: '',
                    pais: 'ALL',
                    valor: '',
                    id: '',
                    levelClient: ''
                }

                this.dialogAddLevel = true
            },
            addLevelDiscount() {
                if (this.$refs.addLevel.validate()) {
                    let request = {
                        url: api.productsBrandsDiscounts() + '/' + this.brand + '/add',
                        data: {
                            input: this.input
                        }
                    }

                    ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data
                            if (responseData.status === 'OK') {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Nivel adicionado com sucesso!',
                                    type: 'success'
                                })
                                this.getLevels()
                                this.dialogAddLevel = false
                            } else {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao adicionar o nivel!',
                                    type: 'error'
                                })
                            }
                        })
                        .catch(response => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
                }
            }
        }
    }
</script>
