<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        back
                        :title="'SubFamilias da familia - '+family"
                ></page-title>
            </v-col>
            <v-col class="text-right" align-self="center">
                <v-btn tile small  color="success" @click="toggleDialogCreateSubFamily()">{{ $t('add') }}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th>SubFamilia</th>
                        <th>{{ $t('description') }}</th>
                        <th>{{ $t('description') }}PT</th>
                        <th>{{ $t('description') }}ES</th>
                        <th>{{ $t('description') }}EN</th>
                        <th>{{ $t('description') }}FR</th>
                        <th>{{ $t('description') }}IT</th>
                        <th>{{ $t('description') }}DE</th>
                        <th>{{ $t('product') }} B2B</th>
                        <th>{{ $t('product') }} Activos</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                    <tr>
                        <td>
                            <v-text-field
                                    v-model="filters.SubFamilia"
                                    dense
                                    hide-details
                                    outlined
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.Descricao"
                                    dense
                                    hide-details
                                    outlined
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.DescricaoPT"
                                    dense
                                    hide-details
                                    outlined
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.DescricaoES"
                                    dense
                                    hide-details
                                    outlined
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.DescricaoEN"
                                    dense
                                    hide-details
                                    outlined
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.DescricaoFR"
                                    dense
                                    hide-details
                                    outlined
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.DescricaoIT"
                                    dense
                                    hide-details
                                    outlined
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.DescricaoDE"
                                    dense
                                    hide-details
                                    outlined
                                    @keyup="filterTable"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-select
                                    v-model="filters.ArtigosB2B"
                                    dense
                                    hide-details
                                    outlined
                                    :items="filtersYesNo"
                                    item-value="VALUE"
                                    item-text="TEXT"
                                    @change="filterTable"
                            ></v-select>
                        </td>
                        <td>
                            <v-select
                                    v-model="filters.Artigos"
                                    dense
                                    hide-details
                                    outlined
                                    :items="filtersYesNo"
                                    item-value="VALUE"
                                    item-text="TEXT"
                                    @change="filterTable"
                            ></v-select>
                        </td>
                        <td>
                            <v-select
                                    v-model="filters.Total"
                                    dense
                                    hide-details
                                    outlined
                                    :items="filtersYesNo"
                                    item-value="VALUE"
                                    item-text="TEXT"
                                    @change="filterTable"
                            ></v-select>
                        </td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="loading">
                        <td colspan="12">
                            <v-progress-linear indeterminate></v-progress-linear>
                        </td>
                    </tr>
                    <tr v-for="subFamily in subFamilies">
                        <td>{{ subFamily.SubFamilia }}</td>
                        <td>{{ subFamily.Descricao }}</td>
                        <td>{{ subFamily.DescricaoPT }}</td>
                        <td>{{ subFamily.DescricaoES }}</td>
                        <td>{{ subFamily.DescricaoEN }}</td>
                        <td>{{ subFamily.DescricaoFR }}</td>
                        <td>{{ subFamily.DescricaoIT }}</td>
                        <td>{{ subFamily.DescricaoDE }}</td>
                        <td>{{ subFamily.ArtigosB2B }}</td>
                        <td>{{ subFamily.Artigos }}</td>
                        <td>{{ subFamily.Total }}</td>
                        <td>
                            <v-btn color="secondary" small @click="toggleDialogEditSubFamily(subFamily)">Editar</v-btn>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col>
                <v-dialog v-model="dialogEditSubFamily" persistent max-width="600">
                    <staff-dialog :title="$t('change')+' SubFamilia '+editSubFamily.SubFamilia">
                        <template slot="content">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoPT"
                                            dense
                                            outlined
                                            :label="$t('description')+' PT'"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoEN"
                                            dense
                                            outlined
                                            :label="$t('description')+' EN'"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoIT"
                                            dense
                                            outlined
                                            :label="$t('description')+' IT'"
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoES"
                                            dense
                                            outlined
                                            :label="$t('description')+' ES'"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoFR"
                                            dense
                                            outlined
                                            :label="$t('description')+' FR'"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoDE"
                                            dense
                                            outlined
                                            :label="$t('description')+' DE'"
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                        <template slot="action">
                            <v-btn @click="dialogEditSubFamily = false">Fechar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="success" @click="saveDescriptionSubFamily()">Guardar</v-btn>
                        </template>
                    </staff-dialog>
                </v-dialog>
                <v-dialog v-model="dialogCreateSubFamily" persistent max-width="600">
                    <staff-dialog :title="$t('add')+' SubFamilia '">
                        <template slot="content">
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            v-model="editSubFamily.SubFamilia"
                                            dense
                                            outlined
                                            label="SubFamilia"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoPT"
                                            dense
                                            outlined
                                            :label="$t('description')+' PT'"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoEN"
                                            dense
                                            outlined
                                            :label="$t('description')+' EN'"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoIT"
                                            dense
                                            outlined
                                            :label="$t('description')+' IT'"
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-text-field
                                            v-model="editSubFamily.Descricao"
                                            dense
                                            outlined
                                            :label="$t('description')"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoES"
                                            dense
                                            outlined
                                            :label="$t('description')+' ES'"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoFR"
                                            dense
                                            outlined
                                            :label="$t('description')+' FR'"
                                    ></v-text-field>
                                    <v-text-field
                                            v-model="editSubFamily.DescricaoDE"
                                            dense
                                            outlined
                                            :label="$t('description')+' DE'"
                                            hide-details
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </template>
                        <template slot="action">
                            <v-btn @click="dialogCreateSubFamily = false">Fechar</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="success" :disabled="editSubFamily.SubFamilia === '' || editSubFamily.Descricao === '' || editSubFamily.DescricaoPT === '' || editSubFamily.DescricaoES === ''" @click="createSubFamily()">
                                Guardar
                            </v-btn>
                        </template>
                    </staff-dialog>
                </v-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: function () {
        return {
            family: '',
            loading: false,
            subFamilies: [],
            subFamiliesAux: [],
            filters: {
                SubFamilia: '',
                Descricao: '',
                DescricaoPT: '',
                DescricaoES: '',
                DescricaoEN: '',
                DescricaoFR: '',
                DescricaoIT: '',
                DescricaoDE: '',
                ArtigosB2B: '',
                Artigos: '',
                Total: ''
            },
            filtersYesNo: [
                {TEXT: 'Todos', VALUE: ''},
                {TEXT: 'Não', VALUE: '0'},
                {TEXT: 'Sim', VALUE: '1'}
            ],
            editSubFamily: {
                SubFamilia: '',
                Descricao: '',
                DescricaoPT: '',
                DescricaoES: '',
                DescricaoEN: '',
                DescricaoFR: '',
                DescricaoIT: '',
                DescricaoDE: ''
            },
            dialogEditSubFamily: false,
            dialogCreateSubFamily: false
        }
    },
    created() {
        document.title = 'SubFamílias'
        this.family = this.$route.params.family
        this.getSubFamilies()
    },
    methods: {
        getSubFamilies() {
            this.loading = true
            let request = {
                url: api.subFamilies() + '/' + this.family
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.subFamilies = responseData.subFamilies
                            this.subFamiliesAux = this.subFamilies
                            this.filterTable()
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        toggleDialogEditSubFamily(subFamily) {
            this.editSubFamily = Object.assign({}, subFamily)
            this.dialogEditSubFamily = true
        },
        saveDescriptionSubFamily() {
            let request = {
                url: api.subFamilies() + '/' + this.family,
                data: {
                    subFamily: this.editSubFamily
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Descrição alterada com sucesso!',
                                type: 'success'
                            })
                            this.getSubFamilies()
                            this.dialogEditSubFamily = false
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao alterar descrição!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        filterTable() {
            this.subFamilies = this.subFamiliesAux
            if (this.filters.SubFamilia !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => subFamily.SubFamilia !== null && subFamily.SubFamilia.toLowerCase().includes(this.filters.SubFamilia.toLowerCase()))
            }
            if (this.filters.Descricao !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => subFamily.Descricao.toLowerCase().includes(this.filters.Descricao.toLowerCase()))
            }
            if (this.filters.DescricaoPT !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => subFamily.DescricaoPT !== null && subFamily.DescricaoPT !== '' && subFamily.DescricaoPT.toLowerCase().includes(this.filters.DescricaoPT.toLowerCase()))
            }
            if (this.filters.DescricaoES !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => subFamily.DescricaoES !== null && subFamily.DescricaoES.toLowerCase().includes(this.filters.DescricaoES.toLowerCase()))
            }
            if (this.filters.DescricaoEN !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => subFamily.DescricaoEN !== null && subFamily.DescricaoEN.toLowerCase().includes(this.filters.DescricaoEN.toLowerCase()))
            }
            if (this.filters.DescricaoFR !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => subFamily.DescricaoFR !== null && subFamily.DescricaoFR.toLowerCase().includes(this.filters.DescricaoFR.toLowerCase()))
            }
            if (this.filters.DescricaoIT !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => subFamily.DescricaoIT !== null && subFamily.DescricaoIT.toLowerCase().includes(this.filters.DescricaoIT.toLowerCase()))
            }
            if (this.filters.DescricaoDE !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => subFamily.DescricaoDE !== null && subFamily.DescricaoDE.toLowerCase().includes(this.filters.DescricaoDE.toLowerCase()))
            }
            if (this.filters.ArtigosB2B !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => this.filters.ArtigosB2B === '0' ? subFamily.ArtigosB2B === 0 : subFamily.ArtigosB2B > 0)
            }
            if (this.filters.Artigos !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => this.filters.Artigos === '0' ? subFamily.Artigos === 0 : subFamily.Artigos > 0)
            }
            if (this.filters.Total !== '') {
                this.subFamilies = this.subFamilies.filter(subFamily => this.filters.Total === '0' ? subFamily.Total === 0 : subFamily.Total > 0)
            }
        },
        createSubFamily() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/subFamilies/' + this.family + '/create',
                data: {
                    subFamily: this.editSubFamily
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.dialogCreateSubFamily = false;
                            this.getSubFamilies();
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar subFamilia!',
                                type: 'error'
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        toggleDialogCreateSubFamily() {
            this.editSubFamily = {
                SubFamilia: '',
                Descricao: '',
                DescricaoPT: '',
                DescricaoES: '',
                DescricaoEN: '',
                DescricaoFR: '',
                DescricaoIT: '',
                DescricaoDE: ''
            }
            this.dialogCreateSubFamily = true;
        }
    }
}
</script>
