<template>
    <v-dialog scrollable v-model="showSelf" fullscreen persistent @click:outside="canClose">
        <v-card>
            <v-btn
                color="grey lighten-3"
                dark
                fab
                top
                absolute
                right
                @click="closeDialogParent"
            >
                <span class="buttonClose">x</span>
            </v-btn>

            <v-toolbar class="mb-3" top fixed dark dense color="warning" style="max-height: 48px;">
                <v-toolbar-title>SELECIONAR ARTIGOS</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text style="height:500px">
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th></th>
                        <th>{{ $t('product') }}</th>
                        <th>{{ $t('description') }}</th>
                        <th>Marca</th>
                        <th>Familia</th>
                        <th>SubFamilia</th>
                        <th>Ano</th>
                        <th>Stock</th>
                    </tr>
                    <tr class="filters_row">
                        <td>
                                <span style="cursor: pointer; margin-left: 2px">
                                    <i v-if="selected === 0" class="far fa-square fa-lg" @click="selectAll()"></i>
                                    <i v-if="selected !== 0 && selected !== products.length"
                                       class="far fa-minus-square fa-lg" @click="selectAll()"></i>
                                    <i v-if="selected !== 0 && selected === products.length"
                                       class="fas fa-check-square fa-lg" style="color:#1976d2"
                                       @click="unselectAll()"></i>
                                </span>
                        </td>
                        <td>
                            <v-text-field
                                v-model="filtersModal.productId"
                                outlined
                                single-line
                                @keyup.enter="getProducts()"
                                hide-details
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="filtersModal.description"
                                outlined
                                single-line
                                @keyup.enter="getProducts()"
                                hide-details
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="filtersModal.brand"
                                outlined
                                single-line
                                @keyup.enter="getProducts()"
                                hide-details
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="filtersModal.family"
                                outlined
                                single-line
                                @keyup.enter="getProducts()"
                                hide-details
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="filtersModal.subFamily"
                                outlined
                                single-line
                                @keyup.enter="getProducts()"
                                hide-details
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                v-model="filtersModal.year"
                                outlined
                                single-line
                                @keyup.enter="getProducts()"
                                hide-details
                            ></v-text-field>
                        </td>
                        <td>
                            <v-select
                                v-model="filtersModal.stock"
                                :items="filtersYesNo"
                                outlined
                                single-line
                                @change="getProducts()"
                                item-text="TEXT"
                                item-value="VALUE"
                                style="width: 60px"
                                hide-details
                            ></v-select>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <!--                            <tr v-for="product in products" style="cursor: pointer" @click="selectProduct(product.Artigo)">-->
                    <tr v-for="product in products" style="cursor: pointer"
                        @click="product.selected = !product.selected;selected = product.selected ? selected+1 : selected-1">
                        <td>
                            <i v-if="!product.selected" class="far fa-square fa-lg"></i>
                            <i v-if="product.selected" class="fas fa-check-square fa-lg" style="color:#1976d2"></i>
                        </td>
                        <td>{{ product.Artigo }}</td>
                        <td>{{ product.Descricao }}</td>
                        <td>{{ product.Marca }}</td>
                        <td>{{ product.Familia }}</td>
                        <td>{{ product.SubFamilia }}</td>
                        <td>{{ product.CDU_CampoVar1 }}</td>
                        <td>{{ product.STKActual }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>

                    <v-progress-linear class="mt-1" v-if="loaded==false" height="2" indeterminate color="blue darken-4"></v-progress-linear>

            </v-card-text>

            <v-card-actions class="pa-0">

                <v-toolbar dense color="white">
                    <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        outlined
                        single-line
                        @change="pagination.page = 1;getProducts()"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="max-width: 90px;"
                        class="staff_select"
                        hide-details
                    ></v-select>
                    <v-pagination
                        v-model="pagination.page"
                        :length="pagination.pages"
                        :total-visible="7"
                        @input="getProducts"
                        style="max-width: 420px;"
                    ></v-pagination>
                    <p class="ma-0">A mostrar 1-{{ products.length }} de {{ totalItems }} artigos.</p>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="
                                JSON.stringify(this.filtersModal)
                                ===
                                JSON.stringify({
                                    productId: '',
                                    barcode: '',
                                    description: '',
                                    brand: '',
                                    family: '',
                                    subFamily: '',
                                    year: '',
                                    stock: ''
                                })"
                           style="margin-right:1vw" tile small color="error" @click="resetFilters">Limpar Filtros
                    </v-btn>
                    <v-btn tile small color="success" :disabled="selected === 0"
                           @click="confirmSelection()">Adicionar ({{ selected }})
                    </v-btn>
                </v-toolbar>

            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>

import axios from "axios";

export default {
    data: () => ({
        showSelf: true,
        form: {
            idColumn: null,
            quantityColumn: null,
            startingRow: null,
            file: null
        },
        errorMessage: "",
        loaded: false,
        totalItems: 0,
        selected: 0,
        pagination: {
            per_page: 250,
            page: 1,
            pages: 100
        },
        filtersModal: {
            productId: '',
            barcode: '',
            description: '',
            brand: '',
            family: '',
            subFamily: '',
            year: '',
            stock: ''
        },
        filtersYesNo: [
            {TEXT: 'Todos', VALUE: ''},
            {TEXT: 'Não', VALUE: '0'},
            {TEXT: 'Sim', VALUE: '1'}
        ],
        filtersPerPage: [
            {TEXT: '20', VALUE: 20},
            {TEXT: '50', VALUE: 50},
            {TEXT: '250', VALUE: 250},
            {TEXT: '500', VALUE: 500},
            {TEXT: 'Todos', VALUE: 1000000}
        ],
        products: []
    }),
    name: "ProductListSelection",
    props: {
        b2bOnly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    created() {
        this.getProducts();
    },
    computed: {},
    methods: {
        getProducts() {
            this.loaded = false;
            // let loader = this.$loading.show();
            this.products = [];
            if(this.b2bOnly == true){
                this.filtersModal.b2bSync = 1
            }else{
                this.filtersModal.b2bSync = ""
            }
            let request = {
                url: api.b2bCampaignsGetProducts() + '?' + new URLSearchParams(this.filtersModal).toString() + '&' + new URLSearchParams(this.pagination).toString(),
            };
            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.products = responseData.products;
                        this.totalItems = responseData.totalProducts;
                        this.pagination.pages = Math.ceil(this.totalItems / this.pagination.per_page);
                        this.selected = 0;
                    }
                    //  loader.hide();
                    this.loaded = true;
                })
        },
        resetFilters() {
            this.filtersModal = {
                productId: '',
                barcode: '',
                description: '',
                brand: '',
                family: '',
                subFamily: '',
                year: '',
                stock: ''
            };
            this.pagination.page = 1;
            this.getProducts();
        },
        confirmSelection() {
            if (this.selected == 0)
                return;

            let selectedProducts = this.products.filter((product) => {
                return product.selected
            });

            this.$emit('confirm-selection', selectedProducts);
        },
        selectAll() {
            this.selected = 0;
            this.products.forEach((product) => {
                product.selected = true;
                this.selected++;
            });
        },
        unselectAll() {
            this.selected = 0;
            this.products.forEach((product) => {
                product.selected = false;
            });
        },
        closeDialogParent() {
            this.$emit('close-list');
        },
        canClose() {
            if (this.loaded == true)
                this.closeDialogParent();
        }

    }
}
</script>

<style scoped>
.buttonClose {
    margin-top: 24px;
    color: #a79090;
    font-size: 22px;
    font-weight: 1000;
}
</style>
