<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Pró-formas"
                        :subtitle="'A mostrar ' + proformas.length + ' pró-formas.'"
                ></page-title>
            </v-col>
            <v-col class="text-right">

                <v-btn tile small class="ml-2 mb-2" @click="clearFilters()" color="error"
                       :block="$vuetify.breakpoint.xs">
                    Limpar Filtros
                </v-btn>

                <v-btn tile small class="ml-2 mb-2" color="info" @click="initialize()">
                    <i class="fas fa-fw fa-sync-alt"></i>Atualizar
                </v-btn>
            </v-col>
        </v-row>

        <!-- PROFORMAS TABLE -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th width="1">Dias</th>
                            <th width="80" class="text-center">Criado</th>
                            <th width="80" class="text-center">Atualizado</th>
                            <th width="80">Cliente</th>
                            <th>{{ $t('name') }}</th>
                            <th width="220">Email</th>
                            <th width="1" class="text-center">Emails</th>
                            <th width="1" class="text-center">User</th>
                            <th width="1" class="text-center">Vendedor</th>
                            <th width="80" class="text-center">Data Doc</th>
                            <th width="1" class="text-center">Secção</th>
                            <th width="80" class="text-center">Pró-forma</th>
                            <th width="80" class="text-center">Valor</th>
                            <th width="1" class="text-center">Valor Pago</th>
                            <th width="1">Pago</th>
                            <th width="1"></th>
                            <th width="1"></th>
                            <th width="1"></th>
                            <th width="1"></th>
                        </tr>
                        <!-- FILTERS -->
                        <tr class="filters_row">
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.created"
                                        outlined
                                        hide-details
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.updated"
                                        outlined
                                        hide-details
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.entity"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.email"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.user"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.salesman"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.date"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-select
                                        v-model="filters.seccion"
                                        :items="filtersSeccion"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterProformas"
                                ></v-select>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.numDoc"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.value"
                                        outlined
                                        hide-details
                                        single-line
                                        @keyup="filterProformas"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td>
                                <v-select
                                        v-model="filters.paid"
                                        :items="filtersPaid"
                                        item-text="text"
                                        item-value="value"
                                        outlined
                                        hide-details
                                        @change="filterProformas"
                                ></v-select>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>

                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="18">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="proforma in proformas"
                            :class="{'row_error':  proforma.Dias > 7, 'row_success' : proforma.pago === 1 }">
                            <td class="text-center">{{ proforma.Dias }}</td>
                            <td class="text-center">{{ proforma.created_at.substring(0, 10) }}</td>
                            <td class="text-center">{{ proforma.updated_at.substring(0, 10) }}</td>
                            <td>{{ proforma.Entidade }}</td>
                            <td>
                                <i v-if="proforma.TipoCred == '2'" class="fas fa-minus-circle" style="color: red;"></i>
                                {{ proforma.Nome }}
                            </td>
                            <td>{{ proforma.email }}</td>
                            <td class="text-center">{{ proforma.emails_enviados }}</td>
                            <td class="text-center">{{ proforma.Utilizador }}</td>
                            <td class="text-center">{{ proforma.Vendedor }}</td>
                            <td class="text-center" style="white-space: nowrap">{{ proforma.Data }}</td>
                            <td class="text-center">
                                <strong v-if="proforma.Seccao === '3'" style="color: #ff5a00">FOX</strong>
                                <strong v-if="proforma.Seccao === '1'" style="color: #ce0000">B2B</strong>
                                <strong v-if="proforma.Seccao === '6'" style="color: #1c1bce">FORM</strong>
                                <strong v-if="proforma.Seccao !== '3' && proforma.Seccao !== '1' && proforma.Seccao !== '6'">{{
                                        proforma.Seccao
                                    }}</strong>
                            </td>
                            <td class="text-center">{{ proforma.NumDoc }} <i class="far fa-file-pdf"
                                                                             style="color: red; cursor: pointer"
                                                                             @click="$downloadPDF(proforma.Serie, proforma.TipoDoc, proforma.NumDoc)"></i>
                            </td>
                            <td class="text-right">{{ $money(proforma.TotalDocumento) }}</td>
                            <td>
                                <template v-if="writePermission('orders/proformas_button_paid')">
                                    <v-text-field
                                            v-model="proforma.valor_pago"
                                            outlined
                                            single-line
                                            hide-details
                                            append-icon="fas fa-save"
                                            class="staff_text-field_small"
                                            @click:append="paid(proforma, 'partial')"
                                            style="width: 85px"
                                    ></v-text-field>
                                </template>
                                <template v-if="proforma.pago && !writePermission('orders/proformas_button_paid')">
                                    {{ proforma.valor_pago }}
                                </template>
                            </td>
                            <td class="text-center">
                                <i class="fas fa-check" v-if="proforma.pago" style="color: green"></i>
                                <template v-if="!proforma.pago">
                                    <v-btn tile small v-if="writePermission('orders/proformas_button_paid')"
                                           @click="paid(proforma, 'full')" color="info">
                                        Pago
                                    </v-btn>
                                    <i v-if="!writePermission('orders/proformas_button_paid')" class="fas fa-times" style="color: red"></i>
                                </template>
                            </td>
                            <td>
                                <v-btn tile small
                                       v-if="proforma.proforma_id !== 0 && !proforma.pago && writePermission('orders/proformas')"
                                       @click="openDialogResend(proforma)" color="warning">
                                    Reenviar
                                </v-btn>
                            </td>
                            <td>
                                <v-btn tile small
                                       v-if="writePermission('orders/proformas') && proforma.Seccao === '1' && proforma.faturar"
                                       color="success" @click="openDialogInvoice(proforma)">
                                    Faturar
                                </v-btn>
                                <v-btn tile small v-if="writePermission('orders/proformas') && proforma.Seccao === '6'"
                                       color="primary" @click="openDialogInvoice(proforma)">
                                    Faturar
                                </v-btn>
                            </td>
                            <td>
<!--                                <v-btn tile small v-if="proforma.proforma_id !== 0 && proforma.Seccao === '1'"-->
<!--                                       @click="showCreatePickingDialog(proforma)" color="#9932cc" style="color: white">-->
<!--                                    Picking-->
<!--                                </v-btn>-->
                            </td>
                            <td>
                                <v-btn tile small v-if="writePermission('orders/proformas') && proforma.Seccao === '1'"
                                       color="error" @click="openDialogCancel('cancel', proforma)">
                                    Anular
                                </v-btn>
                                <v-btn tile small color="error"
                                       v-else-if="writePermission('orders/proformas') && proforma.Seccao !== '1'"
                                       @click="openDialogCancel('cancelWithoutTransfer', proforma)">
                                    Anular
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">

            <!-- RESEND EMAIL -->
            <v-dialog v-model="dialogResendEmail" persistent max-width="600">
                <v-card>
                    <v-toolbar flat dense dark color="orange darken-1">
                        <v-toolbar-title class="text-uppercase">
                            <span>Reenviar Email</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogResendEmail = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="pb-0">
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-icon>
                                    <i class="fas fa-user fa-lg"></i>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ proforma.Entidade }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ proforma.Nome }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <i class="fas fa-file-invoice-dollar fa-lg"></i>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ proforma.TipoDoc }} {{ proforma.Serie }}/{{
                                            proforma.NumDoc
                                        }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ $money(proforma.TotalDocumento) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <i class="fas fa-map-marker-alt fa-lg"></i>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ proforma.Localidade }}</v-list-item-title>
                                    <v-list-item-subtitle class="white--text">-</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-container class="py-0">
                            <hr class="mb-6">
                            <v-row>
                                <v-col cols="12" class="pb-0">
                                    <v-text-field
                                            dense
                                            v-model="proforma.email"
                                            outlined
                                            :rules="[rules.required, rules.email]"
                                            label="Email"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-checkbox class="my-0 py-0" dense v-model="proforma.EmailSalesman"
                                                label="Enviar também ao vendedor"></v-checkbox>
                                </v-col>
                            </v-row>
                        </v-container>

                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="cancelResendEmail()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="confirmResendEmail()">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- FATURAR -->
            <v-dialog v-model="dialogInvoice" persistent max-width="600">
                <v-card>
                    <v-toolbar flat dense dark color="green">
                        <v-toolbar-title class="text-uppercase">
                            <span>Faturar</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogInvoice = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-icon>
                                    <i class="fas fa-user fa-lg"></i>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ proforma.Entidade }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ proforma.Nome }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <i class="fas fa-file-invoice-dollar fa-lg"></i>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ proforma.TipoDoc }} {{ proforma.Serie }}/{{
                                            proforma.NumDoc
                                        }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ $money(proforma.TotalDocumento) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <i class="fas fa-map-marker-alt fa-lg"></i>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ proforma.Localidade }}</v-list-item-title>
                                    <v-list-item-subtitle class="white--text">-</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-container>
                            <hr>
                            <div class="title text-center mt-3" style="color: black">
                                <div>Pretende converter a Pró-forma em Fatura?</div>
                            </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="cancelDialogInvoice()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="confirmDialogInvoice()" color="success">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- ANULAR -->
            <v-dialog v-model="dialogCancel" persistent max-width="600">
                <v-card tile>
                    <v-toolbar flat dense dark color="red">
                        <v-toolbar-title class="text-uppercase">
                            <span style="color:white">Anular</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogCancel = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-list two-line>
                            <v-list-item>
                                <v-list-item-icon>
                                    <i class="fas fa-user fa-lg"></i>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ proforma.Entidade }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ proforma.Nome }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <i class="fas fa-file-invoice-dollar fa-lg"></i>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ proforma.TipoDoc }} {{ proforma.Serie }}/{{
                                            proforma.NumDoc
                                        }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ $money(proforma.TotalDocumento) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-icon>
                                    <i class="fas fa-map-marker-alt fa-lg"></i>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ proforma.Localidade }}</v-list-item-title>
                                    <v-list-item-subtitle class="white--text">-</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <v-container>
                            <hr>
                            <div class="title text-center mt-3" style="color: black">
                                <div v-if="optionDialog === 'cancel'">Pretende anular a Pró-forma?</div>
                                <v-alert v-if="optionDialog === 'cancelWithoutTransfer'" type="error">
                                    Alguns artigos não têm stock disponível no armazém A2.
                                </v-alert>
                                <div v-if="optionDialog === 'cancelWithoutTransfer'">Pretende anular a Pró-forma sem
                                    tranferir os artigos para o Armazém A1?
                                </div>
                            </div>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="cancelDialogCancel()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="confirmDialogCancel()" color="error">Confirmo</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- GENERIC DIALOG -->
            <v-dialog v-model="dialog.open" persistent max-width="600">
                <v-card>
                    <v-card-title>
                        {{ dialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="dialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!dialog.loading">
                                <i v-if="dialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!dialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ dialog.message }}</p>
                                <v-alert v-if="dialog.alert" type="error">
                                    {{ dialog.alertMessage }}
                                </v-alert>
                                <v-container v-if="dialog.error && dialog.errors">
                                    <hr>
                                    <v-list two-line class="text-left">
                                        <v-list-item :key="error" v-for="error in dialog.errors">
                                            <v-list-item-content>
                                                <v-list-item-title v-html="error"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-container>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile small v-if="!dialog.loading" color="success" @click="dialog.open = false">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- PICK -->
            <v-dialog v-model="dialogCreatePicking" v-if="selectedProforma != null" persistent scrollable
                      max-width="900">
                <v-card>
                    <v-toolbar flat dense dark color="blue darken-1">
                        <v-toolbar-title class="text-uppercase">
                            <span style="color: white">Criar Picking</span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <span class="subtitle-1" style="color:white">{{
                                selectedProforma.Entidade
                            }} - {{ selectedProforma.Nome }}</span>
                        <v-chip class="ml-2 black--text" color="white">
                            {{ selectedProforma.Localidade }}
                        </v-chip>
                        <v-btn icon @click="closeDialogCreatePicking">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text v-if="!loading">
                        <v-simple-table class="staff_table mt-4">
                            <thead>
                            <tr>
                                <th>ARTIGOS</th>
                                <th>DESCRIÇÃO</th>
                                <th style="width: 1px;">DESC.1</th>
                                <th style="width: 1px;">DESC.2</th>
                                <th style="width: 1px;">QUANT.</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="product in proformaProducts">
                                <td style="white-space: nowrap">{{ product.Artigo }}</td>
                                <td>{{ product.Descricao }}</td>
                                <td class="text-center">{{ product.Desconto1 }}</td>
                                <td class="text-center">{{ product.Desconto2 }}</td>
                                <td class="text-center">{{ product.Quantidade }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="closeDialogCreatePicking">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="createPickOrder">Criar Picking</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>

    </v-container>
</template>

<script>
export default {
    data: () => ({
        loading: false,
        headers: [],
        proformas: [],
        originalProformas: [],
        dialogResendEmail: false,
        dialogInvoice: false,
        dialogCancel: false,
        dialogCreatePicking: false,
        selectedProforma: null,
        proformaProducts: [],
        optionDialog: '',
        proforma: {
            Entidade: '',
            Nome: '',
            email: '',
            EmailSalesman: false,
            proforma_id: 0
        },
        dialog: {
            open: false,
            loading: false,
            error: false,
            errors: [],
            alert: false,
            title: '',
            message: '',
            alertMessage: ''
        },
        filters: {
            created: '',
            updated: '',
            entity: '',
            name: '',
            email: '',
            user: '',
            date: '',
            seccion: '',
            numDoc: '',
            value: '',
            paid: -1,
            salesman: ''
        },
        filtersPaid: [
            {text: '-', value: -1},
            {text: 'Sim', value: 1},
            {text: 'Não', value: 0}
        ],
        filtersSeccion: [
            {text: '-', value: ''},
            {text: 'B2B', value: '1'},
            {text: 'FOX', value: '3'},
            {text: 'FORM', value: '6'}
        ],
        rules: {
            required: value => !!value || 'Este campo é obrigatório.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'O Email é inválido.'
            },
        },
    }),
    created() {
        document.title = 'Proformas'
        this.initialize()
    },
    methods: {
        initialize() {
            this.loading = true
            this.proformas = []
            ApiWS.dispatch('makeGet', {url: '/api/staff/orders/proformas'})
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.headers = responseData.headers
                            this.proformas = responseData.proformas
                            this.originalProformas = responseData.proformas
                            this.filterProformas()
                        }
                        this.loading = false
                    })
                    .catch(() => {
                        this.loading = false
                    })
        },
        writePermission(permission) {
            return User.getters.writePermission(permission)
        },
        clearFilters() {
            this.filters = {
                created: '',
                updated: '',
                entity: '',
                name: '',
                email: '',
                user: '',
                date: '',
                seccion: '',
                numDoc: '',
                value: '',
                paid: -1
            }
            this.filterProformas()
        },
        filterProformas() {
            this.proformas = this.originalProformas
            if (this.filters.created !== '') {
                this.proformas = this.proformas.filter(proforma => proforma.created_at.includes(this.filters.created))
            }
            if (this.filters.updated !== '') {
                this.proformas = this.proformas.filter(proforma => proforma.updated_at.includes(this.filters.updated))
            }
            if (this.filters.entity !== '') {
                this.proformas = this.proformas.filter(proforma => proforma.Entidade.toUpperCase().includes(this.filters.entity.toUpperCase()))
            }
            if (this.filters.name !== '') {
                this.proformas = this.proformas.filter(proforma => proforma.Nome.toLowerCase().includes(this.filters.name.toLowerCase()))
            }
            if (this.filters.email !== '') {
                this.proformas = this.proformas.filter(proforma => (proforma.email !== null && proforma.email.toLowerCase().includes(this.filters.email.toLowerCase())))
            }
            if (this.filters.user !== '') {
                this.proformas = this.proformas.filter(proforma => (proforma.Utilizador !== null && proforma.Utilizador.toUpperCase().includes(this.filters.user.toUpperCase())))
            }
            if (this.filters.date !== '') {
                this.proformas = this.proformas.filter(proforma => proforma.Data.includes(this.filters.date))
            }
            if (this.filters.seccion !== '') {
                this.proformas = this.proformas.filter(proforma => proforma.Seccao === this.filters.seccion)
            }
            if (this.filters.numDoc !== '') {
                this.proformas = this.proformas.filter(proforma => proforma.NumDoc.toString().includes(this.filters.numDoc))
            }
            if (this.filters.value !== '') {
                this.proformas = this.proformas.filter(proforma => proforma.TotalDocumento.includes(this.filters.value))
            }
            if (this.filters.paid !== -1) {
                this.proformas = this.proformas.filter(proforma => proforma.pago === this.filters.paid)
            }
            if (this.filters.salesman !== '') {
                this.proformas = this.proformas.filter(proforma => proforma.Vendedor === this.filters.salesman)
            }
        },
        newDialog(title) {
            this.dialog.title = title
            this.dialog.message = ''
            this.dialog.alertMessage = ''
            this.dialog.loading = true
            this.dialog.error = false
            this.dialog.alert = false
            this.dialog.open = true
            this.dialog.errors = []
        },
        // PAGO
        paid(proforma, option) {
            let title = option === 'partial' ? 'A alterar valor pago...' : 'A marcar a pró-forma como paga...'
            this.newDialog(title)
            let request = {
                url: '/api/staff/orders/proformas/paid',
                data: {
                    option: option,
                    numDoc: proforma.NumDoc,
                    valuePaid: proforma.valor_pago
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialog.message = option === 'partial' ? 'Valor pago actualizado com sucesso!' : 'Pró-forma marcada como paga com sucesso!'
                            this.dialog.loading = false
                            this.initialize()
                        } else {
                            this.dialog.message = option === 'partial' ? 'Ocorreu um erro ao actualizar o valor pago!' : 'Ocorreu um erro ao marcar a próforma como paga!'
                            this.dialog.error = true
                            this.dialog.loading = false
                        }
                    })
                    .catch(() => {
                        this.dialog.message = option === 'partial' ? 'Ocorreu um erro ao actualizar o valor pago!' : 'Ocorreu um erro ao marcar a próforma como paga!'
                        this.dialog.error = true
                        this.dialog.loading = false
                    })
        },
        // REENVIAR
        openDialogResend(proforma) {
            this.proforma = Object.assign({}, proforma)
            this.dialogResendEmail = true
        },
        cancelResendEmail() {
            this.dialogResendEmail = false
        },
        confirmResendEmail() {
            this.dialogResendEmail = false
            this.newDialog('A reenviar o email...')
            let request = {
                url: '/api/staff/orders/proformas/resend',
                data: {
                    id: this.proforma.proforma_id,
                    email: this.proforma.email,
                    client: this.proforma.Entidade,
                    salesman: this.proforma.EmailSalesman
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialog.message = 'O email foi reenviado com sucesso!'
                            this.dialog.loading = false
                            this.initialize()
                        } else if (responseData.status === 'WARN') {
                            this.dialog.message = responseData.data
                            this.dialog.errors = responseData.errors
                            this.dialog.error = true
                            this.dialog.loading = false
                        } else {
                            this.dialog.message = 'Ocorreu um erro a reenviar o email'
                            this.dialog.error = true
                            this.dialog.loading = false
                            this.dialog.alert = true
                            this.dialog.alertMessage = 'Erro de servidor!'
                        }
                    })
                    .catch(() => {
                        this.dialog.message = 'Ocorreu um erro a reenviar o email'
                        this.dialog.error = true
                        this.dialog.loading = false
                        this.dialog.alert = true
                        this.dialog.alertMessage = 'Erro de servidor!'
                    })
        },
        // FATURAR
        openDialogInvoice(proforma) {
            this.proforma = Object.assign({}, proforma)
            this.dialogInvoice = true
        },
        cancelDialogInvoice() {
            this.dialogInvoice = false
            this.proforma = {}
        },
        confirmDialogInvoice() {
            this.dialogInvoice = false
            this.newDialog('A faturar a pró-forma...')
            let request = {
                url: '/api/staff/orders/proformas/invoice',
                data: {
                    option: this.optionDialog,
                    numDoc: this.proforma.NumDoc,
                    tipoDoc: this.proforma.TipoDoc,
                    serie: this.proforma.Serie,
                    seccao: this.proforma.Seccao,
                    id: this.proforma.Id
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialog.loading = false
                            this.dialog.message = responseData.data
                            this.sendInvoiceEmail(responseData.document);
                            this.initialize()
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = responseData.data
                            this.dialog.errors = responseData.errors
                        }
                    })
                    .catch(() => {
                        this.dialog.message = 'Ocorreu um erro a faturar a proforma'
                        this.dialog.error = true
                        this.dialog.loading = false
                        this.dialog.alert = true
                        this.dialog.alertMessage = 'Erro de servidor!'
                    })
        },
        // ANULAR
        openDialogCancel(option, proforma) {
            this.optionDialog = option
            this.proforma = Object.assign({}, proforma)
            this.dialogCancel = true
        },
        cancelDialogCancel() {
            this.dialogCancel = false
            this.optionDialog = ''
            this.proforma = {}
        },
        confirmDialogCancel() {
            this.dialogCancel = false
            this.newDialog('A anular a pró-forma...')
            let request = {
                url: '/api/staff/orders/proformas/cancel',
                data: {
                    option: this.optionDialog,
                    numDoc: this.proforma.NumDoc,
                    tipoDoc: this.proforma.TipoDoc,
                    serie: this.proforma.Serie,
                    seccao: this.proforma.Seccao,
                    id: this.proforma.Id
                }
            }
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialog.loading = false
                            this.dialog.message = responseData.data
                            if (this.optionDialog === 'cancelWithoutTransfer') {
                                this.dialog.alert = true
                                this.dialog.alertMessage = 'Não foi possível transferir os artigos para o A1'
                            }
                            this.initialize()
                        } else if (responseData.status === 'WARN') {
                            this.optionDialog = 'cancelWithoutTransfer'
                            this.dialogCancel = true
                            this.dialog.open = false
                            this.dialog.error = true
                            this.dialog.errors = responseData.errors
                            this.dialog.loading = false
                        } else {
                            this.dialog.message = 'Ocorreu um erro a anular a proforma'
                            this.dialog.error = true
                            this.dialog.loading = false
                            this.dialog.alert = true
                            this.dialog.alertMessage = 'Erro de servidor!'
                        }
                    })
                    .catch(() => {
                        if (this.optionDialog === 'invoice') {
                            this.dialog.message = 'Ocorreu um erro a faturar a proforma'
                        } else {
                            this.dialog.message = 'Ocorreu um erro a anular a proforma'
                        }
                        this.dialog.error = true
                        this.dialog.loading = false
                        this.dialog.alert = true
                        this.dialog.alertMessage = 'Erro de servidor!'
                    })
        },
        sendInvoiceEmail(invoice) {
            this.newDialog('A enviar fatura ...')
            let request = {
                url: '/api/staff/primavera/invoice/send/' + invoice.TipoDoc + '/' + invoice.NumDoc + "/" + invoice.Serie
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.dialog.loading = false
                            this.dialog.message = 'Email da fatura enviado com sucesso!'
                        } else {
                            this.dialog.message = 'Ocorreu um erro ao enviar email da fatura'
                            this.dialog.error = true
                            this.dialog.loading = false
                            this.dialog.alert = true
                            this.dialog.alertMessage = 'Erro de servidor!'
                        }
                    })
                    .catch(() => {
                        this.dialog.message = 'Ocorreu um erro ao enviar email da fatura'
                        this.dialog.error = true
                        this.dialog.loading = false
                        this.dialog.alert = true
                        this.dialog.alertMessage = 'Erro de servidor!'
                    })
        },
        //PICKING
        showCreatePickingDialog(proforma) {
            let loader = this.$loading.show()
            // this.loading = true;
            this.selectedProforma = JSON.parse(JSON.stringify(proforma));

            //Get lists of products by proformaID
            let request = {
                url: api.ordersProformaLines(),
                data: {
                    proformaId: this.selectedProforma.NumDoc
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.proformaProducts = responseData.products;

                            // this.loading = false
                            loader.hide();
                        } else {
                            // this.loading = false

                        }
                    }).catch(() => {
                        // this.loading = false
                        // loader.hide()

                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })

            //Show dialog with list of products & option to createPick
            this.dialogCreatePicking = true;
            // console.log(proforma)

        },
        createPickOrder() {
            let loader = this.$loading.show();

            if (this.proformaProducts.length > 0) {
                let request = {
                    url: '/api/staff/orders/addPick',
                    data: {
                        products: this.proformaProducts
                    }
                }

                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.idLastPick = responseData.pickId;
                                this.dialogAfterPick = true
                                loader.hide();
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Artigos adicionados ao picking com sucesso!',
                                    type: 'success'
                                })
                            } else {
                                loader.hide();
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Erro ao adicionar ao picking!',
                                    type: 'error'
                                })
                            }
                            this.loadingPick = false;
                            this.dialogCreatePicking = false;
                        })
                        .catch(() => {
                            this.loadingPick = false;
                            this.dialogCreatePicking = false;
                            loader.hide();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })

            } else {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Não existe artigos para picking!',
                    type: 'error'
                })
            }
        },
        closeDialogCreatePicking() {
            this.dialogCreatePicking = false
            this.proformaProducts = [];
        }
    }
}
</script>
