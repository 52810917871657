<template>
  <v-card flat>

    <v-row no-gutters>

      <!-- DETAILS -->
      <v-col class="ma-2">
        <v-card>

          <v-toolbar color="primary" dense dark>
            <v-toolbar-title class="subtitle-1">DEFINIÇÕES DA CAMPANHA </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn tile small v-if="!campaignPristine || !detailPtPristine || !detailEsPristine" color="success" class="float-right" :disabled="saving"
                   :loading="saving" @click="save">Guardar
            </v-btn>
          </v-toolbar>

          <v-row v-if="loading">
            <v-col class="text-center">
              <v-progress-circular
                  class="ma-12"
                  :size="100"
                  :width="2"
                  color="blue darken-4"
                  indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>

          <v-row no-gutters v-if="!loading" style="min-height: 400px">

            <!-- BASE SETTINGS -->
            <v-col class="ma-2">

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    NOME
                    <v-text-field v-model="campaign.mainName" outlined dense hide-details
                                  @keyup="validate"></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Tipo
                    <div>
                      <v-btn v-for="(type, typeIndex) in campaignTypes" :key="typeIndex" tile small :color="campaign.campaignTypeId===type.id ? 'primary' : ''"
                             @click="setType(type.id)">{{ type.type }}
                      </v-btn>

                      <!--                      <v-btn tile small :color="campaign.campaignTypeId===2 ? 'primary' : ''"
                                                   @click="setType(2)">Promoção
                                            </v-btn>
                                            <v-btn tile small :color="campaign.campaignTypeId===1 ? 'primary' : ''"
                                                   @click="setType(1)">Pré-encomenda
                                            </v-btn>
                                            <v-btn tile small :color="campaign.campaignTypeId===4 ? 'primary' : ''"
                                                   @click="setType('quantity')">Quantidade
                                            </v-btn>
                                            <v-btn tile small :color="campaign.campaignTypeId===5 ? 'primary' : ''" @click="setType('coupon')">
                                              Cupão
                                            </v-btn>-->
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

             <v-list-item v-if="campaign.campaignTypeId!=='coupon'">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Quem pode encomendar
                    <div>
                      <v-btn tile small :color="campaign.B2BVisibility === 'PARTNER' ? 'primary' : ''"
                             @click="setVisualization('PARTNER')">Comercial
                      </v-btn>
                      <v-btn tile small :color="campaign.B2BVisibility === 'ALL' ? 'primary' : ''"
                             @click="setVisualization('ALL')">Comercial + Cliente
                      </v-btn>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="campaign.campaignTypeId!=1">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Múltiplas encomendas
                    <div>
                      <v-btn tile small :color="campaign.multipleOrders ? 'success' : 'default'"
                             @click="setMultipleOrders(true)">Sim
                      </v-btn>
                      <v-btn tile small :color="campaign.multipleOrders ? 'default' : 'error'"
                             @click="setMultipleOrders(false)">Não
                      </v-btn>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

<!--              <v-list-item v-if="campaign.campaignTypeId===1">-->
              <v-list-item v-else>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Alterar datas das linhas
                    <div>
                      <v-btn tile small :color="campaign.fixedDates ? 'success' : ''" @click="setFixedDates(true)">Sim
                      </v-btn>
                      <v-btn tile small :color="campaign.fixedDates ? '' : 'error'" @click="setFixedDates(false)">Não
                      </v-btn>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item v-if="campaign.campaignTypeId===2">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Apenas artigos com stock
                    <div>
                      <v-btn tile small :color="campaign.stock_only ? 'success' : ''" @click="setStockOnly(true)">Sim
                      </v-btn>
                      <v-btn tile small :color="campaign.stock_only ? '' : 'error'" @click="setStockOnly(false)">Não
                      </v-btn>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-col>

            <v-col class="ma-2">

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Estado
                    <div>
                      <v-btn tile small :color="campaign.active ? 'success' : ''" @click="setActive(true)">Activa
                      </v-btn>
                      <v-btn tile small :color="campaign.active ? '' : 'error'" @click="setActive(false)">Inactiva
                      </v-btn>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Desconto base
                    <v-text-field type="number" v-model.number="campaign.basicDiscount" outlined dense hide-details
                                  @keyup="validate"></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Ordem
                    <v-text-field v-model="campaign.ordenation" outlined dense hide-details
                                  @keyup="validate"></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Cor Principal
                    <v-text-field v-model="campaign.principalColor" outlined dense hide-details
                                  @keyup="validate"></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">
                    Cor Secundária
                    <v-text-field v-model="campaign.secondaryColor" outlined dense hide-details
                                  @keyup="validate"></v-text-field>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-col>

            <!-- DATAS -->
            <v-col class="ma-2" sm="auto">
              <v-row no-gutters>
                <v-col class="mx-2">
                  <v-date-picker v-model="campaign.dateStart" :locale="locale" @change="validate"></v-date-picker>
                </v-col>
                <v-col>
                  <v-date-picker v-model="campaign.dateEnd" :locale="locale" @change="validate"></v-date-picker>
                </v-col>
              </v-row>
            </v-col>

          </v-row>

          <v-row no-gutters v-if="!loading">

            <!-- DESCRIPTIONS PT -->
            <v-col class="ma-2">
              <v-card>
                <v-toolbar color="primary" dark dense>
                  <v-toolbar-title class="subtitle-1">INFORMAÇÃO PT</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <flag iso="pt" v-bind:squared=false class="float-right"></flag>
                </v-toolbar>
                <v-card-text>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Nome PT</v-list-item-title>
                      <v-text-field v-model="campaign.detailsName" outlined dense hide-details
                                    @keyup="validate"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Legenda PT
                      </v-list-item-title>
                      <v-text-field v-model="campaign.description" outlined dense hide-details
                                    @keyup="validate"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Descrição PT
                      </v-list-item-title>
                      <editor
                          apiKey="pmmncfsqqm0oln114ff7w3t2w21ugzvr2aumwlrt0e0q3luh"
                          v-model="campaign.explanation"
                          style="height: 500px"
                          @keyup="validate"
                          :init="{plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste imagetools wordcount'}"
                      ></editor>
                    </v-list-item-content>
                  </v-list-item>

                </v-card-text>
              </v-card>
            </v-col>

            <!-- DESCRIPTIONS ES -->
            <v-col class="ma-2">
              <v-card>
                <v-toolbar color="primary" dark dense>
                  <v-toolbar-title class="subtitle-1">INFORMAÇÃO ES</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <flag iso="es" v-bind:squared=false class="float-right"></flag>
                </v-toolbar>
                <v-card-text>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Nome ES</v-list-item-title>
                      <v-text-field v-model="esDetails.detailsName" outlined dense hide-details
                                    @keyup="validate"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Legenda ES
                      </v-list-item-title>
                      <v-text-field v-model="esDetails.description" outlined dense hide-details
                                    @keyup="validate"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 font-weight-bold text-uppercase">Descrição ES
                      </v-list-item-title>
                      <editor
                          apiKey="pmmncfsqqm0oln114ff7w3t2w21ugzvr2aumwlrt0e0q3luh"
                          v-model="esDetails.explanation"
                          style="height: 500px"
                          @keyup="validate"
                          :init="{plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste imagetools wordcount'}"
                      ></editor>

                    </v-list-item-content>
                  </v-list-item>

                </v-card-text>
              </v-card>
            </v-col>

          </v-row>

        </v-card>
      </v-col>

    </v-row>

  </v-card>
</template>
<script>

import Editor from '@tinymce/tinymce-vue';

export default {

  components: {
    'editor': Editor
  },
  data() {
    return {
      locale: '',
      saving: false,
      loading: true,
      campaignOriginal: {},
      editCampaign: {},
      campaign: {},
      campaignId: null,
      esDetails: {},
      esDetailsOriginal: {},
      campaignTypes: [],
      campaignPristine: true,
      detailPtPristine: true,
      detailEsPristine: true,
    }
  },
  mounted() {
    this.locale = (User.getters.getLanguage + '-' + User.getters.getLanguage).toLocaleLowerCase();
  },
  created() {
    this.campaignId = this.$route.params.id;
    if (this.campaignId > 0) {
      this.getCampaign();
    } else {
      this.campaign.active = false;
      this.getCampaignTypes();
      this.setFixedDates(false);
      this.setMultipleOrders(false);
      this.loading = false;
    }
  },
  methods: {
    getCampaignTypes(){
      let request = {
        url: '/api/staff/campaigns/types'
      };
      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data;
            if (responseData.status === 'OK') {
              this.campaignTypes = responseData.campaignTypes
              this.loading = false;
            }
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    },
      getCampaign() {
        let request = {
          url: '/api/staff/campaigns/' + this.campaignId
        };
        ApiWS.dispatch('makeGet', request)
            .then(response => {
              let responseData = response.data;
              if (responseData.status === 'OK') {
                this.campaign = responseData.campaign;
                this.campaignOriginal = Object.assign({}, this.campaign);
                this.esDetails = responseData.esDetails;
                this.esDetailsOriginal = Object.assign({}, this.esDetails);
                this.campaignTypes = responseData.campaignTypes;
                this.loading = false;
              }
            })
            .catch(() => {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro de servidor!',
                type: 'error'
              })
            })
      },

    setType(value) {
      this.campaign.campaignTypeId = value;
      this.validate();
    },
    setActive(value) {
      this.campaign.active = value;
      this.validate();
    },
    setVisualization(value) {
      this.campaign.B2BVisibility = value;
      this.validate();
    },
    setMultipleOrders(value) {
      this.campaign.multipleOrders = value;
      this.campaign.fixedDates = false;
      this.validate();
    },
    setFixedDates(value) {
      this.campaign.fixedDates = value;
      this.campaign.multipleOrders = false;
      this.validate();
    },
    setStockOnly(value) {
      this.campaign.stock_only = value;
      this.validate();
    },
    validate() {

      this.campaignPristine = true;
      this.detailPtPristine = true;
      this.detailEsPristine = true;

      if (this.campaign.mainName == '' || this.campaign.mainName != this.campaignOriginal.mainName) {
        this.campaignPristine = false;
      }
      if (this.campaign.active != this.campaignOriginal.active) {
        this.campaignPristine = false;
      }
      if (this.campaign.campaignTypeId == '' || this.campaign.campaignTypeId != this.campaignOriginal.campaignTypeId) {
        this.campaignPristine = false;
      }
      if (this.campaign.B2BVisibility != this.campaignOriginal.B2BVisibility) {
        this.campaignPristine = false;
      }
      if (this.campaign.multipleOrders != this.campaignOriginal.multipleOrders) {
        this.campaignPristine = false;
      }
      if (this.campaign.basicDiscount != this.campaignOriginal.basicDiscount) {
        this.campaignPristine = false;
      }
      if (this.campaign.fixedDates != this.campaignOriginal.fixedDates) {
        this.campaignPristine = false;
      }
      if (this.campaign.ordenation != this.campaignOriginal.ordenation) {
        this.campaignPristine = false;
      }
      if (this.campaign.principalColor != this.campaignOriginal.principalColor) {
        this.campaignPristine = false;
      }
      if (this.campaign.secondaryColor != this.campaignOriginal.secondaryColor) {
        this.campaignPristine = false;
      }
      /*if (this.campaign.stock_only != this.campaignOriginal.stock_only) {
        this.campaignPristine = false;
      }*/

      if (this.campaign.dateStart != this.campaignOriginal.dateStart) {
        this.campaignPristine = false;
      }
      if (this.campaign.dateEnd != this.campaignOriginal.dateEnd) {
        this.campaignPristine = false;
      }
      if (this.campaign.detailsName != this.campaignOriginal.detailsName) {
        this.detailPtPristine = false;
      }
      if (this.esDetails.detailsName != this.esDetailsOriginal.detailsName) {
        this.detailEsPristine = false;
      }
      if (this.campaign.description != this.campaignOriginal.description) {
        this.detailPtPristine = false;
      }
      if (this.esDetails.description != this.esDetailsOriginal.description) {
        this.detailEsPristine = false;
      }
      if (this.campaign.explanation != this.campaignOriginal.explanation) {
        this.detailPtPristine = false;
      }
      if (this.esDetails.explanation != this.esDetailsOriginal.explanation) {
        this.detailEsPristine = false;
      }
    },
    save() {
      this.saving = true;
      let type = "makePut";
      if(this.campaignId == 0)
        type = "makePost";


      let request = {
        url: this.campaignId > 0 ? api.b2bCampaignsUpdateInfo() + this.campaignId : api.b2bCampaignsUpdateInfo() + "new",
        data: {
          campaign: !this.campaignPristine ? this.campaign : null,
          esDetails: !this.detailEsPristine ? this.esDetails : null,
          ptDetails: !this.detailPtPristine ? {'description':this.campaign.description,
                                               'detailsName':this.campaign.detailsName,
                                               'explanation':this.campaign.explanation,
                                               'id':this.campaign.id
                                              } : null
        }
      };
      ApiWS.dispatch(type, request)
          .then(response => {
            let responseData = response.data;

            this.saving = false;

            if (responseData.status === 'OK') {
              this.campaignOriginal = Object.assign({}, this.campaign) ;
              //this.campaiggn = Object.assign({}, this.campaignOriginal);

              if(this.campaignId == 0){
                this.$router.push('/b2b/campaigns2/' + responseData.data);
                this.campaignId = responseData.data;
              }
              this.campaignPristine = true;
              this.detailPtPristine = true;
              this.detailEsPristine = true;

              Alert.dispatch('show', {
                snackbar: true,
                text: 'Alterações guardadas com sucesso!',
                type: 'success'
              })
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro ao guardar as alterações!',
                type: 'error'
              })
            }
            this.saving = false;
          })
          .catch(() => {
            this.saving = false;
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
          })
    }
  }
}
</script>
