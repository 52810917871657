import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pt from '../lang/pt'
import es from '../lang/es'

Vue.use(VueI18n)

const messages = {
    pt,
    es
}

const i18n = new VueI18n({
    locale: 'pt',
    fallbackLocale: 'es',
    messages
})

export default i18n
