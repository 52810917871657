<template>
    <v-card flat>

        <v-row no-gutters>

            <!-- BASE SETTINGS -->
            <v-col class="ma-2">
                <v-card>

                    <v-toolbar color="primary" dense dark>
                        <v-toolbar-title class="subtitle-1">VISIBILIDADE</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-row no-gutters>

                        <!-- PERFIS PERMITIDOS -->
                        <v-col class="ma-2">
                            <v-card outlined>
                                <v-toolbar color="teal darken-1" dark dense>
                                    <v-toolbar-title class="subtitle-1">PERFIS PERMITIDOS</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon v-if="profiles.length && !loading && !addProfile && !editProfile" @click="toogleAddProfile">
                                        <v-icon>add_circle</v-icon>
                                    </v-btn>
                                    <v-btn icon v-if="profilesOK.length && !loading && !addProfile" :outlined="editProfile" @click="toogleEditProfile">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-list-item v-if="loading">
                                    <v-progress-linear height="2" indeterminate color="blue darken-4"></v-progress-linear>
                                </v-list-item>
                                <v-list class="pa-0" v-if="!loading">
                                    <v-list-item class="pt-2" v-if="addProfile">
                                        <v-list-item-content>
                                            <v-select
                                                    :items="profiles"
                                                    v-model="selectedProfile"
                                                    hide-details
                                                    label="PERFIL A ADICIONAR"
                                                    outlined
                                                    :error="selectedProfile === ''"
                                                    dense
                                                    :disabled="saving"
                                            >
                                                <template slot="selection" slot-scope="data">
                                                    {{ data.item.value }} - {{ data.item.text }}
                                                </template>
                                                <template slot="item" slot-scope="data">
                                                    <v-list-tile-content>
                                                        <v-list-tile-title>{{ data.item.value }} - {{ data.item.text }}</v-list-tile-title>
                                                    </v-list-tile-content>
                                                </template>
                                            </v-select>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-btn-toggle borderless dense>
                                                <v-btn @click="toogleAddProfile" :disabled="saving">
                                                    <v-icon>clear</v-icon>
                                                </v-btn>
                                                <v-btn color="success" class="white--text" :disabled="saving || selectedProfile === ''" :loading="saving" @click="saveProfile(selectedProfile)">
                                                    <v-icon>done</v-icon>
                                                </v-btn>
                                            </v-btn-toggle>
                                        </v-list-item-action>
                                    </v-list-item>
                                    <template v-for="profile in profilesOK">
                                        <v-divider></v-divider>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-bold">{{ profile.B2BWEBCatalogProfile_id }}</v-list-item-title>
                                                <v-list-item-subtitle v-if="profile.Nome">{{ profile.Nome }}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="!profile.Nome" class="red--text">PERFIL INVÁLIDO</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn
                                                        x-small
                                                        fab

                                                        color="error"
                                                        :disabled="saving && selectedProfile===profile.B2BWEBCatalogProfile_id"
                                                        :loading="saving && selectedProfile===profile.B2BWEBCatalogProfile_id"
                                                        v-if="editProfile && !addProfile"
                                                        @click="deleteProfile(profile.B2BWEBCatalogProfile_id)"
                                                >
                                                    <v-icon>clear</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-card>
                        </v-col>

                        <!-- CLIENTES PERMITIDOS -->
                        <v-col class="ma-2">

                            <v-card outlined>
                                <v-toolbar color="success" dark dense>
                                    <v-toolbar-title class="subtitle-1">CLIENTES PERMITIDOS</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon v-if="!loading && !addClientOK && !editClientOK" @click="openDialogClientOK">
                                        <v-icon>add_circle</v-icon>
                                    </v-btn>
                                    <v-btn icon v-if="!loading && !addClientOK && clientsOK.length" :outlined="editClientOK" @click="toogleEditClientOK">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-list-item v-if="loading">
                                    <v-progress-linear height="2" indeterminate color="blue darken-4"></v-progress-linear>
                                </v-list-item>
                                <v-list class="pa-0" v-if="!loading">
                                    <template v-for="client in clientsOK">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-bold">{{ client.Cliente }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ client.Nome }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn
                                                        x-small
                                                        fab

                                                        color="error"
                                                        :disabled="saving && selectedClient===client.Cliente"
                                                        :loading="saving && selectedClient===client.Cliente"
                                                        v-if="editClientOK && !addClientOK"
                                                        @click="deleteClient(client.Cliente)"
                                                >
                                                    <v-icon>clear</v-icon>
                                                </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                    </template>
                                </v-list>
                            </v-card>
                        </v-col>

                        <!-- CLIENTES BLOQUEADOS -->
                        <v-col class="ma-2">
                            <v-card outlined>
                                <v-toolbar color="error" dark dense>
                                    <v-toolbar-title class="subtitle-1">CLIENTES BLOQUEADOS</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon v-if="!loading && !addClientNOK && !editClientNOK" @click="openDialogClientNOK">
                                        <v-icon>add_circle</v-icon>
                                    </v-btn>
                                    <v-btn icon v-if="!loading && !addClientNOK && clientsNOK.length" :outlined="editClientNOK" @click="toogleEditClientNOK">
                                        <v-icon>edit</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-list-item v-if="loading">
                                    <v-progress-linear height="2" indeterminate color="blue darken-4"></v-progress-linear>
                                </v-list-item>
                                <v-list class="pa-0" v-if="!loading">
                                    <v-list-item-group active-class="blue--text">
                                        <template v-for="client in clientsNOK">
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-bold">{{ client.Cliente }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ client.Nome }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn
                                                            x-small
                                                            fab

                                                            color="error"
                                                            :disabled="saving && selectedClient===client.Cliente"
                                                            :loading="saving && selectedClient===client.Cliente"
                                                            v-if="editClientNOK && !addClientNOK"
                                                            @click="deleteClient(client.Cliente)"
                                                    >
                                                        <v-icon>clear</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-divider></v-divider>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>
                        </v-col>


                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">
            <!-- ADD CLIENT -->
            <v-dialog v-model="dialogAddClient" scrollable max-width="800">
                <v-card>
                    <v-card-title class="headline darken-1" :class="cardTitleClass">
                        <v-row no-gutters>
                            <v-col class="text-uppercase">
                                <v-icon dark style="float: right;" @click="dialogAddClient = false">close</v-icon>
                                <span style="color:white" v-if="cardTitleClass==='green'">Adicionar Cliente Permitido</span>
                                <span style="color:white" v-if="cardTitleClass==='red'">Adicionar Cliente Bloqueado</span>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table dense>
                            <thead>
                            <tr>
                                <th class="text-left" style="width: 120px">Cliente</th>
                                <th class="text-left">Name</th>
                                <th style="width: 1px"></th>
                            </tr>
                            <tr>
                                <th class="pa-1">
                                    <v-text-field v-model="filters.code" outlined hide-details dense @keyup="filterClients"></v-text-field>
                                </th>
                                <th class="pa-1">
                                    <v-text-field v-model="filters.name" outlined hide-details dense @keyup="filterClients"></v-text-field>
                                </th>
                                <th class="pa-1"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in items" :key="item.name">
                                <td>{{ item.Cliente }}</td>
                                <td>{{ item.Nome }}</td>
                                <td class="pa-0">
                                    <v-btn
                                            x-small
                                            fab

                                            color="success"
                                            class="ma-1"
                                            :disabled="saving && selectedClient===item.Cliente"
                                            :loading="saving && selectedClient===item.Cliente"
                                            @click="saveClient(item.Cliente)">
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <p class="ma-0">A mostrar 1-{{ items.length }} de {{ itemsFiltered.length }} clientes. (Total {{ clients.length }})</p>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </v-card>
</template>
<script>
    export default {
        props: {
            campaign: {},
        },
        data() {
            return {
                addProfile: false,
                editProfile: false,
                addClientOK: false,
                editClientOK: false,
                addClientNOK: false,
                editClientNOK: false,
                selectedProfile: '',
                selectedClient: '',
                saving: false,
                loading: false,
                profiles: [],
                items: [],
                itemsFiltered: [],
                clients: [],
                profilesOKOriginal: [],
                clientsOKOriginal: [],
                clientsNOKOriginal: [],
                profilesOK: [],
                clientsOK: [],
                clientsNOK: [],
                dialogAddClient: false,
                cardTitleClass: 'green',
                filters: {
                    code: '',
                    name: '',
                }
            }
        },
        created() {
            this.loading = true;
            this.getClients();
        },
        methods: {
            getClients() {
                let request = {url: api.b2bCampaigns() + '/' + this.$route.params.id + '/clients'};
                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.profiles = responseData.profiles;
                            this.clients = responseData.clients;
                            this.profilesOKOriginal = responseData.profilesOKOriginal;
                            this.clientsOKOriginal = responseData.clientsOKOriginal;
                            this.clientsNOKOriginal = responseData.clientsNOKOriginal;
                            this.profilesOK = responseData.profilesOK;
                            this.clientsOK = responseData.clientsOK;
                            this.clientsNOK = responseData.clientsNOK;
                            this.loading = false;
                            this.saving = false;
                            this.selectedProfile = '';
                            this.addProfile = false;
                            if (this.clientsOK.length === 0) {
                                this.editClientOK = false;
                            }
                            if (this.clientsNOK.length === 0) {
                                this.editClientNOK = false;
                            }
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            toogleEditProfile() {
                this.addProfile = false;
                this.editProfile = !this.editProfile;
            },
            toogleAddProfile() {
                this.selectedProfile = '';
                this.editProfile = false;
                this.addProfile = !this.addProfile;
            },
            deleteProfile(id) {
                this.selectedClient = '';
                this.selectedProfile = id;
                this.saving = true;
                let request = {
                    url: api.b2bCampaignsSaveProfile(),
                    data: {
                        mode: 'delete',
                        campaign_id: this.campaign.campaign_id,
                        profile: id,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.getClients();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Perfil removido com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao remover o perfil!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })

            },
            saveProfile() {
                this.saving = true;
                let request = {
                    url: api.b2bCampaignsSaveProfile(),
                    data: {
                        mode: 'add',
                        campaign_id: this.campaign.campaign_id,
                        profile: this.selectedProfile,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.getClients();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Perfil adicionado com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao adicionar o perfil!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            toogleEditClientOK() {
                this.addClientOK = false;
                this.cardTitleClass = 'green';
                this.editClientOK = !this.editClientOK;
            },
            toogleEditClientNOK() {
                this.addClientNOK = false;
                this.cardTitleClass = 'red';
                this.editClientNOK = !this.editClientNOK;
            },
            openDialogClientOK() {
                this.dialogAddClient = true;
                this.cardTitleClass = 'green';
                this.resetFiltersClients();
                this.filterClients();
            },
            openDialogClientNOK() {
                this.dialogAddClient = true;
                this.cardTitleClass = 'red';
                this.resetFiltersClients();
                this.filterClients();
            },
            resetFiltersClients() {
                this.filters = {
                    code: '',
                    name: '',
                }
            },
            filterClients() {
                this.itemsFiltered = this.clients;
                if (this.filters.code !== '') {
                    this.itemsFiltered = this.itemsFiltered.filter(item => item.Cliente.toUpperCase().includes(this.filters.code.toUpperCase()))
                }
                if (this.filters.name !== '') {
                    this.itemsFiltered = this.itemsFiltered.filter(item => item.Nome.toUpperCase().includes(this.filters.name.toUpperCase()))
                }
                this.items = this.itemsFiltered.slice(0, 100);
            },
            deleteClient(code) {
                this.selectedProfile = '';
                this.selectedClient = code;
                this.saving = true;
                let request = {
                    url: api.b2bCampaignsSaveClient(),
                    data: {
                        mode: 'delete',
                        list: this.cardTitleClass === 'green' ? 'ok' : 'nok',
                        campaign_id: this.campaign.campaign_id,
                        client: code,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.getClients();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Cliente adicionado com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao adicionar o cliente!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            saveClient(code) {
                this.selectedClient = code;
                this.saving = true;
                let request = {
                    url: api.b2bCampaignsSaveClient(),
                    data: {
                        mode: 'add',
                        list: this.cardTitleClass === 'green' ? 'ok' : 'nok',
                        campaign_id: this.campaign.campaign_id,
                        client: code,
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.getClients();
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Cliente adicionado com sucesso!',
                                type: 'success'
                            });
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao adicionar o cliente!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        this.saving = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            }
        }
    }
</script>
