import Vue from 'vue'
import App from './App.vue'
// import 'babel-polyfill'
import VueHtmlToPaper from 'vue-html-to-paper';
import * as VueGoogleMaps from 'vue2-google-maps'
import Loading from 'vue-loading-overlay';
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment'

//Connect API
import axios from 'axios'

//Translate
import i18n from './plugins/i18n'

//Icon Vuetify e Vuetify
import '@fortawesome/fontawesome-free/css/all.css'
import FlagIcon from 'vue-flag-icon';
import vuetify from './plugins/vuetify';

//Models
import User from './models/User'
import AppModel from './models/App'
import Alert from './models/Alert'
import Permission from './models/Permission'
import Profile from './models/Profile'
import Product from './models/Product'
import B2B from './models/B2B'
import ClientModel from './models/ClientModel'
import Reports from './models/Reports'
import Order from './models/Order'
import DropShipping from './models/DropShipping'
import Debits from './models/Debits'
import ApiWS from './models/ApiWS'

//Routers
import router from './routers/router'
import api from './routers/api'

//Layout
import LoginLayout from './layouts/Login'
import DefaultLayout from './layouts/Default'
import PageTitle from "./components/Common/PageTitle";
import ResponseDialog from "./components/Common/ResponseDialog";
import StaffDialog from "./components/Common/Dialog"

//Cookies
import VueCookies from 'vue-cookies'

//Graphs
import VueGoogleCharts from 'vue-google-charts'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

let access_token = User.getters.getAccessToken
if (access_token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
}

window.axios = axios
//Window routers
window.api = api

//Window models
window.Alert = Alert
window.User = User
window.AppModel = AppModel
window.Permission = Permission
window.Profile = Profile
window.Product = Product
window.B2B = B2B
window.ClientModel = ClientModel
window.Reports = Reports
window.Order = Order
window.DropShipping = DropShipping
window.Debits = Debits
window.ApiWS = ApiWS

// Vue.component('DynamicScroller', VueVirtualScroller.DynamicScroller)
// Vue.component('DynamicScrollerItem', VueVirtualScroller.DynamicScrollerItem)
Vue.component('default-layout', DefaultLayout);
Vue.component('login-layout', LoginLayout);
Vue.component('page-title', PageTitle);
Vue.component('response-dialog', ResponseDialog);
Vue.component('staff-dialog', StaffDialog)
Vue.use(FlagIcon);
Vue.use(VueCookies);
Vue.use(VueHtmlToPaper);
Vue.use(VueGoogleCharts);
Vue.use(VueVirtualScroller)
Vue.use(Loading, {
    container: null,
    canCancel: false,
    color: 'rgb(192, 13, 13)',
    backgroundColor: '#ffffff',
    height: 128,
    width: 128,
    loader: 'spinner',
    opacity: 0.3,
});

VueCookies.config('Infinity');

Vue.prototype.$downloadPDF = function (serie, tipodoc, numdoc) {
    let request = {
        url: api.orderPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
    }

    ApiWS.dispatch('makeGet', request)
        .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {
                let request = {
                    url: api.orderDownloadPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
                }
                ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/pdf'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';
                        a.download = serie + '_' + tipodoc + '_' + numdoc + '.pdf';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            } else {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro ao gerar PDF!',
                    type: 'error'
                })
            }
        })
        .catch(response => {
            Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro de servidor!',
                type: 'error'
            })
        })
}

Vue.prototype.$money = function (value) {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €'
}

Vue.prototype.$2decimal = function (value) {
    return (value / 1).toFixed(2)
}

Vue.prototype.$dollar = function (value) {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' $'
}

Vue.prototype.$integer = function (value) {
    let val = (value / 1).toFixed(0).replace('.', ',')
    return val.toString()
}

Vue.prototype.$isDateBetween = function (start_ts, end_ts) {
    let now = new Date();
    let start = new Date(start_ts);
    let end = (new Date(end_ts)).setHours(23, 59, 59);
    return now >= start && now <= end;
}

Vue.prototype.$writePermission = function (permission) {
    return User.getters.writePermission(permission)
}

Vue.prototype.$readPermission = function (permission) {
    return User.getters.readPermission(permission)
}

Vue.prototype.$formatPercentage = function (value) {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' %'
}

Vue.prototype.$endpoint = function () {
    return '/api/staff';
}

Vue.filter('formatDate', function (value) {
    if (value) {
        return moment(String(value)).format('YYYY/MM/DD hh:mm')
    }
})

Vue.prototype.$campaignType = function (value, array) {
    let filtered = array.filter(a => a.id === value)
    return filtered ? filtered[0].type : ''
}

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyCJCG9OnvC4p0o7A8yxmyTIRYUJbNUyGpw",
        libraries: "places,directions" // necessary for places input
    },
    installComponents: true
});

Vue.config.productionTip = false

new Vue({
    iconfont: 'fa',
    i18n,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
