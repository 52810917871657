<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col cols="4">
                <page-title
                        back
                        :title="'VFA - '+VFANumber"
                        :subtitle="VFA.Entidade+' - '+VFA.Nome"
                ></page-title>
            </v-col>
            <v-col>
                <strong>{{ $t('dateOfDocument') }}:</strong><br>
                {{ VFA.DataDoc }}
            </v-col>
            <v-col>
                <strong>Num Doc. Externo:</strong><br>
                {{ VFA.NumDocExterno }}
            </v-col>
            <v-col>
                <strong>Cambio:</strong><br>
                {{ VFA.Cambio }}
            </v-col>
            <v-col>
                <strong>Total Documento:</strong><br>
                <template v-if="VFA.Moeda === 'USD'">
                    {{ $dollar(VFA.TotalDocumento) }}
                </template>
                <template v-else>
                    {{ $money(VFA.TotalDocumento) }}
                </template>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-row>
                        <v-col>
                            <v-text-field
                                    v-model="inputSearch"
                                    label="Pesquisa"
                                    outlined
                                    dense
                                    :ref="'input_search'"
                                    style="width: 300px"
                                    hide-details
                                    @keypress.enter="searchProduct()"
                                    @focus="$event.target.select()"
                            ></v-text-field>
                        </v-col>
                        <v-col class="text-right" align-self="center">
                            <v-btn tile small  color="success" v-if="$writePermission('purchases/VFAs')" @click="updateVFA()">Atualizar VFA</v-btn>
                        </v-col>
                    </v-row>
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>{{ $t('product') }}</th>
                            <th>{{ $t('description') }}</th>
                            <th>CodBarras</th>
                            <th>EAN</th>
                            <th>Ref.Fornecedor</th>
                            <th class="text-center">Moeda</th>
                            <th width="80" class="text-center">{{ $t('quantity') }}</th>
                            <th width="90" class="text-right">{{ $t('price') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="line in VFALines">
                            <td>{{ line.Artigo }}</td>
                            <td>{{ line.Descricao }}</td>
                            <td>{{ line.CodBarras }}</td>
                            <td>{{ line.CDU_EAN }}</td>
                            <td>{{ line.CDU_REFFORNECEDOR }}</td>
                            <td class="text-center">{{ line.Moeda }}</td>
                            <td>
                                <template v-if="$writePermission('purchases/VFAs')">
                                    <v-text-field
                                            v-model="line.Quantidade"
                                            hide-details
                                            dense
                                            outlined
                                            class="staff_text-field_small"
                                            :ref="'input_qty_'+line.Artigo"
                                            @focus="$event.target.select()"
                                            @keypress.enter="focusPrice('input_price_'+line.Artigo)"
                                    ></v-text-field>
                                </template>
                                <template v-else>
                                    {{ line.Quantidade }}
                                </template>
                            </td>
                            <td>
                                <template v-if="$writePermission('purchases/VFAs')">
                                    <v-text-field
                                            v-model="line.PrecUnit"
                                            hide-details
                                            dense
                                            outlined
                                            class="staff_text-field_small"
                                            :ref="'input_price_'+line.Artigo"
                                            @focus="$event.target.select()"
                                            @keypress.enter="focusPrice('input_search')"
                                    ></v-text-field>
                                </template>
                                <template v-else>
                                    <template v-if="VFA.Moeda === 'USD'">
                                        {{ $dollar(line.PrecUnit) }}
                                    </template>
                                    <template v-else>
                                        {{ $money(line.PrecUnit) }}
                                    </template>
                                </template>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-row justify="center">
            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: function () {
        return {
            VFA: {},
            VFANumber: '',
            VFALines: [],
            VFALinesOriginal: [],
            inputSearch: '',
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
        }
    },
    created() {
        this.VFANumber = this.$route.params.id;
        document.title = 'VFA ' + this.VFANumber + ' | STAFF';
        this.getVFALines();
    },
    methods: {
        getVFALines() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/VFAs/' + this.VFANumber
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.VFA = responseData.VFA;
                            this.VFALines = responseData.VFALines;
                            this.VFALinesOriginal = responseData.VFALines;
                        }
                        loader.hide();
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        searchProduct() {
            for (let i = 0; i < this.VFALinesOriginal.length; i++) {
                for (let j = 0; j < this.VFALines.length; j++) {
                    if (this.VFALinesOriginal[i].Artigo === this.VFALines[j].Artigo) {
                        this.VFALinesOriginal[i].Quantidade = this.VFALines[j].Quantidade;
                        this.VFALinesOriginal[i].PrecUnit = this.VFALines[j].PrecUnit;
                    }
                }
            }

            this.VFALines = this.VFALinesOriginal;

            if (this.inputSearch !== '') {
                this.VFALines = this.VFALines.filter(line => line.Artigo.toUpperCase().includes(this.inputSearch.toUpperCase())
                        || (line.CodBarras && line.CodBarras.toUpperCase().includes(this.inputSearch.toUpperCase()))
                        || (line.CDU_REFFORNECEDOR && line.CDU_REFFORNECEDOR.toUpperCase().includes(this.inputSearch.toUpperCase())));
            }

            if (this.VFALines.length === 1 && User.getters.writePermission('purchases/VFAs')) {
                let inputRef = 'input_qty_' + this.VFALines[0].Artigo;
                this.$nextTick(() => this.$refs[inputRef][0].focus());
            }
        },
        focusPrice(ref) {
            if (ref !== 'input_search') {
                this.$nextTick(() => this.$refs[ref][0].focus());
            } else {
                this.$nextTick(() => this.$refs[ref].focus());
            }
        },
        updateVFA() {
            let loader = this.$loading.show();

            for (let i = 0; i < this.VFALinesOriginal.length; i++) {
                for (let j = 0; j < this.VFALines.length; j++) {
                    if (this.VFALinesOriginal[i].Artigo === this.VFALines[j].Artigo) {
                        this.VFALinesOriginal[i].Quantidade = this.VFALines[j].Quantidade;
                        this.VFALinesOriginal[i].PrecUnit = this.VFALines[j].PrecUnit;
                    }
                }
            }

            let request = {
                url: '/api/staff/VFAs/' + this.VFANumber + '/updateLines',
                data: {
                    lines: this.VFALinesOriginal,
                    VFA: this.VFA
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'VFA atualizada com sucesso!',
                                type: 'success'
                            })
                            this.getVFALines();
                        } else {
                            this.responseDialog.open = true;
                            this.responseDialog.error = true;
                            this.responseDialog.title = 'Erro ao Atualizar VFA!';
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.loading = false;
                        }
                        loader.hide();
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        }
    }
}
</script>
