<template>
    <v-container fluid>
        <!--        <v-row>-->
        <!--            <v-col>-->
        <!--                <v-btn small>DIA</v-btn>-->
        <!--                <v-btn small>MÊS</v-btn>-->
        <!--                <v-btn small>ANO</v-btn>-->
        <!--                <v-btn small>DIA-1</v-btn>-->
        <!--                <v-btn small>MÊS-1</v-btn>-->
        <!--                <v-btn small>ANO-1</v-btn>-->
        <!--            </v-col>-->
        <!--        </v-row>-->
        <v-row>
            <v-col>
                <PendingDPD></PendingDPD>
                <PendingCTT></PendingCTT>
                <PendingDELNEXT></PendingDELNEXT>
            </v-col>
            <v-col>
                <PendingREDUR></PendingREDUR>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
<!--                <v-row class="mt-0">-->
<!--                    <v-col>-->
                        <PendingClient></PendingClient>
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <v-row>-->
<!--                    <v-col>-->
<!--                        <PendingPlanet></PendingPlanet>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <v-row>-->
<!--                    <v-col>-->
<!--                        <PendingRimal></PendingRimal>-->
<!--                    </v-col>-->
<!--                </v-row>-->
<!--                <v-row>-->
<!--                    <v-col>-->
<!--                        <PendingOffmax></PendingOffmax>-->
<!--                    </v-col>-->
<!--                </v-row>-->
            </v-col>
            <v-col>
                <NCStatistics></NCStatistics>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PendingDPD from "./Widgets/PendingDPD";
import PendingCTT from "./Widgets/PendingCTT";
import PendingREDUR from "@/components/Dashboard/Widgets/PendingREDUR";
import PendingDELNEXT from "@/components/Dashboard/Widgets/PendingNACEX.vue";
import NCStatistics from "@/components/Dashboard/Widgets/NCStatistics";
import PendingClient from "@/components/Dashboard/Widgets/PendingClient";
// import PendingPlanet from "@/components/Dashboard/Widgets/PendingPlanet";
// import PendingRimal from "@/components/Dashboard/Widgets/PendingRimal";
// import PendingOffmax from "@/components/Dashboard/Widgets/PendingOffmax";

export default {
    components: {
        PendingClient,
        NCStatistics,
        PendingDPD,
        PendingCTT,
        PendingREDUR,
        PendingDELNEXT
    },
    data: function () {
        return {
            mode: 'day',
            date: {
                start: '',
                end: ''
            }
        }
    }
}
</script>
