<style>
.row-green {
  background-color: rgba(0, 255, 25, 0.40);
}
</style>

<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col cols="3">
                <page-title
                        back
                        :title="'Compra - '+purchaseNumber"
                        :subtitle="purchase.Entidade+' - '+purchase.Nome"
                ></page-title>
            </v-col>
            <v-col>
                <strong>{{ $t('dateOfDocument') }}:</strong><br>
                {{ purchase.DataDoc }}
            </v-col>
            <v-col>
                <strong>Num. Documento Ext.:</strong><br>
                <v-btn tile small outlined @click="toggleDialogUpdateDocExt">
                    {{ purchase.NumDocExterno }}
                </v-btn>
            </v-col>
            <v-col>
                <strong>Total Documento:</strong><br>
                <template v-if="purchase.Moeda === 'USD'">
                    {{ $dollar(purchase.TotalDocumento) }}
                </template>
                <template v-else>
                    {{ $money(purchase.TotalDocumento) }}
                </template>
            </v-col>
            <v-col class="text-right" align-self="center" cols="4">
                <!--                <v-btn tile color="primary" class="mr-3" @click="createPick()" v-if="purchase.pickOrderId === null">-->
                <!--                    Criar Pick-->
                <!--                </v-btn>-->
                <v-btn tile color="primary" class="mr-3" v-if="(purchase.pickStatus === 'Novo' || purchase.pickStatus === 'Picking') && purchase.pickOrderId !== null" :to="'/orders/pick/'+purchase.pickOrderId">
                    Ver Picking
                </v-btn>
                <v-btn tile color="primary" class="mr-3" v-if="(purchase.pickStatus !== 'Novo'&& purchase.pickStatus !== 'Picking') && purchase.pickOrderId !== null" :to="'/orders/pick/'+purchase.pickOrderId+'/view'">
                    Ver Picking
                </v-btn>
                <v-btn tile color="warning" class="mr-3" @click="exportExcel">
                    Exportar Excel
                </v-btn>
                <v-btn tile color="success" @click="toggleDialogAddProducts" v-if="$writePermission('purchases/purchases')" :disabled="haveLinesTransformed > 0">
                    {{ $t('add') }} {{
                        $t('lines')
                    }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-row>
                        <v-col cols="4">
                            Total Referências: {{ purchase.TotalReferencias }} Total de Artigos:
                            {{ purchase.TotalArtigos }}
                        </v-col>
                        <v-col class="text-right">
                            <v-btn tile small color="primary" class="mr-3" :disabled="totalProductsSelected === 0" @click="toggleDialogArriveDateSelected">
                                Data Chegada ({{ totalProductsSelected }})
                            </v-btn>
                            <v-btn tile small color="error" class="mr-3" @click="toggleDialogCloseECF">Anular ECF
                            </v-btn>
                            <v-btn tile small color="primary" @click="toggleDialogDatePendents">Atualizar Data
                                Pendentes
                            </v-btn>
                            <v-btn tile small color="warning" class="ml-3" v-if="!haveLinesTransformed" :to="'/purchases/purchases/'+purchaseNumber+'/update'">
                                Atualizar Quantidade e Preço
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-autocomplete
                                    v-model="fastInsert.product"
                                    :items="productsToSearch"
                                    item-text="Artigo"
                                    item-value="Artigo"
                                    :label="$t('product')"
                                    append-icon="search"
                                    outlined
                                    dense
                                    hide-details
                                    clearable
                                    @keypress.enter="validateProductToAdd()"
                                    @focus="$event.target.select()"
                                    ref="fastInsertProduct"
                            >
                                <template slot="item" slot-scope="data">
                                    {{ data.item.Artigo }} - {{ data.item.Descricao }}
                                </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="1">
                            <v-text-field
                                    v-model="fastInsert.quantity"
                                    outlined
                                    dense
                                    hide-details
                                    :label="$t('quantity')"
                                    ref="fastInsertQuantity"
                                    @focus="$event.target.select()"
                                    @keypress.enter="fastInsertProduct()"
                            ></v-text-field>
                        </v-col>
                        <v-col>
                            <v-btn color="success" @click="fastInsertProduct()">Adicionar</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-simple-table class="staff_table">
                                <thead>
                                <tr class="titles_row">
                                    <th></th>
                                    <th>{{ $t('product') }}</th>
                                    <th>{{ $t('description') }}</th>
                                    <th width="1">CodBarras</th>
                                    <th class="text-right">{{ $t('price') }}</th>
                                    <th class="text-center">{{ $t('discount') }}</th>
                                    <th width="1" class="text-center">{{ $t('quantity') }}</th>
                                    <th width="1" style="white-space: nowrap;" class="text-center">{{ $t('quantity') }}
                                        {{ $t('pending') }}
                                    </th>
                                    <th width="1" style="white-space: nowrap;" class="text-center">{{ $t('quantity') }}
                                        Recebida
                                    </th>
                                    <th width="120" class="text-right">{{ $t('arrivalDate') }}</th>
                                    <th width="1">Estado</th>
                                    <th></th>
                                </tr>
                                <tr class="filters_row">
                                    <td class="text-center">
                                        <span style="cursor: pointer">
                                            <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                                            <i v-if="checkboxAll" class="fas fa-check-square fa-lg" style="color:#1976d2"
                                               @click="unselectAll()"></i>
                                        </span>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.product"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filterLines"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.description"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filterLines"
                                        ></v-text-field>
                                    </td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.barcode"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filterLines"
                                        ></v-text-field>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <v-text-field
                                                v-model="filters.arriveDate"
                                                outlined
                                                hide-details
                                                dense
                                                @keypress.enter="filterLines"
                                        ></v-text-field>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr :class="{ 'row-green' : line.Quantidade - line.QuantTrans == 0}" v-for="line in purchaseLines">
                                    <td class="text-center">
                                        <span style="cursor: pointer">
                                            <i v-if="!line.selected" class="far fa-square fa-lg" style="cursor: pointer"
                                               @click="line.selected = true"></i>
                                            <i v-if="line.selected" class="fas fa-check-square fa-lg" style="color:#1976d2; cursor: pointer"
                                               @click="line.selected = false"></i>
                                        </span>
                                    </td>
                                    <td>{{ line.Artigo }}</td>
                                    <td>{{ line.Descricao }}</td>
                                    <td>{{ line.CodBarras }}</td>
                                    <td class="text-right">
                                        <template v-if="purchase.Moeda === 'USD'">
                                            {{ $dollar(line.PrecUnit) }}
                                        </template>
                                        <template v-else>
                                            {{ $money(line.PrecUnit) }}
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        {{ line.Desconto1 }} + {{ line.Desconto2 }} + {{ line.Desconto3 }}
                                    </td>
                                    <td class="text-center">
                                        <v-btn tile small outlined v-if="line.Quantidade - line.QuantTrans > 0" @click="toggleDialogUpdateQuantity(line)">
                                            {{ line.Quantidade }}
                                        </v-btn>
                                        <span v-else>{{ line.Quantidade }}</span>
                                    </td>
                                    <td class="text-center">{{ line.Quantidade - line.QuantTrans }}</td>
                                    <td class="text-center">{{ line.QuantTrans }}</td>
                                    <td class="text-right">
                                        <v-btn tile small outlined v-if="line.Quantidade - line.QuantTrans > 0" @click="toggleDialogArriveDate(line)">
                                            {{ line.DataEntrega }}
                                        </v-btn>
                                        <span v-else>{{ line.DataEntrega }}</span>
                                    </td>
                                    <td>
                                        <span v-if="line.EstadoTrans === 'P'">Pendente</span>
                                        <span v-if="line.EstadoTrans === 'T'">Recebido</span>
                                    </td>
                                    <td width="1">
                                        <v-menu bottom left offset-x>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                        icon
                                                        v-on="on"
                                                >
                                                    <v-icon>more_vert</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list>
                                                <v-list-item
                                                        key="duplicate"
                                                        @click="duplicateProductLine(line)"
                                                >
                                                    <v-list-item-title>Duplicar</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                        key="docs"
                                                        @click="deleteProductLine(line)"
                                                >
                                                    <v-list-item-title>Eliminar</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-dialog v-model="dialogUpdateRefExt" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Número Documento Externo
                    </v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="inputDocExt"
                                hide-details
                                outlined
                                dense
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogUpdateRefExt = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="updateRefDocExt" color="success">Atualizar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDatePendents" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Atualizar {{ $t('arrivalDate') }}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0">Atualizar a {{ $t('arrivalDate') }} de todos {{ $t('products') }}
                                {{ $t('pending') }}.
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="px-0 pb-0">
                                <v-menu
                                        v-model="menu5"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="inputArriveDate"
                                                :label="$t('arrivalDate')"
                                                readonly
                                                dense
                                                outlined
                                                v-on="on"
                                                style="width: 200px; float: left"
                                                hide-details
                                                class="ml-4"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="inputArriveDate" locale="pt-pt"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogDatePendents = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="updateDateArrivePendents" color="success">Atualizar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogArriveDate" persistent max-width="500">
                <v-card>
                    <v-card-title>
                        Atualizar {{ $t('arrivalDate') }}
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col class="py-0">
                                Atualizar {{ $t('arrivalDate') }} do {{ $t('product') }} {{ inputDialog.product }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="px-0 pb-0">
                                <v-menu
                                        v-model="menu4"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="inputDialog.arriveDate"
                                                :label="$t('arrivalDate')"
                                                readonly
                                                dense
                                                outlined
                                                hide-details
                                                v-on="on"
                                                style="width: 200px; float: left"
                                                class="ml-4"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="inputDialog.arriveDate" locale="pt-pt"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogArriveDate = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="updateDateArrive" color="success">Atualizar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogCloseECF" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Fechar ECF
                    </v-card-title>
                    <v-card-text>
                        Têm a certeza que deseja fechar a compra - {{ purchaseNumber }}?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogCloseECF = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="closeECF" color="error">Fechar ECF</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog
                    v-model="dialogAddProducts"
                    fullscreen
                    hide-overlay
                    scrollable
                    transition="dialog-bottom-transition"
            >
                <v-card>
                    <v-toolbar
                            dark
                            color="red"
                            dense
                            style="max-height: 48px;"
                    >
                        <v-toolbar-title>{{ $t('add') }} {{ $t('products') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                                icon
                                dark
                                @click="dialogAddProducts = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="pa-2">
                        <v-row>
                            <v-col cols="4">
                                <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="filtersAddProduct.dataInicial"
                                                label="Data Inicial"
                                                dense
                                                outlined
                                                readonly
                                                v-on="on"
                                                style="width: 200px; float: left"
                                                hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filtersAddProduct.dataInicial" locale="pt-pt" @input="menu2 = false"></v-date-picker>
                                </v-menu>
                                <v-menu
                                        v-model="menu3"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                                v-model="filtersAddProduct.dataFinal"
                                                label="Data Final"
                                                readonly
                                                dense
                                                outlined
                                                v-on="on"
                                                style="width: 200px; float: left"
                                                hide-details
                                                class="ml-4"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filtersAddProduct.dataFinal" locale="pt-pt" @input="menu3 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="text-right" align-self="center">
                                <v-btn tile small @click="getWarehouse('ALL')" class="mr-2">Todos</v-btn>
                                <template v-for="warehouse in filtersWarehouse">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" small tile @click="getWarehouse(warehouse.name)" :color="warehouse.filter === true ? 'success' : ''">
                                                {{ warehouse.name }}
                                            </v-btn>
                                        </template>
                                        <span>{{ warehouse.desc }}</span>
                                    </v-tooltip>
                                </template>
                                <v-btn tile small class="ml-2" color="info" :loading="loading" @click="getAddPurchaseLines()">
                                    Actualizar
                                </v-btn>
                                <v-btn tile small class="ml-2" color="error" @click="clearFilters()">
                                    {{ $t('clearFilters') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-simple-table class="staff_table">
                                    <thead>
                                    <tr class="titles_row">
                                        <th @click="setOrder('Artigo', filtersAddProduct.orderSentido)">
                                            {{ $t('product') }}
                                            <i v-if="filtersAddProduct.orderSentido == false && filtersAddProduct.orderColuna == 'Artigo'" class="fas fa-angle-down"></i>
                                            <i v-if="filtersAddProduct.orderSentido == true && filtersAddProduct.orderColuna == 'Artigo'" class="fas fa-angle-up"></i>
                                        </th>
                                        <th>{{ $t('description') }}</th>
                                        <th>Cód. Barras Pri</th>
                                        <th>EAN</th>
                                        <th>Ref. Forn.</th>
                                        <th>Marca</th>
                                        <th>Familia</th>
                                        <th>SubFamilia</th>
                                        <th class="text-center">B2B</th>
                                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('STKActual', filtersAddProduct.orderSentido)">
                                            Stock
                                            <i v-if="filtersAddProduct.orderSentido == false && filtersAddProduct.orderColuna == 'STKActual'" class="fas fa-angle-down"></i>
                                            <i v-if="filtersAddProduct.orderSentido == true && filtersAddProduct.orderColuna == 'STKActual'" class="fas fa-angle-up"></i>
                                        </th>
                                        <th style="cursor: pointer;" @click="setOrder('ENC_CLIENTE', filtersAddProduct.orderSentido)">
                                            Enc. Cliente
                                            <i v-if="filtersAddProduct.orderSentido == false && filtersAddProduct.orderColuna == 'ENC_CLIENTE'" class="fas fa-angle-down"></i>
                                            <i v-if="filtersAddProduct.orderSentido == true && filtersAddProduct.orderColuna == 'ENC_CLIENTE'" class="fas fa-angle-up"></i>
                                        </th>
                                        <th style="cursor: pointer;" @click="setOrder('ENC_FORN', filtersAddProduct.orderSentido)">
                                            Enc. Forn.
                                            <i v-if="filtersAddProduct.orderSentido == false && filtersAddProduct.orderColuna == 'ENC_FORN'" class="fas fa-angle-down"></i>
                                            <i v-if="filtersAddProduct.orderSentido == true && filtersAddProduct.orderColuna == 'ENC_FORN'" class="fas fa-angle-up"></i>
                                        </th>
                                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('disponivel', filtersAddProduct.orderSentido)">
                                            <!--                                {{ $t('available') }}-->
                                            Disp.
                                            <i v-if="filtersAddProduct.orderSentido == false && filtersAddProduct.orderColuna == 'disponivel'" class="fas fa-angle-down"></i>
                                            <i v-if="filtersAddProduct.orderSentido == true && filtersAddProduct.orderColuna == 'disponivel'" class="fas fa-angle-up"></i>
                                        </th>
                                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('VENDAS', filtersAddProduct.orderSentido)">
                                            {{ $t('sales') }}
                                            <i v-if="filtersAddProduct.orderSentido == false && filtersAddProduct.orderColuna == 'VENDAS'" class="fas fa-angle-down"></i>
                                            <i v-if="filtersAddProduct.orderSentido == true && filtersAddProduct.orderColuna == 'VENDAS'" class="fas fa-angle-up"></i>
                                        </th>
                                        <th style="cursor: pointer; white-space: nowrap;" @click="setOrder('Total_garantias', filtersAddProduct.orderSentido)">
                                            Garantias
                                            <i v-if="filtersAddProduct.orderSentido == false && filtersAddProduct.orderColuna == 'Total_garantias'" class="fas fa-angle-down"></i>
                                            <i v-if="filtersAddProduct.orderSentido == true && filtersAddProduct.orderColuna == 'Total_garantias'" class="fas fa-angle-up"></i>
                                        </th>
                                        <th>{{ $t('quantity') }}</th>
                                    </tr>
                                    <tr class="filters_row">
                                        <td>
                                            <v-text-field
                                                    v-model="filtersAddProduct.Artigo"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @keypress.enter="filter('Artigo')"
                                                    @focus="$event.target.select()"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="filtersAddProduct.Descricao"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @keypress.enter="filter('Descricao')"
                                                    @focus="$event.target.select()"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="filtersAddProduct.CodBarras"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @keypress.enter="filter('CodBarras')"
                                                    @focus="$event.target.select()"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="filtersAddProduct.EAN"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @keypress.enter="filter('EAN')"
                                                    @focus="$event.target.select()"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-text-field
                                                    v-model="filtersAddProduct.RefFornecedor"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @keypress.enter="filter('RefFornecedor')"
                                                    @focus="$event.target.select()"
                                            ></v-text-field>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="filtersAddProduct.Marca"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @change="filter('Marca')"
                                                    :items="brands"
                                                    item-text="TEXT"
                                                    item-value="VALUE"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="filtersAddProduct.Familia"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @change="filter('Familia')"
                                                    :items="families"
                                                    item-value="VALUE"
                                                    item-text="TEXT"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="filtersAddProduct.SubFamilia"
                                                    dense
                                                    outlined
                                                    hide-details
                                                    @change="filter('SubFamilia')"
                                                    :items="subFamilies"
                                                    item-value="VALUE"
                                                    item-text="TEXT"
                                            ></v-select>
                                        </td>
                                        <td>
                                            <v-select
                                                    v-model="filtersAddProduct.B2B"
                                                    :items="filtersYesNo"
                                                    outlined
                                                    dense
                                                    @change="filter('B2B')"
                                                    item-text="TEXT"
                                                    item-value="VALUE"
                                                    hide-details
                                                    style="width: 80px"
                                            ></v-select>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="product in products">
                                        <td>{{ product.Artigo }}</td>
                                        <td>{{ product.Descricao }}</td>
                                        <td>{{ product.CodBarras }}</td>
                                        <td>{{ product.CDU_EAN }}</td>
                                        <td>{{ product.CDU_REFFORNECEDOR }}</td>
                                        <td>{{ product.Marca }}</td>
                                        <td>{{ product.Familia }}</td>
                                        <td>{{ product.SubFamilia }}</td>
                                        <td class="text-center">
                                            <i class="fas fa-check" style="color: green" v-if="product.CDU_B2B_SYNC == '1'"></i>
                                            <i class="fas fa-times" style="color: red" v-if="product.CDU_B2B_SYNC == '0'"></i>
                                        </td>
                                        <td class="text-center">{{ product.STKActual }}</td>
                                        <td class="text-center">
                                            <span v-if="product.ENC_CLIENTE === null">{{
                                                    product.ENC_CLIENTE || 0
                                                }}</span>
                                            <v-tooltip top v-else>
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" @mouseover="tooltipOrders(product.Artigo)">{{
                                                            product.ENC_CLIENTE || 0
                                                        }}</span>
                                                </template>
                                                <span>
                                        <v-row>
                                            <v-col>{{ $t('order') }}</v-col>
                                            <v-col>{{ $t('quantity') }}</v-col>
                                        </v-row>
                                    </span>
                                                <span v-for="order in ordersTooltip">
                                        <v-row>
                                            <v-col class="py-1 text-center">{{ order.NumDoc }}</v-col>
                                            <v-col class="py-1 text-center">{{ order.Quantidade }}</v-col>
                                        </v-row>
                                    </span>
                                            </v-tooltip>
                                        </td>
                                        <td class="text-center">
                                            <span v-if="product.ENC_FORN === null">{{ product.ENC_FORN || 0 }}</span>
                                            <v-tooltip top v-else>
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" @mouseover="tooltipPurchases(product.Artigo)">{{
                                                            product.ENC_FORN || 0
                                                        }}</span>
                                                </template>
                                                <span>
                                        <v-row>
                                            <v-col class="py-1" style="font-size: 12px" align-self="center">Compra</v-col>
                                            <v-col class="py-1" style="font-size: 12px" align-self="center">{{
                                                    $t('quantity')
                                                }}</v-col>
                                            <v-col class="py-1" style="font-size: 12px">{{ $t('arrivalDate') }}</v-col>
                                        </v-row>
                                    </span>
                                                <span v-for="purchase in purchasesTooltip">
                                        <v-row>
                                            <v-col class="py-1 text-center" style="font-size: 12px">{{
                                                    purchase.NumDoc
                                                }}</v-col>
                                            <v-col class="py-1 text-center" style="font-size: 12px">{{
                                                    purchase.Quantidade
                                                }}</v-col>
                                            <v-col class="py-1 text-center" style="font-size: 12px">{{
                                                    purchase.DataEntrega
                                                }}</v-col>
                                        </v-row>
                                    </span>
                                            </v-tooltip>
                                        </td>
                                        <td class="text-center">{{ product.disponivel || 0 }}</td>
                                        <td class="text-center">{{ product.VENDAS || 0 }}</td>
                                        <td class="text-center">{{ product.Total_garantias || 0 }}</td>
                                        <td>
                                            <v-text-field
                                                    v-model="product.QtyOrder"
                                                    outlined
                                                    dense
                                                    hide-details
                                                    style="width: 60px"
                                                    @input="setValue(product)"
                                            ></v-text-field>
                                        </td>
                                    </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pa-0">
                        <v-toolbar dense color="white">
                            <v-spacer></v-spacer>
                            <v-btn tile small  color="success" :disabled="productsToAdd.length === 0" @click="addNewProductsECF">Adicionar ({{ productsToAdd.length }})</v-btn>
                        </v-toolbar>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogDuplicateProduct" persistent max-width="500">
                <v-card>
                    <v-toolbar
                            dark
                            color="red"
                            dense
                    >
                        <v-toolbar-title>{{ $t('product') }} já existe!</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                                icon
                                dark
                                @click="dialogDuplicateProduct = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        O {{ $t('product') }} <strong>{{ fastInsert.product }}</strong> - {{ fastInsert.description }}
                        já existe na encomenda de compra.
                        <br>O que deseja efetuar?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogDuplicateProduct = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="duplicateProduct()">Duplicar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogEditQuantity" persistent max-width="500">
                <v-card>
                    <v-toolbar
                            dark
                            color="red"
                            dense
                    >
                        <v-toolbar-title>Editar {{ $t('quantity') }}!</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                                icon
                                dark
                                @click="dialogEditQuantity = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <p>Editar a {{ $t('quantity') }} do {{ $t('product') }} <strong>{{
                                editProduct.product
                            }}</strong>.</p>
                        <v-text-field
                                v-model="editProduct.quantity"
                                outlined
                                dense
                                hide-details
                                :label="$t('quantity')"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogEditQuantity = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" @click="saveQuantity()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

import i18n from "@/plugins/i18n";

export default {
    data: function () {
        return {
            purchaseNumber: '',
            purchase: {},
            purchaseLines: [],
            purchaseLinesOriginal: [],
            dialogUpdateRefExt: false,
            dialogDatePendents: false,
            dialogArriveDate: false,
            dialogCloseECF: false,
            dialogAddProducts: false,
            inputDocExt: '',
            menu4: false,
            menu5: false,
            inputArriveDate: '',
            inputDialog: {
                product: '',
                arriveDate: '',
                id: '',
                mode: ''
            },
            checkboxAll: false,
            filters: {
                product: '',
                description: '',
                barcode: '',
                arriveDate: ''
            },
            loading: false,
            filtersAddProduct: {
                Artigo: '',
                Descricao: '',
                CodBarras: '',
                EAN: '',
                RefFornecedor: '',
                Marca: '',
                Familia: '',
                SubFamilia: '',
                dataInicial: '',
                dataFinal: '',
                orderSentido: true,
                orderColuna: 'Artigo',
                B2B: '',
                Geral: ''
            },
            products: [],
            brands: [],
            purchasesByProduct: [],
            ordersByProduct: [],
            filWarehouse: {
                Filter: "'A1'"
            },
            filtersWarehouse: [],
            filtersWarehouseAux: [],
            filtersAllWarehouse: true,
            pagination: {
                per_page: 250,
                page: 1
            },
            reload: false,
            menu2: false,
            menu3: false,
            families: [],
            subFamilies: [],
            filtersYesNo: [
                {TEXT: 'Todos', VALUE: ''},
                {TEXT: 'Não', VALUE: '0'},
                {TEXT: 'Sim', VALUE: '1'}
            ],
            ordersTooltip: [],
            purchasesTooltip: [],
            checkboxAllAddProducts: false,
            productsToSearch: [],
            fastInsert: {
                product: '',
                description: '',
                quantity: 1
            },
            dialogDuplicateProduct: false,
            dialogEditQuantity: false,
            editProduct: {
                product: '',
                quantity: '',
                arriveDate: '',
                numLine: ''
            },
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
            productsToAdd: []
        }
    },
    created() {
        this.purchaseNumber = this.$route.params.id;
        document.title = 'Compra ' + this.purchaseNumber + ' | STAFF';
        this.getPurchaseLines();

        let currentYear = new Date().getFullYear();
        if (this.$cookies.isKey('addPurchaseLines_filtersWarehouse')) {
            this.filtersWarehouse = this.$cookies.get('addPurchaseLines_filtersWarehouse')
        }
        if (this.$cookies.isKey('addPurchaseLines_filters_2')) {
            this.filtersWarehouse = this.$cookies.get('addPurchaseLines_filters_2')
        }

        if (this.filtersWarehouse.length > 0) {
            this.filtersWarehouseAux = this.filtersWarehouse
            this.reload = true
        }

        this.filtersAddProduct.dataInicial = currentYear - 1 + '-01-01';
        this.filtersAddProduct.dataFinal = currentYear + '-12-31';
    },
    methods: {
        getPurchaseLines() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/' + this.purchaseNumber
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.purchase = responseData.purchase;
                            this.purchaseLines = responseData.purchaseLines;
                            this.purchaseLinesOriginal = responseData.purchaseLines;

                            this.filterLines();
                            this.getAllProducts();
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        getAllProducts() {
            ApiWS.dispatch('makeGet', {url: '/api/staff/products/all'})
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.productsToSearch = responseData.products;
                        }
                    })
        },
        createPick() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/createPickECF',
                data: {
                    purchase: this.purchase
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Picking criado com sucesso!',
                                type: 'success'
                            })
                            this.$router.push('/orders/pick/' + responseData.id);
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar o picking!',
                                type: 'error'
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        exportExcel() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/' + this.purchaseNumber + '/exportExcel'
            }

            ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';

                        a.download = 'ECF_' + this.purchaseNumber + '.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        toggleDialogUpdateDocExt() {
            this.inputDocExt = this.purchase.NumDocExterno;
            this.dialogUpdateRefExt = true;
        },
        updateRefDocExt() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/' + this.purchaseNumber + '/updateRefDocExt',
                data: {
                    numDocExt: this.inputDocExt
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.getPurchaseLines();
                            this.dialogUpdateRefExt = false;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        toggleDialogDatePendents() {
            this.dialogDatePendents = true;
        },
        updateDateArrivePendents() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/' + this.purchaseNumber + '/updateArriveDatePend',
                data: {
                    arriveDate: this.inputArriveDate
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: i18n.t('arrivalDate') + ' atualizada com sucesso!',
                                type: 'success'
                            })
                            this.dialogDatePendents = false;
                            this.getPurchaseLines();
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        updateDateArrive() {
            let loader = this.$loading.show();

            if (this.inputDialog.mode === 'one') {
                let request = {
                    url: '/api/staff/purchases/' + this.purchaseNumber + '/updateArriveDate',
                    data: {
                        input: this.inputDialog
                    }
                }

                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: i18n.t('arrivalDate') + ' atualizada com sucesso!',
                                    type: 'success'
                                })
                                this.dialogArriveDate = false;
                                this.getPurchaseLines();
                            }
                            loader.hide();
                        })
                        .catch(() => {
                            loader.hide();
                        })
            } else {
                let updateSuccess = 0;
                this.purchaseLines.forEach((line) => {
                    if (line.selected) {
                        let request = {
                            url: '/api/staff/purchases/' + this.purchaseNumber + '/updateArriveDate',
                            data: {
                                input: {
                                    product: line.Artigo,
                                    arriveDate: this.inputDialog.arriveDate,
                                    id: line.Id
                                }
                            }
                        }

                        ApiWS.dispatch('makePost', request)
                                .then(response => {
                                    let responseData = response.data;
                                    if (responseData.status === 'OK') {
                                        updateSuccess++
                                        if (updateSuccess === this.totalProductsSelected) {
                                            Alert.dispatch('show', {
                                                snackbar: true,
                                                text: i18n.t('arrivalDate') + ' atualizada com sucesso!',
                                                type: 'success'
                                            })
                                            this.dialogArriveDate = false;
                                            this.filters = {
                                                product: '',
                                                description: '',
                                                barcode: '',
                                                arriveDate: ''
                                            }
                                            this.getPurchaseLines();
                                        }
                                    }
                                    loader.hide();
                                })
                                .catch(() => {
                                    loader.hide();
                                })
                    }
                })
            }
        },
        toggleDialogArriveDate(line) {
            this.inputDialog.product = line.Artigo;
            this.inputDialog.arriveDate = line.DataEntrega;
            this.inputDialog.id = line.Id;
            this.inputDialog.mode = 'one';
            this.dialogArriveDate = true;
        },
        toggleDialogCloseECF() {
            this.dialogCloseECF = true;
        },
        closeECF() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/' + this.purchaseNumber + '/closeECFById'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'ECF fechada com sucesso!',
                                type: 'success'
                            })
                            this.$router.push('/purchases/purchases');
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        selectAll() {
            this.checkboxAll = true;
            this.purchaseLines.forEach((line) => {
                line.selected = true;
            })
        },
        unselectAll() {
            this.checkboxAll = false;
            this.purchaseLines.forEach((line) => {
                line.selected = false;
            })
        },
        filterLines() {
            this.purchaseLines = this.purchaseLinesOriginal;
            if (this.filters.product !== '') {
                this.purchaseLines = this.purchaseLines.filter(item => item.Artigo.toUpperCase().includes(this.filters.product.toUpperCase()));
            }

            if (this.filters.description !== '') {
                this.purchaseLines = this.purchaseLines.filter(item => item.Descricao.toUpperCase().includes(this.filters.description.toUpperCase()));
            }

            if (this.filters.barcode !== '') {
                this.purchaseLines = this.purchaseLines.filter(item => item.CodBarras.includes(this.filters.barcode));
            }

            if (this.filters.arriveDate !== '') {
                this.purchaseLines = this.purchaseLines.filter(item => item.DataEntrega.includes(this.filters.arriveDate));
            }
        },
        toggleDialogArriveDateSelected() {
            this.inputDialog.product = '';
            this.inputDialog.arriveDate = '';
            this.inputDialog.id = '';
            this.inputDialog.mode = 'multiple';
            this.dialogArriveDate = true;
        },
        getAddPurchaseLines() {
            this.loading = true;
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/getAddLines',
                data: {
                    filters: this.filtersAddProduct,
                    pagination: this.pagination,
                    filtersWarehouse: this.filWarehouse
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.products = responseData.products;
                            this.brands = responseData.brands;
                            this.purchasesByProduct = responseData.purchasesByProduct;
                            this.ordersByProduct = responseData.ordersByProduct;

                            if (this.reload === false) {
                                this.filtersWarehouse = responseData.warehouse;
                                if (this.filtersWarehouse.length > 0) {
                                    this.filtersWarehouse[0].filter = true;
                                }
                                // for (let i = 0; i < this.filtersWarehouse.length; i++) {
                                //     this.filtersWarehouse[i].filter = true;
                                // }

                                this.reload = true;
                            } else {
                                if (this.filtersWarehouseAux.length > 0) {
                                    this.filtersWarehouse = this.filtersWarehouseAux;
                                }
                            }

                            this.getFamilies();
                            this.getSubFamilies();

                            this.products.forEach((product) => {
                                this.productsToAdd.forEach((productToAdd) => {
                                    if (product.Artigo === productToAdd.product) {
                                        product.QtyOrder = productToAdd.qty;
                                    }
                                })
                            })
                        }
                        this.loading = false;
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loading = false;
                        loader.hide();
                    })
        },
        getFamilies() {
            let request = {
                url: api.productsFamilies(),
                data: {
                    brand: this.filtersAddProduct.Marca
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.families = responseData.families
                        }
                    })
                    .catch(response => {

                    })
        },
        getSubFamilies() {
            Product.dispatch('getSubFamilies', this.filtersAddProduct.Familia)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.subFamilies = responseData.subFamilies
                        }
                    })
                    .catch(response => {

                    })
        },
        getWarehouse(warehouse) {
            let i;
            if (warehouse === 'ALL') {
                for (i = 0; i < this.filtersWarehouse.length; i++) {
                    this.filtersWarehouse[i].filter = true
                }
                this.filtersAllWarehouse = true
                this.filWarehouse.Filter = ''
            } else if (warehouse !== 'ALL') {
                if (this.filtersAllWarehouse === true) {
                    for (i = 0; i < this.filtersWarehouse.length; i++) {
                        this.filtersWarehouse[i].filter = false
                    }
                    this.filtersAllWarehouse = false
                }
                this.query = '';
                for (i = 0; i < this.filtersWarehouse.length; i++) {
                    if (warehouse == this.filtersWarehouse[i].name && this.filtersWarehouse[i].filter == false && this.filtersAllWarehouse == false) {
                        this.filtersWarehouse[i].filter = true
                    } else if (warehouse == this.filtersWarehouse[i].name && this.filtersWarehouse[i].filter == true && this.filtersAllWarehouse == false) {
                        this.filtersWarehouse[i].filter = false
                    }
                }
                let soma = 0
                for (i = 0; i < this.filtersWarehouse.length; i++) {
                    if (this.filtersWarehouse[i].filter == true) {
                        this.query += "'" + this.filtersWarehouse[i].name + "',"
                    } else {
                        soma++
                    }
                }
                if (soma == this.filtersWarehouse.length) {
                    this.reload = false
                    this.filtersAllWarehouse = true
                }
                this.filWarehouse.Filter = this.query.substring(0, this.query.length - 1)
            }

            this.filtersWarehouseAux = this.filtersWarehouse
            this.getAddPurchaseLines()
        },
        toggleDialogAddProducts() {
            this.getAddPurchaseLines();
            this.dialogAddProducts = true;
        },
        setOrder(column, order) {
            this.filtersAddProduct.orderColuna = column;
            this.filtersAddProduct.orderSentido = !order;
            this.getAddPurchaseLines()
        },
        nextPage() {
            this.pagination.page++
            this.getAddPurchaseLines()
        },
        previousPage() {
            this.pagination.page--
            this.getAddPurchaseLines()
        },
        filter(column) {
            if (column === 'Marca') {
                this.filtersAddProduct.Familia = '';
                this.filtersAddProduct.SubFamilia = '';
            }
            if (column === 'Familia') {
                this.filtersAddProduct.SubFamilia = '';
            }
            this.$cookies.set("addPurchaseLines_filters_2", this.filtersAddProduct);
            this.pagination.page = 1
            this.getAddPurchaseLines()
        },
        tooltipOrders(product) {
            this.ordersTooltip = [];
            this.ordersByProduct.forEach((order) => {
                if (order.Artigo === product) {
                    this.ordersTooltip.push(order);
                }
            })
        },
        tooltipPurchases(product) {
            this.purchasesTooltip = [];
            this.purchasesByProduct.forEach((purchase) => {
                if (purchase.Artigo === product) {
                    this.purchasesTooltip.push(purchase);
                }
            })
        },
        clearFilters() {
            let currentYear = new Date().getFullYear()
            this.filtersAddProduct = {
                Artigo: '',
                Descricao: '',
                CodBarras: '',
                EAN: '',
                RefFornecedor: '',
                Marca: '',
                Familia: '',
                SubFamilia: '',
                dataInicial: '',
                dataFinal: new Date().toISOString().substr(0, 10),
                orderSentido: true,
                orderColuna: 'Artigo',
                B2B: '',
                Geral: ''
            }
            this.filtersAddProduct.dataInicial = currentYear - 1 + '-01-01'
            this.pagination.page = 1
            this.getAddPurchaseLines();
        },
        validateProductToAdd() {
            let productToAdd = this.productsToSearch.filter(item => item.Artigo === this.fastInsert.product);
            if (productToAdd.length === 1) {
                this.$nextTick(() => this.$refs.fastInsertProduct.blur());
                this.$nextTick(() => this.$refs.fastInsertQuantity.focus());
            }
        },
        fastInsertProduct() {
            let productToAdd = this.productsToSearch.filter(item => item.Artigo === this.fastInsert.product);
            if (productToAdd.length === 1) {
                let productInPurchase = this.purchaseLines.filter(item => item.Artigo === this.fastInsert.product);
                if (productInPurchase.length === 0) {
                    let productToSend = [
                        {
                            product: this.fastInsert.product,
                            qty: this.fastInsert.quantity,
                            arriveDate: new Date().toISOString().substr(0, 10)
                        }
                    ]

                    this.addProductsECF(productToSend);
                } else {
                    this.fastInsert.description = productInPurchase[0].Descricao;
                    this.dialogDuplicateProduct = true;
                }
            } else {
                //TODO:: Show message warning product not exist

            }
        },
        addProductsECF(products) {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/purchases/addLinesECF',
                data: {
                    doc: {
                        TipoDoc: this.purchase.TipoDoc,
                        NumDoc: this.purchase.NumDoc,
                        Serie: this.purchase.Serie
                    },
                    lines: products
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Linhas adicionadas com sucesso!',
                                type: 'success'
                            })
                            this.getPurchaseLines();
                            this.fastInsert.product = '';
                        } else {
                            this.responseDialog.open = true;
                            this.responseDialog.error = true;
                            this.responseDialog.title = 'Erro ao Atualizar Linhas!';
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.loading = false;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        duplicateProduct() {
            this.dialogDuplicateProduct = false;
            let productToSend = [
                {
                    product: this.fastInsert.product,
                    qty: this.fastInsert.quantity,
                    arriveDate: new Date().toISOString().substr(0, 10)
                }
            ]

            this.addProductsECF(productToSend);
        },
        saveQuantity() {
            this.dialogEditQuantity = false;

            let productToSend = [
                {
                    product: this.editProduct.product,
                    qty: this.editProduct.quantity,
                    arriveDate: this.editProduct.arriveDate,
                    numLine: this.editProduct.numLine
                }
            ]

            this.addProductsECF(productToSend);
        },
        toggleDialogUpdateQuantity(product) {
            this.editProduct.product = product.Artigo;
            this.editProduct.quantity = product.Quantidade;
            this.editProduct.arriveDate = product.DataEntrega;
            this.editProduct.numLine = product.NumLinha;

            this.dialogEditQuantity = true;
        },
        duplicateProductLine(product) {
            let productToSend = [
                {
                    product: product.Artigo,
                    qty: product.Quantidade,
                    arriveDate: product.DataEntrega
                }
            ]

            this.addProductsECF(productToSend);
        },
        deleteProductLine(product) {
            let loader = this.$loading.show();

            let products = [
                {
                    product: product.Artigo,
                    numLine: product.NumLinha
                }
            ]

            let request = {
                url: '/api/staff/purchases/deleteLinesECF',
                data: {
                    doc: {
                        TipoDoc: this.purchase.TipoDoc,
                        NumDoc: this.purchase.NumDoc,
                        Serie: this.purchase.Serie
                    },
                    lines: products
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Linhas removidas com sucesso!',
                                type: 'success'
                            })
                            this.getPurchaseLines();
                        } else {
                            this.responseDialog.open = true;
                            this.responseDialog.error = true;
                            this.responseDialog.title = 'Erro ao Atualizar Linhas!';
                            this.responseDialog.message = responseData.data;
                            this.responseDialog.loading = false;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        setValue(product) {
            this.productsToAdd.forEach((productToAdd, index) => {
                if (product.Artigo === productToAdd.product) {
                    this.productsToAdd.splice(index, 1);
                }
            })

            if (product.QtyOrder > 0 && product.QtyOrder !== '') {
                this.productsToAdd.push({
                    product: product.Artigo,
                    qty: product.QtyOrder,
                    arriveDate: new Date().toISOString().substr(0, 10)
                })
            }
        },
        addNewProductsECF() {
            this.addProductsECF(this.productsToAdd);
            this.dialogAddProducts = false;
            this.productsToAdd = [];
        }
    },
    computed: {
        haveLinesTransformed() {
            let count = 0;
            this.purchaseLines.forEach((line) => {
                if (line.EstadoTrans === 'T') {
                    count++;
                }
            })

            return count;
        },
        totalProductsSelected() {
            let count = 0;
            this.purchaseLines.forEach((line) => {
                if (line.selected) {
                    count++;
                }
            })

            return count;
        }
    }
}
</script>
