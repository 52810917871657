<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title title="BikePlanet - Encomendas"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small  class="mr-2" :color="buttonColor('dispatch')" @click="changeOption('dispatch')">
                    <v-badge v-if="totalOrdersByStatus.to_dispatch" color="red" :content="totalOrdersByStatus.to_dispatch">
                        Expedição
                    </v-badge>
                    <template v-else>Expedição</template>
                </v-btn>
                <v-btn tile small  class="mr-2" :color="buttonColor('history')" @click="changeOption('history')">
                    Histórico
                </v-btn>
                <v-btn tile small  @click="clearFilters()" color="error">Limpar Filtros</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th>Referência</th>
                        <th>Origem</th>
                        <th>Artigos</th>
                        <th>Cliente</th>
                        <th>
                            <span v-if="option === 'invoice'">Morada Faturação</span>
                            <span v-if="option !== 'invoice'">Morada Entrega</span>
                        </th>
                        <th>Documentos</th>
                        <th v-if="option === 'history'">Tracking</th>
                        <th v-if="option === 'history'">Estado</th>
                        <th>Opções</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="filters_row">
                        <td>
                            <v-text-field
                                    v-model="filters.reference"
                                    outlined
                                    single-line
                                    @keyup.enter="initialize"
                                    hide-details
                                    dense
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.market_reference"
                                    outlined
                                    single-line
                                    @keyup.enter="initialize"
                                    hide-details
                                    dense
                            ></v-text-field>
                        </td>
                        <td>
                            <!--                            <v-text-field-->
                            <!--                                    v-model="filters.product"-->
                            <!--                                    outlined-->
                            <!--                                    single-line-->
                            <!--                                    @keyup.enter="initialize"-->
                            <!--                            ></v-text-field>-->
                        </td>
                        <td>
                            <!--                            <v-text-field-->
                            <!--                                    v-model="filters.client"-->
                            <!--                                    outlined-->
                            <!--                                    single-line-->
                            <!--                                    @keyup.enter="initialize"-->
                            <!--                            ></v-text-field>-->
                        </td>
                        <td></td>
                        <td>
                            <v-text-field
                                    v-model="filters.invoice"
                                    outlined
                                    single-line
                                    @keyup.enter="initialize"
                                    hide-details
                                    dense
                            ></v-text-field>
                        </td>
                        <td v-if="option === 'history'">
                            <v-text-field
                                    v-model="filters.tracking"
                                    outlined
                                    single-line
                                    @keyup.enter="initialize"
                                    hide-details
                                    dense
                            ></v-text-field>
                        </td>
                        <td v-if="option === 'history'">
                            <v-select
                                    v-model="filters.status"
                                    :items="selectFilters"
                                    item-text="text"
                                    item-value="value"
                                    outlined
                                    @change="initialize"
                                    hide-details
                                    dense
                            ></v-select>
                        </td>
                        <td></td>
                    </tr>
                    <tr v-if="loading">
                        <td colspan="9">
                            <v-progress-linear
                                    indeterminate
                            ></v-progress-linear>
                        </td>
                    </tr>
                    <tr v-for="order in orders" v-if="orders.length">
                        <td class="text-md-center">
                            <strong>{{ order.generic.reference }}</strong> <br> {{ order.generic.created_at }}
                        </td>
                        <td class="text-md-center">
                            <strong>{{ order.generic.origin }}</strong> <br>
                            <strong>{{ order.generic.marketplace_country }}</strong>
                            <br> {{ order.generic.marketplace_order_id }}
                        </td>
                        <td>
                            <v-simple-table style="background-color: transparent !important;">
                    <thead>
                    <tr>
                        <th>Código</th>
                        <th v-if="option === 'dispatch'">Cod. Barras</th>
                        <th>Qty.</th>
                        <!--                <th>Descrição</th>-->
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="article in order.articles">
                        <td>
                            <strong>{{ article.codigo }}</strong> <br>
                            {{ article.descricao }}
                        </td>
                        <td v-if="option === 'dispatch'"
                            v-bind:class="{'green lighten-3': article.codBarras && article.codBarras == article.codBarrasValidate, 'red lighten-3': article.codBarras && article.codBarras != article.codBarrasValidate}"
                            style="width: 160px; padding-top: 10px">
                            <template v-if="article.codBarras">
                                {{ article.codBarras }} <br>
                                <v-text-field
                                        single-line
                                        solo
                                        v-model="article.codBarrasValidate"
                                        style="width: 140px"
                                        dense
                                        hide-details
                                >
                                </v-text-field>
                            </template>
                        </td>
                        <td class="text-md-center">
                            {{ article.quantidade }}
                            <i class="fas fa-check" style="color: green"
                               v-if="article.quantidade <= article.stockT1 && (option === 'invoice' || option === 'dispatch')"></i>
                            <i class="fas fa-times" style="color: red"
                               v-if="article.quantidade > article.stockT1 && (option === 'invoice' || option === 'dispatch')"></i>
                        </td>
                        <!--                <td>{{ article.descricao }}</td>-->
                    </tr>
                    </tbody>
                </v-simple-table>
                </td>
                <td style="width: 180px">
                    <span>{{ order.invoiceAddress.nome }}</span><br>
                    <span>{{ order.invoiceAddress.contribuinte || 999999990 }}</span> <br>
                    <span>Fac. <i class="fas fa-phone"></i> {{ order.invoiceAddress.telefone }}</span><br>
                    <span v-if="order.invoiceAddress.telefone !== order.deliveryAddress.telefone">Ent. <i
                            class="fas fa-phone"></i> {{ order.deliveryAddress.telefone }}</span>
                </td>
                <td>
                    <template v-if="option === 'invoice'">
                        {{ order.invoiceAddress.morada }}<br>
                        <span v-if="order.invoiceAddress.morada2">{{ order.invoiceAddress.morada2 }}<br></span>
                        {{ order.invoiceAddress.codpostal }} - {{ order.invoiceAddress.cplocal }}<br>
                        <strong>{{ order.invoiceAddress.pais }}</strong>
                    </template>
                    <template v-if="option !== 'invoice'">
                        {{ order.deliveryAddress.morada }}<br>
                        <span v-if="order.deliveryAddress.morada2">{{ order.deliveryAddress.morada2 }}<br></span>
                        {{ order.deliveryAddress.codpostal }} - {{ order.deliveryAddress.cplocal }}<br>
                        <strong>{{ order.deliveryAddress.pais }}</strong>
                    </template>
                </td>
                <td>
                    <v-simple-table style="background-color: transparent !important;">
                        <tbody>
                        <tr>
                            <td class="text-md-center">{{ order.generic.invoice_number_bicimax || '-' }}</td>
                            <template v-if="getProfileId === 1 || getProfileId === 16 || getProfileId === 12">
                                <td class="text-md-center">
                                    <v-btn
                                            v-if="order.generic.invoice_number_bicimax == null"
                                            color="success"
                                            small
                                            style="min-width: 0px"
                                            :disabled="order.generic.invoice_number_bicimax != null"
                                            @click="createInvoiceAll(order.generic.reference, 'invoiceBicimax')"
                                    >
                                        <i class="fas fa-check fa-fw"></i>
                                    </v-btn>
                                    <v-btn
                                            v-if="order.generic.invoice_number_bicimax != null"
                                            color="error"
                                            small
                                            style="min-width: 0px"
                                            :disabled="order.generic.invoice_number_planet != null"
                                            @click="cancelInvoiceAll(order.generic.reference, 'invoiceBicimax')"
                                    >
                                        <i class="fas fa-times fa-fw"></i>
                                    </v-btn>
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <td class="text-md-center">{{ order.generic.purchase_number_planet || '-' }}</td>
                            <template v-if="getProfileId === 1 || getProfileId === 16 || getProfileId === 12">
                                <td class="text-md-center">
                                    <v-btn
                                            v-if="order.generic.purchase_number_planet == null"
                                            color="success"
                                            small
                                            style="min-width: 0px"
                                            :disabled="order.generic.invoice_number_bicimax == null"
                                            @click="createInvoiceAll(order.generic.reference, 'purchasePlanet')"
                                    >
                                        <i class="fas fa-check fa-fw"></i>
                                    </v-btn>
                                    <v-btn
                                            v-if="order.generic.purchase_number_planet != null"
                                            color="error"
                                            small
                                            style="min-width: 0px"
                                            :disabled="order.generic.invoice_number_bicimax != null"
                                            @click="cancelInvoiceAll(order.generic.reference, 'purchasePlanet')"
                                    >
                                        <i class="fas fa-times fa-fw"></i>
                                    </v-btn>
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <td class="text-md-center">{{ order.generic.invoice_number_planet || '-' }}</td>
                            <template v-if="getProfileId === 1 || getProfileId === 16 || getProfileId === 12">
                                <td class="text-md-center">
                                    <v-btn
                                            v-if="order.generic.invoice_number_planet == null"
                                            color="success"
                                            small
                                            style="min-width: 0px"
                                            :disabled="order.generic.purchase_number_planet == null || order.generic.invoice_number_bicimax == null"
                                            @click="createInvoiceAll(order.generic.reference, 'invoicePlanet')"
                                    >
                                        <i class="fas fa-check fa-fw"></i>
                                    </v-btn>
                                    <v-btn
                                            v-if="order.generic.invoice_number_planet != null"
                                            color="error"
                                            small
                                            style="min-width: 0px"
                                            @click="cancelInvoiceAll(order.generic.reference, 'invoicePlanet')"
                                    >
                                        <i class="fas fa-times fa-fw"></i>
                                    </v-btn>
                                </td>
                            </template>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </td>
                <td v-if="option === 'history'">
                    {{ order.generic.tracking }}<br><br>
                    {{ order.generic.tracking_return }}
                </td>
                <td v-if="option === 'history'">
                    <span v-if="order.generic.current_state === 6">Cancelado</span>
                    <span v-if="order.generic.current_state === 16 || order.generic.current_state === 4 || order.generic.current_state === 22">Enviado</span>
                    <span v-if="order.generic.current_state === 26 || order.generic.current_state === 41 || order.generic.current_state === 42">Aguarda Expediçao</span>
                    <span v-if="order.generic.current_state === 15 || order.generic.current_state === 3 || order.generic.current_state === 21">Aguarda Faturação</span>
                    <span v-if="order.generic.current_state === 24 || order.generic.current_state === 18">Aguardar devolução</span>
                    <span v-if="order.generic.current_state === 19 || order.generic.current_state === 25">Devolvido</span>
                </td>
                <td>
                    <template v-if="option === 'invoice'">
                        <v-btn
                                :disabled="order.generic.invoice_number_bicimax != null || order.generic.purchase_number_planet != null || order.generic.invoice_number_planet != null"
                                color="success"
                                @click="createInvoiceAll(order.generic.reference, 'full')"
                        >
                            <strong>FATURAR</strong>
                        </v-btn>
                    </template>
                    <template v-if="option === 'dispatch'">
                        <v-btn
                                color="success"
                                large
                                @click="setupShipping(order, false)"
                        >
                            <strong>CONFIGURAR<br>ENVIO</strong>
                        </v-btn>
                        <br><br>
                    </template>
                    <!--                    <template v-if="option === 'dispatch' || option === 'returning'">-->
                    <!--                        <v-btn-->
                    <!--                                color="error"-->
                    <!--                                large-->
                    <!--                                @click="cancelInvoiceAll(order.generic.reference, 'full')"-->
                    <!--                        >-->
                    <!--                            ANULAR<br>DOCUMENTOS-->
                    <!--                        </v-btn>-->
                    <!--                    </template>-->
                    <template v-if="option === 'history'">
                        <v-menu bottom left offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        icon
                                        v-on="on"
                                >
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <v-list-item
                                        key="re-envio"
                                        @click="setupShipping(order, true)"
                                >
                                    <v-list-item-title>Re-envio</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                        key="docs"
                                        @click="getHistoricDocs(order)"
                                >
                                    <v-list-item-title>Documentos</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                        key="label"
                                        @click="downloadLabel(order)"
                                >
                                    <v-list-item-title>Etiqueta</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                        key="returnLabel"
                                        @click="downloadReturnLabel(order)"
                                >
                                    <v-list-item-title>Etiqueta Devolução</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                        key="registerReturned"
                                        @click="registerReturned(order)"
                                >
                                    <v-list-item-title>Registar Devolução</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </td>
                </tr>
                </tbody>
                <tr v-if="!orders.length">
                    <td colspan="7">
                        Não existe encomendas
                    </td>
                </tr>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row justify-space-around>
            <v-pagination
                    v-model="filters.page"
                    :length="getTotalPages"
                    :total-visible="7"
                    @input="initialize"
            ></v-pagination>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialogCreateInvoice" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">FATURAR</v-card-title>
                    <v-card-text>
                        <span v-if="invoiceStatus === 0">Pretende criar uma fatura para a encomenda {{
                                orderReference
                            }}?</span>
                        <div v-if="invoiceStatus !== 0">
                            A faturar ...
                            <v-progress-linear v-model="invoiceProgressBar"></v-progress-linear>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="invoiceStatus === 0" @click="cancelCreateInvoice()">Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmCreateInvoice()" color="success">Confirmo</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogCancelInvoice" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">ANULAR FATURAS</v-card-title>
                    <v-card-text>
                        <span v-if="invoiceStatus === 0">Confirma que pretende anular as faturas desta encomenda {{
                                orderReference
                            }}?</span>
                        <div v-if="invoiceStatus !== 0">
                            A anular ...
                            <v-progress-linear v-model="invoiceProgressBar"></v-progress-linear>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="invoiceStatus === 0" @click="cancelCancelInvoiceAll()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmCancelInvoiceAll()" color="error">Confirmo</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogShipping" max-width="1000">
                <v-card>
                    <v-card-title class="headline">Expedição da Encomenda {{ newShipping.orderReference }}
                    </v-card-title>
                    <v-card-text>
                        <v-layout row wrap>
                            <v-flex sm6>
                                <strong>Morada de Entrega:</strong><br>
                                <p>
                                    <span>{{ newShipping.deliveryAddress.nome }}</span><br>
                                    <span>{{ newShipping.deliveryAddress.morada }}</span><br>
                                    <span v-if="newShipping.deliveryAddress.morada2 !== null ">{{
                                            newShipping.deliveryAddress.morada2
                                        }}<br></span>
                                    <span>{{ newShipping.deliveryAddress.codpostal }}</span><br>
                                    <span>Telf. {{ newShipping.deliveryAddress.telefone }}</span><br>
                                    <span>{{ newShipping.deliveryAddress.pais }}</span>
                                </p>
                            </v-flex>
                            <v-flex sm3>
                                <strong>Dimensões das caixas:</strong>
                                <p>
                                    <span>Altura: 0.1</span><br>
                                    <span>Largura: 0.1</span><br>
                                    <span>Comprimento: 0.1</span><br>
                                    <span>Peso: 0.1</span>
                                </p>
                            </v-flex>
                            <v-flex sm3>
                                <strong>Data de Recolha:</strong><br>
                                <span v-if="shippingType.length > 0">{{ shippingType[0].PickupDate }}</span>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex sm12>
                                <strong>Quantidade Caixas: </strong>
                                <v-btn-toggle>
                                    <v-btn @click="updateQuantity(1, 'ship')" :color="quantityButtonColor(1)"
                                           :disabled="disableButtonShipping">
                                        1
                                    </v-btn>
                                    <v-btn @click="updateQuantity(2, 'ship')" :color="quantityButtonColor(2)"
                                           :disabled="disableButtonShipping">
                                        2
                                    </v-btn>
                                    <v-btn @click="updateQuantity(3, 'ship')" :color="quantityButtonColor(3)"
                                           :disabled="disableButtonShipping">
                                        3
                                    </v-btn>
                                    <v-btn @click="updateQuantity(4, 'ship')" :color="quantityButtonColor(4)"
                                           :disabled="disableButtonShipping">
                                        4
                                    </v-btn>
                                    <v-btn @click="updateQuantity(5, 'ship')" :color="quantityButtonColor(5)"
                                           :disabled="disableButtonShipping">
                                        5
                                    </v-btn>
                                </v-btn-toggle>
                            </v-flex>
                        </v-layout>
                        <br>
                        <v-layout row wrap v-if="newShipping.quantity !== ''">
                            <strong>Tipo: </strong>
                        </v-layout>
                        <v-layout row wrap v-if="newShipping.quantity !== ''" style="min-height: 260px"
                                  :align-center="loadingDHL" :justify-center="loadingDHL">
                            <template v-if="loadingDHL">
                                <v-progress-circular
                                        width="3"
                                        size="150"
                                        indeterminate
                                        color="primary"
                                ></v-progress-circular>
                            </template>
                            <template v-for="type in shippingType" v-if="!loadingDHL">
                                <v-card
                                        width="150"
                                        class="ma-2"
                                        hover
                                        @click="selectCourierType(type.Type, type.Courier)"
                                        :disabled="disableButtonShipping"
                                >
                                    <v-img
                                            class="my-2"
                                            style="margin-left: auto; margin-right: auto"
                                            :src="type.Image"
                                            :alt="type.Type"
                                            max-width="100"
                                            max-height="100"
                                            contain
                                    ></v-img>
                                    <v-card-text
                                            class="text-md-center"
                                            :class="{'white--text success': colorCardCourierType(type.Type, type.Courier) === 'success'}"
                                    >
                                        {{ type.Name }}<br>
                                        {{ type.DataEntrega }}<br>
                                        {{ type.Amount }} €
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-layout>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                                color="success"
                                :disabled="!newShipping.type || disableButtonShipping"
                                @click="saveShipping()"
                                style="margin-left: auto"
                        ><i class="fas fa-check"></i>Registar Envio
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogHistoricDocs" max-width="1000">
                <v-card>
                    <v-card-title class="headline">Historico Documentos: <span
                            v-if="historicDocs.length > 0">{{ historicDocs[0].order_id }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th>Empresa</th>
                                <th>Data Gravação</th>
                                <th>Tipo Doc.</th>
                                <th>Série</th>
                                <th>Num. Doc</th>
                                <th>Observações</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="historic in historicDocs">
                                <td>{{ historic.db }}</td>
                                <td>{{ historic.date_pri }}</td>
                                <td>{{ historic.tipo_doc }}</td>
                                <td>{{ historic.serie }}</td>
                                <td>
                                    {{ historic.num_doc }}
                                    <template v-if="historic.tipo_doc !== 'VFA'">
                                        <template v-if="historic.db === 'bicimax'">
                                            <i class="far fa-file-pdf" style="color: red; cursor: pointer" @click="downloadPDF(historic.serie, historic.tipo_doc, historic.num_doc)"></i>
                                        </template>
                                        <template v-else>
                                            <i class="far fa-file-pdf" style="color: red; cursor: pointer" @click="downloadPDFPlanet(historic.serie, historic.tipo_doc, historic.num_doc)"></i>
                                        </template>
                                    </template>
                                </td>
                                <td>{{ historic.observacoes_pri }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn style="margin-left: auto" @click="dialogHistoricDocs = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogRegisterReturned" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">REGISTAR DEVOLUÇÃO</v-card-title>
                    <v-card-text>
                        <span>Confirma que pretende registar devolução e anular as faturas desta encomenda {{
                                orderReference
                            }}?</span>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="invoiceStatus === 0" @click="cancelCancelInvoiceAll()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn @click="confirmCancelInvoiceAll()" color="error">Confirmo</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        loadingDHL: false,
        loading: false,
        loadingHistoric: false,
        shippingType: [],
        disableButtonShipping: false,
        dialogShipping: false,
        dialogHistoricDocs: false,
        dialogRegisterReturned: false,
        newShipping: {
            deliveryAddress: {
                nome: '',
                morada: '',
                morada2: '',
                codpostal: '',
                telefone: '',
                pais: ''
            },
            quantity: 0,
            courier: '',
            type: ''
        },
        dialogCancelInvoice: false,
        totalOrdersByStatus: [],
        invoiceMode: '',
        invoiceProgressBar: 0,
        invoiceStatus: 0,
        orderReference: '',
        dialogCreateInvoice: false,
        totalOrders: 0,
        option: 'dispatch',
        orders: [],
        historicDocs: [],
        headersHistoricDoc: [],
        filters: {
            reference: '',
            market_reference: '',
            product: '',
            client: '',
            invoice: '',
            tracking: '',
            status: '',
            page: 1,
            per_page: 50
        },
        selectFilters: [
            {text: 'Todos', value: ''},
            {text: 'Enviado', value: 'shipped'},
            {text: 'Aguardar devolução', value: 'returning'},
            {text: 'Aguarda Faturação', value: 'invoice'},
            {text: 'Aguarda Expedição', value: 'dispatch'},
            {text: 'Cancelado', value: 'canceled'},
            {text: 'Devolvido', value: 'refunded'}
        ]
    }),
    methods: {
        initialize() {
            this.loading = true
            let data = {
                option: this.option,
                filters: this.filters
            }

            this.orders = []
            DropShipping.dispatch('getOrders', data)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.orders = responseData.orders
                            this.totalOrders = responseData.count
                            this.totalOrdersByStatus = responseData.totalOrders
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        changeOption(option) {
            this.option = option
            this.filters = {
                reference: '',
                market_reference: '',
                invoice: '',
                tracking: '',
                status: '',
                page: 1,
                per_page: 50
            }
            this.initialize()
        },
        clearFilters() {
            this.filters = {
                reference: '',
                market_reference: '',
                invoice: '',
                tracking: '',
                status: '',
                page: 1,
                per_page: 50
            }
            this.initialize()
        },
        buttonColor(option) {
            return option === this.option ? 'primary' : ''
        },
        createInvoiceAll(orderReference, option) {
            this.invoiceMode = option
            this.orderReference = orderReference
            this.invoiceStatus = 0
            this.invoiceProgressBar = 0
            this.dialogCreateInvoice = true
        },
        cancelCreateInvoice() {
            this.orderReference = false
            this.dialogCreateInvoice = false
        },
        confirmCreateInvoice() {
            if (this.invoiceMode !== 'full') {
                this.createInvoice(this.orderReference, this.invoiceMode)
            }
        },
        createInvoice(orderReference, option) {
            let order = {
                orderReference: orderReference,
                option: option
            }

            DropShipping.dispatch('createInvoice', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.initialize()
                            this.dialogCreateInvoice = false
                            this.invoiceMode = ''

                            let alert = {
                                snackbar: true,
                                text: 'Fatura criada com sucesso!',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)
                        } else {
                            this.initialize()
                            this.dialogCreateInvoice = false
                            this.invoiceMode = ''
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao criar fatura!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao criar fatura!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        cancelCancelInvoiceAll() {
            this.orderReference = ''
            this.invoiceMode = ''
            this.invoiceStatus = 0
            this.invoiceProgressBar = 0
            this.dialogCancelInvoice = false
            this.dialogRegisterReturned = false
        },
        confirmCancelInvoiceAll() {
            this.cancelInvoice(this.orderReference, this.invoiceMode)
        },
        cancelInvoice(orderReference, option) {
            let order = {
                orderReference: orderReference,
                option: option
            }

            DropShipping.dispatch('cancelInvoice', order)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.initialize()
                            this.dialogCancelInvoice = false
                            this.dialogRegisterReturned = false
                            this.invoiceMode = ''

                            // let alert = {
                            //     snackbar: true,
                            //     text: 'Fatura anulada com sucesso!',
                            //     type: 'success'
                            // }
                            //
                            // Alert.dispatch('show', alert)
                        } else {
                            this.dialogCancelInvoice = false
                            this.dialogRegisterReturned = false
                            // let alert = {
                            //     snackbar: true,
                            //     text: 'Erro ao anular fatura!',
                            //     type: 'error'
                            // }
                            //
                            // Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        this.dialogCancelInvoice = false
                        this.dialogRegisterReturned = false
                        // let alert = {
                        //     snackbar: true,
                        //     text: 'Erro ao anular fatura!',
                        //     type: 'error'
                        // }
                        //
                        // Alert.dispatch('show', alert)
                    })
        },
        cancelInvoiceAll(orderReference, option) {
            this.orderReference = orderReference
            this.invoiceMode = option
            this.invoiceProgressBar = 0
            this.invoiceStatus = 0
            this.dialogCancelInvoice = true
        },
        setupShipping(order, resend) {
            console.log(order)
            this.newShipping = {
                origin: order.generic.origin,
                orderReference: order.generic.reference,
                invoiceNumber: order.generic.invoice_number_planet,
                deliveryAddress: order.deliveryAddress,
                marketplace_order_id: order.generic.marketplace_order_id,
                courier: '',
                type: null,
                quantity: 0,
                resend: resend
            }

            this.shippingType = []
            this.disableButtonShipping = false
            this.dialogShipping = true
        },
        updateQuantity(quantity, option) {
            this.newShipping.quantity = quantity
            this.newShipping.courier = ''
            this.newShipping.type = ''
            this.shippingType = []
            if (option === 'ship') {
                this.getPriceCourier()
            }
            this.loadingDHL = true
        },
        getPriceCourier() {
            let ship = {
                shipping: this.newShipping,
                shipper: 'bikeplanet'
            }

            DropShipping.dispatch('getPriceCourier', ship)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.shippingType = responseData.infoCourier
                            this.loadingDHL = false
                        }
                    })
                    .catch(response => {

                    })
        },
        quantityButtonColor(quantity) {
            return quantity === this.newShipping.quantity ? 'success' : ''
        },
        selectCourierType(type, courier) {
            this.newShipping.type = type
            this.newShipping.courier = courier
        },
        colorCardCourierType(type, courier) {
            return this.newShipping.type === type && this.newShipping.courier === courier ? 'success' : ''
        },
        saveShipping() {
            this.orders = [];
            this.disableButtonShipping = true

            let ship = {
                shipping: this.newShipping,
                shipper: 'bikeplanet'
            }

            DropShipping.dispatch('createShipping', ship)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.dialogShipping = false

                            let alert = {
                                snackbar: true,
                                text: 'Envio configurado com sucesso!',
                                type: 'success'
                            }

                            Alert.dispatch('show', alert)
                            setTimeout(() => {
                                this.initialize()
                            }, 5000)
                        } else {
                            let alert = {
                                snackbar: true,
                                text: 'Erro ao configurar envio!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        }
                    })
                    .catch(response => {
                        let alert = {
                            snackbar: true,
                            text: 'Erro ao configurar envio!',
                            type: 'error'
                        }

                        Alert.dispatch('show', alert)
                    })
        },
        getHistoricDocs(order) {
            this.historicDocs = []
            this.dialogHistoricDocs = true
            this.loadingHistoric = true

            DropShipping.dispatch('getHistoricDocs', order.generic.reference)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.headersHistoricDoc = responseData.headers
                            this.historicDocs = responseData.historicDocs
                        }
                        this.loadingHistoric = false
                    })
                    .catch(response => {
                        this.loadingHistoric = false
                    })
        },
        downloadReturnLabel(order) {
            if (order.generic.tracking_return !== null) {
                DropShipping.dispatch('downloadReturnLabel', order.generic.tracking_return)
                        .then(response => {
                            let link = document.createElement('a');
                            document.body.appendChild(link);
                            link.setAttribute('type', 'hidden');
                            link.download = order.generic.tracking_return + '.pdf';
                            link.href = window.URL.createObjectURL(response.data);
                            link.click();
                        })
                        .catch(response => {
                            let alert = {
                                snackbar: true,
                                text: 'Erro no download da etiqueta de devolução!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        })
            } else {
                let alert = {
                    snackbar: true,
                    text: 'Erro no download da etiqueta de devolução!',
                    type: 'error'
                }

                Alert.dispatch('show', alert)
            }
        },
        downloadLabel(order) {
            if (order.generic.tracking !== null) {
                DropShipping.dispatch('downloadReturnLabel', order.generic.tracking)
                        .then(response => {
                            let link = document.createElement('a');
                            document.body.appendChild(link);
                            link.setAttribute('type', 'hidden');
                            link.download = order.generic.tracking + '.pdf';
                            link.href = window.URL.createObjectURL(response.data);
                            link.click();
                        })
                        .catch(response => {
                            let alert = {
                                snackbar: true,
                                text: 'Erro no download da etiqueta!',
                                type: 'error'
                            }

                            Alert.dispatch('show', alert)
                        })
            } else {
                let alert = {
                    snackbar: true,
                    text: 'Erro no download da etiqueta!',
                    type: 'error'
                }

                Alert.dispatch('show', alert)
            }
        },
        registerReturned(order) {
            this.orderReference = order.generic.reference
            this.invoiceMode = 'full'
            this.dialogRegisterReturned = true
        },
        downloadPDF(serie, tipodoc, numdoc) {
            let request = {
                url: api.orderPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let request = {
                                url: api.orderDownloadPDFFile() + '/' + serie + '/' + tipodoc + '/' + numdoc
                            }
                            ApiWS.dispatch('makeDownload', request)
                                    .then(response => {
                                        var file = new Blob([response.data], {
                                            type: 'application/pdf'
                                        });
                                        var fileURL = URL.createObjectURL(file);
                                        var a = document.createElement('a');
                                        a.href = fileURL;
                                        a.target = '_blank';
                                        a.download = serie + '_' + tipodoc + '_' + numdoc + '.pdf';
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                    .catch(response => {
                                        Alert.dispatch('show', {
                                            snackbar: true,
                                            text: 'Erro de servidor!',
                                            type: 'error'
                                        })
                                    })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar PDF!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        downloadPDFPlanet(serie, tipodoc, numdoc) {
            let request = {
                url: '/api/staff/orders/getPDFFilePlanet/' + serie + '/' + tipodoc + '/' + numdoc
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let request = {
                                url: '/api/staff/orders/openPDFFilePlanet/' + serie + '/' + tipodoc + '/' + numdoc
                            }
                            ApiWS.dispatch('makeDownload', request)
                                    .then(response => {
                                        var file = new Blob([response.data], {
                                            type: 'application/pdf'
                                        });
                                        var fileURL = URL.createObjectURL(file);
                                        var a = document.createElement('a');
                                        a.href = fileURL;
                                        a.target = '_blank';
                                        a.download = serie + '_' + tipodoc + '_' + numdoc + '.pdf';
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                    .catch(response => {
                                        Alert.dispatch('show', {
                                            snackbar: true,
                                            text: 'Erro de servidor!',
                                            type: 'error'
                                        })
                                    })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar PDF!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        }
    },
    created() {
        document.title = 'BikePlanet - Encomendas'
        this.initialize()
    },
    computed: {
        getProfileId() {
            return User.getters.getProfileId
        },
        getTotalPages() {
            return Math.ceil(this.totalOrders / this.filters.per_page)
        }
    }
}
</script>
