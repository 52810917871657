<template>
    <v-container fluid>
        <v-row>
            <v-col cols="3">
                <page-title title="Ecovalor"></page-title>
            </v-col>
            <v-col>
                <v-btn color="error" style="float: right" class="ml-3" @click="print()">
                    Imprimir
                </v-btn>
                <v-btn style="float: right" class="ml-3" @click="getEcovalor">
                    Filtrar
                </v-btn>
                <v-select
                        v-model="filters.country"
                        dense
                        outlined
                        hide-details
                        label="País"
                        :items="filtersCountry"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="width: 100px; float: right"
                        class="ml-3"
                ></v-select>
                <v-text-field
                        v-model="filters.trimestre"
                        dense
                        outlined
                        hide-details
                        label="Trimestre"
                        style="width: 100px; float: right"
                        class="ml-3"
                ></v-text-field>
                <v-text-field
                        v-model="filters.year"
                        dense
                        outlined
                        hide-details
                        label="Ano"
                        style="width: 100px; float: right"
                ></v-text-field>
                <v-btn
                        tile
                        :color="filters.option === 'bikes' ? 'primary' : ''"
                        @click="changeOption('bikes')"
                        style="float: right"
                        class="mr-3"
                >
                    Bicicletas
                </v-btn>
                <v-btn
                        tile
                        :color="filters.option === 'pneus' ? 'error' : ''"
                        @click="changeOption('pneus')"
                        style="float: right"
                >
                    Pneus
                </v-btn>
                <v-btn
                        tile
                        :color="filters.option === 'oleo' ? 'primary' : ''"
                        @click="changeOption('oleo')"
                        style="float: right"
                >
                    Óleo
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card" id="printMe">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr>
                            <th>Ano</th>
                            <th>Trimestre</th>
                            <th>País</th>
                            <th>Artigo</th>
                            <th>Descrição</th>
                            <th v-if="filters.option==='oleo'">Tipo Produto</th>
                            <th>Quantidade</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="7">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="item in items">
                            <td>{{ item.Ano }}</td>
                            <td>{{ item.Trimestre }}</td>
                            <td>{{ item.Pais }}</td>
                            <td>{{ item.Produto }}</td>
                            <td>{{ item.Descricao }}</td>
                            <td v-if="filters.option === 'oleo'">{{ item.TipoProduto }}</td>
                            <td>{{ Math.round(item.Quantidade * 100) / 100 }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                    <h4 v-if="filters.option==='oleo' && items.length > 0" style="text-align: right">Total de Óleo: {{ Math.round(totalOleos * 100) / 100 }}</h4>
                    <h4 v-if="filters.option==='oleo' && items.length > 0" style="text-align: right">Total de Massa: {{ Math.round(totalMassa * 100) / 100 }}</h4>
                    <h4 v-if="filters.option==='oleo' && items.length > 0" style="text-align: right">Total de Óleos Perdidos: {{ Math.round(totalOleosPerdidos * 100) / 100 }}</h4>
                    <h4 v-if="filters.option==='oleo' && items.length > 0" style="text-align: right">Total de Veiculos: {{ Math.round(totalVeiculos * 100) / 100 }}</h4>
                    <h4 v-if="(filters.option==='pneus' || filters.option==='bikes') && items.length > 0" style="text-align: right">Total de Artigos: {{ Math.round(totalArtigos * 100) / 100 }}</h4>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    export default {
        data: function () {
            return {
                loading: false,
                filters: {
                    year: '',
                    trimestre: '',
                    country: '',
                    option: 'oleo'
                },
                filtersCountry: [
                    {TEXT: 'Todos', VALUE: ''},
                    {TEXT: 'PT', VALUE: 'PT'},
                    {TEXT: 'ES', VALUE: 'ES'}
                ],
                items: [],
                totalOleos: 0,
                totalMassa: 0,
                totalOleosPerdidos: 0,
                totalVeiculos: 0,
                totalArtigos: 0
            }
        },
        created() {
            document.title = 'Ecovalor'
        },
        methods: {
            getEcovalor() {
                this.loading = true
                this.items = []

                let request = {
                    url: api.productsEcovalor(),
                    data: {
                        filters: this.filters
                    }
                }
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            if (this.filters.option === 'oleo') {
                                this.items = responseData.ecovalorOleos
                                this.totalOleos = responseData.totalOleos
                                this.totalMassa = responseData.totalMassa
                                this.totalOleosPerdidos = responseData.totalOleosPerdidos
                                this.totalVeiculos = responseData.totalVeiculos
                            } else {
                                this.items = responseData.ecovalorPneus
                                this.totalArtigos = responseData.totalArtigos
                            }
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })
            },
            changeOption(option) {
                this.filters.option = option
                this.items = []
            },
            print() {
                this.$htmlToPaper('printMe');
            }
        }
    }
</script>
