<template>
    <div>
    <v-card tile min-height="300">
        <v-card-text>
            <v-row>
                <v-col cols="12" md="7">
                    <v-card>
                        <v-card-text>
                            <v-btn color="primary" fab small absolute right @click="toggleDialogEditBasicSetting()">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <div><strong>ID: {{bannerToShow.id}}</strong></div>
                                    <div><strong>Nome: {{bannerToShow.name}}</strong></div>
                                    <div><strong>Página: {{bannerToShow.page}}</strong></div>
                                    <div><strong>Posição: {{bannerToShow.position}}</strong></div>
                                    <div><strong>Marca: {{bannerToShow.brand}}</strong></div>
                                </v-col>
                                <v-col cols="12" md="5">
                                    <div><strong>Data de criação: {{bannerToShow.created_at}}</strong></div>
                                    <div><strong>Data da última atualização: {{bannerToShow.updated_at}}</strong></div>
                                    <div><strong>Rácio Horizontal: {{bannerToShow.ratioHorizontal}}</strong></div>
                                    <div><strong>Rácio Vertical: {{bannerToShow.ratioHorizontal}}</strong></div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
    <v-row justify="center">
    <v-dialog persistent v-model="dialogEditBasicSetting" max-width="900">
        <v-card>
            <v-card-title class="headline darken-1">
                Editar Banner
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-form v-model="formValid" ref="myForm">
<!--                            <v-text-field
                                    v-model="brandToAdd"
                                    dense
                                    outlined
                                    label="Marca"
                                    hide-details
                                    class="pl-2 pr-2 pb-6 pt-1"
                                    style="max-width: 99.5%"
                            ></v-text-field>-->
                            <v-select
                                    :items="bannerLocations"
                                    outlined
                                    single-line
                                    :rules="rules.required"
                                    v-model="selectedLocation"
                                    item-value="id"
                                    class="mt-2 ml-2"
                                    label="Localização"
                                    dense
                                    style="max-width: 98%"
                            >
                                <template v-slot:item="{ item }">
                                    Página: {{ item.page }} --- Posição: {{ item.position }}
                                </template>
                                <template v-slot:selection="{ item }">
                                    Página: {{ item.page }} --- Posição: {{ item.position }}
                                </template>
                            </v-select>
                            <v-select
                                    :items="bannerTypes"
                                    outlined
                                    single-line
                                    item-value="id"
                                    item-text="description"
                                    :rules="rules.required"
                                    v-model="selectedType"
                                    class="mt-2 ml-2"
                                    label="Tipo de Banner"
                                    dense
                                    style="max-width: 98%"
                            >
                            </v-select>
                            <v-select
                                    :items="brands"
                                    outlined
                                    single-line
                                    item-value="VALUE"
                                    item-text="VALUE"
                                    :rules="rules.required"
                                    v-model="brandToAdd"
                                    class="mt-2 ml-2"
                                    label="Marca"
                                    dense
                                    style="max-width: 98%"
                            >
                            </v-select>
                            <v-text-field
                                    v-model="orderToAdd"
                                    dense
                                    outlined
                                    label="Order"
                                    hide-details
                                    class="pl-2 pr-2 pb-6 pt-1"
                                    style="max-width: 99.5%"
                            ></v-text-field>
                            <v-text-field
                                    v-model="linkToAdd"
                                    dense
                                    outlined
                                    label="Link"
                                    hide-details
                                    class="pl-2 pr-2 pb-6 pt-1"
                                    style="max-width: 99.5%"
                            ></v-text-field>
                            <v-checkbox label="Ativo" v-model="activeOption" hide-details></v-checkbox>
                            <v-checkbox label="Nova Janela" v-model="newWindowOption" hide-details></v-checkbox>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="dialogEditBasicSetting = false">Fechar</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="editBanner()">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    </v-row>
<!--        <v-btn color="success" @click="getbanner()">oxii</v-btn>-->
    </div>
</template>

<script>
export default {
    props: {
        banner: Object,

    },
    name: "BasicSettings",
    data: function (){
        return {
            bannerToShow: {},
            brandToAdd: null,
            brands: [],
            bannerTypes: [],
            bannerLocations: [],
            selectedType: null,
            selectedLocation: null,
            manipulatedLines: [],
            noLanguageLines: [],
            dialogEditBasicSetting: false,
            orderToAdd: '',
            linkToAdd: '',
            newWindowOption: '',
            activeOption: false,
            formValid: false,
            rules: {
                required: [value => !!value || "Required."]
            },
        }
    },
    created() {
        this.bannerToShow = this.banner
        this.getBannerTypes();
        this.getBannerLocations();
        this.getbanner();

    },
    methods: {
        getBannerTypes() {
            this.loading = true
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/b2b/banners-type'
            }
            var spanishLines = []
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            responseData.bannerTypes.forEach(aux => {
                                if (aux.language === null) {
                                    this.bannerTypes.push({
                                        'name': aux.name,
                                        'description': aux.name,
                                        'id': aux.id
                                    })
                                }
                                if (aux.language !== null && aux.language.toUpperCase() === User.getters.getLanguage.toUpperCase()) {
                                    this.bannerTypes.push({
                                        'name': aux.name,
                                        'description': aux.description,
                                        'id': aux.id
                                    })
                                }
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        },
        getBannerLocations() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/b2b/banners-location'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            this.bannerLocations = responseData.bannerLocations;
                            for (let i = 0; i < this.bannerLocations.length; i++) {
                                if (this.bannerLocations[i].deleted_at != null) {
                                    this.bannerLocations.splice(i--, 1);
                                }
                            }
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })

        },
        editBanner() {
            if (this.$refs.myForm.validate()) {
                var auxActive = null
                var auxNewWindow = null
                if (this.activeOption){
                    auxActive = 1;
                }else{
                    auxActive = 0;
                }
                if (this.newWindowOption){
                    auxNewWindow = 1;
                }else{
                    auxNewWindow = 0;
                }
                // if(this.selectedLocation != 1002){
                //     this.brandToAdd = '';
                // }
                let request = {
                    url: '/api/staff/b2b/bannersB2B/'+ this.$route.params.id,
                    data: {
                        banner: {
                            typeId: this.selectedType,
                            brand: this.brandToAdd,
                            locationId: this.selectedLocation,
                            active: auxActive,
                            order: this.orderToAdd,
                            newWindow: auxNewWindow,
                            link: this.linkToAdd,
                        }
                    }
                }
                ApiWS.dispatch('makePut', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === "OK") {
                                Alert.dispatch('show', {
                                    snackbar: true,
                                    text: 'Banner editado com sucesso!',
                                    type: 'success'
                                })
                                this.dialogEditBasicSetting = false
                                this.getbanner();
                            }
                        })
                        .catch(() => {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })
                console.log(request.data.banner)
            }
        },
        getbanner(){
            let request = {
                url: '/api/staff/b2b/bannersB2B/'+this.$route.params.id,
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === "OK") {
                            console.log(responseData)
                            this.bannerToShow = responseData.banner[0]
                            this.brandToAdd = responseData.banner[0].brand;
                            this.selectedLocation = responseData.banner[0].locationId;
                            this.selectedType = responseData.banner[0].typeId;
                            this.activeOption = responseData.banner[0].active;
                            this.linkToAdd = responseData.banner[0].link;
                            this.orderToAdd = responseData.banner[0].order;
                            this.newWindowOption = responseData.banner[0].newWindow;
                            this.brands = responseData.brands;
                            this.brands.splice(0,0,{TEXT: 'ALL', VALUE: 'ALL'})
                            console.log(this.bannerToShow);
                           // console.log(this.brands);
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        toggleDialogEditBasicSetting() {
            this.editProduct = Object.assign({}, this.banner)
            this.dialogEditBasicSetting = true
        },
    }
}
</script>

<style scoped>

</style>