<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        title="Débitos Directos"
                        :subtitle="$t('show')+' '+debits.length+' de '+totalDebits"
                ></page-title>
            </v-col>
            <v-col>
                <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        dense
                        solo
                        outlined
                        single-line
                        hide-details
                        @change="initialize"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="float: right; width: 100px;"
                ></v-select>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th style="width: 140px;">Ficheiro</th>
                        <th style="width: 100px;">{{ $t('date') }}</th>
                        <th class="text-center" style="width: 70px;">TipoDoc</th>
                        <th class="text-center" style="width: 100px;">NumDoc</th>
                        <th class="text-center" style="width: 100px;">{{ $t('entity') }}</th>
                        <th>Cliente</th>
                        <th class="text-right" style="width: 100px;">Valor Cobrança</th>
                        <th class="text-right" style="width: 100px;">Doc. Montante</th>
                        <th class="text-right">Status</th>
                    </tr>
                    <tr class="filters_row">
                        <td></td>
                        <td>
                            <v-text-field
                                    v-model="filters.date"
                                    outlined
                                    single-line
                                    hide-details
                                    @keypress.enter="initialize"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.tipoDoc"
                                    outlined
                                    single-line
                                    hide-details
                                    @keypress.enter="initialize"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.numDoc"
                                    outlined
                                    single-line
                                    hide-details
                                    @keypress.enter="initialize"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.entity"
                                    outlined
                                    single-line
                                    hide-details
                                    @keypress.enter="initialize"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.client"
                                    outlined
                                    single-line
                                    hide-details
                                    @keypress.enter="initialize"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.amountPayable"
                                    outlined
                                    single-line
                                    hide-details
                                    @keypress.enter="initialize"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.documentAmount"
                                    outlined
                                    single-line
                                    hide-details
                                    @keypress.enter="initialize"
                            ></v-text-field>
                        </td>
                        <td></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="loading">
                        <td colspan="10">
                            <v-progress-linear indeterminate></v-progress-linear>
                        </td>
                    </tr>
                    <tr v-for="debit in debits">
                        <td>{{ debit.Ficheiro }}</td>
                        <td>{{ debit.Data }}</td>
                        <td class="text-center">{{ debit.Tipodoc }}</td>
                        <td class="text-center">{{ debit.NumDoc }}</td>
                        <td class="text-center">{{ debit.Entidade }}</td>
                        <td>{{ debit.Cliente }}</td>
                        <td class="text-right">{{ $money(debit.amount_to_pay) }}</td>
                        <td class="text-right">{{ $money(debit.document_amount) }}</td>
                        <td class="text-right">{{ debit.Status }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                        v-model="pagination.page"
                        :length="getTotalPages"
                        :total-visible="7"
                        @input="initialize"
                ></v-pagination>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        data: () => ({
            headers: [],
            debits: [],
            totalDebits: 0,
            pagination: {
                page: 1,
                per_page: 250
            },
            loading: false,
            filtersPerPage: [
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            filters: {
                date: '',
                tipoDoc: '',
                numDoc: '',
                entity: '',
                client: '',
                amountPayable: '',
                documentAmount: ''
            }
        }),
        created() {
            document.title = 'Débitos Directos';
            this.initialize()
        },
        methods: {
            initialize() {
                this.debits = [];
                this.loading = true;
                let request = {
                    url: api.clientsDirectDebits(),
                    data: {
                        pagination: this.pagination,
                        filters: this.filters
                    }
                };

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.debits = responseData.debits;
                            this.totalDebits = responseData.totalDebits;
                            this.loading = false
                        }
                    })
                    .catch(() => {
                        this.loading = false
                    })
            }
        },
        computed: {
            getTotalPages() {
                return Math.ceil(this.totalDebits / this.pagination.per_page)
            }
        }
    }
</script>
