<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row>
            <v-col>
                <page-title
                        :title="$t('orders') + ' Bicicletas'"
                        :subtitle="$t('show') + ' ' + orders.length + ' de ' + originalOrders.length + ' ' + $t('orders').toLowerCase()"
                ></page-title>
            </v-col>
            <v-col class="text-right">

                <v-btn tile small  class="ml-2 mb-2" :color="ordersOption === 'today' ? 'primary' : ''" @click="changeOrdersOption('today')" :loading="loading && ordersOption === 'today'">
                    {{ $t('today') }}
                </v-btn>
                <v-btn tile small  class="mb-2" :color="ordersOption === 'all' ? 'primary' : ''" @click="changeOrdersOption('all')" :loading="loading && ordersOption === 'all'">
                    {{ $t('all') }}
                </v-btn>

                <v-btn tile small  class="ml-2 mb-2" @click="clearFilters()" color="error" :block="$vuetify.breakpoint.xs">
                    Limpar Filtros
                </v-btn>

            </v-col>
        </v-row>

        <!-- ORDERS TABLE -->
        <div class="staff_v-card">
            <v-simple-table class="staff_table">
                <thead>
                <!-- TABLE HEADERS -->
                <tr class="titles_row">
                    <th style="width: 30px;">Disp.</th>
                    <th style="width: 45px;" class="hidden-sm-and-down text-center">Pais</th>
                    <th>Entidade</th>
                    <th class="hidden-sm-and-down">Nome</th>
                    <th style="width: 90px;">Data</th>
                    <th style="width: 120px;" class="hidden-sm-and-down">Familia</th>
                    <th style="width: 45px;" class="text-center">Qt</th>
                    <th>Artigo</th>
                    <th>Stock</th>
                    <th>Componentes</th>
                </tr>
                <tr class="filters_row">
                    <td>
                        <v-select
                                v-model="filters.disp"
                                :items="filtersYesNo"
                                item-text="text"
                                item-value="value"
                                dense
                                outlined
                                hide-details
                                @change="filterOrders"
                        ></v-select>
                    </td>
                    <td class="hidden-sm-and-down">
                        <v-select
                                v-model="filters.country"
                                :items="filtersCountry"
                                item-text="text"
                                item-value="value"
                                dense
                                outlined
                                hide-details
                                @change="filterOrders"
                        ></v-select>
                    </td>
                    <td class="hidden-sm-and-down">
                        <v-text-field
                                v-model="filters.entity"
                                outlined
                                hide-details
                                single-line
                                @change="filterOrders"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-text-field
                                v-model="filters.name"
                                outlined
                                hide-details
                                single-line
                                @change="filterOrders"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-text-field
                                v-model="filters.date"
                                outlined
                                hide-details
                                single-line
                                @change="filterOrders"
                        ></v-text-field>
                    </td>
                    <td class="hidden-sm-and-down">
                        <v-select
                                v-model="filters.family"
                                :items="filtersFamily"
                                item-text="text"
                                item-value="value"
                                dense
                                outlined
                                hide-details
                                @change="filterOrders"
                        ></v-select>
                    </td>
                    <td></td>
                    <td>
                        <v-text-field
                                v-model="filters.product"
                                outlined
                                hide-details
                                single-line
                                @change="filterOrders"
                        ></v-text-field>
                    </td>

                </tr>
                </thead>
                <tbody>
                <tr v-if="loading">
                    <td colspan="10">
                        <v-progress-linear indeterminate></v-progress-linear>
                    </td>
                </tr>
                <template v-if="!loading" v-for="order in orders">
                    <tr v-if="differentDate(order)">
                        <td colspan="10" class="grey lighten-2 date_row">{{ order.DataEntrega }}</td>
                    </tr>
                    <tr>
                        <td>
                            <v-chip small :color="stockAtual(order)>0 ? 'green' : 'red'" text-color="white">{{ stockAtual(order) }}</v-chip>
                        </td>
                        <td class="hidden-sm-and-down text-center">{{order.Pais}}</td>
                        <td>
                            <span @click="selectEntity(order.Entidade)" style="cursor: pointer">
                                <b>{{ order.Entidade }}</b>
                                <a :href="'/clients/clients/'+order.Entidade" target="_blank" class="ml-1"><i class="fas fa-fw fa-external-link-alt"></i></a>
                            </span>
                            <div class="hidden-md-and-up">{{order.NomeFac}}</div>
                        </td>
                        <td class="hidden-sm-and-down">{{order.NomeFac}}</td>
                        <td>{{order.DataDocumento}}</td>
                        <td class="hidden-sm-and-down">{{order.Familia}}</td>
                        <td class="text-center"><b>{{order.Quantidade}}</b></td>
                        <td><b>{{order.Artigo}}</b><br>{{order.Descricao}}</td>
                        <td>{{Math.round(order.STKActual)}}</td>
                        <td>
                            <table v-if="order.Familia !== 'EBIKES'">
                                <tr>
                                    <td style="width: 200px">
                                        <b>{{order.FRAME}}</b>
                                        <div class="hidden-md-and-up">{{order.NOME_FRAME}}</div>
                                    </td>
                                    <td class="hidden-sm-and-down" style="width: 250px">{{order.NOME_FRAME}}</td>
                                    <td style="width: 20px">{{Math.round(order.STK_FRAME)}}</td>
                                </tr>
                                <tr v-if="order.Familia === 'BIKES'">
                                    <td>
                                        <b>{{order.KIT}}</b>
                                        <div class="hidden-md-and-up">{{order.NOME_KIT}}</div>
                                    </td>
                                    <td class="hidden-sm-and-down">{{order.NOME_KIT}}</td>
                                    <td>{{Math.round(order.STK_KIT)}}</td>
                                </tr>
                                <tr v-if="order.Familia === 'BIKES'">
                                    <td>
                                        <b>{{order.FORK}}</b>
                                        <div class="hidden-md-and-up">{{order.NOME_FORK}}</div>
                                    </td>
                                    <td class="hidden-sm-and-down">{{order.NOME_FORK}}</td>
                                    <td>{{Math.round(order.STK_FORK)}}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </template>
                </tbody>

            </v-simple-table>
        </div>

    </v-container>
</template>

<script>
    import i18n from '../../plugins/i18n'

    export default {
        beforeCreate() {
            i18n.locale = User.getters.getLanguage
        },
        data: function () {
            return {
                loading: false,
                ordersOption: 'today',
                orders: [],
                originalOrders: [],
                filters: {
                    disp: '',
                    country: '',
                    entity: '',
                    name: '',
                    date: '',
                    family: '',
                    product: '',
                },
                filtersYesNo: [
                    {text: '-', value: ''},
                    {text: 'Sim', value: '1'},
                    {text: 'Não', value: '0'}
                ],
                filtersCountry: [
                    {text: '-', value: ''},
                    {text: 'PT', value: 'PT'},
                    {text: 'ES', value: 'ES'}
                ],
                filtersFamily: [
                    {text: '-', value: ''},
                    {text: 'BIKES', value: 'BIKES'},
                    {text: 'EBIKES', value: 'EBIKES'},
                    {text: 'FRAMES', value: 'FRAMES'}
                ],
            }
        },
        created() {
            document.title = i18n.t('orders') + ' Bicicletas';
            this.initialize()
        },
        private: {
            currentDate: '',
        },
        methods: {
            initialize() {
                this.loading = true;
                let request = {
                    url: api.ordersBikes(),
                    data: {
                        today: this.ordersOption === 'today'
                    }
                };
                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.orders = responseData.orders;
                            this.originalOrders = this.orders;
                            this.filterOrders();
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            },
            filterOrders() {
                this.orders = this.originalOrders;
                if (this.filters.disp !== '') {
                    if (this.filters.disp === '1') {
                        this.orders = this.orders.filter(function (order) {
                            return order.stockDisp > 0;
                        })
                    } else {
                        this.orders = this.orders.filter(function (order) {
                            return order.stockDisp === 0;
                        })
                    }
                }
                if (this.filters.country !== '') {
                    this.orders = this.orders.filter(order => order.Pais === this.filters.country);
                }
                if (this.filters.entity !== '') {
                    this.orders = this.orders.filter(order => order.Entidade.toUpperCase().includes(this.filters.entity.toUpperCase()));
                }
                if (this.filters.name !== '') {
                    this.orders = this.orders.filter(order => order.NomeFac.toLowerCase().includes(this.filters.name.toLowerCase()));
                }
                if (this.filters.date !== '') {
                    this.orders = this.orders.filter(order => order.DataDocumento.includes(this.filters.date));
                }
                if (this.filters.family !== '') {
                    this.orders = this.orders.filter(order => order.Familia === this.filters.family);
                }
                if (this.filters.product !== '') {
                    this.orders = this.orders.filter(order => order.Artigo.toUpperCase().includes(this.filters.product.toUpperCase()));
                }

            },
            clearFilters() {
                this.filters = {
                    disp: '',
                    country: '',
                    entity: '',
                    name: '',
                    date: '',
                    family: '',
                    product: '',
                };
                this.filterOrders()
            },
            selectEntity(entity) {
                this.filters.entity = entity;
                this.filterOrders()
            },
            changeOrdersOption(option) {
                this.ordersOption = option;
                this.initialize();
            },
            stockAtual(order) {
                let min = 0;
                if (order.FRAME !== null) {
                    min = Math.min(Math.round(order.STK_FRAME), min);
                }
                if (order.KIT !== null) {
                    min = Math.min(Math.round(order.STK_KIT), min);
                }
                if (order.FORK !== null) {
                    min = Math.min(Math.round(order.STK_FORK), min);
                }
                order.stockDisp = Math.round(order.STKActual) + min;
                return order.stockDisp;
            },
            differentDate(order) {
                if (order.DataEntrega !== this.currentDate) {
                    this.currentDate = order.DataEntrega;
                    return true
                }
                return false
            },
        }
    }
</script>

<style scoped>

</style>
