<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title title="Banners B2B"></page-title>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small color="success" class="ml-3" @click="addBanner">
                    {{ $t('addItem') }}
                </v-btn>
                <v-btn tile small color="primary" class="ml-3" @click="getBanners">
                    Actualizar
                </v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>
            <v-col cols="6" class="pb-0" style="border-right: solid 1px white">
                <div class="float-left">
                    <flag iso="pt" v-bind:squared=false></flag>
                </div>
            </v-col>
            <v-col cols="6" class="pb-0">
                <div class="float-right">
                    <flag iso="es" v-bind:squared=false></flag>
                </div>
            </v-col>
        </v-row>
        <v-row>

            <v-col cols="6" align="center" class="justify-center" style="border-right: solid 1px white">
                <template v-for="banner in bannersPT">
                    <v-card class="mb-4" max-width="500" min-height="164"
                            :color="isVisible(banner) ? '' : 'pink lighten-5'">
                        <v-img v-if="banner.file" :src="bannersEndpoint+banner.file"></v-img>
                        <v-img v-else src="https://via.placeholder.com/600x139.png?text=1920x445"></v-img>
                        <v-card-actions>
                            <p class="overline mb-0">
                                <span>{{ banner.start_date }}</span> | <span
                                    :class="{'red--text': banner.end_date < today }">{{ banner.end_date }}</span>
                            </p>
                            <v-spacer></v-spacer>
                            <v-chip small class="mr-2" label outlined @click="toggleDialogClickHist(banner)">
                                <v-icon left small>
                                    mdi-cursor-default-click
                                </v-icon>
                                {{ banner.clicks }}
                            </v-chip>
                            <v-btn small icon @click="deleteBanner(banner)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                            <v-btn small icon :color="banner.order ? 'blue' : ''" @click="toogleOrder(banner)">
                                <v-icon v-if="banner.order">filter_1</v-icon>
                                <v-icon v-if="!banner.order">filter_none</v-icon>
                            </v-btn>
                            <v-btn small icon :color="!banner.active ? 'red' : ''" @click="toogleActive(banner)">
                                <v-icon v-if="banner.active">visibility</v-icon>
                                <v-icon v-if="!banner.active">visibility_off</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editBanner(banner)">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-col>

            <v-col cols="6" align="center" class="justify-center">
                <template v-for="banner in bannersES">
                    <v-card class="mb-4" max-width="500" min-height="164"
                            :color="isVisible(banner) ? '' : 'pink lighten-5'">
                        <v-img :src="bannersEndpoint+banner.file"></v-img>
                        <v-card-actions>
                            <p class="overline mb-0">
                                <span>{{ banner.start_date }}</span> | <span
                                    :class="{'red--text': banner.end_date < today }">{{ banner.end_date }}</span>
                            </p>
                            <v-spacer></v-spacer>
                            <v-chip small class="mr-2" label outlined @click="toggleDialogClickHist(banner)">
                                <v-icon left small>
                                    mdi-cursor-default-click
                                </v-icon>
                                {{ banner.clicks }}
                            </v-chip>
                            <v-btn small icon @click="deleteBanner(banner)">
                                <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                            <v-btn small icon :color="banner.order ? 'blue' : ''" @click="toogleOrder(banner)">
                                <v-icon v-if="banner.order">filter_1</v-icon>
                                <v-icon v-if="!banner.order">filter_none</v-icon>
                            </v-btn>
                            <v-btn small icon :color="!banner.active ? 'red' : ''" @click="toogleActive(banner)">
                                <v-icon v-if="banner.active">visibility</v-icon>
                                <v-icon v-if="!banner.active">visibility_off</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editBanner(banner)">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </template>
            </v-col>

            <v-col v-if="false" cols="6">

                <template v-for="banner in bannersES">

                    <v-card tile class="mb-3" :color="banner.active?'':'red lighten-4'">
                        <v-card-text>
                            <table>
                                <tbody>

                                <tr>
                                    <td rowspan="3" class="pa-0" style="width: 320px;">
                                        <v-img style="width: 320px" v-if="banner.file"
                                               :src="bannersEndpoint+banner.file"></v-img>
                                        <v-img v-else src="https://via.placeholder.com/600x139.png?text=1920x445"></v-img>
                                    </td>
                                    <td style="width: 50%">
                                        <strong>{{ banner.name }}</strong>
                                    </td>
                                    <td style="width: 25%">
                                        <strong>Início:</strong> {{ banner.start_date }}
                                    </td>
                                    <td style="width: 25%">
                                        <strong class="mr-1">Activo:</strong>
                                        <v-chip label x-small v-if="banner.active" class="mx-2" color="success">
                                            {{ $t('yes') }}
                                        </v-chip>
                                        <v-chip label x-small v-if="!banner.active" class="mx-2" color="error">
                                            {{ $t('no') }}
                                        </v-chip>
                                    </td>
                                    <td style="width: 1px;" class="text-right">
                                        <v-btn tile small color="primary">Editar</v-btn>
                                    </td>
                                    <!--                                    <td style="width: 25%">-->
                                    <!--                                        <strong class="mr-1">Activo:</strong>-->
                                    <!--                                        <v-btn tile x-small :class="{'success': banner.active}">{{ $t('yes') }}</v-btn>-->
                                    <!--                                        <v-btn tile x-small :class="{'error': !banner.active}">{{ $t('no') }}</v-btn>-->
                                    <!--                                    </td>-->
                                </tr>

                                <tr>
                                    <td style="width: 25%">
                                        <strong>Nova Janela:</strong> {{ banner.new_window ? $t('no') : $t('yes') }}
                                    </td>
                                    <td style="width: 25%">
                                        <strong>Fim:</strong> {{ banner.end_date }}
                                    </td>
                                    <td style="width: 25%">
                                        <strong class="mr-1">Primeiro:</strong>
                                        <v-chip label x-small v-if="banner.order" class="mx-2" color="success">
                                            {{ $t('yes') }}
                                        </v-chip>
                                        <v-chip label x-small v-if="!banner.order" class="mx-2" color="error">
                                            {{ $t('no') }}
                                        </v-chip>
                                    </td>
                                    <td style="width: 1px;" class="text-right">
                                        <v-btn tile small color="default">Clicks: {{ banner.clicks }}</v-btn>
                                    </td>

                                </tr>

                                <tr>
                                    <td colspan="3">
                                        <strong>URL:</strong> {{ banner.link }}
                                    </td>
                                    <td class="text-right">
                                        <v-btn tile small color="error" @click="deleteBanner(banner)">Remover
                                        </v-btn>
                                    </td>

                                </tr>

                                </tbody>

                            </table>
                        </v-card-text>
                    </v-card>

                </template>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">

            <!-- EDIT BANNER -->
            <v-dialog v-model="editDialog" persistent max-width="600">
                <v-card>
                    <v-img v-if="selected.file !== ''" :src="bannersEndpoint+selected.file">
                        <v-app-bar flat color="rgba(0, 0, 0, 0)">
                            <v-spacer></v-spacer>
                            <v-btn icon color="grey" @click="openImageUpload()">
                                <v-icon>mdi-image</v-icon>
                            </v-btn>
                        </v-app-bar>
                    </v-img>
                    <v-img v-else src="https://via.placeholder.com/600x139.png?text=1920x445">
                        <v-app-bar flat color="rgba(0, 0, 0, 0)">
                            <v-spacer></v-spacer>
                            <v-btn icon color="white" @click="openImageUpload()">
                                <v-icon>mdi-image</v-icon>
                            </v-btn>
                        </v-app-bar>
                    </v-img>
                    <v-card-text class="text-center">
                        <v-row class="pt-3">
                            <v-col>
                                <v-text-field
                                        label="Data Ínicio"
                                        dense
                                        outlined
                                        hide-details
                                        v-model="selected.start_date"
                                >
                                </v-text-field>
                                <v-switch
                                        v-model="selected.active"
                                        label="Banner Activo"
                                        hide-details
                                ></v-switch>
                                <v-switch
                                        v-model="selected.order"
                                        label="Aparece em primeiro"
                                        hide-details
                                ></v-switch>
                            </v-col>
                            <v-col>
                                <v-text-field
                                        label="Data Fim"
                                        dense
                                        outlined
                                        hide-details
                                        v-model="selected.end_date"
                                ></v-text-field>
                                <div class="text-left mt-3">
                                    País:
                                    <v-btn-toggle
                                            mandatory
                                            color="primary"
                                            v-model="selected.country"
                                            dense
                                    >
                                        <v-btn value="PT">PT</v-btn>
                                        <v-btn value="ES">ES</v-btn>
                                    </v-btn-toggle>
                                </div>
                                <v-switch
                                        v-model="selected.new_window"
                                        label="Abre nova janela"
                                        hide-details
                                ></v-switch>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-text-field
                                    class="mt-3"
                                    label="Link"
                                    dense
                                    outlined
                                    v-model="selected.link"
                            ></v-text-field>
                            <v-chip-group
                                    active-class="primary--text"
                                    column
                                    multiple
                                    v-model="selected.checkboxes"
                            >
                                <v-chip
                                        small
                                        filter
                                        outlined
                                        v-for="profile in profiles"
                                        :key="profile.value"
                                        :value="profile.value"
                                >{{ profile.text }}
                                </v-chip>
                            </v-chip-group>
                        </v-row>
                        <!--                        <i style="color: red" class="far fa-trash-alt fa-3x"></i>-->
                        <!--                        <p class="headline">Confirma que pretende remover o banner?</p>-->
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn tile small color="default" @click="editDialog = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="editConfirm()" :loading="editLoading">Gravar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- DELETE BANNER -->
            <v-dialog v-model="deleteDialog" persistent max-width="460">
                <v-card>
                    <v-img :src="bannersEndpoint+selected.file"></v-img>
                    <v-card-text class="text-center pt-6">
                        <p class="headline mb-0">Remover o banner?</p>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn tile small color="default" @click="deleteDialog = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="error" @click="deleteConfirm()">Remover</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogHistClick" max-width="700">
                <v-card>
                    <v-img :src="bannersEndpoint+selected.file"></v-img>
                    <v-card-text class="pt-4">
                        <v-simple-table fixed-header height="500px" class="staff_table">
                            <thead>
                            <tr>
                                <th>{{ $t('typeUser') }}</th>
                                <th>{{ $t('entity') }}</th>
                                <th>{{ $t('name') }}</th>
                                <th>{{ $t('date') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="click in clickHist">
                                <td>{{ click.tipoUser }}</td>
                                <td>{{ click.entidade }}</td>
                                <td>{{ click.nome }}</td>
                                <td>{{ click.updated_at }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- UPLOAD IMAGE -->
            <v-dialog v-model="imageDialog" persistent max-width="500">
                <staff-dialog :title="'Inserir imagem banner'">
                    <template slot="content">
                        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                    </template>
                    <template slot="action" class="text-right">
                        <v-btn color="error" @click="imageDialog = false">{{ $t('close') }}</v-btn>
                    </template>
                </staff-dialog>
            </v-dialog>

        </v-row>

    </v-container>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    data: () => ({
        today: null,
        bannersPT: [],
        bannersES: [],
        profiles: [],
        selected: {},
        deleteDialog: false,
        editDialog: false,
        editLoading: false,
        imageDialog: false,
        dropzoneOptions: {
            url: AppModel.getters.getUrlAPI + '/api/staff/b2b/banners/images',
            thumbnailWidth: 150,
            maxFilesize: 16,
            acceptedFiles: "image/png,image/jpeg,image/gif",
            headers: {"Authorization": "Bearer " + User.getters.getAccessToken},
        },
        dialogHistClick: false,
        clickHist: [],
        app_env: 'production',
        bannersEndpoint: '',
    }),
    created() {
        document.title = 'Banners B2B';
        this.today = this.getCurrentDate();
        this.getBanners();
    },
    components: {
        vueDropzone: vue2Dropzone
    },
    methods: {
        getBanners() {
            let loader = this.$loading.show();
            this.bannersPT = [];
            this.bannersES = [];
            ApiWS.dispatch('makeGet', {url: '/api/staff/b2b/banners'})
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.app_env = responseData.app_env;
                            this.bannersEndpoint = this.app_env === 'local'
                                    ? 'http://dev.b2b.bicimax.com/banners/'
                                    : 'https://static.bicimax.com/banners/';
                            this.bannersPT = responseData.bannersPT;
                            this.bannersES = responseData.bannersES;
                            this.profiles = responseData.profiles;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        getClickHist() {
            let loader = this.$loading.show();
            this.clickHist = [];
            ApiWS.dispatch('makeGet', {url: '/api/staff/b2b/bannersClickHist/' + this.selected.id})
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.clickHist = responseData.clickHist;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        isVisible(banner) {
            var visible = true;
            if (!banner.active) visible = false;
            if (banner.start_date > this.today) visible = false;
            if (banner.end_date < this.today) visible = false;
            return visible;
        },
        getCurrentDate() {
            var today = new Date()
            var dd = today.getDate()
            var mm = today.getMonth() + 1
            var yyyy = today.getFullYear()
            if (dd < 10) {
                dd = '0' + dd
            }
            if (mm < 10) {
                mm = '0' + mm
            }
            return yyyy + '-' + mm + '-' + dd
        },
        deleteBanner(banner) {
            this.selected = banner;
            this.deleteDialog = true;
        },
        deleteConfirm() {
            this.deleteDialog = false;
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/b2b/banners/delete',
                data: {
                    banner: this.selected,
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.bannersPT = responseData.bannersPT;
                            this.bannersES = responseData.bannersES;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Banner removido com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {snackbar: true, text: responseData.message, type: 'error'})
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {snackbar: true, text: 'Erro de servidor!', type: 'error'})
                        loader.hide();
                    })
        },
        editBanner(banner) {
            this.selected = banner;
            this.selected.checkboxes = JSON.parse(this.selected.profile);
            this.editDialog = true;
        },
        toggleDialogClickHist(banner) {
            this.selected = banner;
            this.getClickHist();
            this.dialogHistClick = true;
        },
        editConfirm() {
            this.selected.profile = JSON.stringify(this.selected.checkboxes);
            this.editDialog = false;
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/b2b/banners/edit',
                data: {
                    banner: this.selected,
                }
            };
            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.bannersPT = responseData.bannersPT;
                            this.bannersES = responseData.bannersES;
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Banner guardado com sucesso!',
                                type: 'success'
                            })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.message,
                                type: 'error'
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        toogleOrder(banner) {
            this.selected = banner;
            this.selected.checkboxes = JSON.parse(this.selected.profile);
            this.selected.order = !this.selected.order;
            this.editConfirm();
        },
        toogleActive(banner) {
            this.selected = banner;
            this.selected.checkboxes = JSON.parse(this.selected.profile);
            this.selected.active = !this.selected.active;
            this.editConfirm();
        },
        addBanner() {
            this.selected = {
                id: 0,
                active: 1,
                clicks: 0,
                country: "PT",
                end_date: this.today,
                file: "",
                link: "",
                name: "",
                new_window: 0,
                order: 0,
                profile: "[]",
                start_date: this.today,
                views: 0
            };
            this.selected.checkboxes = JSON.parse(this.selected.profile);
            this.editDialog = true;
        },
        openImageUpload() {
            if (this.selected.id === 0) {
                let request = {
                    url: '/api/staff/b2b/banners/edit',
                    data: {
                        banner: this.selected,
                    }
                };
                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                this.selected.id = responseData.id;
                                this.dropzoneOptions.url = AppModel.getters.getUrlAPI + '/api/staff/b2b/banners/' + this.selected.id + '/upload'
                                this.imageDialog = true
                            }
                        })
                        .catch(() => {

                        })
            } else {
                this.dropzoneOptions.url = AppModel.getters.getUrlAPI + '/api/staff/b2b/banners/' + this.selected.id + '/upload'
                this.imageDialog = true
            }
        },
    },
}
</script>
