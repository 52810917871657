import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

const Reports = new Vuex.Store({
    actions: {
        getSalesZone(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters
                }

                axios.post(api.reportsSalesZone(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSalesZoneDownload(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters
                }

                axios.post(api.reportsSalesZone() + '/download', data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSalesZoneClient(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters
                }

                axios.post(api.reportsSalesZoneClient(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSalesZoneClientDownload(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters
                }

                axios.post(api.reportsSalesZoneClient() + '/download', data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSalesClientYearDownload(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters
                }

                axios.post(api.reportsSalesClientYear() + '/download', data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSalesByClientBrandDownload(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters,
                }

                axios.post(api.reportsSalesClientBrand() + '/download', data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSalesByBrand(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters
                }

                axios.post(api.reportsSalesByBrand(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSalesByBrandDownload(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters,
                }

                axios.post(api.reportsSalesByBrand() + '/download', data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getReportAdminInteractionsDownload(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters,
                }
                axios.post(api.reportsCRMInteractions() + '/download', data, { responseType: 'blob' })
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        },
        getSalesBySection(context, filters) {
            return new Promise((resolve, reject) => {
                let data = {
                    filters: filters
                }

                axios.post(api.reportsSalesBySection(), data)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(response => {
                        reject(response)
                    })
            })
        }
    }
})

export default Reports
