import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"content-header"},[_c(VCol,[_c('page-title',{attrs:{"title":"VFAs"}})],1)],1),_c(VRow,[_c(VCol,[_c('div',{staticClass:"staff_v-card"},[_c(VSimpleTable,{staticClass:"staff_table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('supplier')))]),_c('th',[_vm._v("Documento")]),_c('th',[_vm._v("Num. Doc. Externo")]),_c('th',[_vm._v(_vm._s(_vm.$t('dateOfDocument')))]),_c('th',{staticClass:"text-center"},[_vm._v("Total Referências")]),_c('th',{staticClass:"text-center"},[_vm._v("Total "+_vm._s(_vm.$t('products')))]),_c('th',{staticClass:"text-center",attrs:{"width":"1"}},[_vm._v("Moeda")]),_c('th',{staticClass:"text-right"},[_vm._v("Valor Documento")]),_c('th',{attrs:{"width":"1"}})])]),_c('tbody',_vm._l((_vm.VFAs),function(vfa){return _c('tr',[_c('td',[_vm._v(_vm._s(vfa.Nome))]),_c('td',[_vm._v(_vm._s(vfa.Documento))]),_c('td',[_vm._v(_vm._s(vfa.NumDocExterno != null ? vfa.NumDocExterno : vfa.NumDocExterno_2))]),_c('td',[_vm._v(_vm._s(vfa.DataDoc))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(vfa.TotalReferencias))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(vfa.TotalArtigos))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(vfa.Moeda))]),_c('td',{staticClass:"text-right"},[(vfa.Moeda === 'USD')?[_vm._v(" "+_vm._s(_vm.$dollar(vfa.TotalDocumento))+" ")]:[_vm._v(" "+_vm._s(_vm.$money(vfa.TotalDocumento))+" ")]],2),_c('td',[_c(VBtn,{attrs:{"tile":"","small":"","color":"primary","to":'/purchases/VFAs/'+vfa.NumDoc}},[_vm._v(" Detalhe ")])],1)])}),0)])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }