<style scoped>
.staff_table th, .staff_table td {
    padding: 0px 2px;
}
</style>
<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <page-title
                        :title="$t('ordersSupplier')"
                ></page-title>
            </v-col>
            <v-col class="text-right">
                <file-upload
                        v-if="mode === 'import'"
                        :url='urlUploadFile'
                        btn-label="Importar"
                        :headers="headers"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        @success="onUploadSuccess"
                        @error="onUploadError"
                ></file-upload>
                <v-btn v-if="mode !== 'import' && mode !== 'supplier'" color="error" @click="reset()" small>Nova
                    Importação
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="3" v-if="mode === 'submit' || mode === 'analyze' || mode === 'supplier' || mode === 'import'">
                <v-autocomplete
                        v-model="fieldsSelected.supplier"
                        outlined
                        hide-details
                        :items="suppliers"
                        item-value="VALUE"
                        item-text="TEXT"
                        :label="$t('supplier')"
                        :disabled="disableSupplier"
                        dense
                        @change="selectSupplier"
                ></v-autocomplete>
            </v-col>
            <!--            <v-col cols="12" sm="6" md="3" v-if="mode === 'supplier'">-->
            <!--                <v-btn-->
            <!--                        color="primary"-->
            <!--                        @click="selectSupplier()"-->
            <!--                >-->
            <!--                    Selecionar Fornecedor-->
            <!--                </v-btn>-->
            <!--            </v-col>-->
            <v-col cols="12" sm="6" md="3" v-if="mode === 'submit'">
                <v-menu
                        v-model="menuDatePicker"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                                v-model="fieldsSelected.arrivalDate"
                                :label="$t('arrivalDate')"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                                dense
                                outlined
                                hide-details
                        ></v-text-field>
                    </template>
                    <v-date-picker v-model="fieldsSelected.arrivalDate" @input="menuDatePicker = false" :locale="locale"></v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="3" v-if="mode === 'submit'">
                <v-text-field
                        v-model="fieldsSelected.docExterno"
                        outlined
                        dense
                        hide-details
                        label="Num. Doc. Externo"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" v-if="mode === 'submit'">
                <v-btn color="primary" @click="selectExistProducts()">Selecionar Artigos Existentes</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="mode === 'submit'">
            <v-col>
                <span>Foram analisados {{ lines.length }} artigos. Existindo {{ totalMatch }} artigos no primavera. Não existindo {{
                        totalNoMatch
                    }} artigos no primavera.</span>
            </v-col>
        </v-row>
        <v-row v-if="mode === 'import'">
            <v-col>
                <v-expansion-panels focusable>
                    <v-expansion-panel
                            v-for="purchase in purchases"
                            :key="purchase.NumDoc"
                    >
                        <v-expansion-panel-header>
                            <v-row class="pa-0">
                                <v-col class="pa-0">
                                    Documento:<br><br>
                                    {{ purchase.Documento }}
                                </v-col>
                                <v-col class="pa-0">
                                    {{ $t('dateOfDocument') }}: <br><br>
                                    {{ purchase.DataDoc }}
                                </v-col>
                                <!--                                <v-col class="pa-0">-->
                                <!--                                    {{ $t('date') }} de Carga: <br><br>-->
                                <!--                                    {{ purchase.DataCarga }}-->
                                <!--                                </v-col>-->
                                <v-col class="pa-0">
                                    Documento Externo: <br><br>
                                    {{ purchase.NumDocExterno }}
                                </v-col>
                                <v-col class="pa-0">
                                    Total Documento: <br><br>
                                    <template v-if="purchase.Moeda === 'USD'">
                                        {{ $dollar(purchase.TotalDocumento) }}
                                    </template>
                                    <template v-else>
                                        {{ $money(purchase.TotalDocumento) }}
                                    </template>
                                </v-col>
                                <v-col class="pa-0">
                                    <v-btn
                                            color="error"
                                            tile
                                            @click="toggleCloseECF(purchase)"
                                            v-if="$writePermission('brands/importECF')"
                                    >Anular ECF
                                    </v-btn>
                                    <v-btn
                                            color="primary"
                                            tile
                                            class="ml-3"
                                            @click="createPick(purchase)"
                                            v-if="$writePermission('brands/importECF') && purchase.pickId === null"
                                    >
                                        Criar Pick
                                    </v-btn>
                                    <v-btn
                                            color="primary"
                                            tile
                                            class="ml-3"
                                            :to="'/orders/pick/'+purchase.pickId+'/view'"
                                            v-if="purchase.pickId !== null"
                                    >Ver Pick</v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-simple-table class="staff_table">
                                <thead>
                                <tr>
                                    <th>{{ $t('product') }}</th>
                                    <th>{{ $t('description') }}</th>
                                    <th>{{ $t('quantity') }}</th>
                                    <th>Anulado</th>
                                    <th>Data Entrega</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="line in linesPurchases" v-if="line.NumDoc === purchase.NumDoc && line.Serie === purchase.Serie" :class="line.Fechado ? 'red lighten-4' : ''">
                                    <td>{{ line.Artigo }}</td>
                                    <td>{{ line.Descricao }}</td>
                                    <td>{{ line.Quantidade }}</td>
                                    <td>{{ line.Fechado ? $t('yes') : $t('no') }}</td>
                                    <td>{{ line.DataEntrega }}</td>
                                </tr>
                                </tbody>
                            </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table class="staff_table" v-if="lines.length > 0">
                    <thead v-if="mode === 'submit'">
                    <tr>
                        <td>
                            <span style="cursor: pointer">
                                <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                                <i v-if="checkboxAll" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="unselectAll()"></i>
                            </span>
                        </td>
                        <td>{{ $t('product') }}</td>
                        <td>Ref. Fornecedor</td>
                        <td>{{ $t('quantity') }}</td>
                        <td>{{ $t('price') }}</td>
                        <td>{{ $t('arrivalDate') }}</td>
                        <td>Existe Primavera</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="mode === 'analyze'">
                        <td></td>
                        <td v-for="(col, index) in lines[0]">
                            <v-select
                                    outlined
                                    hide-details
                                    dense
                                    :items="optionsSelect"
                                    item-text="TEXT"
                                    item-value="VALUE"
                                    @change="selectField(index, $event)"
                            ></v-select>
                        </td>
                    </tr>
                    <tr v-for="(line, index) in lines" v-if="mode === 'analyze'">
                        <td class="text-center">
                            <span style="cursor: pointer">
                                <i v-if="lineSelected !== index" class="far fa-square fa-lg" @click="lineSelected = index"></i>
                                <i v-if="lineSelected === index" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="lineSelected = index"></i>
                            </span>
                        </td>
                        <td v-for="col in line">{{ col }}</td>
                    </tr>
                    <template v-for="line in lines" v-if="mode === 'submit'">
                        <!--                        <tr v-if="differentDate(line)">-->
                        <!--                            <td colspan="7" class="grey lighten-2 date_row">{{ line[5] }}</td>-->
                        <!--                        </tr>-->
                        <tr :class="{'red lighten-4': line[line.length - 1] === 'Não' || line[3] === '' || line[3] === 0}">
                            <td>
                                <span style="cursor: pointer" v-if="line[line.length - 1] === 'Sim'">
                                    <i v-if="line[0] == false" class="far fa-square fa-lg" @click="selectLine(line, true)"></i>
                                    <i v-if="line[0] == true" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="selectLine(line, false)"></i>
                                </span>
                            </td>
                            <td v-for="(col, index) in line" v-if="index > 0">{{ col }}</td>
                        </tr>
                    </template>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row v-if="lines.length > 0">
            <v-col class="text-right">
                <v-btn
                        v-if="mode === 'analyze'"
                        :disabled="validateBtnAnalyze"
                        color="warning"
                        @click="initAnalyze()"
                        :loading="loading">
                    Analisar
                </v-btn>
                <v-btn
                        v-if="mode === 'submit'"
                        color="success"
                        @click="submitECF()"
                        :disabled="validateBtnSubmit"
                >
                    Criar ECF
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialog.open" persistent max-width="500">
                <v-card>
                    <v-card-title>
                        ECF
                    </v-card-title>
                    <v-card-text class="text-center">
                        <i v-if="dialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                        <i v-if="!dialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                        <p class="headline">{{ dialog.message }}</p>
                        <v-alert v-if="dialog.alert" type="error">
                            {{ dialog.alertMessage }}
                        </v-alert>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="success" @click="closeDialog()" style="margin-left: auto">
                            OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogClosePurchase" persistent max-width="500">
                <v-card>
                    <v-toolbar class="mb-3" flat dense dark color="red">
                        <v-toolbar-title class="text-uppercase">Anular ECF</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        Deseja anular o documento: <strong>{{ purchaseToClose.Documento }}</strong> ?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogClosePurchase = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="closeECF()">Anular</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import FileUpload from 'v-file-upload'
import i18n from "../../plugins/i18n";

export default {
    components: {
        FileUpload
    },
    data: function () {
        return {
            loading: false,
            checkboxAll: false,
            urlUploadFile: '',
            headers: {},
            suppliers: [],
            lines: [],
            lineSelected: '',
            camps: {},
            optionsSelect: [
                {TEXT: '', VALUE: ''},
                {TEXT: 'Artigo', VALUE: 'product'},
                {TEXT: 'EAN', VALUE: 'ean'},
                {TEXT: 'Quantidade', VALUE: 'qty'},
                {TEXT: 'Preço', VALUE: 'price'},
                {TEXT: 'Data Saída', VALUE: 'exitDate'}
            ],
            fieldsSelected: {
                product: '',
                ean: '',
                qty: '',
                price: '',
                exitDate: '',
                arrivalDate: '',
                supplier: '',
                docExterno: ''
            },
            mode: 'supplier',
            filename: '',
            menu2: false,
            dialog: {
                open: false,
                message: '',
                error: '',
                alert: false,
                alertMessage: ''
            },
            disableSupplier: false,
            purchases: [],
            linesPurchases: [],
            dialogClosePurchase: false,
            purchaseToClose: {},
            menuDatePicker: false,
            currentDate: ''
        }
    },
    created() {
        document.title = i18n.t('ordersSupplier');
        this.currentDate = '';
        this.urlUploadFile = process.env.VUE_APP_API_URL + api.brandsImportOrdersECF();
        this.headers = {'Authorization': 'Bearer ' + User.getters.getAccessToken};
        this.getSuppliers()
    },
    methods: {
        getSuppliers() {
            let request = {
                url: api.brandsImportECFSuppliers()
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.suppliers = responseData.suppliers
                        }
                    })
                    .catch(response => {

                    })
        },
        onUploadSuccess(response) {
            this.resetValues();
            this.lineSelected = '';
            this.disableSupplier = true;
            let responseData = response.target.response;
            if (responseData.status === 'OK') {
                this.lines = responseData.lines
                this.filename = responseData.filename
            }
        },
        onUploadError() {
            console.log('error');
        },
        selectField(index, event) {
            this.currentDate = '';
            if (event === 'product') {
                this.fieldsSelected.product = index
            } else if (event === 'qty') {
                this.fieldsSelected.qty = index
            } else if (event === 'price') {
                this.fieldsSelected.price = index
            } else if (event === 'exitDate') {
                this.fieldsSelected.exitDate = index
            } else if (event === 'arrivalDate') {
                this.fieldsSelected.arrivalDate = index
            } else if (event === 'ean') {
                this.fieldsSelected.ean = index;
            }
        },
        resetValues() {
            this.currentDate = '';
            this.mode = 'analyze';
            this.fieldsSelected = {
                product: '',
                ean: '',
                qty: '',
                price: '',
                exitDate: '',
                arrivalDate: '',
                supplier: this.fieldsSelected.supplier,
                docExterno: ''
            };
            this.lines = [];
        },
        initAnalyze() {
            this.currentDate = ''
            this.loading = true
            let request = {
                url: api.brandsImportECFAnalyze(),
                data: {
                    filename: this.filename,
                    fieldsSelected: this.fieldsSelected,
                    lineSelected: this.lineSelected
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.lines = responseData.lines
                            this.mode = 'submit'
                        }
                        this.loading = false
                    })
                    .catch(response => {
                        this.loading = false
                    })
        },
        submitECF() {
            this.currentDate = '';

            let loader = this.$loading.show();

            let request = {
                url: api.brandsSubmitECF(),
                data: {
                    lines: this.lines,
                    fields: this.fieldsSelected
                }
            };

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        loader.hide();
                        if (responseData.status === 'OK') {
                            this.lines = [];
                            this.mode = 'finish';
                            this.dialog.open = true;
                            this.dialog.message = 'ECF nº ' + responseData.documento.NumDoc + ' efetuada com sucesso!';
                            this.dialog.error = false;
                        } else {
                            this.dialog.open = true;
                            this.dialog.message = 'Erro ao efetuar ECF!';
                            this.dialog.error = true;
                            this.dialog.alert = true;
                            this.dialog.alertMessage = responseData.message;
                        }
                    })
                    .catch(response => {
                        loader.hide();
                        this.dialog.open = true;
                        this.dialog.message = 'Erro ao efetuar ECF!';
                        this.dialog.error = true;
                        this.dialog.alert = false;
                    })
        },
        selectSupplier() {
            this.currentDate = '';
            this.mode = 'import';
            this.getSupplierECF();
        },
        getSupplierECF() {
            this.currentDate = '';
            let request = {
                url: '/api/staff/brands/importECF/suppliers/' + this.fieldsSelected.supplier + '/ecf'
            };

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.purchases = responseData.purchases;
                            this.linesPurchases = responseData.linesPurchases;
                        }
                    })
                    .catch(response => {

                    })
        },
        toggleCloseECF(purchase) {
            this.currentDate = '';
            this.purchaseToClose = purchase;
            this.dialogClosePurchase = true;
        },
        closeECF() {
            this.currentDate = '';
            let request = {
                url: '/api/staff/brands/importECF/suppliers/' + this.fieldsSelected.supplier + '/closeECF',
                data: {
                    purchase: this.purchaseToClose
                }
            };

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'ECF anulada com sucesso!',
                                type: 'success'
                            });
                            this.getSupplierECF();
                            this.dialogClosePurchase = false;
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao anular ECF!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        selectAll() {
            this.currentDate = '';
            this.checkboxAll = true;
            this.lines.forEach(function (line) {
                if (line[6] === 'Sim') {
                    line[0] = true;
                }
            });
        },
        unselectAll() {
            this.currentDate = '';
            this.checkboxAll = false;
            this.lines.forEach(function (line) {
                line[0] = false;
            });
        },
        selectLine(line, option) {
            this.currentDate = '';
            line[0] = option;
            this.$forceUpdate();
        },
        differentDate(line) {
            if (line[5] !== this.currentDate && line[5] !== '') {
                this.currentDate = line[5];
                return true
            }
            return false
        },
        selectExistProducts() {
            this.lines.forEach(function (line) {
                if (line[6] === 'Sim' && line[3] > 0) {
                    line[0] = true;
                }
            });
            this.$forceUpdate();
        },
        reset() {
            location.reload();
        },
        closeDialog() {
            this.dialog.open = false;
            if (!this.dialog.error) {
                location.reload();
            }
        },
        createPick(purchase) {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/brands/importECF/createPick',
                data: {
                    purchase: purchase
                }
            }

            ApiWS.dispatch('makePost', request)
            .then(response => {
                let responseData = response.data;
                loader.hide();
            })
            .catch(() => {
                loader.hide();
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
            })
        }
    },
    computed: {
        validateBtnAnalyze() {
            this.currentDate = '';
            return this.lineSelected === '' || (this.fieldsSelected.product === '' && this.fieldsSelected.ean === '') || this.fieldsSelected.qty === ''
                    || this.fieldsSelected.supplier === '' || this.loading === true
        },
        validateBtnSubmit() {
            this.currentDate = '';
            return this.lineSelected === '' || (this.fieldsSelected.product === '' && this.fieldsSelected.ean === '') || this.fieldsSelected.qty === ''
                    || this.fieldsSelected.arrivalDate === '' || this.fieldsSelected.supplier === '' || this.loading === true
        },
        totalMatch() {
            this.currentDate = '';
            let count = 0
            for (let i = 0; i < this.lines.length; i++) {
                if (this.lines[i][6] === 'Sim') {
                    count++
                }
            }
            return count
        },
        totalNoMatch() {
            this.currentDate = '';
            let count = 0
            for (let i = 0; i < this.lines.length; i++) {
                if (this.lines[i][6] === 'Não') {
                    count++
                }
            }
            return count
        },
        locale() {
            this.currentDate = '';
            return (User.getters.getLanguage + '-' + User.getters.getLanguage).toLocaleLowerCase();
        }
    }
}
</script>
