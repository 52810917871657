<template>
    <v-dialog v-model="open" persistent max-width="400">
        <v-card>
            <v-card-title class="text-capitalize">
                {{ title }}
            </v-card-title>
            <v-card-text>
                <div class="text-center">
                    <v-progress-circular
                            v-if="loading"
                            indeterminate
                            color="primary"
                            size="100"
                    ></v-progress-circular>
                    <div v-if="!loading">
                        <i v-if="error" style="color: red" class="fas fa-times fa-5x"></i>
                        <i v-if="!error" style="color: green" class="fas fa-check fa-5x"></i>
                        <p class="headline">{{ message }}</p>
                        <v-alert v-if="alert" type="error">
                            {{ alertMessage }}
                        </v-alert>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions class="pa-3">
                <v-spacer></v-spacer>
                <v-btn v-if="!loading" color="success" @click="openComputed = false">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'response-dialog',
        props: {
            open: Boolean,
            loading: Boolean,
            error: Boolean,
            alert: Boolean,
            title: String,
            message: String,
            alertMessage: String
        },
        data() {
            return {
                openLocal: this.open
            }
        },
        computed: {
            openComputed: {
                set(value) {
                    console.log('computed - openComputed - set');
                    this.openLocal = value;
                },
                get() {
                    console.log('computed - openComputed - get');
                    return this.open
                }
                // set(value) {
                //     console.log(value);
                //     this.openLocal = false
                // },
                // get() {
                //     return this.open
                // }
            },
        },
        // methods: {
        //     closeDialog() {
        //         this.$emit('closeDialog')
        //     }
        // }
        watch: {
            open(value) {
                console.log(value);
                this.openLocal = value
            }
        }
    }
</script>
