<style scoped>
.back_btn {
    float: left;
}

.staff_subtitle {
    margin-bottom: 0 !important;
    margin-top: -6px;
    font-size: 14px;
    color: gray;
}

.row-last-pick td {
    border-top: solid 2px black !important;
    border-bottom: solid 2px black !important;
}

.row-last-pick td:last-child {
    border-right: solid 2px black !important;
}

.row-last-pick td:first-child {
    border-left: solid 2px black !important;
}

.row-yellow td,
.row-yellow th {
    background-color: rgba(255, 255, 0, 0.25);
}

.row-green td,
.row-green th {
    background-color: rgba(0, 255, 25, 0.40);
}

.pick-table tr td {
    line-height: 28px;
    font-size: 11px !important;
}

.pick-table tr:first-child td {
    font-size: 11px !important;
    border-top: solid 2px black !important;
    border-bottom: solid 2px black !important;
}

.pick-table tr:first-child td:last-child {
    border-right: solid 2px black !important;
}

.pick-table tr:first-child td:first-child {
    border-left: solid 2px black !important;
}


.pick-barcode {
    background-color: #E3F2FD;
    font-size: 20px;
    padding: 8px 16px;
    width: 100%;
    border: 1px solid #000000;
}

</style>
<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <div style="min-height: 44px">
                    <v-btn tile small color="default" class="back_btn mt-2 mr-3"
                           @click="$router.push('/orders/pick')" v-if="pickOrder.tipoCliente !== 'Compras'">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <v-btn tile small color="default" class="back_btn mt-2 mr-3"
                           @click="$router.go(-1)" v-if="pickOrder.tipoCliente === 'Compras'">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <h6 class="title text-uppercase" style="min-height: 32px">{{ 'Picking ' + pickOrderId }}</h6>
                    <p class="staff_subtitle">{{ pickOrder.cliente + ' - ' + pickOrder.nome }}</p>
                </div>
            </v-col>
            <v-col>
                <input type="text" ref="barcode" class="pick-barcode" @keypress.enter="pickBarcode">
            </v-col>
            <v-col class="text-right">
                <v-btn tile small @click="print">Imprimir</v-btn>
                <v-btn tile small class="ml-2" color="error" @click="dialogResetPick = true">Limpar Picking</v-btn>
            </v-col>
        </v-row>

        <!-- CONTENT -->
        <v-row>

            <!-- ÚLTIMO ARTIGO PICADO -->
            <v-col>
                <v-card tile min-height="212px">
                    <v-card-title class="pt-2 pb-0">
                        <p class="subtitle-1 mb-0 black--text">ARTIGO PICADO</p>
                        <v-spacer></v-spacer>
                        <v-btn tile small class="mr-2" v-if="lastProductPick.product !== ''" @click="openDialogBoxQuantity()">
                            CAIXA
                        </v-btn>
                        <v-btn tile small v-if="lastProductPick.product !== ''" @click="addSerialNumberLastProduct()">
                            Num. Série
                        </v-btn>
                    </v-card-title>
                    <v-container>
                        <v-simple-table dense>
                            <tr>
                                <td>
                                    <v-img height="140" width="140" :src="lastProductPick.img"
                                           v-if="lastProductPick.img"></v-img>
                                </td>
                                <td class="text-center">
                                    <p class="font-weight-bold">{{ lastProductPick.product }}</p>
                                    <small>{{ lastProductPick.description }}</small>
                                </td>
                            </tr>
                        </v-simple-table>
                    </v-container>
                </v-card>
            </v-col>

            <!-- LISTA ULTIMOS ARTIGOS -->
            <v-col>
                <v-card tile min-height="212px">
                    <v-card-title class="pt-2 pb-0">
                        <p class="subtitle-1 mb-0 black--text">ÚLTIMOS PICKS</p>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table pick-table">
                            <tbody>
                            <tr v-for="product in lastProductsPick">
                                <td>{{ product.product }}</td>
                                <td>{{ product.description }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>

            <!-- TOTAIS ARTIGOS -->
            <v-col>
                <v-card tile min-height="212px">
                    <v-card-title class="pt-2 pb-0">
                        <p class="subtitle-1 mb-0 black--text">TOTAIS</p>
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <tbody>
                            <tr>
                                <td class="text-center">Por Picar</td>
                                <td class="text-center">Pistola</td>
                                <td class="text-center">Manual</td>
                                <th class="text-center">TOTAL</th>
                            </tr>
                            <tr>
                                <td class="text-center">{{ totalProductsNotPick }}</td>
                                <td class="text-center">{{ totalProductsPick }}</td>
                                <td class="text-center">{{ totalProductsPickManual }}</td>
                                <th class="text-center">{{ totalProductsPick + totalProductsPickManual }}</th>
                            </tr>
                            </tbody>
                        </v-simple-table>
                        <div class="text-center mt-9">
                            <v-chip large color="success" class="px-8" v-if="pickCompleted">PICKING COMPLETO</v-chip>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>

        </v-row>

        <v-row>
            <v-col>
                <v-card tile>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th>{{ $t('product') }}</th>
                                <th>Cód.Barras</th>
                                <th>Cód.Barras CX</th>
                                <th>EAN-13</th>
                                <th>{{ $t('description') }}</th>
                                <th>Num. Série</th>
                                <th>Lote</th>
                                <th class="text-center">Stock A1</th>
                                <th class="text-center">Stock</th>
                                <th class="text-center">{{ $t('quantity') }}</th>
                                <th class="text-center">Uni</th>
                                <th class="text-center">Pick</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="note in notes">
                                <td colspan="4"></td>
                                <td>{{ note.descricao }}</td>
                                <td colspan="8"></td>
                            </tr>
                            <tr v-for="product in products"
                                :class="{ 'row-last-pick' : product.id == lastPick,
                                         'row-yellow' : product.codBarrasPick + product.manualPick > 0,
                                         'row-green' : product.quantity === product.codBarrasPick + product.manualPick
                                        }">
                                <td>
                                    {{ product.artigo }}
                                    <v-btn icon small :href="'/products/products/'+product.artigo" target="_blank"
                                           color="blue darken-2">
                                        <v-icon small>launch</v-icon>
                                    </v-btn>
                                </td>
                                <td>
                                    <v-btn icon small v-if="product.tipoLinha === 10"
                                           @click="toggleDialogUpdateBarcode(product)">
                                        <v-icon small>add</v-icon>
                                    </v-btn>
                                    <v-btn icon small v-if="product.tipoLinha === 10"
                                           @click="dialogPrintEanOpen(product.artigo)">
                                        <v-icon small>print</v-icon>
                                    </v-btn>
                                    {{ product.codBarras }}
                                </td>
                                <td>{{ product.boxBarcode }}</td>
                                <td><div>
                                    <v-btn  icon small :disabled="product.CDU_EAN == null"
                                            @click="printLabel(product.artigo, 'ean')">
                                        <v-icon small>print</v-icon>
                                    </v-btn>
                                    <v-btn  icon small :disabled="product.CDU_EAN == null"
                                            @click="printLabel(product.artigo, 'ean', 'big')">
                                        <v-icon medium>print</v-icon>
                                    </v-btn>
                                    <strong>EAN-13: </strong>{{ product.CDU_EAN }}</div></td>
                                <td>{{ product.descricao }}</td>
                                <td>
                                    {{ product.numSerie.join(', ') }}
                                    <v-btn icon small v-if="product.tipoLinha === 10"
                                           @click="toggleDialogAddSerialNumber(product)">
                                        <v-icon small>add</v-icon>
                                    </v-btn>
                                </td>
                                <td>
                                    {{ product.lote }}
                                    <v-btn icon small v-if="product.tipoLinha === 10" @click="toggleDialogLote(product)">
                                        <v-icon small>add</v-icon>
                                    </v-btn>
                                    <i v-if="product.Lotes && product.lote == null" class="fas fa-times" style="color: red"></i>
                                </td>
                                <td class="text-center" @click="getStockProduct(product.artigo)"
                                    style="cursor: pointer">
                                    {{ product.stock }}
                                </td>
                                <td class="text-center" @click="getStockProduct(product.artigo)"
                                    style="cursor: pointer">
                                    {{ Math.round(product.stockTotal) }}
                                </td>
                                <td class="text-center">{{ product.quantity }}</td>
                                <td class="text-center">{{ product.unidade }}</td>
                                <td class="text-center">
                                    <v-btn outlined small @click="toggleDialogPickManual(product)">
                                        {{ product.codBarrasPick + product.manualPick }}
                                    </v-btn>
                                </td>
                                <td class="text-center">
                                    <v-btn icon small @click="toggleDialogResetPick(product)">
                                        <v-icon small>event_busy</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile v-if="cabecDoc.tipoDocPri !== 'ECN' && cabecDoc.tipoDocPri !== 'ECF'"
                               :disabled="validateForm > 0" :color="pickCompleted ? 'success' : ''"
                               @click="closePick">Finalizar
                        </v-btn>
                        <v-btn tile v-if="cabecDoc.tipoDocPri === 'ECN'" @click="confirmPickECN">Confirmar</v-btn>
                        <v-btn tile v-if="cabecDoc.tipoDocPri === 'ECF'" @click="confirmPickECF">Confirmar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- IMPRIMIR -->
        <v-row style="display: none">
            <v-col>
                <div id="printMe">
                    <table style="border: 1px solid gray; border-collapse: collapse; width: 100%">
                        <thead>
                        <!--                        <span class="subtitle-1" style="color:white">{{ client.Entidade }} - {{ client.Nome }}</span>-->
                        <tr>
                            <th colspan="2" style="padding: 30px 10px; border: 1px solid gray; font-size: 24px;">
                                {{ pickOrderId }}
                            </th>
                            <th colspan="8"
                                style="padding: 30px 10px; border: 1px solid gray; font-size: 24px; text-align: right">
                                {{ pickOrder.cliente }}<br>
                                {{ pickOrder.nome }}
                            </th>
                        </tr>
                        <tr>
                            <th style="border: 1px solid gray; text-align: center">Stock A1</th>
                            <th style="border: 1px solid gray; text-align: center">Stock</th>
                            <th style="border: 1px solid gray">{{ $t('product') }}</th>
                            <th style="border: 1px solid gray">CodBarras</th>
                            <th style="border: 1px solid gray">CodBarras CX</th>
                            <th style="border: 1px solid gray; text-align: center">Qty</th>
                            <th style="border: 1px solid gray">{{ $t('description') }}</th>
                            <th style="border: 1px solid gray">Uni</th>
                            <th style="border: 1px solid gray">Lote</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="order in products">
                            <tr>
                                <td style="border: 1px solid gray; text-align: center; font-size: 10px">
                                    {{ order.stock === 0 ? '' : order.stock }}
                                </td>
                                <td style="border: 1px solid gray; text-align: center; font-size: 10px">
                                    {{ Math.round(order.stockTotal) === 0 ? '' : Math.round(order.stockTotal) }}
                                </td>
                                <td style="border: 1px solid gray; font-size: 10px">{{ order.artigo }}</td>
                                <td style="border: 1px solid gray; font-size: 10px">{{ order.codBarras }}</td>
                                <td style="border: 1px solid gray; font-size: 10px">{{ order.boxBarcode }}</td>
                                <td style="border: 1px solid gray; text-align: center; font-size: 10px">
                                    {{ order.quantity }}
                                </td>
                                <td style="border: 1px solid gray; font-size: 10px">{{ order.descricao }}</td>
                                <td style="border: 1px solid gray">{{ order.unidade }}</td>
                                <td style="border: 1px solid gray">
                                    <v-simple-table class="staff_table" v-if="order.Lotes">
                                        <tbody>
                                        <tr v-for="lote in order.Lotes">
                                            <td style="border: 1px solid gray">{{ lote.Lote }}</td>
                                            <td style="border: 1px solid gray">{{ lote.Validade }}</td>
                                            <td style="border: 1px solid gray">{{ lote.Stock }}</td>
                                        </tr>
                                        </tbody>
                                    </v-simple-table>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>

                    <!--                    <table style="border: 1px solid gray; border-collapse: collapse; width: 100%">-->
                    <!--                        <thead>-->
                    <!--                        <tr>-->
                    <!--                            <th style="border: 1px solid gray; text-align: center">Stock A1</th>-->
                    <!--                            <th style="border: 1px solid gray; text-align: center">Stock</th>-->
                    <!--                            <th style="border: 1px solid gray">{{ $t('product') }}</th>-->
                    <!--                            <th style="border: 1px solid gray">CodBarras</th>-->
                    <!--                            <th style="border: 1px solid gray; text-align: center">Quantidade</th>-->
                    <!--                            <th style="border: 1px solid gray">{{ $t('description') }}</th>-->
                    <!--                        </tr>-->
                    <!--                        </thead>-->
                    <!--                        <tbody>-->
                    <!--                        <template v-for="order in products">-->
                    <!--                            <tr>-->
                    <!--                                <td style="border: 1px solid gray; text-align: center">-->
                    <!--                                    {{ order.stock === 0 ? '' : order.stock }}-->
                    <!--                                </td>-->
                    <!--                                <td style="border: 1px solid gray; text-align: center">-->
                    <!--                                    {{ Math.round(order.stockTotal) === 0 ? '' : Math.round(order.stockTotal) }}-->
                    <!--                                </td>-->
                    <!--                                <td style="border: 1px solid gray">{{ order.artigo }}</td>-->
                    <!--                                <td style="border: 1px solid gray">{{ order.codBarras }}</td>-->
                    <!--                                <td style="border: 1px solid gray; text-align: center">{{ order.quantity }}</td>-->
                    <!--                                <td style="border: 1px solid gray">{{ order.descricao }}</td>-->
                    <!--                            </tr>-->
                    <!--                        </template>-->
                    <!--                        </tbody>-->
                    <!--                    </table>-->
                </div>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row justify="center">

            <!-- RESET PICK-->
            <v-dialog v-model="dialogResetPick" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Limpar Picking
                    </v-card-title>
                    <v-card-text>
                        Deseja reiniciar o picking?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="closeResetDialog">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="error" @click="closeResetConfirm()">Limpar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogResetPickProduct" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Limpar Picking do artigo
                    </v-card-title>
                    <v-card-text>
                        Deseja reiniciar o picking do artigo?<br>
                        <strong>{{ inputResetPick.product }}</strong>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="resetProductCancel()">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="error" @click="closeResetProductConfirm()">Limpar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogClosePick" persistent max-width="600">
                <v-card>
                    <v-card-title>Fechar Picking</v-card-title>
                    <v-card-text>
                        <p class="ma-0"><strong>Quantidade Caixas: </strong></p>
                        <v-row>
                            <v-col md="10">
                                <v-btn-toggle v-model="inputClosePick.volumes">
                                    <v-btn value="1">1</v-btn>
                                    <v-btn value="2">2</v-btn>
                                    <v-btn value="3">3</v-btn>
                                    <v-btn value="4">4</v-btn>
                                    <v-btn value="5">5</v-btn>
                                    <v-btn value="6">6</v-btn>
                                    <v-btn value="7">7</v-btn>
                                    <v-btn value="8">8</v-btn>
                                    <v-btn value="9">9</v-btn>
                                    <v-btn value="10">10</v-btn>
                                </v-btn-toggle>
                            </v-col>
                            <v-col md="2">
                                <v-text-field dense v-model="inputClosePick.volumes" outlined single-line
                                              style="width: 50px; margin-left: auto"></v-text-field>
                            </v-col>
                        </v-row>
                        <p class="ma-0"><strong>Peso: </strong></p>
                        <v-row>
                            <v-col md="10">
                                <v-btn-toggle v-model="inputClosePick.weight">
                                    <v-btn value="1">1</v-btn>
                                    <v-btn value="2">2</v-btn>
                                    <v-btn value="3">3</v-btn>
                                    <v-btn value="4">4</v-btn>
                                    <v-btn value="5">5</v-btn>
                                    <v-btn value="6">6</v-btn>
                                    <v-btn value="7">7</v-btn>
                                    <v-btn value="8">8</v-btn>
                                    <v-btn value="9">9</v-btn>
                                    <v-btn value="10">10</v-btn>
                                </v-btn-toggle>
                            </v-col>
                            <v-col md="2">
                                <v-text-field dense v-model="inputClosePick.weight" outlined single-line
                                              style="width: 50px; margin-left: auto"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="closePickCancel">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="closePickConfirm" :disabled="validBtnClosePick" color="success">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPickManual" persistent max-width="300">
                <v-card>
                    <v-card-title>Picking Manual</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="inputPickManual.quantity"
                                label="Quantidade"
                                dense
                                hide-details
                                outlined
                                ref="inputPick"
                                @focus="$event.target.select()"
                                @keypress.enter="validatePickManual"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="cancelPickManual">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="validatePickManual" :disabled="inputPickManual.quantity === ''"
                               color="success">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogBoxQuantity" persistent max-width="300">
                <v-card>
                    <v-card-title>Quantidade da Caixa</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="inputPickManual.quantity"
                                label="Quantidade"
                                dense
                                hide-details
                                outlined
                                ref="inputBox"
                                @keypress.enter="confirmDialogBoxQuantity"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="cancelDialogBoxQuantity">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="confirmDialogBoxQuantity" :disabled="inputPickManual.quantity === ''"
                               color="success">
                            Guardar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogProductNotExist" persistent max-width="400">
                <v-card>
                    <v-card-title>Artigo inexistente no picking!</v-card-title>
                    <v-card-text>
                        <v-row justify="space-between">
                            <v-col>
                                <v-img
                                        height="150"
                                        width="150"
                                        :src="inputProductNotExist.img"
                                        v-if="inputProductNotExist.img"
                                ></v-img>
                            </v-col>

                            <v-col class="text-center pl-0">
                                <v-row
                                        class="flex-column ma-0 fill-height"
                                        justify="center"
                                >
                                    <v-col class="px-0">
                                        <strong>{{ inputProductNotExist.product }}</strong>
                                    </v-col>

                                    <v-col class="px-0">
                                        {{ inputProductNotExist.description }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="dialogProductNotExist = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogEanInvalid" persistent max-width="400">
                <v-card>
                    <v-card-title>Artigo com ean inválido!</v-card-title>
                    <v-card-text>
                        O artigo com o código de barras {{ inputProductNotExist.barcode }} não existe no primavera.
                        <p class="mb-0"><strong>Deseja atualizar o ean do artigo?</strong></p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="closeDialogChooseProduct">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="toggleDialogChooseProduct" color="error">Atualizar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogProducts" persistent max-width="800">
                <v-card>
                    <v-card-title>Artigos</v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table" height="600px">
                            <thead>
                            <tr>
                                <th>Artigo</th>
                                <th>{{ $t('description') }}</th>
                                <th>Marca</th>
                                <th>Familia</th>
                            </tr>
                            <tr>
                                <td>
                                    <v-text-field
                                            v-model="filtersProducts.product"
                                            outlined
                                            dense
                                            hide-details
                                            @keypress.enter="updateFiltersProducts"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersProducts.description"
                                            outlined
                                            dense
                                            hide-details
                                            @keypress.enter="updateFiltersProducts"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersProducts.brand"
                                            outlined
                                            dense
                                            hide-details
                                            @keypress.enter="updateFiltersProducts"
                                    ></v-text-field>
                                </td>
                                <td>
                                    <v-text-field
                                            v-model="filtersProducts.family"
                                            outlined
                                            dense
                                            hide-details
                                            @keypress.enter="updateFiltersProducts"
                                    ></v-text-field>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="product in productsPri" @click="productSelect = product.Artigo"
                                :class="productSelect === product.Artigo ? 'grey lighten-1' : ''">
                                <td>{{ product.Artigo }}</td>
                                <td>{{ product.Descricao }}</td>
                                <td>{{ product.Marca }}</td>
                                <td>{{ product.Familia }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogProducts = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="updateEanProduct()" :loading="loadingBtnUpdProduct">
                            Atualizar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogAddSerialNumber" persistent max-width="400">
                <v-card>
                    <v-card-title>Adicionar Num. Série</v-card-title>
                    <v-card-text>
                        <v-simple-table class="staff_table">
                            <tr v-for="serie in editProduct.numSerie">
                                <td>{{ serie }}</td>
                                <td class="text-right">
                                    <i class="fas fa-lg fa-times" style="color: red; cursor: pointer;"
                                       @click="deleteNumSerie(serie)"></i>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <v-text-field
                                            v-model="inputNumSerie"
                                            outlined
                                            hide-details
                                            dense
                                            append-outer-icon="add"
                                            @click:append-outer="addSerialNumber()"
                                    ></v-text-field>
                                </td>
                            </tr>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="cancelDialogSerialNumber()">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="success" @click="saveSerialNumber()">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-btn tile small v-if="!responseDialog.loading" @click="closeResponseDialog()">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small v-if="responseDialog.quantity" color="success" class="ml-3"
                               @click="addQuantityToProduct()">
                            Adicionar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- ORDER STOCKS -->
            <v-dialog v-model="dialogStocks" persistent max-width="400">
                <v-card>
                    <v-toolbar dense dark color="info">
                        <v-toolbar-title>ARTIGO - {{ currentProduct }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogStocks = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row v-if="loadingDetail">
                            <v-col class="text-center">
                                <v-progress-circular
                                        class="ma-12"
                                        :size="100"
                                        :width="2"
                                        color="blue darken-4"
                                        indeterminate
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                        <v-row v-if="!loadingDetail">
                            <v-col>
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th>Armazém</th>
                                            <th>Descricao</th>
                                            <th class="text-right">Stock</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="warehouse in warehouses">
                                            <td>{{ warehouse.Armazem }}</td>
                                            <td>{{ warehouse.Descricao }}</td>
                                            <td class="text-right">{{ Math.round(warehouse.STKActual) }}</td>
                                        </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn tile small @click="closeDialogStocks()">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogBicimaxCom" persistent max-width="410">
                <v-card>
                    <v-card-title>Impossivel continuar</v-card-title>
                    <v-card-text>
                        <p>As encomendas com origem em bicimax.com têm de ser picadas por completo para continuar</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile @click="dialogBicimaxCom = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogUpdateEan" persistent max-width="410">
                <v-card>
                    <v-card-title>Atualizar Cód. Barras</v-card-title>
                    <v-card-text>
                        <p>Atualizar o Código de Barras do Artigo {{ productToUpdate.product }}</p>
                        <p>Código de Barras antigo: {{ productToUpdate.oldBarcode }}</p>
                        <v-text-field v-model="productToUpdate.barcode" label="Cód. Barras" dense outlined hide-details
                                      ref="inputBarcodeDialog"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile @click="closeUpdateBarcodeProduct">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile color="success" @click="updateBarcodeProduct">Atualizar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPrintEan" persistent max-width="400">
                <v-card>
                    <v-card-title>Imprimir Etiqueta do Artigo <br> {{ inputPrintEan.product }}</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-btn tile block :color="inputPrintEan.option === 'barcode' ? 'success' : ''"
                                       @click="inputPrintEan.option = 'barcode'">Código de Barras
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-btn tile block :color="inputPrintEan.option === 'EAN' ? 'success' : ''"
                                       @click="inputPrintEan.option = 'EAN'">EAN
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-text-field
                                v-model="inputPrintEan.quantity"
                                outlined
                                dense
                                hide-details
                                label="Quantidade"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile @click="dialogPrintEanClose">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile color="success" @click="dialogPrintEanConfirm">Imprimir</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogSelectLote" persistent max-width="400">
                <v-card>
                    <v-card-title>Selecionar lote {{ editProductLote.artigo }}</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="editProductLote.lote"
                                        :items="editProductLote.Lotes"
                                        item-value="Lote"
                                        item-text="Lote"
                                        hide-details
                                        dense
                                        outlined
                                        label="Lote"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile @click="dialogSelectLoteClose">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile color="success" @click="dialogSelectLoteConfirm">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogPrint" width="400">
                <v-card>
                    <v-toolbar dense dark color="info">
                        <v-toolbar-title class="text-uppercase">{{ $t('quantity') }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="dialogPrint = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row class="mt-4">
                            <v-col offset="4" cols="3">
                                <v-text-field
                                        v-model.number="labelQuantity"
                                        hide-details
                                        type="number"
                                        min="1"
                                        max="5000"
                                        label="Quantidade"
                                        @change="limitNumber()"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small @click="dialogPrint=false">
                            Cancelar
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small color="primary" @click="printLabelCall()">
                            Imprimir
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </v-container>
</template>

<script>

export default {
    data: function () {
        return {
            labelOption: null,
            labelProduct: null,
            labelSize: null,
            labelQuantity: 1,
            dialogPrint: false,
            pickOrderId: '',
            pickOrder: {},
            bicimaxCom: false,
            shippingAddress: [],
            products: [],
            barcode: '',
            lastPick: '',
            dialogResetPick: false,
            dialogBicimaxCom: false,
            dialogResetPickProduct: false,
            dialogClosePick: false,
            dialogPickManual: false,
            dialogProductNotExist: false,
            dialogEanInvalid: false,
            dialogProducts: false,
            dialogBoxQuantity: false,
            dialogSelectLote: false,
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                quantity: false,
                title: '',
                message: '',
                submessage: ''
            },
            inputClosePick: {
                volumes: '',
                weight: ''
            },
            stock: {},
            lastProductPick: {
                product: '',
                description: '',
                img: ''
            },
            notes: [],
            lastProductsPick: [],
            totalProductsByReference: 0,
            inputPickManual: {
                id: '',
                idLine: '',
                product: '',
                description: '',
                quantity: '',
                barcodePick: '',
                orderQuantity: ''
            },
            inputResetPick: {
                idLine: '',
                product: ''
            },
            inputProductNotExist: {
                barcode: '',
                product: '',
                description: '',
                img: ''
            },
            productsPri: [],
            productsPriOriginal: [],
            filtersProducts: {
                product: '',
                description: '',
                brand: '',
                family: ''
            },
            productSelect: '',
            loadingBtnUpdProduct: false,
            cabecDoc: {},
            closeMode: '',
            editProduct: {},
            dialogAddSerialNumber: false,
            inputNumSerie: '',
            dialogStocks: false,
            loadingDetail: false,
            currentProduct: '',
            warehouses: [],
            dialogUpdateEan: false,
            productToUpdate: {
                product: '',
                barcode: '',
                oldBarcode: ''
            },
            dialogPrintEan: false,
            inputPrintEan: {
                product: '',
                option: '',
                quantity: 1
            },
            idLinePriToUpdate: '',
            editProductLote: {}
        }
    },
    created() {
        this.pickOrderId = this.$route.params.id;
        document.title = 'Picking - ' + this.pickOrderId + ' | STAFF';
        this.getPickOrder();
    },
    methods: {
        getPickOrder() {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/pick/' + this.pickOrderId
            }
            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.bicimaxCom = responseData.bicimaxCom;
                        this.pickOrder = responseData.pickOrder;
                        this.shippingAddress = responseData.shippingAddress;
                        this.products = responseData.products;
                        this.cabecDoc = responseData.products[0];
                        this.totalProductsByReference = responseData.totalProductsByReference;
                        this.notes = responseData.notes;
                        this.$nextTick(() => this.$refs.barcode.focus());
                    } else if (responseData.status === 'WAR') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    }
                    loader.hide();
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                    loader.hide();
                })
        },
        pickBarcode() {
            let request = {
                url: '/api/staff/pick/barcode',
                data: {
                    id: this.pickOrderId,
                    barcode: this.$refs.barcode.value
                }
            }
            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.$refs.barcode.value = '';
                        this.products = responseData.products;
                        this.lastPick = responseData.lastPick;
                        this.lastProductPick = responseData.lastProductPick;
                        if (this.lastProductsPick.length >= 5) {
                            this.lastProductsPick.pop();
                        }
                        this.lastProductsPick.unshift({
                            product: this.lastProductPick.product,
                            description: this.lastProductPick.description
                        });
                        var audio = new Audio(this.urlApi + '/beep-07.mp3'); // path to file
                        audio.play();
                        this.$nextTick(() => this.$refs.barcode.focus());
                    } else if (responseData.status === 'WAR') {
                        this.inputProductNotExist.barcode = this.$refs.barcode.value;
                        this.productsPri = responseData.products;
                        this.productsPriOriginal = responseData.products;
                        this.dialogEanInvalid = true;
                        this.$refs.barcode.value = '';
                        var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                        audio.play();
                    } else if (responseData.status === 'WAR2') {
                        this.inputProductNotExist = responseData.product;
                        this.dialogProductNotExist = true;
                        this.$refs.barcode.value = '';
                        var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                        audio.play();
                    } else if (responseData.status === 'WAR3') {
                        this.responseDialog.open = true;
                        this.responseDialog.error = true;
                        this.responseDialog.message = '';
                        this.responseDialog.title = 'Quantidade Inválida!';
                        this.responseDialog.message = responseData.data;
                        if (this.pickOrder.tipoCliente === 'Compras') {
                            this.responseDialog.quantity = true;
                            this.idLinePriToUpdate = responseData.idLinePri;
                        }
                        var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                        audio.play();
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    }
                })
                .catch(() => {
                    var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                    audio.play();
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        closeResetDialog() {
            this.dialogResetPick = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        closeResetConfirm() {
            this.dialogResetPick = false;
            this.responseDialog.open = true;
            this.responseDialog.title = 'Limpar Picking';
            this.responseDialog.loading = true;

            let request = {
                url: '/api/staff/pick/' + this.pickOrderId + '/reset'
            }

            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.responseDialog.message = 'Picking reiniciado com sucesso!';
                        this.responseDialog.error = false;
                        this.getPickOrder();
                    } else {
                        this.responseDialog.message = 'Erro ao reiniciar o picking!';
                        this.responseDialog.error = true;
                    }
                    this.responseDialog.loading = false;
                    this.responseDialog.quantity = false;
                    this.lastPick = '';
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                    this.responseDialog.message = 'Erro de servidor!';
                    this.responseDialog.error = true;
                    this.responseDialog.quantity = false;
                    this.responseDialog.loading = false;
                })
        },
        closeDialogStocks() {
            this.dialogStocks = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        closePick() {
            /** Don't let order proceed without all picks if order is from bicimax.com **/
            if(this.bicimaxCom == true && this.pickCompleted == false){
                this.dialogBicimaxCom = true
                return;
            }
            /** **/
            this.closeMode = 'Picking';
            this.dialogClosePick = true;
        },
        closePickCancel() {
            this.dialogClosePick = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        closePickConfirm() {
            if (this.closeMode === 'ECN') {
                let request = {
                    url: '/api/staff/pick/' + this.pickOrderId + '/closeECN',
                    data: {
                        volumes: this.inputClosePick.volumes,
                        weight: this.inputClosePick.weight
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.$router.push('/online/nozama/orders/' + this.cabecDoc.numOrderPri);
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: responseData.data,
                                type: 'error'
                            })
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            } else {
                let request = {
                    url: '/api/staff/pick/' + this.pickOrderId + '/close',
                    data: {
                        volumes: this.inputClosePick.volumes,
                        weight: this.inputClosePick.weight
                    }
                }

                ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.$router.push('/orders/pick/invoice/' + this.pickOrderId);
                        }
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
            }
        },
        getStockProduct(product) {
            this.currentProduct = product;
            this.dialogStocks = true;
            this.loadingDetail = true;

            let data = {
                product: product
            };
            Order.dispatch('getStocks', data)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.warehouses = responseData.stocks;
                    }
                    this.loadingDetail = false;
                })
                .catch(() => {
                    this.loadingDetail = false;
                })
        },
        cancelPickManual() {
            this.dialogPickManual = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        validatePickManual() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/manual',
                data: {
                    pick: this.inputPickManual
                }
            }

            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.products = responseData.products;

                        this.lastPick = this.inputPickManual.idLine;
                        this.lastProductPick = responseData.lastProductPick;

                        if (this.lastProductsPick.length >= 7) {
                            this.lastProductsPick.pop();
                        }
                        this.lastProductsPick.unshift({
                            product: this.lastProductPick.product,
                            description: this.lastProductPick.description
                        });
                        this.dialogPickManual = false;
                        this.$nextTick(() => this.$refs.barcode.focus());

                    } else if (responseData.status === 'WAR') {
                        this.responseDialog.open = true;
                        this.responseDialog.error = true;
                        this.responseDialog.message = '';
                        this.responseDialog.title = 'Quantidade Inválida!';
                        this.responseDialog.message = responseData.data;

                        this.dialogPickManual = false;
                        var audio = new Audio(this.urlApi + '/beep-03.mp3'); // path to file
                        audio.play();
                    }
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        toggleDialogPickManual(product) {
            this.inputPickManual.id = this.pickOrderId;
            this.inputPickManual.idLine = product.id;
            this.inputPickManual.product = product.artigo;
            this.inputPickManual.description = product.descricao;
            this.inputPickManual.quantity = product.codBarrasPick + product.manualPick;
            this.inputPickManual.barcodePick = product.codBarrasPick;
            this.inputPickManual.orderQuantity = product.quantity;

            this.dialogPickManual = true;
            setTimeout(() => {
                this.$nextTick(() => this.$refs.inputPick.focus());
            }, 200);
        },
        toggleDialogResetPick(product) {
            this.inputResetPick.idLine = product.id;
            this.inputResetPick.product = product.artigo;
            this.dialogResetPickProduct = true
        },
        toggleDialogLote(product) {
            this.editProductLote = Object.assign({}, product);
            this.dialogSelectLote = true;
        },
        resetProductCancel() {
            this.dialogResetPickProduct = false
            this.$nextTick(() => this.$refs.barcode.focus())
        },
        closeResetProductConfirm() {
            this.dialogResetPickProduct = false;
            this.responseDialog.open = true;
            this.responseDialog.title = 'Limpar Picking do artigo';
            this.responseDialog.loading = true;

            let request = {
                url: '/api/staff/pick/' + this.pickOrderId + '/reset/' + this.inputResetPick.idLine
            }

            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.responseDialog.message = 'Picking do artigo reiniciado com sucesso!';
                        this.responseDialog.error = false;
                        this.getPickOrder();
                    } else {
                        this.responseDialog.message = 'Erro ao reiniciar o picking do artigo!';
                        this.responseDialog.error = true;
                    }
                    this.responseDialog.loading = false;
                    this.responseDialog.quantity = false;
                    this.lastPick = '';
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                    this.responseDialog.message = 'Erro de servidor!';
                    this.responseDialog.error = true;
                    this.responseDialog.loading = false;
                })
        },
        toggleDialogChooseProduct() {
            this.dialogProducts = true;
        },
        closeDialogChooseProduct() {
            this.dialogEanInvalid = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        updateFiltersProducts() {
            this.productsPri = this.productsPriOriginal;
            if (this.filtersProducts.product !== '') {
                this.productsPri = this.productsPri.filter(product => product.Artigo.toUpperCase().includes(this.filtersProducts.product.toUpperCase()));
            }
            if (this.filtersProducts.description !== '') {
                this.productsPri = this.productsPri.filter(product => product.Descricao.toLowerCase().includes(this.filtersProducts.description.toLowerCase()));
            }
            if (this.filtersProducts.brand !== '') {
                this.productsPri = this.productsPri.filter(product => product.Marca !== null && product.Marca.toUpperCase().includes(this.filtersProducts.brand.toUpperCase()));
            }
            if (this.filtersProducts.family !== '') {
                this.productsPri = this.productsPri.filter(product => product.Familia.toUpperCase().includes(this.filtersProducts.family.toUpperCase()));
            }
        },
        updateEanProduct() {
            this.loadingBtnUpdProduct = true;

            let request = {
                url: '/api/staff/pick/updateEAN',
                data: {
                    barcode: this.inputProductNotExist.barcode,
                    product: this.productSelect
                }
            }

            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Código de barras atualizado com sucesso!',
                            type: 'success'
                        })

                        this.loadingBtnUpdProduct = false;
                        this.dialogProducts = false;
                        this.dialogEanInvalid = false;
                        this.getPickOrder();
                    } else if (responseData.status === 'WAR') {
                        this.responseDialog.open = true;
                        this.responseDialog.title = 'Atualizar Código de Barras no artigo';
                        this.responseDialog.message = 'Erro ao atualizar o código de barras no artigo!';
                        this.responseDialog.error = true;
                        this.responseDialog.loading = false;
                        this.responseDialog.quantity = false;
                    }
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        closeUpdateBarcodeProduct() {
            this.dialogUpdateEan = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        updateBarcodeProduct() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/updateEAN',
                data: {
                    barcode: this.productToUpdate.barcode,
                    product: this.productToUpdate.product
                }
            }

            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Código de barras atualizado com sucesso!',
                            type: 'success'
                        })

                        this.dialogUpdateEan = false;
                        this.getPickOrder();
                    } else if (responseData.status === 'WAR') {
                        this.responseDialog.open = true;
                        this.responseDialog.title = 'Atualizar Código de Barras no artigo';
                        this.responseDialog.message = 'Erro ao atualizar o código de barras no artigo!';
                        this.responseDialog.error = true;
                        this.responseDialog.loading = false;
                        this.responseDialog.quantity = false;
                    }
                    loader.hide();
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                    loader.hide();
                })
        },
        toggleDialogUpdateBarcode(product) {
            this.productToUpdate.product = product.artigo;
            this.productToUpdate.barcode = '';
            this.productToUpdate.oldBarcode = product.codBarras;

            this.dialogUpdateEan = true;

            setTimeout(() => {
                this.$nextTick(() => this.$refs.inputBarcodeDialog.focus());
            }, 200)
        },
        confirmPickECN() {
            this.dialogClosePick = true;
            this.closeMode = 'ECN';
        },
        printLabelEan() {
            let request = {
                url: '/api/staff/pick/printBarcodeEan',
                data: {
                    product: this.inputPrintEan
                }
            }
            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Etiqueta enviada para a impressora!',
                            type: 'success'
                        })
                        this.$nextTick(() => this.$refs.barcode.focus())
                    } else if (responseData.status === 'WAR') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Não foi encontrado nenhum artigo com este código EAN!',
                            type: 'warning'
                        })
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro a imprimir etiqueta!',
                            type: 'error'
                        })
                    }
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        print() {
            const options = {
                name: '_blank',
                specs: [
                    'fullscreen=yes',
                    'titlebar=yes',
                    'scrollbars=yes'
                ],
                styles: [
                    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
                    '../../assets/css/print.css'
                ]
            }

            this.$htmlToPaper('printMe', options);
        },
        toggleDialogAddSerialNumber(product) {
            this.editProduct = product;
            this.dialogAddSerialNumber = true;
        },
        addSerialNumber() {
            this.editProduct.numSerie = Array.from(this.editProduct.numSerie);
            this.editProduct.numSerie.push(this.inputNumSerie);
            this.inputNumSerie = '';
        },
        deleteNumSerie(numSerie) {
            let index = this.editProduct.numSerie.indexOf(numSerie);
            if (index > -1) {
                this.editProduct.numSerie.splice(index, 1);
            }
        },
        cancelDialogSerialNumber() {
            this.dialogAddSerialNumber = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        saveSerialNumber() {
            let loader = this.$loading.show();

            if (this.inputNumSerie !== '') {
                this.addSerialNumber();
            }

            let request = {
                url: '/api/staff/pick/' + this.pickOrderId + '/numSerie',
                data: {
                    product: this.editProduct
                }
            }

            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Num. Série inseridos com sucesso!',
                            type: 'success'
                        })
                        this.products = responseData.products;
                        this.dialogAddSerialNumber = false;
                        this.$nextTick(() => this.$refs.barcode.focus());
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao inserir Num. Série!',
                            type: 'error'
                        })
                    }
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        addSerialNumberLastProduct() {
            let productSelected = {};
            this.products.forEach(product => {
                if (product.artigo === this.lastProductPick.product) {
                    productSelected = product;
                }
            })

            this.editProduct = productSelected;
            this.dialogAddSerialNumber = true;
        },
        closeResponseDialog() {
            this.responseDialog.open = false
            this.$refs.barcode.value = '';
            this.$nextTick(() => this.$refs.barcode.focus())
        },
        dialogPrintEanOpen(product) {
            this.inputPrintEan = {
                product: product,
                option: 'barcode',
                quantity: 1
            }
            this.dialogPrintEan = true
        },
        dialogPrintEanClose() {
            this.dialogPrintEan = false
            this.$nextTick(() => this.$refs.barcode.focus())
        },
        dialogPrintEanConfirm() {
            for (let i = 0; i < parseInt(this.inputPrintEan.quantity); i++) {
                this.printLabelEan()
            }
            this.dialogPrintEan = false
            this.$nextTick(() => this.$refs.barcode.focus())
        },
        confirmPickECF() {
            this.$router.push('/orders/pick/purchase/' + this.pickOrderId);
        },
        addQuantityToProduct() {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/pick/' + this.pickOrderId + '/addQuantityToProduct',
                data: {
                    idLinePri: this.idLinePriToUpdate
                }
            }
            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.responseDialog.open = false;
                        this.getPickOrder();
                        this.pickBarcode();
                    }
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        openDialogBoxQuantity() {
            this.inputPickManual.quantity = '';
            this.dialogBoxQuantity = true;
            setTimeout(() => {
                this.$nextTick(() => this.$refs.inputBox.focus());
            }, 200);
        },
        confirmDialogBoxQuantity() {
            var qty = parseInt(this.$refs.inputBox.value);
            this.dialogBoxQuantity = false;
            var product = this.products.filter(line => line.id === this.lastPick)[0];
            this.inputPickManual.id = this.pickOrderId;
            this.inputPickManual.idLine = product.id;
            this.inputPickManual.product = product.artigo;
            this.inputPickManual.description = product.descricao;
            this.inputPickManual.quantity = parseInt(product.codBarrasPick) + parseInt(product.manualPick) + qty - 1;
            this.inputPickManual.barcodePick = product.codBarrasPick;
            this.inputPickManual.orderQuantity = product.quantity;
            this.validatePickManual();
        },
        cancelDialogBoxQuantity() {
            this.dialogBoxQuantity = false;
            this.$nextTick(() => this.$refs.barcode.focus());
        },
        dialogSelectLoteClose() {
            this.editProductLote = {};
            this.dialogSelectLote = false;
        },
        dialogSelectLoteConfirm() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/pick/' + this.pickOrderId + '/lote',
                data: {
                    product: this.editProductLote
                }
            }

            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Lote inserido com sucesso!',
                            type: 'success'
                        })
                        this.products = responseData.products;
                        this.dialogSelectLote = false;
                        this.$nextTick(() => this.$refs.barcode.focus());
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro ao inserir Lote!',
                            type: 'error'
                        })
                    }
                    loader.hide();
                })
                .catch(() => {
                    loader.hide();
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                })
        },
        printLabelCall() {
            let request = {
                url: '/api/staff/products/printLabel',
                data: {
                    product: this.labelProduct,
                    option: this.labelOption,
                    size: this.labelSize,
                    quantity: this.labelQuantity
                }
            }
            ApiWS.dispatch('makePost', request)
                .then(response => {
                    let responseData = response.data
                    if (responseData.status === 'OK') {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Etiqueta enviada para a impressora!',
                            type: 'success'
                        })
                        this.$nextTick(() => this.$refs.barcode.focus())
                        this.dialogPrint = false;
                    } else {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro a imprimir etiqueta!',
                            type: 'error'
                        })
                        this.dialogPrint = false;
                    }
                })
                .catch(() => {
                    Alert.dispatch('show', {
                        snackbar: true,
                        text: 'Erro de servidor!',
                        type: 'error'
                    })
                    this.dialogPrint = false;
                })
            this.labelOption = null;
            this.labelProduct = null;
            this.labelSize = null;
        },
        printLabel(product, option, size=null) {
            this.labelOption = option;
            this.labelProduct = product;
            console.log(this.labelProduct)
            this.labelSize = size;
            this.dialogPrint = true;
        },
        limitNumber() {
            if (this.labelQuantity < 1)
                this.labelQuantity = 1
            else if (this.labelQuantity > 5000)
                this.labelQuantity = 5000;
        },
    },
    computed: {
        totalProductsPick() {
            let count = 0;
            this.products.forEach(function (product) {
                count = count + product.codBarrasPick;
            });
            return count;
        },
        totalProductsPickManual() {
            let count = 0;
            this.products.forEach(function (product) {
                count = count + product.manualPick;
            });
            return count;
        },
        totalProductsNotPick() {
            let count = 0;
            this.products.forEach(function (product) {
                count = count + product.quantity;
            });

            return count - this.totalProductsPick - this.totalProductsPickManual;
        },
        validBtnClosePick() {
            return this.inputClosePick.volumes === '' || this.inputClosePick.weight === ''
        },
        urlApi() {
            return process.env.VUE_APP_API_URL;
        },
        pickCompleted() {
            return this.totalProductsNotPick === 0 && this.totalProductsPick + this.totalProductsPickManual > 0
        },
        validateForm() {
            let count = 0;
            this.products.forEach(function (product) {
                if (product.Lotes && product.lote == null) {
                    count++;
                }
            })

            return count
        }
    }
}
</script>
