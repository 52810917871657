<style>
.form-question-user-response {
    transition: box-shadow .3s ease, transform .2s ease;

    &:hover{
        .col{
            box-shadow: 0 0 4px rgba(0,0,0,.6);
        }
    }
}
</style>
<template>
    <v-card v-if="!loading && reloadNeed">
        <v-card-title style="background-color: black; color: white; margin-bottom: 30px;">
            <span style="color: red" class="pr-1">{{ user.entity }}</span> - {{ user.email }}
            <v-spacer></v-spacer>
            <v-btn tile small color="white"
                   depressed outlined
                   id="delete-question"
                   class="float-right ml-2 white--text "
                   @click="callCloseOnParent()">
                FECHAR
                <v-icon size="20">mdi-close</v-icon>
            </v-btn>
        </v-card-title>

        <v-card-text v-if="userResponses.length > 0">
            <v-row class="pl-2 pb-1 form-question-user-response" v-for="(response, index) in userResponses">
                <v-col class="pb-0 pt-0">
                    <h4 class="mt-2">{{ response.header }}</h4>
                    <p class="pl-2 mb-0">R: {{ response.response }}</p>
                    <v-divider v-if="index != userResponses.length - 1" class="divider-aux mt-1"/>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import axios from "axios"

export default {
    props: {
        user: {
            required: true
        },
        formID: {
            required: true
        }
    },
    data: function () {
        return {
            loading: true,
            userResponses: [],
            selectedUser: ''
        }
    },
    computed: {
        reloadNeed() {
            if (this.selectedUser != this.user.email && this.user.email != undefined) {
                this.getUserFormResponse();
            }
            return true
        }
    },
    mounted() {
        this.getUserFormResponse();
    },
    methods: {
        callCloseOnParent() {
            this.userResponses = [];
            this.$emit('close-modal');
        },
        getUserFormResponse() {
            let loader = this.$loading.show();
            this.selectedUser = this.user.email

            axios.get(api.formUserStats(this.formID, this.user.email))
                    .then(response => {
                        let responseData = response.data

                        if (responseData.status == 'OK') {

                            this.userResponses = responseData.data

                        }
                        loader.hide()
                    })
                    .catch(reason => {
                        loader.hide();
                    })
            this.loading = false;
        }
    }
}

</script>