<template>
    <v-container fluid>
        <!-- HEADER -->
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Lista de Pickings de Compras"
                        :subtitle="$t('show') + ' ' + pickingList.length + ' de ' + pickingListOriginal.length + ' Picks'"
                ></page-title>
            </v-col>
            <v-col class="text-right" align-self="center">
                <v-btn tile small  class="mr-2" @click="toggleDialogCreatePick()">Criar Pick</v-btn>
                <v-btn tile small  @click="getPickingList()" color="info"><i class="fas fa-sync-alt mr-1"></i>Atualizar</v-btn>
            </v-col>
        </v-row>

        <!-- LISTA -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th width="1">ID</th>
                            <th width="80">Data</th>
                            <th width="80">Cliente</th>
                            <th>{{ $t('name') }}</th>
                            <th width="100">Total de Referências</th>
                            <th width="100">Total de Artigos</th>
                            <th width="85">Estado</th>
                            <th width="100"></th>
                            <th width="100"></th>
                        </tr>
                        <tr class="filters_row">
                            <td></td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.client"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="filtersPickingList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="filtersPickingList"
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="pick in pickingList">
                            <td>{{ pick.id }}</td>
                            <td>{{ pick.created_at.substring(0,10) }}</td>
                            <td>{{ pick.cliente }}</td>
                            <td>{{ pick.nome }}</td>
                            <td class="text-center">{{ pick.TotalReferencias }}</td>
                            <td class="text-center">{{ pick.TotalArtigos }}</td>
                            <td>{{ pick.status }}</td>
                            <td>
                                <v-btn block small tile color="primary" :to="'/purchases/pick/'+pick.id"
                                       v-if="pick.status !== 'Conferido'">Pick
                                </v-btn>
                                <v-btn block small tile color="success" :to="'/purchases/pick/invoice/'+pick.id"
                                       v-if="pick.status === 'Conferido'">
                                    Faturar
                                </v-btn>
                            </td>
                            <td>
                                <v-btn block small tile color="error" @click="closePick(pick.id)" v-if="$writePermission('orders/pick')">
                                    Arquivar
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <!-- DIALOGS -->
        <v-row>
            <!-- Close Pick-->
            <v-dialog v-model="dialogClosePick" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        Arquivar Picking
                    </v-card-title>
                    <v-card-text>
                        Deseja arquivar o picking?
                    </v-card-text>
                    <v-card-actions>
                        <v-btn tile small  @click="dialogClosePick = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn tile small  color="error" @click="closePickConfirm()">Arquivar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- RESPONSE -->
            <v-dialog v-model="responseDialog.open" persistent max-width="400">
                <v-card>
                    <v-card-title>
                        {{ responseDialog.title }}
                    </v-card-title>
                    <v-card-text>
                        <div class="text-center">
                            <v-progress-circular
                                    v-if="responseDialog.loading"
                                    indeterminate
                                    color="primary"
                                    size="100"
                            ></v-progress-circular>
                            <div v-if="!responseDialog.loading">
                                <i v-if="responseDialog.error" style="color: red" class="fas fa-times fa-5x"></i>
                                <i v-if="!responseDialog.error" style="color: green" class="fas fa-check fa-5x"></i>
                                <p class="headline">{{ responseDialog.message }}</p>
                                <v-alert v-if="responseDialog.submessage" type="error">
                                    {{ responseDialog.submessage }}
                                </v-alert>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="pa-3">
                        <v-spacer></v-spacer>
                        <v-btn v-if="!responseDialog.loading" color="success" @click="responseDialog.open = false">OK
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Create Picking -->
            <v-dialog v-model="dialogCreatePicking" persistent max-width="400">
                <v-card>
                    <v-card-title>Criar Picking</v-card-title>
                    <v-card-text>
                        <v-autocomplete
                                v-model="input.supplier"
                                :items="suppliers"
                                item-value="VALUE"
                                item-text="TEXT"
                                :label="$t('supplier')"
                                outlined
                                hide-details
                        ></v-autocomplete>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogCreatePicking = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" :disabled="input.supplier === ''" @click="createPick()">Criar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>

export default {
    data: function () {
        return {
            pickingList: [],
            pickingListOriginal: [],
            filters: {
                client: '',
                name: ''
            },
            dialogClosePick: false,
            closePickId: '',
            responseDialog: {
                open: false,
                loading: false,
                error: false,
                title: '',
                message: '',
                submessage: ''
            },
            suppliers: [],
            input: {
                supplier: ''
            },
            dialogCreatePicking: false
        }
    },
    created() {
        document.title = 'Picking Compras | STAFF'
        this.getPickingList();
    },
    methods: {
        getPickingList() {
            let loader = this.$loading.show();

            this.pickingList = [];
            this.pickingListOriginal = [];

            let request = {
                url: '/api/staff/purchases/picks'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.pickingList = responseData.pickingList;
                            this.pickingListOriginal = responseData.pickingList;
                            this.suppliers = responseData.suppliers;
                            this.filtersPickingList();
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                    })
        },
        filtersPickingList() {
            this.pickingList = this.pickingListOriginal;

            if (this.filters.client !== '') {
                this.pickingList = this.pickingList.filter(pick => pick.cliente.includes(this.filters.client));
            }
            if (this.filters.name !== '') {
                this.pickingList = this.pickingList.filter(pick => pick.nome.toLowerCase().includes(this.filters.name.toLowerCase()));
            }
        },
        closePick(pickId) {
            this.closePickId = pickId;
            this.dialogClosePick = true;
        },
        closePickConfirm() {
            this.dialogClosePick = false;
            this.responseDialog.open = true;
            this.responseDialog.title = 'Arquivar Picking';
            this.responseDialog.loading = true;

            let request = {
                url: '/api/staff/pick/' + this.closePickId + '/cancel'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.responseDialog.message = 'Picking arquivado com sucesso!'
                            this.responseDialog.error = false;
                            this.getPickingList();
                        } else {
                            this.responseDialog.message = 'Erro ao arquivar picking!'
                            this.responseDialog.error = true;
                        }
                        this.responseDialog.loading = false;
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        createPick() {
            let loader = this.$loading.show();

            let supplierPick = '';
            this.suppliers.forEach((supplier) => {
                if (this.input.supplier === supplier.VALUE) {
                    supplierPick = supplier;
                }
            })

            let request = {
                url: '/api/staff/purchases/pick/createBlankPickingECF',
                data: {
                    supplier: supplierPick
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Picking criado com sucesso!',
                                type: 'success'
                            })
                            this.$router.push('/purchases/pick/' + responseData.id);
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao criar o picking!',
                                type: 'error'
                            })
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        toggleDialogCreatePick() {
            this.input.supplier = '';
            this.dialogCreatePicking = true;
        }
    }
}
</script>
