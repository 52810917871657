import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{attrs:{"tile":"","min-height":"300"}},[_c(VCardText,[_c(VRow,[_c(VCol,[_c(VSimpleTable,{staticClass:"staff_table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('product')))]),_c('th',[_vm._v("Data Entrega")]),_c('th',[_vm._v("Total de Ecomendas")])])]),_c('tbody',_vm._l((this.productPurchases),function(line){return _c('tr',[_c('td',[_vm._v(_vm._s(line.Artigo))]),_c('td',[_vm._v(_vm._s(line.DataEntrega))]),_c('td',[_vm._v(_vm._s(line.totalEncomendado))])])}),0)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }