<style scoped>
    .v-data-table td, .v-data-table th {
        padding: 0 6px;
        height: 26px;
    }
    .v-icon.v-icon.fas {
        font-size: 16px;
    }
</style>
<template>
    <div>
        <v-card v-if="loading" height="280">
            <v-card-text class="text-center" style="height: 100%;">
                <i class="fas fa-cog fa-spin fa-3x" style="margin-top: 90px"></i>
            </v-card-text>
        </v-card>
        <v-card v-if="!loading">
            <v-card-title><i class="fas fa-wrench fa-fw mr-2"></i>FOX SERVICE CENTER</v-card-title>
            <v-card-text>
                <v-simple-table dense style="background-color: transparent;">
                    <thead>
                    <tr>
                        <th></th>
                        <th class="text-center">Pré-Inscr.</th>
                        <th class="text-center">Entradas</th>
                        <th class="text-center">Saídas</th>
                        <th class="text-center">Saldo</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in lines">
                        <td>{{ line.dia }}</td>
                        <td class="text-center">{{ line.registos }}</td>
                        <td class="text-center">{{ line.entradas }}</td>
                        <td class="text-center">{{ line.saidas }}</td>
                        <td class="text-center">{{ line.entradas - line.saidas }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>

    export default {
        data: function () {
            return {
                loading: true,
                lines: []
            }
        },
        created() {
            this.initialize()
        },
        methods: {
            initialize() {
                let request = {
                    url: api.dashboardWidgetTableFox()
                }

                ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.lines = responseData.lines
                            this.loading = false
                        }
                    })
                    .catch(response => {

                    })
            }
        }
    }
</script>
