<template>
    <div style="float: left;">
        <v-row>
            <v-col>
                <v-card width="610" class="mr-3 mb-3" :color="bicimaxPlanet != planetBicimax ? 'red' : ''">
                    <v-row>
                        <v-col class="text-center pa-0">
                            <h4>BICIMAX <i class="fas fa-caret-right"></i>
                                <span style="font-size: 10px;"> A Receber</span> <i class="fas fa-caret-right"></i>
                                PLANET</h4>
                        </v-col>
                        <v-col class="text-center pa-0">
                            <h4>PLANET <i class="fas fa-caret-right"></i> <span style="font-size: 10px;">A Pagar </span>
                                <i class="fas fa-caret-right"></i> BICIMAX</h4>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="text-center pa-2 pb-0">{{ $money(bicimaxPlanet) }}</v-col>
                        <v-col class="text-center pa-2 pb-0">{{ $money(planetBicimax) }}</v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card width="610" class="mr-3 mb-3" :color="bicimaxOffmax != supplierOffmaxBicimax ? 'red' : ''">
                    <v-row>
                        <v-col class="text-center pa-0">
                            <span style="font-weight: bold">BICIMAX <i class="fas fa-caret-right"></i> <span style="font-size: 10px;">A Receber</span> <i class="fas fa-caret-right"></i> OFFMAX</span>
                        </v-col>
                        <v-col class="text-center pa-0">
                            <h4>OFFMAX <i class="fas fa-caret-right"></i> <span style="font-size: 10px;">A Pagar </span>
                                <i class="fas fa-caret-right"></i> BICIMAX</h4>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="text-center pa-2 pb-0">{{ $money(bicimaxOffmax) }}</v-col>
                        <v-col class="text-center pa-2 pb-0">{{ $money(supplierOffmaxBicimax) }}</v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card width="610" class="mr-3 mb-3" :color="planetRimal != supplierRimalPlanet ? 'red' : ''">
                    <v-row>
                        <v-col class="text-center pa-0">
                            <h4>PLANET <i class="fas fa-caret-right"></i>
                                <span style="font-size: 10px;"> A Receber</span> <i class="fas fa-caret-right"></i>
                                RIMAL</h4>
                        </v-col>
                        <v-col class="text-center pa-0">
                            <h4>RIMAL <i class="fas fa-caret-right"></i> <span style="font-size: 10px;">A Pagar </span>
                                <i class="fas fa-caret-right"></i> PLANET</h4>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="text-center pa-2 pb-0">{{ $money(planetRimal) }}</v-col>
                        <v-col class="text-center pa-2 pb-0">{{ $money(supplierRimalPlanet) }}</v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card width="610" class="mr-3 mb-3" :color="bicimaxRimal != supplierRimalBicimax ? 'red' : ''">
                    <v-row>
                        <v-col class="text-center pa-0">
                            <h4>BICIMAX <i class="fas fa-caret-right"></i>
                                <span style="font-size: 10px;"> A Receber</span> <i class="fas fa-caret-right"></i>
                                RIMAL</h4>
                        </v-col>
                        <v-col class="text-center pa-0">
                            <h4>RIMAL <i class="fas fa-caret-right"></i> <span style="font-size: 10px;">A Pagar </span>
                                <i class="fas fa-caret-right"></i> BICIMAX</h4>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="text-center pa-2 pb-0">{{ $money(bicimaxRimal) }}</v-col>
                        <v-col class="text-center pa-2 pb-0">{{ $money(supplierRimalBicimax) }}</v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card width="610" class="mr-3 mb-3" :color="rimalBicimax != bicimaxOCRimal ? 'red' : ''">
                    <v-row>
                        <v-col class="text-center pa-0">
                            <h4>RIMAL <i class="fas fa-caret-right"></i>
                                <span style="font-size: 10px;"> A Receber</span> <i class="fas fa-caret-right"></i>
                                BICIMAX</h4>
                        </v-col>
                        <v-col class="text-center pa-0">
                            <h4>BICIMAX <i class="fas fa-caret-right"></i>
                                <span style="font-size: 10px;"> O. Credor </span>
                                <i class="fas fa-caret-right"></i> RIMAL</h4>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="text-center pa-2 pb-0">{{ $money(rimalBicimax) }}</v-col>
                        <v-col class="text-center pa-2 pb-0">{{ $money(bicimaxOCRimal) }}</v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card width="610" class="mr-3 mb-3" :color="offmaxBicimax != bicimaxOCOffmax ? 'red' : ''">
                    <v-row>
                        <v-col class="text-center pa-0">
                            <h4>OFFMAX <i class="fas fa-caret-right"></i> <span style="font-size: 10px;">A Pagar </span>
                                <i class="fas fa-caret-right"></i> BICIMAX</h4>
                        </v-col>
                        <v-col class="text-center pa-0">
                            <h4>BICIMAX <i class="fas fa-caret-right"></i>
                                <span style="font-size: 10px;"> O. Credor </span>
                                <i class="fas fa-caret-right"></i> OFFMAX</h4>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col class="text-center pa-2 pb-0">{{ $money(offmaxBicimax) }}</v-col>
                        <v-col class="text-center pa-2 pb-0">{{ $money(bicimaxOCOffmax) }}</v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            loading: true,
            bicimaxPlanet: 0,
            planetBicimax: 0,
            bicimaxOffmax: 0,
            supplierOffmaxBicimax: 0,
            planetRimal: 0,
            supplierRimalPlanet: 0,
            bicimaxRimal: 0,
            supplierRimalBicimax: 0,
            rimalBicimax: 0,
            offmaxBicimax: 0,
            bicimaxOCOffmax: 0,
            bicimaxOCRimal: 0
        }
    },
    created() {
        this.initialize()
    },
    methods: {
        initialize() {
            this.loading = true;
            let request = {url: '/api/staff/dashboard/widget/clientPending'};
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.bicimaxPlanet = responseData.bicimaxPlanet;
                            this.planetBicimax = responseData.supplierPlanetBicimax;
                            this.bicimaxOffmax = responseData.bicimaxOffmax;
                            this.supplierOffmaxBicimax = responseData.supplierOffmaxBicimax;
                            this.planetRimal = responseData.planetRimal;
                            this.supplierRimalPlanet = responseData.supplierRimalPlanet;
                            this.bicimaxRimal = responseData.bicimaxRimal;
                            this.supplierRimalBicimax = responseData.supplierRimalBicimax;
                            this.rimalBicimax = responseData.rimalBicimax;
                            this.offmaxBicimax = responseData.offmaxBicimax;
                            this.bicimaxOCOffmax = responseData.bicimaxOCOffmax;
                            this.bicimaxOCRimal = responseData.bicimaxOCRimal;
                            // this.rimal = responseData.rimal;
                        }
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;
                    });
        },
    }
}
</script>
