<style>
.selectedPage {
    font-weight: bold;
    color: blue;
    font-size: 21px;
}

.selectedPage:hover {
    cursor: default !important;
}

.selectablePage {
    margin-left: 15px;
    text-align: center;
    display: inline-block;
    width: 1rem;
}

.selectablePage:hover {
    cursor: pointer;
    font-size: 21px;
    color: #5a5aff;
}

</style>
<template>
    <v-container fluid>

        <!-- HEADER -->
        <v-row class="content-header">
            <v-col class="col-4">
                <page-title
                        title="Lista de encomendas Bicimax.com"
                        :subtitle="$t('show') + ' ' + (pagination.per_page*pagination.page >= orders.length ? (orders.length - pagedList.length + ' - ' + orders.length)  : (((pagedList.length*pagination.page)-pagination.per_page) + ' - ' + pagedList.length*pagination.page)) + ' de ' +  orders.length + ' encomendas'"
                ></page-title>
            </v-col>
            <v-col class="text-center pt-7 col-6">
                <v-pagination
                        v-model="pagination.page"
                        :length="totalPages"
                ></v-pagination>

            </v-col>
            <v-col class="text-right col-2">
                <v-btn tile small class="ml-2 mb-2" @click="getOrderList()" color="info"><i
                        class="fas fa-sync-alt mr-1"></i>Atualizar
                </v-btn>
            </v-col>
        </v-row>

        <!-- LISTA -->
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th width = "50" class="text-center" @click="orderList('id')">ID</th>
                            <th width = "100" class="text-center" @click="orderList('client')">Cliente</th>
                            <th class="text-center" @click="orderList('firstName')">Nome</th>
                            <th class="text-center" @click="orderList('lastName')">Email</th>
                            <th class="text-center" @click="orderList('state')">Estado</th>
                            <th class="text-center" @click="orderList('date')">Data</th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                        v-model="filters.id.value"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="getOrderList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.client.value"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="getOrderList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.name.value"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="getOrderList"
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.email.value"
                                        outlined
                                        dense
                                        hide-details
                                        @keypress.enter="getOrderList"
                                ></v-text-field>
                            </td>
                            <td width="10">
                                <v-select
                                        v-model="filters.state.value"
                                        :items="availableStatus"
                                        item-text="type"
                                        item-value="value"
                                        outlined
                                        dense
                                        hide-details
                                        @change="getOrderList"
                                ></v-select>
                            </td>
                            <td style="text-align:center">
                                <input
                                        type="date"
                                        v-model="filters.date.value"
                                        @change="getOrderList"
                                />
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="loading">
                            <td colspan="15">
                                <v-progress-linear indeterminate></v-progress-linear>
                            </td>
                        </tr>
                        <tr v-for="order in pagedList" @click="showDetails(order)">
                            <td class="text-center">{{ order.id }}</td>
                            <td class="text-center">{{ order.client }}</td>
                            <td class="text-center">{{ order.firstName + ' ' + order.lastName }}</td>
                            <td class="text-center">{{ order.email }}</td>
                            <td width="200" class="text-center">{{ availableStatusDict[order.state] }}</td>
                            <td class="text-center">{{ order.date }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>

        <v-dialog v-model="dialogProducts" persistent max-width="800">
            <v-card v-if="selectedOrder != null">
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <template>
                                <v-data-table
                                        :headers="productTableHeaders"
                                        :items="selectedOrder.products"
                                        :footer-props="{ 'items-per-page-options': [{ text: 'Todos', value: -1 },10, 20, 30], 'items-per-page-text': 'Artigos por página:' }"
                                        :items-per-page="-1"
                                        :server-items-length="selectedOrder.products.length"
                                        :sort-by="['productId']"
                                        :sort-desc="[false]"
                                        class="elevation-1"
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <v-toolbar-title>
                                                Produtos da encomenda número {{ selectedOrder.id }}
                                            </v-toolbar-title>
                                        </v-toolbar>
                                        <br>
                                        <small style="margin-left: 1vw"><strong>Nome</strong>: {{ selectedOrder.firstName }} {{ selectedOrder.lastName}}</small>
                                        <br>
                                        <small style="margin-left: 1vw"><strong>Morada para entrega</strong>: {{ selectedOrder.deliveryAddress1 }} {{ selectedOrder.deliveryAddress2 }} {{ selectedOrder.zipCode }} {{ selectedOrder.city }}</small>
                                        <br>
                                        <small style="margin-left: 1vw"><strong>País</strong>: {{ selectedOrder.country }}</small>
                                    </template>
                                </v-data-table>
                                </template>
<!--                                <table>-->
<!--                                    <thead>-->
<!--                                    <tr>-->
<!--                                        <th>Encomenda</th>-->
<!--                                        <th>Artigo</th>-->
<!--                                        <th>Preco</th>-->
<!--                                        <th>Preco com IVA</th>-->
<!--                                        <th>Quantidade</th>-->
<!--                                    </tr>-->
<!--                                    </thead>-->
<!--                                    <tbody>-->
<!--                                    <tr v-for="product in selectedOrder.products" :key="product.id">-->
<!--                                        <td>{{ product.orderId }}</td>-->
<!--                                        <td>{{ product.productId }}</td>-->
<!--                                        <td>{{ product.price }}</td>-->
<!--                                        <td>{{ product.price_tax }}</td>-->
<!--                                        <td>{{ product.quantity }}</td>-->
<!--                                    </tr>-->
<!--                                    </tbody>-->
<!--                                </table>-->
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="dialogProducts = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


    </v-container>
</template>

<script>

import _ from "lodash";

export default {
    data: function () {
        return {
            loading: false,
            orders: [],
            dialogProducts: false,
            selectedOrder: null,
            ordersOriginal: [],
            availableStatus: [
                {type: "Todos", value: ""},
                {type: "Por Confirmar", value: "created"},
                {type: "Faturado", value: "faturado"},
                {type: "Confirmado", value: "confirmado"},
                {type: "Cancelado", value: "canceled"}
            ],
            filters: {
                id: {value: '', exactValue: false},
                client: {value: '', exactValue: false},
                name: {value: '', exactValue: false},
                email: {value: '', exactValue: false},
                state: {value: '', exactValue: true},
                date: {value: null, exactValue: true},
            },
            productTableHeaders: [
                    /*{
                        text: 'Encomenda',
                        value: 'orderId',
                        align: 'center'
                    },*/
                    {
                        text: 'Artigo',
                        value: 'productId',
                        align: 'center'
                    },
                    {
                        text: 'Preco',
                        value: 'price',
                        align: 'center',
                        slot: 'price'
                    },
                    {
                        text: 'Preco (com IVA)',
                        value: 'price_tax',
                        align: 'center',
                        slot: 'price'
                    },
                    {
                        text: 'Quantidade',
                        value: 'quantity',
                        align: 'center'
                    },
                ],
            availableStatusDict:{
                '1': 'Aguarda Pagamento',
                '2': 'Pago',
                '3': 'Em Processo',
                '4': 'Enviado',
                '5': 'Entregue',
                '6': 'Cancelado',
                '7': 'Devolvido',
                '8': 'Erro de Pagamento',
                '9': 'Pago(Aguarda Stock)',
                '10': 'Aguarda Pagamento Bancário',
                '11': 'Pago',
                '12': 'Aguarda Stock',
                '13': 'Aguarda Pagamento à Cobrança',
                '14': 'Aguarda Pagamento'
            },

            currentRecords: "0",
            countPerPageList: [50, 100, 200, 350, 500],

            currentPageOffset: 0,
            currentPage: 1,

            pagination: {
                page: 1,
                per_page: 50
            },

            selectedHeader: "orderDate",

            headers: {
                "messageId": "asc",
                "clientId": "asc",
                "orderDate": "desc",
                "earliestDeliveryDate": "asc",
                "latestDeliveryDate": "asc",
                "taxIVA": "asc",
                "totalIVA": "asc",
                "totalPrice": "asc",
                "totalProductsRequested": "desc",
                "status": "asc"
            },
        }
    },
    computed: {
        totalPages: function () {
            return Math.ceil(this.orderedList.length / this.pagination.per_page);
        },
        pagedList: function () {
            return this.orderedList.slice((this.pagination.page - 1) * this.pagination.per_page, this.pagination.page * this.pagination.per_page);
        },
        orderedList: function () {
            return _.orderBy(this.orders, this.selectedHeader, this.headers[this.selectedHeader]);
        }
    },
    created() {
        document.title = 'Bicimax.com | STAFF'
        this.getOrderList();
    },
    methods: {
        splitStrings(string,index){
            var aux = [];
            aux = string.split("/");
            return aux[index];
        },
        orderList(col) {
            if (col == this.selectedHeader)
                this.headers[this.selectedHeader] = this.headers[this.selectedHeader] == "asc" ? "desc" : "asc";
            else
                this.headers[this.selectedHeader] = "desc";

            this.selectedHeader = col;
            this.pagination.page = 1;
        },
        getOrderList() {
            this.loading = true;
            this.orders = [];

            this.ordersOriginal = [];

            let request = {
                url: '/api/bicimax-com/orders'
            }

            ApiWS.dispatch('makeGet', request)
                .then(response => {
                    let responseData = response.data;
                    if (responseData.status === 'OK') {
                        this.orders = responseData.data;
                        this.ordersOriginal = responseData.data;
                        //this.filtersPickingList();
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                })
        },
        showDetails(order){
            this.dialogProducts = true;
            this.selectedOrder = order;
        }
    }
}
</script>
