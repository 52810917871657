<template>
  <div>
    <v-card tile min-height="300">
      <v-card-text>
        <v-row>
          <v-col>
            <v-simple-table class="staff_table">
              <thead>
              <tr>
                <th>{{ $t('product') }}</th>
                <th>Código de barras</th>
                <th>Unidade</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="line in this.barCodes">
                <td>{{ line.Artigo }}</td>
                <td>{{ line.CodBarras }}
                    <v-btn  icon small
                            @click="printLabel(line.Artigo, 'eanBox')">
                        <v-icon small>print</v-icon>
                    </v-btn>
                    <v-btn  icon small
                            @click="printLabel(line.Artigo, 'eanBox')">
                        <v-icon>print</v-icon>
                    </v-btn>
                </td>
                <td>
                  {{ line.Unidade }}
                </td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogPrint" width="400">
      <v-card>
        <v-toolbar dense dark color="info">
          <v-toolbar-title class="text-uppercase">{{ $t('quantity') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogPrint = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-btn icon small @click="dialogPrint = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-4">
            <v-col offset="4" cols="3">
              <v-text-field
                  v-model.number="labelQuantity"
                  hide-details
                  type="number"
                  min="1"
                  max="5000"
                  label="Quantidade"
                  @change="limitNumber()"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn tile small @click="dialogPrint=false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn tile small color="primary" @click="printLabelCall()">
            Imprimir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    productId: String,
  },
  data: function () {
    return {
      product: {},
      barCodes: [],
      dialogPrint: false,
      labelQuantity: 1,
      labelOption: null,
      labelProduct: null,
      labelSize: null,
    }
  },
  mounted() {
    this.getBoxBarcode()
  },
  methods: {
    getBoxBarcode() {
      let request = {
        url: '/api/staff/products/' + this.productId + '/barCode'
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {
              this.barCodes = responseData.data
            }
          })

    },
    printLabelCall() {
      let request = {
        url: '/api/staff/products/printLabel',
        data: {
          product: this.labelProduct,
          option: this.labelOption,
          size: this.labelSize,
          quantity: this.labelQuantity
        }
      }
      ApiWS.dispatch('makePost', request)
          .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Etiqueta enviada para a impressora!',
                type: 'success'
              })
              this.$nextTick(() => this.$refs.barcode.focus())
              this.dialogPrint = false;
            } else {
              Alert.dispatch('show', {
                snackbar: true,
                text: 'Erro a imprimir etiqueta!',
                type: 'error'
              })
              this.dialogPrint = false;
            }
          })
          .catch(() => {
            Alert.dispatch('show', {
              snackbar: true,
              text: 'Erro de servidor!',
              type: 'error'
            })
            this.dialogPrint = false;
          })
      this.labelOption = null;
      this.labelProduct = null;
      this.labelSize = null;
    },
    printLabel(product, option, size=null) {
      this.labelOption = option;
      this.labelProduct = product;
      this.labelSize = size;
      this.dialogPrint = true;
    },
  }
}
</script>
