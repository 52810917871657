<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Recibos Bikeplanet"
                ></page-title>
            </v-col>
            <v-col>Total: {{ $money(totalReceipts) }} <br> Total Selecionado: {{ $money(totalReceiptsSelected) }}</v-col>
            <v-col cols="3">
                <v-text-field
                        v-model="settlementId"
                        outlined
                        hide-details
                        dense
                ></v-text-field>
            </v-col>
            <v-col cols="1" class="text-right">
                <v-btn
                        @click="getSettlement()"
                >
                    Carregar
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table class="staff_table">
                    <thead>
                    <tr>
                        <th>SettlementId</th>
                        <th>Tipo</th>
                        <th>Order Id</th>
                        <th>Merchant Order Id</th>
                        <th>Marketplace</th>
                        <th>Amount Type</th>
                        <th>Amount Description</th>
                        <th>Amount</th>
                        <th>Posted Date Time</th>
                        <th>SKU</th>
                        <th>Quantity Purchase</th>
                        <th>NumDoc</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <v-combobox
                                    v-model="filters.transactionType"
                                    :items="transactionTypes"
                                    multiple
                                    outlined
                                    dense
                                    hide-details
                                    @change="settlementFilter"
                            ></v-combobox>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.orderId"
                                    outlined
                                    dense
                                    hide-details
                                    @change="settlementFilter"
                            ></v-text-field>
                        </td>
                        <td>
                            <v-text-field
                                    v-model="filters.merchantOrderId"
                                    outlined
                                    dense
                                    hide-details
                                    @change="settlementFilter"
                            ></v-text-field>
                        </td>
                        <td></td>
                        <td>
                            <v-combobox
                                    v-model="filters.amountType"
                                    :items="amountTypes"
                                    multiple
                                    outlined
                                    dense
                                    hide-details
                                    @change="settlementFilter"
                            ></v-combobox>
                        </td>
                        <td>
                            <v-combobox
                                    v-model="filters.amountDescription"
                                    :items="amountDescriptions"
                                    multiple
                                    outlined
                                    dense
                                    hide-details
                                    @change="settlementFilter"
                            ></v-combobox>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            <span style="cursor: pointer; margin-left: 2px">
                                <i v-if="!checkboxAll" class="far fa-square fa-lg" @click="selectAll()"></i>
                                <i v-if="checkboxAll" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="unselectAll()"></i>
                            </span>
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="settlement in settlements">
                        <td>{{ settlement.settlementId }}</td>
                        <td>{{ settlement.transactionType }}</td>
                        <td>{{ settlement.orderId }}</td>
                        <td>{{ settlement.merchantOrderId }}</td>
                        <td>{{ settlement.marketplaceName }}</td>
                        <td>{{ settlement.amountType }}</td>
                        <td>{{ settlement.amountDescription }}</td>
                        <td>{{ $money(settlement.amount) }}</td>
                        <td>{{ settlement.postedDate }}</td>
                        <td>{{ settlement.sku }}</td>
                        <td>{{ settlement.quantityPurchased }}</td>
                        <td>
                            <v-simple-table>
                    <tbody>
                    <tr v-for="numDoc in settlement.NumDoc">
                        <td>
                            <span style="cursor: pointer">
                                <i v-if="!numDoc.selected" class="far fa-square fa-lg" @click="numDoc.selected = true;makeCounts()"></i>
                                <i v-if="numDoc.selected" class="fas fa-check-square fa-lg" style="color:#1976d2" @click="numDoc.selected = false;makeCounts()"></i>
                            </span>
                        </td>
                        <td>{{ numDoc.Documento }}</td>
                        <td style="white-space: nowrap">{{ $money(numDoc.TotalDocumento) }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
                </td>
                </tr>
                </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row v-if="totalReceiptsSelected > 0 || totalReceiptsSelected < 0">
            <v-col class="text-right">
                <v-btn
                    @click="toggleDialogReceipts()"
                    color="error"
                >Efetuar Recibo</v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="dialogReceipts" persistent max-width="600">
                <v-card>
                    <v-card-title>
                        Efetuar Recibos
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <thead>
                            <tr>
                                <th>Documento</th>
                                <th>Valor</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="invoice in invoices">
                                <td>{{ invoice.Documento }}</td>
                                <td>{{ $money(invoice.TotalDocumento) }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>

                        Valor total dos documentos selecionados: {{ $money(totalInvoices) }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialogReceipts = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: function () {
        return {
            settlementId: '',
            settlements: [],
            settlementsOriginal: [],
            transactionTypes: [],
            amountTypes: [],
            amountDescriptions: [],
            totalReceipts: 0,
            totalReceiptsSelected: 0,
            checkboxAll: false,
            dialogReceipts: false,
            invoices: [],
            totalInvoices: 0,
            filters: {
                transactionType: [],
                orderId: '',
                merchantOrderId: '',
                amountType: [],
                amountDescription: []
            }
        }
    },
    created() {
        document.title = 'Recibos Amazon'
    },
    methods: {
        getSettlement() {
            let loader = this.$loading.show();

            this.filters = {
                transactionType: [],
                orderId: '',
                merchantOrderId: '',
                amountType: [],
                amountDescription: []
            }

            let request = {
                url: '/api/staff/bikeplanet/receipts-amazon/' + this.settlementId
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.settlements = responseData.settlements;

                            let transactionTypes = [];
                            let amountTypes = [];
                            let amountDescriptions = [];
                            this.settlements.forEach((settlement) => {
                                settlement.NumDoc = JSON.parse(settlement.NumDoc);
                                if (!transactionTypes.includes(settlement.transactionType)) {
                                    transactionTypes.push(settlement.transactionType)
                                }
                                if (!amountTypes.includes(settlement.amountType)) {
                                    amountTypes.push(settlement.amountType);
                                }
                                if (!amountDescriptions.includes(settlement.amountDescription)) {
                                    amountDescriptions.push(settlement.amountDescription);
                                }
                            })

                            this.settlementsOriginal = this.settlements;
                            this.transactionTypes = transactionTypes;
                            this.amountTypes = amountTypes;
                            this.amountDescriptions = amountDescriptions;

                            this.settlementFilter();
                        }
                        loader.hide()
                    })
                    .catch(() => {
                        loader.hide()
                    })
        },
        settlementFilter() {
            this.settlements = this.settlementsOriginal;
            if (this.filters.transactionType.length > 0) {
                this.settlements = this.settlements.filter((settlement) => this.filters.transactionType.includes(settlement.transactionType));
            }
            if (this.filters.orderId !== '') {
                this.settlements = this.settlements.filter((settlement) => settlement.orderId.includes(this.filters.orderId));
            }
            if (this.filters.merchantOrderId !== '') {
                this.settlements = this.settlements.filter((settlement) => settlement.merchantOrderId.includes(this.filters.merchantOrderId));
            }
            if (this.filters.amountType.length > 0) {
                this.settlements = this.settlements.filter((settlement) => this.filters.amountType.includes(settlement.amountType));
            }
            if (this.filters.amountDescription.length > 0) {
                this.settlements = this.settlements.filter((settlement) => this.filters.amountDescription.includes(settlement.amountDescription));
            }

            this.makeCounts();
        },
        selectAll() {
            this.checkboxAll = true
            this.settlements.forEach((settlement) => {
                settlement.NumDoc.forEach((doc) => {
                    doc.selected = true;
                })
            })
            this.makeCounts();
        },
        unselectAll() {
            this.checkboxAll = false
            this.settlements.forEach((settlement) => {
                settlement.NumDoc.forEach((doc) => {
                    doc.selected = false;
                })
            })
            this.makeCounts();
        },
        makeCounts() {
            let total = 0;
            let totalSelected = 0;
            let selected = false;
            this.settlements.forEach((settlement) => {
                selected = false;
                if (settlement.NumDoc != null) {
                    settlement.NumDoc.forEach((doc) => {
                        if (doc.selected == true) {
                            selected = true;
                        }
                    })
                }
                if (selected && settlement.amount != null) {
                    totalSelected += parseFloat(settlement.amount)
                }
                if (settlement.amount != null) {
                    total += parseFloat(settlement.amount)
                }
            })
            this.totalReceipts = total;
            this.totalReceiptsSelected = totalSelected;
        },
        toggleDialogReceipts() {
            let invoices = [];
            let exist = false;
            this.settlements.forEach((settlement) => {
                if (settlement.NumDoc != null) {
                    settlement.NumDoc.forEach((doc) => {
                        exist = false;
                        if (doc.selected == true) {
                            invoices.forEach((invoice) => {
                                if (doc.Documento == invoice.Documento) {
                                    exist = true;
                                }
                            })
                            if (!exist) {
                                invoices.push(doc);
                            }
                        }
                    })
                }
            })

            let total = 0;
            invoices.forEach((invoice) => {
                total += invoice.TotalDocumento;
            })

            invoices = _.orderBy(invoices, "Documento", {"Documento": "asc"});

            this.totalInvoices = total;
            this.invoices = invoices;

            this.dialogReceipts = true;
        },
        makeReceipts() {
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/bikeplanet/receipts-amazon/make',
                data: {
                    invoices: this.invoices
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then((response) => {

                        loader.hide()
                    })
                    .catch(() => {
                        loader.hide()
                    })
        }
    }
}
</script>
