<template>
    <v-container fluid>
      <v-row>
        <productsStock></productsStock>
      </v-row>
        <v-row>
            <v-col>
                <TableOrdersB2B></TableOrdersB2B>
            </v-col>
            <v-col></v-col>
        </v-row>
        <v-row>
            <v-col sm="6">
                <last-sales></last-sales>
            </v-col>
            <v-col sm="6">
                <last-orders></last-orders>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import LastOrders from "@/components/Dashboard/Widgets/LastOrders";
    import LastSales from "@/components/Dashboard/Widgets/LastSales";
    import TableServiceCenter from "@/components/Dashboard/Widgets/TableServiceCenter";
    import TableOrdersB2B from "@/components/Dashboard/Widgets/TableOrdersB2B";
    import ProductsStock from "@/components/Dashboard/Widgets/ProductsStock.vue";

    export default {
        components: {
            ProductsStock,
            LastOrders,
            LastSales,
            TableServiceCenter,
            TableOrdersB2B
        },
        computed: {
            profile() {
                return User.getters.getProfileId
            }
        }
    }
</script>
