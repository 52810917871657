<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        title="Logs"
                        :subtitle="$t('show') + ' ' + logs.length + ' logs'"
                ></page-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="staff_v-card">
                    <v-simple-table class="staff_table">
                        <thead>
                        <tr class="titles_row">
                            <th>{{ $t('user') }}</th>
                            <th>{{ $t('source') }}</th>
                            <th>Query</th>
                            <th>{{ $t('created') }}</th>
                        </tr>
                        <tr class="filters_row">
                            <td>
                                <v-text-field
                                        v-model="filters.user"
                                        outlined
                                        @keyup.enter="initialize"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td></td>
                            <td>
                                <v-text-field
                                        v-model="filters.query"
                                        outlined
                                        @keyup.enter="initialize"
                                        hide-details
                                ></v-text-field>
                            </td>
                            <td>
                                <v-text-field
                                        v-model="filters.created_at"
                                        outlined
                                        @keyup.enter="initialize"
                                        hide-details
                                ></v-text-field>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="log in logs">
                            <td>{{ log.user }}</td>
                            <td>{{ log.source }}</td>
                            <td>{{ log.query }}</td>
                            <td>{{ log.created_at }}</td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-select
                        v-model="pagination.per_page"
                        :items="filtersPerPage"
                        outlined
                        single-line
                        @change="initialize()"
                        item-text="TEXT"
                        item-value="VALUE"
                        style="width: 120px; float: left"
                        class="staff_select"
                        hide-details
                ></v-select>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small v-if="pagination.page > 1" @click="pagination.page--;initialize()">Anterior</v-btn>
                <v-btn tile small outlined disabled class="ma-1">{{ pagination.page }}</v-btn>
                <v-btn tile small v-if="pagination.per_page === logs.length" @click="pagination.page++;initialize()">
                    {{ $t('next') }}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
    data: function () {
        return {
            logs: [],
            pagination: {
                per_page: 250,
                page: 1
            },
            filtersPerPage: [
                {TEXT: '25', VALUE: 25},
                {TEXT: '50', VALUE: 50},
                {TEXT: '100', VALUE: 100},
                {TEXT: '250', VALUE: 250},
                {TEXT: '500', VALUE: 500},
                {TEXT: '750', VALUE: 750}
            ],
            filters: {
                user: '',
                query: '',
                created_at: '',
                updated_at: ''
            }
        }
    },
    created() {
        document.title = 'Logs'
        this.initialize()
    },
    methods: {
        initialize() {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/administration/logs',
                data: {
                    filters: this.filters,
                    pagination: this.pagination
                }
            }

            ApiWS.dispatch('makePost', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.logs = responseData.logs;
                        }
                        loader.hide();
                    })
                    .catch(() => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        }
    }
}
</script>
