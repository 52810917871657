<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <page-title
                        :title="$t('create')+' '+$t('products')"
                ></page-title>
            </v-col>
            <v-col class="text-right" align-self="center">
                <v-btn tile small color="primary" class="mr-2" @click="exportTemplate()">Template Artigos</v-btn>
                <v-btn tile small color="success" @click="toggleDialogImportFile()">Importar Excel</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="totalProductsCreated > 0">
            <v-col class="text-right">
                Total de artigos criados: {{ totalProductsCreated }}
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table v-if="lines.length > 0">
                    <thead>
                    <tr>
                        <th>{{ $t('product') }}</th>
                        <th>{{ $t('name') }}</th>
                        <th>{{ $t('description') }} PT</th>
                        <th>{{ $t('description') }} ES</th>
                        <th>{{ $t('description') }} EN</th>
                        <th>Cod. Barras</th>
                        <th>Ean</th>
                        <th>Ref. Forn.</th>
                        <th>Marca</th>
                        <th>Familia</th>
                        <th>Sub Familia</th>
                        <th>Stk Min.</th>
                        <th>Stk Rep.</th>
                        <th>{{ $t('price') }}</th>
                        <th>PVP ES</th>
                        <th>PVP PT</th>
                        <th>Ano</th>
                        <th>Peso</th>
                        <th>Intrastat</th>
                        <!--                        <th>Dim 1</th>-->
                        <!--                        <th>Dim 2</th>-->
                        <!--                        <th>Dim 3</th>-->
                        <!--                        <th>Rub Dim 1</th>-->
                        <!--                        <th>Rub Dim 2</th>-->
                        <!--                        <th>Rub Dim 3</th>-->
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="line in lines">
                        <td>
                            {{ line[0] }}
                            <v-tooltip top v-if="line.hasOwnProperty('product')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="error" v-bind="attrs" v-on="on">error</v-icon>
                                </template>
                                <span>{{ line['product'] }}</span>
                            </v-tooltip>
                        </td>
                        <td>
                            {{ line[1] }}
                            <v-tooltip top v-if="line.hasOwnProperty('name')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="error" v-bind="attrs" v-on="on">error</v-icon>
                                </template>
                                <span>{{ line['name'] }}</span>
                            </v-tooltip>
                        </td>
                        <td>{{ line[2] }}</td>
                        <td>{{ line[3] }}</td>
                        <td>{{ line[4] }}</td>
                        <td>{{ line[5] }}</td>
                        <td>{{ line[6] }}</td>
                        <td>{{ line[7] }}</td>
                        <td>
                            {{ line[8] }}
                            <v-tooltip top v-if="line.hasOwnProperty('brand')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="error" v-bind="attrs" @click="dialogInfoBrands = true" v-on="on">error</v-icon>
                                </template>
                                <span>
                                    <v-row>
                                        <v-col class="py-1">{{ line['brand'] }}</v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-1">Clica para obter mais detalhes!</v-col>
                                    </v-row>
                                </span>
                            </v-tooltip>
                        </td>
                        <td>
                            {{ line[9] }}
                            <v-tooltip top v-if="line.hasOwnProperty('family')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="error" v-bind="attrs" @click="dialogFamilyInfo = true" v-on="on">error</v-icon>
                                </template>
                                <span>
                                    <v-row>
                                        <v-col>{{ line['family'] }}</v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-1">Clica para obter mais detalhes!</v-col>
                                    </v-row>
                                </span>
                            </v-tooltip>
                        </td>
                        <td>
                            {{ line[10] }}
                            <v-tooltip top v-if="line.hasOwnProperty('subFamily')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="error" v-bind="attrs" @click="toggleDialogSubFamilyInfo(line['subFamilies'])" v-on="on">error</v-icon>
                                </template>
                                <span>
                                    <v-row>
                                        <v-col>{{ line['subFamily'] }}</v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-1">Clica para obter mais detalhes!</v-col>
                                    </v-row>
                                </span>
                            </v-tooltip>
                        </td>
                        <td>{{ line[11] }}</td>
                        <td>{{ line[12] }}</td>
                        <td>
                            {{ line[13] }}
                            <v-tooltip top v-if="line.hasOwnProperty('price')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="error" v-bind="attrs" v-on="on">error</v-icon>
                                </template>
                                <span>{{ line['price'] }}</span>
                            </v-tooltip>
                        </td>
                        <td>
                            {{ line[14] }}
                            <v-tooltip top v-if="line.hasOwnProperty('pvp_es')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="error" v-bind="attrs" v-on="on">error</v-icon>
                                </template>
                                <span>{{ line['pvp_es'] }}</span>
                            </v-tooltip>
                        </td>
                        <td>
                            {{ line[15] }}
                            <v-tooltip top v-if="line.hasOwnProperty('pvp_pt')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="error" v-bind="attrs" v-on="on">error</v-icon>
                                </template>
                                <span>{{ line['pvp_pt'] }}</span>
                            </v-tooltip>
                        </td>
                        <td>{{ line[16] }}</td>
                        <td>{{ line[17] }}</td>
                        <td>{{ line[18] }}</td>
                        <!--                        <td>{{ line[19] }}</td>-->
                        <!--                        <td>{{ line[20] }}</td>-->
                        <!--                        <td>{{ line[21] }}</td>-->
                        <!--                        <td>{{ line[22] }}</td>-->
                        <td>
                            <template v-if="line['loading'] === false">
                                {{ line['message'] }}
                                <template v-if="line['status'] === 'OK'">
                                    <v-icon color="success">done</v-icon>
                                </template>
                                <template v-if="line['status'] === 'ERROR'">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon color="error">error</v-icon>
                                        </template>
                                        <span>{{ line['message'] }}</span>
                                    </v-tooltip>
                                </template>
                            </template>
                            <template v-if="line['loading'] === true">
                                <v-progress-circular
                                        indeterminate
                                        color="primary"
                                        :size="20"
                                        :width="2"
                                ></v-progress-circular>
                            </template>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="text-right">
                <v-btn v-if="lines.length > 0 && $writePermission('products/create')" :disabled="totalProductsWithErrors > 0" :loading="loadingCreateProducts" tile small color="success" @click="createProductsPri()">
                    {{ $t('addItem') }} {{ lines.length }} {{ $t('products') }}
                </v-btn>
            </v-col>
        </v-row>


        <!-- Dialogs -->
        <v-row justify="center">
            <v-dialog v-model="dialogImportExcel" persistent max-width="400">
                <v-card>
                    <v-toolbar dark dense color="red" style="max-height: 48px;">
                        <v-toolbar-title>Importar Excel</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="dialogImportExcel = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field class="pt-0" label="Importar Excel" @click='pickFile' v-model='fileName' prepend-icon='attach_file' hide-details></v-text-field>
                        <input
                                type="file"
                                style="display: none"
                                ref="image"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                @change="onFilePicked"
                        >
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="dialogImportExcel = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="success" :loading="loadingImport" :disabled="fileName === ''" @click="analyzeFile">
                            Analisar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogInfoBrands" persistent max-width="500">
                <v-card>
                    <v-toolbar dark dense color="primary" style="max-height: 48px;">
                        <v-toolbar-title>Marcas Permitidas</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="dialogInfoBrands = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-simple-table height="500px">
                            <thead>
                            <tr>
                                <th>Marca</th>
                                <th>{{ $t('description') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="brand in brands">
                                <td>{{ brand.Marca }}</td>
                                <td>{{ brand.Descricao }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small tile @click="dialogInfoBrands = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogFamilyInfo" persistent max-width="500">
                <v-card>
                    <v-toolbar dark dense color="primary" style="max-height: 48px;">
                        <v-toolbar-title>Familias Permitidas</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="dialogFamilyInfo = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-simple-table height="500px">
                            <thead>
                            <tr>
                                <th>Familia</th>
                                <th>{{ $t('description') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="family in families">
                                <td>{{ family.Familia }}</td>
                                <td>{{ family.Descricao }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small tile @click="dialogFamilyInfo = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogSubFamilyInfo" persistent max-width="500">
                <v-card>
                    <v-toolbar dark dense color="primary" style="max-height: 48px;">
                        <v-toolbar-title>Sub Familias Permitidas na Familia <template v-if="subFamilies.length > 0">{{ subFamilies[0].Familia }}</template></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="dialogSubFamilyInfo = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-simple-table height="500px">
                            <thead>
                            <tr>
                                <th>Sub Familia</th>
                                <th>{{ $t('description') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="subFamily in subFamilies">
                                <td>{{ subFamily.SubFamilia }}</td>
                                <td>{{ subFamily.Descricao }}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn small tile @click="dialogSubFamilyInfo = false">Fechar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data: function () {
        return {
            dialogImportExcel: false,
            dialogInfoBrands: false,
            dialogFamilyInfo: false,
            dialogSubFamilyInfo: false,
            loadingImport: false,
            loadingCreateProducts: false,
            fileName: '',
            fileUrl: '',
            fileFile: '',
            lines: [],
            totalProductsCreated: 0,
            brands: [],
            families: [],
            subFamilies: []
        }
    },
    created() {

    },
    computed: {
        totalProductsWithErrors() {
            let count = 0;
            this.lines.forEach((line) => {
                if (line['status'] === 'ERROR') {
                    count++;
                }
            })
            return count;
        }
    },
    methods: {
        pickFile() {
            this.$refs.image.value = '';
            this.$refs.image.click()
        },
        onFilePicked(e) {
            const files = e.target.files
            if (files[0] !== undefined) {
                this.fileName = files[0].name
                if (this.fileName.lastIndexOf('.') <= 0) {
                    return
                }
                const fr = new FileReader()
                fr.readAsDataURL(files[0])
                fr.addEventListener('load', () => {
                    this.fileUrl = fr.result
                    this.fileFile = files[0] // this is an image file that can be sent to server...
                })
            } else {
                this.fileName = ''
                this.fileFile = ''
                this.fileUrl = ''
            }
        },
        analyzeFile() {
            let loader = this.$loading.show();
            this.loadingImport = true;
            let request = {
                url: '/api/staff/products/analyzeExcel',
                file: this.fileFile
            }

            ApiWS.dispatch('makeFile', request)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {
                            this.lines = responseData.lines;
                            this.brands = responseData.brands;
                            this.families = responseData.families;
                            this.dialogImportExcel = false;
                        }
                        this.loadingImport = false;
                        loader.hide();
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        this.loadingImport = false;
                        loader.hide();
                    })
        },
        toggleDialogImportFile() {
            this.mode = 'analyze';
            this.fileName = '';
            this.fileUrl = '';
            this.fileFile = '';

            this.dialogImportExcel = true;
        },
        exportTemplate() {
            let loader = this.$loading.show();
            let request = {
                url: '/api/staff/products/exportTemplateExcel'
            }

            ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        });
                        var fileURL = URL.createObjectURL(file);
                        var a = document.createElement('a');
                        a.href = fileURL;
                        a.target = '_blank';

                        a.download = 'templateArtigos.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        loader.hide();
                    })
                    .catch(() => {
                        loader.hide();
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        createProductsPri() {
            this.loadingCreateProducts = true;
            this.totalProductsCreated = 0;

            this.lines.forEach((line) => {
                line.loading = true;

                let request = {
                    url: '/api/staff/products/create',
                    data: {
                        product: line
                    }
                }

                ApiWS.dispatch('makePost', request)
                        .then(response => {
                            let responseData = response.data;
                            if (responseData.status === 'OK') {
                                line.loading = false;
                                line.status = 'OK';
                                this.totalProductsCreated++;
                            } else {
                                line.loading = false;
                                line.status = 'ERROR';
                                line.message = responseData.message;
                            }
                        })
                        .catch(() => {
                            line.loading = false;
                            line.status = 'ERROR';
                            line.message = 'Erro de servidor!';

                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                        })

                if (this.totalProductsCreated === this.lines.length) {
                    this.loadingCreateProducts = false;
                }
            })
        },
        toggleDialogSubFamilyInfo(subFamilies) {
            this.subFamilies = subFamilies;
            this.dialogSubFamilyInfo = true;
        }
    }
}
</script>
