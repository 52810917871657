<style scoped>
.back_btn {
    float: left;
}

.staff_subtitle {
    margin-bottom: 0 !important;
    margin-top: -6px;
    font-size: 14px;
    color: gray;
}
</style>
<template>
    <v-container fluid>
        <v-row class="content-header">
            <v-col>
                <div style="min-height: 44px">
                    <v-btn tile small color="default" class="back_btn mt-2 mr-3"
                           @click="$router.push('/online/nozama/orders')">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <h6 class="title text-uppercase" style="min-height: 32px">{{
                            $t('nozamaOrders') + ' - ' + numDoc
                        }}</h6>
                </div>
            </v-col>
            <v-col class="text-right">
                <v-btn tile small color="error" @click="cancelOrder()">Cancelar</v-btn>
                <v-btn tile small color="success" :disabled="loading || pick.id !== 0" @click="createPick()"
                       :loading="loadingPick">CRIAR PICK
                </v-btn>
                <v-btn tile small color="success" :disabled="loading || pick.id === 0 || excelExists"
                       @click="exportXlsPick()"
                       :loading="loadingXlsPick">EXCEL
                </v-btn>
                <v-btn tile small color="success" :disabled="loading || !excelExists || invoiceNumber"
                       @click="startInvoiceProcess()">
                    FATURAR
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <h3 class="mb-1">ENCOMENDA</h3>
                                <div>
                                    Estado:
                                    <v-chip small v-if="!cabecDoc.Anulado && !cabecDoc.Fechado" color="info">
                                        Aberto
                                    </v-chip>
                                    <v-chip small v-if="!cabecDoc.Anulado && cabecDoc.Fechado" color="success">
                                        Terminado
                                    </v-chip>
                                    <v-chip small v-if="cabecDoc.Anulado && !cabecDoc.Fechado" color="error">
                                        Cancelado
                                    </v-chip>
                                    <v-chip small v-if="cabecDoc.Anulado && cabecDoc.Fechado" color="black"
                                            text-color="white">
                                        Apagado
                                    </v-chip>
                                </div>
                                <div>Referência: {{ cabecDoc.Referencia }}</div>
                                <div>Total Artigos {{ $t('product') }}s: {{ cabecDoc.quantArtigos }}</div>
                                <div>Satisfação: <strong v-if="cabecDoc.totalPick">{{
                                        (cabecDoc.totalPick / cabecDoc.quantArtigos * 100).toFixed(2)
                                    }}%</strong></div>
                            </v-col>
                            <v-col>
                                <h3 class="mb-1">DOCS</h3>
                                <div>ID Pick: {{ pick.id }} - {{ pick.Status }}</div>
                                <div>Artigos Picados: {{ cabecDoc.totalPick }}</div>
                                <div>Nº Fatura: <strong>{{ invoiceNumber }}</strong></div>
                                <div>Fecha: {{ RimalFA.Data }}</div>
                            </v-col>
                            <v-col>
                                <h3 class="mb-1">DOCS INTERMÉDIOS</h3>
                                <div>Bicimax: {{ BicimaxFA.Documento }}</div>
                                <div>Planet: {{ PlanetVFA }}</div>
                                <div>Planet: {{ PlanetFA.Documento }}</div>
                                <div>Rimal: {{ RimalVFA }}</div>
                            </v-col>
                            <v-col cols="5" class="text-right">
                                <v-row dense>
                                    <v-col cols="2"></v-col>
                                    <v-col cols="2">
                                        <v-card v-if="!cabecDoc.Anulado && pick.id!==0" color="info" dark
                                                @click="showPick()">
                                            <v-card-text class="text-center">
                                                <v-icon>fas fa-barcode</v-icon>
                                                <p class="white--text mb-0 mt-2">Ir para Picking</p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-card v-if="!cabecDoc.Anulado && excelExists" color="green darken-2" dark
                                                @click="exportXlsPick()">
                                            <v-card-text class="text-center">
                                                <v-icon>far fa-file-excel</v-icon>
                                                <p class="white--text mb-0 mt-2">Download Excel</p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-card v-if="!cabecDoc.Anulado && RimalFA.Documento" color="red darken-2" dark
                                                @click="exportPdfRimal()">
                                            <v-card-text class="text-center">
                                                <v-icon>far fa-file-pdf</v-icon>
                                                <p class="white--text mb-0 mt-2">Download Fatura</p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-card v-if="!cabecDoc.Anulado && RimalFA.Documento" color="indigo darken-2"
                                                dark
                                                @click="printLabelSeur()">
                                            <v-card-text class="text-center">
                                                <v-icon>fas fa-truck</v-icon>
                                                <p class="white--text mb-0 mt-2">Imprimir Etiqueta</p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="2">
                                        <v-card v-if="!cabecDoc.Anulado && pdfExists" color="orange darken-2" dark
                                                @click="sendEmailNozama()">
                                            <v-card-text class="text-center">
                                                <v-icon>far fa-paper-plane</v-icon>
                                                <p class="white--text mb-0 mt-2">Enviar <br> Fatura</p>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- LINES -->
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text>

                        <v-row>
                            <v-col>
                                <p class="mb-1">{{ 'A mostrar ' + lines.length + ' linhas' }}</p>
                            </v-col>
                            <v-col class="text-right">
                                <v-btn tile small :class="{'secondary': mode === 'TODOS'}"
                                       @click="filterLines('TODOS')">TODOS
                                </v-btn>
                                <v-btn tile small :class="{'secondary': mode === 'ENVIADOS'}"
                                       @click="filterLines('ENVIADOS')">ENVIADOS
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-simple-table class="staff_table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>{{ $t('product') }}</th>
                                <th>Cód. Barras</th>
                                <th>{{ $t('description') }}</th>
                                <th class="text-center">{{ $t('quantity') }}</th>
                                <th class="text-center">Stock A1</th>
                                <th class="text-center">Stock</th>
                                <th class="text-center">{{ $t('price') }}</th>
                                <th class="text-center">Total</th>
                                <th class="text-center font-weight-black">Picados</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(line, index) in lines"
                                :class="{'red lighten-4': line.totalPick === 0, 'yellow lighten-4': line.totalPick > 0 && line.totalPick !== line.Quantidade}">
                                <td>{{ index + 1 }}</td>
                                <td>{{ line.Artigo }}</td>
                                <td>{{ line.CodBarras }}</td>
                                <td>{{ line.Descricao }}</td>
                                <td class="text-center">{{ line.Quantidade }}</td>
                                <td class="text-center">{{ line.stockA1 }}</td>
                                <td class="text-center">{{ line.stock }}</td>
                                <td class="text-center">{{ $money(line.PrecUnit) }}</td>
                                <td class="text-center">{{ $money(line.TotalIliquido) }}</td>
                                <td class="text-center font-weight-black">{{ line.totalPick }}</td>
                                <td class="text-center">
                                    <i v-if="line.totalPick === 0" style="color: red" class="fas fa-times"></i>
                                    <i v-if="line.totalPick > 0 && line.totalPick !== line.Quantidade"
                                       style="color: orange"
                                       class="fas fa-circle"></i>
                                    <i v-if="line.totalPick > 0 && line.totalPick === line.Quantidade"
                                       style="color: green"
                                       class="fas fa-check"></i>
                                </td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- DIAOLOGS -->
        <v-row justify="center">

            <!-- FATURAR DIALOG -->
            <v-dialog v-model="invoiceDialog" persistent max-width="448">
                <v-card>
                    <v-card-title>
                        A CRIAR FATURA NOZAMA ...
                    </v-card-title>
                    <v-card-text class="pt-4">
                        <v-list-item style="min-height: 48px;">
                            <v-list-item-icon class="my-3">
                                <v-progress-circular v-if="invoiceStep === 0" indeterminate size="24"
                                                     width="2"></v-progress-circular>
                                <v-icon v-if="invoiceStep > 0" color="green">done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>A criar fatura Bicimax</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item style="min-height: 48px;">
                            <v-list-item-icon class="my-3">
                                <v-progress-circular v-if="invoiceStep === 1" indeterminate size="24"
                                                     width="2"></v-progress-circular>
                                <v-icon v-if="invoiceStep > 1" color="green">done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>A criar os artigos na Planet</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item style="min-height: 48px;">
                            <v-list-item-icon class="my-3">
                                <v-progress-circular v-if="invoiceStep === 2" indeterminate size="24"
                                                     width="2"></v-progress-circular>
                                <v-icon v-if="invoiceStep > 2" color="green">done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>A criar a compra na Planet</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item style="min-height: 48px;">
                            <v-list-item-icon class="my-3">
                                <v-progress-circular v-if="invoiceStep === 3" indeterminate size="24"
                                                     width="2"></v-progress-circular>
                                <v-icon v-if="invoiceStep > 3" color="green">done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>A criar fatura na Planet</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item style="min-height: 48px;">
                            <v-list-item-icon class="my-3">
                                <v-progress-circular v-if="invoiceStep === 4" indeterminate size="24"
                                                     width="2"></v-progress-circular>
                                <v-icon v-if="invoiceStep > 4" color="green">done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>A criar os artigos na Rimal</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item style="min-height: 48px;">
                            <v-list-item-icon class="my-3">
                                <v-progress-circular v-if="invoiceStep === 5" indeterminate size="24"
                                                     width="2"></v-progress-circular>
                                <v-icon v-if="invoiceStep > 5" color="green">done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>A criar a compra na Rimal</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item style="min-height: 48px;">
                            <v-list-item-icon class="my-3">
                                <v-progress-circular v-if="invoiceStep === 6" indeterminate size="24"
                                                     width="2"></v-progress-circular>
                                <v-icon v-if="invoiceStep > 6" color="green">done</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>A criar fatura na Rimal</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn v-if="invoiceStep === 7 " color="success" @click="invoiceDialog = false">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- CANCEL ORDER DIALOG -->
            <v-dialog v-model="cancelDialog" persistent max-width="448">
                <v-card>
                    <v-card-title class="text-capitalize"></v-card-title>
                    <v-card-text class="text-center">
                        <i style="color: red" class="fas fa-times fa-5x"></i>
                        <p class="headline">Confirma que pretende cancelar a encomenda?</p>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="default" @click="cancelDialog = false">Fechar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="confirmCancelOrder()" :loading="loadingCancel">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- ERROR DIALOG -->
            <v-dialog v-model="errorDialog" persistent max-width="448">
                <v-card>
                    <v-card-title class="text-capitalize"></v-card-title>
                    <v-card-text class="text-center">
                        <i style="color: red" class="fas fa-times fa-5x"></i>
                        <p class="headline" v-if="errorMessage">{{ errorMessage }}</p>
                        <p class="subtitle-2" v-if="errorSubtitle">{{ errorSubtitle }}</p>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="default" @click="errorDialog = false">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- SUCCESS DIALOG -->
            <v-dialog v-model="successDialog" persistent max-width="448">
                <v-card>
                    <v-card-title class="text-capitalize"></v-card-title>
                    <v-card-text class="text-center">
                        <i style="color: green" class="fas fa-check fa-5x"></i>
                        <p class="headline" v-if="successMessage">{{ successMessage }}</p>
                        <p class="subtitle-2" v-if="successSubtitle">{{ successSubtitle }}</p>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="default" @click="successDialog = false">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-row>

    </v-container>
</template>

<script>

import i18n from "@/plugins/i18n"

export default {
    data: () => ({
        loading: true,
        loadingCancel: false,
        loadingPick: false,
        loadingXlsPick: false,
        loadingPdfRimal: false,
        invoiceExists: false,
        pdfExists: false,
        numDoc: '',
        lines: [],
        linesOriginal: [],
        stock: {},
        pick: {id: 0, Status: ''},
        cabecDoc: {},
        errorDialog: false,
        errorSubtitle: '',
        errorMessage: '',
        successDialog: false,
        successSubtitle: '',
        successMessage: '',
        invoiceDialog: false,
        invoiceStep: 0,
        invoiceNumber: '',
        excelExists: false,
        BicimaxFA: '',
        PlanetVFA: '',
        PlanetFA: '',
        RimalVFA: '',
        RimalFA: '',
        mode: 'TODOS',
        cancelDialog: false,
        shippingAddress: {}
    }),
    created() {
        this.numDoc = this.$route.params.id
        document.title = i18n.t('nozamaOrders') + ' - ' + this.numDoc
        this.getOrder()
    },
    methods: {
        getOrder() {
            this.loading = true
            let loader = this.$loading.show();

            let request = {
                url: '/api/staff/nozama/orders/' + this.numDoc
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.cabecDoc = responseData.cabecDoc
                            if (!this.cabecDoc.Anulado && this.cabecDoc.Fechado) {
                                this.mode = 'ENVIADOS';
                            }
                            this.linesOriginal = responseData.lines
                            this.filterLines(this.mode)
                            this.pick = responseData.pickOrder
                            this.excelExists = responseData.excelExists
                            this.pdfExists = responseData.pdfExists
                            this.BicimaxFA = responseData.BicimaxFA
                            this.PlanetVFA = responseData.PlanetVFA
                            this.PlanetFA = responseData.PlanetFA
                            this.RimalVFA = responseData.RimalVFA
                            this.RimalFA = responseData.RimalFA
                            this.invoiceNumber = this.RimalFA.Documento
                            this.shippingAddress = responseData.shippingAddress
                        }
                        this.loading = false
                        loader.hide()
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loading = false
                        loader.hide()
                    })
        },
        getStockProduct(product) {
            this.stock = {}

            let request = {
                url: '/api/staff/pick/product/' + product + '/stock'
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.stock = responseData.stock
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        createPick() {
            this.loadingPick = true
            let request = {
                url: '/api/staff/nozama/orders/' + this.numDoc + '/createPick'
            }
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.$router.push('/orders/pick/' + responseData.id)
                        } else {
                            this.loadingPick = false
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loadingPick = false
                    })
        },
        showPick() {
            if (this.pick.Status === 'Conferido') {
                this.$router.push('/orders/pick/' + this.pick.id + '/view')
            } else {
                this.$router.push('/orders/pick/' + this.pick.id)
            }
        },
        exportXlsPick() {
            this.loadingXlsPick = true
            let request = {
                url: '/api/staff/nozama/orders/' + this.numDoc + '/' + this.cabecDoc.Referencia + '/exportXlsPick'
            }

            ApiWS.dispatch('makeDownload', request)
                    .then(response => {
                        var file = new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        })
                        var fileURL = URL.createObjectURL(file)
                        var a = document.createElement('a')
                        a.href = fileURL
                        a.target = '_blank'
                        a.download = this.cabecDoc.Referencia + '_conf.xlsx'
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)
                        this.loadingXlsPick = false
                        this.excelExists = true
                    })
                    .catch((error) => {
                        console.log(error)
                        this.loadingXlsPick = false
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        exportPdfRimal() {
            this.loadingPdfRimal = true
            ApiWS.dispatch(
                    'makeGet',
                    {url: '/api/staff/orders/rimal/pdf/get/' + this.RimalFA.Serie + '/' + this.RimalFA.TipoDoc + '/' + this.RimalFA.NumDoc}
            ).then(response => {
                        let responseGet = response.data
                        if (responseGet.status === 'OK') {
                            let request = {url: '/api/staff/orders/rimal/pdf/open/' + this.RimalFA.Serie + '/' + this.RimalFA.TipoDoc + '/' + this.RimalFA.NumDoc}
                            ApiWS.dispatch('makeDownload', request)
                                    .then(response => {
                                        var file = new Blob([response.data], {
                                            type: 'application/pdf'
                                        })
                                        var fileURL = URL.createObjectURL(file)
                                        var a = document.createElement('a')
                                        a.href = fileURL
                                        a.target = '_blank'
                                        a.download = this.RimalFA.TipoDoc + ' ' + this.RimalFA.Serie + '/' + this.RimalFA.NumDoc + ' - Isowo.pdf'
                                        document.body.appendChild(a)
                                        a.click()
                                        document.body.removeChild(a)
                                        this.loadingPdfRimal = false
                                        this.pdfExists = true
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        this.loadingPdfRimal = false
                                        Alert.dispatch('show', {
                                            snackbar: true,
                                            text: 'Erro de servidor!',
                                            type: 'error'
                                        })
                                    })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar PDF!',
                                type: 'error'
                            })
                        }
                    }
            ).catch(() => {
                Alert.dispatch('show', {
                    snackbar: true,
                    text: 'Erro de servidor!',
                    type: 'error'
                })
            })
        },
        exportPdfRimal2() {
            let request = {
                url: '/api/staff/orders/getPDFFilePlanet/' + serie + '/' + tipodoc + '/' + numdoc
            }

            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            let request = {
                                url: '/api/staff/orders/openPDFFilePlanet/' + serie + '/' + tipodoc + '/' + numdoc
                            }
                            ApiWS.dispatch('makeDownload', request)
                                    .then(response => {
                                        var file = new Blob([response.data], {
                                            type: 'application/pdf'
                                        });
                                        var fileURL = URL.createObjectURL(file);
                                        var a = document.createElement('a');
                                        a.href = fileURL;
                                        a.target = '_blank';
                                        a.download = serie + '_' + tipodoc + '_' + numdoc + '.pdf';
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                    })
                                    .catch(response => {
                                        Alert.dispatch('show', {
                                            snackbar: true,
                                            text: 'Erro de servidor!',
                                            type: 'error'
                                        })
                                    })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro ao gerar PDF!',
                                type: 'error'
                            })
                        }
                    })
                    .catch(response => {
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                    })
        },
        startInvoiceProcess() {
            this.invoiceDialog = true
            this.invoiceStep = 0
            this.createInvoiceBicimax()
        },
        createInvoiceBicimax() {
            let request = {url: '/api/staff/nozama/orders/' + this.numDoc + '/bicimax/invoice'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.invoiceStep = 1
                            this.BicimaxFA = responseData.BicimaxFA
                            this.updateProductsPlanet()
                        } else {
                            this.invoiceDialog = false
                            this.errorDialog = true
                            this.errorMessage = "Erro a criar a fatura Bicimax!"
                            this.errorSubtitle = responseData.message
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.invoiceDialog = false
                        this.errorDialog = true
                        this.errorMessage = 'Erro de servidor!'
                        this.errorSubtitle = ''
                    })
        },
        updateProductsPlanet() {
            let request = {url: '/api/staff/nozama/orders/' + this.numDoc + '/planet/products'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.invoiceStep = 2
                            this.createPurchasePlanet()
                        } else {
                            this.invoiceDialog = false
                            this.errorDialog = true
                            this.errorMessage = "Erro ao criar os artigos na Planet!"
                            this.errorSubtitle = responseData.message
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.invoiceDialog = false
                        this.errorDialog = true
                        this.errorMessage = 'Erro de servidor!'
                        this.errorSubtitle = ''
                    })
        },
        createPurchasePlanet() {
            let request = {url: '/api/staff/nozama/orders/' + this.numDoc + '/planet/purchase'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.invoiceStep = 3
                            this.PlanetVFA = responseData.PlanetVFA
                            this.createInvoicePlanet()
                        } else {
                            this.invoiceDialog = false
                            this.errorDialog = true
                            this.errorMessage = "Erro a criar a compra na Planet!"
                            this.errorSubtitle = responseData.message
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.invoiceDialog = false
                        this.errorDialog = true
                        this.errorMessage = 'Erro de servidor!'
                        this.errorSubtitle = ''
                    })
        },
        createInvoicePlanet() {
            let request = {url: '/api/staff/nozama/orders/' + this.numDoc + '/planet/invoice'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.invoiceStep = 4
                            this.PlanetFA = responseData.PlanetFA
                            this.updateProductsRimal()
                        } else {
                            this.invoiceDialog = false
                            this.errorDialog = true
                            this.errorMessage = "Erro a criar a fatura na Planet!"
                            this.errorSubtitle = responseData.message
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.invoiceDialog = false
                        this.errorDialog = true
                        this.errorMessage = 'Erro de servidor!'
                        this.errorSubtitle = ''
                    })
        },
        updateProductsRimal() {
            let request = {url: '/api/staff/nozama/orders/' + this.numDoc + '/rimal/products'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.invoiceStep = 5
                            this.createPurchaseRimal()
                        } else {
                            this.invoiceDialog = false
                            this.errorDialog = true
                            this.errorMessage = "Erro ao criar os artigos na Planet!"
                            this.errorSubtitle = responseData.message
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.invoiceDialog = false
                        this.errorDialog = true
                        this.errorMessage = 'Erro de servidor!'
                        this.errorSubtitle = ''
                    })
        },
        createPurchaseRimal() {
            let request = {url: '/api/staff/nozama/orders/' + this.numDoc + '/rimal/purchase'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.invoiceStep = 6
                            this.PlanetVFA = responseData.PlanetVFA
                            this.createInvoiceRimal()
                        } else {
                            this.invoiceDialog = false
                            this.errorDialog = true
                            this.errorMessage = "Erro a criar a compra na Rimal!"
                            this.errorSubtitle = responseData.message
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.invoiceDialog = false
                        this.errorDialog = true
                        this.errorMessage = 'Erro de servidor!'
                        this.errorSubtitle = ''
                    })
        },
        createInvoiceRimal() {
            let request = {url: '/api/staff/nozama/orders/' + this.numDoc + '/rimal/invoice'}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.invoiceStep = 7
                            this.RimalFA = responseData.RimalFA
                            this.invoiceNumber = this.RimalFA.Documento
                            // this.exportPdfRimal()
                        } else {
                            this.invoiceDialog = false
                            this.errorDialog = true
                            this.errorMessage = "Erro a criar a fatura na Rimal!"
                            this.errorSubtitle = responseData.message
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.invoiceDialog = false
                        this.errorDialog = true
                        this.errorMessage = 'Erro de servidor!'
                        this.errorSubtitle = ''
                    })
        },
        sendEmailNozama() {
            let loader = this.$loading.show({
                container: null,
                canCancel: false,
                color: 'rgb(192, 13, 13)',
                backgroundColor: '#ffffff',
                height: 128,
                width: 128,
                loader: 'spinner',
                opacity: 0.3,
            })
            ApiWS.dispatch(
                    'makeGet',
                    {url: '/api/staff/orders/rimal/pdf/get/' + this.RimalFA.Serie + '/' + this.RimalFA.TipoDoc + '/' + this.RimalFA.NumDoc}
            ).then(response => {
                let responseGet = response.data
                if (responseGet.status === 'OK') {
                    let request = {url: '/api/staff/nozama/orders/' + this.numDoc + '/email'}
                    ApiWS.dispatch('makeGet', request)
                            .then(response => {
                                loader.hide();
                                let responseData = response.data
                                if (responseData.status === 'OK') {
                                    this.cabecDoc = responseData.cabecDoc
                                    this.successDialog = true
                                    this.successMessage = "Email enviado com sucesso!"
                                } else {
                                    this.errorDialog = true
                                    this.errorMessage = "Erro a enviar o email à Nozama!"
                                    this.errorSubtitle = responseData.message
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                                loader.hide();
                                this.invoiceDialog = false
                                this.errorDialog = true
                                this.errorMessage = 'Erro de servidor!'
                                this.errorSubtitle = ''
                            })
                }
            })
        },
        filterLines(mode) {
            this.mode = mode
            if (mode === 'ENVIADOS') {
                this.lines = this.linesOriginal.filter(line => line.totalPick > 0);
            } else {
                this.lines = this.linesOriginal;
            }
        },
        cancelOrder() {
            this.cancelDialog = true;
        },
        confirmCancelOrder() {
            this.loadingCancel = true
            let request = {url: '/api/staff/nozama/orders/' + this.numDoc + '/cancel/' + this.pick.id}
            ApiWS.dispatch('makeGet', request)
                    .then(response => {
                        let responseData = response.data
                        if (responseData.status === 'OK') {
                            this.cabecDoc = responseData.cabecDoc
                            this.$router.push('/nozama/orders')
                        } else {
                            this.cancelDialog = false
                            this.errorDialog = true
                            this.errorMessage = "Erro ao cancelar a encomenda!"
                            this.errorSubtitle = responseData.message
                        }
                        this.loadingCancel = false
                    })
                    .catch(error => {
                        console.log(error)
                        this.loadingCancel = false
                        this.cancelDialog = false
                        this.errorDialog = true
                        this.errorMessage = 'Erro de servidor!'
                        this.errorSubtitle = ''
                    })
        },
        printLabelSeur() {

            let loader = this.$loading.show();

            let order = {
                courier: 'NACEX',
                clientCode: '1500',
                clientName: 'Nozama Soluciones SL',
                salesman: '31'
            };

            Order.dispatch('getTrackingNumber', order)
                    .then(response => {
                        let responseData = response.data;
                        if (responseData.status === 'OK') {

                            let inputConfigShip = {
                                price: '',
                                payment: 'no',
                                quantity: this.pick.volumes,
                                weight: this.pick.peso,
                                observation: '',
                                account: 'auto',
                                portesDebidos: 0,
                                type: '1',
                                address: {
                                    Address: this.shippingAddress.Fac_Mor_EN,
                                    Address2: this.shippingAddress.Fac_Mor2_EN,
                                    Locality: this.shippingAddress.Fac_Local_EN,
                                    Cp: this.shippingAddress.Fac_Cp_EN,
                                    CpLocality: this.shippingAddress.Fac_CpLocal_EN,
                                    Country: this.shippingAddress.Pais_EN
                                }
                            };

                            let shipping = {
                                client: {
                                    entity: '1500',
                                    name: 'Nozama Soluciones SL',
                                    tel: '',
                                    email: 'seguimiento@isowo.es'
                                },
                                shipping: inputConfigShip,
                                trackingCode: responseData.ref,
                                selectedOrders: [{
                                    id: this.BicimaxFA.Id,
                                    type: this.BicimaxFA.TipoDoc,
                                    number: this.BicimaxFA.NumDoc,
                                    serie: this.BicimaxFA.Serie,
                                    total_doc: this.BicimaxFA.TotalDocumento,
                                    vendedor: '31'
                                }],
                                courier: 'NACEX'
                            }

                            Order.dispatch('createShippingLabel', shipping)
                                    .then(response => {
                                        let responseData = response.data
                                        if (responseData.status === 'OK') {
                                            Alert.dispatch('show', {
                                                snackbar: true,
                                                text: 'Etiqueta criada com sucesso!',
                                                type: 'success'
                                            })
                                        } else if (responseData.status === 'WARN') {
                                            Alert.dispatch('show', {
                                                snackbar: true,
                                                text: responseData.data,
                                                type: 'error'
                                            })
                                        } else {
                                            Alert.dispatch('show', {
                                                snackbar: true,
                                                text: 'Erro ao criar envio!',
                                                type: 'error'
                                            })
                                        }
                                        loader.hide();
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        console.log(response)
                                        Alert.dispatch('show', {
                                            snackbar: true,
                                            text: 'Erro de servidor!',
                                            type: 'error'
                                        })
                                        loader.hide();
                                    })
                        } else {
                            Alert.dispatch('show', {
                                snackbar: true,
                                text: 'Erro de servidor!',
                                type: 'error'
                            })
                            loader.hide();
                        }
                    })
                    .catch(response => {
                        console.log(response)
                        Alert.dispatch('show', {
                            snackbar: true,
                            text: 'Erro de servidor!',
                            type: 'error'
                        })
                        loader.hide();
                    })
        }
    }
}
</script>
