<template>
  <div>
    <v-card tile min-height="300">
      <v-card-text>
        <v-row>
          <v-col>
            <v-simple-table class="staff_table">
              <thead>
              <tr>
                <th>{{ $t('product') }}</th>
                <th>Data Entrega</th>
                <th>Total de Ecomendas</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="line in this.productPurchases">
                <td>{{ line.Artigo }}</td>
                <td>{{ line.DataEntrega }}</td>
                <td>{{ line.totalEncomendado }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  props: {
    productId: String,
  },
  data: function () {
    return {
      product: {},
      productPurchases: [],
    }
  },
  mounted() {
    this.getProductPurchases()
  },
  methods: {
    getProductPurchases() {
      let request = {
        url: '/api/staff/products/' + this.productId + '/productPurchases'
      }

      ApiWS.dispatch('makeGet', request)
          .then(response => {
            let responseData = response.data
            if (responseData.status === 'OK') {
              this.productPurchases = responseData.data
            }
          })

    }
  }
}
</script>
